import { useQuery } from 'react-query';
import { getProjectPanelConfig, getProjectPanelConfigs } from '../api/projectPanelConfig';

/*
 * Custom hooks for fetching ProjectPanelConfigs
 */

export const useProjectPanelConfigsQuery = props => {
  const { projectId, onSuccess } = props;

  return useQuery(`projectPanelConfigs-${projectId}`, () => getProjectPanelConfigs({ projectId }), {
    enabled: !!projectId,
    refetchOnWindowFocus: false,
    onSuccess
  });
};

export const useProjectPanelConfigQuery = props => {
  const { id, onSuccess } = props;

  return useQuery(`projectPanelConfig-${id}`, () => getProjectPanelConfig({ id }), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    onSuccess
  });
};
