import React from 'react';

export const TwoBars = props => {
  const { percentLeft, countLeft, percentRight, countRight, backgroundColorLeft, backgroundColorRight } = props;
  const start = 120 - percentLeft;
  return (
    <svg className="two-nets" style={{ marginTop: '0.25rem' }}>
      <rect x={start} y="0" width={`${percentLeft}`} height="24" fill={`${backgroundColorLeft}`}></rect>
      <text x="8" y="55%" dominant-baseline="middle">
        {countLeft}
      </text>
      <rect x="128" y="0" width={`${percentRight}`} height="24" fill={`${backgroundColorRight}`}></rect>
      <text x="256" y="55%" dominant-baseline="middle" text-anchor="end">
        {countRight}
      </text>
    </svg>
  );
};
