import { takeLatest, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import { fetchParticipantsActions } from '../actions/participantDataActions';
import {
  createMetadataCollectionActions,
  saveMetadataJobActions,
  setMetadataCollectionActions,
  replaceMetadataJobActions,
  deleteMetadataCollectionActions,
  fetchParticipantListsActions,
  fetchParticipantCollectionJobsActions,
  pingParticipantCollectionJobsActions
} from '../actions/fetchParticipantLists';
import {
  createMetadataCollection,
  saveMetadataJob,
  setMetadataCollection,
  deleteMetadataCollection,
  fetchParticipantLists,
  fetchParticipantCollectionJobs,
  pingParticipantCollectionJobs
} from '../../../api/participantListApi';
import createFetchSaga from './createFetchSaga';

function showSuccessToast(msg) {
  toast.success({ text: msg });
}

function* reFetchParticipantCollectionJobs({ payload }) {
  yield put(
    fetchParticipantCollectionJobsActions.request({
      projectId: payload.projectId,
      storeId: payload.storeId
    })
  );
}

function* reFetchCollectionParticipants({ payload }) {
  yield put(
    fetchParticipantsActions.request({
      entityId: payload.collectionId
    })
  );
}

// Create metadata collection step 1
function* createMetadataCollectionSaga() {
  const saga = createFetchSaga(createMetadataCollectionActions, createMetadataCollection);
  yield* saga();
}

// Create metadata collection step 2
function* saveMetadataJobSaga() {
  const saga = createFetchSaga(saveMetadataJobActions, saveMetadataJob);
  yield* saga();
  yield takeLatest(saveMetadataJobActions.succeeded, ({ payload }) => {
    showSuccessToast(intl.get('app.participantList.imported', { name: payload.collectionName }));
  });
  yield takeLatest(saveMetadataJobActions.succeeded, reFetchParticipantCollectionJobs);
}

// Replace metadata collection step 1
function* setMetadataCollectionSaga() {
  const saga = createFetchSaga(setMetadataCollectionActions, setMetadataCollection);
  yield* saga();
}

// Replace metadata collection step 2
function* replaceMetadataJobSaga() {
  const saga = createFetchSaga(replaceMetadataJobActions, saveMetadataJob);
  yield* saga();
  yield takeLatest(replaceMetadataJobActions.succeeded, ({ payload }) => {
    showSuccessToast(intl.get('app.participantList.replaced', { name: payload.collectionName }));
  });
  yield takeLatest(replaceMetadataJobActions.succeeded, reFetchCollectionParticipants);
  yield takeLatest(replaceMetadataJobActions.succeeded, reFetchParticipantCollectionJobs);
}

function* deleteMetadataCollectionSaga() {
  const saga = createFetchSaga(deleteMetadataCollectionActions, deleteMetadataCollection);
  yield* saga();
  yield takeLatest(deleteMetadataCollectionActions.succeeded, ({ payload }) => {
    showSuccessToast(intl.get('app.participantList.deleted', { name: payload.collectionName }));
  });
  yield takeLatest(deleteMetadataCollectionActions.succeeded, reFetchParticipantCollectionJobs);
}

function* participantListsSaga() {
  const saga = createFetchSaga(fetchParticipantListsActions, fetchParticipantLists);
  yield* saga();
}

function* participantCollectionJobsSaga() {
  const saga = createFetchSaga(fetchParticipantCollectionJobsActions, fetchParticipantCollectionJobs);
  yield* saga();
}

function* pingParticipantCollectionJobsSaga() {
  const saga = createFetchSaga(pingParticipantCollectionJobsActions, pingParticipantCollectionJobs);
  yield* saga();
}

export {
  createMetadataCollectionSaga,
  saveMetadataJobSaga,
  setMetadataCollectionSaga,
  replaceMetadataJobSaga,
  deleteMetadataCollectionSaga,
  participantListsSaga,
  participantCollectionJobsSaga,
  pingParticipantCollectionJobsSaga
};
