import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { isEqual, get } from 'lodash';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { RESPONSE_SET_TYPE, getJoinerTitle, getJoinerType, isBipolar } from '../../../../util/joinerUtil';
import { getFlattenedJoinersList } from '../../../../util/conceptRotationUtil';
import { mediaUtil } from '../../../../util/mediaUtil';
import { ImageLightbox } from '../../../../components/imageLightbox/ImageLightbox';

const POSITIVE_FILTER = 'Positive';
const NEUTRAL_FILTER = 'Neutral';
const NEGATIVE_FILTER = 'Negative';

function getFilterTerm(field, fieldPrefix, value, valuePrefix) {
  return {
    field,
    fieldPrefix,
    operator: ':',
    type: 'term',
    value,
    valuePrefix
  };
}

function buildCRFilterTerm(conceptId) {
  return getFilterTerm(conceptId, 'C', '1', 'P');
}

function buildFilterTerm({ questionDefId, displayNumber, value, sentiment }) {
  const displayNum = displayNumber.replace ? displayNumber.replace('.', '-') : displayNumber;
  const valuePrefix = sentiment ? 'S' : `A${displayNum}`;
  return getFilterTerm(questionDefId, `Q${displayNum}`, value, valuePrefix);
}

function isStimOnly(joiner) {
  return joiner.stimOnly || joiner.thirdPartyStim;
}

function getMediaUrl(joiner) {
  return joiner.stim && joiner.stim.media && mediaUtil.getMediaUrl(joiner.stim.media);
}

// Get the stim assigned to a choice in a multi/matrix/ranked question
function getChoiceStim(choice) {
  const media = get(choice.value, 'imageStim.media');
  return mediaUtil.getMediaUrl(media);
}

function scrollToSelectedJoiner(selectedJoiner) {
  if (selectedJoiner) {
    const container = document.querySelector('.select-data-points-table');
    const elem = document.querySelector(`#joiner-${selectedJoiner.id}`);
    if (container && elem) {
      container.scrollTo(0, elem.offsetTop);
    }
  }
}

export const SurveyContentSelector = props => {
  const { questionJoiners, keywordWhiteList, selectedFilterTerms, selectedJoiner } = props;

  const [questionData, setQuestionData] = useState([]);
  const [selectedRows, updateSelectedRows] = useState(new Set());

  // Initialize the data
  useEffect(() => {
    if (questionJoiners.length) {
      setQuestionData(buildData());
    }
  }, [questionJoiners]);

  // Initialize the selected rows
  useEffect(() => {
    const selectedRows = new Set();
    questionData.forEach(question => {
      question.answers.forEach(answersObj => {
        answersObj.answers.forEach(answer => {
          if (isTermSelected(answer.term)) {
            selectedRows.add(question.key);
          }
        });
      });
    });
    if (selectedJoiner) {
      selectedRows.add(selectedJoiner.id);
    }
    updateSelectedRows(selectedRows);
    scrollToSelectedJoiner(selectedJoiner);
  }, [questionData]);

  function buildData() {
    const rows = [];
    const flattenedJoiners = getFlattenedJoinersList(questionJoiners);
    flattenedJoiners.forEach(joiner => {
      const questionDefId = joiner.def && joiner.def.id;
      const type = getJoinerType(joiner);
      const { displayNumber } = joiner;
      const questionText = get(joiner, 'def.question.prompt') || '';

      let questionTitle = getJoinerTitle(joiner);
      let groupName = joiner.groupName || '';
      if (joiner.conceptRotationId) {
        const crJoiner = questionJoiners.find(j => j.id === joiner.conceptRotationId);
        const concept = crJoiner.conceptRotation.concepts.find(c => c.id === joiner.conceptId);
        questionTitle = getJoinerTitle(joiner, concept);
        groupName = concept.title;
      }

      if (joiner.conceptId && displayNumber.endsWith('.1')) {
        // Add a row for the concept
        rows.push({
          key: joiner.conceptId,
          groupName: '',
          displayNumber: '',
          type: 'concept',
          questionTitle: groupName,
          questionText: '',
          media: getMediaUrl(joiner),
          answers: []
        });
      }

      if (isStimOnly(joiner)) {
        rows.push({
          key: joiner.id,
          groupName,
          displayNumber,
          type,
          questionTitle,
          questionText,
          media: getMediaUrl(joiner),
          answers: []
        });
      } else if (type === RESPONSE_SET_TYPE.conceptRotation) {
        rows.push({
          key: joiner.id,
          groupName,
          displayNumber,
          type,
          questionTitle,
          questionText,
          answers: [
            {
              answers: joiner.conceptRotation.concepts.map(concept => {
                return {
                  value: concept.title,
                  id: concept.id,
                  term: buildCRFilterTerm(concept.id)
                };
              })
            }
          ]
        });
      } else if (type === RESPONSE_SET_TYPE.open) {
        const answers = keywordWhiteList
          ? keywordWhiteList.map(word => {
              return {
                value: word,
                id: word,
                term: buildFilterTerm({ questionDefId, displayNumber, value: word, sentiment: false })
              };
            })
          : [];
        answers.push({
          value: POSITIVE_FILTER,
          id: POSITIVE_FILTER,
          term: buildFilterTerm({ questionDefId, displayNumber, value: POSITIVE_FILTER, sentiment: true })
        });
        answers.push({
          value: NEUTRAL_FILTER,
          id: NEUTRAL_FILTER,
          term: buildFilterTerm({ questionDefId, displayNumber, value: NEUTRAL_FILTER, sentiment: true })
        });
        answers.push({
          value: NEGATIVE_FILTER,
          id: NEGATIVE_FILTER,
          term: buildFilterTerm({ questionDefId, displayNumber, value: NEGATIVE_FILTER, sentiment: true })
        });
        rows.push({
          key: joiner.id,
          groupName,
          displayNumber,
          type,
          questionTitle,
          questionText,
          media: getMediaUrl(joiner),
          answers: [{ answers }]
        });
      } else if (type === RESPONSE_SET_TYPE.multi) {
        rows.push({
          key: joiner.id,
          groupName,
          displayNumber,
          type,
          questionTitle,
          questionText,
          media: getMediaUrl(joiner),
          answers: [
            {
              answers: joiner.def.responseSet.choices
                .filter(choice => !choice.value.disable)
                .map(choice => {
                  return {
                    value: choice.value.value || choice.value.abbreviatedValue,
                    id: choice.id,
                    media: getChoiceStim(choice),
                    term: buildFilterTerm({ questionDefId, displayNumber, value: choice.id })
                  };
                })
            }
          ]
        });
      } else if (type === RESPONSE_SET_TYPE.matrix || type === RESPONSE_SET_TYPE.ranked) {
        const { responseSet } = joiner.def;
        rows.push({
          key: joiner.id,
          groupName,
          displayNumber,
          type,
          questionTitle,
          questionText,
          media: getMediaUrl(joiner),
          answers: (isBipolar(responseSet) ? responseSet.entries.bipolarRows : responseSet.entries.rows)
            .filter(row => !row.value.disable)
            .map(row => {
              return {
                key: `${joiner.id}-${row.id}`,
                rowTitle: row.value.value,
                media: getChoiceStim(row),
                answers: joiner.def.responseSet.entries.columnData.columns
                  .filter(column => !column.value.disable)
                  .map(column => {
                    return {
                      value: column.value.value || column.value.abbreviatedValue,
                      id: `${row.id}+${column.id}`,
                      media: getChoiceStim(column),
                      term: buildFilterTerm({ questionDefId, displayNumber, value: `${row.id}+${column.id}` })
                    };
                  })
              };
            })
        });
      }
    });
    return rows;
  }

  function isTermSelected(term) {
    return selectedFilterTerms.some(t => isEqual(t, term));
  }

  function addOrRemoveFilterTerm(e, term) {
    e.stopPropagation();
    props.addOrRemoveFilterTerm(term);
  }

  function handleRowClick(key) {
    const copy = new Set(selectedRows);
    if (copy.has(key)) {
      copy.delete(key);
    } else {
      copy.add(key);
    }
    updateSelectedRows(copy);
  }

  function getAnswerElems(question) {
    const elems = [];
    let hasSelected = false;
    question.answers.forEach(answersObj => {
      const answerElems = [];
      answersObj.answers.forEach(answer => {
        const isSelectedTerm = isTermSelected(answer.term);
        hasSelected = isSelectedTerm || hasSelected;
        if (answerElems.length) {
          answerElems.push(<span> | </span>);
        }
        const classes = {
          selected: isSelectedTerm,
          'link-button': true
        };
        const key = `${question.key}-${answer.id}`;
        answerElems.push(
          <Button className={classnames(classes)} onClick={e => addOrRemoveFilterTerm(e, answer.term)} key={key}>
            {answer.media ? (
              <img className="media-thumbnail" src={answer.media} alt={answer.value} title={answer.value} />
            ) : (
              <span>{answer.value}</span>
            )}
          </Button>
        );
      });

      if (question.type !== RESPONSE_SET_TYPE.conceptRotation) {
        if (answersObj.rowTitle) {
          elems.push(<div className="answer-row-title">{answersObj.rowTitle}</div>);
        } else if (answersObj.media) {
          elems.push(<div className="answer-row-title" />);
          elems.push(<img className="media-thumbnail" src={answersObj.media} style={{ display: 'block' }} alt="" />);
        }
      }

      elems.push(answerElems);
    });

    return { hasSelected, elems };
  }

  function getQuestionRows() {
    const rows = [];
    questionData.forEach(question => {
      const isSelectedRow = selectedRows.has(question.key);
      const questionType = intl.get(`app.questionType.${question.type}`);
      const answers = getAnswerElems(question);
      const disabled = answers.elems.length === 0;
      const clickable = !disabled && !answers.hasSelected;

      rows.push(
        <tr
          id={`joiner-${question.key}`}
          className={classnames({ clickable })}
          disabled={disabled}
          onClick={clickable ? () => handleRowClick(question.key) : () => {}}
          key={question.key}
        >
          <td style={{ fontWeight: '600' }}>{question.displayNumber}</td>
          <td className="text-truncate" title={question.groupName}>
            {question.groupName}
          </td>
          <td className="text-truncate" title={question.questionTitle}>
            {question.questionTitle}
          </td>
          <td>
            <div className="text-truncate" title={question.questionText}>
              {question.questionText}
            </div>
            {isSelectedRow && <div className="answers-container">{answers.elems}</div>}
          </td>
          <td className="text-truncate" title={questionType}>
            {questionType}
          </td>
          <td style={{ textAlign: 'center' }}>
            <ImageLightbox
              id={question.key}
              className="media-thumbnail"
              src={question.media}
              largerImageOnHover="left"
            />
          </td>
        </tr>
      );
    });

    return rows;
  }

  return (
    <table className="invoke-table">
      <thead>
        <tr>
          <th style={{ width: '3rem' }}>#</th>
          <th style={{ width: '7rem' }}>{intl.get('app.group')}</th>
          <th style={{ width: '8rem' }}>{intl.get('app.title')}</th>
          <th style={{ width: '100%' }}>{intl.get('app.surveyQuestionsAndContent')}</th>
          <th style={{ width: '7rem' }}>{intl.get('app.type')}</th>
          <th style={{ textAlign: 'center', width: '5rem' }}>{intl.get('app.media')}</th>
        </tr>
      </thead>
      <tbody>{getQuestionRows()}</tbody>
    </table>
  );
};
