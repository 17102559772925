import { createSelector, createStructuredSelector } from 'reselect';

const sessionStore = state => state.session;
const selectSessionId = (state, sessionId) => sessionId;
const sessionSelector = () =>
  createSelector([sessionStore, selectSessionId], (sessionStore, sessionId) => ({
    session: sessionStore.sessions[sessionId],
    requestInProgress: sessionStore.requestInProgress,
    allSessions: sessionStore.allSessions,
    sessionSaved: sessionStore.sessionSaved,
    cloneRequestInProgress: sessionStore.cloneRequestInProgress,
    importRequestInProgress: sessionStore.importRequestInProgress
  }));

const sessionValidationSelector = () =>
  createSelector([sessionStore, selectSessionId], (sessionStore, sessionId) => ({
    sessionValidation: sessionStore.sessionValidations[sessionId]
  }));

const selectSessionNames = sessionStore =>
  (sessionStore.allSessions &&
    sessionStore.allSessions.content &&
    sessionStore.allSessions.content.map(session => ({
      label: session.name,
      value: session.id
    }))) ||
  [];
const selectRequestInProgress = sessionStore => sessionStore.requestInProgress;

const sessionNamesListSelector = () =>
  createSelector(
    sessionStore,
    createStructuredSelector({
      allSessions: selectSessionNames,
      requestInProgress: selectRequestInProgress
    })
  );

export { sessionSelector, sessionValidationSelector, sessionNamesListSelector };
