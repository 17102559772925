import React, { useCallback, useMemo, useReducer } from 'react';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { useQuery } from 'react-query';
import { InvokeTable, Loader } from 'webapp-common';
import { fetchPanelConfigs, savePanelConfig, deletePanelConfig } from '../../../api/clientAdminApi';
import { toast } from '../../../util/toast';
import { panelConfigUtil } from './panelConfigUtil';
import { PanelConfigModal } from './PanelConfigModal';

const { panelConfigType } = panelConfigUtil;
const reducer = (state, payload) => ({ ...state, ...payload });

export const PanelConfigs = () => {
  const [state, setState] = useReducer(reducer, {
    panelConfigs: {},
    editConfig: null,
    showLoader: false
  });

  const { isFetching: fetchPanelConfigsInProgress } = useQuery('fetch-configs', () => fetchPanelConfigs(), {
    refetchOnWindowFocus: false,
    onSuccess: panelConfigs => setState({ panelConfigs })
  });

  async function saveConfig(config) {
    const response = await doQuery(() => savePanelConfig(config), intl.get('app.panelSaved'));
    if (response) {
      setState({
        panelConfigs: updateConfigList(response),
        editConfig: null,
        showLoader: false
      });
    }
  }

  async function deleteConfig(config) {
    const response = await doQuery(() => deletePanelConfig(config), intl.get('app.panelDeleted'));
    if (response) {
      const panelConfigs = cloneDeep(state.panelConfigs);
      const index = panelConfigs.content.findIndex(cfg => cfg.id === config.id);
      panelConfigs.content.splice(index, 1);
      setState({
        panelConfigs,
        editConfig: null,
        showLoader: false
      });
    }
  }

  async function doQuery(queryFunction, successMsg) {
    setState({ showLoader: true });
    try {
      const response = await queryFunction();
      toast.success({ text: successMsg });
      return response;
    } catch (error) {
      setState({ showLoader: false });
      toast.error({ text: error.errorMessage });
    }
  }

  function updateConfigList(config) {
    const panelConfigs = cloneDeep(state.panelConfigs);
    const index = panelConfigs.content.findIndex(cfg => cfg.id === config.id);
    if (index === -1) {
      panelConfigs.content.push(config);
    } else {
      panelConfigs.content[index] = config;
    }
    panelConfigs.content.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return (nameA < nameB && -1) || (nameA > nameB && 1) || 0;
    });
    return panelConfigs;
  }

  const formatNameCell = useCallback(info => {
    const config = info.row.original;
    return (
      <div className="link" onClick={() => setState({ editConfig: config })}>
        {config.name}
      </div>
    );
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: intl.get('app.panelName'),
        cell: formatNameCell,
        cellClassName: 'text-truncate',
        enableSorting: false
      },
      {
        accessorKey: 'panelId',
        header: intl.get('app.panelId'),
        cellClassName: 'text-truncate',
        enableSorting: false
      }
    ];
  }, [formatNameCell]);

  return (
    <>
      {state.showLoader && <Loader spinner fullScreen />}
      <Button
        color="primary"
        onClick={() =>
          setState({ editConfig: panelConfigUtil.getDefaultPanelConfig({ type: panelConfigType.GLOBAL }) })
        }
      >
        {intl.get('app.add')}
      </Button>
      <div style={{ maxWidth: '30rem' }}>
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={state.panelConfigs.content ?? []}
          loading={fetchPanelConfigsInProgress}
        />
      </div>
      {state.editConfig && (
        <PanelConfigModal
          config={state.editConfig}
          toggle={() => setState({ editConfig: null })}
          saveConfig={saveConfig}
          deleteConfig={deleteConfig}
        />
      )}
    </>
  );
};
