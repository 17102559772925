import { handleActions } from 'redux-actions';
import { fetchReportListActions } from '../actions/reportListActions';

const initialState = {
  reportListRequested: false
};

const reportListReducer = handleActions(
  {
    [fetchReportListActions.triggered](state) {
      return { ...state, reportListRequested: true };
    },

    [fetchReportListActions.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload,
        reportListRequested: false
      };
    },

    [fetchReportListActions.failed](state) {
      return {
        ...state,
        reportListRequested: false
      };
    }
  },
  initialState
);
export default reportListReducer;
