import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import moment from 'moment';

import './ParticipantReport.css';

export const ParticipantReport = props => {
  const { fetchSessions, projectId, sessions } = props;

  const [selectedSurvey, setSelectedSurvey] = useState();

  // Fetch sessions/surveys for project
  useEffect(() => {
    if (projectId) {
      fetchSessions({
        projectId,
        screener: false,
        pageSize: -1
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (sessions && sessions.length > 0) {
      setSelectedSurvey(sessions[0].id);
    }
  }, [sessions]);

  function hasMultipleSurveys() {
    return sessions && sessions.length > 1;
  }

  function getSurveyOptions() {
    if (sessions) {
      return sessions.map(s => (
        <option value={s.id} key={s.id}>
          {s.name}
        </option>
      ));
    }
  }

  function getReportLinkParams() {
    const timeStamp = moment().format('MM-DD-YYYY_HH-mm-ss');
    const filename = `${timeStamp}_${selectedSurvey}.csv`;
    const url = `/a/api/exportPostSessionParticipantCSV/${selectedSurvey}/${filename}`;
    return {
      url,
      filename
    };
  }

  function downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function downloadReport() {
    const reportLinkParams = getReportLinkParams();
    downloadURI(reportLinkParams.url, reportLinkParams.filename);
  }

  return (
    <section className="participant-report">
      <div className="survey-select" style={{ visibility: hasMultipleSurveys() ? '' : 'hidden' }}>
        <span className="select-survey">{intl.get('app.selectSurvey')}:</span>
        <Input type="select" onChange={e => setSelectedSurvey(e.target.value)}>
          {getSurveyOptions()}
        </Input>
      </div>
      <div className="label">{intl.get('app.participant.report')}</div>
      <Button color="primary" onClick={downloadReport}>
        {intl.get('app.download')}
      </Button>
    </section>
  );
};
