import { handleActions, combineActions } from 'redux-actions';
import { addToBlacklistAction, deleteFromBlacklistAction, fetchBlacklistAction } from '../actions/clientAdminActions';

const initialState = {
  blacklistUpdated: false
};

const blacklistReducer = handleActions(
  {
    [fetchBlacklistAction.triggered](state) {
      return {
        ...state,
        fetchBlacklistInProgress: true,
        blacklistUpdated: false
      };
    },

    [fetchBlacklistAction.succeeded](state, { payload }) {
      return {
        ...state,
        pagedList: payload,
        fetchBlacklistInProgress: false
      };
    },

    [fetchBlacklistAction.failed](state) {
      return { ...state, fetchBlacklistInProgress: false };
    },

    [combineActions(addToBlacklistAction.triggered, deleteFromBlacklistAction.triggered)](state) {
      return { ...state, updateBlacklistInProgress: true, blacklistUpdated: false };
    },

    [combineActions(addToBlacklistAction.succeeded, deleteFromBlacklistAction.succeeded)](state, { payload }) {
      return { ...state, ...payload, updateBlacklistInProgress: false, blacklistUpdated: true };
    },

    [combineActions(addToBlacklistAction.failed, deleteFromBlacklistAction.failed)](state) {
      return { ...state, updateBlacklistInProgress: false, blacklistUpdated: false };
    }
  },

  initialState
);

export default blacklistReducer;
