import { takeEvery } from 'redux-saga/effects';
import { falconSubscribeActions } from '../actions/falconActions';
import { subscribeToChannel } from '../../../util/websocket';

/**
 * Subscribes to the falconChannel to receive various updates from the back-end
 */
function* falconSubscribe({ payload: { subAction, clientId } }) {
  yield* subscribeToChannel({
    action: 'falconChannel',
    subAction,
    key: clientId
  });
}

function* falconSubscribeSaga() {
  yield takeEvery(falconSubscribeActions.request, falconSubscribe);
}

export { falconSubscribeSaga };
