import { connect } from 'react-redux';
import { ScreenerParticipants } from './ScreenerParticipants';
import { screenerSummarySelector } from '../../../../store/redux/selectors/participantDataSelector';
import { sessionSelector } from '../../../../store/redux/selectors/sessionSelector';
import { surveySelector } from '../../../../store/redux/selectors/surveySelector';
import { fetchSurveyActions } from '../../../../store/redux/actions/surveyActions';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';

const mapStateToProps = (state, { sessionId, surveyId }) => {
  if (!surveyId) {
    const session = sessionSelector()(state, sessionId);
    return { ...state, surveyId: session.surveyId };
  }
  const { survey } = surveySelector()(state, surveyId);
  const { screenerSummary } = screenerSummarySelector()(state, sessionId);
  const { abilities } = sessionUserSelector(state);
  const { joiners = [], segmentCategories = [] } = survey || {};
  return {
    screenerSummary,
    segmentCategories,
    joiners,
    isInternalSuperUser: abilities.isInternalSuperUser
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSurvey: surveyId => dispatch(fetchSurveyActions.request({ surveyId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerParticipants);
