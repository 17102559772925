import { connect } from 'react-redux';
import { customReportConfigSelector } from '../../store/redux/selectors/customReportConfigSelector';
import { sessionSelector } from '../../store/redux/selectors/sessionSelector';
import {
  fetchCustomReportConfigAction,
  fetchCustomReportConsolidatedDataActions,
  saveCustomReportConfigActions,
  generateCustomReportActions
} from '../../store/redux/actions/customReportConfigActions';
import { fetchAllSessionsAction } from '../../store/redux/actions/sessionActions';
import ReportConfig from './ReportConfig';

const filterSessions = sessions => (sessions ? sessions.filter(session => session.surveyId) : []);

const mapStateToProps = (state, { reportConfigId }) => {
  const customReportConfig = customReportConfigSelector()(state, reportConfigId);
  const sessionsSelector = sessionSelector();
  return {
    reportConfigId,
    sessions: filterSessions(sessionsSelector(state).allSessions.content),
    allSessionsRequested: sessionsSelector(state).requestInProgress,
    customReportConfig
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCustomReportConfig: params => dispatch(fetchCustomReportConfigAction.request(params)),
  fetchCustomReportConsolidatedData: params => dispatch(fetchCustomReportConsolidatedDataActions.request(params)),
  fetchSessions: params => dispatch(fetchAllSessionsAction.request(params)),
  saveCustomReportConfig: config => dispatch(saveCustomReportConfigActions.request(config)),
  generateCustomReport: report => dispatch(generateCustomReportActions.request(report))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportConfig);
