import React from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { Input } from 'reactstrap';
import AddRemoveIcons from '../../../components/core/addRemoveIcons/AddRemoveIcons';
import { panelConfigUtil } from './panelConfigUtil';

import './EditPanelConfig.css';

export const EditPanelConfig = props => {
  const { config, showQuota, readOnly, setConfig } = props;

  function setField(key, value) {
    setConfig({ ...config, [key]: value });
  }

  function setFieldStripSpaces(key, value) {
    setField(key, value.replace(/\s+/g, ''));
  }

  function setQuota(quota) {
    setField('maxQuota', quota === '' ? null : quota);
  }

  function setLinkParam(paramListName, index, field, value) {
    const clone = cloneDeep(config);
    clone[paramListName][index][field] = value.replace(/\s+/g, '');
    setConfig(clone);
  }

  function addLinkParam(paramListName) {
    const clone = cloneDeep(config);
    clone[paramListName].push({ type: panelConfigUtil.panelParamType.PARTICIPANT_DATA_POINT, first: '', second: '' });
    setConfig(clone);
  }

  function removeLinkParam(paramListName, index) {
    const clone = cloneDeep(config);
    clone[paramListName].splice(index, 1);
    setConfig(clone);
  }

  function getLinkParamUI(paramListName, param, index, disabled) {
    return (
      <div className="link-param-ui" key={'linkParam_' + index}>
        <div>{intl.get('app.name')}:</div>
        <Input
          value={param.second}
          disabled={disabled}
          onChange={e => setLinkParam(paramListName, index, 'second', e.target.value)}
        />
        <Input
          type="select"
          value={param.type}
          disabled={disabled}
          onChange={e => setLinkParam(paramListName, index, 'type', e.target.value)}
        >
          <option value={panelConfigUtil.panelParamType.PARTICIPANT_DATA_POINT}>
            {intl.get('app.participantDataPoint')}
          </option>
          <option value={panelConfigUtil.panelParamType.STRING}>{intl.get('app.stringValue')}</option>
        </Input>
        <Input
          value={param.first}
          disabled={disabled}
          onChange={e => setLinkParam(paramListName, index, 'first', e.target.value)}
        />
        {!disabled && (
          <AddRemoveIcons
            onAdd={() => addLinkParam(paramListName)}
            onRemove={() => removeLinkParam(paramListName, index)}
          />
        )}
      </div>
    );
  }

  function getLinkParamRows(paramListName, disabled) {
    const params = config[paramListName];
    return params.map((param, index) => getLinkParamUI(paramListName, param, index, disabled));
  }

  return (
    <section className="edit-panel-config">
      <div>
        <div style={{ width: '9rem' }}>{intl.get('app.panelName')}</div>
        <div>
          <Input value={config.name} disabled={readOnly} onChange={e => setField('name', e.target.value)} />
        </div>
        {showQuota && (
          <>
            <div style={{ margin: '0 .5rem 0 3rem' }}>{intl.get('app.quota')}:</div>
            <Input
              type="number"
              style={{ width: '5rem' }}
              min="0"
              value={config.maxQuota}
              disabled={readOnly}
              onChange={e => setQuota(e.target.value)}
            />
          </>
        )}
      </div>

      <div>
        <div style={{ width: '9rem' }}>{intl.get('app.panelIdentifier')}</div>
        <div>
          <Input
            value={config.panelId}
            disabled={readOnly}
            onChange={e => setFieldStripSpaces('panelId', e.target.value)}
          />
        </div>
      </div>

      <div>
        <div style={{ width: '9rem' }}>{intl.get('app.userValidation')}</div>
        <div>
          <Input
            type="select"
            value={config.hashCodeType}
            disabled={readOnly}
            onChange={e => setField('hashCodeType', e.target.value)}
          >
            <option value="NONE">{intl.get('app.none')}</option>
            <option value="SHA1">{intl.get('app.sha1')}</option>
            <option value="MD5">{intl.get('app.md5')}</option>
          </Input>
        </div>
      </div>

      <div className="mt-4">
        <div>{intl.get('app.globalLinkParams')}:</div>
        {!readOnly && config.globalParameters.length === 0 && (
          <div className="add-link-param-link" onClick={() => addLinkParam('globalParameters')}>
            + {intl.get('app.linkParam')}
          </div>
        )}
      </div>

      <div className="mb-4 ms-4" style={{ display: 'block' }}>
        {getLinkParamRows('globalParameters', readOnly)}
      </div>

      <div className="url-input-row ms-4">
        <label>
          <Input
            type="checkbox"
            checked={config.completedUrlEnabled}
            disabled={readOnly}
            onChange={e => setField('completedUrlEnabled', e.target.checked)}
          />
          {intl.get('app.completedUrl')}
        </label>
        <div>
          <Input
            value={config.completedUrl}
            disabled={readOnly || !config.completedUrlEnabled}
            onChange={e => setFieldStripSpaces('completedUrl', e.target.value)}
          />
          <div className="url-example">{panelConfigUtil.buildUrl(config, 'completed')}</div>
          {getLinkParamRows('completedParameters', readOnly || !config.completedUrlEnabled)}
        </div>
        <div
          className="add-link-param-link"
          style={{
            visibility: (readOnly || !config.completedUrlEnabled || config.completedParameters.length !== 0) && 'hidden'
          }}
          onClick={() => addLinkParam('completedParameters')}
        >
          + {intl.get('app.linkParam')}
        </div>
      </div>

      <div className="url-input-row ms-4">
        <label>
          <Input
            type="checkbox"
            checked={config.terminateUrlEnabled}
            disabled={readOnly}
            onChange={e => setField('terminateUrlEnabled', e.target.checked)}
          />
          {intl.get('app.terminateUrl')}
        </label>
        <div>
          <Input
            value={config.terminateUrl}
            disabled={readOnly || !config.terminateUrlEnabled}
            onChange={e => setFieldStripSpaces('terminateUrl', e.target.value)}
          />
          <div className="url-example">{panelConfigUtil.buildUrl(config, 'terminate')}</div>
          {getLinkParamRows('terminateParameters', readOnly || !config.terminateUrlEnabled)}
        </div>
        <div
          className="add-link-param-link"
          style={{
            visibility: (readOnly || !config.terminateUrlEnabled || config.terminateParameters.length !== 0) && 'hidden'
          }}
          onClick={() => addLinkParam('terminateParameters')}
        >
          + {intl.get('app.linkParam')}
        </div>
      </div>

      <div className="url-input-row ms-4">
        <label>
          <Input
            type="checkbox"
            checked={config.overQuotaUrlEnabled}
            disabled={readOnly}
            onChange={e => setField('overQuotaUrlEnabled', e.target.checked)}
          />
          {intl.get('app.overQuotaUrl')}
        </label>
        <div>
          <Input
            value={config.overQuotaUrl}
            disabled={readOnly || !config.overQuotaUrlEnabled}
            onChange={e => setFieldStripSpaces('overQuotaUrl', e.target.value)}
          />
          <div className="url-example">{panelConfigUtil.buildUrl(config, 'overQuota')}</div>
          {getLinkParamRows('overQuotaParameters', readOnly || !config.overQuotaUrlEnabled)}
        </div>
        <div
          className="add-link-param-link"
          style={{
            visibility: (readOnly || !config.overQuotaUrlEnabled || config.overQuotaParameters.length !== 0) && 'hidden'
          }}
          onClick={() => addLinkParam('overQuotaParameters')}
        >
          + {intl.get('app.linkParam')}
        </div>
      </div>
    </section>
  );
};
