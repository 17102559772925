import createFetchActions from './createFetchActions';

const createFunctionActions = createFetchActions('CREATE_FUNCTION');
const fetchFunctionListActions = createFetchActions('FUNCTION_LIST');
const fetchFunctionActions = createFetchActions('FUNCTION');
const deleteFunctionActions = createFetchActions('DELETE_FUNCTION');
const fetchAllFunctionsActions = createFetchActions('ALL_FUNCTIONS');

export {
  createFunctionActions,
  fetchFunctionListActions,
  fetchFunctionActions,
  deleteFunctionActions,
  fetchAllFunctionsActions
};
