import React, { useReducer } from 'react';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { accessHandler, Loader, NavTab } from 'webapp-common';
import appConfig from '../../../../appConfig';
import { saveProjectPanelConfig } from '../../../../api/projectPanelConfig';
import { useProjectPanelConfigQuery } from '../../../../customHooks/useProjectPanelConfigQuery';
import { AUTO_ENROLL_TAB, RECRUIT_TAB, PARTICIPANTS_TAB } from '../../../../util/projectUtil';
import { enrollmentTypes } from '../../../../util/participantDataUtil';
import { PanelConfig } from '../../survey/panelConfig/PanelConfig';
import { AutoEnrollParticipants } from './AutoEnrollParticipants';

const reducer = (state, payload) => ({ ...state, ...payload });

export const ProjectAutoEnroll = props => {
  const { project, hasProjectView, hasProjectManage } = props;

  const [state, setState] = useReducer(reducer, {
    panelConfig: {},
    showLoader: false
  });

  const routeParams = useParams();
  const { subtab: activeTab, subtab2: configId } = routeParams;

  const { projectDetails = {} } = project || {};

  useProjectPanelConfigQuery({
    id: configId,
    onSuccess: panelConfig => {
      setState({ panelConfig, showLoader: false });
      props.updatePanelConfigName(panelConfig.name);
    }
  });

  if (!projectDetails) {
    return null;
  }

  const accessCheck = accessHandler.checkAccess(hasProjectView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  function saveConfig(config) {
    setState({ showLoader: true });
    saveProjectPanelConfig({ ...config, projectId: projectDetails.id, type: enrollmentTypes.AUTO_ENROLL }).then(
      config => {
        setState({
          panelConfig: config,
          showLoader: false
        });
      }
    );
  }

  return (
    <section className="app-body">
      {state.showLoader && <Loader spinner fullScreen />}
      <section className="left-sub-nav">
        <NavTab
          icon="fa-arrow-left"
          iconStyle="light"
          label={intl.get('app.backToRecruit')}
          to={`${appConfig.projectPagePath}/${routeParams.projectId}/${RECRUIT_TAB}`}
        />
        <NavTab
          icon="fa-user-cog"
          iconStyle="light"
          label={intl.get('app.panelConfigurations')}
          active={activeTab !== PARTICIPANTS_TAB}
          to={`${appConfig.projectPagePath}/${routeParams.projectId}/${AUTO_ENROLL_TAB}`}
        />
        <NavTab
          icon="fa-users"
          iconStyle="light"
          label={intl.get('app.participants')}
          active={activeTab === PARTICIPANTS_TAB}
          to={`${appConfig.projectPagePath}/${routeParams.projectId}/${AUTO_ENROLL_TAB}/${PARTICIPANTS_TAB}`}
        ></NavTab>
      </section>
      <section className="body-container l3-bg">
        {activeTab !== PARTICIPANTS_TAB && (
          <PanelConfig
            hasProjectManage={hasProjectManage}
            isAutoEnroll
            projectPanelConfig={state.panelConfig}
            updatePanelConfigName={props.updatePanelConfigName}
            saveProjectPanelConfig={saveConfig}
          />
        )}
        {activeTab === PARTICIPANTS_TAB && (
          <AutoEnrollParticipants project={project} collectionId={state.panelConfig.collectionId} />
        )}
      </section>
    </section>
  );
};
