import React, { useEffect, memo, useReducer, useRef } from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Label } from 'reactstrap';
import { cloneDeep, get, isEqual, last } from 'lodash';
import { InvokeModal, Loader } from 'webapp-common';
import { auth } from '../../../api/Auth';
import Video from '../sessionDetailsCommon/Video';
import { EmotionGraph } from './EmotionGraph';
import { EmotionFilters } from './EmotionFilters';
import { EmotionDataExport } from './EmotionDataExport';
import TimeSelector from '../../../components/core/timePicker/TimeSelector';
import {
  getEmotions,
  getJoinerSelectList,
  getFilterList,
  workerStates,
  getColorMap,
  getReportTableSortingConfig,
  TOTAL,
  TOTAL_FILTER,
  OVERLAY_EMOTION,
  AUTO_SCALE_Y,
  ZOOM_TIME,
  LABEL_STYLE,
  SESSION_CHART_WIDTH,
  VIDEO_SETUP,
  EMOTION_CHART_COLORS,
  MIN_ZOOM_TIME
} from '../sessionDetailsCommon/sessionDetailDataUtil';
import WebWorker from '../../../util/WebWorker';
import { buildEmotionDataWorker } from './buildEmotionDataWorker';

import '../sessionDetailsCommon/sessionDetailDataStyle.css';

const reducer = (state, payload) => ({
  ...state,
  ...payload
});

const skipUpdate = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.filters, nextProps.filters) &&
    isEqual(prevProps.filteredParticipants, nextProps.filteredParticipants) &&
    isEqual(prevProps.emotionDataStore, nextProps.emotionDataStore) &&
    isEqual(prevProps.colorMap, nextProps.colorMap) &&
    isEqual(prevProps.emotionSetting, nextProps.emotionSetting)
  );
};

const getJoinerList = joiners => {
  return joiners.map(joiner => {
    return <option value={joiner.id} key={joiner.id}>{`${joiner.questionNumber}. ${joiner.questionTitle}`}</option>;
  });
};

export const SessionEmotionData = memo(props => {
  const {
    survey = {},
    sessionId,
    surveyId,
    sessionUser,
    sessionName,
    filters,
    filteredParticipants = [],
    emotionDataStore,
    fetchSurveyInProgress,
    researchDashboardConfigRequested,
    exportEmotionDataInProgress,
    totalParticipants
  } = props;
  const showLoader = fetchSurveyInProgress || researchDashboardConfigRequested || exportEmotionDataInProgress;
  const userId = sessionUser && sessionUser.userID;
  const { reportFiles = {} } = emotionDataStore[sessionId] || {};
  const playerId = `player+${sessionId}`;

  const [state, setState] = useReducer(reducer, {
    selectedJoinerId: undefined,
    showSettings: false,
    emotionSetting: {},
    currentVideoTime: 0,
    pause: undefined,
    emotionJoiners: undefined,
    emotions: getEmotions(),
    selectedEmotions: [],
    filters: [TOTAL_FILTER],
    selectedFilters: [TOTAL_FILTER],
    colorMap: getColorMap([TOTAL_FILTER], false),
    config: {
      reportTableSortingConfig: {
        pageNumber: 1,
        pageSize: 5,
        sortBy: 'createDate',
        sortOrder: 'desc',
        type: 'tableSortingConfig'
      }
    }
  });

  const worker = useRef();
  const workerState = useRef(workerStates.IDLE);
  const pendingEmotionData = useRef(false);

  const filtersAndParticipantsChannelSubscribe = subAction => {
    props.filtersAndParticipantsChannelSubscribe({
      subAction,
      sessionId
    });
  };

  const emotionDataChannelSubscribe = subAction => {
    props.emotionDataChannelSubscribe({
      subAction,
      sessionId,
      joinerId: state.selectedJoinerId
    });
  };

  const updateEmotionDataInState = data => {
    const { perSecondEmotions, emotionAvgChartData, emotionAvgData, lastBuilt } = data;
    setState({
      perSecondEmotions,
      emotionAvgChartData,
      emotionAvgData,
      dataLastBuilt: lastBuilt
    });
  };

  const emotionReportFilesChannelSubscribe = subAction => {
    if (userId) {
      props.emotionReportFilesChannelSubscribe({
        subAction,
        rdConfig: {
          sessionId,
          userId,
          configs: state.config
        }
      });
    }
  };

  useEffect(() => {
    worker.current = new WebWorker(buildEmotionDataWorker);
    worker.current.onmessage = e => {
      workerState.current = workerStates.IDLE;
      if (e.data) {
        updateEmotionDataInState(e.data);
      }
    };
    filtersAndParticipantsChannelSubscribe('subscribe');
    return () => {
      worker.current.terminate();
      filtersAndParticipantsChannelSubscribe('unsubscribe');
      emotionDataChannelSubscribe('unsubscribe');
      emotionReportFilesChannelSubscribe('unsubscribe');
      if (state.selectedJoinerId && props.deleteVideoWatermark) {
        props.deleteVideoWatermark({ joinerId: state.selectedJoinerId, participantId: userId });
      }
    };
  }, []);

  useEffect(() => {
    const emotionData = get(emotionDataStore, `${sessionId}-${state.selectedJoinerId}`);
    if (state.selectedJoinerId && emotionData) {
      setState({
        emotionData
      });
    }
  }, [emotionDataStore]);

  useEffect(() => {
    emotionReportFilesChannelSubscribe('subscribe');
  }, [userId, state.config]);

  useEffect(() => {
    if (!survey) {
      props.fetchSurvey(surveyId);
    }
  }, [surveyId]);

  useEffect(() => {
    if (survey.joiners && survey.joiners.length > 0) {
      const joiners = getJoinerSelectList(survey.joiners, 'detectEmotions');
      if (joiners.length > 0) {
        setState({
          emotionJoiners: joiners,
          selectedJoinerId: joiners[0].id,
          selectedJoiner: joiners[0]
        });
      }
    }
  }, [survey]);

  useEffect(() => {
    if (state.selectedJoinerId) {
      emotionDataChannelSubscribe('subscribe');
      props.fetchEmotionSetting({
        userId,
        sessionId,
        joinerId: state.selectedJoinerId
      });
      props.fetchVideoWatermark({
        joinerId: state.selectedJoinerId,
        participantId: userId
      });
    }
  }, [state.selectedJoinerId]);

  useEffect(() => {
    const overlay = props.emotionSetting && props.emotionSetting[OVERLAY_EMOTION];
    const colorMap = getColorMap(state.selectedFilters, overlay);
    setState({
      emotionSetting: props.emotionSetting,
      overlay,
      colorMap
    });
  }, [props.emotionSetting]);

  useEffect(() => {
    if (filters && state.emotionData) {
      const videoLength = last(state.emotionData).videoPosition;
      setState({
        videoLength,
        filters: getFilterList(filters)
      });
      pendingEmotionData.current = true;
    }
  }, [filters, state.emotionData]);

  useEffect(() => {
    if (workerState.current === workerStates.IDLE && pendingEmotionData.current) {
      pendingEmotionData.current = false;
      workerState.current = workerStates.RUNNING;
      worker.current.postMessage({
        emotionData: cloneDeep(state.emotionData),
        filters: state.filters,
        emotions: state.emotions,
        filteredParticipants
      });
    }
  }, [workerState.current, pendingEmotionData.current]);

  const fetchReportList = params => {
    const config = getReportTableSortingConfig(params);
    emotionReportFilesChannelSubscribe('unsubscribe');
    setState({
      config: config
    });
  };

  const onSelectJoinerId = e => {
    emotionDataChannelSubscribe('unsubscribe');
    setState({
      selectedJoinerId: e.target.value,
      selectedJoiner: state.emotionJoiners.find(j => j.id === e.target.value),
      pause: undefined,
      currentVideoTime: 0
    });
  };

  /*
   * This page has minimal user interaction with the server, so need to keep the user's session alive if they are active in the UI.
   */
  function keepSessionAlive() {
    auth.isAuthenticated(true);
  }

  const setCurrentVideoTime = (time, pause) => {
    keepSessionAlive();
    if (time >= 0) {
      setState({
        currentVideoTime: Math.round(time),
        pause
      });
    }
  };

  const toggleEmotionSettings = () => {
    state.showSettings
      ? setState({
          showSettings: !state.showSettings,
          emotionSetting: props.emotionSetting
        })
      : setState({
          showSettings: !state.showSettings
        });
  };

  const onEmotionSettingsChange = setting => {
    setState({
      emotionSetting: {
        ...state.emotionSetting,
        [setting]: !state.emotionSetting[setting]
      }
    });
  };

  const saveEmotionSettings = () => {
    props.saveEmotionSetting({
      ...state.emotionSetting,
      sessionId,
      userId,
      type: 'EmotionsSettingConfig',
      questionJoinerId: state.selectedJoinerId
    });
    toggleEmotionSettings();
  };

  const setEmotion = emotion => {
    const selectedEmotions = cloneDeep(state.selectedEmotions);
    if (selectedEmotions.some(em => em === emotion)) {
      selectedEmotions.splice(selectedEmotions.indexOf(emotion), 1);
      setState({ selectedEmotions });
    } else {
      selectedEmotions.push(emotion);
      const emotionKeys = Object.keys(EMOTION_CHART_COLORS);
      setState({ selectedEmotions: emotionKeys.filter(key => selectedEmotions.includes(key)) });
    }
  };

  const setFilter = (filter, e) => {
    if (
      state.selectedFilters.length >= 5 &&
      !state.selectedFilters.some(f => f.name === filter.name) &&
      e.target.checked
    ) {
      window.alert(intl.get('app.emotions.maxFiltersReached'));
    } else {
      let selectedFilters = cloneDeep(state.selectedFilters);

      const idx = selectedFilters.findIndex(f => f.name === filter.name);
      if (idx < 0) {
        filter.checked = e.target.checked;
        selectedFilters =
          filter.name === TOTAL ? [filter, ...state.selectedFilters] : [...state.selectedFilters, filter];
      } else {
        selectedFilters.splice(idx, 1);
      }
      const colorMap = getColorMap(selectedFilters, state.overlay);
      setState({ selectedFilters, colorMap });
    }
  };

  const getConfiguredTime = () => {
    if (!state.emotionSetting && !state.emotionSetting.timeWindow) {
      return 0;
    }
    return state.emotionSetting.timeWindow;
  };

  const updateTimeWindow = time => {
    if (!time) {
      return 0;
    }
    let inSeconds = time.hours() * 3600 + time.minutes() * 60 + time.seconds();
    if (inSeconds < MIN_ZOOM_TIME) {
      inSeconds = MIN_ZOOM_TIME;
    } else if (inSeconds > state.videoLength) {
      inSeconds = state.videoLength;
    }
    setState({
      emotionSetting: {
        ...state.emotionSetting,
        timeWindow: inSeconds
      }
    });
  };

  const left = state.emotionSetting[ZOOM_TIME] ? state.currentVideoTime - state.emotionSetting.timeWindow / 2 : 0;
  const right = state.emotionSetting[ZOOM_TIME]
    ? state.currentVideoTime + state.emotionSetting.timeWindow / 2
    : state.videoLength;

  return (
    <div className="session-emotion-data">
      {showLoader && <Loader spinner fullScreen />}
      {state.emotionJoiners && (
        <div className="settings-area">
          <Input type="select" className="question-select" value={state.selectedJoinerId} onChange={onSelectJoinerId}>
            {getJoinerList(state.emotionJoiners)}
          </Input>
          <Button className="link-button" onClick={toggleEmotionSettings}>
            <i className="fas fa-cog ms-5 me-3" />
            <Label>{intl.get('app.settings')}</Label>
          </Button>
        </div>
      )}
      {state.selectedJoiner && state.colorMap && state.emotionAvgChartData && (
        <div className={state.overlay ? 'overlay-graph-and-video' : 'emotion-graph-video'}>
          <EmotionGraph
            chartData={state.emotionAvgChartData}
            emotions={state.selectedEmotions}
            selectedFilters={state.selectedFilters}
            currentVideoTime={state.currentVideoTime}
            setCurrentVideoTime={setCurrentVideoTime}
            chartWidth={state.overlay ? VIDEO_SETUP.width : SESSION_CHART_WIDTH}
            height={state.overlay ? VIDEO_SETUP.height - 55 : 332}
            autoScaleY={state.emotionSetting[AUTO_SCALE_Y]}
            overlay={state.overlay}
            colorMap={state.colorMap}
            left={left}
            right={right}
          />
          <Video
            joiner={state.selectedJoiner}
            currentVideoTime={state.currentVideoTime}
            pause={state.pause}
            setCurrentVideoTime={setCurrentVideoTime}
            watermark={props.videoWatermark}
            playerId={playerId}
          />
        </div>
      )}
      {state.perSecondEmotions && (
        <EmotionFilters
          selectedEmotions={state.selectedEmotions}
          setEmotion={setEmotion}
          emotionAvgData={state.emotionAvgData}
          selectedFilters={state.selectedFilters}
          filterList={state.filters}
          setFilter={setFilter}
          filteredParticipants={filteredParticipants}
          currentVideoTime={state.currentVideoTime}
          overlay={state.overlay}
          colorMap={state.colorMap}
          totalParticipants={totalParticipants}
        />
      )}
      {state.selectedJoiner && (
        <EmotionDataExport
          sessionId={sessionId}
          sessionName={sessionName}
          joiner={state.selectedJoiner}
          reportFiles={reportFiles}
          filteredParticipants={filteredParticipants}
          selectedEmotions={state.selectedEmotions}
          exportData={props.exportEmotionData}
          fetchReportList={fetchReportList}
          exportDataInProgress={exportEmotionDataInProgress}
          selectedFilters={state.selectedFilters}
          dataOutputType={state.dataOutputType}
        />
      )}
      <InvokeModal
        showModal={state.showSettings}
        toggle={toggleEmotionSettings}
        className="emotion-settings-modal"
        modalTitle={intl.get('app.emotion.settings')}
        primaryButtonText={intl.get('app.save')}
        save={saveEmotionSettings}
        cancelButtonText={intl.get('app.cancel')}
        enableSave={!isEqual(state.emotionSetting, props.emotionSetting)}
      >
        <div className="mt-3">
          <Label onClick={() => onEmotionSettingsChange(OVERLAY_EMOTION)} style={LABEL_STYLE}>
            <Input type="checkbox" checked={state.emotionSetting[OVERLAY_EMOTION]} />
            <Label>{intl.get(`app.${OVERLAY_EMOTION}`)}</Label>
          </Label>
        </div>
        <div className="mt-3">
          <Label onClick={() => onEmotionSettingsChange(AUTO_SCALE_Y)} style={LABEL_STYLE}>
            <Input type="checkbox" checked={state.emotionSetting[AUTO_SCALE_Y]} />
            <Label>{intl.get(`app.${AUTO_SCALE_Y}`)}</Label>
          </Label>
        </div>
        <div className="mt-3">
          <Label onClick={() => onEmotionSettingsChange(ZOOM_TIME)} style={LABEL_STYLE}>
            <Input type="checkbox" checked={state.emotionSetting[ZOOM_TIME]} style={{ verticalAlign: 'text-top' }} />
            <Label className="me-4">{intl.get(`app.${ZOOM_TIME}`)}:</Label>
          </Label>
          <TimeSelector
            pickedTime={getConfiguredTime()}
            updateTime={updateTimeWindow}
            timeFormat="HH:mm:ss"
            readOnly={!state.emotionSetting[ZOOM_TIME]}
          />
        </div>
      </InvokeModal>
    </div>
  );
}, skipUpdate);
