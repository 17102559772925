import React, { memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import intl from 'react-intl-universal';
import { XAxis, YAxis, CartesianGrid, ReferenceLine, ScatterChart, Scatter, Tooltip, Cell } from 'recharts';
import { isEqual } from 'lodash';
import { getRDEnrollees } from '../../../store/redux/selectors/researchDashboardSelector';
import { getFormatedTime } from '../../../util/joinerUtil';
import { getColorFromSetting, formatScatterActionData } from './DialDataUtil';
import { RANGE_MAX } from '../sessionDetailsCommon/sessionDetailDataUtil';

function CustomTooltip(props) {
  const { active, payload, joiner, adHocJoinerIdMap, adHocAnswers } = props;
  if (active) {
    const { actionButtonName, actualTime, participantId, nickname } = payload[0].payload;
    const adHocJoinerId =
      adHocJoinerIdMap[actionButtonName] || adHocJoinerIdMap[`${actionButtonName}-${joiner.conceptId}`];
    const answer = adHocAnswers.find(a => a.participantId === participantId && a.questionJoinerId === adHocJoinerId);
    const answerText = answer?.answer?.value;
    return (
      <div className="custom-tooltip">
        <p className="label">{`${intl.get('app.time')}: ${getFormatedTime(actualTime)}`}</p>
        <p className="label">{`${intl.get('app.participant')}: ${nickname || participantId}`}</p>
        {answerText && <p className="label">{`${intl.get('app.answer')}: ${answerText}`}</p>}
      </div>
    );
  }
  return null;
}

const CustomScatterShape = () => null;
const RATING = 'app.rating';

const skipUpdate = (prevProps, nextProps) => {
  return (
    prevProps.dataLastBuilt === nextProps.dataLastBuilt &&
    prevProps.currentVideoTime === nextProps.currentVideoTime &&
    prevProps.selectedFilters.length === nextProps.selectedFilters.length &&
    prevProps.selectedAction === nextProps.selectedAction &&
    prevProps.autoScaleY === nextProps.autoScaleY &&
    prevProps.left === nextProps.left &&
    prevProps.right === nextProps.right &&
    prevProps.height === nextProps.height &&
    isEqual(prevProps.dialColorSettings, nextProps.dialColorSettings) &&
    isEqual(prevProps.adHocJoinerIdMap, nextProps.adHocJoinerIdMap) &&
    isEqual(prevProps.adHocAnswers, nextProps.adHocAnswers)
  );
};

const DialDataGraph = memo(props => {
  const {
    sessionId,
    rawScatterActionData,
    scatterRatingData,
    currentVideoTime,
    joiner,
    adHocJoinerIdMap,
    adHocAnswers,
    selectedFilters,
    selectedAction,
    autoScaleY,
    chartWidth,
    height,
    overlay,
    left,
    right
  } = props;

  const { dialConfig } = joiner.stim.options;
  const { scaleLeft = 0, scaleRight = RANGE_MAX } = autoScaleY
    ? { scaleLeft: 'dataMin', scaleRight: 'dataMax' }
    : dialConfig;
  const totalRange = autoScaleY ? RANGE_MAX : scaleRight - scaleLeft;

  const enrolleesInfo = useSelector(state => getRDEnrollees(state, sessionId), shallowEqual);
  const { enrolleeMap } = enrolleesInfo;

  const onChartClick = e => {
    if (e) {
      props.setCurrentVideoTime(e.xValue, true);
    }
  };

  const getRatingLines = () => {
    return selectedFilters.map(filter => {
      const { avg } = scatterRatingData[filter.name] || {};
      return (
        <Scatter
          data={avg}
          fill={getColorFromSetting(filter, overlay, props.dialColorSettings)}
          shape={<CustomScatterShape />}
          line={{ strokeWidth: 2 }}
          key={filter.name}
        />
      );
    });
  };

  const getActionScatters = () => {
    const data = formatScatterActionData({
      rawScatterActionData,
      selectedDataOutput: selectedAction,
      selectedFilters,
      totalRange,
      overlay,
      colorSetting: props.dialColorSettings,
      enrolleeMap
    });
    return (
      <Scatter yAxisId="right" data={data} isAnimationActive={false}>
        {data.map((entry, index) => {
          return <Cell key={`cell-${index}`} fill={entry.color} />;
        })}
      </Scatter>
    );
  };

  const getScatterChart = () => {
    const max = right ? right : 'dataMax';
    return (
      <ScatterChart
        width={chartWidth}
        height={height}
        margin={{ top: 10, right: 20, bottom: 0, left: -20 }}
        onClick={onChartClick}
      >
        <CartesianGrid stroke="#666" />
        <XAxis
          dataKey="time"
          allowDataOverflow={true}
          type="number"
          name="time"
          tickFormatter={getFormatedTime}
          domain={[left, max]}
        />
        <YAxis dataKey="y" type="number" name="y" domain={[scaleLeft, scaleRight]} />
        <YAxis
          yAxisId="right"
          dataKey="y"
          type="number"
          name="y"
          orientation="right"
          domain={[0, RANGE_MAX]}
          hide={true}
        />
        <Tooltip
          content={props => (
            <CustomTooltip {...props} joiner={joiner} adHocJoinerIdMap={adHocJoinerIdMap} adHocAnswers={adHocAnswers} />
          )}
          cursor={false}
          isAnimationActive={false}
        />
        {getRatingLines()}
        {getActionScatters()}
        {currentVideoTime >= 0 && <ReferenceLine x={currentVideoTime} stroke="#ff0000" />}
      </ScatterChart>
    );
  };

  return (
    <div className="dial-data-graph">
      <div className="graph-controls">
        <label>
          {intl.get('app.data')}: {selectedAction ? selectedAction : intl.get(RATING)}
        </label>
      </div>
      {getScatterChart()}
    </div>
  );
}, skipUpdate);

export default DialDataGraph;
