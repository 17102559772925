import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual } from 'lodash';
import { Button } from 'reactstrap';
import { usePrompt } from '../../../../customHooks/usePrompt';
import { AllowedDevices } from './devices/AllowedDevices';
import { ImageWatermark } from './watermark/ImageWatermark';
import { WatermarkSettings } from './watermark/WatermarkSettings';

const reducer = (state, payload) => ({ ...state, ...payload });

export const SurveySettingsSS = props => {
  const { readOnly } = props;

  const [state, setState] = useReducer(reducer, {
    session: props.session,
    survey: props.survey
  });

  usePrompt(intl.get('app.unsavedChangesWarning'), isSaveEnabled());

  function setSessionField(field, value) {
    setState({ session: { ...state.session, [field]: value } });
  }

  function setWatermarkProperty(property, value) {
    const session = cloneDeep(state.session);
    session[property] = value;
    setState({ session });
  }

  function sessionHasChanged() {
    return state.session.id === props.session.id && !isEqual(props.session?.agentSupport, state.session?.agentSupport);
  }

  function surveyHasChanged() {
    return (
      state.survey.id === props.survey.id &&
      !isEqual(props.survey?.surveyOptions?.watermarkOption, state.survey?.surveyOptions?.watermarkOption)
    );
  }

  function save() {
    const doSaveSession = sessionHasChanged();

    if (doSaveSession) {
      props.saveSession({ payload: state.session });
    }

    if (surveyHasChanged()) {
      props.saveSurvey({
        survey: state.survey,
        sessionId: props.session.id,
        projectId: props.session.projectId,
        suppressToast: doSaveSession
      });
    }
  }

  function isSaveEnabled() {
    return !readOnly && (sessionHasChanged() || surveyHasChanged());
  }

  return (
    <section className="survey-settings">
      <div>
        <div>{intl.get('app.allowedDevices')}</div>
        <AllowedDevices
          agentSupport={state.session.agentSupport}
          origAgentSupport={props.session.agentSupport}
          readOnly={readOnly}
          setSessionField={setSessionField}
        />
      </div>
      <div>
        <div>{intl.get('app.images.watermark')}</div>
        <ImageWatermark
          enableImageWatermark={state.session.enableImageWatermark}
          readOnly={readOnly}
          setWatermarkProperty={setWatermarkProperty}
        />
      </div>
      <div>
        <div>{intl.get('app.videos.watermark')}</div>
        <WatermarkSettings
          type={'video'}
          enabled={state.session.enableVideoWatermark}
          securityLevel={state.session.videoSecurityLevel}
          readOnly={readOnly}
          setWatermarkProperty={setWatermarkProperty}
        />
      </div>

      {!readOnly && (
        <Button color="primary" onClick={save} disabled={!isSaveEnabled()}>
          {intl.get('app.save')}
        </Button>
      )}
    </section>
  );
};
