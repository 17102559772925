import React from 'react';
import { RDStatusBar } from '../../../researchDashboard/rdStatusBar/RDStatusBar';
import { RDSessionMgmt } from '../../../researchDashboard/rdSessionMgmt/RDSessionMgmt';
import { isLiveMode, isOpen, isTest } from '../../../../util/sessionUtil';

export const SurveyResponses = props => {
  const { sessionId, session } = props;
  return (
    <>
      {isLiveMode(session) && (isOpen(session) || isTest(session)) && <RDStatusBar sessionId={sessionId} />}
      <RDSessionMgmt
        sessionId={sessionId}
        viewLanguage={props.viewLanguage}
        setViewLanguage={props.setViewLanguage}
        language={props.language}
      />
    </>
  );
};
