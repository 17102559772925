import React from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { getTableColumns } from './RDQuestionListUtil';
import { InvokeTable } from 'webapp-common';

import './RDQuestionList.css';

export const RDQuestionList = props => {
  const { questions, viewMode, onSelectQuestion, qlExpandInProp } = props;

  const collapsed = viewMode === 'collapsed';

  const classes = {
    'question-list': true,
    'collapsed-view': collapsed
  };

  return (
    <CSSTransition in={qlExpandInProp} timeout={500} classNames="ql-expand">
      <div className={classnames(classes)} style={{ overflowY: 'auto' }}>
        <InvokeTable
          className="invoke-table"
          columns={getTableColumns(collapsed)}
          data={questions}
          enablePagination={false}
          enableSort={false}
          onRowSelect={onSelectQuestion}
        />
      </div>
    </CSSTransition>
  );
};
