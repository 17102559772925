import { handleActions } from 'redux-actions';
import { updateParticipantSummaryFiltersActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdParticipantSummaryFiltersReducer = handleActions(
  {
    [updateParticipantSummaryFiltersActions.succeeded](state, { payload }) {
      const { sessionId, summaryFilters } = payload;
      return {
        ...state,
        [sessionId]: {
          summaryFilters
        }
      };
    }
  },
  initialState
);
