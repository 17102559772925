import React from 'react';

import './VFGIcon.css';

export const VFGIcon = props => {
  const { iconStyle, size } = props;
  const color =
    (iconStyle === 'light' && '#aaa') || (iconStyle === 'solid' && '#666') || (iconStyle === 'active' && '#0569a9');
  const fontSize = 75 * (size || 1);
  const cellSize = size || 1;
  return (
    <div
      className={`vfg-icon ${props.className || ''}`}
      style={{
        color,
        fontSize: `${fontSize}%`,
        gridTemplateColumns: `repeat(2, ${cellSize}rem)`,
        gridTemplateRows: `repeat(2, ${cellSize}rem)`
      }}
    >
      <i className="fas fa-user-tie" />
      <i className="fas fa-user" />
      <i className="fas fa-user" />
      <i className="fas fa-user" />
    </div>
  );
};
