import { takeLatest, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import { fetchUserActions, updateUserNameAction } from '../actions/userActions';
import { fetchSessionUser, updateUsername } from '../../../api/userApi';
import createFetchSaga from './createFetchSaga';
import { auth } from '../../../api/Auth';

function onFetchSessionUser({ payload }) {
  const { user } = payload || {};
  const client = (user && user.clients && user.clients.find(c => c.clientID === user.clientId)) || {};
  if (client.mfaEnabled) {
    const mfa = sessionStorage.getItem('mfa');
    if (!mfa) {
      auth.login(null, true);
    }
  }
}

function* sessionUserSaga() {
  const saga = createFetchSaga(fetchUserActions, fetchSessionUser);
  yield* saga();
  yield takeLatest(fetchUserActions.succeeded, onFetchSessionUser);
}

function* updateUserNameSaga() {
  const saga = createFetchSaga(updateUserNameAction, updateUsername);
  yield* saga();
  yield takeLatest(updateUserNameAction.succeeded, updateSuccess);
}

function* updateSuccess({ payload: user }) {
  toast.success({ text: intl.get('app.profile.saved', { name: user.userName }) });
  yield put(fetchUserActions.request());
}

export { sessionUserSaga, updateUserNameSaga };
