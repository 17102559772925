import React from 'react';
import intl from 'react-intl-universal';
import update from 'immutability-helper';
import { Input } from 'reactstrap';
import { CommonJoinerEditFields } from '../../joinerEditor';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';

/**
 * The most confusing part of this component is keeping in sync with the accessUrlParameterMapping field in the joiner.
 * That field value is a map, but we really need an array to generate and manage the UI controls. So we convert the map
 * to an array in the local state and use that for the UI. As changes are made, we convert the data back into a map, and
 * update the joiner.
 */
class DialSmithStimEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessUrlParams: []
    };

    // Convert the accessUrlParameterMapping to an array of objects (this.state.accessUrlParams)
    const { accessUrlParameterMapping } = props.joiner.stim.media;
    const keys = Object.keys(accessUrlParameterMapping);
    if (keys.length === 0) {
      this.state.accessUrlParams.push({ key: '', value: '' });
    }
    keys.forEach(key => this.state.accessUrlParams.push({ key, value: accessUrlParameterMapping[key] }));
  }

  // Convert the accessUrlParams array to a map and update the joiner
  syncJoiner = accessUrlParams => {
    const accessUrlParamMap = {};
    accessUrlParams.forEach(obj => {
      accessUrlParamMap[obj.key] = obj.value;
    });
    this.props.updateHandlers.setAccessUrlParamMap(accessUrlParamMap);
  };

  updateAccessUrlParams = (index, key, value) => {
    const state = update(this.state, {
      accessUrlParams: {
        [index]: { $set: { key, value } }
      }
    });
    this.setState(state);
    this.syncJoiner(state.accessUrlParams);
  };

  addRow = index => {
    const state = update(this.state, {
      accessUrlParams: {
        $splice: [[index, 0, { key: '', value: '' }]]
      }
    });
    this.setState(state);
    this.syncJoiner(state.accessUrlParams);
  };

  removeRow = index => {
    const state = update(this.state, {
      accessUrlParams: {
        $splice: [[index, 1]]
      }
    });
    this.setState(state);
    this.syncJoiner(state.accessUrlParams);
  };

  getLinkParamRow = (obj, index) => {
    const { readOnly } = this.props;
    const addDisabled = this.state.accessUrlParams.some(obj => !obj.key.trim() || !obj.value.trim());
    const removeDisabled = this.state.accessUrlParams.length === 1;
    return (
      <div className="link-param-row" key={`link_param_${index}`}>
        <Input
          type="text"
          value={obj.key}
          disabled={readOnly}
          onChange={e => this.updateAccessUrlParams(index, e.target.value, obj.value)}
        />
        <Input
          type="text"
          value={obj.value}
          disabled={readOnly}
          onChange={e => this.updateAccessUrlParams(index, obj.key, e.target.value)}
        />
        {!readOnly && (
          <AddRemoveIcons
            addDisabled={addDisabled}
            removeDisabled={removeDisabled}
            onAdd={() => this.addRow(index + 1)}
            onRemove={() => this.removeRow(index)}
          />
        )}
      </div>
    );
  };

  getLinkParamRows = () => {
    return this.state.accessUrlParams.map((obj, index) => this.getLinkParamRow(obj, index));
  };

  render() {
    return (
      <div className={`${this.props.tabs.edit} dialsmith-stim-edit`}>
        <CommonJoinerEditFields {...this.props} />
        <div className="link-params">
          <div className="label editor-left-column">Link Parameters</div>
          <div className="editor-right-column">
            <div className="link-param-row">
              <div>{intl.get('app.paramName')}</div>
              <div>{intl.get('app.participantDataPoint')}</div>
            </div>
            {this.getLinkParamRows()}
          </div>
        </div>
      </div>
    );
  }
}

export default DialSmithStimEdit;
