import { createSelector, createStructuredSelector } from 'reselect';
import { sessionValidationSelector } from './sessionSelector';
import { projectSelector } from './projectSelector';

const selectValidation = (session = {}, project = {}) =>
  (session.sessionValidation && session.sessionValidation.validation) ||
  (project.project && project.project.validation);
const selectValidationProgress = (session = {}, project = {}) =>
  (session.sessionValidation && session.sessionValidation.validationInProgress) ||
  (project.project && project.project.validationInProgress) ||
  false;
const selectValidationSupported = (session = {}, project = {}) =>
  session.sessionValidation || project.project ? true : false;

const validationSelector = () => {
  const entitySessionSelector = sessionValidationSelector();
  const entityProjectSelector = projectSelector();
  return createSelector(
    entitySessionSelector,
    entityProjectSelector,
    createStructuredSelector({
      validation: selectValidation,
      validationInProgress: selectValidationProgress,
      validationSupported: selectValidationSupported
    })
  );
};

export { validationSelector };
