import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { usePrevious, InvokeModal, Loader } from 'webapp-common';
import ParticipantList from '../../../participantList';
import { ImportModal } from '../import/ImportModal';

import './CollectionParticipants.css';

export const CollectionParticipants = props => {
  const { project, collectionId, job, hasProjectManage } = props;

  const {
    projectDetails = {},
    newMetadataCollection,
    setMetadataCollectionRequested,
    setMetadataCollectionSuccess,
    replaceMetadataJobRequested,
    replaceMetadataJobSuccess,
    deleteMetadataCollectionInProgress
  } = project;

  const { id: projectId } = projectDetails;
  const storeId = projectDetails.config?.metaDataStoreId;
  const readOnly = !hasProjectManage;

  const prevDeleteMetadataCollectionInProgress = usePrevious(deleteMetadataCollectionInProgress);

  const [showImportModal, setShowImportModal] = useState();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState();
  const fileUploadField = useRef();

  useEffect(() => {
    if (!setMetadataCollectionRequested && setMetadataCollectionSuccess) {
      setShowImportModal(true);
    }
  }, [setMetadataCollectionRequested, setMetadataCollectionSuccess]);

  useEffect(() => {
    if (!replaceMetadataJobRequested && replaceMetadataJobSuccess) {
      setShowImportModal(false);
    }
  }, [replaceMetadataJobRequested, replaceMetadataJobSuccess]);

  useEffect(() => {
    if (prevDeleteMetadataCollectionInProgress && deleteMetadataCollectionInProgress === false) {
      props.onCollectionDelete();
    }
  }, [deleteMetadataCollectionInProgress]);

  function handleSelectFileClick() {
    const ref = fileUploadField.current;
    ref.value = '';
    ref.click();
  }

  function onFileSelect(file) {
    props.setMetadataCollection({
      projectId,
      storeId,
      collectionId,
      file
    });
  }

  function toggleImportModal() {
    setShowImportModal(!showImportModal);
  }

  function toggleConfirmDeleteModal() {
    setShowConfirmDeleteModal(!showConfirmDeleteModal);
  }

  function replaceMetadataJob(jobUnit) {
    jobUnit.collectionName = jobUnit.collectionName || job.collection.name;
    const payload = {
      projectId,
      job: jobUnit
    };
    props.replaceMetadataJob(payload);
  }

  function deleteCollection() {
    props.deleteMetadataCollection({ projectId, job });
    toggleConfirmDeleteModal();
  }

  const showLoader =
    setMetadataCollectionRequested || replaceMetadataJobRequested || deleteMetadataCollectionInProgress;

  const showList =
    job.status === 'Complete' ||
    job.status === 'Screener' ||
    (job.status === null && job.collection?.autoEnrolled) ||
    props.showList;

  return (
    <>
      {showLoader && <Loader spinner fullScreen />}
      <div className="participant-list-container">
        {showList && <ParticipantList entityId={collectionId} showParticipantURLs={false} enablePagination />}
        {job.status === 'Failed' && (
          <div style={{ marginBottom: '3rem' }}>
            <b>{intl.get('app.importFailed')}:</b> {job.errMsg}
          </div>
        )}
        {job.status === 'Running' && (
          <div>
            <b>{intl.get('app.importInProgress')}</b>
          </div>
        )}
        {job.status !== 'Running' && (
          <Button disabled={readOnly} onClick={handleSelectFileClick}>
            {intl.get('app.replaceData')}
          </Button>
        )}
        {!job.collection?.autoEnrolled &&
          job.status !== 'Running' &&
          job.status !== 'Screener' &&
          !props.hideDelete && (
            <Button className="btn-warn" disabled={readOnly} onClick={toggleConfirmDeleteModal}>
              {intl.get('app.deleteList')}
            </Button>
          )}
        {showImportModal && (
          <ImportModal
            toggle={toggleImportModal}
            newMetadataCollection={newMetadataCollection}
            onSave={replaceMetadataJob}
          />
        )}
        <input
          type="file"
          className="hidden"
          accept="text/csv, .csv"
          ref={fileUploadField}
          onChange={e => onFileSelect(e.target.files[0])}
        />
        {showConfirmDeleteModal && (
          <InvokeModal
            showModal
            toggle={toggleConfirmDeleteModal}
            modalTitle={intl.get('app.deleteList')}
            cancelButtonText={intl.get('app.cancel')}
            primaryButtonText={intl.get('app.delete')}
            enableSave
            save={deleteCollection}
          >
            {intl.get('app.deleteList.confirm', { name: job.collection?.name })}
          </InvokeModal>
        )}
      </div>
    </>
  );
};
