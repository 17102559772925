import { isEqual } from 'lodash';

// From PanelConfigType.java
const panelConfigType = {
  CUSTOM: 'CUSTOM',
  GLOBAL: 'GLOBAL',
  GLOBAL_REF: 'GLOBAL_REF',
  DYNATA: 'DYNATA'
};

// From PanelParamType.java
const panelParamType = {
  PARTICIPANT_DATA_POINT: 'PARTICIPANT_DATA_POINT',
  STRING: 'STRING'
};

function getDefaultPanelConfig(args = {}) {
  return {
    type: args.type || panelConfigType.CUSTOM,
    panelId: args.panelId || '',
    name: args.name || '',
    hashCodeType: 'NONE',
    maxQuota: null,
    terminateUrl: '',
    completedUrl: '',
    overQuotaUrl: '',
    terminateUrlEnabled: false,
    completedUrlEnabled: false,
    overQuotaUrlEnabled: false,
    globalParameters: [],
    completedParameters: [],
    terminateParameters: [],
    overQuotaParameters: [],
    active: args.active === undefined ? true : args.active
  };
}

function buildUrl(config, urlType) {
  let url = config[`${urlType}Url`];
  if (!url) {
    return '';
  }

  // Build a map of query params from the global params and any override params
  const paramMap = {};
  [...config.globalParameters, ...config[`${urlType}Parameters`]].forEach(param => {
    const first = param.first.trim();
    const second = param.second.trim();
    if (first && second) {
      if (param.type === panelParamType.PARTICIPANT_DATA_POINT) {
        paramMap[second] = `{${first}}`;
      } else if (param.type === panelParamType.STRING) {
        paramMap[second] = first;
      }
    }
  });

  // Build the query string
  let queryStr = '';
  Object.entries(paramMap).forEach(([key, value]) => {
    queryStr += queryStr ? '&' : '';
    queryStr += `${key}=${value}`;
  });

  // Add the query string to the URL
  if (queryStr) {
    url += url.includes('?') ? '&' : '?';
    url += queryStr;
  }

  return url;
}

function equals(config1, config2) {
  return isEqual(config1, config2);
}

function isValid(config, nameRequired) {
  const { name, panelId, globalParameters, completedParameters, terminateParameters, overQuotaParameters } = config;

  if ((nameRequired && !name.trim()) || !panelId || !panelId.trim()) {
    return false;
  }

  const allParams = [...globalParameters, ...completedParameters, ...terminateParameters, ...overQuotaParameters];
  if (allParams.some(param => !param.first || !param.second)) {
    return false;
  }

  return true;
}

export const panelConfigUtil = { panelConfigType, panelParamType, getDefaultPanelConfig, buildUrl, equals, isValid };
