import React from 'react';
import { v4 as uuid } from 'uuid';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { UncontrolledTooltip } from 'reactstrap';
import appConfig from '../../../appConfig';
import { surveyUtil } from '../../../util/surveyUtil';
import { getRDChats } from '../../../store/redux/selectors/researchDashboardSelector';
import chatUtil from '../../../components/chat/chatUtil';
import { NavTab } from 'webapp-common';
import { statusIconPath } from '../../../containers/validationStatus/ValidationIcon';
import { RECRUIT_TAB } from '../../../util/projectUtil';
import { Icons } from '../../../components/icons/Icons';

const tabs = {
  SETTINGS_TAB: 'settings',
  DISPLAY_PREFS_TAB: 'displayPrefs',
  PANEL_CONFIG_TAB: 'panelConfig',
  EDITOR_TAB: 'editor',
  PARTICIPANTS_TAB: 'participants',
  RESPONSES_TAB: 'responses',
  DIAL_TAB: 'dial',
  EMOTIONS_TAB: 'emotions',
  COMPARATIVE_VIEWS_TAB: 'comparativeViews',
  CHATS_TAB: 'chat',
  VIRTUAL_FOCUS_GROUP_TAB: 'vfg'
};

const iconId1 = uuid().replace(/-/g, '');
const iconId2 = uuid().replace(/-/g, '');
const iconId3 = uuid().replace(/-/g, '');

const getUnreadMessagesCountForChat = chat => {
  // Count how many unread participant messages
  // A participant message has message.ownerId in chat.particpantIds
  let count = 0;
  chat.messages.forEach(message => {
    if (!message.read && chatUtil.isParticipantMessage(message, chat)) {
      count++;
    }
  });
  return count;
};

const getUnreadMessagesCount = chats => {
  let count = 0;
  chats.forEach(chat => {
    count += getUnreadMessagesCountForChat(chat);
  });
  if (count > 99) {
    count = 99;
  }
  return count;
};

const hasParticipantListSet = session => {
  return (
    session.screener ||
    session.autoEnroll ||
    session.sessionMode === 'Open' ||
    session.config?.participantCollectionIds[0]
  );
};

const hasScheduleSet = session => {
  return !session || session.startDate;
};

const hasSurveySet = survey => {
  return survey && survey.joiners.length > 0;
};

const ProjectSurveyTabs = props => {
  const { session, survey, activeSessionId, isTemplate, project, isScreener } = props;
  const isSelfServe = project.selfServe;

  const routeParams = useParams();
  const activeTab =
    (routeParams.subtab2 === 'screener' && tabs.SETTINGS_TAB) || routeParams.subtab2 || tabs.SETTINGS_TAB;

  const chats = useSelector(state => getRDChats(state, session.id), shallowEqual);
  const isActiveSession = isTemplate ? false : activeSessionId === session.id;
  const surveyHasConceptRotation = isActiveSession && surveyUtil.hasConceptRotation(survey);
  const dialEnabled = isActiveSession && surveyUtil.hasDialEnabledVideo(survey);
  const emotionDetectEnabled = isActiveSession && surveyUtil.emotionDetectEnabled(survey);
  const unreadMessagesCount = getUnreadMessagesCount(chats);
  const baseUrl = isTemplate ? appConfig.libraryPagePath : appConfig.projectPagePath;

  const getSurveyListUrl = () => {
    const page = isScreener ? 'screener' : 'survey';
    return `${baseUrl}/${routeParams.projectId}/${page}`;
  };

  function getLibraryUrl() {
    const page = isScreener ? 'screenerTemplates' : 'surveyTemplates';
    return `${baseUrl}/${page}`;
  }

  function getUrl(tab) {
    if (isTemplate) {
      return `${baseUrl}/${routeParams.tab}/${routeParams.subtab}/${tab}`;
    }
    return `${baseUrl}/${routeParams.projectId}/${routeParams.tab}/${routeParams.subtab}/${tab}`;
  }

  function getRecruitTab() {
    return `${baseUrl}/${routeParams.projectId}/${RECRUIT_TAB}`;
  }

  const isLegacyAutoEnroll = session.autoEnroll && !session.panelConfigId;

  return (
    <>
      {(isActiveSession || isTemplate) && (
        <>
          {(isScreener || project.multipleSurveysEnabled || project.totalSessionCount > 1) && (
            <NavTab
              icon="fa-arrow-left"
              iconStyle="light"
              label={isScreener ? intl.get('app.backToRecruit') : intl.get('app.backToSurveys')}
              to={isScreener ? getRecruitTab() : getSurveyListUrl()}
            />
          )}
          {isTemplate && (
            <NavTab
              icon="fa-arrow-left"
              iconStyle="light"
              label={intl.get('app.backToTemplates')}
              to={getLibraryUrl()}
            />
          )}
          <NavTab
            icon="fa-sliders-h"
            iconStyle="light"
            label={intl.get('app.settings')}
            active={activeTab === tabs.SETTINGS_TAB}
            to={getUrl(tabs.SETTINGS_TAB)}
          >
            {!isTemplate && !isSelfServe && !hasScheduleSet(session) && (
              <div className="validation-warning">
                <i className={statusIconPath.WARNING} id={`icon_${iconId1}`} />
                <UncontrolledTooltip placement="bottom" target={`icon_${iconId1}`}>
                  {intl.get('app.participantList.noScheduleConfigured')}
                </UncontrolledTooltip>
              </div>
            )}
          </NavTab>

          {(isTemplate || !session.newSessionAndSurvey) && (
            <>
              {!isTemplate && !session.screener && (
                <NavTab
                  icon="fa-cogs"
                  iconStyle="light"
                  label={intl.get('app.displayPreferences')}
                  active={activeTab === tabs.DISPLAY_PREFS_TAB}
                  to={getUrl(tabs.DISPLAY_PREFS_TAB)}
                />
              )}
              {!isTemplate && !isSelfServe && (session.screener || isLegacyAutoEnroll) && (
                <NavTab
                  icon="fa-user-cog"
                  iconStyle="light"
                  label={intl.get('app.panelConfigurations')}
                  active={activeTab === tabs.PANEL_CONFIG_TAB}
                  to={getUrl(tabs.PANEL_CONFIG_TAB)}
                />
              )}
              <NavTab
                icon="fa-edit"
                iconStyle="light"
                label={intl.get('app.editor')}
                active={activeTab === tabs.EDITOR_TAB}
                to={getUrl(tabs.EDITOR_TAB)}
              >
                {!hasSurveySet(survey) && (
                  <div className="validation-warning">
                    <i className={statusIconPath.WARNING} id={`icon_${iconId2}`} />
                    <UncontrolledTooltip placement="bottom" target={`icon_${iconId2}`}>
                      {intl.get('app.participantList.noValidSurvey')}
                    </UncontrolledTooltip>
                  </div>
                )}
              </NavTab>
              {!isTemplate && (
                <>
                  <NavTab
                    icon="fa-users"
                    iconStyle="light"
                    label={intl.get('app.participants')}
                    active={activeTab === tabs.PARTICIPANTS_TAB}
                    to={getUrl(tabs.PARTICIPANTS_TAB)}
                  >
                    {!hasParticipantListSet(session) && (
                      <div className="validation-warning">
                        <i className={statusIconPath.WARNING} id={`icon_${iconId3}`} />
                        <UncontrolledTooltip placement="bottom" target={`icon_${iconId3}`}>
                          {intl.get('app.participantList.noListConfigured')}
                        </UncontrolledTooltip>
                      </div>
                    )}
                  </NavTab>
                  {chats.length > 0 && (
                    <NavTab
                      icon="fa-comment"
                      iconStyle="light"
                      label={intl.get('app.chatManagement')}
                      active={activeTab === tabs.CHATS_TAB}
                      to={getUrl(tabs.CHATS_TAB)}
                    >
                      {unreadMessagesCount > 0 && <span className="unread-banner">{unreadMessagesCount}</span>}
                    </NavTab>
                  )}
                  <NavTab
                    icon="fa-poll-people"
                    iconStyle="light"
                    label={intl.get('app.responses')}
                    active={activeTab === tabs.RESPONSES_TAB}
                    to={getUrl(tabs.RESPONSES_TAB)}
                  />
                </>
              )}
              {dialEnabled && !session.screener && (
                <NavTab
                  icon="fa-chart-line"
                  iconStyle="light"
                  label={intl.get('app.dial')}
                  active={activeTab === tabs.DIAL_TAB}
                  to={getUrl(tabs.DIAL_TAB)}
                />
              )}
              {emotionDetectEnabled && !session.screener && (
                <NavTab
                  icon="fa-grin-squint-tears"
                  iconStyle="light"
                  label={intl.get('app.emotions')}
                  active={activeTab === tabs.EMOTIONS_TAB}
                  to={getUrl(tabs.EMOTIONS_TAB)}
                />
              )}
              {surveyHasConceptRotation && !session.screener && (
                <NavTab
                  icon="fa-table"
                  iconStyle="light"
                  label={intl.get('app.comparativeViews')}
                  active={activeTab === tabs.COMPARATIVE_VIEWS_TAB}
                  to={getUrl(tabs.COMPARATIVE_VIEWS_TAB)}
                />
              )}
              {surveyUtil.getVirtualFocusGroupJoiners(survey && survey.joiners).length !== 0 && (
                <NavTab
                  iconComponent={
                    <Icons.VFGIcon
                      iconStyle={activeTab === tabs.VIRTUAL_FOCUS_GROUP_TAB ? 'active' : 'solid'}
                      size={1.5}
                    />
                  }
                  label={intl.get('app.Vis-a-Vis')}
                  active={activeTab === tabs.VIRTUAL_FOCUS_GROUP_TAB}
                  to={getUrl(tabs.VIRTUAL_FOCUS_GROUP_TAB)}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export { ProjectSurveyTabs, tabs };
