import { connect } from 'react-redux';
import {
  fetchRDConfigsListAction,
  rdConfigTypeSubscribeAction,
  saveCompleteRDConfigActions,
  applyProjectRDConfigActions,
  shareRDConfigAction,
  deleteRDConfigAction
} from '../../../../store/redux/actions/researchDashboardActions';

import { SurveyDisplayPrefs } from './SurveyDisplayPrefs';

const mapStateToProps = (state, { sessionId }) => {
  const user = state.sessionUser.user || {};
  const sessionsStore = state.sessionListDetails.sessions;
  const { content: sessions } = sessionsStore;
  const { email, userID: userId } = user;
  const { [sessionId]: rdConfigStore = {}, rdConfigsApiRequested, rdConfigSaveInProgress } = state.rdConfig;
  const { rdConfig = {}, configOwners = {}, researchDashboardConfigs = [] } = rdConfigStore;
  return {
    email,
    userId,
    sessions,
    rdConfig,
    configOwners,
    researchDashboardConfigs,
    rdConfigsApiRequested,
    rdConfigSaveInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRDConfigs: params => dispatch(fetchRDConfigsListAction.request(params)),
  rdConfigTypeSubAction: params => dispatch(rdConfigTypeSubscribeAction.request(params)),
  saveToConfigs: params => dispatch(saveCompleteRDConfigActions.request(params)),
  applyProjectConfig: params => dispatch(applyProjectRDConfigActions.request(params)),
  shareConfig: params => dispatch(shareRDConfigAction.request(params)),
  resetAll: params => dispatch(deleteRDConfigAction.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDisplayPrefs);
