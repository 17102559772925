import { connect } from 'react-redux';
import { fetchAllDataTablesActions } from '../../../../../../store/redux/actions/dataTableActions';
import { allDataTablesSelector } from '../../../../../../store/redux/selectors/dataTableSelector';
import LookupValueCondition from './LookupValueCondition';

const mapStateToProps = (state, props) => {
  return {
    allDataTablesSelector: allDataTablesSelector()(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAllDataTables: () => dispatch(fetchAllDataTablesActions.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(LookupValueCondition);
