import React from 'react';
import Cookies from 'js-cookie';
import intl from 'react-intl-universal';
import { STIM_TYPE } from '../../util/joinerUtil';
import { mediaUtil } from '../../util/mediaUtil';
import { Icons } from '../../components/icons/Icons';
import webcontent_src from '../../images/icons8-internet.png';

function getMediaType(type) {
  return type === 'all' ? '' : type;
}

function getImage(accessUrl, media) {
  const type = media.type?.toLowerCase();
  switch (type) {
    case STIM_TYPE.image:
      return <img src={mediaUtil.getMediaUrl(media)} className="table-cell-image" alt="" />;
    case STIM_TYPE.video:
      return <Icons.VideoIcon className="fa-3x table-cell-image" />;
    case STIM_TYPE.webcontent:
      return <img src={webcontent_src} className="table-cell-image" alt="" />;
    default:
      return null;
  }
}

function formatTitle(cell) {
  return <span title={cell}>{cell}</span>;
}

function renderMediaConvertStatus(mediaConvertStatus) {
  if (!mediaConvertStatus) {
    return '';
  }
  const { state, errMsg } = mediaConvertStatus;
  if (state === 'ERROR') {
    return (
      <span title={errMsg} style={{ color: 'red' }}>
        {intl.get('app.error')}
      </span>
    );
  }
  return mediaUtil.getMediaConvertInProgressMsg(mediaConvertStatus);
}

function getCookies() {
  const cookies = Cookies.get();
  let cookieStr = '';
  for (const i in cookies) {
    cookieStr += `${i}=${cookies[i]};`;
  }
  return cookieStr;
}

export const mediaLibraryUtil = {
  getMediaType,
  getImage,
  formatTitle,
  renderMediaConvertStatus,
  getCookies
};
