import { handleActions } from 'redux-actions';
import { getSurveyVideosIngestStatusAction } from '../actions/watermarkActions';

const initialState = {};

export const watermarkReducer = handleActions(
  {
    [getSurveyVideosIngestStatusAction.triggered](state) {
      return {
        ...state,
        fetchIngestStatusInProgress: true,
        fetchIngestStatusSuccess: false
      };
    },

    [getSurveyVideosIngestStatusAction.succeeded](state, { payload }) {
      const { surveyId, ingestStatus } = payload;
      return {
        ...state,
        [surveyId]: ingestStatus,
        fetchIngestStatusInProgress: false,
        fetchIngestStatusSuccess: true
      };
    },

    [getSurveyVideosIngestStatusAction.failed](state) {
      return {
        ...state,
        fetchIngestStatusInProgress: false,
        fetchIngestStatusSuccess: false,
        fetchIngestStatusFailure: true
      };
    }
  },
  initialState
);
export default watermarkReducer;
