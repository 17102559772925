import React from 'react';
import { Switch } from 'antd';

export const ImageWatermark = props => {
  const { readOnly, setWatermarkProperty } = props;
  return (
    <div>
      <Switch
        checked={props.enableImageWatermark}
        onChange={bool => setWatermarkProperty('enableImageWatermark', bool)}
        disabled={readOnly}
      />
    </div>
  );
};
