import React from 'react';
import intl from 'react-intl-universal';
import { Col, Input, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import { FlexRow, InvokeModal, InvokeTable, SearchInput } from 'webapp-common';

import './ViewParticipantModal.css';

const KEY = 'key';
const VALUE = 'value';
const ASC = 'asc';
const PAGE_SIZE = 5;

class ViewParticipantModal extends React.Component {
  state = {
    data: [],
    active: this.props.active,
    searchText: '',
    sortBy: '',
    sortOrder: ASC,
    pageNumber: 1
  };

  componentDidMount() {
    const data = this.doDataPrep();
    this.setState({ data });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      const data = this.doDataPrep();
      this.setState({ data });
    }
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active });
    }
  }

  /*
   * Convert the data to an array of objects for display in table, default sorted by columnOrder.
   */
  doDataPrep = () => {
    const data = [];
    this.props.columnOrder.forEach(key => {
      const value = this.props.data[key];
      if (value) {
        data.push({
          [KEY]: this.props.keyTransformMap[key] || key,
          [VALUE]: value
        });
      }
    });
    return data;
  };

  enableSave = () => {
    return this.props.active !== this.state.active;
  };

  renderCell = info => {
    const value = info.getValue();
    return <span title={value}>{value}</span>;
  };

  getColumns = () => {
    return [
      {
        accessorKey: KEY,
        header: intl.get('app.questionTitle'),
        headerStyle: { width: '6rem' },
        cell: this.renderCell,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: VALUE,
        header: intl.get('app.participantResponse'),
        headerStyle: { width: '12rem' },
        cell: this.renderCell,
        cellClassName: 'text-truncate'
      }
    ];
  };

  updateSearchText = e => {
    this.doSearch(e.target.value);
  };

  compare = (a = '', b = '') => {
    const aUp = a.toUpperCase();
    const bUp = b.toUpperCase();
    return aUp > bUp ? 1 : bUp > aUp ? -1 : 0;
  };

  compareKey = (a, b) => {
    return this.compare(a.key, b.key);
  };

  compareValue = (a, b) => {
    return this.compare(a.value, b.value);
  };

  sort = ({ sortBy, sortOrder }) => {
    this.setState({ sortBy, sortOrder, pageNumber: 1 });
  };

  paginate = ({ pageNumber }) => {
    this.setState({ pageNumber });
  };

  doSearch = searchText => {
    this.setState({ searchText, pageNumber: 1 });
  };

  doSort = data => {
    const { sortBy, sortOrder } = this.state;
    if (!sortBy) {
      return data;
    }
    const copy = [...data];
    copy.sort(sortBy === VALUE ? this.compareValue : this.compareKey);
    sortOrder !== ASC && copy.reverse();
    return copy;
  };

  setActive = active => {
    this.setState({ active });
  };

  saveActive = () => {
    const { active } = this.state;
    const { entityId, activeOnly, sessionId } = this.props;
    this.props.saveParticipantActive({
      participantId: this.props.participantId,
      sessionId,
      active,
      entityId,
      activeOnly
    });
    this.props.toggle();
  };

  getTableData = () => {
    const { data, searchText, pageNumber } = this.state;

    // Filter the data by searchText
    const filterStr = searchText.trim().toLowerCase();
    const filteredData = data.filter(
      element =>
        filterStr === '' ||
        element[KEY].toLowerCase().indexOf(filterStr) !== -1 ||
        element[VALUE].toLowerCase().indexOf(filterStr) !== -1
    );

    // Sort the data
    const tableData = this.doSort(filteredData);

    // Return the pagedList object
    const startIndex = (pageNumber - 1) * PAGE_SIZE;
    return {
      content: tableData.slice(startIndex, startIndex + PAGE_SIZE),
      totalElements: tableData.length,
      pageRequest: {
        pageNumber,
        pageSize: PAGE_SIZE
      }
    };
  };

  render() {
    const {
      toggle,
      modalTitle = '',
      primaryButtonText = intl.get('app.ok'),
      cancelButtonText = intl.get('app.cancel')
    } = this.props;

    const pagedList = this.getTableData();
    const pagination = {
      pageSize: pagedList.pageRequest.pageSize,
      pageNumber: pagedList.pageRequest.pageNumber,
      totalSize: pagedList.totalElements
    };

    return (
      <InvokeModal
        showModal={true}
        toggle={toggle}
        modalTitle={modalTitle}
        cancelButtonText={cancelButtonText}
        primaryButtonText={primaryButtonText}
        enableSave={this.enableSave()}
        save={this.saveActive}
        className="view-participant-modal"
      >
        <FlexRow>
          <Label sm="3">{intl.get('app.status')}</Label>
          <Col sm="4" title={!this.props.surveyComplete ? intl.get('app.qualificationStatusChangeDisabled') : ''}>
            <Input
              type="select"
              value={this.state.active && this.props.surveyComplete}
              disabled={!this.props.surveyComplete} // Can't set to qualified if surveyComplete == false
              onChange={e => this.setActive(e.target.value)}
            >
              <option value={true}>{intl.get('app.qualified')}</option>
              <option value={false}>{intl.get('app.disqualified')}</option>
            </Input>
          </Col>
        </FlexRow>
        <div className="my-3">
          <SearchInput
            type="text"
            className="search"
            placeholder={intl.get('app.search')}
            value={this.state.searchText}
            onChange={this.updateSearchText}
          />
        </div>
        <InvokeTable
          className="invoke-table"
          columns={this.getColumns()}
          data={pagedList.content}
          pagination={pagination}
          onSortingChange={this.sort}
          onPaginationChange={this.paginate}
        />
      </InvokeModal>
    );
  }
}

export default ViewParticipantModal;
