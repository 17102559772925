import { connect } from 'react-redux';
import { fetchUserActions } from '../../store/redux/actions/userActions';
import { mediaListSelector } from '../../store/redux/selectors/mediaListSelector';
import { sessionUserSelector } from '../../store/redux/selectors/sessionUserSelector';
import { clientAdminSelector } from '../../store/redux/selectors/clientAdminSelector';

import App from './App';

const mapStateToProps = state => {
  const mediaSelector = mediaListSelector(state);
  const { sessionUser, sessionTenant, clientUIConfig } = sessionUserSelector(state);
  const clientStore = clientAdminSelector()(state, sessionTenant && sessionTenant.clientID);

  return {
    ...mediaSelector,
    clientStore,
    sessionUser,
    sessionTenant,
    clientUIConfig
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSessionUser: () => dispatch(fetchUserActions.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
