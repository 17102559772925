import { get, put } from '../util/request';
const SESSION_INFO_URL = '/a/api/sessionInfo';
const USER_PROFILE_URL = '/a/api/userProfile';

async function fetchSessionUser() {
  return get(SESSION_INFO_URL)().then(res => (res.body && res.body.payload) || null);
}

async function updateUsername({ payload }) {
  return put(USER_PROFILE_URL)(payload).then(res => (res.body && res.body.payload) || null);
}

export { fetchSessionUser, updateUsername };
