import React, { useCallback, useMemo, useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, get, isEqual } from 'lodash';
import { DEFAULT_PAGE_SIZE } from '../../../../util/joinerUtil';
import { participantListUtil } from '../../../participantList/participantListUtil';
import { InvokeTable, usePagination } from 'webapp-common';
import ViewParticipantModal from '../../../participantList/ViewParticipantModal';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

export default function OpenTextResponses(props) {
  const { joinerId, sessionId, fetchScreenerPreviewResults, getPagedOrSortedResponses } = props;

  const [state, setState] = useReducer(reducer, {
    pageNumber: 1,
    tableData: undefined,
    showScreenerResponses: false,
    selectedRow: null
  });

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      getPagedOrSortedResponses(joinerId, 1, DEFAULT_PAGE_SIZE, sortBy, sortOrder);
    },
    [getPagedOrSortedResponses, joinerId]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      getPagedOrSortedResponses(joinerId, pageNumber, pageSize, sortBy, sortOrder);
      setState({ pageNumber });
    },
    [getPagedOrSortedResponses, joinerId]
  );

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'userId',
        header: intl.get('app.userId'),
        headerClassName: 'header right-border',
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'response',
        header: intl.get('app.response'),
        headerClassName: 'header right-border',
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'wordCount',
        header: intl.get('app.wordCount'),
        headerClassName: 'header right-border',
        cellClassName: 'text-truncate'
      }
    ];
  }, []);

  const toggleshowScreenerResponses = useCallback(() => {
    setState({ showScreenerResponses: !state.showScreenerResponses });
  }, [state.showScreenerResponses]);

  const saveParticipantActive = participant => {
    props.saveParticipantActive(participant);
    toggleshowScreenerResponses();
  };

  const onRowSelect = useCallback(
    row => {
      setState({
        selectedRow: row
      });
      if (!state.showScreenerResponses) {
        fetchScreenerPreviewResults({ sessionId, participantId: row.userId });
      }
      if (Object.keys(row).length !== 0 || row.constructor !== Object) {
        toggleshowScreenerResponses();
      }
    },
    [fetchScreenerPreviewResults, sessionId, state.showScreenerResponses, toggleshowScreenerResponses]
  );

  if (props.summary?.pagedListOpenText && !isEqual(state.tableData, props.summary.pagedListOpenText)) {
    setState({
      tableData: cloneDeep(props.summary.pagedListOpenText)
    });
  }

  function getColumnOrder() {
    const { columnOrder, questionJoiners, segmentCategories, enrolleeDataDef, keyTransformMap } = props;
    return participantListUtil.getColumnOrder(
      columnOrder,
      questionJoiners,
      segmentCategories,
      enrolleeDataDef,
      keyTransformMap
    );
  }

  const responseMetaData = get(props, 'screenerPreviewResults.metadata');
  const responsesDataStore = get(responseMetaData, 'dataStore');
  const { columnOrder, keyTransformMap } = getColumnOrder();

  const rowClassNames = useCallback(() => {
    return {
      clickable: true
    };
  }, []);

  const { pageRequest, totalElements } = state.tableData ?? {};
  const pagination = usePagination({ pageRequest, totalElements });

  return (
    <>
      {props.summary && (
        <InvokeTable
          className="response-table invoke-table"
          columns={columns}
          data={state.tableData?.content ?? []}
          pagination={pagination}
          onSortingChange={sort}
          onPaginationChange={paginate}
          onRowSelect={onRowSelect}
          rowClassNames={rowClassNames}
        />
      )}
      {state.showScreenerResponses && props.screenerPreviewResults && (
        <ViewParticipantModal
          toggle={toggleshowScreenerResponses}
          modalTitle={intl.get('app.viewParticipantResponses')}
          cancelButtonText={intl.get('app.cancel')}
          data={responsesDataStore || {}}
          columnOrder={columnOrder}
          keyTransformMap={keyTransformMap}
          active={responseMetaData ? responseMetaData.active : false}
          surveyComplete={state.selectedRow && state.selectedRow.surveyComplete}
          participantId={state.selectedRow ? state.selectedRow.userId : ''}
          sessionId={sessionId}
          saveParticipantActive={saveParticipantActive}
        />
      )}
    </>
  );
}
