import { connect } from 'react-redux';
import { fetchProjectActions } from '../../../store/redux/actions/fetchProject';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';

import { ProjectPage } from './ProjectPage';

const mapStateToProps = (state, props) => {
  const { hasProjectView, hasProjectManage, hasReportsView, hasReportsManage } = sessionUserSelector(state).abilities;
  return {
    hasProjectView,
    hasProjectManage,
    hasReportsView,
    hasReportsManage
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProject: projectId => dispatch(fetchProjectActions.request(projectId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
