import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

import './FilterSelectorModal.css';

export const FilterSelectorModal = props => {
  const { sessions, consolidatedReportData, videoCaptureEnabled, banner, bannerIndex, setFilters, closeModal } = props;

  const [selectedFilters, setSelectedFilters] = useState((banner && banner.filters) || []);

  useEffect(() => {
    if (banner && banner.filters && selectedFilters.length === 0) {
      setSelectedFilters(banner.filters);
    }
  }, [banner]);

  function getFilterIndex(filter) {
    return selectedFilters.findIndex(
      selectedFilter => filter.name === selectedFilter.name && filter.expression === selectedFilter.expression
    );
  }

  function handleSelectFilter(filter) {
    const index = getFilterIndex(filter);
    const filters = [...selectedFilters];
    if (index !== -1) {
      filters.splice(index, 1);
    } else {
      filters.push(filter);
    }
    setSelectedFilters(filters);
  }

  function generateFilterRow(filter, index) {
    return (
      <div className="group-item" key={`filter_${filter.name}-${index}`}>
        <label>
          <Input type="checkbox" checked={getFilterIndex(filter) !== -1} onChange={() => handleSelectFilter(filter)} />
          <span className="truncated" title={filter.name}>
            {filter.name}
          </span>
        </label>
      </div>
    );
  }

  function getFilterGroupName(group) {
    const session = sessions.find(s => s.id === group);
    return session ? session.name : group;
  }

  function getFilterRows() {
    const { filters } = consolidatedReportData;
    return (
      <div>
        {Object.keys(filters)
          .filter(group => filters[group] && filters[group].length > 0)
          .map(group => {
            const groupFilters = filters[group];
            return (
              <div className="filter-group">
                <div className="group-name"> {getFilterGroupName(group)} </div>
                {groupFilters
                  .filter(filter => filter.type !== 'DEFAULT_FILTER' || videoCaptureEnabled)
                  .map((filter, index) => generateFilterRow(filter, index))}
              </div>
            );
          })}
      </div>
    );
  }

  function save() {
    setFilters(selectedFilters, bannerIndex);
    closeModal();
  }

  return (
    <InvokeModal
      showModal
      toggle={closeModal}
      modalTitle={intl.get('app.selectFilters')}
      className="filter-selector-modal"
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.save')}
      enableSave={selectedFilters.length > 0}
      save={save}
    >
      {getFilterRows()}
    </InvokeModal>
  );
};
