import React from 'react';
import { Input } from 'reactstrap';
import appConfig from '../../appConfig';
const Cookies = require('js-cookie');

export const ClientsDropdown = props => {
  const { clients, tenant = {} } = props;

  function onSelectClient(e) {
    Cookies.set(appConfig.clientIdCookieName, e.target.value);
    window.location.href = '/f/home-page';
  }

  return (
    <div className="client-select">
      {clients.length > 1 && (
        <Input type="select" value={tenant.clientID} onChange={onSelectClient} style={{ color: 'initial' }}>
          {clients
            .sort((a, b) => {
              const nameA = a.clientName.toUpperCase();
              const nameB = b.clientName.toUpperCase();
              return nameA > nameB ? 1 : -1;
            })
            .map(client => (
              <option key={client.clientID} value={client.clientID}>
                {client.clientName}
              </option>
            ))}
        </Input>
      )}
      {clients.length === 1 && <span>{tenant.clientName + ' /'}</span>}
    </div>
  );
};
