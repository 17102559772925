import React, { useCallback, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import intl from 'react-intl-universal';
import { InvokeTable } from 'webapp-common';
import { getRDSession, getRDEnrollees } from '../../../../../store/redux/selectors/researchDashboardSelector';
import { ENGLISH } from '../../../../../util/joinerUtil';

export const ChoiceAnswersForOther = props => {
  const { answers, sessionId, viewLanguage, setSelectedParticipant } = props;
  const session = useSelector(state => getRDSession(state, sessionId), shallowEqual);
  const { requireNickname } = session;
  const enrolleesInfo = useSelector(state => getRDEnrollees(state, sessionId), shallowEqual);
  const { enrolleeMap } = enrolleesInfo;

  const getNickname = useCallback(
    participantId => {
      const enrollee = enrolleeMap[participantId];
      return enrollee ? enrollee.nickname || enrollee.id : '-';
    },
    [enrolleeMap]
  );

  const data = useMemo(() => {
    if (!answers) {
      return [];
    }
    return Object.keys(answers)
      .filter(responseKey => answers[responseKey].answer?.otherInputAnswer !== null)
      .sort(
        (responseKey1, responseKey2) => (answers[responseKey1].createDate < answers[responseKey2].createDate && 1) || -1
      )
      .map((responseKey, index) => {
        const response = answers[responseKey];
        const { otherInputAnswer } = response.answer;
        return {
          id: getNickname(response.participantId),
          index: index + 1,
          response: viewLanguage === ENGLISH ? otherInputAnswer.value : otherInputAnswer.origValue,
          participant: enrolleeMap[response.participantId]
        };
      });
  }, [answers, enrolleeMap, getNickname, viewLanguage]);

  const getParticipant = useCallback(
    info => {
      const obj = info.row.original;
      return <span onClick={() => setSelectedParticipant(obj.participant)}>{obj.id}</span>;
    },
    [setSelectedParticipant]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'index',
        header: '#',
        headerStyle: { width: '4rem' }
      },
      {
        accessorKey: 'id',
        header: requireNickname ? intl.get('app.nickname') : intl.get('app.id'),
        headerStyle: { width: '15rem' },
        cell: getParticipant,
        cellClassName: 'link'
      },
      {
        accessorKey: 'response',
        header: intl.get('app.response')
      }
    ],
    [getParticipant, requireNickname]
  );

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      const sorted = data.toSorted((a, b) => {
        return sortBy === 'index'
          ? a[sortBy] >= b[sortBy]
            ? 1
            : -1
          : a[sortBy].toLowerCase() >= b[sortBy].toLowerCase()
          ? 1
          : -1;
      });
      return sortOrder === 'asc' ? sorted : sorted.reverse();
    },
    [data]
  );

  return (
    <InvokeTable
      className="invoke-table"
      columns={columns}
      data={data}
      initialState={{
        sorting: [
          {
            id: 'index',
            desc: false
          }
        ]
      }}
      manualSorting={false}
      onSortingChange={sort}
    />
  );
};
