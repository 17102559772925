import update from 'immutability-helper';
import { cloneDeep, findIndex } from 'lodash';
import {
  isRequired,
  OPTION_TYPE,
  MATRIX_CHOICES,
  REFERENCED_QUESTION,
  BIPOLAR_OPTION,
  REQUIRED_OPTION,
  STIM_TYPE,
  getDefaultChoice,
  RESPONSE_SET_TYPE,
  getAbbreviation,
  getDefaultVideoOptions,
  ENGLISH,
  setChoiceValues,
  STRING_LABEL
} from '../../../../util/joinerUtil';
import { idUtils } from '../../../../util/Id';
import { getVideoStimFromJoiner } from '../../../../util/conceptRotationUtil';

const DEFAULT = 'DEFAULT';
const OTHER = 'OTHER';
const IMAGE_LABEL = 'imagelabel';

/**
 * This class contains all functions for updating question joiners
 */
class JoinerUpdateHandlers {
  constructor(obj) {
    this.obj = obj;
  }

  updateExitPage = e => {
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          exitPage: { $set: e.target.value }
        }
      }
    });
    this.obj.setState(state);
  };

  updateJoiner = joiner => {
    this.obj.setState({ ...this.obj.state, joiner });
  };

  /*
   * Convenience function for setting a top-level joiner field
   */
  updateJoinerField = (field, value) => {
    const state = update(this.obj.state, {
      joiner: {
        [field]: { $set: value }
      }
    });
    this.obj.setState(state);
  };

  updateQuestionTitle = e => {
    this.updateJoinerField('researchPrompt', e.target.value);
  };

  updateGroupName = value => {
    this.updateJoinerField('groupName', value);
  };

  updateHidden = value => {
    if (this.obj.state.joiner.conceptRotationId) {
      const conceptVisibility = cloneDeep(this.obj.state.conceptVisibility);
      conceptVisibility.forEach(c => (c.hidden = value));
      const state = update(this.obj.state, {
        conceptVisibility: { $set: conceptVisibility },
        joiner: {
          hidden: { $set: value }
        }
      });
      this.obj.setState(state);
    } else {
      this.updateJoinerField('hidden', value);
    }
  };

  updateDisplayLayout = value => {
    this.updateJoinerField('displayLayout', value);
  };

  updateConfiguredTime = time => {
    const state = update(this.obj.state, {
      joiner: {
        timingOptions: {
          configuredTime: { $set: time.minutes() * 60 + time.seconds() }
        }
      }
    });
    this.obj.setState(state);
  };

  updateTextStimText = (contents, richContents, language, viewLanguage) => {
    const { stim } = this.obj.state.joiner;
    const setText = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          contents: { $set: setText ? contents : stim.contents },
          richContents: { $set: setText ? richContents : stim.richContents },
          origContents: { $set: setOrig ? contents : stim.origContents },
          origRichContents: { $set: setOrig ? richContents : stim.origRichContents }
        }
      }
    });
    this.obj.setState(state);
  };

  updateQuestionText = (prompt, richContents, language, viewLanguage) => {
    const { question } = this.obj.state.joiner.def;
    const setText = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const state = update(this.obj.state, {
      joiner: {
        def: {
          question: {
            prompt: { $set: setText ? prompt : question.prompt },
            richContents: { $set: setText ? richContents : question.richContents },
            origPrompt: { $set: setOrig ? prompt : question.origPrompt },
            origRichContents: { $set: setOrig ? richContents : question.origRichContents }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateStimCaption = (caption, language, viewLanguage) => {
    const { stim } = this.obj.state.joiner;
    const setCaption = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          caption: { $set: setCaption ? caption : stim.caption },
          origCaption: { $set: setOrig ? caption : stim.origCaption }
        }
      }
    });
    this.obj.setState(state);
  };

  updateStimAltTag = (altTag, language, viewLanguage) => {
    const { stim } = this.obj.state.joiner;
    const setAltTag = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          altTag: { $set: setAltTag ? altTag : stim.altTag },
          origAltTag: { $set: setOrig ? altTag : stim.origAltTag }
        }
      }
    });
    this.obj.setState(state);
  };

  updateStimMedia = media => {
    const stim = cloneDeep(this.obj.state.joiner.stim) || {};
    stim.media = media;
    stim.type = media.type.toLowerCase();
    if (stim.type === STIM_TYPE.video && !stim.options) {
      stim.options = getDefaultVideoOptions();
    }
    this.updateJoinerField('stim', stim);
  };

  updateVideoStimOptions = (key, value) => {
    if (!this.obj.state.joiner.conceptRotation) {
      const stim = cloneDeep(this.obj.state.joiner.stim) || {};
      stim.options[key] = value;
      this.updateJoinerField('stim', stim);
      return;
    }

    // Update concept rotation joiners
    const concepts = cloneDeep(this.obj.state.joiner.conceptRotation.concepts);
    concepts.forEach(concept => {
      if (concept.startJoiner.stim.type === STIM_TYPE.video) {
        concept.startJoiner.stim.options[key] = value;
      }
    });
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          concepts: { $set: concepts }
        }
      }
    });

    this.obj.setState(state);
  };

  updateVideoDialConfig = dialConfig => {
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          options: {
            dialConfig: { $set: dialConfig },
            fullScreen: { $set: dialConfig.enabled ? false : this.obj.state.joiner.stim.options.fullScreen }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateVideoDialConfigField = (key, value) => {
    const dialConfig = cloneDeep(this.obj.state.joiner.stim.options.dialConfig) || { actionButtons: [] };
    dialConfig[key] = value;
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          options: {
            dialConfig: { $set: dialConfig }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateVideoDialActionButton = ({ index, label, origLabel, multiClick, enableAdHocQuestion, adHocJoinerId }) => {
    const actionButtons = [...this.obj.state.joiner.stim.options.dialConfig.actionButtons];
    if (!actionButtons[index]) {
      actionButtons[index] = {};
    }
    actionButtons[index].label = label;
    actionButtons[index].origLabel = origLabel;
    actionButtons[index].multiClick = multiClick;
    actionButtons[index].enableAdHocQuestion = enableAdHocQuestion;
    actionButtons[index].adHocJoinerId = adHocJoinerId;
    this.updateVideoDialConfigField('actionButtons', actionButtons);
  };

  addVideoDialActionButton = index => {
    const actionButtons = [...this.obj.state.joiner.stim.options.dialConfig.actionButtons];
    actionButtons.splice(index, 0, {});
    this.updateVideoDialConfigField('actionButtons', actionButtons);
  };

  removeVideoDialActionButton = index => {
    const actionButtons = [...this.obj.state.joiner.stim.options.dialConfig.actionButtons];
    actionButtons.splice(index, 1);
    this.updateVideoDialConfigField('actionButtons', actionButtons);
  };

  updateVideoDialConfigCR = dialConfig => {
    const concepts = cloneDeep(this.obj.state.joiner.conceptRotation.concepts);
    concepts.forEach(concept => {
      const { stim } = concept.startJoiner;
      if (stim.type === STIM_TYPE.video && stim.options) {
        stim.options.dialConfig = dialConfig;
        stim.options.fullScreen = dialConfig.enabled ? false : stim.options.fullScreen;
      }
    });

    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          concepts: { $set: concepts }
        }
      }
    });

    this.obj.setState(state);
  };

  updateVideoDialConfigFieldCR = (key, value) => {
    const concepts = cloneDeep(this.obj.state.joiner.conceptRotation.concepts);

    concepts.forEach(concept => {
      if (concept.startJoiner.stim.type === STIM_TYPE.video) {
        const { options = {} } = concept.startJoiner.stim;
        const dialConfig = options.dialConfig || { actionButtons: [] };
        dialConfig[key] = value;
        concept.startJoiner.stim.options && (concept.startJoiner.stim.options.dialConfig = dialConfig);
      }
    });

    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          concepts: { $set: concepts }
        }
      }
    });

    this.obj.setState(state);
  };

  updateVideoDialActionButtonCR = ({ index, label, origLabel, multiClick, enableAdHocQuestion, adHocJoinerId }) => {
    const videoStim = getVideoStimFromJoiner(this.obj.state.joiner);
    const actionButtons = [...videoStim.options.dialConfig.actionButtons];
    if (!actionButtons[index]) {
      actionButtons[index] = {};
    }
    actionButtons[index].label = label;
    actionButtons[index].origLabel = origLabel;
    actionButtons[index].multiClick = multiClick;
    actionButtons[index].enableAdHocQuestion = enableAdHocQuestion;
    actionButtons[index].adHocJoinerId = adHocJoinerId;
    this.updateVideoDialConfigFieldCR('actionButtons', actionButtons);
  };

  addVideoDialActionButtonCR = index => {
    const videoStim = getVideoStimFromJoiner(this.obj.state.joiner);
    const actionButtons = [...videoStim.options.dialConfig.actionButtons];
    actionButtons.splice(index, 0, {});
    this.updateVideoDialConfigFieldCR('actionButtons', actionButtons);
  };

  removeVideoDialActionButtonCR = index => {
    const videoStim = getVideoStimFromJoiner(this.obj.state.joiner);
    const actionButtons = [...videoStim.options.dialConfig.actionButtons];
    actionButtons.splice(index, 1);
    this.updateVideoDialConfigFieldCR('actionButtons', actionButtons);
  };

  onRestartMediaUpdate = value => {
    const state = update(this.obj.state, {
      joiner: {
        restartMedia: { $set: value }
      }
    });
    this.obj.setState(state);
  };

  updateStimMediaAccessUrl = e => {
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          media: {
            accessUrl: { $set: e.target.value }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  setAccessUrlParamMap = map => {
    const state = update(this.obj.state, {
      joiner: {
        stim: {
          media: {
            accessUrlParameterMapping: { $set: map }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  removeStim = () => {
    const state = update(this.obj.state, {
      joiner: {
        stim: { $set: null }
      }
    });
    this.obj.setState(state);
  };

  updateQuestionRequired = () => {
    const { joiner } = this.obj.state;
    const options = cloneDeep(joiner.def.responseSet.options);
    if (isRequired(joiner)) {
      const index = findIndex(options, o => o.type === OPTION_TYPE.requiredoption);
      options.splice(index, 1);
    } else {
      options.push(REQUIRED_OPTION);
    }
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            options: { $set: options }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateOpenTextAnswerFormat = (value, validateGibberish, options) => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            expectedAnswerType: { $set: value },
            options: { $set: options },
            validateGibberish: { $set: validateGibberish }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateValFromAnswerFormat = (value, index, type) => {
    let options = cloneDeep(this.obj.state.joiner.def.responseSet.options);
    if (type === 'reset') {
      options = [];
    } else {
      if (index >= 0) {
        options[index].limit = value;
      } else {
        options.push({ name: OPTION_TYPE.limitoption, type: OPTION_TYPE.limitoption, limit: value, limitType: type });
      }
    }
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            options: { $set: options }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateSharedResponseOption = (type, value) => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            sharedResponseOption: {
              [type]: { $set: value }
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateShareVoteChoice = value => {
    this.updateSharedResponseOption('type', value);
  };

  updateShareVoteMaxExposure = value => {
    this.updateSharedResponseOption('maxExposures', value);
  };

  updateShareVoteMaxResp = value => {
    this.updateSharedResponseOption('maxResponses', value);
  };

  updateShareVoteMinResp = value => {
    this.updateSharedResponseOption('minResponses', value);
  };

  updateVideoCaptureEnabled = value => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            videoCaptureEnabled: { $set: value }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  toggleRandomOptions = () => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            randomOptions: { $set: !this.obj.state.joiner.def.responseSet.randomOptions }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  toggleMinRanking = () => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            validate: { $set: !this.obj.state.joiner.def.responseSet.validate }
          }
        }
      }
    });
    if (!state.joiner.def.responseSet.validate) {
      const newState = update(state, {
        joiner: {
          def: {
            responseSet: {
              minAnswers: { $set: '' }
            }
          }
        }
      });
      this.obj.setState(newState);
    } else {
      this.obj.setState(state);
    }
  };

  updateMinChoices = val => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            minAnswers: { $set: val }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateMaxParticipants = val => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            maxParticipants: { $set: val }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateMatrixType = type => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            matrixType: { $set: type }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateChoiceDisplay = (allowMultipleAnswers, displayType) => {
    const responseSet = cloneDeep(this.obj.state.joiner.def.responseSet);
    responseSet.allowMultipleAnswers = allowMultipleAnswers;
    const { options } = responseSet;
    const index = findIndex(options, o => o.type && o.type === OPTION_TYPE.displayoption);
    if (index === -1) {
      options.push({ name: OPTION_TYPE.displayoption, type: OPTION_TYPE.displayoption, displayType });
    } else {
      options[index].displayType = displayType;
    }
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: { $set: responseSet }
        }
      }
    });
    this.obj.setState(state);
  };

  updateChoiceQuestionValue = (value, index) => {
    const choices = cloneDeep(this.obj.state.joiner.def.responseSet.choices);
    choices[index] = value;
    this.updateChoiceQuestion(choices);
  };

  updateChoiceQuestion = choices => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            choices: { $set: choices }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateChoiceOtherOptionFormat = otherChoice => {
    const choices = cloneDeep(this.obj.state.joiner.def.responseSet.choices);
    const otherIndex = findIndex(choices, otherChoice);
    choices.splice(otherIndex, 1, otherChoice);
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            choices: { $set: choices }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateMediaForChoiceQuestion = (stim, index) => {
    // it is very clunky to use update 3 times in a row,
    // but I couldn't find a way to directly update the object in the array
    stim.type = STIM_TYPE.image;
    const { choices } = this.obj.state.joiner.def.responseSet;
    const choice = update(choices[index], {
      value: {
        abbreviatedValue: { $set: getAbbreviation(stim.media.title || stim.origCaption) },
        imageStim: { $set: stim },
        type: { $set: IMAGE_LABEL }
      }
    });
    const updatedChoices = update(choices, {
      $splice: [[index, 1, choice]]
    });
    this.updateChoiceQuestion(updatedChoices);
  };

  addOrRemoveChoiceToQuestion = (index, add) => {
    let choices = cloneDeep(this.obj.state.joiner.def.responseSet.choices);
    if (add) {
      choices.splice(index + 1, 0, {
        id: idUtils.getId(),
        value: {
          entryType: DEFAULT,
          type: STRING_LABEL
        }
      });
    } else {
      choices[index].value.disable = true;
    }

    // If no default choices exist, add one.
    if (choices.filter(choice => choice.value.entryType === DEFAULT && !choice.value.disable).length === 0) {
      choices.push(getDefaultChoice());
    }

    // Re-adjust indexes
    choices.forEach((choice, i) => (choice.index = i));

    this.updateChoiceQuestion(choices);
  };

  /*
   * Adds an IDK, NOTA, or OTHER choice. If the choice already exists, set disable to false.
   * If the choice does not exist, add it.
   */
  addOtherChoice = (entryType, value, language, viewLanguage) => {
    const choices = cloneDeep(this.obj.state.joiner.def.responseSet.choices);
    const index = findIndex(choices, choice => choice.value.entryType === entryType);
    if (index !== -1) {
      choices[index].value.disable = false;
    } else {
      const newChoice = {
        id: idUtils.getId(),
        index: choices.length,
        value: {
          common: true,
          entryType,
          otherInput: entryType === OTHER,
          otherInputOptions: [],
          type: STRING_LABEL,
          value
        }
      };
      setChoiceValues(language, viewLanguage, value, newChoice.value, false);
      choices.push(newChoice);
    }
    this.updateChoiceQuestion(choices);
  };

  updateOtherChoiceText = (entryType, value, language, viewLanguage) => {
    const choices = cloneDeep(this.obj.state.joiner.def.responseSet.choices);
    const index = findIndex(choices, choice => choice.value.entryType === entryType);
    if (viewLanguage === ENGLISH) {
      choices[index].value.origValue = value;
    }
    if (viewLanguage === language) {
      choices[index].value.value = value;
    }
    this.updateChoiceQuestion(choices);
  };

  removeOtherChoice = entryType => {
    const choices = cloneDeep(this.obj.state.joiner.def.responseSet.choices);
    const index = findIndex(choices, choice => choice.value.entryType === entryType);
    choices[index].value.disable = true;
    this.updateChoiceQuestion(choices);
  };

  updateMatrixRangeLabels = rangeLabels => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            entries: {
              rangeLabels: { $set: rangeLabels }
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  findReferencedQuestion = joiner => {
    const { options } = joiner.def.responseSet;
    if (!options || options.length < 1) {
      return false;
    }
    return options.filter(o => o.type === REFERENCED_QUESTION);
  };

  getReferencedJoiner = (joiners, option) => {
    const joinerById = joiners.filter(j => j.id === option[0].joinerId)[0];
    if (joinerById) {
      return joinerById;
    }
    return joiners.filter(j => j.researchPrompt === option[0].joinerName)[0]; // needed to resolve after import because joiner id's have changed
  };

  updateMatrixRowsAndOptions = (rows, bipolarRows, columns, options) => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            entries: {
              columnData: {
                columns: { $set: columns }
              },
              rows: { $set: rows },
              bipolarRows: { $set: bipolarRows }
            },
            options: { $set: options }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  onChooseAnswersChange = (joiners, joiner, value) => {
    const multipleChoiceQuestions =
      value === MATRIX_CHOICES.choiceAnswers
        ? joiners.filter(
            (j, index) =>
              joiner.displayIndex > index &&
              j.active &&
              !j.hidden &&
              !j.orphaned &&
              j.def &&
              j.def.responseSet.type === RESPONSE_SET_TYPE.multi &&
              j.def.responseSet.allowMultipleAnswers
          )
        : [];
    if (
      value === MATRIX_CHOICES.enterRows &&
      (joiner.def.responseSet.options.filter(o => o.type === REFERENCED_QUESTION).length > 0 ||
        joiner.def.responseSet.options.filter(o => o.type === BIPOLAR_OPTION.type).length > 0)
    ) {
      const options = joiner.def.responseSet.options.filter(
        o => o.type !== REFERENCED_QUESTION && o.type !== BIPOLAR_OPTION.type
      );
      const { rows, bipolarRows } = joiner.def.responseSet.entries;
      const { columns } = joiner.def.responseSet.entries.columnData;
      this.updateMatrixRowsAndOptions(rows, bipolarRows, columns, options);
    } else if (value === MATRIX_CHOICES.bipolar) {
      const { options } = joiner.def.responseSet;
      const index = options.indexOf(REFERENCED_QUESTION);
      options.splice(index, 1);
      options.push(BIPOLAR_OPTION);
      const { rows, bipolarRows } = joiner.def.responseSet.entries;
      const { columns } = joiner.def.responseSet.entries.columnData;
      this.updateMatrixRowsAndOptions(rows, bipolarRows, columns, options);
    }
    return multipleChoiceQuestions;
  };

  pickChoiceQuestion = (question, joiner) => {
    const rows = question.def.responseSet.choices
      .filter(c => !c.value.disable)
      .map((c, index) => {
        return {
          id: idUtils.getId(),
          index,
          value: c.value
        };
      });
    let { columns } = joiner.def.responseSet.entries.columnData;
    if (joiner.def.responseSet.type === RESPONSE_SET_TYPE.ranked) {
      columns = rows.map((r, index) => {
        return {
          id: idUtils.getId(),
          index,
          value: {
            entryType: DEFAULT,
            type: STRING_LABEL,
            abbreviatedValue: index + 1,
            value: index + 1
          }
        };
      });
    }
    const { options } = joiner.def.responseSet;
    const index = options.indexOf(BIPOLAR_OPTION);
    options.splice(index, 1);
    options.push({ type: REFERENCED_QUESTION, joinerId: question.id });
    const { bipolarRows } = joiner.def.responseSet.entries;
    this.updateMatrixRowsAndOptions(rows, bipolarRows, columns, options);
  };

  updateMatrixRows = rows => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            entries: {
              rows: { $set: rows }
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateMatrixBipolarRows = rows => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            entries: {
              bipolarRows: { $set: rows }
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateMatrixRowValue = (row, index) => {
    const rows = update(this.obj.state.joiner.def.responseSet.entries.rows, {
      $splice: [[index, 1, row]]
    });
    this.updateMatrixRows(rows);
  };

  updateMatrixBipolarRowValue = (row, index) => {
    const rows = update(this.obj.state.joiner.def.responseSet.entries.bipolarRows, {
      $splice: [[index, 1, row]]
    });
    this.updateMatrixBipolarRows(rows);
  };

  updateMediaForMatrixRow = (stim, index) => {
    stim.type = STIM_TYPE.image;
    const { rows } = this.obj.state.joiner.def.responseSet.entries;
    const row = update(rows[index], {
      value: {
        abbreviatedValue: { $set: getAbbreviation(stim.media.title || stim.origCaption) },
        imageStim: { $set: stim },
        type: { $set: IMAGE_LABEL }
      }
    });
    const updatedRows = update(rows, {
      $splice: [[index, 1, row]]
    });
    this.updateMatrixRows(updatedRows);
  };

  insertMatrixRow = index => {
    const rows = cloneDeep(this.obj.state.joiner.def.responseSet.entries.rows);
    rows.splice(index + 1, 0, {
      id: idUtils.getId(),
      value: {
        entryType: DEFAULT,
        type: STRING_LABEL
      }
    });

    // Re-adjust indexes
    rows.forEach((row, i) => (row.index = i));

    this.updateMatrixRows(rows);
  };

  removeMatrixRow = index => {
    const rows = cloneDeep(this.obj.state.joiner.def.responseSet.entries.rows);
    rows[index].value.disable = true;

    // If no rows, add one.
    if (rows.filter(row => !row.value.disable).length === 0) {
      rows.push(getDefaultChoice());
    }

    // Re-adjust indexes
    rows.forEach((row, i) => (row.index = i));

    this.updateMatrixRows(rows);
  };

  insertBipolarRow = index => {
    const rows = cloneDeep(this.obj.state.joiner.def.responseSet.entries.bipolarRows);
    rows.splice(index + 1, 0, {
      id: idUtils.getId(),
      value: {
        entryType: DEFAULT,
        type: STRING_LABEL
      }
    });

    // Re-adjust indexes
    rows.forEach((row, i) => (row.index = i));

    this.updateMatrixBipolarRows(rows);
  };

  removeBipolarRow = index => {
    const rows = cloneDeep(this.obj.state.joiner.def.responseSet.entries.bipolarRows);
    rows[index].value.disable = true;

    // If no rows, add one.
    if (rows.filter(row => !row.value.disable).length === 0) {
      rows.push(getDefaultChoice());
    }

    // Re-adjust indexes
    rows.forEach((row, i) => (row.index = i));

    this.updateMatrixBipolarRows(rows);
  };

  updateMatrixColumns = columns => {
    const state = update(this.obj.state, {
      joiner: {
        def: {
          responseSet: {
            entries: {
              columnData: {
                columns: { $set: columns }
              }
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateMatrixColumnValue = (column, index) => {
    const columns = update(this.obj.state.joiner.def.responseSet.entries.columnData.columns, {
      $splice: [[index, 1, column]]
    });
    this.updateMatrixColumns(columns);
  };

  insertMatrixColumn = index => {
    const columns = cloneDeep(this.obj.state.joiner.def.responseSet.entries.columnData.columns);
    columns.splice(index + 1, 0, {
      id: idUtils.getId(),
      value: {
        entryType: DEFAULT,
        type: STRING_LABEL
      }
    });

    // Re-adjust indexes
    columns.forEach((col, i) => (col.index = i));

    this.updateMatrixColumns(columns);
  };

  removeMatrixColumn = index => {
    const columns = cloneDeep(this.obj.state.joiner.def.responseSet.entries.columnData.columns);
    columns[index].value.disable = true;

    // If no columns, add one.
    if (columns.filter(column => !column.value.disable).length === 0) {
      columns.push(getDefaultChoice());
    }

    // Re-adjust indexes
    columns.forEach((col, i) => (col.index = i));

    this.updateMatrixColumns(columns);
  };

  updateRankedChoiceValue = (index, value, language, viewLanguage) => {
    const joiner = cloneDeep(this.obj.state.joiner);
    const row = joiner.def.responseSet.entries.rows[index];
    setChoiceValues(language, viewLanguage, value, row.value, false);
    this.obj.setState({
      joiner
    });
  };

  updateRankedChoiceAbbreviatedValue = (index, value) => {
    const joiner = cloneDeep(this.obj.state.joiner);
    joiner.def.responseSet.entries.rows[index].value.abbreviatedValue = value.trim();
    this.obj.setState({
      joiner
    });
  };

  updateRankedChoiceMedia = (index, stim) => {
    stim.type = STIM_TYPE.image;
    const joiner = cloneDeep(this.obj.state.joiner);
    const { value } = joiner.def.responseSet.entries.rows[index];
    value.imageStim = stim;
    value.type = IMAGE_LABEL;
    value.abbreviatedValue = getAbbreviation(stim.media.title || stim.origCaption);
    this.obj.setState({
      joiner
    });
  };

  addRankedChoice = index => {
    const joiner = cloneDeep(this.obj.state.joiner);
    const { entries } = joiner.def.responseSet;

    // Add the row, and update the indexes.
    entries.rows.splice(index, 0, {
      id: idUtils.getId(),
      value: {
        entryType: DEFAULT,
        type: STRING_LABEL
      }
    });
    entries.rows.forEach((row, i) => (row.index = i));

    // Add the column, and update the index-related fields.
    entries.columnData.columns.splice(index, 0, {
      id: idUtils.getId(),
      value: {
        entryType: DEFAULT,
        type: STRING_LABEL
      }
    });
    let activeCount = 1;
    entries.columnData.columns.forEach((col, i) => {
      col.index = i;
      if (!col.value.disable) {
        col.value.abbreviatedValue = activeCount;
        col.value.value = activeCount;
        col.value.origValue = activeCount;
        activeCount++;
      }
    });

    this.obj.setState({
      joiner
    });
  };

  removeRankedChoice = index => {
    const joiner = cloneDeep(this.obj.state.joiner);
    const { entries } = joiner.def.responseSet;

    // Remove from the columns by setting disable = true
    entries.columnData.columns[index].value.disable = true;
    if (entries.columnData.columns.filter(col => !col.value.disable).length === 0) {
      entries.columnData.columns.push(getDefaultChoice());
    }

    // Remove from the rows by setting disable = true
    entries.rows[index].value.disable = true;
    if (entries.rows.filter(row => !row.value.disable).length === 0) {
      entries.rows.push(getDefaultChoice());
    }

    // Update the index-related fields
    entries.rows.forEach((row, i) => (row.index = i));

    let activeCount = 1;
    entries.columnData.columns.forEach((col, i) => {
      col.index = i;
      if (!col.value.disable) {
        col.value.abbreviatedValue = activeCount;
        col.value.value = activeCount;
        col.value.origValue = activeCount;
        activeCount++;
      }
    });

    this.obj.setState({
      joiner
    });
  };

  updateConceptVisibility = conceptTitles => {
    const conceptVisibility = cloneDeep(this.obj.state.conceptVisibility);
    conceptVisibility.forEach(c => (c.hidden = conceptTitles.indexOf(c.title) === -1));
    const state = update(this.obj.state, {
      conceptVisibility: { $set: conceptVisibility },
      joiner: {
        hidden: { $set: conceptVisibility.filter(c => c.hidden).length === conceptVisibility.length }
      }
    });
    this.obj.setState(state);
  };

  toggleShowConceptMediaOnce = bool => {
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          showConceptMediaOnce: { $set: bool }
        }
      }
    });
    this.obj.setState(state);
  };

  updateNumPositions = value => {
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          numOfRotation: { $set: value }
        }
      }
    });
    this.obj.setState(state);
  };

  addConcept = (index, concept) => {
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          concepts: { $splice: [[index, 0, concept]] }
        }
      }
    });
    this.obj.setState(state);
  };

  updateConcept = (index, concept) => {
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          concepts: {
            [index]: { $merge: concept }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  removeConcept = index => {
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          concepts: { $splice: [[index, 1]] }
        }
      }
    });
    this.obj.setState(state);
  };

  addStimJoinerToConceptRotation = (joinerType, joiner) => {
    const updatedJoiner = update(joiner, {
      id: { $set: idUtils.getId() },
      conceptRotationId: { $set: this.obj.state.joiner.id },
      defaultNextJoinerId: { $set: this.obj.state.joiner.id }
    });
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: {
          [joinerType]: { $set: updatedJoiner }
        }
      }
    });
    this.obj.setState(state);
  };

  updateConceptRotationStimJoiner = (joinerType, joiner) => {
    if (this.obj.state.joiner.conceptRotation[joinerType]) {
      const state = update(this.obj.state, {
        joiner: {
          conceptRotation: {
            [joinerType]: {
              $set: joiner
            }
          }
        }
      });
      this.obj.setState(state);
    } else {
      this.addStimJoinerToConceptRotation(joinerType, joiner);
    }
  };

  updateConceptRotation = conceptRotation => {
    const state = update(this.obj.state, {
      joiner: {
        conceptRotation: { $set: conceptRotation }
      }
    });
    this.obj.setState(state);
  };

  //
  // Survey Rules begin
  //

  addRule = event => {
    const joiner = cloneDeep(this.obj.state.joiner);
    const { surveyRules = [] } = joiner;
    surveyRules.push({
      type: event.target.value,
      allOrAny: 'ANY',
      conditions: [
        {
          operator: '',
          values: []
        }
      ]
    });
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: { $set: surveyRules }
      }
    });
    this.obj.setState(state);
  };

  copyRule = (index, rule) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          $splice: [[index + 1, 0, rule]]
        }
      }
    });
    this.obj.setState(state);
  };

  deleteRule = index => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          $splice: [[index, 1]]
        }
      }
    });
    this.obj.setState(state);
  };

  setSegmentCategory = (ruleIndex, segCat) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            segmentCategory: { $set: segCat }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  setSegmentValue = (ruleIndex, value) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            segment: { $set: value }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  setVariable = (ruleIndex, variable) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            variable: { $set: variable }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  setVariableValue = (ruleIndex, value) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            value: { $set: value }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  // For SetFlag Rule
  setParticipantGroup = (ruleIndex, group) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            group: { $set: group }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  setAllOrAny = (ruleIndex, value) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            allOrAny: { $set: value }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  updateRuleCondition = (ruleIndex, conditionIndex, condition) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            conditions: {
              [conditionIndex]: { $set: condition }
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  setRuleConditionType = (ruleIndex, conditionIndex, type) => {
    const condition = {
      type,
      operator: '',
      values: []
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setRuleConditionJoinerId = (ruleIndex, conditionIndex, joinerId, parentId) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      operator: '',
      values: [],
      joinerId,
      parentId
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setRuleConditionMatrix = (ruleIndex, conditionIndex, rowId) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      rowId
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setRuleConditionMetadataLabel = (ruleIndex, conditionIndex, label) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      label
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setRuleConditionMetadataValue = (ruleIndex, conditionIndex, value) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      values: [value]
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setRuleConditionOperator = (ruleIndex, conditionIndex, operator) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      operator
    };
    if (condition.values.length > 1) {
      condition.values = [];
    }
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  updateRuleConditionValues = (ruleIndex, conditionIndex, value, singleConditionValue) => {
    const condition = cloneDeep(this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex]);
    if (singleConditionValue) {
      condition.values = [];
    }
    const foundIndex = condition.values.indexOf(value);
    if (foundIndex === -1) {
      condition.values.push(value);
    } else {
      condition.values.splice(foundIndex, 1);
    }
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setLookupValueConditionDataTableId = (ruleIndex, conditionIndex, dataTableId) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      dataTableId,
      column: ''
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setLookupValueConditionColumn = (ruleIndex, conditionIndex, column) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      column
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setRuleConditionCustomFunctionId = (ruleIndex, conditionIndex, functionId) => {
    const condition = {
      ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex],
      functionId,
      params: {}
    };
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  setRuleConditionCustomFunctionParam = (ruleIndex, conditionIndex, paramKey, joinerId) => {
    const condition = { ...this.obj.state.joiner.surveyRules[ruleIndex].conditions[conditionIndex] };
    condition.params[paramKey] = joinerId;
    this.updateRuleCondition(ruleIndex, conditionIndex, condition);
  };

  addRuleCondition = (ruleIndex, conditionIndex, condition) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            conditions: {
              $splice: [
                [
                  conditionIndex,
                  0,
                  condition || {
                    type: '',
                    operator: '',
                    values: []
                  }
                ]
              ]
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  removeRuleCondition = (ruleIndex, conditionIndex) => {
    const state = update(this.obj.state, {
      joiner: {
        surveyRules: {
          [ruleIndex]: {
            conditions: {
              $splice: [[conditionIndex, 1]]
            }
          }
        }
      }
    });
    this.obj.setState(state);
  };

  //
  // Import Questions
  //
  findChoices = inputChoices => {
    const choices = [];
    for (let i = 0; i < inputChoices.length; i++) {
      if (i > 0 && inputChoices[i] === '' && choices.length > 0) {
        break;
      }
      if (inputChoices[i]) {
        choices.push(inputChoices[i]);
      }
    }

    return choices;
  };

  importQuestion = (question, joinerType, viewLanguage, language) => {
    // this test different language settings:
    // 1. viewLanguage and language are both set to English
    // 2. viewLanguage and language are not the same, which indicates it's viewing English
    // 3. viewLanguage and language are the same, but it's not English
    const source = (language === ENGLISH && 1) || (viewLanguage !== language && 2) || 3;
    const joiner = cloneDeep(this.obj.state.joiner);
    joiner.researchPrompt = question[0].trim();
    if (viewLanguage === ENGLISH) {
      joiner.def.question.origPrompt = question[1];
      joiner.def.question.origRichContents = [{ insert: question[1] }];
      joiner.def.question.prompt = '';
      joiner.def.question.richContents = [{ insert: '' }];
    }
    if (viewLanguage === language) {
      joiner.def.question.prompt = question[1];
      joiner.def.question.richContents = [{ insert: question[1] }];
      if (language !== ENGLISH) {
        joiner.def.question.origPrompt = '';
        joiner.def.question.origRichContents = [{ insert: '' }];
      }
    }
    const tempArray = question.slice(2);
    const choiceArray = this.findChoices(tempArray);
    const rows =
      choiceArray.length > 0 ? choiceArray.map((a, i) => getDefaultChoice(a, i, source)) : [getDefaultChoice()];
    switch (joinerType) {
      case RESPONSE_SET_TYPE.open:
        break;
      case RESPONSE_SET_TYPE.matrix:
        const columnIndex = findIndex(tempArray, t => t === '' || t === null);
        const columnsArray = columnIndex > 0 ? tempArray.slice(columnIndex) : tempArray;
        const columns =
          columnsArray.length > 0
            ? this.findChoices(columnsArray).map((c, i) => getDefaultChoice(c, i, source))
            : [getDefaultChoice()];
        joiner.def.responseSet.entries.rows = rows;
        joiner.def.responseSet.entries.columnData.columns = columns;
        break;
      case RESPONSE_SET_TYPE.multi:
        const otherChoices = joiner.def.responseSet.choices.filter(
          choice => choice.value.entryType !== DEFAULT && !choice.value.disable
        );
        joiner.def.responseSet.choices = rows.concat(otherChoices);
        break;
      case RESPONSE_SET_TYPE.ranked:
        joiner.def.responseSet.entries.rows = rows;
        joiner.def.responseSet.entries.columnData.columns =
          choiceArray.length > 0 ? choiceArray.map((a, i) => getDefaultChoice(i + 1, i, source)) : [getDefaultChoice()];
        break;
      default:
        break;
    }
    // UI-only flag for the joiner editor
    joiner.importedTimeStamp = Date.now();

    this.updateJoiner(joiner);
  };
}

export default JoinerUpdateHandlers;
