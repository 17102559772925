import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { Input } from 'reactstrap';
import { saveRDConfig } from '../../../../../util/researchDashboardUtil';
import { InvokeModal } from 'webapp-common';

import './CustomNetsModal.css';

function buildTableHead(matrixColumns) {
  return (
    <thead>
      <tr>
        <th />
        {matrixColumns.map(col => (
          <th title={col.value.value}>{col.value.value}</th>
        ))}
      </tr>
    </thead>
  );
}

export const CustomNetsModal = props => {
  const { matrixColumns, questionDefId, rdConfig, toggle } = props;

  const [netDetails, setNetDetails] = useState({});

  useEffect(() => {
    setNetDetails(props.netDetails);
  }, []);

  function updateNetDetails(colId, position) {
    const cloned = cloneDeep(netDetails);

    if (!cloned[position]) {
      cloned[position] = [];
    }

    // index === -1 means add, else, remove
    const index = cloned[position].indexOf(colId);

    // Remove the ID from all nets
    for (let key in cloned) {
      cloned[key] = cloned[key].filter(id => id !== colId);
    }

    if (index === -1) {
      // Add
      cloned[position].push(colId);
    }

    setNetDetails(cloned);
  }

  function isSaveEnabled() {
    let str1 = '';
    Object.keys(props.netDetails)
      .sort()
      .forEach(key => {
        if (props.netDetails[key].length) {
          str1 += key + props.netDetails[key].sort().join('');
        }
      });
    let str2 = '';
    Object.keys(netDetails)
      .sort()
      .forEach(key => {
        if (netDetails[key].length) {
          str2 += key + netDetails[key].sort().join('');
        }
      });
    return str1 !== str2;
  }

  function saveConfig() {
    const cloned = cloneDeep(rdConfig);
    const { questionsConfigMap } = cloned.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    questionsConfigMap[questionDefId].netDetails = netDetails;
    saveRDConfig(cloned);
    toggle();
  }

  function buildTableBody() {
    const rows = [];
    ['top', 'middle', 'bottom'].forEach(position => {
      const cells = [<td>{intl.get(`app.${position}`)}</td>];
      matrixColumns.forEach(col => {
        const checked = netDetails[position] && netDetails[position].indexOf(col.id) !== -1;
        cells.push(
          <td>
            <Input type="checkbox" checked={checked} onChange={() => updateNetDetails(col.id, position)} />
          </td>
        );
      });
      rows.push(<tr>{cells}</tr>);
    });
    return <tbody>{rows}</tbody>;
  }

  return (
    <InvokeModal
      showModal={true}
      className="custom-nets-modal"
      toggle={toggle}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      save={saveConfig}
      enableSave={isSaveEnabled()}
    >
      <table>
        {buildTableHead(matrixColumns)}
        {buildTableBody()}
      </table>
    </InvokeModal>
  );
};
