import React from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { accessHandler, NavTab } from 'webapp-common';
import appConfig from '../../../appConfig';
import { DownloadReportsPage } from '../reportsPage/dowloadReportsPage/DownloadReportsPage';
import CustomReportsPage from '../reportsPage/customReportsPage';

const DOWNLOAD_TAB = 'download';
const CUSTOM_TAB = 'custom';

export const ReportsHomePage = props => {
  const { sessionUser, hasReportsManage, hasReportsView, isCustomReportsEnabled } = props;

  const { tab: activeTab = DOWNLOAD_TAB } = useParams();

  function getUrl(tab) {
    return `${appConfig.reportsPagePath}/${tab}`;
  }

  if (activeTab === CUSTOM_TAB && isCustomReportsEnabled === false) {
    return <accessHandler.AccessDenied />;
  }

  return (
    <section className="app-body">
      <section className="left-nav">
        {hasReportsView && (
          <NavTab
            icon="fa-download"
            iconStyle="solid"
            label={intl.get('app.title.download')}
            active={activeTab === DOWNLOAD_TAB}
            to={getUrl(DOWNLOAD_TAB)}
          />
        )}
        {hasReportsManage && sessionUser && sessionUser.clientId && isCustomReportsEnabled && (
          <NavTab
            icon="fa-tools"
            iconStyle="solid"
            label={intl.get('app.title.custom')}
            active={activeTab === CUSTOM_TAB}
            to={getUrl(CUSTOM_TAB)}
          />
        )}
      </section>
      <div className="body-container l2-bg">
        {activeTab === DOWNLOAD_TAB && <DownloadReportsPage hasReportsView={hasReportsView} />}
        {sessionUser?.clientId && activeTab === CUSTOM_TAB && isCustomReportsEnabled && (
          <CustomReportsPage hasReportsManage={hasReportsManage} isCustomReportsEnabled={isCustomReportsEnabled} />
        )}
      </div>
    </section>
  );
};

export default ReportsHomePage;
