import { del, get, post } from '../util/request';

// Create metadata collection step 1
async function createMetadataCollection({ projectId, storeId, collectionName, dataFile }) {
  const params = {
    storeId,
    collectionName,
    dataFile,
    useType: 'CSV'
  };
  return post('/a/binapi/uploadDefAndData')(params).then(
    res => (res.body && { projectId, storeId, newMetadataCollection: res.body }) || {}
  );
}

// Replace metadata collection step 1
async function setMetadataCollection({ projectId, storeId, collectionId, file }) {
  const params = {
    storeId,
    collectionId,
    file,
    useType: 'CSV'
  };
  return post(`/a/binapi/store/${storeId}/collection/${collectionId}/collectionData`)(params).then(
    res => (res.body && { projectId, storeId, newMetadataCollection: res.body }) || {}
  );
}

// Create/replace metadata collection step 2
async function saveMetadataJob({ projectId, job }) {
  return post('/a/api/saveMetadataJob')(job).then(() => ({
    projectId,
    storeId: job.metadataStoreId,
    collectionId: job.metadataCollectionId,
    collectionName: job.collectionName
  }));
}

async function deleteMetadataCollection({ projectId, job }) {
  return del(`/a/api/deleteMetadataCollection/${job.collection.id}`)({}).then(() => ({
    projectId,
    storeId: job.collection.storeId,
    collectionId: job.collection.id,
    collectionName: job.collection.name
  }));
}

async function fetchParticipantLists({ storeId, projectId }) {
  const params = { storeId };
  return get('/a/api/getMetadataCollections')(params).then(
    res => (res.body && res.body.payload && { projectId, participantLists: res.body.payload }) || {}
  );
}

async function fetchParticipantCollectionJobs(opts) {
  const params =
    (opts && {
      pageNumber: opts.pageNumber || 1,
      pageSize: opts.pageSize || 10,
      sortBy: opts.sortBy || 'modifiedDate',
      sortOrder: opts.sortOrder || 'desc',
      storeId: opts.storeId
    }) ||
    {};
  return get('/a/api/participantCollectionJobs')(params).then(
    res =>
      (res.body && res.body.payload && { projectId: opts.projectId, participantCollectionJobs: res.body.payload }) || {}
  );
}

// Used to ping the status of one or more participant collection jobs.
async function pingParticipantCollectionJobs({ projectId, jobIds }) {
  return post('/a/api/participantCollectionJobsByJobIds')(jobIds).then(
    res => (res.body && res.body.payload && { projectId, jobs: res.body.payload.content }) || {}
  );
}

export {
  createMetadataCollection,
  saveMetadataJob,
  setMetadataCollection,
  deleteMetadataCollection,
  fetchParticipantLists,
  fetchParticipantCollectionJobs,
  pingParticipantCollectionJobs
};
