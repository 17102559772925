import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Input } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { useFetchProdegeQuestions } from '../../../customHooks/useFetchProdegeQuestions';
import { saveProdegeQuestion } from '../../../api/prodegeApi';
import { Loader } from 'webapp-common';
import { Icons } from '../../../components/icons/Icons';
import { ProdegeCountrySelector } from '../../../components/prodege/ProdegeCountrySelector';

import './ProdegeConfig.css';

const reducer = (state, payload) => ({ ...state, ...payload });

export const ProdegeConfig = () => {
  const [state, setState] = useReducer(reducer, {
    selectedCountry: 1,
    questions: [],
    expanded: {}
  });

  const { isFetching } = useFetchProdegeQuestions({
    countryId: state.selectedCountry,
    enabledOnly: false,
    onSuccess: questions => setState({ questions })
  });

  function selectCountry(e) {
    setState({ selectedCountry: e.target.value });
  }

  function toggleQuestion(index) {
    const questions = cloneDeep(state.questions);
    const q = questions[index];
    q.enabled = !q.enabled;
    saveProdegeQuestion(q);
    setState({ questions });
  }

  function expand(i, bool) {
    setState({
      expanded: {
        ...state.expanded,
        [i]: bool
      }
    });
  }

  function getQuestionRows() {
    const rows = [];
    state.questions.forEach((q, i) => {
      if (!q.deleted_question) {
        rows.push(
          <div className="question-row">
            <div>
              <Input type="checkbox" checked={q.enabled} onChange={() => toggleQuestion(i)} />
            </div>
            <div className={q.options.length === 0 ? 'disabled' : ''}>
              {state.expanded[i] ? (
                <Icons.CaretDownIcon onClick={() => expand(i, false)} />
              ) : (
                <Icons.CaretRightIcon onClick={() => expand(i, true)} />
              )}
            </div>
            <div>{i + 1}.</div>
            <div>{q.question_text}</div>
            <div>{q.question_type}</div>
          </div>
        );
      }
      if (q.options.length !== 0) {
        const className = classnames({
          'question-options-row': true,
          collapsibleY: true,
          expandedY: state.expanded[i]
        });
        rows.push(
          <div className={className}>
            <ul>
              {q.options.map(option => (
                <li>{option.option_text}</li>
              ))}
            </ul>
          </div>
        );
      }
    });

    return rows;
  }

  return (
    <div className="prodege-config">
      {isFetching && <Loader fullScreen spinner />}
      <div>{intl.get('app.prodege.config.selectQuestions')}</div>
      <ProdegeCountrySelector
        style={{ marginTop: '1rem' }}
        selectedCountry={state.selectedCountry}
        onCountrySelect={selectCountry}
      />
      <div className="prodege-questions">{getQuestionRows()}</div>
    </div>
  );
};
