import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { Switch } from 'antd';
import { securityLevel } from '../../../../../util/sessionUtil';

import './WatermarkSettings.css';

export const WatermarkSettings = props => {
  const { type, enabled, readOnly, setWatermarkProperty } = props;
  const enabledField = (type === 'video' && 'enableVideoWatermark') || (type === 'image' && 'enableImageWatermark');
  const securityLevelField = (type === 'video' && 'videoSecurityLevel') || (type === 'image' && 'imageSecurityLevel');
  return (
    <>
      <div>
        <Switch checked={enabled} onChange={bool => setWatermarkProperty(enabledField, bool)} disabled={readOnly} />
      </div>
      {enabled && (
        <div className="watermark">
          <div>{intl.get('app.security').toLowerCase()}:</div>
          <Input
            type="select"
            value={props.securityLevel ?? securityLevel.STANDARD}
            disabled={readOnly}
            onChange={e => setWatermarkProperty(securityLevelField, e.target.value)}
          >
            <option value={securityLevel.LOW}>{intl.get('app.securityLevel.low')}</option>
            <option value={securityLevel.STANDARD}>{intl.get('app.securityLevel.standard')}</option>
            <option value={securityLevel.ELEVATED}>{intl.get('app.securityLevel.elevated')}</option>
            <option value={securityLevel.HIGH}>{intl.get('app.securityLevel.high')}</option>
          </Input>
        </div>
      )}
    </>
  );
};
