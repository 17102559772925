import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import intl from 'react-intl-universal';
import { find, get } from 'lodash';
import moment from 'moment';
import { Button } from 'reactstrap';
import {
  rdParticipantAnswersSubscribeActions,
  openChatWindowAction,
  toggleFavoriteParticipantAction,
  terminateParticipantAction,
  unTerminateParticipantAction
} from '../../../store/redux/actions/researchDashboardActions';
import {
  filtersAndParticipantsSubscribeActions,
  setParticipantGroupActions
} from '../../../store/redux/actions/filtersAndParticipantsActions';
import { getRDChats, getRDConfig } from '../../../store/redux/selectors/researchDashboardSelector';
import { useSessionUserSelector } from '../../../customHooks/reduxHelper';
import { TerminateParticipantModal } from './TerminateParticipantModal';
import ParticipantMetadata from './participantMetadata';
import { ParticipantResponses } from './ParticipantResponses';
import chatUtil from '../../../components/chat/chatUtil';
import { ENGLISH } from '../../../util/joinerUtil';
import { PARTICIPANT_FLAG_COLORS } from '../../../util/researchDashboardUtil';
import { TranslateLabel } from '../../surveyBuilder/editor/editForms/edit/TranslateLabel';
import { ChatIcon, StarIcon, FlagsDropdown, FlagIcon } from '../../../components/icons/Icons';

import './RDParticipantDetails.css';

function getTimeStamp(time) {
  return time ? moment(time).format('HH:mm:ss') : '-';
}

const reducer = (state, payload) => ({ ...state, ...payload });
const DEFAULT = 'gray';

export const RDParticipantDetails = props => {
  const { sessionId, participant, language } = props;
  const {
    id: participantId,
    nickname,
    surveyStartTime,
    lastAnsweredTime,
    favorite,
    enrolleeStatus,
    participantGroup
  } = participant;
  const isTerminated = enrolleeStatus === 'Terminated';
  const isInvited = enrolleeStatus === 'Invited';

  const userId = useSessionUserSelector().sessionUser.userID;
  const chats = useSelector(state => getRDChats(state, sessionId), shallowEqual);
  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  const viewLanguage = get(rdConfig, 'configs.questionsConfig.globalQuestionConfig.displayLanguage') || ENGLISH;
  const [state, setState] = useReducer(reducer, {
    showTerminateModal: false
  });

  const dispatch = useDispatch();

  useEffect(() => {
    rdParticipantAnswersSubscribe('subscribe');
    return () => {
      rdParticipantAnswersSubscribe('unsubscribe');
      filtersAndParticipantsSubscribe('unsubscribe');
    };
  }, []);

  /*
   * Due to a timing/caching issues on the back-end, we need to send a re-sub request
   * to get updated filtersAndParticipants data whenever favorite or participantGroup
   * are changed in the UI (in case there are filters using those as criteria). This
   * also subscribes on initial render.
   */
  useEffect(() => {
    filtersAndParticipantsSubscribe('subscribe');
  }, [favorite, participantGroup]);

  function rdParticipantAnswersSubscribe(subAction) {
    dispatch(
      rdParticipantAnswersSubscribeActions.request({
        subAction,
        sessionId,
        participantId
      })
    );
  }

  function filtersAndParticipantsSubscribe(subAction) {
    dispatch(filtersAndParticipantsSubscribeActions.request({ subAction, sessionId }));
  }

  function updateFavoriteParticipant() {
    dispatch(
      toggleFavoriteParticipantAction.request({
        sessionId,
        participantId,
        favorite
      })
    );
  }

  function terminateParticipant(reason) {
    dispatch(
      terminateParticipantAction.request({
        sessionId,
        participantId: participant.id,
        reason: (reason && reason.trim()) || 'NO_REASON'
      })
    );
  }

  function unTerminateParticipant() {
    dispatch(
      unTerminateParticipantAction.request({
        sessionId,
        participantId: participant.id,
        reason: ''
      })
    );
  }

  function openChatWindow() {
    const chatPayload = {
      chatTitle: nickname || participantId,
      participantId,
      researcherId: userId,
      sessionId
    };
    dispatch(openChatWindowAction.request(chatPayload));
  }

  const onFlagClick = group => {
    dispatch(setParticipantGroupActions.request({ sessionId, participantId, group }));
  };

  const genericChat = find(chats, chat => !chat.questionJoinerId && chat.participantIds.indexOf(participantId) > -1);
  const hasMessage = genericChat && genericChat.messages.length > 0;
  const chattable = chatUtil.isActiveParticipant(participant);
  const flagColor = participant.participantGroup ? PARTICIPANT_FLAG_COLORS[participant.participantGroup] : DEFAULT;

  return (
    <section className="rd-participant-details">
      <section className="top-row">
        <div>
          <div className="fw-600">{nickname ? intl.get('app.nickname') : intl.get('app.participantId')}</div>
          <div>{nickname || participantId}</div>
        </div>
        <div>
          <div className="fw-600">{intl.get('app.login')}</div>
          <div>{getTimeStamp(surveyStartTime)}</div>
        </div>
        <div>
          <div className="fw-600 nowrap">{intl.get('app.lastAnswered')}</div>
          <div>{getTimeStamp(lastAnsweredTime)}</div>
        </div>
        <div className="participant-controls">
          {isInvited ? (
            <ChatIcon className="chat-icon disabled" />
          ) : (
            <ChatIcon
              title={hasMessage || chattable ? '' : intl.get('app.noChat')}
              className={`chat-icon ${hasMessage ? 'existing-chat' : ''} ${hasMessage || chattable ? '' : 'disabled'}`}
              onClick={() => `${hasMessage || chattable ? openChatWindow() : ''}`}
            />
          )}
          {isInvited ? (
            <StarIcon className="favorite-icon disabled" />
          ) : (
            <StarIcon className={`favorite-icon ${favorite ? 'favorite' : ''}`} onClick={updateFavoriteParticipant} />
          )}
          <div style={{ display: 'inline-block', width: '2.5rem' }}>
            {isInvited ? (
              <FlagIcon className="flag-icon disabled" />
            ) : (
              <FlagsDropdown
                id={participant.id}
                flagClasses={flagColor}
                title={
                  flagColor === DEFAULT
                    ? intl.get('app.setFlags')
                    : intl.get(`app.participantFlag.${participant.participantGroup}.color`)
                }
                colorProp={PARTICIPANT_FLAG_COLORS}
                showBan={true}
                onFlagClick={onFlagClick}
              />
            )}
          </div>
          {isTerminated && <Button onClick={unTerminateParticipant}>{intl.get('app.unterminate')}</Button>}
          {!isTerminated && (
            <Button onClick={() => setState({ showTerminateModal: true })} disabled={isInvited}>
              {intl.get('app.terminate')}
            </Button>
          )}
        </div>
      </section>
      <ParticipantMetadata sessionId={sessionId} participant={participant} />
      {language !== ENGLISH && (
        <TranslateLabel
          setViewLanguage={props.setViewLanguage}
          language={props.language}
          viewLanguage={viewLanguage}
          sessionId={sessionId}
        />
      )}
      <ParticipantResponses sessionId={sessionId} participant={participant} viewLanguage={viewLanguage} />
      {state.showTerminateModal && (
        <TerminateParticipantModal
          participant={participant}
          toggle={() => setState({ showTerminateModal: false })}
          terminateParticipant={terminateParticipant}
        />
      )}
    </section>
  );
};
