import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { isEqual } from 'lodash';
import {
  EMOTION_CHART_COLORS,
  TOTAL,
  isFilterSelected,
  isEmotionChecked
} from '../sessionDetailsCommon/sessionDetailDataUtil';

const skipUpdate = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.emotionAvgData, nextProps.emotionAvgData) &&
    prevProps.filterList.length === nextProps.filterList.length &&
    prevProps.selectedFilters.length === nextProps.selectedFilters.length &&
    prevProps.selectedEmotions.length === nextProps.selectedEmotions.length &&
    prevProps.filteredParticipants.length === nextProps.filteredParticipants.length &&
    prevProps.totalParticipants === nextProps.totalParticipants &&
    prevProps.overlay === nextProps.overlay
  );
};

const getEmotionColorBox = (emotion, colorMap) => {
  return <div className="color-box" style={{ backgroundColor: colorMap.default[emotion] }} />;
};

export const EmotionFilters = memo(props => {
  const {
    selectedEmotions, // array of string
    setEmotion,
    emotionAvgData,
    filterList,
    selectedFilters,
    setFilter,
    filteredParticipants,
    overlay,
    colorMap,
    totalParticipants
  } = props;

  const getSelectedEmotions = (filter, selectedEmotions, overlay, index) => {
    return selectedEmotions.map(emotion => {
      const color = colorMap[filter.name] ? colorMap[filter.name][emotion] : colorMap.default[emotion];
      const average = emotionAvgData[filter.name][emotion];
      return (
        <td className="ps-5">
          <div className="color-box inline-block" style={{ backgroundColor: color }} />
          <label className="ms-3">{average}</label>
        </td>
      );
    });
  };

  const getFilterRow = (filter, index) => {
    const { name } = filter;
    const filterIsSelected = isFilterSelected(filter, selectedFilters);
    return (
      <tr key={name}>
        <td>
          <label className="text-ellipsis" title={name}>
            <Input
              type="checkbox"
              checked={selectedFilters.some(f => f.name === name)}
              onChange={e => setFilter(filter, e)}
            />
            {name}
          </label>
        </td>
        <td>
          <label className="ps-5">{name === TOTAL ? totalParticipants : filteredParticipants[name].length}</label>
        </td>
        {selectedEmotions && filterIsSelected && getSelectedEmotions(filter, selectedEmotions, overlay, index)}
      </tr>
    );
  };

  const getFilterRows = () => {
    return filterList.map((filter, i) => getFilterRow(filter, i));
  };

  const getEmotionColumns = () => {
    return Object.keys(EMOTION_CHART_COLORS).map(emotion => {
      if (isEmotionChecked(selectedEmotions, emotion)) {
        return <th className="ps-5">{intl.get(`app.${emotion}`)}</th>;
      }
    });
  };

  const getEmotionRows = () => {
    return Object.keys(EMOTION_CHART_COLORS).map(emotion => {
      return (
        <tr>
          <td>
            <label>
              <Input
                type="checkbox"
                checked={isEmotionChecked(selectedEmotions, emotion)}
                onChange={() => setEmotion(emotion)}
              />
              {intl.get(`app.${emotion}`)}
            </label>
          </td>
          <td>{getEmotionColorBox(emotion, colorMap)}</td>
        </tr>
      );
    });
  };

  return (
    <div className="emotion-filters my-5">
      <div className="emotion-filters-tables">
        <table>
          <thead>
            <tr>
              <th>{intl.get('app.emotions')}</th>
            </tr>
          </thead>
          <tbody>{getEmotionRows()}</tbody>
        </table>
        <table className="ms-5">
          <thead>
            <tr>
              <th>{intl.get('app.filters')}</th>
              <th className="ps-5">N</th>
              {getEmotionColumns()}
            </tr>
          </thead>
          <tbody>{getFilterRows()}</tbody>
        </table>
      </div>
    </div>
  );
}, skipUpdate);
