import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { SURVEY_RULE_TYPE } from '../../../../../util/joinerUtil';
import SurveyRule from './SurveyRule';

import './Rules.css';

const Rules = props => {
  const { sessionId, joiner, joiners, segmentCategories, surveyVariables, updateHandlers, tabs, readOnly, isScreener } =
    props;

  const rules = joiner.surveyRules || [];

  return (
    <div className={`${tabs.rules} survey-rules`}>
      <div>
        {rules.map((rule, index) => {
          return (
            <SurveyRule
              sessionId={sessionId}
              joiner={joiner}
              rule={rule}
              ruleIndex={index}
              joiners={joiners}
              segmentCategories={segmentCategories}
              surveyVariables={surveyVariables}
              updateHandlers={updateHandlers}
              key={index}
              readOnly={readOnly}
              isScreener={isScreener}
            />
          );
        })}
      </div>
      <div className="flex-row add-rule-selector-row">
        <label>{intl.get('app.surveyRules.addRule')}</label>
        <Input type="select" value="" disabled={readOnly} onChange={updateHandlers.addRule}>
          <option />
          <option value={SURVEY_RULE_TYPE.terminationRule}>{intl.get('app.surveyRules.termination')}</option>
          <option value={SURVEY_RULE_TYPE.includeQuestionRule}>{intl.get('app.surveyRules.questionVisibility')}</option>
          {isScreener && (
            <option value={SURVEY_RULE_TYPE.segmentationRule}>{intl.get('app.surveyRules.segmentation')}</option>
          )}
          <option value={SURVEY_RULE_TYPE.setVariableRule}>{intl.get('app.surveyRules.setVariable')}</option>
          {!isScreener && <option value={SURVEY_RULE_TYPE.setFlagRule}>{intl.get('app.surveyRules.setFlag')}</option>}
        </Input>
      </div>
    </div>
  );
};

export default Rules;
