import React, { memo, useEffect, useRef } from 'react';
import { auth } from '../../api/Auth';
import { mediaUtil } from '../../util/mediaUtil';

const getDefaultSetup = () => ({
  repeat: true,
  autostart: true,
  file: '',
  withCredentials: true,
  controls: true,
  height: 360,
  width: 640
});

const getDefaultOptions = () => ({
  hideHorizontalSlider: false,
  hidePlayback: false,
  hideRewind: true,
  hideVolume: false,
  hideCC: true,
  hideSettings: true,
  hidePiP: true,
  hideFullScreen: true
});

const skipUpdate = (prevProps, nextProps) => {
  return (
    prevProps.currentVideoTime === nextProps.currentVideoTime &&
    prevProps.media?._id === nextProps.media?._id &&
    prevProps.media?.accessUrl === nextProps.media?.accessUrl
  );
};

export const Video = memo(props => {
  const { currentVideoTime, media } = props;

  const { accessUrl } = media || {};
  const PLAYER_ID = media && `player+${media._id}`;

  const videoRef = useRef(null);

  const setCurrentVideoTime = () => {
    if (videoRef.current && props.setCurrentVideoTime) {
      const time = Math.floor(videoRef.current.getPosition());
      if (time !== currentVideoTime) {
        props.setCurrentVideoTime(time);
      }
    }
  };

  const jwplayer = window.jwplayer;

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (!accessUrl) {
      return;
    }

    const setup = Object.assign(getDefaultSetup(), props.setup || {});
    const options = Object.assign(getDefaultOptions(), props.options || {});

    setup.type = accessUrl.split('.').pop();
    setup.file = mediaUtil.getMediaUrl(media);
    jwplayer.key = 'lsjpmmGheGT4A/MmGb3hTDWp344MDDHsb5z2tB9fXNU=';
    videoRef.current = jwplayer(PLAYER_ID).setup(setup);

    videoRef.current.on('ready', function () {
      document.querySelector('.jw-display-container').style = 'position:absolute; top:36%; buttom:0; left:0; right:0;';
      document.querySelector('.jw-display-icon-rewind').style = 'visibility: hidden;';
      const jwControlBar = document.querySelector('.jw-controlbar');
      jwControlBar.querySelector('.jw-slider-horizontal').hidden = !!options.hideHorizontalSlider;
      jwControlBar.querySelector('.jw-icon-playback').hidden = !!options.hidePlayback;
      jwControlBar.querySelector('.jw-icon-rewind').hidden = !!options.hideRewind;
      jwControlBar.querySelector('.jw-icon-volume').hidden = !!options.hideVolume;
      jwControlBar.querySelector('.jw-icon-cc').hidden = !!options.hideCC;
      jwControlBar.querySelector('.jw-icon-settings').hidden = !!options.hideSettings;
      jwControlBar.querySelector('.jw-icon-pip').hidden = !!options.hidePiP;
      jwControlBar.querySelectorAll('.jw-icon-fullscreen').forEach(elem => (elem.hidden = !!options.hideFullScreen));
    });

    videoRef.current.on('pause', () => {
      const elem = document.querySelector('.jw-display');
      if (elem) {
        elem.style = 'display: block';
      }
    });

    videoRef.current.on('play', () => {
      document.querySelector('.jw-display').style = 'display: none';
    });

    videoRef.current.on('time', () => {
      setCurrentVideoTime();
    });

    videoRef.current.on('firstFrame', () => {
      videoRef.current.pause();
    });
  }, [accessUrl]);

  useEffect(() => {
    // Keep the user's session active
    auth.isAuthenticated(true);
    if (currentVideoTime >= 0 && videoRef.current?.seek && videoRef.current?.getPosition) {
      const time = Math.floor(videoRef.current.getPosition());
      if (currentVideoTime !== time) {
        videoRef.current.seek(currentVideoTime);
      }
    }
  }, [currentVideoTime]);

  return (
    <div className="video">
      <div id={PLAYER_ID} ref={videoRef} />
    </div>
  );
}, skipUpdate);
