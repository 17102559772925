import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { STIM_TYPE, UPDATE_HANDLER_TYPE } from '../../../../../../util/joinerUtil';
import VideoDialOptions from '../VideoDialOptions';
import { DetectionOptions } from '../DetectionOptions';
import { TranslateLabel } from '../../edit/TranslateLabel';

import './ConceptRotationOptions.css';

function doesContainVideoInConcepts(concepts) {
  return concepts.some(concept => concept.startJoiner.stim && concept.startJoiner.stim.type === STIM_TYPE.video);
}

class ConceptRotationOptions extends React.Component {
  toggleHidden = e => {
    this.props.updateHandlers.updateHidden(e.target.checked);
  };

  toggleShowConceptMediaOnce = e => {
    this.props.updateHandlers.toggleShowConceptMediaOnce(e.target.checked);
  };

  updateNumPositions = e => {
    this.props.updateHandlers.updateNumPositions(parseInt(e.target.value, 10));
  };

  getPositionOptions = numConcepts => {
    const options = [];
    options.push(<option value={0}>{intl.get('app.all')}</option>);
    if (numConcepts > 1) {
      for (let i = 1; i < numConcepts; i++) {
        options.push(<option value={i}>{i}</option>);
      }
    }
    return options;
  };

  updateVideoDialConfigCR = (...args) => {
    const { updateHandlers } = this.props;
    switch (args[args.length - 1]) {
      case UPDATE_HANDLER_TYPE.dialConfig:
        args.pop();
        updateHandlers.updateVideoDialConfigCR(...args);
        break;
      case UPDATE_HANDLER_TYPE.dialConfigField:
        args.pop();
        updateHandlers.updateVideoDialConfigFieldCR(...args);
        break;
      case UPDATE_HANDLER_TYPE.dialActionButton:
        args.pop();
        updateHandlers.updateVideoDialActionButtonCR(...args);
        break;
      case UPDATE_HANDLER_TYPE.addActionButton:
        args.pop();
        updateHandlers.addVideoDialActionButtonCR(...args);
        break;
      case UPDATE_HANDLER_TYPE.removeActionButton:
        args.pop();
        updateHandlers.removeVideoDialActionButtonCR(...args);
        break;
      default:
        return;
    }
  };

  render() {
    const { tabs, joiner, joiners, readOnly, isVideoDialEnabled, sessionId, language, viewLanguage, setViewLanguage } =
      this.props;

    const numConcepts = joiner.conceptRotation.concepts.length;
    const numPosition = joiner.conceptRotation.numOfRotation || 0;
    const videosExist = doesContainVideoInConcepts(joiner.conceptRotation.concepts);
    return (
      <div className={`concept-rotation-options ${tabs.options}`}>
        <label disabled={readOnly}>
          <Input type="checkbox" checked={joiner.hidden} onChange={this.toggleHidden} />{' '}
          {intl.get('app.hideConceptRotation')}
        </label>

        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={joiner.conceptRotation.showConceptMediaOnce}
            onChange={this.toggleShowConceptMediaOnce}
          />{' '}
          {intl.get('app.label.showConceptMediaOnce')}
        </label>
        <div className="flex-row position-options">
          <label>{intl.get('app.Rotate')}</label>
          <Input type="select" value={numPosition} disabled={readOnly} onChange={this.updateNumPositions}>
            {this.getPositionOptions(numConcepts)}
          </Input>
          <label>{intl.get('app.positions')}</label>
        </div>
        {videosExist && isVideoDialEnabled && (
          <VideoDialOptions
            readOnly={readOnly}
            joiner={joiner}
            joiners={joiners}
            isCRType={true}
            language={language}
            viewLanguage={viewLanguage}
            updateVideoDialConfig={(...args) => this.updateVideoDialConfigCR(...args)}
          />
        )}
        {videosExist && (
          <DetectionOptions updateHandlers={this.props.updateHandlers} readOnly={readOnly} crJoiner={joiner} />
        )}
        {videosExist && isVideoDialEnabled && (
          <TranslateLabel
            classes="float-right"
            setViewLanguage={setViewLanguage}
            language={language}
            viewLanguage={viewLanguage}
            sessionId={sessionId}
          />
        )}
      </div>
    );
  }
}

export default ConceptRotationOptions;
