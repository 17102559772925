import { toast as toastify } from 'react-toastify';

export const toast = {
  error: ({ text, position = 'top-right' }) => {
    toastify.error(text, {
      position
    });
  },

  success: ({ text, position = 'top-right' }) => {
    toastify.success(text, {
      position
    });
  }
};
