import React from 'react';

export const OneBar = props => {
  const { percent, count, backgroundColor } = props;
  const percentage = Math.round(parseFloat(percent));
  return (
    <svg className="one-net" style={{ marginTop: '0.25rem' }}>
      <rect x="0" y="0" width={`${percentage}`} height="24" fill={`${backgroundColor}`}></rect>
      <text x="120" y="55%" dominant-baseline="middle" text-anchor="end">
        {count}
      </text>
    </svg>
  );
};
