import { v4 as uuid } from 'uuid';
import BigNumber from 'bignumber.js';
import { range } from 'lodash';

const generateId = () => {
  const id1 = uuid().replace(/-/g, '');
  const number = new BigNumber(id1, 16);
  return number.toString(36);
};

const getId = () => generateId();

const getIds = (num = 1) => range(num).map(() => generateId());

export const idUtils = {
  getId,
  getIds
};
