import React from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual, debounce } from 'lodash';
import update from 'immutability-helper';
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { Checkbox, Select } from 'antd';
import { FlexRow, Loader } from 'webapp-common';
import { toast } from '../../../util/toast';

const Option = Select.Option;
const DAYS = 'days';
const MONTHS = 'months';
const YEARS = 'years';

class ClientAdminGeneralSettingsForm extends React.Component {
  state = {
    client: cloneDeep(this.props.client),
    saveClientSubmitted: false,
    disableSave: true
  };

  componentDidUpdate(prevProps) {
    const newState = {};
    const clientUpdated = this.clientHasChanged(this.props.client, prevProps.client);
    if (clientUpdated) {
      newState.client = cloneDeep(this.props.client);
    }
    if (Object.keys(newState).length) {
      this.setState(newState);
    }
    if (!isEqual(this.props.client, prevProps.client) && this.state.saveClientSubmitted) {
      this.setState({ disableSave: true, saveClientSubmitted: false });
    }
    if (!isEqual(this.state.client, this.props.client) && this.state.disableSave) {
      this.setState({ disableSave: false });
    }
  }

  clientHasChanged = (client, prevClient) => {
    return client && client.tenantName !== prevClient.tenantName;
  };

  updateClientName = name => {
    this.setState({
      client: {
        ...this.state.client,
        tenantName: name
      }
    });
  };

  handleSave = () => {
    this.props.saveClient(this.state.client);
    this.setState({ saveClientSubmitted: true });
  };

  checkAutoArchiveChanges = () => {
    return (
      this.state.client.autoArchiveAge === this.props.client.autoArchiveAge &&
      this.state.client.autoArchiveUnits === this.props.client.autoArchiveUnits &&
      this.state.client.autoArchiveEnabled === this.props.client.autoArchiveEnabled
    );
  };

  enableArchiving = e => {
    const client = update(this.state.client, {
      $merge: {
        autoArchiveEnabled: e.target.checked
      }
    });

    this.setState({ client }, () => {
      this.setState({ disableSave: this.verifyTimeValue(client) });
    });
  };

  verifyTimeValue = client => {
    const { autoArchiveAge, autoArchiveUnits, autoArchiveEnabled } = client;
    const failTest = true;
    if (this.props.client.autoArchiveEnabled && !autoArchiveEnabled) {
      return !failTest;
    }
    if (!autoArchiveUnits) {
      return failTest;
    }
    if (autoArchiveAge) {
      switch (autoArchiveUnits) {
        case DAYS:
          if (autoArchiveAge < 90) {
            toast.error({ text: intl.get('app.archive.daysLessThan90', {}) });
            return failTest;
          }
          break;
        case MONTHS:
          if (autoArchiveAge < 3) {
            toast.error({ text: intl.get('app.archive.monthsLessThan3', {}) });
            return failTest;
          }
          break;
        case YEARS:
          if (autoArchiveAge < 1) {
            toast.error({ text: intl.get('app.archive.yearsLessThan1', {}) });
            return failTest;
          }
          break;
        default:
      }
      return this.checkAutoArchiveChanges();
    }
    return failTest;
  };

  verifyTimeValueDebounced = debounce(client => {
    const disableSave = this.verifyTimeValue(client);
    this.setState({ disableSave });
  }, 500);

  onTimeUnitChange = unit => {
    const client = update(this.state.client, {
      $merge: {
        autoArchiveUnits: unit
      }
    });
    this.setState({ client }, () => {
      this.setState({
        disableSave: this.verifyTimeValue(this.state.client)
      });
    });
  };

  onTimeValueChange = e => {
    const timeValue = parseInt(e.target.value, 10);
    const client = update(this.state.client, {
      $merge: {
        autoArchiveAge: timeValue
      }
    });
    this.setState({ client }, () => this.verifyTimeValueDebounced(this.state.client));
  };

  updateWhitelistedDomains = whitelistedDomains => {
    this.setState({
      client: {
        ...this.state.client,
        whitelistedDomains
      }
    });
  };

  render() {
    const { isInternalSuperUser, saveClientInProgress, abilities } = this.props;
    const { client = {}, disableSave = true } = this.state;
    const saveDisabled = client.tenantName === this.props.client.tenantName && disableSave;

    if (!client.clientID) {
      return <Loader spinner fullScreen />;
    }

    return (
      <Form>
        <FormGroup row>
          <Label sm="2">{intl.get('app.domain')}</Label>
          <Col sm="4">
            <Input type="text" value={client.clientDomain} disabled />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm="2">{intl.get('app.name')}</Label>
          <Col sm="4">
            <Input
              type="text"
              value={client.tenantName}
              disabled={!isInternalSuperUser}
              onChange={e => this.updateClientName(e.target.value)}
            />
          </Col>
        </FormGroup>
        {abilities.hasProjectManage && (
          <FormGroup row>
            <Label sm="2">{intl.get('app.archiveData')}</Label>
            <Col sm="10">
              <div>
                <Checkbox checked={this.state.client.autoArchiveEnabled} onChange={this.enableArchiving}>
                  {intl.get('app.enableAutoArchiving')}
                </Checkbox>
              </div>
              <FlexRow alignItems="baseline" className="mt-3">
                <Label>{intl.get('app.archiveAllProjects')}</Label>
                <Input
                  type={'number'}
                  className="archive-input"
                  value={this.state.client.autoArchiveAge || ''}
                  onChange={this.onTimeValueChange}
                  disabled={!this.state.client.autoArchiveEnabled}
                />
                <Select
                  className="archive-select"
                  placeholder={intl.get('app.interval')}
                  value={this.state.client.autoArchiveUnits}
                  onChange={this.onTimeUnitChange}
                  disabled={!this.state.client.autoArchiveEnabled}
                >
                  <Option value={DAYS}>{intl.get('app.days')}</Option>
                  <Option value={MONTHS}>{intl.get('app.months')}</Option>
                  <Option value={YEARS}>{intl.get('app.years')}</Option>
                </Select>
                <Label> {intl.get('app.archive.afterMessage')} </Label>
              </FlexRow>
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label sm="2">{intl.get('app.questionType.webcontent')}</Label>
          <Col sm="6">
            <Label>{intl.get('app.whiteList.domains')}</Label>
            <Input
              type="textarea"
              value={this.state.client.whitelistedDomains}
              disabled={!isInternalSuperUser}
              onChange={e => this.updateWhitelistedDomains(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm="2" />
          <Col sm="4">
            <Button color="primary" disabled={saveDisabled} onClick={this.handleSave}>
              {intl.get('app.save')}
            </Button>
          </Col>
        </FormGroup>
        {saveClientInProgress && <Loader spinner fullScreen />}
      </Form>
    );
  }
}

export default ClientAdminGeneralSettingsForm;
