import { get, post } from '../util/request';

async function saveProjectPanelConfig(config) {
  return post('/a/api/projectPanelConfigs')(config).then(res => res.body.payload);
}

async function getProjectPanelConfigs({ projectId }) {
  return get(`/a/api/projectPanelConfigs/${projectId}`)().then(res => res.body.payload);
}

async function getProjectPanelConfig({ id }) {
  return get(`/a/api/projectPanelConfig/${id}`)().then(res => res.body.payload);
}

export { saveProjectPanelConfig, getProjectPanelConfigs, getProjectPanelConfig };
