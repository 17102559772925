import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { InvokeModal, Loader } from 'webapp-common';
import { sessionStates } from '../../../util/sessionUtil';

const findOpenSessions = sessions => sessions.filter(s => s.state !== sessionStates.CLOSED);

const displayOpenSessionNames = sessions =>
  sessions.map(session => (
    <p key={session.id} className="ps-4 mb-2 session">
      {session.name}
    </p>
  ));

export const ArchiveModal = props => {
  const {
    fetchSessions,
    projectId,
    projectName,
    onSave,
    toggle,
    sessionListRequested,
    sessions: { content }
  } = props;

  useEffect(() => {
    fetchSessions({ projectId: projectId, pageSize: -1 });
  }, []);

  if (sessionListRequested) {
    return <Loader spinner fullScreen />;
  }
  const openSessions = (content && content.length > 0 && findOpenSessions(content)) || [];
  return (
    <InvokeModal
      showModal={true}
      toggle={toggle}
      modalTitle={intl.get('app.archive')}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.archive')}
      enableSave={true}
      save={onSave}
      className="archive-modal"
    >
      <p className="ms-3">{intl.get('app.archive.project.confirm', { project: projectName })}</p>
      {openSessions.length > 0 && (
        <div className="ms-3 pt-3 warning">
          <h5>{intl.get('app.warning')}:</h5>
          <p className="mb-2">{intl.get('app.containOpenSessions', { count: openSessions.length })}:</p>
          {displayOpenSessionNames(openSessions)}
        </div>
      )}
    </InvokeModal>
  );
};
