import { connect } from 'react-redux';
import {
  createMetadataCollectionActions,
  saveMetadataJobActions,
  fetchParticipantCollectionJobsActions,
  pingParticipantCollectionJobsActions
} from '../../../store/redux/actions/fetchParticipantLists';
import { ProjectRecruitCollections as Component } from './ProjectRecruitCollections';

const mapDispatchToProps = dispatch => ({
  fetchParticipantCollectionJobs: params => dispatch(fetchParticipantCollectionJobsActions.request(params)),
  createMetadataCollection: params => dispatch(createMetadataCollectionActions.request(params)),
  saveMetadataJob: params => dispatch(saveMetadataJobActions.request(params)),
  pingParticipantCollectionJobs: payload => dispatch(pingParticipantCollectionJobsActions.request(payload))
});

export const ProjectRecruitCollections = connect(null, mapDispatchToProps)(Component);
