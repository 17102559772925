import { connect } from 'react-redux';
import {
  getRDConfig,
  getRDEnrollees,
  getRDSession,
  getRDVerbatimData
} from '../../../../../store/redux/selectors/researchDashboardSelector';
import { OpenQuestionDetails } from './OpenQuestionDetails';
import { saveProjectActions } from '../../../../../store/redux/actions/saveProject';

const mapDispatchToProps = dispatch => ({
  saveProject: project => dispatch(saveProjectActions.request(project))
});

const mapStateToProps = (state, props) => {
  const { sessionId, questionJoiner, projectId } = props;
  const projectStore = (state.projects !== undefined && state.projects[projectId]) || {};
  return {
    rdConfig: getRDConfig(state, sessionId),
    enrolleesInfo: getRDEnrollees(state, sessionId),
    verbatimData: getRDVerbatimData(state, sessionId, questionJoiner.def.id),
    session: getRDSession(state, sessionId),
    project: projectStore.projectDetails,
    projectDetailsRequested: projectStore.projectDetailsRequested
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenQuestionDetails);
