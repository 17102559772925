import React from 'react';
import intl from 'react-intl-universal';
import { chain } from 'lodash';
import classnames from 'classnames';
import {
  RESPONSE_SET_TYPE,
  STIM_TYPE,
  SURVEY_RULE_TYPE,
  getJoinerType,
  getJoinerTitle,
  ENGLISH
} from '../../../util/joinerUtil';
import { mediaUtil } from '../../../util/mediaUtil';
import webcontent_src from '../../../images/icons8-internet.png';
import { Icons } from '../../../components/icons/Icons';
import { ImageLightbox } from '../../../components/imageLightbox/ImageLightbox';

const MAX_WORD_LENGTH = 30;
const LONG_WORD_PATTERN = new RegExp(`(\\w+?){${MAX_WORD_LENGTH}}`);
const CONCEPT = 'concept';

function getMediaThumbnail({ id, type, mediaUrl, largerImageOnHover }) {
  switch (type) {
    case STIM_TYPE.image:
    case CONCEPT:
      return (
        <ImageLightbox id={id} className="media-thumbnail" src={mediaUrl} largerImageOnHover={largerImageOnHover} />
      );
    case STIM_TYPE.video:
      if (mediaUrl.toLowerCase().match(/\.thumb.gif$/)) {
        return <img className="media-thumbnail" src={mediaUrl} alt="" />;
      }
      return <Icons.VideoIcon />;
    case STIM_TYPE.webcontent:
      return <img src={webcontent_src} alt="" />;
    default:
      return '';
  }
}

function checkForReallyLongWord(str) {
  return LONG_WORD_PATTERN.test(str);
}

const getDeployStatusCell = info => {
  const rowData = info.row.original;
  const isChild = rowData.isConcept || !!rowData.joiner.containerId || !!rowData.joiner.conceptId;
  const classNames = {
    [rowData.deployStatus]: !!rowData.deployStatus,
    'child-joiner': isChild
  };
  return <div className={classnames(classNames)} />;
};

function hasVisibilityRule(rules) {
  if (!rules || rules.length < 1) {
    return false;
  }
  return rules.filter(r => r.type === SURVEY_RULE_TYPE.includeQuestionRule).length > 0;
}

function getDataCell(key) {
  return info => {
    const rowData = info.row.original;
    const value = info.getValue();
    let tooltip = value;
    let renderValue = value;
    const classes = {
      'has-long-word': checkForReallyLongWord(value)
    };
    if (key === 'prompt') {
      tooltip = rowData.toolTipForPrompt;
      if (rowData.joiner.hidden) {
        renderValue = (
          <>
            <span className="hidden-label">{intl.get('app.hidden')}</span>
            {value}
          </>
        );
      }
    } else if (key === 'percent' && hasVisibilityRule(rowData.joiner.surveyRules)) {
      renderValue = <i className="fas fa-eye-slash" />;
    }
    return (
      <span className={classnames(classes)} title={tooltip}>
        {renderValue}
      </span>
    );
  };
}

/*
 * Returns the thumbnail markup. Currently used internally as the formatter
 * for the thumbnail table cell and for the question details header.
 */
export const getThumbnail = ({ mediaUrl, question, largerImageOnHover }) => {
  const { type, joiner } = question;
  const { stim } = joiner;
  return getMediaThumbnail({
    id: joiner.id,
    type: stim?.type || type,
    mediaUrl,
    largerImageOnHover
  });
};

const getMediaCell = info => {
  const question = info.row.original;
  return getThumbnail({ mediaUrl: question.media, question, largerImageOnHover: 'left' });
};

export const getTableColumns = collapsed => {
  const columns = [
    {
      accessorKey: 'deployStatus',
      header: '',
      headerStyle: { width: '.35rem', padding: 0 },
      cellClassName: 'deploy-status-cell',
      cell: getDeployStatusCell
    },
    {
      accessorKey: 'number',
      header: '#',
      headerStyle: { width: '2rem' },
      cellClassName: 'question-number-cell'
    }
  ];
  if (!collapsed) {
    columns.push({
      accessorKey: 'ans',
      header: intl.get('app.ans'),
      headerStyle: { width: '3rem' },
      cellClassName: 'num-answers-cell',
      cell: getDataCell()
    });
    columns.push({
      accessorKey: 'percent',
      header: '%',
      headerStyle: { width: '2rem' },
      cellClassName: 'percent-cell',
      cell: getDataCell('percent')
    });
    columns.push({
      accessorKey: 'groupName',
      header: intl.get('app.group'),
      headerStyle: { width: '7rem' },
      cellClassName: 'text-truncate',
      cell: getDataCell()
    });
  }
  columns.push({
    accessorKey: 'title',
    header: intl.get('app.title'),
    headerStyle: { width: '8rem' },
    cellClassName: 'text-truncate',
    cell: getDataCell()
  });
  if (!collapsed) {
    columns.push({
      accessorKey: 'prompt',
      header: intl.get('app.surveyQuestionsAndContent'),
      cellClassName: 'text-truncate',
      cell: getDataCell('prompt')
    });
    columns.push({
      accessorKey: 'typeLabel',
      header: intl.get('app.type'),
      headerStyle: { width: '7rem' },
      cellClassName: 'text-truncate',
      cell: getDataCell()
    });
    columns.push({
      accessorKey: 'media',
      header: intl.get('app.media'),
      headerStyle: { width: '5rem' },
      cellClassName: 'media-thumbnail-cell',
      cell: getMediaCell
    });
  }
  return columns;
};

function getResponseRate(questionJoinerResponseRates, joiner) {
  return (
    questionJoinerResponseRates[joiner.id] || {
      ansCount: 0,
      ansPct: 0
    }
  );
}

function getLastConceptJoiner(concept, joinerIndex, questionJoiners) {
  if (!concept) {
    return false;
  }
  return concept.isLastConcept && joinerIndex + 1 === questionJoiners.length;
}

function getQuestionTitle(joiner, concept) {
  if (joiner.container) {
    return intl.get('app.page') + ' ' + joiner.questionNum;
  }
  return getJoinerTitle(joiner, concept);
}

function getJoinerPrompt(joiner, viewLanguage) {
  if (joiner.def) {
    return viewLanguage === ENGLISH ? joiner.def.question?.origPrompt : joiner.def.question?.prompt;
  }
  if (joiner.stim) {
    return joiner.stim.caption || joiner.stim.contents;
  }
  if (joiner.container) {
    return '';
  }
  return intl.get('app.questionType.conceptRotation');
}

function getPrompt(joiner, viewLanguage) {
  const prompt = getJoinerPrompt(joiner, viewLanguage);
  if (prompt && prompt.length > 200) {
    return prompt.substr(0, 200) + '...';
  }
  return prompt;
}

function getMediaUrl(questionJoiner) {
  const { stim } = questionJoiner;
  const { media } = stim || {};
  return media && mediaUtil.getMediaUrl(media, '');
}

function getConcept(concept, isLastConceptJoiner) {
  if (!concept) {
    return null;
  }
  return {
    ...concept,
    isLastConceptJoiner
  };
}

function getCurrentDeployStatus(currentJoinerId, joiner, currentDeployStatus) {
  if (!currentJoinerId) {
    // Not a live session, or nothing deployed.
    return currentDeployStatus;
  }
  if (
    joiner.id === currentJoinerId ||
    joiner.conceptRotationId === currentJoinerId ||
    joiner.containerId === currentJoinerId
  ) {
    return 'current';
  }
  if (currentDeployStatus === 'current' || currentDeployStatus === 'undeployed') {
    return 'undeployed';
  }
  return 'deployed';
}

/*
 * Builds the data for the question list table. The data is an array of 'question' objects
 * that contain the joiner and other useful computed fields, which are used not only by the
 * table, but are also passed to the QuestionDetails components.
 */
const mapJoinersToTableData = (
  questionJoiners,
  questionJoinerResponseRates,
  currentJoinerId,
  viewLanguage,
  currentDeployStatus,
  concept
) => {
  return chain(questionJoiners)
    .map((joiner, joinerIndex) => {
      currentDeployStatus = getCurrentDeployStatus(currentJoinerId, joiner, currentDeployStatus);
      const responseRate = getResponseRate(questionJoinerResponseRates, joiner);
      const type = getJoinerType(joiner);
      const isLastConceptJoiner = getLastConceptJoiner(concept, joinerIndex, questionJoiners);
      const selectable = !(
        joiner.container ||
        joiner.stimOnly ||
        joiner.thirdPartyStim ||
        type === CONCEPT ||
        type === RESPONSE_SET_TYPE.conceptRotation
      );
      const returnObj = {
        id: joiner.id,
        deployStatus: currentDeployStatus,
        number: joiner.container ? '' : joiner.questionNum,
        groupName: joiner.groupName || concept?.title || '',
        joiner,
        title: getQuestionTitle(joiner, concept),
        prompt: getPrompt(joiner, viewLanguage),
        toolTipForPrompt: getJoinerPrompt(joiner, viewLanguage),
        media: getMediaUrl(joiner),
        ans: joiner.container ? '' : responseRate.ansCount,
        percent: joiner.container ? '' : responseRate.ansPct,
        type,
        isLastConceptJoiner,
        concept: getConcept(concept, isLastConceptJoiner),
        typeLabel: type ? intl.get(`app.questionType.${type}`) : '',
        selectable
      };

      if (type === RESPONSE_SET_TYPE.conceptRotation) {
        const concepts = joiner.conceptRotation.concepts;
        return [returnObj].concat(
          chain(concepts)
            .map((concept, conceptIndex) => {
              const stim = concept.startJoiner?.stim ?? {};
              const responseRate = getResponseRate(questionJoinerResponseRates, concept);
              const conceptEntry = {
                id: stim.id,
                deployStatus: currentDeployStatus,
                number: '',
                groupName: '',
                joiner: { stim },
                title: concept.title,
                prompt: stim.media && stim.caption,
                toolTipForPrompt: CONCEPT,
                media: mediaUtil.getMediaUrl(stim.media, ''),
                ans: responseRate.ansCount,
                percent: responseRate.ansPct,
                type: CONCEPT,
                isConcept: true,
                deployed: joiner.id === currentJoinerId,
                isLastConceptJoiner: false,
                typeLabel: intl.get('app.concept'),
                selectable: false
              };
              const isLastConcept = concepts.length === conceptIndex + 1;
              return [conceptEntry].concat(
                mapJoinersToTableData(
                  concept.joiners,
                  questionJoinerResponseRates,
                  currentJoinerId,
                  viewLanguage,
                  currentDeployStatus,
                  {
                    conceptId: concept.id,
                    title: concept.title,
                    conceptRotationId: joiner.id,
                    isLastConcept
                  }
                )
              );
            })
            .flatten()
            .value()
        );
      }
      return [returnObj];
    })
    .flatten()
    .value();
};

export const getTableData = (questionJoiners, questionJoinerResponseRates, currentJoinerId, viewLanguage) => {
  if (!questionJoiners) {
    return [];
  }
  return mapJoinersToTableData(questionJoiners, questionJoinerResponseRates, currentJoinerId, viewLanguage);
};
