import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { VFGIcon } from './VFGIcon';
import { useOutsideClickDetectorById } from 'webapp-common';
import { PARTICIPANT_FLAG_COLORS } from '../../util/researchDashboardUtil';

import './FlagsDropdown.css';

export const CheckIcon = props => (
  <i
    className={`fas fa-check ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  />
);

export const DownloadIcon = props => (
  <i
    className={`fa fa-download ${props.className || ''}`}
    title={props.title || intl.get('app.download')}
    onClick={props.onClick}
  />
);

export const EyeIcon = props => (
  <i
    className={`fa fa-eye ${props.className || ''}`}
    title={props.title || intl.get('app.preview')}
    onClick={props.onClick}
  />
);

export const FunctionsIcon = props => (
  <i
    className={`fas fa-function ${props.className || ''}`}
    title={props.title || intl.get('app.functions')}
    onClick={props.onClick}
  />
);

export const ImagesIcon = props => (
  <i className={`far fa-images ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const LockIcon = props => (
  <i className={`fal fa-lock ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const BlackLockIcon = props => (
  <i
    className={`fas fa-lock ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  />
);

export const OpenLockIcon = props => (
  <i
    className={`far fa-lock-open ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  />
);

export const VideoIcon = props => (
  <i
    className={`fa fa-video ${props.className || ''}`}
    title={props.title}
    id={props.id || ''}
    onClick={props.onClick}
  />
);

export const SaveIcon = props => (
  <i
    className={`fa fa-save ${props.className || ''}`}
    title={props.title || intl.get('app.save')}
    onClick={props.onClick}
  />
);

export const TrashAltIcon = props => (
  <i
    className={`fa fa-trash-alt ${props.className || ''}`}
    title={props.title || intl.get('app.delete')}
    onClick={props.onClick}
  />
);

export const WordCloudIcon = props => (
  <i className={`fas fa-cloud ${props.className || ''}`} style={{ position: 'relative' }} onClick={props.onClick}>
    <div
      style={{
        color: 'white',
        cursor: props.onClick ? 'pointer' : 'default',
        fontFamily: 'arial',
        fontSize: '60%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      {intl.get('app.abc')}
    </div>
  </i>
);

export const FlagIcon = props => (
  <i className={`fas fa-flag ${props.className || ''}`} title={props.title} onClick={props.onClick} id={props.id} />
);

export const BanIcon = props => (
  <i
    className={`fas fa-ban ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  />
);

export const StarIcon = props => <i className={`fas fa-star ${props.className || ''}`} onClick={props.onClick} />;

export const ChatIcon = props => (
  <i title={props.title} className={`fas fa-comment ${props.className || ''}`} onClick={props.onClick} />
);

export const FlagsDropdown = props => {
  const colors = props.colorProp || PARTICIPANT_FLAG_COLORS;

  const [showFlags, setShowFlags] = useState(false);
  const onFlagClick = group => {
    setShowFlags(!showFlags);
    props.onFlagClick(group);
  };

  const handleOutsideFlagsSelectClick = () => setShowFlags(false);

  useOutsideClickDetectorById(props.id, handleOutsideFlagsSelectClick, null);

  return (
    <div className={showFlags ? 'flags-dropdown all-flags selected' : 'flags-dropdown unselected'}>
      <FlagIcon
        className={props.flagClasses}
        title={props.title}
        onClick={() => setShowFlags(!showFlags)}
        id={props.id}
      />
      {showFlags && (
        <div className="colored-flags">
          {Object.keys(colors).map(key => {
            return (
              <FlagIcon
                className={colors[key]}
                title={intl.get(`app.participantFlag.${key}.color`)}
                onClick={() => onFlagClick(key)}
              />
            );
          })}
          {props.showBan && <BanIcon title={intl.get('app.removeFlag')} onClick={() => onFlagClick(null)} />}
        </div>
      )}
    </div>
  );
};

export const CopyIcon = props => (
  <i className={`far fa-copy ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const PlusIcon = props => (
  <i className={`fas fa-plus ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const MinusIcon = props => (
  <i className={`fas fa-minus ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const CaretUpIcon = props => (
  <i className={`fas fa-caret-up ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const CaretRightIcon = props => (
  <i className={`fas fa-caret-right ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const CaretDownIcon = props => (
  <i className={`fas fa-caret-down ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const UserFriendsIcon = props => (
  <i className={`fas fa-user-friends ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const CogIcon = props => (
  <i className={`fas fa-cog ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const SmileIcon = props => (
  <i className={`far fa-smile ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const FrownIcon = props => (
  <i className={`fas fa-frown ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const EmotionsIcon = props => (
  <div title={props.title} onClick={props.onClick} style={{ display: 'inherit' }}>
    <span style={{ marginTop: '0.5rem', fontSize: '0.9rem' }}>
      <i className="fas fa-frown" />
    </span>
    <span style={{ marginLeft: '-0.35rem', fontSize: '0.9rem' }}>
      <i className="far fa-smile" />
    </span>
  </div>
);

export const ClustersIcon = props => (
  <i className={`fas fa-ball-pile ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const TableIcon = props => (
  <i className={`far fa-table ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const FilterIcon = props => (
  <i
    className={`fas fa-filter ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  />
);

export const ListIcon = props => (
  <i className={`fas fa-list ${props.className || ''}`} title={props.title} onClick={props.onClick}></i>
);

export const BlockIcon = props => (
  <i className={`fas fa-th ${props.className || ''}`} title={props.title} onClick={props.onClick}></i>
);

export const NotepadIcon = props => (
  <i
    className={`fal fa-clipboard-list-check ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  ></i>
);

export const UsersIcon = props => (
  <i className={`fas fa-users ${props.className || ''}`} title={props.title} onClick={props.onClick}></i>
);

export const UsersIconLight = props => (
  <i
    className={`fal fa-users ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  ></i>
);

export const ExcelIcon = props => (
  <i className="fas fa-file-excel fa-lg link" title={intl.get('app.outputType.excel')} onClick={props.onClick}></i>
);

export const PPTIcon = props => (
  <i
    className={`fas fa-file-powerpoint fa-lg link ${props.className || ''}`}
    title={intl.get('app.outputType.powerPoint')}
    onClick={props.onClick}
  ></i>
);

export const PollIcon = props => (
  <i className={`fas fa-poll ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const PollPeopleIcon = props => (
  <i
    className={`fal fa-poll-people ${props.className || ''}`}
    style={props.style}
    title={props.title}
    onClick={props.onClick}
  />
);

export const DataBaseIcon = props => (
  <i className={`fas fa-database ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const PencilIcon = props => (
  <i className={`fas fa-pencil ${props.className || ''}`} title={props.title} onClick={props.onClick} />
);

export const QuestionCircleIcon = props => (
  <i
    className={`fa fa-question-circle ${props.className || ''}`}
    title={props.title || intl.get('app.download')}
    onClick={props.onClick}
  />
);

export const FileIcon = props => (
  <i
    className={`far fa-file ${props.className || ''}`}
    title={props.title || intl.get('app.download')}
    onClick={props.onClick}
  />
);

export const PhoneIcon = props => (
  <i
    className={`fa fa-phone-square ${props.className || ''}`}
    title={props.title || intl.get('app.download')}
    onClick={props.onClick}
  />
);

export const Icons = {
  BanIcon,
  BlackLockIcon,
  BlockIcon,
  CheckIcon,
  CaretDownIcon,
  CaretRightIcon,
  CaretUpIcon,
  ChatIcon,
  ClustersIcon,
  CogIcon,
  CopyIcon,
  DataBaseIcon,
  DownloadIcon,
  EmotionsIcon,
  ExcelIcon,
  EyeIcon,
  FileIcon,
  FilterIcon,
  FlagIcon,
  FlagsDropdown,
  FrownIcon,
  FunctionsIcon,
  ImagesIcon,
  ListIcon,
  LockIcon,
  MinusIcon,
  NotepadIcon,
  OpenLockIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  PollIcon,
  PollPeopleIcon,
  PPTIcon,
  QuestionCircleIcon,
  SaveIcon,
  SmileIcon,
  StarIcon,
  TableIcon,
  TrashAltIcon,
  UserFriendsIcon,
  UsersIcon,
  UsersIconLight,
  VFGIcon,
  VideoIcon,
  WordCloudIcon
};
