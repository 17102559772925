import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { useQuery } from 'react-query';
import { cloneDeep, isEqual } from 'lodash';
import { Button, Input, Label } from 'reactstrap';
import { Loader } from 'webapp-common';
import { toast } from '../../../../util/toast';
import { usePrompt } from '../../../../customHooks/usePrompt';
import { fetchPanelConfigs } from '../../../../api/clientAdminApi';
import { updateDynataPanelConfigActions } from '../../../../store/redux/actions/sessionActions';
import { panelConfigUtil } from '../../../clientAdmin/panelConfigs/panelConfigUtil';
import { DynataSamplifyConfig } from './DynataSamplifyConfig';
import { GlobalPanelConfig } from './GlobalPanelConfig';
import { EditPanelConfig } from '../../../clientAdmin/panelConfigs/EditPanelConfig';

import './PanelConfig.css';

const { panelConfigType } = panelConfigUtil;
const RESERVED_PANEL_IDS = new Set(['samplify']);
const reducer = (state, payload) => ({ ...state, ...payload });

/**
 * This component supports three types of panel configs:
 *
 * 1) Global panel configs - User can select a global config to use and can set a quota and override URL params as necessary.
 * 2) Custom panel configs - Panel configs that are defined and used in this session only.
 * 3) Dynata Samplify panel config - A pre-defined/static panel config. We use the Dynata API to manage the config.
 *
 */
export const PanelConfig = props => {
  const { session, hasProjectManage, saveSession, isAutoEnroll, projectPanelConfig, saveProjectPanelConfig } = props;

  const dispatch = useDispatch();
  const readOnly = !hasProjectManage;
  const { panelConfigs = [] } = session || projectPanelConfig || {};

  const [state, setState] = useReducer(reducer, {
    globalPanelConfigs: [],
    panelConfigs: [],
    name: projectPanelConfig?.name || ''
  });

  const { isFetching: fetchGlobalPanelConfigsInProgress } = useQuery('fetch-configs', () => fetchPanelConfigs(), {
    refetchOnWindowFocus: false,
    onSuccess: data => setState({ globalPanelConfigs: data.content })
  });

  useEffect(() => {
    if (panelConfigs.length > 0) {
      setState({ panelConfigs });
    }
  }, [panelConfigs]);

  useEffect(() => {
    if (projectPanelConfig?.name) {
      setState({ name: projectPanelConfig.name });
    }
  }, [projectPanelConfig?.name]);

  usePrompt(intl.get('app.unsavedChangesWarning'), saveEnabled());

  function setName(name) {
    props.updatePanelConfigName && props.updatePanelConfigName(name);
    setState({ name });
  }

  // Used for global configs and Dynata Samplify
  function onPanelSelect(config) {
    if (config.type === panelConfigType.GLOBAL) {
      toggleGlobalPanelConfig(config);
    } else {
      toggleStaticPanelConfig(config);
    }
  }

  // A global panel override has been configured if it has a maxQuota,
  // or if a 'first' param value exists in any of the param arrays.
  function panelOverrideHasConfig(config) {
    const { maxQuota, globalParameters, completedParameters, terminateParameters, overQuotaParameters } = config;
    if (maxQuota > 0) {
      return true;
    }
    const allParams = [...globalParameters, ...completedParameters, ...terminateParameters, ...overQuotaParameters];
    return allParams.some(param => param.first);
  }

  /*
   * Only used for global configs. Does one of the following:
   * 1) If the config is not already in panelConfigs, add it.
   * 2) If the config has been cofigured, toggle the active flag.
   * 3) If the config has not been configured, remove it.
   */
  function toggleGlobalPanelConfig(config) {
    const panelConfigs = cloneDeep(state.panelConfigs);

    let panelConfig;
    let index;
    panelConfigs.forEach((pc, i) => {
      if (pc.type === panelConfigType.GLOBAL_REF && pc.id === config.id) {
        panelConfig = pc;
        index = i;
      }
    });

    if (!panelConfig) {
      // Add a blank override config
      panelConfigs.push({
        id: config.id,
        type: panelConfigType.GLOBAL_REF,
        active: true,
        globalParameters: config.globalParameters.map(param => ({ first: '', second: param.second })),
        completedParameters: config.completedParameters.map(param => ({ first: '', second: param.second })),
        terminateParameters: config.terminateParameters.map(param => ({ first: '', second: param.second })),
        overQuotaParameters: config.overQuotaParameters.map(param => ({ first: '', second: param.second }))
      });
    } else if (panelOverrideHasConfig(panelConfig)) {
      // Panel has been configured. Toggle the active flag.
      panelConfig.active = !panelConfig.active;
    } else {
      // Remove the config
      panelConfigs.splice(index, 1);
    }

    setState({ panelConfigs });
  }

  /*
   * Currently only used for Dynata Samplify. Does one of the following:
   * 1) If the config is not already in panelConfigs, add it.
   * 2) If the config has been cofigured, toggle the active flag.
   * 3) If the config has not been configured, remove it.
   */
  function toggleStaticPanelConfig(config) {
    const panelConfigs = cloneDeep(state.panelConfigs);

    let panelConfig;
    let index;
    panelConfigs.forEach((pc, i) => {
      if (pc.type === config.type) {
        panelConfig = pc;
        index = i;
      }
    });

    if (!panelConfig) {
      // Add the config
      config.active = true;
      panelConfigs.push(config);
    } else if (panelConfig.maxQuota || panelConfig.externalId) {
      // Panel has been configured. Toggle the active flag.
      panelConfig.active = !panelConfig.active;
    } else {
      // Remove the config
      panelConfigs.splice(index, 1);
    }

    setState({ panelConfigs });
  }

  // Used for global configs and Dynata Samplify
  function setQuota(config, quota) {
    const panelConfigs = cloneDeep(state.panelConfigs);
    const panelConfig =
      config.type === panelConfigType.GLOBAL
        ? panelConfigs.find(pc => pc.type === panelConfigType.GLOBAL_REF && pc.id === config.id)
        : panelConfigs.find(pc => pc.type === config.type);
    panelConfig.maxQuota = quota === '' ? null : quota;
    setState({ panelConfigs });
  }

  // Used for global configs and Dynata Samplify
  function setExpectedIR(config, expectedIR) {
    const panelConfigs = cloneDeep(state.panelConfigs);
    const panelConfig =
      config.type === panelConfigType.GLOBAL
        ? panelConfigs.find(pc => pc.type === panelConfigType.GLOBAL_REF && pc.id === config.id)
        : panelConfigs.find(pc => pc.type === config.type);
    panelConfig.expectedIR = expectedIR === '' ? null : expectedIR;
    setState({ panelConfigs });
  }

  // Only used for global config overrides
  function setOverrideValue(configId, paramGroup, second, first) {
    const panelConfigs = cloneDeep(state.panelConfigs);
    const config = panelConfigs.find(pc => pc.id === configId);
    const params = config[`${paramGroup}Parameters`];
    let param = params.find(p => p.second === second);
    if (!param) {
      param = { second };
      params.push(param);
    }
    param.first = first.replace(/\s+/g, '');
    setState({ panelConfigs });
  }

  // Handles clicking on the Other/Custom checkbox. If no custom configs exist, add a default config.
  // If custom configs exist, toggle the active flag on all of them.
  function onCustomClick() {
    const panelConfigs = cloneDeep(state.panelConfigs);
    const customConfigs = panelConfigs.filter(config => config.type === panelConfigType.CUSTOM);
    if (customConfigs.length === 0) {
      panelConfigs.push(panelConfigUtil.getDefaultPanelConfig());
    } else {
      panelConfigs.forEach(config => config.type === panelConfigType.CUSTOM && (config.active = !config.active));
    }
    setState({ panelConfigs });
  }

  // Only used for custom configs
  function addPanelConfig() {
    const panelConfigs = [...state.panelConfigs];
    panelConfigs.push(panelConfigUtil.getDefaultPanelConfig());
    setState({ panelConfigs });
  }

  // Only used for custom configs
  function setConfig(config, index) {
    const panelConfigs = [...state.panelConfigs];
    panelConfigs[index] = config;
    setState({ panelConfigs });
  }

  // Only used for custom configs
  function removeConfig(index) {
    const panelConfigs = [...state.panelConfigs];
    panelConfigs.splice(index, 1);
    setState({ panelConfigs });
  }

  // Currently only used for Dynata Samplify
  function setExternalId(config, externalId) {
    const panelConfigs = cloneDeep(state.panelConfigs);
    const panelConfig = panelConfigs.find(pc => pc.type === config.type);
    panelConfig.externalId = externalId.replace(/\s+/g, '');
    setState({ panelConfigs });
  }

  // Update the Dynata panel config and save the session
  function updateDynataPanelConfig(panelConfig) {
    dispatch(updateDynataPanelConfigActions.request({ sessionId: session.id, panelConfig }));
  }

  function save() {
    for (let config of state.panelConfigs) {
      if (config.type === panelConfigType.CUSTOM && config.active && RESERVED_PANEL_IDS.has(config.panelId)) {
        toast.error({ text: intl.get('app.reserved.panelId.warning', { panelId: config.panelId }) });
        return;
      }
    }

    if (!isAutoEnroll) {
      saveSession({ payload: { ...session, panelConfigs: state.panelConfigs } });
    } else {
      saveProjectPanelConfig({ ...projectPanelConfig, panelConfigs: state.panelConfigs, name: state.name.trim() });
    }
  }

  function getGlobalPanelConfigs() {
    return state.globalPanelConfigs.map(config => {
      const sessionConfig = state.panelConfigs.find(pc => pc.id === config.id && pc.active);
      return (
        <GlobalPanelConfig
          config={config}
          sessionConfig={sessionConfig}
          isSelected={!!sessionConfig}
          isScreener={session?.screener}
          readOnly={readOnly}
          onPanelSelect={onPanelSelect}
          setQuota={setQuota}
          setExpectedIR={setExpectedIR}
          setOverrideValue={setOverrideValue}
          key={config.id}
        />
      );
    });
  }

  function getCustomPanelConfigs() {
    const customConfigs = [];
    state.panelConfigs.forEach((config, index) => {
      if (config.active && config.type === panelConfigType.CUSTOM) {
        customConfigs.push(
          <div className="custom-panel-config ms-5">
            <EditPanelConfig
              config={config}
              showQuota={session?.screener}
              readOnly={readOnly}
              setConfig={cfg => setConfig(cfg, index)}
            />
            <Button className="btn-warn" disabled={readOnly} onClick={() => removeConfig(index)}>
              {intl.get('app.removePanel')}
            </Button>
          </div>
        );
      }
    });
    const hasCustomConfigs = !!customConfigs.length;
    return (
      <div className="panel">
        <div className="panel-name-row">
          <label>
            <Input type="checkbox" checked={hasCustomConfigs} disabled={readOnly} onChange={onCustomClick} />
            <span style={{ fontWeight: hasCustomConfigs && '600' }}>
              {intl.get('app.other')} / {intl.get('app.custom')} ...
            </span>
          </label>
        </div>
        {customConfigs}
        {hasCustomConfigs && (
          <Button className="ms-5" disabled={readOnly} onClick={addPanelConfig}>
            {intl.get('app.addPanel')}
          </Button>
        )}
      </div>
    );
  }

  function saveEnabled() {
    if (isAutoEnroll && !state.name.trim()) {
      return false;
    }
    const configsHaveChanged = !isEqual(panelConfigs, state.panelConfigs);
    const customConfigs = state.panelConfigs.filter(config => config.type === panelConfigType.CUSTOM && config.active);
    const allValidCustomConfigs = customConfigs.every(config => panelConfigUtil.isValid(config, false));
    if (isAutoEnroll) {
      const nameHasChanged = state.name.trim() !== projectPanelConfig.name;
      return allValidCustomConfigs && (configsHaveChanged || nameHasChanged);
    }
    return allValidCustomConfigs && configsHaveChanged;
  }

  if (fetchGlobalPanelConfigsInProgress) {
    return <Loader spinner fullScreen />;
  }

  return (
    <div className="panel-configs">
      {isAutoEnroll && (
        <>
          <Label>
            <strong>{intl.get('app.configuration.name')}:</strong>
          </Label>
          <Input
            style={{ width: 'auto', display: 'inline-block', marginLeft: '1rem' }}
            value={state.name}
            onChange={e => setName(e.target.value)}
          />
        </>
      )}
      <div style={{ fontWeight: '600', marginBottom: '.5rem' }}>{intl.get('app.selectPanels')}:</div>
      <DynataSamplifyConfig
        config={state.panelConfigs.find(cfg => cfg.type === panelConfigType.DYNATA)}
        isScreener={session?.screener}
        readOnly={readOnly}
        onPanelSelect={onPanelSelect}
        setQuota={setQuota}
        setExpectedIR={setExpectedIR}
        setExternalId={setExternalId}
        updateDynataPanelConfig={updateDynataPanelConfig}
      />
      {getGlobalPanelConfigs()}
      {getCustomPanelConfigs()}
      <Button color="primary" style={{ marginTop: '1rem' }} disabled={readOnly || !saveEnabled()} onClick={save}>
        {intl.get('app.save')}
      </Button>
    </div>
  );
};
