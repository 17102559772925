import React from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from 'webapp-common';
import { fetchSaveSessionAction } from '../../store/redux/actions/sessionActions';
import { useProjectPanelConfigsQuery } from '../../customHooks/useProjectPanelConfigQuery';
import { useSessionsQuery } from '../../customHooks/useSessionsQuery';
import { ParticipantEnrollmentSelectModal as Modal } from './ParticipantEnrollmentSelectModal';

export const ParticipantEnrollmentSelectModal = props => {
  const dispatch = useDispatch();

  const { projectId } = props.session;

  const projectPanelConfigsQuery = useProjectPanelConfigsQuery({ projectId });

  const sessionsQuery = useSessionsQuery({
    projectId,
    pageSize: -1
  });

  const screenerSessionsQuery = useSessionsQuery({
    projectId,
    screener: true,
    pageSize: -1
  });

  function saveSession(payload) {
    dispatch(fetchSaveSessionAction.request(payload));
  }

  const showLoader = sessionsQuery.isLoading || screenerSessionsQuery.isLoading || projectPanelConfigsQuery.isLoading;

  return (
    <>
      {showLoader && <Loader fullScreen spinner />}
      <Modal
        {...props}
        projectPanelConfigs={projectPanelConfigsQuery.data?.content}
        sessions={sessionsQuery.data?.content}
        screeners={screenerSessionsQuery.data?.content}
        saveSession={saveSession}
      />
    </>
  );
};
