import React, { useEffect, useReducer } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { cloneDeep, get } from 'lodash';
import { CopyToClipboard } from 'webapp-common';
import { ComboDropdown } from './ComboDropdown';
import { getRDComparativeViewAggregateData } from '../../../../store/redux/selectors/researchDashboardSelector';
import { rdConfigUtil } from '../../../../util/rdConfigUtil';
import { idUtils } from '../../../../util/Id';
import { surveyUtil } from '../../../../util/surveyUtil';
import { getNonPrefixedJoinerTitle } from '../../../../util/conceptRotationUtil';
import { QuestionAnswerPicker } from './QuestionAnswerPicker';
import { ConfirmModal } from '../../../../components/core/modal/ConfirmModal';
import { SigTesting } from '../../../../containers/project/reportsAndData/common/sigTesting/SigTesting';
import { ENGLISH, isBipolar } from '../../../../util/joinerUtil';

import './SurveyComparativeViews.css';

const reducer = (state, payload) => ({ ...state, ...payload });
const options = ['count', 'responseRate', 'countAndRate'];
const toSign = input => {
  switch (input) {
    case 'count':
      return 'N';
    case 'responseRate':
      return '%';
    default:
      return 'N & %';
  }
};

export const ComparativeView = props => {
  const { crJoiners, rdConfig, survey, selectedView = {}, saveView, viewIndex } = props;

  const [state, setState] = useReducer(reducer, {
    selectedView,
    rdConfig,
    crJoiners,
    currentQuestionAnswer: {},
    selectedJoiner: crJoiners.find(j => j.id === selectedView.conceptRotationJoinerId) || crJoiners[0],
    selectedConcepts: selectedView.conceptIds || [],
    selectedPositions: selectedView.positions || [],
    selectedDisplayOption: selectedView.displayOption,
    conceptsDropDownOpen: false,
    positionsDropDownOpen: false,
    showAddQuestionAnswersModal: false,
    showDeleteViewModal: false,
    viewTableData: [],
    language: surveyUtil.getConfiguredLanguage(survey),
    viewLanguage: props.viewLanguage
  });

  const comparativeViewAggregateData = useSelector(
    state => getRDComparativeViewAggregateData(state, selectedView.id),
    shallowEqual
  );

  const { conceptLetterMap = {} } = comparativeViewAggregateData || {};
  const reverseConceptLetterMap = Object.entries(conceptLetterMap).reduce((acc, entry) => {
    acc[entry[1]] = entry[0];
    return acc;
  }, {});

  useEffect(() => {
    setState({ crJoiners });
  }, [crJoiners]);

  const getConcepts = () => get(state.selectedJoiner, 'conceptRotation.concepts', []);

  const getJoiner = parentId => {
    const concept = getConcepts()[0] || {};
    return concept.joiners.find(j => j.parentId === parentId);
  };

  const getTableData = (incomingAggregateData, view) => {
    if (!incomingAggregateData) {
      return [];
    }
    const tableData = [];
    const { comparativeData, conceptLetterMap } = incomingAggregateData;
    if (!comparativeData || state.selectedView.conceptRotationJoinerId !== state.selectedJoiner.id) {
      return [];
    }
    const { questionAnswers, conceptIds } = view || state.selectedView;
    questionAnswers.map(qa => {
      const joiner = getJoiner(qa.parentId);
      const row = {
        concepts: [],
        title:
          (props.viewLanguage === ENGLISH ? qa.origTitle : qa.title) || qa.title || getNonPrefixedJoinerTitle(joiner),
        id: qa.id
      };
      const results = comparativeData[qa.id];
      if (results) {
        const concepts = getConcepts();
        concepts.map(c => {
          if (conceptIds.includes(c.id)) {
            const data = results[c.id] || {};
            row.concepts.push({
              title: c.title,
              conceptLetter: conceptLetterMap && conceptLetterMap[c.id],
              counts: data
            });
          }
        });
        tableData.push(row);
      }
    });
    return tableData;
  };

  useEffect(() => {
    if (selectedView.id) {
      if (comparativeViewAggregateData) {
        const viewTableData = getTableData(comparativeViewAggregateData, selectedView);
        setState({
          selectedView,
          viewTableData,
          selectedConcepts: selectedView.conceptIds || [],
          selectedPositions: selectedView.positions || [],
          showDeleteViewModal: false,
          showAddQuestionAnswersModal: false
        });
      } else {
        setState({
          selectedView,
          selectedConcepts: selectedView.conceptIds || [],
          selectedPositions: selectedView.positions || [],
          showDeleteViewModal: false,
          showAddQuestionAnswersModal: false
        });
      }
    }
  }, [selectedView]);

  useEffect(() => {
    if (comparativeViewAggregateData) {
      const viewTableData = getTableData(comparativeViewAggregateData);
      setState({ viewTableData });
    }
  }, [comparativeViewAggregateData]);

  useEffect(() => {
    if (props.viewLanguage !== state.viewLanguage) {
      const viewTableData = getTableData(comparativeViewAggregateData);
      setState({
        viewTableData,
        viewLanguage: props.viewLanguage
      });
    }
  }, [props.viewLanguage]);

  const setViewTitle = e => {
    setState({
      selectedView: {
        ...state.selectedView,
        title: e.target.value
      }
    });
  };

  const onTitleInputBlur = () => saveView(state.selectedView.title, 'title', viewIndex);

  const getConceptRotations = () => {
    return state.crJoiners.map(j => (
      <option value={j.researchPrompt} key={j.id} selected={j.researchPrompt === state.selectedJoiner.researchPrompt}>
        {j.researchPrompt}
      </option>
    ));
  };

  const onSetConceptRotation = e => {
    const selectedJoiner = state.crJoiners.find(j => j.researchPrompt === e.target.value);
    const previousViews = {
      [state.selectedView.conceptRotationJoinerId]: state.selectedView.questionAnswers
    };
    const view = cloneDeep(state.selectedView);
    view.conceptRotationJoinerId = selectedJoiner.id;
    view.questionAnswers = (state.previousViews && state.previousViews[selectedJoiner.id]) || [];
    props.setDefaultConceptsAndPositions(view, selectedJoiner);
    saveView(view, null, viewIndex);
    setState({ selectedJoiner, previousViews });
  };

  const onSetDisplayOptions = e => {
    setState({ selectedDisplayOption: e.target.value });
    saveView(e.target.value, 'displayOption', viewIndex);
  };

  const getDisplayOptions = () => {
    return options.map(o => (
      <option value={o} key={o} selected={o === state.selectedDisplayOption}>
        {intl.get('app.showDisplayOption', { option: toSign(o) })}
      </option>
    ));
  };

  const getConceptRotationsCount = () => {
    const totalRotations = state.selectedJoiner.conceptRotation.numOfRotation;
    if (totalRotations !== 0) {
      return totalRotations;
    }
    return state.selectedJoiner.conceptRotation.concepts.length;
  };

  const getSelectedPositions = () => {
    const conceptRotationsCount = getConceptRotationsCount();
    const selectedPositions = [];
    const positions = state.selectedPositions || [];
    positions.forEach(position => {
      if (position <= conceptRotationsCount) {
        selectedPositions.push(position);
      }
    });
    if (conceptRotationsCount === selectedPositions.length || state.selectedView.showAllPositions) {
      return intl.get('app.all');
    }
    return selectedPositions.join(', ');
  };

  const getSelectedConceptsText = () => {
    const conceptCount = getConceptRotationsCount();
    return conceptCount === state.selectedConcepts.length ? intl.get('app.all') : '';
  };

  const handleAllConceptsClick = e => {
    const selectedConcepts = [];
    if (e.target.checked) {
      const count = getConceptRotationsCount();
      for (let i = 0; i < count; i++) {
        selectedConcepts.push(state.selectedJoiner.conceptRotation.concepts[i].id);
      }
    }
    setState({
      selectedConcepts
    });
    saveView(selectedConcepts, 'conceptIds', viewIndex);
  };

  const handlePositionClick = (value, target) => {
    const selected = cloneDeep(state[target]);
    const index = selected.indexOf(value);
    if (index === -1) {
      selected.push(value);
    } else {
      selected.splice(index, 1);
    }
    const selectedView = cloneDeep(state.selectedView);
    selectedView.showAllPositions =
      target === 'selectedPositions'
        ? selected.length === getConceptRotationsCount()
        : state.selectedView.showAllPositions;
    setState({
      [target]: selected,
      selectedView
    });
    saveView(selected, target === 'selectedConcepts' ? 'conceptIds' : 'positions', viewIndex);
  };

  const getConceptOptions = () => {
    const concepts = getConcepts();
    const allSelected = concepts.length === state.selectedConcepts.length;
    const options = [];
    options.push(
      <label key="all">
        {intl.get('app.selectDeselect.all')}
        <Input type="checkbox" checked={allSelected} onChange={handleAllConceptsClick} />
      </label>
    );
    concepts.forEach(c => {
      const checked = allSelected || state.selectedConcepts.some(s => s === c.id);
      options.push(
        <label key={c.id}>
          {c.title}
          <Input type="checkbox" checked={checked} onChange={() => handlePositionClick(c.id, 'selectedConcepts')} />
        </label>
      );
    });
    return options;
  };

  const handleAllPositionsClick = e => {
    const selectedPositions = [];
    if (e.target.checked) {
      const conceptRotationsCount = getConceptRotationsCount();
      for (let i = 1; i <= conceptRotationsCount; i++) {
        selectedPositions.push(i);
      }
    }
    const view = {
      ...state.selectedView,
      positions: selectedPositions,
      showAllPositions: e.target.checked
    };
    setState({
      selectedView: view,
      selectedPositions
    });
    saveView(view, '', viewIndex);
  };

  const getPositionOptions = () => {
    const options = [];
    const conceptRotationsCount = getConceptRotationsCount();
    const allSelected = state.selectedView.showAllPositions || state.selectedPositions.length === conceptRotationsCount;
    options.push(
      <label key="all">
        {intl.get('app.selectDeselect.all')}
        <Input type="checkbox" checked={allSelected} onChange={handleAllPositionsClick} />
      </label>
    );
    for (let i = 1; i <= conceptRotationsCount; i++) {
      const checked = allSelected || state.selectedPositions.some(p => p === i);
      options.push(
        <label key={'position_' + i}>
          {`${intl.get('app.position')} ${i}`}
          <Input type="checkbox" checked={checked} onChange={() => handlePositionClick(i, 'selectedPositions')} />
        </label>
      );
    }
    return options;
  };

  const handleToggleClick = target => {
    setState({
      [target]: !state[target]
    });
  };

  const closeDropDown = target => {
    setState({
      [target]: false
    });
  };

  const getConceptColumnHeaderCells = () => {
    const cells = [];
    const concepts = getConcepts();
    selectedView.conceptIds.forEach(cid => {
      const concept = concepts.find(c => c.id === cid);
      if (concept) {
        cells.push(`"${concept.title}"`);
      }
    });
    return cells.join('\t');
  };

  const getCount = counts => {
    if (state.selectedDisplayOption === rdConfigUtil.questionConfigDisplayOptions.COUNT) {
      return typeof counts.count === 'number' ? counts.count : '-';
    }
    if (state.selectedDisplayOption === rdConfigUtil.questionConfigDisplayOptions.RESPONSE_RATE) {
      return typeof counts.percentage === 'number' ? `${counts.percentage}%` : '-%';
    }
    return typeof counts.count === 'number' ? `${counts.count} (${counts.percentage}%)` : '- (-%)';
  };

  const getQuestionTitleAndAnswers = questionAndAnswer => {
    if (questionAndAnswer.title) {
      return questionAndAnswer.title;
    }
    const { parentId, answer } = questionAndAnswer;
    const joiner = getJoiner(parentId);
    if (!joiner) {
      return null;
    }
    let title = '';
    const answers = [];
    const { responseSet } = joiner.def;
    if (responseSet.type === 'multi') {
      title = getNonPrefixedJoinerTitle(joiner);
      const answerIds = answer.choices;
      answerIds.forEach(answerId => {
        const choice = responseSet.choices.find(c => c.id === answerId);
        if (choice) {
          answers.push({ value: choice.value.value, origValue: choice.value.origValue });
        }
      });
    } else if (responseSet.type === 'matrix') {
      const { rowId } = answer;
      const { rows, bipolarRows } = responseSet.entries;
      const matrixRows = isBipolar(responseSet) ? bipolarRows : rows;
      const row = matrixRows.find(r => r.id === rowId);
      if (!row) {
        return null;
      }
      title = row.value.abbreviatedValue;
      const answerIds = answer.columnIds;
      answerIds.forEach(answerId => {
        const choice = responseSet.entries.columnData.columns.find(c => c.id === answerId);
        if (choice) {
          answers.push({ value: choice.value.value, origValue: choice.value.origValue });
        }
      });
    }
    if (answers.length === 0) {
      return null;
    }
    return `${title} - ${answers.join(', ')}`;
  };

  const getContentToCopy = () => {
    if (!comparativeViewAggregateData) {
      return null;
    }
    const rows = [];
    const columns = getConceptColumnHeaderCells();
    rows.push(columns);
    const questionAnswers = state.selectedView.questionAnswers || props.selectedView.questionAnswers;
    questionAnswers.forEach(obj => {
      const questionTitleAndAnswers = getQuestionTitleAndAnswers(obj);
      if (questionTitleAndAnswers) {
        const joinerComparativeData = comparativeViewAggregateData.comparativeData[obj.id] || {};
        const conceptContent = props.selectedView.conceptIds.map(conceptId => {
          const conceptComparativeData = joinerComparativeData[conceptId] || { count: null, percentage: null };
          return getCount(conceptComparativeData);
        });
        rows.push(`"${questionTitleAndAnswers}"\t"${conceptContent.join('"\t"')}"`);
      }
    });

    return rows.join('\r\n');
  };

  const contentCopied = getContentToCopy();

  const cloneComparativeView = () => {
    const view = cloneDeep(state.selectedView);
    view.id = null;
    view.title = `${view.title} - ${intl.get('app.copy')}`;
    props.cloneView(view);
  };

  const onDeleteView = () => {
    props.deleteView(viewIndex);
  };

  const toggleAddQuestionAnswers = id => {
    setState({
      currentQuestionAnswer: id ? state.selectedView.questionAnswers.find(qa => qa.id === id) : {},
      showAddQuestionAnswersModal: !state.showAddQuestionAnswersModal
    });
  };

  const synchQuestionAnswerConcepts = questionAnswer => {
    const conceptQuestionJoinerIds = [];
    const concepts = getConcepts();
    concepts.forEach(concept => {
      const joiner = concept.joiners.find(j => j.parentId === questionAnswer.parentId);
      if (joiner) {
        conceptQuestionJoinerIds.push(joiner.id);
      }
    });
    questionAnswer.conceptQuestionJoinerIds = conceptQuestionJoinerIds;
  };

  const getTableHeader = () => {
    const tHead = [
      <th style={{ textAlign: 'left', width: '40%', paddingLeft: '0.5rem' }} key="qaTitle">
        {intl.get('app.questionAnswerTitle')}
      </th>
    ];
    state.viewTableData[0].concepts.forEach(concept => {
      tHead.push(
        <th style={{ textAlign: 'center', verticalAlign: 'top', minWidth: '5.5rem' }} key={concept.title}>
          <div title={concept.title}>{concept.title}</div>
          {state.selectedView.showSigTestData && <div style={{ color: '#0569a9' }}>{concept.conceptLetter}</div>}
        </th>
      );
    });
    return tHead;
  };

  // Only show concept letters for selected concepts
  const getSigTestResultLetters = cell => {
    let sigResult = '';
    if (cell.counts.sigTestResult) {
      const letters = cell.counts.sigTestResult.split('');
      for (const letter of letters) {
        const conceptId = reverseConceptLetterMap[letter];
        if (state.selectedConcepts.includes(conceptId)) {
          sigResult += letter;
        }
      }
    }
    return sigResult;
  };

  const getCells = row => {
    return row.map((cell, i) => {
      return (
        <td style={{ textAlign: 'center' }} key={cell.title}>
          {getCount(cell.counts)}
          {` ${getSigTestResultLetters(cell)}`}
        </td>
      );
    });
  };

  const getTable = () => {
    return state.viewTableData.map(row => {
      return (
        <tr key={row.id}>
          <td style={{ textAlign: 'left', width: '40%' }}>
            <Button
              className="link-button"
              style={{ paddingLeft: '0', textAlign: 'left' }}
              onClick={() => toggleAddQuestionAnswers(row.id)}
            >
              {row.title}
            </Button>
          </td>
          {getCells(row.concepts)}
        </tr>
      );
    });
  };

  const onSave = currentQuestionAnswer => {
    synchQuestionAnswerConcepts(currentQuestionAnswer);
    const questionAnswers = cloneDeep(state.selectedView.questionAnswers);
    if (!currentQuestionAnswer.id) {
      currentQuestionAnswer.id = idUtils.getId();
      questionAnswers.push(currentQuestionAnswer);
    } else {
      const index = questionAnswers.findIndex(qa => qa.id === currentQuestionAnswer.id);
      questionAnswers.splice(index, 1, currentQuestionAnswer);
    }
    saveView(questionAnswers, 'questionAnswers', viewIndex);
  };

  const deleteQuestionAnswersRow = currentQuestionAnswer => {
    const questionAnswers = cloneDeep(state.selectedView.questionAnswers);
    const index = questionAnswers.findIndex(qa => qa.id === currentQuestionAnswer.id);
    questionAnswers.splice(index, 1);
    saveView(questionAnswers, 'questionAnswers', viewIndex);
  };

  const onShowSigTest = e => {
    saveView(e.target.checked, 'showSigTestData', viewIndex);
    setState({
      selectedView: {
        ...state.selectedView,
        showSigTestData: !state.selectedView.showSigTestData
      }
    });
  };

  const setSigTestingField = (field, val) => {
    const sigTestSetting = cloneDeep(state.selectedView.sigTestSetting);
    sigTestSetting[field] = val;
    saveView(sigTestSetting, 'sigTestSetting', viewIndex);
  };

  return (
    <div className="comparative-view" style={{ minHeight: state.selectedView.showSigTestData && '19rem' }}>
      <div className="name-and-config">
        <Input
          className="view-title"
          placeholder={intl.get('app.viewTitle')}
          value={state.selectedView.title}
          onChange={setViewTitle}
          onBlur={onTitleInputBlur}
        />
        <Input type="select" className="select mx-3" onChange={onSetConceptRotation}>
          {getConceptRotations()}
        </Input>
        <ComboDropdown
          open={state.conceptsDropDownOpen}
          toggle={handleToggleClick}
          target="conceptsDropDownOpen"
          message={intl.get('app.rd.showSelectedConcepts', { concepts: getSelectedConceptsText() })}
          getOptions={getConceptOptions}
          close={closeDropDown}
        />
        <span className="mx-3">
          <ComboDropdown
            open={state.positionsDropDownOpen}
            toggle={handleToggleClick}
            target="positionsDropDownOpen"
            message={intl.get('app.rd.showSelectedPosition', { positions: getSelectedPositions() })}
            getOptions={getPositionOptions}
            close={closeDropDown}
          />
        </span>
        <div style={{ maxWidth: '14rem', whiteSpace: 'nowrap' }}>
          <label className="me-4">{intl.get('app.display')}:</label>
          <Input type="select" className="display-options" onChange={onSetDisplayOptions}>
            {getDisplayOptions()}
          </Input>
        </div>
        <div className="copy-clone ms-3">
          <CopyToClipboard
            copyText={contentCopied}
            title={intl.get('app.copy')}
            toastText={intl.get('app.qa.copiedToClipboard')}
          />
          <i className="far fa-clone" title={intl.get('app.clone')} onClick={cloneComparativeView} />
          <i
            className="fa fa-trash-alt"
            title={intl.get('app.delete')}
            onClick={() => handleToggleClick('showDeleteViewModal')}
          />
        </div>
      </div>
      <div className="table-and-sig-testing">
        <div className="comparative-table-view">
          {state.viewTableData.length > 0 && (
            <table>
              <thead>
                <tr>{getTableHeader()}</tr>
              </thead>
              <tbody>{getTable()}</tbody>
            </table>
          )}
        </div>
        <div className="sig-testing-selection">
          <label>
            <Input type="checkbox" checked={state.selectedView.showSigTestData} onChange={onShowSigTest} />
            {intl.get('app.statistics.significanceTesting')}
          </label>
          <SigTesting
            sigTestSetting={state.selectedView.sigTestSetting || {}}
            setSigTestingField={setSigTestingField}
            hidden={!state.selectedView.showSigTestData}
          />
        </div>
      </div>
      <div style={{ margin: '1rem 0 0 1rem', color: '#1890ff' }}>
        <a onClick={toggleAddQuestionAnswers}>+ {intl.get('app.addQuestion.answer')}</a>
      </div>
      {state.showAddQuestionAnswersModal && (
        <QuestionAnswerPicker
          showModal
          toggle={toggleAddQuestionAnswers}
          className="comparative-view-question-answer"
          modalTitle={intl.get('app.addEdit.row')}
          primaryButtonText={intl.get('app.save')}
          cancelButtonText={intl.get('app.cancel')}
          save={onSave}
          currentQuestionAnswer={state.currentQuestionAnswer}
          concepts={state.selectedJoiner.conceptRotation.concepts || []}
          questionNum={state.selectedJoiner.displayIndex + 1}
          onDelete={deleteQuestionAnswersRow}
          language={state.language}
          viewLanguage={state.viewLanguage}
          setViewLanguage={props.setViewLanguage}
          sessionId={props.sessionId}
        />
      )}
      {state.showDeleteViewModal && (
        <ConfirmModal
          showModal={state.showDeleteViewModal}
          toggle={() => handleToggleClick('showDeleteViewModal')}
          modalTitle={intl.get('app.deleteView')}
          enableSave
          onConfirm={onDeleteView}
        >
          <p>{intl.get('app.delete.comparativeView', { view: state.selectedView.title })}</p>
        </ConfirmModal>
      )}
    </div>
  );
};
