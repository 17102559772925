import { createSelector, createStructuredSelector } from 'reselect';

const selectDataTableStore = state => state.dataTable;
const selectDataTableListRequested = dataTableStore => dataTableStore.dataTableListRequested;
const selectDataTableList = dataTableStore => dataTableStore.dataTableList;

const dataTableListSelector = () =>
  createSelector(
    selectDataTableStore,
    createStructuredSelector({
      dataTableListRequested: selectDataTableListRequested,
      dataTableList: selectDataTableList
    })
  );

const selectAllDataTablesRequested = dataTableStore => dataTableStore.allDataTablesRequested;
const selectAllDataTables = dataTableStore => dataTableStore.allDataTables;

const allDataTablesSelector = () =>
  createSelector(
    selectDataTableStore,
    createStructuredSelector({
      allDataTablesRequested: selectAllDataTablesRequested,
      allDataTables: selectAllDataTables
    })
  );

const selectDataTableId = (state, dataTableId) => dataTableId;
const selectDataTableRequested = dataTableStore => dataTableStore.dataTableRequested;
const selectDataTable = (dataTableStore, dataTableId) => dataTableStore.dataTables[dataTableId];

const dataTableSelector = () =>
  createSelector(
    [selectDataTableStore, selectDataTableId],
    createStructuredSelector({
      dataTableRequested: selectDataTableRequested,
      dataTable: selectDataTable
    })
  );

const selectDataTableRecordsRequested = (dataTableStore, dataTableId) => dataTableStore.dataTableRecordsRequested;
const selectDataTableRecords = (dataTableStore, dataTableId) => dataTableStore.dataTableRecords[dataTableId];

const dataTableRecordsSelector = () =>
  createSelector(
    [selectDataTableStore, selectDataTableId],
    createStructuredSelector({
      dataTableRecordsRequested: selectDataTableRecordsRequested,
      dataTableRecords: selectDataTableRecords
    })
  );

export { dataTableListSelector, allDataTablesSelector, dataTableSelector, dataTableRecordsSelector };
