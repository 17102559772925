import { takeLatest, call, put, delay } from 'redux-saga/effects';
import { toast } from '../../../util/toast';

function* fetchRequestedSaga(fetchApi, fetchActions, { payload }) {
  yield put(fetchActions.triggered(payload));
  try {
    if (payload?.deferTime) {
      yield delay(payload.deferTime);
    }
    const response = yield call(fetchApi, payload);
    yield put(fetchActions.succeeded(response));
  } catch (exception) {
    let errMsg = exception.errorMessage || exception.message;
    let status = exception.status;
    if (errMsg === 'Internal Server Error' || status === 500) {
      errMsg = exception.response.body.errorMessage || errMsg;
    }
    toast.error({ text: errMsg });
    yield put(fetchActions.failed(payload, exception));
  }
}

function createFetchSaga(fetchActions, fetchApi) {
  function* fetchSaga() {
    yield takeLatest(fetchActions.request, fetchRequestedSaga, fetchApi, fetchActions);
  }
  return fetchSaga;
}

export default createFetchSaga;
export { fetchRequestedSaga };
