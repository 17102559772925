import createFetchSaga from './createFetchSaga';
import { takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import { addToBlacklistAction, deleteFromBlacklistAction, fetchBlacklistAction } from '../actions/clientAdminActions';
import { addToBlackList, deleteFromBlackList, fetchBlackList } from '../../../api/clientAdminApi';

function handleAddSuccess({ payload: { email } }) {
  toast.success({ text: intl.get('app.email.blacklisted', { email }) });
}

function handleDeleteSuccess({ payload: { email } }) {
  toast.success({ text: intl.get('app.email.deleted', { email }) });
}

function* addToBlacklistSaga() {
  const saga = createFetchSaga(addToBlacklistAction, addToBlackList);
  yield* saga();
  yield takeLatest(addToBlacklistAction.succeeded, handleAddSuccess);
}

function* deletFromBlacklistSaga() {
  const saga = createFetchSaga(deleteFromBlacklistAction, deleteFromBlackList);
  yield* saga();
  yield takeLatest(deleteFromBlacklistAction.succeeded, handleDeleteSuccess);
}

function* fetchBlacklistSaga() {
  const saga = createFetchSaga(fetchBlacklistAction, fetchBlackList);
  yield* saga();
}

export { addToBlacklistSaga, deletFromBlacklistSaga, fetchBlacklistSaga };
