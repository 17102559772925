import { connect } from 'react-redux';
import { projectListSelector, recentProjectsSelector } from '../../store/redux/selectors/projectListSelector';
import { fetchProjectListActions, fetchRecentProjectsActions } from '../../store/redux/actions/projectListActions';
import { sessionUserSelector } from '../../store/redux/selectors/sessionUserSelector';

import ProjectList from './ProjectList';

const mapDispatchToProps = dispatch => ({
  fetchProjects: params => dispatch(fetchProjectListActions.request(params)),
  fetchRecentProjects: params => dispatch(fetchRecentProjectsActions.request(params))
});

const mapStateToProps = state => {
  const listSelector = projectListSelector(state);
  const { recentProjects } = recentProjectsSelector(state);
  return {
    hasProjectManage: sessionUserSelector(state).abilities.hasProjectManage,
    ...listSelector,
    recentProjects
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
