import { get } from '../util/request';

const INGEST_STATUS_API = '/a/api/surveyVideosIngestStatus';

async function getSurveyVideosIngestStatus(surveyId) {
  return get(`${INGEST_STATUS_API}/${surveyId}`)().then(res => ({
    ingestStatus: res.body?.payload ?? 'NA',
    surveyId
  }));
}

export { getSurveyVideosIngestStatus };
