import createFetchSaga from './createFetchSaga';
import { getSurveyVideosIngestStatusAction } from '../actions/watermarkActions';
import { getSurveyVideosIngestStatus } from '../../../api/watermarkApi';

function* getSurveyVideosIngestStatusSaga() {
  const saga = createFetchSaga(getSurveyVideosIngestStatusAction, getSurveyVideosIngestStatus);
  yield* saga();
}

export { getSurveyVideosIngestStatusSaga };
