import React from 'react';
import intl from 'react-intl-universal';
import localtime from '../../util/localtime';
import { mediaUtil } from '../../util/mediaUtil';
import { Icons } from '../../components/icons/Icons';

import './SurveyCard.css';

export const SurveyCard = props => {
  const { status, goToSurvey, session = {} } = props;
  const { name, startDate, surveySubmissions, includedParticipants, firstStim } = session;
  const active = (status === 'ACTIVE' && 'active') || '';

  return (
    <div className={`survey-card clickable ${active}`} onClick={goToSurvey}>
      <div className="mb-3 text-truncate" style={{ height: '1.5rem' }} title={name}>
        <strong>{name}</strong>
      </div>
      <div className="mb-3" style={{ height: '1.5rem' }}>
        <strong>{status}</strong>
      </div>
      <div className="mb-3">{`${intl.get('app.start')}: ${
        startDate ? localtime.getFormattedDateCap(startDate) : intl.get('app.unset')
      }`}</div>
      <div className="users-media">
        <Icons.UsersIcon className="me-4 pt-2" />
        <div className="me-3" style={{ minWidth: '100px' }}>
          <label>{`${intl.get('app.active')}: ${surveySubmissions || ''}`}</label>
          <label style={{ display: 'block' }}>{`${intl.get('app.invited')}: ${includedParticipants || 0}`}</label>
        </div>
        <div>{mediaUtil.getCardViewMedia(firstStim)}</div>
      </div>
    </div>
  );
};
