import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash';
import { fetchMediaListActions, uploadLargeMediaActions, mediaUpdateActions } from '../actions/mediaActions';

const initialState = {
  mediaListRequested: false
};

const mediaListReducer = handleActions(
  {
    [fetchMediaListActions.triggered](state) {
      return { ...state, mediaListRequested: true };
    },
    [fetchMediaListActions.succeeded](state, { payload }) {
      return {
        pagedList: payload,
        mediaListRequested: false
      };
    },
    [fetchMediaListActions.failed](state) {
      return { ...state, mediaListRequested: false };
    },

    [uploadLargeMediaActions.triggered](state) {
      return { ...state, uploadMediaInProgress: true };
    },
    [uploadLargeMediaActions.succeeded](state) {
      return { ...state, uploadMediaInProgress: false };
    },
    [uploadLargeMediaActions.failed](state) {
      return { ...state, uploadMediaInProgress: false };
    },

    [mediaUpdateActions.succeeded](state, { payload }) {
      if (!state.pagedList || !state.pagedList.content) {
        return { ...state };
      }
      const pagedList = cloneDeep(state.pagedList);
      payload.media.forEach(updatedMedia => {
        const index = pagedList.content.findIndex(m => updatedMedia._id === m._id);
        if (index !== -1) {
          pagedList.content[index] = updatedMedia;
        }
      });
      return {
        ...state,
        pagedList
      };
    }
  },
  initialState
);

export default mediaListReducer;
