import { put, takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import createFetchSaga from './createFetchSaga';
import { mediaUtil } from '../../../util/mediaUtil';
import { toast } from '../../../util/toast';
import {
  fetchClientsActions,
  fetchClientActions,
  createClientActions,
  saveClientActions,
  archiveClientActions,
  uploadLogoActions,
  updateTenantLogoActions,
  fetchAllLicensesActions,
  updateTenantLicensesActions
} from '../actions/clientAdminActions';
import {
  fetchClients,
  fetchClient,
  createClient,
  saveClient,
  archiveClient,
  uploadLogo,
  updateTenantLogo,
  fetchAllLicenses,
  updateTenantLicenses
} from '../../../api/clientAdminApi';

function handleClientCreated({ payload: client }) {
  toast.success({ text: intl.get('app.client.created', { name: client.tenantName }) });
}

function handleClientSaved({ payload: client }) {
  toast.success({ text: intl.get('app.client.saved', { name: client.tenantName }) });
}

function handleClientArchived({ payload }) {
  toast.success({ text: intl.get('app.client.archived', { name: payload.clientName }) });
}

/*
 * Called after logo upload success. Set the accessUrl, and send to the server for save.
 */
function* doUpdateTenantLogo({ payload: { client, media } }) {
  media.accessUrl = mediaUtil.getMediaUrl(media);
  yield put(updateTenantLogoActions.request({ client, media }));
}

// sagas

function* fetchClientsSaga() {
  const saga = createFetchSaga(fetchClientsActions, fetchClients);
  yield* saga();
}

function* fetchClientSaga() {
  const saga = createFetchSaga(fetchClientActions, fetchClient);
  yield* saga();
}

function* createClientSaga() {
  const saga = createFetchSaga(createClientActions, createClient);
  yield* saga();
  yield takeLatest(createClientActions.succeeded, handleClientCreated);
}

function* saveClientSaga() {
  const saga = createFetchSaga(saveClientActions, saveClient);
  yield* saga();
  yield takeLatest(saveClientActions.succeeded, handleClientSaved);
}

function* archiveClientSaga() {
  const saga = createFetchSaga(archiveClientActions, archiveClient);
  yield* saga();
  yield takeLatest(archiveClientActions.succeeded, handleClientArchived);
}

function* uploadLogoSaga() {
  const saga = createFetchSaga(uploadLogoActions, uploadLogo);
  yield* saga();
  yield takeLatest(uploadLogoActions.succeeded, doUpdateTenantLogo);
}

function* updateTenantLogoSaga() {
  const saga = createFetchSaga(updateTenantLogoActions, updateTenantLogo);
  yield* saga();
}

function* fetchAllLicensesSaga() {
  const saga = createFetchSaga(fetchAllLicensesActions, fetchAllLicenses);
  yield* saga();
}

function* updateTenantLicensesSaga() {
  const saga = createFetchSaga(updateTenantLicensesActions, updateTenantLicenses);
  yield* saga();
  yield takeLatest(updateTenantLicensesActions.succeeded, handleClientSaved);
}

export {
  fetchClientsSaga,
  fetchClientSaga,
  createClientSaga,
  saveClientSaga,
  archiveClientSaga,
  uploadLogoSaga,
  updateTenantLogoSaga,
  fetchAllLicensesSaga,
  updateTenantLicensesSaga
};
