import React from 'react';
import intl from 'react-intl-universal';
import { MEDIA_TYPE } from './joinerUtil';
import { Icons } from '../components/icons/Icons';

function isMediaConvertInProgress(media) {
  const { mediaConvertStatus } = media;
  return mediaConvertStatus && mediaConvertStatus.state === 'IN_PROGRESS';
}

function getMediaConvertInProgressMsg(mediaConvertStatus) {
  if (!mediaConvertStatus) {
    return '';
  }
  const { percentComplete } = mediaConvertStatus;
  return intl.get('app.mediaConvert.status.msg', { percentComplete });
}

/*
 * Returns the src URL for the media
 */
function getMediaUrl(media, filename) {
  if (!media?._id) {
    return '';
  }
  if (media.type === 'WebContent') {
    return media.accessUrl;
  }
  const { location } = window;
  return `${location.protocol}//${location.hostname}/a/binapi/serveMedia/${media._id}/${filename || ''}`;
}

const getCardViewMedia = stim => {
  const { media, altTag } = stim || {};
  if (!media) {
    return null;
  }
  if (media.type === MEDIA_TYPE.image) {
    return <img className="media-thumbnail" src={getMediaUrl(media)} alt={altTag} />;
  }
  // If not image, must be video.
  return <Icons.VideoIcon className="fa-3x" />;
};

export const mediaUtil = {
  isMediaConvertInProgress,
  getMediaConvertInProgressMsg,
  getMediaUrl,
  getCardViewMedia
};
