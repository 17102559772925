import React, { useCallback, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { accessHandler, InvokeTable, usePagination } from 'webapp-common';
import appConfig from '../../../../appConfig';
import localtime from '../../../../util/localtime';
import ReportConfig from '../../../customReportConfig';
import { CreateCustomReportConfigModal } from './CreateCustomReportConfigModal';

const DEFAULT_SORT_FIELD = 'modifiedDate';

export const CustomReportsPage = props => {
  const { customReportConfigList = {}, customReportConfigSaved, fetchCustomReportConfigs } = props;
  const { pageRequest = {}, totalElements } = customReportConfigList;

  const navigate = useNavigate();
  const { subtab: reportConfigId } = useParams();

  const [showCreateCustomReportConfigModal, setShowCreateCustomReportConfigModal] = useState();

  useEffect(() => {
    fetchCustomReportConfigs({});
  }, []);

  useEffect(() => {
    if (customReportConfigSaved && showCreateCustomReportConfigModal) {
      toggleCreateCustomReportConfigModal();
    }
  }, [customReportConfigSaved]);

  function toggleCreateCustomReportConfigModal() {
    setShowCreateCustomReportConfigModal(!showCreateCustomReportConfigModal);
  }

  const toggle = useCallback(
    rowId => {
      navigate(`${appConfig.reportsPagePath}/custom/${rowId}`);
    },
    [navigate]
  );

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchCustomReportConfigs({
        pageNumber: 1,
        pageSize: pageRequest.pageSize,
        sortBy,
        sortOrder
      });
    },
    [fetchCustomReportConfigs, pageRequest.pageSize]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchCustomReportConfigs({
        pageNumber,
        pageSize,
        sortBy,
        sortOrder
      });
    },
    [fetchCustomReportConfigs]
  );

  const formatLabel = useCallback(
    info => {
      const report = info.row.original;
      return (
        <span
          style={{ color: 'var(--link-color)', cursor: 'pointer' }}
          title={report.name}
          onClick={() => toggle(report.id)}
        >
          {report.name}
        </span>
      );
    },
    [toggle]
  );

  const formatDate = useCallback(info => {
    return localtime.getFormattedDate(info.getValue());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: intl.get('app.configuration.name'),
        cell: formatLabel,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: DEFAULT_SORT_FIELD,
        header: intl.get('app.lastModified'),
        cell: formatDate
      },
      {
        accessorKey: 'sessionCount',
        header: intl.get('app.session.count'),
        enableSorting: false
      },
      {
        accessorKey: 'questionsCount',
        header: intl.get('app.dataColumns'),
        enableSorting: false
      }
    ],
    [formatDate, formatLabel]
  );

  const pagination = usePagination({ pageRequest, totalElements });

  const accessCheck = accessHandler.checkAccess(props.hasReportsManage && props.isCustomReportsEnabled);
  if (accessCheck !== true) {
    return accessCheck;
  }

  if (reportConfigId) {
    return <ReportConfig reportConfigId={reportConfigId} navigate={navigate} />;
  }

  return (
    <>
      <h6>{intl.get('app.custom.reports.configurations')}</h6>
      <Button color="primary" className="mt-4" onClick={toggleCreateCustomReportConfigModal}>
        {intl.get('app.add.new.configuration')}
      </Button>
      <div className="mt-4">
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={customReportConfigList.content ?? []}
          initialState={{
            sorting: [
              {
                id: 'modifiedDate',
                desc: true
              }
            ]
          }}
          loading={props.customReportConfigListRequested || props.customReportConfigSaveRequested}
          pagination={pagination}
          onSortingChange={sort}
          onPaginationChange={paginate}
        />
      </div>
      {showCreateCustomReportConfigModal && (
        <CreateCustomReportConfigModal
          toggle={toggleCreateCustomReportConfigModal}
          onSave={props.saveCustomReportConfig}
        />
      )}
    </>
  );
};
