import { handleActions } from 'redux-actions';
import {
  newEmotionDataActions,
  newEmotionReportFilesActions,
  fetchEmotionSettingConfigActions,
  saveEmotionSettingConfigActions
} from '../actions/emotionDataActions';

const initialState = {};

const emotionDataReducer = handleActions(
  {
    [newEmotionDataActions.succeeded](state, { payload }) {
      const { sessionId, questionJoinerId } = payload[0] || {};
      const returnObj = {
        ...state
      };
      if (sessionId && questionJoinerId) {
        returnObj[`${sessionId}-${questionJoinerId}`] = payload;
      }
      return returnObj;
    },

    [newEmotionReportFilesActions.succeeded](state, { payload }) {
      const { sessionId, reportFiles } = payload;
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          reportFiles
        }
      };
    },

    // [exportDialDataActions.triggered](state) {
    //   return {
    //     ...state,
    //     exportDialDataInProgress: true,
    //     exportDialDataSuccess: false
    //   };
    // },

    // [exportDialDataActions.succeeded](state, { payload }) {
    //   return {
    //     ...state,
    //     exportDialDataInProgress: false,
    //     exportDialDataSuccess: true
    //   };
    // },

    // [exportDialDataActions.failed](state) {
    //   return {
    //     ...state,
    //     exportDialDataInProgress: false,
    //     exportDialDataSuccess: false
    //   };
    // },

    [fetchEmotionSettingConfigActions.triggered](state) {
      return {
        ...state,
        fetchEmotionSettingConfigInProgress: true,
        fetchEmotionSettingConfigSuccess: false,
        saveEmotionSettingConfigSuccess: false
      };
    },

    [fetchEmotionSettingConfigActions.succeeded](state, { payload }) {
      return {
        ...state,
        emotionSettingConfig: payload,
        fetchEmotionSettingConfigInProgress: false,
        fetchEmotionSettingConfigSuccess: true
      };
    },

    [fetchEmotionSettingConfigActions.failed](state) {
      return {
        ...state,
        fetchEmotionSettingConfigInProgress: false,
        fetchEmotionSettingConfigSuccess: false
      };
    },

    [saveEmotionSettingConfigActions.triggered](state, { payload }) {
      return {
        ...state,
        saveEmotionSettingConfigSuccess: false
      };
    },

    [saveEmotionSettingConfigActions.succeeded](state, { payload }) {
      return {
        ...state,
        emotionSettingConfig: payload,
        saveEmotionSettingConfigSuccess: true
      };
    },

    [saveEmotionSettingConfigActions.failed](state, { payload }) {
      return {
        ...state,
        emotionSettingConfig: {
          ...state.emotionSettingConfig,
          emotionSettingConfigSaveError: true,
          saveEmotionSettingConfigSuccess: false
        }
      };
    }
  },
  initialState
);
export default emotionDataReducer;
