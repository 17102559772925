import React, { useState } from 'react';
import { CustomPicker, SketchPicker } from 'react-color';

const ColorPicker = props => {
  const [color, setColor] = useState('#949494');
  const popover = {
    position: 'absolute',
    zIndex: '50',
    top: '30%',
    left: '55%'
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  };
  const saveColor = (color, target) => {
    setColor(color);
    props.saveColor(color, target);
  };

  return (
    <div style={popover}>
      <div style={cover} onClick={props.handleClose} />
      <SketchPicker color={color} onChangeComplete={color => saveColor(color, props.target)} />
    </div>
  );
};

export default CustomPicker(ColorPicker);
