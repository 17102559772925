import React, { useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import Select from 'react-select';
import { SearchInput } from 'webapp-common';

export const QuestionFilters = props => {
  const { filters, showSurveyTypeFilter, onFilterChange } = props;

  const questionTypeOptions = useMemo(() => {
    return [
      {
        label: intl.get('app.all'),
        value: ''
      },
      {
        label: intl.get('app.questionType.multi'),
        value: 'CHOICELIST'
      },
      {
        label: intl.get('app.questionType.matrix'),
        value: 'MATRIX'
      },
      {
        label: intl.get('app.questionType.ranked'),
        value: 'RANK'
      },
      {
        label: intl.get('app.questionType.open'),
        value: 'STRING'
      },
      {
        label: intl.get('app.questionType.thirdparty'),
        value: 'BOOLEAN'
      }
    ];
  }, []);

  const surveyTypeOptions = useMemo(() => {
    return [
      {
        label: intl.get('app.all'),
        value: ''
      },
      {
        label: intl.get('app.session'),
        value: 'Session'
      },
      {
        label: intl.get('app.screener'),
        value: 'Screener'
      }
    ];
  }, []);

  const onTitleSearch = useCallback(
    e => {
      onFilterChange({ ...filters, title: e.target.value });
    },
    [onFilterChange, filters]
  );

  const onTextSearch = useCallback(
    e => {
      onFilterChange({ ...filters, text: e.target.value });
    },
    [onFilterChange, filters]
  );

  const onQuestionTypeSelect = useCallback(
    type => {
      onFilterChange({ ...filters, type: type.value });
    },
    [onFilterChange, filters]
  );

  const onSurveyTypeSelect = useCallback(
    type => {
      onFilterChange({ ...filters, surveyType: type.value });
    },
    [onFilterChange, filters]
  );

  return (
    <div className="filters">
      <SearchInput placeholder={intl.get('app.searchTitle')} onChange={onTitleSearch} />
      <SearchInput placeholder={intl.get('app.searchText')} onChange={onTextSearch} />
      <Select
        classNamePrefix="react-select"
        onChange={onQuestionTypeSelect}
        options={questionTypeOptions}
        placeholder={intl.get('app.questionType')}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            width: '10rem'
          })
        }}
      />
      {showSurveyTypeFilter && (
        <Select
          classNamePrefix="react-select"
          onChange={onSurveyTypeSelect}
          options={surveyTypeOptions}
          placeholder={intl.get('app.surveyType')}
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              width: '10rem'
            })
          }}
        />
      )}
    </div>
  );
};
