export const buildDialDataWorker = () => {
  const TOTAL_FILTER = { name: 'Total' };

  onmessage = e => {
    const { rawDialData, filters, selectedJoiner, chartWidth, filteredParticipants = {} } = e.data;
    const data = buildDialData(rawDialData, filters, selectedJoiner, filteredParticipants, chartWidth);
    postMessage(data);
  };

  /*
   * Loop through all participant data, filling in their dialValues for each second.
   */
  function fillInDialValues(rawDialData) {
    if (!rawDialData) {
      return;
    }

    rawDialData.forEach(data => {
      const completeDialValues = [];
      for (let i = 0; i <= data.currentPosition; i++) {
        let dialValue = data.dialValues[i];
        if (dialValue === undefined) {
          // If no dial value, carry the previous one forward.
          dialValue = completeDialValues[i - 1];
        }
        completeDialValues.push(dialValue);
      }
      data.dialValues = completeDialValues;
    });
    return rawDialData;
  }

  /*
   * Prepare the per-second dial data structure
   */
  function preparePerSecondDataStructure(videoLength, filters) {
    const data = [];
    for (let i = 0; i <= videoLength; i++) {
      const timeSlice = {};
      filters.forEach(filter => {
        timeSlice[filter.name] = {
          dialValues: [],
          actions: {}
        };
      });
      data.push(timeSlice);
    }
    return data;
  }

  function prepareScatterRatingDataStructure(filters) {
    const data = {};
    filters.forEach(filter => {
      data[filter.name] = {};
      data[filter.name].avg = [];
    });
    return data;
  }

  function getFilterNamesFromParticipantId(filteredParticipantMap, participantId) {
    const filters = [];
    for (const filterName in filteredParticipantMap) {
      if (filteredParticipantMap[filterName].has(participantId)) {
        filters.push(filterName);
      }
    }
    if (!filters.length) {
      filters.push(TOTAL_FILTER.name);
    }
    return filters;
  }

  function getRawScatterActionData(rawDialData, filteredParticipantMap) {
    const rawScatterActionData = [];
    const { videoLength } = rawDialData[0];

    rawDialData.forEach(data => {
      const { participantId, actions } = data;
      const filterNames = getFilterNamesFromParticipantId(filteredParticipantMap, participantId);
      for (const key in actions) {
        const obj = {
          filterNames,
          actionButtonName: actions[key],
          actualTime: key,
          participantId,
          videoLength
        };
        rawScatterActionData.push(obj);
      }
    });

    return rawScatterActionData;
  }

  /*
   * Workhorse function for building the data needed by the chart and the filters component
   */
  function buildDialData(rawDialData, filters, selectedJoiner, filteredParticipants, chartWidth) {
    // Step 1: Fill in per-second dial values for each participant
    const dialData = fillInDialValues(rawDialData);
    if (!dialData || !dialData.length) {
      return null;
    }

    // Step 2: Prepare the data structure for inserting per-second participant dial data
    const { videoLength } = dialData[0];
    const perSecondData = preparePerSecondDataStructure(videoLength, filters);

    // Convert filteredParticipants to a map of Sets for faster lookup
    const filteredParticipantMap = {};
    for (const filterName in filteredParticipants) {
      filteredParticipantMap[filterName] = new Set(filteredParticipants[filterName]);
    }

    // Set up a map to track action counts for each filter
    const { actionButtons } = selectedJoiner.stim.options.dialConfig;
    const actionCounts = {};
    filters.forEach(filter => {
      actionCounts[filter.name] = {};
      actionButtons.forEach(actionButton => {
        actionCounts[filter.name][actionButton.label] = 0;
      });
    });

    // Step 3: Fill in the per-second participant dial data for each filter
    perSecondData.forEach((timeSlice, i) => {
      filters.forEach(filter => {
        const target = timeSlice[filter.name];
        dialData.forEach(source => {
          // Only add data for participants in this filter
          const okToAdd =
            filter.name === TOTAL_FILTER.name || filteredParticipantMap[filter.name].has(source.participantId);

          // Add the dial values
          if (okToAdd) {
            target.dialValues.push(source.dialValues[i]);
          }

          // Bring action counts forward, incrementing where appropriate.
          const action = source.actions[i];

          actionButtons.forEach(actionButton => {
            if (okToAdd && actionButton.label === action) {
              actionCounts[filter.name][action]++;
            }
            target.actions[actionButton.label] = actionCounts[filter.name][actionButton.label];
          });
        });
      });
    });

    // Object to hold the overall totals for each filter
    const filterTotals = {};
    filters.forEach(filter => {
      filterTotals[filter.name] = {
        numParticipants: 0,
        averages: [],
        max: 0,
        avg: 0
      };
    });

    // Step 4: Compute the averages and percentiles for each timeslice.
    // Also update the filter totals as we go.
    perSecondData.forEach(timeSlice => {
      filters.forEach(filter => {
        const target = timeSlice[filter.name];
        const dialValues = target.dialValues
          .filter(val => val >= 0)
          .map(val => parseInt(val, 10))
          .sort((a, b) => (a < b && -1) || (a > b && 1) || 0);
        const numValues = dialValues.length;
        target.percentiles = [];
        if (numValues) {
          const total = dialValues.reduce((acc, val) => acc + val, 0);
          target.avg = Math.round(total / numValues);
        }
        // We no longer need this
        delete target.dialValues;

        // Update the filter totals
        const totals = filterTotals[filter.name];
        totals.numParticipants = numValues > totals.numParticipants ? numValues : totals.numParticipants;
        const max = dialValues[dialValues.length - 1];
        if (max > totals.max) {
          totals.max = max;
        }
        if (target.avg) {
          totals.averages.push(target.avg);
        }
      });
    });

    // Finally, compute the total avg for each filter.
    filters.forEach(filter => {
      const { averages } = filterTotals[filter.name];
      if (averages.length > 0) {
        const total = averages.reduce((acc, val) => acc + val, 0);
        filterTotals[filter.name].avg = Math.round(total / averages.length);
      }
      // We no longer need this
      delete filterTotals[filter.name].averages;
    });

    // We only need a max of one data point per pixel for the width of the chart.
    // Too much data can bog down the rechart component.
    const interval = Math.ceil(perSecondData.length / chartWidth);

    const scatterRatingData = prepareScatterRatingDataStructure(filters);
    perSecondData.forEach((time, index) => {
      if (index % interval === 0) {
        Object.keys(time).forEach(filter => {
          const avgScatterObj = {
            time: index,
            y: time[filter].avg
          };
          scatterRatingData[filter].avg.push(avgScatterObj);
        });
      }
    });

    //Step 5: Compute scatter values for action buttons
    const rawScatterActionData = getRawScatterActionData(rawDialData, filteredParticipantMap);

    return {
      perSecondData,
      filterTotals,
      lastBuilt: Date.now(),
      rawScatterActionData,
      scatterRatingData
    };
  }
};
