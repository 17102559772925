import React from 'react';
import intl from 'react-intl-universal';
import { Transfer } from 'antd';
import { isEqual } from 'lodash';
import { InvokeModal } from 'webapp-common';

class ReportSessionsPickerModal extends React.Component {
  state = {
    targetKeys: []
  };

  componentDidMount() {
    this.setState({ targetKeys: this.props.targetKeys });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.showModal && !isEqual(this.state.targetKeys, this.props.targetKeys)) {
      this.setState({
        targetKeys: this.props.targetKeys
      });
    }
  }

  handleSessionChanges = targetKeys => {
    this.setState({ targetKeys });
  };

  saveSessions = () => {
    if (!this.props.sessions || !this.props.sessions.length < 0) {
      return;
    }
    this.props.saveSessions(this.state.targetKeys);
  };

  closeModal = () => {
    this.props.toggle();
  };

  filterOption = (inputValue, option) => {
    return option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  render() {
    const { sessions, showModal } = this.props;
    const { targetKeys } = this.state;
    return (
      <div>
        <InvokeModal
          toggle={this.closeModal}
          showModal={showModal}
          modalTitle={intl.get('app.addEditSessions')}
          primaryButtonText={intl.get('app.save')}
          cancelButtonText={intl.get('app.cancel')}
          save={this.saveSessions}
          enableSave={!isEqual(targetKeys, this.props.targetKeys)}
          className="custom-report-config"
        >
          <h5 className="inline-block">{intl.get('app.availableSessions')}</h5>
          <h5 className="inline-block included-title-text">{intl.get('app.includedSessions')}</h5>
          <Transfer
            showSearch={true}
            filterOption={this.filterOption}
            onChange={this.handleSessionChanges}
            dataSource={sessions}
            targetKeys={targetKeys}
            rowKey={rec => rec.id}
            render={rec => rec.name}
            style={{ width: 800, height: 335 }}
            listStyle={{
              width: 350,
              height: 335
            }}
          />
        </InvokeModal>
      </div>
    );
  }
}

export default ReportSessionsPickerModal;
