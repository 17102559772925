import { TOTAL_FILTER } from '../sessionDetailsCommon/sessionDetailDataUtil';

const FILTER_COLOR_MAP = new Map();
const FILTER_COLOR_MAP_OVERLAY = new Map();
const TOTAL_ACTION_BUCKETS = 60;
const ACTION_BUCKET = new Map();
const INCREMENT_FACTOR = 1 / 25;

const DIAL_CHART_COLORS = [
  '#737373',
  '#F15A60',
  '#7AC36A',
  '#5A9BD4',
  '#F7A75C',
  '#9E68AB',
  '#CE7058',
  '#D77FB4',
  '#CCCCCC',
  '#F2AFAD',
  '#D9E4A9',
  '#B8D2EC',
  '#F3D1B0',
  '#D5B2D4',
  '#DDB9A9',
  '#EBC0DA'
];
const DIAL_CHART_COLORS_OVERLAY = [
  '#DDDDDD',
  '#F15A60',
  '#7AC36A',
  '#5A9BD4',
  '#F7A75C',
  '#9E68AB',
  '#CE7058',
  '#D77FB4',
  '#999999',
  '#F2AFAD',
  '#D9E4A9',
  '#B8D2EC',
  '#F3D1B0',
  '#D5B2D4',
  '#DDB9A9',
  '#EBC0DA'
];
let currentColorIndex = 0;
let overlayColorIndex = 0;

const getNearestBucket = (input, bucketSize) => {
  input = parseInt(input, 10);
  const remainder = input % bucketSize;
  return remainder > bucketSize / 2 ? input + (bucketSize - remainder) : input - remainder;
};

const getNextActionValue = (currentBucket, actionButtonName, totalRange) => {
  const key = currentBucket + actionButtonName;
  const isPresent = ACTION_BUCKET.has(key);
  if (!isPresent) {
    ACTION_BUCKET.set(key, 0);
  }
  const currentvalue = ACTION_BUCKET.get(key) + totalRange * INCREMENT_FACTOR;
  ACTION_BUCKET.set(key, currentvalue);
  return currentvalue;
};

const getFilterColorOverlaid = str => {
  const isPresent = FILTER_COLOR_MAP_OVERLAY.has(str);
  if (!isPresent) {
    FILTER_COLOR_MAP_OVERLAY.set(str, DIAL_CHART_COLORS_OVERLAY[overlayColorIndex]);
    overlayColorIndex = (overlayColorIndex + 1) % DIAL_CHART_COLORS_OVERLAY.length;
  }
  return FILTER_COLOR_MAP_OVERLAY.get(str);
};

export const getFilterColor = (str, overlay) => {
  if (overlay) {
    return getFilterColorOverlaid(str);
  }
  const isPresent = FILTER_COLOR_MAP.has(str);
  if (!isPresent) {
    FILTER_COLOR_MAP.set(str, DIAL_CHART_COLORS[currentColorIndex]);
    currentColorIndex = (currentColorIndex + 1) % DIAL_CHART_COLORS.length;
  }
  return FILTER_COLOR_MAP.get(str);
};

function getColor(matchingFilters, selectedFilters, overlay) {
  if (matchingFilters.length === 1) {
    // Matches one filter
    return getFilterColor(matchingFilters[0].name, overlay);
  }
  if (matchingFilters.length > 1) {
    // Matches multiple filters
    return getFilterColor(TOTAL_FILTER.name, overlay);
  }
  const isTotalSelected = selectedFilters.some(f => f.name === TOTAL_FILTER.name);
  if (isTotalSelected) {
    // No matching filters and Total is selected
    return getFilterColor(TOTAL_FILTER.name, overlay);
  }
  return 'transparent';
}

export const getColorFromSetting = (filter, overlay, settings) => {
  const foundFilter = settings?.find(s => s.filterId === filter.id);
  const foundColor = overlay ? foundFilter?.overlayColor : foundFilter?.color;
  if (!foundColor) {
    return getFilterColor(filter.name, overlay);
  }
  return foundColor;
};

export const formatScatterActionData = params => {
  const { rawScatterActionData, selectedDataOutput, selectedFilters, totalRange, overlay, colorSetting, enrolleeMap } =
    params;
  ACTION_BUCKET.clear();

  const scatterActionData = [];
  rawScatterActionData.forEach(item => {
    const matchesSelectedAction = item.actionButtonName === selectedDataOutput;
    if (matchesSelectedAction) {
      const matchingFilters = selectedFilters.filter(f => item.filterNames.includes(f.name));
      const match =
        (matchingFilters.length === 1 && colorSetting.find(s => s.filterId === matchingFilters[0]?.id)) || {};
      const color = overlay
        ? match.overlayColor || getColor(matchingFilters, selectedFilters, overlay)
        : match.color || getColor(matchingFilters, selectedFilters, overlay);
      const bucketSize = Math.ceil(item.videoLength / TOTAL_ACTION_BUCKETS);
      const currentBucket = getNearestBucket(item.actualTime, bucketSize);
      const y = getNextActionValue(currentBucket, item.actionButtonName, totalRange);
      if (color === 'transparent') {
        scatterActionData.push({});
      } else {
        scatterActionData.push({
          participantId: item.participantId,
          nickname: enrolleeMap?.[item.participantId]?.nickname,
          matchingFilters,
          color,
          actionButtonName: item.actionButtonName,
          actualTime: item.actualTime,
          videoLength: item.videoLength,
          time: currentBucket,
          y
        });
      }
    }
  });

  return scatterActionData;
};

export const getFilteredActionTotals = (rawScatterActionData, action) => {
  const actionTotals = {
    [TOTAL_FILTER.name]: 0
  };
  if (action) {
    rawScatterActionData.forEach(data => {
      if (data.actionButtonName === action) {
        if (!data.filterNames.includes(TOTAL_FILTER.name)) {
          actionTotals[TOTAL_FILTER.name]++;
        }
        data.filterNames.forEach(filterName => {
          actionTotals[filterName] = actionTotals[filterName] || 0;
          actionTotals[filterName]++;
        });
      }
    });
  }
  return actionTotals;
};
