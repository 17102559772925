import { createFetchActions, createWebSocketSendActions, createWebSocketReceiveActions } from './createFetchActions';

const fetchParticipantDataDefActions = createFetchActions('FETCH_PARTICIPANT_DATA_DEFINITION', {
  failure_payload: (sessionId, exception) => ({
    sessionId,
    exception
  })
});

const fetchParticipantsActions = createFetchActions('FETCH_PARTICIPANTS', {
  failure_payload: (participantsRequest, exception) => ({
    participantsRequest,
    exception
  })
});

const fetchEnrollmentInfoActions = createFetchActions('ENROLLMENT_INFO');

const saveParticipantActiveActions = createFetchActions('SAVE_PARTICIPANT_ACTIVE', {
  failure_payload: (participantId, active) => ({
    participantId,
    active
  })
});

const subscribeScreenerSummaryActions = createFetchActions('SUBSCRIBE_SCREENER_SUMMARY');
const receiveScreenerSummaryActions = createWebSocketReceiveActions('SCREENER_SUMMARY');
const fetchScreenersAnswersTotalActions = createWebSocketSendActions('SUBSCRIBE_SCREENER_ANSWERS');
const receiveScreenerResponseDataActions = createWebSocketReceiveActions('SCREENER_RESPONSE_DATA');

const pingParticipantDataActions = createFetchActions('PING_PARTICIPANT_DATA');
const fetchScreenerPreviewResultsActions = createFetchActions('FETCH_SCREENER_PREVIEW_RESULTS');
const setVFGstatusActions = createFetchActions('SET_VFG_STATUS');
const endVFGMeetingActions = createFetchActions('END_VFG_MEETING');

export {
  fetchParticipantDataDefActions,
  fetchParticipantsActions,
  fetchEnrollmentInfoActions,
  fetchScreenersAnswersTotalActions,
  receiveScreenerResponseDataActions,
  saveParticipantActiveActions,
  pingParticipantDataActions,
  subscribeScreenerSummaryActions,
  receiveScreenerSummaryActions,
  fetchScreenerPreviewResultsActions,
  setVFGstatusActions,
  endVFGMeetingActions
};
