import { handleActions } from 'redux-actions';
import { cloneDeep, find, merge, union, uniq } from 'lodash';
import {
  rdChatsActions,
  openChatWindowAction,
  closeChatWindowAction,
  sendChatResponseAction,
  newChatMessagesAction
} from '../../actions/researchDashboardActions';
import chatUtil from '../../../../components/chat/chatUtil';
import { idUtils } from '../../../../util/Id';
import update from 'immutability-helper';

const initialState = {};

function findChat(chatObj, state, sessionId) {
  return state[sessionId] && find(state[sessionId].chats, { id: chatObj.id });
}

function createChat(chatObj, sessionId) {
  return merge(
    {
      id: chatObj.id,
      initiatorId: chatObj.initiatorId || chatObj.researcherId,
      sessionId: chatObj.sessionId || sessionId,
      messages: chatObj.messages || [],
      state: chatObj.state || chatUtil.chatState.active,
      created: true
    },
    chatObj
  );
}

function getChat(chatObj, state, sessionId) {
  return findChat(chatObj, state, sessionId) || createChat(chatObj, sessionId);
}

function addResearcherToChat(chat, researcherId) {
  let updChat = chat;
  if (!updChat.researcherIds) {
    updChat.researcherIds = [researcherId];
  } else if (updChat.researcherIds.indexOf(researcherId) === -1) {
    updChat.researcherIds.push(researcherId);
  }
  return updChat;
}

function removeEmptyChatMessage(chat) {
  if (chat.messages) {
    chat.messages = chat.messages.filter(msg => msg.message !== '');
  }
  return chat;
}

function openChatWindow(payload, state, sessionId) {
  // If only one participantId was passed in, convert it to an array of one element.
  if (!payload.participantIds) {
    payload.participantIds = payload.participantId ? [payload.participantId] : [];
    delete payload.participantId;
  }

  let messageRead = false;
  const chat = getChat(payload, state, sessionId);
  chat.messages.forEach(m => {
    if (chat.researcherIds.indexOf(m.ownerId) === -1 && !m.read) {
      m.read = true;
      messageRead = true;
    }
  });
  chat.messageRead = messageRead;
  let clonedChat = cloneDeep(chat);
  clonedChat = addResearcherToChat(clonedChat, payload.researcherId);
  clonedChat.windowState = chatUtil.windowState.open;
  clonedChat.state = chatUtil.chatState.active;
  return removeEmptyChatMessage(clonedChat);
}

/**
 * When new messages arrive, add them to the chat object making sure to not add duplicates. Also update the "read" flag.
 *
 * @return boolean indicating whether any msgs were added
 */
function addNewMessagesToChat(chat, msgs) {
  msgs = msgs || [];
  let msgsAdded = false;
  msgs.forEach(newMsg => {
    const foundExistingMsg = find(chat.messages, existingMsg => {
      if (newMsg.id === existingMsg.id) {
        existingMsg.read = newMsg.read;
        return existingMsg;
      }
      if (!existingMsg.id && existingMsg.message === newMsg.message && existingMsg.ownerId === newMsg.ownerId) {
        // The existing msg has no ID, but the message and ownerIDs match.
        existingMsg.id = newMsg.id;
        existingMsg.read = newMsg.read;
        return existingMsg;
      }
    });
    if (!foundExistingMsg) {
      chat.messages.push(newMsg);
      msgsAdded = true;
    }
  });
  return msgsAdded;
}

function updateChatState(chat, clonedChat, state, sessionId) {
  const index = state[sessionId] && state[sessionId].chats.indexOf(chat);
  let newState;
  if (index === -1) {
    const clonedChats = cloneDeep(state[sessionId].chats);
    clonedChats.push(clonedChat);
    newState = {
      ...state,
      [sessionId]: {
        chats: clonedChats
      }
    };
  } else {
    newState = update(state, {
      [sessionId]: {
        chats: {
          $splice: [[index, 1, clonedChat]]
        }
      }
    });
  }
  return newState;
}

export const rdChatsReducer = handleActions(
  {
    [rdChatsActions.succeeded](state, { payload }) {
      const { sessionId, chats } = payload;
      return {
        ...state,
        [sessionId]: {
          chats: chats.chats
        }
      };
    },
    [openChatWindowAction.request](state, payload) {
      let innerPayload = payload.payload;
      const { sessionId } = innerPayload;

      // If payload has no ID and no questionJoinerId, see if there's already a matching chat object in the store.
      if (!innerPayload.id && !innerPayload.questionJoinerId) {
        let chat = null;
        if (state[sessionId]) {
          chat = find(state[sessionId].chats, chat => {
            return (
              !chat.questionJoinerId &&
              chat.participantIds.length === 1 &&
              chat.participantIds[0] === innerPayload.participantId
            );
          });
        }
        if (chat) {
          if (innerPayload.researcherId) {
            chat.researcherId = innerPayload.researcherId;
          }
          innerPayload = chat;
        }
      }

      // If payload has an ID, open the chat object from the store.
      innerPayload.id = innerPayload.id || idUtils.getId();
      const chat = findChat(innerPayload, state, sessionId);
      const clonedChat = openChatWindow(innerPayload, state, sessionId);

      return updateChatState(chat, clonedChat, state, sessionId);
    },
    [sendChatResponseAction.succeeded](state, payload) {
      const innerPayload = payload.payload;
      const { sessionId } = innerPayload;
      const chat = getChat(innerPayload, state, sessionId);
      let clonedChat = cloneDeep(chat);
      clonedChat.id = innerPayload.id;
      clonedChat = removeEmptyChatMessage(clonedChat);

      return updateChatState(chat, clonedChat, state, sessionId);
    },
    [newChatMessagesAction.succeeded](state, payload) {
      const innerPayload = payload.payload;
      const chats = innerPayload.chats;
      let newState;
      chats.forEach(chatObj => {
        let chat = getChat(chatObj, state, chatObj.sessionId);
        chat.researcherIds = uniq(union(chat.researcherIds, chatObj.researcherIds));
        const chatEnded = chatUtil.isEnded(chatObj);

        // Add all non-empty msgs to the chat window.
        chatObj = removeEmptyChatMessage(chatObj);
        let clonedChat = cloneDeep(chat);
        addNewMessagesToChat(clonedChat, chatObj.messages);

        // Manage the chat and window state for researcher
        if (chatEnded) {
          if (clonedChat.windowState && !chatUtil.isClosed(clonedChat.windowState)) {
            clonedChat.state = chatUtil.chatState.active;
          } else {
            clonedChat.state = chatUtil.chatState.ended;
          }
        }

        clonedChat = removeEmptyChatMessage(clonedChat);
        newState = updateChatState(chat, clonedChat, state, chatObj.sessionId);
      });

      return newState;
    },
    [closeChatWindowAction.request](state, payload) {
      const innerPayload = payload.payload;
      const { sessionId } = innerPayload;
      const chat = findChat(innerPayload, state, sessionId);
      let clonedChat = cloneDeep(chat);
      clonedChat.windowState = chatUtil.windowState.closed;
      clonedChat = removeEmptyChatMessage(clonedChat);
      return updateChatState(chat, clonedChat, state, sessionId);
    }
  },
  initialState
);
