import { createWebSocketSendActions, createWebSocketReceiveActions, createFetchActions } from './createFetchActions';

const emotionDataChannelSubscribeActions = createWebSocketSendActions('EMOTION_DATA_CHANNEL_SUBSCRIBE');
const newEmotionDataActions = createWebSocketReceiveActions('NEW_EMOTIONS');

const emotionReportFilesChannelSubscribeActions = createWebSocketSendActions('EMOTION_REPORT_FILES_CHANNEL_SUBSCRIBE');
const newEmotionReportFilesActions = createWebSocketReceiveActions('NEW_EMOTION_REPORT_FILES');

const exportEmotionDataActions = createFetchActions('EXPORT_EMOTION_DATA');

const fetchEmotionSettingConfigActions = createFetchActions('FETCH_EMOTION_SETTING_CONFIG');
const saveEmotionSettingConfigActions = createFetchActions('SAVE_EMOTION_SETTING_CONFIG');

export {
  emotionDataChannelSubscribeActions,
  newEmotionDataActions,
  emotionReportFilesChannelSubscribeActions,
  newEmotionReportFilesActions,
  exportEmotionDataActions,
  fetchEmotionSettingConfigActions,
  saveEmotionSettingConfigActions
};
