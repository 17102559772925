import { connect } from 'react-redux';
import {
  saveQuestionJoinerActions,
  fetchSurveyActions,
  surveyRefreshed,
  surveyImportSubscribeAction,
  generateSurveyRawExportActions,
  generateSurveyContentReviewActions,
  importSurveyAction,
  saveSurveyAsTemplateActions
} from '../../../../store/redux/actions/surveyActions';
import { fetchScreenerPreviewResultsActions } from '../../../../store/redux/actions/participantDataActions';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';
import { isClosed, isOpen } from '../../../../util/sessionUtil';
import { SurveyEditor } from './SurveyEditor';

const mapStateToProps = (state, props) => {
  const { abilities } = sessionUserSelector(state);
  const readOnly =
    isClosed(props.session) ||
    (abilities.hasProjectView && !abilities.hasProjectManage) ||
    (props.isSelfServe && isOpen(props.session));
  const surveyStore = state.surveys;
  const {
    fetchSurveyInProgress,
    saveQuestionJoinerFailed,
    saveQuestionJoinerInProgress,
    saveQuestionJoinerSuccess,
    refetchSurvey
  } = surveyStore;
  return {
    readOnly,
    fetchSurveyInProgress,
    saveQuestionJoinerInProgress,
    saveQuestionJoinerSuccess,
    saveQuestionJoinerFailed,
    refetchSurvey,
    surveyTemplate: state.surveyTemplate
  };
};

const mapDispatchToProps = dispatch => ({
  saveQuestionJoiner: payload => dispatch(saveQuestionJoinerActions.request(payload)),
  fetchSurvey: surveyId => dispatch(fetchSurveyActions.request({ surveyId })),
  surveyRefreshComplete: () => dispatch(surveyRefreshed()),
  fetchScreenerPreviewResults: params => dispatch(fetchScreenerPreviewResultsActions.request(params)),
  surveyImportChannelSubscribe: params => dispatch(surveyImportSubscribeAction.request(params)),
  generateSurveyRawExport: params => dispatch(generateSurveyRawExportActions.request(params)),
  generateSurveyContentReview: options => dispatch(generateSurveyContentReviewActions.request(options)),
  importSurvey: params => dispatch(importSurveyAction.request(params)),
  saveSurveyAsTemplate: template => dispatch(saveSurveyAsTemplateActions.request(template))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyEditor);
