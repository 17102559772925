import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Select } from 'antd';
import { InvokeModal } from 'webapp-common';

import './WhiteListModal.css';

const Option = Select.Option;

export const WhitelistModal = props => {
  const { keywordWhiteList, toggle, saveProject } = props;

  const [keywords, setKeywords] = useState(keywordWhiteList);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const save = () => {
    saveProject(keywords);
    setSaveEnabled(false);
  };

  const updateWhiteList = whitelist => {
    setKeywords(whitelist);
    setSaveEnabled(true);
  };

  const getWhitelistOptions = () => {
    return keywords ? keywords.filter(name => name).map(name => <Option key={name}>{name}</Option>) : null;
  };

  return (
    <InvokeModal
      showModal={true}
      modalTitle={intl.get('app.whitelist.modal.title')}
      toggle={toggle}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      save={save}
      enableSave={saveEnabled}
    >
      <div className="whitelist-modal-content">
        <div className="whitelist-modal-instructions">{intl.get('app.whitelist.modal.instructions')}</div>
        <Select
          mode="tags"
          value={keywords ? keywords : []}
          onChange={updateWhiteList}
          style={{ minWidth: '29rem' }}
          size="large"
          dropdownStyle={{ display: 'none' }}
        >
          {getWhitelistOptions()}
        </Select>
      </div>
    </InvokeModal>
  );
};
