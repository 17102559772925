import { get, post } from '../util/request';

const PRODEGE_API = '/a/api/prodege';

async function fetchProdegeQuestions(payload) {
  return get(`${PRODEGE_API}/questions/${payload.countryId}/${payload.enabledOnly}`)().then(res => res.body?.payload);
}

async function saveProdegeQuestion(question) {
  return post(`${PRODEGE_API}/question`)(question).then(res => res.body?.payload);
}

async function saveProdegeConfig(payload) {
  return post('/a/api/prodegeConfig')(payload).then(res => res.body?.payload || {});
}

async function feasibilityCheck(payload) {
  return post('/a/api/prodegeFeasibilityCheck')(payload).then(res => res.body?.payload || {});
}

async function getProdegeTestUrl(prodegeProjectId) {
  return get(`/a/api/prodegeTestUrl/${prodegeProjectId}`)().then(res => res.body?.payload || '');
}

export { fetchProdegeQuestions, saveProdegeQuestion, saveProdegeConfig, feasibilityCheck, getProdegeTestUrl };
