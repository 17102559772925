import React from 'react';
import intl from 'react-intl-universal';
import update from 'immutability-helper';
import { Input, Row } from 'reactstrap';
import { InvokeModal } from 'webapp-common';
import { MEDIA_TYPE, getStimCaption, getStimAltTag, ENGLISH } from '../../../../../util/joinerUtil';
import { toast } from '../../../../../util/toast';
import MediaPreview from '../../../../../components/core/mediaPreview/MediaPreview';
import SelectMediaModal from '../../modals/selectMedia/SelectMediaModal';

import './MediaChoiceModal.css';

class MediaChoiceModal extends React.Component {
  state = {
    stim: this.props.stim || {},
    showSelectMediaModal: false
  };

  componentDidMount = () => {
    if (!this.state.stim.media) {
      // Show the select-media modal if in 'add' mode
      this.toggleSelectMediaModal();
    }
  };

  updateCaption = e => {
    const { language, viewLanguage } = this.props;
    const setCaption = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const { stim } = this.state;
    this.setState({
      ...this.state,
      stim: {
        ...stim,
        caption: setCaption ? e.target.value : stim.caption,
        origCaption: setOrig ? e.target.value : stim.origCaption
      }
    });
  };

  updateAltTag = e => {
    const { language, viewLanguage } = this.props;
    const setAltTag = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const { stim } = this.state;
    this.setState({
      ...this.state,
      stim: {
        ...stim,
        altTag: setAltTag ? e.target.value : stim.altTag,
        origAltTag: setOrig ? e.target.value : stim.origAltTag
      }
    });
  };

  onMediaUpdate = media => {
    if (media.type !== MEDIA_TYPE.image) {
      toast.error({ text: intl.get('app.choiceRowMediaTypeError', {}) });
      return;
    }
    const state = update(this.state, {
      stim: {
        media: { $set: media }
      },
      showSelectMediaModal: { $set: false }
    });
    this.setState(state);
  };

  toggleSelectMediaModal = () => {
    if (!this.props.readOnly) {
      this.setState({
        showSelectMediaModal: !this.state.showSelectMediaModal
      });
    }
  };

  onSave = () => {
    this.props.onSave(this.state.stim);
  };

  render() {
    const { toggle, readOnly, language, viewLanguage } = this.props;
    const { stim } = this.state;
    const media = stim.media || {};
    const caption = getStimCaption(stim, language, viewLanguage);
    const altTag = getStimAltTag(stim, language, viewLanguage);
    const saveEnabled = !!stim.media;

    return (
      <InvokeModal
        showModal
        toggle={toggle}
        className="media-choice-modal"
        modalTitle={intl.get('app.title.mediaChoice')}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        save={this.onSave}
        enableSave={saveEnabled}
      >
        <Row>
          <div />
          <MediaPreview
            mediaType={media.type}
            media={media}
            text={intl.get('app.clickToChange')}
            onClick={this.toggleSelectMediaModal}
            readOnly={readOnly}
          />
        </Row>
        <Row>
          <div className="label">{intl.get('app.caption')}</div>
          <Input value={caption} onChange={this.updateCaption} />
        </Row>
        <Row>
          <div className="label">{intl.get('app.altText')}</div>
          <Input value={altTag} onChange={this.updateAltTag} />
        </Row>
        {this.state.showSelectMediaModal && (
          <SelectMediaModal toggle={this.toggleSelectMediaModal} onMediaUpdate={this.onMediaUpdate} />
        )}
      </InvokeModal>
    );
  }
}

export default MediaChoiceModal;
