import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { OPERATORS } from '../../../../../../util/joinerUtil';
import { PARTICIPANT_FLAG_COLORS } from '../../../../../../util/researchDashboardUtil';
import { FlagIcon } from '../../../../../../components/icons/Icons';

const getOperatorSelectList = () => {
  return [
    <option value={OPERATORS.eq} key="op1">
      {intl.get('app.surveyRules.operator.is')}
    </option>,
    <option value={OPERATORS.ne} key="op2">
      {intl.get('app.surveyRules.operator.isNot')}
    </option>,
    <option value={OPERATORS.notNull} key="op3">
      {intl.get('app.any').toLowerCase()}
    </option>,
    <option value={OPERATORS.null} key="op4">
      {intl.get('app.surveyRules.operator.notFlagged')}
    </option>
  ];
};

const ParticipantFlagCondition = props => {
  const { ruleIndex, condition, conditionIndex, updateHandlers, readOnly } = props;

  useEffect(() => {
    if (!condition.operator) {
      updateHandlers.setRuleConditionOperator(ruleIndex, conditionIndex, OPERATORS.eq);
    }
  }, []);

  function getGroupInputs() {
    return Object.keys(PARTICIPANT_FLAG_COLORS).map(grp => {
      return (
        <label key={grp} disabled={readOnly}>
          <Input
            type="radio"
            style={{ top: 'unset' }}
            name={`condition_${ruleIndex}_${conditionIndex}`}
            checked={condition.values.includes(grp)}
            onChange={() => updateHandlers.updateRuleConditionValues(ruleIndex, conditionIndex, grp, true)}
          />
          <FlagIcon className={PARTICIPANT_FLAG_COLORS[grp]} title={intl.get(`app.participantFlag.${grp}.color`)} />
        </label>
      );
    });
  }

  return (
    <>
      <div>
        <div>{intl.get('app.operator')}:</div>
        <Input
          type="select"
          value={condition.operator}
          disabled={readOnly}
          onChange={e => updateHandlers.setRuleConditionOperator(ruleIndex, conditionIndex, e.target.value)}
        >
          {getOperatorSelectList()}
        </Input>
      </div>

      {(condition.operator === OPERATORS.eq || condition.operator === OPERATORS.ne) && (
        <div>
          <div>{intl.get('app.value')}:</div>
          <div className="choice-checkboxes">{getGroupInputs()}</div>
        </div>
      )}
    </>
  );
};

export default ParticipantFlagCondition;
