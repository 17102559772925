// Ported from firefly
import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { chain, clone, find, findIndex, truncate } from 'lodash';
import { RESPONSE_SET_TYPE, isBipolar } from './joinerUtil';
import { getFlattenedJoinersList } from './conceptRotationUtil';

const TRUNC_STRING_LENGTH = 15;

const TERM_TYPE = {
  bracket: 'bracket',
  operator: 'operator',
  term: 'term'
};

// From FilterConstants.java
const FIELD_PREFIX = {
  A: 'A',
  C: 'C',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S'
};

const VALUE_PREFIX = {
  A: 'A',
  P: 'P'
};

const FILTER_TEMPLATES = {
  DEFAULT_FILTER: {
    name: 'Video Respondents',
    count: 0,
    expression: 'R."videoCaptureEnabled":A."true";',
    type: 'DEFAULT_FILTER'
    //originalTextFields: ['Favorite']
  }
};

const FAVORITE_FIELD_NAME = 'Favorite';
const PARTICIPANT_GROUP_FIELD_NAME = 'participantGroup';

function truncateString(string, length) {
  return truncate(string, { length });
}

function getFilterByName(name, filters) {
  return find(filters, { name });
}

function getSelectedFilter(selected, type, filters, quotaFilters) {
  if (type === 'GENERAL_FILTER') {
    return find(filters, { name: selected });
  }
  if (type === 'DEFAULT_FILTER') {
    return FILTER_TEMPLATES.DEFAULT_FILTER;
  }
  return find(quotaFilters, { id: selected });
}

/*
 * Returns an array of term and operator objects that make up the filter expression.
 */
function getFilterExpression(expr) {
  if (expr === '' || expr == null) {
    return [];
  }

  return expr
    .split(' ')
    .filter(term => term !== '')
    .map(term => {
      switch (term.toLowerCase()) {
        case '(':
        case ')':
          return {
            type: TERM_TYPE.bracket,
            value: term
          };
        case 'or':
        case 'and':
        case 'not':
        case '!':
          return {
            type: TERM_TYPE.operator,
            value: term
          };
        default:
          try {
            const termPattern = /(.(?:[0-9,-]+)?)."(.*)"([:,>,<])(.(?:[0-9,-]+)?)."(.*)"/;
            const matches = term.match(termPattern);
            return {
              type: TERM_TYPE.term,
              field: unescape(matches[2]),
              fieldPrefix: matches[1],
              operator: matches[3],
              value: unescape(matches[5]),
              valuePrefix: matches[4]
            };
          } catch (e) {
            // invalid filter expression
            return {};
          }
      }
    });
}

/*
 * Returns the HTML element for a complete filter expression
 */
function renderFilterExpression(filter, joiners, keyTransformMap, consolidatedQuestion) {
  let filterStr = '';
  const filterStringArray = [];
  getFilterStrings(filter.expression, 'short', joiners, keyTransformMap, consolidatedQuestion).forEach(
    (term, index) => {
      let fieldText = term.matrixQuestionTitle ? `${term.matrixQuestionTitle} - ${term.field}` : term.field;
      fieldText = fieldText ? fieldText.replace(/%20/g, ' ') : fieldText;
      const classes = {
        term: true,
        bracket: term.type === TERM_TYPE.bracket,
        operator: term.type === TERM_TYPE.operator
      };
      if (term.type !== TERM_TYPE.term) {
        const op = intl.get(`app.FILTER_TERM_${term.value.toUpperCase()}`);
        filterStr += `${op} `;
        filterStringArray.push(<span className={classnames(classes)}> {op} </span>);
      }
      if (term.field === FAVORITE_FIELD_NAME) {
        // favorite values are true/false, map to the friendly strings:
        term.value = getFavoriteString(term.value);
      } else if (term.field === PARTICIPANT_GROUP_FIELD_NAME) {
        // Map generic GROUP# to color
        term.value = intl.get(`app.participantFlag.${term.value}.color`) || term.value;
      }
      if (term.type === TERM_TYPE.term) {
        const operator = term.operator || ':';
        filterStr += `${fieldText}${operator} ${term.value}; `;
        filterStringArray.push(
          <span
            className={classnames(classes)}
            key={`filter-expr-${index}`}
            style={{ background: '#c3daea', padding: '0.125rem 0.5rem', borderRadius: '4px' }}
          >
            <span className="field">
              <span>
                {truncateString(fieldText, TRUNC_STRING_LENGTH)} {operator}{' '}
              </span>
            </span>
            <span className="value">{truncateString(term.value, TRUNC_STRING_LENGTH)}</span>
            <span>; </span>
          </span>
        );
      }
    }
  );
  return <span title={filterStr}>{filterStringArray}</span>;
}

function renderDataPointExpression(filter, joiners, keyTransformMap, consolidatedQuestion) {
  let filterStr = '';
  let fromQuestion = '';
  let fromAnswer = '';
  let filterStringObj = {};
  getFilterStrings(filter.expression, 'short', joiners, keyTransformMap, consolidatedQuestion).forEach(
    (term, index) => {
      let fieldText = term.matrixQuestionTitle ? `${term.matrixQuestionTitle} - ${term.field}` : term.field;
      fieldText = fieldText ? fieldText.replace(/%20/g, ' ') : fieldText;
      if (term.field === FAVORITE_FIELD_NAME) {
        term.value = getFavoriteString(term.value);
      }
      if (term.type === TERM_TYPE.term) {
        const operator = term.operator || ':';
        filterStr += `${fieldText} ${operator}${term.value};`;
        fromQuestion = `${term.fieldPrefix}) ${term.field}`;
        fromAnswer = `${term.valuePrefix}) ${term.value}`;
        const key = `filter-expr-${index}`;
        filterStringObj = { ...filterStringObj, filterStr, fromAnswer, fromQuestion, key };
      }
    }
  );
  return { ...filterStringObj, ...filter };
}

function getFavoriteString(str) {
  const map = {
    true: intl.get('app.isFavorite'),
    false: intl.get('app.isNotFavorite')
  };
  return map[str] || str;
}

/*
 * Return the proper value from the valueEntity object.
 */
function getValue(type, valueEntity) {
  if (type !== 'long') {
    return valueEntity.value.abbreviatedValue || valueEntity.value.entryType;
  }
  const { imageStim } = valueEntity.value;
  if (imageStim) {
    return imageStim.caption;
  }
  return valueEntity.value.value;
}

/*
 * Returns an array of filter objects that make up the complete filter expression.
 */
function getFilterStrings(filter, type, joiners, keyTransformMap, consolidatedQuestion) {
  return getFilterExpression(filter).map(term => {
    const fieldPrefix = term.fieldPrefix;
    if (!fieldPrefix) {
      return term;
    }
    const joinerList =
      (consolidatedQuestion && getFlattenedJoinersList(consolidatedQuestion)) || getFlattenedJoinersList(joiners);
    if (fieldPrefix.indexOf('C') > -1) {
      const concepts = chain(joinerList)
        .filter(jn => jn.conceptRotation)
        .map(jn => jn.conceptRotation.concepts)
        .flatten()
        .value();
      const concept = find(concepts, c => c.id === term.field);
      return {
        field: concept ? concept.title : '',
        fieldPrefix,
        type: term.type,
        value: intl.get('app.positionNumLabel', { pos: term.value }),
        valuePrefix: term.valuePrefix
      };
    }
    if (fieldPrefix.indexOf('Q') > -1) {
      let field,
        matrixQuestion,
        matrixQuestionTitle,
        value = '';
      const joiner = find(joinerList, j => j.def && j.def.id === term.field);
      if (!joiner) {
        return {
          field,
          fieldPrefix,
          type: term.type,
          value,
          valuePrefix: term.valuePrefix,
          matrixQuestion,
          matrixQuestionTitle
        };
      }
      field = type === 'long' ? joiner.def.question.prompt : joiner.researchPrompt;
      field = field || joiner.def.question.prompt;
      if (joiner.conceptRotation) {
        value = find(joiner.conceptRotation.concepts, { id: term.value }).title;
      } else {
        switch (joiner.def.responseSet.type) {
          case RESPONSE_SET_TYPE.open:
            value = term.value;
            break;
          case RESPONSE_SET_TYPE.ranked:
          case RESPONSE_SET_TYPE.matrix:
            matrixQuestion = joiner.def.question.prompt;
            matrixQuestionTitle = joiner.researchPrompt;
            const rowId = term.value.split('+')[0];
            const columnId = term.value.split('+')[1];
            const { responseSet } = joiner.def;
            const entryRows = isBipolar(responseSet) ? responseSet.entries.bipolarRows : responseSet.entries.rows;
            const rowEntity = entryRows.find(row => row.id === rowId);
            const columnEntity = responseSet.entries.columnData.columns.find(column => column.id === columnId).value;
            value = type !== 'long' ? columnEntity.abbreviatedValue : columnEntity.value;
            field = getValue(type, rowEntity);
            break;
          case RESPONSE_SET_TYPE.multi:
            const valueEntity = joiner.def.responseSet.choices.find(choice => choice.id === term.value);
            value = getValue(type, valueEntity);
            value = value || valueEntity.value.value;
            break;
          default:
            break;
        }
      }

      return {
        field,
        fieldPrefix,
        type: term.type,
        value,
        valuePrefix: term.valuePrefix,
        matrixQuestion,
        matrixQuestionTitle
      };
    }

    term.field = keyTransformMap?.[term.field] || term.field;
    return term;
  });
}

function generateParticipantDataFilterExpression(field, value) {
  const filter = {
    field,
    value,
    operator: ':',
    fieldPrefix: FIELD_PREFIX.P,
    valuePrefix: VALUE_PREFIX.A
  };
  return makeCleanFilterExpression(filter);
}

function makeCleanFilterExpression(term) {
  if (!term.field) {
    return '';
  }
  const filterValue = term.value ? term.value.replace(/ /g, '%20') : '';
  return `${term.fieldPrefix}."${term.field.replace(/ /g, '%20')}"${term.operator}${
    term.valuePrefix
  }."${filterValue}";`;
}

/*
 * Filter names are unique. If a filter is saved with an existing name it will replace the existing filter.
 * This function returns an updated collection of filters after adding or editing the saved filter.
 */
function addOrUpdateFilter({ savedFilter, existingFilters }) {
  const filters = clone(existingFilters);
  const { name } = savedFilter;
  const filterIndex = findIndex(filters, { name });
  if (filterIndex === -1) {
    filters.push(savedFilter);
    return filters;
  }
  filters[filterIndex] = savedFilter;
  return filters;
}

/*
 * Verify that all 'term' expressions in the filter are for participant data
 */
function isParticipantDataFilter(filter) {
  const expressionObjects = getFilterExpression(filter.expression);
  return !expressionObjects.some(obj => obj.type === TERM_TYPE.term && obj.fieldPrefix !== FIELD_PREFIX.P);
}

function getFilterJsonString(filter) {
  return JSON.stringify({
    expression: filter.expression,
    id: filter.id,
    name: filter.name,
    type: filter.type
  });
}

function getFilterJsonStrings(filters) {
  return filters ? filters.map(f => getFilterJsonString(f)) : [];
}

export const filterUtil = {
  TERM_TYPE,
  FILTER_TEMPLATES,
  FAVORITE_FIELD_NAME,
  truncateString,
  getFilterByName,
  getSelectedFilter,
  getFilterExpression,
  renderFilterExpression,
  getFavoriteString,
  getFilterStrings,
  generateParticipantDataFilterExpression,
  makeCleanFilterExpression,
  addOrUpdateFilter,
  isParticipantDataFilter,
  renderDataPointExpression,
  getFilterJsonString,
  getFilterJsonStrings
};
