import React from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { getRDConfig } from '../../../../../store/redux/selectors/researchDashboardSelector';
import { saveRDConfig } from '../../../../../util/researchDashboardUtil';

import './Sentiments.css';

const POSITIVE_FILTER = 'POSITIVE';
const NEUTRAL_FILTER = 'NEUTRAL';
const NEGATIVE_FILTER = 'NEGATIVE';

export const Sentiments = props => {
  const { sessionId, questionDefId, sentiments, openSentimentSummary } = props;

  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);

  const getToggleSentiment = filter => {
    const configMap = rdConfig.configs.questionsConfig.questionsConfigMap[questionDefId] || {};
    const list = configMap.selectedSentiments || [];
    const set = new Set(list);
    return set.has(filter);
  };

  const { POSITIVE = 0, NEUTRAL = 0, NEGATIVE = 0 } = sentiments || {};
  const total = POSITIVE + NEUTRAL + NEGATIVE;
  const positivePercent = `${Math.round((POSITIVE * 100) / total) || 0}%`;
  const neutralPercent = `${Math.round((NEUTRAL * 100) / total) || 0}%`;
  const negativePercent = `${Math.round((NEGATIVE * 100) / total) || 0}%`;

  const setOrUnsetSentiment = sentiment => {
    const clonedRdConfig = cloneDeep(rdConfig);
    const { questionsConfigMap } = clonedRdConfig.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    const list = questionsConfigMap[questionDefId].selectedSentiments || [];
    const set = new Set(list);
    if (set.has(sentiment)) {
      set.delete(sentiment);
    } else {
      set.add(sentiment);
    }
    questionsConfigMap[questionDefId].selectedSentiments = Array.from(set);
    saveRDConfig(clonedRdConfig);
  };

  return (
    <>
      <div className="sentiments-header">
        <h6>{intl.get('app.sentiment')}</h6>
        <div className="show-sentiment-chart-link link" onClick={openSentimentSummary}>
          {intl.get('app.showSummary')}
        </div>
      </div>
      <div className="sentiments">
        <div
          onClick={() => setOrUnsetSentiment(POSITIVE_FILTER)}
          className={getToggleSentiment(POSITIVE_FILTER) ? 'clickable selected' : 'clickable'}
        >
          <div>{intl.get('app.positive')}</div>
          <div>{POSITIVE}</div>
        </div>
        <div title={positivePercent}>
          <div className="sentiment-positive" style={{ width: positivePercent }} />
          <div />
        </div>
        <div
          onClick={() => setOrUnsetSentiment(NEUTRAL_FILTER)}
          className={getToggleSentiment(NEUTRAL_FILTER) ? 'clickable selected' : 'clickable'}
        >
          <div>{intl.get('app.neutral')}</div>
          <div>{NEUTRAL}</div>
        </div>
        <div title={neutralPercent}>
          <div className="sentiment-neutral" style={{ width: neutralPercent }} />
          <div />
        </div>
        <div
          onClick={() => setOrUnsetSentiment(NEGATIVE_FILTER)}
          className={getToggleSentiment(NEGATIVE_FILTER) ? 'clickable selected' : 'clickable'}
        >
          <div>{intl.get('app.negative')}</div>
          <div>{NEGATIVE}</div>
        </div>
        <div title={negativePercent}>
          <div className="sentiment-negative" style={{ width: negativePercent }} />
          <div />
        </div>
      </div>
    </>
  );
};
