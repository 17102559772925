import { createStructuredSelector, createSelector } from 'reselect';

const selectSessionListDetails = state => state.sessionListDetails;
const selectSessionListRequested = sessionListDetails => sessionListDetails.sessionListRequested;
const selectSessions = sessionListDetails => sessionListDetails.sessions;
const selectScreenerSessions = sessionListDetails => sessionListDetails.screenerSessions;

const sessionListSelector = createSelector(
  selectSessionListDetails,
  createStructuredSelector({
    sessionListRequested: selectSessionListRequested,
    sessions: selectSessions,
    screenerSessions: selectScreenerSessions
  })
);

export { sessionListSelector };
