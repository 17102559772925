import React, { useEffect, useReducer } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Switch } from 'antd';
import { isEqual } from 'lodash';
import intl from 'react-intl-universal';
import appConfig from '../../../appConfig';
import { saveProject as saveProjectApi } from '../../../api/projectsApi';
import { removeRecentProject } from '../../../util/projectUtil';
import { ConnectedArchiveModal } from '../../../containers/projectDetails/projectArchiveForm';

const reducer = (state, payload) => ({ ...state, ...payload });

export const ProjectDetails = props => {
  const { project = {}, saveProject, editMode, updateProjectName, sessionUser, readOnly } = props;

  const navigate = useNavigate();

  const [state, setState] = useReducer(reducer, {
    projectDetails: {
      name: intl.get('app.newProject')
    },
    showArchive: false
  });

  useEffect(() => {
    if (project.projectDetails) {
      setState({
        projectDetails: project.projectDetails
      });
    }
  }, [project]);

  useEffect(() => {
    updateProjectName(state.projectDetails.name);
  }, [state.projectDetails.name]);

  const onProjectChange = (value, field) =>
    setState({
      projectDetails: {
        ...state.projectDetails,
        [field]: value
      }
    });

  const isSaveDisabled = () => {
    const { projectDetails } = state;
    return (
      (!projectDetails.id && projectDetails.name.trim() === intl.get('app.newProject')) ||
      (projectDetails.id && isEqual(projectDetails, project.projectDetails))
    );
  };

  async function saveNewProject() {
    props.showLoader(true);
    const savedProject = await saveProjectApi(state.projectDetails);
    props.showLoader(false);
    if (savedProject) {
      navigate(`${appConfig.projectPagePath}/${savedProject.id}/details`);
    }
  }

  const save = () => {
    if (state.projectDetails.id) {
      saveProject(state.projectDetails);
    } else {
      saveNewProject();
    }
  };

  const toggleArchiveModal = () => {
    setState({
      showArchive: !state.showArchive
    });
  };

  const archive = () => {
    props.archiveProject({ projectId: state.projectDetails.id, name: state.projectDetails.name });
    navigate(appConfig.archivedProjectsPath);
    removeRecentProject(state.projectDetails.id, sessionUser.clientId);
    toggleArchiveModal();
  };

  function onCancel() {
    navigate(appConfig.projectsPath);
  }

  const hasMultipleSurveys = state.projectDetails.activeSessionCount > 1 || state.projectDetails.totalSessionCount > 1;
  const wrapperStyle = hasMultipleSurveys
    ? { cursor: 'not-allowed', display: 'inline-block' }
    : { display: 'inline-block' };
  const switchStyle = hasMultipleSurveys ? { backgroundColor: '#1890ff', opacity: '0.4', pointerEvents: 'none' } : {};

  return (
    <>
      <div>
        <Row style={{ alignItems: 'center' }}>
          <Col sm="1" style={{ marginRight: '2rem' }}>
            <span>{intl.get('app.projectName')}</span>
          </Col>
          <Col sm="4">
            <Input
              value={state.projectDetails.name}
              disabled={readOnly}
              onChange={e => onProjectChange(e.target.value, 'name')}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="5">
            <Label className="fw-600">{intl.get('app.surveys')}</Label>
          </Col>
        </Row>
        <Row>
          <Col sm="10">
            <Label>{intl.get('app.enable.multipleSurveys')}:</Label>
            <div style={wrapperStyle} title={hasMultipleSurveys ? intl.get('app.multipleSurveys.disabledMessage') : ''}>
              <Switch
                className="ms-3"
                style={switchStyle}
                checked={state.projectDetails.multipleSurveysEnabled}
                disabled={readOnly}
                onChange={e => onProjectChange(e, 'multipleSurveysEnabled')}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="5">
            <Button color="primary" onClick={save} disabled={readOnly || isSaveDisabled()}>
              {intl.get('app.save')}
            </Button>
            <Button color="secondary" className="ms-5" onClick={onCancel}>
              {intl.get('app.cancel')}
            </Button>
          </Col>
        </Row>
      </div>
      <Row className="mt-5">
        <Col sm="5">
          <Label className="fw-600">{intl.get('app.archiveProject')}</Label>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          <a href="/projects/archived" className="link">
            {intl.get('app.archivedProjects')}{' '}
          </a>{' '}
          {intl.get('app.archiveProjectText')}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button color="secondary" onClick={toggleArchiveModal} disabled={readOnly || !editMode}>
            {intl.get('app.archiveProject')}
          </Button>
        </Col>
      </Row>
      {state.showArchive && (
        <ConnectedArchiveModal
          toggle={toggleArchiveModal}
          onSave={archive}
          projectName={state.projectDetails.name}
          projectId={state.projectDetails.id}
        />
      )}
    </>
  );
};
