import { connect } from 'react-redux';
import {
  fetchClientsActions,
  createClientActions,
  saveClientActions,
  archiveClientActions
} from '../../../store/redux/actions/clientAdminActions';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { clientAdminSelector } from '../../../store/redux/selectors/clientAdminSelector';
import { ClientsPage } from './ClientsPage';

const mapStateToProps = state => {
  const selector = clientAdminSelector()(state);
  const tenant = sessionUserSelector(state).sessionTenant;
  return {
    tenant,
    clients: selector.clients,
    fetchClientsInProgress: selector.fetchClientsInProgress,
    createClientInProgress: selector.createClientInProgress,
    createClientSuccess: selector.createClientSuccess,
    saveClientInProgress: selector.saveClientInProgress,
    saveClientSuccess: selector.saveClientSuccess
  };
};

const mapDispatchToProps = dispatch => ({
  fetchClients: payload => dispatch(fetchClientsActions.request(payload)),
  createClient: payload => dispatch(createClientActions.request(payload)),
  saveClient: payload => dispatch(saveClientActions.request(payload)),
  archiveClient: payload => dispatch(archiveClientActions.request(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
