import { connect } from 'react-redux';
import { getRDConfig, getRDEnrollees } from '../../../../store/redux/selectors/researchDashboardSelector';
import { ParticipantMetadata } from './ParticipantMetadata';

const mapStateToProps = (state, props) => {
  const { sessionId } = props;
  const rdConfig = getRDConfig(state, sessionId);
  const enrolleesInfo = getRDEnrollees(state, sessionId);
  const { columnOrder, keyTransformMap } = enrolleesInfo;
  return {
    participantDataColumnConfig: rdConfig.configs.participantDataColumnConfig,
    columnOrder,
    keyTransformMap
  };
};

export default connect(mapStateToProps)(ParticipantMetadata);
