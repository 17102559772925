import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { Select } from 'antd';
import { cloneDeep } from 'lodash';
import { toast } from '../../../../../util/toast';
import { CommonJoinerEditFields } from '../../joinerEditor';
import MediaChoiceModal from '../../modals/mediaChoice/MediaChoiceModal';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';
import { ImportQuestionButton } from '../../modals/importQuestion/ImportQuestionButton';
import { ImportQuestionModal } from '../../modals/importQuestion/ImportQuestionModal';
import {
  RESPONSE_SET_TYPE,
  MATRIX_CHOICES,
  getChoiceValue,
  getChoiceValue2,
  getStimCaption,
  setChoiceValues,
  setChoiceValue2s,
  ENGLISH,
  OPTION_TYPE
} from '../../../../../util/joinerUtil';
import { mediaUtil } from '../../../../../util/mediaUtil';
import { TranslateLabel } from './TranslateLabel';

const Option = Select.Option;

class MatrixEdit extends React.Component {
  constructor(props) {
    super(props);
    const { joiner, joiners, updateHandlers } = props;
    const option = updateHandlers.findReferencedQuestion(joiner);
    const hasReferenced = option.length > 0;
    const referencedJoiner = hasReferenced ? updateHandlers.getReferencedJoiner(joiners, option) : {};
    const multipleChoiceQuestions = hasReferenced
      ? updateHandlers.onChooseAnswersChange(joiners, joiner, MATRIX_CHOICES.choiceAnswers)
      : [];
    let selectedRowLabelOption = null;
    if (joiner.def.responseSet.options.filter(o => o.type === OPTION_TYPE.bipolar).length > 0) {
      selectedRowLabelOption = MATRIX_CHOICES.bipolar;
    } else {
      selectedRowLabelOption = hasReferenced ? MATRIX_CHOICES.choiceAnswers : MATRIX_CHOICES.enterRows;
    }
    this.state = {
      showSelectMediaModal: false,
      showImportQuestionModal: false,
      expandRows: true,
      expandColumns: true,
      selectedRowLabelOption: selectedRowLabelOption,
      selectedChoiceQuestionId: referencedJoiner?.id,
      multipleChoiceQuestions
    };
  }
  toggleExpand = target => {
    this.setState({ [target]: !this.state[target] });
  };

  updateRowValue = (e, index) => {
    const row = cloneDeep(this.props.joiner.def.responseSet.entries.rows[index]);
    setChoiceValues(this.props.language, this.props.viewLanguage, e.target.value, row.value, false);
    this.props.updateHandlers.updateMatrixRowValue(row, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateRowAbbrevValue = (e, index) => {
    const row = cloneDeep(this.props.joiner.def.responseSet.entries.rows[index]);
    row.value.abbreviatedValue = e.target.value.trim();
    this.props.updateHandlers.updateMatrixRowValue(row, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateBipolarRowValue = (e, index) => {
    const row = cloneDeep(this.props.joiner.def.responseSet.entries.bipolarRows[index]);
    setChoiceValues(this.props.language, this.props.viewLanguage, e.target.value, row.value, true);
    this.props.updateHandlers.updateMatrixBipolarRowValue(row, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateBipolarRowValue2 = (e, index) => {
    const row = cloneDeep(this.props.joiner.def.responseSet.entries.bipolarRows[index]);
    setChoiceValue2s(this.props.language, this.props.viewLanguage, e.target.value, row.value);
    this.props.updateHandlers.updateMatrixBipolarRowValue(row, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateBipolarRowAbbrevValue = (e, index) => {
    const row = cloneDeep(this.props.joiner.def.responseSet.entries.bipolarRows[index]);
    row.value.abbreviatedValue = e.target.value.trim();
    this.props.updateHandlers.updateMatrixBipolarRowValue(row, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  toggleSelectMediaModal = (rowIndex, rowStim) => {
    if (!this.props.readOnly) {
      this.setState({
        showSelectMediaModal: !this.state.showSelectMediaModal,
        rowIndex,
        rowStim
      });
    }
  };

  toggleImportQuestionModal = imported => {
    this.setState({ showImportQuestionModal: !this.state.showImportQuestionModal, imported });
  };

  onMediaUpdate = stim => {
    this.props.updateHandlers.updateMediaForMatrixRow(stim, this.state.rowIndex);
    this.toggleSelectMediaModal();
  };

  insertRow = index => {
    if (!this.props.readOnly) {
      this.props.updateHandlers.insertMatrixRow(index);
    }
  };

  removeRow = index => {
    if (!this.props.readOnly) {
      this.props.updateHandlers.removeMatrixRow(index);
    }
  };

  getRows = rows => {
    const { language, viewLanguage, readOnly } = this.props;
    if (rows.length < 1) {
      this.props.updateHandlers.insertMatrixRow(-1);
      return;
    }
    return rows
      .filter(row => !row.value.disable)
      .map(row => {
        const index = parseInt(row.index, 10);
        const rowValue = getChoiceValue(row, language, viewLanguage);
        const showRemove =
          rows.length > 1 ||
          (row.value &&
            (row.value.abbreviatedValue ||
              row.value.value ||
              row.value.origValue ||
              row.value.imageStim ||
              row.value.scalar));
        return (
          <div className="editor-right-column" key={row.id}>
            <div className="labels">
              {row.value.imageStim ? (
                <Input
                  className="label-input"
                  value={getStimCaption(row.value.imageStim, language, viewLanguage)}
                  disabled={true}
                />
              ) : (
                <Input
                  className="label-input mb-2"
                  value={rowValue}
                  disabled={readOnly}
                  onChange={e => this.updateRowValue(e, index)}
                />
              )}
            </div>
            <React.Fragment>
              <div
                className="media-column media-input mx-2 mb-1"
                onClick={() => this.toggleSelectMediaModal(index, row.value.imageStim)}
              >
                {row.value.imageStim && row.value.imageStim.media ? (
                  <img className="real-image" src={mediaUtil.getMediaUrl(row.value.imageStim.media)} alt="" />
                ) : (
                  <i className="far fa-image fa-2x image" />
                )}
              </div>
              <Input
                className="row-title-input"
                value={this.state.imported && viewLanguage !== ENGLISH ? '' : row.value.abbreviatedValue}
                disabled={readOnly}
                onChange={e => this.updateRowAbbrevValue(e, index)}
              />
            </React.Fragment>
            <div className="ms-5">
              {!readOnly && (
                <AddRemoveIcons
                  onAdd={() => this.insertRow(index)}
                  onRemove={showRemove ? () => this.removeRow(index) : null}
                />
              )}
            </div>
          </div>
        );
      });
  };

  insertBipolarRow = index => {
    if (!this.props.readOnly) {
      this.props.updateHandlers.insertBipolarRow(index);
    }
  };

  removeBipolarRow = index => {
    if (!this.props.readOnly) {
      this.props.updateHandlers.removeBipolarRow(index);
    }
  };

  getBipolarRows = rows => {
    const { language, viewLanguage, readOnly } = this.props;
    if (rows.length < 1) {
      this.props.updateHandlers.insertBipolarRow(-1);
      return;
    }
    return rows
      .filter(row => !row.value.disable)
      .map(row => {
        const index = parseInt(row.index, 10);
        const rowValue = getChoiceValue(row, language, viewLanguage);
        const rowValue2 = getChoiceValue2(row, language, viewLanguage);
        const showRemove =
          rows.length > 1 ||
          (row.value &&
            (row.value.abbreviatedValue ||
              row.value.value ||
              row.value.origValue ||
              row.value.value2 ||
              row.value.origValue2 ||
              row.value.scalar));
        return (
          <div className="editor-right-column" key={row.id}>
            <div className="bipolar-labels">
              <Input
                className="label-input mb-2"
                value={rowValue}
                disabled={readOnly}
                onChange={e => this.updateBipolarRowValue(e, index)}
              />
            </div>
            <div className="bipolar-labels mx-2 mb-2">
              <Input
                className="label-input"
                value={rowValue2}
                disabled={readOnly}
                onChange={e => this.updateBipolarRowValue2(e, index)}
              />
            </div>
            <div className="bipolar-labels-title mb-2">
              <Input
                className="row-title-input"
                value={this.state.imported && viewLanguage !== ENGLISH ? '' : row.value.abbreviatedValue}
                disabled={readOnly}
                onChange={e => this.updateBipolarRowAbbrevValue(e, index)}
              />
            </div>
            <div className="ms-5">
              {!readOnly && (
                <AddRemoveIcons
                  onAdd={() => this.insertBipolarRow(index)}
                  onRemove={showRemove ? () => this.removeBipolarRow(index) : null}
                />
              )}
            </div>
          </div>
        );
      });
  };

  insertColumn = index => {
    if (!this.props.readOnly) {
      this.props.updateHandlers.insertMatrixColumn(index);
    }
  };

  removeColumn = index => {
    this.props.updateHandlers.removeMatrixColumn(index);
  };

  updateColumnValue = (e, index) => {
    const column = cloneDeep(this.props.joiner.def.responseSet.entries.columnData.columns[index]);
    setChoiceValues(this.props.language, this.props.viewLanguage, e.target.value, column.value, false);
    this.props.updateHandlers.updateMatrixColumnValue(column, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateColumnAbbrevValue = (e, index) => {
    const column = cloneDeep(this.props.joiner.def.responseSet.entries.columnData.columns[index]);
    column.value.abbreviatedValue = e.target.value.trim();
    this.props.updateHandlers.updateMatrixColumnValue(column, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateColumnScalar = (e, index) => {
    const column = cloneDeep(this.props.joiner.def.responseSet.entries.columnData.columns[index]);
    column.value.scalar = parseInt(e.target.value);
    this.props.updateHandlers.updateMatrixColumnValue(column, index);
  };

  getColumns = columns => {
    const { language, viewLanguage, readOnly, isScreener } = this.props;
    if (columns.length < 1) {
      return (
        <div className="mb-2">
          <div className="label editor-left-column" />
          <div className="editor-right-column add-input" onClick={() => this.insertColumn(-1)}>
            <div className="mt-1 add-area">
              <span className="add-text">+</span>
              <span className="ms-2 mt-2">{intl.get('app.addAnotherColumn')}</span>
            </div>
          </div>
        </div>
      );
    }
    return columns
      .filter(col => !col.value.disable)
      .map(column => {
        const index = parseInt(column.index, 10);
        const columnValue = getChoiceValue(column, language, viewLanguage);
        const showRemove =
          columns.length > 1 ||
          (column.value &&
            (column.value.abbreviatedValue ||
              column.value.value ||
              column.value.origValue ||
              column.value.imageStim ||
              column.value.scalar));
        return (
          <div className="mb-2" key={column.id}>
            <div className="label editor-left-column" />
            <div className="editor-right-column">
              <div className="choices me-3">
                <Input
                  className="choice-input"
                  value={columnValue}
                  disabled={readOnly}
                  onChange={e => this.updateColumnValue(e, index)}
                />
              </div>
              {!isScreener && (
                <Input
                  className="abbreviate-input mx-2"
                  value={this.state.imported && viewLanguage !== ENGLISH ? '' : column.value.abbreviatedValue}
                  disabled={readOnly}
                  onChange={e => this.updateColumnAbbrevValue(e, index)}
                />
              )}
              {!isScreener && (
                <Input
                  className="value-input"
                  type="number"
                  min="0"
                  max="10"
                  value={column.value.scalar}
                  disabled={readOnly}
                  onChange={e => this.updateColumnScalar(e, index)}
                />
              )}
              <div className="ms-5">
                {!readOnly && (
                  <AddRemoveIcons
                    onAdd={() => this.insertColumn(index)}
                    onRemove={showRemove ? () => this.removeColumn(index) : null}
                  />
                )}
              </div>
            </div>
          </div>
        );
      });
  };

  importQuestion = question => {
    this.props.updateHandlers.importQuestion(
      question,
      RESPONSE_SET_TYPE.matrix,
      this.props.viewLanguage,
      this.props.language
    );
    this.toggleImportQuestionModal(true);
  };

  onChooseAnswersChange = value => {
    const { joiner, joiners = [], updateHandlers } = this.props;
    const multipleChoiceQuestions = updateHandlers.onChooseAnswersChange(joiners, joiner, value);
    this.setState({ multipleChoiceQuestions, selectedRowLabelOption: value });
  };

  pickChoiceQuestion = joinerId => {
    const question = this.state.multipleChoiceQuestions.find(j => j.id === joinerId);
    this.props.updateHandlers.pickChoiceQuestion(question, this.props.joiner);
    this.setState({
      selectedChoiceQuestionId: joinerId
    });
  };

  getChoiceAnswers = () => {
    if (this.state.multipleChoiceQuestions.length < 1) {
      toast.error({ text: intl.get('app.matrixEdit.noPreviousChoiceQuestions') });
      this.setState({ selectedRowLabelOption: MATRIX_CHOICES.enterRows });
      return;
    }
    return this.state.multipleChoiceQuestions.map(j => (
      <Option value={j.id} key={j.id}>
        {j.researchPrompt}
      </Option>
    ));
  };

  render() {
    const { readOnly, isScreener, isSessionOpen, insertOrEditAction, viewLanguage, language, setViewLanguage } =
      this.props;
    const { entries } = this.props.joiner.def.responseSet;
    const {
      rows = [],
      bipolarRows = [],
      columnData: { columns = [] }
    } = entries;
    const disableImport = this.state.selectedRowLabelOption === MATRIX_CHOICES.choiceAnswers;
    return (
      <div className={`${this.props.tabs.edit} matrix-edit`}>
        <CommonJoinerEditFields {...this.props} />
        <div>
          <div className="mb-2">
            <div className="editor-left-column label">
              {!this.state.expandRows && (
                <i className="fas fa-chevron-right me-3" onClick={() => this.toggleExpand('expandRows')} />
              )}
              {this.state.expandRows && (
                <i className="fas fa-chevron-down me-3" onClick={() => this.toggleExpand('expandRows')} />
              )}
              {intl.get('app.matrixEdit.rowLabels')}
            </div>
            {this.state.selectedRowLabelOption === MATRIX_CHOICES.enterRows && (
              <div className="editor-right-column">
                <div className="labels">
                  <div className="label-input label-text">{intl.get('app.labels')}</div>
                </div>
                <div className="media-column label-text mx-2">{intl.get('app.media')}</div>
                <div className="row-title-input label-text">{intl.get('app.matrixEdit.rowTitle')}</div>
                <div />
                <div />
              </div>
            )}
            {this.state.selectedRowLabelOption === MATRIX_CHOICES.bipolar && (
              <div className="editor-right-column">
                <div className="bipolar-labels me-2">
                  <div className="label-input label-text">{intl.get('app.leftLabels')}</div>
                </div>
                <div className="bipolar-labels me-2">
                  <div className="label-input label-text">{intl.get('app.rightLabels')}</div>
                </div>
                <div className="row-title-input label-text">{intl.get('app.matrixEdit.rowTitle')}</div>
                <div />
                <div />
              </div>
            )}
          </div>
          {this.state.expandRows && (
            <div className="mb-2 rows-area">
              <div className="label editor-left-column">
                <Select
                  value={this.state.selectedRowLabelOption}
                  onChange={this.onChooseAnswersChange}
                  className="rows-value-selector"
                >
                  <Option className="option-text" value={MATRIX_CHOICES.enterRows}>
                    {intl.get('app.matrixEdit.enterRowOptions')}
                  </Option>
                  <Option className="option-text" value={MATRIX_CHOICES.choiceAnswers}>
                    {intl.get('app.matrixEdit.choiceAnswers')}
                  </Option>
                  <Option className="option-text" value={MATRIX_CHOICES.bipolar}>
                    {intl.get('app.matrixEdit.bipolar')}
                  </Option>
                </Select>
              </div>
              {this.state.selectedRowLabelOption === MATRIX_CHOICES.enterRows && (
                <div style={{ width: '100%' }}>{this.getRows(rows)}</div>
              )}
              {this.state.selectedRowLabelOption === MATRIX_CHOICES.choiceAnswers && (
                <div style={{ width: '100%' }}>
                  <Select
                    placeholder={intl.get('app.matrixEdit.chooseChoiceQuestions')}
                    value={this.state.selectedChoiceQuestionId}
                    onChange={this.pickChoiceQuestion}
                    className="choice-question-selector"
                  >
                    {this.getChoiceAnswers()}
                  </Select>
                </div>
              )}
              {this.state.selectedRowLabelOption === MATRIX_CHOICES.bipolar && (
                <div style={{ width: '100%' }}>{this.getBipolarRows(bipolarRows)}</div>
              )}
            </div>
          )}
          <div className="mb-2 mt-3">
            <div className="editor-left-column label">
              {!this.state.expandColumns && (
                <i className="fas fa-chevron-right me-3" onClick={() => this.toggleExpand('expandColumns')} />
              )}
              {this.state.expandColumns && (
                <i className="fas fa-chevron-down me-3" onClick={() => this.toggleExpand('expandColumns')} />
              )}
              {intl.get('app.matrixEdit.columnChoices')}
            </div>
            <div className="editor-right-column">
              <div className="labels choices me-3">
                <div className="choice-input label-text">
                  {isScreener ? intl.get('app.screener.choices') : intl.get('app.choices')}
                </div>
              </div>
              {!isScreener && (
                <div className="abbreviate-input label-text mx-2">{intl.get('app.choiceEdit.abbreviate')}</div>
              )}
              {!isScreener && <div className="value-input label-text">{intl.get('app.choiceEdit.value')}</div>}
              <div />
              <div />
            </div>
          </div>
          {this.state.expandColumns && this.getColumns(columns)}
        </div>
        <div className="import-and-language">
          <ImportQuestionButton
            isDisabled={disableImport}
            readOnly={readOnly}
            isSessionOpen={isSessionOpen}
            insertOrEditAction={insertOrEditAction}
            toggleImportQuestionModal={this.toggleImportQuestionModal}
          />
          {language !== ENGLISH && (
            <TranslateLabel
              setViewLanguage={setViewLanguage}
              language={language}
              viewLanguage={viewLanguage}
              sessionId={this.props.sessionId}
            />
          )}
        </div>
        {this.state.showImportQuestionModal && (
          <ImportQuestionModal
            toggle={this.toggleImportQuestionModal}
            modalTitle={intl.get('app.import.matrixQuestion')}
            questionType={RESPONSE_SET_TYPE.matrix}
            joiner={this.props.joiner}
            importQuestion={this.importQuestion}
            isEnglish={language === viewLanguage}
          />
        )}
        {this.state.showSelectMediaModal && (
          <MediaChoiceModal
            stim={this.state.choiceStim}
            language={language}
            viewLanguage={viewLanguage}
            readOnly={readOnly}
            toggle={this.toggleSelectMediaModal}
            onSave={this.onMediaUpdate}
          />
        )}
      </div>
    );
  }
}

export default MatrixEdit;
