import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { useOutsideClickDetector } from 'webapp-common';
import MediaLibrary from '../../../../mediaLibrary';

import './SelectMediaModal.css';

export const SelectMediaPopover = props => {
  const { target, enableAdd = true, enableEdit = true, toggle } = props;

  const [selectedMedia, setSelectedMedia] = useState({});

  useOutsideClickDetector(['select-media-popover', 'thumbnail-container', 'joiner-icon'], toggle);

  function onMediaSelect(media) {
    setSelectedMedia(media);
  }

  function save() {
    props.onMediaUpdate(selectedMedia);
  }

  return (
    <Popover
      isOpen
      className="select-media-popover"
      trigger="click"
      placement="right"
      target={target}
      toggle={toggle}
      key={target}
    >
      <PopoverHeader>{intl.get('app.title.selectMedia')}</PopoverHeader>
      <PopoverBody>
        <MediaLibrary
          enableAdd={enableAdd}
          enableEdit={enableEdit}
          enableDelete={false}
          sortable
          onMediaSelect={onMediaSelect}
          pageSize={10}
        />
        <div style={{ textAlign: 'right' }}>
          <Button className="link-button" onClick={toggle}>
            {intl.get('app.cancel')}
          </Button>
          <Button color="primary" disabled={!selectedMedia._id} onClick={save}>
            {intl.get('app.save')}
          </Button>
        </div>
      </PopoverBody>
    </Popover>
  );
};
