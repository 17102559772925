import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { STIM_TYPE } from '../../../../../util/joinerUtil';
import { getFlattenedJoinersList } from '../../../../../util/conceptRotationUtil';
import { InvokeModal } from 'webapp-common';
import { isSelectableQuestion, getTableData } from './SurveyQuestionPickerUtil';

import './SurveyQuestionPicker.css';

const QUANT = 'quant';
const QUAL = 'qual';

const reducer = (state, payload) => ({ ...state, ...payload });

function getMediaCell(payload) {
  const type = payload ? payload.mediaType : '';
  switch (type) {
    case STIM_TYPE.image:
      return <img src={payload.mediaUrl} alt="" />;
    case STIM_TYPE.video:
      return <i className="fa fa-video" />;
    default:
      return '';
  }
}

export const SurveyQuestionPicker = props => {
  const { questionJoiners, closeModal } = props;

  const [state, setState] = useReducer(reducer, {
    selectableJoiners: [],
    selectedJoinerIds: [],
    tableData: []
  });

  // Initialize state
  useEffect(() => {
    const flattenedJoiners = getFlattenedJoinersList(questionJoiners);
    const selectableJoiners = flattenedJoiners.reduce((acc, joiner) => {
      if (isSelectableQuestion(joiner)) {
        acc.push(joiner);
      }
      return acc;
    }, []);
    const selectedJoinerIds = props.includeAllQuestions ? selectableJoiners.map(j => j.id) : props.selectedJoinerIds;
    const tableData = getTableData(flattenedJoiners);
    setState({
      selectableJoiners,
      selectedJoinerIds,
      tableData
    });
  }, [questionJoiners.length]);

  const allSelected = state.selectableJoiners.length === state.selectedJoinerIds.length;

  function selectJoiner(joinerId) {
    const selectedJoinerIds = new Set(state.selectedJoinerIds);
    selectedJoinerIds.has(joinerId) ? selectedJoinerIds.delete(joinerId) : selectedJoinerIds.add(joinerId);
    setState({
      selectedJoinerIds: [...selectedJoinerIds]
    });
  }

  function selectAllJoiners() {
    setState({
      selectedJoinerIds: allSelected ? [] : state.selectableJoiners.map(j => j.id)
    });
  }

  function save() {
    props.setSelectedQuestionJoiners({
      includeAllQuestions: allSelected,
      selectedQuestionJoinerIds: allSelected ? [] : state.selectedJoinerIds
    });
    closeModal();
  }

  function getTableRows() {
    return state.tableData.map(d => {
      const questionText = d.questionAnswers.questionPrompt || d.questionAnswers.questionAnswers[0].questionText;
      return (
        <tr className={d.selectable && 'clickable'} onClick={d.selectable ? () => selectJoiner(d.joinerId) : null}>
          <td>
            {d.selectable && (
              <Input
                type="checkbox"
                checked={state.selectedJoinerIds.includes(d.joinerId)}
                onChange={() => selectJoiner(d.joinerId)}
              />
            )}
          </td>
          <td>{d.index}</td>
          <td>{d.title}</td>
          <td>{questionText}</td>
          <td>{d.type}</td>
          <td>{getMediaCell(d.media)}</td>
        </tr>
      );
    });
  }

  function selectAllQuestionsOfType(type, remove) {
    const selectedJoinerIds = new Set(state.selectedJoinerIds);
    state.selectableJoiners.forEach(joiner => {
      if (
        (type === QUAL && joiner.def.responseSet.type === 'open') ||
        (type === QUANT && joiner.def.responseSet.type !== 'open')
      ) {
        remove ? selectedJoinerIds.delete(joiner.id) : selectedJoinerIds.add(joiner.id);
      }
    });
    setState({
      selectedJoinerIds: [...selectedJoinerIds]
    });
  }

  function allQualOrQuantSelected(type) {
    for (let joiner of state.selectableJoiners) {
      if (
        (type === QUAL && joiner.def.responseSet.type === 'open') ||
        (type === QUANT && joiner.def.responseSet.type !== 'open')
      ) {
        if (!state.selectedJoinerIds.includes(joiner.id)) {
          return false;
        }
      }
    }
    return true;
  }

  const allQuantSelected = allQualOrQuantSelected(QUANT);
  const allQualSelected = allQualOrQuantSelected(QUAL);
  const saveEnabled = state.selectedJoinerIds.length !== 0;

  return (
    <InvokeModal
      showModal
      className="survey-question-picker-modal"
      modalTitle={intl.get('app.selectQuestions')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      toggle={closeModal}
      save={save}
      enableSave={saveEnabled}
    >
      <div>
        <label style={{ cursor: 'pointer', display: 'block' }}>
          <Input
            type="checkbox"
            checked={allQuantSelected}
            onClick={() => selectAllQuestionsOfType(QUANT, allQuantSelected)}
          />
          {intl.get('app.allQuantQuestions')}
        </label>
        <label style={{ cursor: 'pointer', display: 'block', marginBottom: '1rem' }}>
          <Input
            type="checkbox"
            checked={allQualSelected}
            onClick={() => selectAllQuestionsOfType(QUAL, allQualSelected)}
          />
          {intl.get('app.allQualQuestions')}
        </label>
        <table className="invoke-table">
          <thead>
            <tr>
              <th>
                <Input type="checkbox" checked={allSelected} onChange={selectAllJoiners} />
              </th>
              <th>#</th>
              <th>{intl.get('app.title')}</th>
              <th>{intl.get('app.surveyQuestions')}</th>
              <th>{intl.get('app.type')}</th>
              <th>{intl.get('app.media')}</th>
            </tr>
          </thead>
          <tbody>{getTableRows()}</tbody>
        </table>
      </div>
    </InvokeModal>
  );
};
