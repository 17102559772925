import React from 'react';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { participantListUtil } from './participantListUtil';
import { InvokeTable, SearchInput } from 'webapp-common';
import ViewParticipantModal from './ViewParticipantModal';

class ParticipantList extends React.Component {
  state = {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    sortOrder: '',
    showViewParticipantModal: false,
    selectedRow: null,
    searchString: ''
  };

  fetchParticipantData({ pageNumber, pageSize, sortBy, sortOrder, searchString }) {
    if (this.props.entityId) {
      this.props.fetchParticipantData({
        pageNumber,
        pageSize: pageSize || this.state.pageSize,
        sortBy,
        sortOrder,
        entityType: this.props.entityType,
        entityId: this.props.entityId,
        participantType: this.props.participantType,
        activeOnly: !this.props.includeDisqualified,
        searchString: searchString || this.state.searchString
      });
    }
  }

  componentDidMount() {
    const { sessionId, fromScreener, entityId } = this.props;
    if (!fromScreener) {
      this.fetchParticipantData({});
    } else {
      if (sessionId && entityId) {
        this.fetchParticipantData({});
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.jobStatus === 'Running') {
      setTimeout(
        () =>
          this.props.pingParticipantData({
            pageSize: this.state.pageSize,
            entityType: this.props.entityType,
            entityId: this.props.entityId,
            participantType: this.props.participantType,
            activeOnly: !this.props.includeDisqualified
          }),
        2000
      );
    }
    if (this.props.reFetch) {
      this.fetchParticipantData({
        pageNumber: this.state.pageNumber,
        sortBy: this.state.sortBy,
        sortOrder: this.state.sortOrder
      });
    } else if (
      prevProps.includeDisqualified !== undefined &&
      prevProps.includeDisqualified !== this.props.includeDisqualified
    ) {
      this.fetchParticipantData({});
    } else if (prevProps.entityId !== this.props.entityId) {
      this.fetchParticipantData({});
    }
  }

  getSortField = sortBy => {
    return sortBy ? sortBy.replace('metadata.dataStore.', '') : undefined;
  };

  sort = ({ sortBy, sortOrder }) => {
    const fetchParams = { sortBy: this.getSortField(sortBy), sortOrder };
    this.setState(fetchParams);
    this.fetchParticipantData(fetchParams);
  };

  paginate = ({ pageNumber, pageSize, sortBy, sortOrder }) => {
    const fetchParams = { pageNumber, pageSize, sortBy: this.getSortField(sortBy), sortOrder };
    this.setState(fetchParams);
    this.fetchParticipantData(fetchParams);
  };

  searchParticipantsDebounced = debounce(value => {
    this.setState({ searchString: value }, () => {
      this.fetchParticipantData({
        searchString: this.state.searchString
      });
    });
  }, 500);

  handleSearchChange = e => {
    this.searchParticipantsDebounced(e.target.value);
  };

  formatParticipantURL = info => {
    const obj = info.row.original;
    const uri = decodeURIComponent(info.getValue());
    return (
      <a key={`url-${obj.id}`} href={uri} target="_blank" rel="noopener noreferrer">
        {uri}
      </a>
    );
  };

  getColumnOrder = () => {
    const { columnOrder, questionJoiners, segmentCategories, enrolleeDataDef, keyTransformMap } = this.props;
    return participantListUtil.getColumnOrder(
      columnOrder,
      questionJoiners,
      segmentCategories,
      enrolleeDataDef,
      keyTransformMap
    );
  };

  getColumns = (columnOrder, keyTransformMap) => {
    if (columnOrder.length === 0) {
      return [];
    }

    const columns = columnOrder.map(key => ({
      accessorKey: `metadata.dataStore.${key}`,
      header: keyTransformMap?.[key] || key,
      cellClassName: 'text-truncate'
    }));
    if (this.props.showParticipantURLs) {
      columns.push({
        accessorKey: 'participantURL',
        header: 'participantURL',
        cell: this.formatParticipantURL,
        cellClassName: 'text-truncate',
        enableSorting: false
      });
      columns.push({
        accessorKey: 'participantPreviewURL',
        header: 'participantPreviewURL',
        cell: this.formatParticipantURL,
        cellClassName: 'text-truncate',
        enableSorting: false
      });
    }
    return columns;
  };

  toggleViewParticipantModal = () => {
    this.setState({ showViewParticipantModal: !this.state.showViewParticipantModal });
  };

  onRowSelect = row => {
    this.setState({
      selectedRow: row
    });
    if (Object.keys(row).length !== 0 || row.constructor !== Object) {
      this.toggleViewParticipantModal();
    }
  };

  render() {
    const {
      participantList = { content: [] },
      enablePagination,
      enableViewParticipant,
      sessionId,
      enableSearch
    } = this.props;
    const { pageRequest = {}, totalElements = 0 } = participantList;
    const { pageNumber, pageSize } = pageRequest;
    const { showViewParticipantModal, selectedRow } = this.state;

    const { columnOrder, keyTransformMap } = this.getColumnOrder();
    const participantListColumns = this.getColumns(columnOrder, keyTransformMap);

    const pagination = {
      pageNumber,
      pageSize,
      totalSize: totalElements
    };

    return (
      <>
        {enableSearch && (
          <SearchInput
            placeholder={intl.get('app.searchParticipants')}
            disabled={false}
            onChange={this.handleSearchChange}
            className="mb-3"
          />
        )}
        <InvokeTable
          className={participantList.content.length > 0 ? 'invoke-table' : 'invoke-table no-data'}
          columns={participantListColumns}
          data={participantList.content}
          loading={this.props.participantDataRequested}
          enablePagination={enablePagination}
          pagination={pagination}
          onPaginationChange={this.paginate}
          onSortingChange={this.sort}
          onRowSelect={enableViewParticipant ? this.onRowSelect : undefined}
        />
        {enableViewParticipant && showViewParticipantModal && (
          <ViewParticipantModal
            toggle={this.toggleViewParticipantModal}
            modalTitle={intl.get('app.viewParticipantResponses')}
            cancelButtonText={intl.get('app.cancel')}
            primaryButtonText={intl.get('app.save')}
            data={selectedRow?.metadata?.dataStore ?? {}}
            columnOrder={columnOrder}
            keyTransformMap={keyTransformMap}
            active={selectedRow?.metadata?.active}
            surveyComplete={selectedRow?.surveyComplete}
            participantId={selectedRow ? selectedRow.id : ''}
            saveParticipantActive={this.props.saveParticipantActive}
            entityId={this.props.entityId}
            activeOnly={!this.props.includeDisqualified}
            sessionId={sessionId}
          />
        )}
      </>
    );
  }
}

export default ParticipantList;
