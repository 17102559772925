import React, { useCallback, useMemo } from 'react';
import { scaleLog } from '@visx/scale';
import { Text } from '@visx/text';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

export const WordCloud = props => {
  const { keywords = [], onKeywordClick } = props;
  const options = {
    colors: ['#FFC700', '#89C35F', '#755EEA', '#07D2CE', '#FF872F'],
    fontFamily: 'Volte,helvetica,sans-serif',
    fontSizes: [12, 60],
    fontWeight: 600,
    padding: 6,
    spiral: 'rectangular',
    width: 990,
    height: 300,
    ...props.options
  };

  const words = useMemo(
    () =>
      keywords.map(entry => {
        return {
          text: entry.value,
          value: entry.frequency
        };
      }),
    [keywords]
  );

  const fontScale = useMemo(() => {
    const frequencies = words.map(w => w.value);
    return scaleLog({
      domain: [Math.min(...frequencies), Math.max(...frequencies)],
      range: options.fontSizes
    });
  }, [options.fontSizes, words]);

  const fontSizeSetter = useCallback(datum => fontScale(datum.value), [fontScale]);

  const fixedValueGenerator = () => 0.5;

  return (
    <Wordcloud
      words={words}
      width={options.width}
      height={options.height}
      font={options.fontFamily}
      fontSize={fontSizeSetter}
      fontWeight={options.fontWeight}
      padding={options.padding}
      spiral={options.spiral}
      rotate={0}
      random={fixedValueGenerator}
    >
      {cloudWords =>
        cloudWords.map((w, i) => (
          <Text
            key={w.text}
            fill={options.colors[i % options.colors.length]}
            textAnchor={'middle'}
            transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
            fontSize={w.size}
            fontFamily={w.font}
            style={{ cursor: 'pointer' }}
            onClick={() => onKeywordClick(w.text)}
          >
            {w.text}
          </Text>
        ))
      }
    </Wordcloud>
  );
};
