import { connect } from 'react-redux';
import {
  setMetadataCollectionActions,
  replaceMetadataJobActions,
  deleteMetadataCollectionActions
} from '../../../../store/redux/actions/fetchParticipantLists';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';
import { CollectionParticipants } from './CollectionParticipants';

const mapDispatchToProps = dispatch => ({
  setMetadataCollection: params => dispatch(setMetadataCollectionActions.request(params)),
  replaceMetadataJob: params => dispatch(replaceMetadataJobActions.request(params)),
  deleteMetadataCollection: params => dispatch(deleteMetadataCollectionActions.request(params))
});

const mapStateToProps = (state, props) => {
  const { hasProjectManage } = sessionUserSelector(state).abilities;
  return {
    hasProjectManage
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionParticipants);
