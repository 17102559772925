import { createSelector } from 'reselect';

const store = state => state.clientAdmin;
const selectClientId = (state, clientId) => clientId;

const clientAdminSelector = () =>
  createSelector([store, selectClientId], (store, clientId) => {
    return {
      ...store,
      client: store.clientMap[clientId]
    };
  });

export { clientAdminSelector };
