import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

import './VirtualFocusGroupOptions.css';

const VirtualFocusGroupOptions = props => {
  const { tabs, joiner, updateHandlers, readOnly } = props;
  return (
    <div className={`${tabs.options} virtual-focus-group-options`}>
      <label disabled={readOnly}>
        {intl.get('app.maxNumParticipants')}:
        <Input
          type="number"
          min="0"
          max="16"
          value={joiner.def.responseSet.maxParticipants}
          onChange={e => updateHandlers.updateMaxParticipants(e.target.value)}
        />
      </label>
    </div>
  );
};

export default VirtualFocusGroupOptions;
