import React from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { rdConfigUtil } from '../../util/rdConfigUtil';
import { saveRDConfig } from '../../util/researchDashboardUtil';

import './QuestionDisplayOptionSelector.css';

const SELECTED = 'selected-option';
const NOT_SELECTED = 'display-option';

export const QuestionDisplayOptionSelector = props => {
  const selectedOptions = rdConfigUtil.questionConfigDisplayOptions;
  const { rdConfig, questionDefId } = props;

  function updateDisplayOption(option) {
    const cloned = cloneDeep(rdConfig);
    const { questionsConfigMap } = cloned.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    questionsConfigMap[questionDefId].displayOption = option;
    saveRDConfig(cloned);
  }

  const displayOption = rdConfigUtil.getQuestionConfigDisplayOption(rdConfig, questionDefId);

  return (
    <div className="options-display">
      <div
        className={displayOption === selectedOptions.COUNT ? SELECTED : NOT_SELECTED}
        onClick={() => {
          updateDisplayOption(rdConfigUtil.questionConfigDisplayOptions.COUNT);
        }}
        title={intl.get('app.rd.N.tooltip')}
      >
        {intl.get('app.rd.N')}
      </div>
      <div
        className={displayOption === selectedOptions.RESPONSE_RATE ? SELECTED : NOT_SELECTED}
        onClick={() => {
          updateDisplayOption(rdConfigUtil.questionConfigDisplayOptions.RESPONSE_RATE);
        }}
        title={intl.get('app.percentSign.tooltip')}
      >
        {intl.get('app.percentSign')}
      </div>
      <div
        style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem' }}
        className={displayOption === selectedOptions.COUNT_AND_RATE ? SELECTED : NOT_SELECTED}
        onClick={() => {
          updateDisplayOption(rdConfigUtil.questionConfigDisplayOptions.COUNT_AND_RATE);
        }}
        title={intl.get('app.numberAndPercent.tooltip')}
      >
        {intl.get('app.numberAndPercent')}
      </div>
    </div>
  );
};
