import { connect } from 'react-redux';
import { saveClientActions } from '../../../store/redux/actions/clientAdminActions';
import { clientAdminSelector } from '../../../store/redux/selectors/clientAdminSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import ClientAdminGeneralSettingsForm from './ClientAdminGeneralSettingsForm';

const mapDispatchToProps = dispatch => ({
  saveClient: client => dispatch(saveClientActions.request(client))
});

const mapStateToProps = (state, props) => {
  const selector = clientAdminSelector()(state, props.clientId);
  return {
    client: selector.client,
    saveClientInProgress: selector.saveClientInProgress,
    abilities: sessionUserSelector(state).abilities
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAdminGeneralSettingsForm);
