import { handleActions } from 'redux-actions';
import { newClusterAnalysisAction } from '../../actions/clusterAnalysisActions';

const initialState = {};

export const rdClusterAnalysisReducer = handleActions(
  {
    [newClusterAnalysisAction.succeeded](state, { payload }) {
      return {
        ...state,
        [`${payload.sessionId}-${payload.questionDefId}`]: payload
      };
    }
  },
  initialState
);
