import React from 'react';

export const ThreeBars = props => {
  const {
    percentLeft,
    countLeft,
    percentMiddle,
    countMiddle,
    percentRight,
    countRight,
    backgroundColorLeft,
    backgroundColorMiddle,
    backgroundColorRight
  } = props;
  const middleStart = 128 - percentMiddle / 2;
  const leftStart = middleStart - percentLeft - 8;
  const rightStart = 128 + percentMiddle / 2 + 8;
  return (
    <svg className="three-nets" style={{ marginTop: '0.25rem' }}>
      <rect x={leftStart} y="0" width={`${percentLeft}`} height="24" fill={`${backgroundColorLeft}`}></rect>
      <text x="0" y="55%" dominant-baseline="middle">
        {countLeft}
      </text>
      <rect x={middleStart} y="0" width={`${percentMiddle}`} height="24" fill={`${backgroundColorMiddle}`}></rect>
      <text x="128" y="55%" dominant-baseline="middle" text-anchor="middle">
        {countMiddle}
      </text>
      <rect x={rightStart} y="0" width={`${percentRight}`} height="24" fill={`${backgroundColorRight}`}></rect>
      <text x="256" y="55%" dominant-baseline="middle" text-anchor="end">
        {countRight}
      </text>
    </svg>
  );
};
