import { connect } from 'react-redux';
import { fetchUsersActions } from '../../../store/redux/actions/clientAdminActions';
import { clientAdminUsersSelector } from '../../../store/redux/selectors/clientAdminUsersSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { ClientUsers } from './ClientUsers';

const mapStateToProps = state => {
  const selector = clientAdminUsersSelector()(state);
  const { hasUserAccountsManage, hasUserAccountsView } = sessionUserSelector(state).abilities;
  return {
    pagedList: selector.pagedList,
    usersRequested: selector.usersRequested,
    userSaveSuccess: selector.userSaveSuccess,
    hasUserAccountsManage,
    hasUserAccountsView
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUsers: payload => dispatch(fetchUsersActions.request(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientUsers);
