import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { ENGLISH, STIM_TYPE } from '../../../../../util/joinerUtil';
import { mediaUtil } from '../../../../../util/mediaUtil';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';
import { Icons } from '../../../../../components/icons/Icons';
import AddOrEditConceptModal from '../../modals/addOrEditConcept/AddOrEditConceptModal';
import { TranslateLabel } from './TranslateLabel';

import webcontent_src from '../../../../../images/icons8-internet.png';

class ConceptRotationEdit extends React.Component {
  state = {
    showAddOrEditConceptModal: false
  };

  toggleAddOrEditConceptModal = (index, concept) => {
    if (!this.props.readOnly) {
      this.setState({
        showAddOrEditConceptModal: !this.state.showAddOrEditConceptModal,
        selectedIndex: index,
        selectedConcept: concept
      });
    }
  };

  onConceptSave = concept => {
    if (this.state.selectedConcept) {
      // edit mode
      this.props.updateHandlers.updateConcept(this.state.selectedIndex, concept);
    } else {
      // add mode
      this.props.updateHandlers.addConcept(this.state.selectedIndex, concept);
    }
    this.toggleAddOrEditConceptModal();
  };

  onConceptRemove = index => {
    this.props.updateHandlers.removeConcept(index);
  };

  getConceptStim = concept => {
    const { startJoiner } = concept;
    const { stim } = startJoiner || {};
    const { media } = stim || {};
    const stimType = stim && stim.type;
    const accessUrl = media && media.accessUrl;
    switch (stimType) {
      case STIM_TYPE.image:
        return accessUrl ? <img src={mediaUtil.getMediaUrl(media)} alt="" /> : <i className="far fa-image" />;
      case STIM_TYPE.video:
        return <Icons.VideoIcon />;
      case STIM_TYPE.text:
        return intl.get('app.text');
      case STIM_TYPE.webcontent:
        return <img src={webcontent_src} alt="" />;
      default:
        return <i className="far fa-image" />;
    }
  };

  getConcepts = () => {
    const { readOnly, joiner } = this.props;
    if (joiner.conceptRotation.concepts.length > 0) {
      return joiner.conceptRotation.concepts.map((concept, index) => {
        return (
          <div className="concept-control" key={concept.id}>
            <div
              className="text-truncate"
              title={concept.title}
              onClick={() => this.toggleAddOrEditConceptModal(index, concept)}
            >
              {concept.title}
            </div>
            <div onClick={() => this.toggleAddOrEditConceptModal(index, concept)}>{this.getConceptStim(concept)}</div>
            <>
              <Icons.EyeIcon
                className="clickable me-4"
                title={intl.get('app.preview')}
                onClick={e => this.props.saveAndPreviewJoiner(e, concept.startJoiner.id)}
              />
              {!readOnly && (
                <AddRemoveIcons
                  onAdd={() => this.toggleAddOrEditConceptModal(index + 1)}
                  onRemove={() => this.onConceptRemove(index)}
                />
              )}
            </>
          </div>
        );
      });
    } else {
      return (
        <div className="concept-control">
          <div onClick={() => this.toggleAddOrEditConceptModal(0)}>{intl.get('app.addConcept')}</div>
          <div onClick={() => this.toggleAddOrEditConceptModal(0)}>
            <i className="far fa-image" />
          </div>
          {!readOnly && <AddRemoveIcons onAdd={() => this.toggleAddOrEditConceptModal(0)} />}
        </div>
      );
    }
  };

  render() {
    const { tabs, joiners, joiner, updateHandlers, readOnly, viewLanguage, language, setViewLanguage } = this.props;
    return (
      <div className={`${tabs.edit} concept-rotation-edit`}>
        <div>
          <div className="label editor-left-column">{intl.get('app.title')}</div>
          <div className="editor-right-column">
            <Input
              className="title-input"
              value={joiner.researchPrompt}
              disabled={readOnly}
              onChange={updateHandlers.updateQuestionTitle}
            />
          </div>
        </div>
        <div>
          <div className="label editor-left-column">{intl.get('app.concepts')}</div>
          <div className="editor-right-column">{this.getConcepts()}</div>
        </div>
        {language !== ENGLISH && (
          <TranslateLabel
            classes="stim-text-translation"
            setViewLanguage={setViewLanguage}
            language={language}
            viewLanguage={viewLanguage}
            sessionId={this.props.sessionId}
          />
        )}
        {this.state.showAddOrEditConceptModal && (
          <AddOrEditConceptModal
            concept={this.state.selectedConcept}
            conceptRotationId={joiner.id}
            toggle={this.toggleAddOrEditConceptModal}
            onConceptSave={this.onConceptSave}
            joiners={joiners}
            readOnly={readOnly}
            language={language}
            viewLanguage={viewLanguage}
          />
        )}
      </div>
    );
  }
}

export default ConceptRotationEdit;
