import { handleActions } from 'redux-actions';
import { initEnrolleesActions, enrolleeUpdateActions } from '../../actions/researchDashboardActions';
import { idUtils } from '../../../../util/Id';

const initialState = {};

export const rdEnrolleesReducer = handleActions(
  {
    [initEnrolleesActions.succeeded](state, { payload }) {
      const { sessionId, enrolleesInfo } = payload;
      return {
        ...state,
        [sessionId]: {
          enrolleesInfo
        }
      };
    },

    [enrolleeUpdateActions.succeeded](state, { payload }) {
      const { sessionId, enrolleesInfo, isAutoEnroll } = payload;

      // Copy the enrolleeMap and merge in the updated enrollees.
      const { enrolleeMap } = enrolleesInfo;
      const copy = Object.assign({}, state[sessionId].enrolleesInfo.enrolleeMap);
      for (let enrolleeId in enrolleeMap) {
        const enrollee = enrolleeMap[enrolleeId];
        const existing = copy[enrolleeId] || {};
        existing.participantGroup = enrollee.participantGroup;
        copy[enrolleeId] = Object.assign({}, existing, enrollee);
      }

      // Count the accepted and active enrollees.
      let totalAccepted = 0;
      let activeEnrolleeCount = 0;
      for (let enrolleeId in copy) {
        const { surveyStartTime, enrolleeStatus } = copy[enrolleeId];
        totalAccepted += surveyStartTime ? 1 : 0;
        activeEnrolleeCount += surveyStartTime && enrolleeStatus !== 'Terminated' ? 1 : 0;
      }

      const enrolleesInfoCopy = {
        ...state[sessionId].enrolleesInfo,
        enrolleeMap: copy,
        hashCode: enrolleesInfo.hashCode || idUtils.getId(),
        totalAccepted,
        activeEnrolleeCount
      };

      if (isAutoEnroll) {
        // These can change as participants enter the survey
        enrolleesInfoCopy.columnOrder = enrolleesInfo.columnOrder;
        enrolleesInfoCopy.enrolleeDataDef = enrolleesInfo.enrolleeDataDef;
      }

      return {
        ...state,
        [sessionId]: {
          enrolleesInfo: enrolleesInfoCopy
        }
      };
    }
  },

  initialState
);
