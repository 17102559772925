import { connect } from 'react-redux';
import { participantDataSelector } from '../../store/redux/selectors/participantDataSelector';
import {
  fetchParticipantsActions,
  saveParticipantActiveActions,
  pingParticipantDataActions
} from '../../store/redux/actions/participantDataActions';
import ParticipantList from './ParticipantList';

const mapDispatchToProps = dispatch => ({
  fetchParticipantData: params => dispatch(fetchParticipantsActions.request(params)),
  saveParticipantActive: params => dispatch(saveParticipantActiveActions.request(params)),
  pingParticipantData: params => dispatch(pingParticipantDataActions.request(params))
});

const mapStateToProps = state => {
  const selector = participantDataSelector();
  return (state, props) => (props.entityId && selector(state, props.entityId)) || {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
