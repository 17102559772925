import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get, isEqual } from 'lodash';
import { accessHandler, usePrevious, Loader } from 'webapp-common';
import appConfig from '../../../appConfig';
import { ProjectSurveyLeftNav } from './ProjectSurveyLeftNav';
import { tabs } from './ProjectSurveyTabs';
import { SurveyComparativeViews } from './comparativeViews/SurveyComparativeViews';
import { RDChatMgmt } from '../../../containers/researchDashboard/rdChatMgmt/RDChatMgmt';
import { SurveyDial } from './dial/SurveyDial';
import SurveyDisplayPrefs from './displayPrefs';
import { PanelConfig } from './panelConfig/PanelConfig';
import SurveyEditor from './editor';
import { SurveyEmotions } from './emotions/SurveyEmotions';
import SurveyParticipants from './participants';
import { SurveyResponses } from './responses/SurveyResponses';
import { SurveySettingsWrapper } from './settings/SurveySettingsWrapper';
import ScreenerParticipants from './screenerParticipants';
import { ScreenerResponses } from './screenerResponses/ScreenerResponses';
import VirtualFocusGroup from './virtualFocusGroup';
import { Chat } from '../../../components/chat/Chat';
import { rdSubscribeActions } from '../../../store/redux/actions/researchDashboardActions';
import { surveyUtil } from '../../../util/surveyUtil';
import { ENGLISH } from '../../../util/joinerUtil';

const COLLECTION_IDS = 'config.participantCollectionIds';

export const ProjectSurvey = props => {
  const {
    project = {},
    activeSessionId,
    activeSession,
    fetchSessionsInProgress,
    importRequestInProgress,
    rdSubscribeInProgress,
    surveyStore,
    isTemplate,
    isScreener,
    routeParams,
    setViewLanguage,
    hasProjectManage,
    hasProjectView,
    fetchSession,
    fetchSessionAndSurvey,
    fetchSurvey,
    saveSession,
    saveSurvey,
    deleteSurvey,
    surveyTemplateId,
    ingestStatus
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const surveyPinger = useRef();

  const {
    fetchSurveyInProgress,
    saveSurveyInProgress,
    translateSurveyInProgress,
    deleteSurveyInProgress,
    deleteSurveySuccess,
    deleteSurveyQuestionsInProgress
  } = surveyStore;

  const showLoader =
    !surveyPinger.current &&
    (fetchSessionsInProgress ||
      fetchSurveyInProgress ||
      rdSubscribeInProgress ||
      saveSurveyInProgress ||
      translateSurveyInProgress ||
      deleteSurveyInProgress ||
      deleteSurveyQuestionsInProgress);

  const { newSessionAndSurvey = false } = activeSession || {};
  const baseUrl = appConfig.projectPagePath;
  const activeTab = routeParams.subtab2 || tabs.SETTINGS_TAB;
  const cannotSaveTemplate = isTemplate && !hasProjectManage;
  const survey = getSurvey();
  const language = surveyUtil.getConfiguredLanguage(survey);
  const viewLanguage = props.viewLanguage || language; // props.viewLanguage is undefined for templates
  const translationInProgress = survey && survey.translationStatus === 'IN_PROGRESS';

  const prevSession = usePrevious(activeSession);
  const prevDeleteSurveySuccess = usePrevious(deleteSurveySuccess);

  useEffect(() => {
    if (newSessionAndSurvey) {
      return;
    }
    if (importRequestInProgress === false) {
      if (activeSessionId) {
        fetchSession(activeSessionId);
      } else if (surveyTemplateId) {
        fetchSurvey(surveyTemplateId);
      }
    }
  }, [activeSessionId, newSessionAndSurvey, importRequestInProgress]);

  useEffect(() => {
    if (activeSession?.surveyId && !newSessionAndSurvey) {
      fetchSurvey(activeSession.surveyId);
    }
  }, [activeSession?.surveyId]);

  useEffect(() => {
    const { id: prevSessionId, newSessionAndSurvey: prevNewSessionAndSurvey } = prevSession || {};
    if (newSessionAndSurvey) {
      return;
    }
    if (activeSessionId && (activeSessionId !== prevSessionId || newSessionAndSurvey !== prevNewSessionAndSurvey)) {
      rdSubscribe();
      return () => {
        rdUnSubscribe();
      };
    }
  }, [activeSessionId, newSessionAndSurvey]);

  useEffect(() => {
    if (props.rdConfig.configs) {
      const displayLanguage =
        get(props.rdConfig.configs, 'questionsConfig.globalQuestionConfig.displayLanguage') || ENGLISH;
      setViewLanguage(displayLanguage);
    }
  }, [props.rdConfig]);

  useEffect(() => {
    if (deleteSurveySuccess && prevDeleteSurveySuccess === false) {
      if (routeParams.projectId) {
        // Project survey
        navigate(`${baseUrl}/${routeParams.projectId}/${routeParams.tab}`);
      } else {
        // Survey template
        const templateUrl = isScreener ? 'screenerTemplates' : 'surveyTemplates';
        navigate(`${baseUrl}/${templateUrl}`);
      }
    }
  }, [deleteSurveySuccess, prevDeleteSurveySuccess]);

  // Get a new RD subscription if either:
  // Session is taken out of TEST mode
  // The collectionIds have changed
  useEffect(() => {
    if (!activeSession || !prevSession) {
      return;
    }
    const currCollectionIds = get(activeSession, COLLECTION_IDS) || [];
    const prevCollectionIds = get(prevSession, COLLECTION_IDS) || [];
    if (
      (prevSession.state === 'TEST' && activeSession.state !== 'TEST') ||
      !isEqual(currCollectionIds, prevCollectionIds)
    ) {
      rdUnSubscribe();
      rdSubscribe();
    }
  }, [activeSession]);

  useEffect(() => {
    if (translationInProgress && (activeTab === tabs.EDITOR_TAB || activeTab === tabs.SETTINGS_TAB)) {
      if (activeSessionId) {
        surveyPinger.current = setInterval(() => fetchSessionAndSurvey(activeSessionId), 2000);
      } else if (surveyTemplateId) {
        surveyPinger.current = setInterval(() => fetchSurvey(surveyTemplateId), 2000);
      }
    } else {
      clearInterval(surveyPinger.current);
    }
    return () => {
      clearInterval(surveyPinger.current);
    };
  }, [translationInProgress, activeTab]);

  function rdSubscribe() {
    if (activeSessionId) {
      dispatch(
        rdSubscribeActions.request({
          subAction: 'subscribe',
          sessionId: activeSessionId
        })
      );
    }
  }

  function rdUnSubscribe() {
    if (activeSessionId) {
      dispatch(
        rdSubscribeActions.request({
          subAction: 'unsubscribe',
          sessionId: activeSessionId
        })
      );
    }
  }

  function getSurvey() {
    const surveyId = surveyTemplateId || (activeSession && activeSession.surveyId);
    return surveyId && surveyStore[surveyId];
  }

  function getContent() {
    if (!survey) {
      return null;
    }
    const metaDataCollectionIds = get(activeSession, COLLECTION_IDS, null);
    const metaDataCollectionId = metaDataCollectionIds && metaDataCollectionIds[0];
    switch (activeTab) {
      case tabs.DISPLAY_PREFS_TAB:
        return <SurveyDisplayPrefs sessionId={activeSessionId} projectId={project.id} />;
      case tabs.PANEL_CONFIG_TAB:
        return <PanelConfig session={activeSession} hasProjectManage={hasProjectManage} saveSession={saveSession} />;
      case tabs.EDITOR_TAB:
        return (
          <SurveyEditor
            surveyId={survey.id}
            session={activeSession}
            saveSession={saveSession}
            survey={survey}
            isScreener={isScreener || (activeSession && activeSession.screener)}
            translationInProgress={translationInProgress}
            saveTemplateName={saveSurvey}
            projectId={project.id}
            isSelfServe={project.selfServe}
            deleteSurvey={deleteSurvey}
            deleteSurveyQuestions={props.deleteSurveyQuestions}
            saveQuestionJoinerInProgress={props.saveQuestionJoinerInProgress}
            deleteSurveyQuestionsInProgress={deleteSurveyQuestionsInProgress}
            saveQuestionJoinerFailed={props.saveQuestionJoinerFailed}
            cannotSaveTemplate={cannotSaveTemplate}
            isTemplate={isTemplate}
            setViewLanguage={setViewLanguage}
            viewLanguage={viewLanguage}
          />
        );
      case tabs.PARTICIPANTS_TAB:
        if (isScreener) {
          return (
            <ScreenerParticipants
              sessionId={activeSessionId}
              sessionName={activeSession.name}
              surveyId={survey.id}
              survey={survey}
              metaDataCollectionId={metaDataCollectionId}
              setViewLanguage={setViewLanguage}
              language={language}
              viewLanguage={viewLanguage}
            />
          );
        }
        return (
          <SurveyParticipants
            projectId={project.id}
            storeId={project.config && project.config.metaDataStoreId}
            sessionId={activeSessionId}
            session={activeSession}
            setViewLanguage={setViewLanguage}
            language={language}
            viewLanguage={viewLanguage}
          />
        );
      case tabs.RESPONSES_TAB:
        if (isScreener) {
          return (
            <ScreenerResponses
              session={activeSession}
              sessionId={activeSessionId}
              surveyId={survey.id}
              metaDataCollectionId={metaDataCollectionId}
              projectId={activeSession.projectId}
            />
          );
        }
        return (
          <SurveyResponses
            sessionId={activeSessionId}
            session={activeSession}
            language={language}
            setViewLanguage={setViewLanguage}
            viewLanguage={viewLanguage}
          />
        );
      case tabs.DIAL_TAB:
        return survey.id ? (
          <SurveyDial
            sessionId={activeSessionId || routeParams.subtab}
            sessionName={activeSession.name}
            surveyId={survey.id}
          />
        ) : null;
      case tabs.EMOTIONS_TAB:
        return (
          <SurveyEmotions
            sessionId={activeSessionId || routeParams.subtab}
            surveyId={survey.id}
            sessionName={activeSession.name}
          />
        );
      case tabs.COMPARATIVE_VIEWS_TAB:
        return (
          <SurveyComparativeViews
            sessionId={activeSessionId}
            rdConfig={props.rdConfig}
            survey={survey}
            setViewLanguage={setViewLanguage}
            viewLanguage={viewLanguage}
            project={project}
          />
        );
      case tabs.CHATS_TAB:
        return <RDChatMgmt sessionId={activeSessionId} />;
      case tabs.VIRTUAL_FOCUS_GROUP_TAB:
        return (
          <VirtualFocusGroup
            sessionId={activeSessionId}
            survey={survey}
            rdConfig={props.rdConfig}
            setViewLanguage={setViewLanguage}
            language={language}
            viewLanguage={viewLanguage}
            project={project}
          />
        );
      case tabs.SETTINGS_TAB:
      default:
        return (
          <SurveySettingsWrapper
            project={project}
            session={activeSession}
            survey={survey}
            translationInProgress={translationInProgress}
            multipleSurveysEnabled={project.multipleSurveysEnabled}
            saveSession={saveSession}
            cloneSession={props.cloneSession}
            saveSurvey={saveSurvey}
            deleteSurvey={deleteSurvey}
            routeParams={routeParams}
            baseUrl={baseUrl}
            isTemplate={isTemplate}
            getSurveyVideosIngestStatus={props.getSurveyVideosIngestStatus}
            ingestStatus={ingestStatus}
          />
        );
    }
  }

  const accessCheck = accessHandler.checkAccess(hasProjectView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <section className="app-body">
      {showLoader && <Loader spinner fullScreen />}
      <section className="left-sub-nav project-survey-left-nav">
        <ProjectSurveyLeftNav
          project={project}
          isScreener={isScreener}
          activeSessionId={activeSessionId}
          activeSession={activeSession}
          survey={survey}
          isTemplate={isTemplate}
        />
      </section>
      <section className="body-container l3-bg">{getContent()}</section>
      <Chat sessionId={activeSessionId} viewLanguage={viewLanguage} />
    </section>
  );
};
