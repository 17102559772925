import { takeLatest, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  createFunction,
  fetchFunctionList,
  fetchFunction,
  deleteFunction,
  fetchAllFunctions
} from '../../../api/functionApi';
import {
  createFunctionActions,
  fetchFunctionListActions,
  fetchFunctionActions,
  deleteFunctionActions,
  fetchAllFunctionsActions
} from '../actions/functionActions';

function* createFunctionSaga() {
  const saga = createFetchSaga(createFunctionActions, createFunction);
  yield* saga();
  yield takeLatest(createFunctionActions.succeeded, handleFunctionCreated);
}

function* handleFunctionCreated({ payload: { payload: functionRule } }) {
  if (functionRule) {
    toast.success({ text: intl.get('app.function.saved', { name: functionRule.name }) });
    yield put(fetchFunctionListActions.request({}));
  }
}

function* fetchFunctionListSaga() {
  const saga = createFetchSaga(fetchFunctionListActions, fetchFunctionList);
  yield* saga();
}

function* fetchFunctionSaga() {
  const saga = createFetchSaga(fetchFunctionActions, fetchFunction);
  yield* saga();
}

function* deleteFunctionSaga() {
  const saga = createFetchSaga(deleteFunctionActions, deleteFunction);
  yield* saga();
  yield takeLatest(deleteFunctionActions.succeeded, handleFunctionDeleted);
}

function* handleFunctionDeleted({ payload: functionRule }) {
  if (functionRule) {
    toast.success({ text: intl.get('app.function.deleted', { name: functionRule.name }) });
    yield put(fetchFunctionListActions.request({}));
  }
}

function* fetchAllFunctionsSaga() {
  const saga = createFetchSaga(fetchAllFunctionsActions, fetchAllFunctions);
  yield* saga();
}

export { createFunctionSaga, fetchFunctionListSaga, fetchFunctionSaga, deleteFunctionSaga, fetchAllFunctionsSaga };
