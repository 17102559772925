import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import classnames from 'classnames';
import { FlexRow } from 'webapp-common';
import { STIM_TYPE, VIDEO_STIM_OPTIONS, UPDATE_HANDLER_TYPE } from '../../../../../../util/joinerUtil';
import VideoDialOptions from '../VideoDialOptions';
import { DetectionOptions } from '../DetectionOptions';
import { TranslateLabel } from '../../edit/TranslateLabel';

class ImageStimOptions extends React.Component {
  updateVideoDialConfigJoiner = (...args) => {
    const { updateHandlers } = this.props;
    switch (args[args.length - 1]) {
      case UPDATE_HANDLER_TYPE.dialConfig:
        args.pop();
        updateHandlers.updateVideoDialConfig(...args);
        break;
      case UPDATE_HANDLER_TYPE.dialConfigField:
        args.pop();
        updateHandlers.updateVideoDialConfigField(...args);
        break;
      case UPDATE_HANDLER_TYPE.dialActionButton:
        args.pop();
        updateHandlers.updateVideoDialActionButton(...args);
        break;
      case UPDATE_HANDLER_TYPE.addActionButton:
        args.pop();
        updateHandlers.addVideoDialActionButton(...args);
        break;
      case UPDATE_HANDLER_TYPE.removeActionButton:
        args.pop();
        updateHandlers.removeVideoDialActionButton(...args);
        break;
      default:
        return;
    }
  };

  render() {
    const {
      tabs,
      joiner,
      joiners,
      updateHandlers,
      readOnly,
      isVideoDialEnabled,
      sessionId,
      language,
      viewLanguage,
      setViewLanguage
    } = this.props;

    const stim = (joiner && joiner.stim) || {};
    const { options = {}, type } = stim;

    const videoControlsConfigDisabled = readOnly || !options.showVideoControls;
    const videoControlsConfigOptionsClasses = classnames({
      'mb-1': true,
      pointer: !videoControlsConfigDisabled,
      'not-allowed': videoControlsConfigDisabled
    });

    const { dialConfig = {} } = options;

    const fullScreenClasses = classnames({
      'mb-1': true,
      pointer: !videoControlsConfigDisabled && !dialConfig.enabled,
      'not-allowed': videoControlsConfigDisabled || dialConfig.enabled
    });

    const showObsoleteVideoConfig = false;

    return (
      <div className={`media-options ${tabs.options}`}>
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={joiner.hidden}
            onChange={e => updateHandlers.updateHidden(e.target.checked)}
          />
          {intl.get('app.hideQuestion')}
        </label>
        {type === STIM_TYPE.video && (
          <>
            {showObsoleteVideoConfig && (
              <FlexRow alignItems="flex-start">
                <label disabled={readOnly} className={readOnly ? 'not-allowed' : 'clickable'}>
                  <Input
                    type="checkbox"
                    checked={options.showVideoControls}
                    onChange={e =>
                      updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.showVideoControls, e.target.checked)
                    }
                  />
                  {intl.get('app.media.showVideoControls')}
                </label>
                <div className="ms-4 vertical-items">
                  <label disabled={videoControlsConfigDisabled} className={videoControlsConfigOptionsClasses}>
                    <Input
                      type="checkbox"
                      disabled={videoControlsConfigDisabled}
                      checked={options.showPlayPause}
                      onChange={e =>
                        updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.showPlayPause, e.target.checked)
                      }
                    />
                    {intl.get('app.media.playPause')}
                  </label>
                  <label disabled={videoControlsConfigDisabled} className={videoControlsConfigOptionsClasses}>
                    <Input
                      type="checkbox"
                      disabled={videoControlsConfigDisabled}
                      checked={options.showVolume}
                      onChange={e =>
                        updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.showVolume, e.target.checked)
                      }
                    />
                    {intl.get('app.media.soundVolume')}
                  </label>
                  <label disabled={videoControlsConfigDisabled} className={videoControlsConfigOptionsClasses}>
                    <Input
                      type="checkbox"
                      disabled={videoControlsConfigDisabled}
                      checked={options.closedCaptions}
                      onChange={e =>
                        updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.closedCaptions, e.target.checked)
                      }
                    />
                    {intl.get('app.media.closedCaptions')}
                  </label>
                  <label disabled={videoControlsConfigDisabled || dialConfig.enabled} className={fullScreenClasses}>
                    <Input
                      type="checkbox"
                      disabled={videoControlsConfigDisabled}
                      checked={options.fullScreen}
                      onChange={e =>
                        updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.fullScreen, e.target.checked)
                      }
                    />
                    {intl.get('app.media.fullScreen')}
                  </label>
                </div>
              </FlexRow>
            )}
            {isVideoDialEnabled && (
              <VideoDialOptions
                readOnly={readOnly}
                joiner={joiner}
                joiners={joiners}
                language={language}
                viewLanguage={viewLanguage}
                updateVideoDialConfig={(...args) => this.updateVideoDialConfigJoiner(...args)}
              />
            )}
            <DetectionOptions options={options} updateHandlers={updateHandlers} readOnly={readOnly} />
            {isVideoDialEnabled && (
              <TranslateLabel
                classes="float-right"
                setViewLanguage={setViewLanguage}
                language={language}
                viewLanguage={viewLanguage}
                sessionId={sessionId}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default ImageStimOptions;
