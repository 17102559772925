import { handleActions } from 'redux-actions';
import { fetchQuestionListActions } from '../actions/questionListActions';

const initialState = {
  questionListRequested: false
};

const questionListReducer = handleActions(
  {
    [fetchQuestionListActions.triggered](state) {
      return {
        ...state,
        questionListRequested: true
      };
    },

    [fetchQuestionListActions.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload,
        questionListRequested: false
      };
    },

    [fetchQuestionListActions.failed](state) {
      return { ...state, questionListRequested: false };
    }
  },
  initialState
);
export default questionListReducer;
