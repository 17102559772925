import { get } from 'lodash';
import intl from 'react-intl-universal';
import { idUtils } from './Id';
import { ENGLISH, RESPONSE_SET_TYPE } from './joinerUtil';
import { generateSession, generateUniqueSessionName } from './sessionUtil';

function generateSurvey({ id, name }) {
  return {
    id: id || idUtils.getId(),
    name,
    includeSessionCount: true,
    joiners: [],
    surveyOptions: {
      allQuestionsRequired: true,
      allQuestionsVideoCapture: false,
      languageOptions: {
        language: ENGLISH
      },
      participantDataExcludedQuestions: [],
      watermarkOption: {
        enabled: true,
        identifier: 'PARTICIPANT_ID',
        mediaType: 'VIDEOS_AND_IMAGES',
        position: 'TOP_RIGHT'
      }
    }
  };
}

function hasConceptRotation(survey) {
  return survey?.joiners.find(joiner => joiner.conceptRotation && !joiner.hidden);
}

function hasDialEnabledVideo(survey) {
  return survey?.joiners?.some(j => {
    return (
      get(j, 'stim.options.dialConfig.enabled') ||
      j.conceptRotation?.concepts?.some(c => get(c, 'startJoiner.stim.options.dialConfig.enabled'))
    );
  });
}

function emotionDetectEnabled(survey) {
  return survey?.joiners?.some(j => {
    return (
      j.stim?.options?.detectEmotions ||
      j.conceptRotation?.concepts?.some(c => c.startJoiner?.stim?.options?.detectEmotions)
    );
  });
}

function getVirtualFocusGroupJoiners(joiners) {
  return joiners?.filter(j => get(j, 'def.responseSet.type') === RESPONSE_SET_TYPE.virtualFocusGroup) ?? [];
}

function hasVariables(survey) {
  return Object.keys(survey.variables || {}).length !== 0;
}

function hasAlternateLanguage(survey) {
  const language = get(survey, 'surveyOptions.languageOptions.language');
  return language && language !== ENGLISH;
}

function hasSegmentCategories(survey) {
  return survey.segmentCategories && survey.segmentCategories.length > 0;
}

const getConfiguredLanguage = survey => {
  return get(survey, 'surveyOptions.languageOptions.language') || ENGLISH;
};

const isVFGquestionDeployed = (survey, session, questionJoinerId) => {
  if (!survey || !session) {
    return false;
  }
  const vfgIndex = survey.joiners.findIndex(j => j.id === questionJoinerId);
  const currentIndex = survey.joiners.findIndex(j => j.id === session.currentJoinerId);
  return vfgIndex <= currentIndex;
};

const stripBadCharacters = filename => {
  return filename.replace(/[\\/:"*?<>|#;?@=&%[\]{}~`]+/g, '_');
};

const addSurvey = (isScreener, allSessions, projectDetails) => {
  const baseName = isScreener ? intl.get('app.newScreener') : intl.get('app.newSurvey');
  const name = generateUniqueSessionName(baseName, allSessions.content);
  const survey = generateSurvey({
    name
  });
  const session = generateSession({
    name,
    project: projectDetails,
    surveyId: survey.id,
    screener: isScreener,
    newSessionAndSurvey: true // UI-only flag
  });
  return { session, survey };
};

export const surveyUtil = {
  generateSurvey,
  hasConceptRotation,
  hasDialEnabledVideo,
  emotionDetectEnabled,
  getVirtualFocusGroupJoiners,
  hasVariables,
  hasAlternateLanguage,
  hasSegmentCategories,
  getConfiguredLanguage,
  isVFGquestionDeployed,
  stripBadCharacters,
  addSurvey
};
