import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { validateEmail } from '../../../../util/jsUtil';
import { FlexRow, InvokeModal } from 'webapp-common';

export const AddToBlackList = props => {
  const [email, setEmail] = useState('');

  const onEmailChange = e => {
    setEmail(e.target.value);
  };

  const onSave = () => {
    props.addToBlacklist({ email });
    props.toggle();
  };

  const validate = () => {
    if (!email || !validateEmail(email)) {
      return false;
    }
    return true;
  };

  return (
    <InvokeModal
      showModal
      modalTitle={intl.get('app.blacklist.email')}
      toggle={props.toggle}
      save={onSave}
      enableSave={validate()}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      backdrop={false}
      keyboard={false}
    >
      <FlexRow className="mt-4">
        <div style={{ whiteSpace: 'nowrap', marginRight: '2rem' }}>{intl.get('app.emailAddress')}</div>
        <Input value={email} onChange={onEmailChange} />
      </FlexRow>
      <div style={{ height: '5rem' }} />
    </InvokeModal>
  );
};
