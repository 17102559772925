import React, { useEffect, useReducer } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Input, Button } from 'reactstrap';
import { cloneDeep, isEqual } from 'lodash';
import classnames from 'classnames';
import { InvokeModal, Loader } from 'webapp-common';
import {
  getRDConfig,
  getRDEnrollees,
  getRDQuestionJoiners,
  getRDSession,
  getRDSessionQuotaFilters
} from '../../../store/redux/selectors/researchDashboardSelector';
import { useSessionUserSelector } from '../../../customHooks/reduxHelper';
import { filterUtil } from '../../../util/filterUtil';
import { saveRDConfig } from '../../../util/researchDashboardUtil';
import { toast } from '../../../util/toast';
import { sendWSMessage } from '../../../util/websocket';
import { SelectDataPointsModal } from './selectDataPoints/SelectDataPointsModal';
import { FilterLabel } from './FilterLabel';
import AddRemoveIcons from '../../../components/core/addRemoveIcons/AddRemoveIcons';
import AdvancedFilterBuilder from './advancedFilterBuilder';
import { FilterIcon } from '../../../components/icons/Icons';

import './EditFiltersModal.css';

const reducer = (state, payload) => ({ ...state, ...payload });

const GENERAL_FILTER = 'GENERAL_FILTER';
const QUOTA_FILTER = 'QUOTA_FILTER';
const DEFAULT_FILTER = 'DEFAULT_FILTER';
const DELETE = 'Delete';

function getGeneralFilter() {
  return {
    type: GENERAL_FILTER,
    name: '',
    isNew: true
  };
}

function getQuotaFilter(sessionId) {
  return {
    type: QUOTA_FILTER,
    name: '',
    sessionId,
    maxQuota: 0,
    updateAction: 'Add',
    isNew: true
  };
}

function getDupeNames(filters = [], quotaFilters) {
  const allFilters = [...filters, ...quotaFilters];
  const filterNames = new Set();
  const dupes = new Set();
  for (const filter of allFilters) {
    const name = filter.name.trim();
    if (!name || filter.updateAction === DELETE) {
      continue;
    }
    if (filterNames.has(name)) {
      dupes.add(name);
    } else {
      filterNames.add(name);
    }
  }
  return dupes;
}

export const EditFiltersModal = props => {
  const { sessionId, toggle } = props;

  // Selectors
  const quotaFilters = useSelector(state => getRDSessionQuotaFilters(state, sessionId), shallowEqual);
  const updateQuotaFiltersResponse = useSelector(state => state.rdSessionQuotaFilters.updateResponse, shallowEqual);
  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  const questionJoiners = useSelector(state => getRDQuestionJoiners(state, sessionId), shallowEqual);
  const enrolleesInfo = useSelector(state => getRDEnrollees(state, sessionId), shallowEqual);
  const { hasProjectManage } = useSessionUserSelector().abilities;
  const session = useSelector(state => getRDSession(state, sessionId), shallowEqual);
  const projectStore = useSelector(
    state => state.projects !== undefined && state.projects[session.projectId],
    shallowEqual
  );

  const [state, setState] = useReducer(reducer, {
    quotaFilters: [getQuotaFilter(sessionId)],
    rdConfig,
    showFilterBuilder: false,
    filterToEdit: null,
    showAdvanceFilter: false,
    updateQuotaFiltersInProgress: false
  });

  const { projectDetails: project } = projectStore || {};
  const dupeNames = getDupeNames(state.rdConfig.configs.filterListConfig.filters, state.quotaFilters);

  // Initialize the rdConfig in the state
  useEffect(() => {
    const rdConfigClone = cloneDeep(rdConfig);
    setState({
      rdConfig: rdConfigClone
    });
  }, [rdConfig]);

  // Initialize the quotaFilters in the state
  useEffect(() => {
    if (!state.quotaFiltersInitialized && quotaFilters && quotaFilters.length !== 0) {
      // We only want these fields
      const cloned = quotaFilters.map(qf => ({
        id: qf.id,
        expression: qf.expression,
        maxQuota: qf.maxQuota,
        name: qf.name,
        prodegeQuotaId: qf.prodegeQuotaId,
        sessionId: qf.sessionId,
        type: qf.type
      }));
      setState({
        quotaFilters: cloned,
        quotaFiltersInitialized: true
      });
    }
  }, [quotaFilters]);

  // Make sure there is always at least an empty input row for general filters.
  useEffect(() => {
    if (generalFiltersAreEmpty(state.rdConfig.configs.filterListConfig.filters)) {
      const rdConfigClone = cloneDeep(state.rdConfig);
      rdConfigClone.configs.filterListConfig.filters.push(getGeneralFilter());
      setState({ rdConfig: rdConfigClone });
    }
  }, [state.rdConfig.configs.filterListConfig.filters]);

  // Make sure there is always at least an empty input row for quota filters.
  useEffect(() => {
    if (quotaFiltersAreEmpty(state.quotaFilters)) {
      const stateQuotaFilters = cloneDeep(state.quotaFilters);
      stateQuotaFilters.push(getQuotaFilter(sessionId));
      setState({ quotaFilters: stateQuotaFilters });
    }
  }, [state.quotaFilters]);

  useEffect(() => {
    if (updateQuotaFiltersResponse && state.updateQuotaFiltersInProgress) {
      const { status, error } = updateQuotaFiltersResponse;
      if (status === 'OK') {
        toggle();
      } else if (error) {
        toast.error({ text: error.errorMessage });
        setState({ updateQuotaFiltersInProgress: false });
      }
    }
  }, [updateQuotaFiltersResponse]);

  function generalFiltersAreEmpty(filters) {
    return filters.length === 0 || (filters.length === 1 && filters[0].type === DEFAULT_FILTER);
  }

  function quotaFiltersAreEmpty(filters) {
    return filters.length === 0 || filters.every(f => f.updateAction === DELETE);
  }

  function toggleFilterBuilder(filter, index) {
    setState({
      filterToEdit: filter,
      filterToEditIndex: index,
      showFilterBuilder: !state.showFilterBuilder
    });
  }

  function toggleAdvancedFilter(filter, index, isQuota) {
    setState({
      filterToEdit: filter,
      filterToEditIndex: index,
      showAdvanceFilter: !state.showAdvanceFilter,
      isQuota
    });
  }

  function updateFilterName(filter, index, name) {
    if (filter.type === GENERAL_FILTER) {
      const rdConfigClone = cloneDeep(state.rdConfig);
      rdConfigClone.configs.filterListConfig.filters[index].name = name;
      setState({
        rdConfig: rdConfigClone
      });
    } else if (filter.type === QUOTA_FILTER) {
      const stateFilters = cloneDeep(state.quotaFilters);
      stateFilters[index].name = name;
      setState({ quotaFilters: stateFilters });
    }
  }

  function updateFilterQuota(index, quota) {
    const maxQuota = parseInt(quota, 10);
    if (maxQuota >= 0) {
      const stateFilters = cloneDeep(state.quotaFilters);
      stateFilters[index].maxQuota = maxQuota;
      setState({ quotaFilters: stateFilters });
    }
  }

  /*
   * Convert a quota filter to a general filter, put it into the filters array,
   * and remove it from the the quotaFilters array.
   */
  function convertToGeneralFilter(index) {
    const quotaFiltersClone = cloneDeep(state.quotaFilters);
    const quotaFilter = quotaFiltersClone[index];
    const generalFilter = {
      ...quotaFilter,
      type: GENERAL_FILTER
    };
    const rdConfigClone = cloneDeep(state.rdConfig);
    rdConfigClone.configs.filterListConfig.filters.push(generalFilter);
    if (quotaFilter.id) {
      // Mark existing quota filter for delete
      quotaFilter.updateAction = DELETE;
    } else {
      // Quota filter does not yet exist, so just remove it from the list.
      quotaFiltersClone.splice(index, 1);
    }

    setState({
      rdConfig: rdConfigClone,
      quotaFilters: quotaFiltersClone
    });
  }

  /*
   * Convert a general filter to a quota filter, put it into the quotaFilters array,
   * and remove it from the filters array.
   */
  function convertToQuotaFilter(index) {
    const rdConfigClone = cloneDeep(state.rdConfig);
    const { filters } = rdConfigClone.configs.filterListConfig;
    const generalFilter = filters[index];
    const quotaFilter = {
      ...generalFilter,
      type: QUOTA_FILTER,
      sessionId,
      maxQuota: 0,
      updateAction: 'Add'
    };
    const quotaFiltersClone = [...state.quotaFilters];
    quotaFiltersClone.push(quotaFilter);
    filters.splice(index, 1);

    setState({
      rdConfig: rdConfigClone,
      quotaFilters: quotaFiltersClone
    });
  }

  function addGeneralFilter(index) {
    const filter = getGeneralFilter();
    const rdConfigClone = cloneDeep(state.rdConfig);
    const { filters } = rdConfigClone.configs.filterListConfig;
    if (filters[index]) {
      filters.splice(index, 0, filter);
    } else {
      filters.push(filter);
    }
    setState({ rdConfig: rdConfigClone });
  }

  function addQuotaFilter(index) {
    const filter = getQuotaFilter(sessionId);
    const stateFilters = [...state.quotaFilters];
    if (stateFilters[index]) {
      stateFilters.splice(index, 0, filter);
    } else {
      stateFilters.push(filter);
    }
    setState({ quotaFilters: stateFilters });
  }

  function addFilter(type, index) {
    if (type === GENERAL_FILTER) {
      addGeneralFilter(index);
    } else if (type === QUOTA_FILTER) {
      addQuotaFilter(index);
    }
  }

  function removeGeneralFilter(index) {
    const rdConfigClone = cloneDeep(state.rdConfig);
    rdConfigClone.configs.filterListConfig.filters.splice(index, 1);
    setState({
      rdConfig: rdConfigClone
    });
  }

  function removeQuotaFilter(index) {
    const stateFilters = [...state.quotaFilters];
    if (stateFilters[index].id) {
      // Mark existing quota filter for delete
      stateFilters[index].updateAction = DELETE;
    } else {
      // Quota filter does not yet exist, so just remove it from the list.
      stateFilters.splice(index, 1);
    }

    setState({
      quotaFilters: stateFilters
    });
  }

  function removeFilter(filter, index) {
    if (filter.type === GENERAL_FILTER) {
      removeGeneralFilter(index);
    } else if (filter.type === QUOTA_FILTER) {
      removeQuotaFilter(index);
    }
  }

  function removeFilterExpression(type, filterIndex) {
    if (type === GENERAL_FILTER) {
      const rdConfigClone = cloneDeep(state.rdConfig);
      const filter = rdConfigClone.configs.filterListConfig.filters[filterIndex];
      filter.expression = '';
      setState({ rdConfig: rdConfigClone });
    } else if (type === QUOTA_FILTER) {
      const stateFilters = cloneDeep(state.quotaFilters);
      const filter = stateFilters[filterIndex];
      filter.expression = '';
      setState({ quotaFilters: stateFilters });
    }
  }

  function onFilterExpressionUpdate(expression, forAdvanced) {
    const { filterToEdit, filterToEditIndex } = state;
    if (filterToEdit.type === GENERAL_FILTER) {
      const rdConfigClone = cloneDeep(state.rdConfig);
      rdConfigClone.configs.filterListConfig.filters[filterToEditIndex].expression = expression;
      setState({
        rdConfig: rdConfigClone
      });
    } else if (filterToEdit.type === QUOTA_FILTER) {
      const stateFilters = cloneDeep(state.quotaFilters);
      stateFilters[filterToEditIndex].expression = expression;
      setState({ quotaFilters: stateFilters });
    }
    forAdvanced ? toggleAdvancedFilter() : toggleFilterBuilder();
  }

  function getValidGeneralFilters() {
    return state.rdConfig.configs.filterListConfig.filters.filter(f => f.name.trim() && f.expression);
  }

  function getValidQuotaFilters() {
    return state.quotaFilters.filter(f => f.name.trim() && f.expression);
  }

  function generalFiltersHaveChanged() {
    const { filters = [] } = rdConfig.configs.filterListConfig;
    const validFilters = getValidGeneralFilters();
    if (filters.length !== validFilters.length) {
      return true;
    }
    for (let i = 0; i < validFilters.length; i++) {
      const f1 = filters[i];
      const f2 = validFilters[i];
      if (f1.name !== f2.name || f1.expression !== f2.expression) {
        return true;
      }
    }
    return false;
  }

  function quotaFiltersHaveChanged() {
    const validFilters = getValidQuotaFilters();
    if (quotaFilters.length !== validFilters.length) {
      return true;
    }
    for (let i = 0; i < validFilters.length; i++) {
      const f1 = quotaFilters[i];
      const f2 = validFilters[i];
      if (
        f1.name !== f2.name ||
        f1.expression !== f2.expression ||
        f1.maxQuota !== f2.maxQuota ||
        f2.updateAction === DELETE
      ) {
        return true;
      }
    }
    return false;
  }

  /*
   * Recursive function for keeping all filters in all RD configs in sync with filterListConfig and quotaFilters.
   * Handles filters that have been deleted, renamed, or re-defined.
   */
  function syncAllFilters(obj, filters) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        if (Array.isArray(obj[key]) && obj.type !== 'filterListConfig' && obj[key].some(f => f.expression)) {
          obj[key] = obj[key].reduce((acc, curr) => {
            const filter = filters.find(f => f.id === curr.id);
            filter && acc.push(filter);
            return acc;
          }, []);
        }
        return syncAllFilters(obj[key], filters);
      }
    });
  }

  function saveGeneralFilters() {
    const rdConfigClone = cloneDeep(state.rdConfig);

    // Remove invalid filters
    rdConfigClone.configs.filterListConfig.filters = rdConfigClone.configs.filterListConfig.filters.filter(
      f => f.name.trim() && f.expression
    );

    const allFilters = [
      ...state.rdConfig.configs.filterListConfig.filters,
      ...state.quotaFilters.filter(f => f.updateAction !== DELETE)
    ];
    syncAllFilters(rdConfigClone.configs, allFilters);

    if (!isEqual(rdConfig.configs, rdConfigClone.configs)) {
      saveRDConfig(rdConfigClone);
    }
  }

  function saveQuotaFilters() {
    sendWSMessage({
      action: 'updateQuotaFilters',
      quotaFilters: getValidQuotaFilters()
    });
  }

  function save() {
    if (dupeNames.size) {
      toast.error({ text: intl.get('app.uniqueFilterNamesMsg', { dupes: [...dupeNames].join(', ') }) });
      return;
    }

    saveGeneralFilters();

    if (quotaFiltersHaveChanged()) {
      setState({ updateQuotaFiltersInProgress: true });
      saveQuotaFilters();
    } else {
      // If not saving quota filters, we can close the modal.
      toggle();
    }
  }

  /*
   * Need to use the advanced filter editor if the filter has any of the following:
   * A NOT operator
   * Grouping (parenthesis)
   * Mixed ANDs and ORs
   */
  function isAdvanced(expression) {
    const terms = filterUtil.getFilterExpression(expression);
    const counts = { bracket: 0, 'operator-!': 0, 'operator-and': 0, 'operator-or': 0 };
    terms.forEach(term => {
      if (term.type !== 'term') {
        let key = term.type;
        if (term.type === 'operator') {
          key += `-${term.value}`;
        }
        counts[key]++;
      }
    });
    return counts.bracket || counts['operator-!'] || (counts['operator-and'] && counts['operator-or']);
  }

  function getFilterLabel(filter, index, transformKeyMap, readOnly) {
    if (!filter.expression) {
      return null;
    }
    const expressionElem = filterUtil.renderFilterExpression(filter, questionJoiners, transformKeyMap);
    const onClick = isAdvanced(filter.expression)
      ? () => toggleAdvancedFilter(filter, index)
      : () => toggleFilterBuilder(filter, index);

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <FilterLabel
          name={expressionElem}
          close={readOnly ? null : () => removeFilterExpression(filter.type, index)}
          onClick={readOnly ? null : onClick}
        />
        <Button className="link-button" onClick={readOnly ? null : () => toggleAdvancedFilter(filter, index)}>
          {intl.get('app.advanced')}...
        </Button>
      </div>
    );
  }

  function isConvertToGeneralEnabled(filter) {
    return filter.name.trim() && filter.expression && !filter.prodegeQuotaId;
  }

  function isConvertToQuotaEnabled(filter) {
    return filter.name.trim() && filter.expression && filterUtil.isParticipantDataFilter(filter);
  }

  function getFilterRows(filters, isQuota) {
    const filterRows = [];
    filters.forEach((filter, index) => {
      if (filter.type !== DEFAULT_FILTER && filter.updateAction !== DELETE) {
        const isQuotaFilter = filter.type === QUOTA_FILTER;
        const readOnly = isQuotaFilter && !hasProjectManage;
        const filterLabel = getFilterLabel(
          filter,
          index,
          enrolleesInfo.keyTransformMap,
          readOnly || filter.prodegeQuotaId
        );
        filterRows.push(
          <tr>
            <td className="label-cell">
              <Input
                style={{ borderColor: dupeNames.has(filter.name) ? 'red' : '' }}
                value={filter.name}
                disabled={readOnly || filter.prodegeQuotaId}
                onChange={e => updateFilterName(filter, index, e.target.value)}
              />
            </td>
            {isQuotaFilter && (
              <td className="quota-cell">
                <Input
                  type="number"
                  min="0"
                  value={filter.maxQuota}
                  disabled={readOnly || filter.prodegeQuotaId}
                  onChange={e => updateFilterQuota(index, e.target.value)}
                />
              </td>
            )}
            <td className={classnames({ 'filter-cell': true, 'quota-filter-cell': isQuotaFilter })}>
              <div className="filter-bar">
                {!filterLabel && (
                  <div className={`add-filter-icon ${readOnly ? 'disabled' : ''}`}>
                    <div className="clickable" onClick={() => toggleFilterBuilder(filter, index)}>
                      +<FilterIcon />
                      <span className="filter-bar-placeholder">{intl.get('app.filterbar.placeholder')}</span>
                    </div>
                    <Button className="link-button" onClick={() => toggleAdvancedFilter(filter, index, isQuota)}>
                      {intl.get('app.advanced')}...
                    </Button>
                  </div>
                )}
                {filterLabel}
              </div>
            </td>
            {isQuotaFilter && hasProjectManage && (
              <td className="convert-filter-cell">
                {isConvertToGeneralEnabled(filter) && (
                  <i className="fas fa-level-down-alt" onClick={() => convertToGeneralFilter(index)} />
                )}
              </td>
            )}
            {filter.type === GENERAL_FILTER && hasProjectManage && (
              <td className="convert-filter-cell">
                {isConvertToQuotaEnabled(filter) && (
                  <i className="fas fa-level-up-alt" onClick={() => convertToQuotaFilter(index)} />
                )}
              </td>
            )}
            <td className="add-remove-cell">
              <AddRemoveIcons
                addDisabled={readOnly}
                removeDisabled={readOnly || filter.prodegeQuotaId}
                onAdd={() => addFilter(filter.type, index + 1)}
                onRemove={() => removeFilter(filter, index)}
              />
            </td>
          </tr>
        );
      }
    });
    return filterRows;
  }

  function isSaveEnabled() {
    const allFilters = [...state.rdConfig.configs.filterListConfig.filters, ...state.quotaFilters];

    // Check for invalid filters
    for (const filter of allFilters) {
      if (!filter.isNew && (!filter.name.trim() || !filter.expression)) {
        return false;
      }
      if (filter.isNew && !filter.name.trim() && filter.expression) {
        return false;
      }
      if (filter.isNew && filter.name.trim() && !filter.expression) {
        return false;
      }
    }

    return generalFiltersHaveChanged() || quotaFiltersHaveChanged();
  }

  return (
    <>
      {state.updateQuotaFiltersInProgress && <Loader fullScreen spinner />}
      <InvokeModal
        showModal
        className="rd-edit-filters-modal"
        modalTitle={intl.get('app.editFilters')}
        toggle={toggle}
        cancelButtonText={intl.get('app.cancel')}
        primaryButtonText={intl.get('app.save')}
        enableSave={isSaveEnabled()}
        save={save}
      >
        <section className="filters-container">
          <div className="title-bar">{intl.get('app.quotaFilters')}</div>
          <div className="filters-form-container">
            <table className="filters-form-controls">
              <thead>
                <tr>
                  <th>{intl.get('app.label')}</th>
                  <th>{intl.get('app.quota')}</th>
                  <th>{intl.get('app.filter')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{getFilterRows(state.quotaFilters, 'quota')}</tbody>
            </table>
          </div>
        </section>
        <section className="filters-container">
          <div className="title-bar">{intl.get('app.filters')}</div>
          <div className="filters-form-container">
            <table className="filters-form-controls">
              <thead>
                <tr>
                  <th>{intl.get('app.label')}</th>
                  <th>{intl.get('app.filter')}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{getFilterRows(state.rdConfig.configs.filterListConfig.filters)}</tbody>
            </table>
          </div>
        </section>
        {state.showFilterBuilder && (
          <SelectDataPointsModal
            filter={state.filterToEdit}
            enrolleesInfo={enrolleesInfo}
            project={project}
            questionJoiners={questionJoiners}
            rdConfig={rdConfig}
            selectedTab="participantData"
            toggle={toggleFilterBuilder}
            onSave={onFilterExpressionUpdate}
          />
        )}
        {state.showAdvanceFilter && (
          <AdvancedFilterBuilder
            filter={state.filterToEdit}
            isQuota={state.isQuota}
            sessionId={sessionId}
            toggle={toggleAdvancedFilter}
            title={intl.get('app.advanced.filterCriteria')}
            onSave={onFilterExpressionUpdate}
            enrolleesInfo={enrolleesInfo}
            project={project}
            questionJoiners={questionJoiners}
            rdConfig={rdConfig}
          />
        )}
      </InvokeModal>
    </>
  );
};
