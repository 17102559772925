import { connect } from 'react-redux';
import ArchivedProjectsPage from './ArchivedProjectsPage';
import { archivedProjectListSelector } from '../../../../store/redux/selectors/projectListSelector';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';
import { fetchArchivedProjectListActions } from '../../../../store/redux/actions/projectListActions';
import {
  restoreArchivedProjectAction,
  deleteArchivedProjectAction
} from '../../../../store/redux/actions/archiveProjectActions';

const mapStateToProps = state => {
  return {
    ...archivedProjectListSelector(state),
    abilities: sessionUserSelector(state).abilities
  };
};

const mapDispatchToProps = dispatch => ({
  fetchArchivedProjects: params => dispatch(fetchArchivedProjectListActions.request(params)),
  restoreArchivedProject: project => dispatch(restoreArchivedProjectAction.request(project)),
  deleteArchivedProject: project => dispatch(deleteArchivedProjectAction.request(project))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedProjectsPage);
