import React from 'react';
import { useSessionUserSelector } from '../../../../customHooks/reduxHelper';
import { SurveySettings } from './SurveySettings';
import { SurveySettingsSS } from './SurveySettingsSS';

import './SurveySettings.css';

/**
 * Wrapper for rendering the correct SurveySettings component. Currently only supports self-serve and non-self-serve.
 * The non-self-serve component currently supports screener and non-screener surveys, plus screener and non-screener
 * survey templates, using flags for conditional rendering. Would be nice to split some of that out into separate
 * components and render them from here. Maybe two to start: one for surveys and one for survey templates.
 */
export const SurveySettingsWrapper = props => {
  const { project, survey, translationInProgress } = props;

  const { isVideoCaptureEnabled, hasProjectManage } = useSessionUserSelector().abilities;
  const readOnly = !hasProjectManage || translationInProgress;

  if (project?.selfServe) {
    return <SurveySettingsSS {...props} readOnly={readOnly} />;
  }

  if (survey) {
    return <SurveySettings {...props} readOnly={readOnly} isVideoCaptureEnabled={isVideoCaptureEnabled} />;
  }
};
