import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { cloneDeep, get, isEqual } from 'lodash';
import { filterUtil } from '../../../../../../util/filterUtil';
import { saveRDConfig } from '../../../../../../util/researchDashboardUtil';
import { InvokeModal } from 'webapp-common';
import { FilterSelect } from '../../../../../../components/filterSelect/FilterSelect';

import './ClusterAnalysisSettingsModal.css';

const AUTO = 'AUTO';
const FIXED = 'FIXED';
const ALL = 'ALL';

function getDefaultConfig() {
  return {
    calculated: AUTO,
    calculatedOverride: 1,
    displayed: ALL,
    displayedOverride: 1,
    filters: []
  };
}

function isDirty(config1, config2) {
  return (
    config1.calculated !== config2.calculated ||
    config1.calculatedOverride !== config2.calculatedOverride ||
    config1.displayed !== config2.displayed ||
    config1.displayedOverride !== config2.displayedOverride ||
    !isEqual(filterUtil.getFilterJsonStrings(config1.filters), filterUtil.getFilterJsonStrings(config2.filters))
  );
}

function isBelowCalculated(config) {
  if (config.calculated !== 'FIXED') {
    return true;
  }
  return config.calculatedOverride >= config.displayedOverride;
}

function getGlobalClusterAnalysisConfig(rdConfig) {
  const clusterAnalysisConfig = get(rdConfig.configs, 'questionsConfig.globalQuestionConfig.clusterAnalysisConfig');
  return clusterAnalysisConfig ? cloneDeep(clusterAnalysisConfig) : getDefaultConfig();
}

function getQuestionClusterAnalysisConfig(rdConfig, qDefId) {
  const clusterAnalysisConfig = get(
    rdConfig.configs,
    `questionsConfig.questionsConfigMap.${qDefId}.clusterAnalysisConfig`
  );
  return clusterAnalysisConfig ? cloneDeep(clusterAnalysisConfig) : getDefaultConfig();
}

export const ClusterAnalysisSettingsModal = props => {
  const { rdConfig, questionJoiner, quotaFilters, hasProjectManage, toggle } = props;

  const origGlobalClusterAnalysisConfig = getGlobalClusterAnalysisConfig(rdConfig);
  const origQuestionClusterAnalysisConfig = getQuestionClusterAnalysisConfig(rdConfig, questionJoiner.def.id);

  const [globalClusterAnalysisConfig, updateGlobalClusterAnalysisConfig] = useState(origGlobalClusterAnalysisConfig);
  const [questionClusterAnalysisConfig, updateQuestionClusterAnalysisConfig] = useState(
    origQuestionClusterAnalysisConfig
  );

  // Fields are set in the question-specific config
  function setField(field, value) {
    updateQuestionClusterAnalysisConfig({
      ...questionClusterAnalysisConfig,
      [field]: value
    });
  }

  // Filters are set in the global config
  function updateFilters(filters) {
    updateGlobalClusterAnalysisConfig({
      ...globalClusterAnalysisConfig,
      filters
    });
  }

  function save() {
    const cloned = cloneDeep(rdConfig);
    const { questionsConfig } = cloned.configs;

    // Update global config
    questionsConfig.globalQuestionConfig = questionsConfig.globalQuestionConfig || {
      verbatimResponsesView: 'clusters'
    };
    questionsConfig.globalQuestionConfig.clusterAnalysisConfig = globalClusterAnalysisConfig;

    // Update question-specific config
    const { questionsConfigMap } = questionsConfig;
    questionsConfigMap[questionJoiner.def.id] = questionsConfigMap[questionJoiner.def.id] || {};
    questionsConfigMap[questionJoiner.def.id].clusterAnalysisConfig = questionClusterAnalysisConfig;

    saveRDConfig(cloned);
    toggle({ showSpinner: true });
  }

  const readOnly = !hasProjectManage;
  const saveEnabled =
    !readOnly &&
    (isDirty(globalClusterAnalysisConfig, origGlobalClusterAnalysisConfig) ||
      isDirty(questionClusterAnalysisConfig, origQuestionClusterAnalysisConfig)) &&
    isBelowCalculated(questionClusterAnalysisConfig);

  return (
    <InvokeModal
      showModal
      className="cluster-analysis-settings-modal"
      toggle={toggle}
      modalTitle={intl.get('app.clusteringSettings')}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.save')}
      enableSave={saveEnabled}
      save={save}
    >
      <div>
        <div>{intl.get('app.calculatedClusters')}</div>
        <div>
          <label>
            <Input
              type="radio"
              disabled={readOnly}
              checked={questionClusterAnalysisConfig.calculated === AUTO}
              onChange={() => setField('calculated', AUTO)}
            />
            {intl.get('app.automatic')}
          </label>
          <div>
            <label>
              <Input
                type="radio"
                disabled={readOnly}
                checked={questionClusterAnalysisConfig.calculated === FIXED}
                onChange={() => setField('calculated', FIXED)}
              />
              {intl.get('app.fixedCount')}
            </label>
            <Input
              type="number"
              className="fixed-count-field"
              value={Math.max(questionClusterAnalysisConfig.calculatedOverride, 1)}
              disabled={readOnly || questionClusterAnalysisConfig.calculated !== FIXED}
              onChange={e => setField('calculatedOverride', parseInt(e.target.value, 10))}
            />
          </div>
        </div>
      </div>
      <div>
        <div>{intl.get('app.displayedClusters')}</div>
        <div>
          <label>
            <Input
              type="radio"
              disabled={readOnly}
              checked={questionClusterAnalysisConfig.displayed === ALL}
              onChange={() => setField('displayed', ALL)}
            />
            {intl.get('app.all')}
          </label>
          <div>
            <label>
              <Input
                type="radio"
                disabled={readOnly}
                checked={questionClusterAnalysisConfig.displayed === FIXED}
                onChange={() => setField('displayed', FIXED)}
              />
              {intl.get('app.fixedCount')}
            </label>
            <Input
              type="number"
              className="fixed-count-field"
              value={Math.max(questionClusterAnalysisConfig.displayedOverride, 1)}
              disabled={readOnly || questionClusterAnalysisConfig.displayed !== FIXED}
              onChange={e => setField('displayedOverride', parseInt(e.target.value, 10))}
            />
          </div>
        </div>
      </div>
      <div>
        <div>{intl.get('app.subtotals')}</div>
        <div style={{ width: '100%' }}>
          <FilterSelect
            filters={rdConfig.configs.filterListConfig.filters}
            quotaFilters={quotaFilters}
            selectedFilters={globalClusterAnalysisConfig.filters}
            readOnly={readOnly}
            onFilterSelect={updateFilters}
          />
        </div>
      </div>
    </InvokeModal>
  );
};
