import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Select } from 'antd';
import { Loader } from 'webapp-common';
import { jsUtil } from '../../../../util/jsUtil';
import { isOpenMode } from '../../../../util/sessionUtil';
import localtime from '../../../../util/localtime';
import { getValidParticipantLists } from '../../../../util/participantDataUtil';
import { ParticipantEnrollmentSelectModal } from '../../../participantEnrollmentSelect';
import RDParticipantMgmt from '../../../researchDashboard/rdParticipantMgmt';

import './SurveyParticipants.css';

function getFileDownloadTimestamp() {
  const { year, month, day, hour, minute, second } = localtime.getDateParts(new Date(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
  return `${month}-${day}-${year}_${hour}-${minute}-${second}`;
}

export const SurveyParticipants = props => {
  const {
    projectId,
    storeId,
    sessionId,
    session,
    participantLists,
    participantListsRequested,
    participantCollectionJobsRequested,
    participantCollectionJobs,
    hasProjectManage
  } = props;

  const { participantCollectionIds } = session.config;
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [showParticipantEnrollmentSelect, setShowParticipantEnrollmentSelect] = useState(false);

  useEffect(() => {
    if (storeId && projectId) {
      props.fetchParticipantLists(storeId, projectId);
      props.fetchParticipantCollectionJobs({
        pageNumber: 1,
        pageSize: -1,
        storeId,
        projectId
      });
    }
  }, [storeId, projectId]);

  function getEnrollmentOptions() {
    const activeParticipantLists = getValidParticipantLists(participantLists, participantCollectionJobs);
    return activeParticipantLists.map(list => <Select.Option key={list.id}>{list.name}</Select.Option>);
  }

  function initiateDownload(e, type) {
    e.stopPropagation();
    e.preventDefault();
    setDownloadInProgress(true);
    jsUtil.initiateFileDownload(
      `/a/api/exportParticipantCSV/${sessionId}/${type}`,
      null,
      `${getFileDownloadTimestamp()}_${type}.csv`,
      () => setDownloadInProgress(false)
    );
  }

  return (
    <div>
      {(participantListsRequested || participantCollectionJobsRequested || downloadInProgress) && (
        <Loader spinner fullScreen lightbox={downloadInProgress} />
      )}
      <div className="participant-enrollment-select">
        {intl.get('app.participantEnrollment')}:{' '}
        <Select
          mode={session.screener ? '' : 'multiple'}
          style={{ minWidth: '10%', width: 'max-content' }}
          className={isOpenMode(session) || participantCollectionIds[0] ? 'mx-3' : 'mx-3 unselected'}
          placeholder={isOpenMode(session) ? '' : intl.get('app.placeholder.pleaseSelect') + ' ...'}
          value={participantCollectionIds}
          removeIcon={null}
          dropdownStyle={{ display: 'none' }}
          onClick={() => setShowParticipantEnrollmentSelect(true)}
        >
          {getEnrollmentOptions()}
        </Select>
        {participantCollectionIds.length > 0 && (
          <div className="download-icon">
            <i className="fas fa-download" />
            <ul className="dropdown-menu">
              <li>
                <a onClick={e => initiateDownload(e, 'participant')}>{intl.get('app.allParticipants')}</a>
              </li>
              <li>
                <a onClick={e => initiateDownload(e, 'delinquent')}>{intl.get('app.onlyInactive')}</a>
              </li>
            </ul>
          </div>
        )}
      </div>
      <RDParticipantMgmt
        sessionId={sessionId}
        setViewLanguage={props.setViewLanguage}
        language={props.language}
        viewLanguage={props.viewLanguage}
      />
      {showParticipantEnrollmentSelect && (
        <ParticipantEnrollmentSelectModal
          session={session}
          participantCollectionJobs={participantCollectionJobs}
          readOnly={!hasProjectManage}
          toggle={() => setShowParticipantEnrollmentSelect(false)}
        />
      )}
    </div>
  );
};
