import { takeEvery } from 'redux-saga/effects';
import { clusterAnalysisSubscribeActions } from '../actions/clusterAnalysisActions';
import { subscribeToChannel } from '../../../util/websocket';

function* clusterAnalysisSubscribe({ payload: { subAction, sessionId, questionJoinerId, questionDefId, rdConfig } }) {
  yield* subscribeToChannel({
    action: 'clusterAnalysis',
    subAction,
    key: `${sessionId}_${questionJoinerId}`,
    questionJoinerId,
    qdid: questionDefId,
    sessionId,
    rdConfig
  });
}

function* clusterAnalysisSubscribeSaga() {
  yield takeEvery(clusterAnalysisSubscribeActions.request, clusterAnalysisSubscribe);
}

export { clusterAnalysisSubscribeSaga };
