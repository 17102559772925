import { combineReducers, applyMiddleware, createStore } from 'redux';
import { identity } from 'lodash';
import { sagaMiddleware } from './sagas/saga';

import authReducer from './reducers/authReducer';
import projectListReducer from './reducers/projectListReducer';
import reportListReducer from './reducers/reportListReducer';
import questionListReducer from './reducers/questionListReducer';
import dialDataReducer from './reducers/dialDataReducer';
import filtersAndParticipantsReducer from './reducers/filtersAndParticipantsReducer';
import surveyTemplatesReducer from './reducers/surveyTemplatesReducer';
import surveyTemplateReducer from './reducers/surveyTemplateReducer';
import userReducer from './reducers/userReducer';
import sessionListReducer from './reducers/sessionListReducer';
import sessionReducer from './reducers/sessionReducer';
import mediaListReducer from './reducers/mediaListReducer';
import abilitiesReducer from './reducers/abilitiesReducer';
import tenantRolesReducer from './reducers/clientAdminTenantRolesReducer';

import projectDetailsReducer from './reducers/projectDetailsReducer';
import participantDataReducer from './reducers/participantDataReducer';
import videoCaptureReducer from './reducers/videoCaptureReducer';
import clientAdminReducer from './reducers/clientAdminReducer';
import clientAdminUsersReducer from './reducers/clientAdminUsersReducer';
import surveysReducer from './reducers/surveysReducer';
import customReportConfigReducer from './reducers/customReportConfigReducer';
import dataTableReducer from './reducers/dataTableReducer';
import functionReducer from './reducers/functionReducer';
import { rdSubscribeReducer } from './reducers/researchDashboard/rdSubscribeReducer';
import { rdActiveParticipantsReducer } from './reducers/researchDashboard/rdActiveParticipantsReducer';
import { rdChatsReducer } from './reducers/researchDashboard/rdChatsReducer';
import { rdConfigReducer } from './reducers/researchDashboard/rdConfigReducer';
import { rdEnrolleesReducer } from './reducers/researchDashboard/rdEnrolleesReducer';
import { rdFilteredEnrolleesReducer } from './reducers/researchDashboard/rdFilteredEnrolleesReducer';
import { rdParticipantDataPointsReducer } from './reducers/researchDashboard/rdParticipantDataPointsReducer';
import { rdParticipantSummaryFiltersReducer } from './reducers/researchDashboard/rdParticipantSummaryFiltersReducer';
import { rdQuestionJoinerReducer } from './reducers/researchDashboard/rdQuestionJoinerReducer';
import { rdQuestionJoinerResponseRateReducer } from './reducers/researchDashboard/rdQuestionJoinerResponseRateReducer';
import { rdSessionQuotaFiltersReducer } from './reducers/researchDashboard/rdSessionQuotaFiltersReducer';
import { rdSessionReducer } from './reducers/researchDashboard/rdSessionReducer';
import { rdAggregateDataReducer } from './reducers/researchDashboard/rdAggregateDataReducer';
import { rdVerbatimDataReducer } from './reducers/researchDashboard/rdVerbatimDataReducer';
import { rdClusterAnalysisReducer } from './reducers/researchDashboard/rdClusterAnalysisReducer';
import { rdParticipantAnswersReducer } from './reducers/researchDashboard/rdParticipantAnswersReducer';
import { rdComparativeViewReducer } from './reducers/researchDashboard/rdComparativeViewReducer';
import blacklistReducer from './reducers/blacklistReducer';
import emotionDataReducer from './reducers/emotionDataReducer';
import { virtualFocusGroupReducer } from './reducers/virtualFocusGroupReducer';
import { watermarkReducer } from './reducers/watermarkReducer';

const configureStore = (initialState = {}) => {
  const rootReducer = combineReducers({
    auth: authReducer,
    sessionUser: userReducer,
    abilities: abilitiesReducer,
    projectListDetails: projectListReducer,
    reportListDetails: reportListReducer,
    questionListDetails: questionListReducer,
    dialDataStore: dialDataReducer,
    filtersAndParticipants: filtersAndParticipantsReducer,
    sessionListDetails: sessionListReducer,
    session: sessionReducer,
    projects: projectDetailsReducer,
    surveyTemplates: surveyTemplatesReducer,
    surveyTemplate: surveyTemplateReducer,
    surveys: surveysReducer,
    mediaListLibrary: mediaListReducer,
    participantDataDetails: participantDataReducer,
    videoCapture: videoCaptureReducer,
    clientAdmin: clientAdminReducer,
    tenantRoles: tenantRolesReducer,
    clientAdminUsers: clientAdminUsersReducer,
    customReportConfig: customReportConfigReducer,
    dataTable: dataTableReducer,
    function: functionReducer,
    rdSubscribe: rdSubscribeReducer,
    rdActiveParticipants: rdActiveParticipantsReducer,
    rdChats: rdChatsReducer,
    rdConfig: rdConfigReducer,
    rdEnrollees: rdEnrolleesReducer,
    rdFilteredEnrollees: rdFilteredEnrolleesReducer,
    rdParticipantDataPoints: rdParticipantDataPointsReducer,
    rdParticipantSummaryFilters: rdParticipantSummaryFiltersReducer,
    rdQuestionJoiners: rdQuestionJoinerReducer,
    rdQuestionJoinerResponseRates: rdQuestionJoinerResponseRateReducer,
    rdSessionQuotaFilters: rdSessionQuotaFiltersReducer,
    rdSession: rdSessionReducer,
    rdAggregateData: rdAggregateDataReducer,
    rdComparativeViewAggregateData: rdComparativeViewReducer,
    rdVerbatimData: rdVerbatimDataReducer,
    rdClusterAnalysis: rdClusterAnalysisReducer,
    rdParticipantAnswers: rdParticipantAnswersReducer,
    blacklist: blacklistReducer,
    emotionDataStore: emotionDataReducer,
    virtualFocusGroupStore: virtualFocusGroupReducer,
    watermarkStore: watermarkReducer
  });

  // Create the store
  const compose = identity;

  return createStore(rootReducer, initialState, compose(applyMiddleware(sagaMiddleware)));
};

export { configureStore };
