import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

// These match the sentiment colors in styleConstants.scss
const COLORS = {
  POSITIVE: '#d2dec4',
  NEUTRAL: '#ddd',
  NEGATIVE: '#f2c1c8'
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function buildData(sentiments = {}) {
  const data = [];
  for (const key in sentiments) {
    if (sentiments[key]) {
      data.push({ key, value: sentiments[key] });
    }
  }
  return data;
}

export const SentimentSummaryPieChart = props => {
  const data = buildData(props.sentiments);

  return (
    <div>
      <PieChart width={200} height={200}>
        <Pie
          data={data}
          dataKey="value"
          label={renderCustomizedLabel}
          outerRadius="100%"
          labelLine={false}
          blendStroke={true}
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[entry.key]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};
