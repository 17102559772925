import { createStructuredSelector, createSelector } from 'reselect';

const selectReportListDetails = state => state.reportListDetails;
const selectReportListRequested = reportListDetails => reportListDetails.reportListRequested;
const selectReportPagination = reportListDetails => reportListDetails.pageRequest;
const selectReportContent = reportListDetails => reportListDetails.content;
const selectReportTotalElements = reportListDetails => reportListDetails.totalElements;

const reportListSelector = createSelector(
  selectReportListDetails,
  createStructuredSelector({
    reportListRequested: selectReportListRequested,
    reports: selectReportContent,
    pagination: selectReportPagination,
    totalElements: selectReportTotalElements
  })
);

export { reportListSelector };
