import React, { useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { InvokeTable } from 'webapp-common';
import { Icons } from '../../components/icons/Icons';

export const SurveyTable = props => {
  const { hasProjectManage, allSessions, isScreener, cloneSession, goToSurvey } = props;

  const surveyNameFormatter = useCallback(
    info => {
      const session = info.row.original;
      return (
        <span onClick={() => goToSurvey(session.id)} className="link" title={session.name}>
          {session.name}
        </span>
      );
    },
    [goToSurvey]
  );

  const statusFormatter = useCallback(info => {
    return intl.get(`app.session.state.${info.getValue()}`);
  }, []);

  const getNameHeading = useCallback(() => {
    return intl.get(isScreener ? 'app.screener.name' : 'app.survey.name');
  }, [isScreener]);

  const createDateFormatter = useCallback(info => {
    const session = info.row.original;
    return session.created;
  }, []);

  const cloneFormatter = useCallback(
    info => {
      const session = info.row.original;
      return <Icons.CopyIcon onClick={() => cloneSession(session.id)} />;
    },
    [cloneSession]
  );

  const columns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'name',
        header: getNameHeading(),
        headerStyle: { width: '23rem' },
        cell: surveyNameFormatter,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'createDate',
        header: intl.get('app.createDate'),
        headerStyle: { width: '12rem' },
        cell: createDateFormatter
      },
      {
        accessorKey: 'state',
        header: intl.get('app.status'),
        headerStyle: { width: '12rem' },
        cell: statusFormatter
      },
      {
        accessorKey: 'includedParticipants',
        header: intl.get('app.numInvited'),
        headerStyle: { width: '12rem' },
        enableSorting: false
      },
      {
        accessorKey: 'surveySubmissions',
        header: intl.get('app.numResponses'),
        headerStyle: { width: '12rem' },
        enableSorting: false
      }
    ];
    if (hasProjectManage) {
      columns.push({
        header: intl.get('app.clone'),
        headerStyle: { paddingLeft: '2rem', width: '5rem' },
        cell: cloneFormatter,
        cellClassName: 'centered clickable',
        enableSorting: false
      });
    }
    return columns;
  }, [cloneFormatter, createDateFormatter, getNameHeading, hasProjectManage, statusFormatter, surveyNameFormatter]);

  return (
    <InvokeTable
      className="invoke-table"
      columns={columns}
      data={allSessions.content ?? []}
      initialState={{
        sorting: [
          {
            id: 'createDate',
            desc: true
          }
        ]
      }}
      onSortingChange={props.sort}
      onPaginationChange={props.paginate}
    />
  );
};
