import { connect } from 'react-redux';
import QuestionList from './QuestionList';
import { questionListSelector } from '../../store/redux/selectors/questionListSelector';
import { fetchQuestionListActions } from '../../store/redux/actions/questionListActions';

const mapDispatchToProps = dispatch => ({
  fetchQuestions: payload => dispatch(fetchQuestionListActions.request(payload))
});

const mapStateToProps = state => {
  return {
    ...questionListSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
