import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { cloneDeep, get } from 'lodash';
import { ENGLISH } from '../../../../../util/joinerUtil';
import { saveCompleteRDConfigActions } from '../../../../../store/redux/actions/researchDashboardActions';
import { getRDConfig } from '../../../../../store/redux/selectors/researchDashboardSelector';

export const TranslateLabel = props => {
  const { setViewLanguage, language, classes, sessionId } = props;
  const dispatch = useDispatch();
  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  const viewLanguage =
    props.viewLanguage || get(rdConfig, 'configs.questionsConfig.globalQuestionConfig.displayLanguage') || ENGLISH;

  const onLanguageChange = e => {
    const clone = cloneDeep(rdConfig);
    const { questionsConfig } = clone.configs;
    questionsConfig.globalQuestionConfig = {
      ...questionsConfig.globalQuestionConfig,
      displayLanguage: e.target.value
    };
    dispatch(saveCompleteRDConfigActions.request(clone));
    setViewLanguage(e.target.value);
  };

  if (language === ENGLISH) {
    return null;
  }

  return (
    <div className={classes} style={{ width: 'fit-content' }}>
      <Input type="select" value={viewLanguage} onChange={onLanguageChange}>
        <option value={ENGLISH}>{intl.get('app.language.en')}</option>
        <option value={language}>{intl.get(`app.language.${language}`)}</option>
      </Input>
    </div>
  );
};
