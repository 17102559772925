import React, { memo, useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual, remove, find } from 'lodash';
import { Input, Row } from 'reactstrap';
import { InvokeModal, Loader } from 'webapp-common';
import { getFeatureAbilities } from '../../../util/abilitiesUtil';

import './EditRoles.css';

const skipUpdate = (prevProps, nextProps) => {
  if (!isEqual(prevProps.abilities, nextProps.abilities)) {
    return false;
  }
  if (!isEqual(prevProps.tenantRoles, nextProps.tenantRoles)) {
    return false;
  }
  return true;
};

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

const mapAbilities = (fromAbilities, fromRole) => {
  if (!fromAbilities || !fromRole) {
    return [];
  }
  return fromRole.map(a => find(fromAbilities, fa => fa.featureAbilityID === a.featureAbilityID));
};

export const EditRoles = memo(props => {
  const FEATURE_ABILITIES = getFeatureAbilities();
  const { tenantRoleId, roleAdd, toggle, tenantRoles = {}, abilities = {} } = props;
  const readOnly = !props.hasRoleManage;

  const { fetchTenantRolesRequested, updateTenantRolesRequested, deleteTenantRolesRequested, addRoleRequested } =
    tenantRoles;

  const showLoader =
    abilities.requestAbilitiesInProgress ||
    fetchTenantRolesRequested ||
    addRoleRequested ||
    updateTenantRolesRequested ||
    deleteTenantRolesRequested;

  const [state, setState] = useReducer(reducer, {
    role: { roleLabel: '', roleDescription: '', featureAbilities: [] },
    roleAdd: roleAdd,
    abilityList: FEATURE_ABILITIES,
    savedAbilities: FEATURE_ABILITIES
  });

  const selectedRole = tenantRoles.roles.find(r => r && r.tenantRoleID === tenantRoleId);

  useEffect(() => {
    props.fetchAbilities();
    if (!state.roleAdd) {
      props.fetchRoles();
    }
  }, []);

  useEffect(() => {
    if (selectedRole) {
      const abilitiesArray = mapAbilities(abilities.abilities, selectedRole.featureAbilities);
      const abilityList = cloneDeep(state.abilityList);
      abilitiesArray.length > 0 &&
        abilityList.length > 0 &&
        abilityList.forEach(a => {
          const view = find(abilitiesArray, ab => ab.abilityIdentifier === a.viewIdentifier);
          const manage = find(abilitiesArray, ab => ab.abilityIdentifier === a.manageIdentifier);
          a.manage = manage !== undefined;
          a.view = view !== undefined;
        });
      setState({ abilityList, savedAbilities: abilityList, role: cloneDeep(selectedRole) });
    }
  }, [abilities.abilities, tenantRoles.roles]);

  const editRole = (value, property) => {
    const role = cloneDeep(state.role);
    role[property] = value;
    setState({
      role
    });
  };

  const onAbilityChange = (status, access, index) => {
    const abilityList = cloneDeep(state.abilityList);
    const viewAbility = find(abilities.abilities, a => a.abilityIdentifier === abilityList[index].viewIdentifier);
    const manageAbility = find(abilities.abilities, a => a.abilityIdentifier === abilityList[index].manageIdentifier);
    const role = cloneDeep(state.role);
    remove(role.featureAbilities, fa => fa.featureAbilityID === manageAbility.featureAbilityID);
    remove(role.featureAbilities, fa => fa.featureAbilityID === viewAbility.featureAbilityID);
    if (access === 'VIEW') {
      abilityList[index].view = status;
      abilityList[index].manage = false;
      if (status) {
        role.featureAbilities.push(viewAbility);
      }
    } else {
      abilityList[index].manage = status;
      abilityList[index].view = status;
      if (status) {
        role.featureAbilities.push(manageAbility);
        role.featureAbilities.push(viewAbility);
      }
    }
    setState({ abilityList, role });
  };

  const getRoleAbilities = () => {
    return state.abilityList.map((a, i) => (
      <Row key={a.viewIdentifier}>
        <label className="full-width clickable">
          <span className="ability-label">{a.abilityName}</span>
          <span className="view align-center">
            <Input
              className="clickable"
              type="checkbox"
              checked={a.view && !a.manage}
              disabled={readOnly}
              onChange={e => onAbilityChange(e.target.checked, 'VIEW', i)}
            />
          </span>
          <span className="manage align-center">
            <Input
              type="checkbox"
              className="clickable"
              checked={a.manage}
              disabled={readOnly}
              onChange={e => onAbilityChange(e.target.checked, 'MANAGE', i)}
            />
          </span>
        </label>
      </Row>
    ));
  };

  const isSaveEnabled = () => {
    const stateRole = state.role;
    if (!stateRole || !stateRole.roleLabel.trim()) {
      return false;
    }
    if (state.roleAdd) {
      return stateRole.roleLabel.trim();
    }
    const propsRole = selectedRole;
    if (
      propsRole.roleLabel.trim() !== stateRole.roleLabel.trim() ||
      propsRole.roleDescription.trim() !== stateRole.roleDescription.trim()
    ) {
      return true;
    }
    return !isEqual(state.abilityList, state.savedAbilities);
  };

  const save = () => {
    const { role } = state;
    const featureAbilities = role.featureAbilities.map(fa => fa.featureAbilityID);
    props.updateRoles({
      tenantRoleID: role.tenantRoleID,
      tenantRole: role.roleLabel.trim(),
      roleDescription: role.roleDescription.trim(),
      featureAbilities
    });
  };

  const { role } = state;

  const addRole = () => {
    const featureAbilities = role.featureAbilities.map(fa => fa.featureAbilityID);
    props.addRole({
      roleDescription: role.roleDescription,
      tenantRole: role.roleLabel,
      featureAbilities
    });
  };

  const deleteRole = () => {
    if (window.confirm(intl.get('app.role.delete.confirmation', { role: role.roleLabel }))) {
      const { role } = state;
      props.deleteRoles({ tenantRoleID: role.tenantRoleID });
    }
  };

  return (
    <div>
      {showLoader && <Loader spinner fullScreen />}
      <InvokeModal
        className="edit-modal"
        modalTitle={props.roleAdd ? intl.get('app.addRole') : intl.getHTML('app.editRole', { name: role.roleLabel })}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        deleteButtonText={props.roleAdd ? null : intl.get('app.delete')}
        delete={deleteRole}
        save={props.roleAdd ? addRole : save}
        enableSave={!readOnly && isSaveEnabled()}
        toggle={toggle}
        enableDelete={!readOnly}
        backdrop={false}
        keyboard={false}
        showModal
      >
        <div className="edit-roles">
          <Row className="mt-4 pe-4">
            <label className="col-sm-4 ps-4">{intl.get('app.role.name')}</label>
            <Input
              style={{ width: '66%' }}
              value={role?.roleLabel}
              disabled={readOnly}
              onChange={e => editRole(e.target.value, 'roleLabel')}
            />
          </Row>
          <Row className="mt-4 pe-4">
            <label className="col-sm-4 ps-4">{intl.get('app.description')}</label>
            <Input
              style={{ width: '66%' }}
              value={role?.roleDescription}
              disabled={readOnly}
              onChange={e => editRole(e.target.value, 'roleDescription')}
            />
          </Row>
          <Row className="mt-4">
            <label className="col-sm-4 ps-4">{intl.get('app.abilities')}</label>
            <Row className="col-sm-8">
              <div className="inline-block roles-row me-3">
                <Row>
                  <label className="full-width">
                    <span className="ability-label"> </span>
                    <span className="read-only align-center">{intl.get('app.readOnly')}</span>
                    <span className="full-access align-center">{intl.get('app.fullAccess')}</span>
                  </label>
                </Row>
                {getRoleAbilities()}
              </div>
            </Row>
          </Row>
        </div>
      </InvokeModal>
    </div>
  );
}, skipUpdate);
