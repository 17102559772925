import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Lightbox } from 'react-modal-image';

import './ImageLightbox.css';

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

// Combined with the local showLightbox state, ensures the lightbox remains open on re-mount/re-render.
const LightboxContext = React.createContext({ currentId: '' });

const skipUpdate = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export const ImageLightbox = memo(props => {
  const { id, className, src, largerImageOnHover, alt = '' } = props;

  const lightboxId = `imageLightbox-${id}-${src}`;
  const lightboxContext = useContext(LightboxContext);

  const [largerImage, setLargerImage] = useState();
  const [showLightbox, setShowLightbox] = useState(lightboxContext.currentId === lightboxId);

  function onMouseOver(e) {
    if (largerImageOnHover) {
      const x =
        (largerImageOnHover === 'left' && e.clientX - (MAX_WIDTH + 20)) ||
        (largerImageOnHover === 'right' && e.clientX + e.currentTarget.width + 20);
      const y = e.clientY + e.currentTarget.height / 2 - MAX_HEIGHT / 2;
      setLargerImage({ x, y });
    }
  }

  function onMouseOut() {
    if (largerImageOnHover) {
      setLargerImage(null);
    }
  }

  function toggleLightbox(e) {
    if (e) {
      e.stopPropagation();
    }
    lightboxContext.currentId = showLightbox ? '' : lightboxId;
    setShowLightbox(!showLightbox);
  }

  function handleLightboxClick(e) {
    e.stopPropagation();
  }

  return (
    <>
      <img
        className={className}
        style={{ cursor: 'zoom-in' }}
        src={src}
        alt={alt}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={toggleLightbox}
      />
      {largerImage && (
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            top: largerImage.y,
            left: largerImage.x,
            width: MAX_WIDTH,
            height: MAX_HEIGHT,
            zIndex: 1
          }}
        >
          <img src={src} alt="" style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }} />
        </div>
      )}
      {showLightbox && (
        <div onClick={handleLightboxClick}>
          <Lightbox large={src} alt={alt} hideDownload hideZoom onClose={toggleLightbox} />
        </div>
      )}
    </>
  );
}, skipUpdate);

ImageLightbox.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  largerImageOnHover: PropTypes.oneOf(['left', 'right']),
  alt: PropTypes.string
};
