import React from 'react';
import intl from 'react-intl-universal';
import { find, get } from 'lodash';
import { Input } from 'reactstrap';
import {
  RESPONSE_SET_TYPE,
  ANSWER_TYPE,
  SURVEY_RULE_TYPE,
  OPTION_TYPE,
  OPERATORS
} from '../../../../../../util/joinerUtil';
import { Loader } from 'webapp-common';

class LookupValueCondition extends React.Component {
  constructor(props) {
    super(props);
    props.fetchAllDataTables();
  }

  setLookupValueConditionDataTableId = (ruleIndex, conditionIndex, dataTableId) => {
    this.props.updateHandlers.setLookupValueConditionDataTableId(ruleIndex, conditionIndex, dataTableId);
  };

  getDataTablesSelectList = () => {
    const options = [];
    this.props.allDataTablesSelector.allDataTables.forEach(dt => {
      if (!dt.deleted || (this.props.condition && this.props.condition.dataTableId === dt.id)) {
        options.push(
          <option value={dt.id} key={dt.id}>
            {dt.name}
          </option>
        );
      }
    });
    return options;
  };

  getColumnSelectList = dataTableId => {
    if (!dataTableId) {
      return null;
    }
    const dataTable = find(this.props.allDataTablesSelector.allDataTables, dt => dt.id === dataTableId) || {};
    const { valueColumnHeaders = [], keyTransformMap } = dataTable;
    return valueColumnHeaders.map(col => {
      const name = (keyTransformMap && keyTransformMap[col]) || col;
      return (
        <option value={col} key={col}>
          {name}
        </option>
      );
    });
  };

  getJoinerSelectList = () => {
    const { activeJoiner, joiners, rule } = this.props;
    const options = [];
    for (let i = 0; i < joiners.length; i++) {
      const joiner = joiners[i];
      const type = get(joiner, 'def.responseSet.type');
      if (rule.type === SURVEY_RULE_TYPE.includeQuestionRule && joiner.id === activeJoiner.id) {
        // Can only select previous joiners
        break;
      }
      if (type === RESPONSE_SET_TYPE.open && !joiner.hidden) {
        // Question dropdown should show only Open Text questions
        options.push(
          <option value={joiner.id} key={joiner.id}>
            {joiner.researchPrompt}
          </option>
        );
      }
      if (joiner.id === activeJoiner.id) {
        // Can only select previous and current joiner
        break;
      }
    }
    return options;
  };

  isNumericAnswer = joiner => {
    const { expectedAnswerType, options } = joiner.def.responseSet;
    return (
      (expectedAnswerType === ANSWER_TYPE.double || expectedAnswerType === ANSWER_TYPE.numeric) &&
      options.some(o => o.type === OPTION_TYPE.limitoption)
    );
  };

  getOperatorSelectList = () => {
    return [
      <option value={OPERATORS.lt} key="op1">
        {intl.get('app.surveyRules.operator.lt')}
      </option>,
      <option value={OPERATORS.lte} key="op2">
        {intl.get('app.surveyRules.operator.lte')}
      </option>,
      <option value={OPERATORS.eq} key="op3">
        {intl.get('app.surveyRules.operator.eq')}
      </option>,
      <option value={OPERATORS.gte} key="op4">
        {intl.get('app.surveyRules.operator.gte')}
      </option>,
      <option value={OPERATORS.gt} key="op5">
        {intl.get('app.surveyRules.operator.gt')}
      </option>,
      <option value={OPERATORS.ne} key="op6">
        {intl.get('app.surveyRules.operator.ne')}
      </option>
    ];
  };

  render() {
    const { ruleIndex, condition, conditionIndex, updateHandlers, readOnly } = this.props;
    const showLoader = this.props.allDataTablesSelector.allDataTablesRequested;

    return (
      <>
        {showLoader && <Loader spinner fullScreen />}
        <div>
          <div>{intl.get('app.question')}</div>
          <Input
            type="select"
            value={condition.joinerId}
            disabled={readOnly}
            onChange={e => updateHandlers.setRuleConditionJoinerId(ruleIndex, conditionIndex, e.target.value)}
          >
            <option />
            {this.getJoinerSelectList()}
          </Input>
        </div>
        <div>
          <div>{intl.get('app.dataTable')}</div>
          <Input
            type="select"
            value={condition.dataTableId}
            disabled={readOnly}
            onChange={e => this.setLookupValueConditionDataTableId(ruleIndex, conditionIndex, e.target.value)}
          >
            <option />
            {this.getDataTablesSelectList()}
          </Input>
        </div>
        <div>
          <div>{intl.get('app.column')}</div>
          <Input
            type="select"
            value={condition.column || ''}
            disabled={readOnly}
            onChange={e => updateHandlers.setLookupValueConditionColumn(ruleIndex, conditionIndex, e.target.value)}
          >
            <option />
            {this.getColumnSelectList(condition.dataTableId)}
          </Input>
        </div>
        <div>
          <div>{intl.get('app.operator')}</div>
          <Input
            type="select"
            value={condition.operator}
            disabled={readOnly}
            onChange={e => updateHandlers.setRuleConditionOperator(ruleIndex, conditionIndex, e.target.value)}
          >
            <option />
            {this.getOperatorSelectList()}
          </Input>
        </div>
        <div>
          <div>&nbsp;</div>
          <Input
            type="number"
            className="number-input"
            value={condition.values[0] || ''}
            disabled={readOnly}
            onChange={e => updateHandlers.updateRuleConditionValues(ruleIndex, conditionIndex, e.target.value, true)}
          />
        </div>
      </>
    );
  }
}

export default LookupValueCondition;
