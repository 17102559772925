import { handleActions, combineActions } from 'redux-actions';
import { find } from 'lodash';
import { fetchProjectActions } from '../actions/fetchProject';
import { saveProjectActions } from '../actions/saveProject';
import {
  archiveProjectActions,
  restoreArchivedProjectAction,
  deleteArchivedProjectAction
} from '../actions/archiveProjectActions';
import { fetchValidationActions } from '../actions/fetchValidation';
import {
  createMetadataCollectionActions,
  saveMetadataJobActions,
  setMetadataCollectionActions,
  replaceMetadataJobActions,
  deleteMetadataCollectionActions,
  fetchParticipantListsActions,
  fetchParticipantCollectionJobsActions,
  pingParticipantCollectionJobsActions
} from '../actions/fetchParticipantLists';

import { entityType } from '../../../util/jsUtil';

const initialState = {};

const projectDetailsReducer = handleActions(
  {
    [combineActions(fetchProjectActions.triggered, saveProjectActions.triggered)](state, { payload }) {
      const id = payload.id || payload.projectId;
      return {
        ...state,
        [id]: {
          ...state[id],
          projectDetailsRequested: true
        }
      };
    },

    [combineActions(fetchProjectActions.succeeded, saveProjectActions.succeeded)](state, { payload }) {
      const id = payload.id || payload.projectId;
      return {
        ...state,
        [id]: {
          ...state[id],
          projectDetails: payload,
          projectDetailsRequested: false
        }
      };
    },

    [combineActions(fetchProjectActions.failed, saveProjectActions.failed)](state, { payload: { project } }) {
      const id = project.id || project.projectId;
      return {
        ...state,
        [id]: {
          ...state[id],
          projectDetailsRequested: false
        }
      };
    },

    [fetchValidationActions.succeeded](state, { payload }) {
      const { id, entity } = payload;
      if (entity !== entityType.project) {
        return state;
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          validation: payload.validation,
          validationInprogress: false
        }
      };
    },

    [fetchValidationActions.triggered](state, { payload }) {
      const { id, entity } = payload;
      if (entity !== entityType.project) {
        return state;
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          validationInprogress: true
        }
      };
    },

    [createMetadataCollectionActions.triggered](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          createMetadataCollectionRequested: true,
          createMetadataCollectionSuccess: false,
          newMetadataCollection: null
        }
      };
    },

    [createMetadataCollectionActions.succeeded](state, { payload }) {
      const { projectId, newMetadataCollection } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          createMetadataCollectionRequested: false,
          createMetadataCollectionSuccess: true,
          newMetadataCollection
        }
      };
    },

    [createMetadataCollectionActions.failed](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          createMetadataCollectionRequested: false
        }
      };
    },

    [saveMetadataJobActions.triggered](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          saveMetadataJobRequested: true,
          saveMetadataJobSuccess: false
        }
      };
    },

    [saveMetadataJobActions.succeeded](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          saveMetadataJobRequested: false,
          saveMetadataJobSuccess: true
        }
      };
    },

    [saveMetadataJobActions.failed](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          saveMetadataJobRequested: false
        }
      };
    },

    [setMetadataCollectionActions.triggered](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          setMetadataCollectionRequested: true,
          setMetadataCollectionSuccess: false,
          newMetadataCollection: null
        }
      };
    },

    [setMetadataCollectionActions.succeeded](state, { payload }) {
      const { projectId, newMetadataCollection } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          setMetadataCollectionRequested: false,
          setMetadataCollectionSuccess: true,
          newMetadataCollection
        }
      };
    },

    [setMetadataCollectionActions.failed](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          setMetadataCollectionRequested: false
        }
      };
    },

    [replaceMetadataJobActions.triggered](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          replaceMetadataJobRequested: true,
          replaceMetadataJobSuccess: false
        }
      };
    },

    [replaceMetadataJobActions.succeeded](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          replaceMetadataJobRequested: false,
          replaceMetadataJobSuccess: true
        }
      };
    },

    [replaceMetadataJobActions.failed](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          replaceMetadataJobRequested: false
        }
      };
    },

    [deleteMetadataCollectionActions.triggered](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          deleteMetadataCollectionInProgress: true
        }
      };
    },

    [combineActions(deleteMetadataCollectionActions.succeeded, deleteMetadataCollectionActions.failed)](
      state,
      { payload }
    ) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          deleteMetadataCollectionInProgress: false
        }
      };
    },

    [fetchParticipantListsActions.triggered](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          participantListsRequested: true
        }
      };
    },

    [fetchParticipantListsActions.succeeded](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          participantLists: payload.participantLists,
          participantListsRequested: false
        }
      };
    },

    [fetchParticipantCollectionJobsActions.triggered](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          participantCollectionJobsRequested: true
        }
      };
    },

    [fetchParticipantCollectionJobsActions.succeeded](state, { payload }) {
      const { projectId } = payload;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          participantCollectionJobs: payload.participantCollectionJobs,
          participantCollectionJobsRequested: false
        }
      };
    },

    [pingParticipantCollectionJobsActions.succeeded](state, { payload }) {
      const { projectId, jobs } = payload;
      const newJobs = state[projectId].participantCollectionJobs.content.map(
        job => find(jobs, j => j.jobId === job.jobId) || job
      );
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          participantCollectionJobs: {
            ...state[projectId].participantCollectionJobs,
            content: newJobs
          }
        }
      };
    },

    [archiveProjectActions.triggered](state) {
      return {
        ...state,
        archiveProjectRequested: true
      };
    },

    [archiveProjectActions.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload,
        archiveProjectRequested: false
      };
    },

    [archiveProjectActions.failed](state) {
      return {
        ...state,
        archiveProjectRequested: false
      };
    },

    [restoreArchivedProjectAction.triggered](state) {
      return {
        ...state,
        restoreArchiveProjectRequested: true
      };
    },

    [restoreArchivedProjectAction.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload,
        restoreArchiveProjectRequested: false
      };
    },

    [restoreArchivedProjectAction.failed](state) {
      return {
        ...state,
        restoreArchiveProjectRequested: false
      };
    },

    [deleteArchivedProjectAction.triggered](state) {
      return {
        ...state,
        deleteArchiveProjectRequested: true
      };
    },

    [deleteArchivedProjectAction.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload,
        deleteArchiveProjectRequested: false
      };
    },

    [deleteArchivedProjectAction.failed](state) {
      return {
        ...state,
        deleteArchiveProjectRequested: false
      };
    }
  },
  initialState
);
export default projectDetailsReducer;
