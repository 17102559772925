import { connect } from 'react-redux';
import { validationSelector } from '../../store/redux/selectors/validationSelector';
import { fetchValidationActions } from '../../store/redux/actions/fetchValidation';
import ValidationIcon from './ValidationIcon';

const mapDispatchToProps = dispatch => ({
  fetchValidation: (id, entity) =>
    dispatch(
      fetchValidationActions.request({
        id,
        entity
      })
    )
});

const mapStateToProps = (state, { id, entity }) => {
  const selector = validationSelector();
  return (state, props) => (id && selector(state, id, entity)) || {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationIcon);
