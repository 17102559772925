import { handleActions } from 'redux-actions';
import { newFilteredEnrolleeDataActions } from '../../actions/researchDashboardActions';
import { checkFilterActions } from '../../actions/filtersAndParticipantsActions';

const initialState = {};

export const rdFilteredEnrolleesReducer = handleActions(
  {
    [newFilteredEnrolleeDataActions.succeeded](state, { payload }) {
      const { sessionId, filteredEnrollees } = payload;
      return {
        ...state,
        [sessionId]: {
          filteredEnrollees
        }
      };
    },
    [checkFilterActions.triggered](state) {
      return {
        ...state,
        checkFilterInProgress: true
      };
    },
    [checkFilterActions.succeeded](state, { payload }) {
      const { sessionId } = payload;
      return {
        ...state,
        [`${sessionId}-advanced`]: {
          ...payload
        },
        checkFilterInProgress: false
      };
    },
    [checkFilterActions.failed](state) {
      return {
        ...state,
        checkFilterInProgress: false
      };
    }
  },
  initialState
);
