import React from 'react';
import intl from 'react-intl-universal';
import { Switch } from 'antd';
import { Input } from 'reactstrap';

import './ShortUrls.css';

export const ShortUrls = props => {
  const { urlOption, readOnly, setUrlProperty } = props;

  return (
    <div className="short-url-options">
      <div>
        <div>{intl.get('app.limitReuse')}</div>
        <Switch
          checked={urlOption.limitHits}
          onChange={checked => setUrlProperty('limitHits', checked)}
          disabled={readOnly}
        />
      </div>
      <div style={{ display: urlOption.limitHits ? 'flex' : 'none' }}>
        <div>{intl.get('app.hitsLimit')}</div>
        <Input
          type="number"
          value={urlOption.maxHits || 1}
          onChange={e => setUrlProperty('maxHits', e.target.value)}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};
