import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { cloneDeep, isEqual } from 'lodash';
import { FlexRow, Loader } from 'webapp-common';
import { fetchProjectActions } from '../../../store/redux/actions/fetchProject';
import { fetchAllSessionsAction } from '../../../store/redux/actions/sessionActions';
import { useProjectSelector, useSessionSelector } from '../../../customHooks/reduxHelper';
import { saveProdegeConfig } from '../../../api/prodegeApi';
import { useFetchProdegeQuestions } from '../../../customHooks/useFetchProdegeQuestions';
import { sessionStates } from '../../../util/sessionUtil';
import { toast } from '../../../util/toast';
import { usePrompt } from '../../../customHooks/usePrompt';
import { Icons } from '../../../components/icons/Icons';
import AddRemoveIcons from '../../../components/core/addRemoveIcons/AddRemoveIcons';
import { TargetCriteriaModal } from './TargetCriteriaModal';

import './SelfServeRecruit.css';

const reducer = (state, payload) => ({ ...state, ...payload });

const MAX_TARGETS = 4;

const getNewQuota = () => ({
  name: '',
  quota: 0,
  criteria: [
    {
      question_id: null,
      operator: 'OR',
      precodes: []
    }
  ]
});

const getNewParticipantData = () => {
  return {
    questionId: '',
    label: ''
  };
};

export const SelfServeRecruit = props => {
  const { projectId, hasProjectManage } = props;

  const dispatch = useDispatch();

  const { projectDetails: project } = useProjectSelector({ projectId });

  const { allSessions, requestInProgress } = useSessionSelector();
  const session = allSessions.content?.find(s => s.projectId === projectId);
  const readOnly = !hasProjectManage || session?.state === sessionStates.OPEN;

  const [state, setState] = useReducer(reducer, {
    quotas: project.prodegeConfig.quotas,
    participantData: project.prodegeConfig.participantData,
    questions: [],
    showQuotaEditModal: false
  });

  useEffect(() => {
    if (projectId && !session) {
      dispatch(fetchAllSessionsAction.request({ projectId }));
    }
  }, [projectId]);

  const { isFetching: fetchQuestionsInProgress } = useFetchProdegeQuestions({
    countryId: project.prodegeConfig?.countryId || 1,
    enabledOnly: true,
    onSuccess: questions => setState({ questions })
  });

  const [prodegeConfigMutation, { isLoading: saveInProgress }] = useMutation(payload => saveProdegeConfig(payload), {
    onError: error => {
      toast.error({ text: error.errorMessage });
    },
    onSuccess: updatedProject => {
      setState({
        quotas: updatedProject.prodegeConfig.quotas,
        participantData: updatedProject.prodegeConfig.participantData
      });
      dispatch(fetchProjectActions.succeeded(updatedProject));
    }
  });

  usePrompt(intl.get('app.unsavedChangesWarning'), isSaveEnabled());

  const onAddQuota = index => {
    if (state.quotas.length < MAX_TARGETS) {
      const quotas = cloneDeep(state.quotas);
      index < quotas.length - 1 ? quotas.splice(index + 1, 0, getNewQuota()) : quotas.push(getNewQuota());
      setState({ quotas });
    } else {
      toast.error({ text: intl.get('app.targetRule.limitReached') });
    }
  };

  const onQuotaChange = (value, index, key) => {
    const quotas = cloneDeep(state.quotas);
    quotas[index][key] = value;
    setState({ quotas });
  };

  const onQuotaEdit = index => setState({ showQuotaEditModal: !state.showQuotaEditModal, targetIndex: index });

  const onSaveRuleChanges = rule => {
    const quotas = cloneDeep(state.quotas);
    quotas[state.targetIndex] = rule;
    setState({ quotas, showQuotaEditModal: !state.showQuotaEditModal });
  };

  const onRemoveQuota = index => {
    const quotas = cloneDeep(state.quotas);
    const quota = quotas[index];
    if (quota.prodegeId) {
      // Existing quota
      quota.delete = true;
    } else {
      // Newly-added quota. Just remove it.
      quotas.splice(index, 1);
    }
    setState({ quotas });
  };

  const onAddDataRow = index => {
    const participantData = cloneDeep(state.participantData);
    participantData.splice(index + 1, 0, getNewParticipantData());
    setState({ participantData });
  };

  const onDataLabelChange = (e, index) => {
    const participantData = cloneDeep(state.participantData);
    participantData[index].label = e.target.value;
    setState({ participantData });
  };

  const onQuestionSelect = (e, index) => {
    const question = state.questions.find(q => q.question_id === e.target.value);
    const participantData = cloneDeep(state.participantData);
    participantData[index].questionId = e.target.value;
    if (!participantData[index].label.trim()) {
      participantData[index].label = question.question_name;
    }
    setState({ participantData });
  };

  const onRemoveDataRow = index => {
    const participantData = cloneDeep(state.participantData);
    participantData.splice(index, 1);
    setState({ participantData });
  };

  function isValidParticipantData(data) {
    return !!(data.label.trim() && data.questionId);
  }

  function getValidParticipantData() {
    return state.participantData.filter(data => isValidParticipantData(data));
  }

  // disallow save if data not filled out
  function hasEmptyData() {
    for (const quota of state.quotas) {
      if (!quota.name.trim() || isNaN(quota.quota) || quota.quota < 1) {
        return true;
      }
      for (const criteria of quota.criteria) {
        if (!criteria.question_id || criteria.precodes.length === 0) {
          return true;
        }
      }
    }
    for (const data of state.participantData) {
      if (!data.label.trim() || !data.questionId) {
        return true;
      }
    }
    return false;
  }

  function isSaveEnabled() {
    return (
      !hasEmptyData() &&
      (!isEqual(project.prodegeConfig?.quotas, state.quotas) ||
        !isEqual(project.prodegeConfig?.participantData, state.participantData))
    );
  }

  function save() {
    const clonedProject = cloneDeep(project);
    clonedProject.prodegeConfig.quotas = state.quotas;
    clonedProject.prodegeConfig.participantData = getValidParticipantData();
    prodegeConfigMutation(clonedProject);
  }

  function getQuotaQuestionIds() {
    let ids = [];
    state.quotas.forEach(quota => {
      quota.criteria.forEach(criteria => ids.push(criteria.question_id));
    });
    return ids;
  }

  const getPDQuestions = i => {
    const questions = [];
    state.questions.forEach(q => {
      const alreadySelectedPDIndex = state.participantData.findIndex(d => d.questionId === q.question_id);
      const quotaIds = getQuotaQuestionIds();
      const alreadySelectedQQIndex = quotaIds.findIndex(qId => qId === q.question_id);
      if ((alreadySelectedPDIndex === -1 || alreadySelectedPDIndex === i) && alreadySelectedQQIndex === -1) {
        questions.push(
          <option key={q.question_id} value={q.question_id}>
            {q.question_text}
          </option>
        );
      }
    });
    return questions;
  };

  const getQuotas = () => {
    const quotas = [];

    state.quotas.forEach((q, i) => {
      if (!q.delete) {
        quotas.push(
          <div className="target-rule">
            <div className="target">
              <input
                className="edit-target"
                value={q.name || ''}
                disabled={readOnly}
                onChange={e => onQuotaChange(e.target.value, i, 'name')}
              />
              <Icons.PencilIcon className="pointer" onClick={() => onQuotaEdit(i)} />
            </div>
            <Input
              className="edit-number"
              type="number"
              value={q.quota}
              disabled={readOnly}
              onChange={e => onQuotaChange(parseInt(e.target.value, 10), i, 'quota')}
            />
            <AddRemoveIcons
              addDisabled={readOnly}
              removeDisabled={readOnly}
              onAdd={() => onAddQuota(i)}
              onRemove={() => onRemoveQuota(i)}
            />
          </div>
        );
      }
    });

    if (quotas.length === 0) {
      return (
        <div className="target-rule">
          <div style={{ display: 'flex' }}>
            <AddRemoveIcons addDisabled={readOnly} onAdd={() => onAddQuota(0)} />
            {intl.get('app.addQuota')}
          </div>
        </div>
      );
    }

    return quotas;
  };

  const getDataRows = () => {
    return (
      <div className="additional-data">
        <FlexRow style={{ marginLeft: '0.5rem', marginTop: '1rem', fontSize: '0.9rem' }}>
          <div className="label">
            <strong>{intl.get('app.label')}</strong>
          </div>
          <div>
            <strong>{intl.get('app.question')}</strong>
          </div>
          <div />
        </FlexRow>
        {state.participantData.length === 0 ? (
          <div style={{ display: 'flex' }}>
            <AddRemoveIcons className="add-remove" addDisabled={readOnly} onAdd={() => onAddDataRow(0)} />
            {intl.get('app.addParticipantData')}
          </div>
        ) : (
          state.participantData.map((d, i) => (
            <FlexRow alignItems="baseline" columnGap="1rem" key={i}>
              <Input
                className="label"
                value={d.label}
                placeholder={intl.get('app.questionName')}
                disabled={readOnly}
                onChange={e => onDataLabelChange(e, i)}
              />
              <Input
                type="select"
                className="question"
                value={d.questionId}
                disabled={readOnly}
                onChange={e => onQuestionSelect(e, i)}
              >
                <option value="" disabled selected>
                  --- {intl.get('app.pickAQuestion')} ---
                </option>
                {getPDQuestions(i)}
              </Input>
              <AddRemoveIcons
                className="add-remove"
                addDisabled={readOnly || !isValidParticipantData(d)}
                removeDisabled={readOnly}
                onAdd={() => onAddDataRow(i)}
                onRemove={() => onRemoveDataRow(i)}
              />
            </FlexRow>
          ))
        )}
      </div>
    );
  };

  return (
    <section className="body-container l2-bg self-serve-recruit">
      {(fetchQuestionsInProgress || requestInProgress || saveInProgress) && (
        <Loader fullScreen spinner lightbox={saveInProgress} />
      )}
      <div className="target-audience-section">
        <p>
          <strong>{intl.get('app.targetAudience')}</strong>
        </p>
        <div>{intl.get('app.targetAudience.message1')}</div>
        <div>{intl.get('app.targetAudience.message2')}</div>
        {getQuotas()}
      </div>
      <div className="additional-participant-section">
        <p>
          <strong>{intl.get('app.additionalParticipantData')}</strong>
        </p>
        <div>{intl.get('app.additionalParticipant.message1')}</div>
        <div>{intl.get('app.additionalParticipant.message2')}</div>
        {getDataRows()}
      </div>
      <Button className="save-button" color="primary" disabled={!isSaveEnabled()} onClick={save}>
        {intl.get('app.save')}
      </Button>
      {state.showQuotaEditModal && (
        <TargetCriteriaModal
          toggle={onQuotaEdit}
          questions={state.questions}
          targetRule={state.quotas[state.targetIndex]}
          readOnly={readOnly}
          save={onSaveRuleChanges}
          saveEnabled
        />
      )}
    </section>
  );
};
