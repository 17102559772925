import { connect } from 'react-redux';
import {
  fetchSessionAction,
  fetchSessionAndSurveyAction,
  fetchSaveSessionAction,
  cloneSessionAction
} from '../../../store/redux/actions/sessionActions';
import {
  fetchSurveyActions,
  saveSurveyActions,
  deleteSurveyActions,
  deleteQuestionJoinersActions
} from '../../../store/redux/actions/surveyActions';
import { getSurveyVideosIngestStatusAction } from '../../../store/redux/actions/watermarkActions';
import { sessionSelector } from '../../../store/redux/selectors/sessionSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { ingestStatusSelector } from '../../../store/redux/selectors/watermarkSelector';

import { ProjectSurvey } from './ProjectSurvey';

const mapDispatchToProps = dispatch => ({
  fetchSession: sessionId => dispatch(fetchSessionAction.request({ sessionId })),
  fetchSessionAndSurvey: sessionId => dispatch(fetchSessionAndSurveyAction.request({ sessionId })),
  fetchSurvey: surveyId => dispatch(fetchSurveyActions.request({ surveyId })),
  saveSession: payload => dispatch(fetchSaveSessionAction.request(payload)),
  cloneSession: sessionId => dispatch(cloneSessionAction.request(sessionId)),
  saveSurvey: payload => dispatch(saveSurveyActions.request(payload)),
  deleteSurvey: params => dispatch(deleteSurveyActions.request(params)),
  deleteSurveyQuestions: params => dispatch(deleteQuestionJoinersActions.request(params)),
  getSurveyVideosIngestStatus: surveyId => dispatch(getSurveyVideosIngestStatusAction.request(surveyId))
});

const mapStateToProps = (state, props) => {
  const { tab, subtab } = props.routeParams;
  const isTemplate = tab === 'surveyTemplate' || tab === 'screenerTemplate';
  const activeSessionId = !isTemplate && subtab;
  const surveyTemplateId = isTemplate && subtab;

  const sessionsSelector = sessionSelector()(state, activeSessionId);
  const { session, requestInProgress, importRequestInProgress } = sessionsSelector;
  const { hasProjectManage, hasProjectView } = sessionUserSelector(state).abilities;
  const { rdSubscribeInProgress } = state.rdSubscribe;
  const { [activeSessionId]: rdConfigStore = {}, rdConfigsApiRequested, rdConfigSaveInProgress } = state.rdConfig;
  const { rdConfig = {} } = rdConfigStore;
  const { ingestStatus } = ingestStatusSelector(state) ?? {};

  return {
    activeSessionId,
    activeSession: session,
    surveyTemplateId,
    fetchSessionsInProgress: requestInProgress,
    importRequestInProgress,
    surveyStore: state.surveys,
    hasProjectManage,
    hasProjectView,
    isTemplate,
    rdSubscribeInProgress,
    rdConfig,
    rdConfigsApiRequested,
    rdConfigSaveInProgress,
    ingestStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSurvey);
