import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { Input } from 'reactstrap';
import { Select } from 'antd';
import { SurveyLanguage } from '../../../survey/settings/language/SurveyLanguage';
import { ENGLISH } from '../../../../../util/joinerUtil';
import { fetchSurvey } from '../../../../../api/surveysApi';

import './ReportCommonFields.css';

const Option = Select.Option;

/*
 * Common Reports component for name, description, and sessions fields.
 */
export const ReportCommonFields = props => {
  const { project, sessions, reportType, state, setState, setLanguageForReport } = props;

  const { projectDetails } = project || {};

  const [sessionIds, setSessionIds] = useState(state.reportConfig.sessionIds);

  const [surveys, setSurveys] = useState([]);

  const [reportLanguage, setReportLanguage] = useState(ENGLISH);

  const [sessionSelectorOpen, setSessionSelectorOpen] = useState(false);

  const [queryParams, setQueryParams] = useState({ surveyId: '', sessionId: '' });

  const surveyQuery = useQuery(['survey', queryParams], () => fetchSurvey(queryParams), {
    enabled: validQueryParams(),
    onSuccess: survey => {
      updateStateSurveys(survey);
    }
  });

  useEffect(() => {
    if (projectDetails && projectDetails.name && !state.reportName) {
      const timeStamp = moment().format('MM-DD-YYYY_HH-mm-ss');
      setState({
        reportName: `${projectDetails.name} - ${reportType} - ${timeStamp}`
      });
    }
  }, [project]);

  // Update local sessionIds if parent state changes.
  useEffect(() => {
    if (!isEqual(sessionIds, state.reportConfig.sessionIds)) {
      setSessionIds(state.reportConfig.sessionIds);
    }
  }, [state.reportConfig.sessionIds]);

  // If only one session, auto-select it.
  useEffect(() => {
    if (sessions.length === 1 && state.reportConfig.sessionIds.length === 0) {
      const sessionId = sessions[0].id;
      setState({
        reportConfig: {
          ...state.reportConfig,
          sessionIds: [sessionId]
        }
      });
    }
  }, [sessions]);

  // Update the selected sessions either when the session selector closes or a session is removed.
  useEffect(() => {
    if (!sessionSelectorOpen) {
      setState({
        reportConfig: {
          ...state.reportConfig,
          sessionIds
        }
      });
      sessionIds.forEach(sessionId => getSurveys());
    }
  }, [sessionSelectorOpen, sessionIds]);

  useEffect(() => {
    if (validQueryParams()) {
      surveyQuery.refetch();
    }
  }, [queryParams]);

  function validQueryParams() {
    return !!(queryParams.surveyId && queryParams.sessionId);
  }

  function updateStateSurveys(survey) {
    if (!survey) {
      return;
    }

    const stateSurveys = cloneDeep(surveys);
    if (stateSurveys) {
      if (!stateSurveys.includes(survey)) {
        stateSurveys.push(survey);
      }
      setSurveys(stateSurveys);
    }
  }

  function getSurveys() {
    if (!sessions) {
      return;
    }
    sessionIds.forEach(sessionId => {
      const session = sessions.find(s => s.id === sessionId);
      setQueryParams({ surveyId: session.surveyId, sessionId: sessionId });
    });
  }

  function updateReportName(e) {
    setState({
      reportName: e.target.value
    });
  }

  function updateDescription(e) {
    setState({
      reportDescription: e.target.value
    });
  }

  function updateSessions(ids) {
    const sessionIdList = ids.filter(sessionId => sessions.some(session => session.id === sessionId));
    setSessionIds(sessionIdList);
    // Remove any surveys whose id's do not match the selected sessions (e.g. have been deleted/deselected)
    const selectedSessions = sessions.filter(session => sessionIdList.includes(session.id));
    const selectedSessionsSurveyIds = selectedSessions.map(ss => ss.surveyId);
    const validSurveys = surveys.filter(survey => selectedSessionsSurveyIds.includes(survey.id));
    setSurveys(validSurveys);
  }

  function getSessions() {
    return sessions.map(session => <Option key={session.id}>{session.name}</Option>);
  }

  function updateReportLanguage(value) {
    setReportLanguage(value);
    setLanguageForReport(value);
  }

  // Every session selected must have the same alt language configured for that language to be considered
  // the alt language of the chosen set of sessions (and appear in drop-down).
  // Only it and English will appear in the SurveyLanguage drop-down below; if no alt language, only English is shown.
  function getAltLanguage() {
    if (
      surveys.length === 0 ||
      surveys.find(survey => !survey.surveyOptions || survey.surveyOptions.languageOptions.language === ENGLISH)
    ) {
      return ENGLISH; // If any survey is set to English, only English may be selected
    }
    // All surveys for selected sessions must have same alt language set:
    const uniqueLanguages = [...new Set(surveys.map(survey => survey.surveyOptions.languageOptions.language))];
    if (uniqueLanguages.length > 1) {
      return ENGLISH;
    }
    return uniqueLanguages[0];
  }

  return (
    <div className="report-common-fields">
      <div className="flex-row">
        <div>{intl.get('app.reportName')}:</div>
        <div>
          <Input value={state.reportName} onChange={updateReportName} />
        </div>
      </div>
      <div className="flex-row">
        <div>{intl.get('app.description')}:</div>
        <div>
          <Input value={state.reportDescription} onChange={updateDescription} />
        </div>
      </div>
      <div className="flex-row">
        <div>{intl.get('app.survey(s)')}:</div>
        <div>
          <Select
            mode="tags"
            style={{ width: '100%' }}
            size="large"
            value={sessionIds}
            onChange={updateSessions}
            disabled={sessions.length === 1}
            onDropdownVisibleChange={setSessionSelectorOpen}
          >
            {getSessions()}
          </Select>
        </div>
      </div>
      <div className="flex-row">
        <div>{intl.get('app.language(s)')}:</div>
        <SurveyLanguage
          survey={state.survey}
          setLanguageOption={updateReportLanguage}
          selectedLanguage={reportLanguage}
          altLanguage={getAltLanguage()}
        />
      </div>
    </div>
  );
};
