import React from 'react';
import { Input } from 'reactstrap';
import { cloneDeep } from 'lodash';

export const ReportFilterList = props => {
  const { selectedFilters, sessions, consolidatedReportData, videoCaptureEnabled, warning } = props;
  const { filters } = consolidatedReportData;
  function setFilters(e, filter) {
    const cloneFilters = cloneDeep(selectedFilters);
    if (e.target.checked) {
      cloneFilters.push(filter);
    } else {
      const i = cloneFilters.findIndex(
        f => f.type === filter.type && f.name === filter.name && f.expression === filter.expression
      );
      cloneFilters.splice(i, 1);
    }
    props.setFilters(cloneFilters);
  }

  function getFilterGroupName(group) {
    const session = sessions.find(s => s.id === group);
    return session?.name || group;
  }

  function isFilterChecked(filter) {
    if (!filter) {
      return false;
    }
    return (
      selectedFilters &&
      selectedFilters.some(f => f.type === filter.type && f.name === filter.name && f.expression === filter.expression)
    );
  }

  return (
    <div>
      {Object.keys(filters)
        .filter(group => filters[group]?.length > 0)
        .map(group => (
          <div>
            <label style={{ display: 'block', color: 'gray' }}>{getFilterGroupName(group)}</label>
            {filters[group]
              .filter(filter => filter.type !== 'DEFAULT_FILTER' || videoCaptureEnabled)
              .map(filter => (
                <label
                  style={{
                    display: 'block',
                    marginLeft: '1rem',
                    paddingRight: '5rem',
                    color: warning ? 'red' : 'inherit'
                  }}
                >
                  <Input type="checkbox" checked={isFilterChecked(filter)} onChange={e => setFilters(e, filter)} />
                  {filter.name}
                </label>
              ))}
          </div>
        ))}
    </div>
  );
};
