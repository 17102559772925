import React from 'react';
import { InvokeModal } from 'webapp-common';
import { useSessionUserSelector } from '../../customHooks/reduxHelper';
import Iframe from '../core/iframe/Iframe';

import './JoinerPreview.css';

const JoinerPreviewModal = ({ toggle, sessionId, joinerId, locale = 'en-US' }) => {
  const userId = useSessionUserSelector().sessionUser.userID;
  const previewUrl = sessionId
    ? `/survey/question-preview/${userId}/${joinerId}/${sessionId}?locale=${locale}`
    : `/survey/question-preview/${userId}/${joinerId}?locale=${locale}`;
  return (
    <InvokeModal showModal toggle={toggle} modalTitle className="joiner-preview-modal">
      <Iframe src={previewUrl} />
    </InvokeModal>
  );
};

export default JoinerPreviewModal;
