import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash';
import {
  sessionQuotaFiltersActions,
  updateQuotaFilterActions,
  updateQuotaFilterResponseActions
} from '../../actions/researchDashboardActions';
import { idUtils } from '../../../../util/Id';

const initialState = {};

export const rdSessionQuotaFiltersReducer = handleActions(
  {
    [sessionQuotaFiltersActions.succeeded](state, { payload }) {
      const { sessionId, quotaFilters } = payload;
      return {
        ...state,
        [sessionId]: {
          quotaFilters
        }
      };
    },

    [updateQuotaFilterActions.succeeded](state, { payload }) {
      const { action, filter } = payload;
      const { sessionId } = filter;
      const { quotaFilters = [] } = cloneDeep(state[sessionId]) || {};
      const index = quotaFilters.findIndex(f => f.id === filter.id);
      if (action === 'Add') {
        quotaFilters.push(filter);
      } else if (action === 'Delete') {
        quotaFilters.splice(index, 1);
      } else {
        // Update
        quotaFilters[index] = filter;
      }
      return {
        ...state,
        [sessionId]: {
          quotaFilters
        },
        updateResult: null
      };
    },

    [updateQuotaFilterResponseActions.succeeded](state, { payload }) {
      return {
        ...state,
        updateResponse: {
          id: idUtils.getId(), // Unique ID needed for useEffect() to fire
          status: payload
        }
      };
    },

    [updateQuotaFilterResponseActions.failed](state, { error }) {
      return {
        ...state,
        updateResponse: { error }
      };
    }
  },
  initialState
);
