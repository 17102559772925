import { takeEvery, put } from 'redux-saga/effects';
import { fetchValidationActions } from '../actions/fetchValidation';
import { fetchRequestedSaga } from './createFetchSaga';
import { validateEntity } from '../../../api/validateApi';

function* requestValidation({ id, entity }) {
  if (id && entity) {
    yield put(fetchValidationActions.request({ id, entity }));
  }
}

function createValidationSaga() {
  function* fetchSaga() {
    /* listen on every fetchValidationActions request and execute fetchRequestedSaga dynamically with api and action */
    yield takeEvery(fetchValidationActions.request, fetchRequestedSaga, validateEntity, fetchValidationActions);
  }
  return fetchSaga;
}

function* validationSaga() {
  // create a saga for validation
  const fetchValidationSaga = createValidationSaga();
  yield* fetchValidationSaga();
}

export default validationSaga;
export { requestValidation };
