import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Row, Input } from 'reactstrap';
import ParticipantList from '../../../../containers/participantList';
import moment from 'moment';
import { subscribeScreenerSummaryActions } from '../../../../store/redux/actions/participantDataActions';
import { ScreenerSummary } from '../../../../containers/sessionDetails/screenerSummary/ScreenerSummary';

import './ScreenerParticipants.css';

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const ScreenerParticipants = props => {
  const { sessionId, survey, surveyId, screenerSummary, segmentCategories, sessionName } = props;
  const [state, setState] = useReducer(reducer, {
    includeDisqualified: false
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyId !== survey.id) {
      props.fetchSurvey(surveyId);
    }
  }, [surveyId]);

  useEffect(() => {
    if (sessionId) {
      dispatch(
        subscribeScreenerSummaryActions.request({
          subAction: 'subscribe',
          sessionId
        })
      );

      return () => {
        dispatch(
          subscribeScreenerSummaryActions.request({
            subAction: 'unsubscribe',
            sessionId
          })
        );
      };
    }
  }, [sessionId]);

  useEffect(() => {
    const { lastUpdated } = screenerSummary || {};
    if (lastUpdated !== state.screenerSummaryLastUpdated) {
      setState({ screenerSummaryLastUpdated: lastUpdated });
    }
  }, [screenerSummary]);

  const toggleDisqualified = e => {
    setState({
      includeDisqualified: e.target.checked
    });
  };

  const { includeDisqualified, screenerSummaryLastUpdated } = state;
  const { lastUpdated } = screenerSummary || {};
  const timeStamp = moment().format('MM-DD-YYYY_HH-mm-ss');
  const isNewSummaryData = !!lastUpdated && !!screenerSummaryLastUpdated && lastUpdated !== screenerSummaryLastUpdated;

  return (
    <Row className="screener-participants mt-3">
      <ScreenerSummary screenerSummary={screenerSummary} segmentCategories={segmentCategories} showSubTotals={true} />
      <div className="right-panel px-5">
        <div>
          <a
            href={`/a/api/exportScreenerParticipantsCSV/${sessionId}/${!includeDisqualified}`}
            download={`${sessionName}_${timeStamp}.csv`}
            className="btn btn-secondary"
          >
            {intl.get('app.export')}
          </a>
          {props.isInternalSuperUser && (
            <a
              href={`/a/api/exportScreenerParticipantsCSVWithEmail/${sessionId}/${!includeDisqualified}`}
              download={`${sessionName}_${timeStamp}.csv`}
              className="btn btn-secondary ms-3"
            >
              {intl.get('app.exportWithEmail')}
            </a>
          )}
        </div>
        <div>
          <label className="mt-3">
            <Input type="checkbox" checked={includeDisqualified} onChange={toggleDisqualified} />
            {intl.get('app.includeDisqualifiedParticipants')}
          </label>
        </div>
        <div className="mt-1 inline-block participant-list-container">
          <ParticipantList
            entityId={props.metaDataCollectionId}
            showParticipantURLs={false}
            enablePagination
            includeDisqualified={includeDisqualified}
            enableViewParticipant
            sessionId={sessionId}
            fromScreener
            reFetch={isNewSummaryData}
            questionJoiners={props.joiners}
            segmentCategories={segmentCategories}
            enableSearch
            setViewLanguage={props.setViewLanguage}
            language={props.language}
            viewLanguage={props.viewLanguage}
          />
        </div>
      </div>
    </Row>
  );
};
