const LICENCE_CUSTOM_REPORTS = 'CUSTOM_REPORTS';
const VIDEO_DIAL_TESTING = 'VIDEO_DIAL_TESTING';

const getCurrentClient = sessionUser => {
  return sessionUser?.clients?.find(c => c.clientID === sessionUser.clientId);
};

const isLicenseAvailable = (sessionUser, licenseName) => {
  const client = getCurrentClient(sessionUser);
  return !!client?.licenses?.some(l => l.licenseName === licenseName && l.isActive);
};

const isVideoCaptureEnabled = sessionUser => {
  // return isLicenseAvailable(sessionUser, 'VIDEO_CAPTURE');
  return false;
};

const isCustomReportsEnabled = sessionUser => {
  return isLicenseAvailable(sessionUser, LICENCE_CUSTOM_REPORTS);
};

const isVideoDialEnabled = sessionUser => {
  return isLicenseAvailable(sessionUser, VIDEO_DIAL_TESTING);
};

export { isVideoCaptureEnabled, getCurrentClient, isCustomReportsEnabled, isVideoDialEnabled };
