import React from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Row, Label } from 'reactstrap';
import { DISPLAY_LAYOUT, STIM_TYPE, getStimCaption, getStimAltTag } from '../../../../../util/joinerUtil';
import { InvokeModal } from 'webapp-common';
import SelectMediaModal from '../../modals/selectMedia/SelectMediaModal';
import MediaPreview from '../../../../../components/core/mediaPreview/MediaPreview';

import './AttachMedia.css';

class AttachMedia extends React.Component {
  state = {
    showSelectMediaModal: false,
    restartMedia: !!this.props.joiner.restartMedia,
    showRemoveMediaModal: false
  };

  componentDidMount() {
    if (!this.props.joiner.displayLayout) {
      this.props.updateHandlers.updateDisplayLayout(DISPLAY_LAYOUT.vertical);
    }
  }

  toggleSelectMediaModal = () => {
    if (!this.props.readOnly) {
      this.setState({
        showSelectMediaModal: !this.state.showSelectMediaModal
      });
    }
  };

  toggleRemoveMediaModal = () => {
    this.setState({
      showRemoveMediaModal: !this.state.showRemoveMediaModal
    });
  };

  onMediaUpdate = media => {
    this.props.updateHandlers.updateStimMedia(media);
    this.toggleSelectMediaModal();
  };

  onRestartMediaUpdate = e => {
    const val = e.target.value === 'true';
    this.props.updateHandlers.onRestartMediaUpdate(val);
    this.setState({ restartMedia: val });
  };

  removeMedia = () => {
    this.props.updateHandlers.removeStim();
    this.toggleRemoveMediaModal();
  };

  render() {
    const { joiner, updateHandlers, readOnly, language, viewLanguage } = this.props;
    const { updateDisplayLayout, updateStimCaption, updateStimAltTag } = updateHandlers;
    const { displayLayout, stim } = joiner;
    const media = (stim && stim.media) || {};
    const caption = getStimCaption(stim, language, viewLanguage);
    const altTag = getStimAltTag(stim, language, viewLanguage);
    const controlsDisabled = readOnly || !stim;
    const stimType = stim && stim.type;

    return (
      <div className={`${this.props.tabs.media} joiner-editor-media`}>
        <div className="left">
          <Row>
            <div className="label">{intl.get('app.media')}:</div>
            <MediaPreview
              mediaType={media.type}
              media={media}
              text={intl.get('app.clickToAddOrEdit')}
              onClick={this.toggleSelectMediaModal}
              readOnly={readOnly}
            />
          </Row>
          <Row>
            <div className="label">{intl.get('app.layout')}:</div>
            <div>
              <label disabled={controlsDisabled}>
                <Input
                  type="radio"
                  checked={displayLayout === DISPLAY_LAYOUT.vertical}
                  onChange={() => updateDisplayLayout(DISPLAY_LAYOUT.vertical)}
                />{' '}
                {intl.get('app.media.above')}
              </label>
              <label disabled={controlsDisabled}>
                <Input
                  type="radio"
                  checked={displayLayout === DISPLAY_LAYOUT.horizontal}
                  onChange={() => updateDisplayLayout(DISPLAY_LAYOUT.horizontal)}
                />{' '}
                {intl.get('app.media.left')}
              </label>
            </div>
          </Row>
          <Button color="outline-danger" disabled={controlsDisabled} onClick={this.toggleRemoveMediaModal}>
            {intl.get('app.removeMedia')}
          </Button>
        </div>
        <div className="right">
          <Row>
            <div className="label">{intl.get('app.caption')}:</div>
            <Input
              value={caption}
              disabled={controlsDisabled}
              onChange={e => updateStimCaption(e.target.value, language, viewLanguage)}
            />
          </Row>
          {stimType !== STIM_TYPE.webcontent && stimType !== STIM_TYPE.video && (
            <Row>
              <div className="label">{intl.get('app.altText')}:</div>
              <Input
                value={altTag}
                disabled={controlsDisabled}
                onChange={e => updateStimAltTag(e.target.value, language, viewLanguage)}
              />
            </Row>
          )}
          {(stimType === STIM_TYPE.webcontent || stimType === STIM_TYPE.video) && (
            <div>
              <Row className="mb-1">
                <div className="label">
                  {stimType === STIM_TYPE.webcontent ? intl.get('app.stim.webcontent') : intl.get('app.stim.video')}:
                </div>
                <Label
                  className={
                    stimType === STIM_TYPE.webcontent
                      ? 'editor-right-column-web-radio1'
                      : 'editor-right-column-video-radio1'
                  }
                >
                  <Input
                    checked={!this.state.restartMedia}
                    type="radio"
                    onChange={this.onRestartMediaUpdate}
                    value={false}
                  />
                  {intl.get('app.media.continue')}
                </Label>
              </Row>
              <Row>
                <Label
                  className={
                    stimType === STIM_TYPE.webcontent
                      ? 'editor-right-column-web-radio2'
                      : 'editor-right-column-video-radio2'
                  }
                >
                  <Input
                    checked={this.state.restartMedia}
                    type="radio"
                    onChange={this.onRestartMediaUpdate}
                    value={true}
                  />
                  {intl.get('app.media.restart')}
                </Label>
              </Row>
            </div>
          )}
        </div>
        {this.state.showSelectMediaModal && (
          <SelectMediaModal toggle={this.toggleSelectMediaModal} onMediaUpdate={this.onMediaUpdate} />
        )}
        {this.state.showRemoveMediaModal && (
          <InvokeModal
            showModal
            toggle={this.toggleRemoveMediaModal}
            modalTitle={intl.get('app.removeMedia')}
            primaryButtonText={intl.get('app.ok')}
            cancelButtonText={intl.get('app.cancel')}
            save={this.removeMedia}
            enableSave={true}
          >
            {intl.get('app.removeMedia.confirm')}
          </InvokeModal>
        )}
      </div>
    );
  }
}

export default AttachMedia;
