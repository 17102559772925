import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import chatUtil from './chatUtil';
import { ChatWindow } from './ChatWindow';
import { useSessionUserSelector } from '../../customHooks/reduxHelper';
import { getRDChats, getRDEnrollees } from '../../store/redux/selectors/researchDashboardSelector';

export const Chat = props => {
  const { sessionId } = props;

  const chats = useSelector(state => getRDChats(state, sessionId), shallowEqual);

  const enrolleesInfo = useSelector(state => getRDEnrollees(state, sessionId), shallowEqual);
  const { enrolleeMap } = enrolleesInfo;

  const userId = useSessionUserSelector().sessionUser.userID;

  /*
   * If all chat participants have either been terminated or have completed the survey, the chat is ended.
   */
  function isChatEnded(chat) {
    if (!enrolleeMap || !Object.keys(enrolleeMap).length) {
      return false;
    }
    const activeEnrollees = chat.participantIds.some(participantId => {
      const enrollee = enrolleeMap[participantId];
      return chatUtil.isActiveParticipant(enrollee);
    });
    return !activeEnrollees;
  }

  function getChatWindows() {
    const chatWindows = [];
    let counter = 0;

    chats &&
      chats.forEach(chat => {
        // Chats can come from the server in ACTIVE state, but they should really be ENDED based on the state of the participants.
        if (chatUtil.isActive(chat) && isChatEnded(chat)) {
          chat.state = chatUtil.chatState.ended;
        }

        if (chat.windowState && !chatUtil.isClosed(chat.windowState)) {
          // This is a hack to tile the chat windows. We should find a better way to do it.
          let right = counter * 20 + 'rem';
          const key = chat.id ? chat.id : chat.initiatorId + chat.participantIds.join('') + chat.questionJoinerId;
          chatWindows.push(
            <ChatWindow key={key} userId={userId} chat={chat} right={right} viewLanguage={props.viewLanguage} />
          );
          counter++;
        }
      });

    return chatWindows;
  }

  return <div>{getChatWindows()}</div>;
};
