import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { RULE_CONDITION_TYPE, SURVEY_RULE_TYPE } from '../../../../../util/joinerUtil';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';
import { CopyIcon } from '../../../../../components/icons/Icons';
import QuestionCondition from './questionCondition/QuestionCondition';
import LookupValueCondition from './lookupValueCondition';
import CustomFunctionCondition from './functionCondition';
import ParticipantMetadataCondition from './participantMetadataCondition/ParticipantMetadataCondition';
import ParticipantFlagCondition from './participantFlagCondition/ParticipantFlagCondition';

const SurveyRuleConditions = props => {
  const { sessionId, joiner: activeJoiner, rule, ruleIndex, joiners, updateHandlers, readOnly, isScreener } = props;

  return (
    <div className="conditions">
      {rule.conditions.map((condition, index) => (
        <div className="condition-row" key={index}>
          <div className="condition">
            <div>
              <div>{intl.get('app.criteriaType')}</div>
              <Input
                type="select"
                value={condition.type}
                disabled={readOnly}
                onChange={e => updateHandlers.setRuleConditionType(ruleIndex, index, e.target.value)}
              >
                <option />
                <option value={RULE_CONDITION_TYPE.questionCondition}>{intl.get('app.questionAnswer')}</option>
                {isScreener && (
                  <option value={RULE_CONDITION_TYPE.lookupValueCondition}>{intl.get('app.lookupValue')}</option>
                )}
                {isScreener && (
                  <option value={RULE_CONDITION_TYPE.customFunctionCondition}>{intl.get('app.customFunction')}</option>
                )}
                <option value={RULE_CONDITION_TYPE.participantMetadataCondition}>
                  {intl.get('app.participantMetadata')}
                </option>
                {rule.type !== SURVEY_RULE_TYPE.setFlagRule && (
                  <option value={RULE_CONDITION_TYPE.participantFlagCondition}>
                    {intl.get('app.participantFlag')}
                  </option>
                )}
              </Input>
            </div>
            {condition.type === RULE_CONDITION_TYPE.questionCondition && (
              <QuestionCondition
                activeJoiner={activeJoiner}
                rule={rule}
                ruleIndex={ruleIndex}
                condition={condition}
                conditionIndex={index}
                joiners={joiners}
                updateHandlers={updateHandlers}
                readOnly={readOnly}
              />
            )}
            {condition.type === RULE_CONDITION_TYPE.lookupValueCondition && (
              <LookupValueCondition
                activeJoiner={activeJoiner}
                rule={rule}
                ruleIndex={ruleIndex}
                condition={condition}
                conditionIndex={index}
                joiners={joiners}
                updateHandlers={updateHandlers}
                readOnly={readOnly}
              />
            )}
            {condition.type === RULE_CONDITION_TYPE.customFunctionCondition && (
              <CustomFunctionCondition
                activeJoiner={activeJoiner}
                rule={rule}
                ruleIndex={ruleIndex}
                condition={condition}
                conditionIndex={index}
                joiners={joiners}
                updateHandlers={updateHandlers}
                readOnly={readOnly}
              />
            )}
            {condition.type === RULE_CONDITION_TYPE.participantMetadataCondition && (
              <ParticipantMetadataCondition
                sessionId={sessionId}
                ruleIndex={ruleIndex}
                condition={condition}
                conditionIndex={index}
                updateHandlers={updateHandlers}
                readOnly={readOnly}
              />
            )}
            {condition.type === RULE_CONDITION_TYPE.participantFlagCondition && (
              <ParticipantFlagCondition
                ruleIndex={ruleIndex}
                condition={condition}
                conditionIndex={index}
                updateHandlers={updateHandlers}
                readOnly={readOnly}
              />
            )}
          </div>
          {!readOnly && (
            <div>
              <AddRemoveIcons
                onAdd={() => updateHandlers.addRuleCondition(ruleIndex, index + 1)}
                onRemove={
                  rule.conditions.length === 1 ? null : () => updateHandlers.removeRuleCondition(ruleIndex, index)
                }
              />
              <CopyIcon
                className="clickable me-4"
                title={intl.get('app.survey.copyCondition')}
                onClick={() => updateHandlers.addRuleCondition(ruleIndex, index + 1, rule.conditions[index])}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SurveyRuleConditions;
