import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import update from 'immutability-helper';
import { cloneDeep, isEqual } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { InvokeModal } from 'webapp-common';

import './ConfigureDataColumns.css';

const HIDDEN = 'hidden';
const VISIBLE = 'visible';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

export const ConfigureDataColumns = props => {
  const {
    showModal,
    toggle,
    rdConfig,
    columnConfig = {},
    defaultColumns = [],
    renamedColumns = {},
    columnOrder = [],
    keyTransformMap = {},
    sessionId,
    targetConfig = 'verbatimDetailsColumnConfig',
    table
  } = props;

  const [state, setState] = useReducer(reducer, {
    hidden: getHiddenColumns(Object.keys(columnConfig.hiddenColumns || {})),
    visible: columnConfig.columnPositions || []
  });

  /*
   * Hidden columns are listed with default columns first, followed by metadata columns.
   */
  function getHiddenColumns(columns) {
    const cols1 = defaultColumns.filter(col => columns.indexOf(col) !== -1);
    const cols2 = columnOrder.filter(col => columns.indexOf(col) !== -1);
    return [...cols1, ...cols2];
  }

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: '0 0.25rem 0.25rem'
  });

  const getRowStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: '0.25rem',
    textAlign: 'left',
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle
  });

  const getList = status => {
    return status.indexOf(HIDDEN) !== -1 ? state[HIDDEN] : state[VISIBLE];
  };

  const handleColumnClick = (index, direction) => {
    const hiddenColumns = [...state[HIDDEN]];
    const visibleColumns = [...state[VISIBLE]];
    let col = '';
    if (direction === HIDDEN) {
      [col] = visibleColumns.splice(index, 1);
      hiddenColumns.push(col);
    } else {
      [col] = hiddenColumns.splice(index, 1);
      visibleColumns.push(col);
    }
    setState({
      ...state,
      hidden: getHiddenColumns(hiddenColumns),
      visible: visibleColumns
    });
  };

  const updateAnswerValue = () => {
    const hiddenColumns =
      state[HIDDEN].length > 0
        ? state[HIDDEN].reduce((result, h) => {
            result[h] = true;
            return result;
          }, {})
        : {};
    const config = table
      ? update(rdConfig, {
          configs: {
            [targetConfig]: {
              [table]: {
                columnPositions: { $set: [...state[VISIBLE]] },
                hiddenColumns: { $set: hiddenColumns },
                renamedColumns: { $set: renamedColumns }
              }
            }
          }
        })
      : update(rdConfig, {
          configs: {
            [targetConfig]: {
              columnPositions: { $set: [...state[VISIBLE]] },
              hiddenColumns: { $set: hiddenColumns }
            }
          }
        });
    props.saveRDConfig(config);
    toggle();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = cloneDeep(source);
    const destClone = cloneDeep(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    return {
      [droppableSource.droppableId]: sourceClone,
      [droppableDestination.droppableId]: destClone
    };
  };

  const onDragEnd = dropped => {
    const { source, destination } = dropped;
    let hiddenColumns = state[HIDDEN];
    let visibleColumns = state[VISIBLE];
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const cols = reorder(getList(source.droppableId), source.index, destination.index);
      if (source.droppableId.indexOf(HIDDEN) !== -1) {
        hiddenColumns = cols;
      } else {
        visibleColumns = cols;
      }
    } else {
      const result = move(getList(source.droppableId), getList(destination.droppableId), source, destination);
      visibleColumns =
        source.droppableId.indexOf(HIDDEN) !== -1 ? result[destination.droppableId] : result[source.droppableId];
      hiddenColumns =
        source.droppableId.indexOf(HIDDEN) !== -1 ? result[source.droppableId] : result[destination.droppableId];
    }
    setState({
      ...state,
      hidden: getHiddenColumns(hiddenColumns),
      visible: visibleColumns
    });
  };

  function getTransformedKey(key) {
    return keyTransformMap[key] || key;
  }

  const getDropableZone = (provided, snapshot, zone) => {
    const columns = zone === HIDDEN ? state[HIDDEN] : state[VISIBLE];
    return (
      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
        {columns.map((col, index) => {
          const columnName = getTransformedKey(col);
          return (
            <div key={columnName}>
              {zone === VISIBLE && <div className="col-number inline-block">{index + 1}.</div>}
              <div className={zone === VISIBLE ? 'column-item visible-columns' : 'column-item visible-columns wider'}>
                <Draggable draggableId={`${sessionId}-${col}-${index}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="column"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getRowStyle(snapshot.isDragging, provided.draggableProps.style)}
                      title={columnName}
                    >
                      <i className="fas fa-grip-vertical ms-1 me-3" />
                      <span className={zone === VISIBLE ? 'visible-title' : 'title'}>{columnName}</span>
                      {zone === HIDDEN ? (
                        <button className="no-style" onClick={() => handleColumnClick(index, VISIBLE)}>
                          <i className="fas fa-caret-right" />
                        </button>
                      ) : (
                        <button className="no-style" onClick={() => handleColumnClick(index, HIDDEN)}>
                          <i className="fas fa-times" />
                        </button>
                      )}
                    </div>
                  )}
                </Draggable>
              </div>
            </div>
          );
        })}
        {zone === VISIBLE && state[VISIBLE].length < state[VISIBLE].length + state[HIDDEN].length && (
          <div className="temp">
            <div className="col-number inline-block">{state[VISIBLE].length + 1}.</div>
            <div className="column-item place-holder" />
          </div>
        )}
        {provided.placeholder}
      </div>
    );
  };

  return (
    <InvokeModal
      modalTitle={intl.get('app.configureColumns')}
      showModal={showModal}
      toggle={toggle}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.save')}
      save={updateAnswerValue}
      enableSave={!isEqual(state[VISIBLE], columnConfig.columnPositions) && state[VISIBLE].length}
    >
      <div className="participant-data-columns">
        <DragDropContext onDragEnd={onDragEnd}>
          <p>
            <strong>{intl.get('app.selectAndOrderColumns')}</strong>
          </p>
          <div className="hidden-section">
            <p>{intl.get('app.hidden')}</p>
            <Droppable droppableId={`${sessionId}-hidden`}>
              {(provided, snapshot) => getDropableZone(provided, snapshot, HIDDEN)}
            </Droppable>
          </div>
          <div className="visible-section">
            <p>{intl.get('app.visible')}</p>
            <Droppable droppableId={`${sessionId}-visible`}>
              {(provided, snapshot) => getDropableZone(provided, snapshot, VISIBLE)}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </InvokeModal>
  );
};
