import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

export const FiltersDropdown = props => {
  const { selectedFilter, sessions, consolidatedReportData, videoCaptureEnabled, filterSelectRef } = props;
  const { filters } = consolidatedReportData;

  function setFilter(e) {
    props.setFilter(JSON.parse(e.target.value));
  }

  function getFilterGroupName(group) {
    const session = sessions.find(s => s.id === group);
    return session ? session.name : group;
  }

  function getFilterStringValue(filter) {
    if (!filter) {
      return '';
    }
    return JSON.stringify({ type: filter.type, name: filter.name, expression: filter.expression });
  }

  return (
    <Input
      type="select"
      required
      value={getFilterStringValue(selectedFilter)}
      onChange={setFilter}
      innerRef={filterSelectRef}
    >
      <option value="" disabled selected>
        {intl.get('app.selectFilterPlaceholder')}
      </option>
      {Object.keys(filters)
        .filter(group => filters[group] && filters[group].length > 0)
        .map(group => (
          <optgroup label={getFilterGroupName(group)}>
            {filters[group]
              .filter(filter => filter.type !== 'DEFAULT_FILTER' || videoCaptureEnabled)
              .map(filter => (
                <option value={getFilterStringValue(filter)}>{filter.name}</option>
              ))}
          </optgroup>
        ))}
    </Input>
  );
};
