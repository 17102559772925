import { takeLatest, put, select } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  fetchCustomReportConfigListAction,
  fetchCustomReportConfigAction,
  saveCustomReportConfigActions,
  fetchCustomReportConsolidatedDataActions,
  generateCustomReportActions
} from '../actions/customReportConfigActions';
import {
  fetchCustomReportConfigs,
  fetchCustomReportConfig,
  saveCustomReportConfig,
  fetchCustomReportConsolidatedData
} from '../../../api/customReportConfigApi';
import { sessionUserSelector } from '../selectors/sessionUserSelector';
import { generateReport } from '../../../api/reportsApi';

function* customReportConfigListSaga() {
  const saga = createFetchSaga(fetchCustomReportConfigListAction, fetchCustomReportConfigs);
  yield* saga();
}

function* customReportConfigSaga() {
  const saga = createFetchSaga(fetchCustomReportConfigAction, fetchCustomReportConfig);
  yield* saga();
}

function* handleCustomReportConfigSaved({ payload: config }) {
  toast.success({ text: intl.get('app.configuration.saved', { name: config.name }) });
  yield put(fetchCustomReportConfigListAction.request());
}

function* handleCustomReportRequested({ payload }) {
  const state = yield select();
  const sessionUser = sessionUserSelector(state).sessionUser;
  const email = (sessionUser && sessionUser.email) || '';
  toast.success({ text: intl.get('app.customReport.mail', { mail: email, ref: payload.id }) });
}

function* saveCustomReportConfigSaga() {
  const saga = createFetchSaga(saveCustomReportConfigActions, saveCustomReportConfig);
  yield* saga();
  yield takeLatest(saveCustomReportConfigActions.succeeded, handleCustomReportConfigSaved);
}

function* customReportConsolidatedDataSaga() {
  const saga = createFetchSaga(fetchCustomReportConsolidatedDataActions, fetchCustomReportConsolidatedData);
  yield* saga();
}

function* generateCustomReportSaga() {
  const saga = createFetchSaga(generateCustomReportActions, generateReport);
  yield* saga();
  yield takeLatest(generateCustomReportActions.succeeded, handleCustomReportRequested);
}

export {
  customReportConfigListSaga,
  customReportConfigSaga,
  saveCustomReportConfigSaga,
  customReportConsolidatedDataSaga,
  generateCustomReportSaga
};
