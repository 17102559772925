import React from 'react';
import intl from 'react-intl-universal';
import { Icons } from '../../../../../../components/icons/Icons';

const CLUSTERS = 'clusters';
const RESPONSES = 'responses';

export const ViewSelector = props => {
  const { view, setView } = props;
  return (
    <div className="view-icons">
      <div
        className={(view === CLUSTERS && 'selected clickable') || 'clickable'}
        style={{ flexDirection: 'column' }}
        onClick={() => setView(CLUSTERS)}
      >
        <Icons.ClustersIcon className="icon" title={intl.get('app.clusterAnalysis')} />
        <div style={{ fontSize: '8px' }}>{intl.get('app.clusters')}</div>
      </div>
      <div
        className={(view === RESPONSES && 'selected clickable') || 'clickable'}
        style={{ flexDirection: 'column' }}
        onClick={() => setView(RESPONSES)}
      >
        <Icons.TableIcon className="icon" title={intl.get('app.responseTable')} />
        <div style={{ fontSize: '8px' }}>{intl.get('app.table')}</div>
      </div>
    </div>
  );
};
