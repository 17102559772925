import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { Loader } from 'webapp-common';
import { useProjectSelector } from '../../../customHooks/reduxHelper';
import { ProjectStatusBar } from '../../project/projectStatusBar';
import ProjectDetailsWrapper from '../../project/details';
import { ENGLISH } from '../../../util/joinerUtil';
import {
  NEWPROJECT,
  PROJECT_DETAILS_TAB,
  REPORTS_AND_DATA_TAB,
  RECRUIT_TAB,
  SURVEY_TAB,
  AUTO_ENROLL_TAB
} from '../../../util/projectUtil';
import { jsUtil } from '../../../util/jsUtil';
import { ProjectLeftNav } from './ProjectLeftNav';
import { ProjectRecruit } from '../../project/recruit/ProjectRecruit';
import { ProjectRecruitCollection } from '../../project/recruit/import';
import { ProjectReportsAndData } from '../../project/reportsAndData/ProjectReportsAndData';
import { ProjectSurveyWrapper } from '../../project/survey/ProjectSurveyWrapper';
import { ProjectAutoEnroll } from '../../project/recruit/autoEnroll/ProjectAutoEnroll';

const reducer = (state, payload) => ({ ...state, ...payload });

const ProjectPage = props => {
  const { hasProjectView, hasProjectManage, hasReportsView, hasReportsManage } = props;

  const routeParams = useParams();
  const { projectId, tab: activeTab, subtab, subtab2 } = routeParams;
  const project = useProjectSelector({ projectId }) ?? {};
  const { projectDetailsRequested, projectDetails } = project;

  const { name: projectName } = projectDetails || {};

  const [state, setState] = useReducer(reducer, {
    projectName: projectName || '',
    editMode: true,
    viewLanguage: ENGLISH
  });

  useEffect(() => {
    if (!projectDetails && projectId !== NEWPROJECT) {
      props.fetchProject({ projectId, includeSessionCount: true });
      setState({ editMode: true });
    } else if (projectId === NEWPROJECT && state.editMode) {
      setState({
        projectName: projectName || intl.get('app.newProject'),
        editMode: false
      });
    }
  }, [projectId]);

  const updateProjectName = name => {
    setState({ projectName: name });
  };

  function setViewLanguage(lang) {
    setState({ viewLanguage: lang });
  }

  const updatePanelConfigName = name => {
    setState({ configName: name });
  };

  const heightOffset = jsUtil.getProjectStatusBarHeight();

  return (
    <>
      <ProjectStatusBar
        project={project}
        projectName={state.projectName || projectName}
        routeParams={routeParams}
        viewLanguage={state.viewLanguage}
        setViewLanguage={setViewLanguage}
        configName={state.configName}
      />
      <section className="app-body" style={{ height: `calc(100% - ${heightOffset}px)` }}>
        <ProjectLeftNav
          projectDetails={projectDetails}
          activeTab={activeTab}
          editMode={state.editMode}
          hasProjectView={hasProjectView}
          hasReportsView={hasReportsView}
        />
        {activeTab === PROJECT_DETAILS_TAB && (
          <ProjectDetailsWrapper
            projectId={projectId}
            editMode={state.editMode}
            updateProjectName={updateProjectName}
          />
        )}
        {activeTab === RECRUIT_TAB && !subtab && (
          <ProjectRecruit projectId={projectId} project={project} updatePanelConfigName={updatePanelConfigName} />
        )}
        {activeTab === RECRUIT_TAB && subtab && !subtab2 && <ProjectRecruitCollection project={project} />}
        {activeTab === RECRUIT_TAB && subtab && subtab2 && (
          <ProjectSurveyWrapper
            isScreener
            project={project}
            viewLanguage={state.viewLanguage}
            setViewLanguage={setViewLanguage}
            key={`ProjectSurveyHoc_${activeTab}`}
          />
        )}
        {activeTab === AUTO_ENROLL_TAB && (
          <ProjectAutoEnroll
            project={project}
            hasProjectView={hasProjectView}
            hasProjectManage={hasProjectManage}
            viewLanguage={state.viewLanguage}
            setViewLanguage={setViewLanguage}
            key={`ProjectSurveyHoc_${activeTab}`}
            updatePanelConfigName={updatePanelConfigName}
          />
        )}
        {activeTab === SURVEY_TAB && (
          <ProjectSurveyWrapper
            project={project}
            viewLanguage={state.viewLanguage}
            setViewLanguage={setViewLanguage}
            key={`ProjectSurveyHoc_${activeTab}`}
          />
        )}
        {activeTab === REPORTS_AND_DATA_TAB && (
          <ProjectReportsAndData
            projectId={projectId}
            project={project}
            hasReportsView={hasReportsView}
            hasReportsManage={hasReportsManage}
            hasProjectManage={hasProjectManage}
          />
        )}
        {projectDetailsRequested && <Loader spinner fullScreen />}
      </section>
    </>
  );
};

export { ProjectPage };
