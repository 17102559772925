import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function getDateTime(dateTime) {
  return (dateTime && dayjs(dateTime)) || null;
}

export const DateTimePickerWrapper = ComposedComponent => props => {
  function handleChange(dateTime) {
    props.onChange(dateTime?.toDate());
  }

  return <ComposedComponent {...props} dateTime={getDateTime(props.dateTime)} onChange={handleChange} />;
};

DateTimePickerWrapper.displayName = 'DateTimePickerWrapper';

export default DateTimePickerWrapper;
