import React from 'react';
import intl from 'react-intl-universal';

import './ReadOnlyBanner.css';

export const ReadOnlyBanner = ({ translationInProgress }) => {
  const msgKey = translationInProgress
    ? 'app.surveyEditor.readOnlyTranslationInProgress'
    : 'app.surveyEditor.readOnlyMode';

  return (
    <div className="read-only-banner">
      <h2>{intl.get(msgKey)}</h2>
    </div>
  );
};
