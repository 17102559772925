import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { accessHandler } from 'webapp-common';
import { getFlattenedJoinersList } from '../../../../util/conceptRotationUtil';
import { SelectedQuestionsConfig } from '../common/surveyQuestionPicker/SelectedQuestionsConfig';
import { ParticipantDataPicker } from '../common/participantDataPicker/ParticipantDataPicker';

import './RawDataReport.css';

// From RawDataType.java
const outputTypes = {
  Excel: 'Excel',
  SPSS: 'SPSS',
  Both: 'Both'
};

// From VerbatimFormat.java
const verbatimFormat = {
  WIDE: 'WIDE',
  LONG: 'LONG'
};

function getFlattenedDataPoints(dataPoints) {
  let flattened = [];
  dataPoints.forEach(qDef => {
    qDef.value = qDef.value || [];
    const value = qDef.value;
    if (value.rowInfo && value.columnInfo) {
      value.rowInfo.forEach(row => {
        const copy = {
          ...qDef,
          varId: row.varId,
          questionTitle: `${qDef.questionTitle} - ${row.answerText}`,
          value: value.columnInfo
        };
        flattened.push(copy);
      });
    } else {
      if (qDef.type === 'MULTI') {
        // Answer values for MULTI questions don't work as-is for filters, so we need to add a filterValue field
        // that can be used when building filters. Multi values are always Yes/No, so selecting this answer in
        // the filter builder is the equivalent of selecting 'Yes'.
        value.forEach(v => {
          v.filterValue = 'Yes';
        });
      }
      flattened.push(qDef);
    }
  });
  return flattened;
}

export const RawDataReport = props => {
  const { reportConfig, consolidatedReportData, setField, updateReportConfig, generateReport, hasReportsView } = props;

  const [showParticipantDataPicker, setShowParticipantDataPicker] = useState();

  const accessCheck = accessHandler.checkAccess(hasReportsView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  function toggleIncludeAnswerChoiceText() {
    updateReportConfig({ ...reportConfig, includeAnswerChoiceText: !reportConfig.includeAnswerChoiceText });
  }

  function setSelectedDataPoints(payload) {
    updateReportConfig({
      ...reportConfig,
      includeAllDataPoints: payload.includeAllDataPoints,
      selectedDataPoints: payload.selectedDataPoints
    });
  }

  const participantDataPointsList = Object.keys(consolidatedReportData.metadata).reduce((accum, metaKey) => {
    return accum.concat(consolidatedReportData.metadata[metaKey]);
  }, []);

  const participantDataPoints = getFlattenedDataPoints(participantDataPointsList);

  const dataPoints = reportConfig.includeAllDataPoints
    ? Object.keys(participantDataPoints).length
    : reportConfig.selectedDataPoints.length;

  const questions = reportConfig.includeAllQuestions
    ? getFlattenedJoinersList(consolidatedReportData.consolidatedQuestion).length
    : reportConfig.selectedQuestionJoinerIds.length;

  const exportDisabled = dataPoints <= 0 || (questions <= 0 && dataPoints <= 0);

  return (
    <section className="raw-data-report">
      <div className="config-area">
        <div className="config-column">
          <div>
            <div>{intl.get('app.participants')}</div>
            <label>
              <Input
                type="radio"
                checked={!reportConfig.includeTerminated}
                onChange={() => setField('includeTerminated', false)}
              />
              {intl.get('app.allNonTerminated')}
            </label>
            <label>
              <Input
                type="radio"
                checked={reportConfig.includeTerminated}
                onChange={() => setField('includeTerminated', true)}
              />
              {intl.get('app.allIncludingTerminated')}
            </label>
          </div>

          <SelectedQuestionsConfig
            reportConfig={reportConfig}
            consolidatedReportData={consolidatedReportData}
            updateReportConfig={updateReportConfig}
          />

          <div>
            <div>{intl.get('app.outputType')}</div>
            <label>
              <Input
                type="radio"
                checked={reportConfig.outputType === outputTypes.Excel}
                onChange={() => setField('outputType', outputTypes.Excel)}
              />
              {intl.get('app.outputType.excel')}
            </label>
            <label>
              <Input
                type="radio"
                checked={reportConfig.outputType === outputTypes.SPSS}
                onChange={() => setField('outputType', outputTypes.SPSS)}
              />
              {intl.get('app.outputType.spss')}
            </label>
            <label>
              <Input
                type="radio"
                checked={reportConfig.outputType === outputTypes.Both}
                onChange={() => setField('outputType', outputTypes.Both)}
              />
              {intl.get('app.outputType.both')}
            </label>
          </div>

          <Button color="primary" style={{ marginLeft: 0 }} onClick={generateReport} disabled={exportDisabled}>
            {intl.get('app.createReport')}
          </Button>
        </div>

        <div className="config-column">
          <div>
            <div>{intl.get('app.quantQuestionFormattingOptions')}</div>
            <label>
              <Input
                type="radio"
                checked={!reportConfig.useScalarValue}
                onChange={() => setField('useScalarValue', false)}
              />
              {intl.get('app.usePunchNumberForResponse')}
            </label>
            <label>
              <Input
                type="radio"
                checked={reportConfig.useScalarValue}
                onChange={() => setField('useScalarValue', true)}
              />
              {intl.get('app.useScalarValueForResponse')}
            </label>
            <label style={{ margin: '1rem 0' }}>
              <Input
                type="checkbox"
                checked={reportConfig.includeAnswerChoiceText}
                onChange={toggleIncludeAnswerChoiceText}
              />
              {intl.get('app.includeAnswerChoiceTextAsColumn')}
            </label>
            <div>{intl.get('app.valueWhenParticipant')}</div>
            <div className="not-answered-row">
              {intl.get('app.didNotAnswerTheQuestion')}{' '}
              <Input
                type="number"
                value={reportConfig.notAnswered}
                onChange={e => setField('notAnswered', e.target.value)}
              />
            </div>
            <div className="not-answered-row">
              {intl.get('app.didNotSeeTheQuestion')}{' '}
              <Input
                type="number"
                value={reportConfig.questionNotSeen}
                onChange={e => setField('questionNotSeen', e.target.value)}
              />
            </div>
          </div>

          <div>
            <div>{intl.get('app.qualQuestionFormattingOptions')}</div>
            <label>
              <Input
                type="radio"
                checked={reportConfig.verbatimFormat === verbatimFormat.LONG}
                onChange={() => setField('verbatimFormat', verbatimFormat.LONG)}
              />
              {intl.get('app.oneQuestionResponsePerRow')}
            </label>
            <label>
              <Input
                type="radio"
                checked={reportConfig.verbatimFormat === verbatimFormat.WIDE}
                onChange={() => setField('verbatimFormat', verbatimFormat.WIDE)}
              />
              {intl.get('app.oneRowPerParticipant')}
            </label>
          </div>
        </div>

        <div className="config-column">
          <div>
            <div>{intl.get('app.participantData')}</div>
            <label>
              <Input
                type="radio"
                checked={reportConfig.includeAllDataPoints}
                onChange={() => setSelectedDataPoints({ includeAllDataPoints: true, selectedDataPoints: [] })}
              />
              {intl.get('app.allParticipantData')}
            </label>
            <div style={{ display: 'flex' }}>
              <label>
                <Input
                  type="radio"
                  checked={!reportConfig.includeAllDataPoints}
                  onChange={() => setShowParticipantDataPicker(true)}
                />
                {intl.get('app.selectedParticipantData')}
              </label>
              <Button
                className="link-button configure-button"
                onClick={() => setShowParticipantDataPicker(true)}
                disabled={reportConfig.includeAllDataPoints}
              >
                {intl.get('app.configure')}
              </Button>
            </div>
          </div>

          <div>
            <div>{intl.get('app.chat')}</div>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.includeChats}
                onChange={e => setField('includeChats', e.target.checked)}
              />
              {intl.get('app.participantChatMessages')}
            </label>
          </div>
        </div>
      </div>
      {showParticipantDataPicker && (
        <ParticipantDataPicker
          questionDataDefList={participantDataPoints}
          selectedDataPoints={reportConfig.selectedDataPoints}
          includeAllDataPoints={reportConfig.includeAllDataPoints}
          setSelectedDataPoints={setSelectedDataPoints}
          closeModal={() => setShowParticipantDataPicker(false)}
        />
      )}
    </section>
  );
};
