import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { InvokeTable, Loader } from 'webapp-common';
import appConfig from '../../../appConfig';
import { useSessionSelector } from '../../../customHooks/reduxHelper';
import { useProjectPanelConfigsQuery } from '../../../customHooks/useProjectPanelConfigQuery';
import { enrollmentTypes } from '../../../util/participantDataUtil';
import { surveyUtil } from '../../../util/surveyUtil';
import { AUTO_ENROLL_TAB, PANEL_CONFIG_TAB } from '../../../util/projectUtil';
import { tabs } from '../survey/ProjectSurveyTabs';
import { addNewSurveyActions } from '../../../store/redux/actions/surveyActions';
import { addNewSessionAction, fetchAllSessionsAction } from '../../../store/redux/actions/sessionActions';
import localtime from '../../../util/localtime';
import { UploadModal } from './import/UploadModal';
import { ImportModal } from './import/ImportModal';

import './ProjectRecruitCollections.css';

const reducer = (state, payload) => ({ ...state, ...payload });

/*
 * Combines the participantCollectionJobs and projectPanelConfigs into a single list of sorted objects
 */
function getTableData(participantCollectionJobs, projectPanelConfigs) {
  // Filter out auto-enroll collections
  const filteredCollectionJobs = participantCollectionJobs?.content?.filter(cj => !cj.collection.autoEnrolled);
  const list = [...(filteredCollectionJobs || []), ...(projectPanelConfigs?.content || [])];
  list.sort((a, b) => (a.modifiedDate < b.modifiedDate && -1) || (a.modifiedDate > b.modifiedDate && 1) || 0);
  return list;
}

export const ProjectRecruitCollections = props => {
  const { project, hasProjectManage } = props;

  const {
    projectDetails,
    participantCollectionJobs = {},
    newMetadataCollection,
    participantCollectionJobsRequested,
    createMetadataCollectionRequested,
    createMetadataCollectionSuccess,
    saveMetadataJobRequested,
    saveMetadataJobSuccess
  } = project || {};

  const [state, setState] = useReducer(reducer, {
    showUploadModal: false,
    showImportModal: false,
    showAddAutoEnrollModal: false
  });
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const routeParams = useParams();
  const pingerRef = useRef();
  const participantCollectionJobsRef = useRef([]);
  const { allSessions, requestInProgress } = useSessionSelector();

  const projectPanelConfigsQuery = useProjectPanelConfigsQuery({ projectId: projectDetails?.id });

  // On component load, fetch collection jobs, and start the pinger.
  useEffect(() => {
    if (projectDetails?.id) {
      props.fetchParticipantCollectionJobs({
        pageNumber: 1,
        pageSize: -1,
        projectId: projectDetails.id,
        storeId: projectDetails.config.metaDataStoreId
      });
      pingerRef.current = setInterval(pingParticipantCollectionJobs, 2000);
      return () => {
        clearInterval(pingerRef.current);
      };
    }
  }, [projectDetails?.id]);

  // Fired whenever collectionJobs changes
  useEffect(() => {
    if (participantCollectionJobs.content && participantCollectionJobs.content.length !== 0) {
      participantCollectionJobsRef.current = participantCollectionJobs.content;
    }
  }, [participantCollectionJobs.content]);

  useEffect(() => {
    if (!createMetadataCollectionRequested && createMetadataCollectionSuccess) {
      // First step is complete. Show the import modal.
      setState({
        showUploadModal: false,
        showImportModal: true
      });
    }
  }, [createMetadataCollectionRequested, createMetadataCollectionSuccess]);

  useEffect(() => {
    if (!saveMetadataJobRequested && saveMetadataJobSuccess && newMetadataCollection) {
      // Second step is complete. Close the dialog, and select the new collection.
      setState({
        showImportModal: false,
        selectedCollectionId: newMetadataCollection.jobUnit.metadataCollectionId
      });
    }
  }, [saveMetadataJobRequested, saveMetadataJobSuccess]);

  useEffect(() => {
    if (projectDetails?.id) {
      dispatch(
        fetchAllSessionsAction.request({
          projectId: projectDetails.id,
          screener: true,
          pageNumber: 1,
          pageSize: -1
        })
      );
    }
  }, [projectDetails?.id]);

  // Ping for updates for running jobs
  function pingParticipantCollectionJobs() {
    const jobs = participantCollectionJobsRef.current;
    const runningJobIds = jobs.filter(job => job.status === 'Running').map(job => job.jobId);
    if (runningJobIds.length > 0) {
      props.pingParticipantCollectionJobs({
        projectId: projectDetails.id,
        jobIds: runningJobIds
      });
    }
  }

  function toggleUploadModal() {
    setState({ showUploadModal: !state.showUploadModal });
  }

  function toggleImportModal() {
    setState({ showImportModal: !state.showImportModal });
  }

  function onAddScreenerClick() {
    const { session, survey } = surveyUtil.addSurvey(true, allSessions, projectDetails);
    dispatch(addNewSurveyActions.succeeded(survey));
    dispatch(addNewSessionAction.succeeded(session));
    navigate(`${appConfig.projectPagePath}/${routeParams.projectId}/recruit/${session.id}/screener`);
  }

  function onAddAutoEnrollClick() {
    props.updatePanelConfigName(intl.get('app.newAutoEnrollConfig'));
    navigate(`${appConfig.projectPagePath}/${routeParams.projectId}/${AUTO_ENROLL_TAB}`);
  }

  // First part of collection creation
  function onCreateSubmit(params) {
    params.projectId = projectDetails.id;
    params.storeId = projectDetails.config.metaDataStoreId;
    params.collectionName = params.entityName;
    props.createMetadataCollection(params);
  }

  // Second part of collection creation
  function saveMetadataJob(job) {
    const payload = {
      projectId: projectDetails.id,
      job
    };
    props.saveMetadataJob(payload);
  }

  const showLoader =
    projectPanelConfigsQuery.isLoading ||
    participantCollectionJobsRequested ||
    createMetadataCollectionRequested ||
    saveMetadataJobRequested;

  const nameFormatter = useCallback(info => {
    const obj = info.row.original;
    const name = obj.collection?.name || obj.name;
    return (
      <span className="link" title={name}>
        {name}
      </span>
    );
  }, []);

  const typeFormatter = useCallback(info => {
    const obj = info.row.original;
    const type = info.getValue();
    const collectionType = type || obj.type || (obj.collection?.autoEnrolled && enrollmentTypes.AUTO_ENROLL) || '';
    return <div title={obj.errMsg}>{intl.get(`app.collectionType.${collectionType}`)}</div>;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: intl.get('app.name'),
        headerStyle: { width: '18rem' },
        cell: nameFormatter,
        cellClassName: 'text-truncate',
        enableSorting: false
      },
      {
        accessorKey: 'type',
        header: intl.get('app.type'),
        headerStyle: { width: '7rem' },
        cell: typeFormatter,
        enableSorting: false
      },
      {
        accessorKey: 'modifiedDate',
        header: intl.get('app.lastUpdated'),
        headerStyle: { width: '14rem' },
        cell: timestamp => localtime.getFormattedDate(timestamp),
        enableSorting: false
      },
      {
        accessorKey: 'records',
        header: intl.get('app.records'),
        headerStyle: { width: '6rem' },
        enableSorting: false
      }
    ];
  }, [nameFormatter, typeFormatter]);

  const getScreenerId = useCallback(
    collection => {
      const session = allSessions?.content?.find(s => s.config?.participantCollectionIds?.[0] === collection.id);
      return session.id;
    },
    [allSessions?.content]
  );

  const onCollectionSelect = useCallback(
    obj => {
      let id = '';
      let tab = routeParams.tab;
      if (obj.type === enrollmentTypes.SCREENER) {
        id = getScreenerId(obj.collection);
        id = `${id}/${tabs.SETTINGS_TAB}`;
      } else if (obj.type === enrollmentTypes.AUTO_ENROLL) {
        tab = `${AUTO_ENROLL_TAB}/${PANEL_CONFIG_TAB}`;
        id = obj.id;
      } else {
        id = obj.collection.id;
      }
      navigate(`${appConfig.projectPagePath}/${routeParams.projectId}/${tab}/${id}`);
    },
    [getScreenerId, navigate, routeParams.projectId, routeParams.tab]
  );

  if (!projectDetails) {
    return null;
  }

  return (
    <section className="project-recruit-collections">
      {(showLoader || requestInProgress) && <Loader spinner fullScreen />}
      <section className="body-container l2-bg">
        <Button color="secondary" style={{ marginLeft: 0 }} disabled={!hasProjectManage} onClick={toggleUploadModal}>
          {intl.get('app.importList')}
        </Button>
        <Button color="secondary" disabled={!hasProjectManage} onClick={onAddScreenerClick}>
          {intl.get('app.addScreener')}
        </Button>
        <Button color="secondary" disabled={!hasProjectManage} onClick={onAddAutoEnrollClick}>
          {intl.get('app.addAutoEnroll')}
        </Button>
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={getTableData(participantCollectionJobs, projectPanelConfigsQuery?.data)}
          onRowSelect={onCollectionSelect}
        />
      </section>
      {state.showUploadModal && (
        <UploadModal
          toggle={toggleUploadModal}
          onSubmit={onCreateSubmit}
          modalTitle={intl.get('app.importParticipantData')}
          cancelButtonText={intl.get('app.cancel')}
          primaryButtonText={intl.get('app.next')}
          uploadNameLabel={intl.get('app.listName')}
        />
      )}
      {state.showImportModal && (
        <ImportModal
          toggle={toggleImportModal}
          newMetadataCollection={newMetadataCollection}
          onSave={saveMetadataJob}
        />
      )}
    </section>
  );
};
