import { createStructuredSelector } from 'reselect';

const sessionUserDetail = state => (state.sessionUser && state.sessionUser.user) || {};
const sessionTenantDetail = state => (state.sessionUser && state.sessionUser.tenant) || {};
const abilitiesDetail = state => state.sessionUser && state.sessionUser.abilities;
const clientUIConfig = state => state.sessionUser && state.sessionUser.clientUIConfig;

const sessionUserSelector = createStructuredSelector({
  sessionUser: sessionUserDetail,
  sessionTenant: sessionTenantDetail,
  abilities: abilitiesDetail,
  clientUIConfig
});

export { sessionUserSelector };
