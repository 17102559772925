import { cloneDeep } from 'lodash';

// From DisplayOption.java
const questionConfigDisplayOptions = {
  COUNT: 'count',
  RESPONSE_RATE: 'responseRate',
  COUNT_AND_RATE: 'countAndRate'
};

/*
 * Get the displayOption from either the question config, the global question config, or the default.
 */
const getQuestionConfigDisplayOption = (rdConfig, questionDefId) => {
  const { questionsConfig } = rdConfig.configs;
  const globalQuestionConfig = questionsConfig.globalQuestionConfig || {};
  const questionConfig = questionsConfig.questionsConfigMap[questionDefId] || {};
  return questionConfig.displayOption || globalQuestionConfig.displayOption || questionConfigDisplayOptions.COUNT;
};

/*
 * Get the netDetails from either the question config or the global question config.
 */
const getQuestionConfigNetDetails = (rdConfig, questionDefId) => {
  const { questionsConfig } = rdConfig.configs;
  const globalQuestionConfig = questionsConfig.globalQuestionConfig || {};
  const questionConfig = questionsConfig.questionsConfigMap[questionDefId] || {};
  return questionConfig.netDetails || globalQuestionConfig.netDetails || {};
};

/*
 * Get the sorting configs from matrix or choice sorting config.
 */
const getMatrixChoiceSortingConfig = (rdConfig, joinerId) => {
  const { matrixChoiceSortingConfig = {} } = rdConfig.configs;
  const { individualQuestionConfigs } = matrixChoiceSortingConfig;
  if (individualQuestionConfigs) {
    return individualQuestionConfigs[joinerId];
  }
  return null;
};

/*
 * If the Column Config doesn't already exist, generate a default config.
 *
 * @param columnConfig - The columnConfig object
 * @param defaultColumns - Map of default columns for the table
 * @param defaultHiddenColumns - Map of any default columns that should be hidden by default
 * @param metadataColumns - Enrollee metadata columns, which are hidden by default.
 */
const getColumnConfig = (columnConfig, defaultColumns, defaultHiddenColumns, metadataColumns) => {
  const clonedConfig = cloneDeep(columnConfig);
  clonedConfig.columnPositions = clonedConfig.columnPositions || [];
  clonedConfig.hiddenColumns = clonedConfig.hiddenColumns || {};
  const { columnPositions, hiddenColumns } = clonedConfig;

  if (columnPositions.length === 0) {
    Object.values(defaultColumns).forEach(col => {
      if (!defaultHiddenColumns[col] && !hiddenColumns[col]) {
        columnPositions.push(col);
      }
    });
  }

  if (Object.keys(hiddenColumns).length === 0) {
    // Add any default hidden columns
    Object.keys(defaultHiddenColumns).forEach(col => {
      if (columnPositions.indexOf(col) === -1) {
        hiddenColumns[col] = true;
      }
    });
    // Add enrollee metadata columns
    metadataColumns?.forEach(col => {
      if (columnPositions.indexOf(col) === -1) {
        hiddenColumns[col] = true;
      }
    });
  }

  return clonedConfig;
};

export const rdConfigUtil = {
  questionConfigDisplayOptions,
  getQuestionConfigDisplayOption,
  getQuestionConfigNetDetails,
  getMatrixChoiceSortingConfig,
  getColumnConfig
};
