import { del, get, post } from '../util/request';

async function fetchReports(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy || 'createDate',
      sortOrder: apiOptions.sortOrder || 'desc',
      reportName: apiOptions.reportName || '',
      reportType: apiOptions.reportType || '',
      projectName: apiOptions.projectName || '',
      firstSessionName: apiOptions.firstSessionName || '',
      projectId: apiOptions.projectId || ''
    }) ||
    {};
  const url = '/a/api/reports';
  return get(url)(params).then(response => response.body.payload);
}

async function generateReport(payload) {
  return post('/a/api/report')(payload).then(res => (res.body && res.body.payload) || '');
}

async function deleteReport(reportId) {
  const url = `/a/api/report/${reportId}`;
  return del(url)({}).then(res => res && res.body && res.body.payload);
}

async function getConsolidatedProjectData(payload) {
  return post('/a/api/getConsolidatedProjectData')(payload).then(res => res.body && res.body.payload);
}

export { fetchReports, generateReport, deleteReport, getConsolidatedProjectData };
