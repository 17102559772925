import React from 'react';
import intl from 'react-intl-universal';
import { FormGroup, Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

class CreateSurveyTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTemplateName: ''
    };
    this.updateTemplateName = this.updateTemplateName.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  updateTemplateName(e) {
    this.setState({
      newTemplateName: e.target.value
    });
  }

  onSave() {
    this.props.onSave({ surveyName: this.state.newTemplateName, isScreener: this.props.isScreener });
  }

  render() {
    const { showModal, toggle, isScreener } = this.props;
    return (
      <InvokeModal
        showModal={showModal}
        enableSave={!!this.state.newTemplateName}
        toggle={toggle}
        save={this.onSave}
        modalTitle={isScreener ? intl.get('app.addScreenerTemplate') : intl.get('app.addSurveyTemplate')}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
      >
        <FormGroup className="ms-3">
          <h6>{isScreener ? intl.get('app.screenerTemplateName') : intl.get('app.surveyTemplateName')}:</h6>
          <Input className="mt-3 w-100" autoFocus onChange={this.updateTemplateName} />
        </FormGroup>
      </InvokeModal>
    );
  }
}

export default CreateSurveyTemplateModal;
