import { get, post, del } from '../util/request';
import { cleanStringArray } from '../util/jsUtil';

const PROJECTS_API = '/a/api/projects';

async function fetchProjects(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy || 'createDate',
      sortOrder: apiOptions.sortOrder || 'desc',
      includeSessionCount: apiOptions.includeSessionCount === undefined || apiOptions.includeSessionCount,
      labels: cleanStringArray(apiOptions.filter),
      name: cleanStringArray(apiOptions.filter)
    }) ||
    {};
  return get(PROJECTS_API)(params).then(response => response.body.payload);
}

async function fetchRecentProjects(params) {
  return post('/a/api/recentProjects')(params).then(response => response.body.payload);
}

async function fetchAllProjects() {
  const params = {
    pageSize: -1,
    sortField: 'name',
    sortOrder: 'asc'
  };
  return get(PROJECTS_API)(params).then(response => response.body.payload);
}

async function fetchProject({ projectId, includeSessionCount = false }) {
  const url = `/a/api/project/${projectId}`;
  return get(url)({ includeSessionCount }).then(res => res.body.payload);
}

async function saveProject(project) {
  const url = '/a/api/project';
  return post(url)(project).then(response => response.body && response.body.payload);
}

function getResponse(project, resp) {
  return {
    ...resp.body,
    message: resp.body.payload,
    projectName: project.name
  };
}

async function archiveProject(project) {
  const url = `/a/api/archiveProject/${project.projectId}`;
  return post(url)({}).then(res => {
    return getResponse(project, res);
  });
}

async function fetchArchivedProjects(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || '',
      sortBy: apiOptions.sortBy || '',
      sortOrder: apiOptions.sortOrder || '',
      labels: cleanStringArray(apiOptions.filter),
      name: cleanStringArray(apiOptions.filter)
    }) ||
    {};
  const url = '/a/api/archivedProjects';
  return get(url)(params).then(response => response.body.payload);
}

async function deleteArchivedProject(project) {
  const url = `/a/api/archiveProject/${project.id}`;
  return del(url)({}).then(res => {
    return getResponse(project, res);
  });
}

async function restoreArchivedProject(project) {
  const url = `/a/api/restoreProject/${project.id}`;
  return post(url)({}).then(res => {
    return getResponse(project, res);
  });
}

export {
  fetchProjects,
  fetchRecentProjects,
  fetchAllProjects,
  fetchProject,
  saveProject,
  archiveProject,
  fetchArchivedProjects,
  restoreArchivedProject,
  deleteArchivedProject
};
