import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Label, Col, Input } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { FlexRow, InvokeModal, Loader } from 'webapp-common';
import { validateEmail } from '../../../util/jsUtil';

import './UserEdit.css';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

function hasProjectPermission(user, projectId) {
  const { userPermissions } = user;
  return userPermissions?.some(perm => perm.projectId === projectId);
}

function hasAllProjectsAccess(user) {
  const { userPermissions } = user;
  return !userPermissions || userPermissions.length === 0;
}

const updateAllRoles = (roles, userTenantRoles) => {
  const tempRoles = cloneDeep(roles);
  tempRoles.forEach(role => {
    role.checked = userTenantRoles?.some(userRole => userRole.tenantRoleID === role.tenantRoleId);
    return role;
  });
  return tempRoles;
};

// before finishing user project role, check/uncheck all projects and select projects for user.
export const UserEdit = props => {
  const { rolesSummaryRequested, saveUserInProgress, userSaveSuccess, create } = props;
  const readOnly = !props.hasUserAccountsManage;

  const [state, setState] = useReducer(reducer, {
    user: {},
    roles: [],
    allProjects: [],
    allProjectsChecked: false
  });

  useEffect(() => {
    props.fetchRoles({ pageSize: -1 });
    props.fetchAllProjects();
  }, []);

  useEffect(() => {
    const { user, allProjects = [] } = props;
    const roles = props.roles && updateAllRoles(props.roles, user.userTenantRoles);
    if (create) {
      user.isActive = true;
      user.userTenantRoles = [];
    }
    setState({
      roles,
      allProjects,
      allProjectsChecked: !create && hasAllProjectsAccess(user)
    });
  }, [props.roles, props.allProjects]);

  useEffect(() => {
    setState({
      user: props.user
    });
  }, [props.user]);

  useEffect(() => {
    if (userSaveSuccess) {
      props.openUserModal();
    }
  }, [userSaveSuccess]);

  const handleEmailChange = e => {
    const enteredEmail = e.target.value.trim();
    setState({
      user: {
        ...state.user,
        email: enteredEmail
      }
    });
  };

  const updateIsActive = isActive => {
    setState({
      user: {
        ...state.user,
        isActive
      }
    });
  };

  /*
   * Note case difference between user.userTenantRoles.tenantRoleID and role.tenantRoleId !!!!
   */
  const updateRoles = role => {
    const userTenantRoles = cloneDeep(state.user.userTenantRoles);
    const index = userTenantRoles.findIndex(r => r.tenantRoleID === role.tenantRoleId);
    if (index === -1) {
      userTenantRoles.push({
        roleLabel: role.roleLabel,
        tenantRoleID: role.tenantRoleId
      });
    } else {
      userTenantRoles.splice(index, 1);
    }
    const roles = updateAllRoles(state.roles, userTenantRoles);

    setState({
      user: {
        ...state.user,
        userTenantRoles
      },
      roles
    });
  };

  const isSaveEnabled = () => {
    const stateUser = state.user;
    const propsUser = props.user;

    if (!stateUser.email || !validateEmail(stateUser.email)) {
      return false;
    }

    if (!state.allProjectsChecked && hasAllProjectsAccess(stateUser)) {
      return false;
    }

    const stateRoles = stateUser.userTenantRoles
      .map(r => r.tenantRoleID)
      .sort()
      .join();

    const propsRoles = propsUser.userTenantRoles
      ?.map(r => r.tenantRoleID)
      .sort()
      .join();

    const statePerms =
      stateUser.userPermissions
        ?.map(p => p.projectId)
        .sort()
        .join() || '';

    const propsPerms =
      propsUser.userPermissions
        ?.map(p => p.projectId)
        .sort()
        .join() || '';

    return (
      propsUser.email !== stateUser.email ||
      propsUser.isActive !== stateUser.isActive ||
      propsRoles !== stateRoles ||
      propsPerms !== statePerms
    );
  };

  const saveUser = () => {
    const user = cloneDeep(state.user);
    if (create) {
      user.userName = user.email;
    }
    if (props.user.isActive && !user.isActive) {
      // If we're de-activating the user, wipe out the tenant roles too.
      user.userTenantRoles = [];
    }
    props.saveUser(user);
  };

  const getRoles = roles => {
    return roles.map(r => {
      return (
        <div className="user-role-project" key={r.tenantRoleId}>
          <label className="role clickable">
            <span className="row-label text-truncate" title={r.roleLabel}>
              {r.roleLabel}
            </span>
            <Input
              type="checkbox"
              className="row-checkbox"
              checked={r.checked}
              disabled={readOnly}
              onChange={() => updateRoles(r)}
            />
          </label>
        </div>
      );
    });
  };

  const updateProjects = projectId => {
    const userPermissions = cloneDeep(state.user.userPermissions) || [];
    const index = userPermissions.findIndex(perm => perm.projectId === projectId);
    if (index === -1) {
      userPermissions.push({
        projectId,
        sessionId: ''
      });
    } else {
      userPermissions.splice(index, 1);
    }
    setState({
      user: {
        ...state.user,
        userPermissions
      }
    });
  };

  const onAllProjectsClick = () => {
    setState({
      user: {
        ...state.user,
        userPermissions: []
      },
      allProjectsChecked: !state.allProjectsChecked
    });
  };

  const getProjects = () => {
    return state.allProjects.map(p => {
      return (
        <div className="user-role-project" key={p.id}>
          <label
            className={state.allProjectsChecked ? 'project' : 'project clickable'}
            disabled={state.allProjectsChecked}
          >
            <span className="row-label text-truncate" title={p.name}>
              {p.name}
            </span>
            <Input
              type="checkbox"
              className="row-checkbox"
              checked={hasProjectPermission(state.user, p.id)}
              disabled={readOnly}
              onChange={() => updateProjects(p.id)}
            />
          </label>
        </div>
      );
    });
  };

  if (rolesSummaryRequested) {
    return <Loader spinner fullScreen />;
  }

  return (
    <InvokeModal
      className="user-edit-modal user-edit"
      showModal
      toggle={() => props.openUserModal()}
      modalTitle={intl.get('app.addEditUser')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      save={saveUser}
      enableSave={!readOnly && isSaveEnabled()}
      backdrop={false}
      keyboard={false}
    >
      {saveUserInProgress && <Loader spinner fullScreen />}
      <FlexRow className="mt-3">
        <Label sm="3">{intl.get('app.emailAddress')}</Label>
        <Col sm="9">
          <Input value={state.user.email || ''} disabled={!create || readOnly} onChange={handleEmailChange} />
        </Col>
      </FlexRow>
      <FlexRow className="mt-4">
        <Label sm="3">{intl.get('app.active')}</Label>
        <Col sm="9">
          <Input
            type="checkbox"
            checked={state.user.isActive}
            disabled={readOnly}
            onChange={e => updateIsActive(e.target.checked)}
          />
        </Col>
      </FlexRow>
      <FlexRow className="mt-4" alignItems="flex-start">
        <Label sm="3">{intl.get('app.roles')}</Label>
        <Col sm="9" className="roles-container">
          {state.roles.length > 0 && getRoles(state.roles)}
        </Col>
      </FlexRow>
      <FlexRow className="mt-4" alignItems="flex-start">
        <Label sm="3">{intl.get('app.Projects')}</Label>
        <Col sm="9">
          <div>
            <Label>
              <Input
                type="checkbox"
                checked={state.allProjectsChecked}
                disabled={readOnly}
                onChange={onAllProjectsClick}
              />
              {intl.get('app.allProjects')}
            </Label>
          </div>
          <div className="projects-container">
            {state.allProjects.length > 0 ? getProjects() : <div>{intl.get('app.projects.notAvailable')}</div>}
          </div>
        </Col>
      </FlexRow>
    </InvokeModal>
  );
};
