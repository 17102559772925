import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, find, get, isEqual, range } from 'lodash';
import { Button, Input } from 'reactstrap';
import { InvokeModal, Loader } from 'webapp-common';
import localtime from '../../../../util/localtime';
import { idUtils } from '../../../../util/Id';
import { toast } from '../../../../util/toast';

import './SurveyDisplayPrefs.css';

const TOP = 'top';
const BOTTOM = 'bottom';
const COUNT = 'count';
const RESPONSE_RATE = 'responseRate';
const COUNT_AND_RATE = 'countAndRate';
const CANCLE_TEXT = 'app.cancel';

const reducer = (state, payload) => ({ ...state, ...payload });

const getNetConfig = rdConfigInUse => {
  const globalQuestionConfig = get(rdConfigInUse, 'configs.questionsConfig.globalQuestionConfig');
  const netDetails = (globalQuestionConfig && globalQuestionConfig.netDetails) || {};
  return {
    top: (netDetails.top && netDetails.top.length) || 0,
    bottom: (netDetails.bottom && netDetails.bottom.length) || 0,
    overwrite: false
  };
};

export const SurveyDisplayPrefs = props => {
  const [state, setState] = useReducer(reducer, {
    rdConfig: {},
    modifiedDate: '',
    useNew: true,
    showResetModal: false,
    showShareModal: false,
    showImportModal: false,
    importOrResetInProgress: false
  });

  const {
    sessionId,
    projectId,
    rdConfig,
    configOwners,
    researchDashboardConfigs,
    rdConfigsApiRequested,
    rdConfigSaveInProgress,
    sessions,
    userId
  } = props;

  useEffect(() => {
    if (userId && sessionId) {
      props.fetchRDConfigs({ userId, sessionId });
    }
  }, [userId, sessionId]);

  useEffect(() => {
    if (rdConfig.id) {
      setState({
        rdConfig: { ...rdConfig, draft: true },
        modifiedDate: localtime.stampToLocalString(rdConfig.modifiedDate)
      });
    }
  }, [rdConfig]);

  useEffect(() => {
    if (!rdConfigsApiRequested) {
      setState({
        showShareModal: false,
        showImportModal: false,
        showResetModal: false
      });
    }
  }, [rdConfigsApiRequested]);

  useEffect(() => {
    if (rdConfigSaveInProgress === false) {
      setState({
        modifiedDate: localtime.stampToLocalString(rdConfig.modifiedDate),
        showImportModal: false,
        showResetModal: false
      });
    }
  }, [rdConfigSaveInProgress]);

  useEffect(() => {
    if (state.importOrResetInProgress && !rdConfigSaveInProgress && !rdConfigsApiRequested) {
      // On rdConfig import or reset, reload so we start with a clean rd subscription.
      window.location.reload();
    }
  }, [rdConfigSaveInProgress, rdConfigsApiRequested, state.importOrResetInProgress]);

  const isPrefsNotChanged = () => {
    const netDetails = get(rdConfig, 'configs.questionsConfig.globalQuestionConfig.netDetails', {});
    const stateNetDetails = get(state.rdConfig, 'configs.questionsConfig.globalQuestionConfig.netDetails', {});
    const globalQuestionConfigPref = get(rdConfig, 'configs.questionsConfig.globalQuestionConfig.displayOption', '');
    const stateGlobalQuestionConfigPref = get(
      state.rdConfig,
      'configs.questionsConfig.globalQuestionConfig.displayOption',
      ''
    );
    return isEqual(netDetails, stateNetDetails) && globalQuestionConfigPref === stateGlobalQuestionConfigPref;
  };

  const toggleModal = (key, target) => {
    setState({ [key]: !state[key], target });
  };

  const onReset = () => {
    const myConfig = {
      ...state.rdConfig,
      configs: {
        ...state.rdConfig.configs,
        questionsConfig: {
          ...state.rdConfig.configs.questionsConfig,
          globalQuestionConfig: {
            displayOption: ''
          }
        }
      }
    };
    props.saveToConfigs({
      ...myConfig,
      projectId,
      draft: true
    });
  };

  const resetAll = config => {
    props.resetAll({ id: config.id });
    setState({ importOrResetInProgress: true });
  };

  const onGlobalNetsChange = (value, key) => {
    // verify top or bottom in range
    if (value > 100) {
      toast.error({ text: intl.get('app.notGreaterThan100') });
      return;
    }

    const myConfig = cloneDeep(state.rdConfig);
    if (!myConfig.configs.questionsConfig.globalQuestionConfig) {
      myConfig.configs.questionsConfig.globalQuestionConfig = {};
      myConfig.configs.questionsConfig.globalQuestionConfig.netDetails = {};
    }

    // set netDetails.top or bottom
    myConfig.configs.questionsConfig.globalQuestionConfig.netDetails = {
      ...myConfig.configs.questionsConfig.globalQuestionConfig.netDetails,
      [key]: +value === 0 ? [] : range(value).toString().split(',')
    };

    if (
      !rdConfig.configs.questionsConfig.globalQuestionConfig &&
      (!myConfig.configs.questionsConfig.globalQuestionConfig.netDetails[TOP] || (!value && key === TOP)) &&
      (!myConfig.configs.questionsConfig.globalQuestionConfig.netDetails[BOTTOM] || (!value && key === BOTTOM))
    ) {
      myConfig.configs.questionsConfig.globalQuestionConfig = rdConfig.configs.questionsConfig.globalQuestionConfig;
    }

    setState({ rdConfig: myConfig });
  };

  const setResponseDisplay = e => {
    const myConfig = cloneDeep(state.rdConfig);
    if (!myConfig.configs.questionsConfig.globalQuestionConfig) {
      myConfig.configs.questionsConfig.globalQuestionConfig = {};
    }
    if (e.target.value) {
      myConfig.configs.questionsConfig.globalQuestionConfig.displayOption = e.target.value;
    } else {
      if (!rdConfig.configs.questionsConfig.globalQuestionConfig) {
        myConfig.configs.questionsConfig.globalQuestionConfig = null;
      } else {
        delete myConfig.configs.questionsConfig.globalQuestionConfig.displayOption;
      }
    }
    setState({ rdConfig: myConfig });
  };

  const getResponseDisplay = () => {
    const displayOption = get(state, 'rdConfig.configs.questionsConfig.globalQuestionConfig.displayOption');
    return (
      <Input type="select" className="select" onChange={setResponseDisplay}>
        <option value="" key="default">
          -{intl.get('app.notSet')}-
        </option>
        <option value={COUNT} key={COUNT} selected={COUNT === displayOption}>
          {intl.get('app.rd.N')}
        </option>
        <option value={RESPONSE_RATE} key={RESPONSE_RATE} selected={RESPONSE_RATE === displayOption}>
          {intl.get('app.percentSign')}
        </option>
        <option value={COUNT_AND_RATE} key={COUNT_AND_RATE} selected={COUNT_AND_RATE === displayOption}>
          {intl.get('app.both')}
        </option>
      </Input>
    );
  };

  const getGlobalQuestionConfig = config => {
    const { top, bottom } = getNetConfig(config);
    return {
      ...config.configs.questionsConfig.globalQuestionConfig,
      netDetails: {
        top: range(top),
        bottom: range(bottom)
      }
    };
  };

  const saveToConfigs = () => {
    const temp = state.rdConfig;
    const myConfig = {
      ...temp,
      configs: {
        ...temp.configs,
        questionsConfig: {
          ...temp.configs.questionsConfig,
          globalQuestionConfig: getGlobalQuestionConfig(temp)
        }
      }
    };

    props.saveToConfigs({
      ...myConfig,
      projectId,
      configType: 'Project',
      shared: true
    });
  };

  const configsSessionName =
    sessions.length > 0 &&
    sessions.reduce((result, s) => {
      result[s.id] = s.name;
      return result;
    }, {});

  const getSavedConfigs = () => ({
    content: researchDashboardConfigs.filter(c => {
      if (!c.draft) {
        c.sessionName = configsSessionName[c.sessionId];
        return c;
      }
    })
  });

  const onSelectConfig = (e, configs) => {
    const name = e.target.value;
    const config = find(configs, c => c.name === name, null);
    setState({
      configToShare: config,
      nameToShare: name
    });
  };

  const getConfigsToShare = () => {
    const myConfigs = getSavedConfigs().content;
    return (
      <div className="survey-display-prefs">
        <input list="sharedConfigList" autocomplete="off" onChange={e => onSelectConfig(e, myConfigs)} />
        <datalist id="sharedConfigList">
          {myConfigs.map(c => {
            return (
              <option className="option" value={c.name} key={c.id}>
                {c.name}
              </option>
            );
          })}
        </datalist>
      </div>
    );
  };

  const shareConfig = () => {
    let id = idUtils.getId();
    if (state.configToShare) {
      if (state.configToShare.userId === userId) {
        // overwrite an existing config with new changes
        id = state.configToShare.id;
      }
    }
    props.shareConfig({
      sessionId,
      researchDashboardConfig: {
        ...state.rdConfig,
        id,
        name: state.nameToShare,
        projectId,
        configType: 'Project',
        shared: true,
        draft: false,
        userId
      }
    });
  };

  const onSelectConfigToImport = e => {
    setState({ configToImport: researchDashboardConfigs.find(c => c.id === e.target.value) });
  };

  const getSharedConfigs = () => {
    const sharedConfigs = researchDashboardConfigs.filter(c => {
      const { email = '' } = configOwners[c.userId] || {};
      if (c.shared) {
        c.sessionName = configsSessionName[c.sessionId];
        c.email = email;
        return c;
      }
    });
    if (sharedConfigs.length > 0) {
      return (
        <Input type="select" className="select" value={state.configToImport?.id} onChange={onSelectConfigToImport}>
          <option value="" disabled selected>
            {intl.get('app.rdConfigSelectPlaceholder')}
          </option>
          {sharedConfigs.map(c => (
            <option value={c.id} key={c.id}>
              {c.name} {c.email ? `[${c.email}]` : ''}
            </option>
          ))}
        </Input>
      );
    }
    return <p className="no-shared">{intl.get('app.noSharedConfig')}.</p>;
  };

  const importConfig = () => {
    const params = {
      configId: state.configToImport.id,
      sessionId
    };
    props.applyProjectConfig(params);
    setState({ importOrResetInProgress: true });
  };

  return (
    <div className="survey-display-prefs">
      {state.importOrResetInProgress && <Loader spinner fullScreen lightbox />}
      <p className="mt-3" style={{ marginBottom: '0' }}>
        {intl.get('app.surveyPref.message1')}
      </p>
      <p>{intl.get('app.surveyPref.message2')}</p>
      <table>
        <thead>
          <tr>
            <th>{intl.get('app.currentPreferences')}:</th>
            <td>{intl.get('app.lastUpdated')}:</td>
            <td>
              <span className="ms-4">{state.modifiedDate}</span>
            </td>
          </tr>
        </thead>
        <tbody className="mt-5">
          <tr className="mt-5">
            <td style={{ verticalAlign: 'top' }}>{intl.get('app.rd.globalNets')}</td>
            <td>
              <div>
                <label style={{ width: '50%' }}>{intl.get('app.top')}</label>
                <Input
                  style={{ width: '50%', display: 'inline-block' }}
                  className="input"
                  type="number"
                  value={getNetConfig(state.rdConfig)[TOP]}
                  onChange={e => onGlobalNetsChange(e.target.value, TOP)}
                />
              </div>
              <div>
                <label style={{ width: '50%' }}>{intl.get('app.bottom')}</label>
                <Input
                  style={{ width: '50%', display: 'inline-block' }}
                  className="input"
                  type="number"
                  value={getNetConfig(state.rdConfig)[BOTTOM]}
                  onChange={e => onGlobalNetsChange(e.target.value, BOTTOM)}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>{intl.get('app.rd.globalResponseDisplay')}</td>
            <td>{getResponseDisplay()}</td>
            <td className="save-and-reset-buttons">
              <Button color="primary" disabled={isPrefsNotChanged()} onClick={saveToConfigs}>
                {intl.get('app.save')}
              </Button>
              <Button color="secondary" onClick={onReset}>
                {intl.get('app.reset')}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginTop: '3rem', display: 'flex', flexDirection: 'column', width: '7rem' }}>
        <Button color="secondary" onClick={() => toggleModal('showResetModal')}>
          {intl.get('app.resetAll')}...
        </Button>
        <Button color="secondary" onClick={() => toggleModal('showShareModal')}>
          {intl.get('app.share')}...
        </Button>
        <Button color="secondary" onClick={() => toggleModal('showImportModal')}>
          {intl.get('app.import')}...
        </Button>
      </div>
      {state.showResetModal && (
        <InvokeModal
          showModal={state.showResetModal}
          toggle={() => toggleModal('showResetModal')}
          modalTitle={intl.get('app.reset')}
          save={() => resetAll(state.rdConfig)}
          cancelButtonText={intl.get(CANCLE_TEXT)}
          primaryButtonText={intl.get('app.reset')}
          enableSave
        >
          {intl.get('app.resetRDConfig')}
        </InvokeModal>
      )}
      {state.showShareModal && (
        <InvokeModal
          showModal={state.showShareModal}
          toggle={() => toggleModal('showShareModal')}
          modalTitle={intl.get('app.shareDisplayPreferences')}
          save={shareConfig}
          cancelButtonText={intl.get(CANCLE_TEXT)}
          primaryButtonText={intl.get('app.share')}
          enableSave
        >
          <p>{intl.get('app.sharePrefMessage')}:</p>
          {getConfigsToShare()}
        </InvokeModal>
      )}
      {state.showImportModal && (
        <InvokeModal
          showModal={state.showImportModal}
          toggle={() => toggleModal('showImportModal')}
          modalTitle={intl.get('app.importDisplayPreferences')}
          save={importConfig}
          cancelButtonText={intl.get(CANCLE_TEXT)}
          primaryButtonText={intl.get('app.import')}
          enableSave
        >
          <p>{intl.get('app.importPrefMessage')}:</p>
          {getSharedConfigs()}
        </InvokeModal>
      )}
    </div>
  );
};
