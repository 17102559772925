import React, { useEffect, useCallback, useMemo, useReducer } from 'react';
import { InvokeTable, Loader, usePagination } from 'webapp-common';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import DeleteDataTableModal from './DeleteDataTableModal';
import { UploadModal } from '../../../project/recruit/import/UploadModal';

import './DataTableEditor.css';

const reducer = (state, payload) => ({ ...state, ...payload });

export const DataTableEditor = props => {
  const {
    dataTable,
    dataTableId,
    dataTableRecords,
    pageSize,
    dataTableRecordsRequested,
    dataTableRequested,
    dataTableDeleted,
    fetchDataTableRecords,
    refetchDataTableList,
    replaceDataTable
  } = props;

  const { pageRequest = {}, totalElements } = dataTableRecords ?? {};

  const [state, setState] = useReducer(reducer, {
    showDeleteDataTableModal: false,
    showReplaceDataTableModal: false
  });

  const toggleReplaceDataTableModal = useCallback(() => {
    setState({ showReplaceDataTableModal: !state.showReplaceDataTableModal });
  }, [state.showReplaceDataTableModal]);

  useEffect(() => {
    fetchDataTableRecords({ dataTableId, pageSize });
    if (!dataTable) {
      props.fetchDataTable(dataTableId);
    }
  }, [dataTableId]);

  useEffect(() => {
    if (dataTableRecords) {
      if (state.showReplaceDataTableModal) {
        refetchDataTableList();
        toggleReplaceDataTableModal();
      }
    }
  }, [dataTableRecords]);

  useEffect(() => {
    if (dataTableDeleted) {
      refetchDataTableList();
      props.closeEditor('showDataEditorModal');
    }
  }, [dataTableDeleted]);

  const getTransformedKey = useCallback(
    key => {
      return dataTable?.keyTransformMap?.[key] || key;
    },
    [dataTable?.keyTransformMap]
  );

  const columns = useMemo(() => {
    if (!dataTable) {
      return [];
    }
    const columns = dataTable.valueColumnHeaders.map(key => ({
      accessorKey: `data.${key}`,
      header: getTransformedKey(key)
    }));
    columns.unshift({
      accessorKey: `data.${dataTable.uniqueColumnHeader}`,
      header: getTransformedKey(dataTable.uniqueColumnHeader)
    });
    return columns;
  }, [dataTable, getTransformedKey]);

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      const { pageSize } = pageRequest;
      fetchDataTableRecords({ pageNumber: 1, pageSize, sortBy, sortOrder, dataTableId });
    },
    [pageRequest, fetchDataTableRecords, dataTableId]
  );

  const pagination = usePagination({ pageRequest, totalElements });

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchDataTableRecords({ pageNumber, pageSize, sortBy, sortOrder, dataTableId });
    },
    [dataTableId, fetchDataTableRecords]
  );

  const toggleDeleteDataTableModal = useCallback(() => {
    setState({
      showDeleteDataTableModal: !state.showDeleteDataTableModal
    });
  }, [state.showDeleteDataTableModal]);

  const deleteDataTable = useCallback(() => {
    props.deleteDataTable({ dataTableId });
  }, [dataTableId, props.deleteDataTable]);

  const onReplaceSubmit = useCallback(
    params => {
      params.name = params.entityName;
      params.dataTableId = dataTableId;
      replaceDataTable(params);
    },
    [dataTableId, replaceDataTable]
  );

  if (dataTableRequested || !dataTable || !dataTableRecords) {
    return <Loader spinner />;
  }

  return (
    <>
      <div className="data-table-records-container">
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={dataTableRecords?.content ?? []}
          loading={dataTableRecordsRequested}
          pagination={pagination}
          onPaginationChange={paginate}
          onSortingChange={sort}
        />
      </div>
      <div>
        <Button className="btn-primary" onClick={toggleReplaceDataTableModal}>
          {intl.get('app.replaceData')}
        </Button>
      </div>
      <div>
        <Button className="btn-warn mt-2" onClick={toggleDeleteDataTableModal}>
          {intl.get('app.title.deleteData')}
        </Button>
      </div>
      {state.showReplaceDataTableModal && (
        <UploadModal
          entityName={dataTable.name}
          toggle={toggleReplaceDataTableModal}
          onSubmit={onReplaceSubmit}
          modalTitle={intl.get('app.replaceData')}
          cancelButtonText={intl.get('app.cancel')}
          primaryButtonText={intl.get('app.save')}
          uploadNameLabel={intl.get('app.dataTableName')}
        />
      )}
      {state.showDeleteDataTableModal && (
        <DeleteDataTableModal toggle={toggleDeleteDataTableModal} deleteDataTable={deleteDataTable} />
      )}
    </>
  );
};
