import React from 'react';
import { Input } from 'reactstrap';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { CommonJoinerEditFields } from '../../joinerEditor';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';
import MediaChoiceModal from '../../modals/mediaChoice/MediaChoiceModal';
import { ImportQuestionButton } from '../../modals/importQuestion/ImportQuestionButton';
import { ImportQuestionModal } from '../../modals/importQuestion/ImportQuestionModal';
import {
  RESPONSE_SET_TYPE,
  getChoiceValue,
  getStimCaption,
  setChoiceValues,
  getCustomChoice,
  ENGLISH
} from '../../../../../util/joinerUtil';
import { mediaUtil } from '../../../../../util/mediaUtil';
import { TranslateLabel } from './TranslateLabel';

const DEFAULT = 'DEFAULT';
const IDK = 'IDK';
const NOTA = 'NOTA';
const OTHER = 'OTHER';

class ChoiceEdit extends React.Component {
  state = {
    showSelectMediaModal: false,
    showImportQuestionModal: false
  };

  toggleSelectMediaModal = (choiceIndex, choiceStim) => {
    if (!this.props.readOnly) {
      this.setState({
        showSelectMediaModal: !this.state.showSelectMediaModal,
        choiceIndex,
        choiceStim
      });
    }
  };

  toggleImportQuestionModal = imported => {
    this.setState({ showImportQuestionModal: !this.state.showImportQuestionModal, imported });
  };

  onMediaUpdate = stim => {
    this.props.updateHandlers.updateMediaForChoiceQuestion(stim, this.state.choiceIndex);
    this.toggleSelectMediaModal();
  };

  updateChoiceValue = (e, index) => {
    const choice = cloneDeep(this.props.joiner.def.responseSet.choices[index]);
    setChoiceValues(this.props.language, this.props.viewLanguage, e.target.value, choice.value);
    this.props.updateHandlers.updateChoiceQuestionValue(choice, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateChoiceAbbrevValue = (e, index) => {
    const choice = cloneDeep(this.props.joiner.def.responseSet.choices[index]);
    choice.value.abbreviatedValue = e.target.value;
    this.props.updateHandlers.updateChoiceQuestionValue(choice, index);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateChoiceScalar = (e, index) => {
    const choice = cloneDeep(this.props.joiner.def.responseSet.choices[index]);
    choice.value.scalar = parseInt(e.target.value);
    this.props.updateHandlers.updateChoiceQuestionValue(choice, index);
  };

  addOrRemoveChoiceToQuestion = (index, bool) => {
    if (!this.props.readOnly) {
      this.props.updateHandlers.addOrRemoveChoiceToQuestion(index, bool);
    }
  };

  addOtherChoice = entryType => {
    const text = intl.get(`app.entryType.${entryType}.defaultText`);
    this.props.updateHandlers.addOtherChoice(entryType, text, this.props.language, this.props.viewLanguage);
  };

  getChoicesRows = choices => {
    const { language, viewLanguage, readOnly } = this.props;

    if (choices.filter(choice => choice.value.entryType === DEFAULT && !choice.value.disable).length === 0) {
      return (
        <div className="add-text mb-3" onClick={() => this.addOrRemoveChoiceToQuestion(-1, true)}>
          <span style={{ fontWeight: '600', fontSize: '1.5rem' }}>+</span>
          <span className="ms-4 mt-3">{intl.get('app.addAnotherChoice')}</span>
        </div>
      );
    }

    return choices
      .filter(choice => choice.value.entryType === DEFAULT && !choice.value.disable)
      .map(choice => {
        const index = parseInt(choice.index, 10);
        const choiceValue = getChoiceValue(choice, language, viewLanguage);
        const showRemove =
          choices.length > 1 ||
          (choice.value &&
            (choice.value.value ||
              choice.value.origValue ||
              choice.value.abbreviatedValue ||
              choice.value.imageStim ||
              choice.value.scalar));
        return (
          <div className="choice-row" key={choice.id}>
            {choice.value.imageStim ? (
              <React.Fragment>
                <Input value={getStimCaption(choice.value.imageStim, language, viewLanguage)} disabled />
                <div
                  className="media-input ms-2"
                  onClick={() => this.toggleSelectMediaModal(index, choice.value.imageStim)}
                >
                  {choice.value.imageStim.media ? (
                    <img className="image" src={mediaUtil.getMediaUrl(choice.value.imageStim.media)} alt="" />
                  ) : (
                    <i className="far fa-image image" />
                  )}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Input
                  key={`choice_${choice.id}`}
                  value={choiceValue}
                  onChange={e => this.updateChoiceValue(e, index)}
                  disabled={readOnly}
                />
                <div
                  className="media-input ms-2"
                  onClick={() => this.toggleSelectMediaModal(index, choice.value.imageStim)}
                >
                  <i className="far fa-image fa-2x image" />
                </div>
              </React.Fragment>
            )}
            <React.Fragment>
              <Input
                className="ms-2"
                value={this.state.imported && viewLanguage !== ENGLISH ? '' : choice.value.abbreviatedValue || ''}
                onChange={e => this.updateChoiceAbbrevValue(e, index)}
                disabled={readOnly}
              />
              <Input
                className="ms-2"
                type="number"
                value={parseInt(choice.value.scalar) || 0}
                onChange={e => this.updateChoiceScalar(e, index)}
                disabled={readOnly}
              />
            </React.Fragment>
            {!readOnly && (
              <AddRemoveIcons
                onAdd={() => this.addOrRemoveChoiceToQuestion(index, true)}
                onRemove={showRemove ? () => this.addOrRemoveChoiceToQuestion(index, false) : null}
              />
            )}
          </div>
        );
      });
  };

  getOtherChoiceRows = choices => {
    const { readOnly, viewLanguage, language } = this.props;
    return choices
      .filter(choice => choice.value.entryType !== DEFAULT && !choice.value.disable)
      .map((choice, index) => {
        return (
          <div className="other-choice-row" key={index}>
            <Input
              value={getCustomChoice(choice, language, viewLanguage)}
              onChange={e =>
                this.props.updateHandlers.updateOtherChoiceText(
                  choice.value.entryType,
                  e.target.value,
                  language,
                  viewLanguage
                )
              }
              disabled={readOnly}
            />
            {!readOnly && (
              <AddRemoveIcons onRemove={() => this.props.updateHandlers.removeOtherChoice(choice.value.entryType)} />
            )}
          </div>
        );
      });
  };

  getOtherInputOptions = choices => {
    const options = [];
    [IDK, NOTA, OTHER].forEach(entryType => {
      if (!choices.some(choice => choice.value.entryType === entryType && !choice.value.disable)) {
        options.push(
          <option value={entryType} key={entryType}>
            {intl.get('app.include')} "{intl.get(`app.entryType.${entryType}`)}"
          </option>
        );
      }
    });
    return options;
  };

  importQuestion = question => {
    this.props.updateHandlers.importQuestion(
      question,
      RESPONSE_SET_TYPE.multi,
      this.props.viewLanguage,
      this.props.language
    );
    this.toggleImportQuestionModal(true);
  };

  render() {
    const {
      joiner,
      tabs,
      readOnly,
      isScreener,
      isSessionOpen,
      insertOrEditAction,
      viewLanguage,
      language,
      setViewLanguage
    } = this.props;
    const { choices = [] } = joiner.def.responseSet;
    return (
      <div className={`${tabs.edit} choice-editor`}>
        <CommonJoinerEditFields {...this.props} />
        <div className="mb-2">
          <div className="label editor-left-column">{intl.get('app.choices')}</div>
          <div className="editor-right-column">
            {!isScreener && (
              <div className="choice-row">
                <div className="label-text pt-3">{intl.get('app.choices')}</div>
                <div className="label-text pt-3 me-1">{intl.get('app.media')}</div>
                <div className="label-text pt-3">{intl.get('app.choiceEdit.abbreviate')}</div>
                <div className="label-text pt-3">{intl.get('app.choiceEdit.value')}</div>
              </div>
            )}
            {this.getChoicesRows(choices)}
            <div className="mb-2">
              <Input
                type="select"
                className="other-input-select"
                value=""
                disabled={readOnly}
                required
                onChange={e => this.addOtherChoice(e.target.value)}
              >
                <option value="" disabled hidden>
                  {intl.get('app.choiceEdit.otherInputPlaceholder')}
                </option>
                {this.getOtherInputOptions(choices)}
              </Input>
            </div>
            {this.getOtherChoiceRows(choices)}
          </div>
        </div>
        <div className="import-and-language">
          <ImportQuestionButton
            readOnly={readOnly}
            isSessionOpen={isSessionOpen}
            insertOrEditAction={insertOrEditAction}
            toggleImportQuestionModal={this.toggleImportQuestionModal}
          />
          {language !== ENGLISH && (
            <TranslateLabel
              setViewLanguage={setViewLanguage}
              language={language}
              viewLanguage={viewLanguage}
              sessionId={this.props.sessionId}
            />
          )}
        </div>
        {this.state.showSelectMediaModal && (
          <MediaChoiceModal
            stim={this.state.choiceStim}
            language={language}
            viewLanguage={viewLanguage}
            readOnly={readOnly}
            toggle={this.toggleSelectMediaModal}
            onSave={this.onMediaUpdate}
          />
        )}
        {this.state.showImportQuestionModal && (
          <ImportQuestionModal
            toggle={this.toggleImportQuestionModal}
            modalTitle={intl.get('app.import.choiceQuestion')}
            questionType={RESPONSE_SET_TYPE.multi}
            joiner={joiner}
            importQuestion={this.importQuestion}
            isEnglish={language === viewLanguage}
          />
        )}
      </div>
    );
  }
}

export default ChoiceEdit;
