import { get, post } from '../util/request';

const EMOTION_SETTING_API = '/a/api/emotionsSettingConfig';

async function fetchEmotionSettingConfig({ joinerId, userId, sessionId }) {
  if (!joinerId || !sessionId || !userId) {
    return {};
  }
  const url = `${EMOTION_SETTING_API}/${userId}/${sessionId}/${joinerId}`;
  return get(url)().then(res => (res.body && res.body.payload) || {});
}

async function saveEmotionSettingConfig(config) {
  return post(EMOTION_SETTING_API)(config).then(res => {
    if (res.body && res.body.payload && res.body.payload.id) {
      return {
        ...res.body.payload
      };
    }
    return { dialSettingConfigSaveError: true };
  });
}

export { fetchEmotionSettingConfig, saveEmotionSettingConfig };
