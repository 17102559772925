import { take, takeRight } from 'lodash';
import { RESPONSE_SET_TYPE } from './joinerUtil';

function sortEntries(self, other) {
  const selfScalar = self.value.scalar;
  const otherScalar = other.value.scalar;
  if (selfScalar < otherScalar) {
    return 1;
  }
  if (selfScalar > otherScalar) {
    return -1;
  }
  return 0;
}

function getMatrixEntries(responseSet) {
  return responseSet.entries.columnData.columns
    .filter(entry => !entry.value.disable)
    .sort(sortEntries)
    .map(entry => entry.id);
}

function getChoiceEntries(responseSet) {
  return responseSet.choices
    .filter(entry => !(entry.value.disable || entry.value.common))
    .sort(sortEntries)
    .map(entry => entry.id);
}

function getEntries(responseSet) {
  if (responseSet.type === RESPONSE_SET_TYPE.matrix || responseSet.type === RESPONSE_SET_TYPE.ranked) {
    return getMatrixEntries(responseSet);
  }
  return getChoiceEntries(responseSet);
}

function getDefaultNetsConfig(choices, globalQuestionConfig) {
  if (!choices || !globalQuestionConfig) {
    return;
  }

  const defaultNetDetails = globalQuestionConfig.netDetails;
  if (!defaultNetDetails) {
    return;
  }

  const top = defaultNetDetails.top.length;
  const bottom = defaultNetDetails.bottom.length;

  const bottomChoices = takeRight(choices, bottom);
  const topChoices = take(choices, Math.min(choices.length - bottomChoices.length, top));
  return {
    top: topChoices,
    bottom: bottomChoices
  };
}

// Returns the netDetails from the specific question config if it exists.
// If not, converts the netDetails from the global questions config to
// contain the correct choice IDs for the question.
function getNetDetails(joiner, questionsConfig) {
  if (!joiner) {
    return;
  }

  const { type } = joiner.def.responseSet;
  if (type !== RESPONSE_SET_TYPE.matrix && type !== RESPONSE_SET_TYPE.multi && type !== RESPONSE_SET_TYPE.ranked) {
    return;
  }

  const questionConfig = questionsConfig.questionsConfigMap[joiner.def.id];
  if (questionConfig?.netDetails) {
    return questionConfig.netDetails;
  }

  const choices = getEntries(joiner.def.responseSet);
  const { globalQuestionConfig } = questionsConfig;
  return getDefaultNetsConfig(choices, globalQuestionConfig);
}

export const netsUtil = {
  getNetDetails
};
