import intl from 'react-intl-universal';
import { get } from 'lodash';
import { STIM_TYPE, getJoinerTitle, isBipolar } from '../../../../../util/joinerUtil';
import { getConceptAndJoinerTitles } from '../../../../../util/conceptRotationUtil';
import { mediaUtil } from '../../../../../util/mediaUtil';

const VALID_QUESTION_TYPES = ['open', 'multi', 'matrix', 'ranked'];

export function isSelectableQuestion(joiner) {
  const type = get(joiner, 'def.responseSet.type', '');
  return VALID_QUESTION_TYPES.indexOf(type) !== -1;
}

export function getTableData(flattenedJoiners) {
  // if there's a concept rotation, we need to insert the rotation object itself
  // along with its children (question joiners) to the list here.
  return flattenedJoiners.map(joiner => {
    let title = getJoinerTitle(joiner);
    if (joiner.conceptId) {
      const splitTitles = getConceptAndJoinerTitles({
        joiner,
        conceptRotationJoiner: flattenedJoiners.find(j => j.id === joiner.conceptRotationId)
      });
      title = splitTitles.joiner;
    }
    return {
      title,
      joinerId: joiner.id,
      questionAnswers: getAnswers(joiner),
      index: joiner.displayNumber,
      type: getType(joiner),
      conceptRotation: !!joiner.conceptRotation,
      media: getMediaInfo(joiner),
      selectable: isSelectableQuestion(joiner)
    };
  });
}

function getMediaInfo(questionJoiner) {
  if (questionJoiner.stim && !questionJoiner.thirdPartyStim && questionJoiner.stim.media) {
    const mediaType = questionJoiner.stim.media.type && questionJoiner.stim.media.type.toLowerCase();
    const mediaUrl = mediaUtil.getMediaUrl(questionJoiner.stim.media);
    return {
      mediaType,
      mediaUrl
    };
  }
  return null;
}

function isStimOnly(joiner) {
  return joiner.stimOnly || joiner.thirdPartyStim;
}

function getType(joiner) {
  if (joiner.conceptRotation) {
    return '';
  }
  return isStimOnly(joiner)
    ? intl.get(`app.questionType.${joiner.stim.type}`)
    : intl.get(`app.questionType.${joiner.def.responseSet.type}`);
}

function getAnswers(joiner) {
  return isStimOnly(joiner) ? getStimData(joiner) : getQuestionData(joiner);
}

function getStimData(joiner) {
  if (!isStimOnly(joiner)) {
    return;
  }
  const { type } = joiner.stim;
  const questionText =
    ((type === STIM_TYPE.image || type === STIM_TYPE.video) && joiner.stim.label) ||
    (type === STIM_TYPE.text && joiner.stim.contents) ||
    (type === STIM_TYPE.thirdparty && intl.get('app.questionType.thirdparty')) ||
    intl.get('app.stim');
  return {
    questionAnswers: [
      {
        questionText,
        answers: []
      }
    ]
  };
}

function getStim(choice) {
  return choice.value.imageStim ? mediaUtil.getMediaUrl(choice.value.imageStim.media) : null;
}

function getQuestionData(joiner) {
  if (joiner.conceptRotation) {
    return {
      questionAnswers: [
        {
          questionText: intl.get('app.questionType.conceptRotation'),
          helpText: intl.get('app.firstPosSelectorText'),
          title: intl.get('app.firstPosFilterTitle'),
          questionId: joiner.id,
          answers: joiner.conceptRotation.concepts.map(concept => {
            return {
              conceptRotation: true,
              value: concept.title,
              id: concept.id,
              abbr: concept.title
            };
          })
        }
      ]
    };
  }
  switch (joiner.def.responseSet.type) {
    case 'open':
      return {
        questionAnswers: [
          {
            questionText: joiner.def.question.prompt,
            questionId: joiner.def.id,
            title: joiner.researchPrompt
          }
        ]
      };
    case 'multi':
      return {
        questionAnswers: [
          {
            questionText: joiner.def.question.prompt,
            questionId: joiner.def.id,
            title: joiner.researchPrompt,
            answers: joiner.def.responseSet.choices
              .filter(choice => !choice.value.disable)
              .map(choice => {
                return {
                  value: choice.value.value || choice.value.descriptiveText,
                  id: choice.id,
                  abbr: choice.value.abbreviatedValue || choice.value.descriptiveText || choice.value.entryType,
                  media: getStim(choice)
                };
              })
          }
        ]
      };
    case 'ranked':
    case 'matrix':
      return {
        questionPrompt: joiner.def.question.prompt || joiner.researchPrompt,
        questionId: joiner.def.id,
        title: joiner.researchPrompt,
        questionAnswers: isBipolar(joiner.def.responseSet)
          ? joiner.def.responseSet.entries.bipolarRows
          : joiner.def.responseSet.entries.rows
              .filter(row => !row.value.disable)
              .map(row => {
                return {
                  abbr: row.value.abbreviatedValue,
                  questionId: row.id,
                  questionText: row.value.value,
                  title: joiner.researchPrompt,
                  media: getStim(row),
                  answers: joiner.def.responseSet.entries.columnData.columns
                    .filter(column => !column.value.disable)
                    .map(column => {
                      return {
                        value: column.value.value,
                        id: column.id,
                        abbr: column.value.abbreviatedValue,
                        media: getStim(column)
                      };
                    })
                };
              })
      };

    default:
      return {
        questionAnswers: [
          {
            questionText: joiner.def.question.prompt,
            answers: []
          }
        ]
      };
  }
}
