import { createSelector } from 'reselect';

const store = state => state.clientAdminUsers;
const selectUserId = (state, userId) => userId;

const clientAdminUsersSelector = () =>
  createSelector([store, selectUserId], (store, userId) => {
    return {
      ...store,
      user: store.users[userId] || {}
    };
  });

export { clientAdminUsersSelector };
