import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isEqual } from 'lodash';
import AddFunction from './AddFunction';

class FunctionModal extends React.Component {
  functionRuleRef = React.createRef();
  static defaultProps = { enableSave: false };
  state = { enableSave: this.props.enableSave };

  functionRuleChange = updatedFR => {
    const { name, functionCode, inputParams } = updatedFR;
    const validInputParams = inputParams.map(inputParam => inputParam.value).filter(Boolean).length;

    const { functionRule: { name: origName, inputParams: origInputParams, functionCode: origFunctionCode } = {} } =
      this.props;
    const enableSave =
      (!isEqual(origInputParams, inputParams) || name !== origName || functionCode !== origFunctionCode) &&
      validInputParams &&
      name?.trim() !== '' &&
      functionCode?.trim() !== '';

    this.setState({
      enableSave
    });
  };

  render() {
    const {
      showModal,
      deleteFunction,
      toggle,
      className,
      backdrop,
      keyboard,
      modalTitle,
      primaryButtonText,
      save,
      deleteButtonText,
      cancelButtonText,
      isEditable,
      functionRule: { name, inputParams, functionCode, id } = {}
    } = this.props;

    return (
      <Modal isOpen={showModal} toggle={toggle} className={className} backdrop={backdrop} keyboard={keyboard}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <AddFunction
            id={id}
            ref={this.functionRuleRef}
            name={name}
            inputParams={inputParams}
            functionCode={functionCode}
            isEditable={isEditable}
            onChange={this.functionRuleChange}
          />
        </ModalBody>
        <ModalFooter>
          <div className="me-auto p-2">
            {deleteButtonText && (
              <Button outline color="danger" className="pull-left" onClick={deleteFunction} disabled={!isEditable}>
                {deleteButtonText}
              </Button>
            )}
          </div>
          <div className="p-2">
            {cancelButtonText && (
              <Button className="link-button" onClick={toggle}>
                {cancelButtonText}
              </Button>
            )}
          </div>
          <div className="p-2">
            {primaryButtonText && (
              <button
                className="btn btn-primary"
                onClick={() => save(this.functionRuleRef.current.state)}
                disabled={!this.state.enableSave}
              >
                {primaryButtonText}
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FunctionModal;
