import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

export const UploadModal = props => {
  const {
    toggle,
    modalTitle = '',
    uploadNameLabel = intl.get('app.title.name'),
    primaryButtonText = intl.get('app.ok'),
    cancelButtonText = intl.get('app.cancel')
  } = props;

  const fileUploadField = useRef();
  const [entityName, setEntityName] = useState(props.entityName || '');
  const [dataFile, setDataFile] = useState({});

  function updateName(e) {
    setEntityName(e.target.value);
  }

  function handleSelectFileClick() {
    fileUploadField.current.click();
  }

  function onFileSelect(file) {
    if (file) {
      setDataFile(file);
    }
  }

  function onSubmit() {
    props.onSubmit({
      entityName,
      dataFile
    });
  }

  return (
    <InvokeModal
      showModal
      className="upload-participant-data-modal"
      toggle={toggle}
      modalTitle={modalTitle}
      cancelButtonText={cancelButtonText}
      primaryButtonText={primaryButtonText}
      enableSave={entityName && dataFile.name}
      save={onSubmit}
    >
      <label>{uploadNameLabel}</label>
      <Input type="text" value={entityName} onChange={updateName} />
      <Button onClick={handleSelectFileClick}>
        <span className="fa fa-upload" />
        {intl.get('app.uploadFile')}
      </Button>
      <div className={dataFile.name ? '' : 'invisible'}>
        <span className="fa fa-paperclip" /> {dataFile.name}
      </div>
      <input
        type="file"
        className="hidden"
        accept="text/csv, .csv"
        ref={fileUploadField}
        onChange={e => onFileSelect(e.target.files[0])}
      />
    </InvokeModal>
  );
};
