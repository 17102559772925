import { connect } from 'react-redux';
import participantListsSelector from '../../../../store/redux/selectors/participantListsSelector';
import {
  fetchParticipantListsActions,
  fetchParticipantCollectionJobsActions
} from '../../../../store/redux/actions/fetchParticipantLists';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';
import participantCollectionJobsSelector from '../../../../store/redux/selectors/participantCollectionJobsSelector';
import { SurveyParticipants } from './SurveyParticipants';

const mapDispatchToProps = dispatch => ({
  fetchParticipantLists: (storeId, projectId) =>
    dispatch(
      fetchParticipantListsActions.request({
        storeId,
        projectId
      })
    ),
  fetchParticipantCollectionJobs: params => dispatch(fetchParticipantCollectionJobsActions.request(params))
});

const mapStateToProps = (state, props) => {
  const listsSelector = participantListsSelector();
  const jobsSelector = participantCollectionJobsSelector();
  const { abilities } = sessionUserSelector(state);
  return {
    ...((props.projectId && listsSelector(state, props.projectId)) || {}),
    ...((props.projectId && jobsSelector(state, props.projectId)) || {}),
    hasProjectManage: abilities.hasProjectManage
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyParticipants);
