import { useQuery } from 'react-query';
import { fetchSessions } from '../api/sessionsApi';

/*
 * Custom hooks for fetching Sessions using react-query
 */

export const useSessionsQuery = props => {
  const { projectId, screener } = props;

  const queryKey = screener ? `screener-sessions` : `sessions`;

  return useQuery(
    `${queryKey}-${projectId}`,
    () =>
      fetchSessions({
        projectId,
        screener,
        pageNumber: props.pageNumber,
        pageSize: -1,
        sortBy: props.sortBy,
        sortOrder: props.sortOrder,
        includeResponseRates: props.includeResponseRates,
        includeMedia: props.includeMedia
      }),
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
      onSuccess: props.onSuccess
    }
  );
};
