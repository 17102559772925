import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';

/**
 * Generic Confirm Modal. Has some defaults for Confirm Delete, which is probably the most common use-case.
 */
export const ConfirmModal = props => {
  return (
    <InvokeModal
      showModal={props.showModal}
      modalTitle={props.modalTitle}
      toggle={props.toggle}
      primaryButtonText={props.primaryButtonText || intl.get('app.delete')}
      cancelButtonText={props.cancelButtonText || intl.get('app.cancel')}
      enableSave
      save={props.onConfirm}
    >
      {props.children}
    </InvokeModal>
  );
};
