import { handleActions, combineActions } from 'redux-actions';
import {
  fetchClientsActions,
  fetchClientActions,
  createClientActions,
  saveClientActions,
  uploadLogoActions,
  updateTenantLogoActions,
  fetchAllLicensesActions,
  updateTenantLicensesActions
} from '../actions/clientAdminActions';

const initialState = {
  clients: {
    content: [],
    pageRequest: {},
    totalElements: null
  },
  clientMap: {},
  licenses: [],
  fetchClientsInProgress: false,
  createClientInProgress: false,
  createClientSuccess: false,
  saveClientInProgress: false,
  saveClientSuccess: false,
  updateLogoInProgress: false,
  updateLicensesInProgress: false
};

const clientAdminReducer = handleActions(
  {
    // fetchClients

    [fetchClientsActions.triggered](state) {
      return { ...state, fetchClientsInProgress: true };
    },

    [fetchClientsActions.succeeded](state, { payload }) {
      return {
        ...state,
        clients: payload,
        fetchClientsInProgress: false
      };
    },

    [fetchClientsActions.failed](state) {
      return { ...state, fetchClientsInProgress: false };
    },

    // fetchClient

    [fetchClientActions.triggered](state) {
      return { ...state, fetchClientInProgress: true };
    },

    [fetchClientActions.succeeded](state, { payload }) {
      const returnObj = {
        ...state,
        fetchClientInProgress: false
      };
      if (payload) {
        const { clientMap } = state;
        clientMap[payload.clientID] = payload;
        returnObj.clientMap = clientMap;
      }
      return returnObj;
    },

    [fetchClientActions.failed](state) {
      return { ...state, fetchClientInProgress: false };
    },

    // createClient

    [createClientActions.triggered](state) {
      return {
        ...state,
        createClientInProgress: true,
        createClientSuccess: false
      };
    },

    [createClientActions.succeeded](state, { payload }) {
      const { clientMap } = state;
      clientMap[payload.clientID] = payload;
      return {
        ...state,
        clientMap,
        createClientInProgress: false,
        createClientSuccess: true
      };
    },

    [createClientActions.failed](state) {
      return { ...state, createClientInProgress: false };
    },

    // saveClient
    [saveClientActions.triggered](state) {
      return { ...state, saveClientInProgress: true, saveClientSuccess: false };
    },

    [saveClientActions.succeeded](state, { payload }) {
      const { clientMap } = state;
      clientMap[payload.clientID] = payload;
      return {
        ...state,
        clientMap,
        saveClientInProgress: false,
        saveClientSuccess: true
      };
    },

    [saveClientActions.failed](state) {
      return { ...state, saveClientInProgress: false };
    },

    // uploadLogo and updateTenantLogo are performed sequentially.
    // updateTenantLogo can be called by itself to reset the logo to the default.

    [combineActions(uploadLogoActions.triggered, updateTenantLogoActions.triggered)](state) {
      return { ...state, updateLogoInProgress: true };
    },

    [combineActions(uploadLogoActions.failed, updateTenantLogoActions.failed)](state) {
      return { ...state, updateLogoInProgress: false };
    },

    [updateTenantLogoActions.succeeded](state, { payload }) {
      const { clientMap } = state;
      clientMap[payload.clientID] = payload;
      return {
        ...state,
        clientMap,
        updateLogoInProgress: false
      };
    },

    // fetchLicenses

    [fetchAllLicensesActions.triggered](state) {
      return { ...state };
    },

    [fetchAllLicensesActions.succeeded](state, { payload }) {
      return {
        ...state,
        licenses: payload
      };
    },

    [fetchAllLicensesActions.failed](state) {
      return { ...state };
    },

    // update tenant licenses

    [updateTenantLicensesActions.triggered](state) {
      return { ...state, updateLicensesInProgress: true };
    },

    [updateTenantLicensesActions.succeeded](state, { payload }) {
      const { clientMap } = state;
      clientMap[payload.clientID] = payload;
      return {
        ...state,
        clientMap,
        updateLicensesInProgress: false
      };
    },

    [updateTenantLicensesActions.failed](state) {
      return { ...state, updateLicensesInProgress: false };
    }
  },

  initialState
);

export default clientAdminReducer;
