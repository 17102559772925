import React from 'react';
import { ENGLISH } from '../../../../../util/joinerUtil';
import { CommonJoinerEditFields } from '../../joinerEditor';
import { TranslateLabel } from './TranslateLabel';

class TextStimEdit extends React.Component {
  render() {
    const { language, setViewLanguage, viewLanguage } = this.props;
    return (
      <div className={`${this.props.tabs.edit} text-stim-edit`}>
        <CommonJoinerEditFields {...this.props} />
        {language !== ENGLISH && (
          <TranslateLabel
            classes="stim-text-translation"
            setViewLanguage={setViewLanguage}
            language={language}
            viewLanguage={viewLanguage}
            sessionId={this.props.sessionId}
          />
        )}
      </div>
    );
  }
}

export default TextStimEdit;
