import React, { useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { Switch } from 'antd';
import QuillEditor from '../../../../../components/quillEditor/QuillEditor';

import './SurveyQualification.css';

export const SurveyQualification = props => {
  const { qualification, readOnly, setQualification } = props;

  // Need this for the Quill editor, which updates asynchronously. Without this,
  // the update from the Quill editor uses a stale qualification object.
  const qualRef = useRef({});

  useEffect(() => {
    qualRef.current = { ...qualification };
  }, [qualification]);

  function handleEmailCollectChange(emailCollect) {
    setQualification({ ...qualification, emailCollect });
  }

  function handleEmailSendChange(emailSend) {
    setQualification({ ...qualification, emailSend });
  }

  function handleEmailSubjectChange(e) {
    setQualification({ ...qualification, emailSubject: e.target.value });
  }

  function handleEmailContentChange(e, ops, html) {
    if (qualification.emailContent) {
      setQualification({ ...qualification, emailContent: e.target.value });
    } else {
      setQualification({
        ...qualRef.current,
        emailRichText: e,
        emailRichContents: ops,
        emailHtml: html
      });
    }
  }

  return (
    <div className="survey-qualification">
      <div>
        <div>
          <Switch checked={qualification.emailCollect} onChange={handleEmailCollectChange} disabled={readOnly} />
        </div>
        <div>{intl.get('app.screener.collectEmail')}</div>
      </div>
      {qualification.emailCollect && (
        <>
          <div>
            <div>
              <Switch checked={qualification.emailSend} onChange={handleEmailSendChange} disabled={readOnly} />
            </div>
            <div>{intl.get('app.screener.sendEmail')}</div>
          </div>
          <div>
            <div />
            <div>
              {intl.get('app.screener.emailSubject')}:
              <Input
                value={qualification.emailSubject ?? ''}
                placeholder={intl.get('app.screener.emailSubject')}
                onChange={handleEmailSubjectChange}
                disabled={readOnly || !qualification.emailSend}
              />
            </div>
          </div>
          <div>
            <div />
            <div>
              {intl.get('app.screener.emailContent')}:
              {qualification.emailContent ? (
                <Input
                  type="textarea"
                  rows="6"
                  value={qualification.emailContent}
                  placeholder={intl.get('app.screener.emailContent')}
                  onChange={handleEmailContentChange}
                  disabled={readOnly || !qualification.emailSend}
                />
              ) : (
                <QuillEditor
                  id="qualification-editor"
                  contents={qualification.emailRichText}
                  richContents={qualification.emailRichContents}
                  readOnly={readOnly || !qualification.emailSend}
                  update={handleEmailContentChange}
                  placeholder={intl.get('app.screener.emailContent')}
                  containerStyle="email-quill-container"
                  editorStyle="email-quill-text"
                  getHtml={true}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
