import React from 'react';
import intl from 'react-intl-universal';
import { DataExport } from '../dataExport/DataExport';

// From ReportType.java
const reportType = {
  EMOTION_RAW_DATA: 'EMOTION_RAW_DATA',
  EMOTION_CHART: 'EMOTION_CHART'
};

const getReportType = type => {
  switch (type) {
    case reportType.EMOTION_RAW_DATA:
      return intl.get('app.reportType.emotionRawData');
    case reportType.EMOTION_CHART:
      return intl.get('app.reportType.emotionPresentation');
    default:
      return '';
  }
};

export const EmotionDataExport = props => {
  const {
    joiner,
    sessionId,
    sessionName,
    exportData,
    reportFiles,
    exportDataInProgress,
    fetchReportList,
    filteredParticipants,
    selectedEmotions,
    selectedFilters,
    dataOutputType
  } = props;

  function getReportConfig() {
    return {
      config: {
        type: 'emotionReportConfig',
        sessionName: sessionName,
        sessionId: sessionId,
        joinerId: joiner.id,
        selectedEmotions
      }
    };
  }

  return (
    <DataExport
      sessionId={sessionId}
      sessionName={sessionName}
      exportData={exportData}
      reportFiles={reportFiles}
      exportDataInProgress={exportDataInProgress}
      fetchReportList={fetchReportList}
      filteredParticipants={filteredParticipants}
      selectedFilters={selectedFilters}
      reportConfig={getReportConfig()}
      chartReportType={reportType.EMOTION_CHART}
      rawReportType={reportType.EMOTION_RAW_DATA}
      dataOutputType={dataOutputType}
      getReportType={getReportType}
    />
  );
};
