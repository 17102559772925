import { handleActions, combineActions } from 'redux-actions';
import {
  rdConfigActions,
  fetchDraftRDConfigActions,
  saveCompleteRDConfigActions,
  saveRDConfigAction,
  fetchRDConfigsListAction,
  deleteRDConfigAction,
  shareRDConfigAction,
  applyProjectRDConfigActions,
  applyRDConfigTypeAction,
  wsSaveRdConfigResponseAction,
  wsUpdateRDConfigTypeAction
} from '../../actions/researchDashboardActions';

const initialState = {};

export const rdConfigReducer = handleActions(
  {
    [rdConfigActions.succeeded](state, { payload }) {
      const { sessionId, rdConfig } = payload;
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig
        },
        rdConfigsApiRequested: false
      };
    },

    [wsSaveRdConfigResponseAction.succeeded](state, { payload }) {
      const { rdConfig } = payload;
      const sessionId = rdConfig.sessionId;
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig
        },
        rdConfigsApiRequested: false
      };
    },

    [wsUpdateRDConfigTypeAction.succeeded](state, { payload }) {
      const { researchDashboardConfigs, configOwners, sessionId } = payload;
      const rdConfig = researchDashboardConfigs.filter(c => c.draft)[0];
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig,
          configOwners,
          researchDashboardConfigs
        },
        rdConfigsApiRequested: false
      };
    },

    [combineActions(
      shareRDConfigAction.triggered,
      fetchRDConfigsListAction.triggered,
      saveRDConfigAction.triggered,
      deleteRDConfigAction.triggered,
      applyRDConfigTypeAction.triggered
    )](state) {
      return { ...state, rdConfigsApiRequested: true };
    },

    [combineActions(fetchRDConfigsListAction.succeeded, saveRDConfigAction.succeeded, deleteRDConfigAction.succeeded)](
      state,
      { payload }
    ) {
      const { configOwners, researchDashboardConfigs, sessionId } = payload;
      const rdConfig = researchDashboardConfigs.filter(c => c.draft)[0];
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig,
          configOwners,
          researchDashboardConfigs
        },
        rdConfigsApiRequested: false
      };
    },

    [shareRDConfigAction.succeeded](state, { payload }) {
      const { configOwners, researchDashboardConfigs, sessionId, configName } = payload;
      const rdConfig = researchDashboardConfigs.filter(c => c.name === configName && !c.draft)[0];
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig,
          configOwners,
          researchDashboardConfigs
        },
        rdConfigsApiRequested: false
      };
    },

    [combineActions(
      saveRDConfigAction.failed,
      fetchRDConfigsListAction.failed,
      deleteRDConfigAction.failed,
      shareRDConfigAction.failed
    )](state, error) {
      return {
        ...state,
        error,
        rdConfigsApiRequested: false
      };
    },

    [saveCompleteRDConfigActions.triggered](state) {
      return { ...state, rdConfigSaveInProgress: true };
    },

    [saveCompleteRDConfigActions.succeeded](state, { payload }) {
      const { sessionId } = payload;
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig: payload
        },
        rdConfigSaveInProgress: false
      };
    },

    [saveCompleteRDConfigActions.failed](state) {
      return { ...state, rdConfigSaveInProgress: false };
    },

    [applyProjectRDConfigActions.succeeded](state, { payload }) {
      const { sessionId } = payload;
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig: payload
        }
      };
    },

    [fetchDraftRDConfigActions.triggered](state) {
      return { ...state, rdConfigsApiRequested: true };
    },

    [fetchDraftRDConfigActions.succeeded](state, { payload }) {
      const { sessionId } = payload;
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          rdConfig: payload
        },
        rdConfigsApiRequested: false
      };
    },

    [fetchDraftRDConfigActions.failed](state) {
      return { ...state, rdConfigsApiRequested: false };
    }
  },
  initialState
);
