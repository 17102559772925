import React from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Row } from 'reactstrap';
import validator from 'validator';
import { InvokeModal } from 'webapp-common';
import { MEDIA_TYPE } from '../../util/joinerUtil';
import { mediaUtil } from '../../util/mediaUtil';
import { toast } from '../../util/toast';
import { urlUtil } from '../../util/urlUtil';
import MediaPreview from '../../components/core/mediaPreview/MediaPreview';

import './AddOrEditMediaModal.css';

// These are also defined in LewisBinaryServiceImpl.java
const IMAGE_FILE_EXTS = {
  jpeg: true,
  jpg: true,
  jpe: true,
  png: true,
  gif: true
};

// These are also defined in LewisBinaryServiceImpl.java
const VIDEO_FILE_EXTS = {
  mp4: true,
  mp4v: true,
  mpg4: true,
  m3u8: true,
  mpd: true,
  mov: true
};

const VALID_MEDIA_TYPES = {
  'image/jpeg': true,
  'image/png': true,
  'image/gif': true,
  'video/mp4': true,
  'application/x-mpegURL': true,
  'application/vnd.apple.mpegurl': true,
  'application/dash+xml': true,
  'video/quicktime': true
};

const domainVerified = (accessUrl, whitelistedDomains) => {
  const { host } = urlUtil.parseURL(accessUrl);
  const domains = whitelistedDomains.split(',').map(d => d.trim());
  const found = domains.some(domain => host.endsWith(domain));
  if (host && !found) {
    toast.error({ text: intl.get('app.url.whitelist.error', { domainName: host }) });
  }
  return found;
};

class AddOrEditMediaModal extends React.Component {
  constructor(props) {
    super(props);
    const { mode, selectedMedia } = this.props;
    if (mode === 'edit' && selectedMedia) {
      this.state = {
        ...selectedMedia
      };
    } else {
      this.state = {
        accessUrl: '',
        title: '',
        type: ''
      };
    }
    this.fileUploadField = React.createRef();
  }

  updateUrl = accessUrl => {
    this.setState({
      accessUrl,
      fileName: accessUrl,
      type: this.getMediaTypeByUrl(accessUrl)
    });
  };

  updateTitle = e => {
    this.setState({
      title: e.target.value
    });
  };

  onUrlPaste = e => {
    const accessUrl = e.clipboardData.getData('text/plain');
    e.preventDefault();
    e.stopPropagation();
    this.validateAndUpdateUrl(accessUrl);
  };

  onUrlChange = e => {
    const accessUrl = e.target.value.trim();
    if (!accessUrl) {
      // Reset state
      this.updateUrl('');
    } else if (e.key === 'Enter') {
      this.validateAndUpdateUrl(accessUrl);
    } else {
      this.updateUrl(accessUrl);
    }
  };

  onUrlBlur = e => {
    const accessUrl = e.target.value.trim();
    this.validateAndUpdateUrl(accessUrl);
  };

  isValidUrl = url => {
    return validator.isURL(url, { protocols: ['https'], require_protocol: true });
  };

  validateAndUpdateUrl = accessUrl => {
    if (accessUrl.indexOf('http:') === 0) {
      return toast.error({ text: intl.get('app.url.https.validation.error') });
    }
    if (accessUrl.length > 0 && !this.isValidUrl(accessUrl)) {
      return toast.error({ text: intl.get('app.url.validation.error') });
    }
    this.updateUrl(accessUrl);
  };

  handleSelectFileClick = () => {
    this.fileUploadField.current.click();
  };

  onFileSelect = e => {
    const file = e.target.files[0];
    if (file) {
      if (!VALID_MEDIA_TYPES[file.type]) {
        return toast.error({ text: intl.get('app.media.invalidFileTypeError') });
      }
      this.setState({
        file,
        type: this.getMediaTypeByFile(file)
      });
    }
  };

  getMediaTypeByFile = file => {
    return file.type.indexOf('image') === 0 ? MEDIA_TYPE.image : MEDIA_TYPE.video;
  };

  getMediaTypeByUrl = accessUrl => {
    if (accessUrl) {
      const ext = accessUrl.split('.').pop().toLowerCase();
      if (IMAGE_FILE_EXTS[ext]) {
        return MEDIA_TYPE.image;
      }
      if (VIDEO_FILE_EXTS[ext]) {
        return MEDIA_TYPE.video;
      }
      if (accessUrl.startsWith('https')) {
        return MEDIA_TYPE.webcontent;
      }
    }
    return '';
  };

  isLinkedUrl = () => {
    const { accessUrl, fileName } = this.state;
    return accessUrl === fileName && accessUrl.match(/^https/);
  };

  onSave = () => {
    const { mode, selectedMedia } = this.props;
    if (mode === 'edit' && selectedMedia && selectedMedia.type !== this.state.type) {
      toast.error({ text: intl.get('app.cantChangeMediaType', { type1: selectedMedia.type, type2: this.state.type }) });
      return;
    }
    if (this.state.file) {
      this.props.handleMultiUpload(this.state.file, this.state.title, this.state._id);
    } else {
      this.props.saveMedia(this.state);
      this.props.toggle();
    }
  };

  isUploadDisabled = (filename, accessUrl) => {
    const { mode, selectedMedia } = this.props;
    if (mode === 'edit' && selectedMedia.type === 'WebContent') {
      return true;
    }
    if (!filename && !!accessUrl) {
      return true;
    }
    if (mediaUtil.isMediaConvertInProgress(this.state)) {
      return true;
    }
    return false;
  };

  render() {
    const { mode, selectedMedia, toggle } = this.props;
    const { file, accessUrl, type, fileName, title, mediaConvertStatus } = this.state;
    const saveEnabled = (file || this.isValidUrl(accessUrl)) && title;
    const modalTitle = mode === 'add' ? intl.get('app.title.addMedia') : intl.get('app.title.editMedia');
    const filename = file?.name || (mode === 'edit' && !this.isLinkedUrl() && fileName);
    const typeDisplay = (type && intl.get(`app.stim.${type.toLowerCase()}`)) || '';
    const uploadDisabled = this.isUploadDisabled(filename, accessUrl);

    return (
      <InvokeModal
        showModal
        className="add-or-edit-media-modal"
        toggle={toggle}
        modalTitle={modalTitle}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        save={this.onSave}
        enableSave={saveEnabled}
      >
        <Row>
          <div className="label">{intl.get('app.uploadFile')}</div>
          <div>
            <Button disabled={uploadDisabled} onClick={this.handleSelectFileClick}>
              <span className="fa fa-upload" /> {intl.get('app.uploadFile')}
            </Button>
            {mediaUtil.isMediaConvertInProgress(this.state) && (
              <span style={{ marginLeft: '1rem' }}>{mediaUtil.getMediaConvertInProgressMsg(mediaConvertStatus)}</span>
            )}
          </div>
        </Row>
        {filename && (
          <Row>
            <div className="label" />
            <div>
              <i className="fa fa-paperclip" /> {filename}
            </div>
          </Row>
        )}
        {!filename && (
          <Row>
            <div className="label">{intl.get('app.orSpecifyURL')}</div>
            <div>
              <Input value={accessUrl} onChange={this.onUrlChange} onPaste={this.onUrlPaste} onBlur={this.onUrlBlur} />
            </div>
          </Row>
        )}
        <Row className="preview-row">
          <div className="label">{intl.get('app.preview')}</div>
          <div>
            {type !== MEDIA_TYPE.webcontent && <MediaPreview mediaType={type} media={selectedMedia} file={file} />}
            {type === MEDIA_TYPE.webcontent && (
              <div className="webcontent-preview">
                {domainVerified(accessUrl, this.props.whitelistedDomains) && (
                  <iframe src={accessUrl} title={accessUrl} />
                )}
              </div>
            )}
          </div>
        </Row>
        <Row>
          <div className="label">{intl.get('app.type')}</div>
          <div>{typeDisplay}</div>
        </Row>
        <Row>
          <div className="label">{intl.get('app.title')}</div>
          <div>
            <Input value={title} onChange={this.updateTitle} />
          </div>
        </Row>
        <input
          type="file"
          className="hidden"
          accept="image/jpeg, image/png, image/gif, video/mp4, application/x-mpegURL, application/vnd.apple.mpegurl, application/dash+xml, video/quicktime, .jpeg, .jpg, .jpe, .png, .gif, .mp4, .mp4v, .mpg4, .m3u8, .mpd, .mov"
          ref={this.fileUploadField}
          onChange={this.onFileSelect}
        />
      </InvokeModal>
    );
  }
}

export default AddOrEditMediaModal;
