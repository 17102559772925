import createFetchActions, { createWebSocketSendActions, createWebSocketReceiveActions } from './createFetchActions';
import { createAction } from 'redux-actions';

const fetchSurveyTemplatesActions = createFetchActions('FETCH_SURVEY_TEMPLATES');
const fetchSurveyActions = createFetchActions('FETCH_SURVEY');
const createSurveyTemplateActions = createFetchActions('CREATE_SURVEY_TEMPLATE');
const saveQuestionJoinerActions = createFetchActions('SAVE_QUESTION_JOINER');
const surveyRefreshed = createAction('SURVEY_REFRESHED');
const addNewSurveyActions = createFetchActions('ADD_NEW_SURVEY');
const saveSurveyActions = createFetchActions('SAVE_SURVEY');
const deleteSurveyActions = createFetchActions('DELETE_SURVEY');
const deleteQuestionJoinersActions = createFetchActions('DELETE_QUESTIONS_FOR_SURVEY');
const generateSurveyContentReviewActions = createFetchActions('GENERATE_SURVEY_CONTENT_REVIEW');
const generateSurveyRawExportActions = createFetchActions('GENERATE_SURVEY_RAW_EXPORT');
const saveSurveyAsTemplateActions = createFetchActions('SAVE_SURVEY_AS_TEMPLATE');
const importSurveyAction = createFetchActions('IMPORT_SURVEY');
const uploadTranslationsAction = createFetchActions('UPLOAD_TRANSLATIONS');

const surveyImportSubscribeAction = createWebSocketSendActions('SURVEY_IMPORT_CHANNEL_SUBSCRIBE');
const newSurveyImportAction = createWebSocketReceiveActions('NEW_SURVEY_IMPORT_DATA');

export {
  fetchSurveyTemplatesActions,
  createSurveyTemplateActions,
  fetchSurveyActions,
  saveQuestionJoinerActions,
  surveyRefreshed,
  addNewSurveyActions,
  saveSurveyActions,
  deleteSurveyActions,
  generateSurveyContentReviewActions,
  generateSurveyRawExportActions,
  saveSurveyAsTemplateActions,
  deleteQuestionJoinersActions,
  importSurveyAction,
  uploadTranslationsAction,
  surveyImportSubscribeAction,
  newSurveyImportAction
};
