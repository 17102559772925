import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { cloneDeep, find, isEqual } from 'lodash';
import { Button } from 'reactstrap';

function getFilterTerm({ field, value }) {
  return {
    field,
    fieldPrefix: 'P',
    operator: ':',
    type: 'term',
    value,
    valuePrefix: 'A'
  };
}

/*
 * Returns a flattened version of the QuestionDataDefList, applying column config
 * (custom column order, renamed columns, hidden columns) if it exists.
 * Mainly used for building filters.
 */
function getFlattenedQuestionDataDefList(enrolleeDataDef, participantDataColumnConfig = {}) {
  const { columnPositions = [], renamedColumns = {}, hiddenColumns = {} } = participantDataColumnConfig;

  let columnData = [];

  if (!enrolleeDataDef) {
    return columnData;
  }

  const metadataDefIds = Object.keys(enrolleeDataDef);
  if (metadataDefIds.length === 0) {
    return columnData;
  }

  const metadataDefId = metadataDefIds[0];
  const metadataDef = enrolleeDataDef[metadataDefId];
  const questionDataDefList = metadataDef.questionDataDefList || [];
  const uniqueCols = metadataDef.uniqueIdCols || [];
  const hiddenColumnData = [];
  questionDataDefList.forEach(qDef => {
    const { value } = qDef || {};
    if (uniqueCols.indexOf(qDef.varId) !== -1) {
      // don't include IDs in the uniqueIdCols list
    } else if (value.rowInfo && value.columnInfo) {
      value.rowInfo.forEach(row => {
        const copy = cloneDeep(qDef);
        copy.varId = row.varId;
        copy.questionTitle = renamedColumns[row.varId] || `${qDef.questionTitle} - ${row.answerText}`;
        copy.value = value.columnInfo;
        if (hiddenColumns[row.varId]) {
          hiddenColumnData.push(copy);
        } else {
          columnData.push(copy);
        }
      });
    } else if (value?.length > 0) {
      if (qDef.type === 'MULTI') {
        // Answer values for MULTI questions don't work as-is for filters, so we need to add a filterValue field
        // that can be used when building filters. Multi values are always Yes/No, so selecting this answer in
        // the filter builder is the equivalent of selecting 'Yes'.
        value.forEach(v => {
          v.filterValue = 'Yes';
        });
      }
      qDef.questionTitle = renamedColumns[qDef.varId] || qDef.questionTitle;
      if (hiddenColumns[qDef.varId]) {
        hiddenColumnData.push(qDef);
      } else {
        columnData.push(qDef);
      }
    }
  });

  // Sort the data based on the column config (if it exists)
  if (columnPositions && columnPositions.length) {
    const sorted = columnPositions.map(colId => find(columnData, { varId: colId })).filter(qDef => qDef);
    if (sorted.length === columnData.length) {
      // Apply the sorted data only if nothing has been dropped
      columnData = sorted;
    }
  }

  // Return the column data with hidden columns at the end
  return [...columnData, ...hiddenColumnData];
}

export const ParticipantDataSelector = props => {
  const { enrolleeDataDef, participantDataColumnConfig, segmentCategories, mode, selectedFilterTerms } = props;

  const [participantData, setParticipantData] = useState([]);
  const [selectedRows, updateSelectedRows] = useState(new Set());

  // Initialize the data
  useEffect(() => {
    setParticipantData(getFormattedData());
  }, []);

  // Initialize the selected rows
  useEffect(() => {
    const selectedRows = new Set();
    participantData.forEach(data => {
      data.values.forEach(entry => {
        if (isTermSelected(entry.term)) {
          selectedRows.add(data.key);
        }
      });
    });
    updateSelectedRows(selectedRows);
  }, [participantData]);

  function getFormattedData() {
    const questionDataDefList = getFlattenedQuestionDataDefList(enrolleeDataDef, participantDataColumnConfig);
    const data = [];
    questionDataDefList.forEach(qDef => {
      if (
        mode !== 'screener' ||
        (mode === 'screener' && segmentCategories.some(segCat => segCat.name === qDef.questionTitle))
      ) {
        const values = qDef.value.map(v => ({
          valueText: v.answerText,
          term: getFilterTerm({
            field: v.varId || qDef.varId,
            value: v.filterValue || v.answerText
          })
        }));
        data.push({
          key: qDef.varId,
          label: qDef.questionTitle,
          values
        });
      }
    });
    return data;
  }

  function isTermSelected(term) {
    return selectedFilterTerms.some(t => isEqual(t, term));
  }

  function addOrRemoveFilterTerm(e, term, rowKey) {
    e.stopPropagation();
    if (!isTermSelected(term) && !selectedRows.has(rowKey)) {
      // If we are adding a term, make sure the row is selected.
      updateSelectedRows(new Set(selectedRows).add(rowKey));
    }
    props.addOrRemoveFilterTerm(term);
  }

  function handleRowClick(key) {
    const copy = new Set(selectedRows);
    if (copy.has(key)) {
      copy.delete(key);
    } else {
      copy.add(key);
    }
    updateSelectedRows(copy);
  }

  function getValueElems(data, rowKey) {
    const elems = [];
    let hasSelected = false;
    data.values.forEach(entry => {
      const key = entry.valueText;
      const isSelectedTerm = isTermSelected(entry.term);
      hasSelected = isSelectedTerm || hasSelected;
      const classes = {
        selected: isSelectedTerm,
        'link-button': true
      };
      if (elems.length) {
        elems.push(<span> | </span>);
      }
      elems.push(
        <Button className={classnames(classes)} onClick={e => addOrRemoveFilterTerm(e, entry.term, rowKey)} key={key}>
          {entry.valueText}
        </Button>
      );
    });
    return { hasSelected, elems };
  }

  function getRows() {
    return participantData.map(data => {
      const isSelectedRow = selectedRows.has(data.key);
      const values = getValueElems(data, data.key);
      const clickable = !values.hasSelected;
      const valuesCellClasses = {
        'text-truncate': !isSelectedRow,
        'answers-container': true
      };
      return (
        <tr
          className={classnames({ clickable })}
          onClick={clickable ? () => handleRowClick(data.key) : () => {}}
          key={data.key}
        >
          <td className="participant-data-label" title={data.label}>
            {data.label}
          </td>
          <td>
            <div className={classnames(valuesCellClasses)}>{values.elems}</div>
          </td>
        </tr>
      );
    });
  }

  return (
    <table className="invoke-table">
      <thead>
        <tr>
          <th style={{ width: '15rem' }}>{intl.get('app.label')}</th>
          <th style={{ width: '100%' }}>{intl.get('app.values')}</th>
        </tr>
      </thead>
      <tbody>{getRows()}</tbody>
    </table>
  );
};
