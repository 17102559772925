import React from 'react';
import intl from 'react-intl-universal';
import { find, get } from 'lodash';
import { Input } from 'reactstrap';
import {
  RESPONSE_SET_TYPE,
  ANSWER_TYPE,
  MATRIX_TYPE,
  SURVEY_RULE_TYPE,
  OPTION_TYPE,
  OPERATORS,
  isBipolar
} from '../../../../../../util/joinerUtil';
import { getNonPrefixedJoinerTitle } from '../../../../../../util/conceptRotationUtil';

const QuestionCondition = props => {
  const { activeJoiner, rule, ruleIndex, condition, conditionIndex, joiners, updateHandlers, readOnly } = props;

  const getOpenValueSelector = () => {
    if (condition.operator === OPERATORS.isGibberish) {
      return null;
    }
    return (
      <Input
        type="text"
        value={condition.values[0] || ''}
        disabled={readOnly}
        onChange={e => updateHandlers.updateRuleConditionValues(ruleIndex, conditionIndex, e.target.value, true)}
      />
    );
  };

  const getPromptValue = choiceValue => {
    return choiceValue.type === 'imagelabel' ? choiceValue.imageStim.caption : choiceValue.value;
  };

  const getMultiValueSelector = joiner => {
    const { responseSet } = joiner.def;
    const { operator } = condition;
    const choices = responseSet.choices.filter(choice => !choice.value.disable && getPromptValue(choice.value));
    const singleConditionValue =
      operator === OPERATORS.eq || operator === OPERATORS.ne || operator === OPERATORS.doesNotContain;
    const inputType = singleConditionValue ? 'radio' : 'checkbox';
    return (
      <div className="choice-checkboxes">
        {choices.map(choice => (
          <label key={choice.id} disabled={readOnly}>
            <Input
              type={inputType}
              name={`condition_${ruleIndex}_${conditionIndex}`}
              checked={condition.values.indexOf(choice.id) !== -1}
              onChange={() =>
                updateHandlers.updateRuleConditionValues(ruleIndex, conditionIndex, choice.id, singleConditionValue)
              }
            />
            <span title={getPromptValue(choice.value)}>{getPromptValue(choice.value)}</span>
          </label>
        ))}
      </div>
    );
  };

  const getMatrixValueSelector = joiner => {
    const { responseSet } = joiner.def;
    const { operator } = condition;
    const columns = responseSet.entries.columnData.columns.filter(
      column => !column.value.disable && getPromptValue(column.value)
    );
    const singleConditionValue =
      operator === OPERATORS.eq || operator === OPERATORS.ne || operator === OPERATORS.doesNotContain;
    const inputType = singleConditionValue ? 'radio' : 'checkbox';
    return (
      <div className="choice-checkboxes">
        {columns.map(column => (
          <label key={column.id} disabled={readOnly}>
            <Input
              type={inputType}
              name={`condition_${ruleIndex}_${conditionIndex}`}
              checked={condition.values.indexOf(column.id) !== -1}
              onChange={() =>
                updateHandlers.updateRuleConditionValues(ruleIndex, conditionIndex, column.id, singleConditionValue)
              }
            />
            <span title={getPromptValue(column.value)}>{getPromptValue(column.value)}</span>
          </label>
        ))}
      </div>
    );
  };

  const getCRJoiners = () => {
    const crJoiner = joiners.find(joiner => joiner.id === activeJoiner.conceptRotationId);
    return (crJoiner && crJoiner.conceptRotation.concepts[0].joiners) || [];
  };

  /*
   * Return the joiner the condition is acting upon. It may be the active joiner being edited,
   * or a joiner from the list of joiners.
   */
  const getJoinerForCondition = () => {
    if (activeJoiner.id === condition.joinerId) {
      return activeJoiner;
    }
    const crJoiners = getCRJoiners();
    return crJoiners.length
      ? find(crJoiners, j => j.parentId === condition.parentId)
      : find(joiners, j => j.id === condition.joinerId);
  };

  const getValueSelector = () => {
    const joiner = getJoinerForCondition();
    if (!joiner || !joiner.def || !condition.operator) {
      return null;
    }
    const { type: responseSetType } = joiner.def.responseSet;
    if (responseSetType === RESPONSE_SET_TYPE.open) {
      return getOpenValueSelector();
    }
    if (responseSetType === RESPONSE_SET_TYPE.multi) {
      return getMultiValueSelector(joiner);
    }
    if (responseSetType === RESPONSE_SET_TYPE.matrix) {
      return getMatrixValueSelector(joiner);
    }
    return null;
  };

  const getOptionsForCRJoiner = () => {
    const options = [];
    const crJoiners = getCRJoiners();
    for (let i = 0; i < crJoiners.length; i++) {
      const joiner = crJoiners[i];
      const type = get(joiner, 'def.responseSet.type');
      if (
        joiner.id === activeJoiner.id &&
        (rule.type === SURVEY_RULE_TYPE.includeQuestionRule || rule.type === SURVEY_RULE_TYPE.setVariableRule)
      ) {
        // Can only select previous joiners
        break;
      }
      if (type === RESPONSE_SET_TYPE.multi || type === RESPONSE_SET_TYPE.open || type === RESPONSE_SET_TYPE.matrix) {
        options.push(
          <option value={joiner.parentId} key={joiner.parentId} data-concept={true}>
            {getNonPrefixedJoinerTitle(joiner)}
          </option>
        );
      }
      if (joiner.id === activeJoiner.id) {
        // Can only select previous and current joiner
        break;
      }
    }
    return options;
  };

  const getJoinerSelectList = () => {
    const options = [];
    if (activeJoiner.conceptRotationId) {
      return getOptionsForCRJoiner();
    } else {
      for (let i = 0; i < joiners.length; i++) {
        const joiner = joiners[i];
        const type = get(joiner, 'def.responseSet.type');
        if (joiner.id === activeJoiner.id && rule.type === SURVEY_RULE_TYPE.includeQuestionRule) {
          // Can only select previous joiners
          break;
        }
        if (type === RESPONSE_SET_TYPE.multi || type === RESPONSE_SET_TYPE.open || type === RESPONSE_SET_TYPE.matrix) {
          // We'll only support multi and open for v1
          options.push(
            <option value={joiner.id} key={joiner.id}>
              {joiner.researchPrompt}
            </option>
          );
        }
        if (joiner.id === activeJoiner.id) {
          // Can only select previous and current joiner
          break;
        }
      }
    }
    return options;
  };

  const isNumericAnswer = joiner => {
    const { expectedAnswerType, options } = joiner.def.responseSet;
    return (
      (expectedAnswerType === ANSWER_TYPE.double || expectedAnswerType === ANSWER_TYPE.numeric) &&
      options.some(o => o.type === OPTION_TYPE.limitoption)
    );
  };

  const getOperatorSelectList = () => {
    const joiner = getJoinerForCondition();
    if (!joiner || !joiner.def) {
      return null;
    }
    const { type, allowMultipleAnswers, matrixType } = joiner.def.responseSet;
    if (type === RESPONSE_SET_TYPE.open) {
      if (isNumericAnswer(joiner)) {
        return [
          <option value={OPERATORS.lt} key="op1">
            {intl.get('app.surveyRules.operator.lt')}
          </option>,
          <option value={OPERATORS.lte} key="op2">
            {intl.get('app.surveyRules.operator.lte')}
          </option>,
          <option value={OPERATORS.eq} key="op3">
            {intl.get('app.surveyRules.operator.eq')}
          </option>,
          <option value={OPERATORS.gte} key="op4">
            {intl.get('app.surveyRules.operator.gte')}
          </option>,
          <option value={OPERATORS.gt} key="op5">
            {intl.get('app.surveyRules.operator.gt')}
          </option>,
          <option value={OPERATORS.ne} key="op6">
            {intl.get('app.surveyRules.operator.ne')}
          </option>
        ];
      }
      return [
        <option value={OPERATORS.startsWith} key="op1">
          {intl.get('app.surveyRules.operator.startsWith')}
        </option>,
        <option value={OPERATORS.contains} key="op2">
          {intl.get('app.surveyRules.operator.contains')}
        </option>,
        <option value={OPERATORS.doesNotContain} key="op3">
          {intl.get('app.surveyRules.operator.doesNotContain')}
        </option>,
        <option value={OPERATORS.endsWith} key="op4">
          {intl.get('app.surveyRules.operator.endsWith')}
        </option>,
        <option value={OPERATORS.isGibberish} key="op5">
          {intl.get('app.surveyRules.operator.isGibberish')}
        </option>
      ];
    }
    if (type === RESPONSE_SET_TYPE.multi || type === RESPONSE_SET_TYPE.matrix) {
      if (allowMultipleAnswers === false || matrixType === MATRIX_TYPE.singleChoice) {
        // single choice
        return [
          <option value={OPERATORS.eq} key="op1">
            {intl.get('app.surveyRules.operator.is')}
          </option>,
          <option value={OPERATORS.ne} key="op2">
            {intl.get('app.surveyRules.operator.isNot')}
          </option>,
          <option value={OPERATORS.in} key="op3">
            {intl.get('app.surveyRules.operator.isIncludedIn')}
          </option>
        ];
      }
      if (allowMultipleAnswers === true || matrixType === MATRIX_TYPE.multipleChoice) {
        // multi choice
        return [
          <option value={OPERATORS.contains} key="op1">
            {intl.get('app.surveyRules.operator.includesAny')}
          </option>,
          <option value={OPERATORS.containsAll} key="op2">
            {intl.get('app.surveyRules.operator.includesAll')}
          </option>,
          <option value={OPERATORS.doesNotContain} key="op3">
            {intl.get('app.surveyRules.operator.doesNotInclude')}
          </option>
        ];
      }
    }
    if (type === RESPONSE_SET_TYPE.ranked) {
      return [
        <option value={OPERATORS.lt} key="op1">
          {intl.get('app.surveyRules.operator.lt')}
        </option>,
        <option value={OPERATORS.lte} key="op2">
          {intl.get('app.surveyRules.operator.lte')}
        </option>,
        <option value={OPERATORS.eq} key="op3">
          {intl.get('app.surveyRules.operator.eq')}
        </option>,
        <option value={OPERATORS.gte} key="op4">
          {intl.get('app.surveyRules.operator.gte')}
        </option>,
        <option value={OPERATORS.gt} key="op5">
          {intl.get('app.surveyRules.operator.gt')}
        </option>,
        <option value={OPERATORS.ne} key="op6">
          {intl.get('app.surveyRules.operator.ne')}
        </option>
      ];
    }
  };

  const joiner = getJoinerForCondition();
  const { type: responseSetType } = (joiner && joiner.def.responseSet) || {};
  const isMatrixType = (condition.joinerId || condition.parentId) && responseSetType === RESPONSE_SET_TYPE.matrix;

  const getMatrixRowsList = () => {
    const bipolar = isBipolar(joiner.def.responseSet);
    const { entries } = joiner.def.responseSet;
    const rows = isMatrixType && bipolar ? entries.bipolarRows : entries.rows;

    const rowOptions = [];
    rows.forEach(row => {
      if (!row.value.disable) {
        rowOptions.push(
          <option value={row.id} key={row.id}>
            {row.value.abbreviatedValue}
          </option>
        );
      }
    });

    return rowOptions;
  };

  const setRuleConditionJoinerId = e => {
    if (e.target.selectedOptions[0].dataset.concept) {
      updateHandlers.setRuleConditionJoinerId(ruleIndex, conditionIndex, null, e.target.value);
    } else {
      updateHandlers.setRuleConditionJoinerId(ruleIndex, conditionIndex, e.target.value, null);
    }
  };

  return (
    <>
      <div>
        <div>
          <div>{intl.get('app.question')}:</div>
          <Input
            type="select"
            value={condition.joinerId || condition.parentId}
            disabled={readOnly}
            onChange={setRuleConditionJoinerId}
          >
            <option />
            {getJoinerSelectList()}
          </Input>
        </div>
        {isMatrixType && (
          <div className="mt-4">
            <div>{intl.get('app.label.row')}:</div>
            <Input
              type="select"
              value={condition.rowId}
              disabled={readOnly}
              onChange={e => updateHandlers.setRuleConditionMatrix(ruleIndex, conditionIndex, e.target.value)}
            >
              <option />
              {getMatrixRowsList()}
            </Input>
          </div>
        )}
      </div>
      <div>
        <div>{intl.get('app.operator')}:</div>
        <Input
          type="select"
          value={condition.operator}
          disabled={readOnly}
          onChange={e => updateHandlers.setRuleConditionOperator(ruleIndex, conditionIndex, e.target.value)}
        >
          <option />
          {getOperatorSelectList()}
        </Input>
      </div>
      <div>
        <div>&nbsp;</div>
        {getValueSelector()}
      </div>
    </>
  );
};

export default QuestionCondition;
