import React from 'react';
import intl from 'react-intl-universal';
import { CommonJoinerEditFields } from '../../joinerEditor';
import { ImportQuestionButton } from '../../modals/importQuestion/ImportQuestionButton';
import { ImportQuestionModal } from '../../modals/importQuestion/ImportQuestionModal';
import { ENGLISH, RESPONSE_SET_TYPE } from '../../../../../util/joinerUtil';
import { TranslateLabel } from './TranslateLabel';

class OpenTextEdit extends React.Component {
  state = {
    showImportQuestionModal: false
  };

  toggleImportQuestionModal = () => {
    this.setState({ showImportQuestionModal: !this.state.showImportQuestionModal });
  };

  importQuestion = question => {
    this.props.updateHandlers.importQuestion(
      question,
      RESPONSE_SET_TYPE.open,
      this.props.viewLanguage,
      this.props.language
    );
    this.toggleImportQuestionModal();
  };

  render() {
    const { joiner, tabs, readOnly, isSessionOpen, insertOrEditAction, viewLanguage, language, setViewLanguage } =
      this.props;
    return (
      <div className={`${tabs.edit} open-text-edit`}>
        <CommonJoinerEditFields {...this.props} />
        <div className="import-and-language">
          <ImportQuestionButton
            readOnly={readOnly}
            isSessionOpen={isSessionOpen}
            insertOrEditAction={insertOrEditAction}
            toggleImportQuestionModal={this.toggleImportQuestionModal}
          />
          {language !== ENGLISH && (
            <TranslateLabel
              setViewLanguage={setViewLanguage}
              language={language}
              viewLanguage={viewLanguage}
              sessionId={this.props.sessionId}
            />
          )}
        </div>
        {this.state.showImportQuestionModal && (
          <ImportQuestionModal
            toggle={this.toggleImportQuestionModal}
            modalTitle={intl.get('app.import.openTextQuestion')}
            questionType={RESPONSE_SET_TYPE.open}
            joiner={joiner}
            importQuestion={this.importQuestion}
            isEnglish={language === viewLanguage}
          />
        )}
      </div>
    );
  }
}

export default OpenTextEdit;
