import { connect } from 'react-redux';
import { fetchAllSessionsAction } from '../../../../store/redux/actions/sessionActions';
import { uploadTranslationsAction } from '../../../../store/redux/actions/surveyActions';
import { sessionSelector } from '../../../../store/redux/selectors/sessionSelector';
import { Translations } from './Translations';

const mapDispatchToProps = dispatch => ({
  fetchSessions: params => dispatch(fetchAllSessionsAction.request(params)),
  uploadTranslations: params => dispatch(uploadTranslationsAction.request(params))
});

const mapStateToProps = (state, props) => {
  const sessionsSelector = sessionSelector()(state);
  return {
    sessions: sessionsSelector.allSessions.content,
    uploadTranslationsSuccess: state.surveys.uploadTranslationsSuccess
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Translations);
