import { connect } from 'react-redux';
import VideoResponses from './VideoResponses';

import { fetchParticipantDataDefActions } from '../../../../../store/redux/actions/participantDataActions';
import { makeParticipantDataDefSelectorInstance } from '../../../../../store/redux/selectors/participantDataDefSelector';

const makeMapState = state => {
  const selectItemForThisComponent = makeParticipantDataDefSelectorInstance();
  return function realMapState(state, props) {
    return {
      participantCollection: (props.session && selectItemForThisComponent(state, props.session.id)) || []
    };
  };
};

const mapDispatchToProps = dispatch => ({
  getParticipantDataDef: sessionId => dispatch(fetchParticipantDataDefActions.request(sessionId))
});

export default connect(makeMapState, mapDispatchToProps)(VideoResponses);
