import auth0 from 'auth0-js';
import Cookie from 'js-cookie';
import appConfig from '../appConfig';

const ACCESS_TOKEN = 'access_token';
const ID_TOKEN = 'id_token';
const EXPIRES_AT = 'expires_at';
const USER_PICTURE = 'user_picture';
const MFA = 'mfa';
const ACR_VALUES = 'http://schemas.openid.net/pape/policies/2007/06/multi-factor';

class Auth {
  auth0 = new auth0.WebAuth({
    domain: appConfig.auth0Domain,
    clientID: appConfig.auth0ClientId,
    redirectUri: appConfig.auth0CallbackURL,
    audience: `https://${appConfig.auth0Domain}/userinfo`,
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login(params, enableMFA) {
    const queryString =
      params &&
      Object.keys(params)
        .map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        })
        .join('&');
    const redirectUri = params ? appConfig.auth0CallbackURL + '?' + queryString : appConfig.auth0CallbackURL;

    this.auth0.authorize({
      redirectUri,
      acr_values: enableMFA ? ACR_VALUES : ''
    });
  }

  handleAuthentication(callback) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          callback && callback(null, authResult);
          resolve(authResult);
        } else if (err) {
          console.log(err);
          callback && callback(err);
          reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    const {
      accessToken,
      idToken,
      idTokenPayload: { picture, amr = [] }
    } = authResult;
    sessionStorage.setItem(ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(ID_TOKEN, idToken);
    localStorage.setItem(EXPIRES_AT, this.getExpiresTime());
    sessionStorage.setItem(USER_PICTURE, picture);
    sessionStorage.setItem(MFA, amr.indexOf('mfa') !== -1 ? 1 : '');
  }

  logout() {
    if (Cookie.get(appConfig.authCookieName)) {
      fetch('/a/api/logout').catch(err => {
        if (err !== 'TypeError: Failed to fetch') {
          // Log non-benign error
          console.warn(err);
        }
      });
      requestAnimationFrame(() => {
        Cookie.remove(appConfig.authCookieName);
        sessionStorage.removeItem(ACCESS_TOKEN);
        sessionStorage.removeItem(ID_TOKEN);
        sessionStorage.removeItem(MFA);
        localStorage.removeItem(EXPIRES_AT);
      });
    }
  }

  // Check whether the current time is past the expiry time
  isAuthenticated(keepActive) {
    const now = Date.now();
    const expiresAt = localStorage.getItem(EXPIRES_AT);
    if (now > expiresAt) {
      return false;
    }
    if (keepActive) {
      const newExpiresTime = this.getExpiresTime();
      if (newExpiresTime - expiresAt > 1000) {
        // Update no more than once/second
        localStorage.setItem(EXPIRES_AT, newExpiresTime);
      }
    }
    return true;
  }

  getExpiresTime() {
    return Date.now() + appConfig.userSessionMaxIdleMinutes * 60 * 1000;
  }

  getProfile(accessToken) {
    return new Promise((resolve, reject) =>
      this.auth0.client.userInfo(accessToken, (err, profile) => {
        if (err) reject(err);
        resolve(profile);
      })
    );
  }
}

export const auth = new Auth();
