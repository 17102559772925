import { handleActions } from 'redux-actions';
import {
  fetchFunctionActions,
  fetchFunctionListActions,
  deleteFunctionActions,
  fetchAllFunctionsActions
} from '../actions/functionActions';

const initialState = {
  functionListRequested: false,
  functionRequested: false,
  functions: {},
  allFunctions: []
};

const dataTableReducer = handleActions(
  {
    [fetchFunctionListActions.triggered](state) {
      return { ...state, functionListRequested: true };
    },
    [fetchFunctionListActions.succeeded](state, { payload }) {
      return {
        ...state,
        functions: payload,
        functionListRequested: false
      };
    },
    [fetchFunctionListActions.failed](state) {
      return { ...state, functionListRequested: false };
    },

    [fetchFunctionActions.triggered](state) {
      return {
        ...state,
        functionRequested: true
      };
    },
    [fetchFunctionActions.succeeded](state, { payload }) {
      return {
        ...state,
        functions: { ...state.functions, [payload.id]: payload },
        functionRequested: false
      };
    },
    [fetchFunctionActions.failed](state) {
      return { ...state, functionRequested: false };
    },

    [deleteFunctionActions.succeeded](state, { payload }) {
      return {
        ...state,
        functions: { ...state.functions, [payload.id]: null }
      };
    },

    [fetchAllFunctionsActions.triggered](state) {
      return { ...state, allFunctionsRequested: true };
    },
    [fetchAllFunctionsActions.succeeded](state, { payload }) {
      return {
        ...state,
        allFunctions: payload.content,
        allFunctionsRequested: false
      };
    },
    [fetchAllFunctionsActions.failed](state) {
      return { ...state, allFunctionsRequested: false };
    }
  },
  initialState
);
export default dataTableReducer;
