import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

class TextStimOptions extends React.Component {
  render() {
    const { tabs, joiner, updateHandlers, readOnly } = this.props;
    return (
      <div className={tabs.options}>
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={joiner.hidden}
            onChange={e => updateHandlers.updateHidden(e.target.checked)}
          />
          {intl.get('app.hideQuestion')}
        </label>
      </div>
    );
  }
}

export default TextStimOptions;
