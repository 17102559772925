import { handleActions } from 'redux-actions';
import { receiveComparativeViewActions } from '../../actions/researchDashboardActions';
const initialState = {};

export const rdComparativeViewReducer = handleActions(
  {
    [receiveComparativeViewActions.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload.aggregateData
      };
    }
  },
  initialState
);
