import { get } from '../util/request';

const SCREENER_PREFIX = 'SCREENER_';

async function validateEntity({ id, entity }) {
  if (entity.indexOf(SCREENER_PREFIX) === 0) {
    entity = entity.replace(SCREENER_PREFIX, '');
  }
  const url = `/a/api/validate/${entity}/${id}`;
  return get(url)().then(res => (res.body && res.body.payload && { id, entity, validation: res.body.payload }) || {});
}

export { validateEntity };
