import { connect } from 'react-redux';
import { PageHeader } from './PageHeader';
import { logoutUser } from '../../store/redux/actions/authActions';
import { sessionUserSelector } from '../../store/redux/selectors/sessionUserSelector';

const mapStateToProps = state => {
  const { sessionUser, sessionTenant, abilities } = sessionUserSelector(state);
  return {
    user: sessionUser,
    tenant: sessionTenant,
    abilities
  };
};

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser())
});
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
