import { find } from 'lodash';

const isConceptRotationInProgress = (currentQuestion, responseRates) => {
  if (!currentQuestion || !currentQuestion.conceptRotation) {
    // Not a concept rotation
    return false;
  }
  if (currentQuestion.conceptRotation.numOfRotation === 1) {
    // Concept rotation is configured to show only 1 position
    return false;
  }
  if (
    responseRates &&
    responseRates.positionAnswerPcts &&
    responseRates.position < responseRates.positionAnswerPcts.length
  ) {
    // The current position is behind the total # of positions
    return true;
  }
  return false;
};

const findNextJoiner = (questions, currentQuestion) => {
  if (!questions || !currentQuestion) {
    return;
  }

  let joiner = currentQuestion;
  while (true) {
    const nextId = joiner.defaultNextJoinerId;
    joiner = find(questions, q => q.id === nextId);
    if (!joiner) {
      break;
    }
    if (joiner.hidden) {
      continue;
    }
    if (currentQuestion.container && joiner.containerId === currentQuestion.id) {
      continue;
    }
    break;
  }
  return joiner;
};

export const getNextJoinerToDeploy = (deployButtonEnabled, questions, questionJoinerResponseRates, currentJoinerId) => {
  if (!deployButtonEnabled || questions.length <= 0) {
    return;
  }
  const filteredQuestions = questions.filter(q => !q.hidden);
  const currentQuestion = currentJoinerId ? find(questions, q => q.id === currentJoinerId) : filteredQuestions[0];
  const responseRates = questionJoinerResponseRates[currentJoinerId];
  const conceptRotationInProgress = isConceptRotationInProgress(currentQuestion, responseRates);
  if (conceptRotationInProgress) {
    return currentQuestion;
  }
  // Return the next joiner
  return currentJoinerId ? findNextJoiner(questions, currentQuestion) : currentQuestion;
};
