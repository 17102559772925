import React from 'react';
import intl from 'react-intl-universal';
import { DataExport } from '../dataExport/DataExport';

// From ReportType.java
const reportType = {
  DIAL_RAW_DATA: 'DIAL_RAW_DATA',
  DIAL_CHART: 'DIAL_CHART'
};

const getReportType = type => {
  switch (type) {
    case reportType.DIAL_RAW_DATA:
      return intl.get('app.reportType.dialRawData');
    case reportType.DIAL_CHART:
      return intl.get('app.reportType.dialPresentation');
    default:
      return '';
  }
};

export const DialDataExport = props => {
  const {
    joiner,
    sessionId,
    sessionName,
    exportData,
    reportFiles,
    exportDataInProgress,
    fetchReportList,
    filteredParticipants,
    selectedAction,
    selectedFilters,
    dataOutputType
  } = props;

  const { dialConfig } = joiner.stim.options;
  const { actionButtons = [] } = dialConfig;
  const actionList = actionButtons.map(action => action.label);

  function getReportConfig() {
    return {
      config: {
        type: 'dialReportConfig',
        sessionName: sessionName,
        sessionId: sessionId,
        joinerId: joiner.id,
        selectedAction,
        actionList
      }
    };
  }

  return (
    <DataExport
      sessionId={sessionId}
      sessionName={sessionName}
      exportData={exportData}
      reportFiles={reportFiles}
      exportDataInProgress={exportDataInProgress}
      fetchReportList={fetchReportList}
      filteredParticipants={filteredParticipants}
      selectedAction={selectedAction}
      selectedFilters={selectedFilters}
      reportConfig={getReportConfig()}
      chartReportType={reportType.DIAL_CHART}
      rawReportType={reportType.DIAL_RAW_DATA}
      dataOutputType={dataOutputType}
      getReportType={getReportType}
    />
  );
};
