import { handleActions, combineActions } from 'redux-actions';
import {
  fetchProjectListActions,
  fetchRecentProjectsActions,
  fetchAllProjectsActions,
  fetchArchivedProjectListActions
} from '../actions/projectListActions';

const initialState = {
  projectListRequested: false,
  allProjects: {}
};

const projectListReducer = handleActions(
  {
    [combineActions(
      fetchProjectListActions.triggered,
      fetchRecentProjectsActions.triggered,
      fetchArchivedProjectListActions.triggered
    )](state) {
      return { ...state, projectListRequested: true };
    },

    [fetchProjectListActions.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload,
        projectListRequested: false
      };
    },

    [fetchRecentProjectsActions.succeeded](state, { payload }) {
      return {
        ...state,
        recentProjects: { ...payload },
        projectListRequested: false
      };
    },

    [fetchArchivedProjectListActions.succeeded](state, { payload }) {
      return {
        ...state,
        archivedProjects: payload,
        projectListRequested: false
      };
    },

    [combineActions(
      fetchProjectListActions.failed,
      fetchRecentProjectsActions.failed,
      fetchArchivedProjectListActions.failed
    )](state) {
      return { ...state, projectListRequested: false };
    },

    [fetchAllProjectsActions.triggered](state) {
      return { ...state, allProjectsRequested: true };
    },

    [fetchAllProjectsActions.succeeded](state, { payload }) {
      return {
        ...state,
        allProjects: { ...payload },
        allProjectsRequested: false
      };
    },

    [fetchAllProjectsActions.triggered](state) {
      return { ...state, allProjectsRequested: false };
    }
  },
  initialState
);
export default projectListReducer;
