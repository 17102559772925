import { RESPONSE_SET_TYPE, isBipolar } from '../../util/joinerUtil';

// Convert a string to the format of the metadata keys. This matches logic in MetaObjDef.removeSpecialChars().
function getMetadataKey(str) {
  return str.toLowerCase().replace(/[^\-\w\s]+/g, ' ');
}

/**
 * We normally just use the columnOrder array from the EnrolleesInfo object. But for screener surveys, the
 * requirement is to order the columns by: 1) Any query params sent at survey start (e.g. source, uid),
 * 2) Question responses, 3) Segmentation categories. So, if questionJoiners and segmentCategories are
 * passed in, we'll build the ordered columns list using this data.
 *
 * We also need to build the keyTransformMap for surveys that have not yet been taken.
 *
 * @param columnOrder
 * @param questionJoiners
 * @param segmentCategories
 * @param enrolleeDataDef
 * @param keyTransformMap
 *
 * @return { columnOrder, keyTransformMap }
 */
function getColumnOrder(
  columnOrder = [],
  questionJoiners = [],
  segmentCategories = [],
  enrolleeDataDef = {},
  keyTransformMap
) {
  if (!questionJoiners || questionJoiners.length === 0) {
    return { columnOrder, keyTransformMap };
  }

  const newKeyTransformMap = Object.assign({}, keyTransformMap || {});

  // Build the list of unique columns (source, uid, etc)
  const uniqueIdCols = [];
  for (let id in enrolleeDataDef) {
    enrolleeDataDef[id].uniqueIdCols.forEach(col => {
      uniqueIdCols.indexOf(col) === -1 && uniqueIdCols.push(col);
    });
  }

  // Build the list of ordered questions
  const orderedQuestions = [];
  questionJoiners
    .filter(
      j => !j.hidden && !j.container && !j.conceptRotation && !j.stimOnly && !j.thirdPartyStim && !j.emailCollector
    )
    .forEach(joiner => {
      const { responseSet } = joiner.def;
      if (
        responseSet.type === RESPONSE_SET_TYPE.open ||
        (responseSet.type === RESPONSE_SET_TYPE.multi && !responseSet.allowMultipleAnswers)
      ) {
        const key = getMetadataKey(joiner.researchPrompt);
        orderedQuestions.push(key);
        !newKeyTransformMap[key] && (newKeyTransformMap[key] = joiner.researchPrompt);
      } else if (responseSet.type === RESPONSE_SET_TYPE.multi && responseSet.allowMultipleAnswers) {
        responseSet.choices.forEach(choice => {
          if (!choice.value.disable) {
            // Same formatting as on the back-end
            const choiceTitle = joiner.researchPrompt + ' - ' + choice.value.value;
            const key = getMetadataKey(choiceTitle);
            orderedQuestions.push(key);
            !newKeyTransformMap[key] && (newKeyTransformMap[key] = choiceTitle);
          }
        });
      } else if (responseSet.type === RESPONSE_SET_TYPE.matrix) {
        const { rows, bipolarRows } = responseSet.entries;
        const entryRows = isBipolar(responseSet) ? bipolarRows : rows;
        entryRows.forEach(row => {
          if (!row.value.disable) {
            const label = row.value.type === 'imagelabel' ? row.value.imageStim.caption : row.value.value;
            // Same formatting as on the back-end
            const choiceTitle = joiner.researchPrompt + ' - ' + label;
            const key = getMetadataKey(choiceTitle);
            orderedQuestions.push(key);
            !newKeyTransformMap[key] && (newKeyTransformMap[key] = choiceTitle);
          }
        });
      }
    });

  // Build the list of segment categories
  const orderedSegmentCategories = [];
  segmentCategories.forEach(segCat => {
    const key = getMetadataKey(segCat.name);
    orderedSegmentCategories.push(key);
    !newKeyTransformMap[key] && (newKeyTransformMap[key] = segCat.name);
  });

  // Remove all columns in the lists built above from the original list.
  // This will leave whatever is left over in metadata.
  const metadataColumns = [...columnOrder];
  [...uniqueIdCols, ...orderedQuestions, ...orderedSegmentCategories].forEach(col => {
    const index = metadataColumns.indexOf(col);
    index !== -1 && metadataColumns.splice(index, 1);
  });

  return {
    columnOrder: [...uniqueIdCols, ...metadataColumns, ...orderedQuestions, ...orderedSegmentCategories],
    keyTransformMap: newKeyTransformMap
  };
}

export const participantListUtil = {
  getColumnOrder
};
