import React from 'react';
import { get } from 'lodash';
import { Select } from 'antd';
import { RESPONSE_SET_TYPE } from '../../../../../util/joinerUtil';

const Option = Select.Option;

export const ExcludedQuestions = props => {
  const { survey, readOnly, setExcludedQuestions } = props;

  function getParticipantDataExcludedQuestionOptions() {
    const { joiners = [] } = survey;
    const filteredJoiners = joiners.filter(j => {
      const type = get(j, 'def.responseSet.type');
      return (
        (!j.hidden || survey.surveyOptions.participantDataExcludedQuestions.indexOf(j.id) !== -1) &&
        !j.emailCollector &&
        (type === RESPONSE_SET_TYPE.open || type === RESPONSE_SET_TYPE.matrix || type === RESPONSE_SET_TYPE.multi)
      );
    });
    return filteredJoiners.map(j => <Option key={j.id}>{j.researchPrompt}</Option>);
  }

  return (
    <Select
      mode="tags"
      value={survey.surveyOptions && survey.surveyOptions.participantDataExcludedQuestions}
      onChange={setExcludedQuestions}
      disabled={readOnly}
    >
      {getParticipantDataExcludedQuestionOptions()}
    </Select>
  );
};
