import { connect } from 'react-redux';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';
import {
  fetchParticipantCollectionJobsActions,
  pingParticipantCollectionJobsActions
} from '../../../../store/redux/actions/fetchParticipantLists';
import { ProjectRecruitCollection as Component } from './ProjectRecruitCollection';

const mapDispatchToProps = dispatch => ({
  fetchParticipantCollectionJobs: params => dispatch(fetchParticipantCollectionJobsActions.request(params)),
  pingParticipantCollectionJobs: payload => dispatch(pingParticipantCollectionJobsActions.request(payload))
});

const mapStateToProps = state => {
  const { hasProjectView } = sessionUserSelector(state).abilities;
  return { hasProjectView };
};

export const ProjectRecruitCollection = connect(mapStateToProps, mapDispatchToProps)(Component);
