import React, { useCallback, useMemo, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { findIndex, flatten, find } from 'lodash';
import { SURVEY_RULE_TYPE } from '../../../../../util/joinerUtil';
import { InvokeTable } from 'webapp-common';
import ValidationIcon from '../../../../validationStatus/ValidationIcon';
import { AddOrEditSegmentCategoryModal } from './AddOrEditSegmentCategoryModal';

const reducer = (state, payload) => ({ ...state, ...payload });

export const SurveySegments = props => {
  const { survey, readOnly, setSegmentCategories } = props;

  const [state, setState] = useReducer(reducer, {
    selectedSegmentCategory: {},
    showAddOrEditSegmentCategoryModal: false,
    rowIndex: -1
  });

  const getSegmentCategoryValidation = useCallback(
    segmentCategoryName => {
      const segmentCategoryList = survey.segmentCategories || [];
      const segmentCategory = find(segmentCategoryList, { name: segmentCategoryName });
      if (!segmentCategory || !segmentCategory.segments || segmentCategory.segments.length === 0) {
        return null;
      }
      const allSegments = segmentCategory.segments.map(segment => segment.name);
      const rules = flatten(survey.joiners && survey.joiners.map(a => a.surveyRules));
      const segmentCategoryRules = rules.filter(
        rule => rule.type === SURVEY_RULE_TYPE.segmentationRule && rule.segmentCategory === segmentCategoryName
      );
      if (segmentCategoryRules.length === 0) {
        // there are no rules that use this segment category
        return getValidationIcon('WARNING', intl.get('app.segement.warning', { segments: allSegments.join(', ') }));
      }
      // get segments used in rules.
      const ruleSegments = segmentCategoryRules.map(rule => rule.segment);

      const unusedSegments = allSegments.filter(segment => ruleSegments.indexOf(segment) === -1);
      if (unusedSegments.length === 0) {
        return null;
      }
      return getValidationIcon('WARNING', intl.get('app.segement.warning', { segments: unusedSegments.join(', ') }));
    },
    [survey.joiners, survey.segmentCategories]
  );

  const renderSegmentCategory = useCallback(
    info => {
      const obj = info.row.original;
      return (
        <div>
          <span className="link" title={obj.name}>
            {obj.name}
          </span>
          {getSegmentCategoryValidation(obj.name)}
        </div>
      );
    },
    [getSegmentCategoryValidation]
  );

  function getValidationIcon(status, message) {
    const validation = {
      status,
      message
    };
    return <ValidationIcon validation={validation} validationSupported onlyWarning />;
  }

  const renderSegments = useCallback(info => {
    const obj = info.row.original;
    const { segments } = obj;
    if (segments) {
      const names = segments.map(({ name }) => name);
      return <span title={names.join(', ')}>{names.join(', ')}</span>;
    }
    return '';
  }, []);

  const renderQuotas = useCallback(info => {
    const quotaEnabled = info.getValue();
    return <span>{quotaEnabled ? intl.get('app.enabled') : intl.get('app.none')}</span>;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: intl.get('app.segmentCategory'),
        headerStyle: { width: '12rem' },
        cell: renderSegmentCategory,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'segments',
        header: intl.get('app.segments'),
        headerStyle: { width: '12rem' },
        cell: renderSegments,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'quotaEnabled',
        header: intl.get('app.quotas'),
        headerStyle: { width: '4rem' },
        cell: renderQuotas
      }
    ];
  }, [renderQuotas, renderSegmentCategory, renderSegments]);

  function deleteSegmentCategory() {
    const segmentCategories = [...survey.segmentCategories];
    segmentCategories.splice(state.rowIndex, 1);
    setSegmentCategories(segmentCategories);
    toggleAddOrEditSegmentCategoryModal();
  }

  function onAddSegmentCategory(params) {
    const segmentCategories = [...survey.segmentCategories];
    const segCat = {
      name: params.name,
      quotaEnabled: params.quotaEnabled,
      segments: params.segments
    };
    if (params.rowIndex < 0) {
      segmentCategories.push(segCat);
    } else {
      segmentCategories[params.rowIndex] = segCat;
    }
    setSegmentCategories(segmentCategories);
    toggleAddOrEditSegmentCategoryModal();
  }

  function createNewSegmentCategory() {
    setState({
      selectedSegmentCategory: {},
      rowIndex: -1
    });
    toggleAddOrEditSegmentCategoryModal();
  }

  const toggleAddOrEditSegmentCategoryModal = useCallback(() => {
    setState({ showAddOrEditSegmentCategoryModal: !state.showAddOrEditSegmentCategoryModal });
  }, [state.showAddOrEditSegmentCategoryModal]);

  const getGlobalRowIndex = useCallback(
    row => {
      return findIndex(survey.segmentCategories, row);
    },
    [survey.segmentCategories]
  );

  function deleteEnabled() {
    // delete is enabled only if segment category is not used in any rule
    const rules = flatten(survey.joiners && survey.joiners.map(a => a.surveyRules));
    if (!rules || rules.length <= 0) {
      return true;
    }
    const segments = rules.map(a => a.segmentCategory);
    let index = -1;
    for (let i = 0; i < segments.length; ++i) {
      if (segments[i]) {
        index = segments[i].indexOf(state.selectedSegmentCategory.name);
      }
      if (index !== -1) {
        break;
      }
    }
    return index === -1;
  }

  const onRowSelect = useCallback(
    row => {
      setState({
        selectedSegmentCategory: row,
        rowIndex: getGlobalRowIndex(row) // rowIndex itself does not work because it is relative to the page ...
      });
      // toggle modal up only if object is non-empty (wasn't just clicking to clear row select)
      if (Object.keys(row).length !== 0 || row.constructor !== Object) {
        toggleAddOrEditSegmentCategoryModal();
      }
    },
    [getGlobalRowIndex, toggleAddOrEditSegmentCategoryModal]
  );

  return (
    <>
      <div style={{ marginRight: '1rem', width: '48rem' }}>
        <InvokeTable
          className="invoke-table"
          data={survey.segmentCategories ?? []}
          columns={columns}
          enableSort={false}
          onRowSelect={onRowSelect}
        />
      </div>
      <Button color="primary" style={{ whiteSpace: 'nowrap' }} onClick={createNewSegmentCategory} disabled={readOnly}>
        {intl.get('app.segmentCategory.addCategory')}
      </Button>
      {state.showAddOrEditSegmentCategoryModal && (
        <AddOrEditSegmentCategoryModal
          toggle={toggleAddOrEditSegmentCategoryModal}
          onSubmit={onAddSegmentCategory}
          onDelete={deleteSegmentCategory}
          selectedSegmentCategory={state.selectedSegmentCategory}
          rowIndex={state.rowIndex}
          enableDelete={deleteEnabled}
          readOnly={readOnly}
        />
      )}
    </>
  );
};
