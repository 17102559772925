import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useQuery } from 'react-query';
import { isEqual } from 'lodash';
import { Button } from 'reactstrap';
import { PARTICIPANT_FLAG_COLORS } from '../../../../util/researchDashboardUtil';
import { fetchPanelConfigs } from '../../../../api/clientAdminApi';
import { Loader } from 'webapp-common';
import { FlagIcon } from '../../../../components/icons/Icons';

const SURVEY_COMPLETE_FILTER_KEY = 'Survey Completed';
const FAVORITE_FILTER_KEY = 'Favorite';
const PARTICIPANT_GROUP_FILTER_KEY = 'participantGroup';

function getSurveyCompleteFilterTerm(value) {
  return {
    field: SURVEY_COMPLETE_FILTER_KEY,
    fieldPrefix: 'S',
    operator: '<',
    type: 'term',
    value,
    valuePrefix: 'A'
  };
}

function getFavoriteFilterTerm(value) {
  return {
    field: FAVORITE_FILTER_KEY,
    fieldPrefix: 'A',
    operator: ':',
    type: 'term',
    value,
    valuePrefix: 'A'
  };
}

function getParticipantGroupFilterTerm(value) {
  return {
    field: PARTICIPANT_GROUP_FILTER_KEY,
    fieldPrefix: 'R',
    operator: ':',
    type: 'term',
    value,
    valuePrefix: 'A'
  };
}

// This is really just a participant metadata filter
function getPanelIdFilterTerm(value) {
  return {
    field: 'source',
    fieldPrefix: 'P',
    operator: ':',
    type: 'term',
    value,
    valuePrefix: 'A'
  };
}

export const OtherFiltersSelector = props => {
  const { selectedFilterTerms, session, mode, addOrRemoveFilterTerm } = props;

  const { isFetching: fetchGlobalPanelConfigsInProgress, data: globalPanelConfigs } = useQuery(
    'fetch-global-panel-configs',
    () => (mode === 'screener' ? fetchPanelConfigs() : null),
    {
      refetchOnWindowFocus: false
    }
  );

  function isTermSelected(term) {
    return selectedFilterTerms.some(t => isEqual(t, term));
  }

  function getButton(term, label) {
    const classes = {
      selected: isTermSelected(term),
      'link-button': true
    };
    return (
      <Button className={classnames(classes)} onClick={() => addOrRemoveFilterTerm(term)}>
        {label}
      </Button>
    );
  }

  function getFlagIcon(term, group) {
    const classes = {
      [PARTICIPANT_FLAG_COLORS[group]]: true,
      selected: isTermSelected(term)
    };
    return (
      <FlagIcon
        className={classnames(classes)}
        title={intl.get(`app.participantFlag.${group}.color`)}
        onClick={() => addOrRemoveFilterTerm(term)}
      />
    );
  }

  function getSeparator() {
    return <span> | </span>;
  }

  function getSurveyCompleteElems() {
    const elems = [];
    ['10', '30', '50', '70', '90', '100'].forEach(n => {
      const term = getSurveyCompleteFilterTerm(n);
      if (elems.length) {
        elems.push(getSeparator());
      }
      elems.push(getButton(term, `${n}%`));
    });
    return elems;
  }

  function getFavoriteOption(value, label) {
    const term = getFavoriteFilterTerm(value);
    return getButton(term, label);
  }

  function getFavoriteElems() {
    return [
      getFavoriteOption('true', intl.get('app.isFavorite')),
      getSeparator(),
      getFavoriteOption('false', intl.get('app.isNotFavorite'))
    ];
  }

  function getParticipantGroupElems() {
    const elems = [getButton(getParticipantGroupFilterTerm('any'), intl.get('app.any')), getSeparator()];
    Object.keys(PARTICIPANT_FLAG_COLORS).forEach(group => {
      elems.push(getFlagIcon(getParticipantGroupFilterTerm(group), group));
      elems.push(getSeparator());
    });
    elems.push(getButton(getParticipantGroupFilterTerm('none'), intl.get('app.none')));
    return elems;
  }

  function getPanelId(panel) {
    let panelId = panel.panelId; // Custom panel config
    if (panel.type === 'GLOBAL_REF') {
      // If a reference to a global panel config, get the panelId from the global panel config.
      const panelConfig = globalPanelConfigs?.content.find(globalPanelConfig => globalPanelConfig.id === panel.id);
      panelId = panelConfig?.panelId;
    }
    return panelId;
  }

  function getPanelIdElems() {
    const elems = [];
    session.panelConfigs.forEach(panel => {
      if (panel.active) {
        const panelId = getPanelId(panel);
        const term = getPanelIdFilterTerm(panelId);
        if (elems.length) {
          elems.push(getSeparator());
        }
        elems.push(getButton(term, panelId));
      }
    });
    return elems;
  }

  return (
    <>
      {fetchGlobalPanelConfigsInProgress && <Loader spinner fullScreen />}
      <table className="invoke-table">
        <thead>
          <tr>
            <th style={{ width: '15rem' }}>{intl.get('app.type')}</th>
            <th style={{ width: '100%' }}>{intl.get('app.values')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{intl.get('app.surveyComplete')}</td>
            <td>
              <div className="answers-container">{getSurveyCompleteElems()}</div>
            </td>
          </tr>
          {mode !== 'screener' && (
            <tr>
              <td>{intl.get('app.favorite')}</td>
              <td>
                <div className="answers-container">{getFavoriteElems()}</div>
              </td>
            </tr>
          )}
          <tr>
            <td>{intl.get('app.participantFlag')}</td>
            <td>
              <div className="answers-container">{getParticipantGroupElems()}</div>
            </td>
          </tr>
          {mode === 'screener' && (
            <tr>
              <td>{intl.get('app.panel')}</td>
              <td>
                <div className="answers-container">{getPanelIdElems()}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};
