import React from 'react';
import intl from 'react-intl-universal';
import { UncontrolledTooltip } from 'reactstrap';

import './VoteSummary.css';

export const VoteSummary = props => {
  const {
    voteData: { agreePercent, neutralPercent, disagreePercent, totalVotes, votes = {} },
    index
  } = props;
  const { AGREE = 0, DISAGREE = 0, NEUTRAL = 0 } = votes;
  const id = `tips-${index}`;

  return (
    <div className="vote-summary" id={id}>
      <div>
        <div>{`${agreePercent}% / ${neutralPercent}% / ${disagreePercent}%`}</div>
        <div>{`(n:${totalVotes})`}</div>
      </div>
      <div>
        <div style={{ width: `${agreePercent}%` }} />
        <div style={{ width: `${neutralPercent}%` }} />
        <div style={{ width: `${disagreePercent}%` }} />
      </div>
      <UncontrolledTooltip className="vote-summary-tooltip" target={id} placement="right">
        <>
          <div className="summary-tooltip">
            <div className="box me-3" style={{ backgroundColor: '#71a6cb' }} />
            <span className="me-5" style={{ width: '40%' }}>
              {intl.get('app.positive')}:
            </span>
            <span className="me-3">{AGREE}</span>
          </div>
          <div className="summary-tooltip">
            <div className="box me-3" style={{ backgroundColor: '#eeeeee' }} />
            <span className="me-5" style={{ width: '40%' }}>
              {intl.get('app.neutral')}:
            </span>
            <span className="me-3">{NEUTRAL}</span>
          </div>
          <div className="summary-tooltip">
            <div className="box" style={{ backgroundColor: '#d9d382' }} />
            <span className="me-5" style={{ width: '40%' }}>
              {intl.get('app.negative')}:
            </span>
            <span className="me-3">{DISAGREE}</span>
          </div>
        </>
      </UncontrolledTooltip>
    </div>
  );
};
