import { handleActions } from 'redux-actions';
import { cloneDeep } from 'lodash';
import { virtualFocusGroupDataActions } from '../actions/virtualFocusGroupActions';
import { mediaUpdateActions } from '../actions/mediaActions';

const initialState = {};

export const virtualFocusGroupReducer = handleActions(
  {
    [virtualFocusGroupDataActions.succeeded](state, { payload }) {
      const { sessionId, questionJoinerId } = payload;
      return {
        ...state,
        [`${sessionId}-${questionJoinerId}`]: payload
      };
    },

    // Update Chime meeting(s) with updated streaming Media objects
    [mediaUpdateActions.succeeded](state, { payload }) {
      const copy = cloneDeep(state);
      for (const key of Object.keys(copy)) {
        const { chimeMeeting } = copy[key];
        const media = payload.media.find(m => m.type === 'Video' && m.inactive && m.cfId === chimeMeeting.id);
        if (media) {
          chimeMeeting.streamingMedia = media;
        }
      }
      return {
        ...copy
      };
    }
  },
  initialState
);
