import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

import './UploadLogoModal.css';

class UploadLogoModal extends React.Component {
  state = {
    logoFile: null,
    updateButtonDisabled: true
  };

  getLogoUrl = () => {
    return (this.state.logoFile && window.URL.createObjectURL(this.state.logoFile)) || this.props.logoUrl;
  };

  handleSelectImageClick = () => {
    this.imgUploadField.click();
  };

  onImageSelect = logoFile => {
    this.setState({
      logoFile,
      updateButtonDisabled: false
    });
  };

  uploadLogo = () => {
    this.props.uploadLogo(this.state.logoFile);
  };

  render() {
    const { show, toggle } = this.props;
    const logoUrl = this.getLogoUrl();

    return (
      <InvokeModal
        showModal={show}
        toggle={toggle}
        className="uploadLogoModal"
        modalTitle={intl.get('app.uploadLogo.uploadLogo')}
        primaryButtonText={intl.get('app.uploadLogo.updateImage')}
        save={this.uploadLogo}
        enableSave={!this.state.updateButtonDisabled}
        cancelButtonText={intl.get('app.cancel')}
      >
        <div className="instructionsHeader">{intl.get('app.uploadLogo.instructions')}</div>
        <ul>
          <li>{intl.get('app.uploadLogo.step1')}</li>
          <li>{intl.get('app.uploadLogo.step2')}</li>
          <li>{intl.get('app.uploadLogo.step3')}</li>
          <li>{intl.get('app.uploadLogo.step4')}</li>
        </ul>
        <Button color="primary" className="selectImgButton" onClick={this.handleSelectImageClick}>
          {intl.get('app.uploadLogo.selectImage')}
        </Button>
        <div className="previewContainer">
          <div>{intl.get('app.preview')}</div>
          <div className="logoImgPreview">{logoUrl && <img src={logoUrl} alt="" />}</div>
        </div>
        <input
          type="file"
          className="hidden"
          onChange={ev => this.onImageSelect(ev.target.files[0])}
          accept="image/*"
          ref={obj => {
            this.imgUploadField = obj;
          }}
        />
      </InvokeModal>
    );
  }
}

export default UploadLogoModal;
