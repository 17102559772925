import React from 'react';
import { accessHandler } from 'webapp-common';
import { useSessionUserSelector } from '../../../customHooks/reduxHelper';
import { SelfServeRecruit } from './SelfServeRecruit';
import { ProjectRecruitCollections } from './';

export const ProjectRecruit = props => {
  const { projectId, project } = props;

  const { hasProjectManage, hasProjectView } = useSessionUserSelector().abilities;

  const isSelfServe = project?.projectDetails?.selfServe;

  const accessCheck = accessHandler.checkAccess(hasProjectView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  if (isSelfServe) {
    return <SelfServeRecruit projectId={projectId} hasProjectManage={hasProjectManage} />;
  }

  if (project) {
    return <ProjectRecruitCollections {...props} hasProjectManage={hasProjectManage} />;
  }

  return null;
};
