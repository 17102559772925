import React from 'react';
import intl from 'react-intl-universal';

import './FilterResponseCounts.css';

export const FilterResponseCounts = props => {
  const { selectedRow, filters, quotaFilters, getResponseRate } = props;

  function getTableRows() {
    const allFilters = [...quotaFilters, ...filters];
    const largest = allFilters.reduce((acc, filter) => {
      const { count = 0 } = selectedRow.filtered[filter.name] || {};
      return count > acc ? count : acc;
    }, 0);
    const multiplier = largest > 100 ? 100 / largest : 1;
    return allFilters.map(filter => {
      const obj = selectedRow.filtered[filter.name] || {};
      const { count = 0 } = obj;
      return (
        <tr>
          <td>{filter.name}</td>
          <td>
            <div className="response-rate-bar" style={{ width: `${Math.round(multiplier * count * 2)}px` }} />
          </td>
          <td>{getResponseRate(obj)}</td>
        </tr>
      );
    });
  }

  return (
    <div className="filter-response-counts">
      <div>
        <strong>{intl.get('app.responseCountByFilter')}</strong>
      </div>
      <table>
        <tbody>{getTableRows()}</tbody>
      </table>
      <div style={{ color: 'var(--gray-800)' }}>
        {intl.get('app.answered')}: '
        {selectedRow.choiceNum ? `${selectedRow.choiceNum}. ${selectedRow.displayText}` : selectedRow.displayText}'
      </div>
    </div>
  );
};
