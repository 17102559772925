import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { find } from 'lodash';
import { openChatWindowAction } from '../../../store/redux/actions/researchDashboardActions';
import { useSessionUserSelector } from '../../../customHooks/reduxHelper';
import { RESPONSE_SET_TYPE, STIM_TYPE, getJoinerType, isBipolar, ENGLISH } from '../../../util/joinerUtil';
import { getJoinersOrderedByParticipantAnswers, getConceptAndJoinerTitles } from '../../../util/conceptRotationUtil';
import chatUtil from '../../../components/chat/chatUtil';
import { mediaUtil } from '../../../util/mediaUtil';
import localtime from '../../../util/localtime';
import {
  getRDQuestionJoiners,
  getRDParticipantAnswers,
  getRDChats
} from '../../../store/redux/selectors/researchDashboardSelector';
import { Icons } from '../../../components/icons/Icons';
import { VideoResponseIcon } from '../../../components/videoResponse/VideoResponseIcon';
import webcontent_src from '../../../images/icons8-internet.png';

import './ParticipantResponses.css';

function getTableHeader() {
  return (
    <thead>
      <tr>
        <th className="qNum-col">#</th>
        <th className="group-col">Group</th>
        <th className="title-col">Title</th>
        <th className="content-col">Content</th>
        <th className="type-col">Type</th>
        <th className="chat-col">Chat</th>
        <th className="time-col">Time</th>
      </tr>
    </thead>
  );
}

function findAnswer(answers, joinerId) {
  const answer = find(answers, ans => ans.questionJoinerId === joinerId);
  return answer ? answer.answer.answer : null;
}

function getQuestionText(joiner) {
  let questionText = joiner.def && joiner.def.question && joiner.def.question.prompt;
  if (!questionText) {
    questionText = joiner.stim && (joiner.stim.caption || joiner.stim.contents);
  }
  return questionText;
}

// functions for building question content - start

function getMediaContent(joiner, ignoreTextStim) {
  const { type, media, contents } = joiner.stim || {};
  switch (type) {
    case STIM_TYPE.image:
      return media ? <img src={mediaUtil.getMediaUrl(media)} alt="" /> : null;
    case STIM_TYPE.text:
      return ignoreTextStim ? '' : contents;
    case STIM_TYPE.video:
      return <Icons.VideoIcon className="fa-3x" />;
    case STIM_TYPE.webcontent:
      return <img src={webcontent_src} alt="" />;
    default:
      return null;
  }
}

function getImageChoice(choice, suffix = '') {
  const caption = (choice.value.imageStim.caption || choice.value.abbreviatedValue) + suffix;
  return (
    <span>
      <img className="choice-image" src={mediaUtil.getMediaUrl(choice.value.imageStim.media)} alt="" /> {caption}
    </span>
  );
}

function getChoiceAnswer(joiner, answerId, inEnglish) {
  const choice = find(joiner.def.responseSet.choices, choice => choice.id === answerId);
  if (choice.value.type === 'imagelabel') {
    return getImageChoice(choice);
  }
  return inEnglish ? choice.value.origValue : choice.value.value;
}

function getChoices(joiner, answerIds, inEnglish) {
  const answerItems = answerIds.map(ansId => {
    const choice = getChoiceAnswer(joiner, ansId, inEnglish);
    return <li key={ansId}>{choice}</li>;
  });
  return <ul>{answerItems}</ul>;
}

function getRowLabel(row, inEnglish) {
  const value = inEnglish ? 'origValue' : 'value';
  switch (row.value.type) {
    case 'imagelabel':
      return getImageChoice(row, ': ');
    case 'stringlabel':
    default:
      return <span>{row.value[value]}: </span>;
  }
}

function getMatrixChoices(joiner, answerData, inEnglish) {
  const { responseSet } = joiner.def;
  const { columnData, rows, bipolarRows } = responseSet.entries;
  const value = inEnglish ? 'origValue' : 'value';
  const choiceRows = isBipolar(responseSet) ? bipolarRows : rows;
  const answerItems = choiceRows
    .filter(row => !row.value.disable)
    .map(row => {
      const choices = answerData[row.id];
      const choiceValues = choices
        ? choices.map(choice => columnData.columns.find(col => col.id === choice).value[value])
        : [];
      const choicesStr = choiceValues.length > 1 ? choiceValues.join(', ') : choiceValues[0];
      return (
        <li key={row.id}>
          {getRowLabel(row, inEnglish)}
          <span>{choicesStr}</span>
        </li>
      );
    });
  return <ul>{answerItems}</ul>;
}

function getAnswer(joiner, type, answerValue, inEnglish) {
  const label = 'app.answer';
  switch (type) {
    case RESPONSE_SET_TYPE.open:
      return {
        label,
        content: answerValue
      };
    case RESPONSE_SET_TYPE.multi:
      return {
        label: answerValue.length > 1 ? 'app.answers' : label,
        content: getChoices(joiner, answerValue, inEnglish)
      };
    case RESPONSE_SET_TYPE.ranked:
    case RESPONSE_SET_TYPE.matrix:
      const { responseSet } = joiner.def;
      const { rows, bipolarRows } = responseSet.entries;
      const answerRows = isBipolar(responseSet) ? bipolarRows : rows;
      return {
        label: answerRows.length > 1 ? 'app.answers' : label,
        content: getMatrixChoices(joiner, answerValue, inEnglish)
      };
    default:
  }
  return {};
}

// functions for building question content - end

function getTimestamp(answer) {
  const { createDate } = (answer && answer.answer) || {};
  if (!createDate) {
    return '';
  }
  return localtime.getLocalTime({
    date: createDate,
    offset: new Date().getTimezoneOffset() * -1,
    stringDateFormat: 'HH:mm:ss'
  });
}

export const ParticipantResponses = props => {
  const { sessionId, participant } = props;
  const { id: participantId, nickname } = participant;

  const dispatch = useDispatch();

  const userId = useSessionUserSelector().sessionUser.userID;
  const questionJoiners = useSelector(state => getRDQuestionJoiners(state, sessionId), shallowEqual);
  const answers = useSelector(state => getRDParticipantAnswers(state, sessionId, participantId), shallowEqual);
  const chats = useSelector(state => getRDChats(state, sessionId), shallowEqual);

  function startChat({ joiner, chatTitle, chatItem }) {
    const { stim } = joiner;
    let chatPayload = {
      sessionId,
      researcherId: userId
    };
    if (chatItem && chatItem.id) {
      chatPayload.id = chatItem.id;
    } else {
      const answer = findAnswer(answers, joiner.id);
      Object.assign(chatPayload, {
        participantId,
        chatTitle,
        questionJoinerId: joiner.id,
        stim,
        questionText: getQuestionText(joiner),
        answers: { [participantId]: answer },
        responseSet: joiner.def ? joiner.def.responseSet : {}
      });
    }
    dispatch(openChatWindowAction.request(chatPayload));
  }

  function getChatIcon(joiner) {
    if (joiner.stimOnly || joiner.conceptRotation || joiner.def.responseSet.type === RESPONSE_SET_TYPE.thirdparty) {
      // Don't show the chat icon for stim-only, concept rotation, or thirdparty (dialsmith)
      return;
    }
    const chatItem = chatUtil.getChatItem({
      chats,
      participantId,
      questionJoinerId: joiner.id
    });
    const chatPayload = {
      joiner,
      chatTitle: nickname || participantId,
      chatItem
    };
    const hasMessage = chatItem && chatItem.messages.length > 0;
    const chattable = chatUtil.isActiveParticipant(participant);
    return (
      <div title={chattable || hasMessage ? '' : intl.get('app.noChat')}>
        <i
          className={`fas fa-comment chat-icon ${hasMessage ? 'existing-chat' : ''} ${
            chattable || hasMessage ? '' : 'disabled'
          }`}
          onClick={() => `${hasMessage || chattable ? startChat(chatPayload) : ''}`}
        />
      </div>
    );
  }

  function getQuestionAnswersContent(joiner, type, answer, inEnglish) {
    const value = inEnglish ? 'value' : 'origValue';
    const prompt = inEnglish ? 'origPrompt' : 'prompt';
    const answerValue =
      type === RESPONSE_SET_TYPE.open ? answer && answer.answer.answer[value] : answer && answer.answer.answer.value;
    const pending = answer && answer.answer.answer.pending;
    const answerContent = answerValue && getAnswer(joiner, type, answerValue, inEnglish);
    const videoCaptureStatus = joiner.def.responseSet.videoCaptureEnabled && answer && answer.answer.videoCaptureStatus;

    return (
      <>
        <div className="question-image-and-prompt">
          {getMediaContent(joiner, true)}
          <div className="question-prompt">{joiner.def.question[prompt]}</div>
        </div>
        {(answerValue || videoCaptureStatus) && (
          <div className="answer-section">
            {answerContent && <div>{intl.get(answerContent.label)}: </div>}
            {videoCaptureStatus && <VideoResponseIcon videoCaptureStatus={videoCaptureStatus} />}
            {answerContent && <span>{answerContent.content}</span>}
            {pending && !answerContent && <span className="pending-answers">{intl.get('app.pending')} ...</span>}
          </div>
        )}
      </>
    );
  }

  function getJoinerContent(joiner, type, answer) {
    if (joiner.conceptRotation) {
      return intl.get('app.questionType.conceptRotation');
    }
    if (joiner.stimOnly) {
      return getMediaContent(joiner, false);
    }
    if (joiner.def && type !== RESPONSE_SET_TYPE.thirdparty && type !== RESPONSE_SET_TYPE.virtualFocusGroup) {
      return getQuestionAnswersContent(joiner, type, answer, props.viewLanguage === ENGLISH);
    }
    return;
  }

  function getTableBody() {
    // This gets a list or joiners as they were seen by the participant
    const orderedJoiners = getJoinersOrderedByParticipantAnswers({
      answers,
      joiners: questionJoiners
    });

    answers.forEach(answer => {
      const joiner = find(orderedJoiners, j => j && j.id === answer.answer.questionJoinerId);
      answer.answer.conceptRotationId = joiner && joiner.conceptRotationId;
    });

    const rows = [];
    orderedJoiners
      .filter(joiner => joiner)
      .forEach(joiner => {
        const answer = find(answers, ans => ans.questionJoinerId === joiner.id);
        const splitTitles =
          joiner.conceptId &&
          getConceptAndJoinerTitles({
            joiner,
            conceptRotationJoiner: find(orderedJoiners, j => j.id === joiner.conceptRotationId)
          });
        const groupName = splitTitles ? splitTitles.concept : joiner.groupName;
        const researchPrompt = splitTitles ? splitTitles.joiner : joiner.researchPrompt;
        const type = getJoinerType(joiner);
        const typeStr =
          type === RESPONSE_SET_TYPE.thirdparty
            ? intl.get('app.questionType.dialsmith')
            : intl.get(`app.questionType.${type}`);
        const hasAnswer =
          !!answer || (joiner.conceptRotation && answers.some(ans => ans.answer.conceptRotationId === joiner.id));

        rows.push(
          <tr className={!hasAnswer ? 'unanswered' : ''} key={joiner.id}>
            <td className="qNum-cell">{joiner.displayNumber}</td>
            <td className="group-cell" title={groupName}>
              {groupName}
            </td>
            <td className="title-cell" title={researchPrompt}>
              {researchPrompt}
            </td>
            <td className="content-cell">{getJoinerContent(joiner, type, answer)}</td>
            <td>{typeStr}</td>
            <td className="chat-cell">{getChatIcon(joiner)}</td>
            <td>{getTimestamp(answer)}</td>
          </tr>
        );
      });

    return <tbody>{rows}</tbody>;
  }

  return (
    <section className="participant-responses">
      <div className="fw-600">{intl.get('app.surveyResponses')}</div>
      <table className="invoke-table participant-responses-table">
        {getTableHeader()}
        {getTableBody()}
      </table>
    </section>
  );
};
