import React, { useReducer, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Label, Col, Row, Input, Button } from 'reactstrap';
import { Select } from 'antd';
import update from 'immutability-helper';
import { cloneDeep, isEqual } from 'lodash';
import { appUtil } from '../../../util/appUtil';
import { Loader, Logo } from 'webapp-common';
import UploadLogoModal from '../UploadLogoModal';
import { PreviewCustomUIModal } from './PreviewCustomUIModal';
import ColorPicker from '../../../components/core/colorPicker/ColorPicker';

import './ClientAdminCustomUIForm.css';

const defaultUIConfig = appUtil.getDefaultUIConfig();

const reducer = (state, payload) => ({ ...state, ...payload });

const FONTS = [
  'Helvetica, sans-serif',
  'Roboto, sans-serif',
  'Georgia, serif',
  'Times New Roman, serif',
  'Courier New, monospace',
  'Monaco, monospace'
];

const {
  headerColor,
  headerTextColor,
  buttonColor,
  buttonTextColor,
  secondaryButtonColor,
  secondaryButtonTextColor,
  footerBackground,
  footerTextColor
} = defaultUIConfig;

const CUSTOM_COLORS = [
  {
    target: 'headerColor',
    color: headerColor
  },
  {
    target: 'buttonColor',
    color: buttonColor
  },
  {
    target: 'secondaryButtonColor',
    color: secondaryButtonColor
  },
  {
    target: 'footerBackground',
    color: footerBackground
  }
];

const CUSTOM_TEXT = [
  {
    target: 'headerTextColor',
    color: headerTextColor
  },
  {
    target: 'buttonTextColor',
    color: buttonTextColor
  },
  {
    target: 'secondaryButtonTextColor',
    color: secondaryButtonTextColor
  },
  {
    target: 'footerTextColor',
    color: footerTextColor
  }
];

export default function ClientAdminCustomUIForm(props) {
  const { client = {}, abilities, saveClientInProgress, updateLogoInProgress } = props;
  const { isInternalSuperUser, hasOrgManage } = abilities;

  const [state, setState] = useReducer(reducer, {
    saveDisabled: true,
    showPreviewModal: false,
    showLogoUploadModal: false,
    showColorPicker: false,
    client: cloneDeep(client)
  });

  useEffect(() => {
    if (state.client.logoUrl !== props.client.logoUrl) {
      const client = update(state.client, {
        $merge: {
          logoUrl: props.client.logoUrl
        }
      });
      setState({ client });
    }
  }, [props.client.logoUrl]);

  useEffect(() => {
    setState({ client });
  }, [client.clientUIConfig]);

  const toggle = target => {
    if (isInternalSuperUser) {
      setState({ [target]: !state[target] });
    }
  };

  const resetDefaultLogo = () => {
    props.updateTenantLogo(state.client, { accessUrl: '' });
  };

  const uploadLogo = logoFile => {
    props.uploadLogo(state.client, logoFile);
    toggle('showLogoUploadModal');
  };

  const setColor = (target, value) => {
    const clientUIConfig = state.client.clientUIConfig
      ? update(state.client.clientUIConfig, {
          $merge: { [target]: value.toUpperCase() }
        })
      : { [target]: value.toUpperCase() };
    const client = update(state.client, {
      $merge: { clientUIConfig }
    });
    setState({ client });
  };

  const toggleColorPicker = target => {
    if (isInternalSuperUser) {
      setState({ showColorPicker: !state.showColorPicker, target });
    }
  };

  const getColorRows = rows => {
    return rows.map(row => {
      const visibility = row.target ? 'visible' : 'hidden';
      return (
        <Row className="mt-2 mb-3" key={row.target}>
          <Label sm="5">{intl.get(`app.${row.target}`)}</Label>
          <div className="col-sm-5 flex-row" style={{ visibility }}>
            <Input
              value={state.client.clientUIConfig?.[row.target] || ''}
              placeholder={row.color}
              onChange={e => setColor(row.target, e.target.value)}
              disabled={!isInternalSuperUser}
            />
            <div
              className="square"
              onClick={() => toggleColorPicker(row.target)}
              style={{
                backgroundColor: state.client.clientUIConfig?.[row.target] || row.color
              }}
            />
          </div>
        </Row>
      );
    });
  };

  const handleSave = () => {
    props.saveClient(state.client);
  };

  const handleClose = () => {
    setState({ showColorPicker: false });
  };

  const saveColor = (color, target) => {
    setColor(target, color.hex);
  };

  const handleResetStyles = () => {
    const client = update(state.client, {
      clientUIConfig: { $set: {} }
    });
    if (window.confirm(intl.get('app.resetStyles.message'))) {
      props.saveClient(client);
    }
  };

  const getFontOptions = () =>
    FONTS.map(font => (
      <Select.Option value={font} title={font} key={font}>
        {font}
      </Select.Option>
    ));

  const onFontsChange = font => {
    const clientUIConfig = state.client.clientUIConfig
      ? update(state.client.clientUIConfig, {
          $merge: { fontFamily: font }
        })
      : { fontFamily: font };
    const client = update(state.client, {
      $merge: { clientUIConfig }
    });
    setState({ client });
  };

  if (isEqual(props.client.clientUIConfig, state.client.clientUIConfig) && !state.saveDisabled) {
    setState({ saveDisabled: true });
  } else if (!isEqual(props.client.clientUIConfig, state.client.clientUIConfig) && state.saveDisabled) {
    setState({ saveDisabled: false });
  }

  if (!client.clientID) {
    return <Loader spinner fullScreen />;
  }

  return (
    <div className="custom-ui-form">
      {hasOrgManage && (
        <>
          <Button className="ms-0" onClick={() => toggle('showPreviewModal')}>
            {intl.get('app.preview')}
          </Button>
          <Row className="mt-4">
            <Label sm="2">{intl.get('app.customLogo')}</Label>
            <Col sm="6" className="logo-controls">
              <div className="logoImgPreview">
                <Logo logoURL={state.client.logoUrl} />
              </div>
              <Button onClick={() => toggle('showLogoUploadModal')} disabled={!isInternalSuperUser}>
                {intl.get('app.uploadImage')}
              </Button>
              {client.logoUrl && isInternalSuperUser && (
                <Button className="link-button ms-5" onClick={resetDefaultLogo}>
                  {intl.get('app.useDefaultLogo')}
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm="6">{getColorRows(CUSTOM_COLORS)}</Col>
            <Col sm="6">{getColorRows(CUSTOM_TEXT)}</Col>
          </Row>
          <Row className="mt-4 mb-3" style={{ width: '50%' }}>
            <Label sm="5">{intl.get('app.fontFamily')}</Label>
            <div className="col-sm-6">
              <Select
                className="archive-select"
                placeholder={FONTS[0]}
                value={state.client.clientUIConfig?.fontFamily || undefined}
                onChange={e => onFontsChange(e)}
                disabled={!isInternalSuperUser}
              >
                {getFontOptions()}
              </Select>
            </div>
          </Row>
          <div className="update-buttons">
            <Button color="primary" disabled={state.saveDisabled} onClick={handleSave}>
              {intl.get('app.save')}
            </Button>
            <Button color="secondary" disabled={!state.client.clientUIConfig} onClick={handleResetStyles}>
              {intl.get('app.resetStyles')}
            </Button>
          </div>
        </>
      )}
      {state.showColorPicker && (
        <ColorPicker
          saveColor={saveColor}
          color={state.client.clientUIConfig || {}}
          handleClose={handleClose}
          target={state.target}
        />
      )}
      {state.showLogoUploadModal && (
        <UploadLogoModal
          show={state.showLogoUploadModal}
          toggle={() => toggle('showLogoUploadModal')}
          logoUrl={client.logoUrl}
          uploadLogo={uploadLogo}
        />
      )}
      {state.showPreviewModal && (
        <PreviewCustomUIModal
          toggle={() => toggle('showPreviewModal')}
          logoUrl={state.client.logoUrl}
          headerColor={state.client.clientUIConfig?.headerColor || headerColor}
          headerTextColor={state.client.clientUIConfig?.headerTextColor || headerTextColor}
          buttonColor={state.client.clientUIConfig?.buttonColor || buttonColor}
          buttonTextColor={state.client.clientUIConfig?.buttonTextColor || buttonTextColor}
          secondaryButtonColor={state.client.clientUIConfig?.secondaryButtonColor || secondaryButtonColor}
          secondaryButtonTextColor={state.client.clientUIConfig?.secondaryButtonTextColor || secondaryButtonTextColor}
          footerBackground={state.client.clientUIConfig?.footerBackground || footerBackground}
          footerTextColor={state.client.clientUIConfig?.footerTextColor || footerTextColor}
          fontFamily={state.client.clientUIConfig?.fontFamily}
        />
      )}
      {(saveClientInProgress || updateLogoInProgress) && <Loader spinner fullScreen />}
    </div>
  );
}
