import { createSelector, createStructuredSelector } from 'reselect';
import { projectSelector } from './projectSelector';

const selectParticipantLists = (project = {}) => project.project && project.project.participantLists;
const selectParticipantListsStatus = (project = {}) =>
  (project.project && project.project.participantListsRequested) || false;

const participantListsSelector = () => {
  const entityProjectSelector = projectSelector();
  return createSelector(
    entityProjectSelector,
    createStructuredSelector({
      participantLists: selectParticipantLists,
      participantListsRequested: selectParticipantListsStatus
    })
  );
};

export default participantListsSelector;
