import React from 'react';
import LoginPage from '../containers/pages/loginPage';
import ProjectPage from '../containers/pages/projectPage';
import ProjectsHomePage from '../containers/pages/projectsHome';
import LibraryPage from '../containers/pages/libraryPage';
import AdminHomePage from '../containers/pages/adminPage';
import OrgPage from '../containers/pages/orgPage';
import ProfilePage from '../containers/pages/profilePage';
import CallbackPage from '../containers/pages/callbackPage';
import HomePage from '../containers/pages/homePage';
import ReportsHomePage from '../containers/pages/reportsHome';

const NEED_USER_LOG_IN = true;

export const routes = [
  {
    path: '/login',
    authenticated: !NEED_USER_LOG_IN,
    component: () => <LoginPage />
  },
  {
    path: '/logout',
    authenticated: !NEED_USER_LOG_IN,
    component: () => <LoginPage doLogout />
  },
  {
    path: '/callback',
    authenticated: !NEED_USER_LOG_IN,
    component: props => <CallbackPage history={props.history} />
  },
  {
    path: '/home-page',
    authenticated: NEED_USER_LOG_IN,
    component: () => <HomePage />
  },
  {
    path: '/projects/:tab?',
    authenticated: NEED_USER_LOG_IN,
    component: () => <ProjectsHomePage />
  },
  {
    path: '/project/:projectId/:tab?/:subtab?/:subtab2?',
    authenticated: NEED_USER_LOG_IN,
    component: () => <ProjectPage />
  },
  {
    path: '/library/:tab?/:subtab?/:subtab2?',
    authenticated: NEED_USER_LOG_IN,
    component: () => <LibraryPage />
  },
  {
    path: '/reports/:tab?/:subtab?',
    authenticated: NEED_USER_LOG_IN,
    component: () => <ReportsHomePage />
  },
  {
    path: '/admin/:tab?/:subtab?',
    authenticated: NEED_USER_LOG_IN,
    component: () => <AdminHomePage />
  },
  {
    path: '/org/:tab?/:subtab?',
    authenticated: NEED_USER_LOG_IN,
    component: () => <OrgPage />
  },
  {
    path: '/profile',
    authenticated: NEED_USER_LOG_IN,
    component: () => <ProfilePage />
  },

  {
    path: '(.*)',
    authenticated: !NEED_USER_LOG_IN,
    component: () => <h1 style={{ textAlign: 'center', marginTop: '1rem' }}>404 Not Found</h1>
  }
];
