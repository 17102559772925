import { connect } from 'react-redux';
import { DataTableEditor } from './DataTableEditor';
import { dataTableSelector, dataTableRecordsSelector } from '../../../../store/redux/selectors/dataTableSelector';
import {
  fetchDataTableActions,
  fetchDataTableRecordsAction,
  deleteDataTableAction,
  replaceDataTableActions
} from '../../../../store/redux/actions/dataTableActions';

const mapStateToProps = (state, { dataTableId }) => {
  const selector = dataTableSelector()(state, dataTableId);
  const recordsSelector = dataTableRecordsSelector()(state, dataTableId);
  return {
    ...selector,
    ...recordsSelector,
    dataTableDeleted: state.dataTable && state.dataTable.dataTableDeleted
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDataTableRecords: params => dispatch(fetchDataTableRecordsAction.request(params)),
  fetchDataTable: dataTableId => dispatch(fetchDataTableActions.request({ dataTableId })),
  deleteDataTable: params => dispatch(deleteDataTableAction.request(params)),
  replaceDataTable: params => dispatch(replaceDataTableActions.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTableEditor);
