import { takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import { fetchUsersActions, fetchUserActions, saveUserActions } from '../actions/clientAdminActions';
import { fetchUsers, fetchUser, saveUser } from '../../../api/clientAdminApi';

function handleUserSaved({ payload: user }) {
  toast.success({ text: intl.get('app.user.saved', { name: user.email }) });
}

function* fetchUsersSaga() {
  const saga = createFetchSaga(fetchUsersActions, fetchUsers);
  yield* saga();
}

function* fetchUserSaga() {
  const saga = createFetchSaga(fetchUserActions, fetchUser);
  yield* saga();
}

function* saveUserSaga() {
  const saga = createFetchSaga(saveUserActions, saveUser);
  yield* saga();
  yield takeLatest(saveUserActions.succeeded, handleUserSaved);
}

export { fetchUsersSaga, fetchUserSaga, saveUserSaga };
