import { connect } from 'react-redux';
import { getAdvancedFiltered } from '../../../../store/redux/selectors/researchDashboardSelector';
import { checkFilterActions } from '../../../../store/redux/actions/filtersAndParticipantsActions';
import { AdvancedFilterBuilder } from './AdvancedFilterBuilder';

const mapStateToProps = (state, props) => {
  const { sessionId } = props;
  const filterResult = getAdvancedFiltered(state, sessionId);
  return {
    filterResult
  };
};

const mapDispatchToProps = dispatch => ({
  checkFilter: params => dispatch(checkFilterActions.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilterBuilder);
