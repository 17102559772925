import { createStructuredSelector, createSelector } from 'reselect';

const selectProjectListDetails = state => state.projectListDetails;
const selectProjectListRequested = projectListDetails => projectListDetails.projectListRequested;
const selectProjectPagination = projectListDetails => projectListDetails.pageRequest;
const selectProjectContent = projectListDetails => projectListDetails.content;
const selectProjectTotalElements = projectListDetails => projectListDetails.totalElements;

const selectRecentProjects = projectListDetails => projectListDetails.recentProjects;

const selectAllProjectsRequested = projectListDetails => projectListDetails.allProjectsRequested;
const selectAllProjects = projectListDetails => projectListDetails.allProjects.content;

const archivedProjectListRequested = projectListDetails =>
  (projectListDetails && projectListDetails.projectListRequested) || false;
const archivedProjectPagination = projectListDetails =>
  (projectListDetails.archivedProjects && projectListDetails.archivedProjects.pageRequest) || {};
const archivedProjectContent = projectListDetails =>
  (projectListDetails.archivedProjects && projectListDetails.archivedProjects.content) || [];
const archivedProjectTotalElements = projectListDetails =>
  (projectListDetails.archivedProjects && projectListDetails.archivedProjects.totalElements) || 0;

const projectListSelector = createSelector(
  selectProjectListDetails,
  createStructuredSelector({
    projectListRequested: selectProjectListRequested,
    projects: selectProjectContent,
    pagination: selectProjectPagination,
    totalElements: selectProjectTotalElements
  })
);

const recentProjectsSelector = createSelector(
  selectProjectListDetails,
  createStructuredSelector({
    projectListRequested: selectProjectListRequested,
    recentProjects: selectRecentProjects
  })
);

const allProjectsSelector = createSelector(
  selectProjectListDetails,
  createStructuredSelector({
    allProjectsRequested: selectAllProjectsRequested,
    allProjects: selectAllProjects
  })
);

const archivedProjectListSelector = createSelector(
  selectProjectListDetails,
  createStructuredSelector({
    archiveProjectListRequested: archivedProjectListRequested,
    archivedProjects: archivedProjectContent,
    pagination: archivedProjectPagination,
    totalElements: archivedProjectTotalElements
  })
);

export { projectListSelector, recentProjectsSelector, allProjectsSelector, archivedProjectListSelector };
