import React from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { accessHandler } from 'webapp-common';
import { getFlattenedJoinersList } from '../../../../util/conceptRotationUtil';
import { SelectedQuestionsConfig } from '../common/surveyQuestionPicker/SelectedQuestionsConfig';
import { SigTesting } from '../common/sigTesting/SigTesting';
import { ReportFilterList } from '../common/reportFilterList/ReportFilterList';

// From VerbatimsDisplay.java
const verbatimsDisplay = {
  CONSOLIDATED: 'CONSOLIDATED',
  INDIVIDUAL: 'INDIVIDUAL',
  BOTH: 'BOTH'
};

export const ChartReport = props => {
  const {
    reportConfig,
    sessions,
    consolidatedReportData,
    isConsolidatedReport,
    videoCaptureEnabled,
    setField,
    updateReportConfig,
    setSigTestingField,
    generateReport,
    hasReportsView
  } = props;

  const { includeAllParticipants } = reportConfig;

  const onIncludeAllParticipantsChanged = e => {
    setField('includeAllParticipants', e.target.checked);
  };

  const accessCheck = accessHandler.checkAccess(hasReportsView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  function setFilters(filters) {
    setField('participantFilters', filters);
  }

  const questions = reportConfig.includeAllQuestions
    ? getFlattenedJoinersList(consolidatedReportData.consolidatedQuestion).length
    : reportConfig.selectedQuestionJoinerIds.length;

  const validateClusterExport = () => {
    return (
      !reportConfig.responseClusters ||
      reportConfig.wordClouds ||
      reportConfig.sentiment ||
      reportConfig.filterSubtotals ||
      reportConfig.individualResponses
    );
  };

  const exportDisabled = questions <= 0 || !validateClusterExport();

  const oneSessionSelected = reportConfig.sessionIds?.length === 1;

  const warning =
    reportConfig.responseClusters &&
    !reportConfig.wordClouds &&
    !reportConfig.sentiment &&
    !reportConfig.filterSubtotals &&
    !reportConfig.individualResponses;
  const noFiltersWarning =
    !includeAllParticipants && (!reportConfig.participantFilters || reportConfig.participantFilters.length === 0);

  return (
    <section className="chart-report">
      <div className="config-area">
        <div className="config-column">
          <SelectedQuestionsConfig
            reportConfig={reportConfig}
            consolidatedReportData={consolidatedReportData}
            updateReportConfig={updateReportConfig}
          />

          <div style={{ borderColor: warning ? 'red' : 'inherit' }}>
            <div>{intl.get('app.verbatimQuestions')}</div>
            <label style={{ display: isConsolidatedReport ? 'block' : 'none' }}>
              <Input
                type="radio"
                checked={reportConfig.verbatimsDisplay === verbatimsDisplay.CONSOLIDATED}
                onChange={() => setField('verbatimsDisplay', verbatimsDisplay.CONSOLIDATED)}
              />
              {intl.get('app.consolidateQuestions')}
            </label>
            <label style={{ display: isConsolidatedReport ? 'block' : 'none' }}>
              <Input
                type="radio"
                checked={reportConfig.verbatimsDisplay === verbatimsDisplay.INDIVIDUAL}
                onChange={() => setField('verbatimsDisplay', verbatimsDisplay.INDIVIDUAL)}
              />
              {intl.get('app.individualQuestions')}
            </label>
            <label style={{ display: isConsolidatedReport ? 'block' : 'none' }}>
              <Input
                type="radio"
                checked={reportConfig.verbatimsDisplay === verbatimsDisplay.BOTH}
                onChange={() => setField('verbatimsDisplay', verbatimsDisplay.BOTH)}
              />
              {intl.get('app.both')}
            </label>
            {oneSessionSelected && (
              <>
                <label>
                  <Input
                    type="checkbox"
                    checked={reportConfig.responseClusters}
                    onChange={() => setField('responseClusters', !reportConfig.responseClusters)}
                  />
                  {intl.get('app.responseClusters')}
                </label>
                <label className="subCheck" style={{ color: warning ? 'red' : 'inherit' }}>
                  <Input
                    type="checkbox"
                    checked={reportConfig.wordClouds}
                    onChange={() => setField('wordClouds', !reportConfig.wordClouds)}
                    disabled={!reportConfig.responseClusters}
                  />
                  {intl.get('app.wordClouds')}
                </label>
                <label className="subCheck" style={{ color: warning ? 'red' : 'inherit' }}>
                  <Input
                    type="checkbox"
                    checked={reportConfig.sentiment}
                    onChange={() => setField('sentiment', !reportConfig.sentiment)}
                    disabled={!reportConfig.responseClusters}
                  />
                  {intl.get('app.sentiment')}
                </label>
                <label className="subCheck" style={{ color: warning ? 'red' : 'inherit' }}>
                  <Input
                    type="checkbox"
                    checked={reportConfig.filterSubtotals}
                    onChange={() => setField('filterSubtotals', !reportConfig.filterSubtotals)}
                    disabled={!reportConfig.responseClusters}
                  />
                  {intl.get('app.filterSubtotals')}
                </label>
                <label className="subCheck" style={{ color: warning ? 'red' : 'inherit' }}>
                  <Input
                    type="checkbox"
                    checked={reportConfig.individualResponses}
                    onChange={() => setField('individualResponses', !reportConfig.individualResponses)}
                    disabled={!reportConfig.responseClusters}
                  />
                  {intl.get('app.individualResponses')}
                </label>
              </>
            )}
          </div>

          <Button
            color="primary"
            style={{ marginLeft: 0 }}
            onClick={generateReport}
            disabled={exportDisabled || warning || noFiltersWarning}
          >
            {intl.get('app.createReport')}
          </Button>
        </div>

        <div className="config-column">
          <div style={{ paddingRight: '5rem' }}>
            <div>{intl.get('app.comparativeViews')}</div>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.includeComparativeViews}
                onChange={() => setField('includeComparativeViews', !reportConfig.includeComparativeViews)}
              />
              {intl.get('app.includeComparativeViews')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.sigTestEnabled}
                onChange={() => setField('sigTestEnabled', !reportConfig.sigTestEnabled)}
              />
              {intl.get('app.statistics.significanceTesting')}
            </label>
            <SigTesting
              sigTestSetting={reportConfig.sigTestSetting}
              setSigTestingField={setSigTestingField}
              disabled={!reportConfig.sigTestEnabled}
            />
          </div>
        </div>

        <div className="config-column">
          <div style={{ borderColor: noFiltersWarning ? 'red' : 'inherit' }}>
            <div>{intl.get('app.filter')}</div>
            <label style={{ color: noFiltersWarning ? 'red' : 'inherit' }}>
              {' '}
              <Input type="checkbox" checked={includeAllParticipants} onChange={onIncludeAllParticipantsChanged} />
              {intl.get('app.allParticipants')}
            </label>
            <ReportFilterList
              selectedFilters={reportConfig.participantFilters}
              warning={noFiltersWarning}
              sessions={sessions}
              consolidatedReportData={consolidatedReportData}
              videoCaptureEnabled={videoCaptureEnabled}
              setFilters={setFilters}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
