import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Cookies from 'js-cookie';
import { Fonts, InvokeCSS } from 'webapp-common';
import unregister from './registerServiceWorker';
import appConfig from './appConfig';
import { locale as en_US } from './locales/en-US';
import localtime from './util/localtime';
import { validateAuth } from './store/redux/actions/authActions';
import { configureStore } from './store/redux/store';
import { configureSaga } from './store/redux/sagas/saga';
import App from './containers/app';

import './index.css';

const locales = {
  'en-US': en_US
};

if (!Cookies.get(appConfig.offsetCookieName)) {
  Cookies.set(appConfig.offsetCookieName, localtime.getOffset());
}
if (!Cookies.get(appConfig.timezoneCookieName)) {
  Cookies.set(appConfig.timezoneCookieName, localtime.getTimezoneName());
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
  maxBreadcrumbs: process.env.SENTRY_MAX_BREADCRUMBS,
  tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
  environment: process.env.SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()]
});

const store = configureStore();

configureSaga();

// Remove obsolete service worker after upgrade to CRA 5
unregister();

function authValidator() {
  store.dispatch(validateAuth());
  if (Cookies.get(appConfig.authCookieName)) {
    setTimeout(authValidator, 1000);
  }
}

setTimeout(authValidator, 1000);

const queryClient = new QueryClient();

// Init i18n. We currently only support en-US.
intl
  .init({
    currentLocale: 'en-US',
    locales
  })
  .then(() => {
    const root = createRoot(document.getElementById('root'));
    root.render(
      <Provider store={store}>
        <BrowserRouter basename="/f">
          <InvokeCSS />
          <Fonts />
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    );
  });
