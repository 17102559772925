import { takeLatest, put, take } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  archiveProjectActions,
  restoreArchivedProjectAction,
  deleteArchivedProjectAction
} from '../actions/archiveProjectActions';
import { fetchArchivedProjectListActions } from '../actions/projectListActions';
import { archiveProject, restoreArchivedProject, deleteArchivedProject } from '../../../api/projectsApi';

function* archiveProjectSaga() {
  const saga = createFetchSaga(archiveProjectActions, archiveProject);
  yield* saga();
  yield takeLatest(archiveProjectActions.succeeded, archiveSuccess);
}

function* restoreArchivedProjectSaga() {
  const saga = createFetchSaga(restoreArchivedProjectAction, restoreArchivedProject);
  yield* saga();
  yield takeLatest(restoreArchivedProjectAction.succeeded, archiveSuccess);
}

function* deleteArchivedProjectSaga() {
  const saga = createFetchSaga(deleteArchivedProjectAction, deleteArchivedProject);
  try {
    yield* saga();
    yield takeLatest(deleteArchivedProjectAction.succeeded, archiveSuccess);
  } catch (error) {
    yield take(deleteArchivedProjectAction.failed, archiveFailed);
  }
}

function* archiveSuccess({ payload }) {
  toast.success({ text: intl.get(`app.archive.project${payload.message}`, { project: payload.projectName }) });
  yield put(fetchArchivedProjectListActions.request({}));
}

function archiveFailed(error) {
  toast.error({ text: intl.get('app.errorEncountered', { error }) });
}

export { archiveProjectSaga, restoreArchivedProjectSaga, deleteArchivedProjectSaga };
