import { takeEvery } from 'redux-saga/effects';
import createFetchSaga from './createFetchSaga';
import {
  filtersAndParticipantsSubscribeActions,
  checkFilterActions,
  setParticipantGroupActions
} from '../actions/filtersAndParticipantsActions';
import { checkFilterApi } from '../../../api/researchDashboardApi';
import { setParticipantGroup } from '../../../api/participantData';
import { subscribeToChannel } from '../../../util/websocket';

function* filtersAndParticipantsChannelSubscribeSaga() {
  yield takeEvery(filtersAndParticipantsSubscribeActions.request, filtersAndParticipantsChannelSubscribe);
}

function* filtersAndParticipantsChannelSubscribe({ payload: { subAction, sessionId } }) {
  yield* subscribeToChannel({
    action: 'filtersAndParticipants',
    subAction,
    key: sessionId,
    sessionId
  });
}

function* checkFilterSaga() {
  const saga = createFetchSaga(checkFilterActions, checkFilterApi);
  yield* saga();
}

function* setParticipantGroupSaga() {
  const saga = createFetchSaga(setParticipantGroupActions, setParticipantGroup);
  yield* saga();
}

export { filtersAndParticipantsChannelSubscribeSaga, checkFilterSaga, setParticipantGroupSaga };
