import { handleActions } from 'redux-actions';
import {
  fetchRolesSummaryAction,
  fetchTenantRolesAction,
  updateTenantRolesAction,
  deleteTenantRolesAction,
  addRoleAction
} from '../actions/clientAdminActions';

const initialState = {
  summary: {
    content: [],
    pageRequest: {}
  },
  roles: [],
  rolesSummaryRequested: false,
  fetchTenantRolesRequested: false,
  updateTenantRolesRequested: false,
  deleteTenantRolesRequested: false,
  addRoleRequested: false,
  roleUpdateSuccess: false
};

const reducer = handleActions(
  {
    [fetchRolesSummaryAction.triggered](state) {
      return {
        ...state,
        rolesSummaryRequested: true,
        roleUpdateSuccess: false
      };
    },
    [fetchRolesSummaryAction.succeeded](state, { payload }) {
      return {
        ...state,
        summary: payload,
        rolesSummaryRequested: false
      };
    },
    [fetchRolesSummaryAction.failed](state) {
      return {
        ...state,
        rolesSummaryRequested: false
      };
    },

    [fetchTenantRolesAction.triggered](state) {
      return {
        ...state,
        fetchTenantRolesRequested: true,
        roleUpdateSuccess: false
      };
    },
    [fetchTenantRolesAction.succeeded](state, { payload }) {
      return {
        ...state,
        roles: payload.content,
        fetchTenantRolesRequested: false
      };
    },
    [fetchTenantRolesAction.failed](state) {
      return {
        ...state,
        fetchTenantRolesRequested: false
      };
    },

    [updateTenantRolesAction.triggered](state) {
      return {
        ...state,
        updateTenantRolesRequested: true,
        roleUpdateSuccess: false
      };
    },
    [updateTenantRolesAction.succeeded](state, { payload }) {
      const index = state.roles.findIndex(role => role.tenantRoleID === payload.tenantRoleID);
      state.roles[index].featureAbilities = payload.featureAbilities;
      return {
        ...state,
        updateTenantRolesRequested: false,
        roleUpdateSuccess: true
      };
    },
    [updateTenantRolesAction.failed](state) {
      return {
        ...state,
        updateTenantRolesRequested: false
      };
    },

    [deleteTenantRolesAction.triggered](state) {
      return {
        ...state,
        deleteTenantRolesRequested: true,
        roleUpdateSuccess: false
      };
    },
    [deleteTenantRolesAction.succeeded](state) {
      return {
        ...state,
        deleteTenantRolesRequested: false,
        roleUpdateSuccess: true
      };
    },
    [deleteTenantRolesAction.failed](state) {
      return {
        ...state,
        deleteTenantRolesRequested: false
      };
    },

    [addRoleAction.triggered](state) {
      return {
        ...state,
        addRoleRequested: true,
        addRoleSuccess: false,
        roleUpdateSuccess: false
      };
    },
    [addRoleAction.succeeded](state) {
      return {
        ...state,
        addRoleRequested: false,
        addRoleSuccess: true,
        roleUpdateSuccess: true
      };
    },
    [addRoleAction.failed](state) {
      return {
        ...state,
        addRoleRequested: false
      };
    }
  },
  initialState
);

export default reducer;
