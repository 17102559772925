import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { EXIT_PAGE_TYPE } from '../../../../../util/joinerUtil';

import './DialSmithStimOptions.css';

class DialSmithStimOptions extends React.Component {
  state = {
    exitPage: this.props.joiner.stim.exitPage
  };

  updateExitPage(e) {
    this.setState({
      exitPage: e.target.value
    });
    this.props.updateHandlers.updateExitPage(e);
  }

  render() {
    return (
      <div className={`${this.props.tabs.options} dialsmith-options`}>
        <div className="flex-row">
          <label disabled={this.props.readOnly}>
            <Input
              type="checkbox"
              checked={this.props.joiner.hidden}
              onChange={e => this.props.updateHandlers.updateHidden(e.target.checked)}
            />
            {intl.get('app.hideQuestion')}
          </label>
        </div>
        <div className="flex-row exit-page-selector-row">
          {intl.get('app.exitPage')}
          <Input
            type="select"
            value={this.state.exitPage}
            onChange={e => this.updateExitPage(e)}
            disabled={this.props.readOnly}
          >
            <option value={EXIT_PAGE_TYPE.DIALSMITH_COMPLETE}>{intl.get('app.videoComplete')}</option>
            <option value={EXIT_PAGE_TYPE.DIALSMITH_CONTINUE_SESSION}>{intl.get('app.continueSession')}</option>
          </Input>
        </div>
      </div>
    );
  }
}

export default DialSmithStimOptions;
