import LoginPage from './LoginPage';
import { authSelector } from '../../../store/redux/selectors/authSelectors';
import { connect } from 'react-redux';
import { validateAuth, validateAuthFailed } from '../../../store/redux/actions/authActions';

const mapStateToProps = state => {
  return {
    authToken: authSelector(state).authToken,
    authError: authSelector(state).error
  };
};

const mapDispatchToProps = dispatch => ({
  validateAuth: options => dispatch(validateAuth(options)),
  handleAuthError: error => dispatch(validateAuthFailed(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
