import React, { useEffect, useReducer } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useOutsideClickDetector } from 'webapp-common';
import {
  getRDConfig,
  getRDEnrollees,
  getRDParticipantSummaryFilters,
  getRDSessionQuotaFilters
} from '../../../store/redux/selectors/researchDashboardSelector';
import { saveRDConfig } from '../../../util/researchDashboardUtil';
import { FilterSummaryBox } from './FilterSummaryBox';

import './FilterSummaries.css';

const reducer = (state, payload) => ({ ...state, ...payload });

function getParticipantCount(enrolleesInfo) {
  const { totalAccepted = 0, activeEnrolleeCount = 0, enrolleeMap = {} } = enrolleesInfo;
  return totalAccepted > 0 ? activeEnrolleeCount : Object.keys(enrolleeMap).length;
}

export const FilterSummaries = props => {
  const { sessionId } = props;

  const [state, setState] = useReducer(reducer, {
    filterSummaryListConfig: null,
    showFilterSelect: false,
    isDragging: false
  });

  const enrolleesInfo = useSelector(state => getRDEnrollees(state, sessionId), shallowEqual);
  const quotaFilters = useSelector(state => getRDSessionQuotaFilters(state, sessionId), shallowEqual);
  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  // This is the list of filters with counts from the back-end
  const summaryFilters = useSelector(state => getRDParticipantSummaryFilters(state, sessionId), shallowEqual);

  function handleOutsideFilterSelectClick() {
    if (state.showFilterSelect) {
      setState({ showFilterSelect: false });
    }
  }

  useOutsideClickDetector(['filter-select'], handleOutsideFilterSelectClick, null);

  // Initialize (or update) the filterSummaryListConfig in the state
  useEffect(() => {
    const { filterSummaryListConfig } = rdConfig.configs || {};
    if (filterSummaryListConfig && !isEqual(state.filterSummaryListConfig, filterSummaryListConfig)) {
      setState({ filterSummaryListConfig });
    }
  }, [rdConfig]);

  function updateFilterSummaryListConfig(filterSummaryListConfig) {
    const rdConfigCopy = cloneDeep(rdConfig);
    rdConfigCopy.configs.filterSummaryListConfig = filterSummaryListConfig;
    saveRDConfig(rdConfigCopy);
  }

  function addFilter(filter) {
    const filterSummaryListConfig = cloneDeep(state.filterSummaryListConfig);
    filterSummaryListConfig.filterSummaryList.push(filter);
    updateFilterSummaryListConfig(filterSummaryListConfig);
    setState({ filterSummaryListConfig, showFilterSelect: false });
  }

  function removeFilter(filter) {
    const filterSummaryListConfig = cloneDeep(state.filterSummaryListConfig);
    const { filterSummaryList } = filterSummaryListConfig;
    const index = filterSummaryList.findIndex(f => f.id === filter.id);
    filterSummaryList.splice(index, 1);
    updateFilterSummaryListConfig(filterSummaryListConfig);
    setState({ filterSummaryListConfig });
  }

  function moveFilter(srcIndex, targetIndex) {
    const filterSummaryListConfig = cloneDeep(state.filterSummaryListConfig);
    const { filterSummaryList } = filterSummaryListConfig;
    const [removed] = filterSummaryList.splice(srcIndex, 1);
    filterSummaryList.splice(targetIndex, 0, removed);
    updateFilterSummaryListConfig(filterSummaryListConfig);
    setState({ filterSummaryListConfig });
  }

  function onDragStart(arg) {
    setState({ isDragging: true });
  }

  function onDragEnd(result) {
    setState({ isDragging: false });
    if (!result.destination) {
      // dropped outside the list
      return;
    }
    moveFilter(result.source.index, result.destination.index);
  }

  function getFilterSummaries() {
    const filterSelectList = getFilterSelectList();
    const { filterSummaryList = [] } = state.filterSummaryListConfig || {};
    const participantCount = getParticipantCount(enrolleesInfo);
    const filterSummaries = [];
    let index = 0;
    filterSummaryList.forEach(filter => {
      const found = summaryFilters.find(f => filter.id === f.id);
      if (found) {
        filterSummaries.push(
          <Draggable key={found.id} draggableId={found.id} index={index}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <FilterSummaryBox filter={found} participantCount={participantCount} removeFilter={removeFilter} />
              </div>
            )}
          </Draggable>
        );
        index++;
      }
    });

    // Add the filter select control if applicable
    if (filterSelectList.length !== 0 && !state.isDragging) {
      filterSummaries.push(
        <div className="filter-select" key="filter-select-control">
          <div onClick={() => setState({ showFilterSelect: !state.showFilterSelect })}>
            <i className="fas fa-plus" />
            {filterSummaries.length === 0 && (
              <span className="add-filter-text"> {intl.get('app.addFilterSummary')}</span>
            )}
          </div>
          {state.showFilterSelect && <div className="filter-select-list">{filterSelectList}</div>}
        </div>
      );
    }

    return filterSummaries;
  }

  function getFilterSelectList() {
    const availableFilters = [];
    const filters = rdConfig?.configs?.filterListConfig?.filters || [];
    const allFilters = [...filters, ...quotaFilters];
    allFilters.forEach(f => {
      if (f.type !== 'DEFAULT_FILTER') {
        const found = summaryFilters.find(sf => f.id === sf.id);
        if (!found) {
          availableFilters.push(
            <div title={f.name} onClick={() => addFilter(f)} key={f.id}>
              {f.name}
            </div>
          );
        }
      }
    });
    return availableFilters;
  }

  return (
    <section className="filter-summaries">
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }} ref={provided.innerRef} {...provided.droppableProps}>
              {getFilterSummaries()}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};
