import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { FlexRow } from 'webapp-common';
import { ENGLISH, isRequired, MATRIX_TYPE } from '../../../../../util/joinerUtil';

class MatrixOptions extends React.Component {
  updateMatrixType = e => {
    this.props.updateHandlers.updateMatrixType(e.target.value);
  };

  updateMatrixRangeLabels = (rangeLabels, index, e) => {
    this.setRangeLabel(this.props.language, this.props.viewLanguage, e.target.value, rangeLabels[index]);
    this.props.updateHandlers.updateMatrixRangeLabels(rangeLabels);
  };

  getRangeLabelChoice = (rangeLabel, language, viewLanguage) => {
    return (language === viewLanguage ? rangeLabel.label : rangeLabel.origLabel) || '';
  };

  setRangeLabel = (language, viewLanguage, input, output) => {
    if (language === viewLanguage) {
      output.label = input;
    }
    if (viewLanguage === ENGLISH) {
      output.origLabel = input;
    }
  };

  render() {
    const { tabs, joiner, updateHandlers, readOnly, language, viewLanguage } = this.props;
    const { responseSet } = joiner.def;
    const { matrixType, entries } = responseSet;
    const rangeLabels =
      entries.rangeLabels.length === 3
        ? cloneDeep(entries.rangeLabels)
        : [
            { index: '0', label: '', origLabel: '' },
            { index: '1', label: '', origLabel: '' },
            { index: '2', label: '', origLabel: '' }
          ];

    return (
      <div className={`${tabs.options} matrix-options`}>
        <label disabled={readOnly}>
          <Input type="checkbox" checked={isRequired(joiner)} onChange={updateHandlers.updateQuestionRequired} />
          {intl.get('app.questionRequired')}
        </label>
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={joiner.hidden}
            onChange={e => updateHandlers.updateHidden(e.target.checked)}
          />
          {intl.get('app.hideQuestion')}
        </label>
        <label disabled={readOnly}>
          <Input type="checkbox" checked={responseSet.randomOptions} onChange={updateHandlers.toggleRandomOptions} />
          {intl.get('app.randomizeRows')}
        </label>
        <FlexRow alignItems="flex-start">
          {intl.get('app.choiceType')}:
          <div className="matrix-types">
            <label disabled={readOnly}>
              <Input
                type="radio"
                name="matrixType"
                value={MATRIX_TYPE.singleChoice}
                checked={matrixType === MATRIX_TYPE.singleChoice}
                onChange={this.updateMatrixType}
              />
              {intl.get('app.singleChoice')}
            </label>
            <label disabled={readOnly}>
              <Input
                type="radio"
                name="matrixType"
                value={MATRIX_TYPE.multipleChoice}
                checked={matrixType === MATRIX_TYPE.multipleChoice}
                onChange={this.updateMatrixType}
              />
              {intl.get('app.multipleChoice')}
            </label>
            <label disabled={readOnly}>
              <Input
                type="radio"
                name="matrixType"
                value={MATRIX_TYPE.forcedRank}
                checked={matrixType === MATRIX_TYPE.forcedRank}
                onChange={this.updateMatrixType}
              />
              {intl.get('app.forcedRank')}
            </label>
          </div>
        </FlexRow>
        <div className="range-labels">
          <label className="option-label">{intl.get('app.matrixOptions.valueLabels')}</label>
          <div className="flex-row">
            <div className="col-sm-3">
              <label>{intl.get('app.leftLabel')}</label>
              <Input
                value={this.getRangeLabelChoice(rangeLabels[0], language, viewLanguage)}
                onChange={e => this.updateMatrixRangeLabels(rangeLabels, 0, e)}
              />
            </div>
            <div className="col-sm-3">
              <label>{intl.get('app.centerLabel')}</label>
              <Input
                value={this.getRangeLabelChoice(rangeLabels[1], language, viewLanguage)}
                onChange={e => this.updateMatrixRangeLabels(rangeLabels, 1, e)}
              />
            </div>
            <div className="col-sm-3">
              <label>{intl.get('app.rightLabel')}</label>
              <Input
                value={this.getRangeLabelChoice(rangeLabels[2], language, viewLanguage)}
                onChange={e => this.updateMatrixRangeLabels(rangeLabels, 2, e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MatrixOptions;
