import createFetchActions, { createWebSocketSendActions, createWebSocketReceiveActions } from './createFetchActions';

const rdSubscribeActions = createWebSocketSendActions('RESEARCH_DASHBOARD_SUBSCRIBE');
const rdSubscribeSuccessActions = createWebSocketReceiveActions('NEW_CONNECTION');
const rdAggregateAnswersSubscribeActions = createWebSocketSendActions('RD_AGGREGATE_ANSWERS_SUBSCRIBE');
const rdVerbatimAnswersSubscribeActions = createWebSocketSendActions('RD_VERBATIM_ANSWERS_SUBSCRIBE');
const rdParticipantAnswersSubscribeActions = createWebSocketSendActions('RD_PARTICPANT_ANSWERS_SUBSCRIBE');
const rdComparativeViewSubscribeActions = createWebSocketSendActions('RD_COMPARATIVE_VIEW_SUBSCRIBE');

const newSessionDataActions = createWebSocketReceiveActions('NEW_SESSION_DATA');
const rdConfigActions = createWebSocketReceiveActions('RD_CONFIG');
const initEnrolleesActions = createWebSocketReceiveActions('INIT_ENROLLEES');
const enrolleeUpdateActions = createWebSocketReceiveActions('ENROLLEE_UPDATE');
const newQuestionJoinerDataActions = createWebSocketReceiveActions('NEW_QUESTION_JOINER_DATA');
const newQuestionJoinerResponseRateDataActions = createWebSocketReceiveActions(
  'NEW_QUESTION_JOINER_RESPONSE_RATE_DATA'
);
const participantDataPointsActions = createWebSocketReceiveActions('PARTICIPANT_DATA_POINTS');
const rdChatsActions = createWebSocketReceiveActions('RD_CHATS');
const sessionQuotaFiltersActions = createWebSocketReceiveActions('SESSION_QUOTA_FILTERS');
const updateQuotaFilterActions = createWebSocketReceiveActions('UPDATE_QUOTA_FILTER');
const updateQuotaFilterResponseActions = createWebSocketReceiveActions('UPDATE_QUOTA_FILTER_RESPONSE');
const activeParticipantsActions = createWebSocketReceiveActions('ACTIVE_PARTICIPANTS');
const newFilteredEnrolleeDataActions = createWebSocketReceiveActions('NEW_FILTERED_ENROLLEE_DATA');
const updateParticipantSummaryFiltersActions = createWebSocketReceiveActions('UPDATE_PARTICIPANT_SUMMARY_FILTERS');
const wsSaveRdConfigResponseAction = createWebSocketReceiveActions('SAVE_RD_CONFIG');
const newAggregateDataAction = createWebSocketReceiveActions('NEW_AGGREGATE_DATA');
const newVerbatimDataAction = createWebSocketReceiveActions('NEW_VERBATIM_DATA');
const newParticipantAnswersActions = createWebSocketReceiveActions('NEW_PARTICIPANT_ANSWERS');
const receiveComparativeViewActions = createWebSocketReceiveActions('NEW_COMPARATIVE_VIEW_AGGREGATE_DATA');

const toggleFavoriteParticipantAction = createFetchActions('UPDATE_FAVORITE_PARTICIPANT');
const terminateParticipantAction = createFetchActions('TERMINATE_PARTICIPANT');
const unTerminateParticipantAction = createFetchActions('UNTERMINATE_PARTICIPANT');

// ws action for RD config type change
const rdConfigTypeSubscribeAction = createWebSocketSendActions('RD_CONFIG_TYPE_SUBSCRIBE');
const wsUpdateRDConfigTypeAction = createWebSocketReceiveActions('NEW_RD_CONFIG_TYPE');

const sendChatResponseAction = createWebSocketReceiveActions('SEND_CHAT_RESPONSE');
const newChatMessagesAction = createWebSocketReceiveActions('NEW_CHAT_MESSAGES');

// RD Config ajax actions start

const fetchDraftRDConfigActions = createFetchActions('FETCH_DRAFT_RD_CONFIG');
const saveCompleteRDConfigActions = createFetchActions('SAVE_COMPLETE_RD_CONFIG');
const applyProjectRDConfigActions = createFetchActions('APPLY_PROJECT_RD_CONFIG');

const saveRDConfigAction = createFetchActions('SAVE_RD_CONFIG', {
  failure_payload: (payload, exception) => ({
    payload,
    exception
  })
});

const fetchRDConfigsListAction = createFetchActions('FETCH_RD_CONFIGS_LIST', {
  failure_payload: (rdConfigs, exception) => ({
    rdConfigs,
    exception
  })
});

const deleteRDConfigAction = createFetchActions('DELETE_RD_CONFIG', {
  failure_payload: (payload, exception) => ({
    payload,
    exception
  })
});

const shareRDConfigAction = createFetchActions('SHARE_RD_CONFIG', {
  failure_payload: (payload, exception) => ({
    payload,
    exception
  })
});

const applyRDConfigTypeAction = createFetchActions('UPDATE_RD_CONFIG_TYPE', {
  failure_payload: (payload, exception) => ({
    payload,
    exception
  })
});

const openChatWindowAction = createFetchActions('OPEN_CHAT_WINDOW');

const closeChatWindowAction = createFetchActions('CLOSE_CHAT_WINDOW');

// RD Config ajax actions end

export {
  rdSubscribeActions,
  rdSubscribeSuccessActions,
  rdAggregateAnswersSubscribeActions,
  rdVerbatimAnswersSubscribeActions,
  rdParticipantAnswersSubscribeActions,
  newSessionDataActions,
  rdConfigActions,
  initEnrolleesActions,
  fetchDraftRDConfigActions,
  saveCompleteRDConfigActions,
  applyProjectRDConfigActions,
  saveRDConfigAction,
  deleteRDConfigAction,
  shareRDConfigAction,
  applyRDConfigTypeAction,
  enrolleeUpdateActions,
  newQuestionJoinerDataActions,
  newQuestionJoinerResponseRateDataActions,
  participantDataPointsActions,
  rdChatsActions,
  sessionQuotaFiltersActions,
  updateQuotaFilterActions,
  updateQuotaFilterResponseActions,
  activeParticipantsActions,
  newFilteredEnrolleeDataActions,
  updateParticipantSummaryFiltersActions,
  fetchRDConfigsListAction,
  wsSaveRdConfigResponseAction,
  newAggregateDataAction,
  newVerbatimDataAction,
  newParticipantAnswersActions,
  rdComparativeViewSubscribeActions,
  receiveComparativeViewActions,
  toggleFavoriteParticipantAction,
  terminateParticipantAction,
  unTerminateParticipantAction,
  rdConfigTypeSubscribeAction,
  wsUpdateRDConfigTypeAction,
  openChatWindowAction,
  closeChatWindowAction,
  sendChatResponseAction,
  newChatMessagesAction
};
