import { takeEvery, takeLatest } from 'redux-saga/effects';
import createFetchSaga from './createFetchSaga';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import {
  rdSubscribeActions,
  rdAggregateAnswersSubscribeActions,
  rdVerbatimAnswersSubscribeActions,
  rdParticipantAnswersSubscribeActions,
  fetchRDConfigsListAction,
  fetchDraftRDConfigActions,
  saveCompleteRDConfigActions,
  applyProjectRDConfigActions,
  saveRDConfigAction,
  deleteRDConfigAction,
  shareRDConfigAction,
  applyRDConfigTypeAction,
  toggleFavoriteParticipantAction,
  terminateParticipantAction,
  unTerminateParticipantAction,
  rdConfigTypeSubscribeAction,
  rdComparativeViewSubscribeActions
} from '../actions/researchDashboardActions';
import {
  fetchRDConfigsList,
  fetchDraftRDConfig,
  saveRDConfig,
  saveRDConfigApi,
  deleteRDConfigApi,
  shareRDConfigApi,
  applyProjectRDConfigApi,
  applyRDConfigTypeApi,
  toggleFavoriteParticipantApi,
  terminateParticipantApi,
  unTerminateParticipantApi
} from '../../../api/researchDashboardApi';
import { subscribeToChannel } from '../../../util/websocket';

function* rdSubscribe({ payload: { subAction, sessionId } }) {
  yield* subscribeToChannel({
    action: 'initRDSession',
    subAction,
    key: sessionId,
    sessionId
  });
}

function* rdAggregateAnswersSubscribe({ payload: { subAction, sessionId, questionDefId, rdConfig } }) {
  yield* subscribeToChannel({
    action: 'aggregateAnswers',
    subAction,
    key: `${sessionId}-${questionDefId}`,
    qdid: questionDefId,
    sessionId,
    rdConfig
  });
}

function* rdVerbatimAnswersSubscribe({ payload: { subAction, sessionId, questionDefId, rdConfig } }) {
  yield* subscribeToChannel({
    action: 'verbatimAnswers',
    subAction,
    key: `${sessionId}-${questionDefId}`,
    qdid: questionDefId,
    sessionId,
    rdConfig
  });
}

function* rdParticipantAnswersSubscribe({ payload: { subAction, sessionId, participantId } }) {
  yield* subscribeToChannel({
    action: 'participantAnswers',
    subAction,
    key: `${sessionId}-${participantId}`,
    sessionId,
    participantId
  });
}

function* rdConfigTypeSubscribe({ payload: { subAction, sessionId } }) {
  yield* subscribeToChannel({
    action: 'rdConfigType',
    subAction,
    key: sessionId,
    sessionId
  });
}

function* rdComparativeViewSubscribe({ payload: { subAction, rdConfig } }) {
  yield* subscribeToChannel({
    action: 'comparativeViewData',
    subAction,
    rdConfig
  });
}

function onSaveCompleteRDConfig() {
  toast.success({ text: intl.get('app.surveyPref.saved') });
}

function* rdSubscribeSaga() {
  yield takeEvery(rdSubscribeActions.request, rdSubscribe);
}

function* rdAggregateAnswersSubscribeSaga() {
  yield takeEvery(rdAggregateAnswersSubscribeActions.request, rdAggregateAnswersSubscribe);
}

function* rdVerbatimAnswersSubscribeSaga() {
  yield takeEvery(rdVerbatimAnswersSubscribeActions.request, rdVerbatimAnswersSubscribe);
}

function* rdParticipantAnswersSubscribeSaga() {
  yield takeEvery(rdParticipantAnswersSubscribeActions.request, rdParticipantAnswersSubscribe);
}

function* rdConfigTypeSubscribeSaga() {
  yield takeEvery(rdConfigTypeSubscribeAction.request, rdConfigTypeSubscribe);
}

function* rdComparativeViewSubscribeSaga() {
  yield takeEvery(rdComparativeViewSubscribeActions.request, rdComparativeViewSubscribe);
}

function* fetchRDConfigsListSaga() {
  const saga = createFetchSaga(fetchRDConfigsListAction, fetchRDConfigsList);
  yield* saga();
}

function* fetchDraftRDConfigSaga() {
  const saga = createFetchSaga(fetchDraftRDConfigActions, fetchDraftRDConfig);
  yield* saga();
}

function* saveCompleteRDConfigSaga() {
  const saga = createFetchSaga(saveCompleteRDConfigActions, saveRDConfig);
  yield* saga();
  yield takeLatest(saveCompleteRDConfigActions.succeeded, onSaveCompleteRDConfig);
}

function* applyProjectRDConfigSaga() {
  const saga = createFetchSaga(applyProjectRDConfigActions, applyProjectRDConfigApi);
  yield* saga();
}

function* saveRDConfigSaga() {
  const saga = createFetchSaga(saveRDConfigAction, saveRDConfigApi);
  yield* saga();
}

function* deleteRDConfigSaga() {
  const saga = createFetchSaga(deleteRDConfigAction, deleteRDConfigApi);
  yield* saga();
}

function* shareRDConfigSaga() {
  const saga = createFetchSaga(shareRDConfigAction, shareRDConfigApi);
  yield* saga();
}

function* applyRDConfigTypeSaga() {
  const saga = createFetchSaga(applyRDConfigTypeAction, applyRDConfigTypeApi);
  yield* saga();
}

function* toggleFavoriteParticipantSaga() {
  const saga = createFetchSaga(toggleFavoriteParticipantAction, toggleFavoriteParticipantApi);
  yield* saga();
}

function* terminateParticipantSaga() {
  const saga = createFetchSaga(terminateParticipantAction, terminateParticipantApi);
  yield* saga();
}

function* unTerminateParticipantSaga() {
  const saga = createFetchSaga(unTerminateParticipantAction, unTerminateParticipantApi);
  yield* saga();
}

export {
  rdSubscribeSaga,
  rdAggregateAnswersSubscribeSaga,
  rdVerbatimAnswersSubscribeSaga,
  rdParticipantAnswersSubscribeSaga,
  fetchRDConfigsListSaga,
  fetchDraftRDConfigSaga,
  saveCompleteRDConfigSaga,
  saveRDConfigSaga,
  deleteRDConfigSaga,
  applyProjectRDConfigSaga,
  shareRDConfigSaga,
  applyRDConfigTypeSaga,
  toggleFavoriteParticipantSaga,
  terminateParticipantSaga,
  unTerminateParticipantSaga,
  rdConfigTypeSubscribeSaga,
  rdComparativeViewSubscribeSaga
};
