import { handleActions } from 'redux-actions';
import { newQuestionJoinerDataActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdQuestionJoinerReducer = handleActions(
  {
    [newQuestionJoinerDataActions.succeeded](state, { payload }) {
      const { sessionId, questionJoiners } = payload;
      return {
        ...state,
        [sessionId]: {
          questionJoiners
        }
      };
    }
  },
  initialState
);
