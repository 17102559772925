import React from 'react';
import intl from 'react-intl-universal';

function isQuotaFilter(filter) {
  return filter.type === 'QUOTA_FILTER';
}

export const FilterSummaryBox = props => {
  const { filter, participantCount, removeFilter } = props;

  return (
    <div
      className="filter-summary-box"
      title={isQuotaFilter(filter) ? `${intl.get('app.maxQuota')}: ${filter.maxQuota}` : ''}
    >
      {isQuotaFilter(filter) && (
        <div className={`quota-filter ${filter.count >= filter.maxQuota ? '' : 'full'}`}>Q</div>
      )}
      <div className="remove-filter" onClick={() => removeFilter(filter)}>
        &times;
      </div>
      <div className="filter-count">{filter.count}</div>
      <div className="filter-stats">
        {(participantCount > 0 ? (filter.count / participantCount) * 100 : 0).toFixed(0)}%
      </div>
      <div className="filter-name" title={filter.name}>
        {filter.name}
      </div>
    </div>
  );
};
