import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import appConfig from '../../appConfig';
import SurveyList from '../surveyList';
import CreateSurveyTemplateModal from './CreateSurveyTemplateModal';
import { SURVEY_TEMPLATE_TAB, SCREENER_TEMPLATE_TAB } from '../pages/libraryPage/LibraryPage';

import './SurveyTemplateLibrary.css';

const SurveyTemplateLibrary = props => {
  const { isScreener, surveyTemplateCreated, hasProjectManage, createSurveyTemplate } = props;

  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (surveyTemplateCreated && showCreateTemplateModal) {
      toggleCreateTemplateModal();
    }
  }, [surveyTemplateCreated]);

  function onSurveySelect(survey) {
    const tab = isScreener ? SCREENER_TEMPLATE_TAB : SURVEY_TEMPLATE_TAB;
    navigate(`${appConfig.libraryPagePath}/${tab}/${survey.id}`);
  }

  function toggleCreateTemplateModal() {
    setShowCreateTemplateModal(!showCreateTemplateModal);
  }

  return (
    <div className="survey-template-library">
      <Button color="primary" onClick={toggleCreateTemplateModal} disabled={isScreener || !hasProjectManage}>
        {intl.get('app.addTemplate')}
      </Button>
      <SurveyList onSurveySelect={onSurveySelect} isScreener={isScreener} />
      {showCreateTemplateModal && (
        <CreateSurveyTemplateModal
          showModal={true}
          toggle={toggleCreateTemplateModal}
          onSave={createSurveyTemplate}
          isScreener={isScreener}
        />
      )}
    </div>
  );
};

export default SurveyTemplateLibrary;
