import createFetchActions from './createFetchActions';

// Create metadata collection steps 1 and 2
const createMetadataCollectionActions = createFetchActions('CREATE_METADATA_COLLECTION');
const saveMetadataJobActions = createFetchActions('SAVE_METADATA_JOB');

// Replace metadata collection steps 1 and 2
const setMetadataCollectionActions = createFetchActions('SET_METADATA_COLLECTION');
const replaceMetadataJobActions = createFetchActions('REPLACE_METADATA_JOB');

const deleteMetadataCollectionActions = createFetchActions('DELETE_METADATA_COLLECTION');

const fetchParticipantListsActions = createFetchActions('FETCH_PARTICIPANT_LISTS');
const fetchParticipantCollectionJobsActions = createFetchActions('FETCH_PARTICIPANT_COLLECTION_JOBS');
const pingParticipantCollectionJobsActions = createFetchActions('PING_PARTICIPANT_COLLECTION_JOBS');

export {
  createMetadataCollectionActions,
  saveMetadataJobActions,
  setMetadataCollectionActions,
  replaceMetadataJobActions,
  deleteMetadataCollectionActions,
  fetchParticipantListsActions,
  fetchParticipantCollectionJobsActions,
  pingParticipantCollectionJobsActions
};
