import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import { RDParticipantDetails } from './RDParticipantDetails';

import './RDParticipantDetailsModal.css';

export const RDParticipantDetailsModal = props => {
  const { sessionId, participant, toggle } = props;

  return (
    <InvokeModal
      showModal={true}
      className="rd-participant-details-modal"
      modalTitle={intl.get('app.participantDetails')}
      toggle={toggle}
      cancelButtonText={intl.get('app.close')}
    >
      <RDParticipantDetails
        sessionId={sessionId}
        participant={participant}
        setViewLanguage={props.setViewLanguage}
        language={props.language}
        viewLanguage={props.viewLanguage}
      />
    </InvokeModal>
  );
};
