import { connect } from 'react-redux';
import { fetchAllSessionsAction } from '../../../../store/redux/actions/sessionActions';
import { sessionSelector } from '../../../../store/redux/selectors/sessionSelector';
import { ParticipantReport } from './ParticipantReport';

const mapDispatchToProps = dispatch => ({
  fetchSessions: params => dispatch(fetchAllSessionsAction.request(params))
});

const mapStateToProps = state => {
  const sessionsSelector = sessionSelector()(state);
  return {
    sessions: sessionsSelector.allSessions.content
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantReport);
