import Cookies from 'js-cookie';
import * as auth0 from 'auth0-js';
import appConfig from '../appConfig';
import intl from 'react-intl-universal';
import { toast } from '../util/toast';

const webAuth = new auth0.WebAuth({
  domain: appConfig.auth0Domain,
  clientID: appConfig.auth0ClientId
});
const realm = appConfig.auth0DB;
async function authenticateUserApi({ username, password, client }) {}

async function authTokenToCookie(auth) {
  Cookies.set(appConfig.authCookieName, auth);
  return appConfig.authCookieName;
}

async function authTokenFromCookie() {
  return {
    authToken: Cookies.get(appConfig.authCookieName)
  };
}

async function changeUserPasswordApi(payload) {
  webAuth.changePassword(
    {
      connection: realm,
      email: payload.email
    },
    (error, resp) => {
      if (error) {
        toast.error({ text: intl.get('app.change.password.failed', { error }) });
      } else {
        toast.success({ text: intl.get('app.change.password.succeeded', { message: resp }) });
      }
    }
  );
}

export { authenticateUserApi, authTokenToCookie, authTokenFromCookie, changeUserPasswordApi };
