import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';

class DeleteDataTableModal extends React.Component {
  onDelete = () => {
    this.props.deleteDataTable();
  };

  render() {
    return (
      <InvokeModal
        showModal
        className="delete-data-table-modal"
        toggle={this.props.toggle}
        modalTitle={intl.get('app.title.deleteData')}
        primaryButtonText={intl.get('app.delete')}
        cancelButtonText={intl.get('app.cancel')}
        save={this.onDelete}
        enableSave={true}
      >
        {intl.get('app.confirm.deleteDataTable')}
      </InvokeModal>
    );
  }
}

export default DeleteDataTableModal;
