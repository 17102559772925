import React from 'react';
import { JoinerEditorTabs, JoinerEditorHeader, JoinerEditorButtons } from '../joinerEditor';
import Edit from '../editForms/edit/MatrixEdit';
import AttachMedia from '../editForms/media/AttachMedia';
import Options from '../editForms/options/MatrixOptions';
import Timing from '../editForms/timing/Timing';
import Rules from '../editForms/rules/Rules';

import './MatrixEditor.css';

class MatrixEditor extends React.Component {
  render() {
    const { props } = this;
    return (
      <>
        <JoinerEditorTabs
          tabs={props.tabs}
          joinerInfo={props.joinerInfo}
          hideMedia={props.isScreener}
          hideTiming={props.isScreener}
        />
        <div className="joiner-editor">
          <JoinerEditorHeader {...props} />
          <div className="joiner-editor-form">
            <Edit {...props} />
            {!props.isScreener && <AttachMedia {...props} />}
            <Options {...props} />
            <Timing {...props} />
            <Rules {...props} />
          </div>
          <JoinerEditorButtons
            toggle={props.toggle}
            saveEnabled={props.saveEnabled}
            saveJoiner={props.saveJoiner}
            readOnly={props.readOnly}
          />
        </div>
      </>
    );
  }
}

export default MatrixEditor;
