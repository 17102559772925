import createFetchActions from './createFetchActions';

const fetchCustomReportConfigListAction = createFetchActions('FETCH_CUSTOM_REPORT_CONFIG_LIST');
const fetchCustomReportConfigAction = createFetchActions('FETCH_CUSTOM_REPORT_CONFIG');
const saveCustomReportConfigActions = createFetchActions('SAVE_CUSTOM_REPORT_CONFIG');
const fetchCustomReportConsolidatedDataActions = createFetchActions('FETCH_CUSTOM_REPORT_CONSOLIDATED_DATA');
const generateCustomReportActions = createFetchActions('GENERATE_CUSTOM_REPORT');

export {
  fetchCustomReportConfigListAction,
  fetchCustomReportConfigAction,
  saveCustomReportConfigActions,
  fetchCustomReportConsolidatedDataActions,
  generateCustomReportActions
};
