import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

export const ImportModal = props => {
  const [selectedColumns, setSelectedColumns] = useState([]);

  function selectColumn(e) {
    setSelectedColumns([...selectedColumns, e.target.value]);
  }

  function removeColumn(column) {
    setSelectedColumns(selectedColumns.filter(col => col !== column));
  }

  function save() {
    const {
      newMetadataCollection: { jobUnit }
    } = props;
    jobUnit.def.uniqueIdCols = selectedColumns;
    jobUnit.readyForProcessing = true;
    jobUnit.status = 'Running';
    props.onSave(jobUnit);
  }

  function getSelectedColumns() {
    return selectedColumns.map((column, i) => (
      <div key={column}>
        <div>{i + 1}</div>{' '}
        <div>
          <span className="text-truncate" title={column}>
            {column}
          </span>{' '}
          <span className="fa fa-times" onClick={() => removeColumn(column)} />
        </div>
      </div>
    ));
  }

  function getColumnOptions() {
    const {
      newMetadataCollection: { sampleData }
    } = props;
    const columns = sampleData[0]; // first item is the columns
    const unselectedColumns = columns.filter(col => selectedColumns.indexOf(col) === -1);
    return unselectedColumns.map(column => (
      <option value={column} key={column}>
        {column}
      </option>
    ));
  }

  return (
    <InvokeModal
      showModal
      className="import-participant-data-modal"
      toggle={props.toggle}
      modalTitle={intl.get('app.importParticipantData')}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.save')}
      enableSave={selectedColumns.length > 0}
      save={save}
    >
      <div>{intl.get('app.setParticipantIdentKey')}</div>
      <div className="selected-columns">{getSelectedColumns()}</div>
      <div>{intl.get('app.addDataColumn')}</div>
      <div className="add-column-controls">
        <Input type="select" value="" onChange={selectColumn}>
          <option value="" disabled>
            {intl.get('app.selectColumn')}
          </option>
          {getColumnOptions()}
        </Input>
      </div>
    </InvokeModal>
  );
};
