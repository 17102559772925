import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Button, Col } from 'reactstrap';
import intl from 'react-intl-universal';
import { InvokeTable, SearchInput, usePagination } from 'webapp-common';
import AddToBlackList from './addToBlackList';

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const BlacklistPage = props => {
  const { blacklist, fetchBlacklist, deleteFromBlacklist } = props;
  const { pagedList = {}, fetchBlacklistInProgress, blacklistUpdated } = blacklist;
  const { pageRequest = {}, totalElements = 0 } = pagedList;

  const [state, setState] = useReducer(reducer, {
    searchStr: '',
    showModal: false
  });

  useEffect(() => {
    fetchBlacklist({
      sortBy: 'email',
      sortOrder: 'asc'
    });
  }, []);

  useEffect(() => {
    if (blacklistUpdated) {
      fetchBlacklist({
        sortBy: 'email',
        sortOrder: 'asc'
      });
    }
  }, [blacklistUpdated]);

  const toggleEmailModal = () => {
    setState({ showModal: !state.showModal });
  };

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchBlacklist({
        pageNum: 1,
        pageSize: pageRequest.pageSize,
        sortBy,
        sortOrder,
        active: state.active,
        email: state.searchStr
      });
    },
    [fetchBlacklist, pageRequest.pageSize, state.active, state.searchStr]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchBlacklist({
        pageNum: pageNumber,
        pageSize,
        sortBy: sortBy || pageRequest.sortBy,
        sortOrder: sortOrder || pageRequest.sortOrder,
        email: state.searchStr
      });
    },
    [fetchBlacklist, pageRequest.sortBy, pageRequest.sortOrder, state.searchStr]
  );

  const handleSearchInput = useCallback(
    e => {
      setState({ searchStr: e.target.value });
      fetchBlacklist({
        pageNum: 1,
        pageSize: pageRequest.pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder,
        active: state.active,
        email: e.target.value
      });
    },
    [fetchBlacklist, pageRequest.pageSize, pageRequest.sortBy, pageRequest.sortOrder, state.active]
  );

  const deleteEmail = useCallback(
    email => {
      if (window.confirm(intl.get('app.delete.blacklist', { email }))) {
        deleteFromBlacklist({ email });
      }
    },
    [deleteFromBlacklist]
  );

  const formatEmailCell = useCallback(
    info => {
      const email = info.getValue();
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <span>{email}</span>
          <i
            className="far fa-trash-alt clickable me-3"
            style={{ fontWeight: '600' }}
            onClick={() => deleteEmail(email)}
          />
        </div>
      );
    },
    [deleteEmail]
  );

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'email',
        header: intl.get('app.email'),
        headerStyle: { width: '18rem' },
        cell: formatEmailCell,
        cellClassName: 'text-truncate'
      }
    ];
  }, [formatEmailCell]);

  const pagination = usePagination({ pageRequest, totalElements });

  return (
    <div>
      <Button color="primary" onClick={toggleEmailModal}>
        {intl.get('app.add')}
      </Button>
      <SearchInput placeholder={intl.get('app.search')} onChange={handleSearchInput} className="my-4" />
      <Col sm="4">
        {pagedList && (
          <InvokeTable
            className="invoke-table"
            columns={columns}
            data={pagedList.content ?? []}
            initialState={{
              sorting: [
                {
                  id: 'email',
                  desc: false
                }
              ]
            }}
            loading={fetchBlacklistInProgress}
            pagination={pagination}
            onSortingChange={sort}
            onPaginationChange={paginate}
          />
        )}
      </Col>
      {state.showModal && <AddToBlackList toggle={toggleEmailModal} />}
    </div>
  );
};
