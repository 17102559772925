import { connect } from 'react-redux';
import { ProfilePage } from './ProfilePage';
import { fetchUserActions, updateUserNameAction } from '../../../store/redux/actions/userActions';
import { changePasswordActions } from '../../../store/redux/actions/authActions';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';

const mapDispatchToProps = dispatch => ({
  fetchSessionUser: () => dispatch(fetchUserActions.request()),
  updateUser: payload => dispatch(updateUserNameAction.request(payload)),
  changePassword: payload => {
    return dispatch(changePasswordActions.request(payload));
  }
});
export default connect(sessionUserSelector, mapDispatchToProps)(ProfilePage);
