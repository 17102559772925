import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { filterUtil } from '../../../../../util/filterUtil';
import { BannerFilters } from '../bannerFilters/BannerFilters';

const CONSOLIDATED_DATA_GROUP_NAME = 'Consolidated Data';

function getFilterObject(name, expression, type) {
  return {
    name,
    type,
    expression
  };
}

export const ParticipantDataBannerControls = props => {
  const { banner, bannerIndex, metadata, sessions, setParticipantDataBanner, removeFilter, moveFilter } = props;

  function getOption(qDef) {
    if (!qDef.value || !qDef.value.length) {
      return null;
    }
    return <option value={qDef.varId}>{qDef.questionTitle}</option>;
  }

  function getFilterGroupName(group) {
    const session = sessions.find(s => s.id === group);
    return session ? session.name : group;
  }

  function getGroup(group) {
    const dataPoints = metadata[group] || [];
    if (!dataPoints.length) {
      return null;
    }
    return <optgroup label={getFilterGroupName(group)}>{dataPoints.map(qDef => getOption(qDef))}</optgroup>;
  }

  function getOptions() {
    const groups = Object.keys(metadata);
    return (
      <>
        {getGroup(CONSOLIDATED_DATA_GROUP_NAME)}
        {groups.filter(group => group !== CONSOLIDATED_DATA_GROUP_NAME && metadata[group]).map(getGroup)}
      </>
    );
  }

  function getSelectedDataPoint(varId) {
    const groups = Object.keys(metadata);
    for (let i = 0; i < groups.length; i++) {
      const dataPoints = metadata[groups[i]];
      const dataPoint = dataPoints.find(obj => obj.varId === varId);
      if (dataPoint) {
        return dataPoint;
      }
    }
  }

  /**
   * Default behavior for adding a data point is to add it with filters for all answers
   */
  function handleAddDataPointClick(e) {
    const varId = e.target.value;
    const qDef = getSelectedDataPoint(varId);
    let filters;
    if (qDef.type === 'MULTI') {
      filters = qDef.value.map(v =>
        getFilterObject(
          v.answerText,
          filterUtil.generateParticipantDataFilterExpression(`${qDef.questionTitle}_${v.answerText}`, v.filterValue),
          'GENERAL_FILTER'
        )
      );
    } else {
      filters = qDef.value.map(v =>
        getFilterObject(
          v.answerText,
          filterUtil.generateParticipantDataFilterExpression(qDef.questionTitle, v.answerText),
          'GENERAL_FILTER'
        )
      );
    }
    setParticipantDataBanner(varId, filters, bannerIndex);
  }

  return (
    <>
      <Input type="select" value={banner.label} required onChange={handleAddDataPointClick}>
        <option value="" disabled selected>
          {intl.get('app.selectDataPointPlaceholder')}
        </option>
        {getOptions()}
      </Input>
      <BannerFilters banner={banner} bannerIndex={bannerIndex} removeFilter={removeFilter} moveFilter={moveFilter} />
    </>
  );
};
