import React from 'react';
import { accessHandler } from 'webapp-common';
import ReportList from '../../../reportList';

export const DownloadReportsPage = props => {
  const accessCheck = accessHandler.checkAccess(props.hasReportsView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return <ReportList showProject showSession pageSize={10} />;
};
