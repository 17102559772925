import React from 'react';
import update from 'immutability-helper';
import intl from 'react-intl-universal';
import { find } from 'lodash';
import { Input, Row, Col, Label } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

import './ReportAddSegmentModal.css';

class ReportAddSegmentModal extends React.Component {
  state = {
    segmentConfig: {
      name: '',
      defaultDataField: '',
      sessionOverrides: {}
    }
  };

  closeModal = () => {
    this.props.toggle();
  };

  setSegmentName = e => {
    const state = update(this.state, {
      segmentConfig: {
        name: { $set: e.target.value }
      }
    });
    this.setState(state);
  };

  setDefaultDataPoint = e => {
    const state = update(this.state, {
      segmentConfig: {
        defaultDataField: { $set: e.target.value },
        sessionOverrides: { $set: {} }
      }
    });
    this.setState(state);
  };

  setSessionOverride = (sessionId, value) => {
    const state = update(this.state, {
      segmentConfig: {
        sessionOverrides: {
          [sessionId]: { $set: value }
        }
      }
    });
    this.setState(state);
  };

  saveSegment = () => {
    const { segmentConfig } = this.state;
    const config = {
      name: segmentConfig.name,
      defaultDataField: segmentConfig.defaultDataField,
      sessionOverrides: {}
    };
    const sessionIds = Object.keys(segmentConfig.sessionOverrides);
    sessionIds.forEach(sessionId => {
      const value = segmentConfig.sessionOverrides[sessionId];
      if (value) {
        config.sessionOverrides[sessionId] = value;
      }
    });
    this.props.saveSegment(config);
  };

  getDataPointOptions = dataPoints => {
    const { consolidatedData } = this.props;
    dataPoints.sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1));
    return dataPoints.map(dataPoint => (
      <option value={dataPoint} key={dataPoint}>{`${dataPoint} [${consolidatedData.consolidatedDataPoints[
        dataPoint
      ].join(', ')}]`}</option>
    ));
  };

  getConsolidatedDataPointOptions = () => {
    const { consolidatedData } = this.props;
    const dataPoints = Object.keys(consolidatedData.consolidatedDataPoints);
    return this.getDataPointOptions(dataPoints);
  };

  getSessionsWithoutDefaultDataPoint = () => {
    const { consolidatedData } = this.props;
    const { defaultDataField } = this.state.segmentConfig;
    return defaultDataField ? consolidatedData.uncommonDataPoints[defaultDataField] : [];
  };

  getSessionDataPointOptions = sessionId => {
    const { consolidatedData } = this.props;
    const dataPoints = consolidatedData.sessionDataPointMap[sessionId];
    return this.getDataPointOptions(dataPoints);
  };

  getSessionOverrideControls = sessionIds => {
    const { sessions } = this.props;
    return sessionIds.map(sessionId => {
      const session = find(sessions, s => s.id === sessionId);
      const sessionOverride = this.state.segmentConfig.sessionOverrides[sessionId];
      return (
        <Row key={sessionId}>
          <Col>
            <Label>
              <Input
                type="checkbox"
                checked={sessionOverride !== undefined}
                onClick={() => this.setSessionOverride(sessionId, sessionOverride === undefined ? '' : undefined)}
              />
              <div className="text-truncate" title={session.name}>
                {session.name}
              </div>
            </Label>
          </Col>
          <Col>
            <Input
              type="select"
              value={sessionOverride || ''}
              disabled={sessionOverride === undefined}
              onChange={e => this.setSessionOverride(sessionId, e.target.value)}
            >
              <option disabled value="">
                {intl.get('app.placeholder.chooseDataField')}
              </option>
              {this.getSessionDataPointOptions(sessionId)}
            </Input>
          </Col>
        </Row>
      );
    });
  };

  isSaveEnabled = () => {
    const { segmentConfig } = this.state;
    if (!segmentConfig.name || !segmentConfig.defaultDataField) {
      return false;
    }
    const overrideKeys = Object.keys(segmentConfig.sessionOverrides);
    for (let i = 0; i < overrideKeys.length; i++) {
      if (segmentConfig.sessionOverrides[overrideKeys[i]] === '') {
        return false;
      }
    }
    return true;
  };

  render() {
    const { segmentConfig } = this.state;
    const sessionsWithoutDefaultDataPoint = this.getSessionsWithoutDefaultDataPoint();

    return (
      <InvokeModal
        showModal={true}
        toggle={this.closeModal}
        modalTitle={intl.get('app.addSegment')}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        save={this.saveSegment}
        enableSave={this.isSaveEnabled()}
        className="custom-report-config report-add-segment-modal"
      >
        <section className="main-section">
          <Row>
            <Col sm="4">{intl.get('app.segmentName')}</Col>
            <Col>
              <Input
                type="text"
                className="segment-name-field"
                value={segmentConfig.name}
                onChange={this.setSegmentName}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4">{intl.get('app.defaultParticipantDataField')}</Col>
            <Col>
              <Input type="select" value={segmentConfig.defaultDataField} onChange={this.setDefaultDataPoint}>
                <option disabled value="">
                  {intl.get('app.placeholder.chooseDataField')}
                </option>
                {this.getConsolidatedDataPointOptions()}
              </Input>
            </Col>
          </Row>
          <div className="desc-text">{intl.get('app.defaultParticipantDataFieldDesc')}</div>
        </section>

        {sessionsWithoutDefaultDataPoint.length > 0 && (
          <section className="session-override-section">
            <h5>{intl.get('app.sessionOverrides')}</h5>
            <div className="desc-text">
              {intl.get('app.sessionOverridesDesc', { field: segmentConfig.defaultDataField })}
            </div>
            <div className="session-override-controls">
              {this.getSessionOverrideControls(sessionsWithoutDefaultDataPoint)}
            </div>
          </section>
        )}
      </InvokeModal>
    );
  }
}

export default ReportAddSegmentModal;
