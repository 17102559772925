import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Label } from 'reactstrap';
import { accessHandler, InvokeTable, SearchInput, usePagination } from 'webapp-common';
import localtime from '../../../util/localtime';
import UserEdit from '../userEdit';

const DEFAULT_SORT_FIELD = 'email';
const DEFAULT_SORT_ORDER = 'asc';
const DEFAULT_USERS_SETTING = {
  pageNum: 1,
  pageSize: 10,
  sortBy: DEFAULT_SORT_FIELD,
  sortOrder: DEFAULT_SORT_ORDER,
  active: true
};

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

export const ClientUsers = props => {
  const { pagedList, usersRequested, hasUserAccountsManage, hasUserAccountsView, fetchUsers, userSaveSuccess } = props;
  const { pageRequest = {}, totalElements } = pagedList;

  const [state, setState] = useReducer(reducer, {
    active: true,
    user: {},
    searchStr: '',
    editUser: false
  });

  useEffect(() => {
    fetchUsers(DEFAULT_USERS_SETTING);
  }, []);

  useEffect(() => {
    if (userSaveSuccess) {
      fetchUsers({ ...DEFAULT_USERS_SETTING, active: state.active });
    }
  }, [userSaveSuccess]);

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchUsers({
        pageNum: 1,
        pageSize: pageRequest.pageSize,
        sortBy,
        sortOrder,
        active: state.active,
        email: state.searchStr
      });
    },
    [fetchUsers, pageRequest.pageSize, state.active, state.searchStr]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchUsers({
        pageNum: pageNumber,
        pageSize,
        sortBy,
        sortOrder,
        active: state.active,
        email: state.searchStr
      });
    },
    [fetchUsers, state.active, state.searchStr]
  );

  function handleSearchInput(e) {
    setState({ searchStr: e.target.value });
    fetchUsers({
      pageNum: 1,
      pageSize: pageRequest.pageSize,
      sortBy: pageRequest.sortBy,
      sortOrder: pageRequest.sortOrder,
      active: state.active,
      email: e.target.value
    });
  }

  function toggleActiveUsers(e) {
    fetchUsers({
      pageNum: 1,
      pageSize: pageRequest.pageSize,
      sortBy: pageRequest.sortBy,
      sortOrder: pageRequest.sortOrder,
      active: !e.target.checked,
      email: state.searchStr
    });
    setState({ active: !e.target.checked });
  }

  /*
   * Supports both edit and add
   */
  const openUserModal = useCallback(
    (user = {}) => {
      setState({
        editUser: !state.editUser,
        user
      });
    },
    [state.editUser]
  );

  const formatEmailCell = useCallback(
    info => {
      const user = info.row.original;
      return (
        <span className="link" title={user.email} onClick={() => openUserModal(user)}>
          {user.email}
        </span>
      );
    },
    [openUserModal]
  );

  const formatRolesCell = useCallback(info => {
    const user = info.row.original;
    const roleLabels = user.userTenantRoles.map(role => role.roleLabel);
    const roleLabelsStr = roleLabels
      .sort((a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a === b ? 0 : a > b ? 1 : -1;
      })
      .join(', ');
    return <span title={roleLabelsStr}>{roleLabelsStr}</span>;
  }, []);

  const formatActiveCell = useCallback(
    info => {
      const user = info.row.original;
      return <Input type="checkbox" checked={user.isActive} onChange={() => openUserModal(user)} />;
    },
    [openUserModal]
  );

  const formatDate = useCallback(info => {
    return localtime.getFormattedDate(info.getValue());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: intl.get('app.email'),
        headerStyle: { width: '18rem' },
        cell: formatEmailCell,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'userTenantRoles',
        header: intl.get('app.roles'),
        headerStyle: { width: '13rem' },
        cell: formatRolesCell,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'isActive',
        header: intl.get('app.enabled'),
        headerStyle: { width: '7rem' },
        cell: formatActiveCell,
        cellClassName: 'centered'
      },
      {
        accessorKey: 'lastLogin',
        header: intl.get('app.lastLogin'),
        headerStyle: { width: '13rem' },
        cell: formatDate
      }
    ],
    [formatActiveCell, formatDate, formatEmailCell, formatRolesCell]
  );

  const pagination = usePagination({ pageRequest, totalElements });

  const accessCheck = accessHandler.checkAccess(hasUserAccountsView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <div className="body-container l2-bg client-users">
      {hasUserAccountsManage && (
        <Button color="primary" onClick={() => openUserModal()}>
          {intl.get('app.addUser')}
        </Button>
      )}
      <SearchInput placeholder={intl.get('app.search')} onChange={handleSearchInput} className="my-4" />
      <Label check className="active-users-checkbox">
        <Input type="checkbox" checked={!state.active} onChange={toggleActiveUsers} />
        {intl.get('app.showInactiveUsers')}
      </Label>
      <div style={{ maxWidth: '66%' }}>
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={pagedList.content}
          initialState={{
            sorting: [
              {
                id: DEFAULT_SORT_FIELD,
                desc: false
              }
            ]
          }}
          loading={usersRequested}
          pagination={pagination}
          onSortingChange={sort}
          onPaginationChange={paginate}
        />
      </div>
      {state.editUser && <UserEdit user={state.user} create={!state.user.userID} openUserModal={openUserModal} />}
    </div>
  );
};
