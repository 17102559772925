import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';

const JoinerEditorButtons = ({ toggle, saveEnabled, saveJoiner, readOnly }) => {
  return (
    <div className="joiner-editor-buttons">
      <Button className="link-button" onClick={toggle}>
        {intl.get('app.cancel')}
      </Button>
      <Button color="primary" disabled={readOnly || !saveEnabled} onClick={saveJoiner}>
        {intl.get('app.save')}
      </Button>
    </div>
  );
};

export default JoinerEditorButtons;
