import React from 'react';
import { DatePicker } from 'antd';
import localtime from '../../../util/localtime';
import Wrapper from './DateTimePickerWrapper';

import './DateTimePicker.css';

function getTimeZoneAbbr() {
  const dateTimeParts = Intl.DateTimeFormat([], { timeZoneName: 'short' }).formatToParts();
  const timeZoneName = dateTimeParts.find(part => part.type === 'timeZoneName');
  return (timeZoneName && timeZoneName.value) || 'GMT';
}

const DateTimePicker = ({ dateTime, isDateClearable, disabled, onChange, label, placeholder }) => {
  return (
    <div className="date-container">
      <label>{label}</label>
      <DatePicker
        disabled={disabled}
        value={dateTime}
        format={localtime.DATE_TIME_FORMAT_CAPS}
        showTime={{ use12Hours: true, format: 'h:mm A', onAmPmChange: () => {} }}
        allowClear={isDateClearable}
        placeholder={placeholder}
        onChange={onChange}
      />
      <label className="est-label">{getTimeZoneAbbr()}</label>
    </div>
  );
};

export default Wrapper(DateTimePicker);
