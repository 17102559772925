import React, { useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import Select from 'react-select';
import { SearchInput } from 'webapp-common';

export const ReportFilters = props => {
  const { filters, showProject, showSession, onFilterChange } = props;

  const reportTypeOptions = useMemo(() => {
    return [
      {
        label: intl.get('app.all'),
        value: ''
      },
      {
        label: intl.get('app.reportType.chart'),
        value: 'CHART'
      },
      {
        label: intl.get('app.reportType.crosstab'),
        value: 'CROSSTAB'
      },
      {
        label: intl.get('app.reportType.rawExtract'),
        value: 'RAWEXTRACT'
      },
      {
        label: intl.get('app.reportType.consolidatedChart'),
        value: 'CONSOLIDATED_CHART'
      },
      {
        label: intl.get('app.reportType.consolidatedCrosstab'),
        value: 'CONSOLIDATED_CROSSTAB'
      },
      {
        label: intl.get('app.reportType.consolidatedRawExtract'),
        value: 'CONSOLIDATED_RAWEXTRACT'
      },
      {
        label: intl.get('app.reportType.translationUpload'),
        value: 'TRANSLATION_UPLOAD'
      },
      {
        label: intl.get('app.title.custom'),
        value: 'CUSTOM'
      },
      {
        label: intl.get('app.reportType.dialRawData'),
        value: 'DIAL_RAW_DATA'
      },
      {
        label: intl.get('app.reportType.dialPresentation'),
        value: 'DIAL_CHART'
      },
      {
        label: intl.get('app.reportType.emotionRawData'),
        value: 'EMOTION_RAW_DATA'
      },
      {
        label: intl.get('app.reportType.emotionPresentation'),
        value: 'EMOTION_CHART'
      }
    ];
  }, []);

  const onReportNameSearch = useCallback(
    e => {
      onFilterChange({ ...filters, reportName: e.target.value });
    },
    [onFilterChange, filters]
  );

  const onReportTypeSelect = useCallback(
    type => {
      onFilterChange({ ...filters, reportType: type.value });
    },
    [onFilterChange, filters]
  );

  const onProjectNameSearch = useCallback(
    e => {
      onFilterChange({ ...filters, projectName: e.target.value });
    },
    [onFilterChange, filters]
  );

  const onSessionNameSearch = useCallback(
    e => {
      onFilterChange({ ...filters, firstSessionName: e.target.value });
    },
    [onFilterChange, filters]
  );

  return (
    <div className="filters">
      <SearchInput placeholder={intl.get('app.reportName')} onChange={onReportNameSearch} />
      <Select
        classNamePrefix="react-select"
        onChange={onReportTypeSelect}
        options={reportTypeOptions}
        placeholder={intl.get('app.reportType')}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            width: '16rem'
          })
        }}
      />
      {showProject && <SearchInput placeholder={intl.get('app.projectName')} onChange={onProjectNameSearch} />}
      {showSession && <SearchInput placeholder={intl.get('app.sessionName')} onChange={onSessionNameSearch} />}
    </div>
  );
};
