import React, { useEffect, useRef, memo } from 'react';
import Quill from 'quill';
import { isEqual } from 'lodash';
import { IndentStyle } from './QuillUtils';

import './QuillEdtor.css';

const FontStyle = Quill.import('attributors/style/font');
const SizeStyle = Quill.import('attributors/style/size');
const AlignStyle = Quill.import('attributors/style/align');

SizeStyle.whitelist = ['10px', '20px', '40px'];

Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);
Quill.register(AlignStyle, true);
Quill.register({ 'formats/indent': IndentStyle }, true);

const toolbarOptions = [
  [{ font: [] }], // fonts
  [{ size: ['10px', false, '20px', '40px'] }], // custom dropdown
  ['bold', 'italic', 'underline'], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ align: [] }]
];

function QuillEditor(props) {
  const {
    id,
    richContents,
    contents,
    readOnly = false,
    update,
    containerStyle,
    editorStyle,
    placeholder = '',
    getHtml = false,
    extraContent = ''
  } = props;

  const quillRef = useRef(null);

  useEffect(() => {
    const quill = new Quill('#' + id, {
      modules: {
        toolbar: toolbarOptions
      },
      theme: 'snow',
      placeholder,
      readOnly
    });

    quillRef.current = quill.container && quill;

    (quillRef.current.container && richContents && quillRef.current.setContents(richContents)) ||
      quillRef.current.setText(contents || '');
  }, [id]);

  useEffect(() => {
    quillRef.current.container && quillRef.current.enable(!readOnly);
  }, [readOnly]);

  useEffect(() => {
    quillRef.current.container &&
      quillRef.current.on('text-change', function (delta, oldDelta, source) {
        if (source === 'user') {
          if (getHtml) {
            const html = quillRef.current.container.querySelector('.ql-editor').innerHTML;
            update(quillRef.current.getText(), quillRef.current.getContents().ops, html);
          } else {
            update(quillRef.current.getText(), quillRef.current.getContents().ops);
          }
        }
      });
  }, [extraContent]);

  return (
    <div className={containerStyle}>
      <div className={editorStyle} id={id} />
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.id === nextProps.id &&
    prevProps.readOnly === nextProps.readOnly &&
    isEqual(prevProps.extraContent, nextProps.extraContent)
  );
}

export default memo(QuillEditor, areEqual);
