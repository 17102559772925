import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';

import './AddOrEditSegmentCategoryModal.css';

const reducer = (state, payload) => ({ ...state, ...payload });
const defaultQuota = '';

export const AddOrEditSegmentCategoryModal = props => {
  const { selectedSegmentCategory, rowIndex, readOnly, toggle } = props;

  const [state, setState] = useReducer(reducer, {
    name: (selectedSegmentCategory && selectedSegmentCategory.name) || '',
    quotaEnabled: (selectedSegmentCategory && selectedSegmentCategory.quotaEnabled) || false,
    segments: (selectedSegmentCategory && selectedSegmentCategory.segments) || [{ name: '', quota: defaultQuota }]
  });

  function updateName(e) {
    setState({ name: e.target.value });
  }

  function onSubmit() {
    props.onSubmit({
      name: state.name,
      quotaEnabled: state.quotaEnabled,
      segments: state.segments,
      rowIndex
    });
  }

  function onDelete() {
    props.onDelete();
  }

  function setQuotaEnabled(e) {
    setState({ quotaEnabled: e.target.checked });
  }

  function validateQuota(value) {
    return !isNaN(value) && value > -2147483648 && value < 2147483647;
  }

  function updateQuota(index, name, quota) {
    if (validateQuota(quota)) {
      updateSegmentParams(index, name, quota);
    }
  }

  function updateSegmentParams(index, name, quota) {
    const segments = [...state.segments];
    segments[index] = { name, quota };
    setState({ segments });
  }

  function getParamRow(obj, index) {
    return (
      <div className="link-param-row" key={`link_param_${index}`}>
        <div>
          <Input
            value={obj.name}
            onChange={e => updateSegmentParams(index, e.target.value, obj.quota)}
            disabled={readOnly}
          />
        </div>
        <div>
          <Input
            value={obj.quota}
            onChange={e => updateQuota(index, obj.name, e.target.value)}
            disabled={!state.quotaEnabled || readOnly}
          />
        </div>
        <div>
          <AddRemoveIcons
            addDisabled={readOnly}
            removeDisabled={state.segments.length === 1 || readOnly}
            onAdd={() => addRow(index + 1)}
            onRemove={() => removeRow(index)}
          />
        </div>
      </div>
    );
  }

  function addRow(index) {
    const segments = [...state.segments];
    segments.splice(index, 0, { name: '', quota: defaultQuota });
    setState({ segments });
  }

  function removeRow(index) {
    const segments = [...state.segments];
    segments.splice(index, 1);
    setState({ segments });
  }

  function getParamRows() {
    return state.segments.map((obj, index) => getParamRow(obj, index));
  }

  function verifySegments() {
    const { segments } = state;
    if (segments) {
      for (let i = 0; i < segments.length; ++i) {
        const segment = segments[i];
        if (!segment || !segment.name) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  function segmentsEqual() {
    const { segments } = selectedSegmentCategory;
    const { segments: segmentsState } = state;

    if (segments && segmentsState && segments.length !== segmentsState.length) {
      return false;
    }

    for (let i = 0; i < segments.length; ++i) {
      const segment = segments[i];
      const segmentFromState = segmentsState[i];
      if (segment.name !== segmentFromState.name) {
        return false;
      }
      if (segment.quota !== segmentFromState.quota) {
        if (typeof segmentFromState.quota === 'string') {
          if (parseInt(segmentFromState.quota, 10) !== segment.quota) {
            // quota in state can be a string but props is number
            return false;
          }
          continue;
        }
        return false;
      }
    }
    return true;
  }

  function enableSave() {
    if (rowIndex < 0) {
      return state.name;
    }
    if (state.quotaEnabled && !verifySegments()) {
      return false;
    }
    return (
      selectedSegmentCategory.name !== state.name ||
      selectedSegmentCategory.quotaEnabled !== state.quotaEnabled ||
      !segmentsEqual()
    );
  }

  const modalTitle =
    rowIndex >= 0
      ? intl.get('app.segmentCategory.editSegmentCategory')
      : intl.get('app.segmentCategory.addSegmentCategory');

  return (
    <InvokeModal
      showModal
      toggle={toggle}
      modalTitle={modalTitle}
      deleteButtonText={!readOnly && rowIndex >= 0 ? intl.get('app.delete') : ''}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.save')}
      enableSave={enableSave()}
      save={onSubmit}
      className="add-or-edit-segment-category-modal"
      delete={onDelete}
      enableDelete={!readOnly && props.enableDelete()}
    >
      <div className="fw-600">{intl.get('app.segmentCategory')}:</div>
      <div className="mb-5">
        <Input value={state.name} onChange={updateName} disabled={readOnly} />
      </div>
      <div className="fw-600 mb-3">{intl.get('app.segments')}:</div>
      <div className="mb-3">
        <label>
          <Input type="checkbox" checked={state.quotaEnabled} onChange={setQuotaEnabled} disabled={readOnly} />
          {intl.get('app.enableQuotas')}
        </label>
      </div>
      <div className="link-param-row">
        <div>{intl.get('app.segment')}</div>
        <div>{intl.get('app.quota')}</div>
        <div />
      </div>
      {getParamRows()}
    </InvokeModal>
  );
};
