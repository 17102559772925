import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

const FILTER_FIELD = 'FILTER_FIELD';
const PERCENTAGE = 'PERCENTAGE';

export const VideoResponses = props => {
  const { session, participantCollection, readOnly } = props;
  const { videoCaptureConfig = {} } = session;
  const { allParticipants, selectParticipantsBy, quota, percentage, filterField } = videoCaptureConfig;

  useEffect(() => {
    props.getParticipantDataDef(session.id);
  }, [session]);

  function onChange(name, value) {
    props.setSessionField('videoCaptureConfig', {
      ...videoCaptureConfig,
      [name]: value
    });
  }

  function setParticipantsConfig(value) {
    const copy = {
      ...videoCaptureConfig,
      allParticipants: null,
      selectParticipantsBy: undefined
    };
    if (value === 'all') {
      copy.allParticipants = true;
    } else if (value === 'filter') {
      copy.allParticipants = false;
      copy.selectParticipantsBy = FILTER_FIELD;
    } else if (value === 'random') {
      copy.allParticipants = false;
      copy.selectParticipantsBy = PERCENTAGE;
    }
    props.setSessionField('videoCaptureConfig', copy);
  }

  function setPercentage(value) {
    const p = value.trim();
    if (!isNaN(p) && p < 101) {
      onChange('percentage', p);
    }
  }

  function setQuota(value) {
    const q = value.trim();
    if (q !== '0' && !isNaN(q)) {
      props.setSessionField('videoCaptureConfig', {
        ...videoCaptureConfig,
        quota: q || null,
        quotaEnabled: !!q
      });
    }
  }

  function getFilterFieldOptions() {
    // build option list with an empty option
    let filterFieldOptions = [];
    filterFieldOptions.push(<option value="" disabled />);
    participantCollection.forEach(participantDataDef => {
      filterFieldOptions.push(
        <option key={participantDataDef.varId} value={participantDataDef.varId}>
          {participantDataDef.questionTitle}
        </option>
      );
    });
    return filterFieldOptions;
  }

  // Filter field is only available for private sessions
  const selectParticipantsByFilterFieldEnabled = session.targetedURL;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem' }}>
        <div style={{ marginRight: '.5rem' }}>{intl.get('app.participants')}:</div>
        <Input
          type="select"
          style={{ width: '7rem', marginRight: '.5rem' }}
          value={
            (allParticipants && 'all') ||
            (allParticipants === false && selectParticipantsBy === FILTER_FIELD && 'filter') ||
            (allParticipants === false && selectParticipantsBy === PERCENTAGE && 'random') ||
            (allParticipants === null && 'none')
          }
          onChange={e => setParticipantsConfig(e.target.value)}
          disabled={readOnly}
        >
          <option value="all">{intl.get('app.all')}</option>
          <option value="filter" disabled={!selectParticipantsByFilterFieldEnabled}>
            {intl.get('app.filter')}
          </option>
          <option value="random">{intl.get('app.random')}</option>
          <option value="none">{intl.get('app.none')}</option>
        </Input>
        {allParticipants === false && selectParticipantsBy === FILTER_FIELD && (
          <Input
            type="select"
            style={{ maxWidth: '52rem' }}
            value={filterField || ''}
            onChange={e => onChange('filterField', e.target.value)}
            disabled={!selectParticipantsByFilterFieldEnabled}
          >
            {participantCollection && getFilterFieldOptions()}
          </Input>
        )}
        {allParticipants === false && selectParticipantsBy === PERCENTAGE && (
          <>
            <Input
              style={{ width: '4rem' }}
              value={percentage || ''}
              onChange={e => setPercentage(e.target.value)}
              disabled={readOnly}
            />
            %
          </>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem' }}>
        <div style={{ marginRight: '.5rem' }}>{intl.get('app.quota')}:</div>
        <Input
          style={{ width: '4rem' }}
          value={quota || ''}
          onChange={e => setQuota(e.target.value)}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default VideoResponses;
