import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { auth } from '../../../api/Auth';
import { jsUtil } from '../../../util/jsUtil';
import { get } from '../../../util/request';
import { Loader } from 'webapp-common';
import { LoginForm } from '../../../components/loginForm/LoginForm';

const LoginPage = ({ authToken, validateAuth, authError, handleAuthError, doLogout }) => {
  const [isClientMfaEnabled, setClientMfaEnabled] = useState(null);

  // Capture the queries from the first render
  const queries = jsUtil.getUrlQueries();
  const queriesRef = useRef(Object.keys(queries).length !== 0 ? queries : null);

  useEffect(() => {
    queries.authError && handleAuthError(queries.authError);
    if (doLogout) {
      auth.logout();
    }
    get('/a/api/clientMfaEnabled')()
      .then(res => {
        setClientMfaEnabled(res.body.payload);
      })
      .catch(err => {
        setClientMfaEnabled(false);
        console.warn(err);
      });
  }, []);

  const login = () => {
    auth.login(queries, isClientMfaEnabled);
  };

  return (
    <div className="welcome-page app-bg">
      {isClientMfaEnabled === null && <Loader spinner fullScreen />}
      {authToken && validateAuth({ redirectToHome: true }) && <h4>{intl.get('app.loading')}</h4>}
      {!authToken && validateAuth({ redirectToHome: true }) && (
        <div style={{ textAlign: 'center' }}>
          <LoginForm onLogin={login} authError={authError} queries={queriesRef.current} />
        </div>
      )}
    </div>
  );
};

export default LoginPage;
