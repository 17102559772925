import React from 'react';
import intl from 'react-intl-universal';
import { cleanJoinerTitle } from '../../util/joinerUtil';
import { InvokeTable } from 'webapp-common';
import JoinerPreviewModal from '../../components/joinerPreview/JoinerPreviewModal';
import { QuestionFilters } from './QuestionFilters';

import './QuestionList.css';

const RESEARCH_TEXT = 'researchText';
const QUESTION_TEXT = 'questionText';
const RESPONSE_TYPE = 'responseType';
const RESEARCH_PROMPT = 'researchPrompt';
const DEF_QUESTION = 'def.question';
const DEF_RESPONSE_SET = 'def.responseSet';
const SCREENER = 'screener';

// FE sort field > BE sort field
const sortFieldMap = {
  [RESEARCH_PROMPT]: RESEARCH_TEXT,
  [DEF_QUESTION]: QUESTION_TEXT,
  [DEF_RESPONSE_SET]: RESPONSE_TYPE
};

class QuestionList extends React.Component {
  state = {
    filters: {
      title: '',
      text: '',
      type: '',
      surveyType: ''
    },
    showPreviewModal: false,
    joinerPreviewId: ''
  };

  componentDidMount() {
    this.props.fetchQuestions({
      pageSize: this.props.pageSize,
      sortBy: 'popularity',
      sortOrder: 'desc'
    });
  }

  onRowSelect = joiner => {
    if (this.props.clickToSelect) {
      this.props.clickToSelect(joiner);
      this.setState({
        selectedJoiner: joiner
      });
    }
  };

  convertFESortFieldToBE(sortField) {
    return sortFieldMap[sortField] ?? sortField;
  }

  sort = ({ sortBy, sortOrder }) => {
    this.props.fetchQuestions({
      pageSize: this.props.pageSize,
      sortBy: this.convertFESortFieldToBE(sortBy),
      sortOrder,
      title: this.state.filters.title,
      text: this.state.filters.text,
      type: this.state.filters.type,
      surveyType: this.props.surveyType || this.state.filters.surveyType
    });
  };

  getPagination = () => {
    const { pagination, questions, totalElements } = this.props;
    const { pageSize = 10, pageNumber = 1 } = pagination || {};

    if (questions?.length) {
      return {
        pageNumber,
        pageSize,
        totalSize: totalElements
      };
    }

    return {};
  };

  paginate = ({ pageNumber, pageSize, sortBy, sortOrder }) => {
    this.props.fetchQuestions({
      pageNumber,
      pageSize,
      sortBy: this.convertFESortFieldToBE(sortBy),
      sortOrder,
      title: this.state.filters.title,
      text: this.state.filters.text,
      type: this.state.filters.type,
      surveyType: this.props.surveyType || this.state.filters.surveyType
    });
  };

  filter(filters) {
    const { sortBy, sortOrder } = this.props.pagination;
    this.props.fetchQuestions({
      pageNumber: 1,
      pageSize: this.props.pageSize,
      sortBy,
      sortOrder,
      title: filters.title,
      text: filters.text,
      type: filters.type,
      surveyType: this.props.surveyType || filters.surveyType
    });
  }

  onFilterChange = filters => {
    this.setState({ filters });
    this.filter(filters);
  };

  renderResearchPrompt = info => {
    return <span title={info.getValue()}>{cleanJoinerTitle(info.getValue())}</span>;
  };

  renderQuestionText = info => {
    const questionJoiner = info.row.original;
    const { question } = questionJoiner.def;
    if (question) {
      return <span title={question.prompt}>{question.prompt}</span>;
    }
    return '';
  };

  renderResponseType = info => {
    const questionJoiner = info.row.original;
    const { responseSet } = questionJoiner.def;
    if (responseSet) {
      const id = `app.questionType.${responseSet.type}`;
      return intl.get(id);
    }
    return '';
  };

  renderSurveyType = info => {
    return info.getValue() ? 'Screener' : 'Session';
  };

  togglePreviewModal = joinerId => {
    this.setState({
      showPreviewModal: !this.state.showPreviewModal,
      joinerPreviewId: joinerId
    });
  };

  onPreview = joinerId => {
    this.togglePreviewModal(joinerId);
  };

  previewQuestionRenderer = info => {
    return (
      <button
        onClick={() => this.onPreview(info.getValue())}
        className="fa fa-eye"
        title={intl.get('app.questionList.preview')}
      />
    );
  };

  getColumns = (enablePreview, showSurveyTypeColumn) => {
    const columns = [
      {
        accessorKey: RESEARCH_PROMPT,
        header: intl.get('app.title'),
        headerStyle: { width: showSurveyTypeColumn ? '10rem' : '12rem' },
        cell: this.renderResearchPrompt,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: DEF_QUESTION,
        header: intl.get('app.text'),
        headerStyle: { width: '12rem' },
        cell: this.renderQuestionText,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: DEF_RESPONSE_SET,
        header: intl.get('app.type'),
        headerStyle: { width: showSurveyTypeColumn ? '4rem' : '6rem' },
        cell: this.renderResponseType,
        cellClassName: 'text-truncate'
      }
    ];
    if (showSurveyTypeColumn) {
      columns.push({
        accessorKey: SCREENER,
        header: intl.get('app.surveyType'),
        headerStyle: { width: '4rem' },
        cell: this.renderSurveyType
      });
    }
    columns.push({
      accessorKey: 'popularity',
      header: intl.get('app.popularity'),
      headerStyle: { width: enablePreview ? '4rem' : '5rem' }
    });
    if (enablePreview) {
      columns.push({
        accessorKey: 'id',
        header: '',
        headerStyle: { width: '2rem' },
        cell: this.previewQuestionRenderer,
        enableSorting: false
      });
    }
    return columns;
  };

  render() {
    const { questions, questionListRequested, enablePreview, showSurveyTypeColumn = false } = this.props;

    const questionsColumns = this.getColumns(enablePreview, showSurveyTypeColumn);

    return (
      <div className="questions-list">
        <QuestionFilters
          filters={this.state.filters}
          showSurveyTypeFilter={showSurveyTypeColumn}
          onFilterChange={this.onFilterChange}
        />
        <InvokeTable
          className="invoke-table"
          columns={questionsColumns}
          data={questions ?? []}
          initialState={{
            sorting: [
              {
                id: 'popularity',
                desc: true
              }
            ]
          }}
          loading={questionListRequested}
          pagination={this.getPagination()}
          onPaginationChange={this.paginate}
          onSortingChange={this.sort}
          onRowSelect={this.props.clickToSelect ? this.onRowSelect : undefined}
        />
        {this.state.showPreviewModal && (
          <JoinerPreviewModal toggle={this.togglePreviewModal} joinerId={this.state.joinerPreviewId} />
        )}
      </div>
    );
  }
}
export default QuestionList;
