import React from 'react';
import intl from 'react-intl-universal';
import { InvokeTable } from 'webapp-common';
import localtime from '../../util/localtime';
import { ReportFilters } from './ReportFilters';

import './ReportList.css';

const REPORT_NAME = 'reportName';
const REPORT_TYPE = 'reportType';
const PROJECT_NAME = 'projectName';
const FIRST_SESSION_NAME = 'firstSessionName';
const CREATE_DATE = 'createDate';

class ReportList extends React.Component {
  state = {
    filters: {
      reportName: '',
      reportType: '',
      projectName: '',
      firstSessionName: ''
    }
  };

  componentDidMount() {
    this.props.fetchReports({ pageSize: this.props.pageSize, projectId: this.props.projectId || '' });
  }

  sort = ({ sortBy, sortOrder }) => {
    this.props.fetchReports({
      pageNumber: 1,
      pageSize: this.props.pageSize,
      sortBy,
      sortOrder,
      ...this.state.filters,
      projectId: this.props.projectId || ''
    });
  };

  paginate = ({ pageNumber, pageSize, sortBy, sortOrder }) => {
    this.props.fetchReports({
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      ...this.state.filters,
      projectId: this.props.projectId || ''
    });
  };

  filter(filters) {
    const { sortBy, sortOrder } = this.props.pagination;
    this.props.fetchReports({
      pageNumber: 1,
      pageSize: this.props.pageSize,
      sortBy,
      sortOrder,
      ...filters,
      projectId: this.props.projectId || ''
    });
  }

  onFilterChange = filters => {
    this.setState({ filters });
    this.filter(filters);
  };

  reportNameCell(info) {
    const report = info.row.original;
    const { mediaId, reportName } = report;
    return mediaId ? (
      <a href={`/a/binapi/report/${mediaId}`} className="no-text-underline" title={reportName}>
        {reportName}
      </a>
    ) : (
      <span title={reportName}>{reportName}</span>
    );
  }

  prettyUpReportType(info) {
    const type = info.getValue();
    switch (type) {
      case 'CHART':
        return intl.get('app.reportType.chart');
      case 'CROSSTAB':
        return intl.get('app.reportType.crosstab');
      case 'RAWEXTRACT':
        return intl.get('app.reportType.rawExtract');
      case 'CONSOLIDATED_CHART':
        return intl.get('app.reportType.consolidatedChart');
      case 'CONSOLIDATED_CROSSTAB':
        return intl.get('app.reportType.consolidatedCrosstab');
      case 'CONSOLIDATED_RAWEXTRACT':
        return intl.get('app.reportType.consolidatedRawExtract');
      case 'TRANSLATION_UPLOAD':
        return intl.get('app.reportType.translationUpload');
      case 'CUSTOM':
        return intl.get('app.title.custom');
      case 'DIAL_RAW_DATA':
        return intl.get('app.reportType.dialRawData');
      case 'DIAL_CHART':
        return intl.get('app.reportType.dialPresentation');
      case 'EMOTION_RAW_DATA':
        return intl.get('app.reportType.emotionRawData');
      case 'EMOTION_CHART':
        return intl.get('app.reportType.emotionPresentation');
      default:
        return type;
    }
  }

  cellElemWithTitle = info => <span title={info.getValue()}>{info.getValue()}</span>;

  formatDate = info => localtime.getFormattedDate(info.getValue());

  formatStatusIcons = info => {
    const report = info.row.original;
    const { status } = report;
    const icon = status === 'SUCCESS' ? <i className="far fa-trash-alt"></i> : <i className="far fa-hourglass"></i>;
    const onClick = status === 'SUCCESS' ? () => this.deleteReport(report.id, report.reportName) : undefined;
    return (
      <span className="icon" onClick={onClick}>
        {icon}
      </span>
    );
  };

  deleteReport(reportId, reportName) {
    if (window.confirm(intl.get('app.report.delete.confirmation', { name: reportName }))) {
      this.props.deleteReport(reportId);
      window.location.reload();
    }
  }

  render() {
    const { showProject, showSession, reports: reportList, totalElements, reportListRequested, projectId } = this.props;
    const { pageSize = -1, pageNumber = 1 } = this.props.pagination || {};

    const reportsData = reportList?.length ? reportList : [];

    const columns = [
      {
        accessorKey: REPORT_NAME,
        header: intl.get('app.reportName'),
        cell: this.reportNameCell,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: REPORT_TYPE,
        header: intl.get('app.reportType'),
        cell: this.prettyUpReportType
      }
    ];

    if (showProject) {
      columns.push({
        accessorKey: PROJECT_NAME,
        header: intl.get('app.project'),
        cell: this.cellElemWithTitle,
        cellClassName: 'text-truncate'
      });
    }
    if (showSession) {
      columns.push({
        accessorKey: FIRST_SESSION_NAME,
        header: intl.get('app.session'),
        cell: this.cellElemWithTitle,
        cellClassName: 'text-truncate'
      });
    }

    columns.push({
      accessorKey: CREATE_DATE,
      header: intl.get('app.createDate'),
      cell: this.formatDate
    });

    if (this.props.showStatusIcons) {
      columns.push({
        accessorKey: 'status',
        header: '',
        headerStyle: { width: '40px', textAlign: 'center' },
        cell: this.formatStatusIcons,
        enableSorting: false
      });
    }

    const pagination = {
      pageNumber,
      pageSize: projectId ? reportsData.length : pageSize,
      totalSize: totalElements
    };

    return (
      <div className="reports-list">
        <ReportFilters
          filters={this.state.filters}
          showProject={showProject}
          showSession={showSession}
          onFilterChange={this.onFilterChange}
        />
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={reportsData}
          initialState={{
            sorting: [
              {
                id: 'createDate',
                desc: true
              }
            ]
          }}
          loading={reportListRequested}
          pagination={pagination}
          onPaginationChange={this.paginate}
          onSortingChange={this.sort}
        />
      </div>
    );
  }
}
export default ReportList;
