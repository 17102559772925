import createFetchSaga from './createFetchSaga';
import { fetchSessionListAction, fetchScreenerSessionListAction } from '../actions/sessionActions';
import { fetchSessions } from '../../../api/sessionsApi';

function* sessionListSaga() {
  const saga = createFetchSaga(fetchSessionListAction, fetchSessions);
  yield* saga();
}

function* screenerSessionListSaga() {
  const saga = createFetchSaga(fetchScreenerSessionListAction, fetchSessions);
  yield* saga();
}

export { sessionListSaga, screenerSessionListSaga };
