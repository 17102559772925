import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

const AllOrAnySelector = ({ rule, setAllOrAny, readOnly }) => {
  return (
    <Input
      type="select"
      className="all-or-any-selector"
      value={rule.allOrAny}
      disabled={readOnly}
      onChange={setAllOrAny}
    >
      <option value="ALL">{intl.get('app.surveyRules.all')}</option>
      <option value="ANY">{intl.get('app.surveyRules.any')}</option>
    </Input>
  );
};

export default AllOrAnySelector;
