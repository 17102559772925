import React from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { accessHandler, NavTab } from 'webapp-common';
import appConfig from '../../../appConfig';
import SurveyTemplateLibrary from '../../surveyTemplateLibrary';
import MediaLibrary from '../../mediaLibrary';
import QuestionLibrary from '../../questionLibrary/QuestionLibrary';
import DataTableList from '../../dataTableList';
import Functions from '../../functions';
import ProjectSurvey from '../../project/survey/';

export const SURVEY_TEMPLATES_TAB = 'surveyTemplates';
export const MEDIA_TAB = 'media';
export const QUESTIONS_TAB = 'questions';
export const SCREENER_TEMPLATES_TAB = 'screenerTemplates';
export const DATA_TAB = 'data';
export const FUNCTIONS = 'functions';

export const SURVEY_TEMPLATE_TAB = 'surveyTemplate';
export const SCREENER_TEMPLATE_TAB = 'screenerTemplate';

export const LibraryPage = props => {
  const { hasProjectView } = props;

  const routeParams = useParams();
  const { tab: activeTab = MEDIA_TAB } = routeParams;

  function getUrl(tab) {
    return `${appConfig.libraryPagePath}/${tab}`;
  }

  function isTemplateTab(tab) {
    return tab === SURVEY_TEMPLATE_TAB || tab === SCREENER_TEMPLATE_TAB;
  }

  const accessCheck = accessHandler.checkAccess(hasProjectView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <section className="app-body">
      <section className="left-nav">
        <NavTab
          icon="fa-photo-video"
          iconStyle="solid"
          label={intl.get('app.title.media')}
          active={activeTab === MEDIA_TAB}
          to={getUrl(MEDIA_TAB)}
        />
        <NavTab
          icon="fa-question-square"
          iconStyle="solid"
          label={intl.get('app.title.questions')}
          active={activeTab === QUESTIONS_TAB}
          to={getUrl(QUESTIONS_TAB)}
        />
        <NavTab
          icon="fa-poll"
          iconStyle="light"
          label={intl.get('app.title.surveyTemplates')}
          active={activeTab === SURVEY_TEMPLATES_TAB || activeTab === SURVEY_TEMPLATE_TAB}
          to={getUrl(SURVEY_TEMPLATES_TAB)}
        />
        <NavTab
          icon="fa-user-check"
          iconStyle="light"
          label={intl.get('app.title.screenerTemplates')}
          active={activeTab === SCREENER_TEMPLATES_TAB || activeTab === SCREENER_TEMPLATE_TAB}
          to={getUrl(SCREENER_TEMPLATES_TAB)}
        />
        <NavTab
          icon="fa-table"
          iconStyle="solid"
          label={intl.get('app.title.data')}
          active={activeTab === DATA_TAB}
          to={getUrl(DATA_TAB)}
        />
        <NavTab
          icon="fa-function"
          iconStyle="solid"
          label={intl.get('app.title.functions')}
          active={activeTab === FUNCTIONS}
          to={getUrl(FUNCTIONS)}
        />
      </section>
      <div className={isTemplateTab(activeTab) ? '' : 'body-container l2-bg'}>
        {activeTab === MEDIA_TAB && <MediaLibrary sortable />}
        {activeTab === QUESTIONS_TAB && <QuestionLibrary enablePreview showSurveyTypeColumn />}
        {activeTab === SURVEY_TEMPLATES_TAB && <SurveyTemplateLibrary />}
        {activeTab === SCREENER_TEMPLATES_TAB && <SurveyTemplateLibrary isScreener={true} />}
        {activeTab === DATA_TAB && <DataTableList />}
        {activeTab === FUNCTIONS && <Functions />}
        {activeTab === SURVEY_TEMPLATE_TAB && <ProjectSurvey isScreener={false} routeParams={routeParams} />}
        {activeTab === SCREENER_TEMPLATE_TAB && <ProjectSurvey isScreener routeParams={routeParams} />}
      </div>
    </section>
  );
};

export default LibraryPage;
