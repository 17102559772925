import React, { useRef } from 'react';
import intl from 'react-intl-universal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { InvokeModal } from 'webapp-common';

import './TerminateParticipantModal.css';

function getTerminateReasons() {
  return [
    intl.get('app.TERMINATE_REASON_NON_RESPONSIVE'),
    intl.get('app.TERMINATE_REASON_TECH'),
    intl.get('app.TERMINATE_REASON_DISRUPTIVE'),
    intl.get('app.TERMINATE_REASON_DOESNT_BELONG')
  ];
}

export const TerminateParticipantModal = props => {
  const { participant, toggle } = props;

  const reasonInput = useRef();

  function terminateParticipant() {
    props.terminateParticipant(reasonInput.current.getInput().value);
    toggle();
  }

  return (
    <InvokeModal
      showModal
      className="terminate-participant-modal"
      modalTitle={intl.get('app.terminateParticipant')}
      toggle={toggle}
      primaryButtonText={intl.get('app.ok')}
      cancelButtonText={intl.get('app.cancel')}
      save={terminateParticipant}
      enableSave={true}
    >
      <div>{intl.get('app.terminate.confirm', { user: participant.nickname || participant.id })}</div>
      <div>{intl.get('app.terminationReason')}</div>
      <Typeahead
        emptyLabel=""
        options={getTerminateReasons()}
        placeholder={intl.get('app.terminate.placeholder')}
        ref={reasonInput}
      />
    </InvokeModal>
  );
};
