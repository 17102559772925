import { createActions } from 'redux-actions';
import createFetchActions from './createFetchActions';

const { logoutUser, validateAuth, validateAuthFailed } = createActions(
  {},
  'LOGOUT_USER',
  'VALIDATE_AUTH',
  'VALIDATE_AUTH_FAILED'
);

const fetchAuthTokenActions = createFetchActions('AUTH_TOKEN', {
  request_payload: (username, password, client) => ({
    username,
    password,
    client
  })
});

const changePasswordActions = createFetchActions('CHANGE_PASSWORD');

export { fetchAuthTokenActions, logoutUser, validateAuth, validateAuthFailed, changePasswordActions };
