import { handleActions, combineActions } from 'redux-actions';
import { fetchSurveyTemplatesActions, createSurveyTemplateActions } from '../actions/surveyActions';

const initialState = {
  surveyTemplatesRequested: false
};

const surveyTemplatesReducer = handleActions(
  {
    [combineActions(fetchSurveyTemplatesActions.triggered, createSurveyTemplateActions.triggered)](state) {
      return { ...state, surveyTemplatesRequested: true, surveyTemplateCreated: null };
    },
    [fetchSurveyTemplatesActions.succeeded](state, { payload }) {
      return {
        ...payload,
        surveyTemplatesRequested: false,
        surveyTemplateCreated: null
      };
    },
    [createSurveyTemplateActions.succeeded](state, { payload }) {
      return {
        ...payload,
        surveyTemplatesRequested: false,
        surveyTemplateCreated: true
      };
    },
    [combineActions(fetchSurveyTemplatesActions.failed, createSurveyTemplateActions.failed)](state) {
      return { ...state, surveyTemplatesRequested: false, surveyTemplateCreated: null };
    }
  },
  initialState
);

export default surveyTemplatesReducer;
