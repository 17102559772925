import React, { useEffect, useReducer } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { cloneDeep, isEmpty, get } from 'lodash';
import { Input, Label } from 'reactstrap';
import intl from 'react-intl-universal';
import { FlexRow } from 'webapp-common';
import { RESPONSE_SET_TYPE, DEFAULT_PAGE_SIZE } from '../../../util/joinerUtil';
import { filterUtil } from '../../../util/filterUtil';
import { ScreenerSummary } from '../screenerSummary/ScreenerSummary';
import { SelectDataPointsModal } from '../../researchDashboard/rdFilters/selectDataPoints/SelectDataPointsModal';
import { FilterLabel } from '../../researchDashboard/rdFilters/FilterLabel';
import { ChoiceResponses } from './responseDetailsTable/choiceResponses';
import { MatrixResponses } from './responseDetailsTable/matrixResponses';
import OpenTextResponses from './responseDetailsTable/openTextResponses';

import './ScreenerResponses.css';

// From ScreenerResponseSummaryReq.java
const COMPLETE = 'COMPLETE';
const INCOMPLETE = 'INCOMPLETE';
const ALL = 'ALL';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

function getScreenerResponseFilters(sessionId) {
  const json = sessionStorage.getItem(`screenerResponseSearchFilters_${sessionId}`);
  return (
    (json && JSON.parse(json)) || {
      qualifiedStatus: ALL,
      filter: undefined
    }
  );
}

function setScreenerResponseFilters(sessionId, searchFilters) {
  sessionStorage.setItem(`screenerResponseSearchFilters_${sessionId}`, JSON.stringify(searchFilters));
}

const ScreenerResponses = props => {
  const [state, setState] = useReducer(reducer, {
    questionJoiners: [],
    screenerJoiners: [], // List of selected joiners
    searchFilters: getScreenerResponseFilters(props.sessionId),
    showFilterMenu: false
  });

  const projectStore = useSelector(
    state => state.projects !== undefined && state.projects[props.projectId],
    shallowEqual
  );
  const { projectDetails: project } = projectStore || {};

  useEffect(() => {
    if (!props.survey && props.surveyId) {
      props.fetchSurvey(props.surveyId);
    }
  }, [props.surveyId]);

  useEffect(() => {
    // We're calling this to get some things for OpenTextResponses and FiltersMenu
    props.fetchEnrollmentInfo({
      metaDataCollectionId: props.metaDataCollectionId
    });
    return () => {
      // clean-up
      fetchScreenersAnswersTotal('unsubscribe');
    };
  }, []);

  useEffect(() => {
    fetchScreenersAnswersTotal('subscribe');
  }, [state.searchFilters, state.screenerJoiners]);

  function fetchScreenersAnswersTotal(subAction) {
    props.fetchScreenersAnswersTotal({
      subAction,
      qualifiedStatus: state.searchFilters.qualifiedStatus,
      filter: state.searchFilters.filter,
      screenerJoiners: state.screenerJoiners,
      sessionId: props.sessionId
    });
  }

  if (!props.surveyId) {
    return (
      <div style={{ minHeight: '20rem', fontWeight: 'bolder', paddingTop: '2rem' }}>
        {intl.get('app.noSurveyDefined')}
      </div>
    );
  }

  const joiners = get(props, 'survey.joiners');
  if (joiners && joiners.length === 0) {
    return (
      <div style={{ minHeight: '20rem', fontWeight: 'bolder', paddingTop: '2rem' }}>
        {intl.get('app.noQuestionDefined')}
      </div>
    );
  }

  if (joiners && !state.questionJoiners.length > 0) {
    setState({
      questionJoiners: joiners
    });
  }

  function toggleFilterMenu() {
    setState({
      showFilterMenu: !state.showFilterMenu
    });
  }

  /*
   * Update the search filters in both the sessionStorage and the local state.
   * This will trigger a re-fetch, so we also need to reset the pageNumber
   * to 1 for all joiners.
   */
  function updateSearchFilters(searchFilters) {
    setScreenerResponseFilters(props.sessionId, searchFilters);
    const screenerJoiners = cloneDeep(state.screenerJoiners);
    screenerJoiners.forEach(j => (j.pageNumber = 1));
    setState({
      searchFilters,
      screenerJoiners
    });
  }

  /*
   * Handles the output of the FiltersMenu component after the user has selected some criteria.
   */
  function selectFilter(expression) {
    const filter = {
      expression,
      type: 'GENERAL_FILTER'
    };
    const searchFilters = getScreenerResponseFilters(props.sessionId);
    searchFilters.filter = filter;
    updateSearchFilters(searchFilters);
    toggleFilterMenu();
  }

  function removeFilter() {
    const searchFilters = getScreenerResponseFilters(props.sessionId);
    searchFilters.filter = undefined;
    updateSearchFilters(searchFilters);
  }

  function handleQualifiedStatus(e) {
    const searchFilters = getScreenerResponseFilters(props.sessionId);
    searchFilters.qualifiedStatus = e.target.value;
    updateSearchFilters(searchFilters);
  }

  const toggleDetails = index => {
    const questionJoiners = cloneDeep(state.questionJoiners);
    questionJoiners[index].showJoinerDetail = !questionJoiners[index].showJoinerDetail;
    const screenerJoiners = questionJoiners.reduce((selected, joiner) => {
      if (joiner.showJoinerDetail) {
        joiner.def.responseSet.type === RESPONSE_SET_TYPE.open
          ? selected.push({ joinerId: joiner.id, pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE })
          : selected.push({ joinerId: joiner.id });
      }
      return selected;
    }, []);
    setState({
      questionJoiners,
      screenerJoiners
    });
  };

  const getPagedOrSortedResponses = (joinerId, pageNumber, pageSize, sortField, sortOrder) => {
    const screenerJoiners = cloneDeep(state.screenerJoiners);
    screenerJoiners.forEach(sj => {
      if (sj.joinerId === joinerId) {
        sj.pageNumber = pageNumber;
        sj.pageSize = pageSize;
        sj.sortBy = sortField;
        sj.sortOrder = sortOrder;
      }
    });
    setState({
      screenerJoiners
    });
  };

  const flattenArray = (answers, key, value) => {
    return answers
      ? answers.reduce((answer, current) => {
          answer[current[key]] = value ? current[value] : current;
          return answer;
        }, {})
      : {};
  };

  const getPercentAnswered = (joiner, answers, totalParticipants) => {
    if (totalParticipants && answers[joiner.id]) {
      return Math.round((answers[joiner.id] / totalParticipants) * 100) + '%';
    }
    return 'N/A';
  };

  const getQuestionJoiner = () => {
    const { saveParticipantActive, sessionId, survey, enrollmentInfo } = props;
    const totalParticipants = get(props, 'screenerSummary.totalParticipants');
    const answersTotal = get(props, 'screenersAnswersTotal', []);
    const answers = flattenArray(answersTotal, 'joinerId', 'totalResponses');
    const screenersAnswersSummaries = flattenArray(props.screenersAnswersSummaries, 'joinerRowColId');
    const { joiners, segmentCategories } = survey || {};
    const { columnOrder, enrolleeDataDef, keyTransformMap = {} } = enrollmentInfo || {};

    return state.questionJoiners.map((joiner, index) => {
      const type = get(joiner, 'def.responseSet.type') || get(joiner, 'stim.type');
      const joinerType = type ? intl.get(`app.questionType.${type}`) : undefined;
      const showJoinerDetail = joiner.showJoinerDetail && !joiner.stimOnly;
      return (
        <React.Fragment key={joiner.id}>
          {!joiner.stimOnly && !joiner.container && !joiner.emailCollector && (
            <FlexRow
              onClick={() => toggleDetails(index)}
              className={joiner.stimOnly ? 'not-allowed row-head' : 'clickable row-head'}
            >
              <span>
                <span>{showJoinerDetail ? <Label className="me-2">-</Label> : <Label className="me-2">+</Label>}</span>
                <Label>{`${joiner.questionNum}. ${joiner.researchPrompt}`}</Label>
              </span>
              <span>
                <div className="me-5 inline-block response-stats">{joinerType}</div>
                <div className="inline-block response-stats">
                  {!isEmpty(answers) &&
                    answers[joiner.id] &&
                    `${answers[joiner.id]} - ${getPercentAnswered(joiner, answers, totalParticipants)}`}
                </div>
                <span />
              </span>
            </FlexRow>
          )}
          {showJoinerDetail && type === RESPONSE_SET_TYPE.matrix && (
            <MatrixResponses joiner={joiner} summary={screenersAnswersSummaries[joiner.id]} />
          )}
          {showJoinerDetail && type === RESPONSE_SET_TYPE.multi && (
            <ChoiceResponses joiner={joiner} summary={screenersAnswersSummaries[joiner.id]} />
          )}
          {showJoinerDetail && type === RESPONSE_SET_TYPE.open && (
            <OpenTextResponses
              joinerId={joiner.id}
              summary={screenersAnswersSummaries[joiner.id]}
              sessionId={sessionId}
              fetchScreenerPreviewResults={props.fetchScreenerPreviewResults}
              screenerPreviewResults={props.screenerPreviewResults}
              getPagedOrSortedResponses={getPagedOrSortedResponses}
              saveParticipantActive={saveParticipantActive}
              // These are needed by participantListUtil.getColumnOrder()
              columnOrder={columnOrder}
              questionJoiners={joiners}
              segmentCategories={segmentCategories}
              enrolleeDataDef={enrolleeDataDef}
              keyTransformMap={keyTransformMap}
            />
          )}
        </React.Fragment>
      );
    });
  };

  function getFilterLabel() {
    if (props.enrollmentInfo && state.searchFilters.filter && joiners) {
      const { keyTransformMap = {} } = props.enrollmentInfo;
      const expressionElem = filterUtil.renderFilterExpression(state.searchFilters.filter, joiners, keyTransformMap);
      return <FilterLabel name={expressionElem} close={removeFilter} />;
    }
    return null;
  }

  return (
    <>
      <ScreenerSummary
        screenerSummary={props.screenerSummary}
        segmentCategories={props.survey && props.survey.segmentCategories}
        showSubTotals={false}
      />
      <div className="screener-response-filters">
        <div className="filter-controls">
          <div className="filter-bar">
            <i className="fas fa-filter" onClick={toggleFilterMenu} />
            {getFilterLabel()}
          </div>
          <Input type="select" value={state.searchFilters.qualifiedStatus} onChange={handleQualifiedStatus}>
            <option value={ALL}>All Responses</option>
            <option value={COMPLETE}>Complete</option>
            <option value={INCOMPLETE}>Incomplete</option>
          </Input>
        </div>
        {state.showFilterMenu && (
          <SelectDataPointsModal
            enrolleesInfo={props.enrollmentInfo}
            questionJoiners={joiners}
            segmentCategories={props.survey && props.survey.segmentCategories}
            session={props.session}
            mode="screener"
            selectedTab="participantData"
            project={project}
            onSave={selectFilter}
            toggle={toggleFilterMenu}
          />
        )}
      </div>
      {state.questionJoiners.length > 0 && <div className="screener-responses">{getQuestionJoiner()}</div>}
    </>
  );
};

export default ScreenerResponses;
