import createFetchSaga from './createFetchSaga';

import { fetchVideoResponsePathActions } from '../actions/videoCaptureActions';

import { fetchVideoResponsePath } from '../../../api/VideoCaptureApi';

function* videoResponsePathSaga() {
  const saga = createFetchSaga(fetchVideoResponsePathActions, fetchVideoResponsePath);
  yield* saga();
}

export { videoResponsePathSaga };
