import { handleActions, combineActions } from 'redux-actions';
import { fetchSessionListAction, fetchScreenerSessionListAction } from '../actions/sessionActions';

const initialState = {
  sessions: {
    content: [],
    pageRequest: {}
  },
  screenerSessions: {
    content: [],
    pageRequest: {}
  },
  sessionListRequested: false
};

const sessionListReducer = handleActions(
  {
    [combineActions(fetchSessionListAction.triggered, fetchScreenerSessionListAction.triggered)](state) {
      return { ...state, sessionListRequested: true };
    },

    [combineActions(fetchSessionListAction.succeeded, fetchScreenerSessionListAction.succeeded)](state, props) {
      const collection = props.type === 'FETCH_SCREENER_SESSION_LIST_SUCCEEDED' ? 'screenerSessions' : 'sessions';
      return {
        ...state,
        [collection]: { ...props.payload },
        sessionListRequested: false
      };
    },

    [combineActions(fetchSessionListAction.failed, fetchScreenerSessionListAction.failed)](state) {
      return { ...state, sessionListRequested: false };
    }
  },
  initialState
);

export default sessionListReducer;
