import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { subscribeScreenerSummaryActions } from '../../../../store/redux/actions/participantDataActions';
import ParticipantsResponses from '../../../sessionDetails/screenerResponses';

export const ScreenerResponses = props => {
  const { sessionId } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionId) {
      dispatch(
        subscribeScreenerSummaryActions.request({
          subAction: 'subscribe',
          sessionId
        })
      );

      return () => {
        dispatch(
          subscribeScreenerSummaryActions.request({
            subAction: 'unsubscribe',
            sessionId
          })
        );
      };
    }
  }, [sessionId]);

  return <ParticipantsResponses {...props} />;
};
