import React from 'react';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

const UNAUTHORIZED = 'unauthorized';

export const LoginForm = props => {
  const { authError, onLogin, queries } = props;

  function isPasswordResetSuccess() {
    const { email, success, message } = queries || {};
    return !!(email && success === 'true' && message);
  }

  function getHeaderTitleAndMessage() {
    let titleKey = '';
    let msgKey = '';
    if (authError && authError.error === UNAUTHORIZED) {
      titleKey = 'app.accountDisabled';
      msgKey = 'app.accountDisabled.message';
    } else if (authError) {
      titleKey = 'app.loginFailed';
      msgKey = 'app.loginFailed.message';
    } else if (isPasswordResetSuccess()) {
      titleKey = 'app.login.passwordReset';
      msgKey = 'app.login.passwordReset.message';
    }
    return {
      title: titleKey ? intl.get(titleKey) : '',
      msg: msgKey ? intl.get(msgKey) : ''
    };
  }

  function getPageHeader() {
    const titleAndMsg = getHeaderTitleAndMessage();
    return (
      <div className="align-center">
        <h5 className="welcome-text">{titleAndMsg.title}</h5>
        {titleAndMsg.msg && <div className="support-message mb-5">{titleAndMsg.msg}</div>}
      </div>
    );
  }

  return (
    <>
      {getPageHeader()}
      <Button color="primary" onClick={onLogin} style={{ width: 'auto', padding: '0 3rem', fontSize: '1.5rem' }}>
        {intl.get('app.login.signin')}
      </Button>
    </>
  );
};
