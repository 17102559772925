import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

export const CreateCustomReportConfigModal = props => {
  const [newConfigName, setNewConfigName] = useState('');

  function updateConfigName(e) {
    setNewConfigName(e.target.value);
  }

  function onSave() {
    props.onSave({
      name: newConfigName
    });
  }

  return (
    <InvokeModal
      showModal
      enableSave={!!newConfigName}
      toggle={props.toggle}
      save={onSave}
      modalTitle={intl.get('app.customReports.addCustomReportConfig')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
    >
      <div style={{ marginBottom: '1rem' }}>
        <h6>{intl.get('app.customReports.configName')}:</h6>
        <Input
          className="mt-3 w-100"
          placeholder={intl.get('app.customReports.configName.placeholder')}
          onChange={updateConfigName}
        />
      </div>
    </InvokeModal>
  );
};
