import React from 'react';
import intl from 'react-intl-universal';
import { Input, Row } from 'reactstrap';
import { cloneDeep, get } from 'lodash';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';
import { getVideoStimFromJoiner, getNonPrefixedJoinerTitle } from '../../../../../util/conceptRotationUtil';
import { getJoinerType, RESPONSE_SET_TYPE, UPDATE_HANDLER_TYPE, ENGLISH } from '../../../../../util/joinerUtil';

import './VideoDialOptions.css';

const maxLength = '32';
const min = '0';
const max = '10000';

const VideoDialOptions = props => {
  const { readOnly, joiner, joiners, updateVideoDialConfig, language, viewLanguage, isCRType = false } = props;

  const stim = isCRType ? getVideoStimFromJoiner(joiner) : (joiner && joiner.stim) || {};
  const { options = {} } = stim;
  const { dialConfig = {} } = options;
  const dialConfigDisabled = readOnly || !dialConfig.enabled;

  /*
   * Enable/disable the config. If enabling for the first time, set the left and right labels to default text.
   */
  function toggleConfig(e) {
    const enabled = e.target.checked;
    const clone = cloneDeep(dialConfig);
    clone.enabled = enabled;
    if (enabled && !clone.labelLeft && !clone.origLabelLeft && !clone.labelRight && !clone.origLabelRight) {
      if (language === viewLanguage) {
        clone.labelLeft = intl.get('app.stim.options.dialConfig.dislike');
        clone.labelRight = intl.get('app.stim.options.dialConfig.like');
      }
      if (viewLanguage === ENGLISH) {
        clone.origLabelLeft = intl.get('app.stim.options.dialConfig.dislike');
        clone.origLabelRight = intl.get('app.stim.options.dialConfig.like');
        if (language !== viewLanguage) {
          // unset so translation happens on back-end
          clone.labelLeft = '';
          clone.labelRight = '';
        }
      }
    }
    updateVideoDialConfig(clone, UPDATE_HANDLER_TYPE.dialConfig);
  }

  function updateLabelLeft(e) {
    const clone = cloneDeep(dialConfig);
    if (language === viewLanguage) {
      clone.labelLeft = e.target.value;
    }
    if (viewLanguage === ENGLISH) {
      clone.origLabelLeft = e.target.value;
      if (language !== viewLanguage) {
        // unset so translation happens on back-end
        clone.labelLeft = '';
      }
    }
    updateVideoDialConfig(clone, UPDATE_HANDLER_TYPE.dialConfig);
  }

  function updateLabelRight(e) {
    const clone = cloneDeep(dialConfig);
    if (language === viewLanguage) {
      clone.labelRight = e.target.value;
    }
    if (viewLanguage === ENGLISH) {
      clone.origLabelRight = e.target.value;
      if (language !== viewLanguage) {
        // unset so translation happens on back-end
        clone.labelRight = '';
      }
    }
    updateVideoDialConfig(clone, UPDATE_HANDLER_TYPE.dialConfig);
  }

  function updateVideoDialScale(key, value) {
    let scale = parseInt(value);
    if (isNaN(scale)) {
      scale = null;
    }
    updateVideoDialConfig(key, scale, UPDATE_HANDLER_TYPE.dialConfigField);
  }

  /*
   * Get the list of questions that can be used for an action button click.
   * The questions have to be hidden and of type open or multi.
   */
  function getActionQuestionOptions() {
    const options = [<option value="" disabled key="placeholderOption" />];
    if (!isCRType) {
      joiners.forEach(j => {
        const joinerType = getJoinerType(j);
        if (j.hidden && (joinerType === RESPONSE_SET_TYPE.open || joinerType === RESPONSE_SET_TYPE.multi)) {
          options.push(
            <option value={j.id} key={j.id}>
              {j.researchPrompt}
            </option>
          );
        }
      });
    } else {
      const joiners = joiner.conceptRotation.concepts[0].joiners;
      joiners?.forEach(j => {
        const joinerType = getJoinerType(j);
        if (j.hidden && (joinerType === RESPONSE_SET_TYPE.open || joinerType === RESPONSE_SET_TYPE.multi)) {
          options.push(
            <option value={j.parentId} key={j.id}>
              {getNonPrefixedJoinerTitle(j)}
            </option>
          );
        }
      });
    }

    return options;
  }

  function getUpdateVideoDialConfigLabel(label, newLabel) {
    if (language === viewLanguage) {
      return newLabel;
    }
    if (viewLanguage === ENGLISH) {
      // unset so translation happens on back-end
      return '';
    }
    return label;
  }

  function getActionButtonControl(index, dialConfigDisabled) {
    const actionButtons = get(stim, 'options.dialConfig.actionButtons') || [];
    const actionButton = actionButtons[index] || {};
    const {
      label = '',
      origLabel = '',
      multiClick = false,
      enableAdHocQuestion = false,
      adHocJoinerId = ''
    } = actionButton;

    const addDisabled =
      index === 2 ||
      actionButtons.length === 3 ||
      (language === viewLanguage && !label) ||
      (viewLanguage === ENGLISH && !origLabel);

    return (
      <Row key={index}>
        <Input
          className="action-button-field"
          value={language === viewLanguage ? label : origLabel}
          disabled={dialConfigDisabled}
          onChange={e =>
            updateVideoDialConfig(
              {
                index,
                label: getUpdateVideoDialConfigLabel(label, e.target.value),
                origLabel: viewLanguage === ENGLISH ? e.target.value : origLabel,
                multiClick,
                enableAdHocQuestion,
                adHocJoinerId
              },
              UPDATE_HANDLER_TYPE.dialActionButton
            )
          }
          maxLength={maxLength}
        />
        <div className="action-button-options">
          <label disabled={dialConfigDisabled || enableAdHocQuestion}>
            <Input
              type="checkbox"
              checked={multiClick}
              disabled={dialConfigDisabled || enableAdHocQuestion}
              onChange={() =>
                updateVideoDialConfig(
                  {
                    index,
                    label,
                    origLabel,
                    multiClick: !multiClick,
                    enableAdHocQuestion,
                    adHocJoinerId
                  },
                  UPDATE_HANDLER_TYPE.dialActionButton
                )
              }
            />
            {intl.get('app.allowMultiple')}
          </label>
          <div className="show-question-control">
            <label disabled={dialConfigDisabled || multiClick}>
              <Input
                type="checkbox"
                checked={enableAdHocQuestion}
                disabled={dialConfigDisabled || multiClick}
                onChange={() =>
                  updateVideoDialConfig(
                    {
                      index,
                      label,
                      origLabel,
                      multiClick,
                      enableAdHocQuestion: !enableAdHocQuestion,
                      adHocJoinerId
                    },
                    UPDATE_HANDLER_TYPE.dialActionButton
                  )
                }
              />
              {intl.get('app.showQuestion')}
            </label>
            <Input
              type="select"
              value={adHocJoinerId || ''}
              disabled={dialConfigDisabled || !enableAdHocQuestion}
              onChange={e =>
                updateVideoDialConfig(
                  {
                    index,
                    label,
                    origLabel,
                    multiClick,
                    enableAdHocQuestion,
                    adHocJoinerId: e.target.value
                  },
                  UPDATE_HANDLER_TYPE.dialActionButton
                )
              }
            >
              {getActionQuestionOptions()}
            </Input>
          </div>
        </div>
        <AddRemoveIcons
          onAdd={() => updateVideoDialConfig(index + 1, UPDATE_HANDLER_TYPE.addActionButton)}
          onRemove={() => updateVideoDialConfig(index, UPDATE_HANDLER_TYPE.removeActionButton)}
          addDisabled={addDisabled}
          removeDisabled={index === 0 && actionButtons.length < 2 && !label}
        />
      </Row>
    );
  }

  function getActionButtonControls(dialConfigDisabled) {
    const actionButtons = get(stim, 'options.dialConfig.actionButtons') || [];
    if (actionButtons.length === 0) {
      return getActionButtonControl(0, dialConfigDisabled);
    }
    return actionButtons.map((actionButton, index) => getActionButtonControl(index, dialConfigDisabled));
  }

  return (
    <>
      <label>
        <Input type="checkbox" checked={dialConfig.enabled} disabled={readOnly} onChange={toggleConfig} />
        {intl.get('app.media.enableDial')}
      </label>
      <div className="dial-config">
        <Row>
          <label disabled={dialConfigDisabled}>{intl.get('app.labels')}:</label>
          <Input
            value={(language === viewLanguage ? dialConfig.labelLeft : dialConfig.origLabelLeft) || ''}
            disabled={dialConfigDisabled}
            onChange={updateLabelLeft}
            maxLength={maxLength}
          />
          <Input type="range" tabIndex="-1" />
          <Input
            value={(language === viewLanguage ? dialConfig.labelRight : dialConfig.origLabelRight) || ''}
            disabled={dialConfigDisabled}
            onChange={updateLabelRight}
            maxLength={maxLength}
          />
        </Row>
        <Row>
          <label disabled={dialConfigDisabled}>{intl.get('app.scale')}:</label>
          <Input
            type="number"
            value={dialConfig.scaleLeft === null ? '' : dialConfig.scaleLeft}
            disabled={dialConfigDisabled}
            onChange={e => updateVideoDialScale('scaleLeft', e.target.value)}
            min={min}
            max={max}
          />
          <div />
          <Input
            type="number"
            value={dialConfig.scaleRight === null ? '' : dialConfig.scaleRight}
            disabled={dialConfigDisabled}
            onChange={e => updateVideoDialScale('scaleRight', e.target.value)}
            min={min}
            max={max}
          />
        </Row>
        <label disabled={dialConfigDisabled}>{intl.get('app.actionButtons.configText')}:</label>
        <div className="action-button-controls">{getActionButtonControls(dialConfigDisabled)}</div>
      </div>
    </>
  );
};

export default VideoDialOptions;
