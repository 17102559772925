import { connect } from 'react-redux';
import { ReportsHomePage } from './ReportsHomePage';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';

const mapStateToProps = state => {
  const {
    sessionUser,
    abilities: { hasReportsManage, hasReportsView, isCustomReportsEnabled }
  } = sessionUserSelector(state);
  return {
    sessionUser,
    hasReportsManage,
    hasReportsView,
    isCustomReportsEnabled
  };
};

export default connect(mapStateToProps)(ReportsHomePage);
