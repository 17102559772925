import { handleActions } from 'redux-actions';
import { fetchUsersActions, fetchUserActions, saveUserActions } from '../actions/clientAdminActions';

const initialState = {
  pagedList: {
    content: [],
    pageRequest: {}
  },
  users: {},
  usersRequested: false,
  fetchUserInProgress: false,
  saveUserInProgress: false
};

const clientAdminUsersReducer = handleActions(
  {
    [fetchUsersActions.triggered](state) {
      return { ...state, usersRequested: true, userSaveSuccess: false };
    },
    [fetchUsersActions.succeeded](state, { payload }) {
      const { users } = state;
      payload.content.forEach(user => {
        users[user.userID] = user;
      });
      return {
        ...state,
        pagedList: payload,
        users,
        usersRequested: false
      };
    },
    [fetchUsersActions.failed](state) {
      return { ...state, usersRequested: false };
    },

    [fetchUserActions.triggered](state) {
      return { ...state, fetchUserInProgress: true, userSaveSuccess: false };
    },
    [fetchUserActions.succeeded](state, { payload }) {
      const { users } = state;
      users[payload.userID] = payload;
      return {
        ...state,
        users,
        fetchUserInProgress: false
      };
    },
    [fetchUserActions.failed](state) {
      return { ...state, fetchUserInProgress: false };
    },
    [saveUserActions.triggered](state) {
      return { ...state, saveUserInProgress: true, userSaveSuccess: false };
    },
    [saveUserActions.succeeded](state, { payload }) {
      return {
        ...state,
        users: {
          ...state.users,
          [payload.userID]: payload
        },
        saveUserInProgress: false,
        userSaveSuccess: true
      };
    },
    [saveUserActions.failed](state) {
      return { ...state, saveUserInProgress: false };
    }
  },
  initialState
);

export default clientAdminUsersReducer;
