import { get, post } from '../util/request';

const DIAL_SETTING_API = '/a/api/dialSettingConfig';
const FETCH_VIDEO_WATERMARK_API = '/a/api/claimWatermark';
const DELETE_VIDEO_WATERMARK_API = '/a/api/deleteClaimedWatermark';

async function fetchDialSettingConfig(params) {
  const { userId, sessionId, joinerId } = params;
  if (!userId || !sessionId || !joinerId) {
    return {};
  }
  const url = `${DIAL_SETTING_API}/${userId}/${sessionId}/${joinerId}`;
  return get(url)().then(res => res.body?.payload ?? {});
}

async function saveDialSettingConfig(config) {
  return post(DIAL_SETTING_API)(config).then(res => {
    if (res.body?.payload?.id) {
      return {
        ...res.body.payload,
        dialSettingConfigSaved: true
      };
    }
    return { dialSettingConfigSaveError: true };
  });
}

async function fetchVideoWatermark(params) {
  return post(FETCH_VIDEO_WATERMARK_API)(params).then(res => {
    if (res.body?.payload) {
      return res.body.payload.length === 13
        ? {
            videoId: res.body.payload
          }
        : {
            refId: res.body.payload
          };
    }
    return { fetchVideoWatermarkError: true };
  });
}

async function deleteVideoWatermark(params) {
  return post(DELETE_VIDEO_WATERMARK_API)(params).then(res => {
    if (res.body?.payload) {
      return {
        ...res.body.payload,
        deleteVideoWatermarkSuccess: true
      };
    }
    return { deleteVideoWatermarkError: true };
  });
}

export { fetchDialSettingConfig, saveDialSettingConfig, fetchVideoWatermark, deleteVideoWatermark };
