import React from 'react';
import intl from 'react-intl-universal';
import { SentimentSummaryPieChart } from './SentimentSummaryPieChart';
import { SentimentSummaryKeywordsChart } from './SentimentSummaryKeywordsChart';

import './SentimentSummary.css';

export const SentimentSummary = props => {
  const { keywords, sentiments, keywordSentiments, closeSentimentSummary } = props;
  return (
    <div className="sentiment-summary-container">
      <h6>{intl.get('app.sentimentSummary')}</h6>
      <i className="far fa-times-circle" onClick={closeSentimentSummary} />
      <div className="sentiment-summary-body">
        <div className="sentiment-summary-left">
          <div className="sentiment-chart-legend">
            <div>
              <div className="sentiment-positive" />
              <div>{intl.get('app.positive').toLowerCase()}</div>
            </div>
            <div>
              <div className="sentiment-neutral" />
              <div>{intl.get('app.neutral').toLowerCase()}</div>
            </div>
            <div>
              <div className="sentiment-negative" />
              <div>{intl.get('app.negative').toLowerCase()}</div>
            </div>
          </div>
          <SentimentSummaryPieChart sentiments={sentiments} />
        </div>
        <div className="sentiment-summary-right">
          <SentimentSummaryKeywordsChart keywords={keywords} keywordSentiments={keywordSentiments} />
        </div>
      </div>
    </div>
  );
};
