import { connect } from 'react-redux';
import {
  fetchTenantAbilitiesAction,
  fetchTenantRolesAction,
  updateTenantRolesAction,
  deleteTenantRolesAction,
  addRoleAction
} from '../../../store/redux/actions/clientAdminActions';
import { tenantRolesSelector } from '../../../store/redux/selectors/clientAdminTenantRolesSelector';
import { abilitiesSelector } from '../../../store/redux/selectors/abilitiesSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { EditRoles } from './EditRoles';

const mapStateToProps = state => {
  const rolesSelector = tenantRolesSelector()(state);
  const { tenantRoles } = rolesSelector;
  const abilities = abilitiesSelector(state).abilities;
  const { hasRoleManage } = sessionUserSelector(state).abilities;
  return {
    tenantRoles,
    hasRoleManage,
    abilities
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRoles: () => dispatch(fetchTenantRolesAction.request()),
  fetchAbilities: () => dispatch(fetchTenantAbilitiesAction.request()),
  updateRoles: payload => dispatch(updateTenantRolesAction.request(payload)),
  addRole: payload => dispatch(addRoleAction.request(payload)),
  deleteRoles: payload => dispatch(deleteTenantRolesAction.request(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRoles);
