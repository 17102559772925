import React, { useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { Loader } from 'webapp-common';

import './validationIcon.css';

export const VALIDATION_STATUS_OK = 'OK';

export const statusIconPath = {
  WARNING: 'fa fa-exclamation-triangle ms-4 me-1',
  OK: 'fa fa-check ms-4 me-1'
};

const ValidationIcon = props => {
  const { validation, validationInProgress, validationSupported, placement, onlyWarning } = props;

  useEffect(() => {
    if (!validation) {
      props.fetchValidation(props.id, props.entity);
    }
  }, []);

  if (!validationSupported) {
    return null;
  }

  if (validationInProgress || !validation || !validation.status) {
    return <Loader spinner inline />;
  }

  if (onlyWarning && validation.status === VALIDATION_STATUS_OK) {
    return null;
  }

  const iconId = props.id || uuid().replace(/-/g, '');
  const id = `validation_icon_${iconId}`;

  return (
    <>
      <i className={statusIconPath[validation.status]} id={id} data-validation-msg={validation.message} />
      <UncontrolledTooltip placement={placement || 'right'} target={id}>
        {validation.message}
      </UncontrolledTooltip>
    </>
  );
};

export default ValidationIcon;
