import { createSelector, createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import { getKeyTransformMap } from '../../../util/participantDataUtil';

const selectParticipantDetails = (state, entityId) =>
  state.participantDataDetails && state.participantDataDetails[entityId];
const selectEnrollmentInfo = (state, entityId) =>
  state.participantDataDetails &&
  state.participantDataDetails[entityId] &&
  state.participantDataDetails[entityId].enrollmentInfo;
const selectScreenersAnswersTotal = (state, entityId) =>
  state.participantDataDetails &&
  state.participantDataDetails[entityId] &&
  state.participantDataDetails[entityId].screenersAnswersTotal;
const selectScreenersAnswersSummaries = (state, entityId) =>
  state.participantDataDetails &&
  state.participantDataDetails[entityId] &&
  state.participantDataDetails[entityId].screenersAnswersSummaries;
const selectScreenerSummary = (state, sessionId) =>
  state.participantDataDetails &&
  state.participantDataDetails.screenerSummary &&
  state.participantDataDetails.screenerSummary[sessionId];

const selectPreviewResults = (state, sessionId) =>
  state.participantDataDetails &&
  state.participantDataDetails.screenerPreviewResults &&
  state.participantDataDetails.screenerPreviewResults[sessionId];

const selectParticipantDataRequested = (participantDataDetails = {}) => participantDataDetails.participantDataRequested;

// This is the pagedList
const selectParticipantList = (participantDataDetails = {}) =>
  get(participantDataDetails, 'participantData.participantList');
const selectColumnOrder = (participantDataDetails = {}) => get(participantDataDetails, 'participantData.columnOrder');
const selectKeyTransforMap = (participantDataDetails = {}) =>
  getKeyTransformMap(get(participantDataDetails, 'participantData.enrolleeDataDef'));
const selectEnrolleeDataDef = (participantDataDetails = {}) =>
  get(participantDataDetails, 'participantData.enrolleeDataDef');
const selectMetadataCollection = (participantDataDetails = {}) => participantDataDetails.metadataCollection;
const selectJobId = (participantDataDetails = {}) => get(participantDataDetails, 'participantData.jobId');
const selectJobStatus = (participantDataDetails = {}) => get(participantDataDetails, 'participantData.jobStatus');
const selectJobType = (participantDataDetails = {}) => get(participantDataDetails, 'participantData.jobType');

const participantDataSelector = () => {
  return createSelector(
    selectParticipantDetails,
    createStructuredSelector({
      participantList: selectParticipantList,
      columnOrder: selectColumnOrder,
      keyTransformMap: selectKeyTransforMap,
      participantDataRequested: selectParticipantDataRequested,
      enrolleeDataDef: selectEnrolleeDataDef,
      metadataCollection: selectMetadataCollection,
      jobId: selectJobId,
      jobStatus: selectJobStatus,
      jobType: selectJobType
    })
  );
};

const enrollmentInfoSelector = () => {
  return createSelector(selectEnrollmentInfo, enrollmentInfo => enrollmentInfo);
};

const screenerSummarySelector = () => {
  return createSelector(selectScreenerSummary, screenerSummary => ({
    screenerSummary
  }));
};

const screenerPreviewResultsSelector = () => {
  return createSelector(selectPreviewResults, screenerPreviewResults => ({
    screenerPreviewResults
  }));
};

const screenersAnswersTotalSelector = () =>
  createSelector(selectScreenersAnswersTotal, screenersAnswersTotal => ({
    screenersAnswersTotal
  }));

const screenersAnswersSummariesSelector = () =>
  createSelector(selectScreenersAnswersSummaries, screenersAnswersSummaries => ({
    screenersAnswersSummaries
  }));

export {
  participantDataSelector,
  enrollmentInfoSelector,
  screenerSummarySelector,
  screenerPreviewResultsSelector,
  screenersAnswersTotalSelector,
  screenersAnswersSummariesSelector
};
