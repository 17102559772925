import { connect } from 'react-redux';
import { ArchiveModal } from './ArchiveModal';
import { sessionListSelector } from '../../../store/redux/selectors/sessionListSelector';
import { fetchSessionListAction } from '../../../store/redux/actions/sessionActions';

const mapDispatchForArchiveModal = dispatch => ({
  fetchSessions: params => dispatch(fetchSessionListAction.request(params))
});

export const ConnectedArchiveModal = connect(sessionListSelector, mapDispatchForArchiveModal)(ArchiveModal);
