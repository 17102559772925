import React, { useCallback, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import { InvokeTable } from 'webapp-common';
import localtime from '../../util/localtime';
import { mediaLibraryUtil } from './mediaLibraryUtil';

export const MediaTable = props => {
  const {
    mediaListLibrary,
    enablePagination = true,
    pagination,
    sortable,
    fetchMedia,
    onPaginationChange,
    onRowSelect
  } = props;

  const { pagedList = {} } = mediaListLibrary;
  const { content: mediaList = [] } = pagedList;

  useEffect(() => {
    fetchMedia({});
  }, [fetchMedia]);

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchMedia({ sortBy, sortOrder });
    },
    [fetchMedia]
  );

  const getMediaThumbnail = useCallback(info => {
    const media = info.row.original;
    return <div>{mediaLibraryUtil.getImage(media.accessUrl, media)}</div>;
  }, []);

  const getMediaTitle = useCallback(info => {
    return mediaLibraryUtil.formatTitle(info.getValue());
  }, []);

  const getFormattedModifyDate = useCallback(info => {
    return localtime.getFormattedDate(info.getValue());
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'accessUrl',
        header: intl.get('app.media'),
        headerStyle: { width: '6rem' },
        cell: getMediaThumbnail,
        cellClassName: 'centered',
        enableSorting: false
      },
      {
        accessorKey: 'title',
        header: intl.get('app.title'),
        cell: getMediaTitle,
        cellClassName: 'vertical-middle text-truncate',
        enableSorting: sortable
      },
      {
        accessorKey: 'usageCount',
        header: intl.get('app.usage'),
        headerStyle: { width: '6rem' },
        cellClassName: 'vertical-middle',
        enableSorting: false
      },
      {
        accessorKey: 'modifiedDate',
        header: intl.get('app.lastModified'),
        headerStyle: { width: '13rem' },
        cell: getFormattedModifyDate,
        cellClassName: 'vertical-middle',
        enableSorting: sortable
      }
    ];
  }, [getFormattedModifyDate, getMediaThumbnail, getMediaTitle, sortable]);

  return (
    <div style={{ width: 'min-content', minWidth: '42.5rem' }}>
      <InvokeTable
        className="invoke-table"
        columns={columns}
        data={mediaList}
        initialState={{
          sorting: [
            {
              id: 'modifiedDate',
              desc: true
            }
          ]
        }}
        enablePagination={enablePagination}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        onSortingChange={sort}
        onRowSelect={onRowSelect}
      />
    </div>
  );
};
