import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual } from 'lodash';
import { filterUtil } from '../../../util/filterUtil';
import { InvokeModal } from 'webapp-common';
import { FilterSelect } from '../../filterSelect/FilterSelect';

import './SelectFiltersModal.css';

export const SelectFiltersModal = props => {
  const { max } = props;

  const [selectedFilters, setSelectedFilters] = useState(props.selectedFilters);

  function updateFilters(filters) {
    if (filters.length <= max) {
      setSelectedFilters(cloneDeep(filters));
    }
  }

  function save() {
    props.onSave(selectedFilters);
  }

  const saveEnabled = !isEqual(
    filterUtil.getFilterJsonStrings(props.selectedFilters),
    filterUtil.getFilterJsonStrings(selectedFilters)
  );

  return (
    <InvokeModal
      showModal
      className="select-filters-modal"
      modalTitle={intl.get('app.selectFilters')}
      toggle={props.toggle}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      enableSave={saveEnabled}
      save={save}
    >
      {max && <div style={{ marginBottom: '1rem' }}>{intl.get('app.selectUpToMaxFilters', { max })}:</div>}
      <FilterSelect
        style={{ width: '75%' }}
        filters={props.filters}
        quotaFilters={props.quotaFilters}
        selectedFilters={selectedFilters}
        readOnly={props.readOnly}
        onFilterSelect={updateFilters}
      />
    </InvokeModal>
  );
};
