import React, { memo, useEffect, useReducer } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import intl from 'react-intl-universal';
import moment from 'moment';
import { cloneDeep, get, isEqual } from 'lodash';
import { rdAggregateAnswersSubscribeActions } from '../../../../../store/redux/actions/researchDashboardActions';
import { getRDConfig, getRDAggregateData } from '../../../../../store/redux/selectors/researchDashboardSelector';
import { filtersAndParticipantsSubscribeActions } from '../../../../../store/redux/actions/filtersAndParticipantsActions';
import { getFiltersAndParticipants } from '../../../../../store/redux/selectors/filtersAndParticipantsSelector';
import { jsUtil } from '../../../../../util/jsUtil';
import { ENGLISH } from '../../../../../util/joinerUtil';
import { rdConfigUtil } from '../../../../../util/rdConfigUtil';
import { netsUtil } from '../../../../../util/netsUtil';
import { mediaUtil } from '../../../../../util/mediaUtil';
import { saveRDConfig } from '../../../../../util/researchDashboardUtil';
import { QuestionDisplayOptionSelector } from '../../../../../components/questionDisplayOptionSelector/QuestionDisplayOptionSelector';
import { InvokeModal } from 'webapp-common';
import { ChoiceAnswersTable } from './ChoiceAnswersTable';
import { ChoiceAnswersForOther } from './ChoiceAnswersForOther';
import { RDParticipantDetailsModal } from '../../../rdParticipantDetails/RDParticipantDetailsModal';
import { surveyUtil } from '../../../../../util/surveyUtil';
import { Icons } from '../../../../../components/icons/Icons';

import './ChoiceQuestionDetails.css';

const skipUpdate = (prevProps, nextProps) => {
  if (!isEqual(prevProps.questionJoiner, nextProps.questionJoiner)) {
    return false;
  }
  if (prevProps.viewLanguage !== nextProps.viewLanguage) {
    return false;
  }
};

const OTHER = 'OTHER';
const NA_SYMBOL = '-';

const getChoiceScalar = (choices, id) => {
  const choice = choices.find(c => c.id === id);
  if (!choice) {
    return NA_SYMBOL;
  }
  const { common, scalar } = choice.value;
  return common ? NA_SYMBOL : scalar;
};

const reducer = (state, payload) => ({ ...state, ...payload });

const getExportFileName = (questionJoiner, extension) => {
  const joinerName = surveyUtil.stripBadCharacters(questionJoiner.researchPrompt);
  const questionTypeString = questionJoiner.def.responseSet.allowMultipleAnswers ? 'Multiple-Choice' : 'Single-Choice';
  const timeStamp = moment().format('MM-DD-YYYY');
  return `${joinerName}-${questionTypeString}-${timeStamp}.${extension}`;
};

const getComparisonFilterRows = (rdConfig, state) => {
  const selectedFilter = get(rdConfig.configs, `filterListConfig.selected`) || [];
  const rows = cloneDeep(state.rows);
  rows.forEach(row => {
    for (const f1 in row.filtered) {
      if (f1 !== selectedFilter) {
        row.filtered[f1] = null; // no match
      }
    }
  });
  return rows;
};

export const ChoiceQuestionDetails = memo(props => {
  const { sessionId, questionJoiner, viewLanguage } = props;
  const questionDefId = questionJoiner.def.id;
  const { choices } = questionJoiner.def.responseSet;

  const [state, setState] = useReducer(reducer, {
    selectedParticipant: null,
    netsModalOpen: false,
    netDetails: null,
    selectedRowIndex: -1,
    rows: []
  });

  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  const aggregateData = useSelector(state => getRDAggregateData(state, sessionId, questionDefId), shallowEqual);
  const { aggregate, netMap, answers } = aggregateData;
  const filtersAndParticipants = useSelector(state => getFiltersAndParticipants(state, sessionId), shallowEqual);
  const { filteredParticipants } = filtersAndParticipants;
  const quotaFilters = filtersAndParticipants.filters
    ? filtersAndParticipants.filters.filter(f => f.type === 'QUOTA_FILTER')
    : [];

  const netDetails = netsUtil.getNetDetails(questionJoiner, rdConfig.configs.questionsConfig) || {};
  const displayOption = rdConfigUtil.getQuestionConfigDisplayOption(rdConfig, questionDefId);
  const selectedFilters = get(rdConfig.configs, `questionsConfig.questionsConfigMap[${questionDefId}].filters`) || [];
  const {
    matrixChoiceSortingConfig: { individualQuestionConfigs, defaultSortingConfig }
  } = rdConfig.configs;
  const sortingConfig = individualQuestionConfigs[questionDefId] || defaultSortingConfig;

  if (!state.netDetails) {
    setState({
      netDetails: cloneDeep(netDetails)
    });
  }

  const dispatch = useDispatch();

  useEffect(() => {
    rdAggregateAnswersSubscribe('subscribe');
    filtersAndParticipantsSubscribe('subscribe');
    return () => {
      rdAggregateAnswersSubscribe('unsubscribe');
      filtersAndParticipantsSubscribe('unsubscribe');
    };
  }, []);

  // When a filter is created, re-sub to get the latest.
  useEffect(() => {
    filtersAndParticipantsSubscribe('subscribe');
  }, [rdConfig.configs.filterListConfig.filters.length]);

  function rdAggregateAnswersSubscribe(subAction) {
    dispatch(
      rdAggregateAnswersSubscribeActions.request({
        subAction,
        sessionId,
        questionDefId,
        rdConfig
      })
    );
  }

  function filtersAndParticipantsSubscribe(subAction) {
    dispatch(filtersAndParticipantsSubscribeActions.request({ subAction, sessionId }));
  }

  const getChoiceValueAttrs = (choices, id) => {
    const choice = choices.find(c => c.id === id);
    const { index, value } = choice;
    const { imageStim } = value;
    const displayValue = viewLanguage === ENGLISH ? 'origValue' : 'value';
    const caption = viewLanguage === ENGLISH ? 'origCaption' : 'caption';
    return {
      index: parseInt(index, 10),
      otherInput: value.otherInput,
      content: (imageStim && (imageStim[caption] || imageStim.media.title)) || value[displayValue],
      textForSorting: value[displayValue] || value.abbreviatedValue || (imageStim && imageStim[caption]) || NA_SYMBOL,
      entryType: value.type,
      otherType: value.entryType,
      common: value.common,
      src: imageStim ? mediaUtil.getMediaUrl(imageStim.media) : ''
    };
  };

  function getNormalizedNets(net) {
    return {
      count: net.count,
      netType: net.type,
      responseRate: net.percentage
    };
  }

  function getFilteredAggregates() {
    const filteredAggs = {};
    if (filteredParticipants) {
      for (let filterName in filteredParticipants) {
        filteredAggs[filterName] = {
          aggregate: {},
          netMap: {
            BOTTOM: { netType: 'BOTTOM', count: 0, responseRate: 0 },
            MIDDLE: { netType: 'MIDDLE', count: 0, responseRate: 0 },
            TOP: { netType: 'TOP', count: 0, responseRate: 0 }
          }
        };
        const participantIds = filteredParticipants[filterName];
        const filteredAnswers = answers.filter(ans => participantIds.includes(ans.participantId));
        aggregate.forEach(agg => {
          let count = 0;
          filteredAnswers.forEach(answer => {
            if (answer.answer.value.includes(agg.id)) {
              count++;
              if (agg.netType) {
                filteredAggs[filterName].netMap[agg.netType].count++;
                filteredAggs[filterName].netMap[agg.netType].responseRate =
                  (filteredAggs[filterName].netMap[agg.netType].count / filteredAnswers.length) * 100 || 0;
              }
            }
          });
          filteredAggs[filterName].aggregate[agg.id] = {
            count,
            responseRate: (count / filteredAnswers.length) * 100 || 0
          };
        });
      }
    }
    return filteredAggs;
  }

  function sortRows(rows) {
    const { sortBy, sortOrder } = sortingConfig;
    rows.sort((a, b) => {
      // default 'index' sort values
      let aVal = a.index;
      let bVal = b.index;
      if (sortBy === 'total') {
        aVal = a.total.responseRate;
        bVal = b.total.responseRate;
      } else if (a.filtered[sortBy] && b.filtered[sortBy]) {
        // sorting by filter
        aVal = a.filtered[sortBy].responseRate;
        bVal = b.filtered[sortBy].responseRate;
      }
      const [val1, val2] = sortOrder === 'asc' ? [aVal, bVal] : [bVal, aVal];
      return val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
    });
  }

  function buildTableData() {
    const filteredAggregates = getFilteredAggregates();
    const rows = [];
    let choiceNum = 1;
    aggregate.forEach(choice => {
      const choiceAttrs = getChoiceValueAttrs(choices, choice.id);
      const { index, otherInput, entryType, content, src } = choiceAttrs;
      const filtered = Object.keys(filteredAggregates).reduce((acc, key) => {
        acc[key] = filteredAggregates[key].aggregate[choice.id];
        return acc;
      }, {});

      rows.push({
        id: choice.id,
        index,
        otherInput,
        entryType,
        choiceNum: choiceNum++,
        displayText: content,
        src,
        total: choice,
        filtered
      });
    });

    sortRows(rows);

    // Add net summary rows
    ['TOP', 'MIDDLE', 'BOTTOM'].forEach(net => {
      const lcNet = net.toLowerCase();
      const choiceIds = netDetails[lcNet] || [];
      const netCount = choiceIds.length;
      if (netCount) {
        const filtered = Object.keys(filteredAggregates).reduce((acc, key) => {
          acc[key] = filteredAggregates[key].netMap[net];
          return acc;
        }, {});
        rows.push({
          total: getNormalizedNets(netMap[net]),
          displayText: intl.get(`app.${lcNet}`) + ' ' + netCount,
          filtered,
          netsSummaryRow: true,
          choiceIds
        });
      }
    });
    if (!isEqual(state.rows, rows)) {
      setState({ rows });
    }

    return rows;
  }

  const prepareTableData = () => {
    let index = 0;
    return aggregate.map(choice => {
      const scalar = getChoiceScalar(choices, choice.id);
      const { responseRate, count } = choice;
      const choiceAttrs = getChoiceValueAttrs(choices, choice.id);
      const { content, entryType, otherType, textForSorting, common, src, otherInput } = choiceAttrs;
      const nets = choice.netType ? `${choice.netType} ${choice.netCount}` : NA_SYMBOL;
      const displayResults = { count, responseRate: Math.round(responseRate) };
      return {
        index: otherType === OTHER ? undefined : ++index,
        displayText: content,
        common: common && index === aggregate.length - 1,
        displayResults,
        choiceId: choice.id,
        otherInput,
        count,
        src,
        scalar,
        entryType,
        otherType,
        nets,
        textForSorting,
        responseRate
      };
    });
  };

  const getOtherAnswers = input => {
    const otherData = input.filter(row => !row.netsSummaryRow && row.otherType === OTHER);
    return otherData.length > 0 ? otherData : undefined;
  };

  const getAnswerChoiceColumn = (cell, row) => {
    const width = row.netsSummaryRow ? 0 : `${row.responseRate}%`;
    if (row.entryType === 'imagelabel') {
      return (
        <>
          <img src={row.src} className="thumbnail" alt="" />
          <div style={{ height: '0.35rem', width: width }} className="status-bar" />
        </>
      );
    }
    return (
      <span title={cell}>
        {cell}
        <div style={{ height: '0.35rem', width: width }} className="status-bar" />
      </span>
    );
  };

  const getCustomNetDetails = (id, type) => {
    return state.netDetails?.[type]?.some(el => el === id);
  };

  const removeFrom = (target, elem) => {
    const i = target.indexOf(elem);
    if (i !== -1) {
      target.splice(i, 1);
    }
    return target;
  };

  const setCustomNetDetails = (type, id) => {
    const top = state.netDetails?.top || [];
    const middle = state.netDetails?.middle || [];
    const bottom = state.netDetails?.bottom || [];
    let i;
    switch (type) {
      case 'top':
        i = top.indexOf(id);
        i === -1 ? top.push(id) : removeFrom(top, id);
        removeFrom(middle, id);
        removeFrom(bottom, id);
        break;
      case 'middle':
        i = middle.indexOf(id);
        i === -1 ? middle.push(id) : removeFrom(middle, id);
        removeFrom(top, id);
        removeFrom(bottom, id);
        break;
      case 'bottom':
        i = bottom.indexOf(id);
        i === -1 ? bottom.push(id) : removeFrom(bottom, id);
        removeFrom(middle, id);
        removeFrom(top, id);
        break;
      default:
        return;
    }
    setState({
      netDetails: {
        top,
        middle,
        bottom
      }
    });
  };

  const getChoices = () => {
    let index = 0;
    return aggregate.map(choice => {
      const { id } = choice;
      const choiceAttrs = getChoiceValueAttrs(choices, id);
      const { textForSorting, src, entryType, otherType } = choiceAttrs;
      choice.entryType = entryType;
      choice.src = src;
      choice.choiceId = id;
      const oddRow = index % 2 === 0;

      if (!otherType || otherType !== OTHER) {
        return (
          <tr className={oddRow ? 'odd-row' : ''}>
            <td>{++index}</td>
            <td className="text-ellipsis me-3" title={textForSorting}>
              {getAnswerChoiceColumn(textForSorting, choice)}
            </td>
            <td className="align-center">
              <Input
                type="checkbox"
                checked={getCustomNetDetails(id, 'top')}
                onChange={() => setCustomNetDetails('top', id)}
              />
            </td>
            <td className="align-center">
              <Input
                type="checkbox"
                checked={getCustomNetDetails(id, 'middle')}
                onChange={() => setCustomNetDetails('middle', id)}
              />
            </td>
            <td className="align-center">
              <Input
                type="checkbox"
                checked={getCustomNetDetails(id, 'bottom')}
                onChange={() => setCustomNetDetails('bottom', id)}
              />
            </td>
          </tr>
        );
      }
    });
  };

  const setSelectedRow = selectedRowIndex => {
    setState({ selectedRowIndex });
  };

  const setSelectedParticipant = selectedParticipant => {
    setState({
      selectedParticipant
    });
  };

  const toggleSetNets = reset => {
    reset
      ? setState({
          netsModalOpen: !state.netsModalOpen,
          netDetails: cloneDeep(netDetails)
        })
      : setState({
          netsModalOpen: !state.netsModalOpen
        });
  };

  const setCustomNets = () => {
    const cloned = cloneDeep(rdConfig);
    const { questionsConfigMap } = cloned.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    questionsConfigMap[questionDefId].netDetails = state.netDetails;
    saveRDConfig(cloned);
    setState({
      netsModalOpen: !state.netsModalOpen
    });
  };

  const setSelectedFilters = filters => {
    const cloned = cloneDeep(rdConfig);
    const { questionsConfigMap } = cloned.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    questionsConfigMap[questionDefId].filters = filters;
    saveRDConfig(cloned);
  };

  const removeFilter = filter => {
    const cloned = cloneDeep(rdConfig);
    const { questionsConfigMap } = cloned.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    questionsConfigMap[questionDefId].filters = questionsConfigMap[questionDefId].filters.filter(
      f => (f.id && f.id !== filter.id) || f.name !== filter.name
    );
    saveRDConfig(cloned);
  };

  const sort = (sortBy, sortOrder) => {
    const cloned = cloneDeep(rdConfig);
    const {
      matrixChoiceSortingConfig: { defaultSortingConfig, individualQuestionConfigs }
    } = cloned.configs;
    if (!individualQuestionConfigs[questionDefId]) {
      individualQuestionConfigs[questionDefId] = cloneDeep(defaultSortingConfig);
    }
    individualQuestionConfigs[questionDefId].sortBy = sortBy;
    individualQuestionConfigs[questionDefId].sortOrder = sortOrder;
    saveRDConfig(cloned);
  };

  const preparedData = aggregate && prepareTableData();

  const otherAnswers = (preparedData && getOtherAnswers(preparedData)) || undefined;

  const offsetHeight = jsUtil.getRdQuestionDetailsHeaderHeight();

  const generateExcelReport = () => {
    const fileName = getExportFileName(questionJoiner, 'xlsx');
    const rows = getComparisonFilterRows(rdConfig, state);

    const params = {
      sessionId,
      fileName,
      rows: rows,
      joinerId: questionJoiner.id,
      useOrigValue: viewLanguage === ENGLISH
    };
    jsUtil.initiateFileDownload('/a/binapi/exportQuestionExcel', params, fileName);
  };

  const generatePowerPointReport = () => {
    const fileName = getExportFileName(questionJoiner, 'pptx');
    const rows = getComparisonFilterRows(rdConfig, state);

    const params = {
      sessionId,
      fileName,
      rows: rows,
      joinerId: questionJoiner.id,
      useOrigValue: viewLanguage === ENGLISH
    };
    jsUtil.initiateFileDownload('/a/binapi/exportQuestionPPT', params, fileName);
  };

  return (
    <div className="choice-question-details" style={{ overflowY: 'auto', height: `calc(100% - ${offsetHeight}px)` }}>
      <div className="controls-row">
        <QuestionDisplayOptionSelector rdConfig={rdConfig} questionDefId={questionDefId} />
        <Button onClick={toggleSetNets} disabled={!aggregate}>
          {intl.get('app.customNets')}
        </Button>
        <span className="export-buttons">
          <Icons.ExcelIcon onClick={generateExcelReport} />
          <Icons.PPTIcon onClick={generatePowerPointReport} />
        </span>
      </div>
      {aggregate && (
        <ChoiceAnswersTable
          questionJoiner={questionJoiner}
          tableData={buildTableData()}
          displayOption={displayOption}
          netDetails={netDetails}
          rdConfig={rdConfig}
          quotaFilters={quotaFilters}
          selectedFilters={selectedFilters}
          sortingConfig={sortingConfig}
          sort={sort}
          selectedRowIndex={state.selectedRowIndex}
          setSelectedRow={setSelectedRow}
          setSelectedFilters={setSelectedFilters}
          removeFilter={removeFilter}
        />
      )}
      {aggregate && otherAnswers && !state.selectedRowIndex >= 0 && (
        <div className="other-answers-table">
          <div className="fw-600">
            {intl.get('app.rd.responsesFor')}
            {otherAnswers[0].displayText}:
          </div>
          <ChoiceAnswersForOther
            rows={otherAnswers}
            sessionId={sessionId}
            answers={answers}
            viewLanguage={viewLanguage}
            setSelectedParticipant={setSelectedParticipant}
          />
        </div>
      )}
      {state.netsModalOpen && (
        <InvokeModal
          showModal={state.netsModalOpen}
          toggle={toggleSetNets}
          save={setCustomNets}
          cancelButtonText={intl.get('app.cancel')}
          primaryButtonText={intl.get('app.setCustomNets')}
          enableSave
        >
          <div className="choice-question-custom-nets">
            <table className="center">
              <thead>
                <th className="ps-2">#</th>
                <th>{intl.get('app.rd.answerChoices')}</th>
                <th className="nets">{intl.get('app.top')}</th>
                <th className="nets">{intl.get('app.middle')}</th>
                <th className="nets">{intl.get('app.bottom')}</th>
              </thead>
              <tbody>{getChoices()}</tbody>
            </table>
          </div>
        </InvokeModal>
      )}
      {!!state.selectedParticipant && (
        <RDParticipantDetailsModal
          sessionId={sessionId}
          participant={state.selectedParticipant}
          toggle={() => setState({ selectedParticipant: null })}
        />
      )}
    </div>
  );
}, skipUpdate);
