import React, { useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { STIM_TYPE } from '../../../../util/joinerUtil';
import { mediaUtil } from '../../../../util/mediaUtil';
import { Icons } from '../../../../components/icons/Icons';

import plusCircle from '../../../../images/plus-circle.png';
import plusCircleCR from '../../../../images/plus-circle-cr.png';
import minusCircle from '../../../../images/minus-circle.png';
import webcontent_src from '../../../../images/icons8-internet.png';

import './CollapsedQuestionJoiner.css';

export const CollapsedQuestionJoiner = props => {
  const {
    joinerInfo,
    addPrevQuestionNum,
    addNextQuestionNum,
    readOnly,
    parentJoinerIndex,
    children = [],
    isScreener,
    highlightedJoinerId,
    handleAddToSurveyClick,
    toggleSelectMediaPopover,
    handleDeleteJoinerClick,
    handlePreviewJoinerClick,
    handleMoveJoinerClick,
    isSelectMediaPopoverOpen
  } = props;

  const {
    joiner,
    isConceptRotation,
    isPageContainer,
    joinerIcon,
    questionNum,
    questionName,
    questionPrompt,
    previewId,
    isEmailCollector,
    isVirtualFocusGroup
  } = joinerInfo;

  const { surveyRules } = joiner;
  const [state, setState] = useState({});

  const onMouseEnter = e => {
    e.stopPropagation();
    const joinerId = joinerInfo.joiner.id;
    if (joinerId !== highlightedJoinerId) {
      props.setHighlightedJoinerId(joinerId);
    }
  };

  const onMouseLeave = () => {
    props.setHighlightedJoinerId('');
  };

  // Component for adding the first question in a concept rotation, page container, or VFG.
  const getAddChildQuestionComponent = ({ position, parentJoinerIndex, questionNum, addIcon }) => {
    return (
      <div className="collapsed" onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div />
        <div className="collapsedJoinerContainer">
          <div
            className="collapsedJoiner"
            onClick={e => props.handleAddToSurveyClick(e, position, parentJoinerIndex, questionNum)}
          >
            <img
              src={addIcon || plusCircle}
              className="clickable"
              style={{ height: 'unset', width: 'unset' }}
              title={intl.get('app.addQuestion')}
              alt=""
            />
            <div>{intl.get('app.addQuestion')}</div>
          </div>
        </div>
      </div>
    );
  };

  const getMediaThumbnail = () => {
    const { stim, stimOnly, conceptRotation, conceptRotationId, containerId } = joiner;

    if (stimOnly || conceptRotation || conceptRotationId || containerId) {
      return null;
    }

    const stimType = stim && stim.type;
    const id = `media-thumbnail-${joiner.id}`;
    const onClick = e => toggleSelectMediaPopover(e, joiner);
    switch (stimType) {
      case STIM_TYPE.image:
        return <img id={id} className="thumbnail" src={mediaUtil.getMediaUrl(stim.media)} alt="" onClick={onClick} />;
      case STIM_TYPE.video:
        return <Icons.VideoIcon id={id} className="fa-3x thumbnail" onClick={onClick} />;
      case STIM_TYPE.webcontent:
        return <img id={id} className="thumbnail" src={webcontent_src} alt="" onClick={onClick} />;
      case STIM_TYPE.thirdparty:
        return null;
      default:
        return isScreener ? null : (
          <div
            id={id}
            className={classnames({ 'add-media-icon': true, active: isSelectMediaPopoverOpen })}
            onClick={onClick}
          />
        );
    }
  };

  const handleEditJoinerClick = () => {
    if (!joinerInfo.isPageContainer) {
      props.handleEditJoinerClick(joinerInfo);
    }
  };

  const toggleShowRules = id => setState({ [id]: !state[id] });

  const isHoveredJoiner = highlightedJoinerId === joiner.id;
  const insertIconImg = ((joiner.conceptRotationId || joiner.containerId) && plusCircleCR) || plusCircle;
  const addNextPosition =
    isPageContainer || isVirtualFocusGroup ? joiner.displayIndex + children.length + 1 : joiner.displayIndex + 1;

  const containerClassNames = {
    collapsedJoinerContainer: true,
    emailCollector: isEmailCollector
  };

  const collapsedJoinerClassNames = {
    collapsedJoiner: true,
    pageContainer: isPageContainer
  };

  const rulesIndicatorId = `rules-indicator-${joiner.id}`;

  return (
    <div className="collapsed" onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div>
        {isHoveredJoiner && !readOnly && !joiner.container && !isEmailCollector && (
          <>
            <div
              title={intl.get('app.moveUp')}
              onClick={e => handleMoveJoinerClick(e, joiner, joiner.displayIndex - 1)}
            >
              &#x25B2;
            </div>
            <div
              title={intl.get('app.moveDown')}
              onClick={e => handleMoveJoinerClick(e, joiner, joiner.displayIndex + 1)}
            >
              &#x25BC;
            </div>
          </>
        )}
      </div>
      <div className={classnames(containerClassNames)}>
        {surveyRules && surveyRules.length > 0 && (
          <>
            <div className="has-rules" id={rulesIndicatorId} />
            <Popover
              placement="right"
              target={rulesIndicatorId}
              isOpen={state[rulesIndicatorId]}
              trigger="hover"
              toggle={() => toggleShowRules(rulesIndicatorId)}
            >
              <PopoverBody>
                {surveyRules.length > 1
                  ? intl.get('app.survey.rulesConfigured', { number: surveyRules.length })
                  : intl.get('app.survey.ruleConfigured')}
              </PopoverBody>
            </Popover>
          </>
        )}
        <div className={classnames(collapsedJoinerClassNames)} onClick={handleEditJoinerClick}>
          <div>
            {joiner.hidden && <i className="fa fa-ban fa-2x" />}
            {joinerIcon}
          </div>
          <div>
            {isPageContainer && (
              <div>
                {intl.get('app.page')} {joiner.questionNum}
              </div>
            )}
            {!isPageContainer && (
              <div className="text-truncate">
                <span className="questionNum">{questionNum}</span>
                <span title={questionName}>{questionName}</span>
              </div>
            )}
            {joiner.groupName && (
              <div className="text-truncate">
                <span className="groupName" title={joiner.groupName}>
                  {joiner.groupName}
                </span>
              </div>
            )}
          </div>
          <div className="text-truncate" title={questionPrompt}>
            {!isPageContainer && !isVirtualFocusGroup && (
              <div className="thumbnail-container">{getMediaThumbnail()}</div>
            )}
            {questionPrompt}
          </div>
          <div style={{ visibility: isHoveredJoiner ? '' : 'hidden' }}>
            <span
              className={`fa fa-eye clickable ${
                isConceptRotation || joiner.containerId || isVirtualFocusGroup ? 'invisible' : ''
              }`}
              title={intl.get('app.preview')}
              data-joiner-preview-id={previewId}
              onClick={handlePreviewJoinerClick}
            />
            {!readOnly && !isEmailCollector && (
              <img
                src={minusCircle}
                className="clickable"
                title={intl.get('app.delete')}
                alt=""
                onClick={e => handleDeleteJoinerClick(e, joiner)}
              />
            )}
          </div>
        </div>

        {children.length > 0 && <div className="collapsedCRJoiners">{children}</div>}

        {isConceptRotation && children.length === 0 && (
          <div className="collapsedCRJoiners">
            {getAddChildQuestionComponent({
              position: 0,
              parentJoinerIndex: joiner.displayIndex,
              questionNum: `${questionNum}1`,
              addIcon: plusCircleCR
            })}
          </div>
        )}

        {(isPageContainer || isVirtualFocusGroup) && children.length === 0 && (
          <div className="collapsedCRJoiners">
            {getAddChildQuestionComponent({
              position: joiner.displayIndex + 1,
              parentJoinerIndex: joiner.displayIndex,
              questionNum: addNextQuestionNum
            })}
          </div>
        )}

        {isHoveredJoiner && !readOnly && (
          <img
            src={insertIconImg}
            className="clickable insertJoinerIcon insertJoinerIconTop"
            title={intl.get('app.insertHere')}
            alt=""
            onClick={e => handleAddToSurveyClick(e, joiner.displayIndex, parentJoinerIndex, addPrevQuestionNum)}
          />
        )}
        {isHoveredJoiner && !readOnly && !isEmailCollector && (
          <img
            src={insertIconImg}
            className="clickable insertJoinerIcon insertJoinerIconBottom"
            title={intl.get('app.insertHere')}
            alt=""
            onClick={e => handleAddToSurveyClick(e, addNextPosition, parentJoinerIndex, addNextQuestionNum)}
          />
        )}
      </div>
    </div>
  );
};
