import React from 'react';
import { JoinerEditorTabs, JoinerEditorHeader, JoinerEditorButtons } from '../joinerEditor';
import Edit from '../editForms/edit/VirtualFocusGroupEdit';
import Options from '../editForms/options/VirtualFocusGroupOptions';
import Rules from '../editForms/rules/Rules';

const VirtualFocusGroupEditor = props => {
  return (
    <>
      <JoinerEditorTabs tabs={props.tabs} joinerInfo={props.joinerInfo} hideMedia hideTiming />
      <div className="joiner-editor">
        <JoinerEditorHeader {...props} />
        <div className="joiner-editor-form">
          <Edit {...props} />
          <Options {...props} />
          <Rules {...props} />
        </div>
        <JoinerEditorButtons
          toggle={props.toggle}
          saveEnabled={props.saveEnabled}
          saveJoiner={props.saveJoiner}
          readOnly={props.readOnly}
        />
      </div>
    </>
  );
};

export default VirtualFocusGroupEditor;
