import { handleActions } from 'redux-actions';
import { rdSubscribeActions, rdSubscribeSuccessActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdSubscribeReducer = handleActions(
  {
    [rdSubscribeActions.request](state, { payload }) {
      return {
        ...state,
        rdSubscribeInProgress: payload.subAction === 'subscribe'
      };
    },
    [rdSubscribeSuccessActions.succeeded](state) {
      return {
        ...state,
        rdSubscribeInProgress: false
      };
    }
  },
  initialState
);
