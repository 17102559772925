import { get, post, put, del } from '../util/request';

const CLIENTS_API = '/a/api/clients';
const ARCHIVE_API = '/a/api/archiveClient';
const TENANTS_API = '/a/api/tenants';
const LICENSES_API = '/a/api/licenses';
const TENANT_LICENSE_API = '/a/api/tenant/license';
const USERS_API = '/a/api/users';
const ROLES_SUMMARY_API = '/a/api/admin/rolesummary';
const TENANT_ROLES_API = '/a/api/tenants/roles';
const TENANT_ABILITIES_API = '/a/api/tenants/abilities';
const BLACKLIST_API = '/a/api/blacklistedParticipants';
const PANEL_CONFIGS_API = '/a/api/panelConfigs';

async function fetchClients(payload) {
  return get(CLIENTS_API)(payload).then(response => response.body && response.body.payload);
}

async function fetchClient() {
  return get('/a/api/client')().then(response => response.body && response.body.payload);
}

async function createClient(client) {
  return post(CLIENTS_API)(client).then(response => response.body && response.body.payload);
}

async function saveClient(client) {
  return put(CLIENTS_API)(client).then(response => response.body && response.body.payload);
}

async function archiveClient(client) {
  return post(`${ARCHIVE_API}/${client.clientID}`)({}).then(response => {
    const resp = response.body && response.body.payload;
    return {
      ...resp,
      clientName: client.clientName
    };
  });
}

async function uploadLogo({ client, logoFile }) {
  const data = {
    file: logoFile,
    useType: 'Image',
    clientId: client.clientID
  };
  return post('/a/binapi/uploadLogo')(data).then(response => ({ client, media: response.body }));
}

/*
 * Updates the tenant logo with the accessUrl of the uploaded media. To reset the logo to the default,
 * pass in a media object with accessUrl === ''
 */
async function updateTenantLogo({ client, media }) {
  client.newLogoUrl = media.accessUrl;
  return post(`${TENANTS_API}/updateTenantLogo`)(client).then(response => response.body && response.body.payload);
}

async function fetchAllLicenses() {
  return get(LICENSES_API)().then(response => response.body && response.body.payload);
}

async function updateTenantLicenses(payload) {
  return put(TENANT_LICENSE_API)(payload).then(response => response.body && response.body.payload);
}

async function fetchRolesSummaryApi(payload) {
  return get(ROLES_SUMMARY_API)(payload).then(res => (res.body && res.body.payload) || {});
}

async function fetchUsers(payload) {
  return get(USERS_API)(payload).then(response => response.body && response.body.payload);
}

async function fetchUser(userId) {
  return get(`${USERS_API}/${userId}`)().then(response => response.body && response.body.payload);
}

async function saveUser(payload) {
  if (payload.userID) {
    // update
    return put(USERS_API)(payload).then(response => response.body && response.body.payload);
  }
  // create
  return post(USERS_API)(payload).then(response => response.body && response.body.payload);
}

async function fetchTenantRoles() {
  const url = `${TENANT_ROLES_API}?pageSize=-1`;
  return get(url)().then(res => (res.body && res.body.payload) || {});
}

async function updateTenantRoles(payload) {
  return post(TENANT_ROLES_API)(payload).then(res => (res.body && res.body.payload) || {});
}

async function addTenantRoles(payload) {
  return put(TENANT_ROLES_API)(payload).then(res => (res.body && res.body.payload) || {});
}

async function deleteTenantRoles(payload) {
  return del(TENANT_ROLES_API)(payload).then(res => (res.body && res.body.payload) || {});
}

async function fetchTenantAbilities() {
  return get(TENANT_ABILITIES_API)().then(res => (res.body && res.body.payload) || null);
}

async function addToBlackList(payload) {
  return post(BLACKLIST_API)(payload).then(res => ({
    payload: res.body && res.body.payload,
    ...payload
  }));
}

async function deleteFromBlackList(payload) {
  return del(BLACKLIST_API)(payload).then(res => ({
    payload: res.body && res.body.payload,
    ...payload
  }));
}

async function fetchBlackList(payload) {
  return get(BLACKLIST_API)(payload).then(res => (res.body && res.body.payload) || {});
}

async function fetchPanelConfigs() {
  return get(PANEL_CONFIGS_API)().then(res => (res.body && res.body.payload) || {});
}

async function savePanelConfig(panelConfig) {
  return post(PANEL_CONFIGS_API)(panelConfig).then(res => (res.body && res.body.payload) || {});
}

async function deletePanelConfig(panelConfig) {
  return del(`${PANEL_CONFIGS_API}/${panelConfig.id}`)({}).then(res => (res.body && res.body.payload) || {});
}

export {
  fetchClients,
  fetchClient,
  createClient,
  saveClient,
  archiveClient,
  uploadLogo,
  updateTenantLogo,
  fetchAllLicenses,
  updateTenantLicenses,
  fetchUsers,
  fetchUser,
  saveUser,
  fetchRolesSummaryApi,
  fetchTenantRoles,
  updateTenantRoles,
  deleteTenantRoles,
  fetchTenantAbilities,
  addTenantRoles,
  addToBlackList,
  deleteFromBlackList,
  fetchBlackList,
  fetchPanelConfigs,
  savePanelConfig,
  deletePanelConfig
};
