import { idUtils } from './Id';

// From SessionMode.java
const sessionModes = {
  LIVE: 'Live',
  OPEN: 'Open'
};

// From SessionState.java
const sessionStates = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  PAUSED: 'PAUSED',
  TEST: 'TEST'
};

// From MCVideoSecurityLevel.java
const securityLevel = {
  LOW: 'LOW',
  STANDARD: 'STANDARD',
  ELEVATED: 'ELEVATED',
  HIGH: 'HIGH'
};

// Similar logic is in LewisHelper.generateSessionForProject()
const generateSession = ({ id, name, project, surveyId, screener, newSessionAndSurvey }) => {
  const session = {
    id: id || idUtils.getId(),
    name,
    projectId: project.id,
    surveyId,
    agentSupport: ['DESKTOP', 'TABLET'],
    requireNickname: true,
    screener,
    sessionMode: sessionModes.LIVE,
    state: sessionStates.PENDING,
    targetedURL: true,
    videoCaptureConfig: {
      enabled: false
    },
    urlOption: {
      enabled: true,
      limitHits: false,
      maxHits: 1
    },
    relevantIdConfig: {
      enabled: true,
      countryValidationEnabled: true,
      countryCodes: ['US']
    },
    newSessionAndSurvey // UI-only flag
  };
  if (screener) {
    session.agentSupport = ['DESKTOP', 'TABLET', 'PHONE'];
    session.requireNickname = false;
    session.sessionMode = sessionModes.OPEN;
    session.targetedURL = false;
  }
  return session;
};

const isClosed = session => session?.state === sessionStates.CLOSED;

const isPending = session => session?.state === sessionStates.PENDING;

const isOpen = session => session?.state === sessionStates.OPEN;

const isTest = session => session?.state === sessionStates.TEST;

const isLiveMode = session => session?.sessionMode === sessionModes.LIVE;

const isOpenMode = session => session?.sessionMode === sessionModes.OPEN;

const isPrivateSession = session => session?.targetedURL;

const hasCustomLoginTime = session => session.beforeStartTimeOffsetFlag || session.afterStartTimeOffsetFlag;

const generateUniqueSessionName = (baseName, sessionList) => {
  if (!sessionList.some(session => session.name === baseName)) {
    return baseName;
  }
  let name = '';
  for (let i = 2; i < 99999; i++) {
    name = `${baseName} ${i}`;
    if (!sessionList.some(session => session.name === name)) {
      break;
    }
  }
  return name;
};

export {
  sessionModes,
  sessionStates,
  securityLevel,
  generateSession,
  isClosed,
  isPending,
  isOpen,
  isTest,
  isLiveMode,
  isOpenMode,
  isPrivateSession,
  hasCustomLoginTime,
  generateUniqueSessionName
};
