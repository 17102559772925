import React, { memo, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { mediaUtil } from '../../../../util/mediaUtil';
import { InvokeTable } from 'webapp-common';
import { isBipolar } from '../../../../util/joinerUtil';

const skipUpdate = (prevProps, nextProps) => {
  if (!isEqual(prevProps.summary, nextProps.summary)) {
    return false;
  }
  return true;
};

export const MatrixResponses = memo(props => {
  const { joiner, summary = {} } = props;
  const { responseSet } = joiner.def;
  const bipolar = isBipolar(responseSet);
  const columnData = responseSet.entries.columnData.columns;
  const { mapIdToSummary, totalResponses } = summary;

  const getMatrixAnswer = useCallback(
    (rowId, columnId) => {
      if (!mapIdToSummary) {
        return 0;
      }
      return Math.round((mapIdToSummary[rowId]['mapAnswerValueToCount'][columnId] / totalResponses) * 100);
    },
    [mapIdToSummary, totalResponses]
  );

  const getColumns = useCallback(
    rowId => {
      return columnData.reduce((col, { id, value }, index) => {
        const percentage = getMatrixAnswer(rowId, id);
        col[id] = value.value;
        col[`${value.value}-${index}`] = typeof percentage === 'number' ? percentage + '%' : 'N/A';
        return col;
      }, {});
    },
    [columnData, getMatrixAnswer]
  );

  const tableData = useMemo(() => {
    const rows = bipolar ? responseSet.entries.bipolarRows : responseSet.entries.rows;
    return rows.map(row => {
      const { media } = row.value.imageStim ?? {};
      return {
        id: row.id,
        value: row.value.type === 'imagelabel' ? row.value.imageStim.caption : row.value.value,
        columns: getColumns(row.id),
        src: mediaUtil.getMediaUrl(media),
        value2: row.value.value2
      };
    });
  }, [bipolar, getColumns, responseSet]);

  const formatQuestionCell = useCallback(info => {
    const obj = info.row.original;
    return (
      <span>
        {obj.value}
        {obj.src && <img className="image-option ms-3" src={obj.src} alt={obj.value} key={obj.id} />}
      </span>
    );
  }, []);

  const columns = useMemo(() => {
    let columns = [
      {
        accessorKey: 'value',
        header: '',
        headerClassName: 'header right-border',
        cell: formatQuestionCell,
        cellClassName: 'text-truncate',
        enableSorting: false
      }
    ];
    const matrixColumns = columnData.map((column, index) => {
      return {
        accessorKey: `columns.${column.value.value}-${index}`,
        header: `${column.value.value}`,
        headerClassName: 'header right-border',
        cellClassName: 'text-truncate align-right',
        enableSorting: false
      };
    });
    columns = columns.concat(matrixColumns);
    if (bipolar) {
      const columnRight = [
        {
          accessorKey: 'value2',
          header: '',
          headerClassName: 'header right-border',
          cell: formatQuestionCell,
          cellClassName: 'text-truncate',
          enableSorting: false
        }
      ];
      columns = columns.concat(columnRight);
    }
    return columns;
  }, [bipolar, columnData, formatQuestionCell]);

  return <>{tableData && <InvokeTable className="response-table invoke-table" columns={columns} data={tableData} />}</>;
}, skipUpdate);
