import React, { useEffect, useReducer, useRef } from 'react';
import { useQuery } from 'react-query';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { cloneDeep, get, isEqual } from 'lodash';
import { Switch } from 'antd';
import { Button, Input } from 'reactstrap';
import { CopyToClipboard, FlexRow, InvokeModal } from 'webapp-common';
import { usePrompt } from '../../../../customHooks/usePrompt';
import {
  sessionModes,
  sessionStates,
  isClosed,
  isLiveMode,
  hasCustomLoginTime,
  securityLevel
} from '../../../../util/sessionUtil';
import { surveyUtil } from '../../../../util/surveyUtil';
import { toast } from '../../../../util/toast';
import DateTimePicker from '../../../../components/core/dateTimePicker/DateTimePicker';
import { ReadOnlyBanner } from '../../../../components/readOnlyBanner/ReadOnlyBanner';
import { SurveySegments } from './segments/SurveySegments';
import { SurveyVariables, DEFAULT_VARIABLE } from './variables/SurveyVariables';
import { SurveyLanguage } from './language/SurveyLanguage';
import { ExcludedQuestions } from './excludedQuestions/ExcludedQuestions';
import VideoResponses from './videoResponses';
import { CustomLoginTime } from './login/CustomLoginTime';
import { AllowedDevices } from './devices/AllowedDevices';
import { ImageWatermark } from './watermark/ImageWatermark';
import { WatermarkSettings } from './watermark/WatermarkSettings';
import { ShortUrls } from './urlSetting/ShortUrls';
import { RelevantId } from './relevantId/RelevantId';
import { SurveyQualification } from './qualification/SurveyQualification';
import { DeleteSurveyModal } from './DeleteSurveyModal';
import { ENGLISH } from '../../../../util/joinerUtil';
import { sessionCleanUp, fetchSessions } from '../../../../api/sessionsApi';
import { SessionStatusPicker } from './components/SessionStatusPicker';

const firstParticipantCollectionId = 'config.participantCollectionIds[0]';

const reducer = (state, payload) => ({ ...state, ...payload });

function variablesArrayToObject(array) {
  const variables = {};
  array.forEach(v => {
    if (v.token && v.value) {
      variables[v.token] = v.value;
    }
  });
  return variables;
}

export const SurveySettings = props => {
  const { translationInProgress, isTemplate, readOnly, ingestStatus } = props;
  const videoIngestStatus = ingestStatus?.[props.survey.id] ?? 'NA';

  const origVariables = useRef();

  const [state, setState] = useReducer(reducer, {
    session: {},
    survey: {
      surveyOptions: {}
    },
    variablesArray: [],
    showAlternateLanguageUI: false,
    showDeleteSurveyModal: false,
    showCustomLoginTimeUI: false,
    showSegmentsUI: false,
    autoTerminate: false,
    lastDirectToSurveySessionId: null
  });

  // TODO: Use useSessionsQuery() instead
  const { data: nonScreenerSessions, refetch: refetchNonScreenerSessions } = useQuery(
    'fetch-nonscreener-sessions',
    () => {
      if (props.session && props.session.projectId) {
        return fetchSessions({
          projectId: props.session.projectId,
          screener: false,
          pageSize: -1
        });
      }
    },
    {
      refetchOnWindowFocus: false
    }
  );

  // TODO: Use useSessionsQuery() instead
  const { data: screenerSessions, refetch: refetchScreenerSessions } = useQuery(
    'fetch-screener-sessions',
    () => {
      if (props.session && props.session.projectId) {
        return fetchSessions({
          projectId: props.session.projectId,
          screener: true,
          pageSize: -1
        });
      }
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const { data: fetchIngestStatusData, refetch: refetchIngestStatus } = useQuery(
    'fetch-watermark-ingest-status',
    () => {
      return props.getSurveyVideosIngestStatus(props.survey.id);
    },
    {
      refetchOnWindowFocus: ingestStatus === 'IN_PROGRESS' || 'ERROR'
    }
  );

  useEffect(() => {
    if (props.session && props.session.id && !isEqual(props.session, state.session)) {
      setState({
        session: props.session
      });
      refetchScreenerSessions();
      refetchNonScreenerSessions();
    }
  }, [props.session]);

  useEffect(() => {
    if (props.survey.id && !isEqual(props.survey, state.survey)) {
      const { variables } = props.survey;
      const variablesArray = [];
      for (let token in variables) {
        variablesArray.push({ token, value: variables[token] });
      }
      origVariables.current = variablesArray;
      setState({
        survey: props.survey,
        variablesArray
      });
      refetchIngestStatus();
    } else if (!props.survey.id) {
      origVariables.current = [];
      setState({
        survey: {
          surveyOptions: {}
        },
        variablesArray: []
      });
    }
  }, [props.survey]);

  usePrompt(intl.get('app.unsavedChangesWarning'), isSaveEnabled());

  const sessionExists = !!Object.keys(state.session).length;
  const sessionClosed = isClosed(state.session);

  function setSessionField(field, value) {
    setState({ session: { ...state.session, [field]: value } });
  }

  function setSessionFields(field, value, field2, value2) {
    setState({ session: { ...state.session, [field]: value, [field2]: value2 } });
  }

  function setDirectToSurvey(bool) {
    let directToSurveySessionId = getDirectToSurveySessionId();
    if (!bool || sessionAlreadyHasDirectToSurveyParticipantList(directToSurveySessionId)) {
      setState({ lastDirectToSurveySessionId: directToSurveySessionId });
      directToSurveySessionId = null;
    }
    setSessionFields('directToSurvey', bool, 'directToSurveySessionId', directToSurveySessionId);
  }

  function setDirectToSurveySessionId(e) {
    if (!sessionAlreadyHasDirectToSurveyParticipantList(e.target.value)) {
      setState({ lastDirectToSurveySessionId: state.session.directToSurveySessionId });
      setSessionField('directToSurveySessionId', e.target.value);
    }
  }

  function getNonScreenerSession(sessionId) {
    return nonScreenerSessions && nonScreenerSessions.content.find(session => sessionId === session.id);
  }

  function getDirectToSurveySessionId(display) {
    if (display) {
      const collectionId = get(
        getNonScreenerSession(state.session.directToSurveySessionId),
        firstParticipantCollectionId
      );
      if (collectionId === get(state.session, firstParticipantCollectionId)) {
        return state.session.directToSurveySessionId;
      }
      if (collectionId) {
        return -1;
      }
    }
    if (
      state.session.directToSurveySessionId &&
      !sessionAlreadyHasDirectToSurveyParticipantList(state.session.directToSurveySessionId)
    ) {
      return state.session.directToSurveySessionId;
    }
    const found =
      nonScreenerSessions &&
      nonScreenerSessions.content.find(session => !sessionAlreadyHasDirectToSurveyParticipantList(session.id));
    const { id = display ? -1 : null } = found || {};
    return id;
  }

  function sessionAlreadyHasDirectToSurveyParticipantList(sessionId) {
    const sessionCollectionId = get(getNonScreenerSession(sessionId), firstParticipantCollectionId);
    return (
      screenerSessions &&
      screenerSessions.content.find(
        s =>
          s.directToSurvey && s.id !== state.session.id && sessionCollectionId === get(s, firstParticipantCollectionId)
      )
    );
  }

  function saveParticipantEnrollmentOnDirectToSurvey() {
    if (!state.session.screener || isTemplate) {
      return;
    }
    const nonScreenerSessionRemoval = getNonScreenerSession(state.lastDirectToSurveySessionId);
    const nonScreenerSessionAddition = getNonScreenerSession(state.session.directToSurveySessionId);

    const collectionId = get(state.session, firstParticipantCollectionId);
    let needsRefetch = false;
    // UN-ENROLL SCREENER LIST
    if (
      nonScreenerSessionRemoval &&
      collectionId &&
      nonScreenerSessionRemoval.config.participantCollectionIds.indexOf(collectionId) === 0
    ) {
      const cloneRemoval = cloneDeep(nonScreenerSessionRemoval);
      cloneRemoval.config.participantCollectionIds.shift(); // un-enroll screener list
      cloneRemoval.autoEnroll = false;
      props.saveSession({ payload: cloneRemoval });
      needsRefetch = true;
    }
    // ENROLL SCREENER LIST
    if (
      nonScreenerSessionAddition &&
      state.session.directToSurvey &&
      collectionId &&
      nonScreenerSessionAddition.config.participantCollectionIds.indexOf(collectionId) === -1
    ) {
      const cloneAddition = cloneDeep(nonScreenerSessionAddition);
      cloneAddition.config.participantCollectionIds.unshift(collectionId); // enroll screener list
      cloneAddition.autoEnroll = true;
      props.saveSession({ payload: cloneAddition });
      needsRefetch = true;
    }
    setState({ lastDirectToSurveySessionId: null });
    if (needsRefetch) {
      refetchScreenerSessions();
      refetchNonScreenerSessions();
    }
    return needsRefetch;
  }

  function setName(e) {
    setState({
      session: { ...state.session, name: e.target.value },
      survey: { ...state.survey, name: e.target.value }
    });
  }

  function setMCJobId(e) {
    setState({
      survey: { ...state.survey, jobId: e.target.value },
      session: { ...state.session, mcJobId: e.target.value }
    });
  }

  function handleStartDateChange(date) {
    setSessionField('startDate', date && date.getTime());
  }

  function handleEndDateChange(date) {
    setSessionField('endDate', date && date.getTime());
  }

  function setWatermarkProperty(property, value) {
    const session = cloneDeep(state.session);
    session[property] = value;
    setState({ session });
  }

  function enableVideoCaptureConfig(bool) {
    const session = cloneDeep(state.session);
    session.videoCaptureConfig.enabled = bool;
    setState({ session });
  }

  function toggleSegmentsUI(bool) {
    setState({ showSegmentsUI: bool });
    if (!bool) {
      setSegmentCategories([]);
    }
  }

  function setSegmentCategories(segmentCategories) {
    setState({ survey: { ...state.survey, segmentCategories } });
  }

  function toggleVariables(bool) {
    if (bool && !state.variablesArray.length) {
      setState({ variablesArray: [DEFAULT_VARIABLE] });
    } else if (!bool) {
      setState({ variablesArray: [] });
    }
  }

  function setLanguageOption(value) {
    const clone = cloneDeep(state.survey);
    clone.surveyOptions.languageOptions = clone.surveyOptions.languageOptions || {};
    clone.surveyOptions.languageOptions.language = value;
    setState({ survey: clone });
  }

  function toggleAlternateLanguageUI(bool) {
    if (!bool && state.survey.surveyOptions.languageOptions.language !== ENGLISH) {
      setLanguageOption(ENGLISH);
    }
    setState({ showAlternateLanguageUI: bool });
  }

  function toggleCustomLoginTimeUI(bool) {
    if (!bool && hasCustomLoginTime(state.session)) {
      setState({
        session: {
          ...state.session,
          beforeStartTimeOffsetFlag: false,
          afterStartTimeOffsetFlag: false
        }
      });
    }
    setState({ showCustomLoginTimeUI: bool });
  }

  function enableRelevantId(bool) {
    const session = cloneDeep(state.session);
    session.relevantIdConfig.enabled = bool;
    setState({ session });
  }

  function enableRelevantIdCountryValidation(bool) {
    const session = cloneDeep(state.session);
    session.relevantIdConfig.countryValidationEnabled = bool;
    setState({ session });
  }

  function setRelevantIdCountryCodes(codes) {
    const session = cloneDeep(state.session);
    session.relevantIdConfig.countryCodes = codes;
    setState({ session });
  }

  function setExcludedQuestions(joinerIds) {
    const clone = cloneDeep(state.survey);
    clone.surveyOptions.participantDataExcludedQuestions = joinerIds;
    setState({ survey: clone });
  }

  function setQualification(qualification) {
    setState({ session: { ...state.session, qualification } });
  }

  function setUrlProperty(key, value) {
    const session = cloneDeep(state.session);
    session.urlOption[key] = value;
    setState({ session });
  }

  function sessionHasChanged() {
    return state.session.id && state.session.id === props.session.id && !isEqual(props.session, state.session);
  }

  function surveyHasChanged() {
    return (
      state.survey.id &&
      state.survey.id === props.survey.id &&
      (!isEqual(props.survey, state.survey) || !isEqual(origVariables.current, state.variablesArray))
    );
  }

  function canSaveDirectToSurvey() {
    const canSave =
      !state.session.newSessionAndSurvey &&
      ((!state.session.directToSurveySessionId && !state.session.directToSurvey) ||
        (state.session.directToSurveySessionId &&
          state.session.directToSurvey &&
          !sessionAlreadyHasDirectToSurveyParticipantList(state.session.directToSurveySessionId)));
    return canSave !== null;
  }

  function save() {
    const doSaveSession = state.session.newSessionAndSurvey || sessionHasChanged();

    let needsReload = false;
    if (doSaveSession) {
      needsReload = saveParticipantEnrollmentOnDirectToSurvey();
      props.saveSession({
        payload: { ...state.session, videoSecurityLevel: state.session.videoSecurityLevel ?? securityLevel.STANDARD }
      });
    }

    if (surveyHasChanged() || state.session.newSessionAndSurvey) {
      const survey = cloneDeep(state.survey);
      survey.variables = variablesArrayToObject(state.variablesArray);
      props.saveSurvey({
        survey,
        sessionId: isTemplate ? null : props.session.id,
        projectId: isTemplate ? null : props.session.projectId,
        suppressToast: doSaveSession
      });
    }
    if (needsReload) {
      window.location.reload();
    }
  }

  function cloneSession() {
    props.cloneSession(props.session.id);
  }

  function cancelNewSurvey() {
    window.location.assign(`${props.baseUrl}/${props.routeParams.projectId}/${props.routeParams.tab}`);
  }

  function deleteSurvey() {
    props.deleteSurvey({
      surveyId: props.survey.id,
      deleteSession: true, // delete session and related data
      session: state.session,
      isTemplate,
      projectId: state.session.projectId,
      includeSessionCount: true
    });
  }

  function toggleModal(target) {
    setState({
      [target]: !state[target]
    });
  }

  function onTerminateChange(value, target) {
    setState({
      [target]: value
    });
  }

  function checkCleanUpstatus() {
    if (!state.autoTerminate) {
      return true;
    }
    return +state.autoTerminatePercent >= 0 && +state.autoTerminatePercent <= 100;
  }

  async function cleanup() {
    try {
      const response = sessionCleanUp({
        sessionId: state.session.id,
        autoTerminate: state.autoTerminate,
        autoTerminatePercent: state.autoTerminatePercent,
        autoTerminateReason: state.autoTerminateReason
      });
      if (response) {
        setState({ showCleanupModal: false });
        toast.success({ text: intl.get('app.cleanup.success') });
      }
    } catch (error) {
      toast.error({ text: error.errorMessage });
    }
  }

  const surveyUrl = state.session.surveyId && !state.session.newSessionAndSurvey && state.session.surveyUrl;

  function isSaveEnabled() {
    if (isTemplate) {
      return !readOnly && state.survey.name && state.survey.name.trim() && surveyHasChanged();
    }

    const { relevantIdConfig = {} } = state.session;
    const invalidRelevantIdConfig =
      relevantIdConfig.enabled &&
      relevantIdConfig.countryValidationEnabled &&
      relevantIdConfig.countryCodes.length === 0;

    return (
      !readOnly &&
      state.session.name &&
      state.session.name.trim() &&
      !invalidRelevantIdConfig &&
      (state.session.newSessionAndSurvey || sessionHasChanged() || surveyHasChanged())
    );
  }

  if (!sessionExists && !isTemplate) {
    return null;
  }

  const showSegmentsUI = state.showSegmentsUI || surveyUtil.hasSegmentCategories(state.survey);
  const showVariablesUI = state.variablesArray.length !== 0;
  const showAlternateLanguageUI = state.showAlternateLanguageUI || surveyUtil.hasAlternateLanguage(state.survey);
  const showVideoResponsesUI = get(state, 'session.videoCaptureConfig.enabled');
  const showCustomLoginTimeUI = state.showCustomLoginTimeUI || hasCustomLoginTime(state.session);
  const deleteSurveyMessage = isTemplate
    ? intl.get('app.delete.template.confirm', { name: state.survey.name })
    : intl.get('app.delete.survey.confirm', { survey: state.session.name });

  function getSessionOptions() {
    const { content = [] } = nonScreenerSessions || {};
    return content.map(session => (
      <option value={session.id} disabled={!!get(session, firstParticipantCollectionId)} key={session.id}>
        {session.name}
      </option>
    ));
  }

  function deleteDisabled() {
    if (props.session.screener) {
      const screenerCollectionId = props.session.config.participantCollectionIds[0];
      if (
        nonScreenerSessions &&
        nonScreenerSessions.content.some(session =>
          session.config.participantCollectionIds.some(id => id === screenerCollectionId)
        )
      ) {
        // Screener collection is attached to a session
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {translationInProgress && <ReadOnlyBanner translationInProgress={translationInProgress} />}
      <section className="survey-settings">
        {surveyUrl && (
          <div>
            <div>{intl.get('app.takeSurveyLink')}</div>
            <div className="take-survey-link">{surveyUrl}</div>
            <CopyToClipboard
              copyText={surveyUrl}
              title={intl.get('app.copyLink')}
              toastText={intl.get('app.urlCopiedToClipboard')}
            />
          </div>
        )}

        {state.session.newSessionAndSurvey && (
          <div style={{ fontWeight: '600' }}>
            * {state.session.screener ? intl.get('app.newScreenerNotSaved') : intl.get('app.newSurveyNotSaved')}
          </div>
        )}
        <div>
          <div>{intl.get('app.name')}</div>
          <div style={{ marginRight: '7rem', width: '25rem' }}>
            <Input value={state.session.name || state.survey.name} onChange={setName} disabled={readOnly} />
          </div>
          {!isTemplate && (
            <span className={state.session.startDate ? '' : 'unselected'}>
              <DateTimePicker
                dateTime={state.session.startDate}
                onChange={handleStartDateChange}
                isDateClearable={false}
                label={intl.get('app.start')}
                placeholder={intl.get('app.selectStartDate')}
                disabled={readOnly || sessionClosed}
              />
            </span>
          )}
        </div>

        {!isTemplate && (
          <div>
            <div>{intl.get('app.jobId')}</div>
            <div style={{ marginRight: '7rem', width: '25rem' }}>
              <Input
                value={state.session.mcJobId || ''}
                onChange={setMCJobId}
                disabled={readOnly || props.session.mcJobId}
              />
            </div>
            <DateTimePicker
              dateTime={state.session.endDate}
              onChange={handleEndDateChange}
              label={intl.get('app.end')}
              placeholder={intl.get('app.selectEndDate')}
              disabled={readOnly}
            />
          </div>
        )}

        {!isTemplate && (
          <div>
            <div>{intl.get('app.status')}</div>
            <div style={{ marginRight: '7rem', width: '25rem' }}>
              <SessionStatusPicker
                readOnly={readOnly}
                session={state.session}
                videoIngestStatus={videoIngestStatus}
                ingestStatus={ingestStatus}
                setSessionField={setSessionField}
                sessionClosed={sessionClosed}
              />
              <Button
                color="secondary"
                className="ms-4"
                onClick={() => toggleModal('showCleanupModal')}
                disabled={props.session.state !== sessionStates.CLOSED}
              >
                {intl.get('app.cleanup')}...
              </Button>
            </div>

            <div>
              <label style={{ marginRight: '.5rem' }}>{intl.get('app.video.ingest')}:</label>
              <span>{intl.get(`app.ingest.${videoIngestStatus}`)}</span>
            </div>
          </div>
        )}

        {state.session.screener && <div style={{ height: '2rem' }}></div>}

        {!state.session.screener && !isTemplate && (
          <div className="live-survey">
            <div>{intl.get('app.liveSurvey')}</div>
            <div>
              <Switch
                checked={state.session.sessionMode === sessionModes.LIVE}
                onChange={bool => setSessionField('sessionMode', bool ? sessionModes.LIVE : sessionModes.OPEN)}
                disabled={readOnly}
              />
            </div>
          </div>
        )}

        {state.session.screener && !isTemplate && (
          <div>
            <span>{intl.get('app.startSurveyAfterQualification')}</span>
            <Switch checked={state.session.directToSurvey} onChange={setDirectToSurvey} disabled={readOnly} />
            <Input
              className="direct-to-survey-input"
              style={{ visibility: state.session.directToSurvey ? 'visible' : 'hidden' }}
              type="select"
              value={getDirectToSurveySessionId(true)}
              disabled={readOnly}
              onChange={setDirectToSurveySessionId}
            >
              <option value="-1" />
              {getSessionOptions()}
            </Input>
          </div>
        )}

        {(state.session.screener || state.survey.surveyState === 'screener_template') && (
          <div className={classnames({ 'bottom-border': showSegmentsUI })}>
            <div>{intl.get('app.segments')}</div>
            <div>
              <Switch checked={showSegmentsUI} onChange={toggleSegmentsUI} disabled={readOnly} />
            </div>
            {showSegmentsUI && (
              <SurveySegments survey={state.survey} readOnly={readOnly} setSegmentCategories={setSegmentCategories} />
            )}
          </div>
        )}

        <div className={classnames({ 'bottom-border': showVariablesUI })}>
          <div>{intl.get('app.variables')}</div>
          <div>
            <Switch checked={showVariablesUI} onChange={toggleVariables} disabled={readOnly} />
          </div>
          {showVariablesUI && (
            <SurveyVariables variablesArray={state.variablesArray} readOnly={readOnly} setState={setState} />
          )}
        </div>

        <div className={classnames({ 'bottom-border': showAlternateLanguageUI })}>
          <div>{intl.get('app.alternateLanguage')}</div>
          <div>
            <Switch checked={showAlternateLanguageUI} onChange={toggleAlternateLanguageUI} disabled={readOnly} />
          </div>
          {showAlternateLanguageUI && (
            <div style={{ marginBottom: '.5rem' }}>
              <SurveyLanguage survey={state.survey} readOnly={readOnly} setLanguageOption={setLanguageOption} />
            </div>
          )}
        </div>

        {props.isVideoCaptureEnabled && !state.session.screener && !isTemplate && (
          <div className={classnames({ 'bottom-border': showVideoResponsesUI })} style={{ display: 'none' }}>
            <div>{intl.get('app.videoResponses')}</div>
            <div>
              <Switch checked={showVideoResponsesUI} onChange={enableVideoCaptureConfig} disabled={readOnly} />
            </div>
            {showVideoResponsesUI && (
              <VideoResponses session={state.session} readOnly={readOnly} setSessionField={setSessionField} />
            )}
          </div>
        )}

        {isLiveMode(state.session) && !state.session.screener && (
          <div className={classnames({ 'bottom-border': showCustomLoginTimeUI })}>
            <div>{intl.get('app.customLoginTime')}</div>
            <div>
              <Switch checked={showCustomLoginTimeUI} onChange={toggleCustomLoginTimeUI} disabled={readOnly} />
            </div>
            {showCustomLoginTimeUI && (
              <CustomLoginTime session={state.session} readOnly={readOnly} setSessionField={setSessionField} />
            )}
          </div>
        )}

        {state.session.screener && (
          <div className="exclude-questions">
            <div>{intl.get('app.excludeQuestionsFromParticipantData')}</div>
            <ExcludedQuestions survey={state.survey} readOnly={readOnly} setExcludedQuestions={setExcludedQuestions} />
          </div>
        )}

        {!isTemplate && (
          <div>
            <div>{intl.get('app.allowedDevices')}</div>
            <AllowedDevices
              agentSupport={state.session.agentSupport}
              origAgentSupport={props.session.agentSupport}
              readOnly={readOnly}
              setSessionField={setSessionField}
            />
          </div>
        )}

        {!state.session.screener && !isTemplate && (
          <div>
            <div>{intl.get('app.requireNicknames')}</div>
            <div>
              <Switch
                checked={state.session.requireNickname}
                onChange={bool => setSessionField('requireNickname', bool)}
                disabled={readOnly}
              />
            </div>
          </div>
        )}

        {!state.session.screener && !isTemplate && (
          <>
            <div>
              <div>{intl.get('app.images.watermark')}</div>
              <ImageWatermark
                enableImageWatermark={state.session.enableImageWatermark}
                readOnly={readOnly}
                setWatermarkProperty={setWatermarkProperty}
              />
            </div>
            <div>
              <div>{intl.get('app.videos.watermark')}</div>
              <WatermarkSettings
                type={'video'}
                enabled={state.session.enableVideoWatermark}
                securityLevel={state.session.videoSecurityLevel}
                readOnly={readOnly}
                setWatermarkProperty={setWatermarkProperty}
              />
            </div>
          </>
        )}

        {!isTemplate && (
          <div>
            <div>{intl.get('app.shortenUrls')}</div>
            <div>
              <Switch
                checked={state.session?.urlOption?.enabled}
                onChange={bool => setUrlProperty('enabled', bool)}
                disabled={readOnly}
              />
            </div>
            {state.session?.urlOption?.enabled && state.session?.targetedURL && (
              <ShortUrls
                urlOption={state.session?.urlOption || {}}
                setUrlProperty={setUrlProperty}
                readOnly={readOnly}
              />
            )}
          </div>
        )}

        {!state.session.screener && !isTemplate && (
          <div>
            <div>{intl.get('app.showWelcomeVideo')}</div>
            <div>
              <Switch
                checked={state.session.showWelcomeVideo}
                onChange={bool => setSessionField('showWelcomeVideo', bool)}
                disabled={readOnly}
              />
            </div>
          </div>
        )}

        {!isTemplate && (
          <div>
            <div>{intl.get('app.relevantId')}</div>
            <div>
              <Switch
                checked={state.session?.relevantIdConfig?.enabled}
                onChange={bool => enableRelevantId(bool)}
                disabled={readOnly}
              />
            </div>
            {state.session?.relevantIdConfig?.enabled && (
              <RelevantId
                relevantIdConfig={state.session?.relevantIdConfig || {}}
                enableRelevantIdCountryValidation={enableRelevantIdCountryValidation}
                setRelevantIdCountryCodes={setRelevantIdCountryCodes}
                readOnly={readOnly}
              />
            )}
          </div>
        )}

        {state.session.screener && !isTemplate && (
          <div>
            <div>{intl.get('app.email')}</div>
            <SurveyQualification
              qualification={state.session.qualification || {}}
              setQualification={setQualification}
              readOnly={readOnly}
            />
          </div>
        )}
        {!readOnly && (
          <div className="flex-row justify-content-between">
            <div style={{ width: 'auto' }}>
              <Button color="primary" onClick={save} disabled={!isSaveEnabled() || !canSaveDirectToSurvey()}>
                {isTemplate ? intl.get('app.saveTemplate') : intl.get('app.save')}
              </Button>
              {!isTemplate && props.session.newSessionAndSurvey && (
                <Button className="link-button" style={{ marginLeft: '2rem' }} onClick={cancelNewSurvey}>
                  {intl.get('app.cancel')}
                </Button>
              )}
            </div>
            {props.multipleSurveysEnabled && props.survey.name && !props.session.newSessionAndSurvey && (
              <div style={{ flexBasis: '25%' }}>
                <Button color="secondary" className="me-4" onClick={cloneSession}>
                  {state.session.screener ? intl.get('app.clone.screener') : intl.get('app.clone.survey')}
                </Button>
                <Button
                  className="btn-warn"
                  disabled={deleteDisabled()}
                  onClick={() => toggleModal('showDeleteSurveyModal')}
                >
                  {state.session.screener ? intl.get('app.delete.screener') : intl.get('app.delete.survey')}
                </Button>
              </div>
            )}
            {isTemplate && (
              <Button color="warn" onClick={() => toggleModal('showDeleteSurveyModal')}>
                {intl.get('app.deleteTemplate')}
              </Button>
            )}
          </div>
        )}
        {state.showDeleteSurveyModal && (
          <DeleteSurveyModal
            toggle={() => toggleModal('showDeleteSurveyModal')}
            onSave={deleteSurvey}
            title={isTemplate ? intl.get('app.deleteTemplate') : intl.get('app.delete.survey')}
            buttonText={isTemplate ? intl.get('app.confirmDelete') : intl.get('app.delete')}
            confirmation={deleteSurveyMessage}
          />
        )}
        {state.showCleanupModal && (
          <InvokeModal
            className="session-cleanup"
            showModal={state.showCleanupModal}
            toggle={() => toggleModal('showCleanupModal')}
            modalTitle={intl.get('app.cleanup.survey')}
            cancelButtonText={intl.get('app.cancel')}
            primaryButtonText={intl.get('app.run.cleanup')}
            enableSave={checkCleanUpstatus()}
            save={cleanup}
          >
            <div>{intl.get('app.cleanup.message')}</div>
            <FlexRow alignItems="baseline">
              <Input
                type="checkbox"
                value={state.autoTerminate}
                onChange={e => onTerminateChange(e.target.checked, 'autoTerminate')}
              />
              <span>{intl.get('app.cleanup.message2')}</span>
              <Input
                value={state.autoTerminatePercent}
                onChange={e => onTerminateChange(e.target.value, 'autoTerminatePercent')}
                disabled={!state.autoTerminate}
                style={{ width: '3rem' }}
              />
              <span>{intl.get('app.cleanup.message3')}</span>
            </FlexRow>
            <div className="mt-4">
              <span className="ms-5 me-3">{intl.get('app.terminateReason')}:</span>
              <Input
                style={{ width: '20vw' }}
                value={state.autoTerminateReason}
                onChange={e => onTerminateChange(e.target.value, 'autoTerminateReason')}
              />
            </div>
          </InvokeModal>
        )}
      </section>
    </>
  );
};
