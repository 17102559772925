import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { authSaga, changePasswordSaga } from './authSaga';
import { falconSubscribeSaga } from './falconSaga';
import { projectListSaga, recentProjectsSaga, allProjectsSaga, archivedProjectListSaga } from './projectListSaga';
import { deleteReportSaga, reportListSaga } from './reportListSaga';
import questionListSaga from './questionListSaga';
import {
  dialDataChannelSubscribeSaga,
  dialDataFileChannelSubscribeSaga,
  dialDataExportSaga,
  fetchDialSettingConfigSaga,
  saveDialSettingConfigSaga,
  fetchVideoWatermarkSaga,
  deleteVideoWatermarkSaga
} from './dialDataSaga';
import {
  filtersAndParticipantsChannelSubscribeSaga,
  checkFilterSaga,
  setParticipantGroupSaga
} from './filtersAndParticipantsSaga';
import projectSaga from './projectSaga';
import { archiveProjectSaga, restoreArchivedProjectSaga, deleteArchivedProjectSaga } from './archiveProjectSaga';
import { sessionUserSaga, updateUserNameSaga } from './userSaga';
import saveProjectSaga from './saveProjectSaga';
import { sessionListSaga, screenerSessionListSaga } from './sessionListSaga';
import {
  createMetadataCollectionSaga,
  saveMetadataJobSaga,
  setMetadataCollectionSaga,
  replaceMetadataJobSaga,
  deleteMetadataCollectionSaga,
  participantListsSaga,
  participantCollectionJobsSaga,
  pingParticipantCollectionJobsSaga
} from './participantListsSaga';
import { surveyTemplateListSaga, createSurveyTemplateSaga, saveSurveyAsTemplateSaga } from './surveyTemplateListSaga';
import { mediaListSaga, saveMediaSaga, deleteMediaSaga, uploadLargeMediaSaga } from './mediaSaga';
import {
  participantDataDefSaga,
  participantDataSaga,
  enrollmentInfoSaga,
  participantsAnswersTotalSaga,
  saveParticipantActiveSaga,
  pingParticipantDataSaga,
  screenerSummarySaga,
  screenerPreviewResultsSaga,
  setVFGstatusSaga,
  endVFGMeetingSaga
} from './participantSaga';
import { videoResponsePathSaga } from './videoCaptureSaga';
import {
  fetchSessionSaga,
  fetchSessionAndSurveySaga,
  saveSessionSaga,
  fetchAllSessionsSaga,
  cloneSessionSaga,
  updateDynataPanelConfigSaga
} from './sessionSaga';
import validationSaga from './validationSaga';
import {
  fetchClientsSaga,
  fetchClientSaga,
  createClientSaga,
  saveClientSaga,
  archiveClientSaga,
  uploadLogoSaga,
  updateTenantLogoSaga,
  fetchAllLicensesSaga,
  updateTenantLicensesSaga
} from './clientAdminSaga';
import { fetchUsersSaga, fetchUserSaga, saveUserSaga } from './clientAdminUsersSaga';
import {
  clientAdminRolesSummarySaga,
  clientAdminRolesSaga,
  clientAdminUpdateTenantRolesSaga,
  clientAdminDeleteTenantRolesSaga,
  fetchTenantAbilitiesSaga,
  clientAdminAddTenantRolesSaga
} from './clientAdminTenantRolesSaga';
import {
  fetchSurveySaga,
  saveQuestionJoinerSaga,
  refreshSurveySaga,
  saveSurveySaga,
  deleteSurveyQuestionSaga,
  deleteSurveySaga,
  generateSurveyContentReviewSaga,
  generateSurveyRawExportSaga,
  importSurveySaga,
  surveyImportChannelSubscribeSaga,
  uploadTranslationsSaga
} from './surveySaga';
import {
  customReportConfigListSaga,
  customReportConfigSaga,
  saveCustomReportConfigSaga,
  customReportConsolidatedDataSaga,
  generateCustomReportSaga
} from './customReportConfigSaga';
import {
  createDataTableSaga,
  fetchDataTableListSaga,
  fetchAllDataTablesSaga,
  fetchDataTableSaga,
  fetchDataTableRecordsSaga,
  deleteDataTableSaga,
  replaceDataTableSaga
} from './dataTableSaga';
import {
  createFunctionSaga,
  deleteFunctionSaga,
  fetchFunctionListSaga,
  fetchFunctionSaga,
  fetchAllFunctionsSaga
} from './functionSaga';
import { clusterAnalysisSubscribeSaga } from './clusterAnalysisSaga';
import {
  rdSubscribeSaga,
  rdVerbatimAnswersSubscribeSaga,
  rdAggregateAnswersSubscribeSaga,
  rdParticipantAnswersSubscribeSaga,
  fetchRDConfigsListSaga,
  fetchDraftRDConfigSaga,
  saveCompleteRDConfigSaga,
  saveRDConfigSaga,
  deleteRDConfigSaga,
  applyProjectRDConfigSaga,
  shareRDConfigSaga,
  applyRDConfigTypeSaga,
  toggleFavoriteParticipantSaga,
  terminateParticipantSaga,
  unTerminateParticipantSaga,
  rdConfigTypeSubscribeSaga,
  rdComparativeViewSubscribeSaga
} from './researchDashboardSaga';
import { addToBlacklistSaga, deletFromBlacklistSaga, fetchBlacklistSaga } from './blacklistSaga';
import {
  emotionDataChannelSubscribeSaga,
  saveEmotionSettingConfigSaga,
  fetchEmotionSettingConfigSaga,
  emotionReportFilesChannelSubscribeSaga,
  emotionDataExportSaga
} from './emotionDataSaga';
import { virtualFocusGroupChannelSubscribeSaga } from './virtualFocusGroupSaga';
import { getSurveyVideosIngestStatusSaga } from './watermarkSaga';

const sagaMiddleware = createSagaMiddleware();

const configureSaga = () => {
  function* rootSaga() {
    yield all([
      authSaga(),
      falconSubscribeSaga(),
      changePasswordSaga(),
      sessionUserSaga(),
      updateUserNameSaga(),
      projectListSaga(),
      recentProjectsSaga(),
      allProjectsSaga(),
      archivedProjectListSaga(),
      deleteReportSaga(),
      reportListSaga(),
      questionListSaga(),
      filtersAndParticipantsChannelSubscribeSaga(),
      checkFilterSaga(),
      setParticipantGroupSaga(),
      dialDataChannelSubscribeSaga(),
      dialDataFileChannelSubscribeSaga(),
      dialDataExportSaga(),
      fetchDialSettingConfigSaga(),
      saveDialSettingConfigSaga(),
      fetchVideoWatermarkSaga(),
      deleteVideoWatermarkSaga(),
      projectSaga(),
      archiveProjectSaga(),
      restoreArchivedProjectSaga(),
      deleteArchivedProjectSaga(),
      saveProjectSaga(),
      validationSaga(),
      surveyTemplateListSaga(),
      createSurveyTemplateSaga(),
      saveSurveyAsTemplateSaga(),
      mediaListSaga(),
      saveMediaSaga(),
      uploadLargeMediaSaga(),
      deleteMediaSaga(),
      participantListsSaga(),
      participantCollectionJobsSaga(),
      pingParticipantCollectionJobsSaga(),
      sessionListSaga(),
      screenerSessionListSaga(),
      fetchSessionSaga(),
      fetchSessionAndSurveySaga(),
      saveSessionSaga(),
      fetchAllSessionsSaga(),
      cloneSessionSaga(),
      updateDynataPanelConfigSaga(),
      createMetadataCollectionSaga(),
      saveMetadataJobSaga(),
      setMetadataCollectionSaga(),
      replaceMetadataJobSaga(),
      deleteMetadataCollectionSaga(),
      participantDataDefSaga(),
      participantDataSaga(),
      enrollmentInfoSaga(),
      participantsAnswersTotalSaga(),
      saveParticipantActiveSaga(),
      pingParticipantDataSaga(),
      screenerSummarySaga(),
      screenerPreviewResultsSaga(),
      setVFGstatusSaga(),
      endVFGMeetingSaga(),
      rdParticipantAnswersSubscribeSaga(),
      videoResponsePathSaga(),
      fetchClientsSaga(),
      fetchClientSaga(),
      createClientSaga(),
      saveClientSaga(),
      archiveClientSaga(),
      uploadLogoSaga(),
      updateTenantLogoSaga(),
      fetchAllLicensesSaga(),
      updateTenantLicensesSaga(),
      fetchUsersSaga(),
      fetchUserSaga(),
      saveUserSaga(),
      fetchSurveySaga(),
      saveSurveySaga(),
      deleteSurveyQuestionSaga(),
      deleteSurveySaga(),
      generateSurveyContentReviewSaga(),
      generateSurveyRawExportSaga(),
      importSurveySaga(),
      surveyImportChannelSubscribeSaga(),
      uploadTranslationsSaga(),
      refreshSurveySaga(),
      saveQuestionJoinerSaga(),
      clientAdminRolesSummarySaga(),
      clientAdminRolesSaga(),
      clientAdminUpdateTenantRolesSaga(),
      clientAdminDeleteTenantRolesSaga(),
      fetchTenantAbilitiesSaga(),
      clientAdminAddTenantRolesSaga(),
      customReportConfigListSaga(),
      customReportConfigSaga(),
      saveCustomReportConfigSaga(),
      customReportConsolidatedDataSaga(),
      generateCustomReportSaga(),
      createDataTableSaga(),
      fetchDataTableListSaga(),
      fetchAllDataTablesSaga(),
      fetchDataTableSaga(),
      fetchDataTableRecordsSaga(),
      deleteDataTableSaga(),
      createFunctionSaga(),
      deleteFunctionSaga(),
      fetchFunctionListSaga(),
      fetchFunctionSaga(),
      fetchAllFunctionsSaga(),
      replaceDataTableSaga(),
      rdSubscribeSaga(),
      rdVerbatimAnswersSubscribeSaga(),
      clusterAnalysisSubscribeSaga(),
      rdAggregateAnswersSubscribeSaga(),
      fetchRDConfigsListSaga(),
      fetchDraftRDConfigSaga(),
      saveCompleteRDConfigSaga(),
      saveRDConfigSaga(),
      deleteRDConfigSaga(),
      applyProjectRDConfigSaga(),
      shareRDConfigSaga(),
      applyRDConfigTypeSaga(),
      toggleFavoriteParticipantSaga(),
      rdConfigTypeSubscribeSaga(),
      terminateParticipantSaga(),
      unTerminateParticipantSaga(),
      rdConfigTypeSubscribeSaga(),
      addToBlacklistSaga(),
      deletFromBlacklistSaga(),
      fetchBlacklistSaga(),
      emotionDataChannelSubscribeSaga(),
      fetchEmotionSettingConfigSaga(),
      saveEmotionSettingConfigSaga(),
      emotionReportFilesChannelSubscribeSaga(),
      emotionDataExportSaga(),
      rdComparativeViewSubscribeSaga(),
      virtualFocusGroupChannelSubscribeSaga(),
      getSurveyVideosIngestStatusSaga()
    ]);
  }

  sagaMiddleware.run(rootSaga);
};

export { sagaMiddleware, configureSaga };
