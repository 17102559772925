import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

import './CustomLoginTime.css';

export const CustomLoginTime = props => {
  const { session, readOnly, setSessionField } = props;
  const { afterStartTimeOffset, afterStartTimeOffsetFlag, beforeStartTimeOffsetFlag, beforeStartTimeOffset } = session;

  return (
    <div className="custom-login-time">
      <div>
        <div>{intl.get('app.enable')}:</div>
        <Input
          type="radio"
          name="beforeStartTimeOffsetFlag"
          checked={!beforeStartTimeOffsetFlag}
          onChange={e => setSessionField('beforeStartTimeOffsetFlag', !e.target.checked)}
          disabled={readOnly}
        />
        <div>
          {intl.get('app.whenSessionStarts')}, {intl.get('app.or')}
        </div>
        <Input
          type="radio"
          name="beforeStartTimeOffsetFlag"
          checked={beforeStartTimeOffsetFlag}
          onChange={e => setSessionField('beforeStartTimeOffsetFlag', e.target.checked)}
          disabled={readOnly}
        />
        <Input
          value={beforeStartTimeOffset}
          onChange={e => setSessionField('beforeStartTimeOffset', e.target.value)}
          disabled={readOnly || !beforeStartTimeOffsetFlag}
        />
        <div>{intl.get('app.minutesBeforeSessionStarts')}</div>
      </div>
      <div>
        <div>{intl.get('app.disable')}:</div>
        <Input
          type="radio"
          name="afterStartTimeOffsetFlag"
          checked={!afterStartTimeOffsetFlag}
          onChange={e => setSessionField('afterStartTimeOffsetFlag', !e.target.checked)}
          disabled={readOnly}
        />
        <div>
          {intl.get('app.whenSessionEnds')}, {intl.get('app.or')}
        </div>
        <Input
          type="radio"
          name="afterStartTimeOffsetFlag"
          checked={afterStartTimeOffsetFlag}
          onChange={e => setSessionField('afterStartTimeOffsetFlag', e.target.checked)}
          disabled={readOnly}
        />
        <Input
          value={afterStartTimeOffset}
          onChange={e => setSessionField('afterStartTimeOffset', e.target.value)}
          disabled={readOnly || !afterStartTimeOffsetFlag}
        />
        <div>{intl.get('app.minutesAfterSessionStarts')}</div>
      </div>
    </div>
  );
};
