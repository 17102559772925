import React, { Component } from 'react';
import update from 'immutability-helper';
import intl from 'react-intl-universal';
import { Form, FormGroup, Label, Col, Input, Row } from 'reactstrap';
import AddRemoveIcons from '../../components/core/addRemoveIcons/AddRemoveIcons';

import './AddFunction.scss';

class AddFunction extends Component {
  static defaultProps = {
    name: '',
    inputParams: [
      {
        value: '',
        key: 'param1'
      }
    ],

    functionCode: ''
  };

  state = {
    id: this.props.id,
    name: this.props.name,
    inputParams: this.props.inputParams,
    functionCode: this.props.functionCode
  };

  updateName = e => {
    this.updateState({
      name: e.target.value
    });
  };

  addFunctionParam = index => {
    this.updateState(state => {
      const { inputParams } = state;
      const updatedInputParams = update(inputParams, {
        $splice: [
          [
            index + 1,

            0,
            {
              value: '',
              key: `param${index + 2}`
            }
          ]
        ]
      });
      if (updatedInputParams.length > index + 2) {
        updatedInputParams.forEach((param, updatedIndex) => {
          param.key = `param${updatedIndex + 1}`;
        });
      }

      return { ...state, inputParams: updatedInputParams };
    });
  };

  removeFunctionParam = paramIndex => {
    this.updateState(state => {
      const { inputParams } = state;
      const updatedInputParams = update(inputParams, {
        $splice: [[paramIndex, 1]]
      });
      updatedInputParams.forEach((param, updatedIndex) => {
        param.key = `param${updatedIndex + 1}`;
      });
      return { ...state, inputParams: updatedInputParams };
    });
  };

  updateInputParam = (index, value) => {
    this.updateState(state => {
      const { inputParams } = state;
      const updatedInputParams = [...inputParams];
      updatedInputParams[index] = { ...updatedInputParams[index], value };
      return { ...state, inputParams: updatedInputParams };
    });
  };

  updateFunctionCode = e => {
    this.updateState({
      functionCode: e.target.value
    });
  };

  updateState(update) {
    this.setState(update, () => {
      this.props.onChange?.(this.state);
    });
  }

  renderInputParam = (input, index, removeDisabled, isEditable) => {
    return (
      <Row className="function-form-parameter mt-3" key={`function-param-index-${index}`}>
        <Col md={3}>
          <Label>{input.key}</Label>
        </Col>
        <Col md={7}>
          <Input
            onChange={e => {
              this.updateInputParam(index, e.target.value);
            }}
            value={input.value}
            key={`param` + index}
            disabled={!isEditable}
          />
        </Col>
        <Col md={2}>
          <AddRemoveIcons
            addDisabled={!isEditable}
            removeDisabled={removeDisabled}
            onAdd={() => this.addFunctionParam(index)}
            onRemove={() => this.removeFunctionParam(index)}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { isEditable } = this.props;
    const { inputParams, name, functionCode } = this.state;
    const removeDisabled = inputParams.length === 1;

    return (
      <Form className="edit-function-form">
        <FormGroup row>
          <Label sm={2}>{intl.get('app.function.name')}:</Label>
          <Col sm={10}>
            <Input type="text" name="name" value={name} onChange={this.updateName} disabled={!isEditable} />
          </Col>
        </FormGroup>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label> {intl.get('app.function.inputParameters')}: </Label>
              {inputParams?.map((input, index) => this.renderInputParam(input, index, removeDisabled, isEditable))}
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="exampleText">{intl.get('app.function.functionCode')}:</Label>
          <Input type="textarea" value={functionCode} className="function-code" onChange={this.updateFunctionCode} />
        </FormGroup>
      </Form>
    );
  }
}

export default AddFunction;
