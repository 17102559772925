import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, without } from 'lodash';
import { Input } from 'reactstrap';
import { InvokeModal, InvokeTable } from 'webapp-common';
import { ENGLISH, isBipolar } from '../../../../util/joinerUtil';
import { getNonPrefixedJoinerTitle } from '../../../../util/conceptRotationUtil';
import { TranslateLabel } from '../../../surveyBuilder/editor/editForms/edit/TranslateLabel';

const MULTI = 'multi';
const MATRIX = 'matrix';
const reducer = (state, payload) => ({ ...state, ...payload });

const getJoinerVisiblePositions = (joiner, concepts) => {
  return without(
    concepts.map(
      (concept, conceptIndex) =>
        concept.joiners.find(j => j.parentId === joiner.parentId && !j.hidden) && conceptIndex + 1
    ),
    undefined
  );
};

export const QuestionAnswerPicker = props => {
  const { concepts, language, viewLanguage } = props;

  const [state, setState] = useReducer(reducer, {
    currentQuestionAnswer: {
      parentId: '',
      answer: {
        type: '', // 'multi' or 'matrix'
        choices: [], // used only for multi
        rowId: '', // used only for matrix
        columnIds: [] // used only for matrix
      }
    }
  });

  useEffect(() => {
    if (props.currentQuestionAnswer && props.currentQuestionAnswer.parentId) {
      setState({
        currentQuestionAnswer: props.currentQuestionAnswer,
        selectedParentId: props.currentQuestionAnswer.parentId,
        hasAnswer: !!props.currentQuestionAnswer.answer,
        selectedRowId: props.currentQuestionAnswer.answer && props.currentQuestionAnswer.answer.rowId
      });
    }
  }, [props.currentQuestionAnswer]);

  //
  // state-related functions
  //
  const getAnswerObject = useCallback(() => {
    return state.currentQuestionAnswer.answer;
  }, [state.currentQuestionAnswer.answer]);

  const onFieldNameChange = e => {
    setState({
      currentQuestionAnswer: {
        ...state.currentQuestionAnswer,
        title: e.target.value
      }
    });
  };

  /*
   * Joiners can be hidden in some concepts and visible in others.
   * So check all joiners with the same parentId from all concepts,
   * and if at least one is non-hidden, it's a visible joiner.
   */
  const isVisibleJoiner = useCallback(
    joiner => {
      return !!concepts.find(concept => concept.joiners.find(j => j.parentId === joiner.parentId && !j.hidden));
    },
    [concepts]
  );

  /*
   * Get the selected answerIds based on type (multi vs. matrix)
   */
  const getSelectedAnswerIds = useCallback(() => {
    const answerObj = getAnswerObject();
    return answerObj.type === MULTI ? answerObj.choices || [] : answerObj.columnIds || [];
  }, [getAnswerObject]);

  const isSelectedQuestion = useCallback(
    (parentId, rowId) => {
      if (parentId !== state.currentQuestionAnswer.parentId) {
        return false;
      }
      const answerObj = getAnswerObject();
      return answerObj.type === MULTI ? true : rowId === answerObj.rowId;
    },
    [getAnswerObject, state.currentQuestionAnswer.parentId]
  );

  const checkSelectedAnswers = () => {
    const answerIds = getSelectedAnswerIds();
    return !!answerIds.length;
  };

  const isSelectedAnswer = useCallback(
    id => {
      const answerIds = getSelectedAnswerIds();
      return answerIds.includes(id);
    },
    [getSelectedAnswerIds]
  );

  /*
   * Add an answerId to the state maintaining answer order.
   */
  const addAnswerToState = useCallback(
    (id, choices, joinerId, rowId) => {
      const answerObj = cloneDeep(getAnswerObject());
      const answerIds = getSelectedAnswerIds();
      let title = '';
      const selectedAnswerIds = [];
      choices.forEach(choice => {
        if (id === choice.id || answerIds.includes(choice.id)) {
          selectedAnswerIds.push(choice.id);
        }
      });
      if (answerObj.type === MULTI) {
        answerObj.choices = selectedAnswerIds;
      } else {
        answerObj.columnIds = selectedAnswerIds;
      }
      const joiner = concepts[0].joiners.find(j => j.id === joinerId);
      if (!rowId) {
        // multi
        title = getNonPrefixedJoinerTitle(joiner);
      } else {
        const { responseSet } = joiner.def;
        const { rows, bipolarRows } = responseSet.entries;
        const entryRows = isBipolar(responseSet) ? bipolarRows : rows;
        const row = entryRows.find(r => r.id === rowId);
        title = `${getNonPrefixedJoinerTitle(joiner)} - ${row.value.abbreviatedValue}`;
      }
      setState({
        currentQuestionAnswer: {
          ...state.currentQuestionAnswer,
          answer: answerObj,
          title: state.currentQuestionAnswer.title || title
        },
        hasAnswer: true,
        selectedJoinerId: joinerId,
        selectedRowId: answerObj.type === MULTI ? null : rowId
      });
    },
    [concepts, getAnswerObject, getSelectedAnswerIds, state.currentQuestionAnswer]
  );

  const removeAnswerFromState = useCallback(
    (id, joinerId) => {
      const answerObj = cloneDeep(getAnswerObject());
      const answerIds = getSelectedAnswerIds().filter(ansId => ansId !== id);
      if (answerObj.type === MULTI) {
        answerObj.choices = answerIds;
      } else {
        answerObj.columnIds = answerIds;
      }
      const hasAnswer =
        (answerObj.choices && answerObj.choices.length > 0) || (answerObj.columnIds && answerObj.columnIds.length > 0);
      setState({
        currentQuestionAnswer: {
          ...state.currentQuestionAnswer,
          answer: answerObj,
          title: hasAnswer ? state.title : null
        },
        hasAnswer,
        selectedJoinerId: hasAnswer ? joinerId : null,
        selectedRowId: hasAnswer ? state.selectedRowId : null
      });
    },
    [getAnswerObject, getSelectedAnswerIds, state.currentQuestionAnswer, state.selectedRowId, state.title]
  );

  const prepareAnserTextWithHiddenLabel = useCallback(
    (joiner, concepts) => {
      if (!isVisibleJoiner(joiner)) {
        return (
          <span className="hidden-question-prompt">
            <span className="hidden-label" title={intl.get('app.hidden')}>
              [intl.get('app.hidden')]
            </span>
            {viewLanguage === ENGLISH ? joiner.def.question.origPrompt : joiner.def.question.prompt}
          </span>
        );
      } else {
        const selectedRotationPositions = getJoinerVisiblePositions(joiner, concepts);
        const selectedRotationPositionsMessage =
          selectedRotationPositions.length !== concepts.length ? (
            <span className="fw-600">
              [{intl.get('app.showRotaion.positions', { positions: selectedRotationPositions.join(',') })}]
            </span>
          ) : (
            ''
          );
        return (
          <span>
            {' '}
            {selectedRotationPositionsMessage}{' '}
            {viewLanguage === ENGLISH ? joiner.def.question.origPrompt : joiner.def.question.prompt}{' '}
          </span>
        );
      }
    },
    [isVisibleJoiner, viewLanguage]
  );

  //
  // click handlers
  //
  const selectAnswerHandler = useCallback(
    (e, id, choices, joinerId, rowId) => {
      e.stopPropagation();
      if (isSelectedAnswer(id)) {
        removeAnswerFromState(id, joinerId);
      } else {
        addAnswerToState(id, choices, joinerId, rowId);
      }
    },
    [addAnswerToState, isSelectedAnswer, removeAnswerFromState]
  );

  //
  // view-related functions
  //

  const getAnswers = useCallback(
    (choices, joinerId, rowId) => {
      const activeChoices = choices.filter(choice => !choice.value.disable);
      const answers = [];
      const value = viewLanguage === ENGLISH ? 'origValue' : 'value';
      activeChoices.forEach(choice => {
        const selected = isSelectedAnswer(choice.id);
        answers.push(
          <div
            className={selected ? 'choice-row' : 'hide-choices choice-row'}
            onClick={e => selectAnswerHandler(e, choice.id, activeChoices, joinerId, rowId)}
            key={`choice-${choice.id}`}
          >
            <Input type="checkbox" checked={selected} />
            <label className={selected ? 'selected-answer' : ''}>{choice.value[value]}</label>
          </div>
        );
      });
      return answers;
    },
    [isSelectedAnswer, selectAnswerHandler, viewLanguage]
  );

  const getChoiceAnswers = useCallback(
    (responseSet, joinerId) => {
      return getAnswers(responseSet.choices, joinerId);
    },
    [getAnswers]
  );

  const getMatrixAnswers = useCallback(
    (responseSet, joinerId, rowId) => {
      return getAnswers(responseSet.entries.columnData.columns, joinerId, rowId);
    },
    [getAnswers]
  );

  const onSetSelectedQuestion = row => {
    if (state.hasAnswer && state.selectedJoinerId !== row.rowId) {
      return;
    }
    const currentQuestionAnswer = {
      ...state.currentQuestionAnswer,
      parentId: row.parentId,
      rowId: row.rowId,
      answer: {
        ...state.currentQuestionAnswer.answer,
        type: row.type,
        rowId: row.rowId
      }
    };
    setState({ currentQuestionAnswer, selectedParentId: row.parentId });
  };

  const getRowData = useCallback(
    (type, joiner, rowId, questionNum, title, answerText, answers) => {
      const { parentId } = joiner;
      return {
        type,
        answers,
        answerText,
        parentId,
        rowId,
        questionNum: questionNum || <span />,
        title,
        selected: isSelectedQuestion(parentId, rowId),
        joinerId: joiner.id
      };
    },
    [isSelectedQuestion]
  );

  const data = useMemo(() => {
    const data = [];
    let answers;
    let rowData;
    let questionNum = 1;

    concepts[0].joiners.forEach(joiner => {
      if (!joiner.def) {
        return;
      }
      const { responseSet } = joiner.def;
      if (responseSet.type === MULTI || responseSet.type === MATRIX) {
        // Add support for ranked questions here
        const joinerTitle = getNonPrefixedJoinerTitle(joiner);
        const answerText = prepareAnserTextWithHiddenLabel(joiner, concepts);
        if (responseSet.type === MULTI) {
          answers = getChoiceAnswers(responseSet, joiner.id);
          rowData = getRowData(
            responseSet.type,
            joiner,
            null,
            `${props.questionNum}.${questionNum++}`,
            joinerTitle,
            answerText,
            answers
          );
          data.push(rowData);
        } else {
          // add the main matrix/ranked question to the array
          rowData = getRowData(
            responseSet.type,
            joiner,
            null,
            `${props.questionNum}.${questionNum++}`,
            joinerTitle,
            answerText,
            null
          );
          data.push(rowData);

          // Now loop through the row questions adding each to the array
          let matrixQuestionNum = 1;
          const { rows, bipolarRows } = responseSet.entries;
          const entryRows = isBipolar(responseSet) ? bipolarRows : rows;
          const value = viewLanguage === ENGLISH ? 'origValue' : 'value';
          entryRows
            .filter(row => !row.value.disable)
            .forEach(row => {
              answers = getMatrixAnswers(responseSet, joiner.id, row.id);
              const answerText = row.value.caption || (row.value.media && row.value.media.title) || row.value[value];
              if (state.selectedParentId === joiner.parentId) {
                rowData = getRowData(
                  responseSet.type,
                  joiner,
                  row.id,
                  null,
                  `${matrixQuestionNum++}.${row.value.abbreviatedValue}`,
                  answerText,
                  answers
                );
                data.push(rowData);
              }
            });
        }
      }
    });
    return data;
  }, [
    concepts,
    getChoiceAnswers,
    getMatrixAnswers,
    getRowData,
    prepareAnserTextWithHiddenLabel,
    props.questionNum,
    state.selectedParentId,
    viewLanguage
  ]);

  const getQuestionTitle = useCallback(info => {
    const obj = info.row.original;
    const { questionNum, title } = obj;
    return (
      <span className={questionNum ? 'truncated' : 'fw-600 truncated'} title={title}>
        {title}
      </span>
    );
  }, []);

  const getQuestion = useCallback(info => {
    const obj = info.row.original;
    const className = obj.selected ? 'non-truncated' : 'truncated';
    let question = (
      <div className={className} title={obj.answerText}>
        {obj.answerText}
      </div>
    );
    if (obj.answers?.length > 0) {
      const className = obj.selected ? 'answer-selections' : 'hidden-answer-selections';
      question = (
        <span>
          {question}
          <div className={className}>{obj.answers}</div>
        </span>
      );
    }
    return question;
  }, []);

  const getQuestionType = useCallback(info => {
    const type = info.getValue();
    return type === MULTI ? 'Choice' : type;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'questionNum',
        header: '#',
        headerStyle: { width: '6%' },
        cellClassName: 'fw-600'
      },
      {
        accessorKey: 'title',
        header: intl.get('app.title'),
        headerStyle: { width: '25%' },
        cell: getQuestionTitle
      },
      {
        header: intl.get('app.surveyQuestions'),
        headerStyle: { width: '62%' },
        cell: getQuestion
      },
      {
        accessorKey: 'type',
        header: intl.get('app.type'),
        headerStyle: { width: '7%' },
        cell: getQuestionType,
        cellClassName: 'capitalize'
      }
    ];
  }, [getQuestion, getQuestionTitle, getQuestionType]);

  const rowClassNames = useCallback(
    row => {
      if (!state.hasAnswer) {
        return {};
      }
      const isSelectedParentId = row.parentId === state.selectedParentId;
      const isSelectedRowId = row.rowId === state.selectedRowId;
      if (row.type === MULTI) {
        return {
          selected: isSelectedParentId,
          'disabled-row': !isSelectedParentId
        };
      }
      return {
        selected: isSelectedRowId && isSelectedParentId,
        'disabled-row': !(isSelectedRowId && isSelectedParentId)
      };
    },
    [state.hasAnswer, state.selectedParentId, state.selectedRowId]
  );

  const save = () => {
    props.save(state.currentQuestionAnswer);
  };

  const onDelete = () => {
    props.onDelete(state.currentQuestionAnswer);
  };

  return (
    <InvokeModal
      showModal={props.showModal}
      toggle={props.toggle}
      className="comparative-view-question-answer"
      modalTitle={intl.get('app.addEdit.row')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      save={save}
      enableSave={checkSelectedAnswers()}
      deleteButtonText={state.currentQuestionAnswer.id ? intl.get('app.delete') : ''}
      delete={onDelete}
      enableDelete
    >
      <div>
        <label className="ps-3">{intl.get('app.fieldName')}:</label>
        <Input className="field-name" value={state.currentQuestionAnswer.title} onChange={onFieldNameChange} />
      </div>
      {language !== ENGLISH && (
        <div className="mt-4">
          <TranslateLabel
            viewLanguage={viewLanguage}
            setViewLanguage={props.setViewLanguage}
            language={language}
            sessionId={props.sessionId}
          />
        </div>
      )}
      <label className="mt-3 mb-4 survey-content px-3">{intl.get('app.surveyContent')}</label>
      <div>
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={data}
          enableSort={false}
          onRowSelect={onSetSelectedQuestion}
          rowClassNames={rowClassNames}
        />
      </div>
    </InvokeModal>
  );
};
