import { handleActions, combineActions } from 'redux-actions';
import { deleteMediaActions, saveMediaActions } from '../actions/mediaActions';
import {
  fetchSurveyActions,
  saveQuestionJoinerActions,
  addNewSurveyActions,
  saveSurveyActions,
  deleteQuestionJoinersActions,
  deleteSurveyActions,
  generateSurveyContentReviewActions,
  newSurveyImportAction,
  uploadTranslationsAction
} from '../actions/surveyActions';

const initialState = {};

const surveysReducer = handleActions(
  {
    [fetchSurveyActions.triggered](state) {
      return {
        ...state,
        fetchSurveyInProgress: true,
        deleteSurveySuccess: false,
        saveQuestionJoinerFailed: null
      };
    },

    [fetchSurveyActions.succeeded](state, { payload }) {
      return {
        ...state,
        [payload.id]: payload,
        fetchSurveyInProgress: false,
        refetchSurvey: false
      };
    },

    [newSurveyImportAction.succeeded](state, { payload }) {
      return {
        ...state,
        [payload.survey.id]: payload.survey,
        fetchSurveyInProgress: false,
        refetchSurvey: false,
        saveQuestionJoinerFailed: null
      };
    },

    [saveQuestionJoinerActions.triggered](state) {
      return {
        ...state,
        saveQuestionJoinerInProgress: true,
        saveQuestionJoinerSuccess: false,
        saveQuestionJoinerFailed: null
      };
    },

    [saveQuestionJoinerActions.succeeded](state, { payload }) {
      return {
        ...state,
        [payload.id]: payload,
        saveQuestionJoinerInProgress: false,
        saveQuestionJoinerSuccess: true
      };
    },

    [saveQuestionJoinerActions.failed](state) {
      return {
        ...state,
        saveQuestionJoinerInProgress: false,
        saveQuestionJoinerFailed: true
      };
    },

    [addNewSurveyActions.succeeded](state, { payload }) {
      return {
        ...state,
        [payload.id]: payload
      };
    },

    [saveSurveyActions.triggered](state) {
      return {
        ...state,
        saveSurveyInProgress: true,
        saveSurveySuccess: false,
        deleteSurveySuccess: false
      };
    },

    [saveSurveyActions.succeeded](state, { payload }) {
      return {
        ...state,
        [payload.id]: payload,
        saveSurveyInProgress: false,
        saveSurveySuccess: true
      };
    },

    [saveSurveyActions.failed](state) {
      return {
        ...state,
        saveSurveyInProgress: false,
        saveSurveySuccess: false
      };
    },

    [deleteSurveyActions.triggered](state) {
      return {
        ...state,
        deleteSurveyInProgress: true,
        deleteSurveySuccess: false,
        deleteSurveyFailed: false
      };
    },

    [deleteSurveyActions.succeeded](state, { payload }) {
      return {
        ...state,
        deleteSurveyInProgress: false,
        deleteSurveySuccess: true,
        deleteSurveyFailed: false
      };
    },

    [deleteSurveyActions.failed](state) {
      return {
        ...state,
        deleteSurveyInProgress: false,
        deleteSurveySuccess: false,
        deleteSurveyFailed: true
      };
    },

    [deleteQuestionJoinersActions.triggered](state) {
      return {
        ...state,
        deleteSurveyQuestionsInProgress: true
      };
    },

    [deleteQuestionJoinersActions.succeeded](state, { payload }) {
      return {
        ...state,
        [payload.id]: payload,
        deleteSurveyQuestionsInProgress: false
      };
    },

    [deleteQuestionJoinersActions.failed](state) {
      return {
        ...state,
        deleteSurveyQuestionsInProgress: false
      };
    },

    [generateSurveyContentReviewActions.succeeded](state, { payload }) {
      return {
        ...state,
        referenceNumber: payload,
        generateSurveyContentReviewInProgress: false,
        generateSurveyContentReviewSuccess: true
      };
    },

    [generateSurveyContentReviewActions.failed](state) {
      return {
        ...state,
        generateSurveyContentReviewInProgress: false,
        generateSurveyContentReviewSuccess: false
      };
    },

    [combineActions(saveMediaActions.succeeded, deleteMediaActions.succeeded)](state) {
      return {
        ...state,
        refetchSurvey: true
      };
    },

    [uploadTranslationsAction.triggered](state, { payload }) {
      return {
        ...state,
        ...payload,
        uploadTranslationsSuccess: false
      };
    },

    [uploadTranslationsAction.succeeded](state, { payload }) {
      return {
        ...state,
        ...payload,
        uploadTranslationsSuccess: true
      };
    }
  },
  initialState
);

export default surveysReducer;
