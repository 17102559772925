import { connect } from 'react-redux';
import { surveySelector } from '../../../store/redux/selectors/surveySelector';
import { getFiltersAndParticipants } from '../../../store/redux/selectors/filtersAndParticipantsSelector';
import { dialSettingConfigSelector } from '../../../store/redux/selectors/dialSettingConfigSelector';
import { fetchSurveyActions } from '../../../store/redux/actions/surveyActions';
import {
  dialDataChannelSubscribeActions,
  dialDataFileChannelSubscribeActions,
  exportDialDataActions,
  fetchDialSettingConfigActions,
  saveDialSettingConfigActions,
  fetchVideoWatermarkActions,
  deleteVideoWatermarkActions
} from '../../../store/redux/actions/dialDataActions';
import SessionDialData from './SessionDialData';
import { filtersAndParticipantsSubscribeActions } from '../../../store/redux/actions/filtersAndParticipantsActions';

const mapStateToProps = (state, { sessionId, surveyId }) => {
  const surveyStore = surveySelector()(state, surveyId);
  const dialSettingConfigStore = dialSettingConfigSelector(state);
  const sessionDialDataStore = getFiltersAndParticipants(state, sessionId);
  const { filters, filteredParticipants } = sessionDialDataStore;
  const { exportDialDataInProgress = false, fetchVideoWatermarkInProgress, videoWatermark } = state.dialDataStore;
  return {
    survey: surveyStore.survey,
    fetchSurveyInProgress: surveyStore.fetchSurveyInProgress,
    dialDataStore: state.dialDataStore,
    dialSettingConfig: dialSettingConfigStore.dialSettingConfig || {},
    filters,
    filteredParticipants,
    exportDialDataInProgress,
    fetchVideoWatermarkInProgress,
    videoWatermark
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSurvey: surveyId => dispatch(fetchSurveyActions.request({ surveyId })),
  filtersAndParticipantsChannelSubscribe: params => dispatch(filtersAndParticipantsSubscribeActions.request(params)),
  dialDataChannelSubscribe: params => dispatch(dialDataChannelSubscribeActions.request(params)),
  dialDataFileChannelSubscribe: params => dispatch(dialDataFileChannelSubscribeActions.request(params)),
  exportDialData: params => dispatch(exportDialDataActions.request(params)),
  fetchDialSettingConfig: params => dispatch(fetchDialSettingConfigActions.request(params)),
  saveDialSettingConfig: params => dispatch(saveDialSettingConfigActions.request(params)),
  fetchVideoWatermark: params => dispatch(fetchVideoWatermarkActions.request(params)),
  deleteVideoWaterMark: params => dispatch(deleteVideoWatermarkActions.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionDialData);
