import React, { memo, useCallback, useMemo, useReducer } from 'react';
import intl from 'react-intl-universal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { cloneDeep, find } from 'lodash';
import { InvokeTable, SearchInput, localtime, usePagination } from 'webapp-common';
import { getRDChats, getRDQuestionJoiners } from '../../../store/redux/selectors/researchDashboardSelector';
import { openChatWindowAction } from '../../../store/redux/actions/researchDashboardActions';
import { getFlattenedJoinersList } from '../../../util/conceptRotationUtil';

import './RDChatMgmt.css';
import chatUtil from '../../../components/chat/chatUtil';

const SEARCH_KEY = 'chatTitle';
const ASC = 'asc';
const DESC = 'desc';
const PAGE_SIZE = 10;

const reducer = (state, payload) => ({ ...state, ...payload });

function skipUpdate(prevProps, nextProps) {
  return prevProps.sessionId === nextProps.sessionId;
}

export const RDChatMgmt = memo(props => {
  const { sessionId } = props;

  const chats = useSelector(state => getRDChats(state, sessionId), shallowEqual);

  const questionJoiners = useSelector(state => getRDQuestionJoiners(state, sessionId), shallowEqual);

  const [state, setState] = useReducer(reducer, {
    searchText: '',
    sortBy: 'unreadMessages',
    sortOrder: DESC,
    pageNumber: 1
  });

  const dispatch = useDispatch();

  const getLastTimeStamp = messages => {
    let lastTimeStamp = 0;
    messages.forEach(message => {
      if (message.message && message.timestamp > lastTimeStamp) {
        // ignore empty messages
        lastTimeStamp = message.timestamp;
      }
    });
    return lastTimeStamp;
  };

  const getUnReadMessagesCount = chat => {
    // Count how many unread participant messages
    // A participant message has message.ownerId in chat.particpantIds
    let count = 0;
    chat.messages.forEach(message => {
      if (!message.read && chatUtil.isParticipantMessage(message, chat)) {
        count++;
      }
    });
    return count.toString();
  };

  const sanitizeChats = data => {
    data.forEach(chat => {
      chat.lastMessage = getLastTimeStamp(chat.messages).toString(); // need to keep this as timestamp for proper sorting (see lastMessage formatter)
      chat.unreadMessages = getUnReadMessagesCount(chat);
      // calculate question number plus title
      if (chat.questionJoinerId) {
        const allJoiners = getFlattenedJoinersList(questionJoiners);
        const joiner = find(allJoiners, j => j.id === chat.questionJoinerId);
        if (joiner) {
          if (joiner.conceptId) {
            chat.questionNumberAndTitle = joiner.questionNum + ' ' + joiner.researchPrompt;
          } else {
            chat.questionNumberAndTitle = joiner.questionNum + '. ' + joiner.researchPrompt;
          }
        }
      } else {
        // general chat
        chat.questionNumberAndTitle = '-';
      }
    });
  };

  const doSearch = data => {
    const { searchText } = state;
    return data.filter(
      element => searchText.length === 0 || element[SEARCH_KEY].toLowerCase().indexOf(searchText.toLowerCase()) === 0
    );
  };

  const compare = (a, b) => {
    const aUp = a.toUpperCase();
    const bUp = b.toUpperCase();
    if (aUp > bUp) {
      return 1;
    }
    if (bUp > aUp) {
      return -1;
    }
    return 0;
  };

  const compareInt = (a, b) => {
    const aInt = Number(a);
    const bInt = Number(b);
    if (aInt > bInt) {
      return 1;
    }
    if (bInt > aInt) {
      return -1;
    }
    return 0;
  };

  const compareValue = (a, b) => {
    const { sortBy } = state;
    let comp = 0;
    if (sortBy === 'unreadMessages') {
      comp = compareInt(a.unreadMessages, b.unreadMessages);
    } else {
      comp = compare(a[sortBy], b[sortBy]);
    }
    // solve double initial sort (should be by unreadMessages desc then lastMessage desc)
    // by always resolving ties (when compare is returning 0) via lastMessage field comparison
    if (comp === 0) {
      comp = compare(a.lastMessage, b.lastMessage);
    }
    return comp;
  };

  const doSort = data => {
    const { sortOrder } = state;
    data.sort(compareValue);
    if (sortOrder !== ASC) {
      data.reverse();
    }
    return data;
  };

  const doPaginate = data => {
    let startIndex = (state.pageNumber - 1) * PAGE_SIZE;
    if (startIndex > data.length - 1) {
      startIndex = 0;
    }
    return data.slice(startIndex, startIndex + PAGE_SIZE);
  };

  let chatData = cloneDeep(chats); // we need to calculate a lastMessage field and clean up some other items, so clone
  sanitizeChats(chatData);
  chatData = doSearch(chatData);
  const totalElements = chatData.length;
  doSort(chatData);
  chatData = doPaginate(chatData);

  const formatLastMessage = useCallback(info => {
    return localtime.getFormattedDateCap(info.getValue());
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'chatTitle',
        header: intl.get('app.participant')
      },
      {
        accessorKey: 'questionNumberAndTitle',
        header: intl.get('app.question')
      },
      {
        accessorKey: 'unreadMessages',
        header: intl.get('app.unread')
      },
      {
        accessorKey: 'lastMessage',
        header: intl.get('app.lastMessage'),
        cell: formatLastMessage
      }
    ];
  }, [formatLastMessage]);

  const search = searchText => {
    setState({ searchText });
  };

  const handleSearchChange = useCallback(e => {
    search(e.target.value);
  }, []);

  const paginate = ({ pageNumber }) => {
    setState({ pageNumber });
  };

  const sort = useCallback(({ sortBy, sortOrder }) => {
    setState({ sortBy, sortOrder, pageNumber: 1 });
  }, []);

  const rowClassNames = useCallback(row => {
    return {
      clickable: true,
      selected: false,
      'row-unread-messages': row.unreadMessages > 0
    };
  }, []);

  const onRowSelect = useCallback(
    row => {
      dispatch(openChatWindowAction.request(row));
    },
    [dispatch]
  );

  const pageRequest = useMemo(() => {
    return {
      pageNumber: state.pageNumber,
      pageSize: PAGE_SIZE
    };
  }, [state.pageNumber]);

  const pagination = usePagination({ pageRequest, totalElements });

  return (
    <>
      <div className="chat-list-header">{intl.get('app.chatConversations')}</div>
      <div className="mt-3">
        <SearchInput placeholder={intl.get('app.search')} onChange={handleSearchChange} />
      </div>
      <div className="mt-3">
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={chatData}
          initialState={{
            sorting: [
              {
                id: 'unreadMessages',
                desc: true
              }
            ]
          }}
          pagination={pagination}
          onSortingChange={sort}
          onPaginationChange={paginate}
          onRowSelect={onRowSelect}
          rowClassNames={rowClassNames}
        />
      </div>
    </>
  );
}, skipUpdate);
