import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { getFirstMedia } from '../../util/joinerUtil';
import { SurveyCard } from '../surveyCard/SurveyCard';

export const SurveyCardView = props => {
  const { allSessions, goToSurvey } = props;

  const surveyStore = useSelector(state => state.surveys, shallowEqual);

  const getSurveyCards = () => {
    const { content: sessions } = allSessions;
    return sessions ? (
      sessions.map(session => {
        const status = intl.get(`app.session.state.${session.state}`).toUpperCase();
        const survey = surveyStore[session.surveyId];
        const firstStim = getFirstMedia(survey);
        if (firstStim && session.firstStim !== firstStim) {
          session = { ...session, firstStim };
        }
        return (
          <SurveyCard session={session} status={status} goToSurvey={() => goToSurvey(session.id)} key={session.id} />
        );
      })
    ) : (
      <SurveyCard />
    );
  };

  return <div style={{ display: 'flex', flexWrap: 'wrap' }}>{getSurveyCards()}</div>;
};
