import { connect } from 'react-redux';
import { saveProjectActions } from '../../../store/redux/actions/saveProject';
import { archiveProjectActions } from '../../../store/redux/actions/archiveProjectActions';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';

import { ProjectDetailsWrapper } from './ProjectDetailsWrapper';

const mapStateToProps = (state, props) => {
  const {
    sessionUser,
    abilities: { hasProjectManage, hasProjectView }
  } = sessionUserSelector(state);
  return {
    project: state.projects && state.projects[props.projectId],
    sessionUser,
    hasProjectManage,
    hasProjectView
  };
};

const mapDispatchToProps = dispatch => ({
  saveProject: project => dispatch(saveProjectActions.request(project)),
  archiveProject: project => dispatch(archiveProjectActions.request(project))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsWrapper);
