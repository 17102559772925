import { connect } from 'react-redux';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { ClientSettings } from './ClientSettings';

const mapStateToProps = state => {
  const { hasOrgView, isInternalSuperUser } = sessionUserSelector(state).abilities;
  return {
    hasOrgView,
    isInternalSuperUser
  };
};

export default connect(mapStateToProps)(ClientSettings);
