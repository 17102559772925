import React from 'react';
import intl from 'react-intl-universal';
import { find } from 'lodash';
import { Input, Row } from 'reactstrap';
import { OPERATORS, SURVEY_RULE_TYPE } from '../../../../../../util/joinerUtil';
import { Loader } from 'webapp-common';

class CustomFunctionCondition extends React.Component {
  componentDidMount() {
    this.props.fetchAllFunctions();
  }

  getJoinerSelectList = () => {
    const { activeJoiner, joiners, rule } = this.props;
    const options = [];
    for (let i = 0; i < joiners.length; i++) {
      const joiner = joiners[i];
      if (rule.type === SURVEY_RULE_TYPE.includeQuestionRule && joiner.id === activeJoiner.id) {
        // Can only select previous joiners
        break;
      }
      if (!joiner.hidden && !joiner.stimOnly && !joiner.container) {
        options.push(
          <option value={joiner.id} key={joiner.id}>
            {joiner.researchPrompt}
          </option>
        );
      }
      if (joiner.id === activeJoiner.id) {
        // Can only select previous and current joiner
        break;
      }
    }
    return options;
  };

  getFunctionSelectList = () => {
    const options = [];
    this.props.functions.forEach(f => {
      if (!f.isDeleted || (this.props.condition && this.props.condition.functionId === f.id)) {
        options.push(
          <option value={f.id} key={f.id}>
            {f.name}
          </option>
        );
      }
    });
    return options;
  };

  getInputParameters = () => {
    const { condition = {}, functions = [], readOnly, ruleIndex, conditionIndex, updateHandlers } = this.props;
    const selectedFunction = find(functions, f => f.id === condition.functionId);
    if (selectedFunction) {
      const joinerSelectList = this.getJoinerSelectList();
      return selectedFunction.inputParams
        .filter(param => param.key && param.value)
        .map(param => (
          <Row key={param.key}>
            <div>{param.value}:</div>
            <Input
              type="select"
              value={condition.params[param.key]}
              disabled={readOnly}
              onChange={e =>
                updateHandlers.setRuleConditionCustomFunctionParam(ruleIndex, conditionIndex, param.key, e.target.value)
              }
            >
              <option />
              {joinerSelectList}
            </Input>
          </Row>
        ));
    }
    return null;
  };

  getOperatorSelectList = () => {
    return [
      <option value={OPERATORS.lt} key="op1">
        {intl.get('app.surveyRules.operator.lt')}
      </option>,
      <option value={OPERATORS.lte} key="op2">
        {intl.get('app.surveyRules.operator.lte')}
      </option>,
      <option value={OPERATORS.eq} key="op3">
        {intl.get('app.surveyRules.operator.eq')}
      </option>,
      <option value={OPERATORS.gte} key="op4">
        {intl.get('app.surveyRules.operator.gte')}
      </option>,
      <option value={OPERATORS.gt} key="op5">
        {intl.get('app.surveyRules.operator.gt')}
      </option>,
      <option value={OPERATORS.ne} key="op6">
        {intl.get('app.surveyRules.operator.ne')}
      </option>
    ];
  };

  render() {
    const { condition = {}, ruleIndex, conditionIndex, functionsRequested, readOnly, updateHandlers } = this.props;
    return (
      <>
        {functionsRequested && <Loader spinner fullScreen />}
        <div>
          <div>{intl.get('app.function')}</div>
          <Input
            type="select"
            className="function-select"
            value={condition.functionId}
            disabled={readOnly}
            onChange={e => updateHandlers.setRuleConditionCustomFunctionId(ruleIndex, conditionIndex, e.target.value)}
          >
            <option />
            {this.getFunctionSelectList()}
          </Input>
          <div>{intl.get('app.function.inputParameters')}:</div>
          <div className="function-param-controls">{this.getInputParameters()}</div>
        </div>
        <div>
          <div>{intl.get('app.operator')}</div>
          <Input
            type="select"
            value={condition.operator}
            disabled={readOnly}
            onChange={e => updateHandlers.setRuleConditionOperator(ruleIndex, conditionIndex, e.target.value)}
          >
            <option />
            {this.getOperatorSelectList()}
          </Input>
        </div>
        <div>
          <div>&nbsp;</div>
          <Input
            type="number"
            className="number-input"
            value={condition.values[0] || ''}
            disabled={readOnly}
            onChange={e => updateHandlers.updateRuleConditionValues(ruleIndex, conditionIndex, e.target.value, true)}
          />
        </div>
      </>
    );
  }
}

export default CustomFunctionCondition;
