import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { WordCloud } from '../containers/researchDashboard/wordCloud/WordCloud';

/**
 * Custom hook for efficiently rendering multiple wordclouds on a page.
 */
export const useWordclouds = props => {
  const { idPrefix, keywords, options, delay, render } = props;

  useEffect(() => {
    if (render) {
      renderWordcloud(0);
    }
  }, [keywords, render]);

  function renderWordcloud(index) {
    const kws = keywords[index];
    const elem = document.querySelector(`#${idPrefix}${index}`);
    if (kws && elem) {
      const root = createRoot(elem);
      root.render(<WordCloud keywords={kws} options={options} />, () =>
        setTimeout(() => renderWordcloud(index + 1), delay)
      );
    }
  }
};
