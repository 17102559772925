import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import QuestionLibrary from '../../../../../containers/questionLibrary/QuestionLibrary';

import './SelectQuestionModal.css';

class SelectQuestionModal extends React.Component {
  state = {
    selectedQuestion: {}
  };

  onQuestionSelect = question => {
    this.setState({
      selectedQuestion: question
    });
  };

  onSave = () => {
    this.props.insertJoinerFromLibrary(this.state.selectedQuestion);
  };

  render() {
    const { toggle, isScreener } = this.props;
    const surveyType = isScreener ? 'Screener' : 'Session';
    return (
      <InvokeModal
        showModal
        className="select-question-modal"
        toggle={toggle}
        modalTitle={intl.get('app.title.chooseQuestion')}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        save={this.onSave}
        enableSave={!!this.state.selectedQuestion.id}
      >
        <QuestionLibrary clickToSelect={this.onQuestionSelect} enablePreview={false} surveyType={surveyType} />
      </InvokeModal>
    );
  }
}

export default SelectQuestionModal;
