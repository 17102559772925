import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { fetchVideoResponsePathActions } from '../../store/redux/actions/videoCaptureActions';
import { InvokeModal, Loader } from 'webapp-common';

import './VideoResponseIcon.css';

/**
 * Contains all code needed for rendering the video response icon and the video response modal.
 *
 */
export const VideoResponseIcon = props => {
  const { videoCaptureStatus = {} } = props;

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const videoPathExists = videoCaptureStatus.states?.media_transcoded;
  const videoResponsePath = useSelector(state => state.videoCapture.videoResponsePaths[videoCaptureStatus.response_id]);

  function showVideoResponse() {
    if (videoPathExists) {
      setShowModal(true);
      dispatch(fetchVideoResponsePathActions.request(videoCaptureStatus.response_id));
    }
  }

  return (
    <>
      <i className="fa fa-video video-response-icon" disabled={!videoPathExists} onClick={showVideoResponse} />
      {showModal && !videoResponsePath && <Loader spinner fullScreen />}
      {showModal && videoResponsePath && (
        <InvokeModal
          showModal
          modalTitle=" "
          cancelButtonText={intl.get('app.close')}
          toggle={() => setShowModal(false)}
        >
          <video src={videoResponsePath} className="participant-video" autoPlay={true} />
        </InvokeModal>
      )}
    </>
  );
};
