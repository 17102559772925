import { forEach } from 'lodash';

const enrollmentTypes = {
  IMPORT: 'IMPORT',
  SCREENER: 'SCREENER',
  AUTO_ENROLL: 'AUTO_ENROLL'
};

const participantListOperation = {
  add: 'add',
  remove: 'remove'
};

const selectParticipantDataDefFilterFields = participantDataDefs => {
  return (
    Object.keys(participantDataDefs).length &&
    Object.keys(participantDataDefs).reduce((finalDefs, participantDataDefKey) => {
      const participantDataDef = participantDataDefs[participantDataDefKey];
      const selections =
        participantDataDef?.questionDataDefList?.reduce((selectedDef, item) => {
          if (item.type === 'SINGLE' && item.value.length === 2) {
            const answers = [item.value[0].answerText.toLowerCase(), item.value[1].answerText.toLowerCase()];
            if (
              (answers.indexOf('yes') >= 0 && answers.indexOf('no') >= 0) ||
              (answers.indexOf('true') >= 0 && answers.indexOf('false') >= 0) ||
              (answers.indexOf('enabled') >= 0 && answers.indexOf('disabled') >= 0)
            ) {
              selectedDef.push({
                varId: item.varId,
                questionTitle: item.questionTitle
              });
            }
          }
          return selectedDef;
        }, []) ?? [];

      selections.sort((a, b) => {
        const titleA = a.questionTitle.toUpperCase();
        const titleB = b.questionTitle.toUpperCase();
        return titleA > titleB ? 1 : -1;
      });
      return finalDefs.concat(selections);
    }, [])
  );
};

const getKeyTransformMap = enrolleeDataDef => {
  const keyTransformMap = {};
  if (!enrolleeDataDef) {
    return keyTransformMap;
  }
  forEach(enrolleeDataDef, defObj => {
    if (defObj.keyTransformMap) {
      Object.assign(keyTransformMap, defObj.keyTransformMap);
    }
  });
  keyTransformMap.participantURL = 'Participant URL';
  keyTransformMap.participantPreviewURL = 'Participant Preview URL';
  return keyTransformMap;
};

const updateSelectedCollectionIds = (state, collectionIdToUpdate, operation) => {
  const participantCollectionIds = [...state.selectedCollectionIds];
  if (operation === participantListOperation.add) {
    participantCollectionIds.push(collectionIdToUpdate);
  } else {
    const removeIndex = state.selectedCollectionIds.indexOf(collectionIdToUpdate);
    participantCollectionIds.splice(removeIndex, 1);
  }
  return participantCollectionIds;
};

const getValidParticipantLists = (participantLists = [], jobs = []) => {
  const validCollectionIds = jobs
    .filter(
      job =>
        (job.status === 'Complete' || job.status === 'Screener' || job.collection.autoEnrolled) && job.collection.id
    )
    .map(job => job.collection.id);
  return participantLists.filter(list => validCollectionIds.indexOf(list.id) >= 0);
};

export {
  enrollmentTypes,
  selectParticipantDataDefFilterFields,
  getKeyTransformMap,
  updateSelectedCollectionIds,
  participantListOperation,
  getValidParticipantLists
};
