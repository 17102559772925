import React, { useState } from 'react';
import { getStringWidth } from '../../../../../../util/jsUtil';

const EMOTION_COLORS = {
  positive: '#7ED321',
  neutral: '#DDDDDD',
  negative: '#D0021B'
};
const EMOTIONS = {
  positive: 'positive',
  neutral: 'neutral',
  negative: 'negative'
};
const SIZE = 120;
const CX = 60;
const CY = 60;
const R = 50;
const PIE_WIDTH = 20;

export const SentimentPie = props => {
  const {
    sentiment: { positive, negative, neutral }
  } = props;
  const [emotion, setEmotion] = useState(EMOTIONS.positive);

  const circumference = Math.round(R * Math.PI * 2);
  const positiveStart = Math.round((circumference * positive) / 100);
  const positiveEnd = circumference - positiveStart;
  const negativeStart = Math.round((circumference * negative) / 100);
  const negativeEnd = circumference - negativeStart;
  let text = '';
  if (emotion === 'negative') {
    text = `${negative}`;
  } else if (emotion === 'neutral') {
    text = `${neutral}`;
  } else {
    text = `${positive}`;
  }

  const textWidth = getStringWidth(text, 'select');

  return (
    <svg width={SIZE} height={SIZE}>
      <circle cx={CX} cy={CY} r={R} fill="white"></circle>
      <circle
        cx={CX}
        cy={CY}
        r={R}
        fill="transparent"
        stroke={EMOTION_COLORS.neutral}
        stroke-width={PIE_WIDTH}
        onClick={() => setEmotion(EMOTIONS.neutral)}
        pointerEvents="visibleStroke"
        className="clickable"
      >
        <title>{`${EMOTIONS.neutral}: ${neutral}%`}</title>
      </circle>
      <circle
        cx={CX}
        cy={CY}
        r={R}
        fill="transparent"
        stroke={EMOTION_COLORS.negative}
        stroke-width={PIE_WIDTH}
        stroke-dasharray={`${negativeStart} ${negativeEnd}`}
        stroke-dashoffset={`${positiveEnd}`}
        onClick={() => setEmotion(EMOTIONS.negative)}
        pointerEvents="visibleStroke"
        className="clickable"
      >
        <title>{`${EMOTIONS.negative}: ${negative}%`}</title>
      </circle>
      <circle
        cx={CX}
        cy={CY}
        r={R}
        fill="transparent"
        stroke={EMOTION_COLORS.positive}
        stroke-width={PIE_WIDTH}
        stroke-dasharray={`${positiveStart} ${positiveEnd}`}
        stroke-dashoffset="0"
        onClick={() => setEmotion(EMOTIONS.positive)}
        pointerEvents="visibleStroke"
        className="clickable"
      >
        <title>{`${EMOTIONS.positive}: ${positive}%`}</title>
      </circle>
      <g>
        <text
          x="50%"
          y="50%"
          style={{
            fontSize: '2rem',
            fontWeight: '600',
            fill: `${EMOTION_COLORS[emotion]}`,
            textAnchor: 'middle',
            dominantBaseline: 'central'
          }}
        >
          {text}
        </text>
        <text
          x="50%"
          y="50%"
          style={{
            fontSize: '0.8rem',
            fill: `${EMOTION_COLORS[emotion]}`,
            dominantBaseline: 'central',
            transform: `translate(${textWidth}px, 6px)`
          }}
        >
          %
        </text>
      </g>
    </svg>
  );
};
