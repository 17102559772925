import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';

export const ConfirmArchiveModal = props => {
  return (
    <InvokeModal
      className="edit-client-modal"
      showModal={true}
      toggle={props.toggle}
      modalTitle={intl.get('app.confirm.archive')}
      primaryButtonText={intl.get('app.confirm')}
      cancelButtonText={intl.get('app.cancel')}
      save={props.save}
      enableSave
    >
      <p>{intl.get('app.archiveClient.message', { client: props.clientName })}?</p>
      <p>{intl.get('app.action.notUndone')}.</p>
    </InvokeModal>
  );
};
