import React from 'react';
import intl from 'react-intl-universal';
import { UncontrolledTooltip } from 'reactstrap';
import localtime from '../../util/localtime';
import { mediaLibraryUtil } from './mediaLibraryUtil';

export const MediaTooltip = props => {
  const { media, id, place } = props;
  const status = mediaLibraryUtil.renderMediaConvertStatus(media.mediaConvertStatus);
  return (
    <UncontrolledTooltip target={id} className="media-tooltip" placement={place || 'top'}>
      <div style={{ whiteSpace: 'nowrap' }}>
        {intl.get('app.title')}: {media.title}
      </div>
      <div style={{ whiteSpace: 'nowrap' }}>
        {intl.get('app.usage')}: {media.usageCount}
      </div>
      <div style={{ whiteSpace: 'nowrap' }}>
        {intl.get('app.lastModified')}: {localtime.getFormattedDate(media.modifiedDate)}
      </div>
      {status && (
        <div style={{ whiteSpace: 'nowrap' }}>
          {intl.get('app.status')}: {status}
        </div>
      )}
    </UncontrolledTooltip>
  );
};
