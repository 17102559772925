import React, { memo, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { isEqual } from 'lodash';
import { mediaUtil } from '../../../../util/mediaUtil';
import { InvokeTable } from 'webapp-common';

const skipUpdate = (prevProps, nextProps) => {
  if (!isEqual(prevProps.summary, nextProps.summary)) {
    return false;
  }
  return true;
};

export const ChoiceResponses = memo(props => {
  const { choices } = props.joiner.def.responseSet;

  const getChoiceAnswer = useCallback((choice, summary) => {
    if (!summary.mapAnswerValueToCount[choice] || !summary.totalResponses) {
      return '0%';
    }
    return Math.round((summary.mapAnswerValueToCount[choice] / summary.totalResponses) * 100) + '%';
  }, []);

  const tableData = useMemo(() => {
    if (!props.summary) {
      return;
    }
    const rowData = choices
      .filter(choice => !choice.value.disable)
      .map(choice => {
        const { value } = choice;
        const { imageStim } = value;
        const question = (imageStim && (imageStim.caption || imageStim.media.title)) || value.value;
        return {
          id: choice.id,
          value: question,
          src: imageStim && mediaUtil.getMediaUrl(imageStim.media),
          count: getChoiceAnswer(question, props.summary)
        };
      });
    return rowData;
  }, [choices, getChoiceAnswer, props.summary]);

  const formatQuestionCell = useCallback(info => {
    const obj = info.row.original;
    return (
      <span>
        {obj.value}
        {obj.src && <img className="image-option ms-3" src={obj.src} alt={obj.value} key={obj.id} />}
      </span>
    );
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'value',
        header: intl.get('app.answer'),
        headerClassName: 'header right-border',
        cell: formatQuestionCell,
        cellClassName: 'text-truncate',
        enableSorting: false
      },
      {
        accessorKey: 'count',
        header: intl.get('app.responses'),
        headerClassName: 'header',
        cellClassName: 'align-right',
        enableSorting: false
      }
    ];
  }, [formatQuestionCell]);

  return <>{tableData && <InvokeTable className="response-table invoke-table" columns={columns} data={tableData} />}</>;
}, skipUpdate);
