import React, { useCallback, useMemo, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { InvokeModal, InvokeTable, usePagination } from 'webapp-common';
import { ExportModal } from './ExportModal';
import localtime from '../../../util/localtime';
import { omit } from 'lodash';

import './DataExport.css';

const VIDEO_RESPONDENTS_FILTER = 'Video Respondents';

const statusIconPath = {
  PROCESSING: 'fa fa-hourglass-half',
  SUCCESS: 'fa fa-check ',
  ERROR: 'fa fa-exclamation-triangle'
};

const initialState = {
  rawData: false,
  presentation: false,
  showModal: false,
  showInvalidPPTExportModal: false
};

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

function formatLabel(info) {
  const obj = info.row.original;
  const { mediaId, reportName } = obj;
  return mediaId ? (
    <a className="link" title={reportName} href={`/a/binapi/report/${mediaId}`}>
      {reportName}
    </a>
  ) : (
    <span title={reportName}>{reportName}</span>
  );
}

function formatIcon(info) {
  const obj = info.row.original;
  return <i className={statusIconPath[obj.status]} />;
}

function getAllFilteredParticipantData(filteredParticipants) {
  return omit(filteredParticipants, [VIDEO_RESPONDENTS_FILTER]);
}

function getSelectedfilteredParticipantsData(selectedFilters, filteredParticipants) {
  const selectedFiltersSet = new Set();
  selectedFilters.forEach(element => {
    selectedFiltersSet.add(element.name);
  });

  const filteredParticipantsData = {};
  for (let key in filteredParticipants) {
    if (selectedFiltersSet.has(key)) {
      filteredParticipantsData[key] = filteredParticipants[key];
    }
  }
  return filteredParticipantsData;
}

/*
 * Component for generating Chart and Raw Data reports. Used by SessionDialData and SessionEmotionData components.
 */
export const DataExport = props => {
  const {
    sessionId,
    sessionName,
    exportData,
    reportFiles,
    exportDataInProgress,
    fetchReportList,
    filteredParticipants,
    selectedFilters,
    reportConfig,
    chartReportType,
    rawReportType,
    dataOutputType,
    getReportType
  } = props;

  const { pageRequest = {}, totalElements = 0 } = reportFiles;

  const [state, setState] = useReducer(reducer, initialState);
  const { rawData, showModal, showInvalidPPTExportModal } = state;

  const formatType = useCallback(
    info => {
      return <div>{getReportType(info.getValue())}</div>;
    },
    [getReportType]
  );

  const formatDate = useCallback(info => {
    return localtime.getFormattedDate(info.getValue());
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'reportName',
        header: intl.get('app.filename'),
        headerStyle: { width: '10rem' },
        cell: formatLabel,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'reportType',
        header: intl.get('app.type'),
        headerStyle: { width: '8rem' },
        cell: formatType,
        cellClassName: 'text-truncate',
        enableSorting: false
      },
      {
        accessorKey: 'createDate',
        header: intl.get('app.date'),
        headerStyle: { width: '8rem' },
        cell: formatDate,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'status',
        header: '',
        headerStyle: { width: '2rem' },
        cell: formatIcon,
        enableSorting: false
      }
    ];
  }, [formatDate, formatType]);

  const exportRawData = () => {
    setState({
      rawData: true,
      showModal: true
    });
  };

  const exportPresentation = () => {
    if (!selectedFilters.length) {
      setState({
        showInvalidPPTExportModal: true
      });
    } else {
      setState({
        rawData: false,
        presentation: true,
        showModal: true
      });
    }
  };

  const toggleExportModal = () => {
    setState({
      showModal: !showModal
    });
  };

  const togglePPTWarningExportModal = () => {
    setState({
      showInvalidPPTExportModal: !showInvalidPPTExportModal
    });
  };

  const handleSubmit = params => {
    const filteredParticipantsData = rawData
      ? getAllFilteredParticipantData(filteredParticipants)
      : getSelectedfilteredParticipantsData(selectedFilters, filteredParticipants);
    const includeTotal = rawData ? true : selectedFilters.some(filter => filter.name === 'Total');
    const valueType = dataOutputType;
    const requestObj = {
      reportName: params.filename,
      reportDescription: params.description,
      reportType: rawData ? rawReportType : chartReportType,
      sessionList: [sessionId],
      reportConfig: {
        config: {
          ...reportConfig.config,
          filteredParticipants: filteredParticipantsData,
          includeTotal,
          valueType
        }
      }
    };
    toggleExportModal();
    exportData(requestObj);
  };

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchReportList({ pageNum: 1, sortBy, sortOrder });
    },
    [fetchReportList]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchReportList({ pageNum: pageNumber, pageSize, sortBy, sortOrder });
    },
    [fetchReportList]
  );

  const pagination = usePagination({ pageRequest, totalElements });

  return (
    <div className="data-export">
      <div className="title">{intl.get('app.export')}</div>

      <div className="data-export-buttons">
        <Button className="button" onClick={exportPresentation}>
          {intl.get('app.exportButtons.presentation')}
        </Button>
        <Button className="button" onClick={exportRawData}>
          {intl.get('app.exportButtons.rawData')}
        </Button>
      </div>

      <div className="file-title">{intl.get('app.files')}</div>

      <InvokeTable
        className="invoke-table"
        columns={columns}
        data={reportFiles.content ?? []}
        initialState={{
          sorting: [
            {
              id: 'createDate',
              desc: true
            }
          ]
        }}
        loading={exportDataInProgress}
        pagination={pagination}
        onPaginationChange={paginate}
        onSortingChange={sort}
      />

      {showModal && (
        <ExportModal
          toggle={toggleExportModal}
          onSubmit={handleSubmit}
          modalTitle={rawData ? intl.get('app.export.rawData') : intl.get('app.export.presentation')}
          cancelButtonText={intl.get('app.cancel')}
          primaryButtonText={intl.get('app.save')}
          sessionName={sessionName}
          modalType={rawData ? getReportType(rawReportType) : getReportType(chartReportType)}
        />
      )}
      {showInvalidPPTExportModal && (
        <InvokeModal
          toggle={togglePPTWarningExportModal}
          showModal={showInvalidPPTExportModal}
          modalTitle={intl.get('app.invalid.ppt.export.title')}
          primaryButtonText={intl.get('app.ok')}
          save={togglePPTWarningExportModal}
          enableSave={true}
        >
          {intl.get('app.invalid.ppt.export.msg')}
        </InvokeModal>
      )}
    </div>
  );
};
