import React, { memo, useEffect, useRef } from 'react';
import ReactPlayerLoader from '@brightcove/react-player-loader';

const skipUpdate = (prevProps, nextProps) => {
  if (
    prevProps.watermark.refId !== nextProps.watermark.refId ||
    prevProps.watermark.videoId !== nextProps.watermark.videoId
  ) {
    return false;
  }
  if (
    prevProps.currentVideoTime !== nextProps.currentVideoTime &&
    !nextProps.pause &&
    prevProps.joiner.id === nextProps.joiner.id
  ) {
    // Time change was initited from the video player, so don't update to avoid 'jumpiness'.
    return true;
  }
  return prevProps.currentVideoTime === nextProps.currentVideoTime && prevProps.joiner.id === nextProps.joiner.id;
};

const Video = memo(props => {
  const { currentVideoTime } = props;

  const videoRef = useRef(null);

  const setCurrentVideoTime = () => {
    const player = playerRef?.ref ?? videoRef?.current?.ref ?? videoRef?.current?.player;
    if (player) {
      props.setCurrentVideoTime(player.currentTime(), false);
    }
  };

  let playerRef;

  useEffect(() => {
    if (videoRef.current) {
      playerRef = videoRef.current;
    }
  }, [videoRef.current]);

  useEffect(() => {
    if (currentVideoTime) {
      const player = playerRef?.ref ?? videoRef?.current?.ref ?? videoRef?.current?.player;
      if (player) {
        // eslint-disable-next-line no-unused-expressions
        player.currentTime(currentVideoTime);
      }
    }
  }, [currentVideoTime, playerRef]);

  const onSuccess = playerLoader => {
    const videoPlayer = playerLoader.ref.player();
    videoRef.current = playerLoader;
    playerRef = videoRef.current;

    videoPlayer.on('timeupdate', () => {
      setCurrentVideoTime();
    });

    videoPlayer.on('firstplay', () => {
      videoPlayer.pause();
    });
  };

  const getVideoId = videoSrc => {
    if (!videoSrc) {
      return null;
    }
    if (videoSrc.videoId) {
      return videoSrc.videoId;
    }
    if (videoSrc.refId) {
      return `ref:${videoSrc.refId}`;
    }
    return null;
  };

  const videoId = getVideoId(props.watermark);

  if (!videoId) {
    return null;
  }

  return (
    <div className="video" id={props.playerId}>
      <ReactPlayerLoader
        className="video-js"
        accountId="4504957117001"
        playerId="SWb7mCqqG"
        videoId={videoId}
        controls
        onSuccess={onSuccess}
        embedOptions={{ responsive: true }}
        ref={videoRef}
      />
    </div>
  );
}, skipUpdate);

export default Video;
