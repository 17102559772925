import React from 'react';
import intl from 'react-intl-universal';
import { Select, Switch } from 'antd';
import { countryCodes } from './countryCodes';

import './RelevantId.css';

const options = Object.entries(countryCodes)
  .sort(([, a], [, b]) => (a < b && -1) || (a > b && 1) || 0)
  .map(([k, v]) => (
    <Select.Option key={k} label={v}>
      {v}
    </Select.Option>
  ));

export const RelevantId = props => {
  const { relevantIdConfig, enableRelevantIdCountryValidation, setRelevantIdCountryCodes, readOnly } = props;

  return (
    <div className="relevantId-config">
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <div style={{ marginRight: '1rem' }}>{intl.get('app.enableCountryValidation')}</div>
        <Switch
          checked={relevantIdConfig.countryValidationEnabled}
          onChange={checked => enableRelevantIdCountryValidation(checked)}
          disabled={readOnly}
        />
      </div>
      <div>
        <Select
          className="country-select"
          mode="multiple"
          value={relevantIdConfig.countryCodes}
          disabled={readOnly || !relevantIdConfig.countryValidationEnabled}
          optionFilterProp="label"
          onChange={codes => setRelevantIdCountryCodes(codes)}
        >
          {options}
        </Select>
      </div>
    </div>
  );
};
