import { useQuery } from 'react-query';
import { fetchProdegeQuestions } from '../api/prodegeApi';

/*
 * Custom hook for fetching Prodege Questions
 */
export const useFetchProdegeQuestions = props => {
  const { countryId, enabledOnly, onSuccess } = props;

  return useQuery(`fetchProdegeQuestions-${countryId}`, () => fetchProdegeQuestions({ countryId, enabledOnly }), {
    refetchOnWindowFocus: false,
    onSuccess
  });
};
