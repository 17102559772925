import React from 'react';
import intl from 'react-intl-universal';
import minusCircle from '../../../../images/minus-circle.png';

const JoinerEditorHeader = props => {
  const { joiner, joinerIcon, questionNum, questionName, previewId, isConceptRotation, isEmailCollector } =
    props.joinerInfo;

  return (
    <section className="joiner-editor-header">
      <div>
        {joinerIcon}
        <span className="text-truncate">
          <span>{questionNum} </span>
          <span title={questionName}>{questionName}</span>
        </span>
      </div>
      <div>
        <span
          className={`fa fa-eye clickable ${isConceptRotation ? 'invisible' : ''}`}
          title={intl.get('app.saveAndPreview')}
          data-joiner-preview-id={previewId}
          onClick={props.saveAndPreviewJoiner}
        />
        {!isEmailCollector && (
          <img
            src={minusCircle}
            className="clickable"
            title={intl.get('app.delete')}
            onClick={e => props.handleDeleteJoinerClick(e, joiner)}
            alt=""
          />
        )}
      </div>
    </section>
  );
};

export default JoinerEditorHeader;
