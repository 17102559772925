import { createSelector } from 'reselect';
import { selectParticipantDataDefFilterFields } from '../../../util/participantDataUtil';

const selectItems = state => state.participantDataDetails;
const selectItemId = (state, itemId) => itemId;

const makeParticipantDataDefSelectorInstance = () =>
  createSelector(
    [selectItems, selectItemId],
    (items, itemId) =>
      (items[itemId] &&
        items[itemId].participantDataDefs &&
        selectParticipantDataDefFilterFields(items[itemId].participantDataDefs)) ||
      []
  );

export { makeParticipantDataDefSelectorInstance };
