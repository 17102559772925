import React from 'react';
import { ProjectSurveyTabs } from './ProjectSurveyTabs';

import './ProjectSurveyLeftNav.css';

export const ProjectSurveyLeftNav = props => {
  const { project = {}, isScreener, activeSessionId, activeSession, survey, isTemplate } = props;

  return (
    <ProjectSurveyTabs
      session={activeSession || {}}
      survey={survey}
      activeSessionId={activeSessionId}
      isTemplate={isTemplate}
      project={project}
      isScreener={isScreener}
    />
  );
};
