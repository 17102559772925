import React from 'react';
import intl from 'react-intl-universal';
import { FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { cloneDeep, isEqual } from 'lodash';
import { Loader } from 'webapp-common';

// license names
const VIDEO_CAPTURE = 'VIDEO_CAPTURE';
const CUSTOM_REPORTS = 'CUSTOM_REPORTS';
const VIDEO_DIAL_TESTING = 'VIDEO_DIAL_TESTING';

class ClientAdminFeaturesForm extends React.Component {
  state = {
    client: cloneDeep(this.props.client)
  };

  componentDidMount() {
    this.props.fetchAllLicenses();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.client, prevProps.client)) {
      this.setState({
        client: cloneDeep(this.props.client)
      });
    }
  }

  /**
   * @param {*} type - fullServe or selfServe
   */
  toggleProjectType = type => {
    this.setState({
      client: {
        ...this.state.client,
        [type]: !this.state.client[type]
      }
    });
  };

  toggleLicense = licenseName => {
    const clientLicenses = [...this.state.client.licenses];
    const index = clientLicenses.findIndex(l => l.licenseName === licenseName && l.isActive);
    if (index === -1) {
      const license = this.props.licenses.find(l => l.licenseName === licenseName);
      clientLicenses.push(license);
    } else {
      clientLicenses.splice(index, 1);
    }
    this.setState({
      client: {
        ...this.state.client,
        licenses: clientLicenses
      }
    });
  };

  toggleMFA = () => {
    this.setState({
      client: {
        ...this.state.client,
        mfaEnabled: !this.state.client.mfaEnabled
      }
    });
  };

  toggleMaxPasswordAge = () => {
    this.setState({
      client: {
        ...this.state.client,
        maxPasswordAgeEnabled: !this.state.client.maxPasswordAgeEnabled
      }
    });
  };

  setMaxPasswordAge = age => {
    this.setState({
      client: {
        ...this.state.client,
        maxPasswordAge: parseInt(age, 10)
      }
    });
  };

  hasLicense = (client, licenseName) => {
    return client.licenses.some(l => l.licenseName === licenseName && l.isActive);
  };

  saveClientSettings = () => {
    if (
      this.state.client.fullServe !== this.props.client.fullServe ||
      this.state.client.selfServe !== this.props.client.selfServe ||
      this.state.client.maxPasswordAgeEnabled !== this.props.client.maxPasswordAgeEnabled ||
      this.state.client.maxPasswordAge !== this.props.client.maxPasswordAge ||
      this.state.client.mfaEnabled !== this.props.client.mfaEnabled
    ) {
      this.props.saveClient(this.state.client);
    }
  };

  /*
   * For adding/removing a license, all we need is the ID and type. Type is
   * really only needed for adding a license, but won't break remove.
   */
  getLicenseForSave = licenseName => {
    const license = this.props.licenses.find(l => l.licenseName === licenseName);
    return {
      licenseID: license.licenseID,
      licenseType: 'DirectClient'
    };
  };

  updateLicensePayload = (payload, license, key) => {
    const { props, state } = this;
    if (this.hasLicense(state.client, key) && !this.hasLicense(props.client, key)) {
      payload.licensesToAdd.push(license);
    } else if (!this.hasLicense(state.client, key) && this.hasLicense(props.client, key)) {
      payload.licensesToRemove.push(license);
    }
  };

  saveLicenseChanges = () => {
    const { props, state } = this;

    const payload = {
      tenantID: state.client.tenantID,
      parentTenantID: state.client.parentTenantID,
      clientID: state.client.clientID,
      licensesToAdd: [],
      licensesToRemove: []
    };

    const videoCaptureLicense = this.getLicenseForSave(VIDEO_CAPTURE);
    this.updateLicensePayload(payload, videoCaptureLicense, VIDEO_CAPTURE);

    const customReportsLicense = this.getLicenseForSave(CUSTOM_REPORTS);
    this.updateLicensePayload(payload, customReportsLicense, CUSTOM_REPORTS);

    const videoDialTestingLicense = this.getLicenseForSave(VIDEO_DIAL_TESTING);
    this.updateLicensePayload(payload, videoDialTestingLicense, VIDEO_DIAL_TESTING);

    if (payload.licensesToAdd.length !== 0 || payload.licensesToRemove.length !== 0) {
      props.updateTenantLicenses(payload);
    }
  };

  handleSave = () => {
    this.saveClientSettings();
    this.saveLicenseChanges();
  };

  isInvalidProjectTypes = () => {
    return !this.state.client.fullServe && !this.state.client.selfServe;
  };

  isDirty = () => {
    const { props, state } = this;
    if (
      state.client.fullServe !== props.client.fullServe ||
      state.client.selfServe !== props.client.selfServe ||
      state.client.maxPasswordAgeEnabled !== props.client.maxPasswordAgeEnabled ||
      state.client.maxPasswordAge !== props.client.maxPasswordAge ||
      state.client.mfaEnabled !== props.client.mfaEnabled
    ) {
      return true;
    }
    if (this.hasLicense(state.client, VIDEO_CAPTURE) !== this.hasLicense(props.client, VIDEO_CAPTURE)) {
      return true;
    }
    if (this.hasLicense(state.client, VIDEO_DIAL_TESTING) !== this.hasLicense(props.client, VIDEO_DIAL_TESTING)) {
      return true;
    }
    if (this.hasLicense(state.client, CUSTOM_REPORTS) !== this.hasLicense(props.client, CUSTOM_REPORTS)) {
      return true;
    }
    return false;
  };

  render() {
    const { licenses, abilities, saveClientInProgress, updateLicensesInProgress } = this.props;
    const { isInternalSuperUser } = abilities;
    const { client } = this.state;
    const videoCaptureEnabled = this.hasLicense(client, VIDEO_CAPTURE);
    const videoDialTestingEnabled = this.hasLicense(client, VIDEO_DIAL_TESTING);
    const customReportsEnabled = this.hasLicense(client, CUSTOM_REPORTS);

    // MFA is only supported with Auth0 Enterprise subscription
    const isMfaSupported = false;

    return (
      <>
        <FormGroup row style={{ color: this.isInvalidProjectTypes() ? 'red' : 'inherit' }}>
          <Label sm="2">{intl.get('app.projectTypes')}</Label>
          <Col sm="4">
            <FormGroup check>
              <Label check disabled={!isInternalSuperUser}>
                <Input
                  type="checkbox"
                  checked={client.fullServe}
                  disabled={!isInternalSuperUser}
                  onChange={() => this.toggleProjectType('fullServe')}
                />
                {intl.get('app.fullService')}
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check disabled={!isInternalSuperUser}>
                <Input
                  type="checkbox"
                  checked={client.selfServe}
                  disabled={!isInternalSuperUser}
                  onChange={() => this.toggleProjectType('selfServe')}
                />
                {intl.get('app.selfService')}
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm="2">{intl.get('app.enableFeatures')}</Label>
          <Col sm="4">
            <FormGroup check style={{ display: 'none' }}>
              <Label check disabled={!isInternalSuperUser}>
                <Input
                  type="checkbox"
                  checked={videoCaptureEnabled}
                  disabled={!isInternalSuperUser}
                  onChange={() => this.toggleLicense(VIDEO_CAPTURE)}
                />
                {intl.get('app.videoResponses')}
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check disabled={!isInternalSuperUser}>
                <Input
                  type="checkbox"
                  checked={videoDialTestingEnabled}
                  disabled={!isInternalSuperUser}
                  onChange={() => this.toggleLicense(VIDEO_DIAL_TESTING)}
                />
                {intl.get('app.videoDialTesting')}
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check disabled={!isInternalSuperUser}>
                <Input
                  type="checkbox"
                  checked={customReportsEnabled}
                  disabled={!isInternalSuperUser}
                  onChange={() => this.toggleLicense(CUSTOM_REPORTS)}
                />
                {intl.get('app.customReports')}
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm="2">{intl.get('app.security')}</Label>
          <Col sm="4">
            {isMfaSupported && (
              <FormGroup check>
                <Label check disabled={!isInternalSuperUser}>
                  <Input
                    type="checkbox"
                    checked={client.mfaEnabled}
                    disabled={!isInternalSuperUser}
                    onChange={this.toggleMFA}
                  />
                  {intl.get('app.enableMFA')}
                </Label>
              </FormGroup>
            )}
            <FormGroup check>
              <Label check disabled={!isInternalSuperUser}>
                <Input
                  type="checkbox"
                  style={{ marginTop: '.3rem' }}
                  checked={client.maxPasswordAgeEnabled}
                  disabled={!isInternalSuperUser}
                  onChange={this.toggleMaxPasswordAge}
                />
                {intl.get('app.maxPasswordAge')}{' '}
                <Input
                  className="max-password-age-input"
                  value={client.maxPasswordAge || ''}
                  disabled={!client.maxPasswordAgeEnabled || !isInternalSuperUser}
                  onChange={e => this.setMaxPasswordAge(e.target.value)}
                />{' '}
                {intl.get('app.days')}
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm="2" />
          <Col sm="4">
            <Button
              color="primary"
              disabled={this.isInvalidProjectTypes() || !this.isDirty()}
              onClick={this.handleSave}
            >
              {intl.get('app.save')}
            </Button>
          </Col>
        </FormGroup>
        {(licenses.length === 0 || saveClientInProgress || updateLicensesInProgress) && <Loader spinner fullScreen />}
      </>
    );
  }
}

export default ClientAdminFeaturesForm;
