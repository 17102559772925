import { handleActions } from 'redux-actions';
import { participantDataPointsActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdParticipantDataPointsReducer = handleActions(
  {
    [participantDataPointsActions.succeeded](state, { payload }) {
      const { sessionId, participantDataPoints } = payload;
      return {
        ...state,
        [sessionId]: {
          participantDataPoints
        }
      };
    }
  },
  initialState
);
