import React from 'react';
import classnames from 'classnames';

import './FilterLabel.css';

export const FilterLabel = props => {
  const { name, onClick, close, key } = props;

  function handleClick() {
    onClick && onClick();
  }

  function handleClose(e) {
    e.stopPropagation();
    close();
  }

  const cssClasses = {
    'filter-label': true,
    clickable: !!onClick
  };

  return (
    <label className={classnames(cssClasses)} key={key} onClick={handleClick}>
      <span className="text-truncate" title={typeof name === 'string' ? name : ''}>
        {name}
      </span>
      {close ? <i className="fa fa-times clickable" onClick={handleClose} /> : null}
    </label>
  );
};
