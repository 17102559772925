import React, { useEffect, useReducer, useRef } from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Label } from 'reactstrap';
import { cloneDeep, find, findIndex, get, isEqual } from 'lodash';
import { InvokeModal, Loader } from 'webapp-common';
import { auth } from '../../../api/Auth';
import { getAnswers } from '../../../api/answersApi';
import { STIM_TYPE } from '../../../util/joinerUtil';
import { toast } from '../../../util/toast';
import DialDataGraph from './DialDataGraph';
import Video from '../sessionDetailsCommon/Video';
import DialDataFilters from './DialDataFilters';
import TimeSelector from '../../../components/core/timePicker/TimeSelector';
import {
  TOTAL_FILTER,
  SESSION_CHART_WIDTH,
  workerStates,
  VIDEO_SETUP,
  SHOW_ACTIONS,
  OVERLAY_DIAL,
  ZOOM_TIME,
  AUTO_SCALE_Y,
  LABEL_STYLE,
  getJoinerSelectList,
  getFilterList,
  getStartJoinerFromJoiners,
  getAdHocJoinerIdMap,
  getReportTableSortingConfig,
  MIN_ZOOM_TIME
} from '../sessionDetailsCommon/sessionDetailDataUtil';
import { DialDataExport } from './DialDataExport';
import WebWorker from '../../../util/WebWorker';
import { buildDialDataWorker } from './buildDialDataWorker';

import '../sessionDetailsCommon/sessionDetailDataStyle.css';

const reducer = (state, payload) => ({ ...state, ...payload });

const getJoinerList = survey => {
  const joiners = survey?.joiners || [];
  if (joiners.length === 0) {
    return null;
  }
  const dialJoiners = getJoinerSelectList(joiners, 'dialConfig.enabled');
  return dialJoiners.map(joiner => {
    return <option value={joiner.id} key={joiner.id}>{`${joiner.questionNumber}. ${joiner.questionTitle}`}</option>;
  });
};

const SessionDialData = props => {
  const {
    sessionId,
    sessionName,
    surveyId,
    survey,
    filters = [],
    filteredParticipants,
    dialDataStore,
    fetchSurveyInProgress,
    researchDashboardConfigRequested,
    exportDialDataInProgress,
    fetchVideoWatermarkInProgress
  } = props;

  const { userId } = survey || {};
  const { reportFiles = {} } = dialDataStore[sessionId] || {};
  const playerId = `player+${sessionId}`;

  // Set up the state/reducer
  const [state, setState] = useReducer(reducer, {
    selectedJoinerId: undefined,
    currentVideoTime: 0,
    perSecondData: [],
    rawScatterActionData: [],
    scatterRatingData: {},
    filterTotals: {},
    selectedFilters: [TOTAL_FILTER],
    pause: undefined,
    config: {
      reportTableSortingConfig: {
        pageNumber: 1,
        pageSize: 5,
        sortBy: 'createDate',
        sortOrder: 'desc',
        type: 'tableSortingConfig'
      }
    },
    selectedAction: '',
    filteredData: '',
    selectedJoiner: null,
    dataOutputType: 'AVERAGE',
    editDialSettings: false,
    dialSetting: null,
    adHocJoinerIdMap: {},
    adHocAnswers: [],
    overlay: false,
    height: 300
  });

  const { config } = state;

  // Refs for managing the worker that builds the dial data
  const worker = useRef();
  const workerState = useRef(workerStates.IDLE);
  const pendingDialData = useRef(false);

  // This needs to be kept in sync with state.selectedJoinerId. It's needed because dialDataChannelSubscribe()
  // only has access to the initial state when called by the clean-up function.
  const selectedJoinerIdRef = useRef();

  /*
   * Get the raw dial data from the store
   */
  function getRawDialData() {
    return dialDataStore[`${sessionId}-${state.selectedJoinerId}`] || [];
  }

  // Create the WebWorker, and subscribe to the filtersAndParticipants channel on mount.
  useEffect(() => {
    worker.current = new WebWorker(buildDialDataWorker);
    worker.current.onmessage = e => {
      workerState.current = workerStates.IDLE;
      if (e.data) {
        updateDialDataInState(e.data);
      }
    };

    filtersAndParticipantsChannelSubscribe('subscribe');

    return () => {
      // clean-up
      worker.current.terminate();
      filtersAndParticipantsChannelSubscribe('unsubscribe');
      dialDataChannelSubscribe('unsubscribe');
      dialDataFileChannelSubscribe('unsubscribe');
      if (state.selectedJoinerId && props.deleteVideoWatermark) {
        props.deleteVideoWatermark({ joinerId: state.selectedJoinerId, participantId: userId });
      }
    };
  }, []);

  // Fetch the survey if we don't already have it
  useEffect(() => {
    if (!survey) {
      props.fetchSurvey(surveyId);
    }
  }, [surveyId]);

  // we get this specific joiner when survey is available and put the joiner in state
  useEffect(() => {
    const selectedJoiner = getSelectedJoiner();
    setState({ selectedJoiner });
  }, [survey]);

  // Subscribe to the dialData channel when state.selectedJoinerId changes
  useEffect(() => {
    dialDataChannelSubscribe('subscribe');
    props.fetchDialSettingConfig({ userId, sessionId, joinerId: state.selectedJoinerId });
    setState({ selectedJoiner: getSelectedJoiner() });
    if (state.selectedJoinerId) {
      props.fetchVideoWatermark({
        joinerId: state.selectedJoinerId,
        participantId: userId
      });
    }
  }, [state.selectedJoinerId]);

  useEffect(() => {
    dialDataFileChannelSubscribe('subscribe');
  }, [userId, config]);

  useEffect(() => {
    if (props.dialSettingConfig && props.dialSettingConfig.dialSettingConfigSaveError) {
      toast.error({ text: intl.get('app.dialSetting.saveError') });
    }
    setState({
      dialSetting: props.dialSettingConfig,
      overlay: props.dialSettingConfig[OVERLAY_DIAL],
      height: props.dialSettingConfig[OVERLAY_DIAL] ? VIDEO_SETUP.height - 55 : 300
    });
  }, [props.dialSettingConfig]);

  // When new data arrives, set pendingDialData.current = true
  useEffect(() => {
    if (getRawDialData().length > 0) {
      pendingDialData.current = true;
      const rawData = find(getRawDialData(), d => d.questionJoinerId === state.selectedJoinerId);
      const videoLength = (rawData && rawData.videoLength) || 0;
      setState({ videoLength });
    }
  }, [getRawDialData()]);

  // Watch for changes to workerState and pendingDialData. If new data has arrived and the worker is idle, run it.
  useEffect(() => {
    if (workerState.current === workerStates.IDLE && pendingDialData.current && getRawDialData().length > 0) {
      pendingDialData.current = false;
      workerState.current = workerStates.RUNNING;
      worker.current.postMessage({
        rawDialData: cloneDeep(getRawDialData()),
        filters: getFilterList(filters),
        selectedJoiner: getSelectedJoiner(),
        filteredParticipants,
        chartWidth: SESSION_CHART_WIDTH
      });
    }
  }, [workerState.current, pendingDialData.current]);

  /*
   * When new data arrives, see if there are any new action button clicks that are configured with ad-hoc joiners.
   * We expect answers for these, so we need to fetch for any new answers.
   */
  useEffect(() => {
    if (getRawDialData().length > 0) {
      const actionButtonCounts = {};
      getRawDialData().forEach(obj => {
        Object.values(obj.actions).forEach(val => {
          actionButtonCounts[val] = actionButtonCounts[val] || 0;
          actionButtonCounts[val]++;
        });
      });

      const answerCounts = {};
      state.adHocAnswers.forEach(ans => {
        answerCounts[ans.questionJoinerId] = answerCounts[ans.questionJoinerId] || 0;
        answerCounts[ans.questionJoinerId]++;
      });

      let doFetchAnswers = false;
      Object.entries(actionButtonCounts).forEach(([k, v]) => {
        const adHocJoinerId = state.adHocJoinerIdMap[k];
        if (adHocJoinerId && answerCounts[adHocJoinerId] !== v) {
          doFetchAnswers = true;
        }
      });

      if (doFetchAnswers) {
        fetchAnswers();
      }
    }
  }, [getRawDialData()]);

  // When the selected joiner changes, reset adHocJoinerIdMap and adHocAnswers in the state.
  useEffect(() => {
    if (state.selectedJoiner) {
      const adHocJoinerIdMap = getAdHocJoinerIdMap(state.selectedJoiner, survey?.joiners);
      setState({
        adHocJoinerIdMap,
        adHocAnswers: []
      });
    }
  }, [state.selectedJoiner]);

  // When the adHocJoinerIdMap changes, fetch answers.
  useEffect(() => {
    fetchAnswers();
  }, [state.adHocJoinerIdMap]);

  function fetchAnswers() {
    const adHocJoinerIds = Object.values(state.adHocJoinerIdMap);
    if (adHocJoinerIds.length > 0) {
      getAnswers({
        sessionIds: [sessionId],
        questionJoinerIds: Object.values(state.adHocJoinerIdMap)
      })
        .then(res => setState({ adHocAnswers: res }))
        .catch(err => console.log(err));
    }
  }

  function updateDialDataInState(data) {
    const { perSecondData, filterTotals, lastBuilt, rawScatterActionData, scatterRatingData } = data;
    setState({
      perSecondData: perSecondData || [],
      filterTotals: filterTotals || {},
      dataLastBuilt: lastBuilt,
      rawScatterActionData: rawScatterActionData || [],
      scatterRatingData: scatterRatingData || {}
    });
  }

  function filtersAndParticipantsChannelSubscribe(subAction) {
    props.filtersAndParticipantsChannelSubscribe({
      subAction,
      sessionId
    });
  }

  function dialDataChannelSubscribe(subAction) {
    if (selectedJoinerIdRef.current) {
      props.dialDataChannelSubscribe({
        subAction,
        sessionId,
        joinerId: selectedJoinerIdRef.current
      });
    }
  }

  function dialDataFileChannelSubscribe(subAction) {
    if (userId) {
      const rdConfig = {
        sessionId,
        userId,
        configs: config
      };

      props.dialDataFileChannelSubscribe({
        subAction,
        rdConfig
      });
    }
  }

  const fetchReportList = params => {
    const config = getReportTableSortingConfig(params);
    dialDataFileChannelSubscribe('unsubscribe');
    setState({
      config: config
    });
  };

  const selectJoinerId = e => {
    // Unsubscribe from the dial data channel for the previously selected joinerId
    dialDataChannelSubscribe('unsubscribe');

    // Set the selected joinerId in the state, and reset some key values. This will
    // also have the effect of fetching dial data for the newly-selected joiner.
    selectedJoinerIdRef.current = e.target.value;
    setState({
      selectedJoinerId: e.target.value,
      currentVideoTime: 0,
      perSecondData: [],
      rawScatterActionData: [],
      scatterRatingData: {},
      filterTotals: {},
      dataLastBuilt: '',
      pause: undefined
    });
  };

  /*
   * This page has minimal user interaction with the server, so need to keep the user's session alive if they are active in the UI.
   */
  function keepSessionAlive() {
    auth.isAuthenticated(true);
  }

  const setCurrentVideoTime = (time, pause) => {
    keepSessionAlive();
    if (time >= 0) {
      setState({
        currentVideoTime: Math.round(time),
        pause
      });
    }
  };

  const selectFilter = filter => {
    keepSessionAlive();
    const index = findIndex(state.selectedFilters, f => f.name === filter.name);
    const selectedFilters = [...state.selectedFilters];
    if (index === -1) {
      selectedFilters.push(filter);
    } else {
      selectedFilters.splice(index, 1);
    }
    setState({ selectedFilters });
  };

  function getSelectedJoiner() {
    const { joiners = [] } = survey || {};
    return (
      find(joiners, joiner => joiner.id === state.selectedJoinerId) ||
      getStartJoinerFromJoiners(joiners, state.selectedJoinerId)
    );
  }

  const showLoader =
    fetchSurveyInProgress ||
    researchDashboardConfigRequested ||
    exportDialDataInProgress ||
    fetchVideoWatermarkInProgress;

  if (!state.selectedJoinerId && survey && survey.joiners.length > 0) {
    let defaultJoiner = find(
      survey.joiners,
      joiner => joiner.stim && joiner.stim.type === STIM_TYPE.video && joiner.stim.options.dialConfig.enabled
    );
    if (!defaultJoiner) {
      for (let index = 0; index < survey.joiners.length; index++) {
        const joiner = survey.joiners[index];
        if (joiner.conceptRotation && joiner.conceptRotation.concepts) {
          const concept = find(
            joiner.conceptRotation.concepts,
            c =>
              c.startJoiner.stim &&
              c.startJoiner.stim.type === STIM_TYPE.video &&
              c.startJoiner.stim.options.dialConfig.enabled
          );
          if (concept) {
            defaultJoiner = concept.startJoiner;
            break;
          }
        }
      }
    }
    if (defaultJoiner) {
      const selectedId = defaultJoiner.id;
      selectedJoinerIdRef.current = selectedId;
      setState({
        selectedJoinerId: selectedId
      });
    }
  }

  const toggleDialSettings = () =>
    setState({
      editDialSettings: !state.editDialSettings
    });

  const setDialAction = e => {
    setState({ dialSetting: { ...state.dialSetting, action: e.target.value } });
  };

  function getDialActions() {
    const selectedJoiner = getSelectedJoiner();
    return get(selectedJoiner, 'stim.options.dialConfig.actionButtons', []);
  }

  const getActionList = () => {
    const options = [];
    const dialActions = getDialActions();
    dialActions.forEach((action, i) => {
      options.push(
        <option value={action.label} key={`${action.label}+${i}`}>
          {action.label}
        </option>
      );
    });
    return options;
  };

  const saveDialSettings = () => {
    props.saveDialSettingConfig({
      ...state.dialSetting,
      sessionId,
      userId,
      type: 'dialSettingConfig',
      questionJoinerId: state.selectedJoinerId
    });
    setState({ editDialSettings: false });
  };

  const updateTimeWindow = time => {
    if (!time) {
      return 0;
    }
    let inSeconds = time.hours() * 3600 + time.minutes() * 60 + time.seconds();
    if (inSeconds < MIN_ZOOM_TIME) {
      inSeconds = MIN_ZOOM_TIME;
    } else if (inSeconds > state.videoLength) {
      inSeconds = state.videoLength;
    }
    setState({
      dialSetting: {
        ...state.dialSetting,
        timeWindow: inSeconds
      }
    });
  };

  const getConfiguredTime = () => {
    if (!state.dialSetting && !state.dialSetting.timeWindow) {
      return 0;
    }
    return state.dialSetting.timeWindow;
  };

  const getAction = action => {
    const dialActions = getDialActions();
    if (action === SHOW_ACTIONS) {
      return state.dialSetting[action] ? undefined : dialActions[0] && dialActions[0].label;
    } else {
      return state.dialSetting.action;
    }
  };

  const onDialSettingsChange = target => {
    const before = state.dialSetting[target];
    setState({
      dialSetting: {
        ...state.dialSetting,
        [target]: !before,
        action: getAction(target)
      }
    });
  };

  const updateDialFilters = settings => {
    props.saveDialSettingConfig({
      ...settings,
      sessionId,
      userId,
      type: 'dialSettingConfig',
      questionJoinerId: state.selectedJoinerId
    });
  };

  const left =
    state.dialSetting && state.dialSetting[ZOOM_TIME] ? state.currentVideoTime - state.dialSetting.timeWindow / 2 : 0;
  const right =
    state.dialSetting && state.dialSetting[ZOOM_TIME]
      ? state.currentVideoTime + state.dialSetting.timeWindow / 2
      : state.videoLength;

  return (
    <div className="session-dial-data">
      {showLoader && <Loader spinner fullScreen />}
      <div className="settings-area">
        <Input type="select" className="question-select" value={state.selectedJoinerId} onChange={selectJoinerId}>
          <option value="" hidden />
          {getJoinerList(survey)}
        </Input>
        <Button className="link-button" onClick={toggleDialSettings}>
          <i className="fas fa-cog ms-5 me-3" />
          <Label>{intl.get('app.settings')}</Label>
        </Button>
      </div>
      {state.selectedJoiner && (
        <React.Fragment>
          <div className={state.overlay === true ? 'overlay-graph-and-video' : 'dial-data-graph-and-video'}>
            <DialDataGraph
              sessionId={sessionId}
              filterList={getFilterList(filters)}
              rawScatterActionData={state.rawScatterActionData}
              scatterRatingData={state.scatterRatingData}
              dataLastBuilt={state.dataLastBuilt}
              joiner={state.selectedJoiner}
              adHocJoinerIdMap={state.adHocJoinerIdMap}
              adHocAnswers={state.adHocAnswers}
              selectedFilters={state.selectedFilters}
              currentVideoTime={state.currentVideoTime}
              setCurrentVideoTime={setCurrentVideoTime}
              chartWidth={state.overlay === true ? VIDEO_SETUP.width : SESSION_CHART_WIDTH}
              selectedAction={state.dialSetting.action}
              autoScaleY={state.dialSetting[AUTO_SCALE_Y]}
              height={state.height}
              left={left}
              right={right}
              overlay={state.overlay}
              dialColorSettings={state.dialSetting?.dialColorSettings}
            />
            {props.videoWatermark && (
              <Video
                joiner={state.selectedJoiner}
                currentVideoTime={state.currentVideoTime}
                pause={state.pause}
                setCurrentVideoTime={setCurrentVideoTime}
                watermark={props.videoWatermark}
                playerId={playerId}
              />
            )}
          </div>
          <div className="dial-data-filters-and-export">
            <DialDataFilters
              filterList={getFilterList(filters)}
              selectedFilters={state.selectedFilters}
              perSecondData={state.perSecondData}
              filterTotals={state.filterTotals}
              dataLastBuilt={state.dataLastBuilt}
              currentVideoTime={state.currentVideoTime}
              selectFilter={selectFilter}
              dataOutputType={state.dataOutputType}
              scatterRatingData={state.scatterRatingData}
              selectedAction={state.dialSetting.action}
              rawScatterActionData={state.rawScatterActionData}
              overlay={state.dialSetting && state.dialSetting[OVERLAY_DIAL]}
              updateDialFilters={updateDialFilters}
              dialSetting={state.dialSetting}
            />
            <DialDataExport
              sessionId={sessionId}
              sessionName={sessionName}
              joiner={state.selectedJoiner}
              reportFiles={reportFiles}
              filteredParticipants={filteredParticipants}
              exportData={props.exportDialData}
              fetchReportList={fetchReportList}
              exportDataInProgress={exportDialDataInProgress}
              selectedAction={state.dialSetting.action}
              selectedFilters={state.selectedFilters}
              dataOutputType={state.dataOutputType}
            />
          </div>
          <InvokeModal
            showModal={state.editDialSettings}
            toggle={toggleDialSettings}
            className="dial-Settings-Modal"
            modalTitle={intl.get('app.dial.settings')}
            primaryButtonText={intl.get('app.save')}
            save={saveDialSettings}
            cancelButtonText={intl.get('app.cancel')}
            enableSave={!isEqual(state.dialSetting, props.dialSettingConfig)}
          >
            <div className="mt-3">
              <Label style={LABEL_STYLE} onClick={() => onDialSettingsChange(SHOW_ACTIONS)}>
                <Input
                  type="checkbox"
                  checked={state.dialSetting[SHOW_ACTIONS]}
                  style={{ verticalAlign: 'text-top' }}
                />
                <Label className="me-4">{intl.get(`app.${SHOW_ACTIONS}`)}:</Label>
              </Label>
              <Input
                type="select"
                style={{ maxWidth: '50%', display: 'inline-block' }}
                value={state.dialSetting.action}
                onChange={setDialAction}
                disabled={!state.dialSetting[SHOW_ACTIONS]}
              >
                {getActionList()}
              </Input>
            </div>
            <div className="mt-3">
              <Label style={LABEL_STYLE} onClick={() => onDialSettingsChange(AUTO_SCALE_Y)}>
                <Input
                  type="checkbox"
                  checked={state.dialSetting[AUTO_SCALE_Y]}
                  style={{ verticalAlign: 'text-top' }}
                />
                <Label>{intl.get(`app.${AUTO_SCALE_Y}`)}</Label>
              </Label>
            </div>
            <div className="mt-3">
              <Label style={LABEL_STYLE} onClick={() => onDialSettingsChange(OVERLAY_DIAL)}>
                <Input
                  type="checkbox"
                  checked={state.dialSetting[OVERLAY_DIAL]}
                  style={{ verticalAlign: 'text-top' }}
                />
                <Label>{intl.get(`app.${OVERLAY_DIAL}`)}</Label>
              </Label>
            </div>
            <div className="mt-3">
              <Label onClick={() => onDialSettingsChange(ZOOM_TIME)} style={LABEL_STYLE}>
                <Input type="checkbox" checked={state.dialSetting[ZOOM_TIME]} style={{ verticalAlign: 'text-top' }} />
                <Label className="me-4">{intl.get(`app.${ZOOM_TIME}`)}:</Label>
              </Label>
              <TimeSelector
                pickedTime={getConfiguredTime()}
                updateTime={updateTimeWindow}
                timeFormat="HH:mm:ss"
                readOnly={!state.dialSetting[ZOOM_TIME]}
              />
            </div>
          </InvokeModal>
        </React.Fragment>
      )}
    </div>
  );
};

export default SessionDialData;
