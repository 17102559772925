import { takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import {
  fetchRolesSummaryAction,
  fetchTenantRolesAction,
  updateTenantRolesAction,
  deleteTenantRolesAction,
  fetchTenantAbilitiesAction,
  addRoleAction
} from '../actions/clientAdminActions';
import {
  fetchRolesSummaryApi,
  fetchTenantRoles,
  updateTenantRoles,
  deleteTenantRoles,
  fetchTenantAbilities,
  addTenantRoles
} from '../../../api/clientAdminApi';
import createFetchSaga from './createFetchSaga';

function* clientAdminRolesSummarySaga() {
  const saga = createFetchSaga(fetchRolesSummaryAction, fetchRolesSummaryApi);
  yield* saga();
}

function* clientAdminRolesSaga() {
  const saga = createFetchSaga(fetchTenantRolesAction, fetchTenantRoles);
  yield* saga();
}

function* clientAdminUpdateTenantRolesSaga() {
  const saga = createFetchSaga(updateTenantRolesAction, updateTenantRoles);
  yield* saga();
  yield takeLatest(updateTenantRolesAction.succeeded, ({ payload }) => {
    toast.success({ text: intl.get('app.roles.updated', { name: payload.roleLabel }) });
  });
}

function* clientAdminDeleteTenantRolesSaga() {
  const saga = createFetchSaga(deleteTenantRolesAction, deleteTenantRoles);
  yield* saga();
  yield takeLatest(deleteTenantRolesAction.succeeded, ({ payload }) => {
    toast.success({ text: intl.get('app.roles.deleted', { name: payload.roleLabel }) });
  });
}

function* fetchTenantAbilitiesSaga() {
  const saga = createFetchSaga(fetchTenantAbilitiesAction, fetchTenantAbilities);
  yield* saga();
}

function* clientAdminAddTenantRolesSaga() {
  const saga = createFetchSaga(addRoleAction, addTenantRoles);
  yield* saga();
  yield takeLatest(addRoleAction.succeeded, ({ payload }) => {
    toast.success({ text: intl.get('app.roles.added', { name: payload.roleLabel }) });
  });
}

export {
  clientAdminRolesSummarySaga,
  clientAdminRolesSaga,
  clientAdminUpdateTenantRolesSaga,
  clientAdminDeleteTenantRolesSaga,
  fetchTenantAbilitiesSaga,
  clientAdminAddTenantRolesSaga
};
