import React, { useState } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

export const ExportModal = props => {
  const {
    sessionName,
    modalType,
    toggle,
    modalTitle = '',
    exportNameLabel = intl.get('app.filename'),
    exportDescriptionLabel = intl.get('app.description'),
    primaryButtonText = intl.get('app.save'),
    cancelButtonText = intl.get('app.cancel')
  } = props;
  const timeStamp = moment().format('MM-DD-YYYY_HH-mm-ss');
  const reportType = modalType.trim().replace(/ /g, '_');
  const defaultName = `${sessionName}_${reportType}_${timeStamp}`;

  const [filename, setFilename] = useState(defaultName);
  const [description, setDescription] = useState('');

  const updateFileName = e => {
    setFilename(e.target.value.trim());
  };

  const updateDescription = e => {
    setDescription(e.target.value);
  };

  const onSubmit = () => {
    props.onSubmit({
      filename,
      description
    });
  };

  return (
    <InvokeModal
      showModal
      toggle={toggle}
      modalTitle={modalTitle}
      cancelButtonText={cancelButtonText}
      primaryButtonText={primaryButtonText}
      enableSave={filename}
      save={onSubmit}
    >
      <label>{exportNameLabel}</label>
      <Input type="text" value={filename} onChange={updateFileName} />
      <br />
      <label>{exportDescriptionLabel}</label>
      <Input type="text" value={description} onChange={updateDescription} />
    </InvokeModal>
  );
};
