import React from 'react';
import FunctionModal from '../FunctionModal';
import { Loader } from 'webapp-common';
const EditFunction = ({ ...args }) => {
  if (args.functionRequested) {
    return <Loader spinner fullScreen />;
  }
  return <FunctionModal {...args} />;
};
export default EditFunction;
