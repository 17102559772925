import React from 'react';
import { JoinerEditorTabs, JoinerEditorHeader, JoinerEditorButtons } from '../joinerEditor';
import Edit from '../editForms/edit/MediaStimEdit';
import Options from '../editForms/options/imageStimOptions';
import Timing from '../editForms/timing/Timing';
import Rules from '../editForms/rules/Rules';

import './MediaStimEditor.css';

class MediaStimEditor extends React.Component {
  render() {
    const { props } = this;
    return (
      <>
        <JoinerEditorTabs tabs={props.tabs} joinerInfo={props.joinerInfo} hideMedia />
        <div className="joiner-editor">
          <JoinerEditorHeader {...props} />
          <div className="joiner-editor-form">
            <Edit {...props} />
            <Options {...props} />
            <Timing {...props} />
            <Rules {...props} />
          </div>
          <JoinerEditorButtons
            toggle={props.toggle}
            saveEnabled={props.saveEnabled}
            saveJoiner={props.saveJoiner}
            readOnly={props.readOnly}
          />
        </div>
      </>
    );
  }
}

export default MediaStimEditor;
