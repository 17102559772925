import React, { useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { Pagination, useWindowSize } from 'webapp-common';
import { mediaLibraryUtil } from './mediaLibraryUtil';
import { MediaTooltip } from './MediaTooltip';

export const MediaGrid = props => {
  const { mediaListLibrary, pagination, selectedMedia, fetchMedia, onMediaToggle, onPaginationChange } = props;
  const { pagedList = {} } = mediaListLibrary;
  const { pageRequest } = pagedList;

  const windowDims = useWindowSize();

  const [columns, pageSize] = useMemo(() => {
    const height = windowDims[1];
    const container = document.querySelector('.media-library');
    const header = document.querySelector('.page-header');
    const footer = document.querySelector('.page-footer');
    const topRow = document.querySelector('.top-row');
    const filtersRow = document.querySelector('.filters');
    const pagerRow = document.querySelector('.paginator');
    const containerHeight =
      height -
      (header?.clientHeight || 0) -
      (footer?.clientHeight || 0) -
      (topRow?.clientHeight || 0) -
      (filtersRow?.clientHeight || 0) -
      (pagerRow?.clientHeight || 80);
    const containerWidth = container?.clientWidth || 0;
    const columns = Math.floor(containerWidth / 170);
    const rows = Math.floor(containerHeight / 140);
    const pageSize = columns * rows;
    return [columns, pageSize];
  }, [windowDims]);

  useEffect(() => {
    if (pageSize > 0) {
      fetchMedia({
        pageSize
      });
    }
  }, [fetchMedia, pageSize]);

  const mediaGrid = useMemo(() => {
    const rows = [];
    pagedList.content.forEach((media, index) => {
      if (index % columns === 0) {
        rows.push([]);
      }
      const cssClasses = {
        disabled: media.mediaConvertStatus && media.mediaConvertStatus.state === 'IN_PROGRESS',
        selected: selectedMedia._id === media._id
      };
      rows[rows.length - 1].push(
        <td key={media._id}>
          <div id={`tips-${index}`} className={classnames(cssClasses)} onClick={() => onMediaToggle(media)}>
            <div>{mediaLibraryUtil.getImage(media.accessUrl, media)}</div>
            <div title={media.title}>{media.title}</div>
            <MediaTooltip id={`tips-${index}`} media={media} />
          </div>
        </td>
      );
    });
    return rows.map((row, i) => <tr key={i}>{row}</tr>);
  }, [columns, onMediaToggle, pagedList.content, selectedMedia._id]);

  const paginate = useCallback(
    ({ pageNumber, pageSize }) => {
      onPaginationChange({
        pageNumber,
        pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder
      });
    },
    [onPaginationChange, pageRequest.sortBy, pageRequest.sortOrder]
  );

  return (
    <div className="media-grid">
      <table>
        <tbody>{mediaGrid}</tbody>
      </table>
      <Pagination pagination={pagination} onPaginationChange={paginate} />
    </div>
  );
};
