import React from 'react';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

import './ImportQuestionButton.css';

export const ImportQuestionButton = props => {
  const { readOnly, isSessionOpen, insertOrEditAction, toggleImportQuestionModal } = props;

  if (readOnly) {
    return null;
  }

  const isDisabled = (isSessionOpen && insertOrEditAction === 'EDIT') || props.isDisabled;

  return (
    <Button
      className="mt-3 question-import-button"
      disabled={isDisabled}
      title={isDisabled ? intl.get('app.importUnavailable') : intl.get('app.import')}
      onClick={toggleImportQuestionModal}
    >
      <span className="fa fa-upload me-2" />
      {intl.get('app.import')}
    </Button>
  );
};
