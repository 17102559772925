export const buildEmotionDataWorker = () => {
  const TOTAL = 'Total';

  const fillInVoidValues = rawData => {
    if (!rawData) {
      return;
    }
    const keys = Object.keys(rawData);
    const emotions = Object.keys(rawData[keys[0]]);
    const returnValues = {};
    for (let ki = 0; ki < keys.length; ki++) {
      const key = keys[ki];
      returnValues[key] = {};
      for (let ei = 0; ei < emotions.length; ei++) {
        const emotion = emotions[ei];
        returnValues[key][emotion] = [];
        const eData = rawData[key][emotion];
        for (let i = 0; i < eData.length; i++) {
          let startPos = i === 0 ? 0 : eData[i - 1].time + 1;
          const fillinValues = JSON.parse(JSON.stringify(eData[i]));
          for (startPos; startPos <= eData[i].time; startPos++) {
            fillinValues.time = startPos;
            returnValues[key][emotion].push(JSON.parse(JSON.stringify(fillinValues)));
          }
        }
      }
    }
    return returnValues;
  };

  const calculateSimpleAverage = (eData, filter, emotion) => {
    const data = eData[filter.name][emotion];
    const sum = data.reduce((result, snapshot) => {
      return result + snapshot.confidence;
    }, 0);
    return Math.round(sum / data.length);
  };

  const calculateAverage = (source, filters, emotions) => {
    const averages = {};
    filters.map(filter => {
      averages[filter.name] = {};
      emotions.map(emotion => {
        averages[filter.name][emotion] = calculateSimpleAverage(source, filter, emotion);
      });
    });
    return averages;
  };

  onmessage = e => {
    const { emotionData, filters, emotions, filteredParticipants = {} } = e.data;
    const localPerSecond = getPerSecondEmotions(emotionData, emotions, filters, filteredParticipants);
    const localAverage = getEmotionAvgData(localPerSecond, emotions, filters);
    const smoothData = fillInVoidValues(localAverage);
    const emotionAvgData = calculateAverage(localAverage, filters, emotions);

    postMessage({
      perSecondEmotions: localPerSecond,
      emotionAvgChartData: smoothData,
      emotionAvgData,
      lastBuilt: Date.now()
    });
  };

  const foundParticipant = (snapshot, filter, participants) =>
    filter.name === TOTAL || participants[filter.name].indexOf(snapshot.participantId) >= 0;

  const getEmotionFromSnapShot = (snapshot, emotions) => {
    const temp = JSON.parse(JSON.stringify(snapshot));
    const listEmotionData = snapshot.listEmotionData;
    delete temp.listEmotionData;
    emotions.forEach(emotion => {
      const confidence = listEmotionData.filter(e => e.type === emotion)[0].confidence;
      temp[emotion] = Math.round(confidence);
    });
    return temp;
  };

  const getPerSecondEmotions = (data, emotions, filters, participants) => {
    const temp = {};
    if (!participants && !filters && data.length > 0) {
      return {};
    }
    if (filters && filters.length > 0) {
      filters.forEach(f => {
        temp[f.name] = [];
        data.forEach(datum => {
          if (foundParticipant(datum, f, participants)) {
            const snapshot = getEmotionFromSnapShot(datum, emotions);
            if (!temp[f.name][datum.videoPosition]) {
              temp[f.name][datum.videoPosition] = [];
            }
            temp[f.name][datum.videoPosition].push(snapshot);
          }
        });
      });
    }
    return temp;
  };

  const getEmotionAvgData = (incomingPerSecond, emotions, filters) => {
    const chartData = {};
    filters.forEach(f => {
      chartData[f.name] = {};
      emotions.forEach(emotion => {
        chartData[f.name][emotion] = [];
      });
      incomingPerSecond[f.name].forEach((snapshots, time) => {
        if (snapshots) {
          emotions.forEach(emotion => {
            const confidence = snapshots.reduce((result, s) => {
              return result + s[emotion];
            }, 0);
            const average = Math.round(confidence / snapshots.length);
            chartData[f.name][emotion].push({ time, confidence: average });
          });
        }
      });
    });
    return chartData;
  };
};
