import { connect } from 'react-redux';
import { fetchAllSessionsAction } from '../../../../store/redux/actions/sessionActions';
import {
  fetchDraftRDConfigActions,
  saveCompleteRDConfigActions
} from '../../../../store/redux/actions/researchDashboardActions';
import { sessionSelector } from '../../../../store/redux/selectors/sessionSelector';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';

import { ReportWrapper } from './ReportWrapper';

const mapDispatchToProps = dispatch => ({
  fetchSessions: params => dispatch(fetchAllSessionsAction.request(params)),
  fetchRDConfig: params => dispatch(fetchDraftRDConfigActions.request(params)),
  saveRDConfig: rdConfig => dispatch(saveCompleteRDConfigActions.request(rdConfig))
});

const mapStateToProps = (state, props) => {
  const { projectId } = props;
  const sessionsSelector = sessionSelector()(state);
  const { allSessions, requestInProgress } = sessionsSelector;
  const sessions =
    (allSessions.content && allSessions.content.filter(s => s.projectId === projectId && !s.screener)) || [];
  return {
    sessions,
    fetchSessionsInProgress: requestInProgress,
    user: sessionUserSelector(state).sessionUser,
    rdConfigStore: state.rdConfig
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportWrapper);
