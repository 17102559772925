import React from 'react';
import update from 'immutability-helper';
import { FormGroup, Button } from 'reactstrap';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import { isNull, isEqual, cloneDeep } from 'lodash';
import { FlexRow, Loader } from 'webapp-common';
import appConfig from '../../appConfig';
import ReportSessionsPickerModal from './ReportSessionsPickerModal';
import ReportAddDataColumnModal from './ReportAddDataColumnModal';
import ReportAddSegmentModal from './ReportAddSegmentModal';
import ReportConfigNormsModal from './ReportConfigNormsModal';
import localtime from '../../util/localtime';

import './ReportConfig.css';

const SESSION_IDS = 'sessionIds';
const QUESTIONS = 'questions';
const SEGMENTS = 'segments';
const SESSION = 'session';
const NORMS = 'norms';
const SHOW_SESSION_MODAL = 'showSessionModal';
const SHOW_QUESTION_MODAL = 'showQuestionModal';
const SHOW_SEGMENT_MODAL = 'showSegmentModal';
const SHOW_NORM_MODAL = 'showNormModal';

class ReportConfig extends React.Component {
  state = {
    config: {
      questions: []
    },
    selectedSessions: [],
    [SHOW_SESSION_MODAL]: false,
    [SHOW_QUESTION_MODAL]: false,
    [SHOW_SEGMENT_MODAL]: false,
    [SHOW_NORM_MODAL]: false
  };

  componentDidMount() {
    this.props.fetchCustomReportConfig({ id: this.props.reportConfigId });
    this.props.fetchSessions({ pageSize: -1, sortBy: 'startDate' });
  }

  componentDidUpdate(prevProps, prevState) {
    const { props, state } = this;
    const { customReportConfig = {} } = props.customReportConfig;
    if (!isEqual(prevState.selectedSessions, state.selectedSessions)) {
      props.fetchCustomReportConsolidatedData({
        customReportConfigId: customReportConfig.id,
        sessionIds: state.config.sessionIds
      });
    }
    if (customReportConfig.id || !isEqual(props.sessions, prevProps.sessions)) {
      if (
        !state.config.id ||
        !isEqual(customReportConfig, prevProps.customReportConfig.customReportConfig) ||
        !isEqual(props.sessions, prevProps.sessions)
      ) {
        this.setState({
          config: cloneDeep(customReportConfig),
          selectedSessions: this.getCollectionsFromList(props.sessions, customReportConfig.sessionIds, true)
        });
      }
    }
  }

  getCollectionsFromList = (source, target = [], inclusion) => {
    return inclusion ? source.filter(e => target.indexOf(e.id) >= 0) : source.filter(e => target.indexOf(e.id) < 0);
  };

  toggleModal = modal => {
    this.setState({
      [modal]: !this.state[modal]
    });
  };

  toggleQuestionModal = (question, index) => {
    this.setState({
      [SHOW_QUESTION_MODAL]: !this.state[SHOW_QUESTION_MODAL],
      questionForEdit: question,
      questionForEditIndex: index
    });
  };

  saveSessions = targetKeys => {
    if (!this.props.sessions || !this.props.sessions.length < 0) {
      return;
    }
    const selectedSessions = this.getCollectionsFromList(this.props.sessions, targetKeys, true);
    this.setState({
      selectedSessions,
      config: {
        ...this.state.config,
        sessionIds: [...targetKeys]
      },
      [SHOW_SESSION_MODAL]: false
    });
  };

  // Save a new or existing question config
  saveQuestion = questionData => {
    const questions = cloneDeep(this.state.config.questions);
    // Use the index of the existing question config (questionForEditIndex) or add at the end of the list.
    const { questionForEditIndex = questions.length } = this.state;
    questions[questionForEditIndex] = questionData;
    const normsConfigured = questions.some(q => q.answerData.norm);
    const state = update(this.state, {
      config: {
        questions: { $set: questions },
        normsConfigured: { $set: normsConfigured }
      },
      [SHOW_QUESTION_MODAL]: { $set: false },
      questionForEdit: { $set: undefined },
      questionForEditIndex: { $set: undefined }
    });
    this.setState(state);
  };

  saveSegment = segmentData => {
    const state = update(this.state, {
      config: {
        segments: { $push: [segmentData] }
      },
      [SHOW_SEGMENT_MODAL]: { $set: false }
    });
    this.setState(state);
  };

  saveNorms = normsData => {
    const normsConfigured = normsData.some(n => n.answerData.norm);
    const state = update(this.state, {
      config: {
        questions: { $merge: normsData },
        normsConfigured: { $set: normsConfigured }
      },
      [SHOW_NORM_MODAL]: { $set: false }
    });
    this.setState(state);
  };

  onGenerateReport = () => {
    const reportName = this.state.config.name.concat('_', localtime.getFormattedDate(new Date()));
    const report = {
      reportName,
      reportType: 'CUSTOM',
      sessionList: this.state.config.sessionIds,
      reportConfig: {
        config: this.state.config
      }
    };
    this.props.generateCustomReport(report);
  };

  onEntityClick = (e, type, item, index) => {
    switch (type) {
      case QUESTIONS:
        this.toggleQuestionModal(item, index);
        break;
      case SESSION_IDS:
      case SEGMENTS:
      default:
    }
  };

  onRemove = (e, type, target, index) => {
    e.stopPropagation();
    let result = [];
    switch (type) {
      case SESSION_IDS:
        const { sessionIds } = this.state.config;
        result = this.removeFromConfig(type, sessionIds, null, target.id);
        const selectedSessions = this.getCollectionsFromList(this.props.sessions, result, true);
        this.setState({
          selectedSessions
        });
        break;
      case QUESTIONS:
        const { questions } = this.state.config;
        result = this.removeFromConfig(type, questions, 'questionJoinerId', target.questionJoinerId, index);
        break;
      case SEGMENTS:
        const { segments } = this.state.config;
        result = this.removeFromConfig(type, segments, 'name', target.name);
        break;
      default:
        return;
    }
    this.setState({
      config: {
        ...this.state.config,
        [type]: result
      }
    });
  };

  removeFromConfig = (prop, val, filterProp, filterStr, index) => {
    if (index >= 0) {
      val.splice(index, 1);
      return val;
    }
    return val.filter(v => (isNull(filterProp) ? v !== filterStr : v[filterProp] !== filterStr));
  };

  showTitle = (item, obj) => {
    switch (item) {
      case SESSION_IDS:
        return obj.name;
      case QUESTIONS:
        const isMatrix = obj.answerData?.row?.length > 0;
        const label = isMatrix ? `${obj.label} - ${obj.answerData.row}` : obj.label;
        const title = obj.category || label;
        // If category exists, description is the label.
        const description = obj.category && label;
        return (
          <span>
            <span className="title" title={title}>
              {title}
            </span>
            {description && (
              <p className="ms-5 mb-0 question-options" title={description}>
                {description}
              </p>
            )}
          </span>
        );
      case SEGMENTS:
        return obj.name;
      default:
        return obj;
    }
  };

  showItems = itemName => {
    const items = itemName === SESSION_IDS ? this.state.selectedSessions : this.state.config[itemName];
    const rowClasses = {
      'bordered-list': true,
      'ps-3': true,
      'mb-3': true,
      clickable: itemName === QUESTIONS
    };

    return (
      <div className="full-width">
        {items && items.length > 0 ? (
          items.map((item, index) => {
            const title = this.showTitle(itemName, item);
            return (
              <FlexRow
                justifyContent="space-between"
                alignItems="baseline"
                className={classnames(rowClasses)}
                key={`${item}-${index}`}
                onClick={e => this.onEntityClick(e, itemName, item, index)}
              >
                <li className="label-text-width text-truncate" title={title}>
                  {title}
                </li>
                <i className="fa fa-times me-3 clickable" onClick={e => this.onRemove(e, itemName, item, index)} />
              </FlexRow>
            );
          })
        ) : (
          <div>{intl.get('app.nodata')}</div>
        )}
      </div>
    );
  };

  onAdd = type => {
    switch (type) {
      case SESSION:
        this.toggleModal(SHOW_SESSION_MODAL);
        break;
      case QUESTIONS:
        this.toggleQuestionModal();
        break;
      case SEGMENTS:
        this.toggleModal(SHOW_SEGMENT_MODAL);
        break;
      case NORMS:
        this.toggleModal(SHOW_NORM_MODAL);
        break;
      default:
    }
  };

  goBack = () => {
    this.props.navigate(`${appConfig.reportsPagePath}/custom`);
  };

  saveConfig = () => {
    this.props.saveCustomReportConfig(this.state.config);
  };

  checkUpdates = () => {
    return !isEqual(this.state.config, this.props.customReportConfig.customReportConfig);
  };

  render() {
    const { config, selectedSessions } = this.state;
    const { sessionIds, questions } = config;
    const { customReportConfig, allSessionsRequested } = this.props;

    if (customReportConfig.customReportConfigRequested || allSessionsRequested) {
      return <Loader spinner fullScreen />;
    }

    const isDirty = this.checkUpdates();
    return (
      <div className="custom-report-config">
        {customReportConfig.customReportConsolidatedDataRequested && <Loader spinner fullScreen />}
        <FormGroup>
          <div className="link" style={{ marginBottom: '1rem' }} onClick={this.goBack}>
            <i className="fas fa-arrow-alt-circle-left" /> {intl.get('app.backToConfigList')}
          </div>
          <Button
            onClick={this.onGenerateReport}
            disabled={!sessionIds || !questions || sessionIds.length <= 0 || questions.length <= 0}
          >
            {intl.get('app.generateReport')}
          </Button>
          <FlexRow justifyContent="space-between" alignItems="flex-start" className="mt-5">
            <FormGroup className="column3 forms p-3">
              <div className="label-text mb-2">{intl.get('app.sessions')}</div>
              <div className="content-column-height mb-2">{this.showItems(SESSION_IDS)}</div>
              <div>
                <Button onClick={() => this.onAdd(SESSION)} disabled={this.props.sessions.length <= 0}>
                  {intl.get('app.add')}
                </Button>
              </div>
            </FormGroup>
            <FormGroup className="column3 forms p-3">
              <div className="label-text mb-2">{intl.get('app.reportData')}</div>
              <div className="content-column-height mb-1">{this.showItems(QUESTIONS)}</div>
              <div>
                <Button
                  onClick={() => this.onAdd(QUESTIONS)}
                  disabled={!(selectedSessions && selectedSessions.length > 0)}
                >
                  {intl.get('app.add')}
                </Button>
              </div>
            </FormGroup>
            <FormGroup className="column3 forms p-3">
              <div className="label-text mb-2">{intl.get('app.segmentsOptional')}</div>
              <div className="content-column-height mb-2">{this.showItems(SEGMENTS)}</div>
              <div>
                <Button
                  onClick={() => this.onAdd(SEGMENTS)}
                  disabled={!(selectedSessions && selectedSessions.length > 0)}
                >
                  {intl.get('app.add')}
                </Button>
              </div>
            </FormGroup>
            <FormGroup className="column3 forms p-3">
              <div className="label-text mb-2">{intl.get('app.externalNormsOptional')}</div>
              <div className="ms-5 form-label">
                {intl.get('app.status') + ': '}
                {config.normsConfigured ? intl.get('app.configured') : intl.get('app.notConfigured')}
              </div>
              <div>
                <Button
                  className="mt-2"
                  onClick={() => this.onAdd(NORMS)}
                  disabled={!(selectedSessions && selectedSessions.length > 0 && questions && questions.length > 0)}
                >
                  {intl.get('app.configure')}
                </Button>
              </div>
            </FormGroup>
          </FlexRow>
        </FormGroup>
        <div>
          <Button color="primary" onClick={this.saveConfig} disabled={!isDirty}>
            {intl.get('app.saveConfiguration')}
          </Button>
        </div>
        <div>
          <Button className="link-button mt-4" onClick={this.goBack}>
            {intl.get('app.cancel')}
          </Button>
        </div>
        {this.state.showSessionModal && (
          <ReportSessionsPickerModal
            sessions={this.props.sessions}
            showModal={this.state.showSessionModal}
            toggle={() => this.toggleModal(SHOW_SESSION_MODAL)}
            targetKeys={sessionIds}
            saveSessions={this.saveSessions}
          />
        )}
        {this.state.showQuestionModal && (
          <ReportAddDataColumnModal
            questionConfig={this.state.questionForEdit}
            toggle={this.toggleQuestionModal}
            consolidatedData={customReportConfig.customReportConsolidatedData}
            sessions={this.props.sessions}
            categories={questions.filter(q => q.category).map(q => q.category)}
            saveQuestion={this.saveQuestion}
          />
        )}
        {this.state.showSegmentModal && (
          <ReportAddSegmentModal
            toggle={() => this.toggleModal(SHOW_SEGMENT_MODAL)}
            consolidatedData={customReportConfig.customReportConsolidatedData}
            sessions={this.props.sessions}
            saveSegment={this.saveSegment}
          />
        )}
        {this.state.showNormModal && (
          <ReportConfigNormsModal
            toggle={() => this.toggleModal(SHOW_NORM_MODAL)}
            questions={questions.toSorted((a, b) => {
              const categoryA = a.category || intl.get('app.uncategorized');
              const categoryB = b.category || intl.get('app.uncategorized');
              return categoryA > categoryB ? 1 : -1;
            })}
            saveNorms={this.saveNorms}
          />
        )}
      </div>
    );
  }
}

export default ReportConfig;
