import React from 'react';
import QuestionList from '../questionList';

class QuestionLibrary extends React.Component {
  render() {
    return (
      <QuestionList
        clickToSelect={this.props.clickToSelect}
        enablePreview={this.props.enablePreview}
        showSurveyTypeColumn={this.props.showSurveyTypeColumn}
        surveyType={this.props.surveyType}
      />
    );
  }
}
export default QuestionLibrary;
