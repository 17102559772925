import React from 'react';
import intl from 'react-intl-universal';
import localtime from '../../../util/localtime';
import { surveyUtil } from '../../../util/surveyUtil';
import { RECRUIT_TAB, AUTO_ENROLL_TAB } from '../../../util/projectUtil';
import { TranslateLabel } from '../../surveyBuilder/editor/editForms/edit/TranslateLabel';
import { ParticipantCountsWidget } from '../../researchDashboard/rdStatusBar/participantCounts/ParticipantCountsWidget';

import './ProjectStatusBar.css';

function getTimestamp(date) {
  return localtime.getFormattedDateCap(date);
}

function getSessionTimeStamp(session) {
  let label;
  let timestamp;
  if (session.state === 'CLOSED') {
    label = `${intl.get('app.session.state.CLOSED').toLowerCase()}:`;
    timestamp = getTimestamp(session.endDate);
  } else if (session.startDate) {
    label = `${intl.get('app.start').toLowerCase()}:`;
    timestamp = getTimestamp(session.startDate);
  }
  if (label && timestamp) {
    return (
      <div className="session-timestamp">
        <div>{label}</div>
        <div>{timestamp}</div>
      </div>
    );
  }
  return null;
}

export const ProjectStatusBar = props => {
  const { project, projectName, configName, routeParams, session, survey, viewLanguage, setViewLanguage } = props;
  const { participantCollectionJobs } = project || {};
  const { tab, subtab } = routeParams;

  function getCollectionName() {
    if (tab === RECRUIT_TAB && participantCollectionJobs) {
      const collectionId = subtab;
      const selectedJob = participantCollectionJobs.content.find(job => job.collection.id === collectionId);
      return selectedJob?.collection.name;
    }
    if (tab === AUTO_ENROLL_TAB) {
      return configName;
    }
  }

  const collectionName = getCollectionName();

  return (
    <div className="project-status-bar">
      <div className="project-name">
        {intl.get('app.project')}: {projectName}
      </div>
      <div style={{ width: '100%' }}>
        {session && (
          <div className="session-status">
            <div>
              <span style={{ paddingRight: '2rem' }}>
                {session.screener ? intl.get('app.screener') : intl.get('app.survey')}: {session.name}
              </span>
              {session.state && (
                <span style={{ color: 'white' }}>{intl.get(`app.session.state.${session.state}`).toUpperCase()}</span>
              )}
            </div>
            <TranslateLabel
              setViewLanguage={setViewLanguage}
              language={surveyUtil.getConfiguredLanguage(survey)}
              viewLanguage={viewLanguage}
              sessionId={session.id}
            />
            <div style={{ margin: 'auto' }}>
              <ParticipantCountsWidget sessionId={session.id} session={session} />
            </div>
            {getSessionTimeStamp(session)}
          </div>
        )}
        {collectionName && (
          <strong>
            {tab === RECRUIT_TAB ? intl.get('app.list') : intl.get('app.autoEnroll')}: {collectionName}
          </strong>
        )}
      </div>
    </div>
  );
};
