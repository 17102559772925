import { ActiveProjectsPage } from './ActiveProjectsPage';
import { connect } from 'react-redux';
import { fetchProjectListActions } from '../../../../store/redux/actions/projectListActions';
import { sessionUserSelector } from '../../../../store/redux/selectors/sessionUserSelector';
import { projectListSelector } from '../../../../store/redux/selectors/projectListSelector';

const mapStateToProps = (state, props) => {
  const { projectId } = props;
  const listSelector = projectListSelector(state);
  const { abilities, sessionTenant, sessionUser } = sessionUserSelector(state);
  return {
    projectList: listSelector,
    project: (state.projects !== undefined && projectId && state.projects[projectId]) || undefined,
    baseUrl: '',
    sessionTenant,
    sessionUser,
    hasProjectManage: abilities.hasProjectManage
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProjects: params => dispatch(fetchProjectListActions.request(params))
});
export default connect(mapStateToProps, mapDispatchToProps)(ActiveProjectsPage);
