import React, { useEffect, useState } from 'react';
import { accessHandler, Loader } from 'webapp-common';
import { NEWPROJECT, updateRecentProjects } from '../../../util/projectUtil';
import { ProjectDetails } from './ProjectDetails';
import { ProjectDetailsSS } from '../../selfServe/project/details/ProjectDetailsSS';

/**
 * Encapsulates the logic for showing either the full-serve or self-serve ProjectDetails component.
 * Also contains anything common to both components.
 */
export const ProjectDetailsWrapper = props => {
  const {
    project = {},
    sessionUser,
    hasProjectManage,
    hasProjectView,
    editMode,
    updateProjectName,
    saveProject,
    archiveProject
  } = props;

  const { projectDetails } = project;
  const projectId = projectDetails?.id;
  const readOnly = !hasProjectManage;

  const [showLoader, setShowLoader] = useState();

  useEffect(() => {
    if (projectId && projectId !== NEWPROJECT) {
      updateRecentProjects(projectDetails, sessionUser.clientId);
    }
  }, [projectId]);

  const accessCheck = accessHandler.checkAccess(hasProjectView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  const isSelfServe = projectDetails?.selfServe;
  const isFullServe = editMode === false || (projectDetails && !projectDetails.selfServe);

  return (
    <section className="body-container l2-bg">
      {showLoader && <Loader spinner fullScreen />}
      {isSelfServe && <ProjectDetailsSS projectId={projectId} readOnly={readOnly} showLoader={setShowLoader} />}
      {isFullServe && (
        <ProjectDetails
          project={project}
          sessionUser={sessionUser}
          editMode={editMode}
          readOnly={readOnly}
          showLoader={setShowLoader}
          updateProjectName={updateProjectName}
          saveProject={saveProject}
          archiveProject={archiveProject}
        />
      )}
    </section>
  );
};
