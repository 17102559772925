import React, { useReducer, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import { isEqual, get } from 'lodash';
import { clusterAnalysisSubscribeActions } from '../../../../../../store/redux/actions/clusterAnalysisActions';
import {
  toggleFavoriteParticipantAction,
  openChatWindowAction
} from '../../../../../../store/redux/actions/researchDashboardActions';
import { setParticipantGroupActions } from '../../../../../../store/redux/actions/filtersAndParticipantsActions';
import { jsUtil } from '../../../../../../util/jsUtil';
import { useWordclouds } from '../../../../../../customHooks/useWordclouds';
import { Icons } from '../../../../../../components/icons/Icons';
import { ViewSelector } from '../viewSelector/ViewSelector';
import { ClusterAnalysisSettingsModal } from './ClusterAnalysisSettingsModal';
import { RDParticipantDetailsModal } from '../../../../rdParticipantDetails/RDParticipantDetailsModal';
import { CreateFilterModal } from './CreateFilterModal';
import { ENGLISH } from '../../../../../../util/joinerUtil';
import { PARTICIPANT_FLAG_COLORS } from '../../../../../../util/researchDashboardUtil';
import { Loader } from 'webapp-common';
import chatUtil from '../../../../../../components/chat/chatUtil';
import { SentimentPie } from './SentimentPie';

import './OpenQuestionClusterAnalysis.css';

const reducer = (state, payload) => ({ ...state, ...payload });

const views = {
  wordcloud: 'wordcloud',
  participants: 'participants',
  emotion: 'emotion'
};

const CLOUD_WIDTH = 200;
const CLOUD_HEIGHT = 100;
const ICONS_WIDTH = 60;
const WORDCLOUD_DELAY = 100;
const WORDCLOUD_ID_PREFIX = 'wordcloud_';
const DEFAULT = 'gray';

const wordcloudOptions = {
  fontSizes: [10, 32],
  padding: 0,
  width: CLOUD_WIDTH,
  height: CLOUD_HEIGHT
};

const getParticipantsSubtotal = (filtersAndParticipants, answers) => {
  const { filters, filteredParticipants } = filtersAndParticipants;
  const participantIds = answers.map(a => a.participantId);
  return filters.map(filter => {
    const count = filteredParticipants[filter.name].filter(id => participantIds.includes(id)).length;
    return (
      <div style={{ lineHeight: 1, marginBottom: '0.25rem' }}>
        <div className="cluster-filter-label inline-block" title={filter.name}>
          {filter.name}
        </div>
        <div className="inline-block">{`: ${count}`}</div>
      </div>
    );
  });
};

const getNumberOfClustersToDisplay = (analysisConfig, total) => {
  const { displayed, displayedOverride = 1 } = analysisConfig || {};
  if (displayed === 'FIXED') {
    return Math.max(displayedOverride, 1);
  }
  return total;
};

const calculateClusterSentiment = clusters => {
  clusters.forEach(cluster => {
    const { answers } = cluster;
    const total = answers.length;
    let positive = 0;
    let neutral = 0;
    let negative = 0;
    answers.forEach(answer => {
      switch (answer.sentiment) {
        case 'POSITIVE':
          positive++;
          break;
        case 'NEUTRAL':
          neutral++;
          break;
        default:
          negative++;
      }
    });
    cluster.sentiment = {
      positive: Math.round((positive / total) * 100),
      neutral: Math.round((neutral / total) * 100),
      negative: Math.round((negative / total) * 100)
    };
  });
};

export const OpenQuestionClusterAnalysis = props => {
  const {
    clusterAnalysis,
    enrolleesInfo,
    sessionId,
    questionJoiner,
    rdConfig,
    totalAnswers,
    viewLanguage,
    chats,
    userId
  } = props;
  const { clusterAnalyses, filtersAndParticipants } = clusterAnalysis || {};

  const clusterAnalysisConfig = get(
    rdConfig,
    `configs.questionsConfig.questionsConfigMap[${questionJoiner.def.id}].clusterAnalysisConfig`,
    null
  );

  const [state, setState] = useReducer(reducer, {
    view: views.wordcloud,
    showClusterAnalysisSettingsModal: false,
    createFilterModal: null,
    totalAnswers,
    clusterAnalysisConfig: null,
    wordcloudData: [],
    participantDetailsModal: false
  });

  const isWordcloudView = state.view === views.wordcloud;
  const dispatch = useDispatch();

  const subscribeClusterAnalysis = () => {
    dispatch(
      clusterAnalysisSubscribeActions.request({
        subAction: 'subscribe',
        sessionId,
        questionJoinerId: questionJoiner.id,
        questionDefId: questionJoiner.def.id,
        rdConfig
      })
    );
  };

  useEffect(() => {
    subscribeClusterAnalysis();
    return () => {
      // Unsub from the channel
      dispatch(
        clusterAnalysisSubscribeActions.request({
          subAction: 'unsubscribe',
          sessionId,
          questionJoinerId: questionJoiner.id,
          questionDefId: questionJoiner.def.id,
          rdConfig
        })
      );
    };
  }, [rdConfig.configs.filterListConfig.selected, clusterAnalysisConfig]);

  useEffect(() => {
    setState({ numNewAnswers: totalAnswers - state.totalAnswers });
  }, [totalAnswers]);

  useEffect(() => {
    if (clusterAnalyses && totalAnswers === state.totalAnswers) {
      setState({ clusterAnalyses, showSpinner: false });
    }
  }, [clusterAnalyses, state.totalAnswers]);

  useEffect(() => {
    if (state.group && state.group.participantId) {
      const enrollee = get(enrolleesInfo, `enrolleeMap.${state.group.participantId}`, null);
      if (enrollee) {
        setState({
          group: {
            ...state.group,
            participantGroup: enrollee.participantGroup
          }
        });
      }
    }
  }, [enrolleesInfo]);

  useWordclouds({
    idPrefix: WORDCLOUD_ID_PREFIX,
    keywords: state.wordcloudData,
    options: wordcloudOptions,
    delay: WORDCLOUD_DELAY,
    render: isWordcloudView
  });

  const participant =
    (state.group && enrolleesInfo.enrolleeMap && enrolleesInfo.enrolleeMap[state.group.participantId]) || null;
  const isInvited = !!participant && participant.enrolleeStatus === 'Invited';
  const genericChat =
    participant &&
    chats.find(chat => !chat.questionJoinerId && chat.participantIds.indexOf(participant.participantId) > -1);
  const hasMessage = genericChat && genericChat.messages.length > 0;
  const chattable = chatUtil.isActiveParticipant(participant);

  function onParticipantsIconClick() {
    setState({ view: views.participants, group: null });
  }

  function onWordCloudIconClick() {
    setState({ view: views.wordcloud, group: null });
  }

  const onEmotionsIconClick = () => setState({ view: views.emotion, group: null });

  function toggleClusterAnalysisSettingsModal({ showSpinner }) {
    setState({ showClusterAnalysisSettingsModal: !state.showClusterAnalysisSettingsModal, showSpinner: !!showSpinner });
  }

  function toggleCreateFilterModal(answers) {
    if (answers) {
      const participantIds = answers.map(answer => answer.participantId);
      setState({ createFilterModal: { participantIds } });
    } else {
      setState({ createFilterModal: null });
    }
  }

  const toggleParticipantDetailsModal = () => {
    setState({ participantDetailsModal: !state.participantDetailsModal });
  };

  const getParticipantDetails = (participantId, groupId) => {
    const enrollee = get(enrolleesInfo, `enrolleeMap.${participantId}`, {});
    const { nickname, favorite, participantGroup } = enrollee;
    if (state.group && state.group.groupId === groupId && state.group.participantId === participantId) {
      setState({ group: null, selected: false });
    } else {
      setState({
        group: { groupId, participantId, participantGroup, nickname, favorite },
        selected: true
      });
    }
  };

  const getResponseSummary = (answers, sentiment, index) => {
    switch (state.view) {
      case views.emotion:
        return (
          <div style={{ textAlign: 'center' }}>
            <SentimentPie sentiment={sentiment} elem="select" />
          </div>
        );
      case views.wordcloud:
        return <span id={`${WORDCLOUD_ID_PREFIX}${index}`}></span>;
      default:
        return (
          <div style={{ maxHeight: 'inherit', overflowY: 'auto', width: CLOUD_WIDTH }}>
            <div style={{ marginBottom: '0.25rem' }}>
              <strong>{intl.get('app.filterSubtotals')}:</strong>
            </div>
            {getParticipantsSubtotal(filtersAndParticipants, answers)}
          </div>
        );
    }
  };

  const updateFavoriteParticipant = () => {
    const { participantId, favorite } = state.group;
    dispatch(
      toggleFavoriteParticipantAction.request({
        sessionId,
        participantId,
        favorite
      })
    );
    setState({
      group: {
        ...state.group,
        favorite: !favorite
      }
    });
  };

  const getFiltersForParticipant = () => {
    const { filters, filteredParticipants } = filtersAndParticipants;
    return filters
      .filter(f => filteredParticipants[f.name].includes(state.group.participantId))
      .map(f => <li>{f.name}</li>);
  };

  const onFlagClick = group => {
    dispatch(
      setParticipantGroupActions.request({
        sessionId,
        participantId: state.group.participantId,
        group
      })
    );
  };

  const openChatWindow = () => {
    const { nickname, participantId } = participant;
    const chatPayload = {
      chatTitle: nickname || participantId,
      participantId,
      researcherId: userId,
      sessionId
    };
    dispatch(openChatWindowAction.request(chatPayload));
  };

  const getResponseDetails = () => {
    const flag = PARTICIPANT_FLAG_COLORS[state.group.participantGroup] || DEFAULT;
    return (
      <div className="selected-box-height" style={{ height: '100%' }}>
        <div className="fw-600 selected-details" style={{ width: CLOUD_WIDTH }}>
          <div>{intl.get('app.selected')}:</div>
          <div style={{ display: 'flex', marginRight: '1rem', height: '1rem', width: ICONS_WIDTH }}>
            {isInvited ? (
              <Icons.ChatIcon className="chat-icon me-3 disabled" />
            ) : (
              <Icons.ChatIcon
                title={hasMessage || chattable ? '' : intl.get('app.noChat')}
                className={`chat-icon me-3 ${hasMessage ? 'existing-chat' : ''} ${
                  hasMessage || chattable ? '' : 'disabled'
                }`}
                onClick={() => `${hasMessage || chattable ? openChatWindow() : ''}`}
              />
            )}
            {isInvited ? (
              <Icons.StarIcon className="favorite-icon me-4 disabled" />
            ) : (
              <Icons.StarIcon
                className={`favorite-icon me-4 ${state.group.favorite ? 'favorite' : ''}`}
                onClick={updateFavoriteParticipant}
              />
            )}
            <div>
              {isInvited ? (
                <Icons.FlagIcon className="flag-icon disabled" />
              ) : (
                <Icons.FlagsDropdown
                  id={state.group.participantId}
                  flagClasses={flag}
                  title={
                    flag === DEFAULT
                      ? intl.get('app.setFlags')
                      : intl.get(`app.participantFlag.${state.group.participantGroup}.color`)
                  }
                  colorProp={PARTICIPANT_FLAG_COLORS}
                  showBan={true}
                  onFlagClick={group => onFlagClick(group)}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="cluster-filter-label" title={state.group.nickname}>
            {intl.get('app.nickname')}:
            <div className="nickname" onClick={toggleParticipantDetailsModal}>
              {state.group.nickname}
            </div>
          </div>
        </div>
        <div>{getFiltersForParticipant()}</div>
      </div>
    );
  };

  const getAnswers = (answers, groupId) =>
    answers.map((answer, i) => {
      const isSelected =
        state.group &&
        state.group.groupId === groupId &&
        state.group.participantId === answer.participantId &&
        state.selected;
      return (
        <div
          onClick={() => getParticipantDetails(answer.participantId, groupId)}
          style={{ cursor: 'pointer' }}
          className={isSelected && 'selected-row'}
        >
          <strong>{`${i + 1}: `}</strong>
          {viewLanguage === ENGLISH ? answer.answer.value : answer.answer.origValue}
        </div>
      );
    });

  const getClusters = () => {
    const sortedData = state.clusterAnalyses.sort((a, b) => b.answers.length - a.answers.length);

    // Move noise cluster to the end (if it exists [HDBSCAN]):
    const noiseCluster = sortedData.filter(a => a.label === '0')[0];
    if (noiseCluster) {
      const index = sortedData.indexOf(noiseCluster);
      if (index !== sortedData.length - 1) {
        // If not already at the end
        sortedData.splice(index, 1);
        sortedData.push(noiseCluster);
      }
    }

    const override = getNumberOfClustersToDisplay(clusterAnalysisConfig, sortedData.length);
    if (state.view === views.emotion) {
      calculateClusterSentiment(sortedData);
    }

    const wordcloudData = [];

    const clusters = sortedData.map((group, index) => {
      const { answers, keywords, sentiment } = group;
      if (isWordcloudView) {
        wordcloudData.push(keywords.slice(0, 8));
      }
      const isNoiseCluster = group.label === '0';
      return index < override && answers.length > 0 ? (
        <div className="response-cluster-row">
          <div style={{ minWidth: '9%', maxWidth: '9%' }}>
            <div className="fw-600">
              {isNoiseCluster ? intl.get('app.miscNoGroup') : `${intl.get('app.group')} ${index + 1}`}
            </div>
            <div className="nowrap">{`${answers.length} Responses`}</div>
            <div
              style={{ color: 'var(--gray-500)', cursor: 'pointer', width: 'fit-content' }}
              onClick={() => toggleCreateFilterModal(answers)}
            >
              +<i className="fas fa-filter" />
            </div>
          </div>
          {state.group && state.group.groupId === index ? (
            <div style={{ maxHeight: 'inherit' }}> {getResponseDetails()} </div>
          ) : (
            <div style={{ maxHeight: 'inherit' }}> {getResponseSummary(answers, sentiment, index)} </div>
          )}
          <div style={{ width: '100%' }}>{getAnswers(answers, index)}</div>
        </div>
      ) : null;
    });

    if (isWordcloudView && !isEqual(wordcloudData, state.wordcloudData)) {
      setState({ wordcloudData });
    }

    return clusters;
  };

  const offsetHeight = jsUtil.getRdQuestionDetailsHeaderHeight();

  const recalculateClusters = () => {
    setState({ numNewAnswers: 0, totalAnswers });
  };

  return (
    <div className="response-clusters" style={{ overflowY: 'auto', height: `calc(100% - ${offsetHeight}px)` }}>
      <div className="cluster-header">
        <ViewSelector view={props.view} setView={props.setView} />
        <div className="view-icons">
          <div className={classnames({ selected: state.view === views.emotion })}>
            <Icons.EmotionsIcon className="emotions clickable" onClick={onEmotionsIconClick} />
          </div>
          <div className={classnames({ selected: state.view === views.participants })}>
            <Icons.UserFriendsIcon className="clickable" onClick={onParticipantsIconClick} />
          </div>
          <div className={classnames({ selected: state.view === views.wordcloud })}>
            <Icons.WordCloudIcon onClick={onWordCloudIconClick} />
          </div>
          <div>
            <Icons.CogIcon className="clickable" onClick={toggleClusterAnalysisSettingsModal} />
          </div>
        </div>
      </div>
      <div style={{ overflowY: 'auto' }}>
        {state.totalAnswers === 0 && (
          <div style={{ fontSize: '1.5rem', marginTop: '2rem', marginLeft: '2rem' }}>
            {intl.get('app.noAnwer.message')}.
          </div>
        )}
        {state.totalAnswers > 0 && state.clusterAnalyses && getClusters()}
        {state.numNewAnswers > 0 && (
          <div className="show-new-responses">
            <button className="link-button" onClick={recalculateClusters}>
              {intl.get('app.showNewResponses', { num: state.numNewAnswers })}.
            </button>
          </div>
        )}
      </div>
      {state.showClusterAnalysisSettingsModal && (
        <ClusterAnalysisSettingsModal
          rdConfig={rdConfig}
          questionJoiner={questionJoiner}
          quotaFilters={props.quotaFilters}
          hasProjectManage={props.hasProjectManage}
          toggle={toggleClusterAnalysisSettingsModal}
        />
      )}
      {state.createFilterModal && (
        <CreateFilterModal
          participantIds={state.createFilterModal.participantIds}
          enrolleeMap={enrolleesInfo.enrolleeMap}
          rdConfig={rdConfig}
          toggle={() => toggleCreateFilterModal(null)}
        />
      )}
      {state.participantDetailsModal && (
        <RDParticipantDetailsModal
          sessionId={sessionId}
          participant={participant}
          toggle={toggleParticipantDetailsModal}
          setViewLanguage={props.setViewLanguage}
          language={props.language}
          viewLanguage={viewLanguage}
        />
      )}
      {state.showSpinner && <Loader spinner fullScreen />}
    </div>
  );
};
