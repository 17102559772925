import createFetchActions from './createFetchActions';

const createDataTableActions = createFetchActions('CREATE_DATA_TABLE');
const fetchDataTableListAction = createFetchActions('FETCH_DATA_TABLE_LIST');
const fetchAllDataTablesActions = createFetchActions('FETCH_ALL_DATA_TABLES');
const fetchDataTableActions = createFetchActions('FETCH_DATA_TABLE');
const fetchDataTableRecordsAction = createFetchActions('FETCH_DATA_TABLE_RECORDS');
const deleteDataTableAction = createFetchActions('DELETE_DATA_TABLE');
const replaceDataTableActions = createFetchActions('REPLACE_DATA_TABLE');

export {
  createDataTableActions,
  fetchDataTableListAction,
  fetchAllDataTablesActions,
  fetchDataTableActions,
  fetchDataTableRecordsAction,
  deleteDataTableAction,
  replaceDataTableActions
};
