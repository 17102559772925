import React, { useEffect, useRef } from 'react';

import './ComboDropdown.css';

export const ComboDropdown = props => {
  const comboRef = useRef();
  const { open, close, toggle, target, message, getOptions } = props;

  useEffect(() => {
    const handleClickOutside = event => {
      event.stopPropagation();
      if (comboRef.current && !comboRef.current.contains(event.target) && comboRef.current.className !== 'hidden') {
        close(target);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [comboRef]);

  return (
    <div className="combo-select">
      <div className={open ? 'select-positions open' : 'select-positions'} onClick={() => toggle(target)}>
        <span>{message}</span>
        <i className="fas fa-chevron-down" style={{ fontSize: '0.6rem' }} />
      </div>
      <div className={open ? 'position-labels' : 'hidden'} ref={comboRef}>
        {getOptions()}
      </div>
    </div>
  );
};
