import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep, find, get } from 'lodash';
import { Input, Button, Row } from 'reactstrap';
import {
  RESPONSE_SET_TYPE,
  STIM_TYPE,
  OPTION_TYPE,
  REQUIRED_OPTION,
  getJoinerIcon,
  getJoinerType,
  getTotalTiming,
  cleanJoinerTitle,
  questionJoinerGenerator,
  getQuestionPrompt,
  getTextStimContents,
  getDefaultVideoOptions
} from '../../../../util/joinerUtil';
import { isOpen } from '../../../../util/sessionUtil';
import { mediaUtil } from '../../../../util/mediaUtil';
import { surveyUtil } from '../../../../util/surveyUtil';
import { idUtils } from '../../../../util/Id';
import { jsUtil } from '../../../../util/jsUtil';
import { InvokeModal, Loader } from 'webapp-common';
import JoinerPreviewModal from '../../../../components/joinerPreview/JoinerPreviewModal';
import { ReadOnlyBanner } from '../../../../components/readOnlyBanner/ReadOnlyBanner';
import { ExportSurveyModal } from './ExportSurveyModal';
import { SelectSurveyModal } from './SelectSurveyModal';
import AddToSurveyModal from '../../../surveyBuilder/editor/modals/addToSurvey/AddToSurveyModal';
import SelectQuestionModal from '../../../surveyBuilder/editor/modals/selectQuestion/SelectQuestionModal';
import { ImportSurveyModal } from '../../../../containers/sessionDetails/components/ImportSurveyModal';
import { JoinerEditorModal } from '../../../surveyBuilder/editor/joinerEditor';
import SelectMediaModal from '../../../surveyBuilder/editor/modals/selectMedia/SelectMediaModal';
import { SelectMediaPopover } from '../../../surveyBuilder/editor/modals/selectMedia/SelectMediaPopover';
import { ConfirmJoinerRemove } from './ConfirmJoinerRemove';
import { CollapsedQuestionJoiner } from './CollapsedQuestionJoiner';
import { AddFirstQuestion } from './AddFirstQuestion';
import Functions from '../../../functions';
import DataTableList from '../../../dataTableList';
import { Icons } from '../../../../components/icons/Icons';

import './SurveyEditor.css';

const getConcepts = (joiners, id) => {
  const conceptRotation = find(joiners, { id }).conceptRotation;
  return conceptRotation.concepts;
};

const reducer = (state, payload) => ({ ...state, ...payload });

export const SurveyEditor = props => {
  const {
    survey,
    readOnly,
    session,
    projectId,
    isScreener,
    isTemplate,
    translationInProgress,
    cannotSaveTemplate,
    viewLanguage
  } = props;

  const language = surveyUtil.getConfiguredLanguage(survey);

  const [state, setState] = useReducer(reducer, {
    highlightedJoinerId: '',
    editingJoinerInfo: undefined,
    showAddToSurveyModal: false,
    showSelectQuestionModal: false,
    showJoinerEditorModal: false,
    selectMediaPopover: null,
    showMediaLibrary: false,
    showJoinerPreviewModal: false,
    showConfirmRemoveJoinerModal: false,
    showDeleteSurveyConfirmation: false,
    insertOrEditAction: null,
    addAtPosition: null,
    addAtParentJoinerIndex: null,
    addAtConceptRotationId: null,
    addAtContainerId: null,
    addAtQuestionNum: null,
    name: null,
    surveyTemplateName: ''
  });

  /*
   * Handles subscribe/unsub for the survey-import channel, but only if we are editing a survey for a session.
   */
  const surveyImportChannelSubscribe = subAction => {
    if (session) {
      props.surveyImportChannelSubscribe({
        subAction,
        sessionId: session.id
      });
    }
  };

  //
  // Toggle modal handlers start
  //
  const toggleAddToSurveyModal = () => {
    setState({
      insertOrEditAction: 'INSERT',
      showAddToSurveyModal: !state.showAddToSurveyModal
    });
  };

  const toggleSelectQuestionModal = () => {
    setState({
      showSelectQuestionModal: !state.showSelectQuestionModal
    });
  };

  const toggleJoinerEditorModal = joinerInfo => {
    if (props.saveQuestionJoinerFailed) {
      props.fetchSurvey(survey.id);
    }
    setState({
      editingJoinerInfo: joinerInfo,
      showJoinerEditorModal: !state.showJoinerEditorModal
    });
  };

  const toggleSelectMediaPopover = (e, joiner) => {
    e && e.stopPropagation();
    const setJoiner = joiner && joiner.id !== get(state, 'selectMediaPopover.joiner.id');
    setState({ selectMediaPopover: setJoiner ? { joiner } : null });
  };

  const toggleConfirmRemoveJoinerModal = joiner => {
    setState({
      joinerForDelete: joiner,
      showConfirmRemoveJoinerModal: !state.showConfirmRemoveJoinerModal
    });
  };

  const toggleMediaLibrary = () => {
    setState({ showMediaLibrary: !state.showMediaLibrary });
  };

  const toggleJoinerPreviewModal = joinerId => {
    setState({
      previewJoinerId: joinerId,
      showJoinerPreviewModal: !state.showJoinerPreviewModal
    });
  };

  const toggleUseTemplateModal = () => {
    setState({ showUseTemplateModal: !state.showUseTemplateModal });
  };

  const toggleImportSurveyModal = () => {
    setState({ showImportSurveyModal: !state.showImportSurveyModal });
  };

  const toggleDeleteSurveyModal = () => {
    setState({ showDeleteSurveyConfirmation: !state.showDeleteSurveyConfirmation });
  };

  const toggleSurveyExport = () => {
    setState({ showExportSurveyModal: !state.showExportSurveyModal });
  };

  const toggleSaveAsTemplateModal = () => {
    setState({ showSaveAsTemplateModal: !state.showSaveAsTemplateModal });
  };

  const toggleFunctionsModal = () => {
    setState({ showFunctionsModal: !state.showFunctionsModal });
  };

  const toggleDataTableModal = () => {
    setState({ showDataTableModal: !state.showDataTableModal });
  };

  useEffect(() => {
    surveyImportChannelSubscribe('subscribe');
    return () => {
      surveyImportChannelSubscribe('unsubscribe');
    };
  }, []);

  useEffect(() => {
    if (!props.saveQuestionJoinerInProgress && props.saveQuestionJoinerSuccess) {
      if (state.saveAndPreviewJoinerInProgress) {
        // Update the state when saveAndPreview() returns
        setState({
          insertOrEditAction: 'EDIT',
          editingJoinerInfo: getUpdatedJoinerInfo(state.editingJoinerInfo),
          saveAndPreviewJoinerInProgress: false
        });
        toggleJoinerPreviewModal(state.editingJoinerInfo.previewId);
      } else {
        setState({
          highlightedJoinerId: '',
          editingJoinerInfo: undefined,
          showAddToSurveyModal: false,
          showJoinerEditorModal: false,
          showSelectQuestionModal: false,
          showConfirmRemoveJoinerModal: false
        });
      }
    }
  }, [props.saveQuestionJoinerInProgress]);

  useEffect(() => {
    if (!props.deleteSurveyQuestionsInProgress) {
      setState({ showConfirmRemoveJoinerModal: false, showDeleteSurveyConfirmation: false });
    }
  }, [props.deleteSurveyQuestionsInProgress]);

  useEffect(() => {
    if (
      typeof props.saveQuestionJoinerFailed === 'boolean' &&
      !props.saveQuestionJoinerInProgress &&
      !props.saveQuestionJoinerFailed
    ) {
      props.surveyRefreshComplete();
    }
  }, [props.saveQuestionJoinerFailed]);

  useEffect(() => {
    if (survey.name) {
      setState({ name: survey.name });
    }
  }, [survey.name]);

  useEffect(() => {
    if (!props.surveyTemplate.saveSurveyAsTemplateRequested) {
      setState({ showSaveAsTemplateModal: false });
    }
  }, [props.surveyTemplate && props.surveyTemplate.saveSurveyAsTemplateRequested]);

  const isReadOnly = () => {
    return readOnly || translationInProgress;
  };

  const saveAsTemplate = () => {
    props.saveSurveyAsTemplate({
      name: state.surveyTemplateName,
      surveyId: survey.id,
      screener: isScreener,
      overwrite: false
    });
  };

  const updateSurveyTemplateName = e => {
    setState({ surveyTemplateName: e.target.value });
  };

  const setHighlightedJoinerId = joinerId => {
    setState({
      highlightedJoinerId: joinerId
    });
  };

  //
  // Click handlers start
  //
  const handleAddToSurveyClick = (e, position, parentJoinerIndex, questionNum) => {
    e.stopPropagation();
    let conceptRotationId;
    let containerId;
    let isVirtualFocusGroup;
    if (parentJoinerIndex >= 0) {
      const parentJoiner = survey.joiners[parentJoinerIndex];
      if (parentJoiner.conceptRotation) {
        conceptRotationId = parentJoiner.id;
      } else if (parentJoiner.container) {
        containerId = parentJoiner.id;
      } else if (parentJoiner.virtualFocusGroupJoiner) {
        containerId = parentJoiner.id;
        isVirtualFocusGroup = true;
      }
    }
    setState({
      addAtPosition: position,
      addAtParentJoinerIndex: parentJoinerIndex,
      addAtConceptRotationId: conceptRotationId,
      addAtContainerId: containerId,
      addAtQuestionNum: questionNum,
      isVirtualFocusGroup
    });
    toggleAddToSurveyModal();
  };

  const handleEditJoinerClick = joinerInfo => {
    setState({
      insertOrEditAction: 'EDIT'
    });
    toggleJoinerEditorModal(joinerInfo);
  };

  // Similar logic as JoinerUpdateHandlers.updateStimMedia()
  const handleMediaUpdate = media => {
    const { joiner } = state.selectMediaPopover;
    joiner.stim = joiner.stim || { active: true };
    joiner.stim.media = media;
    joiner.stim.type = media.type.toLowerCase();
    if (joiner.stim.type === STIM_TYPE.video && !joiner.stim.options) {
      joiner.stim.options = getDefaultVideoOptions();
    }
    props.saveQuestionJoiner({
      action: 'EDIT',
      surveyId: survey.id,
      questionJoiner: joiner,
      version: survey.version,
      sessionId: session ? session.id : '',
      projectId
    });
    toggleSelectMediaPopover();
  };

  const handleDeleteJoinerClick = (e, joiner) => {
    e.stopPropagation();
    toggleConfirmRemoveJoinerModal(joiner);
  };

  const handleMoveJoinerClick = (e, questionJoiner, position) => {
    e.stopPropagation();
    const { joiners } = survey;
    let numJoiners = joiners.length;
    if (questionJoiner.conceptRotationId) {
      const crJoiner = find(joiners, j => j.id === questionJoiner.conceptRotationId);
      numJoiners = get(crJoiner, 'conceptRotation.concepts[0].joiners.length', 0);
    } else {
      const targetJoiner = joiners[position];
      if (targetJoiner && targetJoiner.emailCollector && !questionJoiner.containerId) {
        // Can't move a joiner if the target is the emailCollector joiner
        return;
      }
    }
    if (position >= 0 && position < numJoiners) {
      props.saveQuestionJoiner({
        action: 'MOVE',
        surveyId: survey.id,
        questionJoiner,
        position,
        version: survey.version,
        sessionId: session ? session.id : '',
        projectId
      });
    }
  };

  const handlePreviewJoinerClick = e => {
    e.stopPropagation();
    toggleJoinerPreviewModal(e.target.dataset.joinerPreviewId);
  };

  const handleQuestionLibraryClick = () => {
    toggleSelectQuestionModal();
  };

  //
  // Save/delete/move/etc handlers begin
  //
  const saveJoiner = ({ joiner, toLanguage, conceptVisibility = [] }) => {
    const conceptVisibilityMap = conceptVisibility.reduce((map, obj) => {
      map[obj.id] = !obj.hidden;
      return map;
    }, {});
    props.saveQuestionJoiner({
      action: state.insertOrEditAction,
      surveyId: survey.id,
      questionJoiner: joiner,
      conceptVisibility: conceptVisibilityMap,
      position: state.addAtPosition,
      version: survey.version,
      sessionId: session ? session.id : '',
      projectId,
      toLanguage
    });
  };

  const insertJoinerFromLibrary = joiner => {
    joiner.id = idUtils.getId();
    const { def } = joiner;
    if (def) {
      def.id = idUtils.getId();
      const { responseSet } = def;
      if (responseSet) {
        responseSet.id = idUtils.getId();
        const isJoinerRequired = responseSet.options.some(opt => opt.name === OPTION_TYPE.requiredoption);
        if (survey.surveyOptions && survey.surveyOptions.allQuestionsRequired && !isJoinerRequired) {
          responseSet.options.push(REQUIRED_OPTION);
        }
      }
    }

    if (joiner.stim) {
      joiner.stim.id = idUtils.getId();
    }
    joiner.conceptRotationId = state.addAtConceptRotationId;
    joiner.conceptId = null;
    joiner.parentId = null;
    joiner.containerId = state.addAtContainerId;
    joiner.displayIndex = state.addAtPosition;
    joiner.researchPrompt = cleanJoinerTitle(joiner.researchPrompt);
    const joinerInfo = getJoinerInfo(joiner, state.addAtQuestionNum);
    toggleJoinerEditorModal(joinerInfo);
  };

  const saveAndPreviewJoiner = (joiner, conceptVisibility, previewId) => {
    setState({
      saveAndPreviewJoinerInProgress: true,
      editingJoinerInfo: previewId
        ? {
            ...state.editingJoinerInfo,
            previewId
          }
        : state.editingJoinerInfo
    });
    saveJoiner({ joiner, conceptVisibility });
  };

  const deleteJoiner = () => {
    props.saveQuestionJoiner({
      action: 'DELETE',
      surveyId: survey.id,
      questionJoiner: state.joinerForDelete,
      version: survey.version,
      sessionId: session ? session.id : '',
      projectId
    });
  };

  //
  // Save/delete/move/etc handlers end
  //
  const addToSurvey = (type, isStim = false) => {
    toggleAddToSurveyModal();
    const { stimType, responseSetType } = isStim ? { stimType: type } : { responseSetType: type };
    const joiner = questionJoinerGenerator({
      stimType,
      responseSetType,
      surveyOptions: survey.surveyOptions || {},
      displayIndex: state.addAtPosition,
      conceptRotationId: state.addAtConceptRotationId,
      containerId: state.addAtContainerId
    });
    if (responseSetType === RESPONSE_SET_TYPE.page) {
      saveJoiner({ joiner });
    } else {
      const joinerInfo = getJoinerInfo(joiner, state.addAtQuestionNum);
      toggleJoinerEditorModal(joinerInfo);
    }
  };

  const getJoinerThumbnailOrIcon = (joiner, type) => {
    const { stim } = joiner;
    const { media } = stim || {};
    const id = `media-thumbnail-${joiner.id}`;
    const onClick = e => toggleSelectMediaPopover(e, joiner);
    switch (type) {
      case STIM_TYPE.image:
        return <img id={id} className="joiner-icon" src={mediaUtil.getMediaUrl(media)} alt="" onClick={onClick} />;
      case STIM_TYPE.video:
        return <Icons.VideoIcon id={id} className="fa-3x joiner-icon" onClick={onClick} />;
      default:
    }
    return getJoinerIcon(joiner);
  };

  /*
   * Returns an object with some useful information about the joiner.
   */
  const getJoinerInfo = (joiner, questionNum) => {
    const { def, stim, conceptRotation, container, researchPrompt } = joiner;
    const type = getJoinerType(joiner);
    const questionName = cleanJoinerTitle(researchPrompt);
    const questionPrompt =
      (def && def.question && getQuestionPrompt(joiner, language, viewLanguage)) ||
      (type === STIM_TYPE.text && getTextStimContents(stim, language, viewLanguage)) ||
      '';
    const previewId = joiner.concepts ? joiner.concepts[0].joinerId : joiner.id;

    // For each concept in the rotation, build a list of objects with the concept's id, title, and joiner's hidden state.
    const conceptVisibility = [];
    if (joiner.conceptRotationId) {
      const concepts = getConcepts(survey.joiners, joiner.conceptRotationId);
      concepts.forEach(c => {
        const foundJoiner = find(c.joiners, j => j.parentId === joiner.parentId);
        conceptVisibility.push({
          id: c.id,
          title: c.title,
          hidden: foundJoiner ? foundJoiner.hidden : false
        });
      });
    }

    const clonedJoiner = cloneDeep(joiner);

    // For joiners in concepts, we use the joiner to represent all equivalent joiners in all concepts.
    // So we need to:
    // 1) Null out the conceptId field
    // 2) Set the hidden flag if the joiner is hidden in all concepts
    // 3) We'll also clean up the researchPrompt while we're here
    if (joiner.conceptId) {
      clonedJoiner.conceptId = null;
      clonedJoiner.hidden = conceptVisibility.filter(c => c.hidden).length === conceptVisibility.length;
      clonedJoiner.researchPrompt = cleanJoinerTitle(researchPrompt);
    }

    const isPageChild = !!(joiner.containerId && survey.joiners.some(j => j.container && j.id === joiner.containerId));

    return {
      joiner: clonedJoiner,
      type,
      isConceptRotation: !!conceptRotation,
      isPageContainer: container,
      conceptVisibility,
      joinerIcon: getJoinerThumbnailOrIcon(joiner, type),
      questionNum,
      questionName,
      questionPrompt,
      previewId,
      isPageChild,
      isEmailCollector: joiner.emailCollector,
      isVirtualFocusGroup: type === RESPONSE_SET_TYPE.virtualFocusGroup
    };
  };

  /*
   * Returns an updated joinerInfo object using the joiners from the latest survey object.
   */
  const getUpdatedJoinerInfo = joinerInfo => {
    const { id, conceptRotationId } = joinerInfo.joiner;
    let joiner;
    if (conceptRotationId) {
      const crJoiner = find(survey.joiners, j => j.id === conceptRotationId);
      joiner = find(crJoiner.conceptRotation.concepts[0].joiners, j => j.id === id);
    } else {
      joiner = find(survey.joiners, j => j.id === id);
    }
    return getJoinerInfo(joiner, joinerInfo.questionNum);
  };

  const getCollapsedQuestionJoiner = ({
    joiner,
    questionNum,
    addPrevQuestionNum,
    addNextQuestionNum,
    parentJoinerIndex,
    children
  }) => {
    return (
      <CollapsedQuestionJoiner
        joinerInfo={getJoinerInfo(joiner, questionNum)}
        addPrevQuestionNum={addPrevQuestionNum}
        addNextQuestionNum={addNextQuestionNum}
        key={`collapsedJoiner_${joiner.id}`}
        parentJoinerIndex={parentJoinerIndex}
        children={children}
        readOnly={isReadOnly()}
        isScreener={isScreener}
        highlightedJoinerId={state.highlightedJoinerId}
        setHighlightedJoinerId={setHighlightedJoinerId}
        handleAddToSurveyClick={handleAddToSurveyClick}
        handleEditJoinerClick={handleEditJoinerClick}
        handleDeleteJoinerClick={handleDeleteJoinerClick}
        handleMoveJoinerClick={handleMoveJoinerClick}
        handlePreviewJoinerClick={handlePreviewJoinerClick}
        toggleSelectMediaPopover={toggleSelectMediaPopover}
        isSelectMediaPopoverOpen={state.selectMediaPopover && state.selectMediaPopover.joiner.id === joiner.id}
      />
    );
  };

  const getCollapsedQuestionJoiners = () => {
    const data = [];
    let questionNum = 0;
    survey.joiners.forEach(joiner => {
      if (!joiner.container) {
        questionNum = parseInt(joiner.questionNum, 10);
      }
      let children = [];
      if (joiner.conceptRotation) {
        let childQuestionNum = 0;
        children = joiner.conceptRotation.concepts[0].joiners.map(j => {
          childQuestionNum++;
          return getCollapsedQuestionJoiner({
            joiner: j,
            questionNum: j.questionNum,
            addPrevQuestionNum: j.questionNum,
            addNextQuestionNum: `${questionNum}.${childQuestionNum + 1}`,
            parentJoinerIndex: joiner.displayIndex
          });
        });
      }
      const pageContainer = joiner.containerId && find(data, d => d.joiner.id === joiner.containerId);
      if (pageContainer) {
        pageContainer.children.push(
          getCollapsedQuestionJoiner({
            joiner,
            questionNum: `${questionNum}.`,
            addPrevQuestionNum: `${questionNum}.`,
            addNextQuestionNum: `${questionNum + 1}.`,
            parentJoinerIndex: pageContainer.joiner.displayIndex
          })
        );
        pageContainer.addNextQuestionNum = `${questionNum + 1}.`;
      } else {
        data.push({
          joiner,
          questionNum: `${questionNum}.`,
          addPrevQuestionNum: joiner.container ? `${questionNum + 1}.` : `${questionNum}.`,
          addNextQuestionNum: `${questionNum + 1}.`,
          children
        });
      }
    });
    return data.map(d => getCollapsedQuestionJoiner(d));
  };

  const getSurveyTotalTiming = (joiner, excludeLast) => getTotalTiming(survey.joiners, joiner, excludeLast);

  if (survey.importStatus === 'InProgress') {
    return <span>{intl.get('app.surveyEditor.surveyImport.inProgress')}</span>;
  }

  const importError = survey.importStatus === 'Failed' ? survey.importError && survey.importError.errorMessage : null;

  const importSurvey = params => {
    params.sessionId = session.id;
    params.name = session.name;
    props.importSurvey(params);
    setState({ showImportSurveyModal: !state.showImportSurveyModal });
  };

  function openPreview() {
    const previewUrl = `/survey/take/${session.id}/${idUtils.getId()}/64f8f0c5c65d4960fefc4d76fa0038c3?prv=true`;
    window.open(previewUrl, '_blank');
  }

  const getSurveyEditorHeader = () => (
    <div className="survey-editor-header mb-2">
      <div className="total-time">
        <strong>
          {intl.get('app.survey.totalTime')}: {getTotalTiming(survey.joiners, null, false)}
        </strong>
      </div>
      <div className="survey-icons">
        <strong>{intl.get('app.library')}: </strong>
        {isScreener ? (
          <div className="ms-4">
            <Icons.FunctionsIcon
              className="clickable"
              onClick={toggleFunctionsModal}
              title={intl.get('app.functions')}
            />
            <Icons.DataBaseIcon
              className="ms-4 clickable"
              onClick={toggleDataTableModal}
              title={intl.get('app.dataTable')}
            />
          </div>
        ) : (
          <Icons.ImagesIcon
            title={intl.get('app.media')}
            className={readOnly && 'disabled'}
            onClick={toggleMediaLibrary}
          />
        )}
      </div>
      <div className="survey-icons me-3">
        <strong>{intl.get('app.actions')}: </strong>
        {!isTemplate && <Icons.EyeIcon onClick={openPreview} />}
        <Icons.DownloadIcon onClick={toggleSurveyExport} />
        <Icons.SaveIcon className={readOnly && 'disabled'} onClick={toggleSaveAsTemplateModal} />
        <Icons.TrashAltIcon className={readOnly && 'disabled'} onClick={toggleDeleteSurveyModal} />
      </div>
    </div>
  );

  const exportSurvey = () => {
    setState({ showExportSurveyModal: !state.showExportSurveyModal });
  };

  const generateSurveyRawExport = params => {
    props.generateSurveyRawExport(params);
    setState({ showExportSurveyModal: !state.showExportSurveyModal });
  };

  const generateSurveyContentReview = params => {
    props.generateSurveyContentReview(params);
    setState({ showExportSurveyModal: !state.showExportSurveyModal });
  };

  const deleteSurvey = () => {
    if (session) {
      props.deleteSurveyQuestions({
        surveyId: props.surveyId
      });
    } else {
      // handle case for deleting surveyTemplate
      props.deleteSurvey({
        surveyId: props.surveyId,
        isTemplate
      });
    }
  };

  function onDeleteMedia(payload) {
    if (payload.fullDelete) {
      props.fetchSurvey(survey.id);
    }
  }

  function getMediaModal() {
    return (
      state.showMediaLibrary && (
        <SelectMediaModal
          enableDelete={true}
          primaryButtonText=""
          cancelButtonText={intl.get('app.close')}
          title={intl.get('app.media')}
          onDeleteMedia={onDeleteMedia}
          toggle={toggleMediaLibrary}
        />
      )
    );
  }

  if (!state.showJoinerEditorModal && (!survey.joiners || survey.joiners.length === 0)) {
    return (
      <>
        {getSurveyEditorHeader()}
        {survey.importStatus === 'Failed' && (
          <div className="alert alert-danger import-error" role="alert">
            <div className="mb-3">{intl.get('app.surveyEditor.surveyImport.failed')}</div>
            <div>{importError}</div>
          </div>
        )}
        {!readOnly && <AddFirstQuestion handleAddToSurveyClick={handleAddToSurveyClick} />}
        {!readOnly && !isTemplate && (
          <div>
            <Button secondary className="use-template" onClick={toggleUseTemplateModal}>
              {intl.get('app.useSurveyTemplate')}
            </Button>
            <Button secondary className="import-survey" onClick={toggleImportSurveyModal}>
              {intl.get('app.importSurvey')}
            </Button>
          </div>
        )}
        {getMediaModal()}
        {state.showAddToSurveyModal && (
          <AddToSurveyModal
            toggle={toggleAddToSurveyModal}
            addToSurvey={addToSurvey}
            handleQuestionLibraryClick={handleQuestionLibraryClick}
            isScreener={isScreener}
          />
        )}
        {state.showSelectQuestionModal && (
          <SelectQuestionModal
            toggle={toggleSelectQuestionModal}
            insertJoinerFromLibrary={insertJoinerFromLibrary}
            isScreener={isScreener}
          />
        )}
        {state.showUseTemplateModal && (
          <SelectSurveyModal
            show={state.showUseTemplateModal}
            toggle={toggleUseTemplateModal}
            session={session}
            saveSession={props.saveSession}
          />
        )}
        {state.showImportSurveyModal && (
          <ImportSurveyModal
            show={state.showImportSurveyModal}
            toggle={toggleImportSurveyModal}
            onSubmit={importSurvey}
            modalTitle={intl.get('app.importSurvey')}
            cancelButtonText={intl.get('app.cancel')}
            primaryButtonText={intl.get('app.save')}
            fileType=".doc, .docx"
          />
        )}
      </>
    );
  }

  const heightOffset =
    jsUtil.getHeaderHeight() + jsUtil.getProjectStatusBarHeight() + jsUtil.getFooterHeight() + 16 + 28;

  return (
    <div>
      <Row>{getSurveyEditorHeader()}</Row>
      <div style={{ overflowY: 'auto', height: `calc(100vh - ${heightOffset}px)`, paddingTop: '1rem' }}>
        {isReadOnly() && <ReadOnlyBanner translationInProgress={translationInProgress} />}
        {props.saveQuestionJoinerInProgress && <Loader spinner fullScreen />}
        {survey.joiners && getCollapsedQuestionJoiners()}
        {state.showAddToSurveyModal && (
          <AddToSurveyModal
            toggle={toggleAddToSurveyModal}
            addToSurvey={addToSurvey}
            handleQuestionLibraryClick={handleQuestionLibraryClick}
            isConceptRotation={!!state.addAtConceptRotationId}
            isScreener={isScreener}
            isPageContainer={!!state.addAtContainerId}
            isVirtualFocusGroup={state.isVirtualFocusGroup}
          />
        )}
        {state.showSelectQuestionModal && (
          <SelectQuestionModal
            toggle={toggleSelectQuestionModal}
            insertJoinerFromLibrary={insertJoinerFromLibrary}
            isScreener={isScreener}
          />
        )}
        {state.showJoinerEditorModal && (
          <JoinerEditorModal
            videoCaptureEnabled={session && session.videoCaptureConfig && session.videoCaptureConfig.enabled}
            surveyId={survey.id}
            language={language}
            joinerInfo={state.editingJoinerInfo}
            readOnly={isReadOnly()}
            groupNames={survey.groupNames}
            toggle={toggleJoinerEditorModal}
            saveJoiner={saveJoiner}
            saveAndPreviewJoiner={saveAndPreviewJoiner}
            handleDeleteJoinerClick={handleDeleteJoinerClick}
            getTotalTiming={getSurveyTotalTiming}
            joiners={survey.joiners}
            segmentCategories={survey.segmentCategories || []}
            surveyVariables={survey.variables}
            insertOrEditAction={state.insertOrEditAction}
            isScreener={isScreener}
            isSessionOpen={isOpen(session)}
            cannotSaveTemplate={cannotSaveTemplate}
            viewLanguage={viewLanguage}
            setViewLanguage={props.setViewLanguage}
            sessionId={session && session.id}
          />
        )}
        {!!state.selectMediaPopover && (
          <SelectMediaPopover
            target={`media-thumbnail-${state.selectMediaPopover.joiner.id}`}
            enableAdd={false}
            enableEdit={false}
            toggle={toggleSelectMediaPopover}
            onMediaUpdate={handleMediaUpdate}
          />
        )}
        {getMediaModal()}
        {state.showJoinerPreviewModal && (
          <JoinerPreviewModal
            joinerId={state.previewJoinerId}
            sessionId={session?.id}
            toggle={toggleJoinerPreviewModal}
            locale={language}
          />
        )}
        {state.showConfirmRemoveJoinerModal && (
          <ConfirmJoinerRemove
            toggle={toggleConfirmRemoveJoinerModal}
            joiner={state.joinerForDelete}
            deleteJoiner={deleteJoiner}
          />
        )}
        {state.showDeleteSurveyConfirmation && (
          <InvokeModal
            showModal
            toggle={toggleDeleteSurveyModal}
            modalTitle={intl.get('app.delete.survey')}
            primaryButtonText={intl.get('app.delete')}
            cancelButtonText={intl.get('app.cancel')}
            save={deleteSurvey}
            enableSave={true}
          >
            {isTemplate
              ? intl.get('app.delete.template.confirm', { name: survey.name })
              : intl.get('app.delete.survey.confirm', { survey: session.name })}
          </InvokeModal>
        )}
        {state.showExportSurveyModal && (
          <ExportSurveyModal
            exportSurvey={exportSurvey}
            survey={survey}
            generateSurveyRawExport={generateSurveyRawExport}
            generateSurveyContentReview={generateSurveyContentReview}
            toggle={toggleSurveyExport}
          />
        )}
        {state.showSaveAsTemplateModal && (
          <InvokeModal
            showModal={true}
            toggle={toggleSaveAsTemplateModal}
            backdrop={false}
            keyboard={false}
            modalTitle={intl.get('app.surveyTemplate.provideName')}
            primaryButtonText={intl.get('app.save')}
            save={saveAsTemplate}
            enableSave={state.surveyTemplateName}
            cancelButtonText={intl.get('app.cancel')}
          >
            <label>{intl.get('app.surveyTemplate.name')}</label>
            <Input value={state.surveyTemplateName} onChange={updateSurveyTemplateName} />
          </InvokeModal>
        )}
        {state.showFunctionsModal && (
          <InvokeModal
            showModal={state.showFunctionsModal}
            modalTitle={intl.get('app.functions')}
            primaryButtonText=""
            cancelButtonText={intl.get('app.close')}
            toggle={toggleFunctionsModal}
            className="functions-modal"
          >
            <Functions />
          </InvokeModal>
        )}
        {state.showDataTableModal && (
          <InvokeModal
            showModal
            modalTitle={intl.get('app.dataTable')}
            primaryButtonText=""
            cancelButtonText={intl.get('app.close')}
            toggle={toggleDataTableModal}
            className="datatable-modal"
          >
            <DataTableList />
          </InvokeModal>
        )}
      </div>
    </div>
  );
};
