import React from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { BannerFilters } from '../bannerFilters/BannerFilters';

export const BannerControls = props => {
  const { banner, bannerIndex, setBannerLabel, showSelectFiltersModal, removeFilter, moveFilter } = props;

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Input
          style={{ width: '18rem' }}
          value={banner.label}
          onChange={e => setBannerLabel(e.target.value, bannerIndex)}
        />
        <Button
          className="link-button"
          style={{ marginLeft: '1rem', padding: 0, whiteSpace: 'nowrap' }}
          onClick={() => showSelectFiltersModal(banner, bannerIndex)}
        >
          {intl.get('app.selectFilters').toLowerCase()}
        </Button>
      </div>
      <BannerFilters banner={banner} bannerIndex={bannerIndex} removeFilter={removeFilter} moveFilter={moveFilter} />
    </>
  );
};
