import { createStructuredSelector } from 'reselect';

const selectMediaList = state => {
  return state.mediaListLibrary;
};
const mediaListSelector = createStructuredSelector({
  mediaListLibrary: selectMediaList
});

export { mediaListSelector };
