import React from 'react';
import intl from 'react-intl-universal';
import { Checkbox, Select, Radio } from 'antd';
import { Row, Input } from 'reactstrap';
import { find, debounce } from 'lodash';
import { isRequired, OPTION_TYPE, ANSWER_TYPE, GIBBERISH_LANGUAGES } from '../../../../../../util/joinerUtil';
import { toast } from '../../../../../../util/toast';

import './Options.css';

const answerTypes = [
  ANSWER_TYPE.string,
  ANSWER_TYPE.alphanumeric,
  ANSWER_TYPE.alphaonly,
  ANSWER_TYPE.double,
  ANSWER_TYPE.numeric,
  ANSWER_TYPE.zipcode
];
const Option = Select.Option;
const RadioGroup = Radio.Group;
const SHARED = 'shared';
const NOT_SHARED = 'notShared';
const VOTED = 'voted';
const SHARED_U = 'SHARED';
const NOT_SHARED_U = 'NOT SHARED';
const VOTED_U = 'VOTED';
const MIN = 'Min';
const MAX = 'Max';

class OpenTextOptions extends React.Component {
  constructor(props) {
    super(props);
    find(props.joiner.def.responseSet.options, o => o.type && o.type === OPTION_TYPE.limitoption)
      ? (this.state = {
          validateEnabled: true,
          rangeEnabled:
            props.joiner.def.responseSet.expectedAnswerType === 'NUMERIC' ||
            props.joiner.def.responseSet.expectedAnswerType === 'DOUBLE',
          [MIN]: this.getValFromAnswerFormat(props.joiner, MIN),
          [MAX]: this.getValFromAnswerFormat(props.joiner, MAX)
        })
      : (this.state = {
          validateEnabled: false,
          rangeEnabled: false,
          [MIN]: props.joiner.def.responseSet.expectedAnswerType === 'STRING' ? 1 : 0,
          [MAX]: 5000
        });
  }

  componentDidMount() {
    this.updateHandlers = this.props.updateHandlers;
  }

  onHideQuestion = e => {
    this.updateHandlers.updateHidden(e.target.checked);
  };

  onRequiredQuestion = () => {
    this.updateHandlers.updateQuestionRequired();
  };

  onAnswerFormatChange = e => {
    const options = [];
    this.setState({
      validateEnabled: e.target.checked,
      [MIN]: 1,
      [MAX]: 5000
    });
    if (!e.target.checked) {
      this.updateHandlers.updateOpenTextAnswerFormat('STRING', false, options);
    }
  };

  getAnswerFormatOptions = (isScreener, language) => {
    const validAnswerTypes = [...answerTypes];
    if (GIBBERISH_LANGUAGES[language]) {
      validAnswerTypes.push(ANSWER_TYPE.notGibberish);
    }
    return validAnswerTypes.map(a => {
      const text = intl.get(`app.surveyEditor.${a}`);
      return (
        <Option key={a} title={text}>
          {text}
        </Option>
      );
    });
  };

  getAnswerFormat = format => {
    const validateGibberish = format === 'NOTGIBBERISH';
    const newFormat = validateGibberish ? 'STRING' : format;
    const rangeEnabled = format === 'NUMERIC' || format === 'DOUBLE';
    const isZipCode = format === 'ZIPCODE';
    const min = rangeEnabled || isZipCode ? 0 : 1;
    const max = isZipCode ? 100000 : 5000;
    const options = [
      { name: OPTION_TYPE.limitoption, type: OPTION_TYPE.limitoption, limit: min, limitType: MIN },
      { name: OPTION_TYPE.limitoption, type: OPTION_TYPE.limitoption, limit: max, limitType: MAX }
    ];
    this.setState({ rangeEnabled, isZipCode, [MIN]: min, [MAX]: max });
    this.updateHandlers.updateOpenTextAnswerFormat(newFormat, validateGibberish, options);
  };

  getValFromAnswerFormat = (joiner, type) => {
    const index = joiner.def.responseSet.options.findIndex(o => o.limitType && o.limitType === type);
    return index >= 0 ? joiner.def.responseSet.options[index].limit : null;
  };

  setValFromAnswerFormatDebounced = debounce((joiner, value, type) => {
    if (value < 0) {
      toast.error({ text: intl.get('app.openText.answerValueOutOfRange') });
    } else {
      const index = joiner.def.responseSet.options.findIndex(o => o.limitType && o.limitType === type);
      this.updateHandlers.updateValFromAnswerFormat(value, index, type);
    }
  }, 500);

  setValFromAnswerFormat = (joiner, e, type) => {
    this.setState(
      {
        [type]: e.target.value
      },
      () => this.setValFromAnswerFormatDebounced(joiner, this.state[type], type)
    );
  };

  getShareVoteChoice = type => type || NOT_SHARED_U;

  setShareVoteChoice = e => {
    switch (e.target.value) {
      case NOT_SHARED_U:
        this.updateHandlers.updateShareVoteChoice(null);
        break;
      case SHARED_U:
        this.updateHandlers.updateShareVoteChoice(SHARED_U);
        break;
      case VOTED_U:
        this.updateHandlers.updateShareVoteChoice(VOTED_U);
        break;
      default:
    }
  };

  updateShareVoteMaxExposure = e => this.updateHandlers.updateShareVoteMaxExposure(e.target.value);

  updateShareVoteMaxResp = e => this.updateHandlers.updateShareVoteMaxResp(e.target.value);

  updateShareVoteMinResp = e => this.updateHandlers.updateShareVoteMinResp(e.target.value);
  onVideoCaptureEnable = e => this.updateHandlers.updateVideoCaptureEnabled(e.target.checked);
  render() {
    const {
      joiner,
      tabs,
      readOnly,
      isVideoCaptureEnabled,
      isScreener,
      language,
      videoCaptureEnabled: videoCaptureEnabledAtSession
    } = this.props;
    const { sharedResponseOption, videoCaptureEnabled, validateGibberish, expectedAnswerType } = joiner.def.responseSet;
    const { type } = sharedResponseOption;
    const type_shared = type && type === SHARED_U;
    const type_voted = type && type === VOTED_U;
    const validateAnswerFormatValue = validateGibberish
      ? intl.get('app.surveyEditor.NOTGIBBERISH')
      : expectedAnswerType;
    return (
      <div className={`${tabs.options} open-text-options`}>
        <div className="flex-row">
          <Checkbox checked={isRequired(joiner)} disabled={readOnly} onChange={this.onRequiredQuestion}>
            {intl.get('app.questionRequired')}
          </Checkbox>
        </div>
        <div className="flex-row">
          <Checkbox checked={joiner.hidden} disabled={readOnly} onChange={this.onHideQuestion}>
            {intl.get('app.hideQuestion')}
          </Checkbox>
        </div>
        <div className="flex-row">
          <span className="left-half">
            <Checkbox checked={this.state.validateEnabled} disabled={readOnly} onChange={this.onAnswerFormatChange}>
              {intl.get('app.openText.validateAnswerFormat')}:
            </Checkbox>
            <Select
              className="ms-2"
              placeholder={intl.get('app.surveyEditor.STRING')}
              onChange={this.getAnswerFormat}
              disabled={readOnly || !this.state.validateEnabled}
              value={validateAnswerFormatValue}
            >
              {this.getAnswerFormatOptions(isScreener, language)}
            </Select>
          </span>
          {this.state.rangeEnabled && (
            <span className="right-half">
              {intl.get('app.openText.rangeBetween')}:
              <Input
                type="number"
                min="0"
                value={this.state[MIN]}
                onChange={e => this.setValFromAnswerFormat(joiner, e, MIN)}
              />
              {intl.get('app.and')}
              <Input
                type="number"
                value={this.state[MAX]}
                style={{ width: '5.75rem' }}
                onChange={e => this.setValFromAnswerFormat(joiner, e, MAX)}
              />
            </span>
          )}
        </div>
        {isVideoCaptureEnabled && videoCaptureEnabledAtSession && !isScreener && (
          <div className="flex-row">
            <Checkbox checked={videoCaptureEnabled} onChange={this.onVideoCaptureEnable}>
              {intl.get('app.openText.videoCaptureEnabled')}
            </Checkbox>
          </div>
        )}

        {!isScreener && (
          <>
            <div className="fw-600 mt-4 mb-2">{intl.get('app.openText.sharingAndVoting')}:</div>
            <Row>
              <RadioGroup
                className="radio-group"
                value={this.getShareVoteChoice(type)}
                disabled={readOnly}
                onChange={this.setShareVoteChoice}
              >
                <Radio value={NOT_SHARED_U} name={NOT_SHARED} className="radio-label mb-2">
                  {intl.get(`app.${NOT_SHARED}`)}
                </Radio>
                <Radio value={SHARED_U} name={SHARED} className="radio-label mt-1 mb-2">
                  {intl.get(`app.${SHARED}`)}
                </Radio>
                <Radio value={VOTED_U} name={VOTED} className="radio-label mt-1">
                  {intl.get(`app.${VOTED}`)}
                </Radio>
              </RadioGroup>

              <div className="shared-voted-group">
                <div className="flex-row mb-2">
                  <span>{intl.get('app.openText.maxExposurePerResponse')}</span>
                  <Input
                    type="number"
                    value={sharedResponseOption.maxExposures}
                    onChange={this.updateShareVoteMaxExposure}
                    disabled={readOnly || (!type_shared && !type_voted)}
                  />
                </div>
                <div className="flex-row mb-2">
                  <span>{intl.get('app.openText.minResponsesBeforeSharing')}</span>
                  <Input
                    type="number"
                    value={sharedResponseOption.minResponses}
                    onChange={this.updateShareVoteMinResp}
                    disabled={readOnly || (!type_shared && !type_voted)}
                  />
                </div>
                <div className="flex-row mb-2">
                  <span>{intl.get('app.openText.maxResponsesToVoteOn')}</span>
                  <Input
                    type="number"
                    value={sharedResponseOption.maxResponses}
                    onChange={this.updateShareVoteMaxResp}
                    disabled={readOnly || !type_voted}
                  />
                </div>
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default OpenTextOptions;
