import createFetchActions from './createFetchActions';

const fetchProjectActions = createFetchActions('FETCH_PROJECT', {
  failure_payload: (project, exception) => ({
    project,
    exception
  })
});

export { fetchProjectActions };
