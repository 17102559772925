import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { find } from 'lodash';
import { SURVEY_RULE_TYPE } from '../../../../../util/joinerUtil';
import { DEFAULT_FLAG_COLOR, PARTICIPANT_FLAG_COLORS } from '../../../../../util/researchDashboardUtil';
import AllOrAnySelector from './AllOrAnySelector';
import SurveyRuleConditions from './SurveyRuleConditions';
import { Icons } from '../../../../../components/icons/Icons';

const SurveyRule = props => {
  const {
    sessionId,
    joiner,
    rule,
    ruleIndex,
    joiners,
    segmentCategories,
    surveyVariables,
    updateHandlers,
    readOnly,
    isScreener
  } = props;

  let title = '';
  let beginText = '';
  if (rule.type === SURVEY_RULE_TYPE.terminationRule) {
    title = intl.get('app.surveyRules.terminationRule');
    beginText = intl.get('app.surveyRules.terminateIfBegin');
  } else if (rule.type === SURVEY_RULE_TYPE.includeQuestionRule) {
    title = intl.get('app.surveyRules.questionVisibilityRule');
    beginText = intl.get('app.surveyRules.includeIfBegin');
  } else if (rule.type === SURVEY_RULE_TYPE.segmentationRule) {
    title = intl.get('app.surveyRules.segmentationRule');
    beginText = intl.get('app.surveyRules.applySegmentationIfBegin');
  } else if (rule.type === SURVEY_RULE_TYPE.setVariableRule) {
    title = intl.get('app.surveyRules.setVariableRule');
    beginText = intl.get('app.surveyRules.applyRuleIfBegin');
  } else if (rule.type === SURVEY_RULE_TYPE.setFlagRule) {
    title = intl.get('app.surveyRules.setFlagRule');
    beginText = intl.get('app.surveyRules.applyRuleIfBegin');
  }

  const getSegmentCategoryOptions = () => {
    return segmentCategories.map(segCat => (
      <option value={segCat.name} key={segCat.name}>
        {segCat.name}
      </option>
    ));
  };

  const getSegmentOptions = () => {
    const segCat = find(segmentCategories, segCat => segCat.name === rule.segmentCategory) || {};
    const { segments = [] } = segCat;
    return segments.map(segment => (
      <option value={segment.name} key={segment.name}>
        {segment.name}
      </option>
    ));
  };

  const getVariableOptions = () => {
    return Object.keys(surveyVariables).map(variable => (
      <option value={variable} key={variable}>
        {variable}
      </option>
    ));
  };

  const flagColor =
    rule.type === SURVEY_RULE_TYPE.setFlagRule && (PARTICIPANT_FLAG_COLORS[rule.group] || DEFAULT_FLAG_COLOR);

  return (
    <div className="survey-rule">
      <div className="title-row">
        <h6>{title}</h6>
        {!readOnly && (
          <span>
            <Icons.CopyIcon
              className="clickable me-4"
              title={intl.get('app.survey.copyRule')}
              onClick={() => updateHandlers.copyRule(ruleIndex, rule)}
            />
            <Icons.TrashAltIcon className="clickable" onClick={() => updateHandlers.deleteRule(ruleIndex)} />
          </span>
        )}
      </div>
      {rule.type === SURVEY_RULE_TYPE.segmentationRule && (
        <div className="flex-row">
          <div>{intl.get('app.surveyRules.segmentUserInto')}:</div>
          <Input
            type="select"
            className="segment-category-select"
            value={rule.segmentCategory}
            disabled={readOnly}
            required
            onChange={e => updateHandlers.setSegmentCategory(ruleIndex, e.target.value)}
          >
            <option value="" hidden>
              {intl.get('app.segmentCategory')}
            </option>
            {getSegmentCategoryOptions()}
          </Input>
          <Input
            type="select"
            className="segment-select"
            value={rule.segment}
            disabled={readOnly}
            required
            onChange={e => updateHandlers.setSegmentValue(ruleIndex, e.target.value)}
          >
            <option value="" hidden>
              {intl.get('app.segment')}
            </option>
            {getSegmentOptions()}
          </Input>
        </div>
      )}
      {rule.type === SURVEY_RULE_TYPE.setVariableRule && (
        <div className="flex-row set-variable-top-row">
          <div>{intl.get('app.surveyRules.setVariableLabel1')}:</div>
          <Input
            type="select"
            className="variable-select"
            value={rule.variable}
            disabled={readOnly}
            required
            onChange={e => updateHandlers.setVariable(ruleIndex, e.target.value)}
          >
            <option value="" hidden />
            {getVariableOptions()}
          </Input>
          <div>{intl.get('app.surveyRules.setVariableLabel2')}:</div>
          <Input
            value={rule.value}
            disabled={readOnly}
            required
            onChange={e => updateHandlers.setVariableValue(ruleIndex, e.target.value)}
          />
        </div>
      )}
      {rule.type === SURVEY_RULE_TYPE.setFlagRule && (
        <div className="flex-row set-flag-top-row">
          <div>{intl.get('app.surveyRules.setFlagTo')}:</div>
          <div style={{ position: 'relative' }}>
            <Icons.FlagsDropdown
              id={`flags_${ruleIndex}`}
              flagClasses={flagColor}
              title={
                flagColor === DEFAULT_FLAG_COLOR
                  ? intl.get('app.setFlags')
                  : intl.get(`app.participantFlag.${rule.group}.color`)
              }
              showBan
              onFlagClick={group => updateHandlers.setParticipantGroup(ruleIndex, group)}
            />
          </div>
        </div>
      )}
      <div className="flex-row">
        <span>{beginText}</span>
        <AllOrAnySelector
          rule={rule}
          setAllOrAny={e => updateHandlers.setAllOrAny(ruleIndex, e.target.value)}
          readOnly={readOnly}
        />
        <span>{intl.get('app.surveyRules.ifEnd')}</span>
      </div>
      <SurveyRuleConditions
        sessionId={sessionId}
        joiner={joiner}
        rule={rule}
        ruleIndex={ruleIndex}
        joiners={joiners}
        updateHandlers={updateHandlers}
        readOnly={readOnly}
        isScreener={isScreener}
      />
    </div>
  );
};

export default SurveyRule;
