import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useOutsideClickDetector, SearchInput } from 'webapp-common';
import { mediaUtil } from '../../../../../util/mediaUtil';
import localtime from '../../../../../util/localtime';
import { Icons } from '../../../../../components/icons/Icons';
import { Video } from '../../../../../components/video/Video';
import { RDParticipantDetailsModal } from '../../../../researchDashboard/rdParticipantDetails/RDParticipantDetailsModal';

import './VirtualFocusGroupCompleted.css';

const MAX = 99999999;
const reducer = (payload, state) => ({ ...payload, ...state });

const getParticipant = (participants, speaker) => participants.find(p => p.nickname === speaker || p.id === speaker);

export const VirtualFocusGroupCompleted = props => {
  const { vfgData, vfgJoiner, sessionId, participants } = props;
  const meetingId = vfgData.chimeMeeting.id;

  const {
    chimeMeeting: { bookmarks, recordingMedia, streamingMedia, transcript, transcriptStatus }
  } = vfgData || {};

  const { entries: transcripts = [] } = transcript || {};

  const [state, setState] = useReducer(reducer, {
    selectedParticipant: null,
    searching: false,
    scrolling: false,
    currentTime: 0,
    transcripts
  });

  useEffect(() => {
    if (state.transcripts.length === 0 && transcripts.length !== 0) {
      setState({ transcripts });
    }
  }, [transcripts]);

  const toggleParticipantDetails = selectedParticipant => setState({ selectedParticipant });
  const videoDownloadUrl = mediaUtil.getMediaUrl(recordingMedia);

  useOutsideClickDetector(['transcripts'], () => setState({ scrolling: false }));

  const onSearchTranscripts = e => {
    if (!e.target.value) {
      setState({ transcripts, searching: false });
    } else {
      const results = transcripts.filter(
        t =>
          t.text.toUpperCase().indexOf(e.target.value.toUpperCase()) !== -1 ||
          t.speaker.toUpperCase().indexOf(e.target.value.toUpperCase()) !== -1
      );
      setState({ transcripts: results, searching: true });
    }
  };

  const onSpeakerClick = speaker => {
    const participant = getParticipant(participants, speaker);
    toggleParticipantDetails(participant);
  };

  const setCurrentVideoTime = time => {
    const currentTime = parseInt(time, 10);
    if (currentTime >= 0) {
      setState({ currentTime });
    }
  };

  function getTranscriptStatus() {
    const { state, errorMessage } = transcriptStatus || {};
    if (state === 'WAITING_FOR_RECORDING') {
      return intl.get('app.waitingForRecording');
    }
    if (state === 'IN_PROGRESS') {
      return intl.get('app.transcribeInProgress');
    }
    if (state === 'FAILED') {
      return (
        <>
          <div>{intl.get('app.transcribeFailed')}</div>
          <div>{errorMessage}</div>
        </>
      );
    }
  }

  const getTranscripts = () => {
    return state.transcripts.map((t, i, texts) => {
      const isParticipant = t.speaker !== 'moderator' && t.speaker.toLowerCase() !== 'unknown';
      const classes = {
        participant: isParticipant,
        speaker: true,
        'text-ellipsis': true,
        moderator: !isParticipant
      };
      const divStyle = ((texts[i + 1]?.time || MAX) < state.currentTime &&
        !state.searching &&
        !state.scrolling && { display: 'none' }) || { display: 'flex' };
      return (
        <div className="transcript-detail mb-3" style={divStyle}>
          <div
            className={classnames(classes)}
            title={t.speaker}
            onClick={() => isParticipant && onSpeakerClick(t.speaker)}
          >
            {t.speaker}:
          </div>
          <div className="speach" onClick={() => setCurrentVideoTime(t.time)}>
            {t.text}
          </div>
          <div className="time">{localtime.getFormattedTime(t.time)}</div>
        </div>
      );
    });
  };

  const getBookmarks = () =>
    bookmarks.map(b => {
      const classes = {
        'text-ellipsis': 'true',
        clickable: b.speaker !== 'moderator',
        speaker: b.speaker !== 'moderator',
        moderator: b.speaker === 'moderator'
      };
      return (
        <div className="bookmark mb-3">
          <div className="time clickable" onClick={() => setCurrentVideoTime(localtime.getTimeFromString(b.time, ':'))}>
            {b.time}
          </div>
          <div
            className={classnames(classes)}
            title={b.speaker}
            onClick={() => b.speaker !== 'moderator' && onSpeakerClick(b.speaker)}
          >
            {b.speaker}
          </div>
          <div className="comment">{b.comment}</div>
        </div>
      );
    });

  const onWheel = evt => {
    if (!state.scrolling) {
      setState({ scrolling: true });
    }
  };

  const onMouseLeave = evt => {
    if (state.scrolling) {
      setState({ scrolling: false });
    }
  };

  const onKeyDown = ({ key }) => {
    if ((key === 'ArrowUp' || key === 'ArrowDown') && !state.scrolling) {
      setState({ scrolling: true });
    }
  };

  return (
    <div className="completed-vfg">
      <div className="recording-and-bookmarks">
        <div className="recording">
          <div className="ms-3 mb-3">
            <strong>
              {intl.get('app.recording')}
              <a href={videoDownloadUrl} download={`${vfgJoiner.researchPrompt}.mp4`} disabled={!videoDownloadUrl}>
                <Icons.DownloadIcon className="ms-4" />
              </a>
            </strong>
          </div>
          {(!streamingMedia || streamingMedia.mediaConvertStatus) && (
            <div style={{ marginLeft: '1rem' }}>
              {intl.get('app.mediaConvert.status.msg', {
                percentComplete: streamingMedia?.mediaConvertStatus?.percentComplete || 0
              })}
            </div>
          )}
          <Video
            media={streamingMedia}
            currentVideoTime={state.currentTime}
            setCurrentVideoTime={setCurrentVideoTime}
          />
        </div>
        <div className="bookmarks-section">
          <div className="ms-3 mb-2">
            <strong>{intl.get('app.bookmarks')}</strong>
          </div>
          <div className="bookmarks">{getBookmarks()}</div>
        </div>
      </div>
      <div className="transcripts-section">
        <div className="ms-3 search-row">
          <strong>
            {intl.get('app.transcript')}
            <a
              href={`/a/api/exportTranscriptCSV/${meetingId}`}
              download={`${vfgJoiner.researchPrompt}_transcript.csv`}
              disabled={!transcript}
            >
              <Icons.DownloadIcon className="ms-4" />
            </a>
          </strong>
          <SearchInput className="ms-4" onChange={onSearchTranscripts} />
        </div>
        <div className="transcripts" tabIndex={-1} onWheel={onWheel} onMouseLeave={onMouseLeave} onKeyDown={onKeyDown}>
          {getTranscriptStatus() || getTranscripts()}
        </div>
      </div>
      {state.selectedParticipant && (
        <RDParticipantDetailsModal
          sessionId={sessionId}
          participant={state.selectedParticipant}
          setViewLanguage={props.setViewLanguage}
          language={props.language}
          viewLanguage={props.viewLanguage}
          toggle={() => toggleParticipantDetails()}
        />
      )}
    </div>
  );
};
