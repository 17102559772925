import React, { memo, useState } from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import { getFormatedTime } from '../../../util/joinerUtil';

const skipUpdate = (prevProps, nextProps) => {
  return (
    prevProps.pickedTime === nextProps.pickedTime &&
    prevProps.timeFormat === nextProps.timeFormat &&
    prevProps.readOnly === nextProps.readOnly
  );
};

const TimeSelector = memo(props => {
  const { pickedTime, timeFormat, readOnly, updateTime } = props;

  const [timerOpen, setTimerOpen] = useState(false);

  function handleOpenChange(b) {
    setTimerOpen(b);
  }

  const selectedTime = timeFormat
    ? {
        timeFormat,
        holder: '00:00:00',
        showHour: true
      }
    : {
        timeFormat: 'mm:ss',
        holder: '00:00',
        showHour: false
      };

  return (
    <TimePicker
      format={selectedTime.timeFormat}
      value={moment(getFormatedTime(pickedTime, selectedTime.showHour), selectedTime.timeFormat)}
      placeholder={selectedTime.holder}
      onSelect={updateTime}
      open={timerOpen}
      onOpenChange={handleOpenChange}
      disabled={readOnly}
      showNow={false}
    />
  );
}, skipUpdate);

export default TimeSelector;
