import { handleActions } from 'redux-actions';
import {
  fetchDataTableListAction,
  fetchAllDataTablesActions,
  fetchDataTableActions,
  fetchDataTableRecordsAction,
  deleteDataTableAction
} from '../actions/dataTableActions';

const initialState = {
  dataTableListRequested: false,
  dataTableRequested: false,
  dataTableRecordsRequested: false,
  dataTables: {},
  dataTableRecords: {},
  allDataTables: []
};

const dataTableReducer = handleActions(
  {
    [fetchDataTableListAction.triggered](state) {
      return { ...state, dataTableListRequested: true };
    },
    [fetchDataTableListAction.succeeded](state, { payload }) {
      return {
        ...state,
        dataTableList: payload,
        dataTableListRequested: false
      };
    },
    [fetchDataTableListAction.failed](state) {
      return { ...state, dataTableListRequested: false };
    },

    [fetchAllDataTablesActions.triggered](state) {
      return { ...state, allDataTablesRequested: true };
    },
    [fetchAllDataTablesActions.succeeded](state, { payload }) {
      return {
        ...state,
        allDataTables: payload.content,
        allDataTablesRequested: false
      };
    },
    [fetchAllDataTablesActions.failed](state) {
      return { ...state, allDataTablesRequested: false };
    },

    [fetchDataTableActions.triggered](state) {
      return {
        ...state,
        dataTableRequested: true,
        dataTableDeleted: false
      };
    },
    [fetchDataTableActions.succeeded](state, { payload }) {
      return {
        ...state,
        dataTables: { ...state.dataTables, [payload.id]: payload },
        dataTableRequested: false
      };
    },
    [fetchDataTableActions.failed](state) {
      return { ...state, dataTableRequested: false };
    },

    [fetchDataTableRecordsAction.triggered](state) {
      return { ...state, dataTableRecordsRequested: true, dataTableDeleted: false };
    },
    [fetchDataTableRecordsAction.succeeded](state, { payload }) {
      const { dataTableId, dataTableRecords } = payload;
      return {
        ...state,
        dataTableRecords: { ...state.dataTableRecords, [dataTableId]: dataTableRecords },
        dataTableRecordsRequested: false
      };
    },
    [fetchDataTableRecordsAction.failed](state) {
      return { ...state, dataTableRecordsRequested: false };
    },

    [deleteDataTableAction.succeeded](state, { payload }) {
      return {
        ...state,
        dataTables: { ...state.dataTables, [payload.id]: null },
        dataTableRecords: { ...state.dataTableRecords, [payload.id]: null },
        dataTableDeleted: true
      };
    }
  },
  initialState
);
export default dataTableReducer;
