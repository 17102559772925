import { handleActions } from 'redux-actions';
import { newAggregateDataAction } from '../../actions/researchDashboardActions';
const initialState = {};

export const rdAggregateDataReducer = handleActions(
  {
    [newAggregateDataAction.succeeded](state, { payload }) {
      return {
        ...state,
        [`${payload.sessionId}-${payload.questionDefId}`]: payload
      };
    }
  },
  initialState
);
