import React from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { accessHandler, NavTab } from 'webapp-common';
import appConfig from '../../../appConfig';
import ClientsPage from '../../clientAdmin/clientsPage';
import { PanelsPage } from '../../clientAdmin/panelsPage/PanelsPage';
import BlacklistPage from '../../clientAdmin/blacklistPage';

const CLIENTS_TAB = 'clients';
const PANEL_CONFIG_TAB = 'panels';
const BLACK_LIST_TAB = 'blacklist';

export const AdminPage = props => {
  const { isInternalSuperUser } = props;

  const { tab: activeTab = CLIENTS_TAB } = useParams();

  function getUrl(tab) {
    return `${appConfig.adminPagePath}/${tab}`;
  }

  const accessCheck = accessHandler.checkAccess(isInternalSuperUser);
  if (accessCheck !== true) {
    return accessCheck;
  }

  function getComponent() {
    if (activeTab === PANEL_CONFIG_TAB) {
      return <PanelsPage />;
    }
    return (
      <div className="body-container l2-bg">
        {activeTab === CLIENTS_TAB && <ClientsPage />}
        {activeTab === BLACK_LIST_TAB && <BlacklistPage />}
      </div>
    );
  }

  return (
    <section className="app-body">
      <section className="left-nav">
        <NavTab
          icon="fa-building"
          iconStyle="solid"
          label={intl.get('app.clients')}
          active={activeTab === CLIENTS_TAB}
          to={getUrl(CLIENTS_TAB)}
        />
        <NavTab
          icon="fa-users-cog"
          iconStyle="solid"
          label={intl.get('app.panelConfigurations')}
          active={activeTab === PANEL_CONFIG_TAB}
          to={getUrl(PANEL_CONFIG_TAB)}
        />
        <NavTab
          icon="fa-ban"
          iconStyle="solid"
          label={intl.get('app.emailBlacklist')}
          active={activeTab === BLACK_LIST_TAB}
          to={getUrl(BLACK_LIST_TAB)}
        />
      </section>
      {getComponent()}
    </section>
  );
};
