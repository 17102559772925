import React from 'react';
import update from 'immutability-helper';
import intl from 'react-intl-universal';
import { Input, Label } from 'reactstrap';
import { AutoComplete, Select } from 'antd';
import { cloneDeep, find, get, isEqual } from 'lodash';
import { FlexRow, InvokeModal } from 'webapp-common';

import './ReportAddDataColumnModal.css';

const Option = Select.Option;

class ReportAddDataColumnModal extends React.Component {
  constructor(props) {
    super(props);
    const { questionConfig } = props;

    const state = {
      commonQuestionsOnly: true,
      questionConfig: {
        questionJoinerId: '',
        category: '',
        label: '',
        answerData: {
          choices: [],
          norm: null,
          row: ''
        },
        sessionOverrides: {}
      }
    };

    if (questionConfig) {
      // Edit mode
      state.questionConfig = cloneDeep(questionConfig);
      const sessionIds = Object.keys(questionConfig.sessionOverrides);
      sessionIds.forEach(sessionId => {
        state.questionConfig.sessionOverrides[sessionId].enabled = true;
      });
      if (this.isUnCommonQuestion(questionConfig.questionJoinerId)) {
        // The question is in the uncommon list, so flip the flag.
        state.commonQuestionsOnly = false;
      }
    }
    this.state = state;
    this.origConfig = cloneDeep(this.state.questionConfig);
  }

  //
  // state updaters begin
  //

  setField = (field, value) => {
    const state = update(this.state, {
      questionConfig: {
        [field]: { $set: value }
      }
    });
    this.setState(state);
  };

  setCategory = category => {
    this.setField('category', category);
  };

  setColumnLabel = e => {
    this.setField('label', e.target.value);
  };

  setAnswerChoices = choices => {
    const state = update(this.state, {
      questionConfig: {
        answerData: {
          choices: { $set: choices }
        }
      }
    });
    this.setState(state);
  };

  setRow = e => {
    const state = update(this.state, {
      questionConfig: {
        answerData: {
          row: { $set: e.target.value }
        }
      }
    });
    this.setState(state);
  };

  setNorm = e => {
    const state = update(this.state, {
      questionConfig: {
        answerData: {
          norm: { $set: parseInt(e.target.value, 10) || null }
        }
      }
    });
    this.setState(state);
  };

  // Set the selected question and reset the state
  setSelectedQuestion = e => {
    const state = update(this.state, {
      questionConfig: {
        questionJoinerId: { $set: e.target.value },
        title: { $set: e.target.selectedOptions[0].dataset.questionTitle },
        answerData: {
          choices: { $set: [] },
          norm: { $set: null },
          row: { $set: '' }
        },
        sessionOverrides: { $set: {} }
      }
    });
    this.setState(state);
  };

  toggleCommonQuestionsOnly = () => {
    const newState = {
      commonQuestionsOnly: { $set: !this.state.commonQuestionsOnly }
    };
    const isUnCommon = this.isUnCommonQuestion(this.state.questionConfig.questionJoinerId);
    if (newState.commonQuestionsOnly && isUnCommon) {
      newState.questionConfig = {
        questionJoinerId: { $set: '' },
        answerData: {
          choices: { $set: [] },
          norm: { $set: null },
          row: { $set: '' }
        },
        sessionOverrides: { $set: {} }
      };
    }
    this.setState(update(this.state, newState));
  };

  toggleSessionOverride = e => {
    const sessionId = e.target.value;
    const sessionOverrides = cloneDeep(this.state.questionConfig.sessionOverrides);
    if (!sessionOverrides[sessionId]) {
      sessionOverrides[sessionId] = {};
    }
    sessionOverrides[sessionId].enabled = !sessionOverrides[sessionId].enabled;
    const state = update(this.state, {
      questionConfig: {
        sessionOverrides: { $set: sessionOverrides }
      }
    });
    this.setState(state);
  };

  setSessionOverrideQuestion = e => {
    const joinerId = e.target.value;
    const sessionId = e.target.dataset.sessionId;
    const state = update(this.state, {
      questionConfig: {
        sessionOverrides: {
          [sessionId]: {
            questionJoinerId: { $set: joinerId },
            title: { $set: e.target.selectedOptions[0].dataset.questionTitle },
            answerData: { $set: {} }
          }
        }
      }
    });
    this.setState(state);
  };

  setSessionOverrideRow = e => {
    const row = e.target.value;
    const sessionId = e.target.dataset.sessionId;
    const state = update(this.state, {
      questionConfig: {
        sessionOverrides: {
          [sessionId]: {
            answerData: {
              row: { $set: row }
            }
          }
        }
      }
    });
    this.setState(state);
  };

  setSessionOverrideChoices = (sessionId, choices) => {
    const state = update(this.state, {
      questionConfig: {
        sessionOverrides: {
          [sessionId]: {
            answerData: {
              choices: { $set: choices }
            }
          }
        }
      }
    });
    this.setState(state);
  };

  //
  // state updaters end
  //

  save = () => {
    const questionConfig = cloneDeep(this.state.questionConfig);
    const { sessionOverrides } = questionConfig;

    // Delete any disabled session overrides from the config
    const sessionIds = Object.keys(sessionOverrides);
    sessionIds.forEach(sessionId => {
      if (!sessionOverrides[sessionId].enabled) {
        delete sessionOverrides[sessionId];
      }
    });

    // Trim strings
    questionConfig.category = questionConfig.category.trim();
    questionConfig.label = questionConfig.label.trim();

    this.props.saveQuestion(questionConfig);
    this.closeModal();
  };

  closeModal = e => {
    // Selecting options in the antd Select widget triggers handleBackdropClick(),
    // which triggers closeModal(), so ignore these events.
    if (!e || e.target.className.indexOf('ant-select-dropdown-menu-item') === -1) {
      this.props.toggle();
    }
  };

  isCommonQuestion = joinerId => {
    return this.props.consolidatedData.commonQuestions.some(q => q.id === joinerId);
  };

  isUnCommonQuestion = joinerId => {
    return this.props.consolidatedData.unCommonQuestions.some(q => q.id === joinerId);
  };

  /*
   * Get the question list from common/uncommon questions based on the value of this.state.commonQuestionsOnly.
   */
  getQuestionList = () => {
    const { commonQuestions, unCommonQuestions } = this.props.consolidatedData;
    return this.state.commonQuestionsOnly ? commonQuestions : commonQuestions.concat(unCommonQuestions);
  };

  /*
   * Get the selected question from common/uncommon questions based on the value of this.state.commonQuestionsOnly.
   */
  getSelectedQuestion = () => {
    const questions = this.getQuestionList();
    return find(questions, q => q.id === this.state.questionConfig.questionJoinerId);
  };

  /*
   * Get questions from the common/uncommon question lists, or from an individual session,
   * and return Option elements for each.
   */
  getQuestionOptions = sessionId => {
    const questions = sessionId ? this.props.consolidatedData.sessionQuestionMap[sessionId] : this.getQuestionList();
    questions.sort((a, b) => (a.questionTitle.toUpperCase() > b.questionTitle.toUpperCase() ? 1 : -1));
    return questions.map(q => (
      <option
        value={q.id}
        data-question-title={q.questionTitle}
        key={q.id}
      >{`${q.questionTitle} - ${q.questionText}`}</option>
    ));
  };

  /*
   * Get the rows for a matrix question from the common/uncommon question lists, or from an individual session,
   * and return Option elements for each.
   */
  getMatrixRowOptions = (sessionId, joinerId) => {
    let selectedQuestion;
    if (sessionId) {
      const questions = this.props.consolidatedData.sessionQuestionMap[sessionId];
      selectedQuestion = find(questions, q => q.id === joinerId);
    } else {
      selectedQuestion = this.getSelectedQuestion() || {};
    }
    const rows = get(selectedQuestion, 'rows.rowList', []);
    return rows.map(row => (
      <option value={row} key={row}>
        {row}
      </option>
    ));
  };

  /*
   * Get the answer choices for a question from the common/uncommon question lists, or from an individual session,
   * and return Option elements for each.
   */
  getAnswerChoiceOptions = (sessionId, joinerId) => {
    let selectedQuestion;
    if (sessionId) {
      const questions = this.props.consolidatedData.sessionQuestionMap[sessionId];
      selectedQuestion = find(questions, q => q.id === joinerId);
    } else {
      selectedQuestion = this.getSelectedQuestion() || {};
    }
    const choiceList = get(selectedQuestion, 'choices.choiceList', []);
    return choiceList.map(choice => <Option key={choice}>{choice}</Option>);
  };

  getSessionsWithoutSelectedQuestion = () => {
    const { questionJoinerId } = this.state.questionConfig;

    if (!questionJoinerId || this.isCommonQuestion(questionJoinerId)) {
      return [];
    }

    // Return the sessionIds that do not contain this question
    const { sessionQuestionMap } = this.props.consolidatedData;
    const sessionIds = Object.keys(sessionQuestionMap);
    return sessionIds.filter(sessionId => {
      const questions = sessionQuestionMap[sessionId];
      return !questions.some(q => q.id === this.state.questionConfig.questionJoinerId);
    });
  };

  getSessionsWithoutSelectedQuestionControls = sessionsWithoutSelectedQuestion => {
    return sessionsWithoutSelectedQuestion.map(sessionId => {
      const session = find(this.props.sessions, s => s.id === sessionId);
      const sessionOverride = this.state.questionConfig.sessionOverrides[sessionId] || {};
      const { enabled, questionJoinerId, answerData = {} } = sessionOverride;
      const matrixRowOptions = this.getMatrixRowOptions(sessionId, questionJoinerId);
      return (
        <div key={sessionId}>
          <Label>
            <Input type="checkbox" value={sessionId} checked={!!enabled} onChange={this.toggleSessionOverride} />{' '}
            {session.name}
          </Label>
          {enabled && (
            <>
              <FlexRow>
                <div className="label">* {intl.get('app.label.question')}:</div>
                <Input
                  type="select"
                  value={questionJoinerId || ''}
                  data-session-id={sessionId}
                  onChange={this.setSessionOverrideQuestion}
                >
                  <option value="" disabled />
                  {this.getQuestionOptions(sessionId)}
                </Input>
              </FlexRow>

              {matrixRowOptions.length !== 0 && (
                <FlexRow>
                  <div className="label">* {intl.get('app.label.row')}:</div>
                  <Input
                    type="select"
                    value={answerData.row || ''}
                    data-session-id={sessionId}
                    onChange={this.setSessionOverrideRow}
                  >
                    <option value="" disabled />
                    {matrixRowOptions}
                  </Input>
                </FlexRow>
              )}

              <FlexRow>
                <div className="label">* {intl.get('app.label.responses')}:</div>
                <Select
                  mode="multiple"
                  value={answerData.choices}
                  disabled={!questionJoinerId}
                  onChange={choices => this.setSessionOverrideChoices(sessionId, choices)}
                >
                  {this.getAnswerChoiceOptions(sessionId, questionJoinerId)}
                </Select>
              </FlexRow>
            </>
          )}
        </div>
      );
    });
  };

  isSaveEnabled = matrixRowOptions => {
    const { label, questionJoinerId, answerData } = this.state.questionConfig;
    const { choices, row, norm } = answerData;

    // Validate fields
    if (!label.trim() || !questionJoinerId || choices.length === 0) {
      return false;
    }
    if (matrixRowOptions.length !== 0 && !row) {
      return false;
    }
    if ((norm && norm < 1) || norm > 100) {
      return false;
    }

    // Validate session overrides
    if (!this.validateSessionOverrides()) {
      return false;
    }

    if (isEqual(this.origConfig, this.state.questionConfig)) {
      // No changes have been made
      return false;
    }

    return true;
  };

  validateSessionOverrides = () => {
    const { sessionOverrides } = this.state.questionConfig;
    const sessionIds = Object.keys(sessionOverrides);

    for (let i = 0; i < sessionIds.length; i++) {
      const sessionId = sessionIds[i];
      const { enabled, questionJoinerId, answerData = {} } = sessionOverrides[sessionId];
      if (enabled) {
        const { choices = [], row } = answerData;
        if (!questionJoinerId || choices.length === 0) {
          return false;
        }
        const matrixRowOptions = this.getMatrixRowOptions(sessionId, questionJoinerId);
        if (matrixRowOptions.length !== 0 && !row) {
          return false;
        }
      }
    }

    return true;
  };

  render() {
    const { questionConfig, commonQuestionsOnly } = this.state;
    const title = this.props.questionConfig
      ? intl.get('app.title.editDataColumn')
      : intl.get('app.title.addDataColumn');
    const matrixRowOptions = this.getMatrixRowOptions();
    const answerChoices = this.getAnswerChoiceOptions();
    const sessionsWithoutSelectedQuestion = this.getSessionsWithoutSelectedQuestion();
    const categoryOptions = [...new Set(this.props.categories)].map(cat => ({ value: cat }));

    return (
      <InvokeModal
        showModal={true}
        toggle={this.closeModal}
        modalTitle={title}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        save={this.save}
        enableSave={this.isSaveEnabled(matrixRowOptions)}
        className="custom-report-config report-add-question-modal"
      >
        <FlexRow className="top-row">
          <div className="label">* {intl.get('app.label.columnLabel')}:</div>
          <Input type="text" value={questionConfig.label} onChange={this.setColumnLabel} />
          <div className="label">{intl.get('app.label.category')}:</div>
          <AutoComplete
            options={categoryOptions}
            value={questionConfig.category}
            onChange={this.setCategory}
            filterOption
          />
        </FlexRow>

        <FlexRow>
          <div className="label">* {intl.get('app.label.question')}:</div>
          <Input type="select" value={questionConfig.questionJoinerId || ''} onChange={this.setSelectedQuestion}>
            <option value="" disabled />
            {this.getQuestionOptions()}
          </Input>
        </FlexRow>

        <FlexRow>
          <div />
          <Label>
            <Input type="checkbox" checked={commonQuestionsOnly} onChange={this.toggleCommonQuestionsOnly} />{' '}
            {intl.get('app.commonQuestionsCheckboxText')}
          </Label>
        </FlexRow>

        {matrixRowOptions.length !== 0 && (
          <FlexRow>
            <div className="label">* {intl.get('app.label.row')}:</div>
            <Input type="select" value={questionConfig.answerData.row} onChange={this.setRow}>
              <option value="" disabled />
              {matrixRowOptions}
            </Input>
          </FlexRow>
        )}

        <FlexRow>
          <div className="label">* {intl.get('app.label.responses')}:</div>
          <Select
            mode="multiple"
            value={questionConfig.answerData.choices}
            disabled={!questionConfig.questionJoinerId}
            onChange={this.setAnswerChoices}
          >
            {answerChoices}
          </Select>
        </FlexRow>

        <FlexRow className="norm-row">
          <div className="label">{intl.get('app.externalNorm')}:</div>
          <Input type="number" min={1} max={100} value={questionConfig.answerData.norm || ''} onChange={this.setNorm} />
        </FlexRow>

        {sessionsWithoutSelectedQuestion.length > 0 && (
          <div className="session-override-container">
            <div>
              <h5>{intl.get('app.sessionOverrides')}</h5>
              <div>{intl.get('app.dataColumn.sessionOverrideText')}</div>
            </div>
            <div>{this.getSessionsWithoutSelectedQuestionControls(sessionsWithoutSelectedQuestion)}</div>
          </div>
        )}

        <div className="footnote">{intl.get('app.requiredFieldFootnote')}</div>
      </InvokeModal>
    );
  }
}

export default ReportAddDataColumnModal;
