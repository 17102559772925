import { createSelector } from 'reselect';

const tenantRoles = state => state.tenantRoles;

const tenantRolesSelector = () =>
  createSelector([tenantRoles], tenantRoles => {
    return { tenantRoles };
  });

export { tenantRolesSelector };
