import { connect } from 'react-redux';
import { createDataTableActions, fetchDataTableListAction } from '../../store/redux/actions/dataTableActions';
import { dataTableListSelector } from '../../store/redux/selectors/dataTableSelector';
import { sessionUserSelector } from '../../store/redux/selectors/sessionUserSelector';
import { DataTableList } from './DataTableList';

const mapStateToProps = state => {
  const { dataTableList, dataTableListRequested } = dataTableListSelector()(state);
  const { isInternalSuperUser } = sessionUserSelector(state).abilities;
  return { dataTableList, dataTableListRequested, isInternalSuperUser };
};

const mapDispatchToProps = dispatch => ({
  createDataTable: params => dispatch(createDataTableActions.request(params)),
  fetchDataTableList: params => dispatch(fetchDataTableListAction.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(DataTableList);
