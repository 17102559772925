import React, { useReducer, useState } from 'react';
import { Input } from 'reactstrap';
import { Checkbox } from 'antd';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import { SurveyLanguage } from '../../survey/settings/language/SurveyLanguage';
import { ENGLISH } from '../../../../util/joinerUtil';

import './ExportSurveyModal.css';

const exportOptions = [
  'includeGroupNames',
  'includeSurveyContentOptions',
  'includeSurveyContentTiming',
  'includeStimThumbNails',
  'includeStimInfo'
];

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const ExportSurveyModal = props => {
  const { survey, generateSurveyContentReview, generateSurveyRawExport, toggle } = props;

  const [state, setState] = useReducer(reducer, {
    includeGroupNames: false,
    includeStimInfo: false,
    includeStimThumbNails: false,
    includeSurveyContentOptions: false,
    includeSurveyContentTiming: false,
    formatRadio: false,
    importRadio: false
  });

  const [reportLanguage, setReportLanguage] = useState(ENGLISH);

  const {
    includeGroupNames,
    includeStimInfo,
    includeStimThumbNails,
    includeSurveyContentOptions,
    includeSurveyContentTiming,
    formatRadio,
    importRadio
  } = state;

  function updateReportLanguage(value) {
    setReportLanguage(value);
  }

  function getAltLanguage() {
    return survey.surveyOptions.languageOptions.language;
  }

  function getExportOptions() {
    return exportOptions.map(option => {
      const label = intl.get(`app.surveyExport.${option}`);
      return (
        <div className="mb-4 ms-3" key={option}>
          <Checkbox checked={state[option]} disabled={!formatRadio} onChange={e => setExportOptions(e, option)}>
            {label}
          </Checkbox>
        </div>
      );
    });
  }

  function setExportOptions(e, option) {
    setState({
      [option]: e.target.checked
    });
  }

  function onExport() {
    if (importRadio) {
      generateSurveyRawExport({ surveyId: survey.id });
    } else {
      generateSurveyContentReview({
        includeGroupNames,
        includeStimInfo,
        includeStimThumbNails,
        includeSurveyContentOptions,
        includeSurveyContentTiming,
        surveyId: survey.id,
        useOrigValue: reportLanguage === ENGLISH
      });
    }
  }

  function toggleRadioOptions(e) {
    if (e.target.id === 'formatRadio') {
      setState({
        formatRadio: e.target.checked,
        importRadio: !e.target.checked
      });
    } else {
      setState({
        formatRadio: !e.target.checked,
        importRadio: e.target.checked
      });
    }
  }

  return (
    <InvokeModal
      showModal
      toggle={toggle}
      modalTitle={intl.get('app.exportSurvey')}
      primaryButtonText={intl.get('app.export')}
      cancelButtonText={intl.get('app.cancel')}
      save={onExport}
      enableSave={formatRadio || importRadio}
    >
      <div className="export-survey-options">
        <div className="mb-4 fw-600">{intl.get('app.surveyExport.introText')}</div>

        <div className="mb-4 format-section">
          <Input type="radio" id="formatRadio" name="customRadio" checked={formatRadio} onChange={toggleRadioOptions} />
          <label for="formatRadio">{intl.get('app.surveyExport.formattedDocument')}</label>
          <SurveyLanguage
            className="language-select"
            survey={survey}
            setLanguageOption={updateReportLanguage}
            selectedLanguage={reportLanguage}
            altLanguage={getAltLanguage()}
          />
        </div>

        <div className="mb-5 ms-5">
          <div className="mb-4">{intl.get('app.surveyExport.includeItems')}</div>
          {getExportOptions()}
        </div>

        <div>
          <Input type="radio" id="importRadio" name="customRadio" checked={importRadio} onChange={toggleRadioOptions} />
          <label for="importRadio">{intl.get('app.surveyExport.importableDocument')}</label>
        </div>
      </div>
    </InvokeModal>
  );
};
