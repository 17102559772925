import React from 'react';
import CollectionParticipants from '../../recruit/collectionParticipants';

import './AutoEnroll.css';

export const AutoEnrollParticipants = props => {
  const { project, hasProjectView, collectionId } = props;
  const { participantCollectionJobs = {} } = project || {};

  function getParticipantCollectionJob() {
    return participantCollectionJobs.content?.find(job => job.collection.id === collectionId) || {};
  }

  return (
    <div className="auto-enroll-participants">
      <CollectionParticipants
        project={props.project}
        collectionId={props.collectionId}
        job={getParticipantCollectionJob()}
        hasProjectView={hasProjectView}
        hideDelete
        showList
      />
    </div>
  );
};
