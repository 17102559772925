import React from 'react';
import intl from 'react-intl-universal';
import { Input, Row, Label } from 'reactstrap';
import { CommonJoinerEditFields } from '../../joinerEditor';
import SelectMediaModal from '../../modals/selectMedia/SelectMediaModal';
import { STIM_TYPE, getStimCaption, getStimAltTag, ENGLISH } from '../../../../../util/joinerUtil';
import { TranslateLabel } from './TranslateLabel';

class MediaStimEdit extends React.Component {
  state = {
    showSelectMediaModal: false,
    restartMedia: !!this.props.joiner.restartMedia
  };

  toggleSelectMediaModal = () => {
    if (!this.props.readOnly) {
      this.setState({
        showSelectMediaModal: !this.state.showSelectMediaModal
      });
    }
  };

  onMediaUpdate = media => {
    this.props.updateHandlers.updateStimMedia(media);
    this.toggleSelectMediaModal();
  };

  onRestartMediaUpdate = e => {
    const val = e.target.value === 'true';
    this.props.updateHandlers.onRestartMediaUpdate(val);
    this.setState({ restartMedia: val });
  };

  render() {
    const { joiner, updateHandlers, readOnly, language, viewLanguage, setViewLanguage } = this.props;
    const { updateStimCaption, updateStimAltTag } = updateHandlers;
    const { stim } = joiner;
    const stimType = stim && stim.type;
    const caption = getStimCaption(stim, language, viewLanguage);
    const altTag = getStimAltTag(stim, language, viewLanguage);

    return (
      <div className={`${this.props.tabs.edit} media-stim-edit`}>
        <CommonJoinerEditFields {...this.props} toggleSelectMediaModal={this.toggleSelectMediaModal} />
        <Row className="mb-4">
          <div className="label editor-left-column">
            <div className="label">{intl.get('app.caption')}</div>
          </div>
          <div className="editor-right-column">
            <Input
              value={caption}
              onChange={e => updateStimCaption(e.target.value, language, viewLanguage)}
              disabled={readOnly}
            />
          </div>
        </Row>
        {stimType !== STIM_TYPE.webcontent && stimType !== STIM_TYPE.video && (
          <Row>
            <div className="label editor-left-column">
              <div className="label">{intl.get('app.altText')}</div>
            </div>
            <div className="editor-right-column">
              <Input
                value={altTag}
                onChange={e => updateStimAltTag(e.target.value, language, viewLanguage)}
                disabled={readOnly}
              />
            </div>
          </Row>
        )}
        {(stimType === STIM_TYPE.webcontent || stimType === STIM_TYPE.video) && (
          <div>
            <Row className="mb-1 editor-right-column-flush">
              <div>
                <Label>
                  <Input
                    checked={!this.state.restartMedia}
                    type="radio"
                    onChange={this.onRestartMediaUpdate}
                    value={false}
                  />
                  {intl.get('app.media.continue')}
                </Label>
              </div>
            </Row>
            <Row className="editor-right-column-flush">
              <div>
                <Label>
                  <Input
                    checked={this.state.restartMedia}
                    type="radio"
                    onChange={this.onRestartMediaUpdate}
                    value={true}
                  />
                  {intl.get('app.media.restart')}
                </Label>
              </div>
            </Row>
          </div>
        )}
        {language !== ENGLISH && (
          <TranslateLabel
            classes="stim-text-translation mt-4"
            setViewLanguage={setViewLanguage}
            language={language}
            viewLanguage={viewLanguage}
            sessionId={this.props.sessionId}
          />
        )}
        {this.state.showSelectMediaModal && (
          <SelectMediaModal toggle={this.toggleSelectMediaModal} onMediaUpdate={this.onMediaUpdate} />
        )}
      </div>
    );
  }
}

export default MediaStimEdit;
