import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Input, Label, Row } from 'reactstrap';
import { InvokeModal } from 'webapp-common';
import MediaPreview from '../../components/core/mediaPreview/MediaPreview';

import './DeleteMediaModal.css';

export const DeleteMediaModal = props => {
  const { selectedMedia, toggle } = props;

  const [fullDelete, setFullDelete] = useState(false);

  function onDelete() {
    props.deleteMedia({ id: selectedMedia._id, fullDelete });
    toggle();
  }

  return (
    <InvokeModal
      showModal
      className="delete-media-modal"
      toggle={toggle}
      modalTitle={intl.get('app.title.deleteMedia')}
      primaryButtonText={intl.get('app.delete')}
      cancelButtonText={intl.get('app.cancel')}
      save={onDelete}
      enableSave={true}
    >
      <Row className="mb-3">{intl.get('app.media.delete.confirm')}</Row>
      <Row className="justify-content-center mb-3">
        <MediaPreview mediaType={selectedMedia.type} media={selectedMedia} />
      </Row>
      <Row className="mb-4">{intl.get('app.media.is.attached.to.msg', { usageCount: selectedMedia.usageCount })}</Row>
      {selectedMedia.usageCount > 0 && (
        <>
          <Row className="mb-4">
            <Label>
              <Input type="radio" checked={!fullDelete} onChange={e => setFullDelete(!e.target.checked)} />
              {intl.get('app.media.delete.only')}
            </Label>
          </Row>
          <Row>
            <Label>
              <Input type="radio" checked={fullDelete} onChange={e => setFullDelete(e.target.checked)} />
              {intl.get('app.media.delete.full')}
            </Label>
          </Row>
        </>
      )}
    </InvokeModal>
  );
};
