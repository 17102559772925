import React from 'react';
import { CommonJoinerEditFields } from '../../joinerEditor';

const VirtualFocusGroupEdit = props => {
  return (
    <div className={`${props.tabs.edit} open-text-edit`}>
      <CommonJoinerEditFields {...props} />
    </div>
  );
};

export default VirtualFocusGroupEdit;
