import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { isEqual, find } from 'lodash';
import { Button, Input } from 'reactstrap';
import { Switch } from 'antd';
import { sendWSMessage } from '../../../../util/websocket';
import { isClosed, isPending, isLiveMode } from '../../../../util/sessionUtil';
import {
  getRDQuestionJoiners,
  getRDQuestionJoinerResponseRates
} from '../../../../store/redux/selectors/researchDashboardSelector';
import { getNextJoinerToDeploy } from './DeployUtil';
import './DeployWidget.css';

// From DeployType.java
const MANUAL = 'Manual';
const AUTO = 'Auto';

function showWidget(session, canDeploy) {
  if (!canDeploy) {
    return false;
  }
  if (isClosed(session)) {
    return false;
  }
  if (isPending(session) && session.loginState.state !== 'Enabled') {
    return false;
  }
  return isLiveMode(session);
}

function skipUpdate(prevProps, nextProps) {
  return isEqual(prevProps.session, nextProps.session);
}

export const DeployWidget = memo(props => {
  const { sessionId, session, canDeploy } = props;

  const questionJoiners = useSelector(state => getRDQuestionJoiners(state, sessionId), shallowEqual);
  const questionJoinerResponseRates = useSelector(
    state => getRDQuestionJoinerResponseRates(state, sessionId),
    shallowEqual
  );
  const { currentJoinerId } = session;
  const [deployConfig, setDeployConfigInState] = useState(session.deployConfig || {});

  // When session.deployConfig changes, update state.deployConfig
  useEffect(() => {
    if (session.deployConfig) {
      setDeployConfigInState(session.deployConfig);
    }
  }, [session.deployConfig]);

  // When state.deployConfig.deployType changes, trigger a call to updateDeployConfig()
  useEffect(() => {
    if (session.id && !isEqual(session.deployConfig, deployConfig)) {
      updateDeployConfig();
    }
  }, [deployConfig.deployType]);

  function getResponseRate(joiner) {
    return questionJoinerResponseRates[joiner.id] || {};
  }

  function getDeployLabel(lastDeployed) {
    if (!lastDeployed) {
      return intl.get('app.deployWidget.questionLabel', { qNum: 0 });
    }
    const responseRate = getResponseRate(lastDeployed);
    if (responseRate.positionAnswerPcts) {
      let { position } = responseRate;
      position = position === 0 ? 1 : position;
      return intl.get('app.deployWidget.rotationLabel', { qNum: lastDeployed.questionNum, pos: position });
    }
    if (lastDeployed.container) {
      return intl.get('app.deployWidget.pageLabel', { pageNum: lastDeployed.questionNum });
    }
    return intl.get('app.deployWidget.questionLabel', { qNum: lastDeployed.questionNum });
  }

  function getProgressPct(lastDeployed) {
    if (!lastDeployed) {
      return 0;
    }
    const responseRate = getResponseRate(lastDeployed);
    if (responseRate.positionAnswerPcts) {
      // concept rotation
      const position = responseRate.position === 0 ? 0 : responseRate.position - 1;
      return responseRate.positionAnswerPcts[position];
    }
    return responseRate.ansPct;
  }

  function deployNext(joiner) {
    if (joiner) {
      const responseRate = getResponseRate(joiner);
      const position = responseRate.position >= 0 ? responseRate.position + 1 : -1;
      sendWSMessage({
        action: 'deployQuestion',
        joinerId: joiner.id,
        position: position,
        sessionId
      });
    }
  }

  function updateDeployConfig() {
    sendWSMessage({
      action: 'deployConfig',
      deployConfig,
      sessionId
    });
  }

  function toggleDeployType() {
    setDeployConfigInState({
      deployType: deployConfig.deployType === MANUAL ? AUTO : MANUAL,
      autoDeployPercentage: deployConfig.autoDeployPercentage
    });
  }

  function updateDeployPercentage(e) {
    setDeployConfigInState({
      deployType: deployConfig.deployType,
      autoDeployPercentage: e.target.value
    });
  }

  function onKeyDown(e) {
    if (e.keyCode === 13) {
      updateDeployConfig();
    }
  }

  const lastDeployed = find(questionJoiners, j => j.id === currentJoinerId);
  const lastDeployedLabel = getDeployLabel(lastDeployed);
  const progressPct = getProgressPct(lastDeployed);
  const nextJoiner = getNextJoinerToDeploy(true, questionJoiners, questionJoinerResponseRates, currentJoinerId);

  return (
    <div className="rd-deploy-widget" style={{ visibility: showWidget(session, canDeploy) ? 'visible' : 'hidden' }}>
      <div className="survey-progress">
        {lastDeployedLabel} {progressPct}%
      </div>
      <Button className="deploy-button" disabled={!nextJoiner || !canDeploy} onClick={() => deployNext(nextJoiner)}>
        {intl.get('app.deployNext')}
      </Button>
      <Switch
        className="auto-manual-deploy-switch"
        checked={deployConfig.deployType === AUTO}
        checkedChildren={intl.get('app.automatic')}
        unCheckedChildren={intl.get('app.manual')}
        disabled={!canDeploy}
        onChange={toggleDeployType}
      />
      {session.deployConfig && (
        <>
          <Input
            className="auto-deploy-percent-input"
            disabled={deployConfig.deployType === MANUAL || !canDeploy}
            value={deployConfig.autoDeployPercentage}
            onChange={updateDeployPercentage}
            onKeyDown={onKeyDown}
          />{' '}
          %
        </>
      )}
    </div>
  );
}, skipUpdate);
