import React from 'react';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { InvokeTable, SearchInput } from 'webapp-common';
import localtime from '../../../../util/localtime';
import DeleteModal from './DeleteModal';
import RestoreConfirmationModal from './RestoreConfirmationModal';
import RestoreErrorModal from './RestoreErrorModal';
import appConfig from '../../../../appConfig';

const ARCHIVED = 'ARCHIVED';

class ArchivedProjectsPage extends React.Component {
  state = {
    showDeleteModal: false,
    showRestoreConfirmationModal: false,
    showRestoreNotAvailableModal: false,
    selectedProject: {},
    filter: [],
    sortBy: '',
    sortOrder: ''
  };

  componentDidMount() {
    this.props.fetchArchivedProjects();
  }

  sort(sortBy, sortOrder) {
    this.setState({ sortBy, sortOrder });
    this.props.fetchArchivedProjects({ sortBy, sortOrder, filter: this.state.filter });
  }

  paginate(pageNumber, pageSize, sortBy, sortOrder) {
    this.props.fetchArchivedProjects({ pageNumber, pageSize, sortBy, sortOrder, filter: this.state.filter });
  }

  searchForProjectsDebounced = debounce(value => {
    this.setState({ filter: value.split(',') }, () => {
      this.props.fetchArchivedProjects({
        filter: this.state.filter,
        sortBy: this.state.sortBy,
        sortOrder: this.state.sortOrder
      });
    });
  }, 500);

  handleSearchChange = e => {
    this.searchForProjectsDebounced(e.target.value);
  };

  onSortingChange = ({ sortBy, sortOrder }) => {
    this.setState({
      selectedProject: {}
    });
    return this.sort(sortBy, sortOrder);
  };

  onPaginationChange = ({ pageNumber, pageSize, sortBy, sortOrder }) => {
    this.setState({
      selectedProject: {}
    });
    return this.paginate(pageNumber, pageSize, sortBy, sortOrder);
  };

  getPagination = archivedProjects => {
    if (!archivedProjects) {
      return {};
    }
    const { totalElements, pagination = {} } = this.props;
    return {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      totalSize: totalElements
    };
  };

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });
  };

  toggleRestoreConfirmationModal = () => {
    this.setState({
      showRestoreConfirmationModal: !this.state.showRestoreConfirmationModal
    });
  };

  toggleRestoreNotAvailableModal = () => {
    this.setState({
      showRestoreNotAvailableModal: !this.state.showRestoreNotAvailableModal,
      selectedProject: {}
    });
  };

  checkRestoreCriteria = () => {
    const archivedDate = this.state.selectedProject.modifiedDate;
    const archiveDateThresholdString = appConfig.projectArchiveThreshold || '12/25/2018'; //read from config
    const archivedDateThreshold = Date.parse(archiveDateThresholdString);
    if (archivedDate < archivedDateThreshold) {
      this.toggleRestoreNotAvailableModal();
    } else {
      this.toggleRestoreConfirmationModal();
    }
  };

  deleteArchivedProject = () => {
    this.props.deleteArchivedProject(this.state.selectedProject);
  };

  onRestoreProject = () => {
    this.props.restoreArchivedProject(this.state.selectedProject);
  };

  formatTitle = info => <span title={info.getValue()}>{info.getValue()}</span>;
  formatTime = info => localtime.getFormattedDate(info.getValue());
  formatStatus = info => intl.get(`app.archive.status.${info.getValue()}`);

  onRowSelect = row => {
    const selectedProject = this.state.selectedProject.id === row.id ? {} : row;
    this.setState({
      selectedProject
    });
    return false;
  };

  render() {
    const { archiveProjectListRequested, archivedProjects = [], abilities } = this.props;
    const pagination = this.getPagination(archivedProjects);
    const deleteEnabled = !!this.state.selectedProject.id && this.state.selectedProject.archiveStatus === ARCHIVED;
    const restoreEnabled = !!this.state.selectedProject.id && this.state.selectedProject.archiveStatus === ARCHIVED;

    const columns = [
      {
        accessorKey: 'name',
        header: intl.get('app.projectName'),
        cell: this.formatTitle,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'modifiedDate',
        header: intl.get('app.archivedDate'),
        cell: this.formatTime
      },
      {
        accessorKey: 'archiveStatus',
        header: intl.get('app.status'),
        cell: this.formatStatus
      }
    ];

    const tableProps = {
      className: 'invoke-table',
      columns,
      data: archivedProjects,
      initialState: {
        sorting: [
          {
            id: 'modifiedDate',
            desc: true
          }
        ]
      },
      loading: archiveProjectListRequested,
      pagination,
      onPaginationChange: this.onPaginationChange,
      onSortingChange: this.onSortingChange,
      onRowSelect: this.onRowSelect
    };

    return (
      <>
        <div>
          <SearchInput placeholder={intl.get('app.search')} onChange={this.handleSearchChange} />
        </div>
        <div className="mt-3">
          <InvokeTable {...tableProps} />
        </div>
        {abilities.hasProjectManage && (
          <div className="flex-div">
            <Button
              className="primary mb-4 fixed-button-width"
              onClick={this.checkRestoreCriteria}
              disabled={!restoreEnabled}
            >
              {intl.get('app.restore')} {intl.get('app.project')}
            </Button>
            <Button
              className="btn-warn mb-4 fixed-button-width"
              onClick={this.toggleDeleteModal}
              disabled={!deleteEnabled}
            >
              {intl.get('app.delete')} {intl.get('app.project')}
            </Button>
          </div>
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            toggle={this.toggleDeleteModal}
            onSave={this.deleteArchivedProject}
            project={this.state.selectedProject.name}
          />
        )}
        {this.state.showRestoreConfirmationModal && (
          <RestoreConfirmationModal
            toggle={this.toggleRestoreConfirmationModal}
            onSave={this.onRestoreProject}
            projectName={this.state.selectedProject.name}
          />
        )}
        {this.state.showRestoreNotAvailableModal && <RestoreErrorModal toggle={this.toggleRestoreNotAvailableModal} />}
      </>
    );
  }
}
export default ArchivedProjectsPage;
