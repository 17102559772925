import { takeLatest, takeEvery } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import { subscribeToChannel } from '../../../util/websocket';

import {
  fetchParticipantDataDefActions,
  fetchParticipantsActions,
  fetchEnrollmentInfoActions,
  fetchScreenersAnswersTotalActions,
  saveParticipantActiveActions,
  pingParticipantDataActions,
  subscribeScreenerSummaryActions,
  fetchScreenerPreviewResultsActions,
  setVFGstatusActions,
  endVFGMeetingActions
} from '../actions/participantDataActions';
import {
  fetchParticipantDataDef,
  fetchParticipantData,
  fetchEnrollmentInfo,
  saveParticipantActive,
  fetchScreenerPreviewResults,
  setVFGstatus,
  endVFGMeetingApi
} from '../../../api/participantData';

function* participantDataDefSaga() {
  const saga = createFetchSaga(fetchParticipantDataDefActions, fetchParticipantDataDef);
  yield* saga();
}

function* participantDataSaga() {
  const saga = createFetchSaga(fetchParticipantsActions, fetchParticipantData);
  yield* saga();
}

function* enrollmentInfoSaga() {
  const saga = createFetchSaga(fetchEnrollmentInfoActions, fetchEnrollmentInfo);
  yield* saga();
}

function* subscribeScreenerSummary({ payload: { subAction, sessionId } }) {
  yield* subscribeToChannel({
    action: 'screenerSummary',
    subAction,
    key: sessionId,
    sessionId
  });
}

function* screenerSummarySaga() {
  yield takeEvery(subscribeScreenerSummaryActions.request, subscribeScreenerSummary);
}

function* fetchScreenersAnswersTotal({ payload }) {
  yield* subscribeToChannel({
    ...payload,
    action: 'screenerResponseData',
    key: payload.sessionId
  });
}

function* participantsAnswersTotalSaga() {
  yield takeEvery(fetchScreenersAnswersTotalActions.request, fetchScreenersAnswersTotal);
}

function handleSaveParticipantActive() {
  toast.success({ text: intl.get('app.participant.active.updated') });
}

function* saveParticipantActiveSaga() {
  const saga = createFetchSaga(saveParticipantActiveActions, saveParticipantActive);
  yield* saga();
  yield takeLatest(saveParticipantActiveActions.succeeded, handleSaveParticipantActive);
}

function* pingParticipantDataSaga() {
  const saga = createFetchSaga(pingParticipantDataActions, fetchParticipantData);
  yield* saga();
}

function* screenerPreviewResultsSaga() {
  const saga = createFetchSaga(fetchScreenerPreviewResultsActions, fetchScreenerPreviewResults);
  yield* saga();
}

function* setVFGstatusSaga() {
  const saga = createFetchSaga(setVFGstatusActions, setVFGstatus);
  yield* saga();
}

function* endVFGMeetingSaga() {
  const saga = createFetchSaga(endVFGMeetingActions, endVFGMeetingApi);
  yield* saga();
}

export {
  participantDataDefSaga,
  participantDataSaga,
  enrollmentInfoSaga,
  participantsAnswersTotalSaga,
  saveParticipantActiveSaga,
  pingParticipantDataSaga,
  screenerSummarySaga,
  screenerPreviewResultsSaga,
  setVFGstatusSaga,
  endVFGMeetingSaga
};
