import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { get } from 'lodash';
import { getRDConfig, getRDSessionQuotaFilters } from '../../../store/redux/selectors/researchDashboardSelector';
import { EditFiltersModal } from './EditFiltersModal';

import './FiltersComponent.css';

function isGeneralFilter(filter) {
  return filter.type === 'GENERAL_FILTER';
}

export const FiltersComponent = props => {
  const { sessionId, filterParticipants, filterListConfig, isFilterApplied } = props;

  const [editFiltersModalOpen, setEditFiltersModalOpen] = useState(false);

  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  const quotaFilters = useSelector(state => getRDSessionQuotaFilters(state, sessionId), shallowEqual);

  function getFilterRow(filter) {
    const cssClasses = {
      filter: true,
      clickable: true,
      selected: isFilterApplied(filter, filterListConfig)
    };
    return (
      <div className="filter-row" key={filter.name}>
        <div className={classnames(cssClasses)} title={filter.name} onClick={() => filterParticipants(filter)}>
          {filter.name}
        </div>
      </div>
    );
  }

  function getFilters() {
    const filters = get(rdConfig, 'configs.filterListConfig.filters') || [];
    const generalFilters = filters.filter(f => isGeneralFilter(f));
    return generalFilters.length || quotaFilters.length ? (
      [...quotaFilters, ...generalFilters].map(filter => getFilterRow(filter))
    ) : (
      <p className="mt-4 ms-4">{intl.get('app.noFiltersCreated')}</p>
    );
  }

  function toggleEditFiltersModal() {
    setEditFiltersModalOpen(!editFiltersModalOpen);
  }

  return (
    <section className="rd-filters">
      <div className="filters-header">
        <span>{intl.get('app.filters')}</span>
        <span className="link" onClick={toggleEditFiltersModal}>
          {intl.get('app.edit')}
        </span>
      </div>
      <div className="filters-container">{getFilters()}</div>
      {editFiltersModalOpen && <EditFiltersModal sessionId={sessionId} toggle={toggleEditFiltersModal} />}
    </section>
  );
};
