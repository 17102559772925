import { connect } from 'react-redux';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { AdminPage } from './AdminPage';

const mapStateToProps = state => {
  const {
    abilities: { isInternalSuperUser }
  } = sessionUserSelector(state);
  return {
    isInternalSuperUser
  };
};

export default connect(mapStateToProps)(AdminPage);
