import { handleActions } from 'redux-actions';
import { newSessionDataActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdSessionReducer = handleActions(
  {
    [newSessionDataActions.succeeded](state, response) {
      const session = response.payload;
      return {
        ...state,
        [session.id]: {
          session
        }
      };
    }
  },
  initialState
);
