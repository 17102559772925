import { handleActions } from 'redux-actions';
import { newQuestionJoinerResponseRateDataActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdQuestionJoinerResponseRateReducer = handleActions(
  {
    [newQuestionJoinerResponseRateDataActions.succeeded](state, { payload }) {
      const { sessionId, questionJoinerResponseRates } = payload;
      const responseRates = (state[sessionId] && state[sessionId].questionJoinerResponseRates) || {};
      return {
        ...state,
        [sessionId]: {
          questionJoinerResponseRates: { ...responseRates, ...questionJoinerResponseRates }
        }
      };
    }
  },
  initialState
);
