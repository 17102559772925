import { takeLatest, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  createDataTable,
  fetchDataTableList,
  fetchAllDataTables,
  fetchDataTable,
  fetchDataTableRecords,
  deleteDataTable
} from '../../../api/dataTableApi';
import {
  createDataTableActions,
  fetchDataTableListAction,
  fetchAllDataTablesActions,
  fetchDataTableActions,
  fetchDataTableRecordsAction,
  deleteDataTableAction,
  replaceDataTableActions
} from '../actions/dataTableActions';

function* createDataTableSaga() {
  const saga = createFetchSaga(createDataTableActions, createDataTable);
  yield* saga();
  yield takeLatest(createDataTableActions.succeeded, handleDataTableCreated);
}

function* handleDataTableCreated({ payload: dataTable }) {
  if (dataTable) {
    toast.success({ text: intl.get('app.dataTable.created', { name: dataTable.name }) });
    yield put(fetchDataTableListAction.request({}));
  }
}

function* replaceDataTableSaga() {
  const saga = createFetchSaga(replaceDataTableActions, createDataTable);
  yield* saga();
  yield takeLatest(replaceDataTableActions.succeeded, handleDataTableReplaced);
}

function* handleDataTableReplaced({ payload: dataTable }) {
  if (dataTable) {
    toast.success({ text: intl.get('app.dataTable.replaced', { name: dataTable.name }) });
    const dataTableId = dataTable.id;
    yield put(fetchDataTableActions.request({ dataTableId }));
    yield put(fetchDataTableRecordsAction.request({ dataTableId }));
  }
}

function* fetchDataTableListSaga() {
  const saga = createFetchSaga(fetchDataTableListAction, fetchDataTableList);
  yield* saga();
}

function* fetchAllDataTablesSaga() {
  const saga = createFetchSaga(fetchAllDataTablesActions, fetchAllDataTables);
  yield* saga();
}

function* fetchDataTableSaga() {
  const saga = createFetchSaga(fetchDataTableActions, fetchDataTable);
  yield* saga();
}
function* fetchDataTableRecordsSaga() {
  const saga = createFetchSaga(fetchDataTableRecordsAction, fetchDataTableRecords);
  yield* saga();
}
function* deleteDataTableSaga() {
  const saga = createFetchSaga(deleteDataTableAction, deleteDataTable);
  yield* saga();
}
export {
  createDataTableSaga,
  fetchDataTableListSaga,
  fetchAllDataTablesSaga,
  fetchDataTableSaga,
  fetchDataTableRecordsSaga,
  deleteDataTableSaga,
  replaceDataTableSaga
};
