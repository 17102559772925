import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import { FormGroup, Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

import './CreateClient.css';

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const CreateClient = props => {
  const [state, setState] = useReducer(reducer, {
    tenantName: '',
    clientDomain: '',
    fullServe: true,
    maxPasswordAgeEnabled: false
  });

  const updateState = (name, value) => {
    setState({
      [name]: value
    });
  };

  const isSaveEnabled = () => {
    return state.tenantName.trim() && state.clientDomain.trim();
  };

  const createClient = () => {
    props.createClient(state);
  };

  return (
    <InvokeModal
      className="create-client-modal"
      showModal={true}
      toggle={props.toggle}
      modalTitle={intl.get('app.addClient')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      save={createClient}
      enableSave={isSaveEnabled()}
    >
      <FormGroup row>
        <label>{intl.get('app.name')}</label>
        <Input type="text" value={state.tenantName} onChange={e => updateState('tenantName', e.target.value)} />
      </FormGroup>
      <FormGroup row>
        <label>{intl.get('app.domain')}</label>
        <Input type="text" value={state.clientDomain} onChange={e => updateState('clientDomain', e.target.value)} />
      </FormGroup>
    </InvokeModal>
  );
};

export default CreateClient;
