import React, { useEffect } from 'react';
import intl from 'react-intl-universal';

import './FiltersMenu.css';

export const FiltersMenu = props => {
  const { rdConfig, quotaFilters, selectFilter, toggleSelectDataPointsModal, toggleEditFiltersModal, handleMouseMove } =
    props;

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  function getFilterMenuItems() {
    if (!rdConfig) {
      return null;
    }
    const { filterListConfig } = rdConfig.configs;
    const allFilters = filterListConfig.filters.filter(f => f.type === 'GENERAL_FILTER');
    quotaFilters.forEach(f => allFilters.push(f));
    return allFilters.map(filter => {
      const disabled =
        filter.type === 'QUOTA_FILTER'
          ? filter.id === filterListConfig.selected
          : filter.name === filterListConfig.selected;
      const displayName = filter.type === 'QUOTA_FILTER' ? <i>{filter.name}</i> : filter.name;
      return (
        <div className="selectable-menu-item" disabled={disabled} onClick={() => selectFilter(filter)} key={filter.id}>
          {displayName}
        </div>
      );
    });
  }

  return (
    <div className="filters-selection-menu">
      <div className="menu top-menu">
        <div className="selectable-menu-item" onClick={toggleSelectDataPointsModal}>
          <span>{intl.get('app.newQuickFilter')}</span>
        </div>
        <div className="scrollable-menu-items">{getFilterMenuItems()}</div>
        <div className="edit-filters selectable-menu-item" onClick={toggleEditFiltersModal}>
          {intl.get('app.editFilters')}
        </div>
      </div>
    </div>
  );
};
