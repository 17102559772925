import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { isEqual } from 'lodash';
import { sendWSMessage } from '../../../../util/websocket';
import { isClosed, isLiveMode } from '../../../../util/sessionUtil';

import './LoginStatusWidget.css';

const AUTO = 'null';
const ENABLED = 'true';
const DISABLED = 'false';

function showWidget(session, canManageLogins) {
  if (!canManageLogins) {
    return false;
  }
  return isLiveMode(session) && !isClosed(session);
}

function getSelectedValue(session) {
  if (session.enableSessionLogin === null) {
    return AUTO;
  }
  return session.enableSessionLogin ? ENABLED : DISABLED;
}

function getAutoOptionLabel(session) {
  if (session.enableSessionLogin === null) {
    return `${intl.get('app.automatic')} (${session.loginState.state})`;
  }
  return intl.get('app.automatic');
}

function skipUpdate(prevProps, nextProps) {
  return isEqual(prevProps.session, nextProps.session);
}

export const LoginStatusWidget = memo(props => {
  const { session, canManageLogins } = props;

  function updateSessionLoginStatus(e) {
    const { value } = e.target;
    const enableSessionLogin = value === AUTO ? null : value === ENABLED;
    sendWSMessage({
      action: 'logins',
      enableSessionLogin,
      sessionId: session.id
    });
  }

  const borderColor = session.enableSessionLogin === false ? '#bb1e10' : '#008315';

  return (
    <div
      className="rd-login-status-widget"
      style={{
        visibility: showWidget(session, canManageLogins) ? 'visible' : 'hidden',
        border: `1px solid ${borderColor}`,
        borderRadius: '4px',
        padding: '0.25rem 0.5rem'
      }}
    >
      {intl.get('app.logins')}:
      <Input
        type="select"
        value={getSelectedValue(session)}
        onChange={updateSessionLoginStatus}
        disabled={!canManageLogins}
      >
        <option value={AUTO}>{getAutoOptionLabel(session)}</option>
        <option value={ENABLED}>{intl.get('app.enabled')}</option>
        <option value={DISABLED}>{intl.get('app.disabled')}</option>
      </Input>
    </div>
  );
}, skipUpdate);
