import { post, get, del } from '../util/request';

const DATA_TABLE_API = '/a/api/dataTables';
const DATA_TABLE_RECORDS_API = '/a/api/dataTableRecords';

async function createDataTable({ name, dataFile, dataTableId }) {
  const params = {
    name,
    dataFile,
    useType: 'CSV'
  };
  if (dataTableId) {
    params.dataTableId = dataTableId;
  }
  return post('/a/binapi/uploadDataTable')(params).then(res => res.body || {});
}

async function fetchDataTableList(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy || 'modifiedDate',
      sortOrder: apiOptions.sortOrder || 'desc',
      name: apiOptions.name || ''
    }) ||
    {};
  return get(DATA_TABLE_API)(params).then(res => (res.body && res.body.payload) || {});
}

async function fetchAllDataTables() {
  return get(DATA_TABLE_API)({ pageSize: -1, includeDeleted: true }).then(res => (res.body && res.body.payload) || {});
}

async function fetchDataTable({ dataTableId }) {
  const url = `${DATA_TABLE_API}/${dataTableId}`;
  return get(url)().then(res => (res && res.body && res.body.payload) || {});
}

async function fetchDataTableRecords(apiOptions) {
  const { dataTableId } = apiOptions;
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy,
      sortOrder: apiOptions.sortOrder,
      dataTableId
    }) ||
    {};

  return get(DATA_TABLE_RECORDS_API)(params).then(
    res => (res && res.body && res.body.payload && { dataTableId, dataTableRecords: res.body.payload }) || {}
  );
}

async function deleteDataTable({ dataTableId }) {
  const url = `${DATA_TABLE_API}/${dataTableId}`;
  return del(url)({}).then(res => res && res.body && res.body.payload);
}

export {
  createDataTable,
  fetchDataTableList,
  fetchAllDataTables,
  fetchDataTable,
  fetchDataTableRecords,
  deleteDataTable
};
