import React, { memo, useCallback, useEffect, useMemo, useReducer } from 'react';
import { Button, Col } from 'reactstrap';
import intl from 'react-intl-universal';
import { InvokeTable, Loader, SearchInput, usePagination } from 'webapp-common';
import CreateClientModal from './createClientModal/CreateClient';
import { EditClientModal } from './editClientModal/EditClientModal';
import { ConfirmArchiveModal } from './editClientModal/ConfirmArchiveModal';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

const skipUpdate = (prevProps, nextProps) => {
  if (prevProps.createClientInProgress && !nextProps.createClientInProgress) {
    return false;
  }
  if (prevProps.saveClientInProgress && !nextProps.saveClientInProgress) {
    return false;
  }
};

const DEFAULT_CONFIG = {
  pageNum: 1,
  pageSize: 10,
  sortBy: 'clientName',
  sortOrder: 'asc'
};

export const ClientsPage = memo(props => {
  const { tenant, clients, fetchClients, fetchClientsInProgress, createClientInProgress } = props;
  const { pageRequest, totalElements } = clients;

  const [state, setState] = useReducer(reducer, {
    search: '',
    showCreateClientModal: false,
    showEditClientModal: false,
    showConfirmArchive: false
  });

  useEffect(() => {
    fetchClients(DEFAULT_CONFIG);
  }, []);

  useEffect(() => {
    setState({
      showCreateClientModal: false
    });
    if (props.createClientSuccess) {
      fetchClients(DEFAULT_CONFIG);
    }
  }, [props.createClientSuccess, props.createClientInProgress]);

  useEffect(() => {
    setState({
      showEditClientModal: false
    });
    if (props.saveClientSuccess) {
      fetchClients(DEFAULT_CONFIG);
    }
  }, [props.saveClientSuccess, props.saveClientInProgress]);

  const toggleEditClient = useCallback(
    client => {
      setState({
        showEditClientModal: !state.showEditClientModal,
        client
      });
    },
    [state.showEditClientModal]
  );

  const formatNameCell = useCallback(
    info => {
      const client = info.row.original;
      return (
        <label
          title={client.clientName}
          onClick={() => toggleEditClient(client)}
          style={{ width: '100%', cursor: 'pointer', marginBottom: '0', color: '#076ac7' }}
        >
          {client.clientName}
        </label>
      );
    },
    [toggleEditClient]
  );

  const formatDomainCell = useCallback(info => <span title={info.getValue()}>{info.getValue()}</span>, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'clientName',
        header: intl.get('app.name'),
        cell: formatNameCell,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'clientDomain',
        header: intl.get('app.domain'),
        cell: formatDomainCell,
        cellClassName: 'text-truncate'
      }
    ];
  }, [formatDomainCell, formatNameCell]);

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchClients({
        pageNum: 1,
        pageSize: pageRequest.pageSize,
        sortBy,
        sortOrder,
        search: state.search
      });
    },
    [fetchClients, pageRequest.pageSize, state.search]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchClients({
        pageNum: pageNumber,
        pageSize,
        sortBy,
        sortOrder,
        search: state.search
      });
    },
    [fetchClients, state.search]
  );

  const handleSearchInput = useCallback(
    e => {
      setState({ search: e.target.value });
      fetchClients({
        pageNum: 1,
        pageSize: pageRequest.pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder,
        search: e.target.value
      });
    },
    [fetchClients, pageRequest.pageSize, pageRequest.sortBy, pageRequest.sortOrder]
  );

  const toggleAddClientModal = () => {
    setState({
      showCreateClientModal: !state.showCreateClientModal
    });
  };

  const createClient = newClient => {
    props.createClient(newClient);
  };

  const saveClient = tenantName => {
    const temp = {
      ...state.client,
      tenantName
    };
    props.saveClient(temp);
  };

  const toggleArchive = () => {
    setState({
      showConfirmArchive: !state.showConfirmArchive
    });
  };

  const archiveClient = () => {
    props.archiveClient(state.client);
    setState({
      showEditClientModal: false,
      showConfirmArchive: false
    });
  };

  const pagination = usePagination({ pageRequest, totalElements });

  return (
    <div>
      {createClientInProgress && <Loader spinner fullScreen />}
      <Button color="primary" onClick={toggleAddClientModal}>
        {intl.get('app.addClient')}
      </Button>
      <SearchInput placeholder={intl.get('app.search')} onChange={handleSearchInput} className="my-4" />
      <Col sm="5">
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={clients.content}
          initialState={{
            sorting: [
              {
                id: 'clientName',
                desc: false
              }
            ]
          }}
          loading={fetchClientsInProgress}
          pagination={pagination}
          onSortingChange={sort}
          onPaginationChange={paginate}
        />
      </Col>
      {state.showCreateClientModal && <CreateClientModal toggle={toggleAddClientModal} createClient={createClient} />}
      {state.showEditClientModal && (
        <EditClientModal
          client={state.client}
          toggle={toggleEditClient}
          save={saveClient}
          archive={toggleArchive}
          tenant={tenant}
        />
      )}
      {state.showConfirmArchive && (
        <ConfirmArchiveModal toggle={toggleArchive} save={archiveClient} clientName={state.client.clientName} />
      )}
    </div>
  );
}, skipUpdate);
