import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Input } from 'reactstrap';

import './RDQuestionNavigation.css';

const CONCEPT = 'concept';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

export const RDQuestionNavigation = props => {
  const { questions, selectedQuestion, onSelectQuestion } = props;

  const [state, setState] = useReducer(reducer, {
    questionOptions: []
  });

  useEffect(() => {
    prepareData();
  }, [selectedQuestion.id]);

  function selectQuestion(id) {
    const question = questions.find(q => q.id === id);
    onSelectQuestion(question);
  }

  function prepareData() {
    let selectedQuestionFound = false;
    let prev = '';
    let next = '';
    const questionOptions = questions.map(q => {
      if (selectedQuestionFound && q.selectable && !next) {
        next = q;
      }
      if (q.id === selectedQuestion.id) {
        selectedQuestionFound = true;
      }
      if (!selectedQuestionFound && q.selectable) {
        prev = q;
      }
      let label = `${q.number}. ${q.title}`;
      if (q.type === CONCEPT) {
        label = `- ${q.title}`;
      } else if (q.number.indexOf('.') !== -1) {
        label = `${q.number} ${q.title}`;
      }
      return (
        <option value={q.id} disabled={!q.selectable}>
          {label}
        </option>
      );
    });
    setState({
      questionOptions,
      prevQuestion: prev,
      nextQuestion: next
    });
  }

  return (
    <div className="question-nav">
      <div
        className={classnames({ link: true, disabled: !state.prevQuestion })}
        onClick={() => selectQuestion(state.prevQuestion.id)}
      >
        {intl.get('app.previous')}
      </div>
      <Input
        type="select"
        className="question-nav-select"
        value={selectedQuestion.id}
        onChange={e => selectQuestion(e.target.value)}
      >
        {state.questionOptions}
      </Input>
      <div
        className={classnames({ link: true, disabled: !state.nextQuestion })}
        onClick={() => selectQuestion(state.nextQuestion.id)}
      >
        {intl.get('app.next')}
      </div>
    </div>
  );
};
