import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import { ConfirmModal } from '../../../components/core/modal/ConfirmModal';
import { panelConfigUtil } from './panelConfigUtil';
import { EditPanelConfig } from './EditPanelConfig';

export const PanelConfigModal = props => {
  const [config, setConfig] = useState(props.config);
  const [showConfirmDelete, setShowConfirmDelete] = useState();

  function save() {
    props.saveConfig(config);
  }

  function deleteConfig() {
    props.deleteConfig(config);
  }

  function saveEnabled() {
    return panelConfigUtil.isValid(config, true) && !panelConfigUtil.equals(props.config, config);
  }

  return (
    <>
      <InvokeModal
        showModal
        className="panel-config-modal"
        modalTitle={intl.get('app.panelConfig.modal.title')}
        toggle={props.toggle}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        enableSave={saveEnabled()}
        save={save}
        deleteButtonText={config.id ? intl.get('app.delete') : ''}
        enableDelete
        delete={() => setShowConfirmDelete(true)}
      >
        <EditPanelConfig config={config} setConfig={setConfig} />
      </InvokeModal>
      {showConfirmDelete && (
        <ConfirmModal
          showModal
          modalTitle={intl.get('app.deletePanel')}
          toggle={() => setShowConfirmDelete(false)}
          onConfirm={deleteConfig}
        >
          <div>{intl.get('app.deletePanelConfirm', { name: config.name })}</div>
        </ConfirmModal>
      )}
    </>
  );
};
