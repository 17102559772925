import React, { useReducer } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { Input } from 'reactstrap';
import {
  getRDConfig,
  getRDQuestionJoinerResponseRates
} from '../../../store/redux/selectors/researchDashboardSelector';
import { saveRDConfig } from '../../../util/researchDashboardUtil';

import './RDPositionFilters.css';

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const RDPositionFilters = props => {
  const { sessionId, selectedQuestion } = props;

  const questionDefId = selectedQuestion.joiner.def.id;
  const checkboxId = `selectAllInputCheckbox-${questionDefId}`;

  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  const questionJoinerResponseRates = useSelector(
    state => getRDQuestionJoinerResponseRates(state, sessionId),
    shallowEqual
  );

  const getResponseRate = id => {
    return questionJoinerResponseRates[id] || {};
  };

  const getPositionsForQuestion = question => {
    if (!question && !question.joiner) {
      return {};
    }
    const { joiner } = question;
    const responseRate = getResponseRate(joiner.conceptRotationId);
    if (!responseRate || !responseRate.positionAnswerPcts) {
      return {};
    }
    const availablePositions = responseRate.positionAnswerPcts.map((v, i) => i + 1);
    const { questionsConfigMap } = rdConfig.configs.questionsConfig;
    return {
      availablePositions,
      selectedPositions:
        questionsConfigMap && questionsConfigMap[questionDefId] && questionsConfigMap[questionDefId].rotationPositions
    };
  };

  const { availablePositions, selectedPositions } = getPositionsForQuestion(selectedQuestion);
  const selectedPositionsInUse = !selectedPositions ? availablePositions : selectedPositions;

  const [state, setState] = useReducer(reducer, {
    showSelect: false,
    selectAll:
      selectedPositionsInUse && availablePositions && selectedPositionsInUse.length === availablePositions.length
  });

  const handlePositionChange = value => {
    let selected = cloneDeep(selectedPositionsInUse);
    if (value === 0) {
      if (selected.length === availablePositions.length) {
        selected.splice(0);
      } else {
        selected = availablePositions;
      }
      setState({ selectAll: !state.selectAll });
    } else {
      const index = selected.indexOf(value);
      if (index >= 0) {
        selected.splice(index, 1);
      } else {
        selected.push(value);
        if (selected.length > 1) {
          selected.sort((a, b) => a - b);
        }
      }
    }
    const myConfig = {
      ...rdConfig,
      configs: {
        ...rdConfig.configs,
        questionsConfig: {
          ...rdConfig.configs.questionsConfig,
          questionsConfigMap: {
            ...rdConfig.configs.questionsConfig.questionsConfigMap,
            [questionDefId]: {
              rotationPositions: selected
            }
          }
        }
      }
    };
    saveRDConfig(myConfig);
  };

  const getPositions = () => {
    if (!availablePositions) {
      return null;
    }
    return [
      <li key="0" value="0" className="position-option" onClick={() => handlePositionChange(0)}>
        <Input
          type="checkbox"
          id={checkboxId}
          checked={selectedPositionsInUse.length === availablePositions.length}
          className="me-5"
        />
        <span>
          {selectedPositionsInUse.length === availablePositions.length
            ? intl.get('app.unselectAll')
            : intl.get('app.selectAll')}
        </span>
      </li>,
      ...availablePositions.map((val, index) => {
        return (
          <li key={`position${val}`} value={val} className="position-option" onClick={() => handlePositionChange(val)}>
            <Input type="checkbox" checked={selectedPositionsInUse.indexOf(val) >= 0} className="me-5" />
            <span>{intl.get('app.rd.filterPosition', { position: val })}</span>
          </li>
        );
      })
    ];
  };

  const toggleShowSelect = () => {
    setState({ showSelect: !state.showSelect });
  };

  const positionsShown =
    selectedPositionsInUse && availablePositions && selectedPositionsInUse.length === availablePositions.length
      ? 'all'
      : (selectedPositionsInUse && selectedPositionsInUse.toString()) || intl.get('app.rd.noneSelected');
  const positionText = intl.get('app.rd.showSelectedPosition', { positions: positionsShown });
  const selectAllInput = document.getElementById(checkboxId);
  if (selectAllInput) {
    selectAllInput.indeterminate =
      selectedPositionsInUse.length > 0 && selectedPositionsInUse.length < availablePositions.length;
  }

  return (
    <div className="rd-position-filters">
      <div className="toggle-selector" onClick={toggleShowSelect}>
        <input className="text-truncate" placeholder={positionText} title={positionText} disabled />
        <span className="selector-button">
          <i className="fas fa-angle-down" />
        </span>
      </div>
      <ol className="selector" style={{ display: state.showSelect ? '' : 'none' }}>
        {getPositions()}
      </ol>
    </div>
  );
};
