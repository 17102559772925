import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { chain, sortBy } from 'lodash';
import { sendWSMessage } from '../../util/websocket';
import { mediaUtil } from '../../util/mediaUtil';
import { Button } from 'reactstrap';
import chatUtil from './chatUtil';
import { ENGLISH, isBipolar } from '../../util/joinerUtil';
import { closeChatWindowAction } from '../../store/redux/actions/researchDashboardActions';
import { useSessionUserSelector } from '../../customHooks/reduxHelper';

import './ChatWindow.css';

function hasUnreadParticipantMessages(chat) {
  return chat.messages.some(message => !message.read && chatUtil.isParticipantMessage(message, chat));
}

function setUnreadParticipantMessagesToRead(chat) {
  const clonedChat = chain(chat).cloneDeep().omit(['answers', 'questionText', 'responseSet', 'stim']).value();
  clonedChat.messages.forEach(message => {
    if (!message.read && chatUtil.isParticipantMessage(message, chat)) {
      message.read = true;
    }
  });
  return clonedChat;
}

function getMatrixAnswers(responseList, answers, bipolar) {
  // make sure the rows are in order:
  const answerRows = bipolar ? responseList.bipolarRows : responseList.rows;
  const sortedRows = sortBy(answerRows, row => row.index);

  const output = [];
  sortedRows.forEach(row => {
    if (!!answers[row.id]) {
      const { caption } = row.value.imageStim || {};
      const { abbreviatedValue, value } = row.value;
      const rowAnswers = answers[row.id].map(id => {
        return responseList.columnData.columns.find(col => col.id === id).value.value;
      });
      output.push(`${caption || abbreviatedValue || value}: ${rowAnswers.join(', ')}`);
    }
  });
  return output;
}

function getMCAnswers(responseList, answers) {
  const output = [];
  answers.forEach(answerId => {
    const ans = responseList.find(r => r.id === answerId);
    if (ans && ans.value) {
      const { caption } = ans.value.imageStim || {};
      const { value } = ans.value;
      output.push(caption || value);
    }
  });
  return output;
}

function getStimThumbnail(stim) {
  switch (stim.type) {
    case 'image':
      return <img src={mediaUtil.getMediaUrl(stim.media)} alt={stim.altTag} />;
    case 'video':
      return <i className="fa fa-video" />;
    default:
      return null;
  }
}

/*
 * Note that responseSet may either be a responseSet object with either an entries (for matrix) or choices (for choicelist) attr,
 * or it may just be the list of response entries/choices. It all depends on what the caller passes in to this component.
 */
function getAnswers(answers, responseSet, viewLanguage) {
  if (!answers) {
    return null;
  }
  let responseList;
  switch (answers.type) {
    case 'MATRIX':
      responseList = responseSet.entries || responseSet;
      return responseSet ? getMatrixAnswers(responseList, answers.value, isBipolar(responseSet)) : null;
    case 'CHOICELIST':
      responseList = responseSet.choices || responseSet;
      return responseSet ? getMCAnswers(responseList, answers.value) : null;
    default:
      // plain text
      return viewLanguage === ENGLISH ? [answers.value] : [answers.origValue];
  }
}

export const ChatWindow = props => {
  const { chat, userId } = props;
  const { hasProjectManage } = useSessionUserSelector().abilities;
  const messageArea = useRef();
  const textInput = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEnded() && !chat.messageRead) {
      return;
    }
    if (chat.windowState === 'open' && (hasUnreadParticipantMessages(chat) || chat.messageRead)) {
      sendChatMessage('setChatReadMessages', setUnreadParticipantMessagesToRead(chat));
    }
    if (messageArea.current) {
      setTimeout(() => (messageArea.current.scrollTop = messageArea.current.scrollHeight), 0);
    }
  }, [chat.messages.length]);

  /**
   * Returns a stripped-down chat object to send to the server when sending a new message or the end-chat request.
   */
  function getStrippedChatObject() {
    return chain(chat).cloneDeep().omit(['answers', 'questionText', 'responseSet', 'stim', 'messages']).value();
  }

  function sendChatMessage(operation, chat) {
    sendWSMessage({
      action: 'chat',
      operation,
      chat
    });
  }

  function sendMessage() {
    const message = textInput.current.value.trim();
    if (message) {
      const chat = getStrippedChatObject();
      chat.messages = [
        {
          chatId: chat.id || '',
          ownerId: userId,
          message
        }
      ];
      sendChatMessage('sendChatMsg', chat);
      textInput.current.value = '';
    }
  }

  function endChat() {
    if (getMessages().length === 0) {
      // Chat has no msgs, so just close the window.
      dispatch(closeChatWindowAction.request(chat));
      return;
    }
    const strippedChat = getStrippedChatObject();
    strippedChat.state = chatUtil.chatState.ended;
    sendChatMessage('endChat', strippedChat);
    dispatch(closeChatWindowAction.request(strippedChat));
  }

  function handleWindowCommandIconClick() {
    dispatch(closeChatWindowAction.request(chat));
  }

  function isEnded() {
    return chatUtil.isEnded(chat);
  }

  function getMessages() {
    return chat.messages || [];
  }

  function getQuestionText() {
    let questionText = chat.questionText;
    if (!questionText) {
      questionText = chat.stim && (chat.stim.caption || chat.stim.contents);
    }
    return questionText;
  }

  function getTextBubbleClass(msg) {
    return props.userId === msg.ownerId || !chat.participantIds.some(pid => pid === msg.ownerId) ? 'right' : 'left';
  }

  function getTextBubble(msg, index) {
    const classes = {
      'text-bubble': true,
      [getTextBubbleClass(msg)]: true
    };

    return (
      <div className={classnames(classes)} key={`chatMsg-${index}`}>
        {msg.message}
      </div>
    );
  }

  function getTextBubbles() {
    const msgs = getMessages();
    return msgs.map((msg, index) => getTextBubble(msg, index));
  }

  const headerClasses = {
    'chat-header': true
  };

  const closeIconClassName = 'close-chat-window-icon';
  const closeIconContents = '\u00D7'; // &times;

  const questionText = getQuestionText();

  let answers;
  let fullAnswerText = '';
  if (chat.answers && chat.participantIds.length === 1) {
    answers = getAnswers(chat.answers[chat.participantIds[0]], chat.responseSet, props.viewLanguage);
    if (answers) {
      if (answers.length === 1) {
        answers = answers[0];
        fullAnswerText = answers;
      } else {
        answers = (
          <select>
            {answers.map(ans => (
              <option key={ans}>{ans}</option>
            ))}
          </select>
        );
      }
    }
  }

  return (
    <div className="chat-window" style={{ right: props.right }}>
      <div className={classnames(headerClasses)}>
        <span className="window-title">{chat.chatTitle}</span>
        <span className="chat-window-command-icon" onClick={handleWindowCommandIconClick}>
          <span className={closeIconClassName}>{closeIconContents}</span>
        </span>
      </div>
      {(questionText || chat.stim) && (
        <div className="chat-question-container">
          <div className="question">
            <span>{intl.get('app.chat.questionAbbrev')}:</span>
            <span>
              {chat.stim && chat.stim.media && getStimThumbnail(chat.stim)}
              <span title={questionText}>{questionText}</span>
            </span>
          </div>
          {answers && (
            <div className="answer">
              <span>{intl.get('app.chat.answerAbbrev')}:</span>
              <span title={fullAnswerText}>{answers}</span>
            </div>
          )}
        </div>
      )}
      <div className="chat-area">
        <div className="messages" ref={messageArea}>
          {getTextBubbles()}
        </div>
        {hasProjectManage && (
          <>
            <textarea ref={textInput} disabled={isEnded()} />
            <div className="send-button-div">
              <span>
                {!isEnded() && (
                  <span className="end-chat-link" onClick={endChat}>
                    {intl.get('app.endChat')}
                  </span>
                )}
              </span>
              <Button onClick={sendMessage} disabled={isEnded()}>
                {intl.get('app.send')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
