import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { OPERATORS } from '../../../../../../util/joinerUtil';
import { getRDParticipantDataPoints } from '../../../../../../store/redux/selectors/researchDashboardSelector';

const ParticipantMetadataCondition = props => {
  const { sessionId, ruleIndex, condition, conditionIndex, updateHandlers, readOnly } = props;

  const dataPoints = useSelector(state => getRDParticipantDataPoints(state, sessionId), shallowEqual);

  const getOperatorSelectList = () => {
    return [
      <option value={OPERATORS.startsWith} key="op1">
        {intl.get('app.surveyRules.operator.startsWith')}
      </option>,
      <option value={OPERATORS.contains} key="op2">
        {intl.get('app.surveyRules.operator.contains')}
      </option>,
      <option value={OPERATORS.doesNotContain} key="op3">
        {intl.get('app.surveyRules.operator.doesNotContain')}
      </option>,
      <option value={OPERATORS.endsWith} key="op4">
        {intl.get('app.surveyRules.operator.endsWith')}
      </option>,
      <option value={OPERATORS.eq} key="op5">
        {intl.get('app.surveyRules.operator.is')}
      </option>,
      <option value={OPERATORS.isIgnoreCase} key="op6">
        {intl.get('app.surveyRules.operator.isIgnoreCase')}
      </option>,
      <option value={OPERATORS.ne} key="op7">
        {intl.get('app.surveyRules.operator.isNot')}
      </option>
    ];
  };

  useEffect(() => {
    if (!condition.operator) {
      updateHandlers.setRuleConditionOperator(ruleIndex, conditionIndex, OPERATORS.eq);
    }
  }, []);

  function getDataPoints() {
    const keys = Object.keys(dataPoints).sort();
    return keys.map(key => (
      <option value={key} key={key}>
        {key}
      </option>
    ));
  }

  function getDataPointValues(dataPoint) {
    const values = dataPoints[condition.label]?.sort() || [];
    return values.map(val => (
      <option value={val} key={val}>
        {val}
      </option>
    ));
  }

  return (
    <>
      <div>
        <div>{intl.get('app.label')}:</div>
        <Input
          value={condition.label}
          disabled={readOnly}
          list="dataPointKeys"
          autocomplete="on"
          onChange={e => updateHandlers.setRuleConditionMetadataLabel(ruleIndex, conditionIndex, e.target.value)}
        />
        <datalist id="dataPointKeys">{getDataPoints()}</datalist>
      </div>

      <div>
        <div>{intl.get('app.operator')}:</div>
        <Input
          type="select"
          value={condition.operator} // default value is "is"
          disabled={readOnly}
          onChange={e => updateHandlers.setRuleConditionOperator(ruleIndex, conditionIndex, e.target.value)}
        >
          {getOperatorSelectList()}
        </Input>
      </div>

      <div>
        <div>{intl.get('app.value')}:</div>
        <Input
          value={condition.values[0]}
          disabled={readOnly}
          list="dataPointValues"
          autocomplete="on"
          onChange={e => updateHandlers.setRuleConditionMetadataValue(ruleIndex, conditionIndex, e.target.value)}
        />
        <datalist id="dataPointValues">{getDataPointValues(dataPoints[condition.label])}</datalist>
      </div>
    </>
  );
};

export default ParticipantMetadataCondition;
