import React from 'react';
import { Nav } from 'reactstrap';
import { useLocation, Link, NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Logo } from 'webapp-common';
import { ClientsDropdown } from '../../components/clientsDropdown/ClientsDropdown';
import appConfig from '../../appConfig';

const USER_PICTURE = 'user_picture';

function goToUrl(e, uri) {
  e.preventDefault();
  e.stopPropagation();
  window.location.assign(uri);
}

export const PageHeader = props => {
  const { user, tenant, abilities, isAuthenticated } = props;
  const { clients } = user || {};
  const clientAdminPath = user?.clientId && appConfig.adminPagePath;
  const currentClient = clients?.find(client => client.clientID === user.clientId);

  const location = useLocation();

  const avatar = typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(USER_PICTURE) : null;

  function logout() {
    props.logoutUser();
  }

  if (clients === null) {
    logout();
    return null;
  }

  return (
    <div className="page-header">
      {isAuthenticated ? (
        <>
          <div className="logo-and-nav-links">
            <Logo href={appConfig.homePage} logoURL={currentClient?.logoUrl} />
            <Nav navbar>
              {abilities.hasProjectView && (
                <li>
                  <NavLink
                    to={appConfig.projectsPath}
                    className={({ isActive }) => (isActive || location.pathname.startsWith('/project') ? 'active' : '')}
                  >
                    {intl.get('app.homePage.projectsTab.title')}
                  </NavLink>
                </li>
              )}
              {abilities.hasProjectView && (
                <li>
                  <NavLink to={appConfig.libraryPagePath}>{intl.get('app.homePage.libraryTab.title')}</NavLink>
                </li>
              )}
              {abilities.hasReportsView && (
                <li>
                  <NavLink to={appConfig.reportsPagePath}>{intl.get('app.homePage.reportsTab.title')}</NavLink>
                </li>
              )}
              {abilities.hasOrgView && (
                <li>
                  <NavLink to={appConfig.orgPagePath}>{intl.get('app.homePage.orgTab.title')}</NavLink>
                </li>
              )}
              {abilities.isInternalSuperUser && (
                <li>
                  <NavLink to={clientAdminPath}>{intl.get('app.homePage.adminTab.title')}</NavLink>
                </li>
              )}
            </Nav>
          </div>
          <div className="no-list-style client-nav-section">
            <span className="nav-label help-info">
              <i className="fa fa-question-circle fa-2x" />
              <ul className="mt-0 dropdown-menu">
                <li onClick={e => goToUrl(e, '/a/ui/freshdesk')}>
                  <span className="nav-label ps-3">{intl.get('app.documentation')}</span>
                  <i className="far fa-file pe-4" />
                </li>
                <li onClick={e => goToUrl(e, 'http://help.invoke.com/contact-us/')}>
                  <span className="nav-label ps-3">{intl.get('app.contact.support')}</span>
                  <i className="fa fa-phone-square pe-4" />
                </li>
              </ul>
            </span>
            {user && (
              <span className="ms-4">
                {clients && <ClientsDropdown clients={clients} tenant={tenant} />}
                <span className="nav-label client-info">
                  {user.email}
                  <span>
                    {avatar ? (
                      <img className="user-picture" src={avatar} alt="" />
                    ) : (
                      <i className="fas fa-user-circle doubleFontSize user-picture" />
                    )}
                  </span>
                  <ul className="mt-0 dropdown-menu">
                    <li>
                      <Link to={appConfig.profilePagePath} className="nav-label ms-3">
                        {intl.get('app.profile')}
                      </Link>
                      <i className="fas fa-cog pe-5" />
                    </li>
                    <li onClick={logout}>
                      <span className="nav-label ps-3">{intl.get('app.logout')}</span>
                      <i className="fas fa-sign-out-alt pe-5" />
                    </li>
                  </ul>
                </span>
              </span>
            )}
          </div>
        </>
      ) : (
        <div className="logo-and-nav-links">
          <Logo href={appConfig.loginPage} />
        </div>
      )}
    </div>
  );
};
