import React, { useEffect, useReducer } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { cloneDeep, get } from 'lodash';
import { Button } from 'reactstrap';
import { rdComparativeViewSubscribeActions } from '../../../../store/redux/actions/researchDashboardActions';
import { getRDFilteredEnrollees } from '../../../../store/redux/selectors/researchDashboardSelector';
import { ComparativeView } from './ComparativeView';
import { getNewComparativeView, saveRDConfig } from '../../../../util/researchDashboardUtil';
import { RDSelectedFiltersBar } from '../../../researchDashboard/rdFilters/RDSelectedFiltersBar';

import './SurveyComparativeViews.css';
import { idUtils } from '../../../../util/Id';

const reducer = (state, payload) => ({
  ...state,
  ...payload
});

const setDefaultConceptsAndPositions = (view, joiner) => {
  const concepts = get(joiner, 'conceptRotation.concepts', []);
  view.conceptIds = [];
  view.positions = [];
  concepts.forEach((c, i) => {
    view.conceptIds.push(c.id);
    view.positions.push(i + 1);
  });
};

export const SurveyComparativeViews = props => {
  const dispatch = useDispatch();
  const { sessionId, rdConfig, project, survey, viewLanguage } = props;
  const [state, setState] = useReducer(reducer, {
    editFiltersModalOpen: false
  });

  const crJoiners = survey.joiners.filter(joiner => joiner.conceptRotation && !joiner.hidden);
  const filteredEnrollees = useSelector(state => getRDFilteredEnrollees(state, sessionId), shallowEqual);

  useEffect(() => {
    if (rdConfig.sessionId && rdConfig.userId) {
      dispatch(
        rdComparativeViewSubscribeActions.request({
          subAction: 'subscribe',
          rdConfig
        })
      );
      return () => {
        dispatch(
          rdComparativeViewSubscribeActions.request({
            subAction: 'unsubscribe',
            rdConfig
          })
        );
      };
    }
  }, [rdConfig.sessionId, rdConfig.userId]);

  const getRDComparativeViews = () => get(rdConfig, 'configs.comparativeViewListConfig.comparativeViews');

  const toggleEditFiltersModal = () => {
    setState({ editFiltersModalOpen: !state.editFiltersModalOpen });
  };

  const saveView = (value, key, viewIndex) => {
    const newRdConfig = cloneDeep(rdConfig);
    key
      ? (newRdConfig.configs.comparativeViewListConfig.comparativeViews[viewIndex][key] = value)
      : (newRdConfig.configs.comparativeViewListConfig.comparativeViews[viewIndex] = value);
    saveRDConfig(newRdConfig);
  };

  const addNewComparativeView = view => {
    if (!view.title) {
      view.title = intl.get('app.newComparativeView');
    }
    if (!view.conceptRotationJoinerId) {
      view.conceptRotationJoinerId = crJoiners[0].id;
    }
    setDefaultConceptsAndPositions(view, crJoiners[0]);
    const comparativeViews = cloneDeep(getRDComparativeViews()) || [];
    comparativeViews.push(view);
    const newConfig = cloneDeep(rdConfig);
    newConfig.configs.comparativeViewListConfig.comparativeViews = comparativeViews;
    saveRDConfig(newConfig);
  };

  const cloneComparativeView = view => {
    view.id = idUtils.getId();
    addNewComparativeView(view);
  };

  const deleteComparativeView = index => {
    const comparativeViews = cloneDeep(getRDComparativeViews());
    const newConfig = cloneDeep(rdConfig);
    comparativeViews.splice(index, 1);
    newConfig.configs.comparativeViewListConfig.comparativeViews = comparativeViews;
    saveRDConfig(newConfig);
  };

  const getComparativeViews = () => {
    const views = getRDComparativeViews();
    if (!views) {
      return null;
    }
    return views.map((v, i) => (
      <ComparativeView
        crJoiners={crJoiners}
        sessionId={sessionId}
        rdConfig={rdConfig}
        survey={survey}
        selectedView={v}
        saveView={saveView}
        viewIndex={i}
        cloneView={cloneComparativeView}
        deleteView={deleteComparativeView}
        viewLanguage={viewLanguage}
        setViewLanguage={props.setViewLanguage}
        setDefaultConceptsAndPositions={setDefaultConceptsAndPositions}
      />
    ));
  };

  return (
    <div className="survey-comparative-views">
      <div className="rd-filters-bar">
        <RDSelectedFiltersBar
          sessionId={sessionId}
          project={project}
          toggleEditFiltersModal={toggleEditFiltersModal}
          showEnrollees
          filteredEnrolleesCount={(filteredEnrollees && filteredEnrollees.totalElements) || 0}
        />
      </div>
      {getComparativeViews()}
      <div>
        <Button color="secondary" onClick={() => addNewComparativeView(getNewComparativeView())}>
          {intl.get('app.newComparativeView')}
        </Button>
      </div>
    </div>
  );
};
