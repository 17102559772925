import { connect } from 'react-redux';
import Functions from './Functions';

import {
  fetchFunctionListActions,
  createFunctionActions,
  fetchFunctionActions,
  deleteFunctionActions
} from '../../store/redux/actions/functionActions';
import { functionListSelector } from '../../store/redux/selectors/functionSelector';
import { sessionUserSelector } from '../../store/redux/selectors/sessionUserSelector';

const mapStateToProps = state => {
  const functionListSelector_ = functionListSelector();
  return {
    ...functionListSelector_(state),
    abilities: sessionUserSelector(state).abilities
  };
};

const mapDispatchToProps = dispatch => ({
  createFunction: params => dispatch(createFunctionActions.request(params)),
  fetchFunctionList: params => dispatch(fetchFunctionListActions.request(params)),
  loadFunction: params => dispatch(fetchFunctionActions.request(params)),
  deleteFunction: params => dispatch(deleteFunctionActions.request(params))
});
export default connect(mapStateToProps, mapDispatchToProps)(Functions);
