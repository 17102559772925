import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { isEqual, cloneDeep } from 'lodash';

import JoinerUpdateHandlers from './JoinerUpdateHandlers';

import { RESPONSE_SET_TYPE, STIM_TYPE } from '../../../../util/joinerUtil';

// editors
import ChoiceEditor from '../editors/ChoiceEditor';
import ConceptRotationEditor from '../editors/ConceptRotationEditor';
import MatrixEditor from '../editors/MatrixEditor';
import OpenTextEditor from '../editors/OpenTextEditor';
import RankedEditor from '../editors/RankedEditor';
import DialSmithStimEditor from '../editors/DialSmithStimEditor';
import MediaStimEditor from '../editors/MediaStimEditor';
import TextStimEditor from '../editors/TextStimEditor';
import VirtualFocusGroupEditor from '../editors/VirtualFocusGroupEditor';

import './JoinerEditor.css';

class JoinerEditorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        edit: 'visible',
        media: 'hidden',
        options: 'hidden',
        timing: 'hidden',
        rules: 'hidden'
      },
      joiner: cloneDeep(props.joinerInfo.joiner),
      conceptVisibility: props.joinerInfo.conceptVisibility
    };
    this.updateHandlers = new JoinerUpdateHandlers(this);
  }

  componentWillMount() {
    document.addEventListener('selectJoinerEditTab', this.selectTab, false);
  }

  componentWillUnmount() {
    document.removeEventListener('selectJoinerEditTab', this.selectTab, false);
  }

  componentDidUpdate(prevProps) {
    const newJoiner = this.props.joinerInfo.joiner;
    if (!isEqual(prevProps.joinerInfo.joiner, newJoiner) && !isEqual(this.state.joiner, newJoiner)) {
      // This handles Save and Preview for a newly added joiner (and maybe other cases).
      this.setState({
        ...this.state,
        joiner: cloneDeep(newJoiner),
        conceptVisibility: this.props.joinerInfo.conceptVisibility
      });
    }
  }

  selectTab = e => {
    const tab = e.detail;
    this.setState({
      ...this.state,
      tabs: {
        edit: 'hidden',
        media: 'hidden',
        options: 'hidden',
        timing: 'hidden',
        rules: 'hidden',
        [tab]: 'visible'
      }
    });
  };

  saveJoiner = () => {
    this.props.saveJoiner({
      joiner: this.state.joiner,
      toLanguage: this.props.language,
      conceptVisibility: this.state.conceptVisibility
    });
  };

  saveAndPreviewJoiner = (e, previewId) => {
    e.stopPropagation();
    this.props.saveAndPreviewJoiner(this.state.joiner, this.state.conceptVisibility, previewId);
  };

  saveEnabled = () => {
    if (this.props.cannotSaveTemplate) {
      return false;
    }
    if (!this.state.joiner.researchPrompt.trim()) {
      return false;
    }
    if (this.props.insertOrEditAction === 'INSERT') {
      return true;
    }
    if (!isEqual(this.state.joiner, this.props.joinerInfo.joiner)) {
      return true;
    }
    if (!isEqual(this.state.conceptVisibility, this.props.joinerInfo.conceptVisibility)) {
      return true;
    }
    return false;
  };

  getComponent = () => {
    const { props, state, updateHandlers } = this;
    const { isScreener } = props;
    switch (props.joinerInfo.type) {
      case RESPONSE_SET_TYPE.open:
        return (
          <OpenTextEditor
            {...props}
            {...state}
            saveEnabled={this.saveEnabled()}
            updateHandlers={updateHandlers}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
            isScreener={isScreener}
          />
        );
      case RESPONSE_SET_TYPE.matrix:
        return (
          <MatrixEditor
            {...props}
            {...state}
            saveEnabled={this.saveEnabled()}
            updateHandlers={updateHandlers}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
          />
        );
      case RESPONSE_SET_TYPE.multi:
        return (
          <ChoiceEditor
            {...props}
            {...state}
            saveEnabled={this.saveEnabled()}
            updateHandlers={updateHandlers}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
            isScreener={isScreener}
          />
        );
      case RESPONSE_SET_TYPE.ranked:
        return (
          <RankedEditor
            {...props}
            {...state}
            saveEnabled={this.saveEnabled()}
            updateHandlers={updateHandlers}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
          />
        );
      case RESPONSE_SET_TYPE.conceptRotation:
        return (
          <ConceptRotationEditor
            {...props}
            {...state}
            saveEnabled={this.saveEnabled()}
            updateHandlers={updateHandlers}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
          />
        );
      case STIM_TYPE.image:
      case STIM_TYPE.video:
      case STIM_TYPE.webcontent:
        return (
          <MediaStimEditor
            {...props}
            {...state}
            saveEnabled={this.saveEnabled()}
            updateHandlers={updateHandlers}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
          />
        );
      case STIM_TYPE.text:
        return (
          <TextStimEditor
            {...props}
            {...state}
            saveEnabled={this.saveEnabled()}
            updateHandlers={updateHandlers}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
          />
        );
      case STIM_TYPE.thirdparty:
        return (
          <DialSmithStimEditor
            {...props}
            {...state}
            updateHandlers={updateHandlers}
            saveEnabled={this.saveEnabled()}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
          />
        );
      case RESPONSE_SET_TYPE.virtualFocusGroup:
        return (
          <VirtualFocusGroupEditor
            {...props}
            {...state}
            updateHandlers={updateHandlers}
            saveEnabled={this.saveEnabled()}
            saveJoiner={this.saveJoiner}
            saveAndPreviewJoiner={this.saveAndPreviewJoiner}
          />
        );
      default:
    }
  };

  render() {
    return (
      <Modal isOpen className="joiner-editor-modal">
        <ModalBody>{this.getComponent()}</ModalBody>
      </Modal>
    );
  }
}

export default JoinerEditorModal;
