import { createSelector, createStructuredSelector } from 'reselect';
import { projectSelector } from './projectSelector';
import { get } from 'lodash';

const selectParticipantCollectionJobs = (project = {}) => get(project, 'project.participantCollectionJobs.content');
const selectParticipantCollectionJobsStatus = (project = {}) =>
  (project.project && project.project.participantCollectionJobsRequested) || false;

const participantCollectionJobsSelector = () => {
  const entityProjectSelector = projectSelector();
  return createSelector(
    entityProjectSelector,
    createStructuredSelector({
      participantCollectionJobs: selectParticipantCollectionJobs,
      participantCollectionJobsRequested: selectParticipantCollectionJobsStatus
    })
  );
};

export default participantCollectionJobsSelector;
