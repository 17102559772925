import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { sessionStates } from '../../../../../util/sessionUtil';

export const SessionStatusPicker = props => {
  const { session, videoIngestStatus, ingestStatus, setSessionField, readOnly, sessionClosed } = props;
  const activationDisabled = useMemo(() => {
    return (
      session.state === sessionStates.TEST ||
      videoIngestStatus === 'IN_PROGRESS' ||
      videoIngestStatus === 'ERROR' ||
      ingestStatus.fetchIngestStatusFailure
    );
  }, [session.state, videoIngestStatus, ingestStatus]);

  return (
    <Input
      type="select"
      style={{ width: '10rem', display: 'inline-block' }}
      value={session.state}
      onChange={e => setSessionField('state', e.target.value)}
      disabled={readOnly}
    >
      <option value={sessionStates.OPEN} disabled={activationDisabled}>
        {intl.get('app.session.state.OPEN')}
      </option>
      <option value={sessionStates.TEST} disabled={session.surveySubmissions > 0}>
        {intl.get('app.session.state.TEST')}
      </option>
      <option value={sessionStates.PENDING}>{intl.get('app.session.state.PENDING')}</option>
      <option value={sessionStates.PAUSED} disabled={sessionClosed}>
        {intl.get('app.session.state.PAUSED')}
      </option>
      <option value={sessionStates.CLOSED}>{intl.get('app.session.state.CLOSED')}</option>
    </Input>
  );
};
