export default {
  // Authentication will cause a redirect to this page
  userDashboardPage: process.env.REACT_APP_DASHBOARD_PAGE,

  homePage: '/home-page',
  loginPage: '/login',
  logoutPage: '/logout',
  projectPagePath: '/project',
  projectsPath: '/projects',
  archivedProjectsPath: '/projects/archived',
  libraryPagePath: '/library',
  reportsPagePath: '/reports',
  orgPagePath: '/org',
  adminPagePath: '/admin',
  panelsPagePath: '/admin/panels',
  profilePagePath: '/profile',

  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0DB: process.env.REACT_APP_AUTH0_DB,
  auth0CallbackURL: process.env.REACT_APP_AUTH0_CALLBACKURL,
  invokeAuthCallbackURL: process.env.REACT_APP_INVOKE_AUTH_CALLBACKURL,

  authCookieName: 'VTOKEN',
  offsetCookieName: 'offset',
  clientIdCookieName: 'clientId',
  timezoneCookieName: 'timezoneName',
  userSessionMaxIdleMinutes: 60,
  projectArchiveThreshold: process.env.REACT_APP_PROJECT_THRESHOLD
};
