import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { Input } from 'reactstrap';
import { saveRDConfig } from '../../../../../../util/researchDashboardUtil';
import { InvokeModal } from 'webapp-common';

import './CreateFilterModal.css';

export const CreateFilterModal = props => {
  const { participantIds, enrolleeMap, rdConfig, toggle } = props;

  const [filterName, setFilterName] = useState('');

  function save() {
    const filter = {
      name: filterName,
      expression: `R."participantIds":A."${participantIds.join(',')}";`,
      type: 'GENERAL_FILTER'
    };
    const cloned = cloneDeep(rdConfig);
    cloned.configs.filterListConfig.filters.push(filter);
    saveRDConfig(cloned);
    toggle();
  }

  function getEnrollees() {
    const enrolleesForDisplay = participantIds.length > 50 ? participantIds.slice(0, 50) : participantIds;
    const columns = [];
    enrolleesForDisplay.forEach((pid, i) => {
      if (i % 10 === 0) {
        columns.push([]);
      }
      const enrollee = enrolleeMap[pid];
      columns[columns.length - 1].push(<li>{enrollee.nickname || enrollee.id}</li>);
    });
    return columns.map(col => <ul>{col}</ul>);
  }

  const nameExists = rdConfig.configs.filterListConfig.filters.some(f => f.name === filterName);
  const saveEnabled = !!filterName.trim() && !nameExists;

  return (
    <InvokeModal
      showModal
      className="cluster-analysis-create-filter-modal"
      toggle={toggle}
      modalTitle={intl.get('app.createFilter')}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.save')}
      enableSave={saveEnabled}
      save={save}
    >
      <div style={{ marginBottom: '1rem' }}>
        <span style={{ marginRight: '2rem' }}>{intl.get('app.filterName')}:</span>
        <Input style={{ display: 'inline-block', width: 'auto' }} onChange={e => setFilterName(e.target.value)} />
        {nameExists && <span style={{ color: 'red', marginLeft: '1rem' }}>{intl.get('app.filterExists')}</span>}
      </div>
      <div>
        {intl.get('app.participants')} ({participantIds.length}):
        <div className="enrollees">{getEnrollees()}</div>
      </div>
      {participantIds.length > 50 && <div className="over-50-msg">{intl.get('app.onlyFirst50ParticipantsShown')}</div>}
    </InvokeModal>
  );
};
