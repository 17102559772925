import React, { useEffect } from 'react';
import { auth } from '../../../api/Auth';
import { Loader } from 'webapp-common';

export const CallbackPage = ({ setToken, redirectToLogin }) => {
  useEffect(() => {
    auth
      .handleAuthentication()
      .then(authResult => {
        setToken(authResult.idToken);
      })
      .catch(redirectToLogin);
  }, []);

  return <Loader />;
};
