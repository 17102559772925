export const NEWPROJECT = 'newproject';
export const PROJECT_DETAILS_TAB = 'details';
export const RECRUIT_TAB = 'recruit';
export const AUTO_ENROLL_TAB = 'autoenroll';
export const SURVEY_TAB = 'survey';
export const REPORTS_AND_DATA_TAB = 'reportsAndData';
export const PANEL_CONFIG_TAB = 'panelConfig';
export const PARTICIPANTS_TAB = 'participants';

export const project_survey_view = {
  list: 'list',
  block: 'block',
  view_style: 'project_survey_view'
};

const RECENT_PROJECTS = 'recent_projects';
const NUMBER_RECENT_PROJECTS = 5;

export const getRecentProjects = clientId => {
  const key = RECENT_PROJECTS + '_' + clientId;
  return JSON.parse(localStorage.getItem(key)) || [];
};

const setRecentProjects = (projects, clientId) => {
  const key = RECENT_PROJECTS + '_' + clientId;
  localStorage.setItem(key, JSON.stringify(projects));
};

export const updateRecentProjects = (projectData, clientId) => {
  if (!clientId) {
    return;
  }

  let projects = getRecentProjects(clientId);

  // Remove any existing occurrence of this object in the array
  for (let i = projects.length - 1; i >= 0; --i) {
    if (projects[i].id === projectData.id) {
      projects.splice(i, 1);
    }
  }

  // Add the projectId
  projects.unshift({ id: projectData.id });

  setRecentProjects(projects.slice(0, NUMBER_RECENT_PROJECTS), clientId);
};

export const removeRecentProject = (id, clientId) => {
  let projects = getRecentProjects(clientId);
  projects = projects.filter(p => p.id !== id);
  setRecentProjects(projects, clientId);
};

export const getProjectPageURL = (projectId, hasProjectManage, path) => {
  const tab = hasProjectManage ? PROJECT_DETAILS_TAB : REPORTS_AND_DATA_TAB;
  return `${path}/${projectId}/${tab}`;
};
