import { compact, trim, uniq } from 'lodash';

// TODO: replace usages of this with the one in webapp-common
export const cleanStringArray = strings =>
  (strings &&
    [compact, uniq].reduce((prev, curr) => {
      return curr(prev);
    }, strings.map(trim))) ||
  [];

// TODO: replace usages of this with the one in webapp-common
export const validateEmail = email => email.match(/\S+@\S+\.\S{2,}/);

export const entityType = {
  session: 'SESSION',
  project: 'PROJECT'
};

export const participantType = {
  participant: 'participant',
  delinquent: 'delinquent'
};

// TODO: replace usages of this with the one in webapp-common
export const getFileExtention = fileName => fileName.split('.').pop();

// TODO: replace usages of this with the one in webapp-common
function getUrlQueries() {
  const queries = {};
  for (const [key, value] of new URL(document.location).searchParams) {
    queries[key] = value;
  }
  return queries;
}

// TODO: replace usages of this with the one in webapp-common
const getComponentHeight = className => {
  const component = document.querySelector(className);
  const componentHeight = component?.clientHeight || 0;
  const styles = component && window.getComputedStyle(component);
  const margin = (styles && parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])) || 0;
  return componentHeight + margin;
};

function getHeaderHeight() {
  return getComponentHeight('.page-header');
}

function getProjectStatusBarHeight() {
  return getComponentHeight('.project-status-bar');
}

function getRdStatusBarHeight() {
  return getComponentHeight('.rd-status-bar');
}

function getRdQuestionDetailsHeaderHeight() {
  return getComponentHeight('.rd-question-details-header');
}

function getFooterHeight() {
  return getComponentHeight('.footer');
}

// TODO: replace usages of this with the one in webapp-common
export const getStringWidth = (text, tagName) => {
  const elem = document.getElementsByTagName(tagName)[0];
  if (!elem) {
    return 0;
  }
  const context = document.createElement('canvas').getContext('2d');
  context.font = getComputedStyle(elem).font;
  return context.measureText(text).width;
};

// TODO: replace usages of this with the one in webapp-common
const initiateFileDownload = (url, params, filename, onComplete) => {
  const link = document.createElement('a');
  link.download = filename; // need to create an 'a' tag to set a filename

  const options = params
    ? {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    : null;

  window
    .fetch(url, options)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .then(url => {
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      if (onComplete) {
        onComplete();
      }
    });
};

export const jsUtil = {
  cleanStringArray,
  validateEmail,
  entityType,
  participantType,
  getFileExtention,
  getUrlQueries,
  getComponentHeight,
  getHeaderHeight,
  getProjectStatusBarHeight,
  getRdStatusBarHeight,
  getRdQuestionDetailsHeaderHeight,
  getFooterHeight,
  getStringWidth,
  initiateFileDownload
};
