import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { cloneDeep, debounce, find, get, isEqual } from 'lodash';
import moment from 'moment';
import { InvokeTable, SearchInput, usePagination } from 'webapp-common';
import { ChatIcon, FlagIcon, FlagsDropdown, StarIcon } from '../../../components/icons/Icons';
import { rdConfigUtil } from '../../../util/rdConfigUtil';
import { PARTICIPANT_FLAG_COLORS, DEFAULT_FLAG_COLOR, saveRDConfig } from '../../../util/researchDashboardUtil';
import chatUtil from '../../../components/chat/chatUtil';
import { ConfigureDataColumns } from '../../researchDashboard/rdSessionMgmt/participantData/ConfigureDataColumns';
import { RDParticipantDetailsModal } from '../rdParticipantDetails/RDParticipantDetailsModal';
import { FilterSummaries } from '../rdFilters/FilterSummaries';
import { FiltersComponent } from '../rdFilters/FiltersComponent';
import {
  toggleFavoriteParticipantAction,
  openChatWindowAction
} from '../../../store/redux/actions/researchDashboardActions';
import { setParticipantGroupActions } from '../../../store/redux/actions/filtersAndParticipantsActions';

import './RDParticipantMgmt.css';

const skipUpdate = (prevProps, nextProps) => {
  if (!isEqual(prevProps.filteredEnrollees, nextProps.filteredEnrollees)) {
    return false;
  }
  if (!isEqual(prevProps.enrolleesInfo, nextProps.enrolleesInfo)) {
    return false;
  }
  if (!isEqual(prevProps.rdConfig, nextProps.rdConfig)) {
    return false;
  }
  if (!isEqual(prevProps.rdChats, nextProps.rdChats)) {
    return false;
  }
  if (prevProps.sessionId === nextProps.sessionId) {
    return true;
  }
  return true;
};

const getParticipants = (enrolleeMap, filtered) => {
  const participants = [];
  filtered.forEach(id => {
    const enrollee = enrolleeMap[id];
    if (enrollee) {
      participants.push({ ...enrollee, ...get(enrollee, 'metadata.dataStore') });
    }
  });
  return participants;
};

export const RDParticipantMgmt = memo(props => {
  const { userId, sessionId, rdChats, rdConfig, enrolleesInfo, filteredEnrollees } = props;

  const { participantDataColumnConfig, participantDataSortingConfig = {} } = rdConfig?.configs || {};

  // All default columns
  const DEFAULT_COLUMNS = useMemo(() => {
    return {
      participant: intl.get('app.participant'),
      favorite: intl.get('app.fav'),
      flag: intl.get('app.flag'),
      chat: intl.get('app.chat'),
      enrolleeStatus: intl.get('app.status'),
      surveyStartTime: intl.get('app.login'),
      lastAnsweredTime: intl.get('app.lastSubmit'),
      percentSurveyComplete: intl.get('app.percentSign')
    };
  }, []);

  // Default columns that are hidden by default
  const DEFAULT_HIDDEN_COLUMNS = {};

  const [showConfigureColumns, setShowConfigureColumns] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipantId, setSelectedParticipantId] = useState();

  const numParticipants = participants.length === 0 ? 0 : filteredEnrollees.totalElements;
  const { columnOrder, enrolleeMap, keyTransformMap = {} } = enrolleesInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    const filtered = get(filteredEnrollees, 'content') || [];
    if (enrolleeMap && filtered) {
      setParticipants(getParticipants(enrolleeMap, filtered));
    }
  }, [enrolleesInfo, filteredEnrollees]);

  const getNewConfigs = useCallback(
    payload => {
      const configs = {
        ...rdConfig.configs,
        participantDataSortingConfig: {
          ...rdConfig.configs.participantDataSortingConfig,
          ...payload
        }
      };
      return {
        ...rdConfig,
        configs
      };
    },
    [rdConfig]
  );

  const updateFavoriteParticipant = useCallback(
    ({ favorite, id }) => {
      dispatch(
        toggleFavoriteParticipantAction.request({
          sessionId,
          participantId: id,
          favorite
        })
      );
    },
    [dispatch, sessionId]
  );

  const openChatWindow = useCallback(
    row => {
      const { nickname, id } = row;
      dispatch(
        openChatWindowAction.request({
          chatTitle: nickname || id,
          participantId: id,
          researcherId: userId,
          sessionId
        })
      );
    },
    [dispatch, sessionId, userId]
  );

  const isFilterApplied = (filter, config) => {
    return (config.selected === filter.name || config.selected === filter.id) && config.selectedType === filter.type;
  };

  const filterParticipants = filter => {
    const rdConfigClone = cloneDeep(rdConfig);
    const filterListConfig = get(rdConfigClone, 'configs.filterListConfig');
    if (isFilterApplied(filter, filterListConfig)) {
      filterListConfig.selected = null;
      filterListConfig.selectedType = 'GENERAL_FILTER';
    } else {
      filterListConfig.selected = filter.type === 'QUOTA_FILTER' ? filter.id : filter.name;
      filterListConfig.selectedType = filter.type;
    }
    saveRDConfig(rdConfigClone);
  };

  const searchParticipant = debounce(participant => {
    saveRDConfig(getNewConfigs({ searchTerm: participant }));
  }, 500);

  const onParticipantInputChange = e => {
    searchParticipant(e.target.value);
  };

  const columnConfig =
    participantDataColumnConfig &&
    rdConfigUtil.getColumnConfig(participantDataColumnConfig, DEFAULT_COLUMNS, DEFAULT_HIDDEN_COLUMNS, columnOrder);

  const getParticipantCell = useCallback(info => {
    const obj = info.row.original;
    const { id, nickname, metadata } = obj;
    const invoke_user = get(metadata, 'dataStore.invoke_user');
    return (
      <span onClick={() => setSelectedParticipantId(id)} className="link-button">
        {nickname || invoke_user || id}
      </span>
    );
  }, []);

  // Invited participant has no survey runner yet.
  function isInvited(participant) {
    return participant.enrolleeStatus === 'Invited';
  }

  const getFavoriteCell = useCallback(
    info => {
      const obj = info.row.original;
      if (isInvited(obj)) {
        return <StarIcon className="favorite-icon disabled" />;
      }
      return (
        <StarIcon
          className={`favorite-icon ${obj.favorite ? 'favorite' : ''}`}
          onClick={() => updateFavoriteParticipant(obj)}
        />
      );
    },
    [updateFavoriteParticipant]
  );

  const onFlagClick = useCallback(
    (group, participantId) => {
      dispatch(setParticipantGroupActions.request({ sessionId, participantId, group, participantSelected: null }));
    },
    [dispatch, sessionId]
  );

  const getFlagCell = useCallback(
    info => {
      const obj = info.row.original;
      const { id, participantGroup } = obj;
      if (isInvited(obj)) {
        return <FlagIcon className="flag-icon disabled" />;
      }
      const flag = PARTICIPANT_FLAG_COLORS[participantGroup] || DEFAULT_FLAG_COLOR;
      return (
        <FlagsDropdown
          id={id}
          flagClasses={flag}
          title={
            flag === DEFAULT_FLAG_COLOR
              ? intl.get('app.setFlags')
              : intl.get(`app.participantFlag.${participantGroup}.color`)
          }
          colorProp={PARTICIPANT_FLAG_COLORS}
          showBan={true}
          onFlagClick={group => onFlagClick(group, id)}
        />
      );
    },
    [onFlagClick]
  );

  const getChatCell = useCallback(
    info => {
      const obj = info.row.original;
      if (isInvited(obj)) {
        return <ChatIcon className="chat-icon disabled" />;
      }
      const genericChat = find(rdChats, chat => !chat.questionJoinerId && chat.participantIds.indexOf(obj.id) > -1);
      const hasMessage = genericChat && genericChat.messages.length > 0;
      const chattable = chatUtil.isActiveParticipant(obj);
      return (
        <ChatIcon
          title={hasMessage || chattable ? '' : intl.get('app.noChat')}
          className={`chat-icon ${hasMessage ? 'existing-chat' : ''} ${hasMessage || chattable ? '' : 'disabled'}`}
          onClick={() => `${hasMessage || chattable ? openChatWindow(obj) : ''}`}
        />
      );
    },
    [openChatWindow, rdChats]
  );

  const getStatusCell = useCallback(info => {
    return <span>{info.getValue()}</span>;
  }, []);

  const getLocalTime = useCallback(info => {
    const timestamp = info.getValue();
    return timestamp ? moment(timestamp).format('hh:mm:ss') : '';
  }, []);

  const getPercentCell = useCallback(info => {
    return <span>{info.getValue() + '%'}</span>;
  }, []);

  const getParticipantMetadataCell = useCallback(info => {
    return <span>{info.getValue()}</span>;
  }, []);

  const getColumn = useCallback((accessorKey, header, cellClassName, cell, enableSorting) => {
    return {
      accessorKey,
      header,
      cell,
      cellClassName,
      enableSorting
    };
  }, []);

  const columns = useMemo(() => {
    const cols = [];

    columnConfig?.columnPositions.forEach(col => {
      if (col === DEFAULT_COLUMNS.participant) {
        cols.push(getColumn('id', col, 'participant-cell', getParticipantCell, true));
      } else if (col === DEFAULT_COLUMNS.favorite) {
        cols.push(getColumn('favorite', col, 'favorite-cell', getFavoriteCell, true));
      } else if (col === DEFAULT_COLUMNS.flag) {
        cols.push(getColumn('participantGroup', col, 'flag-cell', getFlagCell, true));
      } else if (col === DEFAULT_COLUMNS.chat) {
        cols.push(getColumn('chat', col, 'chat-cell', getChatCell, false));
      } else if (col === DEFAULT_COLUMNS.enrolleeStatus) {
        cols.push(getColumn('enrolleeStatus', col, 'status-cell', getStatusCell, true));
      } else if (col === DEFAULT_COLUMNS.surveyStartTime) {
        cols.push(getColumn('surveyStartTime', col, 'time-cell', getLocalTime, true));
      } else if (col === DEFAULT_COLUMNS.lastAnsweredTime) {
        cols.push(getColumn('lastAnsweredTime', col, 'time-cell', getLocalTime, true));
      } else if (col === DEFAULT_COLUMNS.percentSurveyComplete) {
        cols.push(getColumn('percentSurveyComplete', col, 'percent-cell', getPercentCell, true));
      } else {
        // Participant metadata
        cols.push(getColumn(col, keyTransformMap[col] || col, '', getParticipantMetadataCell, true));
      }
    });

    return cols;
  }, [
    DEFAULT_COLUMNS,
    columnConfig?.columnPositions,
    getChatCell,
    getColumn,
    getFavoriteCell,
    getFlagCell,
    getLocalTime,
    getParticipantCell,
    getPercentCell,
    keyTransformMap
  ]);

  const onConfigureColumns = () => {
    setShowConfigureColumns(!showConfigureColumns);
  };

  const paginate = useCallback(
    ({ pageNumber, pageSize }) => {
      saveRDConfig(getNewConfigs({ pageNumber, pageSize }));
    },
    [getNewConfigs]
  );

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      saveRDConfig(getNewConfigs({ sortBy, sortOrder }));
    },
    [getNewConfigs]
  );

  const pageRequest = useMemo(() => {
    return {
      pageNumber: participantDataSortingConfig.pageNumber || 1,
      pageSize: participantDataSortingConfig.pageSize || 10
    };
  }, [participantDataSortingConfig.pageNumber, participantDataSortingConfig.pageSize]);

  const pagination = usePagination({ pageRequest, totalElements: numParticipants });

  return (
    <section className="rd-participant-mgmt">
      <FilterSummaries sessionId={sessionId} />
      <div className="filters-and-participants">
        <FiltersComponent
          sessionId={sessionId}
          filterParticipants={filterParticipants}
          filterListConfig={get(rdConfig, 'configs.filterListConfig', {})}
          isFilterApplied={isFilterApplied}
        />
        <div className="participants">
          <SearchInput
            className="search-participants"
            defaultValue={participantDataSortingConfig.searchTerm}
            placeholder={intl.get('app.search')}
            onChange={onParticipantInputChange}
          />
          <div className="space-between" style={{ height: '1rem' }}>
            <span className="small-font">{intl.get('app.numberOfParticipants', { number: numParticipants })}</span>
            <span>
              <i className="fas fa-cog small-font" />
              <Button className="link-button small-font ps-1" onClick={onConfigureColumns}>
                {intl.get('app.configureColumns')}
              </Button>
            </span>
          </div>
          <div className="participant-container">
            {columnConfig && (
              <InvokeTable
                className="invoke-table"
                columns={columns}
                data={participants}
                pagination={pagination}
                onPaginationChange={paginate}
                onSortingChange={sort}
              />
            )}
          </div>
        </div>
        {showConfigureColumns && (
          <ConfigureDataColumns
            showModal={showConfigureColumns}
            toggle={onConfigureColumns}
            rdConfig={rdConfig}
            targetConfig={'participantDataColumnConfig'}
            columnConfig={columnConfig}
            defaultColumns={Object.values(DEFAULT_COLUMNS)}
            columnOrder={columnOrder}
            keyTransformMap={keyTransformMap}
            sessionId={sessionId}
            saveRDConfig={saveRDConfig}
          />
        )}
        {!!selectedParticipantId && (
          <RDParticipantDetailsModal
            sessionId={sessionId}
            participant={enrolleeMap[selectedParticipantId]}
            toggle={() => setSelectedParticipantId('')}
            setViewLanguage={props.setViewLanguage}
            language={props.language}
            viewLanguage={props.viewLanguage}
          />
        )}
      </div>
    </section>
  );
}, skipUpdate);
