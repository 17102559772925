import intl from 'react-intl-universal';
import { takeLatest, put } from 'redux-saga/effects';
import { toast } from '../../../util/toast';
import { saveProjectActions } from '../actions/saveProject';
import { saveProject as saveProjectApi } from '../../../api/projectsApi';
import createFetchSaga from './createFetchSaga';
import { fetchProjectListActions } from '../actions/projectListActions';
import { requestValidation } from './validationSaga';
import { entityType } from '../../../util/jsUtil';

function* showProjectSavedMessage({ payload: project }) {
  toast.success({ text: intl.get('app.project.saved', { name: project.name }) });
  yield put(
    fetchProjectListActions.request({
      sortBy: 'createDate',
      sortOrder: 'desc'
    })
  );
}

function* requestProjectValidation({ payload }) {
  yield* requestValidation({ id: payload.id, entity: entityType.project });
}

function* saveProjectSaga() {
  const saga = createFetchSaga(saveProjectActions, saveProjectApi);
  yield* saga();
  yield takeLatest(saveProjectActions.succeeded, showProjectSavedMessage);
  yield takeLatest(saveProjectActions.succeeded, requestProjectValidation);
}

export default saveProjectSaga;
