import { handleActions } from 'redux-actions';
import { fetchTenantAbilitiesAction } from '../actions/clientAdminActions';

const initialState = {};

const abilitiesReducer = handleActions(
  {
    [fetchTenantAbilitiesAction.triggered](state) {
      return { ...state, requestAbilitiesInProgress: true };
    },
    [fetchTenantAbilitiesAction.succeeded](state, { payload }) {
      return {
        ...state,
        abilities: payload.featureAbilities,
        requestAbilitiesInProgress: false
      };
    },
    [fetchTenantAbilitiesAction.failed](state) {
      return { ...state, requestAbilitiesInProgress: false };
    }
  },
  initialState
);

export default abilitiesReducer;
