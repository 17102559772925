import React, { memo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { isEqual } from 'lodash';
import { getRDEnrollees } from '../../../../store/redux/selectors/researchDashboardSelector';

import './ParticipantCountsWidget.css';

function getSurveyTerminations(enrolleeMap) {
  let removedCount = 0;
  for (const enrolleeId in enrolleeMap) {
    const enrollee = enrolleeMap[enrolleeId];
    if (enrollee.enrolleeStatus === 'Terminated') {
      removedCount++;
    }
  }
  return removedCount;
}

function skipUpdate(prevProps, nextProps) {
  return isEqual(prevProps.session, nextProps.session);
}

export const ParticipantCountsWidget = memo(props => {
  const { sessionId, session } = props;
  const [widgetClicked, setWidgetClicked] = useState(false);

  const enrolleesInfo = useSelector(state => getRDEnrollees(state, sessionId), shallowEqual);

  const invited = session.includedParticipants || 0;
  const active = enrolleesInfo.activeEnrolleeCount || 0;
  const accepted = enrolleesInfo.totalAccepted || 0;
  const removed = getSurveyTerminations(enrolleesInfo.enrolleeMap);

  const titleInvited = `${intl.get('app.researchDashboard.Invited')} ${invited}`;
  const titleAccepted = `${intl.get('app.researchDashboard.Accepted')} ${accepted}`;
  const titleRemoved = `${intl.get('app.researchDashboard.Removed')} ${removed}`;
  const titleActive = `${intl.get('app.researchDashboard.Active')} ${active}`;

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={`participant-counts-widget ${widgetClicked ? 'view-all' : ''}`}
        onClick={() => setWidgetClicked(!widgetClicked)}
      >
        <div className="active-counts">
          <i className="fas fa-users col-3" />
          <div className="bubble bubble-active col-3" title={titleActive}>
            {active}
          </div>
          {widgetClicked && <div>{intl.get('app.active')}</div>}
        </div>
        {widgetClicked && (
          <div style={{ width: '100%' }}>
            <div className="active-counts">
              <div className="col-3" />
              <div className="bubble bubble-remove col-3" title={titleRemoved}>
                {removed}
              </div>
              <div>{intl.get('app.removed')}</div>
            </div>
            <div className="active-counts">
              <div className="col-3" />
              <div className="bubble bubble-accept col-3" title={titleAccepted}>
                {accepted}
              </div>
              <div>{intl.get('app.accepted')}</div>
            </div>
            <div className="active-counts">
              <div className="col-3" />
              <div className="bubble bubble-invite col-3" title={titleInvited}>
                {invited}
              </div>
              <div>{intl.get('app.invited')}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}, skipUpdate);
