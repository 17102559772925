import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { SurveyQuestionPicker } from './SurveyQuestionPicker';

export const SelectedQuestionsConfig = props => {
  const { reportConfig, consolidatedReportData, updateReportConfig } = props;

  const [showSurveyQuestionPicker, setShowSurveyQuestionPicker] = useState();

  function setSelectedQuestionJoiners(payload) {
    updateReportConfig({
      ...reportConfig,
      includeAllQuestions: payload.includeAllQuestions,
      selectedQuestionJoinerIds: payload.selectedQuestionJoinerIds
    });
  }

  return (
    <div>
      <div>{intl.get('app.questions')}</div>
      <label>
        <Input
          type="radio"
          checked={reportConfig.includeAllQuestions}
          onChange={() => setSelectedQuestionJoiners({ includeAllQuestions: true, selectedQuestionJoinerIds: [] })}
        />
        {intl.get('app.allQuestions')}
      </label>
      <div style={{ display: 'flex' }}>
        <label>
          <Input
            type="radio"
            checked={!reportConfig.includeAllQuestions}
            onChange={() => setShowSurveyQuestionPicker(true)}
          />
          {intl.get('app.selectedQuestions')}
        </label>
        <Button
          className="link-button configure-button"
          onClick={() => setShowSurveyQuestionPicker(true)}
          disabled={reportConfig.includeAllQuestions}
        >
          {intl.get('app.configure')}
        </Button>
      </div>
      {showSurveyQuestionPicker && (
        <SurveyQuestionPicker
          questionJoiners={consolidatedReportData.consolidatedQuestion}
          selectedJoinerIds={reportConfig.selectedQuestionJoinerIds}
          includeAllQuestions={reportConfig.includeAllQuestions}
          setSelectedQuestionJoiners={setSelectedQuestionJoiners}
          closeModal={() => setShowSurveyQuestionPicker(false)}
        />
      )}
    </div>
  );
};
