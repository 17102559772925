import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { Container } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import './DefaultLayout.css';

class DefaultLayout extends Component {
  static propTypes = {
    header: PropTypes.element.isRequired,
    footer: PropTypes.element.isRequired
  };

  static defaultProps = {};

  render() {
    return (
      <div className="app">
        {this.props.header}
        <Container>{this.props.children}</Container>
        {this.props.footer}
        <ToastContainer autoClose={4000} />
      </div>
    );
  }
}

export default DefaultLayout;
