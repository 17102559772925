import { takeEvery } from 'redux-saga/effects';
import { virtualFocusGroupSubscribeActions } from '../actions/virtualFocusGroupActions';
import { subscribeToChannel } from '../../../util/websocket';

function* virtualFocusGroupChannelSubscribe({ payload: { subAction, sessionId, questionJoinerIds } }) {
  yield* subscribeToChannel({
    action: 'virtualFocusGroupData',
    subAction,
    key: sessionId,
    sessionId,
    questionJoinerIds
  });
}

function* virtualFocusGroupChannelSubscribeSaga() {
  yield takeEvery(virtualFocusGroupSubscribeActions.request, virtualFocusGroupChannelSubscribe);
}

export { virtualFocusGroupChannelSubscribeSaga };
