import { connect } from 'react-redux';
import { SessionEmotionData } from './SessionEmotionData';
import { fetchSurveyActions } from '../../../store/redux/actions/surveyActions';
import {
  emotionDataChannelSubscribeActions,
  fetchEmotionSettingConfigActions,
  saveEmotionSettingConfigActions,
  emotionReportFilesChannelSubscribeActions,
  exportEmotionDataActions
} from '../../../store/redux/actions/emotionDataActions';
import { fetchVideoWatermarkActions, deleteVideoWatermarkActions } from '../../../store/redux/actions/dialDataActions';
import { filtersAndParticipantsSubscribeActions } from '../../../store/redux/actions/filtersAndParticipantsActions';
import { surveySelector } from '../../../store/redux/selectors/surveySelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { emotionSettingConfigSelector } from '../../../store/redux/selectors/emotionSettingConfigSelector';
import { getFiltersAndParticipants } from '../../../store/redux/selectors/filtersAndParticipantsSelector';
import { getRDEnrollees } from '../../../store/redux/selectors/researchDashboardSelector';

const mapStateToProps = (state, { sessionId, surveyId }) => {
  const surveyStore = surveySelector()(state, surveyId);
  const emotionSettingStore = emotionSettingConfigSelector(state);
  const filterDataStore = getFiltersAndParticipants(state, sessionId);
  const { filters, filteredParticipants } = filterDataStore;
  const enrolleesInfo = getRDEnrollees(state, sessionId);
  const { fetchVideoWatermarkInProgress, videoWatermark } = state.dialDataStore;
  return {
    sessionUser: sessionUserSelector(state).sessionUser,
    survey: surveyStore.survey,
    emotionDataStore: state.emotionDataStore,
    videoWatermark,
    filters,
    filteredParticipants,
    emotionSetting: emotionSettingStore.emotionSettingConfig || { overlap: true },
    totalParticipants: enrolleesInfo.totalAccepted || 0,
    fetchVideoWatermarkInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSurvey: surveyId => dispatch(fetchSurveyActions.request({ surveyId })),
  fetchEmotionSetting: params => dispatch(fetchEmotionSettingConfigActions.request(params)),
  saveEmotionSetting: params => dispatch(saveEmotionSettingConfigActions.request(params)),
  emotionDataChannelSubscribe: params => dispatch(emotionDataChannelSubscribeActions.request(params)),
  filtersAndParticipantsChannelSubscribe: params => dispatch(filtersAndParticipantsSubscribeActions.request(params)),
  emotionReportFilesChannelSubscribe: params => dispatch(emotionReportFilesChannelSubscribeActions.request(params)),
  exportEmotionData: params => dispatch(exportEmotionDataActions.request(params)),
  fetchVideoWatermark: params => dispatch(fetchVideoWatermarkActions.request(params)),
  deleteVideoWaterMark: params => dispatch(deleteVideoWatermarkActions.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionEmotionData);
