import React, { useRef } from 'react';
import { MEDIA_TYPE } from '../../../util/joinerUtil';
import { mediaUtil } from '../../../util/mediaUtil';
import { Icons } from '../../icons/Icons';
import webcontent_src from '../../../images/icons8-internet.png';

import './MediaPreview.css';

const MediaPreview = props => {
  const { mediaType, media, file, onClick, readOnly, text, classes } = props;

  const fileSrcRef = useRef();

  if (file && !fileSrcRef.current) {
    fileSrcRef.current = URL.createObjectURL(file);
  }

  function getPreview() {
    const src = fileSrcRef.current || mediaUtil.getMediaUrl(media);
    switch (mediaType) {
      case MEDIA_TYPE.image:
        return <img src={src} alt="" />;
      case MEDIA_TYPE.video:
        return <Icons.VideoIcon className="fa-3x" />;
      case MEDIA_TYPE.webcontent:
        return src ? (
          <iframe src={src} className="webcontent-preview" title={src} />
        ) : (
          <img src={webcontent_src} alt="" />
        );
      default:
        return <i className="fa fa-image fa-3x" />;
    }
  }

  return (
    <div>
      <div
        className={classes ? `${classes}` : 'media-preview'}
        onClick={onClick}
        style={{ cursor: onClick && !readOnly ? 'pointer' : 'default' }}
      >
        {getPreview()}
      </div>
      {!readOnly && <div className="footnote">{text}</div>}
    </div>
  );
};

export default MediaPreview;
