import React from 'react';
import { Select } from 'antd';
import { filterUtil } from '../../util/filterUtil';

function getFilterOptions(quotaFilters, filters) {
  return [...quotaFilters, ...filters]
    .filter(f => f.type !== 'DEFAULT_FILTER')
    .map(f => <Select.Option key={filterUtil.getFilterJsonString(f)}>{f.name}</Select.Option>);
}

export const FilterSelect = props => {
  const { style, filters, quotaFilters, selectedFilters, readOnly, onFilterSelect } = props;

  function onChange(filterStrings) {
    onFilterSelect(filterStrings.map(jsonStr => JSON.parse(jsonStr)));
  }

  return (
    <Select
      mode="multiple"
      style={style || {}}
      disabled={readOnly}
      value={filterUtil.getFilterJsonStrings(selectedFilters)}
      onChange={onChange}
    >
      {getFilterOptions(quotaFilters, filters)}
    </Select>
  );
};
