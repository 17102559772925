import { connect } from 'react-redux';
import EditFunction from './EditFunction';

import { functionSelector } from '../../../store/redux/selectors/functionSelector';

const mapStateToProps = (state, props) => {
  const selector = functionSelector()(state, props);
  return {
    ...selector
  };
};

export default connect(mapStateToProps)(EditFunction);
