import { createWebSocketSendActions, createWebSocketReceiveActions, createFetchActions } from './createFetchActions';

const filtersAndParticipantsSubscribeActions = createWebSocketSendActions('FILTERS_AND_PARTICIPANTS_CHANNEL_SUBSCRIBE');
const newFiltersAndParticipantsActions = createWebSocketReceiveActions('NEW_FILTERS_AND_PARTICIPANTS');
const checkFilterActions = createFetchActions('CHECK_FILTER');
const setParticipantGroupActions = createFetchActions('SET_PARTICIPANT_GROUP');

export {
  filtersAndParticipantsSubscribeActions,
  newFiltersAndParticipantsActions,
  checkFilterActions,
  setParticipantGroupActions
};
