// Ported from firefly
import { cloneDeep, filter, find, flatMap, flatten, forEach, get, isObject, merge } from 'lodash';
import { STIM_TYPE } from './joinerUtil';

const getInnerJoinersWithSubNumbers = ({ concept, conceptDisplayNumber }) => {
  return concept.joiners.map((joiner, index) => {
    return merge(joiner, {
      displayNumber: `${conceptDisplayNumber}.${index + 1}`
    });
  });
};

export const hasConceptRotation = joiners => {
  return joiners.some(joiner => joiner.conceptRotation && !joiner.hidden && !joiner.orphaned);
};

export const getFlattenedJoinersList = list => {
  const filteredList = list.filter(j => !j.container);
  return flatMap(filteredList, (joiner, index) => {
    const conceptDisplayNumber = index + 1;
    joiner.displayNumber = conceptDisplayNumber;
    if (joiner.def || joiner.stim) {
      return joiner;
    }
    const rotationJoiner = cloneDeep(joiner);
    const innerJoiners = flatMap(rotationJoiner.conceptRotation.concepts, concept =>
      getInnerJoinersWithSubNumbers({ concept, conceptDisplayNumber })
    );
    return [rotationJoiner].concat(innerJoiners);
  });
};

const getOrderedJoiners = (joiners, rotationsOrder) => {
  const phaseOne = new Set();
  joiners.forEach(jn => {
    phaseOne.add(jn.conceptRotationId || jn);
  });
  const output = Array.from(phaseOne).map(item => {
    // an item is either a joiner or an id of a concept rotation
    if (isObject(item)) {
      return item;
    }
    return (
      rotationsOrder[item] &&
      flatten(rotationsOrder[item].map(conceptId => joiners.filter(jn => jn.conceptId === conceptId)))
    );
  });
  return filter(flatten(output), jn => jn);
};

/*
 * this function gets a list of answers and a list of all the survey joiners.
 * It returns a map of [conceptRotationId]: [[concept1], [concept2], ...] - every rotation and it's concepts,
 * orderd like the participant saw them
 */
const getConceptOrderFromAnswers = (answers, joiners) => {
  const rotations = {};
  answers.forEach(answer => {
    const joiner = find(joiners, jn => jn.id === answer.questionJoinerId);
    if (joiner?.conceptRotationId) {
      if (!rotations[joiner.conceptRotationId]) {
        rotations[joiner.conceptRotationId] = new Set([joiner.conceptId]);
      } else {
        rotations[joiner.conceptRotationId].add(joiner.conceptId);
      }
    }
  });
  forEach(rotations, (concepts, rotationId) => {
    rotations[rotationId] = Array.from(concepts);
  });
  return rotations;
};

export const getJoinersOrderedByParticipantAnswers = ({ answers, joiners }) => {
  const flattenedJoiners = getFlattenedJoinersList(joiners);
  const conceptOrder = getConceptOrderFromAnswers(answers, flattenedJoiners);
  return getOrderedJoiners(flattenedJoiners, conceptOrder);
};

/*
 * If the joiner is in a concept rotation, return the joiner title without the prefixed concept title in parens.
 * Else, return its researchPrompt.
 */
export const getNonPrefixedJoinerTitle = joiner => {
  return joiner.conceptId ? joiner.researchPrompt.replace(/^\(.+?\)/, '').trim() : joiner.researchPrompt;
};

export const getConceptAndJoinerTitles = ({ joiner, conceptRotationJoiner }) => {
  const joinerTitle = getNonPrefixedJoinerTitle(joiner);
  const conceptTitle = conceptRotationJoiner
    ? find(conceptRotationJoiner.conceptRotation.concepts, { id: joiner.conceptId }).title
    : '';
  return {
    joiner: joinerTitle,
    concept: conceptTitle
  };
};

export const getVideoStimFromJoiner = joiner => {
  return joiner.conceptRotation?.concepts?.find(concept => concept.startJoiner.stim.type === STIM_TYPE.video)
    .startJoiner.stim;
};

export const getVideoStimOptionsFromCRJoiner = crJoiner => {
  const concepts = get(crJoiner, 'conceptRotation.concepts') || [];
  const concept = concepts.find(c => get(c, 'startJoiner.stim.type') === STIM_TYPE.video);
  return concept ? concept.startJoiner.stim.options : null;
};

export const getCRJoinerById = (joiners, conceptRotationId) => {
  const joiner = joiners.find(joiner => joiner.id === conceptRotationId);
  const { concepts } = joiner?.conceptRotation ?? {};
  return concepts?.[0].joiners[0];
};
