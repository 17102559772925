import React from 'react';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { accessHandler, NavTab } from 'webapp-common';
import { useSessionUserSelector } from '../../../customHooks/reduxHelper';
import appConfig from '../../../appConfig';
import ReportWrapper from './reportWrapper';
import { ReportDownload } from './download/ReportDownload';
import ParticipantReport from './participant';
import Translations from './translations';

const DOWNLOAD_TAB = 'download';
const CROSSTAB_TAB = 'crosstab';
const CHART_TAB = 'chart';
const RAW_DATA_TAB = 'rawData';
const TRANSLATIONS_TAB = 'translations';
const PARTICIPANT_TAB = 'participant';

export const ProjectReportsAndData = props => {
  const { projectId, project, hasReportsManage, hasProjectManage } = props;

  const routeParams = useParams();
  const { subtab = DOWNLOAD_TAB } = routeParams;

  const { hasReportsView } = useSessionUserSelector().abilities;

  function getUrl(subTab) {
    return `${appConfig.projectPagePath}/${projectId}/${routeParams.tab}/${subTab}`;
  }

  const accessCheck = accessHandler.checkAccess(hasReportsView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <section className="app-body">
      <section className="left-sub-nav">
        <NavTab
          icon="fa-download"
          iconStyle="light"
          label={intl.get('app.download')}
          active={subtab === DOWNLOAD_TAB}
          to={getUrl(DOWNLOAD_TAB)}
        />
        {hasReportsManage && (
          <>
            <NavTab
              icon="fa-table"
              iconStyle="light"
              label={intl.get('app.crosstab')}
              active={subtab === CROSSTAB_TAB}
              to={getUrl(CROSSTAB_TAB)}
            />
            <NavTab
              icon="fa-file-chart-pie"
              iconStyle="light"
              label={intl.get('app.chart')}
              active={subtab === CHART_TAB}
              to={getUrl(CHART_TAB)}
            />
            <NavTab
              icon="fa-database"
              iconStyle="light"
              label={intl.get('app.rawData')}
              active={subtab === RAW_DATA_TAB}
              to={getUrl(RAW_DATA_TAB)}
            />
            <NavTab
              icon="fa-language"
              iconStyle="light"
              label={intl.get('app.translations')}
              active={subtab === TRANSLATIONS_TAB}
              to={getUrl(TRANSLATIONS_TAB)}
            />
            <NavTab
              icon="fa-file-user"
              iconStyle="light"
              label={intl.get('app.participant')}
              active={subtab === PARTICIPANT_TAB}
              to={getUrl(PARTICIPANT_TAB)}
            />
          </>
        )}
      </section>
      <section className="body-container l3-bg">
        {subtab === DOWNLOAD_TAB && <ReportDownload projectId={projectId} />}
        {subtab === CROSSTAB_TAB && (
          <ReportWrapper
            reportType="CROSSTAB"
            projectId={projectId}
            project={project}
            hasReportsView={hasReportsView}
          />
        )}
        {subtab === CHART_TAB && (
          <ReportWrapper reportType="CHART" projectId={projectId} project={project} hasReportsView={hasReportsView} />
        )}
        {subtab === RAW_DATA_TAB && (
          <ReportWrapper
            reportType="RAWEXTRACT"
            projectId={projectId}
            project={project}
            hasReportsView={hasReportsView}
          />
        )}
        {subtab === TRANSLATIONS_TAB && <Translations projectId={projectId} hasProjectManage={hasProjectManage} />}
        {subtab === PARTICIPANT_TAB && <ParticipantReport projectId={projectId} />}
      </section>
    </section>
  );
};
