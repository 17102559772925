import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { get, cloneDeep } from 'lodash';
import { RESPONSE_SET_TYPE } from '../../../util/joinerUtil';
import { saveRDConfig } from '../../../util/researchDashboardUtil';
import { RDQuestionDetailsHeader } from './RDQuestionDetailsHeader';
import OpenQuestionDetails from './questionDetails/open';
import OpenQuestionClusterAnalysis from './questionDetails/open/clusterAnalysis';
import { ChoiceQuestionDetails } from './questionDetails/choice/ChoiceQuestionDetails';
import { MatrixQuestionResponses } from './questionDetails/matrix/MatrixQuestionResponses';
import {
  getRDQuestionJoinerResponseRates,
  getRDConfig
} from '../../../store/redux/selectors/researchDashboardSelector';
import { EditFiltersModal } from '../rdFilters/EditFiltersModal';

export const RDQuestionDetails = props => {
  const {
    sessionId,
    questions,
    selectedQuestion,
    viewMode,
    onSelectQuestion,
    toggleQuestionListViewMode,
    closeQuestionDetail,
    projectId,
    viewLanguage
  } = props;

  const [editFiltersModalOpen, setEditFiltersModalOpen] = useState(false);
  const { joiner, type } = selectedQuestion;
  const rdConfig = useSelector(state => getRDConfig(state, sessionId));
  const projectStore = useSelector(state => state.projects && state.projects[projectId], shallowEqual) || {};
  const project = projectStore.projectDetails || {};
  const view = get(rdConfig, `configs.questionsConfig.globalQuestionConfig.verbatimResponsesView`, 'clusters');

  const setView = view => {
    const clone = cloneDeep(rdConfig);
    const { questionsConfig } = clone.configs;
    questionsConfig.globalQuestionConfig = questionsConfig.globalQuestionConfig || {};
    questionsConfig.globalQuestionConfig.verbatimResponsesView = view;
    saveRDConfig(clone);
  };

  const questionJoinerResponseRates = useSelector(
    state => getRDQuestionJoinerResponseRates(state, sessionId),
    shallowEqual
  );
  const total =
    questionJoinerResponseRates[selectedQuestion.id] && questionJoinerResponseRates[selectedQuestion.id].ansCount;

  const getDetailsComponent = () => {
    switch (type) {
      case RESPONSE_SET_TYPE.open:
        if (view === 'clusters') {
          return (
            <OpenQuestionClusterAnalysis
              sessionId={sessionId}
              view={view}
              setView={setView}
              questionJoiner={joiner}
              totalAnswers={total}
              viewLanguage={viewLanguage}
              setViewLanguage={props.setViewLanguage}
              language={props.language}
            />
          );
        }
        return (
          <OpenQuestionDetails
            sessionId={sessionId}
            questionJoiner={joiner}
            key={joiner.def.id}
            projectId={projectId}
            viewLanguage={viewLanguage}
            setViewLanguage={props.setViewLanguage}
            language={props.language}
            view={view}
            setView={setView}
          />
        );
      case RESPONSE_SET_TYPE.multi:
        return (
          <ChoiceQuestionDetails
            sessionId={sessionId}
            questionJoiner={joiner}
            key={joiner.def.id}
            viewLanguage={viewLanguage}
          />
        );
      case RESPONSE_SET_TYPE.matrix:
      case RESPONSE_SET_TYPE.ranked:
        return (
          <MatrixQuestionResponses
            sessionId={sessionId}
            questionJoiner={joiner}
            key={joiner.def.id}
            viewLanguage={viewLanguage}
          />
        );
      default:
        return <div />;
    }
  };

  const toggleEditFiltersModal = () => {
    setEditFiltersModalOpen(!editFiltersModalOpen);
  };

  return (
    <div className="rd-question-details qd-horizontal" key={selectedQuestion.id}>
      <RDQuestionDetailsHeader
        sessionId={sessionId}
        questions={questions}
        selectedQuestion={selectedQuestion}
        viewMode={viewMode}
        onSelectQuestion={onSelectQuestion}
        toggleQuestionListViewMode={toggleQuestionListViewMode}
        closeQuestionDetail={closeQuestionDetail}
        key={selectedQuestion.id}
        project={project}
        total={total}
        toggleEditFiltersModal={toggleEditFiltersModal}
        viewLanguage={viewLanguage}
      />
      {getDetailsComponent()}
      {editFiltersModalOpen && <EditFiltersModal sessionId={sessionId} toggle={toggleEditFiltersModal} />}
    </div>
  );
};
