import { LibraryPage } from './LibraryPage';
import { connect } from 'react-redux';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';

const mapStateToProps = state => {
  const { hasProjectView } = sessionUserSelector(state).abilities;
  return {
    hasProjectView
  };
};

export default connect(mapStateToProps)(LibraryPage);
