import { eventChannel } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { toast } from './toast';

let ws;
let emitter;

// Map used to track subscriptions so that we can re-submit them on websocket re-connect.
const subscriptions = new Map();

function updateSubscription(payload) {
  const { action, subAction, key } = payload;
  if (!action || !key) {
    return;
  }
  if (!subscriptions.has(action)) {
    subscriptions.set(action, new Map());
  }
  const channelSubscriptions = subscriptions.get(action);
  if (subAction === 'subscribe') {
    channelSubscriptions.set(key, payload);
  } else if (subAction === 'unsubscribe') {
    channelSubscriptions.delete(key);
  }
}

const parseJson = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    // no-op
  }
  return null;
};

/*
 * Handle a message coming through the websocket
 */
function* handleWSMessage(message) {
  const { error, responseType, payload } = message;
  if (!responseType && error) {
    // Handle general errors from the back-end
    return toast.error({ text: error.errorMessage });
  }
  yield put({
    type: `RECEIVE_${responseType}_${error ? 'FAILED' : 'SUCCEEDED'}`,
    payload,
    error,
    '@@redux-saga/SAGA_ACTION': true
  });
}

function reConnect() {
  initWebSocket(true);
  ws.onopen = resendMessages;
}

function resendMessages() {
  subscriptions.forEach(channelSubscriptions => {
    channelSubscriptions.forEach(subscription => {
      sendWSMessage(subscription);
    });
  });
}

function initWebSocket(force) {
  if (!ws || force) {
    const { location } = window;
    const protocol = location.protocol === 'https:' ? 'wss' : 'ws';
    const url = `${protocol}://${location.host}/a/wsSession/falcon`;
    ws = new WebSocket(url);
    ws.onmessage = message => {
      const obj = parseJson(message.data);
      if (obj) {
        return emitter(obj);
      }
    };
    ws.onclose = () => setTimeout(reConnect, 1000);
  }
}

function* initEventChannel() {
  if (!emitter) {
    const channel = eventChannel(emit => {
      emitter = emit;
      return () => {
        ws.close();
      };
    });
    yield takeEvery(channel, handleWSMessage);
  }
}

export function* subscribeToChannel(payload) {
  yield* initEventChannel();
  initWebSocket();
  updateSubscription(payload);
  sendWSMessage(payload);
}

// Send a msg through the websocket. Uses a setTimeout() to
// safely wait for the websocket to be in the ready state.
const send = (payload, ms) => {
  setTimeout(() => {
    if (ws.readyState === ws.OPEN) {
      ws.send(JSON.stringify(payload));
    } else {
      send(payload, 60);
    }
  }, ms);
};

export function sendWSMessage(payload) {
  send(payload, 0);
}
