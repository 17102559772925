import React from 'react';
import { JoinerEditorTabs, JoinerEditorHeader, JoinerEditorButtons } from '../joinerEditor';
import Edit from '../editForms/edit/ConceptRotationEdit';
import ConceptRotationMedia from '../editForms/media/ConceptRotationMedia';
import Options from '../editForms/options/ConceptRotationOptions';
import Rules from '../editForms/rules/Rules';

import './ConceptRotationEditor.css';

class ConceptRotationEditor extends React.Component {
  render() {
    const { props } = this;
    return (
      <>
        <JoinerEditorTabs tabs={props.tabs} joinerInfo={props.joinerInfo} hideTiming />
        <div className="joiner-editor">
          <JoinerEditorHeader {...props} />
          <div className="joiner-editor-form">
            <Edit {...props} />
            <ConceptRotationMedia {...props} />
            <Options {...props} />
            <Rules {...props} />
          </div>
          <JoinerEditorButtons
            toggle={props.toggle}
            saveEnabled={props.saveEnabled}
            saveJoiner={props.saveJoiner}
            readOnly={props.readOnly}
          />
        </div>
      </>
    );
  }
}

export default ConceptRotationEditor;
