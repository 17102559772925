import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';

export const DeleteSurveyModal = props => {
  const { toggle, title, buttonText, confirmation } = props;

  function save() {
    props.onSave();
    props.toggle();
  }

  return (
    <InvokeModal
      showModal={true}
      toggle={toggle}
      modalTitle={title}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={buttonText}
      enableSave={true}
      save={save}
    >
      <p className="m-5">{confirmation}</p>
    </InvokeModal>
  );
};
