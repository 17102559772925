import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { accessHandler, Loader, NavTab } from 'webapp-common';
import appConfig from '../../../../appConfig';
import CollectionParticipants from '../collectionParticipants';

import './ProjectRecruitCollection.css';

export const ProjectRecruitCollection = props => {
  const { project, hasProjectView } = props;

  const navigate = useNavigate();
  const routeParams = useParams();

  const collectionId = routeParams.subtab;
  const { projectDetails = {}, participantCollectionJobs = {}, participantCollectionJobsRequested } = project || {};

  const pingerRef = useRef();
  const selectedJob = getParticipantCollectionJob();

  useEffect(() => {
    if (projectDetails.id && !selectedJob) {
      props.fetchParticipantCollectionJobs({
        pageNumber: 1,
        pageSize: -1,
        projectId: projectDetails.id,
        storeId: projectDetails.config.metaDataStoreId
      });
    }
  }, [projectDetails.id]);

  useEffect(() => {
    if (selectedJob) {
      if (selectedJob.status === 'Running') {
        pingerRef.current = setInterval(pingParticipantCollectionJob, 2000);
      } else {
        clearInterval(pingerRef.current);
      }
    }
  }, [selectedJob?.status]);

  // Ping for job updates
  function pingParticipantCollectionJob() {
    props.pingParticipantCollectionJobs({
      projectId: projectDetails.id,
      jobIds: [selectedJob.jobId]
    });
  }

  function getParticipantCollectionJob() {
    if (participantCollectionJobs.content) {
      return participantCollectionJobs.content.find(job => job.collection.id === collectionId);
    }
    return null;
  }

  function back() {
    navigate.goBack();
  }

  if (!projectDetails) {
    return null;
  }

  const accessCheck = accessHandler.checkAccess(hasProjectView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  const showLoader = participantCollectionJobsRequested;

  return (
    <section className="app-body">
      {showLoader && <Loader spinner fullScreen />}
      <section className="left-sub-nav">
        <NavTab
          icon="fa-arrow-left"
          iconStyle="light"
          label={intl.get('app.backToRecruit')}
          to={`${appConfig.projectPagePath}/${routeParams.projectId}/${routeParams.tab}`}
        />
      </section>
      <section className="body-container l3-bg">
        {selectedJob && (
          <CollectionParticipants
            project={project}
            collectionId={collectionId}
            job={selectedJob}
            onCollectionDelete={back}
          />
        )}
      </section>
    </section>
  );
};
