import React from 'react';
import { cloneDeep } from 'lodash';
import { Input } from 'reactstrap';
import intl from 'react-intl-universal';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';

import './SurveyVariables.css';

const DEFAULT_VARIABLE = { token: '', value: '' };

const SurveyVariables = props => {
  const { variablesArray, readOnly, setState } = props;

  function addOrRemoveVariable(i, add) {
    const variables = cloneDeep(variablesArray);
    if (add) {
      variables.push(DEFAULT_VARIABLE);
    } else {
      variables.splice(i, 1);
      if (variables.length === 0) {
        variables.push(DEFAULT_VARIABLE);
      }
    }
    setState({ variablesArray: variables });
  }

  function onVariableChange(value, token, index) {
    const variables = cloneDeep(variablesArray);
    variables[index][token] = value;
    setState({ variablesArray: variables });
  }

  const showVariables = () => {
    return variablesArray.map((k, i) => {
      return (
        <div style={{ display: 'flex', marginBottom: '.5rem' }} key={`survey_variable_${i}`}>
          <Input
            className="column"
            value={k.token}
            onChange={e => onVariableChange(e.target.value, 'token', i)}
            disabled={readOnly}
          />
          <Input
            className="column"
            value={k.value}
            onChange={e => onVariableChange(e.target.value, 'value', i)}
            disabled={readOnly || !k.token}
          />
          <AddRemoveIcons
            onAdd={() => addOrRemoveVariable(i, true)}
            onRemove={() => addOrRemoveVariable(i, false)}
            addDisabled={readOnly}
            removeDisabled={readOnly}
          />
        </div>
      );
    });
  };

  return (
    <div className="survey-variables">
      <div style={{ display: 'flex' }}>
        <div className="column">{intl.get('app.name')}</div>
        <div>{intl.get('app.defaultValue')}</div>
      </div>
      {showVariables()}
    </div>
  );
};

export { SurveyVariables, DEFAULT_VARIABLE };
