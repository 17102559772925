import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import SurveyList from '../../../../containers/surveyList';

import './SelectSurveyModal.css';

const initialState = {
  selectedSurvey: undefined
};

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const SelectSurveyModal = props => {
  const { session, saveSession, show, toggle } = props;

  const [state, setState] = useReducer(reducer, initialState);
  const { selectedSurvey } = state;

  const onSurveySelect = survey => {
    const selectedSurvey = survey && state.selectedSurvey && survey.id === state.selectedSurvey.id ? undefined : survey;
    setState({
      selectedSurvey
    });
  };

  const onSaveSurveySelect = () => {
    const payload = {
      ...session,
      surveyId: selectedSurvey.id
    };
    saveSession({ payload });
  };

  return (
    <InvokeModal
      className="select-survey-modal"
      toggle={toggle}
      showModal={show}
      modalTitle={intl.get('app.select.surveyTemplate')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      enableSave={!!selectedSurvey}
      save={onSaveSurveySelect}
    >
      <SurveyList
        selectedSurveyId={selectedSurvey && selectedSurvey.id}
        onSurveySelect={onSurveySelect}
        isScreener={session.screener}
      />
    </InvokeModal>
  );
};
