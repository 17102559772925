import { handleActions } from 'redux-actions';
import { appUtil } from '../../../util/appUtil';
import { fetchUserActions, updateUserNameAction } from '../actions/userActions';
import { updateTenantLogoActions } from '../actions/clientAdminActions';

const INTERNAL_SUPER_USER = 'internalSuperUser';
const PROJECT_MANAGE = 'projectManage';
const PROJECT_VIEW = 'projectView';
const REPORTS_MANAGE = 'reportsManage';
const REPORTS_VIEW = 'reportsView';
const ORG_MANAGE = 'orgManage';
const ORG_VIEW = 'orgView';
const ORG_ROLE_MANAGE = 'orgRoleManage';
const ORG_ROLE_VIEW = 'orgRoleView';
const USER_ACCOUNTS_MANAGE = 'userAccountsManage';
const USER_ACCOUNTS_VIEW = 'userAccountsView';

const initialState = {
  user: {},
  tenant: {},
  abilities: {}
};

const getUserAbilities = user => {
  const { userTenantRoles } = user || {};
  const abilities = new Set();
  userTenantRoles &&
    userTenantRoles.forEach(role => {
      role &&
        role.featureAbilities &&
        role.featureAbilities.forEach(ability => abilities.add(ability.abilityIdentifier));
    });
  return abilities.size === 0 ? null : abilities;
};

const getActiveLicenseNames = tenant => {
  const { licenses } = tenant || {};
  const licenseNames = new Set();
  licenses && licenses.forEach(l => l.isActive && licenseNames.add(l.licenseName));
  return licenseNames.size === 0 ? null : licenseNames;
};

const getAbilities = (user, tenant) => {
  const abilities = getUserAbilities(user);
  const licenseNames = getActiveLicenseNames(tenant);
  return {
    // User abilities
    isInternalSuperUser: abilities && abilities.has(INTERNAL_SUPER_USER),
    hasProjectManage: abilities && abilities.has(PROJECT_MANAGE),
    hasProjectView: abilities && abilities.has(PROJECT_VIEW),
    hasReportsManage: abilities && abilities.has(REPORTS_MANAGE),
    hasReportsView: abilities && abilities.has(REPORTS_VIEW),
    hasOrgManage: abilities && abilities.has(ORG_MANAGE),
    hasOrgView: abilities && abilities.has(ORG_VIEW),
    hasRoleManage: abilities && abilities.has(ORG_ROLE_MANAGE),
    hasRoleView: abilities && abilities.has(ORG_ROLE_VIEW),
    hasUserAccountsManage: abilities && abilities.has(USER_ACCOUNTS_MANAGE),
    hasUserAccountsView: abilities && abilities.has(USER_ACCOUNTS_VIEW),
    // Client abilities
    isCustomReportsEnabled: licenseNames && licenseNames.has('CUSTOM_REPORTS'),
    // isVideoCaptureEnabled: licenseNames && licenseNames.has('VIDEO_CAPTURE'),
    isVideoCaptureEnabled: false,
    isVideoDialEnabled: licenseNames && licenseNames.has('VIDEO_DIAL_TESTING')
  };
};

const getUpdatedClients = (clients, client) => {
  const index = clients.findIndex(c => c.clientID === client.clientID);
  if (index !== -1) {
    clients[index] = client;
  }
  return clients;
};

const userReducer = handleActions(
  {
    [fetchUserActions.triggered](state) {
      return { ...state };
    },

    [fetchUserActions.succeeded](state, { payload }) {
      const { user = {}, tenant = {} } = payload || {};
      return {
        ...state,
        user,
        tenant,
        abilities: getAbilities(user, tenant),
        clientUIConfig: (tenant && tenant.clientUIConfig) || appUtil.getDefaultUIConfig()
      };
    },

    [fetchUserActions.failed]() {
      return initialState;
    },

    [updateUserNameAction.triggered](state) {
      return { ...state, requestInProgress: true };
    },

    [updateUserNameAction.succeeded](state, payload) {
      return {
        ...state,
        requestInProgress: false
      };
    },

    [updateUserNameAction.failed]() {
      return { ...initialState, requestInProgress: false };
    },

    [updateTenantLogoActions.succeeded](state, { payload }) {
      const clients = getUpdatedClients(state.clients || state.user.clients, payload);
      return {
        ...state,
        clients
      };
    }
  },
  initialState
);

export default userReducer;
