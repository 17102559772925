import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Input } from 'reactstrap';

import './SigTesting.css';

const PRIMARY_CONFIDENCE_LEVELS = [99, 95, 90, 80, 70];
const SECONDARY_CONFIDENCE_LEVELS = [95, 90, 80, 70];

function getPrimaryConfidenceOptions() {
  return PRIMARY_CONFIDENCE_LEVELS.map(value => (
    <option value={value} key={value}>
      {value}
    </option>
  ));
}

function getSecondaryConfidenceOptions() {
  return SECONDARY_CONFIDENCE_LEVELS.map(value => (
    <option value={value} key={value}>
      {value}
    </option>
  ));
}

export const SigTesting = props => {
  const { sigTestSetting, setSigTestingField, hidden, disabled } = props;
  const { primaryConfidence, secondaryConfidence, testBelowMin, testSecondary, thresholdSize } = sigTestSetting;

  return (
    <div className={classnames({ 'sig-testing': true, hidden })}>
      <div>
        <div>{intl.get('app.sigTesting.primaryConfidence')}</div>
        <Input
          type="select"
          value={primaryConfidence}
          disabled={disabled}
          onChange={e => setSigTestingField('primaryConfidence', e.target.value)}
        >
          {getPrimaryConfidenceOptions()}
        </Input>
      </div>
      <div>
        <label style={{ whiteSpace: 'nowrap' }}>
          <Input
            type="checkbox"
            checked={testSecondary}
            disabled={disabled}
            onChange={() => setSigTestingField('testSecondary', !testSecondary)}
          />
          {intl.get('app.sigTesting.secondaryConfidence')}
        </label>
        <Input
          type="select"
          value={secondaryConfidence}
          onChange={e => setSigTestingField('secondaryConfidence', e.target.value)}
          disabled={disabled || !testSecondary}
        >
          {getSecondaryConfidenceOptions()}
        </Input>
      </div>
      <div>
        <div>{intl.get('app.sigTesting.minThreshold')}</div>
        <Input
          type="number"
          min="2"
          value={thresholdSize}
          disabled={disabled}
          onChange={e => setSigTestingField('thresholdSize', e.target.value)}
        />
      </div>
      <div>
        <label>
          <Input
            type="radio"
            checked={!testBelowMin}
            disabled={disabled}
            onChange={() => setSigTestingField('testBelowMin', false)}
          />
          {intl.get('app.sigTesting.disableTestBelowMin')}
        </label>
      </div>
      <div>
        <label>
          <Input
            type="radio"
            checked={testBelowMin}
            disabled={disabled}
            onChange={() => setSigTestingField('testBelowMin', true)}
          />
          {intl.get('app.sigTesting.enableTestBelowMin')}
        </label>
      </div>
    </div>
  );
};
