import React, { memo, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, ReferenceLine, LineChart, Line, Tooltip } from 'recharts';
import { get, isEqual } from 'lodash';
import intl from 'react-intl-universal';
import { getFormatedTime } from '../../../util/joinerUtil';
import { RANGE_MAX } from '../sessionDetailsCommon/sessionDetailDataUtil';

const skipUpdate = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.chartData, nextProps.chartData) &&
    isEqual(prevProps.currentVideoTime, nextProps.currentVideoTime) &&
    isEqual(prevProps.selectedFilters, nextProps.selectedFilters) &&
    isEqual(prevProps.emotions, nextProps.emotions) &&
    isEqual(prevProps.colorMap, nextProps.colorMap) &&
    prevProps.autoScaleY === nextProps.autoScaleY &&
    prevProps.left === nextProps.left &&
    prevProps.right === nextProps.right &&
    prevProps.height === nextProps.height
  );
};

export const EmotionGraph = memo(props => {
  const {
    chartData,
    emotions,
    currentVideoTime,
    selectedFilters,
    chartWidth,
    height,
    colorMap,
    autoScaleY,
    left,
    right
  } = props;
  const { scaleLeft = 0, scaleRight = RANGE_MAX } = autoScaleY ? { scaleLeft: 'dataMin', scaleRight: 'dataMax' } : {};
  const [tooltipFor, setTooltipFor] = useState(false);

  const showLineTooltip = (name, em) => {
    setTooltipFor(`${name}-${em}`);
  };

  const onChartClick = e => {
    const time = get(e, 'activePayload[0].payload.time');
    if (time >= 0) {
      props.setCurrentVideoTime(time, true);
    }
  };

  const CustomTooltip = props => {
    const { active, payload } = props;
    if (active && payload) {
      return payload.map(line => {
        if (line.name === tooltipFor) {
          const [name, emotion] = tooltipFor.split('-');
          const color = colorMap[name][emotion.toUpperCase()];
          const val = chartData[name][emotion.toUpperCase()][line.payload.time].confidence;
          return (
            <div className="custom-tooltip">
              <p className="label" style={{ color }}>{`${line.name}: ${val}`}</p>
              <p style={{ color }}>{`Time: ${line.payload.time}`}</p>
            </div>
          );
        }
      });
    }
    return null;
  };

  const getEmotionLines = () => {
    return selectedFilters.map(filter => {
      const { name } = filter;
      return emotions.map(emotion => {
        const data = chartData[name][emotion];
        const color = colorMap[name] ? colorMap[name][emotion] : colorMap.default[emotion];
        const em = intl.get(`app.${emotion}`);
        return (
          <Line
            data={data}
            dataKey="confidence"
            stroke={color}
            type="monotone"
            strokeWidth={2}
            name={`${name}-${em}`}
            isAnimationActive={false}
            dot={false}
            onMouseOver={() => showLineTooltip(name, em)}
            onMouseLeave={() => setTooltipFor(null)}
          />
        );
      });
    });
  };

  const getLineChart = () => {
    const max = right ? right : 'dataMax';
    return (
      <LineChart
        width={chartWidth}
        height={height}
        margin={{ top: 10, right: 20, bottom: 0, left: -20 }}
        onClick={onChartClick}
      >
        <CartesianGrid stroke="#666" />
        <XAxis
          dataKey="time"
          allowDataOverflow={true}
          type="number"
          name="time"
          tickFormatter={getFormatedTime}
          domain={[left, max]}
        />
        <YAxis dataKey="confidence" type="number" name="confidence" domain={[scaleLeft, scaleRight]} />
        <Tooltip content={<CustomTooltip />} cursor={true} isAnimationActive={false} />
        {getEmotionLines()}
        {currentVideoTime >= 0 && <ReferenceLine x={currentVideoTime} stroke="#ff0000" />}
      </LineChart>
    );
  };
  return <div className="emotion-graph">{getLineChart()}</div>;
}, skipUpdate);
