import React from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { accessHandler, NavTab } from 'webapp-common';
import appConfig from '../../../appConfig';
import ClientAdminGeneralSettingsForm from '../generalSettings';
import ClientAdminFeaturesForm from '../features';
import ClientAdminCustomUIForm from '../customUI';

const GENERAL_SETTINGS_TAB = 'general';
const FEATURES_TAB = 'features';
const CUSTOM_UI_TAB = 'custom';

export const ClientSettings = props => {
  const { clientId, hasOrgView, isInternalSuperUser, tab } = props;

  const { subtab: activeTab = GENERAL_SETTINGS_TAB } = useParams();

  function getUrl(subTab) {
    return `${appConfig.orgPagePath}/${tab}/${subTab}`;
  }

  const accessCheck = accessHandler.checkAccess(hasOrgView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <div className="app-body client-settings">
      <div className="left-sub-nav">
        <NavTab
          icon="fa-sliders-h-square"
          iconStyle="light"
          label={intl.get('app.generalSettings')}
          active={activeTab === GENERAL_SETTINGS_TAB}
          to={getUrl(GENERAL_SETTINGS_TAB)}
        />
        <NavTab
          icon="fa-tools"
          iconStyle="light"
          label={intl.get('app.features')}
          active={activeTab === FEATURES_TAB}
          to={getUrl(FEATURES_TAB)}
        />
        <NavTab
          icon="fa-swatchbook"
          iconStyle="light"
          label={intl.get('app.customUi')}
          active={activeTab === CUSTOM_UI_TAB}
          to={getUrl(CUSTOM_UI_TAB)}
        />
      </div>
      <section className="body-container l3-bg">
        {activeTab === GENERAL_SETTINGS_TAB && (
          <ClientAdminGeneralSettingsForm clientId={clientId} isInternalSuperUser={isInternalSuperUser} />
        )}
        {activeTab === FEATURES_TAB && (
          <ClientAdminFeaturesForm clientId={clientId} isInternalSuperUser={isInternalSuperUser} />
        )}
        {activeTab === CUSTOM_UI_TAB && (
          <ClientAdminCustomUIForm clientId={clientId} isInternalSuperUser={isInternalSuperUser} />
        )}
      </section>
    </div>
  );
};
