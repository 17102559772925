import createFetchSaga from './createFetchSaga';
import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { fetchAuthTokenActions, validateAuth, logoutUser, changePasswordActions } from '../actions/authActions';
import { jsUtil } from '../../../util/jsUtil';
import {
  authenticateUserApi,
  authTokenToCookie,
  authTokenFromCookie,
  changeUserPasswordApi
} from '../../../api/authApi';
import { authSelector, authenticatedSelector } from '../selectors/authSelectors';
import appConfig from '../../../appConfig';
import { auth as authApi } from '../../../api/Auth';

function* authTokenReceivedSaga({ payload: auth }) {
  const redirectToHome = auth.redirectToHome === undefined || auth.redirectToHome;
  yield call(authTokenToCookie, auth.authToken);
  if (redirectToHome) {
    const queries = jsUtil.getUrlQueries();
    window.location =
      queries && queries.r ? appConfig.invokeAuthCallbackURL + '?r=' + queries.r : appConfig.invokeAuthCallbackURL;
  }
}

function logoutUserSaga({ payload }) {
  authApi.logout();
  let loginUrl = `/f${appConfig.loginPage}`;
  if (window.location.pathname !== loginUrl) {
    const queries = jsUtil.getUrlQueries();
    const logoutUrl = `/f${appConfig.logoutPage}`;
    const redirect = queries.r || (payload && payload.redirect);
    if (redirect && redirect !== loginUrl && redirect !== logoutUrl) {
      loginUrl += `?r=${redirect}`;
    }
    window.location = loginUrl;
  }
}

function* changePasswordSaga() {
  const passwordChangeSaga = createFetchSaga(changePasswordActions, changeUserPasswordApi);
  yield* passwordChangeSaga();
}

function* validateAuthSaga({ payload = {} }) {
  const { redirectToHome = false } = payload;
  const { authToken } = yield call(authTokenFromCookie);

  const state = yield select();
  const auth = authSelector(state);
  const authenticated = authenticatedSelector(state);

  if (authToken != null) {
    // Auth Cookie is present
    if (!authenticated && authApi.isAuthenticated(false)) {
      //user not logged in on app; log user in.
      yield put(fetchAuthTokenActions.succeeded({ authToken, redirectToHome }));
    } else if (!authApi.isAuthenticated(false) || auth.authToken !== authToken) {
      // some other user on app or inconsistent state; log user out
      yield put(logoutUser());
    } else {
      // Nothing to do, its same user
    }
  } else {
    // Auth cookie not present

    // Cookie should be present immediately after login
    // if user is authenticated => cookie expired or deleted -> trigger logout user
    // if user is not authenticated -> trigger logout user to force a redirect to
    // login page by default
    yield put(logoutUser({ redirect: document.location.pathname }));
  }
}

function* authSaga() {
  const fetchAuthTokenSaga = createFetchSaga(fetchAuthTokenActions, authenticateUserApi);
  yield* fetchAuthTokenSaga();
  yield takeLatest(fetchAuthTokenActions.succeeded, authTokenReceivedSaga);
  yield takeLatest(logoutUser, logoutUserSaga);
  yield takeEvery(validateAuth, validateAuthSaga);
}

export { authSaga, changePasswordSaga };
