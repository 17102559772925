import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Input } from 'reactstrap';
import { PanelNameRow } from './PanelNameRow';

export const GlobalPanelConfig = props => {
  const {
    config,
    sessionConfig,
    isSelected,
    isScreener,
    readOnly,
    onPanelSelect,
    setQuota,
    setExpectedIR,
    setOverrideValue
  } = props;

  // paramGroup = global, terminate, completed, overQuota
  function getParamOverrideSection(paramGroup) {
    return (
      <div>
        {intl.get(`app.panel.config.${paramGroup}Params.label`)}:
        {config[`${paramGroup}Parameters`].map((param, index) => {
          const overrideParam = sessionConfig[`${paramGroup}Parameters`][index];
          return (
            <div className="param-override-row">
              <div>{param.second}:</div>
              <Input
                placeholder={param.first}
                value={overrideParam?.first}
                disabled={readOnly}
                onChange={e => setOverrideValue(config.id, paramGroup, param.second, e.target.value)}
              />
              <div className="param-type-small">{intl.get(`app.panel.param.type.${param.type}.small`)}</div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classnames({ panel: true, 'selected-panel': isSelected })}>
      <PanelNameRow
        config={config}
        maxQuota={sessionConfig?.maxQuota}
        expectedIR={sessionConfig?.expectedIR}
        isSelected={isSelected}
        isScreener={isScreener}
        readOnly={readOnly}
        onPanelSelect={onPanelSelect}
        setQuota={setQuota}
        setExpectedIR={setExpectedIR}
      />
      {isSelected && (
        <div className="param-overrides">
          {config.globalParameters.length !== 0 && getParamOverrideSection('global')}
          {config.completedParameters.length !== 0 && getParamOverrideSection('completed')}
          {config.terminateParameters.length !== 0 && getParamOverrideSection('terminate')}
          {config.overQuotaParameters.length !== 0 && getParamOverrideSection('overQuota')}
        </div>
      )}
    </div>
  );
};
