function getDefaultUIConfig() {
  return {
    headerColor: '#FFFFFF',
    headerTextColor: '#444444',
    buttonColor: '#0569A9',
    buttonTextColor: '#FFFFFF',
    secondaryButtonColor: '#D9E9F2',
    secondaryButtonTextColor: '#0569A9',
    footerBackground: '#9ED9F1',
    footerTextColor: '#444444',
    fontFamily: 'Volte,"Helvetica Neue",sans-serif'
  };
}

export const appUtil = { getDefaultUIConfig };
