import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';
import { enrollmentTypes } from '../../util/participantDataUtil';
import ParticipantList from '../participantList';

import './ParticipantEnrollmentSelectModal.css';

const reducer = (state, payload) => ({ ...state, ...payload });

export const ParticipantEnrollmentSelectModal = props => {
  const { session, participantCollectionJobs, projectPanelConfigs = [], sessions, screeners, readOnly } = props;
  const { participantCollectionIds } = session.config;

  const [state, setState] = useReducer(reducer, {
    screenerAndImportCollections: [],
    autoEnrollCollections: [],
    session,
    showConfirmation: false
  });

  useEffect(() => {
    const stateClone = { ...state };
    participantCollectionJobs.forEach(cj => {
      if (cj.type === enrollmentTypes.SCREENER || cj.type === enrollmentTypes.IMPORT) {
        stateClone.screenerAndImportCollections.push(cj);
      } else if (cj.collection.autoEnrolled) {
        stateClone.autoEnrollCollections.push(cj);
      }
    });
    setState(stateClone);
  }, [participantCollectionJobs]);

  const directToSurvey = screeners?.some(s => s.directToSurvey && s.directToSurveySessionId === session.id);
  const isReadOnly = readOnly || directToSurvey;

  function setSessionToImportOrScreener() {
    const clone = cloneDeep(state.session);
    clone.config.participantCollectionIds = [];
    clone.panelConfigId = '';
    clone.targetedURL = true;
    clone.autoEnroll = false;
    setState({
      session: clone
    });
  }

  function setSessionToAutoEnroll() {
    const clone = cloneDeep(state.session);
    clone.config.participantCollectionIds = [];
    clone.panelConfigId = '';
    clone.targetedURL = true;
    clone.autoEnroll = true;
    setState({
      session: clone
    });
  }

  function setSessionToPublic() {
    const clone = cloneDeep(state.session);
    clone.config.participantCollectionIds = [];
    clone.panelConfigId = '';
    clone.targetedURL = false;
    clone.autoEnroll = false;
    setState({
      session: clone
    });
  }

  const isImportOrScreener = state.session.targetedURL && !state.session.autoEnroll;
  const isAutoEnroll = state.session.targetedURL && state.session.autoEnroll;
  const isPublic = !state.session.targetedURL && !state.session.autoEnroll;

  function setSelectedEnrollment(e) {
    const clone = cloneDeep(state.session);
    const idx = clone.config.participantCollectionIds.indexOf(e.target.value);
    if (idx === -1) {
      clone.config.participantCollectionIds.push(e.target.value);
    } else {
      clone.config.participantCollectionIds.splice(idx, 1);
    }
    setState({ session: clone });
  }

  function setSelectedAutoEnroll(e) {
    const panelConfigId = e.target.value;
    const clone = cloneDeep(state.session);
    if (clone.panelConfigId !== panelConfigId) {
      clone.panelConfigId = panelConfigId;
      const config = projectPanelConfigs.find(config => config.id === panelConfigId);
      if (config.collectionId) {
        clone.config.participantCollectionIds = [config.collectionId];
      }
    } else {
      clone.panelConfigId = '';
      clone.config.participantCollectionIds = [];
    }
    setState({ session: clone });
  }

  function showChangeEnrollmentConfirmDialog(bool) {
    setState({ showConfirmation: bool });
  }

  function onSaveClick() {
    if (state.session.config.participantCollectionIds.length > 0 && participantCollectionIds.length === 0) {
      save();
    } else {
      showChangeEnrollmentConfirmDialog(true);
    }
  }

  function save() {
    props.saveSession({ payload: state.session });
    showChangeEnrollmentConfirmDialog(false);
    props.toggle();
  }

  function getEnrollmentSelectControls(enrollmentList, disabled) {
    return (
      <div className="ms-3">
        {enrollmentList.map(cj => (
          <label disabled={disabled || isReadOnly} key={cj.collection.id}>
            <Input
              type="checkbox"
              value={cj.collection.id}
              checked={state.session.config.participantCollectionIds.includes(cj.collection.id)}
              onChange={setSelectedEnrollment}
            />
            {cj.collection.name}
          </label>
        ))}
      </div>
    );
  }

  function getAutoEnrollSelectControls(projectPanelConfigs, disabled) {
    return (
      <div className="ms-3">
        {projectPanelConfigs
          .filter(config => config.type === enrollmentTypes.AUTO_ENROLL)
          .map(config => {
            const configInUse = sessions?.find(s => s.id !== session.id && s.panelConfigId === config.id);
            return (
              <label disabled={disabled || isReadOnly || configInUse} key={config.id}>
                <Input
                  type="radio"
                  value={config.id}
                  checked={state.session.panelConfigId === config.id}
                  onClick={setSelectedAutoEnroll}
                />
                {config.name}
              </label>
            );
          })}
      </div>
    );
  }

  function isSaveEnabled() {
    if (
      isImportOrScreener &&
      state.session.config.participantCollectionIds.length > 0 &&
      state.session.config.participantCollectionIds.sort().join() !== participantCollectionIds.sort().join()
    ) {
      return true;
    }
    if (isAutoEnroll && state.session.panelConfigId && state.session.panelConfigId !== session.panelConfigId) {
      return true;
    }
    if (isPublic && session.targetedURL) {
      return true;
    }
    return false;
  }

  return (
    <>
      <InvokeModal
        showModal
        className="participant-enrollment-select-modal"
        modalTitle={intl.get('app.participantEnrollment')}
        toggle={props.toggle}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        enableSave={isSaveEnabled()}
        save={onSaveClick}
      >
        <div className="participant-enrollment-selection">
          <div className="left">
            <div className="fw-600 mb-2">{intl.get('app.selectEnrollment')}:</div>
            <div className="ms-3">
              {state.screenerAndImportCollections.length > 0 && (
                <>
                  <label disabled={isReadOnly}>
                    <Input type="radio" checked={isImportOrScreener} onChange={setSessionToImportOrScreener} />
                    {intl.get('app.screener')}/{intl.get('app.import')}
                  </label>
                  {getEnrollmentSelectControls(state.screenerAndImportCollections, !isImportOrScreener)}
                </>
              )}
              {projectPanelConfigs.some(config => config.type === enrollmentTypes.AUTO_ENROLL) && (
                <>
                  <label disabled={isReadOnly}>
                    <Input type="radio" checked={isAutoEnroll} onChange={setSessionToAutoEnroll} />
                    {intl.get('app.autoEnroll')}
                  </label>
                  {getAutoEnrollSelectControls(projectPanelConfigs, !isAutoEnroll)}
                </>
              )}
              <label disabled={isReadOnly}>
                <Input type="radio" checked={isPublic} onChange={setSessionToPublic} />
                {intl.get('app.public')}
              </label>
            </div>
          </div>
          <div className="right">
            <div className="fw-600 mb-3">{intl.get('app.participants')}:</div>
            <div>
              <div style={{ minHeight: '28rem' }}>
                <ParticipantList entityId={state.session.config.participantCollectionIds.join(',')} enablePagination />
              </div>
            </div>
          </div>
        </div>
      </InvokeModal>
      {state.showConfirmation && (
        <InvokeModal
          showModal
          toggle={() => showChangeEnrollmentConfirmDialog(false)}
          modalTitle={intl.get('app.warning')}
          cancelButtonText={intl.get('app.cancel')}
          primaryButtonText={intl.get('app.confirm')}
          save={save}
          enableSave
        >
          {intl.get('app.session.enrollment.warning')}
        </InvokeModal>
      )}
    </>
  );
};
