import { connect } from 'react-redux';
import ReportList from './ReportList';
import { reportListSelector } from '../../store/redux/selectors/reportListSelector';
import { deleteReportActions, fetchReportListActions } from '../../store/redux/actions/reportListActions';

const mapDispatchToProps = dispatch => ({
  deleteReport: reportId => dispatch(deleteReportActions.request(reportId)),
  fetchReports: params => dispatch(fetchReportListActions.request(params))
});
export default connect(reportListSelector, mapDispatchToProps)(ReportList);
