import { connect } from 'react-redux';
import { blacklistSelector } from '../../../store/redux/selectors/blacklistSelector';
import { deleteFromBlacklistAction, fetchBlacklistAction } from '../../../store/redux/actions/clientAdminActions';
import { BlacklistPage } from './BlacklistPage';

const mapStateToProps = state => {
  const blacklistStore = blacklistSelector(state);
  return {
    blacklist: blacklistStore.blacklist
  };
};

const mapDispatchToProps = dispatch => ({
  fetchBlacklist: payload => dispatch(fetchBlacklistAction.request(payload)),
  deleteFromBlacklist: payload => dispatch(deleteFromBlacklistAction.request(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlacklistPage);
