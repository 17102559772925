import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { Button, Input } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { accessHandler } from 'webapp-common';
import { hasConceptRotation } from '../../../../util/conceptRotationUtil';
import { SigTesting } from '../common/sigTesting/SigTesting';
import { BannerControls } from '../common/bannerControls/BannerControls';
import { FiltersDropdown } from '../common/filtersDropdown/FiltersDropdown';
import { ParticipantDataBannerControls } from '../common/participantDataBannerControls/ParticipantDataBannerControls';
import { FilterSelectorModal } from '../common/filterSelector/FilterSelectorModal';

import './CrosstabReport.css';

// From CrosstabReportConfig.java
const FILTER_REF = 'FILTER_REF';
const PARTICIPANT_DATA = 'PARTICIPANT_DATA';

export const CrosstabReport = props => {
  const {
    statTypes,
    views,
    reportConfig,
    sessions,
    consolidatedReportData,
    isConsolidatedReport,
    videoCaptureEnabled,
    updateReportConfig,
    setSigTestingField,
    generateReport,
    hasReportsView
  } = props;

  const [filterSelectModal, setFilterSelectModal] = useState();

  const accessCheck = accessHandler.checkAccess(hasReportsView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  function setNonTerminated(nonTerminatedOnly) {
    updateReportConfig({ ...reportConfig, nonTerminatedOnly });
  }

  function updateViews(view) {
    const clone = cloneDeep(reportConfig);
    const index = clone.views.indexOf(view);
    if (index === -1) {
      clone.views.push(view);
    } else {
      clone.views.splice(index, 1);
    }
    updateReportConfig(clone);
  }

  function updateStatTypes(type) {
    const clone = cloneDeep(reportConfig);
    const index = clone.statTypes.indexOf(type);
    if (index === -1) {
      clone.statTypes.push(type);
    } else {
      clone.statTypes.splice(index, 1);
    }
    updateReportConfig(clone);
  }

  function addBanner(type, label) {
    const banners = [...reportConfig.banners];
    banners.push({
      filters: [],
      label,
      name: label,
      type
    });
    updateReportConfig({ ...reportConfig, banners });
  }

  function setBannerLabel(label, index) {
    const clone = cloneDeep(reportConfig);
    clone.banners[index].label = label;
    clone.banners[index].name = label;
    updateReportConfig(clone);
  }

  function removeBanner(index) {
    const clone = cloneDeep(reportConfig);
    clone.banners.splice(index, 1);
    updateReportConfig(clone);
  }

  function moveBanner(srcIndex, targetIndex) {
    const clone = cloneDeep(reportConfig);
    const [removed] = clone.banners.splice(srcIndex, 1);
    clone.banners.splice(targetIndex, 0, removed);
    updateReportConfig(clone);
  }

  function setParticipantDataBanner(label, filters, index) {
    const clone = cloneDeep(reportConfig);
    clone.banners[index].label = label;
    clone.banners[index].name = label;
    clone.banners[index].filters = filters;
    updateReportConfig(clone);
  }

  function setFilters(filters, index) {
    const clone = cloneDeep(reportConfig);
    clone.banners[index].filters = filters;
    updateReportConfig(clone);
  }

  function removeFilter(bannerIndex, filterIndex) {
    const clone = cloneDeep(reportConfig);
    clone.banners[bannerIndex].filters.splice(filterIndex, 1);
    updateReportConfig(clone);
  }

  function moveFilter(bannerIndex, srcIndex, targetIndex) {
    const clone = cloneDeep(reportConfig);
    const { filters } = clone.banners[bannerIndex];
    const [removed] = filters.splice(srcIndex, 1);
    filters.splice(targetIndex, 0, removed);
    updateReportConfig(clone);
  }

  function setBannerFilter(filter, bannerIndex) {
    const clone = cloneDeep(reportConfig);
    clone.banners[bannerIndex].filters = [filter];
    updateReportConfig(clone);
  }

  function showSelectFiltersModal(banner, bannerIndex) {
    setFilterSelectModal({ banner, bannerIndex });
  }

  function closeSelectFiltersModal() {
    setFilterSelectModal(null);
  }

  function onBeforeDragStart() {
    // Maintain container height while dragging
    const bannersElem = document.getElementById('banners');
    bannersElem.style.height = `${bannersElem.clientHeight}px`;
  }

  function onDragEnd(result) {
    // Reset container height
    const bannersElem = document.getElementById('banners');
    bannersElem.style.height = 'auto';
    if (result.destination) {
      moveBanner(result.source.index, result.destination.index);
    }
  }

  function isBanner(banner) {
    return banner.type === FILTER_REF && banner.label !== null;
  }

  function isParticipantDataBanner(banner) {
    return banner.type === PARTICIPANT_DATA;
  }

  function isFilterBanner(banner) {
    return banner.type === FILTER_REF && banner.label === null;
  }

  function getBanners() {
    return reportConfig.banners.map((banner, bannerIndex) => {
      const title =
        (isBanner(banner) && intl.get('app.banner')) ||
        (isParticipantDataBanner(banner) && intl.get('app.participantData')) ||
        (isFilterBanner(banner) && intl.get('app.filter'));
      return (
        <Draggable key={`banner-${bannerIndex}`} draggableId={`banner-${bannerIndex}`} index={bannerIndex}>
          {provided => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div>{title}:</div>
              <div>
                {isBanner(banner) && (
                  <BannerControls
                    banner={banner}
                    bannerIndex={bannerIndex}
                    setBannerLabel={setBannerLabel}
                    showSelectFiltersModal={showSelectFiltersModal}
                    removeFilter={removeFilter}
                    moveFilter={moveFilter}
                  />
                )}
                {isParticipantDataBanner(banner) && (
                  <ParticipantDataBannerControls
                    banner={banner}
                    bannerIndex={bannerIndex}
                    metadata={consolidatedReportData.metadata}
                    sessions={sessions}
                    setParticipantDataBanner={setParticipantDataBanner}
                    removeFilter={removeFilter}
                    moveFilter={moveFilter}
                  />
                )}
                {isFilterBanner(banner) && (
                  <FiltersDropdown
                    selectedFilter={banner.filters[0]}
                    sessions={sessions}
                    consolidatedReportData={consolidatedReportData}
                    videoCaptureEnabled={videoCaptureEnabled}
                    setFilter={filter => setBannerFilter(filter, bannerIndex)}
                  />
                )}
              </div>
              <div>
                <i className="fas fa-times" onClick={() => removeBanner(bannerIndex)} />
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  }

  const showComparativeView =
    consolidatedReportData.consolidatedQuestion && hasConceptRotation(consolidatedReportData.consolidatedQuestion);

  return (
    <section className="crosstab-report">
      <div className="config-area">
        <div className="config-column">
          <div>
            <div>{intl.get('app.output')}</div>
            {showComparativeView && !isConsolidatedReport && (
              <label>
                <Input
                  type="checkbox"
                  checked={reportConfig.views.includes(views.COMPARITIVEVIEW)}
                  onChange={() => updateViews(views.COMPARITIVEVIEW)}
                />
                {intl.get('app.comparativeViews')}
              </label>
            )}
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.views.includes(views.TABS)}
                onChange={() => updateViews(views.TABS)}
              />
              {intl.get('app.tabs')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.views.includes(views.VERBATIM)}
                onChange={() => updateViews(views.VERBATIM)}
              />
              {intl.get('app.verbatims')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.views.includes(views.SCREENER)}
                onChange={() => updateViews(views.SCREENER)}
              />
              {intl.get('app.participantData')}
            </label>
          </div>
          <div>
            <div>{intl.get('app.statistics')}</div>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.statTypes.includes(statTypes.MEAN)}
                onChange={() => updateStatTypes(statTypes.MEAN)}
              />
              {intl.get('app.statistics.mean')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.statTypes.includes(statTypes.STANDARD_DEVIATION)}
                onChange={() => updateStatTypes(statTypes.STANDARD_DEVIATION)}
              />
              {intl.get('app.statistics.standardDeviation')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.statTypes.includes(statTypes.STANDARD_ERROR)}
                onChange={() => updateStatTypes(statTypes.STANDARD_ERROR)}
              />
              {intl.get('app.statistics.standardError')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.statTypes.includes(statTypes.VARIANCE)}
                onChange={() => updateStatTypes(statTypes.VARIANCE)}
              />
              {intl.get('app.statistics.variance')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.statTypes.includes(statTypes.MEDIAN)}
                onChange={() => updateStatTypes(statTypes.MEDIAN)}
              />
              {intl.get('app.statistics.median')}
            </label>
            <label>
              <Input
                type="checkbox"
                checked={reportConfig.statTypes.includes(statTypes.SIG_TESTING)}
                onChange={() => updateStatTypes(statTypes.SIG_TESTING)}
              />
              {intl.get('app.statistics.significanceTesting')}
            </label>
            <SigTesting
              sigTestSetting={reportConfig.sigTestSetting}
              setSigTestingField={setSigTestingField}
              hidden={!reportConfig.statTypes.includes(statTypes.SIG_TESTING)}
            />
          </div>
          <div>
            <div>{intl.get('app.participants')}</div>
            <label>
              <Input type="radio" checked={reportConfig.nonTerminatedOnly} onChange={() => setNonTerminated(true)} />
              {intl.get('app.allNonTerminated')}
            </label>
            <label>
              <Input type="radio" checked={!reportConfig.nonTerminatedOnly} onChange={() => setNonTerminated(false)} />
              {intl.get('app.allIncludingTerminated')}
            </label>
          </div>
          <Button
            color="primary"
            style={{ marginLeft: 0 }}
            onClick={generateReport}
            disabled={reportConfig.views.length === 0 || reportConfig.sessionIds.length === 0}
          >
            {intl.get('app.createReport')}
          </Button>
        </div>

        <div className="config-right">
          <div className="title-row">
            <div>{intl.get('app.configure')}</div>
            <div className="footnote">Add data to report - drag components to modify order</div>
          </div>
          <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-banners">
              {provided => (
                <div id="banners" ref={provided.innerRef} {...provided.droppableProps}>
                  {getBanners()}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="buttons-row">
            <Button onClick={() => addBanner(FILTER_REF, '')}>+ {intl.get('app.banner')}</Button>
            <Button onClick={() => addBanner(PARTICIPANT_DATA, null)}>+ {intl.get('app.participantData')}</Button>
            <Button onClick={() => addBanner(FILTER_REF, null)}>+ {intl.get('app.filter')}</Button>
          </div>
        </div>
      </div>
      {filterSelectModal && (
        <FilterSelectorModal
          sessions={sessions}
          consolidatedReportData={consolidatedReportData}
          banner={filterSelectModal.banner}
          bannerIndex={filterSelectModal.bannerIndex}
          videoCaptureEnabled={videoCaptureEnabled}
          setFilters={setFilters}
          closeModal={closeSelectFiltersModal}
        />
      )}
    </section>
  );
};
