import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { cloneDeep, isEqual } from 'lodash';
import {
  MEDIA_TYPE,
  STIM_TYPE,
  getDefaultVideoOptions,
  getTextStimContents,
  getTextStimRichContents,
  getStimCaption,
  ENGLISH
} from '../../../../../util/joinerUtil';
import { idUtils } from '../../../../../util/Id';
import SelectMediaModal from '../../modals/selectMedia/SelectMediaModal';
import { InvokeModal } from 'webapp-common';
import MediaPreview from '../../../../../components/core/mediaPreview/MediaPreview';
import TimeSelector from '../../../../../components/core/timePicker/TimeSelector';
import { getCRJoinerById } from '../../../../../util/conceptRotationUtil';
import QuillEditor from '../../../../../components/quillEditor/QuillEditor';

import './AddOrEditConceptModal.css';

const EDIT = 'edit';
const ADD = 'add';

const MEDIA = 'media';
const TEXT = 'text';

const reducer = (state, payload) => ({ ...state, ...payload });

const AddOrEditConceptModal = props => {
  const { readOnly, language, viewLanguage } = props;

  const mode = props.concept ? EDIT : ADD;

  const [state, setState] = useReducer(reducer, {}, () => {
    const concept = props.concept || {
      id: idUtils.getId(),
      title: ''
    };
    if (!concept.startJoiner) {
      concept.startJoiner = {
        id: idUtils.getId(),
        conceptId: concept.id,
        conceptRotationId: props.conceptRotationId,
        displayIndex: 0,
        stim: {},
        stimOnly: true,
        timingOptions: {
          timingEnabled: true,
          defaultTime: 30,
          configuredTime: 30
        }
      };
    }
    return {
      concept,
      selectedStimType: concept.startJoiner.stim.type === STIM_TYPE.text ? TEXT : MEDIA,
      showSelectMediaModal: false
    };
  });

  const {
    stim,
    timingOptions: { configuredTime }
  } = state.concept.startJoiner;

  const media = (stim && stim.media) || {};

  const updateTitle = e => {
    setState({
      concept: {
        ...state.concept,
        title: e.target.value
      }
    });
  };

  const updateCaption = e => {
    const setCaption = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const clone = cloneDeep(state.concept);
    const { stim } = clone.startJoiner;
    stim.caption = setCaption ? e.target.value : stim.caption;
    stim.origCaption = setOrig ? e.target.value : stim.origCaption;
    setState({ concept: clone });
  };

  const selectStimType = e => {
    setState({
      selectedStimType: e.target.value
    });
  };

  const updateStimText = (contents, richContents) => {
    const setText = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const clone = cloneDeep(state.concept);
    const { stim } = clone.startJoiner;
    stim.contents = setText ? contents : stim.contents;
    stim.richContents = setText ? richContents : stim.richContents;
    stim.origContents = setOrig ? contents : stim.origContents;
    stim.origRichContents = setOrig ? richContents : stim.origRichContents;
    setState({ concept: clone });
  };

  const getStimOptions = () => {
    const { joiners, conceptRotationId } = props;
    const joiner = getCRJoinerById(joiners, conceptRotationId);
    return joiner ? joiner.stim.options : getDefaultVideoOptions();
  };

  const onMediaUpdate = media => {
    const clone = cloneDeep(state.concept);
    const { stim } = clone.startJoiner;
    if (media.type === MEDIA_TYPE.video && !stim.options) {
      stim.options = getStimOptions();
    }
    stim.media = media;
    clone.title = state.concept.title || media.title;
    setState({
      concept: clone,
      showSelectMediaModal: false
    });
  };

  const updateTimeOptions = configuredTime => {
    const clone = cloneDeep(state.concept);
    const { timingOptions } = clone.startJoiner;
    timingOptions.configuredTime = configuredTime ? configuredTime.minutes() * 60 + configuredTime.seconds() : 0;
    setState({ concept: clone });
  };

  const toggleSelectMediaModal = e => {
    setState({
      showSelectMediaModal: !state.showSelectMediaModal
    });
  };

  function onSave() {
    const concept = cloneDeep(state.concept);

    // Adjust the stim before saving
    const { stim } = concept.startJoiner;
    if (state.selectedStimType === TEXT) {
      delete stim.caption;
      delete stim.origCaption;
      delete stim.media;
      stim.type = STIM_TYPE.text;
    } else {
      delete stim.contents;
      delete stim.richContents;
      delete stim.origContents;
      delete stim.origRichContents;
      const stimType = (stim.media && stim.media.type) || MEDIA_TYPE.image;
      stim.type =
        (stimType === MEDIA_TYPE.image && STIM_TYPE.image) ||
        (stimType === MEDIA_TYPE.video && STIM_TYPE.video) ||
        (stimType === MEDIA_TYPE.webcontent && STIM_TYPE.webcontent);
    }

    concept.title = concept.title.trim();
    props.onConceptSave(concept);
  }

  function saveEnabled() {
    const propsConcept = props.concept || {};
    if (!state.concept.title) {
      return false;
    }
    if (!isEqual(propsConcept.title, state.concept.title)) {
      return true;
    }
    if (!isEqual(propsConcept.startJoiner, state.concept.startJoiner)) {
      return true;
    }
    return false;
  }

  const toggle = e => {
    if (e.target.tagName === 'LI' || e.target.parentElement.className === 'ant-time-picker-panel-addon') {
      return;
    }
    props.toggle(e);
  };

  return (
    <InvokeModal
      showModal
      toggle={toggle}
      className="add-or-edit-concept-modal"
      modalTitle={mode === EDIT ? intl.get('app.editConcept') : intl.get('app.addConcept')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      save={onSave}
      enableSave={saveEnabled()}
    >
      <div>
        <div>{intl.get('app.title')}</div>
        <div>
          <Input value={state.concept.title} onChange={updateTitle} />
        </div>
      </div>
      <div>
        <div>
          <Input type="select" className="stim-type-selector" value={state.selectedStimType} onChange={selectStimType}>
            <option value={MEDIA}>{intl.get('app.concept.selectMedia')}</option>
            <option value={TEXT}>{intl.get('app.concept.textOnly')}</option>
          </Input>
        </div>
        {state.selectedStimType === MEDIA && (
          <MediaPreview
            mediaType={media.type}
            media={media}
            text={intl.get('app.clickToChange')}
            onClick={toggleSelectMediaModal}
          />
        )}
        {state.selectedStimType === TEXT && (
          <QuillEditor
            id="cr-quill-container"
            richContents={getTextStimRichContents(stim, language, viewLanguage)}
            contents={getTextStimContents(stim, language, viewLanguage)}
            readOnly={readOnly}
            update={updateStimText}
            containerStyle="text-stim-quill"
            editorStyle="quill-text-field"
            extraContent={state.concept.title}
          />
        )}
      </div>
      {state.selectedStimType === MEDIA && (
        <div>
          <div>{intl.get('app.caption')}</div>
          <div className="caption-input">
            <Input value={getStimCaption(stim, language, viewLanguage)} onChange={updateCaption} />
          </div>
        </div>
      )}
      <div>
        <div>{intl.get('app.timing')}</div>
        <TimeSelector pickedTime={configuredTime} updateTime={updateTimeOptions} readOnly={readOnly} />
      </div>
      {state.showSelectMediaModal && <SelectMediaModal toggle={toggleSelectMediaModal} onMediaUpdate={onMediaUpdate} />}
    </InvokeModal>
  );
};

export default AddOrEditConceptModal;
