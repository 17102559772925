import React from 'react';
import intl from 'react-intl-universal';
import { find } from 'lodash';

import './SentimentSummaryKeywordsChart.css';

// Same function as in Keywords.js
function getSentimentsForKeyword(keywordSentiments, keyword) {
  const escaped = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regexp = RegExp(`^${escaped} \\(\\d+\\)$`);
  const keys = Object.keys(keywordSentiments);
  const key = find(keys, k => regexp.test(k));
  return keywordSentiments[key] || {};
}

function buildChart(keywords, keywordSentiments) {
  if (!keywords.length) {
    return null;
  }

  const maxFrequency = keywords.reduce((max, kw) => (kw.frequency > max ? kw.frequency : max), 0);

  const sorted = [...keywords].sort(
    (a, b) => (a.frequency < b.frequency && 1) || (a.frequency > b.frequency && -1) || 0
  );

  return sorted.slice(0, 8).map(kw => {
    const sentiments = getSentimentsForKeyword(keywordSentiments, kw.value);
    const { POSITIVE = 0, NEUTRAL = 0, NEGATIVE = 0 } = sentiments;
    const total = POSITIVE + NEUTRAL + NEGATIVE;
    const positivePercent = `${Math.round((POSITIVE * 100) / total) || 0}%`;
    const neutralPercent = `${Math.round((NEUTRAL * 100) / total) || 0}%`;
    const negativePercent = `${Math.round((NEGATIVE * 100) / total) || 0}%`;
    const title =
      `${intl.get('app.positive')}: ${positivePercent}, ` +
      `${intl.get('app.neutral')}: ${neutralPercent}, ` +
      `${intl.get('app.negative')}: ${negativePercent}`;

    return (
      <tr>
        <td>{`${kw.value} (${kw.frequency})`}</td>
        <td>
          <div
            className="keyword-sentiments-bar"
            title={title}
            style={{ width: `${(kw.frequency / maxFrequency) * 100}%` }}
          >
            <div className="sentiment-positive" style={{ width: positivePercent }} />
            <div className="sentiment-neutral" style={{ width: neutralPercent }} />
            <div className="sentiment-negative" style={{ width: negativePercent }} />
          </div>
        </td>
      </tr>
    );
  });
}

export const SentimentSummaryKeywordsChart = props => {
  const { keywords, keywordSentiments } = props;
  return <table className="sentiment-summary-keywords-table">{buildChart(keywords, keywordSentiments)}</table>;
};
