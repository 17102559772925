import React from 'react';
import { Loader } from 'webapp-common';

class Iframe extends React.Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
    this.state = {
      iframeLoaded: false
    };
  }

  componentDidMount() {
    this.iframe.current.onload = () => {
      this.setState({ iframeLoaded: true });
    };
  }

  render() {
    return (
      <div className="embed-responsive embed-responsive-16by9" style={this.props.style || {}}>
        {!this.state.iframeLoaded && <Loader spinner />}
        <iframe
          className="embed-responsive-item"
          src={this.props.src}
          ref={this.iframe}
          title="Iframe"
          id={this.props.id || ''}
        />
      </div>
    );
  }
}

export default Iframe;
