import React, { memo, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getRDSession } from '../../../store/redux/selectors/researchDashboardSelector';
import { useSessionUserSelector } from '../../../customHooks/reduxHelper';
import { sendWSMessage } from '../../../util/websocket';
import { DeployWidget } from './deploy/DeployWidget';
import { LoginStatusWidget } from './loginStatus/LoginStatusWidget';
import { TimeRemainingWidget } from './timeRemaining/TimeRemainingWidget';

import './RDStatusBar.css';

function skipUpdate(prevProps, nextProps) {
  return prevProps.sessionId === nextProps.sessionId;
}

export const RDStatusBar = memo(props => {
  const { sessionId } = props;

  const loginStateTimerId = useRef();

  const { hasProjectManage } = useSessionUserSelector().abilities;
  const session = useSelector(state => getRDSession(state, sessionId), shallowEqual);

  useEffect(() => {
    const { loginState = {} } = session || {};
    if (loginState.state) {
      stopLoginStateTimer();
      if (loginState.state === 'Pending') {
        loginStateTimerId.current = setTimeout(
          () => {
            sendWSMessage({
              action: 'getSession',
              sessionId
            });
          },
          loginState.timeToStartInSeconds * 1000 + 1000
        );
      }
    }
  }, [session && session.loginState]);

  function stopLoginStateTimer() {
    if (loginStateTimerId.current) {
      clearTimeout(loginStateTimerId.current);
    }
  }

  return (
    <div className="rd-status-bar">
      <DeployWidget sessionId={sessionId} session={session} canDeploy={hasProjectManage} />
      <LoginStatusWidget session={session} canManageLogins={hasProjectManage} />
      <TimeRemainingWidget sessionId={sessionId} />
    </div>
  );
}, skipUpdate);
