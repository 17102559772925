import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { get } from 'lodash';
import { getJoinerIcon, ENGLISH } from '../../../util/joinerUtil';
import { getThumbnail } from './RDQuestionListUtil';
import { RDQuestionNavigation } from './RDQuestionNavigation';
import { RDSelectedFiltersBar } from '../rdFilters/RDSelectedFiltersBar';
import { RDPositionFilters } from '../rdFilters/RDPositionFilters';

import './RDQuestionDetailsHeader.css';

const COLLAPSED = 'collapsed';
const EXPANDED = 'expanded';

function getJoinerPrompt(question, viewLanguage) {
  const prompt = viewLanguage === ENGLISH ? 'origPrompt' : 'prompt';
  return get(question, `joiner.def.question.${prompt}`) || '';
}

function getQuestionIcon(question) {
  const { joiner, media } = question;
  const thumbnail = getThumbnail({ mediaUrl: media, question, largerImageOnHover: 'right' });
  if (thumbnail) {
    return <div className="joiner-icon">{thumbnail}</div>;
  }
  return getJoinerIcon(joiner);
}

export const RDQuestionDetailsHeader = props => {
  const {
    sessionId,
    questions,
    selectedQuestion,
    viewMode,
    onSelectQuestion,
    toggleQuestionListViewMode,
    closeQuestionDetail,
    project,
    total,
    toggleEditFiltersModal,
    viewLanguage
  } = props;

  const promptRef = useRef();
  const [promptState, setPromptState] = useState('');

  useEffect(() => {
    const { clientHeight, scrollHeight } = promptRef.current;
    if (scrollHeight > clientHeight && promptState !== COLLAPSED) {
      setPromptState(COLLAPSED);
    } else if (scrollHeight === clientHeight && promptState === COLLAPSED) {
      setPromptState(EXPANDED);
    }
  }, [promptRef]);

  function togglePrompt() {
    if (promptState === COLLAPSED) {
      setPromptState(EXPANDED);
    } else if (promptState === EXPANDED) {
      setPromptState(COLLAPSED);
    }
  }

  function getPrompt() {
    const prompt = getJoinerPrompt(selectedQuestion, viewLanguage);
    const questionPromptClassname = promptState === EXPANDED ? 'expanded-prompt' : '';
    return (
      <div className={`question-prompt ${questionPromptClassname}`} title={prompt} ref={promptRef}>
        {prompt}
        {promptState && (
          <div className="clickable-span" onClick={togglePrompt}>
            {promptState === COLLAPSED ? intl.get('app.more') : intl.get('app.less')}
          </div>
        )}
      </div>
    );
  }

  const viewModeToggle = <i className="fas fa-arrows-alt-h" onClick={toggleQuestionListViewMode} />;
  const questionIcon = getQuestionIcon(selectedQuestion);
  const prompt = getPrompt();
  const closeIcon = <i className="far fa-times-circle" onClick={closeQuestionDetail} />;

  return (
    <section className="rd-question-details-header">
      {viewMode !== 'full' && (
        <div className="flex-row">
          {viewModeToggle}
          {questionIcon}
          {prompt}
          {closeIcon}
        </div>
      )}
      {viewMode === 'full' && (
        <>
          <div className="flex-row">
            {viewModeToggle}
            <RDQuestionNavigation
              questions={questions}
              selectedQuestion={selectedQuestion}
              onSelectQuestion={onSelectQuestion}
            />
            {closeIcon}
          </div>
          <div className="flex-row">
            {questionIcon}
            {prompt}
          </div>
        </>
      )}
      {
        <div className="rd-filters-bar">
          <RDSelectedFiltersBar
            sessionId={sessionId}
            selectedQuestion={selectedQuestion}
            project={project}
            total={total}
            toggleEditFiltersModal={toggleEditFiltersModal}
          />
          {selectedQuestion.concept && <RDPositionFilters sessionId={sessionId} selectedQuestion={selectedQuestion} />}
        </div>
      }
    </section>
  );
};
