import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { isEqual } from 'lodash';
import { Input } from 'reactstrap';
import { filterUtil } from '../../../../util/filterUtil';
import { InvokeModal } from 'webapp-common';
import { SurveyContentSelector } from './SurveyContentSelector';
import { ParticipantDataSelector } from './ParticipantDataSelector';
import { OtherFiltersSelector } from './OtherFiltersSelector';

import './SelectDataPointsModal.css';

const SURVEY_CONTENT_TAB = 'surveyContent';
const PARTICIPANT_DATA_TAB = 'participantData';
const OTHER_FILTERS_TAB = 'other';
const OR = 'or';
const AND = 'and';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

export const SelectDataPointsModal = props => {
  const {
    filter,
    enrolleesInfo = {}, // Can be enrolleesInfo or enrollmentInfo object
    project,
    questionJoiners,
    segmentCategories,
    rdConfig,
    selectedTab,
    selectedJoiner,
    mode,
    toggle,
    onSave,
    isQuota
  } = props;

  const isQuotaFilter = (filter && filter.type === 'QUOTA_FILTER') || isQuota;
  const { participantDataColumnConfig } = (rdConfig && rdConfig.configs) || {};

  const [state, setState] = useReducer(reducer, {
    selectedTab,
    selectedFilterTerms: [],
    origFilterTerms: [],
    operator: OR,
    origOperator: OR
  });

  useEffect(() => {
    if (filter && filter.expression) {
      const allTerms = filterUtil.getFilterExpression(filter.expression);
      const terms = allTerms.filter(term => term.type === 'term');
      const isAnd = allTerms.some(term => term.type === 'operator' && term.value === AND);
      const operator = isAnd ? AND : OR;
      setState({
        selectedFilterTerms: terms,
        origFilterTerms: terms,
        operator,
        origOperator: operator
      });
    }
  }, [filter]);

  function setOperator(e) {
    setState({ operator: e.target.value });
  }

  function addOrRemoveFilterTerm(term) {
    const terms = [...state.selectedFilterTerms];
    const index = terms.findIndex(t => isEqual(t, term));
    if (index !== -1) {
      terms.splice(index, 1);
    } else {
      terms.push(term);
    }
    setState({
      selectedFilterTerms: terms
    });
  }

  /*
   * Convert the selectedFilterTerms to a filter expression string
   */
  function getFilterExpression() {
    let expression = '';
    Object.values(state.selectedFilterTerms).forEach(term => {
      if (expression) {
        expression += state.operator === OR ? ` ${OR} ` : ` ${AND} `;
      }
      expression += filterUtil.makeCleanFilterExpression(term);
    });
    return expression;
  }

  function getFilterExpressionElem() {
    const expression = getFilterExpression();
    const filter = { expression };
    return filterUtil.renderFilterExpression(filter, questionJoiners, enrolleesInfo.keyTransformMap);
  }

  function saveFilter() {
    const expression = getFilterExpression();
    onSave(expression);
  }

  function selectTab(selectedTab) {
    setState({ selectedTab });
  }

  function isSaveEnabled() {
    if (state.selectedFilterTerms.length === 0) {
      return false;
    }
    if (state.operator !== state.origOperator && state.selectedFilterTerms.length > 1) {
      return true;
    }
    return !isEqual(state.selectedFilterTerms, state.origFilterTerms);
  }

  return (
    <InvokeModal
      showModal
      className="select-data-points-modal"
      modalTitle={intl.get('app.selectDataPoints.title')}
      toggle={toggle}
      cancelButtonText={intl.get('app.cancel')}
      primaryButtonText={intl.get('app.save')}
      enableSave={isSaveEnabled()}
      save={saveFilter}
    >
      <div style={{ display: 'flex' }}>
        <Input
          type="select"
          style={{ width: 'auto', marginRight: '1rem' }}
          value={state.operator}
          onChange={setOperator}
        >
          <option value={OR}>{intl.get('app.matchANY')}</option>
          <option value={AND}>{intl.get('app.matchALL')}</option>
        </Input>
        <div className="filter-expression text-truncate">{getFilterExpressionElem()}</div>
      </div>
      <div className="tabs">
        {!isQuotaFilter && (
          <div
            className={state.selectedTab === SURVEY_CONTENT_TAB ? 'selected-tab' : 'clickable'}
            onClick={() => selectTab(SURVEY_CONTENT_TAB)}
          >
            {intl.get('app.surveyContent')}
          </div>
        )}
        <div
          className={state.selectedTab === PARTICIPANT_DATA_TAB ? 'selected-tab' : 'clickable'}
          onClick={() => selectTab(PARTICIPANT_DATA_TAB)}
        >
          {mode === 'screener' ? intl.get('app.segments') : intl.get('app.participantData')}
        </div>
        {!isQuotaFilter && (
          <div
            className={state.selectedTab === OTHER_FILTERS_TAB ? 'selected-tab' : 'clickable'}
            onClick={() => selectTab(OTHER_FILTERS_TAB)}
          >
            {intl.get('app.other')}
          </div>
        )}
      </div>
      <div className="select-data-points-table">
        {state.selectedTab === SURVEY_CONTENT_TAB && (
          <SurveyContentSelector
            questionJoiners={questionJoiners}
            keywordWhiteList={project.keywordWhiteList}
            selectedFilterTerms={state.selectedFilterTerms}
            selectedJoiner={selectedJoiner}
            addOrRemoveFilterTerm={addOrRemoveFilterTerm}
          />
        )}
        {state.selectedTab === PARTICIPANT_DATA_TAB && (
          <ParticipantDataSelector
            enrolleeDataDef={enrolleesInfo.enrolleeDataDef}
            participantDataColumnConfig={participantDataColumnConfig}
            selectedFilterTerms={state.selectedFilterTerms}
            segmentCategories={segmentCategories}
            mode={mode}
            addOrRemoveFilterTerm={addOrRemoveFilterTerm}
          />
        )}
        {state.selectedTab === OTHER_FILTERS_TAB && (
          <OtherFiltersSelector
            selectedFilterTerms={state.selectedFilterTerms}
            session={props.session}
            mode={mode}
            addOrRemoveFilterTerm={addOrRemoveFilterTerm}
          />
        )}
      </div>
    </InvokeModal>
  );
};
