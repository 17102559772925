import { find } from 'lodash';

const windowState = {
  open: 'open',
  minimized: 'minimized',
  closed: 'closed'
};

// See ChatState.java
const chatState = {
  active: 'ACTIVE',
  ended: 'ENDED'
};

const chatIconStatus = {
  empty: 'empty',
  ongoing: 'ongoing',
  closed: 'closed',
  disabled: 'disabled'
};

const chatIconSortOrder = {
  [chatIconStatus.ongoing]: 0,
  [chatIconStatus.closed]: 1,
  [chatIconStatus.empty]: 3,
  [chatIconStatus.disabled]: 4
};

const isOwner = (userId, initiatorId) => {
  return userId === initiatorId;
};

const memberOf = (userId, chat) => {
  if (chat.researcherIds && chat.researcherIds.indexOf(userId) > -1) {
    return true;
  }
  if (chat.participantIds && chat.participantIds.indexOf(userId) > -1) {
    return true;
  }
  return false;
};

const isOpen = state => {
  return state === windowState.open;
};

const isMinimized = state => {
  return state === windowState.minimized;
};

const isClosed = state => {
  return state === windowState.closed;
};

const isActive = chat => {
  return chat.state === chatState.active;
};

const isEnded = chat => {
  return chat.state === chatState.ended;
};

const isActiveParticipant = enrollee => {
  return enrollee && enrollee.enrolleeStatus !== 'Terminated' && enrollee.percentSurveyComplete !== 100;
};

const isParticipantMessage = (message, chat) => {
  return chat.participantIds.some(p => p === message.ownerId);
};

const getChatIconStatus = (chatItem, enrollee) => {
  if (chatItem) {
    if (chatItem.state === chatState.ended || !isActiveParticipant(enrollee)) {
      return chatIconStatus.closed;
    }
    if (chatItem.state === chatState.active) {
      return chatIconStatus.ongoing;
    }
  }
  if (!isActiveParticipant(enrollee)) {
    return chatIconStatus.disabled;
  }
  return chatIconStatus.empty;
};

const getChatItem = payload => {
  return find(
    payload.chats,
    chat =>
      chat.participantIds.indexOf(payload.participantId) > -1 && chat.questionJoinerId === payload.questionJoinerId
  );
};

export default {
  windowState,
  chatState,
  isOwner,
  memberOf,
  isOpen,
  isMinimized,
  isClosed,
  isActive,
  isEnded,
  getChatItem,
  getChatIconStatus,
  isActiveParticipant,
  isParticipantMessage,
  chatIconStatus,
  chatIconSortOrder
};
