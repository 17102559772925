import { connect } from 'react-redux';
import { createSurveyTemplateActions } from '../../store/redux/actions/surveyActions';
import { sessionUserSelector } from '../../store/redux/selectors/sessionUserSelector';
import SurveyTemplateLibrary from './SurveyTemplateLibrary';

const mapDispatchToProps = dispatch => ({
  createSurveyTemplate: payload => dispatch(createSurveyTemplateActions.request(payload))
});

const mapStateToProps = state => {
  const { surveyTemplateCreated } = state.surveyTemplates;
  const { hasProjectManage } = sessionUserSelector(state).abilities;
  return { surveyTemplateCreated, hasProjectManage };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTemplateLibrary);
