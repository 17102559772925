import { takeLatest, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  fetchSurveyTemplatesActions,
  createSurveyTemplateActions,
  saveSurveyAsTemplateActions
} from '../actions/surveyActions';
import { fetchSurveyTemplates, createSurveyTemplate, saveSurveyAsTemplate } from '../../../api/surveysApi';

function* handleSurveyTemplateSaved({ payload }) {
  const { survey = {}, isScreener } = payload;
  const surveyName = survey.name || payload.name;
  const templateAlreadyExist = survey.templateAlreadyExist || payload.templateAlreadyExist;

  if (templateAlreadyExist) {
    toast.error({ text: intl.get('app.surveyTemplate.nameConflictError', { name: surveyName }) });
  } else {
    toast.success({
      text: intl.get(isScreener ? 'app.screenerTemplate.saved' : 'app.surveyTemplate.saved', { name: surveyName })
    });
  }
  yield put(fetchSurveyTemplatesActions.request({ isScreener }));
}

function* surveyTemplateListSaga() {
  const saga = createFetchSaga(fetchSurveyTemplatesActions, fetchSurveyTemplates);
  yield* saga();
}

function* createSurveyTemplateSaga() {
  const saga = createFetchSaga(createSurveyTemplateActions, createSurveyTemplate);
  yield* saga();
  yield takeLatest(createSurveyTemplateActions.succeeded, handleSurveyTemplateSaved);
}

function* saveSurveyAsTemplateSaga() {
  const saga = createFetchSaga(saveSurveyAsTemplateActions, saveSurveyAsTemplate);
  yield* saga();
  yield takeLatest(saveSurveyAsTemplateActions.succeeded, handleSurveyTemplateSaved);
}

export { surveyTemplateListSaga, createSurveyTemplateSaga, saveSurveyAsTemplateSaga };
