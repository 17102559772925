import React from 'react';
import intl from 'react-intl-universal';
import { getFormatedTime } from '../../../../../util/joinerUtil';
import TimeSelector from '../../../../../components/core/timePicker/TimeSelector';

import './Timing.css';

const EXCLUDE_LAST = true;

const Timing = props => {
  const isConcept = joiner => !!joiner.conceptRotation || !!joiner.conceptRotationId;

  const { getTotalTiming, updateHandlers, joiner, tabs, readOnly } = props;
  const {
    timingOptions: { defaultTime, configuredTime }
  } = joiner;
  const totalTimeBefore = getTotalTiming(joiner, EXCLUDE_LAST);
  const totalTimeAfter = getTotalTiming(joiner, !EXCLUDE_LAST);

  return (
    <div className={`${tabs.timing} joiner-editor-timing`}>
      <div className="flex-row">
        <div />
        <div className="footnote">mm:ss</div>
      </div>
      {!isConcept(joiner) && (
        <div className="flex-row">
          <div className="fw-600">{intl.get('app.joiner.timing.elapsed.before')}:</div>
          <div>{totalTimeBefore}</div>
        </div>
      )}
      <div className="flex-row">
        <div>{intl.get('app.joiner.timing.recommended')}:</div>
        <div>{getFormatedTime(defaultTime)}</div>
      </div>
      <div className="flex-row">
        <div>{intl.get('app.joiner.timing.override')}:</div>
        <TimeSelector
          pickedTime={configuredTime}
          updateTime={updateHandlers.updateConfiguredTime}
          readOnly={readOnly}
        />
      </div>
      {!isConcept(joiner) && (
        <div className="flex-row">
          <div className="fw-600">{intl.get('app.joiner.timing.elapsed.after')}:</div>
          <div>{totalTimeAfter}</div>
        </div>
      )}
    </div>
  );
};

export default Timing;
