import React from 'react';
import intl from 'react-intl-universal';
import { Label } from 'reactstrap';
import { FlexRow } from 'webapp-common';

import './ScreenerSummary.css';

function getPanelStatsTable(panelStats) {
  if (panelStats.length === 0) {
    return null;
  }

  let completedTotal = 0;
  let attemptedTotal = 0;
  const rows = [];

  panelStats.forEach(panel => {
    completedTotal += panel.completed;
    attemptedTotal += panel.attempted;
    rows.push(
      <tr style={{ lineHeight: 2 }}>
        <td>{panel.panelId}</td>
        <td>{panel.completed}</td>
        <td>{panel.attempted}</td>
        <td>{getPercentage(panel.completed, panel.attempted)}</td>
      </tr>
    );
  });

  rows.push(
    <tr className="totals-row">
      <td>{intl.get('app.total')}</td>
      <td className="completed-total">{completedTotal}</td>
      <td>{attemptedTotal}</td>
      <td>{getPercentage(completedTotal, attemptedTotal)}</td>
    </tr>
  );

  return (
    <table className="panel-stats-table">
      <thead>
        <tr>
          <th>{intl.get('app.panel')}</th>
          <th>{intl.get('app.completed.abbrev')}</th>
          <th>{intl.get('app.attempted.abbrev')}</th>
          <th>{intl.get('app.incidenceRate.abbrev')}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
function showPercent(num, denom) {
  return isFinite(num / denom) ? `${Math.round((num / denom) * 100)}%` : 'N/A';
}

function getPercentage(completed, attempted) {
  if (!completed || !attempted) {
    return '0%';
  }
  return showPercent(completed, attempted);
}

function convertoToObject(array, target, name) {
  if (array.length === 0) {
    return null;
  }
  const obj = {};
  array.forEach(e => {
    if (target) {
      obj[e.name] = e[target];
    } else {
      if (e) {
        const key = Object.keys(e)[0];
        obj[key] = e[key];
      }
    }
  });
  return name ? { [name]: obj } : obj;
}

function getQuotaSegmentCategoriesObject(segmentCategories) {
  const segments = segmentCategories.map(c => c.quotaEnabled && convertoToObject(c.segments, 'quota', c.name));
  return convertoToObject(segments);
}

function getScreenerSummary(total, qualified, segmentCategories) {
  return total.map(segment => {
    const { segmentCategoryName, segmentTotals } = segment;
    const quotaSegmentCategories = getQuotaSegmentCategoriesObject(segmentCategories);
    return (
      <div key={segmentCategoryName} className="mb-3">
        <div className="bolder text-ellipsis" title={segmentCategoryName}>
          {segmentCategoryName}
        </div>
        {segmentTotals.map(s => {
          return (
            <FlexRow justifyContent="space-between" className="row-content mx-2" key={s.segmentName}>
              <Label className="text-ellipsis" style={{ width: '50%' }} title={s.segmentName}>
                {s.segmentName}
              </Label>
              <div>
                <Label className="counts" title={intl.get('app.segment.qualifiedVsQuota')}>
                  {s.qualified}
                  {quotaSegmentCategories &&
                  quotaSegmentCategories[segmentCategoryName] &&
                  quotaSegmentCategories[segmentCategoryName][s.segmentName]
                    ? `/${quotaSegmentCategories[segmentCategoryName][s.segmentName]}`
                    : ''}
                </Label>
                <Label className="ms-4 counts" title={intl.get('app.segment.qualifiedVsTotalQualified')}>
                  {showPercent(s.qualified, qualified)}
                </Label>
              </div>
            </FlexRow>
          );
        })}
      </div>
    );
  });
}

export const ScreenerSummary = props => {
  const { screenerSummary, segmentCategories, showSubTotals } = props;
  const { qualifiedParticipants = 0, panelStats = [], segmentCategoryTotals = [] } = screenerSummary || {};

  return (
    <div className="col-sm-3 screener-summary">
      {getPanelStatsTable(panelStats)}
      {showSubTotals && segmentCategoryTotals.length > 0 && (
        <>
          <div className="mt-4">
            <Label className="bolder ps-2">{intl.get('app.subtotals')}: </Label>
          </div>
          <div className="subtotal">
            {getScreenerSummary(segmentCategoryTotals, qualifiedParticipants, segmentCategories)}
          </div>
        </>
      )}
    </div>
  );
};
