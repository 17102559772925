import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { rdConfigUtil } from '../../../../../util/rdConfigUtil';
import { saveRDConfig } from '../../../../../util/researchDashboardUtil';
import { toast } from '../../../../../util/toast';
import { SelectFiltersModal } from '../../../../../components/core/modal/SelectFiltersModal';
import { Icons } from '../../../../../components/icons/Icons';
import { FilterResponseCounts } from './FilterResponseCounts';

import './ChoiceAnswersTable.css';

const netColors = {
  top: 'var(--net-top-color)',
  middle: 'var(--net-middle-color)',
  bottom: 'var(--net-bottom-color)'
};

const barColors = ['#3A7CDC', '#BD10E0', '#F5A623', '#50E3C2', '#F8E71C'];

export const ChoiceAnswersTable = props => {
  const {
    questionJoiner,
    tableData,
    displayOption,
    netDetails,
    rdConfig,
    quotaFilters,
    selectedFilters,
    sortingConfig,
    selectedRowIndex,
    setSelectedRow,
    removeFilter
  } = props;

  const { filterListConfig } = rdConfig.configs;
  const filterAppliedToResponses = filterListConfig.selected;
  const { sortBy, sortOrder } = sortingConfig;

  const [showFilterSelectModal, setShowFilterSelectModal] = useState();
  const [createFilterInProgress, setCreateFilterInProgress] = useState();

  useEffect(() => {
    if (filterAppliedToResponses && selectedRowIndex >= 0) {
      setSelectedRow(-1);
    }
  }, [filterAppliedToResponses]);

  useEffect(() => {
    if (selectedRowIndex >= 0) {
      const selectedRow = tableData[selectedRowIndex];
      if (selectedRow.netsSummaryRow && netDetails[selectedRow.total.netType.toLowerCase()].length === 0) {
        setSelectedRow(-1);
      }
    }
  }, [netDetails]);

  useEffect(() => {
    if (createFilterInProgress) {
      setCreateFilterInProgress(false);
      toast.success({ text: intl.get('app.filterCreated') });
    }
  }, [rdConfig.configs.filterListConfig.filters.length]);

  function selectRow(rowIndex) {
    if (!filterAppliedToResponses) {
      if (selectedRowIndex === rowIndex) {
        setSelectedRow(-1);
      } else {
        setSelectedRow(rowIndex);
      }
    }
  }

  function setSelectedFilters(filters) {
    props.setSelectedFilters(filters);
    setShowFilterSelectModal(false);
  }

  function buildFilterExpression(questionNum, qDefId, choiceId) {
    return `Q${questionNum}."${qDefId}":A${questionNum}."${choiceId}";`;
  }

  function createFilter(choice) {
    const { displayText, netsSummaryRow, choiceIds } = choice;
    const { def, researchPrompt } = questionJoiner;

    // Replace the '.' in the questionNum with '-' for CR questions
    const questionNum = questionJoiner.questionNum.replace('.', '-');

    const filter = {
      name: `${researchPrompt} - ${displayText}`,
      type: 'GENERAL_FILTER'
    };

    if (rdConfig.configs.filterListConfig.filters.some(f => f.name === filter.name)) {
      toast.error({ text: intl.get('app.filterExists') });
      return;
    }

    if (netsSummaryRow) {
      filter.expression = choiceIds.map(cId => buildFilterExpression(questionNum, def.id, cId)).join(' or ');
    } else {
      filter.expression = buildFilterExpression(questionNum, def.id, choice.id);
    }

    const cloned = cloneDeep(rdConfig);
    cloned.configs.filterListConfig.filters.push(filter);
    saveRDConfig(cloned);
    setCreateFilterInProgress(true);
  }

  function getResponseRate(obj) {
    if (displayOption === rdConfigUtil.questionConfigDisplayOptions.COUNT_AND_RATE) {
      return `${obj.count} (${Math.round(obj.responseRate)}%)`;
    }
    if (displayOption === rdConfigUtil.questionConfigDisplayOptions.RESPONSE_RATE) {
      return `${Math.round(obj.responseRate)}%`;
    }
    return obj.count;
  }

  function getResponseRateCell(barColor, obj) {
    return (
      <td className="response-rate-cell">
        {obj && (
          <div className="response-rate-div">
            <div
              className="response-rate-bar"
              style={{ backgroundColor: barColor, width: `${Math.round(obj.responseRate)}px` }}
            />
            <div className="response-rate">{getResponseRate(obj)}</div>
          </div>
        )}
      </td>
    );
  }

  function getTableRows() {
    return tableData.map((row, i) => {
      const { choiceNum, displayText, entryType, src, total, filtered, netsSummaryRow } = row;
      const { netType, netCount } = total;
      const bulletColor = (!netsSummaryRow && netType && netColors[netType.toLowerCase()]) || 'transparent';
      const bulletTitle = !netsSummaryRow && netType && intl.get(`app.${netType.toLowerCase()}`) + ' ' + netCount;
      const barColor = netsSummaryRow ? netColors[netType.toLowerCase()] : barColors[0];
      return (
        <tr className={selectedRowIndex === i ? 'selected' : ''} key={row.id}>
          <td style={{ cursor: 'pointer' }} onClick={() => createFilter(row)}>
            +<i className="far fa-filter" />
          </td>
          <td style={{ cursor: filterAppliedToResponses ? '' : 'pointer' }} onClick={() => selectRow(i)}>
            <ul>
              <li style={{ color: bulletColor }} title={bulletTitle}>
                {entryType === 'imagelabel' ? (
                  <>
                    <span>{choiceNum}</span>
                    <img src={src} className="thumbnail" title={displayText} alt="" />
                  </>
                ) : (
                  <span title="">{netsSummaryRow ? displayText : `${choiceNum}. ${displayText}`}</span>
                )}
              </li>
            </ul>
          </td>
          {getResponseRateCell(barColor, total)}
          {!filterAppliedToResponses &&
            selectedFilters.map((filter, idx) => {
              const agg = filtered[filter.name];
              const { netType = '' } = agg || {};
              const barColor = netsSummaryRow ? netColors[netType.toLowerCase()] : barColors[idx + 1];
              return getResponseRateCell(barColor, agg);
            })}
        </tr>
      );
    });
  }

  function getColumnHeader(field, text, filter) {
    const newSortOrder =
      (field === sortBy && (sortOrder === 'asc' ? 'desc' : 'asc')) ||
      (field !== sortBy && field === 'index' && 'asc') ||
      'desc';
    return (
      <div className="column-header">
        <span className="name-and-sort-caret" onClick={() => props.sort(field, newSortOrder)}>
          <div className="column-name" title={text}>
            {text}
          </div>
          {sortBy === field && sortOrder === 'asc' && <Icons.CaretUpIcon />}
          {sortBy === field && sortOrder === 'desc' && <Icons.CaretDownIcon />}
        </span>
        {filter && !filterAppliedToResponses && (
          <i className="far fa-window-close" onClick={() => removeFilter(filter)} />
        )}
      </div>
    );
  }

  function getTotalColumnHeader() {
    const { filters, selected, selectedType } = filterListConfig;
    if (!filterAppliedToResponses) {
      return getColumnHeader('total', intl.get('app.total'));
    }
    const filter =
      selectedType === 'QUOTA_FILTER'
        ? quotaFilters.find(f => f.id === selected) || {}
        : filters.find(f => f.name === selected) || {};
    return getColumnHeader(filter.name, filter.name, filter);
  }

  return (
    <>
      <div className="choice-answers-table">
        <table>
          <thead>
            <tr>
              <th />
              <th style={{ paddingLeft: '1.25rem' }}>{getColumnHeader('index', intl.get('app.choices'))}</th>
              <th>{getTotalColumnHeader()}</th>
              {!filterAppliedToResponses &&
                selectedFilters.map(filter => (
                  <th key={filter.name}>{getColumnHeader(filter.name, filter.name, filter)}</th>
                ))}
            </tr>
          </thead>
          <tbody>{getTableRows()}</tbody>
        </table>
        {!filterAppliedToResponses && selectedFilters.length < 4 && (
          <div>
            <Button className="compare-link link-button" onClick={() => setShowFilterSelectModal(true)}>
              + {intl.get('app.compare')}
            </Button>
          </div>
        )}
        {showFilterSelectModal && (
          <SelectFiltersModal
            filters={filterListConfig.filters}
            quotaFilters={quotaFilters}
            selectedFilters={selectedFilters}
            readOnly={false}
            max={4}
            toggle={() => setShowFilterSelectModal(false)}
            onSave={setSelectedFilters}
          />
        )}
      </div>
      {selectedRowIndex >= 0 && (
        <FilterResponseCounts
          selectedRow={tableData[selectedRowIndex]}
          filters={filterListConfig.filters}
          quotaFilters={quotaFilters}
          getResponseRate={getResponseRate}
        />
      )}
    </>
  );
};
