import { put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  fetchSurveyActions,
  saveQuestionJoinerActions,
  surveyRefreshed,
  saveSurveyActions,
  deleteSurveyActions,
  deleteQuestionJoinersActions,
  generateSurveyContentReviewActions,
  generateSurveyRawExportActions,
  importSurveyAction,
  surveyImportSubscribeAction,
  newSurveyImportAction,
  uploadTranslationsAction
} from '../actions/surveyActions';
import { fetchAllSessionsAction } from '../actions/sessionActions';
import {
  fetchSurvey,
  saveQuestionJoiner,
  saveSurvey,
  deleteSurvey,
  deleteSurveyQuestions,
  generateSurveyContentReview,
  generateSurveyRawExport,
  importSurvey,
  uploadTranslations
} from '../../../api/surveysApi';
import { requestValidation } from './validationSaga';
import { fetchProjectActions } from '../actions/fetchProject';
import { entityType } from '../../../util/jsUtil';
import { subscribeToChannel } from '../../../util/websocket';

function* requestSessionValidation({ payload }) {
  if (payload.sessionId) {
    yield* requestValidation({ id: payload.sessionId, entity: entityType.session });
  }
}

function* requestProjectValidation({ payload }) {
  if (payload.projectId) {
    yield* requestValidation({ id: payload.projectId, entity: entityType.project });
  }
}

function* fetchSurveySaga() {
  const saga = createFetchSaga(fetchSurveyActions, fetchSurvey);
  yield* saga();
  yield takeLatest(fetchSurveyActions.succeeded, requestSessionValidation);
}

function* saveQuestionJoinerSaga() {
  const saga = createFetchSaga(saveQuestionJoinerActions, saveQuestionJoiner);
  yield* saga();
  yield takeLatest(saveQuestionJoinerActions.succeeded, requestSessionValidation);
  yield takeLatest(saveQuestionJoinerActions.succeeded, requestProjectValidation);
}

function* refreshSurveySaga() {
  yield takeLatest(surveyRefreshed, handleSurveyRefreshed);
}

function handleSurveyRefreshed() {
  toast.success({ text: intl.get('app.survey.reload') });
}

function* saveSurveySaga() {
  const saga = createFetchSaga(saveSurveyActions, saveSurvey);
  yield* saga();
  yield takeLatest(saveSurveyActions.succeeded, surveySaved);
  yield takeLatest(saveSurveyActions.succeeded, requestSessionValidation);
  yield takeLatest(saveSurveyActions.succeeded, requestProjectValidation);
}

function* refetchProject(payload) {
  yield put(
    fetchProjectActions.request({
      projectId: payload.projectId,
      includeSessionCount: payload.includeSessionCount
    })
  );
}

function* surveySaved({ payload }) {
  if (!payload.suppressToast) {
    toast.success({ text: intl.get('app.survey.saved', { name: payload.name }) });
  }
  yield refetchProject(payload);
}

function* surveyDeleted({ payload }) {
  const toastMessage = payload.isTemplate ? 'app.surveyTemplate.deleted' : 'app.survey.deleted';
  toast.success({ text: intl.get(toastMessage, { name: payload.name }) });
  if (!payload.isTemplate) {
    yield refetchProject(payload);
    if (payload.deleteSession) {
      yield put(
        fetchAllSessionsAction.request({
          projectId: payload.session.projectId,
          screener: payload.session.screener,
          includeResponseRates: true
        })
      );
    }
  }
}

function* deleteSurveySaga() {
  const saga = createFetchSaga(deleteSurveyActions, deleteSurvey);
  yield* saga();
  yield takeLatest(deleteSurveyActions.succeeded, surveyDeleted);
}

function* deleteSurveyQuestionSaga() {
  const saga = createFetchSaga(deleteQuestionJoinersActions, deleteSurveyQuestions);
  yield* saga();
}

function getEmail(state) {
  return state.sessionUser.user.email;
}

function* generateSurveyContentReviewSuccess({ payload }) {
  const referenceNumber = payload;
  const email = yield select(getEmail);
  toast.success({
    text: intl.get('app.surveyExport.generateSurveyContentReviewSuccess', { email, number: referenceNumber })
  });
}

function* generateSurveyContentReviewSaga() {
  const saga = createFetchSaga(generateSurveyContentReviewActions, generateSurveyContentReview);
  yield* saga();
  yield takeLatest(generateSurveyContentReviewActions.succeeded, generateSurveyContentReviewSuccess);
}

function* generateSurveyRawExportSuccess({ payload }) {
  const referenceNumber = payload;
  const email = yield select(getEmail);
  toast.success({
    text: intl.get('app.surveyExport.generateSurveyRawExportSuccess', { email, number: referenceNumber })
  });
}

function* generateSurveyRawExportSaga() {
  const saga = createFetchSaga(generateSurveyRawExportActions, generateSurveyRawExport);
  yield* saga();
  yield takeLatest(generateSurveyRawExportActions.succeeded, generateSurveyRawExportSuccess);
}

function* importSurveySaga() {
  const saga = createFetchSaga(importSurveyAction, importSurvey);
  yield* saga();
}

function* surveyImportChannelSubscribe({ payload: { subAction, sessionId } }) {
  yield* subscribeToChannel({
    action: 'surveyImport',
    subAction,
    key: sessionId,
    sessionId
  });
  yield takeLatest(newSurveyImportAction.succeeded, requestSessionValidation);
  yield takeLatest(newSurveyImportAction.succeeded, requestProjectValidation);
}

function* surveyImportChannelSubscribeSaga() {
  yield takeEvery(surveyImportSubscribeAction.request, surveyImportChannelSubscribe);
}

function handleUploadTranslations({ payload }) {
  toast.success({ text: intl.get('app.translations.uploaded', { name: payload.fileName }) });
}

function* uploadTranslationsSaga() {
  const saga = createFetchSaga(uploadTranslationsAction, uploadTranslations);
  yield* saga();
  yield takeLatest(uploadTranslationsAction.succeeded, handleUploadTranslations);
}

export {
  fetchSurveySaga,
  saveQuestionJoinerSaga,
  refreshSurveySaga,
  saveSurveySaga,
  deleteSurveySaga,
  deleteSurveyQuestionSaga,
  generateSurveyContentReviewSaga,
  generateSurveyRawExportSaga,
  importSurveySaga,
  surveyImportChannelSubscribeSaga,
  uploadTranslationsSaga
};
