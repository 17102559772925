import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { FlexRow, InvokeModal, InvokeTable, SearchInput } from 'webapp-common';
import localtime from '../../util/localtime';
import FunctionModal from './FunctionModal';
import EditFunction from './editFunction';

import './Functions.scss';

class Functions extends React.Component {
  static defaultProps = {
    functions: {
      content: []
    },
    functionsRequested: false
  };

  state = {
    showAddFunctionModal: false,
    showDeleteFunctionModal: false,
    selectedFunction: {},
    functionSearchName: '',
    showEditFunctionModal: false
  };

  componentDidMount() {
    this.props.fetchFunctionList({ pageSize: this.props.pageSize });
  }

  toggleAddFunctionModal = () => {
    this.setState(state => ({
      showAddFunctionModal: !state.showAddFunctionModal
    }));
  };

  toggleEditFunctionModal = () => {
    this.setState(state => ({
      showEditFunctionModal: !state.showEditFunctionModal
    }));
  };

  toggleDeleteFunctionModal = (toggleEditFunctionModal = true) => {
    this.setState(
      state => ({
        showDeleteFunctionModal: !state.showDeleteFunctionModal
      }),
      () => !this.state.showDeleteFunctionModal && toggleEditFunctionModal && this.toggleEditFunctionModal()
    );
  };

  formatLabel = info => {
    const onCellClick = fn => {
      this.props.loadFunction({ functionId: fn.id });
      this.setState({ selectedFunction: fn, showEditFunctionModal: true });
    };
    const fn = info.row.original;
    return (
      <span onClick={() => onCellClick(fn)} className="link" title={fn.name}>
        {fn.name}
      </span>
    );
  };

  formatDate = info => {
    return localtime.getFormattedDate(info.getValue());
  };

  sort = ({ sortBy, sortOrder }) => {
    const { pageRequest = {} } = this.props.functions;
    const { pageSize } = pageRequest;
    const { functionSearchName: searchName = '' } = this.state;
    this.props.fetchFunctionList({ pageNumber: 1, pageSize, sortBy, sortOrder, name: searchName });
  };

  paginate = ({ pageNumber, pageSize, sortBy, sortOrder }) => {
    const { functionSearchName = '' } = this.state;
    this.props.fetchFunctionList({ pageNumber, pageSize, sortBy, sortOrder, name: functionSearchName });
  };

  getColumns = () => {
    return [
      {
        accessorKey: 'name',
        header: intl.get('app.function.name'),
        headerStyle: { width: '7rem' },
        cell: this.formatLabel,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'modifiedDate',
        header: intl.get('app.lastModified'),
        headerStyle: { width: '5rem' },
        cell: this.formatDate
      }
    ];
  };

  handleSearchChange = e => {
    this.setState({ functionSearchName: e.target.value });
    const { pageRequest = {} } = this.props.functions;
    this.props.fetchFunctionList({
      pageNumber: 1,
      pageSize: pageRequest.pageSize,
      sortBy: pageRequest.sortBy,
      sortOrder: pageRequest.sortOrder,
      name: e.target.value
    });
  };

  upsertFunction = functionRule => {
    this.props.createFunction(functionRule);
  };

  deleteFunction = () => {
    this.props.deleteFunction({ functionId: this.state.selectedFunction.id });
    this.toggleDeleteFunctionModal(false);
  };

  render() {
    const { functions = {}, functionsRequested = false, abilities } = this.props;
    const { isInternalSuperUser } = abilities;
    const { showAddFunctionModal, showEditFunctionModal, selectedFunction, showDeleteFunctionModal } = this.state;
    const { pageRequest = {}, totalElements = 0 } = functions;
    const { pageNumber, pageSize } = pageRequest;

    const pagination = {
      pageNumber,
      pageSize,
      totalSize: totalElements
    };

    return (
      <div className="functions-list">
        <FlexRow justifyContent="space-between">
          {(isInternalSuperUser && (
            <Button color="primary" onClick={this.toggleAddFunctionModal}>
              {intl.get('app.addFunction')}
            </Button>
          )) || <div />}
          <SearchInput placeholder={intl.get('app.search')} disabled={false} onChange={this.handleSearchChange} />
        </FlexRow>
        <div className="mt-4">
          <InvokeTable
            className="invoke-table"
            columns={this.getColumns()}
            data={functions.content ?? []}
            initialState={{
              sorting: [
                {
                  id: 'modifiedDate',
                  desc: true
                }
              ]
            }}
            loading={functionsRequested}
            pagination={pagination}
            onPaginationChange={this.paginate}
            onSortingChange={this.sort}
          />
        </div>
        {showAddFunctionModal && (
          <FunctionModal
            className="add-function-modal"
            modalTitle={intl.get('app.addFunction')}
            save={functionRule => {
              this.upsertFunction(functionRule);
              this.toggleAddFunctionModal();
            }}
            isEditable={isInternalSuperUser}
            showModal={showAddFunctionModal}
            primaryButtonText={intl.get('app.save')}
            toggle={this.toggleAddFunctionModal}
            cancelButtonText={intl.get('app.cancel')}
          />
        )}
        {showEditFunctionModal && (
          <EditFunction
            className="add-function-modal"
            modalTitle={intl.get('app.editFunction')}
            primaryButtonText={intl.get('app.save')}
            cancelButtonText={intl.get('app.cancel')}
            deleteButtonText={intl.get('app.delete')}
            toggle={this.toggleEditFunctionModal}
            showModal={showEditFunctionModal}
            isEditable={isInternalSuperUser}
            save={updatedFunction => {
              this.upsertFunction(updatedFunction);
              this.toggleEditFunctionModal();
            }}
            deleteFunction={() => {
              this.toggleEditFunctionModal();
              this.toggleDeleteFunctionModal();
            }}
            selectedFunction={selectedFunction}
          />
        )}
        {showDeleteFunctionModal && (
          <InvokeModal
            toggle={this.toggleDeleteFunctionModal}
            showModal={showDeleteFunctionModal}
            modalTitle={intl.get('app.function.delete.title', { name: selectedFunction.name })}
            primaryButtonText={intl.get('app.delete')}
            save={this.deleteFunction}
            cancelButtonText={intl.get('app.cancel')}
            enableSave
          >
            <div>{intl.get('app.function.delete.confirmMessage', { name: selectedFunction.name })} </div>
          </InvokeModal>
        )}
      </div>
    );
  }
}
export default Functions;
