import { connect } from 'react-redux';
import {
  fetchAllLicensesActions,
  saveClientActions,
  updateTenantLicensesActions
} from '../../../store/redux/actions/clientAdminActions';
import { clientAdminSelector } from '../../../store/redux/selectors/clientAdminSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import ClientAdminFeaturesForm from './ClientAdminFeaturesForm';

const mapDispatchToProps = dispatch => ({
  fetchAllLicenses: () => dispatch(fetchAllLicensesActions.request()),
  saveClient: client => dispatch(saveClientActions.request(client)),
  updateTenantLicenses: payload => dispatch(updateTenantLicensesActions.request(payload))
});

const mapStateToProps = (state, props) => {
  const selector = clientAdminSelector()(state, props.clientId);
  return {
    licenses: selector.licenses,
    client: selector.client,
    saveClientInProgress: selector.saveClientInProgress,
    updateLicensesInProgress: selector.updateLicensesInProgress,
    abilities: sessionUserSelector(state).abilities
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAdminFeaturesForm);
