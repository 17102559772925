import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { useQuery } from 'react-query';
import { cloneDeep, isEqual } from 'lodash';
import { Loader } from 'webapp-common';
import { getConsolidatedProjectData, generateReport as generateReportApi } from '../../../../api/reportsApi';
import { ReportCommonFields } from '../common/commonFields/ReportCommonFields';
import { CrosstabReport } from '../crosstab/CrosstabReport';
import { ChartReport } from '../chart/ChartReport';
import { RawDataReport } from '../rawdata/RawDataReport';
import { ENGLISH } from '../../../../util/joinerUtil';
import { toast } from '../../../../util/toast';

import './ReportWrapper.css';

const reducer = (state, payload) => ({ ...state, ...payload });

const reportTypes = {
  CROSSTAB: 'CROSSTAB',
  CHART: 'CHART',
  RAWEXTRACT: 'RAWEXTRACT'
};

// From CrosstabStatistics.java
const statTypes = {
  MEAN: 'MEAN',
  STANDARD_DEVIATION: 'STANDARD_DEVIATION',
  STANDARD_ERROR: 'STANDARD_ERROR',
  VARIANCE: 'VARIANCE',
  MEDIAN: 'MEDIAN',
  SIG_TESTING: 'SIG_TESTING'
};

// From CrosstabView.java
const views = {
  COMPARITIVEVIEW: 'COMPARITIVEVIEW',
  TABS: 'TABS',
  VERBATIM: 'VERBATIM',
  SCREENER: 'SCREENER'
};

function getReportConfigName(reportType) {
  return (
    (reportType === reportTypes.CROSSTAB && 'consolidatedReportConfig') ||
    (reportType === reportTypes.CHART && 'consolidatedChartConfig') ||
    (reportType === reportTypes.RAWEXTRACT && 'consolidatedRawDataConfig')
  );
}

export const ReportWrapper = props => {
  const { projectId, project, reportType, sessions, user, fetchSessionsInProgress, rdConfigStore, hasReportsView } =
    props;

  const [state, setState] = useReducer(reducer, {
    reportConfig: {
      sessionIds: [],
      useOrigValue: true
    },
    language: ENGLISH,
    reportName: '',
    reportDescription: ''
  });

  const isConsolidatedReport = state.reportConfig.sessionIds.length > 1;

  // Fetch sessions for project
  useEffect(() => {
    if (projectId) {
      props.fetchSessions({
        projectId,
        screener: false,
        pageSize: -1
      });
      setReportLanguage(ENGLISH);
    }
  }, [projectId]);

  // We use the first session in the list as the runFromSession. When that changes, fetch the RD config.
  useEffect(() => {
    const runFromSession = getRunFromSession();
    if (runFromSession && user.userID) {
      props.fetchRDConfig({
        userId: user.userID,
        sessionId: runFromSession
      });
    }
  }, [getRunFromSession(), user.userID]);

  // When the rdConfig in the store changes, update the reportConfig in the local state.
  useEffect(() => {
    const rdConfig = getRDConfigFromStore();
    if (rdConfig) {
      const reportConfigName = getReportConfigName(reportType);
      const reportConfig = rdConfig.configs[reportConfigName];
      if (reportConfig.sessionIds.length === 0 && state.reportConfig.sessionIds.length !== 0) {
        reportConfig.sessionIds = state.reportConfig.sessionIds;
      }
      reportConfig.useOrigValue = state.language == ENGLISH;
      setState({ reportConfig });
    }
  }, [getRDConfigFromStore()]);

  // When sessionIds change, fetch consolidated data.
  const { isFetching: fetchConsolidatedDataInProgress, data: consolidatedReportData } = useQuery(
    state.reportConfig.sessionIds.join('-'),
    () => {
      if (state.reportConfig.sessionIds.length) {
        return getConsolidatedProjectData({
          projectId,
          reportType,
          sessionIds: state.reportConfig.sessionIds
        });
      }
    },
    {
      refetchOnWindowFocus: false
    }
  );

  // Save the report config whenever it changes
  useEffect(() => {
    const rdConfig = getRDConfigFromStore();
    const reportConfigName = getReportConfigName(reportType);
    if (rdConfig && state.reportConfig.type && !isEqual(rdConfig.configs[reportConfigName], state.reportConfig)) {
      const rdConfigForSave = prepareRDConfigForSave(rdConfig, reportConfigName);
      props.saveRDConfig(rdConfigForSave);
    }
  }, [state.reportConfig]);

  /*
   * Copy the local reportConfig into the rdConfig, and make other adjustments per report type.
   */
  function prepareRDConfigForSave(rdConfig, reportConfigName) {
    const rdConfigClone = cloneDeep(rdConfig);
    rdConfigClone.configs[reportConfigName] = { ...state.reportConfig };
    const sessionCountFromStore = rdConfig.configs[reportConfigName].sessionIds.length;
    const sessionCountFromLocal = state.reportConfig.sessionIds.length;
    if (sessionCountFromStore !== sessionCountFromLocal) {
      // Configured sessions have changed. Reset fields as necessary.
      if (reportType === reportTypes.CROSSTAB) {
        // Wipe out the banners
        rdConfigClone.configs[reportConfigName].banners = [];
        if (sessionCountFromLocal > 1) {
          const compViewIndex = rdConfigClone.configs[reportConfigName].views.indexOf(views.COMPARITIVEVIEW);
          if (compViewIndex !== -1) {
            // De-select comparative view for multiple sessions
            rdConfigClone.configs[reportConfigName].views.splice(compViewIndex, 1);
          }
        }
      } else if (reportType === reportTypes.RAWEXTRACT) {
        rdConfigClone.configs[reportConfigName].includeAllDataPoints = true;
        rdConfigClone.configs[reportConfigName].includeAllQuestions = true;
        rdConfigClone.configs[reportConfigName].selectedDataPoints = [];
        rdConfigClone.configs[reportConfigName].selectedQuestionJoinerIds = [];
      } else if (reportType === reportTypes.CHART) {
        rdConfigClone.configs[reportConfigName].includeAllQuestions = true;
        rdConfigClone.configs[reportConfigName].selectedQuestionJoinerIds = [];
        rdConfigClone.configs[reportConfigName].participantFilter = null;
      }
    }
    return rdConfigClone;
  }

  function getRunFromSession() {
    return state.reportConfig.sessionIds[0];
  }

  function getRDConfigFromStore() {
    const runFromSession = getRunFromSession();
    return rdConfigStore[runFromSession]?.rdConfig;
  }

  async function generateReport() {
    const response = await generateReportApi({
      reportConfig: {
        config: state.reportConfig,
        jobId: isConsolidatedReport ? consolidatedReportData.jobId : null,
        runFromSession: getRunFromSession()
      },
      reportName: state.reportName,
      reportDescription: state.reportDescription,
      reportType: isConsolidatedReport ? `CONSOLIDATED_${reportType}` : reportType,
      sessionList: state.reportConfig.sessionIds
    });
    toast.success({ text: intl.get('app.reportGenerationRequestedMsg', { email: user.email, refId: response.id }) });
  }

  function updateReportConfig(reportConfig) {
    setState({ reportConfig });
  }

  // Set a top-level field in the reportConfig
  function setField(field, value) {
    updateReportConfig({
      ...state.reportConfig,
      [field]: value
    });
  }

  function setSigTestingField(field, val) {
    const reportConfig = cloneDeep(state.reportConfig);
    reportConfig.sigTestSetting[field] = val;
    updateReportConfig(reportConfig);
  }

  function isVideoCaptureEnabled() {
    return state.reportConfig.sessionIds.some(
      sessionId => sessions.find(session => session.id === sessionId).videoCaptureConfig.enabled
    );
  }

  function setReportLanguage(language) {
    const reportConfig = cloneDeep(state.reportConfig);
    reportConfig.useOrigValue = language === ENGLISH;
    updateReportConfig(reportConfig);
    setState({ language });
  }

  const showLoader = fetchSessionsInProgress || fetchConsolidatedDataInProgress || rdConfigStore.rdConfigsApiRequested;

  return (
    <section className="report-wrapper">
      {showLoader && <Loader spinner fullScreen />}
      <ReportCommonFields
        project={project}
        sessions={sessions}
        reportType={reportType}
        state={state}
        setState={setState}
        setLanguageForReport={setReportLanguage}
      />
      {reportType === reportTypes.CROSSTAB && state.reportConfig.type && consolidatedReportData && (
        <CrosstabReport
          statTypes={statTypes}
          views={views}
          reportConfig={state.reportConfig}
          sessions={sessions}
          consolidatedReportData={consolidatedReportData}
          isConsolidatedReport={isConsolidatedReport}
          videoCaptureEnabled={isVideoCaptureEnabled()}
          updateReportConfig={updateReportConfig}
          setSigTestingField={setSigTestingField}
          generateReport={generateReport}
          hasReportsView={hasReportsView}
        />
      )}
      {reportType === reportTypes.CHART && state.reportConfig.type && consolidatedReportData && (
        <ChartReport
          reportConfig={state.reportConfig}
          sessions={sessions}
          consolidatedReportData={consolidatedReportData}
          isConsolidatedReport={isConsolidatedReport}
          videoCaptureEnabled={isVideoCaptureEnabled()}
          setField={setField}
          updateReportConfig={updateReportConfig}
          setSigTestingField={setSigTestingField}
          generateReport={generateReport}
          hasReportsView={hasReportsView}
        />
      )}
      {reportType === reportTypes.RAWEXTRACT && state.reportConfig.type && consolidatedReportData && (
        <RawDataReport
          reportConfig={state.reportConfig}
          consolidatedReportData={consolidatedReportData}
          setField={setField}
          updateReportConfig={updateReportConfig}
          generateReport={generateReport}
          hasReportsView={hasReportsView}
        />
      )}
    </section>
  );
};
