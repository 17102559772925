import { handleActions } from 'redux-actions';

import { fetchVideoResponsePathActions } from '../actions/videoCaptureActions';

const initialState = { videoResponsePaths: {} };

const videoCaptureReducer = handleActions(
  {
    [fetchVideoResponsePathActions.succeeded](state, { payload }) {
      return {
        ...state,
        videoResponsePaths: {
          ...state.videoResponsePaths,
          [payload.responseId]: payload.url
        }
      };
    }
  },
  initialState
);
export default videoCaptureReducer;
