export const locale = {
  'app.addALabel': "Add a label and hit 'Enter'",
  'app.archive': 'Archive',
  'app.archive.project.confirm': 'Are you sure you want to archive this project "{project}"?',
  'app.backToSurveys': 'Back to Surveys',
  'app.backToRecruit': 'Back to Recruit',
  'app.copyLink': 'Copy Link',
  'app.copy': 'Copy',
  'app.delete': 'Delete',
  'app.delete.survey': 'Delete Survey',
  'app.delete.screener': 'Delete Screener',
  'app.clone.survey': 'Clone Survey',
  'app.clone.screener': 'Clone Screener',
  'app.apply': 'Apply',
  'app.ok': 'OK',
  'app.total': 'Total',
  'app.show': 'Show',
  'app.yes': 'Yes',
  'app.no': 'No',
  'app.clients': 'Clients',
  'app.delete.project.confirm': 'Are you sure you want to delete this project: {project}?',
  'app.restore.project.confirm': 'Are you sure you want to restore project {project}?',
  'app.restore.project.error': 'This project archive cannot be restored, please contact Support',
  'app.delete.survey.confirm': 'Are you sure you want to delete survey "{survey}"?\n  This cannot be undone.',
  'app.enterEmailAddress': 'Enter email address',
  'app.footer': 'All Information ©MarketCast™ 2015 - {year}. US Patent No. 9,925,466',
  'app.footerAbout': 'About Us',
  'app.footerContact': 'Contact',
  'app.footerPriv': 'Privacy',
  'app.login.signin': 'Sign In',
  'app.login.passwordReset': 'Password Reset Successful',
  'app.login.passwordReset.message': 'Please log in with your new password',
  'app.loginFailed': 'Login Failed',
  'app.loginFailed.message': 'Try again or contact Support - support@invoke.com',
  'app.accountDisabled': 'Account Disabled',
  'app.accountDisabled.message': 'Contact Support - support@invoke.com',
  'app.logout': 'Logout',
  'app.searchSessions': 'Search Sessions',
  'app.loading': 'Loading ...',
  'app.requiredFieldFootnote': '* Indicates field is required',
  'app.upload.run.backgroud': 'Run in background',
  'app.media.upload.inProgress': 'Another upload is in progress. Retry once it is completed',
  'app.media.upload.inProgress.title': 'Add Media Info',
  'app.cancel.upload': 'Cancel upload',
  'app.file.uplaod.title': 'File upload in progress',
  'app.session': 'Session',
  'app.sessions': 'Sessions',
  'app.survey': 'Survey',
  'app.library': 'Library',
  'app.profile': 'Profile',
  'app.profileEdit': 'Edit Profile',
  'app.chatConversations': 'Chat Conversations',
  'app.participant': 'Participant',
  'app.participant.report': 'Post-Session Participant Report:',
  'app.unread': 'Unread',
  'app.lastMessage': 'Last Message',
  'app.participants': 'Participants',
  'app.enrollment': 'Enrollment',
  'app.participantEnrollment': 'Participant Enrollment',
  'app.selectEnrollment': 'Select Enrollment',
  'app.allParticipants': 'All participants',
  'app.onlyInactive': 'Only inactive',
  'app.numberOfParticipants': '{number} Participants',
  'app.responses': 'Responses',
  'app.active': 'Active',
  'app.removed': 'Removed',
  'app.accepted': 'Accepted',
  'app.invited': 'Invited',
  'app.projectTab.title': 'Project: {name}',
  'app.sessionTab.title': 'Session: {name}',
  'app.userTab.title': 'User: {name}',
  'app.participantListTab.title': 'Participant List: {name}',
  // Message WARN_MSG_SESSION_PRIVATE_NO_LIST_ENABLED from back-end Clark UI messages:
  'app.participantList.noListConfigured': 'Survey does not have a participant list configured',
  // Message WARN_MSG_SESSION_SCHEDULE_UNDEFINED from back-end Clark UI messages:
  'app.participantList.noScheduleConfigured': 'Survey schedule has not been configured',
  // Message WARN_MSG_SESSION_NO_SURVEY from back-end Clark UI messages:
  'app.participantList.noValidSurvey': 'Survey does not have a valid survey with questions',
  'app.screenerTab.title': 'Screener: {name}',
  'app.homePage.projectsTab.title': 'Projects',
  'app.homePage.reportsTab.title': 'Reports',
  'app.homePage.libraryTab.title': 'Library',
  'app.homePage.orgTab.title': 'Organization',
  'app.homePage.adminTab.title': 'Admin',
  'app.emailBlacklist': 'email Blacklist',
  'app.blacklist.email': 'Blacklist email',
  'app.email.blacklisted': '{email} successfully added to blacklist',
  'app.email.deleted': '{email} deleted from blacklist',
  'app.delete.blacklist': 'Are you sure you want to delete {email} from the blacklist?',
  'app.projectName': 'Project Name',
  'app.public': 'Public',
  'app.select': 'Select',
  'app.title': 'Title',
  'app.text': 'Text',
  'app.type': 'Type',
  'app.usage': 'Usage',
  'app.id': 'ID',
  'app.popularity': 'Popularity',
  'app.questionType': 'Question Type',
  'app.surveyType': 'Survey Type',
  'app.live': 'Live',
  'app.nicknames': 'Nicknames',
  'app.nickname': 'Nickname',
  'app.requireNicknames': 'Require Nicknames',
  'app.startSurveyAfterQualification': 'Start Survey After Qualification',
  'app.open': 'Open',
  'app.required': 'Required',
  'app.enabled': 'Enabled',
  'app.enable': 'Enable',
  'app.save': 'Save',
  'app.saveTemplate': 'Save Template',
  'app.saveSettings': 'Save Settings',
  'app.reset': 'Reset',
  'app.resetAll': 'Reset All',
  'app.resetStyles': 'Reset Styles',
  'app.resetStyles.message': 'Are you sure you want to reset your styles?',
  'app.error': 'Error',
  'app.cancel': 'Cancel',
  'app.close': 'Close',
  'app.previous': 'Previous',
  'app.next': 'Next',
  'app.any': 'Any',
  'app.none': 'None',
  'app.sentiment': 'Sentiment',
  'app.sentimentSummary': 'Sentiment Summary',
  'app.positive': 'Positive',
  'app.neutral': 'Neutral',
  'app.negative': 'Negative',
  'app.videoDialTesting': 'Video Dial Testing',
  'app.customReports': 'Custom Reports',
  'app.customReports.addCustomReportConfig': 'Add Custom Report Configuration',
  'app.customReports.configName': 'Configuration Name',
  'app.customReports.configName.placeholder': 'Configuration name',
  'app.backToConfigList': 'Back to configuration list',
  'app.output': 'Output',
  'app.statistics': 'Statistics',
  'app.tabs': 'Tabs',
  'app.verbatims': 'Verbatims',
  'app.statistics.mean': 'Mean',
  'app.statistics.standardDeviation': 'Standard Deviation',
  'app.statistics.standardError': 'Standard Error',
  'app.statistics.variance': 'Variance',
  'app.statistics.median': 'Median',
  'app.statistics.significanceTesting': 'Significance Testing',
  'app.sigTesting.primaryConfidence': 'Primary confidence',
  'app.sigTesting.secondaryConfidence': 'Secondary confidence',
  'app.sigTesting.minThreshold': 'Minimum threshold',
  'app.sigTesting.disableTestBelowMin': 'Do not calculate below min',
  'app.sigTesting.enableTestBelowMin': 'Calculate and mark below min',
  'app.allNonTerminated': 'All Non-Terminated',
  'app.allIncludingTerminated': 'All including Terminated',
  'app.allQuestions': 'All Questions',
  'app.selectedQuestions': 'Selected Questions',
  'app.outputType': 'Output Type',
  'app.outputType.excel': 'Excel Workbook (XLSX)',
  'app.outputType.powerPoint': 'PowerPoint Document (PPTX)',
  'app.outputType.spss': 'SPSS (SAV)',
  'app.outputType.both': 'Both',
  'app.quantQuestionFormattingOptions': 'Quantitative Question Formatting Options',
  'app.usePunchNumberForResponse': 'Use Punch Number for Response',
  'app.useScalarValueForResponse': 'Use Scalar Value for Response',
  'app.includeAnswerChoiceTextAsColumn': 'Include Answer Choice Text as Column',
  'app.valueWhenParticipant': 'Value when Participant:',
  'app.didNotAnswerTheQuestion': 'did not answer the question',
  'app.didNotSeeTheQuestion': 'did not see the question',
  'app.qualQuestionFormattingOptions': 'Qualitative Question Formatting Options',
  'app.oneQuestionResponsePerRow': '1 question response per row (narrow)',
  'app.oneRowPerParticipant': '1 row per Participant (wide)',
  'app.allParticipantData': 'All Participant Data',
  'app.selectedParticipantData': 'Selected Participant Data',
  'app.verbatimQuestions': 'Verbatim Questions',
  'app.consolidateQuestions': 'ConsolidateQuestions',
  'app.individualQuestions': 'Individual Questions',
  'app.responseClusters': 'Response Clusters',
  'app.filterSubtotals': 'Filter Subtotals',
  'app.individualResponses': 'Individual Responses',
  'app.includeComparativeViews': 'Include comparative views',
  'app.banner': 'Banner',
  'app.selectFilters': 'Select Filters',
  'app.selectFilterPlaceholder': 'Select a Filter ...',
  'app.selectDataPointPlaceholder': 'Select a Data Point ...',
  'app.labels': 'Labels',
  'app.leftLabels': 'Left Labels',
  'app.rightLabels': 'Right Labels',
  'app.private': 'Private',
  'app.reportName': 'Report Name',
  'app.reportType': 'Report Type',
  'app.sessionName': 'Session Name',
  'app.status': 'Status',
  'app.currentStatus': 'Current Status',
  'app.numInvited': '# Invited',
  'app.numResponses': '# Responses',
  'app.sessionType': 'Session Type',
  'app.liveSurvey': 'Live Survey',
  'app.nodata': 'No data',
  'app.noAnwer.message': 'There are no answers to this question yet',
  'app.generalSettings': 'General Settings',
  'app.advanced': 'Advanced',
  'app.advancedSettings': 'Advanced Settings',
  'app.features': 'Features',
  'app.customUi': 'Custom UI',
  'app.headerColor': 'Header background',
  'app.headerTextColor': 'Header text',
  'app.buttonColor': 'Primary button color',
  'app.secondaryButtonColor': 'Secondary button color',
  'app.buttonTextColor': 'Primary button text',
  'app.secondaryButtonTextColor': 'Secondary button text',
  'app.footerTextColor': 'Footer text',
  'app.footerBackground': 'Footer background',
  'app.fontFamily': 'Font family',
  'app.primary': 'Primary',
  'app.secondary': 'Secondary',
  'app.submit': 'Submit',
  'app.remove': 'Remove',
  'app.tos': 'Terms of Service',
  'app.settings': 'Settings',
  'app.recruit': 'Recruit',
  'app.project': 'Project',
  'app.cleanup': 'Clean-Up',
  'app.cleanup.survey': 'Clean-Up Survey',
  'app.cleanup.message':
    'Running Clean-Up will close all active surveys. This will immediately and permanently end the survey taking experience for any Participants who are still active, and no data will be received for unanswered Questions.',
  'app.cleanup.message2': 'Auto-terminate participants exposed to less than ',
  'app.cleanup.message3': ' % of survey content',
  'app.cleanup.success': 'Your request for survey clean up has been submitted successfully.',
  'app.terminateReason': 'Termination reason (optional)',
  'app.run.cleanup': 'Run Clean-Up',
  'app.newProject': 'New Project',
  'app.roles': 'Roles',
  'app.users': 'Users',
  'app.userAlreadyExists': 'User already exists.',
  'app.choices': 'Choices',
  'app.choices.otherOptions': '"Other" choice options',
  'app.choices.requireOther': 'Require input, if "Other" is selected',
  'app.choices.validateOther': 'Validate "Other" text input',
  'app.choices.rangeBetween': 'Range between',
  'app.choices.STRING': 'Any',
  'app.choices.ALPHAONLY': 'Alphabetic Only',
  'app.choices.ALPHANUMERIC': 'Alpha-numeric Only',
  'app.choices.NUMERIC': 'Whole Number',
  'app.choices.DOUBLE': 'Decimal',
  'app.choices.ZIPCODE': 'Zip Code',
  'app.choices.answerValueOutOfRange': 'Please provide a value between 0 and 100 for the answer range.',
  'app.addProject': 'Add Project',
  'app.createProject': 'Create Project',
  'app.projectType': 'Project Type',
  'app.dashboard': 'Dashboard',
  'app.dial': 'Dial',
  'app.dial.settings': 'Dial Dashboard Settings',
  'app.dialSetting.saveError': 'Dial setting saving error, please try again.',
  'app.showActions': 'Show Actions',
  'app.autoScaleY': 'Autoscale Y-axis',
  'app.trendlines': 'Trendlines',
  'app.overlayDial': 'Overlay dial chart on video',
  'app.zoomTime': 'Zoom X-axis to time window',
  'app.emotion': 'Emotion',
  'app.emotions': 'Emotions',
  'app.emotion.settings': 'Emotion Dashboard Settings',
  'app.overlayEmotions': 'Overlay emotion chart on video',
  'app.HAPPY': 'Happy',
  'app.SAD': 'Sad',
  'app.ANGRY': 'Angry',
  'app.CONFUSED': 'Confused',
  'app.DISGUSTED': 'Disgusted',
  'app.SURPRISED': 'Surprised',
  'app.FEAR': 'Fear',
  'app.CALM': 'Calm',
  'app.videoResponses': 'Video Responses',
  'app.projectTypes': 'Project Types',
  'app.fullService': 'Full-Service',
  'app.selfService': 'Self-Service',
  'app.enableFeatures': 'Enable Features',
  'app.noPanelConfigs': 'No Panel Configurations',
  'app.panelConfig.modal.title': 'Add/Edit Panel Configuration',
  'app.panel': 'Panel',
  'app.panels': 'Panels',
  'app.panelConfigurations': 'Panel Configurations',
  'app.panelRedirects': 'Panel Redirects',
  'app.panelId': 'Panel ID',
  'app.panelName': 'Panel Name',
  'app.panelIdentifier': 'Identifier (source)',
  'app.completedUrl': 'Completed URL',
  'app.terminateUrl': 'Terminate URL',
  'app.overQuotaUrl': 'Over Quota URL',
  'app.globalLinkParams': 'Global Link Parameters',
  'app.linkParams': 'Link Parameters',
  'app.linkParam': 'Link Parameter',
  'app.participantDataPoint': 'Participant Data Point',
  'app.stringValue': 'String Value',
  'app.panel.config.globalParams.label': 'Global Parameters',
  'app.panel.config.completedParams.label': 'Completed',
  'app.panel.config.terminateParams.label': 'Terminate',
  'app.panel.config.overQuotaParams.label': 'Over Quota',
  'app.panel.param.type.PARTICIPANT_DATA_POINT.small': 'participant data',
  'app.panel.param.type.STRING.small': 'string value',
  'app.paramName': 'Parameter Name',
  'app.addParams': 'Add Parameter',
  'app.deleteParams': 'Delete Parameters',
  'app.sha1': 'PureSpectrum SHA1',
  'app.md5': 'MD5',
  'app.addPanel': 'Add Panel',
  'app.savePanel': 'Save Panel',
  'app.panelSaved': 'Panel Saved',
  'app.deletePanel': 'Delete Panel',
  'app.removePanel': 'Remove Panel',
  'app.deletePanelConfirm': 'Are you sure you want to delete Panel "{name}"?',
  'app.panelDeleted': 'Panel Deleted',
  'app.reserved.panelId.warning': '"{panelId}" is a reserved Panel ID',
  'app.selectPanels': 'Select Panels',
  'app.samplifyProjectId': 'Samplify Project ID',
  'app.openInSamplify': 'Open in Samplify',
  'app.prodege': 'Prodege',
  'app.prodege.config.selectQuestions':
    'Select questions to make available for targeting criteria and participant data:',
  'app.prodege.country.1': 'USA',
  'app.country': 'Country',
  'app.updateProject': 'Update Project',
  'app.dynataProjectUpdated': 'Dynata Project Updated',
  'app.completed': 'Completed',
  'app.terminated': 'Terminated',
  'app.overQuota': 'Over Quota',
  'app.createDate': 'Create Date',
  'app.created': 'Created',
  'app.lastModified': 'Last Modified',
  'app.general': 'General',
  'app.showSummary': 'Show Summary',
  'app.keywords': 'Keywords',
  'app.namedEntities': 'Named Entities',
  'app.wordCloud': 'Word Cloud',
  'app.wordClouds': 'Word Clouds',
  'app.white': 'White',
  'app.list': 'List',
  'app.defaults': 'Defaults',
  'app.maximize': 'Maximize Window',
  'app.minimize': 'Minimize Window',
  'app.import': 'Import',
  'app.importUnavailable': 'Import feature is unavailable for editing questions in an Active session',
  'app.import.choiceQuestion': 'Import Choice Question',
  'app.import.openTextQuestion': 'Import Open Text Question',
  'app.import.rankedQuestion': 'Import Ranked Question',
  'app.import.matrixQuestion': 'Import Matrix Question',
  'app.importQuestion.note1': 'Paste or type into the text field below, matching the formatting',
  'app.importQuestion.note2': 'Question title, Question Text, Choices (every choice) must be on separate lines',
  'app.importQuestion.note3': 'For Matrix questions the row and column choices must be separated by a blank line',
  'app.importQuestion.titleError': 'Format Error: there is no title for this question',
  'app.importParticipants': 'Import Participants',
  'app.importList': 'Import List',
  'app.importParticipantData': 'Import Participant Data',
  'app.importFailed': 'Import Failed',
  'app.importInProgress': 'Import In Progress ...',
  'app.listName': 'List Name',
  'app.records': 'Records',
  'app.title.name': 'Name',
  'app.function.name': 'Function Name',
  'app.function.inputParameters': 'Input Parameters',
  'app.function.functionCode': 'Function Code',
  'app.function.delete.title': 'Delete Function {name}',
  'app.function.delete.confirmMessage':
    'Are you sure you want to delete function "{name}"? Existing survey rules that use this function will not be affected',
  'app.table.name': 'Table Name',
  'app.table.records': 'Records',
  'app.table': 'Table',
  'app.title.data': 'Data',
  'app.title.functions': 'Functions',
  'app.title.surveyTemplate': 'Survey Template: {name}',
  'app.title.screenerTemplate': 'Screener Template: {name}',
  'app.title.surveyTemplates': 'Survey Templates',
  'app.title.screenerTemplates': 'Screener Templates',
  'app.title.media': 'Media',
  'app.title.addMedia': 'Add Media',
  'app.title.editMedia': 'Edit Media',
  'app.title.deleteMedia': 'Delete Media',
  'app.cantChangeMediaType': "Can't change Media type from {type1} to {type2}",
  'app.title.deleteData': 'Delete Data',
  'app.confirm.deleteDataTable': 'Are you sure you want to delete this data table?',
  'app.title.selectMedia': 'Select Media',
  'app.title.questions': 'Questions',
  'app.title.chooseQuestion': 'Choose Question',
  'app.title.icon': 'Icon',
  'app.title.updated': 'Updated',
  'app.title.download': 'Download',
  'app.download': 'Download',
  'app.title.custom': 'Custom',
  'app.title.mediaChoice': 'Media Choice',
  'app.schedule': 'Schedule',
  'app.sessionList.addSession': 'Add Session',
  'app.create.session': 'Create New Session',
  'app.create.survey': 'Create New Survey',
  'app.select.surveyTemplate': 'Select Survey Template',
  'app.session.saved': '{name} saved!',
  'app.session.cloned': 'Session cloned!',
  'app.session.name': 'Session Name',
  'app.survey.name': 'Survey Name',
  'app.screener.name': 'Screener Name',
  'app.newSurvey': 'New Survey',
  'app.newSurveyNotSaved': 'New Survey - NOT SAVED',
  'app.newScreener': 'New Screener',
  'app.newScreenerNotSaved': 'New Screener - NOT SAVED',
  'app.importSurvey': 'Import Survey',
  'app.chooseTemplate': 'Choose Template',
  'app.session.publicOrPrivate': 'Public/Private',
  'app.session.state.OPEN': 'Active',
  'app.session.state.TEST': 'Test',
  'app.session.state.PENDING': 'Pending',
  'app.session.state.PAUSED': 'Paused',
  'app.session.state.CLOSED': 'Closed',
  'app.select.session.type': 'Select Session Type',
  'app.start': 'Start',
  'app.end': 'End',
  'app.email': 'Email',
  'app.selectStartDate': 'Select Start Date',
  'app.selectEndDate': 'Select End Date',
  'app.unset': 'Unset',
  'app.all': 'All',
  'app.all.types': 'All Types',
  'app.random': 'Random',
  'app.selected': 'Selected',
  'app.filterField': 'Filter field',
  'app.project.saved': '{name} saved!',
  'app.pagination.total': 'Showing { from } to { to } of { size } Results',
  'app.quota': 'Quota',
  'app.expectedIR': 'Expected IR',
  'app.maximumParticipants': 'maximum participants',
  'app.autoEnroll': 'Auto-Enroll',
  'app.autoEnrollWithMultipleCollectionsError': 'An Auto-Enroll Session can have only one Participant Collection',
  'app.participantLogin': 'Participant Login',
  'app.whenSessionEnds': 'when session ends',
  'app.whenSessionStarts': 'when session starts',
  'app.or': 'or',
  'app.allowedDevices': 'Allowed Devices',
  'app.desktopSlashLaptop': 'Desktop/Laptop',
  'app.tablet': 'Tablet',
  'app.mobilePhone': 'Mobile Phone',
  'app.disabled': 'Disabled',
  'app.disable': 'Disable',
  'app.minutesAfterSessionEnds': 'minutes after session ends',
  'app.minutesBeforeSessionEnds': 'minutes before session ends',
  'app.minutesBeforeSessionStarts': 'minutes before session starts',
  'app.minutesAfterSessionStarts': 'minutes after session starts',
  'app.takeSurveyLink': 'Survey Link',
  'app.publicSurveyUrl': 'Public Survey URL',
  'app.url': 'URL',
  'app.documentation': 'Documentation',
  'app.contact.support': 'Contact Support',
  'app.session.participantlist.select': 'Select Lists:',
  'app.editor': 'Editor',
  'app.media': 'Media',
  'app.options': 'Options',
  'app.variables': 'Variables',
  'app.variables.message':
    'Define variables that can be used in survey questions. Variable values can be set or changed using question rules.',
  'app.export': 'Export',
  'app.exportWithEmail': 'Export with Email',
  'app.title.addToSurvey': 'Add To Survey',
  'app.moveUp': 'Move Up',
  'app.moveDown': 'Move Down',
  'app.saveAndPreview': 'Save and Preview',
  'app.insertHere': 'Insert Here',
  'app.changeSurvey': 'Change Survey',
  'app.selectSurvey': 'Select Survey',
  'app.previewCustomUI': 'Preview Custom UI',
  'app.survey.reload': 'Survey content has been reloaded',
  'app.noSurveySelected': 'No Survey Selected',
  'app.createNewSurvey': 'Create new survey with name',
  'app.useSurveyTemplate': 'Use Survey Template',
  'app.surveyName': 'Survey name',
  'app.survey.saved': '{name} saved!',
  'app.surveyTemplate.saved': '{name} saved to the Template Library!',
  'app.screenerTemplate.saved': '{name} saved to the Screener Template Library!',
  'app.title.deleteQuestion': 'Delete Question',
  'app.question.delete.confirm': 'Are you sure you want to delete this Question?',
  'app.title.deletePage': 'Delete Page',
  'app.page.delete.confirm': 'Are you sure you want to delete this Page?',
  'app.media.delete.confirm': 'Are you sure you want to delete this Media?',
  'app.media.is.attached.to.msg': 'This media is attached to {usageCount} survey questions',
  'app.media.delete.only': 'Delete from Media Library only',
  'app.media.delete.full': 'Delete from Media Library and all references in existing survey questions and concepts',
  'app.media.continue': 'Continue from previous',
  'app.media.restart': 'Restart from beginning',
  'app.media.above': 'Media above the question',
  'app.media.left': 'Media to the left of the question',
  'app.media.invalidFileTypeError': 'Only Image and Video files can be uploaded as Media',
  'app.media.stim.overrideTiming': 'Override question timing with video duration',
  'app.media.showVideoControls': 'Show Video Controls',
  'app.media.playPause': 'Play/Pause',
  'app.media.soundVolume': 'Sound/Volume',
  'app.media.closedCaptions': 'Closed Captions',
  'app.media.fullScreen': 'Fullscreen',
  'app.media.enableDial': 'Enable Dial',
  'app.detectRecordingDevices': 'Detect recording devices',
  'app.detectMultipleViewers': 'Detect multiple viewers',
  'app.detectEmotions': 'Detect emotions',
  'app.onDetection': 'On detection',
  'app.onDetection.terminate': 'Terminate',
  'app.onDetection.pauseVideo': 'Pause Video',
  'app.Rotate': 'Rotate through',
  'app.positions': 'positions',
  'app.position': 'Position',
  'app.addSurvey': 'Add Survey',
  'app.addSurveyTemplate': 'Add Survey Template',
  'app.addScreenerTemplate': 'Add Screener Template',
  'app.addTemplate': 'Add Template',
  'app.surveyTemplateName': 'Survey Template Name',
  'app.screenerTemplateName': 'Screener Template Name',
  'app.backToTemplates': 'Back to Templates',
  'app.search': 'Search',
  'app.searchText': 'Search Text',
  'app.searchTitle': 'Search Title',
  'app.searchParticipants': 'Search Participants',
  'app.crosstab': 'Crosstab',
  'app.chart': 'Chart',
  'app.translations': 'Translations',
  'app.translations.template': 'Template',
  'app.translations.templateGenerate': 'Generate a template to use for response translation upload.',
  'app.translations.templateFormat': 'Template Format:',
  'app.translations.excel': 'Excel Workbook (.xlsx)',
  'app.translations.csv': 'Comma-separated values (.csv)',
  'app.translations.templateDownload': 'Download Template',
  'app.translations.upload': 'Upload Translations',
  'app.translations.uploaded': 'Translations File "{name}" Uploaded',
  'app.translations.instruct':
    'Upload translated responses. Any previously uploaded translations will be overwritten. Data file must be formatted properly, in either .csv or .xlsx format.',
  'app.translations.dataFile': 'Translated Data File:',
  'app.translations.delete': 'Delete Translations',
  'app.reportType.chart': 'Chart',
  'app.reportType.crosstab': 'Crosstab',
  'app.reportType.rawExtract': 'Raw Extract',
  'app.reportType.consolidatedChart': 'Consolidated Chart',
  'app.reportType.consolidatedCrosstab': 'Consolidated Crosstab',
  'app.reportType.consolidatedRawExtract': 'Consolidated Raw Extract',
  'app.reportType.translationUpload': 'Translation Upload',
  'app.reportType.dialRawData': 'Dial Raw Data',
  'app.reportType.dialPresentation': 'Dial Presentation',
  'app.reportType.emotionRawData': 'Emotion Raw Data',
  'app.reportType.emotionPresentation': 'Emotion Presentation',
  'app.questionList.preview': 'Preview Question',
  'app.questionType.multi': 'Choice',
  'app.questionType.matrix': 'Matrix',
  'app.questionType.open': 'Open Text',
  'app.questionType.text': 'Text Stim',
  'app.questionType.image': 'Image Stim',
  'app.questionType.video': 'Video Stim',
  'app.questionType.ranked': 'Ranked',
  'app.questionType.standAloneText': 'Stand-alone Text',
  'app.questionType.conceptRotation': 'Concept Rotation',
  'app.questionType.concept': 'Concept',
  'app.questionType.thirdparty': 'Third Party',
  'app.questionType.dialsmith': 'Dialsmith',
  'app.questionType.dialSmithVideo': 'Dialsmith Video',
  'app.questionType.webcontent': 'Web Content',
  'app.questionType.page': 'Page',
  'app.questionType.virtualFocusGroup': 'Vis-a-Vis',
  'app.stim': 'Stim',
  'app.firstPosSelectorText': 'Participants saw Concept in First Position Read:',
  'app.firstPosFilterTitle': 'First Position',
  'app.allQuantQuestions': 'All quantitative questions (Choice, Matrix, Ranked)',
  'app.allQualQuestions': 'All qualitative questions (Open Text)',
  'app.session.participantList.note':
    'Note: Changes to participant enrollments are not reflected immediately, it may take a few minutes for changes to appear.',
  'app.session.participantList.show.inactive': 'Only show inactive participants',
  'app.downloadCSV': 'Download CSV',
  'app.organization': 'Organization: {name}',
  'app.button.update': 'Update',
  'app.change.password': 'Change password',
  'app.first.name': 'First name',
  'app.last.name': 'Last name',
  'app.welcome': 'Welcome, {firstName}!',
  'app.profile.saved': 'First/last name of user: "{name}" saved!',
  'app.change.password.succeeded': 'Password change request succeeded: \n {message}',
  'app.change.password.failed': 'Password change request failed, please try again.\n {error}',
  'app.stim.image': 'Image',
  'app.stim.video': 'Video',
  'app.noCaptionProvided': 'No caption provided',
  'app.stim.webcontent': 'Web Content',
  'app.stim.options.dialConfig.like': 'Like',
  'app.stim.options.dialConfig.dislike': 'Dislike',
  'app.name': 'Name',
  'app.jobId': 'Job Id',
  'app.defaultValue': 'Default Value',
  'app.domain': 'Domain',
  'app.whiteList.domains': 'White-listed domains (comma separated):',
  'app.customLogo': 'Custom Logo',
  'app.uploadImage': 'Upload Image',
  'app.addDataTable': 'Add Data Table',
  'app.addFunction': 'Add Function',
  'app.editFunction': 'Edit Function',
  'app.uploadData': 'Upload Data',
  'app.dataTableName': 'Data table name:',
  'app.uploadFile': 'Upload File',
  'app.selectFile': 'Select File',
  'app.uploadFile.fileType': 'Only "{name}" filetypes are allowed',
  'app.orSpecifyURL': 'or specify URL',
  'app.useDefaultLogo': 'Use Default Logo',
  'app.projects.notAvailable': 'No Projects Available',
  'app.Projects': 'Projects',
  'app.noProjects': 'No Projects',
  'app.ProjectsSurveys': 'Projects & Surveys',
  'app.recentProjects': 'Recent Projects',
  'app.allProjects': 'All Projects',
  'app.Sessions': 'Sessions',
  'app.reports': 'Reports',
  'app.Organization': 'Organization',
  'app.ScreenerSurveys': 'Screener Surveys',
  'app.security': 'Security',
  'app.securityLevel.low': 'Low',
  'app.securityLevel.standard': 'Standard',
  'app.securityLevel.elevated': 'Elevated',
  'app.securityLevel.high': 'High',
  'app.video.ingest': 'Video Ingest',
  'app.ingest.NA': 'N/A',
  'app.ingest.ERROR': 'Error',
  'app.ingest.IN_PROGRESS': 'In Progress',
  'app.ingest.COMPLETED': 'Completed',
  'app.ingest.error.message': 'Video ingest status cannot be found, please try again later',
  'app.preview': 'Preview',
  'app.enableMFA': 'Enable Multi-Factor Authentication',
  'app.maxPasswordAge': 'Max Password Age',
  'app.userInterface': 'User Interface',
  'app.readOnly': 'Read Only',
  'app.fullAccess': 'Full Access',
  'app.days': 'Days',
  'app.months': 'Months',
  'app.years': 'Years',
  'app.logo': 'Logo',
  'app.addAnotherColumn': 'Add Another Column',
  'app.addAnotherChoice': 'Add Another Choice',
  'app.footer.priv': 'Privacy',
  'app.footer.about': 'About Us',
  'app.footer.contact': 'Contact',
  'app.access.denied': 'Access Denied',
  'app.access.denied.message': 'You do not have permission to view this page',
  'app.client.created': '{name} created!',
  'app.client.saved': '{name} saved!',
  'app.confirm.archive': 'Confirm Client Archive',
  'app.archiveClient.message': 'Are you sure you want to archive client "{client}"',
  'app.action.notUndone': 'This action cannot be undone',
  'app.client.archived': 'Client "{name}" archived',
  'app.uploadLogo.uploadLogo': 'Upload Logo',
  'app.uploadLogo.instructions': 'Instructions:',
  'app.uploadLogo.step1': 'Select an image file that is no larger than 300px wide by 120px tall.',
  'app.uploadLogo.step2': 'Use PNG, JPEG or GIF.',
  'app.uploadLogo.step3': 'The system will auto-size the image to fit.',
  'app.uploadLogo.step4': 'There is a preview before Publish.',
  'app.uploadLogo.selectImage': 'Select Image',
  'app.uploadLogo.updateImage': 'Update Image',
  'app.add.role': 'Add Role',
  'app.feature.count': 'Feature Count',
  'app.user.count': 'User Count',
  'app.noParticipantData': 'No participant data',
  'app.noParticipantLists': 'No participant lists',
  'app.project.recruit': 'Project Recruit',
  'app.participantList.imported': 'Participant list "{name}" imported',
  'app.participantList.replaced': 'Participant list "{name}" replaced',
  'app.participantList.deleted': 'Participant list "{name}" deleted',
  'app.addUser': 'Add User',
  'app.addEditUser': 'Add/Edit User',
  'app.addRole': 'Add Role',
  'app.editRole': '<div class="text-truncate" title="{name}">Edit Role: {name}</div>',
  'app.tab.entityName.custom.report.config': 'Add Configuration',
  'app.roles.added': 'Role {name} added!',
  'app.showInactiveUsers': 'Show inactive users',
  'app.login': 'Login',
  'app.lastLogin': 'Last Login',
  'app.lastAnswered': 'Last Answered',
  'app.lastSubmit': 'Last Submit',
  'app.userValidation': 'User Validation',
  'app.addClient': 'Add Client',
  'app.editClient': 'Edit Client "{clientDomain}"',
  'app.role.title': 'Role: {name}',
  'app.role.name': 'Role Name',
  'app.description': 'Description',
  'app.abilities': 'Abilities',
  'app.research.dashboard': 'Research Dashboard',
  'app.surveys': 'Surveys',
  'app.survey(s)': 'Survey(s)',
  'app.language(s)': 'Language(s)',
  'app.roles.updated': 'The "{name}" role saved successfully',
  'app.roles.deleted': 'The "{name}" role is deleted',
  'app.delete.role.title': 'Delete role "{name}"',
  'app.deleteRole': 'Delete Role',
  'app.role.delete.confirmation': 'Are you sure you want to delete this Role: {role}?',
  'app.report.delete.confirmation': 'Are you sure you want to delete this Report: {name}?',
  'app.title.organization': 'Organization: {name}',
  'app.dataTable.created': 'DataTable {name} created',
  'app.dataTable.replaced': 'Data Replaced for DataTable {name}',
  'app.function.saved': 'Function {name} saved',
  'app.function.deleted': 'Function {name} deleted',
  'app.surveyTemplate.ability': 'Survey Template',
  'app.surveyTemplate.save': 'Save as Template',
  'app.surveyTemplate.deleted': 'Template {name} deleted',
  'app.survey.deleted': 'Survey {name} deleted',
  'app.surveyTemplate.name': 'Template Name',
  'app.surveyTemplate.provideName': 'Please Provide a Template Name',
  'app.surveyTemplate.nameConflict': 'Naming Conflict',
  'app.surveyTemplate.nameConflictError':
    'A Survey with this name exists in the Template Library. Please enter another name.',
  'app.change': 'Change',
  'app.warning': 'Warning',
  'app.confirm': 'Confirm',
  'app.session.enrollment.warning':
    'Warning: Changing the Participant List assignment on active or completed sessions will result in a loss of Participant Data Columns.',
  'app.emailAddress': 'Email address',
  'app.uploadInProgress': 'Upload is in progress',
  'app.loginEnabled': 'Login enabled',
  'app.customLoginTime': 'Custom Login Time',
  'app.user.saved': '{name} saved!',
  'app.urlCopiedToClipboard': 'URL copied to clipboard',
  'app.copyToClipboard': 'Copy to clipboard',
  'app.copiedToClipboard': 'Copied to clipboard',
  'app.clone': 'Clone',
  'app.custom.reports.configurations': 'Custom Report Configurations',
  'app.add.new.configuration': 'Add New Configuration',
  'app.configuration.saved': '{name} saved!',
  'app.media.uploaded': '{name} media uploaded',
  'app.media.upload.failed': '{name} upload failed or stopped.',
  'app.media.upload.malware': '{name} upload failed -- malware detected.',
  'app.media.uploading.filename': 'Uploading  {name}',
  'app.customReport.mail': 'Custom Report will be sent to email: {mail} with reference number: {ref}',
  'app.dialReport.mail': 'Dial Report will be sent to email: {mail} with reference number: {ref}',
  'app.emotionReport.mail': 'Emotion Report will be sent to email: {mail} with reference number: {ref}',
  'app.invalid.ppt.export.msg': 'Please select at least one filter.',
  'app.invalid.ppt.export.title': 'Invalid Export',
  'app.configuration.name': 'Configuration Name',
  'app.newAutoEnrollConfig': 'New Auto Enroll Config',
  'app.session.count': 'Session Count',
  'app.dataColumns': 'Data Columns',
  'app.no.custom.reports.config.found': 'No custom report configuration found.',
  'app.customReportConfigTab.title': 'Custom Report: {name}',
  'app.dataTable.title': 'Data: {name}',
  'app.configuration': 'Configuration',
  'app.generateReport': 'Generate Report',
  'app.createReport': 'Create Report',
  'app.reportGenerationRequestedMsg': 'Report details will be sent to email: {email} with reference number: {refId}',
  'app.saveConfiguration': 'Save Configuration',
  'app.reportData': 'Report Data',
  'app.segmentsOptional': 'Segments (Optional)',
  'app.segement.warning': 'Segments {segments} not used in any rule.',
  'app.externalNormsOptional': 'External Norms (Optional)',
  'app.configure': 'Configure',
  'app.configured': 'Configured',
  'app.notConfigured': 'Not configured',
  'app.add': 'Add',
  'app.setParticipantIdentKey': 'Set Participant Identifier Key',
  'app.addDataColumn': 'Add data column:',
  'app.selectColumn': 'Select column',
  'app.replaceData': 'Replace Data',
  'app.deleteList': 'Delete List',
  'app.deleteList.confirm': 'Are you sure you want to delete {name}?',
  'app.addEditSessions': 'Add/Edit Sessions',
  'app.availableSessions': 'Available Sessions',
  'app.includedSessions': 'Included Sessions',
  'app.containOpenSessions': 'Project contains {count} open session(s)',
  'app.addQuestion': 'Add Question',
  'app.addFirstQuestion': 'Add First Question',
  'app.editQuestion': 'Edit Question',
  'app.selectQuestion': 'Select Question',
  'app.selectQuestionParenText': '(list includes all matrix and choice questions common to all selected sessions)',
  'app.selectAnswerData': 'Select Answer Data',
  'app.placeholder.selectAnswerChoices': 'Select answer choices',
  'app.matrixRows': 'Matrix Rows',
  'app.placeholder.selectMatrixRows': 'Select matrix rows',
  'app.topN': 'Top N',
  'app.bottomN': 'Bottom N',
  'app.answerChoices': 'Answer choice(s)',
  'app.title.addDataColumn': 'Add Data Column',
  'app.title.editDataColumn': 'Edit Data Column',
  'app.label.category': 'Category',
  'app.label.columnLabel': 'Column Label',
  'app.label.question': 'Question',
  'app.label.stimulus': 'Stimulus',
  'app.page': 'Page',
  'app.label.row': 'Row',
  'app.commonQuestionsCheckboxText': 'only list questions common to all sessions',
  'app.label.responses': 'Response(s)',
  'app.dataColumn.sessionOverrideText':
    'The sessions listed below do not have the selected question/response combination. Please select the data to use in order to include the session in the data column.',
  'app.addSegment': 'Add Segment',
  'app.upload.alert': 'Upload in progress will stop if you reload or close this page.',
  'app.segmentName': 'Segment Name',
  'app.defaultParticipantDataField': 'Default participant data field',
  'app.defaultParticipantDataFieldDesc':
    'This is the default participant data field that will be used for segmenting participants',
  'app.sessionOverrides': 'Session Overrides',
  'app.sessionOverridesDesc':
    'The sessions listed below do not have a "{field}" participant data field. Please select the data field to use in order to include the session in the segment.',
  'app.placeholder.chooseDataField': 'Choose data field',
  'app.placeholder.pleaseSelect': 'Please select',
  'app.configureExternalNorms': 'Configure External Norms',
  'app.dataPoint': 'Data Point',
  'app.dataPoints': 'Data Point(s)',
  'app.externalNorm': 'External Norm',
  'app.archived': 'Archived',
  'app.archivedProjects': 'Archived Projects',
  'app.archivedDate': 'Archived Date',
  'app.archiveProjectText':
    "will no longer appear in the active project list. The project's sessions, survey and survey results will not be available for inclusion in consolidated or custom reports. From the Archived Projects list, projects can be restored or deleted permanently.",
  'app.deleteProjectText':
    "Deleted projects will be removed permanently, and the DATA CANNOT BE RESTORED. No further access to the project's sessions, survey or survey results will be possible. You CANNOT UNDO this change.",
  'app.norm.change.bounds': 'Please enter a value between 1 and 100',
  'app.archiveData': 'Archive Data',
  'app.enableAutoArchiving': 'Enable Auto-Archiving',
  'app.archiveAllProjects': 'Archive all projects',
  'app.archive.afterMessage': 'after last survey has been closed',
  'app.archive.daysLessThan90': 'Projects must be closed at least 90 days ago to be archived.',
  'app.archive.monthsLessThan3': 'Projects must be closed at least 3 months ago to be archived.',
  'app.archive.yearsLessThan1': 'The Year value to be archived is not valid.',
  'app.archive.projectArchiving': 'The project: {project}, is in the process of being archived.',
  'app.archive.projectRestoring': 'The project: {project}, is in the process of being restored.',
  'app.archive.projectDeleted': 'The project: {project}, is deleted from archived projects.',
  'app.archive.status.ARCHIVING': 'Archiving',
  'app.archive.status.DELETING': 'Deleting',
  'app.archive.status.RESTORING': 'Restoring',
  'app.archive.status.ARCHIVED': 'Archived',
  'app.archive.status.DELETED': 'Deleted',
  'app.archive.status.RESTORED': 'Restored',
  'app.archive.status.ERROR_ARCHIVING': 'Error archiving',
  'app.archive.status.ERROR_DELETING': 'Error deleting',
  'app.archive.status.ERROR_RESTORING': 'Error restoring',
  'app.restore': 'Restore',
  'app.interval': 'Interval',
  'app.uncategorized': 'Uncategorized',
  'app.edit': 'Edit',
  'app.timing': 'Timing',
  'app.rules': 'Rules',
  'app.questionLibrary': 'Question Library',
  'app.questionPage': 'Question Page',
  'app.questionGroup': 'Question Group',
  'app.questionGroups': 'Question Groups',
  'app.questionText': 'Question Text',
  'app.excludeQuestionsFromParticipantData': 'Questions to exclude from participant data',
  'app.videoUrl': 'Video URL',
  'app.joiner.timing.elapsed.before': 'Time elapsed before this item',
  'app.joiner.timing.recommended': 'Recommended time for this item',
  'app.joiner.timing.override': 'Override recommended time',
  'app.joiner.timing.elapsed.after': 'Time elapsed after this item',
  'app.clickToAddOrEdit': 'Click to add or edit',
  'app.clickToChange': 'Click to change',
  'app.choiceRowMediaTypeError': 'Only images can be attached to a Matrix/Choice Question row',
  'app.layout': 'Layout',
  'app.caption': 'Caption',
  'app.altText': 'Alt Text',
  'app.shared': 'Shared',
  'app.notShared': 'Not Shared',
  'app.voted': 'Voted',
  'app.votes': 'Votes',
  'app.and': 'and',
  'app.not': 'not',
  'app.language': 'Language',
  'app.alternateLanguage': 'Alternate Language',
  'app.translateTo': 'Translate to',
  'app.language.af': 'Afrikaans (af)',
  'app.language.sq': 'Albanian (sq)',
  'app.language.am': 'Amharic (am)',
  'app.language.hy': 'Armenian (hy)',
  'app.language.az': 'Azerbaijani (az)',
  'app.language.bn': 'Bengali (bn)',
  'app.language.bs': 'Bosnian (bs)',
  'app.language.bg': 'Bulgarian (bg)',
  'app.language.ca': 'Catalan (ca)',
  'app.language.zh': 'Chinese (zh)',
  'app.language.zh-TW': 'Chinese Traditional (zh-TW)',
  'app.language.hr': 'Croatian (hr)',
  'app.language.cs': 'Czech (cs)',
  'app.language.da': 'Danish (da)',
  'app.language.fa-AF': 'Dari (fa-AF)',
  'app.language.nl': 'Dutch (nl)',
  'app.language.en': 'English (en)',
  'app.language.en-US': 'English (en)',
  'app.language.et': 'Estonian (et)',
  'app.language.tl': 'Filipino, Tagalog (tl)',
  'app.language.fi': 'Finnish (fi)',
  'app.language.fr': 'French (fr)',
  'app.language.fr-CA': 'French Canada (fr-CA)',
  'app.language.ka': 'Georgian (ka)',
  'app.language.de': 'German (de)',
  'app.language.el': 'Greek (el)',
  'app.language.gu': 'Gujarati (gu)',
  'app.language.ht': 'Haitian Creole (ht)',
  'app.language.ha': 'Hausa (ha)',
  'app.language.hi': 'Hindi (hi)',
  'app.language.hu': 'Hungarian (hu)',
  'app.language.is': 'Icelandic (is)',
  'app.language.id': 'Indonesian (id)',
  'app.language.ga': 'Irish (ga)',
  'app.language.it': 'Italian (it)',
  'app.language.ja': 'Japanese (ja)',
  'app.language.kn': 'Kannada (kn)',
  'app.language.kk': 'Kazakh (kk)',
  'app.language.ko': 'Korean (ko)',
  'app.language.lv': 'Latvian (lv)',
  'app.language.lt': 'Lithuanian (lt)',
  'app.language.mk': 'Macedonian (mk)',
  'app.language.ms': 'Maylay (ms)',
  'app.language.ml': 'Malayalam (ml)',
  'app.language.mt': 'Maltese (mt)',
  'app.language.mr': 'Marathi (mr)',
  'app.language.mn': 'Mongolian (mn)',
  'app.language.no': 'Norwegian (no)',
  'app.language.fa': 'Persian (fa)',
  'app.language.ps': 'Pashto (ps)',
  'app.language.pl': 'Polish (pl)',
  'app.language.pt': 'Portuguese (pt)',
  'app.language.pt-PT': 'Portuguese (Portugal) (pt)',
  'app.language.pa': 'Punjabi (pa)',
  'app.language.ro': 'Romanian (ro)',
  'app.language.ru': 'Russian (ru)',
  'app.language.sr': 'Serbian (sr)',
  'app.language.si': 'Sinhala (si)',
  'app.language.sk': 'Slovak (sk)',
  'app.language.sl': 'Slovenian (sl)',
  'app.language.so': 'Somali (so)',
  'app.language.es': 'Spanish (es)',
  'app.language.es-MX': 'Spanish Mexico (es-MX)',
  'app.language.sw': 'Swahili (sw)',
  'app.language.sv': 'Swedish (sv)',
  'app.language.ta': 'Tamil (ta)',
  'app.language.te': 'Telugu (te)',
  'app.language.th': 'Thai (th)',
  'app.language.tr': 'Turkish (tr)',
  'app.language.uk': 'Ukrainian (uk)',
  'app.language.uz': 'Uzbek (uz)',
  'app.language.vi': 'Vietnamese (vi)',
  'app.language.cy': 'Welsh (cy)',

  'app.surveyEditor.STRING': 'Any',
  'app.surveyEditor.ALPHAONLY': 'Alphabetic Only',
  'app.surveyEditor.ALPHANUMERIC': 'Alpha-numeric Only',
  'app.surveyEditor.NUMERIC': 'Whole Number',
  'app.surveyEditor.DOUBLE': 'Decimal',
  'app.surveyEditor.EMAIL': 'Email',
  'app.surveyEditor.ZIPCODE': 'Zip Code',
  'app.surveyEditor.NOTGIBBERISH': 'Not Gibberish',
  'app.surveyEditor.readOnlyMode': '* Read Only Mode *',
  'app.surveyEditor.readOnlyTranslationInProgress': '* Read Only - Translation In Progress *',
  'app.surveyEditor.surveyImport.inProgress': 'Please Hold on, survey is importing...',
  'app.surveyEditor.surveyImport.failed': 'Survey Import Failed.',
  'app.removeMedia': 'Remove Media',
  'app.removeMedia.confirm': 'Are you sure you want to remove this media?',
  'app.url.validation.error': 'URL is invalid',
  'app.url.https.validation.error': 'URL must be https',
  'app.url.whitelist.error': 'The domain "{domainName}" is not configured in the White-listed domains.',
  'app.hideQuestion': 'Hide question',
  'app.exitPage': 'Exit Page:',
  'app.videoComplete': 'VIDEO COMPLETE',
  'app.continueSession': 'CONTINUE SESSION',
  'app.questionRequired': 'Question required',
  'app.randomizeRows': 'Randomize rows',
  'app.minRanking.msg.1': 'Participant must rank a minimum of ',
  'app.minRanking.msg.2': ' choices',
  'app.openText.validateAnswerFormat': 'Validate answer format',
  'app.openText.answerValueOutOfRange': 'Please provide a value between 0 and 5000 for the answer range.',
  'app.openText.videoCaptureEnabled': 'Video capture enabled',
  'app.openText.rangeBetween': 'Range between',
  'app.openText.sharingAndVoting': 'Sharing and Voting',
  'app.openText.maxExposurePerResponse': 'Maximum exposures per response',
  'app.openText.minResponsesBeforeSharing': 'Minimum responses before sharing',
  'app.openText.maxResponsesToVoteOn': 'Maximum responses to vote on',
  'app.choiceType': 'Choice Type',
  'app.singleChoice': 'Single choice',
  'app.multipleChoice': 'Multiple choice',
  'app.dropdown': 'Dropdown',
  'app.forcedRank': 'Forced rank',
  'app.matrixOptions.valueLabels': 'Minimum/Center/Maximum Value Labels',
  'app.leftLabel': 'Left Side Label',
  'app.centerLabel': 'Center Label',
  'app.rightLabel': 'Right Side Label',
  'app.choiceEdit.abbreviate': 'Abbreviate',
  'app.choiceEdit.value': 'Value',
  'app.choiceEdit.otherInputPlaceholder': 'Include "I don\'t know", "None of the above", or "Other"',
  'app.include': 'Include',
  'app.entryType.IDK': "I don't know",
  'app.entryType.IDK.defaultText': "I don't know",
  'app.entryType.NOTA': 'None of the above',
  'app.entryType.NOTA.defaultText': 'None of the above',
  'app.entryType.OTHER': 'Other',
  'app.entryType.OTHER.defaultText': 'Other (specify comments)',
  'app.matrixEdit.rowLabels': 'Row Labels',
  'app.matrixEdit.columnChoices': 'Column Choices',
  'app.matrixEdit.rowTitle': 'Row Title',
  'app.matrixEdit.enterRowOptions': 'Enter row options',
  'app.matrixEdit.choiceAnswers': 'Choice answers',
  'app.matrixEdit.bipolar': 'Bipolar',
  'app.matrixEdit.chooseChoiceQuestions': 'Please select a question',
  'app.matrixEdit.noPreviousChoiceQuestions':
    'There appears to be no multiple choice questions before this question, please enter the row options.',
  'app.hideConceptRotation': 'Hide Concept Rotation',
  'app.label.showConceptMediaOnce': 'Do not show Media Content for each question',
  'app.concept': 'Concept',
  'app.concepts': 'Concepts',
  'app.addConcept': 'Add Concept',
  'app.editConcept': 'Edit Concept',
  'app.concept.selectMedia': 'Select Media',
  'app.concept.textOnly': 'Text Only',
  'app.conceptRotation.introText':
    'You can add Intro, Transitional, and Exit Media to help survey takers move through the Rotation.',
  'app.conceptRotation.introMedia': 'Intro Media',
  'app.conceptRotation.transitionalMedia': 'Transitional Media',
  'app.conceptRotation.exitMedia': 'Exit Media',
  'app.survey.totalTime': 'Total Survey Time',
  'app.survey.ruleConfigured': '1 rule configured.',
  'app.survey.rulesConfigured': '{number} rules configured.',
  'app.survey.copyRule': 'Copy/clone rule',
  'app.survey.copyCondition': 'Copy/clone rule condition',
  'app.survey.delete.defaultMessage': 'Remove all questions and delete survey.',
  'app.survey.delete.confirmBoxTitle': 'Delete Survey',
  'app.survey.delete.confirmMessage':
    'Are you sure you want to remove all questions and delete the survey from the session?',
  'app.survey.delete.disabledMessage': 'Session is closed, survey cannot be deleted',
  'app.surveyOptions.setAsRequired': 'Set all questions as Required',
  'app.surveyOptions.enableVideoCapture': 'Enable video capture on all Open Text questions',
  'app.images.watermark': 'Images Watermark',
  'app.videos.watermark': 'Videos Watermark',
  'app.shortenUrls': 'Shorten URLs',
  'app.limitReuse': 'Limit Reuse',
  'app.hitsLimit': 'Limit # of hits to',
  'app.showWelcomeVideo': 'Show Welcome Video',
  'app.relevantId': 'RelevantID',
  'app.enableCountryValidation': 'Enable Country Validation',
  'app.using': 'using',
  'app.videosAndImages': 'Videos and Images',
  'app.videos': 'Videos',
  'app.images': 'Images',
  'app.watermark.participantId': 'Participant ID',
  'app.watermark.sessionId': 'Session ID',
  'app.watermark.nickname': 'Participant Nickname',
  'app.watermark.position': 'position: ',
  'app.watermark.top-right': 'top-right',
  'app.watermark.top-left': 'top-left',
  'app.watermark.bottom-right': 'bottom-right',
  'app.watermark.bottom-left': 'bottom-left',
  'app.exportSurvey': 'Export Survey',
  'app.surveyExport.introText': 'Export survey as Microsoft Word document',
  'app.surveyExport.formattedDocument': 'Formatted Document',
  'app.surveyExport.importableDocument': 'Importable Document',
  'app.surveyExport.includeItems': 'Optional items to include',
  'app.surveyExport.includeGroupNames': 'Group Names',
  'app.surveyExport.includeSurveyContentOptions': 'Survey Content Options',
  'app.surveyExport.includeSurveyContentTiming': 'Survey Content Timing',
  'app.surveyExport.includeStimThumbNails': 'Stim Thumbnails',
  'app.surveyExport.includeStimInfo': 'Stim Information',
  'app.surveyExport.generateSurveyContentReviewSuccess':
    'Survey Content Review will be sent to email: {email} with reference number: {number}.',
  'app.surveyExport.generateSurveyRawExportSuccess':
    'Importable survey document will be sent to email: {email} with reference number: {number}.',
  'app.question': 'Question',
  'app.questions': 'Questions',
  'app.answer': 'Answer',
  'app.dataTable': 'Data Table',
  'app.column': 'Column',
  'app.operator': 'Operator',
  'app.label': 'Label',
  'app.value': 'Value',
  'app.surveyRules.all': 'All',
  'app.surveyRules.any': 'Any',
  'app.surveyRules.addRule': 'Add Rule:',
  'app.surveyRules.terminationRule': 'Termination Rule',
  'app.surveyRules.termination': 'Termination',
  'app.surveyRules.questionVisibilityRule': 'Question Visibility Rule',
  'app.surveyRules.questionVisibility': 'Question Visibility',
  'app.surveyRules.segmentationRule': 'Segmentation Rule',
  'app.surveyRules.segmentation': 'Segmentation',
  'app.surveyRules.setVariableRule': 'Set Variable Rule',
  'app.surveyRules.setVariable': 'Set Variable',
  'app.surveyRules.setFlagRule': 'Set Flag Rule',
  'app.surveyRules.setFlag': 'Set Flag',
  'app.surveyRules.terminateIfBegin': 'Terminate user if',
  'app.surveyRules.includeIfBegin': 'Question is visible if',
  'app.surveyRules.applySegmentationIfBegin': 'Apply segmentation if',
  'app.surveyRules.applyRuleIfBegin': 'Apply rule if',
  'app.surveyRules.ifEnd': 'of the following conditions apply:',
  'app.surveyRules.segmentUserInto': 'Segment user into',
  'app.surveyRules.setVariableLabel1': 'Set Variable',
  'app.surveyRules.setVariableLabel2': 'equal to value',
  'app.surveyRules.setFlagTo': 'Set flag to',
  'app.surveyRules.operator.lt': '<',
  'app.surveyRules.operator.lte': '<=',
  'app.surveyRules.operator.eq': '=',
  'app.surveyRules.operator.ne': '≠',
  'app.surveyRules.operator.gte': '>=',
  'app.surveyRules.operator.gt': '>',
  'app.surveyRules.operator.startsWith': 'starts with',
  'app.surveyRules.operator.endsWith': 'ends with',
  'app.surveyRules.operator.contains': 'contains',
  'app.surveyRules.operator.doesNotContain': 'does not contain',
  'app.surveyRules.operator.is': 'is',
  'app.surveyRules.operator.isNot': 'is not',
  'app.surveyRules.operator.isIgnoreCase': 'is (ignore case)',
  'app.surveyRules.operator.isIncludedIn': 'is included in',
  'app.surveyRules.operator.includesAny': 'includes any',
  'app.surveyRules.operator.includesAll': 'includes all',
  'app.surveyRules.operator.doesNotInclude': 'does not include',
  'app.surveyRules.operator.isGibberish': 'is gibberish',
  'app.surveyRules.operator.notFlagged': 'not flagged',
  'app.criteriaType': 'Criteria Type',
  'app.questionAnswer': 'Question Answer',
  'app.lookupValue': 'Lookup Value',
  'app.customFunction': 'Custom Function',
  'app.participantMetadata': 'Participant data',
  'app.deleteTemplate': 'Delete Template',
  'app.confirmDelete': 'Confirm Delete',
  'app.delete.template.confirm': 'Are you sure you want to delete template "{name}"',
  'app.template.delete.confirmMessage': 'Are you sure you want to remove all questions and delete the template?',
  'app.template.delete.defaultMessage': 'Remove all questions and delete template.',
  'app.template.delete.confirmBoxTitle': 'Delete Template',
  'app.screener': 'Screener',
  'app.screeners': 'Screeners',
  'app.screener.qualification': 'Qualification',
  'app.screener.collectEmail': 'Collect email address on qualification',
  'app.screener.sendEmail': 'Send email on qualification',
  'app.screener.emailSubject': 'Email subject',
  'app.screener.emailContent': 'Email content',
  'app.screener.choices': 'Choice Text',
  'app.screenerSurvey': 'Screener Survey',
  'app.addScreener': 'Add Screener',
  'app.screenerSurveyName': 'Screener Survey Name',
  'app.userId': 'User ID',
  'app.fav': 'Fav',
  'app.chat': 'Chat',
  'app.chatManagement': 'Chat Management',
  'app.chat.questionAbbrev': 'Q',
  'app.chat.answerAbbrev': 'A',
  'app.participantChatMessages': 'Participant chat messages',
  'app.researchStaff': 'Research Staff',
  'app.chatWith': 'Chat w/',
  'app.chatEnded': 'Chat ended by Research Staff. You can close this window now.',
  'app.endChat': 'End Chat',
  'app.noChat': 'Chat Unavailable',
  'app.flag': 'Flag',
  'app.setFlags': 'Set flag for participant',
  'app.participantFlag.GROUP1.color': 'Orange',
  'app.participantFlag.GROUP2.color': 'Red',
  'app.participantFlag.GROUP3.color': 'Purple',
  'app.participantFlag.GROUP4.color': 'Blue',
  'app.participantFlag.GROUP5.color': 'Yellow',
  'app.participantFlag.GROUP6.color': 'Green',
  'app.participantFlag.GROUP7.color': 'Black',
  'app.removeFlag': 'Remove Flag',
  'app.send': 'Send',
  'app.response': 'Response',
  'app.time': 'Time',
  'app.words': 'Words',
  'app.textRank': 'Text Rank',
  'app.wordCount': 'Word Count',
  'app.gibberish': 'Gibberish',
  'app.taken': 'Taken',
  'app.qualified': 'Qualified',
  'app.notQualified': 'Not Qualified',
  'app.disqualified': 'Disqualified',
  'app.qualificationStatusChangeDisabled': 'Qualification status change disabled. Incomplete Survey.',
  'app.participant.active.updated': 'Updated participant qualification status',
  'app.createScreenerSurvey': 'Create Screener Survey',
  'app.data': 'Data',
  'app.values': 'Values',
  'app.segment': 'Segment',
  'app.segments': 'Segments',
  'app.noSegments': 'No Segments',
  'app.segmentCategory': 'Segment Category',
  'app.segmentCategory.addCategory': 'Add Category',
  'app.segmentCategory.addSegmentCategory': 'Add Segment Category',
  'app.segmentCategory.editSegmentCategory': 'Edit Segment Category',
  'app.quotas': 'Quotas',
  'app.enableQuotas': 'Enable Quotas',
  'app.segmentsCreated':
    'Segments created here are available for use in question segmentation rules. Warning sign indicates one or more segments have not been used in any rules.',
  'app.functions': 'Functions',
  'app.function': 'Function',
  'app.includeDisqualifiedParticipants': 'Include disqualified participants',
  'app.completed.abbrev': 'Comp',
  'app.attempted.abbrev': 'Att',
  'app.incidenceRate.abbrev': 'IR',
  'app.subtotals': 'Subtotals',
  'app.segment.qualifiedVsQuota': 'qualified for segment / segment quota',
  'app.segment.qualifiedVsTotalQualified': 'qualified for segment / total number of qualified',
  'app.viewParticipantResponses': 'View Participant Responses',
  'app.questionTitle': 'Question Title',
  'app.participantResponse': 'Participant Response',
  'app.previewResults': 'Preview Results',
  'app.termination': 'Termination',
  'app.questionVisibility': 'Question Visibility',
  'app.segmentation': 'Segmentation',
  'app.answers': 'Answers',
  'app.filter': 'Filter',
  'app.filters': 'Filters',
  'app.createFilter': 'Create Filter',
  'app.filterName': 'Filter Name',
  'app.filterExists': 'A filter with that name already exists',
  'app.uniqueFilterNamesMsg': 'Filter names must be unique: {dupes}',
  'app.quotaFilters': 'Quota Filters',
  'app.editFilters': 'Edit Filters',
  'app.filterCreated': 'Filter created!',
  'app.noFiltersCreated': 'No filters created yet',
  'app.filterbar.placeholder': 'select filter criteria',
  'app.advanced.filterCriteria': 'Advanced Filter Criteria',
  'app.addFilterSummary': 'Click to add filter summary',
  'app.selectUpToMaxFilters': 'Select up to {max} filters',
  'app.responseCountByFilter': 'Response count by filter',
  'app.answered': 'Answered',
  'app.emotions.maxFiltersReached':
    'A max number of 5 filters can be selected, please remove unwanted filters before adding new ones.',
  'app.maxQuota': 'Max Quota',
  'app.color': 'Color',
  'app.avg': 'Avg',
  'app.average': 'Average',
  'app.25Percentile': '25th percentile',
  'app.75Percentile': '75th percentile',
  'app.max': 'Max',
  'app.rating': 'Rating',
  'app.scale': 'Scale',
  'app.rate': 'Rate',
  'app.count': 'Count',
  'app.noQuestionDefined': 'There is no question in the survey, please add question first.',
  'app.noSurveyDefined': 'There is no survey for this session, please add a survey first.',
  'app.actionButtons.configText': 'Action Buttons (add up to three)',
  'app.allowMultiple': 'Allow multiple',
  'app.showQuestion': 'Show question',
  'app.exportButtons.presentation': 'Presentation',
  'app.rawData': 'Raw Data',
  'app.exportButtons.rawData': 'Raw Data',
  'app.files': 'Files',
  'app.filename': 'Filename',
  'app.date': 'Date',
  'app.export.rawData': 'Export Raw Data',
  'app.export.presentation': 'Export Presentation',
  'app.deployWidget.questionLabel': 'Q.{qNum} is @',
  'app.deployWidget.rotationLabel': 'Q.{qNum} Position {pos} is @',
  'app.deployWidget.pageLabel': 'P.{pageNum} is @',
  'app.deployNext': 'Deploy Next',
  'app.automatic': 'Automatic',
  'app.manual': 'Manual',
  'app.logins': 'Logins',
  'app.remaining': 'Remaining',
  'app.elapsed': 'Elapsed',
  'app.noEndDate': 'no end date',
  'app.minutes': 'minutes',
  'app.participantManagement': 'Participant Management',
  'app.sessionManagement': 'Session Management',
  'app.comparativeViews': 'Comparative Views',
  'app.dashboardConfig': 'Dashboard Configuration',
  'app.collapseMenu': 'Collapse Menu',
  'app.expandMenu': 'Expand Menu',
  'app.top': 'Top',
  'app.bottom': 'Bottom',
  'app.middle': 'Middle',
  'app.both': 'Both',
  'app.scalar': 'Scalar',
  'app.percentSign': '%',
  'app.percentSign.tooltip': 'Show percentage',
  'app.numberAndPercent': 'N+%',
  'app.numberAndPercent.tooltip': 'Show numbers and percentage',
  'app.actions': 'Actions',
  'app.lastUpdated': 'Last updated',
  'app.updatedOn': 'Update on: {time}',
  'app.owner': 'Owner',
  'app.share': 'Share',
  'app.unshare': 'Unshare',
  'app.deleteConfig': 'Delete Configuration',
  'app.deleteConfigConfirm': 'Are you sure you want to delete the saved configuration "{name}"?',
  'app.currentConfiguration': 'Current Configuration',
  'app.savedConfigurations': 'Saved Configurations',
  'app.sharedConfigurations': 'Shared Configurations',
  'app.participantDetails': 'Participant Details',
  'app.participantId': 'Participant ID',
  'app.displayPreferences': 'Display Preferences',
  'app.rd.globalNets': 'Global Nets',
  'app.rd.setGlobalNets': 'Set Global Nets',
  'app.rd.globalNetsMsg1':
    'Overwriting all exisitng Questions with this setting or apply it only to Questions with no Custom Nets already set.',
  'app.rd.globalNetsMsg2': 'The setting will be applied automatically to any new questions.',
  'app.rd.overwriteGlobalNets': 'Overwrite Net setting on all Questions',
  'app.rd.notOverwriteGlobalNets': 'Do not overwrite exisiting Custom Nets',
  'app.rd.globalResponseDisplay': 'Global Response Display',
  'app.rd.setGlobalResponseDisplay': 'Set Global Response Display',
  'app.rd.globalRespMsg1':
    'Overwrite all applicable Questions with this setting or apply it only to Questions with no Response Display choice already set.',
  'app.rd.globalRespMsg2': 'The setting will be applied automatically to any new questions.',
  'app.rd.globalRespOption1': 'Do not overwrite existing settings',
  'app.rd.globalRespOption2': 'Overwrite Response Display setting on all Questions',
  'app.rd.showDisplaySetting': 'Show N, % or both',
  'app.rd.N': 'N',
  'app.rd.N.tooltip': 'Show numbers',
  'app.rd.saveNets': 'Save Nets',
  'app.rd.editNets': 'Edit Nets',
  'app.rd.editResponseDisplay': 'Edit Response Display',
  'app.saveToMyConfigurations': 'Save to my configurations',
  'app.researchDashboard.Invited': 'Invited',
  'app.researchDashboard.Accepted': 'Accepted',
  'app.researchDashboard.Removed': 'Removed',
  'app.researchDashboard.Active': 'Active',
  'app.rd.saveResponseDisplay': 'Save Response Display',
  'app.rd.applyToDashboard': 'Apply to Dashboard',
  'app.rd.applyMessage': 'Replace your current configuration with:',
  'app.rd.filterPosition': 'Position: {position}',
  'app.rd.noneSelected': 'none.',
  'app.rd.showSelectedPosition': 'Positions: {positions}',
  'app.configureColumns': 'Configure Columns',
  'app.selectAndOrderColumns': 'Select and order columns',
  'app.rd.answerChoices': 'Answer Choices',
  'app.rd.responsesFor': 'Responses for ',
  'app.net': 'Net',
  'app.scalarValue': 'Scalar Value',
  'app.customNets': 'Custom Nets',
  'app.setCustomNets': 'Set Custom Nets',
  'app.createNew': 'Create New',
  'app.selectExisting': 'Select Existing to Overwrite',
  'app.resetRDConfig': 'Reset your Dashboard Configuration settings back to the Defaults?',
  'app.shareConfig': 'Share Configuration <span class="sharing">"{name}"</span>',
  'app.unshareConfig': '<span class="unshare">Unshare Configuration <span class="sharing">"{name}"</span></span>',
  'app.availableToProject': 'Apply Configuration to Project',
  'app.availableToSession': 'Apply Configuration to Session',
  'app.noSavedConfig': 'No saved configurations',
  'app.noSharedConfig': 'No shared configurations',
  'app.notGreaterThan100': 'Global Nets Value Cannot be Greater than 100.',
  'app.ans': 'Ans',
  'app.group': 'Group',
  'app.miscNoGroup': 'Misc. / No Group',
  'app.surveyQuestions': 'Survey Questions',
  'app.surveyQuestionsAndContent': 'Survey Questions & Content',
  'app.hidden': 'Hidden',
  'app.visible': 'Visible',
  'app.pending': 'Pending',
  'app.waiting': 'Waiting',
  'app.closed': 'Closed',
  'app.more': 'more',
  'app.less': 'less',
  'app.favorite': 'Favorite',
  'app.isFavorite': 'Is Favorite',
  'app.isNotFavorite': 'Is Not Favorite',
  'app.positionNumLabel': 'Position {pos}',
  'app.newQuickFilter': 'New Quick Filter ...',
  'app.comparativeView.filteredResult': '{filteredEnrolleesCount} participants',
  'app.filteredResult': 'Showing {filtered} of {total} responses',
  'app.FILTER_TERM_AND': 'and',
  'app.FILTER_TERM_!': 'not',
  'app.FILTER_TERM_OR': 'or',
  'app.FILTER_TERM_NOT': 'not',
  'app.FILTER_TERM_)': ')',
  'app.FILTER_TERM_(': '(',
  'app.advanceFilter.hint': 'Click buttons to add components, drag to re-arrange:',
  'app.filter.exception': 'Filter expression incomplete.',
  'app.filter.matches': 'Matches: {number} participants.',
  'app.selectQuestions': 'Select Questions',
  'app.selectParticipantData': 'Select Participant Data',
  'app.selectDataPoints.title': 'Select Data Point(s)',
  'app.participantData': 'Participant Data',
  'app.other': 'Other',
  'app.custom': 'Custom',
  'app.showAll': 'show all',
  'app.showLess': 'show less',
  'app.surveyResponses': 'Survey Responses',
  'app.terminate': 'Terminate',
  'app.unterminate': 'Un-terminate',
  'app.terminateParticipant': 'Terminate Participant',
  'app.terminate.confirm': 'Are you sure you want to terminate {user}?',
  'app.terminationReason': 'Termination Reason',
  'app.terminate.placeholder': 'Enter a reason or pick from the list',
  'app.TERMINATE_REASON_TECH': 'Technical difficulty',
  'app.TERMINATE_REASON_DISRUPTIVE': 'Disruptive',
  'app.TERMINATE_REASON_DOESNT_BELONG': 'Does not belong',
  'app.TERMINATE_REASON_NO_REASON': 'N/A',
  'app.TERMINATE_REASON_NON_RESPONSIVE': 'Non-responsive',
  'app.complete': 'Complete',
  'app.surveyComplete': 'Survey Complete',
  'app.internalID': 'Internal ID',
  'app.surveyContent': 'Survey Content',
  'app.showNewResponses': 'Show {num} new response(s)',
  'app.filterSetting.changed': 'Filter setting has been changed',
  'app.whitelist.link': '* white-listed keyword - ',
  'app.whitelist.modal.title': 'Edit Keyword White-list',
  'app.whitelist.modal.instructions': 'Enter single keywords or phrases:',
  'app.clusterAnalysis': 'Cluster Analysis',
  'app.responseTable': 'Response Table',
  'app.clusters': 'Clusters',
  'app.clusteringSettings': 'Clustering Settings',
  'app.calculatedClusters': 'Calculated Clusters',
  'app.displayedClusters': 'Displayed Clusters',
  'app.fixedCount': 'Fixed Count',
  'app.mediaConvert.status.msg': 'In Progress: {percentComplete}% Complete',
  'app.matchANY': 'Match ANY',
  'app.matchALL': 'Match ALL',
  'app.enable.multipleSurveys': 'Enable multiple surveys',
  'app.multipleSurveys.disabledMessage':
    'Since multiple surveys exist in this project, change to single survey is not allowed',
  'app.archiveProject': 'Archive Project',
  'app.archiveProject.message1': 'Archived projects will no longer appear in the active project list. ',
  'app.archiveProject.message2':
    " The project's sessions, survey and survey results <br>will not be available for inclusion in consolidated or custom reports.",
  'app.archiveProject.message3':
    ' From the Archived Projects list projects can be <br>restored or deleted permanently.',
  'app.testMode': 'Test Mode',
  'app.surveyPref.message1':
    'Your preferences for the display of participant and response data are saved automatically as you make changes.',
  'app.surveyPref.message2': 'Use this page to reset or share your personalized display preferences.',
  'app.surveyPref.saved': 'Display preferences saved successfully',
  'app.currentPreferences': 'Current Preferences',
  'app.filtersAndSummaries': 'Filters + Filter Summaries',
  'app.questionResponseDetails': 'Question Response Details',
  'app.reportConfigs': 'Report Configurations',
  'app.notSet': 'Not Set',
  'app.shareDisplayPreferences': 'Share Display Preferences',
  'app.sharePrefMessage': 'Select an existing configuration to overwrite, or type a name to create a new one',
  'app.importDisplayPreferences': 'Import Display Preferences',
  'app.importPrefMessage': 'Select a shared configuration to import',
  'app.rdConfigSelectPlaceholder': 'Select a shared configuration ...',
  'app.viewTitle': 'View Title',
  'app.selectDeselect.all': 'Select/Deselect All',
  'app.questionAnswerTitle': 'Question/Answer',
  'app.addQuestion.answer': 'Add Question/Answer',
  'app.display': 'Display',
  'app.showDisplayOption': 'Show: {option}',
  'app.rd.showSelectedConcepts': 'Concepts: {concepts}',
  'app.addEdit.row': 'Add/Edit Row',
  'app.fieldName': 'Field Name',
  'app.showRotaion.positions': 'Showing Rotation Positions: {positions}',
  'app.qa.copiedToClipboard': 'Question Answers table data copied to Clipboard!',
  'app.newComparativeView': 'New Comparative View',
  'app.deleteView': 'Delete Comparative View',
  'app.delete.comparativeView': 'Are you sure you want to delete this view "{view}"?\n  This cannot be undone.',
  'app.abc': 'abc',
  'app.onlyFirst50ParticipantsShown': 'only first 50 participants shown',
  'app.participantFlag': 'Participant Flag',
  'app.compare': 'compare',
  'app.Vis-a-Vis': 'Vis-a-Vis',
  'app.maxNumParticipants': 'Maximum # of participants',
  'app.moderatorLink': 'Moderator Link',
  'app.observerLink': 'Observer Link',
  'app.vfgNotDeployed.message': 'Waiting Room will become active when Vis-a-Vis is deployed',
  'app.vfgWaiting.message': 'Active participants will enter Vis-a-Vis when moderator starts the meeting',
  'app.meeting.inProgress': 'Meeting is in progress',
  'app.waitingRoom': 'Waiting Room',
  'app.selectAll': 'Select All',
  'app.unselectAll': 'Unselect All',
  'app.selectParticipants': 'Select participants to move from Waiting Room to Active',
  'app.activeParticipants': 'These participants will be active when meeting starts',
  'app.activateSelected': 'Activate Selected',
  'app.removeSelected': 'Remove Selected',
  'app.removeParticipant': 'Remove Participant',
  'app.removeParticipantModalText':
    'Warning! Removed participants will no longer be able to participate in the Vis-a-Vis. This cannot be undone.',
  'app.vfg.maxParticipantMsg': 'Maximum of {max} participants can be active',
  'app.recording': 'Recording',
  'app.endMeeting': 'End Meeting',
  'app.endMeetingMsg': 'Are you sure you want to end the meeting?',
  'app.transcript': 'Transcript',
  'app.bookmarks': 'Bookmarks',
  'app.waitingForRecording': 'Waiting for recording',
  'app.transcribeInProgress': 'Transcription in progress',
  'app.transcribeFailed': 'Transcription failed',
  'app.unsavedChangesWarning': 'You have unsaved changes. Are you sure you want to leave this page?',
  'app.selfServe.getStarted': 'Get started with your Self-Serve project! Follow these steps:',
  'app.programSurvey': 'Program Survey',
  'app.programSurvey.desc': 'Add questions, content, and rules in the',
  'app.surveyEditorTab': 'Survey Editor Tab',
  'app.recruitParticipants': 'Recruit Participants',
  'app.recruitParticipants.desc': 'Define target participants and demographic data in the',
  'app.recruitTab': 'Recruit Tab',
  'app.preFlightCheck': 'Pre-flight Check',
  'app.feasibilityCheck': 'Feasibility Check',
  'app.feasibilityCheckPassed': 'Feasibility passed!',
  'app.testSurvey': 'Test Survey',
  'app.goLive': 'Go Live!',
  'app.launch': 'Launch',
  'app.pause': 'Pause',
  'app.goLive.desc': 'View survey responses in the',
  'app.surveyResponsesTab': 'Survey Responses Tab',
  'app.finalizeAndReport': 'Finalize and Report',
  'app.finalizeAndReport.desc': 'Close survey, view and export survey response data in the',
  'app.reportsTab': 'Reports Tab',
  'app.targetAudience': 'Target Audience',
  'app.targetAudience.message1':
    'Add up to 4 target audiences using participant responses to demographic questions (gender, age, income, etc.)',
  'app.targetAudience.message2':
    'After creating or modifying quotas, run the Feasibility Check to confirm panel can supply the desired quota.',
  'app.targetRule.limitReached': 'Max of 4 target audiences are allowed.',
  'app.additionalParticipantData': 'Additional Participant Data',
  'app.additionalParticipant.message1':
    'The answers to these questions will be included in the participant data, but there are no quotas for them.',
  'app.additionalParticipant.message2': 'Questions used in targeting criteria above are automatically included.',
  'app.createLabel': 'Create Label',
  'app.SelectQuestion': 'Select a question',
  'app.addQuota': 'Add quota',
  'app.addParticipantData': 'Add participant data',
  'app.createQuota': 'Create Quota',
  'app.criteria': 'Criteria',
  'app.addCriteria': 'Add Criteria',
  'app.from': 'From',
  'app.to': 'To',
  'app.pickAnAnswer': 'Please pick an answer',
  'app.pickAQuestion': 'Please pick a question',
  'app.map.values.msg': 'Match any of these comma-separated values',
  'app.questionName': 'Question Name',
  'app.addAutoEnroll': 'Add Auto-Enroll',
  'app.collectionType.IMPORT': 'Import',
  'app.collectionType.SCREENER': 'Screener',
  'app.collectionType.AUTO_ENROLL': 'Auto-Enroll'
};
