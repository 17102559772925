import React from 'react';
import intl from 'react-intl-universal';

import './PageFooter.css';

function getYear() {
  return new Date().getFullYear();
}

export const PageFooter = () => {
  return (
    <footer className="footer">
      <div className="p-2">{intl.get('app.footer', { year: getYear() })}</div>
      <div className="p-2 ms-auto" />
      <div className="p-2">
        <a href="https://marketcast.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
          {intl.get('app.footer.priv')}
        </a>
      </div>
      <div className="p-2">
        <a href="https://marketcast.com/about/" target="_blank" rel="noopener noreferrer">
          {intl.get('app.footer.about')}
        </a>
      </div>
      <div className="p-2">
        <a href="https://marketcast.com/contact/" target="_blank" rel="noopener noreferrer">
          {intl.get('app.footer.contact')}
        </a>
      </div>
    </footer>
  );
};
