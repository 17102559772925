import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

export const PanelNameRow = props => {
  const { config, maxQuota, expectedIR, isSelected, isScreener, readOnly, onPanelSelect, setQuota, setExpectedIR } =
    props;

  return (
    <div className="panel-name-row">
      <label>
        <Input type="checkbox" checked={isSelected} disabled={readOnly} onChange={() => onPanelSelect(config)} />
        <span style={{ fontWeight: isSelected && '600' }}>{config.name}</span>
      </label>
      {isScreener && isSelected && (
        <>
          <div style={{ margin: '0 .5rem 0 2rem' }}>{intl.get('app.quota')}:</div>
          <Input
            type="number"
            style={{ width: '5rem', marginBottom: '.5rem' }}
            min="0"
            value={maxQuota}
            disabled={readOnly}
            onChange={e => setQuota(config, e.target.value)}
          />
          <div style={{ margin: '0 .5rem 0 2rem' }}>{intl.get('app.expectedIR')}:</div>
          <Input
            type="number"
            style={{ width: '5rem', marginBottom: '.5rem' }}
            min="0"
            value={expectedIR}
            disabled={readOnly}
            onChange={e => setExpectedIR(config, e.target.value)}
          />
        </>
      )}
    </div>
  );
};
