import { handleActions } from 'redux-actions';
import { newFiltersAndParticipantsActions } from '../actions/filtersAndParticipantsActions';

const initialState = {};

const filtersAndParticipantsReducer = handleActions(
  {
    [newFiltersAndParticipantsActions.succeeded](state, { payload }) {
      const { sessionId, filters, filteredParticipants } = payload;
      return {
        ...state,
        [sessionId]: {
          filters,
          filteredParticipants
        }
      };
    }
  },
  initialState
);

export default filtersAndParticipantsReducer;
