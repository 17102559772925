import { get, del, post } from '../util/request';

const RD_URI = '/a/api/researchDashboard';
const RD_CONFIG_URI = '/a/api/researchDashboard/config';
const RD_CONFIGS_URI = '/a/api/researchDashboard/configs';
const CHECK_FILTER_URI = '/a/api/allSessionParticipants';

// API for setting a draft config as the saved config
async function saveRDConfigApi(payload) {
  return post(`${RD_URI}/saveConfig`)(payload).then(res => {
    return (res && res.body && res.body.payload) || {};
  });
}

// API for saving a complete rdConfig object
async function saveRDConfig(rdConfig) {
  return post(RD_CONFIGS_URI)(rdConfig).then(res => {
    return (res && res.body && res.body.payload) || {};
  });
}

async function fetchRDConfigsList({ sessionId, userId }) {
  return get(`${RD_CONFIGS_URI}/${sessionId}/${userId}`)().then(res => (res && res.body && res.body.payload) || {});
}

async function fetchDraftRDConfig({ sessionId, userId }) {
  return get(`${RD_CONFIG_URI}/${sessionId}/${userId}`)().then(res => (res && res.body && res.body.payload) || {});
}

async function deleteRDConfigApi({ id, draftFlag }) {
  return del(`${RD_CONFIGS_URI}/${id}`)({ id }).then(res => {
    const { payload } = (res && res.body) || {};
    if (payload) {
      return {
        ...payload,
        draftFlag
      };
    }
    return {};
  });
}

async function shareRDConfigApi(payload) {
  const {
    sessionId,
    researchDashboardConfig: { name }
  } = payload;
  return post(`${RD_URI}/updateConfigShare`)(payload).then(res => {
    if (!res || !res.body || !res.body.payload) {
      return {};
    }
    return {
      ...res.body.payload,
      sessionId,
      configName: name
    };
  });
}

async function applyProjectRDConfigApi(payload) {
  return post(`${RD_URI}/applyProjectConfig`)(payload).then(res => {
    return res?.body?.payload || {};
  });
}

async function applyRDConfigTypeApi(payload) {
  const { sessionId } = payload;
  return post(`${RD_URI}/updateConfigType`)(payload).then(res => {
    if (!res || !res.body || !res.body.payload) {
      return {};
    }
    return {
      ...res.body.payload,
      sessionId
    };
  });
}

async function toggleFavoriteParticipantApi({ sessionId, participantId, favorite }) {
  const url = `/a/api/session/${sessionId}/${participantId}/favoriteParticipant`;
  if (favorite) {
    return del(url)({}).then(res => {
      return (res && res.body && res.body.payload) || {};
    });
  }
  return post(url)({}).then(res => {
    return (res && res.body && res.body.payload) || {};
  });
}

async function terminateParticipantApi(payload) {
  return post('/a/api/terminateSurveyRunner')(payload).then(res => res.body.payload);
}

async function unTerminateParticipantApi(payload) {
  return post('/a/api/unTerminateSurveyRunner')(payload).then(res => res.body.payload);
}

async function checkFilterApi(payload) {
  const { sessionId, filterString } = payload;
  return post(`${CHECK_FILTER_URI}/${sessionId}`)({ filterString }).then(res => {
    return {
      sessionId,
      ...res.body.payload
    };
  });
}

export {
  fetchRDConfigsList,
  saveRDConfigApi,
  fetchDraftRDConfig,
  saveRDConfig,
  deleteRDConfigApi,
  shareRDConfigApi,
  applyProjectRDConfigApi,
  applyRDConfigTypeApi,
  toggleFavoriteParticipantApi,
  terminateParticipantApi,
  unTerminateParticipantApi,
  checkFilterApi
};
