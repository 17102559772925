import { get, post } from '../util/request';

const CUSTOM_REPORT_CONFIGS_API = '/a/api/customReportConfigs';

async function fetchCustomReportConfigs(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy || 'modifiedDate',
      sortOrder: apiOptions.sortOrder || 'desc'
    }) ||
    {};

  return get(CUSTOM_REPORT_CONFIGS_API)(params).then(res => (res.body && res.body.payload) || {});
}

async function fetchCustomReportConfig(payload) {
  return get(`${CUSTOM_REPORT_CONFIGS_API}/${payload.id}`)().then(res => (res.body && res.body.payload) || {});
}

async function saveCustomReportConfig(config) {
  if (!config.type) {
    config.type = 'customReportConfig';
  }
  return post(CUSTOM_REPORT_CONFIGS_API)(config).then(res => (res && res.body && res.body.payload) || {});
}

async function fetchCustomReportConsolidatedData(payload) {
  return post('/a/api/customReportConsolidatedData')(payload).then(res => (res.body && res.body.payload) || {});
}

export { fetchCustomReportConfigs, fetchCustomReportConfig, saveCustomReportConfig, fetchCustomReportConsolidatedData };
