import { handleActions } from 'redux-actions';
import {
  fetchCustomReportConfigListAction,
  fetchCustomReportConfigAction,
  saveCustomReportConfigActions,
  fetchCustomReportConsolidatedDataActions
} from '../actions/customReportConfigActions';

const initialState = {
  customReportConfigListRequested: false,
  customReportConfigRequested: false,
  customReportConfigSaveRequested: false,
  customReportConsolidatedDataRequested: false
};

const customReportConfigReducer = handleActions(
  {
    [fetchCustomReportConfigListAction.triggered](state) {
      return { ...state, customReportConfigListRequested: true };
    },
    [fetchCustomReportConfigListAction.succeeded](state, { payload }) {
      return {
        ...state,
        customReportConfigList: payload,
        customReportConfigListRequested: false
      };
    },
    [fetchCustomReportConfigListAction.failed](state) {
      return { ...state, customReportConfigListRequested: false };
    },

    [fetchCustomReportConfigAction.triggered](state) {
      return { ...state, customReportConfigRequested: true };
    },
    [fetchCustomReportConfigAction.succeeded](state, { payload }) {
      return {
        ...state,
        customReportConfig: payload,
        customReportConfigRequested: false
      };
    },
    [fetchCustomReportConfigAction.failed](state) {
      return { ...state, customReportConfigRequested: false };
    },

    [saveCustomReportConfigActions.triggered](state) {
      return {
        ...state,
        customReportConfigSaveRequested: true,
        customReportConfigSaved: null
      };
    },
    [saveCustomReportConfigActions.succeeded](state) {
      return {
        ...state,
        customReportConfigSaveRequested: false,
        customReportConfigSaved: true
      };
    },
    [saveCustomReportConfigActions.failed](state) {
      return {
        ...state,
        customReportConfigSaveRequested: false,
        customReportConfigSaved: null
      };
    },

    [fetchCustomReportConsolidatedDataActions.triggered](state) {
      return { ...state, customReportConsolidatedDataRequested: true };
    },
    [fetchCustomReportConsolidatedDataActions.succeeded](state, { payload }) {
      return {
        ...state,
        customReportConsolidatedData: payload,
        customReportConsolidatedDataRequested: false
      };
    },
    [fetchCustomReportConsolidatedDataActions.failed](state) {
      return { ...state, customReportConsolidatedDataRequested: false };
    }
  },

  initialState
);

export default customReportConfigReducer;
