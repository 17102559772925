import { connect } from 'react-redux';
import {
  getRDEnrollees,
  getRDSession,
  getRDSessionQuotaFilters
} from '../../../../store/redux/selectors/researchDashboardSelector';
import { VirtualFocusGroup } from './VirtualFocusGroup';
import { getFiltersAndParticipants } from '../../../../store/redux/selectors/filtersAndParticipantsSelector';
import { getVirtualFocusGroupData } from '../../../../store/redux/selectors/virtualFocusGroupSelector';

const mapStateToProps = (state, props) => {
  const { sessionId } = props;
  return {
    session: getRDSession(state, sessionId),
    enrolleesInfo: getRDEnrollees(state, sessionId),
    filteresAndParticipants: getFiltersAndParticipants(state, sessionId),
    quotaFilters: getRDSessionQuotaFilters(state, sessionId),
    getVfgData: joinerId => getVirtualFocusGroupData(state, sessionId, joinerId)
  };
};

export default connect(mapStateToProps)(VirtualFocusGroup);
