import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { Loader } from 'webapp-common';
import appConfig from '../../../appConfig';

import './ProfilePage.css';

export const ProfilePage = props => {
  const [sessionUser, setSessionUser] = useState({});

  useEffect(() => {
    props.fetchSessionUser();
  }, []);

  useEffect(() => {
    props.sessionUser && setSessionUser(props.sessionUser);
  }, [props.sessionUser]);

  function onNameChange(e) {
    setSessionUser({
      ...sessionUser,
      [e.target.name]: e.target.value
    });
  }

  function updateUser() {
    props.updateUser({
      payload: {
        firstName: sessionUser.firstName,
        middleName: sessionUser.middleName,
        lastName: sessionUser.lastName
      }
    });
  }

  function onChangePassword(e) {
    e.preventDefault();
    e.stopPropagation();
    const request = {
      email: sessionUser.email,
      clientID: sessionUser.clientId,
      connection: appConfig.auth0DB
    };
    props.changePassword(request);
  }

  const { email, firstName, lastName, requestInProgress } = sessionUser;
  const userInfoChanged =
    !!props.sessionUser && (firstName !== props.sessionUser.firstName || lastName !== props.sessionUser.lastName);

  return (
    <div className="profile-page pt-4 px-5">
      <label className="darker-label mb-4">{email}</label>
      <div className="flex-row mb-3">
        <label className="col-sm-1 ms-4">{intl.get('app.first.name')}</label>
        <Input
          className="col-sm-2"
          name="firstName"
          value={firstName || ''}
          placeholder={intl.get('app.first.name')}
          onChange={onNameChange}
        />
      </div>
      <div className="flex-row mb-3">
        <label className="col-sm-1 ms-4">{intl.get('app.last.name')}</label>
        <Input
          className="col-sm-2"
          name="lastName"
          value={lastName || ''}
          placeholder={intl.get('app.last.name')}
          onChange={onNameChange}
        />
      </div>
      <div>
        <button className="btn btn-primary" onClick={updateUser} disabled={!userInfoChanged}>
          {intl.get('app.button.update')}
        </button>
      </div>
      <div>
        <button className="btn btn-secondary mt-5" onClick={onChangePassword}>
          {intl.get('app.change.password')}
        </button>
      </div>
      {requestInProgress && <Loader spinner />}
    </div>
  );
};
