import React from 'react';
import intl from 'react-intl-universal';
import { Button, Navbar, Nav } from 'reactstrap';
import { InvokeModal, Logo } from 'webapp-common';

export const PreviewCustomUIModal = props => {
  const {
    logoUrl,
    headerColor,
    headerTextColor,
    buttonColor,
    buttonTextColor,
    secondaryButtonColor,
    secondaryButtonTextColor,
    footerBackground,
    footerTextColor,
    fontFamily,
    toggle
  } = props;

  return (
    <InvokeModal
      showModal
      className="preview-custom-ui-modal"
      modalTitle={intl.get('app.previewCustomUI')}
      toggle={toggle}
      cancelButtonText={intl.get('app.close')}
    >
      <div className="preview-custom-ui" style={{ fontFamily: fontFamily }}>
        <div className="page-header" style={{ background: headerColor, color: headerTextColor }}>
          <Navbar expand="md">
            <Logo logoURL={logoUrl} />
            <Nav navbar>
              <li>
                <a>{intl.get('app.homePage.projectsTab.title')}</a>
              </li>
              <li>
                <a>{intl.get('app.homePage.libraryTab.title')}</a>
              </li>
              <li>
                <a>{intl.get('app.homePage.reportsTab.title')}</a>
              </li>
            </Nav>
          </Navbar>
        </div>
        <div className="container py-4">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </p>
          <div className="align-center">
            <Button color="primary" className="me-5" style={{ background: buttonColor, color: buttonTextColor }}>
              {intl.get('app.primary')}
            </Button>
            <Button color="secondary" style={{ background: secondaryButtonColor, color: secondaryButtonTextColor }}>
              {intl.get('app.secondary')}
            </Button>
          </div>
        </div>
        <div className="footer" style={{ background: footerBackground, color: footerTextColor }}>
          <div className="p-2">Lorem ipsum</div>
          <div className="p-2 ms-auto">
            <a href="#">{intl.get('app.tos')}</a>
          </div>
          <div className="p-2">
            <a href="#">{intl.get('app.footer.priv')}</a>
          </div>
          <div className="p-2">
            <a href="#">{intl.get('app.footer.about')}</a>
          </div>
          <div className="p-2">
            <a href="#">{intl.get('app.footer.contact')}</a>
          </div>
        </div>
      </div>
    </InvokeModal>
  );
};
