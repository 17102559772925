import { handleActions, combineActions } from 'redux-actions';
import {
  fetchSessionAction,
  fetchSessionAndSurveyAction,
  addNewSessionAction,
  fetchSaveSessionAction,
  fetchAllSessionsAction,
  cloneSessionAction,
  updateDynataPanelConfigActions
} from '../actions/sessionActions';
import { importSurveyAction } from '../actions/surveyActions';
import { entityType } from '../../../util/jsUtil';
import { fetchValidationActions } from '../actions/fetchValidation';

const initialState = {
  sessions: {},
  requestInProgress: false,
  sessionValidations: {},
  allSessions: {},
  cloneRequestInProgress: false,
  importRequestInProgress: false
};

const sessionReducer = handleActions(
  {
    [combineActions(
      fetchSessionAction.triggered,
      fetchSessionAndSurveyAction.triggered,
      fetchSaveSessionAction.triggered,
      fetchAllSessionsAction.triggered,
      updateDynataPanelConfigActions.triggered
    )](state) {
      return { ...state, requestInProgress: true, sessionSaved: null };
    },

    [combineActions(fetchSessionAction.succeeded, fetchSessionAndSurveyAction.succeeded)](state, { payload }) {
      const { sessions } = state;
      sessions[payload.id] = payload;
      return {
        ...state,
        sessions,
        requestInProgress: false,
        sessionSaved: null
      };
    },

    [addNewSessionAction.succeeded](state, { payload }) {
      const { sessions } = state;
      sessions[payload.id] = payload;
      return {
        ...state,
        sessions
      };
    },

    [combineActions(fetchSaveSessionAction.succeeded, updateDynataPanelConfigActions.succeeded)](state, { payload }) {
      const { sessions } = state;
      sessions[payload.id] = payload;
      return {
        ...state,
        sessions,
        requestInProgress: false,
        sessionSaved: true
      };
    },

    [fetchAllSessionsAction.succeeded](state, { payload }) {
      return {
        ...state,
        allSessions: payload,
        requestInProgress: false
      };
    },

    [combineActions(
      fetchSessionAction.failed,
      fetchSessionAndSurveyAction.failed,
      fetchSaveSessionAction.failed,
      fetchAllSessionsAction.failed,
      updateDynataPanelConfigActions.failed
    )](state) {
      return { ...state, requestInProgress: false, sessionSaved: null };
    },

    [cloneSessionAction.triggered](state) {
      return { ...state, cloneRequestInProgress: true };
    },

    [cloneSessionAction.succeeded](state) {
      return { ...state, cloneRequestInProgress: false };
    },

    [cloneSessionAction.failed](state) {
      return { ...state, cloneRequestInProgress: false };
    },

    [importSurveyAction.triggered](state) {
      return { ...state, importRequestInProgress: true };
    },

    [importSurveyAction.succeeded](state, { payload }) {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [payload.id]: payload
        },
        importRequestInProgress: false
      };
    },

    [importSurveyAction.failed](state) {
      return { ...state, importRequestInProgress: false };
    },

    [fetchValidationActions.succeeded](state, { payload }) {
      const { id, entity } = payload;
      if (entity !== entityType.session) {
        return state;
      }
      return {
        ...state,
        sessionValidations: {
          ...state.sessionValidations,
          [id]: {
            validation: payload.validation,
            validationInprogress: false
          }
        }
      };
    },

    [fetchValidationActions.triggered](state, { payload }) {
      const { id, entity } = payload;
      if (entity !== entityType.session) {
        return state;
      }
      return {
        ...state,
        sessionValidations: {
          ...state.sessionValidations,
          [id]: {
            validationInprogress: true
          }
        }
      };
    }
  },
  initialState
);
export default sessionReducer;
