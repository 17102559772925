import React, { useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import moment from 'moment';
import { surveyUtil } from '../../../../util/surveyUtil';

import './Translations.css';

const CSV = 'csv';
const XLSX = 'xlsx';

export const Translations = props => {
  const { fetchSessions, projectId, sessions, uploadTranslationsSuccess, hasProjectManage } = props;

  const [selectedSurvey, setSelectedSurvey] = useState();
  const [selectedFormat, setSelectedFormat] = useState(XLSX);
  const [translationFile, setTranslationFile] = useState();

  const fileUploadField = useRef();

  // Fetch sessions/surveys for project
  useEffect(() => {
    if (projectId) {
      fetchSessions({
        projectId,
        screener: false,
        pageSize: -1
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (uploadTranslationsSuccess) {
      setTranslationFile(null);
    }
  }, [uploadTranslationsSuccess]);

  useEffect(() => {
    if (sessions && sessions.length > 0) {
      setSelectedSurvey(sessions[0].id);
    }
  }, [sessions]);

  function uploadTranslations() {
    props.uploadTranslations({
      file: translationFile,
      name: translationFile?.name || '',
      useType: selectedFormat,
      sessionId: getSession(selectedSurvey).id
    });
  }

  function hasMultipleSurveys() {
    return sessions && sessions.length > 1;
  }

  function getSurveyOptions() {
    if (sessions) {
      return sessions.map(s => (
        <option value={s.id} key={s.id}>
          {s.name}
        </option>
      ));
    }
  }

  function getSession(id) {
    return sessions?.find(s => s.id === id);
  }

  function handleSelectFileClick() {
    fileUploadField.current.click();
  }

  function downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function getDownloadTranslationTemplateCSVLinkParams() {
    const timeStamp = moment().format('MM-DD-YYYY');
    let filename = `${getSession(selectedSurvey).name}-TranslationTemplate-${timeStamp}.csv`;
    filename = surveyUtil.stripBadCharacters(filename);
    const url = `/a/api/downloadTranslationTemplateCSV/${selectedSurvey}/${filename}`;
    return {
      url,
      filename
    };
  }

  function getDownloadTranslationTemplateExcelLinkParams() {
    const timeStamp = moment().format('MM-DD-YYYY');
    let filename = `${getSession(selectedSurvey).name}-TranslationTemplate-${timeStamp}.xlsx`;
    filename = surveyUtil.stripBadCharacters(filename);
    const url = `/a/binapi/downloadTranslationTemplateExcel/${selectedSurvey}/${filename}`;
    return {
      url,
      filename
    };
  }

  function generateTemplate() {
    if (selectedFormat === CSV) {
      const translationTemplateCSVLinkParams = getDownloadTranslationTemplateCSVLinkParams();
      downloadURI(translationTemplateCSVLinkParams.url, translationTemplateCSVLinkParams.filename);
    } else {
      const translationTemplateExcelLinkParams = getDownloadTranslationTemplateExcelLinkParams();
      downloadURI(translationTemplateExcelLinkParams.url, translationTemplateExcelLinkParams.filename);
    }
  }

  return (
    <section className="translations">
      <div className="survey-select" style={{ visibility: hasMultipleSurveys() ? '' : 'hidden' }}>
        <span className="select-survey">{intl.get('app.selectSurvey')}:</span>
        <Input type="select" onChange={e => setSelectedSurvey(e.target.value)}>
          {getSurveyOptions()}
        </Input>
      </div>
      <div className="template-container">
        <div className="heading">{intl.get('app.translations.template')}</div>
        <div className="text">{intl.get('app.translations.templateGenerate')}</div>
        <div className="text">{intl.get('app.translations.templateFormat')}</div>
        <fieldset className="mb-3 ms-3">
          <div>
            <label>
              <Input
                type="radio"
                name="radio1"
                value={XLSX}
                checked={XLSX === selectedFormat}
                onClick={e => setSelectedFormat(e.target.value)}
              />{' '}
              {intl.get('app.translations.excel')}
            </label>
          </div>
          <div>
            <label>
              <Input
                type="radio"
                name="radio1"
                value={CSV}
                checked={CSV === selectedFormat}
                onClick={e => setSelectedFormat(e.target.value)}
              />{' '}
              {intl.get('app.translations.csv')}
            </label>
          </div>
        </fieldset>
        <Button onClick={generateTemplate}>{intl.get('app.translations.templateDownload')}</Button>
      </div>
      {hasProjectManage && (
        <div className="upload-translations-container">
          <div className="heading">{intl.get('app.translations.upload')}</div>
          <div className="text">{intl.get('app.translations.instruct')}</div>
          <div className="text">{intl.get('app.translations.dataFile')}</div>
          <input
            type="file"
            className="hidden"
            accept=".csv, .xlsx"
            ref={fileUploadField}
            onChange={e => setTranslationFile(e.target.files[0])}
          />
          <div className="file-select">
            <Button onClick={handleSelectFileClick}>
              <span className="fa fa-upload" /> {intl.get('app.selectFile')}
            </Button>
            {translationFile?.name && (
              <span className="file">
                <i className="fa fa-paperclip" /> {translationFile ? translationFile.name : ''}
              </span>
            )}
          </div>
          <Button color="primary" disabled={!translationFile} onClick={uploadTranslations}>
            {intl.get('app.translations.upload')}
          </Button>
        </div>
      )}
    </section>
  );
};
