import moment from 'moment';
import defaults from 'lodash/defaults';

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'hh:mm a';
const TIME_FORMAT_CAPS = 'hh:mm A';
const DATE_TIME_FORMAT = `${DATE_FORMAT} - ${TIME_FORMAT}`;
const DATE_TIME_FORMAT_CAPS = `${DATE_FORMAT} - ${TIME_FORMAT_CAPS}`;
const DATE_TIME_FORMAT_LIST = `${DATE_FORMAT} ${TIME_FORMAT_CAPS}`;

const localtime = {
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_CAPS,
  DATE_TIME_FORMAT_LIST,
  DATE_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_CAPS,
  defaultOptions: {
    offset: 0,
    stringDateFormat: DATE_TIME_FORMAT,
    dateFormat: DATE_TIME_FORMAT,
    invalid: '-'
  },

  getLocalTime: function (options) {
    options = defaults(options, this.defaultOptions);
    let parsedTime;
    if (options.dateString) {
      parsedTime = moment.utc(options.dateString, options.dateFormat);
    } else if (options.date) {
      parsedTime = moment.utc(options.date);
    }

    return parsedTime?.isValid()
      ? parsedTime.utcOffset(options.offset).format(options.stringDateFormat)
      : options.invalid;
  },

  stampToLocalString: epochValue => moment(epochValue).format(DATE_TIME_FORMAT),

  getOffset: () => new Date().getTimezoneOffset() * -1,

  getTimezoneName: () => Intl.DateTimeFormat().resolvedOptions().timeZone,

  getFormattedDate: date => {
    const m = moment(date);
    return m.isValid() ? m.format(DATE_TIME_FORMAT_LIST) : '';
  },

  getFormattedDateCap: date => {
    const m = moment(date);
    return m.isValid() ? m.format(DATE_TIME_FORMAT_CAPS) : '';
  },

  getFormattedTime: input => {
    let seconds, minutes, hours;
    if (input < 60) {
      seconds = input < 10 ? `0${input}` : input;
      return `00:${seconds}`;
    }
    seconds = input % 60;
    minutes = Math.floor(input / 60);
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    if (minutes < 60) {
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${minutes}:${seconds}`;
    }
    hours = Math.floor(minutes / 60);
    minutes %= 60;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}:${seconds}`;
  },

  getTimeFromString: (string, separator) => {
    const timeArray = string.split(separator).reverse();
    return timeArray.reduce((acc, t, i) => {
      return acc + +t * 60 ** i;
    }, 0);
  },

  // Convenience function for converting the array of objects returned by
  // Intl.DateTimeFormat().formatToParts() to a simple key/value map.
  getDateParts: (date, options) => {
    return Intl.DateTimeFormat([], options)
      .formatToParts(date)
      .reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
      }, {});
  }
};

export default localtime;
