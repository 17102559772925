import React from 'react';
import { PlusIcon, MinusIcon } from '../../icons/Icons';

import './AddRemoveIcons.css';

const AddRemoveIcons = ({ onAdd, onRemove, addDisabled = false, removeDisabled = false }) => {
  return (
    <div className="add-remove-icons">
      {onAdd && (
        <div className="add" disabled={addDisabled} onClick={addDisabled ? () => {} : onAdd}>
          <PlusIcon />
        </div>
      )}
      {onRemove && (
        <div className="remove" disabled={removeDisabled} onClick={removeDisabled ? () => {} : onRemove}>
          <MinusIcon />
        </div>
      )}
    </div>
  );
};

export default AddRemoveIcons;
