import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import intl from 'react-intl-universal';
import { get } from 'lodash';
import { getFiltersAndParticipants } from '../../../../store/redux/selectors/filtersAndParticipantsSelector';
import { FilterLabel } from '../../rdFilters/FilterLabel';

import './ParticipantMetadata.css';

function getInvokeId(participant) {
  return get(participant, 'metadata.dataStore.mappeduniqueid');
}

/*
 * Returns all participant meta data sorted by column order and with certain fields omitted.
 */
function getAllParticipantMetaData(columnOrder, participant) {
  if (!columnOrder || columnOrder.length === 0) {
    return {};
  }

  const participantMetadata = (participant.metadata && participant.metadata.dataStore) || {};

  // Build a list of keys to omit
  const invokeId = getInvokeId(participant);
  const omitKeys = new Set(['nickname', 'participantId']);
  for (let key in participantMetadata) {
    // find the keys that contain the userid so we can hide them
    if (participantMetadata[key] === invokeId) {
      omitKeys.add(key);
    }
  }

  // Sort metadata based on column order ignoring omitted fields
  const metadata = {};
  columnOrder.forEach(key => {
    if (participantMetadata[key] && !omitKeys.has(key)) {
      metadata[key] = participantMetadata[key];
    }
  });

  return metadata;
}

/*
 * Returns the list of all participant meta data after applying column config
 */
function getConfiguredMetadata(allMetadata, participantDataColumnConfig, columnOrder) {
  const columnPositions = participantDataColumnConfig.columnPositions || columnOrder;
  const metadata = {};
  columnPositions.forEach(key => {
    if (allMetadata[key] && !participantDataColumnConfig.hiddenColumns[key]) {
      metadata[key] = allMetadata[key];
    }
  });
  return metadata;
}

function getMetadataForDisplay(metadata, keyTransformMap) {
  const list = Object.keys(metadata).map(key => (
    <li key={key}>
      <span className="item-label">{keyTransformMap[key] || key}: </span>
      <span className="item-value">{metadata[key]}</span>
    </li>
  ));
  return list.length !== 0 ? <ul className="label-value-list metadata-box-list-column-3">{list}</ul> : null;
}

function getParticipantFilters(participant, participants, filters) {
  const pfilters = [];
  filters.forEach(f => {
    if (participants[f.name].some(p => p === participant.id)) {
      pfilters.push(<FilterLabel name={f.name} />);
    }
  });
  return pfilters.length > 0 ? pfilters : null;
}

/*
 * Main component function. Props:
 *
 * participant - same as enrollee
 * participantDataColumnConfig - from rdConfig
 * columnOrder - from enrolleesInfo
 * keyTransformMap - from enrolleesInfo
 * filters, filteredParticipants - from filtersAndParticipants
 */
export const ParticipantMetadata = props => {
  const { sessionId, participant, participantDataColumnConfig, columnOrder, keyTransformMap } = props;

  const filtersAndParticipants = useSelector(state => getFiltersAndParticipants(state, sessionId), shallowEqual);
  const { filteredParticipants, filters } = filtersAndParticipants;
  const allMetadata = getAllParticipantMetaData(columnOrder, participant);
  const allMetadataCount = Object.keys(allMetadata).length;
  const configuredMetadata = getConfiguredMetadata(allMetadata, participantDataColumnConfig, columnOrder);
  const configuredMetadataCount = Object.keys(configuredMetadata).length;

  // showAll is normally false by default. But if all and configured columns exist and are the same,
  // we default showAll to true. This lets 'show less' show no metadata.
  const defaultShowAll = allMetadataCount === configuredMetadataCount && allMetadataCount !== 0;
  const [showAll, setShowAll] = useState(defaultShowAll);
  const nonDefaultFilters = filters && filters.filter(f => f.type !== 'DEFAULT_FILTER');

  let metadataForDisplay;
  if (showAll) {
    metadataForDisplay = getMetadataForDisplay(allMetadata, keyTransformMap);
  } else {
    if (defaultShowAll) {
      metadataForDisplay = getMetadataForDisplay({}, keyTransformMap);
    } else {
      metadataForDisplay = getMetadataForDisplay(configuredMetadata, keyTransformMap);
    }
  }

  const invokeId = getInvokeId(participant);
  const participantFilters =
    nonDefaultFilters &&
    nonDefaultFilters.length > 0 &&
    getParticipantFilters(participant, filteredParticipants, nonDefaultFilters);

  return (
    <section className="participant-metadata">
      <div>
        <span className="fw-600">{intl.get('app.participantData')}</span>
        <span className="ms-5" style={{ display: 'inline-flex', flexWrap: 'wrap', width: '90%' }}>
          {participantFilters}
        </span>
      </div>
      <div className="participant-metadata-container">
        <div>
          <div>
            <span>{intl.get('app.complete')}:</span>
            <span className="fw-600">{`${participant.percentSurveyComplete}%`}</span>
          </div>
          {invokeId && (
            <div>
              <span className="nowrap">{intl.get('app.internalID')}:</span>
              <span className="fw-600">{invokeId}</span>
            </div>
          )}
        </div>
        {metadataForDisplay}
        {!showAll && allMetadataCount !== 0 && (
          <div className="show-all-link" onClick={() => setShowAll(true)}>
            {intl.get('app.showAll')}
          </div>
        )}
        {showAll && (
          <div className="show-all-link" onClick={() => setShowAll(false)}>
            {intl.get('app.showLess')}
          </div>
        )}
      </div>
    </section>
  );
};
