import { cloneDeep, get } from 'lodash';
import { STIM_TYPE } from '../../../util/joinerUtil';

export const TOTAL = 'Total';
export const RANGE_MAX = 100;
export const TOTAL_FILTER = { name: TOTAL, checked: 'true' };
export const SHOW_ACTIONS = 'showActions';
export const AUTO_SCALE_Y = 'autoScaleY';
export const TRENDLINES = 'trendlines';
export const ZOOM_TIME = 'zoomTime';
export const OVERLAY_DIAL = 'overlayDial';
export const OVERLAY_EMOTION = 'overlayEmotions';
export const SESSION_CHART_WIDTH = 600;
export const LABEL_STYLE = { display: 'inline-flex', alignItems: 'baseline' };
export const MIN_ZOOM_TIME = 8;
export const VIDEO_SETUP = {
  repeat: true,
  autostart: true,
  file: '',
  withCredentials: true,
  controls: true,
  height: 560,
  width: 960
};

export const workerStates = {
  IDLE: 'IDLE',
  RUNNING: 'RUNNING'
};

export const EMOTION_CHART_COLORS = {
  HAPPY: 'hsl(109, 43%, 59%)',
  SAD: 'hsl(208, 59%, 59%)',
  ANGRY: 'hsl(358, 84%, 65%)',
  CONFUSED: 'hsl(29, 91%, 66%)',
  DISGUSTED: 'hsl(288, 29%, 54%)',
  SURPRISED: 'hsl(12, 55%, 58%)',
  FEAR: 'hsl(324, 52%, 67%)',
  CALM: 'hsl(0, 0%, 45%)'
};

export const EMOTION_CHART_COLORS_OVERLAY = {
  HAPPY: 'hsl(109, 43%, 59%)',
  SAD: 'hsl(208, 59%, 59%)',
  ANGRY: 'hsl(358, 84%, 65%)',
  CONFUSED: 'hsl(29, 91%, 66%)',
  DISGUSTED: 'hsl(288, 29%, 54%)',
  SURPRISED: 'hsl(12, 55%, 58%)',
  FEAR: 'hsl(324, 52%, 67%)',
  CALM: 'hsl(0, 0%, 45%)'
};

const availableEmotions = new Map([
  ['HAPPY', true],
  ['SAD', true],
  ['ANGRY', true],
  ['CONFUSED', true],
  ['DISGUSTED', true],
  ['SURPRISED', true],
  ['FEAR', true],
  ['CALM', true]
]);

export function getJoinerSelectList(joiners, configType) {
  const eJoiners = [];
  joiners.forEach(joiner => {
    const { stim, conceptRotation } = joiner;
    let ctype = get(stim, `options.${configType}`);
    if (stim && stim.type === STIM_TYPE.video && ctype) {
      const temp = cloneDeep(joiner);
      temp.questionNumber = joiner.questionNum;
      temp.questionTitle = joiner.researchPrompt;
      eJoiners.push(temp);
    } else if (conceptRotation?.concepts) {
      conceptRotation.concepts.forEach((concept, index) => {
        const { startJoiner } = concept;
        ctype = get(startJoiner.stim, `options.${configType}`);
        if (startJoiner.stim && startJoiner.stim.type === STIM_TYPE.video && ctype) {
          const temp = cloneDeep(startJoiner);
          temp.questionNumber = `${joiner.questionNum}.${index + 1}`;
          temp.questionTitle = concept.title;
          eJoiners.push(temp);
        }
      });
    }
  });
  return eJoiners;
}

export const getStartJoinerFromJoiners = (joiners, selectedJoinerId) => {
  for (let index = 0; index < joiners.length; index++) {
    const joiner = joiners[index];
    if (joiner.conceptRotation?.concepts) {
      const concept = joiner.conceptRotation.concepts.find(c => selectedJoinerId === c.startJoiner.id);
      if (concept) {
        return concept.startJoiner;
      }
    }
  }
  return null;
};

/**
 * The adHocJoinerIds from dialConfig.actionButtons may be direct references to joiners,
 * or they may reference joiners in concepts by parentId. This function looks for both
 * cases and returns a map of the actual adHocJoinerIds.
 */
export function getAdHocJoinerIdMap(joiner, joiners) {
  const adHocJoinerIdMap = {};
  const { actionButtons = [] } = joiner?.stim.options.dialConfig || {};
  actionButtons.forEach(ab => {
    if (ab.adHocJoinerId && ab.label) {
      let key = ab.label;
      let adHocJoiner = joiners.find(j => j.id === ab.adHocJoinerId);
      if (!adHocJoiner && joiner.conceptId) {
        const crJoiner = joiners.find(j => j.id === joiner.conceptRotationId);
        const concept = crJoiner.conceptRotation.concepts.find(c => c.id === joiner.conceptId);
        adHocJoiner = concept.joiners.find(j => j.parentId === ab.adHocJoinerId);
        key += `-${concept.id}`;
      }
      if (adHocJoiner) {
        adHocJoinerIdMap[key] = adHocJoiner.id;
      }
    }
  });
  return adHocJoinerIdMap;
}

export const getFilterList = filters => {
  const filterList = filters.filter(f => f.type !== 'DEFAULT_FILTER');
  return [TOTAL_FILTER, ...filterList];
};

export const getEmotions = () => {
  const emotions = [];
  for (const [emotion, selected] of availableEmotions) {
    if (selected) {
      emotions.push(emotion);
    }
  }
  return emotions;
};

const getEmotionColor = (emotion, colors, shade) => {
  let color = colors[emotion];
  let codes = color.match(/[0-9]+/g);
  codes[2] = +codes[2] + shade * 10;
  color = `hsl(${codes[0]}, ${codes[1]}%, ${codes[2]}%)`;
  return color;
};

export const isFilterSelected = (filter, selectedFilters) => selectedFilters.some(f => f.name === filter.name);

export const isEmotionChecked = (emotions, target) => emotions.some(emotion => emotion === target);

const isTotalSelected = filters => filters.some(f => f.name === TOTAL);

export const getColorMap = (selectedFilters, overlay) => {
  const colors = overlay ? EMOTION_CHART_COLORS_OVERLAY : EMOTION_CHART_COLORS;
  const colorMap = {
    default: colors
  };
  const hasTotal = isTotalSelected(selectedFilters);
  selectedFilters.forEach((filter, i) => {
    const { name } = filter;
    const codes = [0, 1, -1, 2, -2];
    let code = 0;
    colorMap[name] = {};
    Object.keys(colors).forEach(emotion => {
      if (!hasTotal) {
        code = codes[i + 1];
      } else {
        if (name !== TOTAL) {
          code = codes[i];
        }
      }
      colorMap[name][emotion] = getEmotionColor(emotion, colors, code);
    });
  });
  return colorMap;
};

export const getReportTableSortingConfig = params => {
  const { pageNum = 1, pageSize = 5, sortBy = 'createDate', sortOrder = 'desc' } = params;
  return {
    reportTableSortingConfig: {
      pageNumber: pageNum,
      pageSize,
      sortBy,
      sortOrder,
      type: 'tableSortingConfig'
    }
  };
};
