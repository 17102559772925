import React from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import appConfig from '../../../appConfig';
import { NavTab } from 'webapp-common';
import { PanelConfigs } from '../panelConfigs/PanelConfigs';
import { ProdegeConfig } from '../prodegeConfig/ProdegeConfig';

import prodege_src from '../../../images/prodege.png';

const PANELS_TAB = 'panels';
const PRODEGE_TAB = 'prodege';

export const PanelsPage = () => {
  const { subtab: activeTab = PANELS_TAB } = useParams();

  function getUrl(tab) {
    return `${appConfig.panelsPagePath}/${tab}`;
  }

  return (
    <section className="app-body">
      <section className="left-sub-nav">
        <NavTab
          icon="fa-user-cog"
          iconStyle="light"
          label={intl.get('app.panels')}
          active={activeTab === PANELS_TAB}
          to={getUrl(PANELS_TAB)}
        />
        <NavTab
          img={<img src={prodege_src} alt={intl.get('app.prodege')} />}
          label={intl.get('app.prodege')}
          active={activeTab === PRODEGE_TAB}
          to={getUrl(PRODEGE_TAB)}
        />
      </section>
      <div className="body-container l3-bg">
        {activeTab === PANELS_TAB && <PanelConfigs />}
        {activeTab === PRODEGE_TAB && <ProdegeConfig />}
      </div>
    </section>
  );
};
