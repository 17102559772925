import { takeLatest, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  fetchSessionAction,
  fetchSessionAndSurveyAction,
  fetchSaveSessionAction,
  fetchAllSessionsAction,
  cloneSessionAction,
  updateDynataPanelConfigActions
} from '../actions/sessionActions';
import { fetchSurveyActions } from '../actions/surveyActions';
import {
  fetchSession,
  saveSession,
  fetchSessions,
  cloneSession,
  updateDynataPanelConfig
} from '../../../api/sessionsApi';
import { requestValidation } from './validationSaga';
import { entityType } from '../../../util/jsUtil';

function* handleSessionSaved({ payload: session }) {
  toast.success({ text: intl.get('app.session.saved', { name: session.name }) });
  yield put(
    fetchAllSessionsAction.request({
      projectId: session.projectId,
      screener: session.screener,
      pageSize: -1
    })
  );
}

function* handleSessionCloned({ payload: session }) {
  toast.success({ text: intl.get('app.session.cloned') });
  yield put(
    fetchAllSessionsAction.request({
      projectId: session.projectId,
      screener: session.screener,
      includeResponseRates: true
    })
  );
}

function* fetchSurveyForSession({ payload: session }) {
  if (session.surveyId) {
    yield put(fetchSurveyActions.request({ surveyId: session.surveyId }));
  }
}

function* fetchSessionSaga() {
  const saga = createFetchSaga(fetchSessionAction, fetchSession);
  yield* saga();
}

// Saga for fetching the session, then the survey for the session.
function* fetchSessionAndSurveySaga() {
  const saga = createFetchSaga(fetchSessionAndSurveyAction, fetchSession);
  yield* saga();
  yield takeLatest(fetchSessionAndSurveyAction.succeeded, fetchSurveyForSession);
}

function* fetchAllSessionsSaga() {
  const saga = createFetchSaga(fetchAllSessionsAction, fetchSessions);
  yield* saga();
}

function* requestSessionValidation({ payload: session }) {
  if (!session.screener) {
    yield* requestValidation({ id: session.id, entity: entityType.session });
  }
}

function* requestProjectValidation({ payload }) {
  yield* requestValidation({ id: payload.projectId, entity: entityType.project });
}

function* saveSessionSaga() {
  const saga = createFetchSaga(fetchSaveSessionAction, saveSession);
  yield* saga();
  yield takeLatest(fetchSaveSessionAction.succeeded, handleSessionSaved);
  yield takeLatest(fetchSaveSessionAction.succeeded, requestSessionValidation);
  yield takeLatest(fetchSaveSessionAction.succeeded, requestProjectValidation);
}

function* cloneSessionSaga() {
  const saga = createFetchSaga(cloneSessionAction, cloneSession);
  yield* saga();
  yield takeLatest(cloneSessionAction.succeeded, handleSessionCloned);
}

function* updateDynataPanelConfigSaga() {
  const saga = createFetchSaga(updateDynataPanelConfigActions, updateDynataPanelConfig);
  yield* saga();
  yield takeLatest(updateDynataPanelConfigActions.succeeded, () => {
    toast.success({ text: intl.get('app.dynataProjectUpdated') });
  });
}

export {
  fetchSessionSaga,
  fetchSessionAndSurveySaga,
  saveSessionSaga,
  fetchAllSessionsSaga,
  cloneSessionSaga,
  updateDynataPanelConfigSaga
};
