import React from 'react';
import intl from 'react-intl-universal';
import './CollapsedQuestionJoiner.css';
import plusCircle from '../../../../images/plus-circle.png';

export const AddFirstQuestion = ({ handleAddToSurveyClick }) => {
  return (
    <div className="collapsed">
      <div />
      <div className="collapsedJoinerContainer">
        <div className="collapsedJoiner" onClick={e => handleAddToSurveyClick(e, 0, undefined, '1.')}>
          <img
            src={plusCircle}
            className="clickable"
            style={{ height: 'unset', width: 'unset' }}
            title={intl.get('app.addFirstQuestion')}
            alt=""
          />
          <div>{intl.get('app.addFirstQuestion')}</div>
        </div>
      </div>
    </div>
  );
};
