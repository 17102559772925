import { connect } from 'react-redux';
import { fetchAuthTokenActions, validateAuthFailed } from '../../../store/redux/actions/authActions';
import appConfig from '../../../appConfig';
import { CallbackPage } from './CallbackPage';

const mapDispatchToProps = (dispatch, props) => ({
  setToken: authToken => dispatch(fetchAuthTokenActions.succeeded({ authToken })),
  redirectToLogin: error => {
    if (error) {
      dispatch(validateAuthFailed(error));
    }
    props.history.push(appConfig.loginPage);
  }
});

export default connect(null, mapDispatchToProps)(CallbackPage);
