import { connect } from 'react-redux';
import {
  getRDChats,
  getRDConfig,
  getRDEnrollees,
  getRDFilteredEnrollees,
  getUserId
} from '../../../store/redux/selectors/researchDashboardSelector';
import { RDParticipantMgmt } from './RDParticipantMgmt';

const mapStateToProps = (state, props) => {
  const { sessionId } = props;
  const rdChats = getRDChats(state, sessionId);
  const rdConfig = getRDConfig(state, sessionId);
  const enrolleesInfo = getRDEnrollees(state, sessionId);
  const filteredEnrollees = getRDFilteredEnrollees(state, sessionId);
  const userId = getUserId(state);
  return {
    enrolleesInfo,
    filteredEnrollees,
    rdChats,
    userId,
    rdConfig
  };
};

export default connect(mapStateToProps)(RDParticipantMgmt);
