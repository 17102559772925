import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { FormGroup, Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

import './EditClientModal.css';

export const EditClientModal = props => {
  const { client, toggle, save, archive, tenant } = props;
  const [name, setName] = useState(client.clientName);
  return (
    <InvokeModal
      className="edit-client-modal"
      showModal={true}
      toggle={toggle}
      modalTitle={intl.get('app.editClient', { clientDomain: client.clientDomain })}
      deleteButtonText={intl.get('app.archive')}
      delete={archive}
      enableDelete={client.clientID !== tenant.clientID}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      save={() => save(name)}
      enableSave={name !== client.clientName}
    >
      <FormGroup row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <label className="col-sm-4">{intl.get('app.name')}</label>
        <Input type="text" value={name} onChange={e => setName(e.target.value)} />
      </FormGroup>
      <FormGroup row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <label className="col-sm-4">{intl.get('app.domain')}</label>
        <Input type="text" value={client.clientDomain} disabled />
      </FormGroup>
    </InvokeModal>
  );
};
