import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { cloneDeep, get } from 'lodash';
import {
  getRDConfig,
  getRDSessionQuotaFilters,
  getRDQuestionJoiners,
  getRDEnrollees,
  getRDAggregateData,
  getRDVerbatimData,
  getClusterAnalysis
} from '../../../store/redux/selectors/researchDashboardSelector';
import { filterUtil } from '../../../util/filterUtil';
import { saveRDConfig } from '../../../util/researchDashboardUtil';
import { RESPONSE_SET_TYPE } from '../../../util/joinerUtil';
import { SelectDataPointsModal } from './selectDataPoints/SelectDataPointsModal';
import { FiltersMenu } from './FiltersMenu';
import { FilterLabel } from './FilterLabel';

import './RDSelectedFiltersBar.css';

export const RDSelectedFiltersBar = props => {
  const {
    sessionId,
    selectedQuestion,
    project,
    total = 0,
    toggleEditFiltersModal,
    showEnrollees,
    filteredEnrolleesCount
  } = props;

  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);
  const quotaFilters = useSelector(state => getRDSessionQuotaFilters(state, sessionId), shallowEqual);
  const questionJoiners = useSelector(state => getRDQuestionJoiners(state, sessionId), shallowEqual);
  const enrolleesInfo = useSelector(state => getRDEnrollees(state, sessionId), shallowEqual);

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showSelectDataPointsModal, setShowSelectDataPointsModal] = useState(false);

  const filterListConfig = get(rdConfig, 'configs.filterListConfig', {});
  const questionsConfigMap = get(rdConfig, 'configs.questionsConfig.questionsConfigMap', {});
  const questionDefId = get(selectedQuestion, 'joiner.def.id');
  const aggregateData = useSelector(state => getRDAggregateData(state, sessionId, questionDefId), shallowEqual);
  const verbatimData = useSelector(state => getRDVerbatimData(state, sessionId, questionDefId), shallowEqual);
  const clusterAnalysisData = useSelector(state => getClusterAnalysis(state, sessionId, questionDefId), shallowEqual);
  const { clusterAnalyses } = clusterAnalysisData;
  const clusterTotal =
    clusterAnalyses &&
    clusterAnalyses.reduce((acc, ca) => {
      acc += ca.answers.length;
      return acc;
    }, 0);

  let filtered = 0;
  if (!showEnrollees) {
    filtered =
      selectedQuestion.type === RESPONSE_SET_TYPE.open
        ? get(verbatimData, 'pagedAnswers.totalElements', 0) || clusterTotal
        : get(aggregateData, 'participantCount', 0);
  }

  const { selectedKeyWords = [], selectedSentiments = [] } = questionsConfigMap[questionDefId] || {};

  function toggleFilterMenu() {
    setShowFilterMenu(!showFilterMenu);
  }

  function toggleSelectDataPointsModal() {
    setShowFilterMenu(false);
    setShowSelectDataPointsModal(!showSelectDataPointsModal);
  }

  function toggleFilterModal() {
    setShowFilterMenu(false);
    toggleEditFiltersModal();
  }

  function selectFilter(filter) {
    if (
      (filter.type === 'QUOTA_FILTER' && filterListConfig.selected === filter.id) ||
      (filter.type === 'GENERAL_FILTER' && filterListConfig.selected === filter.name)
    ) {
      // no change
      return;
    }
    const rdConfigClone = cloneDeep(rdConfig);
    rdConfigClone.configs.filterListConfig.selected = filter.type === 'QUOTA_FILTER' ? filter.id : filter.name;
    rdConfigClone.configs.filterListConfig.selectedType = filter.type;
    saveRDConfig(rdConfigClone);
    setShowFilterMenu(false);
  }

  function saveFilter(expression) {
    const quickFilter = {
      expression,
      name: 'Quick Filter',
      type: 'GENERAL_FILTER'
    };

    // Update the filterListConfig with the new quickFilter.
    // 'quickFilter' is the legacy name from firefly. It can be removed at some point.
    const { filterListConfig } = rdConfig.configs;
    const index = filterListConfig.filters.findIndex(f => f.name === quickFilter.name || f.name === 'quickFilter');
    if (index === -1) {
      filterListConfig.filters.push(quickFilter);
    } else {
      filterListConfig.filters[index] = quickFilter;
    }
    filterListConfig.selected = quickFilter.name;
    filterListConfig.selectedType = quickFilter.type;

    // Save
    saveRDConfig(rdConfig);
    toggleSelectDataPointsModal();
  }

  function removeSelectedFilter() {
    if (filterListConfig.selected) {
      const rdConfigClone = cloneDeep(rdConfig);
      rdConfigClone.configs.filterListConfig.selected = null;
      rdConfigClone.configs.filterListConfig.selectedType = null;
      saveRDConfig(rdConfigClone);
    }
  }

  function getSelectedFilter() {
    if (!filterListConfig) {
      return null;
    }
    const { selected, selectedType } = filterListConfig;
    return filterUtil.getSelectedFilter(selected, selectedType, filterListConfig.filters, quotaFilters);
  }

  function handleRemoveKeywordFilter(kw) {
    const rdConfigClone = cloneDeep(rdConfig);
    const questionConfig = rdConfigClone.configs.questionsConfig.questionsConfigMap[questionDefId];
    const index = questionConfig.selectedKeyWords.indexOf(kw);
    questionConfig.selectedKeyWords.splice(index, 1);
    saveRDConfig(rdConfigClone);
  }

  function handleRemoveSentimentFilter(sentiment) {
    const rdConfigClone = cloneDeep(rdConfig);
    const questionsConfigMap = rdConfigClone.configs.questionsConfig.questionsConfigMap[questionDefId];
    const { selectedSentiments } = questionsConfigMap;
    const index = selectedSentiments.indexOf(sentiment);
    selectedSentiments.splice(index, 1);
    saveRDConfig(rdConfigClone);
  }

  function getSelectedFilterName(filter) {
    const isQuickFilter = filter.name === 'Quick Filter';
    if (isQuickFilter) {
      return filterUtil.renderFilterExpression(filter, questionJoiners, enrolleesInfo.keyTransformMap);
    }
    return filter.name;
  }

  const selectedFilter = getSelectedFilter();
  const mouseMove = e => {
    if (!e.target.closest('.filters-selection-menu') && !e.target.closest('.rd-filters-bar')) {
      e.stopPropagation();
      e.preventDefault();
      setShowFilterMenu(false);
    }
  };

  const getFilteredResult = () =>
    showEnrollees
      ? intl.get('app.comparativeView.filteredResult', { filteredEnrolleesCount })
      : intl.get('app.filteredResult', { filtered, total });

  return (
    <div className="data-filters">
      <div className="filter-bar">
        <i className="fas fa-filter" onMouseOver={toggleFilterMenu} />
        <div className="filter-labels">
          {selectedFilter && <FilterLabel name={getSelectedFilterName(selectedFilter)} close={removeSelectedFilter} />}
          {selectedKeyWords &&
            selectedKeyWords.map(kw => (
              <FilterLabel key={`kw_${kw}`} name={kw} close={() => handleRemoveKeywordFilter(kw)} />
            ))}
          {selectedSentiments &&
            selectedSentiments.map(sentiment => (
              <FilterLabel
                key={`sentiment_${sentiment}`}
                name={intl.get(`app.${sentiment.toLowerCase()}`)}
                close={() => handleRemoveSentimentFilter(sentiment)}
              />
            ))}
        </div>
        <span className="filtered-result">{getFilteredResult()}</span>
      </div>
      {showFilterMenu && (
        <FiltersMenu
          rdConfig={rdConfig}
          quotaFilters={quotaFilters}
          selectFilter={selectFilter}
          toggleSelectDataPointsModal={toggleSelectDataPointsModal}
          toggleEditFiltersModal={toggleFilterModal}
          handleMouseMove={mouseMove}
        />
      )}
      {showSelectDataPointsModal && (
        <SelectDataPointsModal
          enrolleesInfo={enrolleesInfo}
          project={project}
          questionJoiners={questionJoiners}
          rdConfig={rdConfig}
          selectedTab="surveyContent"
          selectedJoiner={selectedQuestion.joiner}
          toggle={toggleSelectDataPointsModal}
          onSave={saveFilter}
        />
      )}
    </div>
  );
};
