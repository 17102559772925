import React, { useEffect, useMemo, useRef } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { AutoComplete, Select } from 'antd';
import Quill from 'quill';
import {
  STIM_TYPE,
  getQuestionPrompt,
  getQuestionRichContents,
  getTextStimContents,
  getTextStimRichContents
} from '../../../../util/joinerUtil';
import MediaPreview from '../../../../components/core/mediaPreview/MediaPreview';

const Option = Select.Option;

export const toolbarOptions = [
  [{ font: [] }], // fonts
  [{ size: ['10px', false, '20px', '40px'] }], // custom dropdown
  ['bold', 'italic', 'underline'], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ align: [] }]
];

const CommonJoinerEditFields = props => {
  const {
    joiner,
    conceptVisibility,
    joinerInfo,
    groupNames,
    language,
    viewLanguage,
    updateHandlers,
    readOnly,
    isScreener
  } = props;

  const isTextStim = joinerInfo.type === STIM_TYPE.text;
  const isThirdPartyStim = joinerInfo.type === STIM_TYPE.thirdparty;
  const { isVirtualFocusGroup } = joinerInfo;
  const isMediaStim =
    joinerInfo.type === STIM_TYPE.image ||
    joinerInfo.type === STIM_TYPE.video ||
    joinerInfo.type === STIM_TYPE.webcontent;
  const questionLabel =
    (isTextStim && intl.get('app.text')) ||
    (isThirdPartyStim && intl.get('app.videoUrl')) ||
    (isMediaStim && intl.get('app.title.media')) ||
    (isVirtualFocusGroup && ' ') ||
    intl.get('app.questionText');
  const conceptOptions =
    conceptVisibility.length > 0 ? conceptVisibility.map(c => <Option key={c.title}>{c.title}</Option>) : [];
  const media = joiner.stim ? joiner.stim.media : {};

  const quill = useRef();
  const textUpdateConfig = useRef({});

  useEffect(() => {
    if (!isThirdPartyStim && !isMediaStim && !isVirtualFocusGroup) {
      quill.current = new Quill('#quill-container', {
        modules: {
          toolbar: toolbarOptions
        },
        theme: 'snow',
        readOnly
      });

      quill.current.container && quill.current.on('text-change', updateText);
    }
  }, []);

  useEffect(() => {
    if (language && viewLanguage && quill.current) {
      const { richContents, text } = getJoinerText();
      richContents ? quill.current.setContents(richContents) : quill.current.setText(text);
      textUpdateConfig.current.language = language;
      textUpdateConfig.current.viewLanguage = viewLanguage;
    }
  }, [language, viewLanguage]);

  useEffect(() => {
    if (joiner.importedTimeStamp) {
      // Joiner has been imported. Set the quill text.
      quill.current.setText(getQuestionPrompt(joiner, language, viewLanguage));
    }
  }, [joiner.importedTimeStamp]);

  const groupNameOptions = useMemo(() => groupNames.map(name => ({ value: name })), [groupNames]);

  function getJoinerText() {
    if (isTextStim) {
      return {
        richContents: getTextStimRichContents(joiner.stim, language, viewLanguage),
        text: getTextStimContents(joiner.stim, language, viewLanguage)
      };
    }
    const richContents = getQuestionRichContents(joiner, language, viewLanguage);
    const hasContent = richContents?.some?.(c => c.insert.trim());
    if (hasContent) {
      return { richContents, text: '' };
    }
    return { richContents: null, text: getQuestionPrompt(joiner, language, viewLanguage) };
  }

  function updateText(_delta, _oldDelta, source) {
    if (source === 'user') {
      isTextStim
        ? updateHandlers.updateTextStimText(
            quill.current.getText(),
            quill.current.getContents().ops,
            textUpdateConfig.current.language,
            textUpdateConfig.current.viewLanguage
          )
        : updateHandlers.updateQuestionText(
            quill.current.getText(),
            quill.current.getContents().ops,
            textUpdateConfig.current.language,
            textUpdateConfig.current.viewLanguage
          );
    }
  }

  return (
    <div className="joiner-editor-common-fields">
      <div>
        <div className="label editor-left-column">{intl.get('app.title')}</div>
        <div className="editor-right-column">
          <Input
            className="question-title-field"
            value={joiner.researchPrompt}
            disabled={readOnly || joinerInfo.isEmailCollector}
            onChange={updateHandlers.updateQuestionTitle}
          />
          {conceptVisibility.length > 0 ? (
            <>
              <div className="label me-4">{intl.get('app.concepts')}</div>
              <Select
                className="select-concept"
                mode="multiple"
                placeholder={intl.get('app.placeholder.pleaseSelect')}
                value={conceptVisibility.filter(c => !c.hidden).map(c => c.title)}
                disabled={readOnly}
                onChange={concept => updateHandlers.updateConceptVisibility(concept)}
              >
                {conceptOptions}
              </Select>
            </>
          ) : (
            !isScreener &&
            !isVirtualFocusGroup && (
              <>
                <div className="label">{intl.get('app.questionGroup')}</div>
                <AutoComplete
                  className="question-group-field"
                  options={groupNameOptions}
                  value={joiner.groupName || ''}
                  disabled={readOnly}
                  onChange={updateHandlers.updateGroupName}
                  filterOption
                />
              </>
            )
          )}
        </div>
      </div>
      <div>
        <div className="label editor-left-column">{questionLabel}</div>
        <div className="editor-right-column">
          {!isThirdPartyStim && !isMediaStim && !isVirtualFocusGroup && (
            <div className="editor-quill-container">
              <div className="question-text-field" id="quill-container" />
            </div>
          )}
          {isMediaStim && (
            <div>
              <MediaPreview
                mediaType={media?.type}
                media={media}
                text={intl.get('app.clickToAddOrEdit')}
                onClick={props.toggleSelectMediaModal}
                readOnly={readOnly}
              />
            </div>
          )}
          {isThirdPartyStim && (
            <Input
              value={joiner.stim.media?.accessUrl || ''}
              disabled={readOnly}
              onChange={updateHandlers.updateStimMediaAccessUrl}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonJoinerEditFields;
