import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Input, Row } from 'reactstrap';
import Quill from 'quill';
import { getTextStimContents, getTextStimRichContents, getStimCaption } from '../../../../../util/joinerUtil';
import { InvokeModal } from 'webapp-common';
import MediaPreview from '../../../../../components/core/mediaPreview/MediaPreview';
import TimeSelector from '../../../../../components/core/timePicker/TimeSelector';
import { toolbarOptions } from '../../joinerEditor/CommonJoinerEditFields';

import './EditMediaModal.css';

// Currently only used by ConceptRotationMedia
const EditMediaModal = props => {
  const {
    readOnly,
    selectedJoiner,
    selectMediaOption,
    selectTextOption,
    handleTimingChange,
    toggleEditMediaModal,
    modalTitle,
    stimJoinerChanged,
    updateConceptRotationStimJoiner,
    handleMediaChange,
    handleResearchPromptChange,
    handleMediaTextChange,
    handleCaptionChange,
    stimType,
    language,
    viewLanguage,
    toggleSelectMediaModal
  } = props;

  const selectMediaOptionText = intl.get('app.concept.selectMedia');
  const selectTextOptionText = intl.get('app.concept.textOnly');
  const getOption = () => (selectMediaOption ? selectMediaOptionText : selectTextOptionText);
  const updateConfiguredTime = time => {
    selectedJoiner.timingOptions.configuredTime = time.minutes() * 60 + time.seconds();
    handleTimingChange(selectedJoiner);
  };

  const closeModal = e => {
    if (e.target.tagName === 'LI' || e.target.parentElement.className === 'ant-time-picker-panel-addon') {
      return;
    }
    toggleEditMediaModal('', selectedJoiner);
  };

  useEffect(() => {
    if (selectTextOption) {
      setTimeout(() => {
        const contents = getTextStimContents(selectedJoiner.stim, language, viewLanguage);
        const richContents = getTextStimRichContents(selectedJoiner.stim, language, viewLanguage);

        const quill = new Quill('#cr-media-quill-container', {
          modules: {
            toolbar: toolbarOptions
          },
          theme: 'snow',
          readOnly
        });

        (quill.container && richContents && richContents.length && quill.setContents(richContents)) ||
          quill.setText(contents);

        quill.container &&
          quill.on('text-change', function (delta, oldDelta, source) {
            if (source === 'user') {
              handleMediaTextChange(quill.getText(), quill.getContents().ops);
            }
          });
      }, 0);
    }
  }, [selectTextOption]);

  return (
    <InvokeModal
      showModal
      className="edit-media-modal"
      toggle={closeModal}
      modalTitle={modalTitle}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      enableSave={stimJoinerChanged}
      save={() => updateConceptRotationStimJoiner(stimType, selectedJoiner)}
    >
      <Row>
        <div className="modal-left-column">
          <span className="label">{intl.get('app.title')}</span>
        </div>
        <div className="modal-right-column">
          <Input value={selectedJoiner.researchPrompt} onChange={handleResearchPromptChange} />
        </div>
      </Row>
      <Row className="mt-4">
        <div className="label modal-left-column">
          <Input
            type="select"
            className="stim-type-selector"
            value={getOption()}
            onChange={e => handleMediaChange(e.target.value)}
          >
            <option value={selectMediaOptionText}>{selectMediaOptionText}</option>
            <option value={selectTextOptionText}>{selectTextOptionText}</option>
          </Input>
        </div>
        <div className="modal-right-column">
          {selectTextOption && (
            <div className="text-stim-quill">
              <div className="quill-text-field" id="cr-media-quill-container" />
            </div>
          )}
          {selectMediaOption && (
            <MediaPreview
              mediaType={selectedJoiner.stim.media ? selectedJoiner.stim.media.type : 'media'}
              media={selectedJoiner.stim.media}
              onClick={toggleSelectMediaModal}
            />
          )}
        </div>
      </Row>
      {selectMediaOption && (
        <Row className="mt-4">
          <div className="label modal-left-column">
            <span className="label">{intl.get('app.caption')}</span>
          </div>
          <div className="modal-right-column">
            <Input
              value={getStimCaption(selectedJoiner.stim, language, viewLanguage)}
              onChange={handleCaptionChange}
              placeholder={intl.get('app.caption')}
            />
          </div>
        </Row>
      )}
      <Row className="mt-4">
        <div className="label modal-left-column">
          <span className="label">{intl.get('app.timing')}</span>
        </div>
        <div className="modal-right-column">
          <TimeSelector
            pickedTime={selectedJoiner.timingOptions.configuredTime}
            updateTime={updateConfiguredTime}
            readOnly={readOnly}
          />
        </div>
      </Row>
    </InvokeModal>
  );
};

export default EditMediaModal;
