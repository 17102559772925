import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { InvokeModal, InvokeTable, SearchInput, usePagination } from 'webapp-common';
import intl from 'react-intl-universal';
import localtime from '../../util/localtime';
import { Button } from 'reactstrap';
import DataTableEditor from '../../containers/pages/dataTableEditorPage/dataTableEditor';
import { UploadModal } from '../project/recruit/import/UploadModal';

import './DataTableList.css';

const reducer = (state, payload) => ({ ...state, ...payload });

export const DataTableList = props => {
  const {
    dataTableList = {},
    dataTableListRequested = false,
    isInternalSuperUser,
    buttonText = intl.get('app.addDataTable'),
    searchClass = '',
    floatRight = true,
    fromScreener,
    createDataTable,
    fetchDataTableList
  } = props;

  const { pageRequest = {}, totalElements } = dataTableList;

  const [state, setState] = useReducer(reducer, {
    showAddDataTableModal: false,
    showDataEditorModal: false,
    searchName: ''
  });

  useEffect(() => {
    fetchDataTableList({});
  }, []);

  const toggleModal = useCallback(
    (modal, id, filename) => {
      setState({
        [modal]: !state[modal],
        dataTableId: id,
        filename
      });
    },
    [state]
  );

  const refetchDataTableList = useCallback(() => {
    fetchDataTableList({});
  }, [fetchDataTableList]);

  const formatLabel = useCallback(
    info => {
      const dataTable = info.row.original;
      const onCellClick = id => {
        if (fromScreener) {
          props.formatLabel(dataTable);
        } else {
          toggleModal('showDataEditorModal', id, dataTable.name);
        }
      };

      return (
        <span onClick={() => onCellClick(dataTable.id)} className="link" title={info.getValue()}>
          {info.getValue()}
        </span>
      );
    },
    [fromScreener, props.formatLabel, toggleModal]
  );

  const formatDate = useCallback(info => {
    return localtime.getFormattedDate(info.getValue());
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: intl.get('app.table.name'),
        headerStyle: { width: '7rem' },
        cell: formatLabel,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'numRecords',
        header: intl.get('app.table.records'),
        headerStyle: { width: '3rem' }
      },
      {
        accessorKey: 'createDate',
        header: intl.get('app.createDate'),
        headerStyle: { width: '5rem' },
        cell: formatDate
      },
      {
        accessorKey: 'modifiedDate',
        header: intl.get('app.lastModified'),
        headerStyle: { width: '5rem' },
        cell: formatDate
      }
    ];
  }, [formatDate, formatLabel]);

  const onCreateSubmit = useCallback(
    params => {
      params.name = params.entityName;
      toggleModal('showAddDataTableModal');
      createDataTable(params);
    },
    [createDataTable, toggleModal]
  );

  const handleSearchChange = useCallback(
    e => {
      setState({ searchName: e.target.value });
      fetchDataTableList({
        pageNumber: 1,
        pageSize: pageRequest.pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder,
        name: e.target.value
      });
    },
    [fetchDataTableList, pageRequest.pageSize, pageRequest.sortBy, pageRequest.sortOrder]
  );

  const pagination = usePagination({ pageRequest, totalElements });

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchDataTableList({
        pageNumber: 1,
        pageSize: pageRequest.pageSize,
        sortBy,
        sortOrder,
        name: state.searchName
      });
    },
    [pageRequest.pageSize, fetchDataTableList, state.searchName]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchDataTableList({ pageNumber, pageSize, sortBy, sortOrder, name: state.searchName });
    },
    [fetchDataTableList, state.searchName]
  );

  return (
    <>
      <div className="data-table-library">
        {(isInternalSuperUser && (
          <Button color="primary" onClick={() => toggleModal('showAddDataTableModal')}>
            {buttonText}
          </Button>
        )) || <div />}
        {!floatRight && <br />}
        <SearchInput
          floatRight={floatRight}
          placeholder={intl.get('app.search')}
          disabled={false}
          onChange={handleSearchChange}
          className={searchClass}
        />
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={dataTableList.content ?? []}
          initialState={{
            sorting: [
              {
                id: 'modifiedDate',
                desc: true
              }
            ]
          }}
          loading={dataTableListRequested}
          pagination={pagination}
          onPaginationChange={paginate}
          onSortingChange={sort}
        />
      </div>
      {state.showAddDataTableModal && (
        <UploadModal
          toggle={() => toggleModal('showAddDataTableModal')}
          onSubmit={onCreateSubmit}
          modalTitle={intl.get('app.uploadData')}
          cancelButtonText={intl.get('app.cancel')}
          primaryButtonText={intl.get('app.save')}
          uploadNameLabel={intl.get('app.dataTableName')}
        />
      )}
      {state.showDataEditorModal && (
        <InvokeModal
          showModal={state.showDataEditorModal}
          modalTitle={state.filename}
          toggle={() => toggleModal('showDataEditorModal')}
          save={() => toggleModal('showDataEditorModal')}
          primaryButtonText={intl.get('app.close')}
          enableSave
        >
          <DataTableEditor
            dataTableId={state.dataTableId}
            refetchDataTableList={refetchDataTableList}
            closeEditor={toggleModal}
          />
        </InvokeModal>
      )}
    </>
  );
};
