import { connect } from 'react-redux';
import { surveySelector } from '../../../store/redux/selectors/surveySelector';
import {
  screenerSummarySelector,
  screenersAnswersTotalSelector,
  screenerPreviewResultsSelector,
  screenersAnswersSummariesSelector,
  enrollmentInfoSelector
} from '../../../store/redux/selectors/participantDataSelector';
import { fetchSurveyActions } from '../../../store/redux/actions/surveyActions';
import {
  saveParticipantActiveActions,
  fetchScreenersAnswersTotalActions,
  fetchScreenerPreviewResultsActions,
  fetchEnrollmentInfoActions
} from '../../../store/redux/actions/participantDataActions';
import ScreenerResponses from './ScreenerResponses';

const mapStateToProps = (state, { sessionId, surveyId, metaDataCollectionId }) => {
  const survey = surveySelector()(state, surveyId);
  const screenerSummary = screenerSummarySelector()(state, sessionId);
  const screenersAnswersTotal = screenersAnswersTotalSelector()(state, sessionId);
  const screenersAnswersSummaries = screenersAnswersSummariesSelector()(state, sessionId);
  const screenerPreviewResults = screenerPreviewResultsSelector()(state, sessionId);
  const enrollmentInfo = enrollmentInfoSelector()(state, metaDataCollectionId);

  return {
    ...survey,
    ...screenerSummary,
    ...screenersAnswersTotal,
    ...screenerPreviewResults,
    ...screenersAnswersSummaries,
    enrollmentInfo
  };
};

const mapDispatchToProps = dispatch => ({
  saveParticipantActive: params => dispatch(saveParticipantActiveActions.request(params)),
  fetchSurvey: templateId => dispatch(fetchSurveyActions.request({ surveyId: templateId })),
  fetchScreenersAnswersTotal: params => dispatch(fetchScreenersAnswersTotalActions.request(params)),
  fetchScreenerPreviewResults: params => dispatch(fetchScreenerPreviewResultsActions.request(params)),
  fetchEnrollmentInfo: params => dispatch(fetchEnrollmentInfoActions.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenerResponses);
