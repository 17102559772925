import { connect } from 'react-redux';
import { mediaListSelector } from '../../store/redux/selectors/mediaListSelector';
import {
  fetchMediaListActions,
  saveMediaActions,
  deleteMediaActions,
  uploadLargeMediaActions
} from '../../store/redux/actions/mediaActions';
import { sessionUserSelector } from '../../store/redux/selectors/sessionUserSelector';
import { clientAdminSelector } from '../../store/redux/selectors/clientAdminSelector';
import { MediaLibrary } from './MediaLibrary';

const mapDispatchToProps = dispatch => ({
  fetchMediaList: params => dispatch(fetchMediaListActions.request(params)),
  saveMedia: params => dispatch(saveMediaActions.request(params)),
  deleteMedia: params => dispatch(deleteMediaActions.request(params)),
  uploadMediaStart: params => dispatch(uploadLargeMediaActions.triggered(params)),
  uploadMediaSuccess: params => dispatch(uploadLargeMediaActions.succeeded(params)),
  uploadMediaCancelled: params => dispatch(uploadLargeMediaActions.failed(params))
});

const mapStateToProps = state => {
  const mediaSelector = mediaListSelector(state);
  const {
    sessionTenant,
    abilities: { hasProjectManage }
  } = sessionUserSelector(state);
  const clientMap = clientAdminSelector()(state).clientMap || {};
  const whitelistedDomains = clientMap[sessionTenant.clientID]
    ? clientMap[sessionTenant.clientID].whitelistedDomains
    : sessionTenant.whitelistedDomains;
  return {
    ...mediaSelector,
    whitelistedDomains,
    hasProjectManage
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibrary);
