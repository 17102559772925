import createFetchActions from './createFetchActions';

const fetchSessionListAction = createFetchActions('SESSION_LIST');
const fetchSessionAction = createFetchActions('FETCH_SESSION');
const fetchSessionAndSurveyAction = createFetchActions('FETCH_SESSION_AND_SURVEY');
const addNewSessionAction = createFetchActions('ADD_NEW_SESSION');
const fetchSaveSessionAction = createFetchActions('SAVE_SESSION');
const cloneSessionAction = createFetchActions('CLONE_SESSION');
const fetchAllSessionsAction = createFetchActions('FETCH_ALL_SESSIONS');
// I think this will be obsolete with the new 2020 UI
const fetchScreenerSessionListAction = createFetchActions('SCREENER_SESSION_LIST');
const updateDynataPanelConfigActions = createFetchActions('UPDATE_DYNATA_PANEL_CONFIG');

export {
  fetchSessionListAction,
  fetchSessionAction,
  fetchSessionAndSurveyAction,
  addNewSessionAction,
  fetchSaveSessionAction,
  cloneSessionAction,
  fetchAllSessionsAction,
  fetchScreenerSessionListAction,
  updateDynataPanelConfigActions
};
