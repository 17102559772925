import React from 'react';
import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';
import appConfig from '../../../../appConfig';
import localtime from '../../../../util/localtime';
import { Icons } from '../../../../components/icons/Icons';
import { getProjectPageURL } from '../../../../util/projectUtil';
import { mediaUtil } from '../../../../util/mediaUtil';

export const ProjectCard = props => {
  const border = props.addBorder ? 'active' : 'not-active';
  const created = localtime.getFormattedDateCap(props.created);

  const navigate = useNavigate();

  return (
    <div
      className={`project-card clickable ${border}`}
      onClick={() => navigate(getProjectPageURL(props.projectId, props.hasProjectManage, appConfig.projectPagePath))}
    >
      <div className="mb-3 text-truncate" style={{ height: '1.5rem' }} title={props.name}>
        <strong>{props.name}</strong>
      </div>
      <div className="mb-3" style={{ height: '1.5rem' }}>
        <p>
          {intl.get('app.created')}: {created}
        </p>
      </div>
      <div className="users-media">
        <Icons.PollIcon className="me-4 pt-2" />
        <div className="me-3" style={{ minWidth: '100px' }}>
          <label style={{ display: 'block' }}>
            {intl.get('app.pending')}: {props.pending}
          </label>
          <label style={{ display: 'block' }}>
            {intl.get('app.active')}: {props.active}
          </label>
          <label style={{ display: 'block' }}>
            {intl.get('app.closed')}: {props.closed}
          </label>
        </div>
        <div style={{ paddingTop: '0.5rem' }}>{mediaUtil.getCardViewMedia(props.stim)}</div>
      </div>
    </div>
  );
};
