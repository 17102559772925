import { connect } from 'react-redux';
import { sessionSelector } from '../../../store/redux/selectors/sessionSelector';
import { surveySelector } from '../../../store/redux/selectors/surveySelector';
import { ProjectStatusBar as Component } from './ProjectStatusBar';

const mapStateToProps = (state, props) => {
  const { tab, subtab } = props.routeParams;

  if (tab === 'survey' || tab === 'recruit') {
    const sessionId = subtab;
    const sessionsSelector = sessionSelector()(state, sessionId);
    const { session } = sessionsSelector;
    const { surveyId } = session || {};
    const { survey } = surveyId ? surveySelector()(state, surveyId) : {};
    return { session, survey };
  }

  return {};
};
export const ProjectStatusBar = connect(mapStateToProps)(Component);
