import { useSelector, shallowEqual } from 'react-redux';
import { projectSelector } from '../store/redux/selectors/projectSelector';
import { sessionSelector } from '../store/redux/selectors/sessionSelector';
import { sessionUserSelector } from '../store/redux/selectors/sessionUserSelector';
import { surveySelector } from '../store/redux/selectors/surveySelector';

export const useProjectSelector = (props = {}) => {
  const projectStore = useSelector(state => projectSelector()(state, props.projectId), shallowEqual);
  return projectStore?.project;
};

export const useSessionSelector = (props = {}) => {
  return useSelector(state => sessionSelector()(state, props.sessionId), shallowEqual);
};

export const useSessionUserSelector = () => {
  return useSelector(state => sessionUserSelector(state), shallowEqual);
};

export const useSurveySelector = (props = {}) => {
  return useSelector(state => surveySelector()(state, props.surveyId), shallowEqual);
};

export const reduxHelper = {
  useProjectSelector,
  useSessionSelector,
  useSessionUserSelector,
  useSurveySelector
};
