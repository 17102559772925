import React, { useReducer } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';
import { ProdegeCountrySelector } from '../../../../components/prodege/ProdegeCountrySelector';

const reducer = (state, payload) => ({ ...state, ...payload });

export const ProjectTypeModal = props => {
  const [state, setState] = useReducer(reducer, {
    projectType: '',
    selectedCountry: 1
  });

  function selectProjectType(e) {
    setState({ projectType: e.target.value });
  }

  function selectCountry(e) {
    setState({ selectedCountry: e.target.value });
  }

  return (
    <InvokeModal
      showModal
      modalTitle={intl.get('app.createProject')}
      toggle={props.toggle}
      primaryButtonText={intl.get('app.select')}
      cancelButtonText={intl.get('app.cancel')}
      enableSave={state.projectType}
      save={() => props.onSelect(state)}
    >
      <div>{intl.get('app.projectType')}:</div>
      <div style={{ margin: '1rem 0 0 1rem' }}>
        <label>
          <Input type="radio" value="selfServe" name="projectType" onChange={selectProjectType} />
          {intl.get('app.selfService')}
        </label>
        <ProdegeCountrySelector
          style={{ marginLeft: '2rem' }}
          selectedCountry={state.selectedCountry}
          onCountrySelect={selectCountry}
          disabled={state.projectType !== 'selfServe'}
        />
        <label style={{ marginTop: '1rem' }}>
          <Input type="radio" value="fullServe" name="projectType" onChange={selectProjectType} />
          {intl.get('app.fullService')}
        </label>
      </div>
    </InvokeModal>
  );
};
