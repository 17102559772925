import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import appConfig from '../../../appConfig';
import { surveyUtil } from '../../../util/surveyUtil';
import { project_survey_view } from '../../../util/projectUtil';
import { addNewSurveyActions } from '../../../store/redux/actions/surveyActions';
import { addNewSessionAction, fetchAllSessionsAction } from '../../../store/redux/actions/sessionActions';
import { useSessionSelector, useSessionUserSelector } from '../../../customHooks/reduxHelper';
import { Loader } from 'webapp-common';
import { Icons } from '../../../components/icons/Icons';
import { SurveyCardView } from '../../surveyCard/SurveyCardView';
import SurveyTable from '../../surveyTable';
import ProjectSurvey from '.';

import './ProjectSurveyWrapper.css';

const getViewStyle = () => localStorage.getItem(project_survey_view.view_style);

export const ProjectSurveyWrapper = props => {
  const { isScreener = false, project, viewLanguage, setViewLanguage } = props;
  const { projectDetails = {} } = project || {};
  const multipleSurveys = projectDetails.multipleSurveysEnabled || projectDetails.totalSessionCount > 1;

  const navigate = useNavigate();
  const routeParams = useParams();
  const dispatch = useDispatch();

  const { hasProjectManage } = useSessionUserSelector().abilities;
  const { allSessions, requestInProgress } = useSessionSelector();

  const [viewStyle, setViewStyle] = useState(getViewStyle() || project_survey_view.block);

  useEffect(() => {
    if (projectDetails.id) {
      fetchSessions(1, -1, 'createDate', 'desc');
    }
  }, [projectDetails.id]);

  function fetchSessions(pageNumber, pageSize, sortBy, sortOrder) {
    dispatch(
      fetchAllSessionsAction.request({
        projectId: projectDetails.id,
        screener: isScreener,
        includeResponseRates: true,
        includeMedia: true,
        pageNumber,
        pageSize,
        sortBy,
        sortOrder
      })
    );
  }

  function sort({ sortBy, sortOrder }) {
    fetchSessions(1, -1, sortBy, sortOrder);
  }

  function paginate({ pageNumber, pageSize, sortBy, sortOrder }) {
    fetchSessions(pageNumber, pageSize, sortBy, sortOrder);
  }

  function goToSurvey(sessionId) {
    navigate(`${appConfig.projectPagePath}/${routeParams.projectId}/${routeParams.tab}/${sessionId}`);
  }

  function setLocalViewStyle(style) {
    localStorage.setItem(project_survey_view.view_style, style);
    setViewStyle(style);
  }

  function onAddSurveyClick() {
    const { session, survey } = surveyUtil.addSurvey(isScreener, allSessions, projectDetails);
    dispatch(addNewSurveyActions.succeeded(survey));
    dispatch(addNewSessionAction.succeeded(session));
    goToSurvey(session.id);
  }

  if (multipleSurveys && !routeParams.subtab) {
    return (
      <div className="survey-table-container">
        {requestInProgress && <Loader fullScreen spinner />}
        <section className="body-container l2-bg" style={{ height: '100%' }}>
          <div>
            {hasProjectManage && (
              <Button color="primary" style={{ marginLeft: 0, marginRight: '2rem' }} onClick={onAddSurveyClick}>
                {isScreener ? intl.get('app.addScreener') : intl.get('app.addSurvey')}
              </Button>
            )}
            <Icons.ListIcon
              className={
                viewStyle === project_survey_view.list ? 'selected me-2 clickable' : 'unselected me-2 clickable'
              }
              onClick={() => setLocalViewStyle(project_survey_view.list)}
            />
            <Icons.BlockIcon
              className={viewStyle === project_survey_view.block ? 'selected clickable' : 'unselected clickable'}
              onClick={() => setLocalViewStyle(project_survey_view.block)}
            />
          </div>
          {viewStyle === project_survey_view.list && (
            <SurveyTable
              hasProjectManage={hasProjectManage}
              allSessions={allSessions}
              isScreener={isScreener}
              goToSurvey={goToSurvey}
              sort={sort}
              paginate={paginate}
            />
          )}
          {viewStyle === project_survey_view.block && (
            <SurveyCardView allSessions={allSessions} goToSurvey={goToSurvey} />
          )}
        </section>
      </div>
    );
  }

  if (!multipleSurveys && !routeParams.subtab) {
    if (allSessions.content) {
      const projectSessions = allSessions.content.filter(
        s => s.projectId === projectDetails.id && s.screener === isScreener
      );
      // Should be just one session for the project in the store
      const sessionId = projectSessions[0] && projectSessions[0].id;
      if (sessionId) {
        goToSurvey(sessionId);
      }
    }
  }

  if (routeParams.subtab) {
    return (
      <ProjectSurvey
        project={projectDetails}
        isScreener={isScreener}
        routeParams={routeParams}
        viewLanguage={viewLanguage}
        setViewLanguage={setViewLanguage}
      />
    );
  }

  return null;
};
