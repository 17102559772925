import { handleActions } from 'redux-actions';
import { newVerbatimDataAction } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdVerbatimDataReducer = handleActions(
  {
    [newVerbatimDataAction.succeeded](state, { payload }) {
      return {
        ...state,
        [`${payload.sessionId}-${payload.questionDefId}`]: payload
      };
    }
  },
  initialState
);
