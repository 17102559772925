import React from 'react';
import intl from 'react-intl-universal';
import update from 'immutability-helper';
import { Row } from 'reactstrap';
import { cloneDeep, isEqual } from 'lodash';
import { idUtils } from '../../../../../util/Id';
import EditMediaModal from '../../modals/selectMedia/EditMediaModal';
import SelectMediaModal from '../../modals/selectMedia/SelectMediaModal';
import MediaPreview from '../../../../../components/core/mediaPreview/MediaPreview';
import { STIM_TYPE, getTextStimContents, ENGLISH } from '../../../../../util/joinerUtil';

import './ConceptRotationMedia.css';

const INTRO_STIM = 'introStimJoiner';
const TRANSITIONAL_STIM = 'transitionalStimJoiner';
const EXIT_STIM = 'exitStimJoiner';

class ConceptRotationMedia extends React.Component {
  constructor(props) {
    super(props);
    this.selectMediaOption = intl.get('app.concept.selectMedia');
    this.selectTextOption = intl.get('app.concept.textOnly');
    this.state = {
      editMediaModalOpen: false,
      selectMediaModalOpen: false,
      stimJoinerChanged: false,
      stimType: ''
    };
  }

  generateStimJoiner = () => {
    return {
      id: idUtils.getId(),
      active: true,
      displayIndex: 0,
      researchPrompt: '',
      stim: {
        altTag: '',
        origAltTag: '',
        caption: '',
        origCaption: '',
        label: '',
        type: STIM_TYPE.image
      },
      timingOptions: {
        timingEnabled: true,
        defaultTime: 30,
        configuredTime: 30
      }
    };
  };

  getStimJoiner = stimType => {
    const { conceptRotation } = this.props.joiner;
    let selectedJoiner;
    if (stimType === INTRO_STIM) {
      selectedJoiner = conceptRotation.introStimJoiner;
    } else if (stimType === TRANSITIONAL_STIM) {
      selectedJoiner = conceptRotation.transitionalStimJoiner;
    } else if (stimType === EXIT_STIM) {
      selectedJoiner = conceptRotation.exitStimJoiner;
    }
    return selectedJoiner || this.generateStimJoiner();
  };

  getModalTitle = stimType => {
    const { conceptRotation } = this.props.joiner;
    let title = '';
    let isEditMode;
    switch (stimType) {
      case INTRO_STIM:
        title = intl.get('app.conceptRotation.introMedia');
        isEditMode = !!conceptRotation.introStimJoiner;
        break;
      case EXIT_STIM:
        title = intl.get('app.conceptRotation.exitMedia');
        isEditMode = !!conceptRotation.exitStimJoiner;
        break;
      case TRANSITIONAL_STIM:
        title = intl.get('app.conceptRotation.transitionalMedia');
        isEditMode = !!conceptRotation.transitionalStimJoiner;
        break;
      default:
    }
    const addOrEdit = isEditMode ? intl.get('app.edit') : intl.get('app.add');
    return `${addOrEdit} ${title}`;
  };

  openEditMediaModal = stimType => {
    const selectedJoiner = this.getStimJoiner(stimType);
    this.setState({
      editMediaModalOpen: true,
      selectMediaOption: selectedJoiner.stim.type !== STIM_TYPE.text,
      selectTextOption: selectedJoiner.stim.type === STIM_TYPE.text,
      savedJoiner: cloneDeep(selectedJoiner),
      selectedJoiner,
      stimType
    });
  };

  closeEditMediaModal = () => {
    this.resetState();
  };

  getOption = () => (this.state.selectMediaOption ? this.selectMediaOption : this.selectTextOption);

  onMediaUpdate = media => {
    const selectedJoiner = update(this.state.selectedJoiner, {
      stim: {
        media: { $set: media },
        type: { $set: media.type.toLowerCase() }
      },
      researchPrompt: { $set: this.state.selectedJoiner.researchPrompt || media.title }
    });
    this.setState({
      stimJoinerChanged: !isEqual(this.state.savedJoiner, selectedJoiner),
      selectMediaModalOpen: !this.state.selectMediaModalOpen,
      selectedJoiner
    });
  };

  toggleSelectMediaModal = () => {
    this.setState({
      selectMediaModalOpen: !this.state.selectMediaModalOpen
    });
  };

  handleMediaChange = option => {
    const { selectedJoiner } = this.state;
    let type;
    if (option === this.selectTextOption) {
      type = STIM_TYPE.text;
    } else if (option === this.selectMediaOption) {
      type = STIM_TYPE.image;
      if (selectedJoiner.stim.media && selectedJoiner.stim.media.type) {
        type = selectedJoiner.stim.media.type.toLowerCase();
      }
    }

    const joiner = cloneDeep(selectedJoiner);
    joiner.stim.type = type;

    this.setState({
      selectMediaOption: this.selectMediaOption === option,
      selectTextOption: this.selectTextOption === option,
      selectedJoiner: joiner
    });
  };

  handleResearchPromptChange = e => {
    const updatedJoiner = update(this.state.selectedJoiner, {
      researchPrompt: { $set: e.target.value }
    });
    this.setState({
      stimJoinerChanged: !isEqual(this.state.savedJoiner, updatedJoiner),
      selectedJoiner: updatedJoiner
    });
  };

  handleCaptionChange = e => {
    const { stim } = this.state.selectedJoiner;
    const { language, viewLanguage } = this.props;
    const setCaption = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const updatedJoiner = update(this.state.selectedJoiner, {
      stim: {
        caption: { $set: setCaption ? e.target.value : stim.caption },
        origCaption: { $set: setOrig ? e.target.value : stim.origCaption }
      }
    });
    this.setState({
      stimJoinerChanged: !isEqual(this.state.savedJoiner, updatedJoiner),
      selectedJoiner: updatedJoiner
    });
  };

  handleMediaTextChange = (contents, richContents) => {
    const { stim } = this.state.selectedJoiner;
    const { language, viewLanguage } = this.props;
    const setText = language === viewLanguage;
    const setOrig = viewLanguage === ENGLISH;
    const updatedJoiner = update(this.state.selectedJoiner, {
      stim: {
        contents: { $set: setText ? contents : stim.contents },
        richContents: { $set: setText ? richContents : stim.richContents },
        origContents: { $set: setOrig ? contents : stim.origContents },
        origRichContents: { $set: setOrig ? richContents : stim.origRichContents }
      }
    });
    this.setState({
      stimJoinerChanged: !isEqual(this.state.savedJoiner, updatedJoiner),
      selectedJoiner: updatedJoiner
    });
  };

  handleTimingChange = selectedJoiner => {
    this.setState({
      stimJoinerChanged: !isEqual(this.state.savedJoiner, selectedJoiner),
      selectedJoiner
    });
  };

  resetState = () => {
    this.setState({
      stimJoinerChanged: false,
      savedJoiner: null,
      selectedJoiner: null,
      stimType: null,
      selectMediaOption: true,
      selectTextOption: false,
      selectMediaModalOpen: false,
      editMediaModalOpen: false
    });
  };

  updateConceptRotationStimJoiner = (stimType, selectedJoiner) => {
    this.props.updateHandlers.updateConceptRotationStimJoiner(stimType, selectedJoiner);
    this.resetState();
  };

  deleteStimMedia = selected => {
    const conceptRotation = cloneDeep(this.props.joiner.conceptRotation);
    delete conceptRotation[selected];
    this.props.updateHandlers.updateConceptRotation(conceptRotation);
    this.setState({
      stimJoinerChanged: true
    });
  };

  render() {
    const { joiner, readOnly, language, viewLanguage } = this.props;
    const { introStimJoiner, transitionalStimJoiner, exitStimJoiner } = joiner.conceptRotation;

    const isIntroMediaText = introStimJoiner && introStimJoiner.stim && introStimJoiner.stim.type === STIM_TYPE.text;
    const isTransitionalMediaText =
      transitionalStimJoiner && transitionalStimJoiner.stim && transitionalStimJoiner.stim.type === STIM_TYPE.text;
    const isExitMediaText = exitStimJoiner && exitStimJoiner.stim && exitStimJoiner.stim.type === STIM_TYPE.text;
    const modalTitle = this.getModalTitle(this.state.stimType);

    return (
      <div className={`${this.props.tabs.media} concept-rotation-media`}>
        <div className="mt-3 mb-4">{intl.get('app.conceptRotation.introText')}</div>
        <Row className="mb-4">
          <div className="col-3 editor-left-column">
            <div className="label">{intl.get('app.conceptRotation.introMedia')}</div>
          </div>
          <div className="col-9 editor-right-column">
            {!readOnly && !introStimJoiner && (
              <span className="add-input" onClick={() => this.openEditMediaModal(INTRO_STIM)}>
                <i className="fas fa-plus" />
              </span>
            )}
            {!readOnly && introStimJoiner && (
              <div className="flex-row">
                {isIntroMediaText ? (
                  <div className="text-only-media" onClick={() => this.openEditMediaModal(INTRO_STIM)}>
                    {getTextStimContents(introStimJoiner.stim, language, viewLanguage)}
                  </div>
                ) : (
                  <MediaPreview
                    mediaType={introStimJoiner.stim.media && introStimJoiner.stim.media.type}
                    media={introStimJoiner.stim.media}
                    onClick={() => this.openEditMediaModal(INTRO_STIM)}
                    classes="small-preview"
                  />
                )}
                <span className="remove-input ms-5" onClick={() => this.deleteStimMedia(INTRO_STIM)}>
                  {' '}
                  <i className="fas fa-minus" />{' '}
                </span>
              </div>
            )}
          </div>
        </Row>
        <Row className="mb-4">
          <div className="col-3 editor-left-column">
            <div className="label">{intl.get('app.conceptRotation.transitionalMedia')}</div>
          </div>
          <div className="col-9 editor-right-column">
            {!readOnly && !transitionalStimJoiner && (
              <span className="add-input" onClick={() => this.openEditMediaModal(TRANSITIONAL_STIM)}>
                <i className="fas fa-plus" />
              </span>
            )}
            {!readOnly && transitionalStimJoiner && (
              <div className="flex-row">
                {isTransitionalMediaText ? (
                  <div className="text-only-media" onClick={() => this.openEditMediaModal(TRANSITIONAL_STIM)}>
                    {getTextStimContents(transitionalStimJoiner.stim, language, viewLanguage)}
                  </div>
                ) : (
                  <MediaPreview
                    mediaType={transitionalStimJoiner.stim.media && transitionalStimJoiner.stim.media.type}
                    media={transitionalStimJoiner.stim.media}
                    onClick={() => this.openEditMediaModal(TRANSITIONAL_STIM)}
                    classes="small-preview"
                  />
                )}
                <span className="remove-input ms-5" onClick={() => this.deleteStimMedia(TRANSITIONAL_STIM)}>
                  {' '}
                  <i className="fas fa-minus" />{' '}
                </span>
              </div>
            )}
          </div>
        </Row>
        <Row className="mb-4">
          <div className="col-3 editor-left-column">
            <div className="label">{intl.get('app.conceptRotation.exitMedia')}</div>
          </div>
          <div className="col-9 editor-right-column">
            {!readOnly && !exitStimJoiner && (
              <span className="add-input" onClick={() => this.openEditMediaModal(EXIT_STIM)}>
                <i className="fas fa-plus" />
              </span>
            )}
            {!readOnly && exitStimJoiner && (
              <div className="flex-row">
                {isExitMediaText ? (
                  <div className="text-only-media" onClick={() => this.openEditMediaModal(EXIT_STIM)}>
                    {getTextStimContents(exitStimJoiner.stim, language, viewLanguage)}
                  </div>
                ) : (
                  <MediaPreview
                    mediaType={exitStimJoiner.stim.media && exitStimJoiner.stim.media.type}
                    media={exitStimJoiner.stim.media}
                    onClick={() => this.openEditMediaModal(EXIT_STIM)}
                    classes="small-preview"
                  />
                )}
                <span className="remove-input ms-5" onClick={() => this.deleteStimMedia(EXIT_STIM)}>
                  {' '}
                  <i className="fas fa-minus" />{' '}
                </span>
              </div>
            )}
          </div>
        </Row>
        {this.state.editMediaModalOpen && (
          <EditMediaModal
            readOnly={readOnly}
            toggleEditMediaModal={this.closeEditMediaModal}
            stimJoinerChanged={this.state.stimJoinerChanged}
            updateConceptRotationStimJoiner={this.updateConceptRotationStimJoiner}
            modalTitle={modalTitle}
            stimType={this.state.stimType}
            language={language}
            viewLanguage={viewLanguage}
            selectedJoiner={this.state.selectedJoiner}
            selectTextOption={this.state.selectTextOption}
            selectMediaOption={this.state.selectMediaOption}
            handleMediaChange={this.handleMediaChange}
            handleResearchPromptChange={this.handleResearchPromptChange}
            handleMediaTextChange={this.handleMediaTextChange}
            handleCaptionChange={this.handleCaptionChange}
            toggleSelectMediaModal={this.toggleSelectMediaModal}
            handleTimingChange={this.handleTimingChange}
          />
        )}
        {this.state.selectMediaModalOpen && (
          <SelectMediaModal toggle={this.toggleSelectMediaModal} onMediaUpdate={this.onMediaUpdate} />
        )}
      </div>
    );
  }
}

export default ConceptRotationMedia;
