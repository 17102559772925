import React from 'react';
import intl from 'react-intl-universal';
import appConfig from '../../../appConfig';
import { NavTab } from 'webapp-common';
import { PROJECT_DETAILS_TAB, REPORTS_AND_DATA_TAB, RECRUIT_TAB, SURVEY_TAB } from '../../../util/projectUtil';

export const ProjectLeftNav = props => {
  const { projectDetails, activeTab, editMode, hasProjectView, hasReportsView } = props;
  const { id: projectId, multipleSurveysEnabled, selfServe } = projectDetails || {};

  function getUrl(tab) {
    return `${appConfig.projectPagePath}/${projectId}/${tab}`;
  }

  function getDetailsTab() {
    if (hasProjectView) {
      return (
        <NavTab
          icon="fa-briefcase"
          iconStyle="solid"
          label={intl.get('app.project')}
          active={activeTab === PROJECT_DETAILS_TAB}
          to={getUrl(PROJECT_DETAILS_TAB)}
          key="details"
        />
      );
    }
  }

  function getRecruitTab() {
    if (editMode && hasProjectView) {
      return (
        <NavTab
          icon="fa-users-class"
          iconStyle="solid"
          label={intl.get('app.recruit')}
          active={activeTab === RECRUIT_TAB}
          to={getUrl(RECRUIT_TAB)}
          key="recruit"
        />
      );
    }
  }

  function getSurveyTab() {
    if (editMode && hasProjectView) {
      return (
        <NavTab
          icon="fa-poll"
          iconStyle="solid"
          label={multipleSurveysEnabled ? intl.get('app.surveys') : intl.get('app.survey')}
          active={activeTab === SURVEY_TAB}
          to={getUrl(SURVEY_TAB)}
          key="survey"
        />
      );
    }
  }

  function getReportsTab() {
    if (editMode && hasReportsView) {
      return (
        <NavTab
          icon="fa-file-spreadsheet"
          iconStyle="solid"
          label={intl.get('app.reports')}
          active={activeTab === REPORTS_AND_DATA_TAB}
          to={getUrl(REPORTS_AND_DATA_TAB)}
          key="reports"
        />
      );
    }
  }

  const tabs = selfServe
    ? [getDetailsTab(), getSurveyTab(), getRecruitTab(), getReportsTab()]
    : [getDetailsTab(), getRecruitTab(), getSurveyTab(), getReportsTab()];

  return <section className="left-nav">{tabs}</section>;
};
