import intl from 'react-intl-universal';

export const getFeatureAbilities = () => {
  return [
    {
      abilityName: intl.get('app.ProjectsSurveys'),
      viewIdentifier: 'projectView',
      manageIdentifier: 'projectManage',
      view: false,
      manage: false
    },
    {
      abilityName: intl.get('app.reports'),
      viewIdentifier: 'reportsView',
      manageIdentifier: 'reportsManage',
      view: false,
      manage: false
    },
    {
      abilityName: intl.get('app.Organization'),
      viewIdentifier: 'orgView',
      manageIdentifier: 'orgManage',
      view: false,
      manage: false
    },
    {
      abilityName: intl.get('app.roles'),
      viewIdentifier: 'orgRoleView',
      manageIdentifier: 'orgRoleManage',
      view: false,
      manage: false
    },
    {
      abilityName: intl.get('app.users'),
      viewIdentifier: 'userAccountsView',
      manageIdentifier: 'userAccountsManage',
      view: false,
      manage: false
    }
  ];
};
