import { sendWSMessage } from './websocket';
import { idUtils } from './Id';

export const saveRDConfig = rdConfig => {
  sendWSMessage({
    action: 'saveRDConfig',
    rdConfig
  });
};

export const getNewComparativeView = () => ({
  conceptIds: [],
  conceptRotationJoinerId: null,
  displayOption: 'count',
  id: idUtils.getId(),
  positions: [],
  questionAnswers: [],
  showAllPositions: false,
  showSigTestData: false,
  sigTestSetting: {
    primaryConfidence: 95,
    secondaryConfidence: 90,
    thresholdSize: 30,
    testSecondary: false,
    testBelowMin: false
  },
  title: '',
  type: 'concept'
});

export const PARTICIPANT_FLAG_COLORS = {
  GROUP1: 'orange',
  GROUP2: 'red',
  GROUP3: 'purple',
  GROUP4: 'blue',
  GROUP5: 'yellow',
  GROUP6: 'green',
  GROUP7: 'black'
};

export const DEFAULT_FLAG_COLOR = 'gray';

export const rdUtil = {
  saveRDConfig,
  getNewComparativeView,
  PARTICIPANT_FLAG_COLORS
};
