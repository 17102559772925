import React from 'react';
import { Input } from 'reactstrap';
import intl from 'react-intl-universal';
import { get } from 'lodash';
import { LANGUAGES, ENGLISH } from '../../../../../util/joinerUtil';

export const SurveyLanguage = props => {
  const { survey = {}, readOnly, setLanguageOption, selectedLanguage, altLanguage } = props;
  const { surveyOptions = {} } = survey;
  const language = selectedLanguage || get(surveyOptions, 'languageOptions.language');

  function getLanguages(altLanguage) {
    const keys = altLanguage ? (altLanguage === ENGLISH ? [ENGLISH] : [ENGLISH, altLanguage]) : Object.keys(LANGUAGES);
    return keys.map(l => (
      <option value={l} key={l}>
        {intl.get(`app.language.${l}`)}
      </option>
    ));
  }

  return (
    <Input
      type="select"
      style={{ width: 'auto' }}
      value={language || ENGLISH}
      onChange={e => setLanguageOption(e.target.value)}
      disabled={readOnly}
    >
      {getLanguages(altLanguage)}
    </Input>
  );
};
