import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';

class DeleteModal extends React.Component {
  save = () => {
    this.props.onSave();
    this.props.toggle();
  };

  render() {
    const { toggle, project } = this.props;
    return (
      <InvokeModal
        showModal={true}
        toggle={toggle}
        modalTitle={intl.get('app.delete')}
        cancelButtonText={intl.get('app.cancel')}
        primaryButtonText={intl.get('app.delete')}
        enableSave={true}
        save={this.save}
      >
        <p className="m-5">{intl.get('app.delete.project.confirm', { project })}</p>
      </InvokeModal>
    );
  }
}

export default DeleteModal;
