import { createSelector } from 'reselect';

const customReportConfigStore = state => state.customReportConfig;

const customReportConfigSelector = () =>
  createSelector([customReportConfigStore], customReportConfigStore => ({
    ...customReportConfigStore
  }));

export { customReportConfigSelector };
