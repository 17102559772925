import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { find } from 'lodash';

import './Keywords.css';

/**
 * This component is used for keywords (including named entities)
 */

// Same function as in SentimentSummaryKeywordsChart.js
function getSentimentsForKeyword(keywordSentiments, keyword) {
  const escaped = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regexp = RegExp(`^${escaped} \\(\\d+\\)$`);
  const keys = Object.keys(keywordSentiments);
  const key = find(keys, k => regexp.test(k));
  return keywordSentiments[key] || {};
}

function getRows(keywords = [], keywordSentiments = {}, selectedKeyWords = [], onKeywordClick) {
  return keywords.map(kw => {
    const sentiments = getSentimentsForKeyword(keywordSentiments, kw.value);
    const { POSITIVE = 0, NEUTRAL = 0, NEGATIVE = 0 } = sentiments;
    const total = POSITIVE + NEUTRAL + NEGATIVE;
    const positivePercent = `${Math.round((POSITIVE * 100) / total) || 0}%`;
    const neutralPercent = `${Math.round((NEUTRAL * 100) / total) || 0}%`;
    const negativePercent = `${Math.round((NEGATIVE * 100) / total) || 0}%`;
    const keywordClasses = {
      clickable: true,
      selected: selectedKeyWords && selectedKeyWords.indexOf(kw.value) !== -1
    };
    const title =
      `${intl.get('app.positive')}: ${positivePercent}, ` +
      `${intl.get('app.neutral')}: ${neutralPercent}, ` +
      `${intl.get('app.negative')}: ${negativePercent}`;

    return (
      <React.Fragment key={kw.value}>
        <div className={classnames(keywordClasses)} onClick={() => onKeywordClick(kw.value)}>
          <div>{(kw.whiteListKeyWord ? '* ' : '') + kw.value}</div>
          <div>{kw.frequency}</div>
        </div>
        <div title={title}>
          <div className="sentiment-positive" style={{ width: positivePercent }} />
          <div className="sentiment-neutral" style={{ width: neutralPercent }} />
          <div className="sentiment-negative" style={{ width: negativePercent }} />
        </div>
      </React.Fragment>
    );
  });
}

export const Keywords = props => {
  const { keywords, keywordSentiments, selectedKeyWords, onKeywordClick, openWordcloud, openWhitelist } = props;
  return (
    <>
      <div className="keywords-header">
        <h6>{props.title}</h6>
        {openWordcloud && (
          <div className="keywords-header-wordcloud link" onClick={openWordcloud}>
            {intl.get('app.wordCloud')}
          </div>
        )}
      </div>
      <div className="keywords-frame">
        <div className="keywords">{getRows(keywords, keywordSentiments, selectedKeyWords, onKeywordClick)}</div>
        {openWhitelist && (
          <div className="whitelist-link">
            <span>{intl.get('app.whitelist.link')}</span>
            <span className="link" onClick={openWhitelist}>
              {intl.get('app.edit')}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
