import { handleActions } from 'redux-actions';
import {
  newDialDataActions,
  newDialDataFileActions,
  exportDialDataActions,
  fetchDialSettingConfigActions,
  saveDialSettingConfigActions,
  fetchVideoWatermarkActions,
  deleteVideoWatermarkActions
} from '../actions/dialDataActions';

const initialState = {};

const dialDataReducer = handleActions(
  {
    [newDialDataActions.succeeded](state, { payload }) {
      const { sessionId, questionJoinerId } = payload[0] || {};
      const returnObj = {
        ...state
      };
      if (sessionId && questionJoinerId) {
        returnObj[`${sessionId}-${questionJoinerId}`] = payload;
      }
      return returnObj;
    },

    [newDialDataFileActions.succeeded](state, { payload }) {
      const { sessionId, reportFiles } = payload;
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          reportFiles
        }
      };
    },

    [exportDialDataActions.triggered](state) {
      return {
        ...state,
        exportDialDataInProgress: true,
        exportDialDataSuccess: false
      };
    },

    [exportDialDataActions.succeeded](state, { payload }) {
      return {
        ...state,
        exportDialDataInProgress: false,
        exportDialDataSuccess: true
      };
    },

    [exportDialDataActions.failed](state) {
      return {
        ...state,
        exportDialDataInProgress: false,
        exportDialDataSuccess: false
      };
    },

    [fetchDialSettingConfigActions.triggered](state) {
      return {
        ...state,
        fetchDialSettingConfigInProgress: true,
        fetchDialSettingConfigSuccess: false
      };
    },

    [fetchDialSettingConfigActions.succeeded](state, { payload }) {
      return {
        ...state,
        dialSettingConfig: payload,
        fetchDialSettingConfigInProgress: false,
        fetchDialSettingConfigSuccess: true
      };
    },

    [fetchDialSettingConfigActions.failed](state) {
      return {
        ...state,
        fetchDialSettingConfigInProgress: false,
        fetchDialSettingConfigSuccess: false
      };
    },

    [saveDialSettingConfigActions.succeeded](state, { payload }) {
      return {
        ...state,
        dialSettingConfig: payload
      };
    },

    [saveDialSettingConfigActions.failed](state, { payload }) {
      return {
        ...state,
        dialSettingConfig: {
          ...state.dialSettingConfig,
          dialSettingConfigSaveError: true
        }
      };
    },

    [fetchVideoWatermarkActions.triggered](state) {
      return {
        ...state,
        fetchVideoWatermarkInProgress: true,
        fetchVideoWatermarkSuccess: false
      };
    },

    [fetchVideoWatermarkActions.succeeded](state, { payload }) {
      return {
        ...state,
        videoWatermark: payload,
        fetchVideoWatermarkInProgress: false,
        fetchVideoWatermarkSuccess: true
      };
    },

    [fetchVideoWatermarkActions.failed](state) {
      return {
        ...state,
        fetchVideoWatermarkInProgress: false,
        fetchVideoWatermarkSuccess: false
      };
    },

    [deleteVideoWatermarkActions.triggered](state) {
      return {
        ...state,
        deleteVideoWatermarkInProgress: true,
        deleteVideoWatermarkSuccess: false
      };
    },

    [deleteVideoWatermarkActions.succeeded](state, { payload }) {
      return {
        ...state,
        deleteVideoWatermarkInProgress: false,
        deleteVideoWatermarkSuccess: true
      };
    },

    [deleteVideoWatermarkActions.failed](state) {
      return {
        ...state,
        deleteVideoWatermarkInProgress: false,
        deleteVideoWatermarkSuccess: false,
        deleteVideoWatermarkError: true
      };
    }
  },
  initialState
);
export default dialDataReducer;
