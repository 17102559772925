import Cookie from 'js-cookie';
import request from 'superagent';
import { omit } from 'lodash';
import { auth } from '../api/Auth';

const defaultAjaxTimeout = 60000;
const CSRF_TOKEN_NAME = 'CSRFTOKEN';
const requestTimedout = 'Request timed out';

// this is here so that we can append the .timeout call to all of our ajax requests with the default value.
const requestWrapper = method => url => data => {
  const requestPromise = request[method](url);

  if (method === 'get') {
    requestPromise.query(data);
  } else {
    if ((data.file || data.dataFile) && data.useType) {
      // File upload. Attach the file(s).
      const { file, dataFile, useType } = data;
      if (file) {
        requestPromise.attach('file', file, file.name).field('useType', useType).field('size', file.size);
      }
      if (dataFile) {
        requestPromise
          .attach('dataFile', dataFile, dataFile.name)
          .field('useType', useType)
          .field('size', dataFile.size);
      }

      // Add any other passed-in fields
      const fields = omit(data, ['file', 'dataFile', 'useType']);
      const keys = Object.keys(fields);
      keys.forEach(key => {
        requestPromise.field(key, data[key]);
      });
    } else {
      requestPromise.send(data);
    }
  }

  // Keep the user's session active on Ajax calls
  auth.isAuthenticated(true);

  return requestPromise
    .timeout(defaultAjaxTimeout)
    .then(function (res) {
      if (res.body?.error) {
        throw res.body.error;
      }
      return res;
    })
    .catch(function (err) {
      let errorObj = err;
      if (err.timeout) {
        errorObj = {
          errorType: 'ERROR',
          errorMessage: requestTimedout,
          id: Date.now(),
          showIdToUser: false
        };
      }
      throw errorObj;
    });
};

const requestWithCsrfTokenInQueryString = method => url => {
  const tokenValue = Cookie.get(CSRF_TOKEN_NAME);
  if (!tokenValue) {
    return request[method](url);
  }
  if (url.indexOf('?') === -1) {
    url += '?';
  } else {
    url += '&';
  }
  url += CSRF_TOKEN_NAME + '=' + tokenValue;
  return requestWrapper(method)(url);
};

const get = requestWrapper('get');
const post = requestWithCsrfTokenInQueryString('post');
const put = requestWithCsrfTokenInQueryString('put');
const del = requestWithCsrfTokenInQueryString('del');

export { get, put, post, del };
