import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { cloneDeep, get, isEqual } from 'lodash';
import { Button, Input } from 'reactstrap';
import { CopyToClipboard, InvokeModal, Loader, SearchInput, SortIcon } from 'webapp-common';
import { virtualFocusGroupSubscribeActions } from '../../../../store/redux/actions/virtualFocusGroupActions';
import {
  filtersAndParticipantsSubscribeActions,
  setParticipantGroupActions
} from '../../../../store/redux/actions/filtersAndParticipantsActions';
import { setVFGstatusActions, endVFGMeetingActions } from '../../../../store/redux/actions/participantDataActions';
import { toggleFavoriteParticipantAction } from '../../../../store/redux/actions/researchDashboardActions';
import { useSessionUserSelector } from '../../../../customHooks/reduxHelper';
import { PARTICIPANT_FLAG_COLORS, DEFAULT_FLAG_COLOR, saveRDConfig } from '../../../../util/researchDashboardUtil';
import { rdConfigUtil } from '../../../../util/rdConfigUtil';
import { surveyUtil } from '../../../../util/surveyUtil';
import { Icons } from '../../../../components/icons/Icons';
import { FilterSelect } from '../../../../components/filterSelect/FilterSelect';
import { VirtualFocusGroupCompleted } from './completed/VirtualFocusGroupCompleted';
import { ConfirmModal } from '../../../../components/core/modal/ConfirmModal';
import { RDParticipantDetailsModal } from '../../../researchDashboard/rdParticipantDetails/RDParticipantDetailsModal';
import { ConfigureDataColumns } from '../../../researchDashboard/rdSessionMgmt/participantData/ConfigureDataColumns';

import './VirtualFocusGroup.css';

const reducer = (payload, state) => ({ ...payload, ...state });

// From VirtualFocusGroupStatus.java
const WAITING = 'WAITING';
const INVITED = 'INVITED';
const UNINVITED = 'UNINVITED';

const SELECTION = 'selection';

const setEnrollee = source => ({
  ...source,
  Nickname: source.nickname || source.id,
  Fav: source.favorite,
  Flag: source.participantGroup || 'group0',
  Chat: source.chat,
  Status: source.enrolleeStatus,
  Login: source.surveyStartTime,
  'Last Submit': source.lastAnsweredTime,
  '%': source.percentSurveyComplete
});

const setupWaitInvitedList = (enrolleesInfo, questionJoinerId) => {
  const waitlist = [];
  const invited = [];
  const { enrolleeMap = {} } = enrolleesInfo;
  Object.values(enrolleeMap).forEach(enrollee => {
    const vfgStatus = enrollee.virtualFocusGroupStates[questionJoinerId];
    if (vfgStatus === WAITING) {
      waitlist.push(setEnrollee(enrollee));
    } else if (vfgStatus === INVITED) {
      invited.push(setEnrollee(enrollee));
    }
  });
  return { waitlist, invited };
};

const filterScreening = (filters, participants, waitlist) => {
  if (!filters || filters.length === 0) {
    return waitlist;
  }
  const result = filters.map(f => participants[f.name]).flat();
  return waitlist.filter(w => result.some(r => r === w.id));
};

const findMatches = (list, value) => list.filter(enrollee => (enrollee.nickname || enrollee.id).indexOf(value) !== -1);

function isVFGDeployed(survey, session, vfgJoinerId, enrolleeMap) {
  return (
    // Live session
    surveyUtil.isVFGquestionDeployed(survey, session, vfgJoinerId) ||
    // Open session
    Object.values(enrolleeMap || {}).some(enrollee => enrollee.virtualFocusGroupStates[vfgJoinerId])
  );
}

export const VirtualFocusGroup = props => {
  const { enrolleesInfo, sessionId, session, survey, rdConfig, filteresAndParticipants } = props;

  const DEFAULT_COLUMNS = {
    participant: intl.get('app.nickname'),
    favorite: intl.get('app.fav'),
    flag: intl.get('app.flag')
  };

  const DEFAULT_HIDDEN_COLUMNS = {
    chat: intl.get('app.chat'),
    enrolleeStatus: intl.get('app.status'),
    surveyStartTime: intl.get('app.login'),
    lastAnsweredTime: intl.get('app.lastSubmit'),
    percentSurveyComplete: intl.get('app.percentSign')
  };

  const dispatch = useDispatch();
  const { hasProjectManage } = useSessionUserSelector().abilities;
  const vfgJoiners = surveyUtil.getVirtualFocusGroupJoiners(survey.joiners);
  const virtualFocusGroupConfig = get(rdConfig, 'configs.virtualFocusGroupConfig');
  const filterList = get(rdConfig, 'configs.filterListConfig.filters', []);
  const { columnOrder, keyTransformMap } = enrolleesInfo;

  const waitingColumns = (virtualFocusGroupConfig &&
    rdConfigUtil.getColumnConfig(
      virtualFocusGroupConfig.waitingTableColumnConfig,
      DEFAULT_COLUMNS,
      DEFAULT_HIDDEN_COLUMNS,
      columnOrder
    )) || { columnPositions: [] };
  const invitedColumns = (virtualFocusGroupConfig &&
    rdConfigUtil.getColumnConfig(
      virtualFocusGroupConfig.invitedTableColumnConfig,
      DEFAULT_COLUMNS,
      DEFAULT_HIDDEN_COLUMNS,
      columnOrder
    )) || { columnPositions: [] };

  const [state, setState] = useReducer(reducer, {
    selectedVfgJoinerId: vfgJoiners[0].id,
    showWaitingConfig: false,
    showInvitedConfig: false,
    showRemoveParticipantModal: false,
    endMeetingRequested: false,
    waitingColumns,
    invitedColumns,
    selectedFilters: [],
    [WAITING]: {
      columns: [SELECTION, ...waitingColumns.columnPositions],
      enrollees: [],
      selected: [],
      allSelected: false,
      sortColumn: null,
      sortDirection: null
    },
    [INVITED]: {
      columns: [SELECTION, ...invitedColumns.columnPositions],
      enrollees: [],
      selected: [],
      allSelected: false,
      sortColumn: null,
      sortDirection: null
    }
  });

  const vfgJoiner = vfgJoiners.find(j => j.id === state.selectedVfgJoinerId);
  const { maxParticipants } = vfgJoiner?.def?.responseSet || {};
  const vfgData = props.getVfgData(state.selectedVfgJoinerId);
  const { chimeMeeting = {}, moderatorUrl, observerUrl } = vfgData || {};
  const isMeetingActive = chimeMeeting.status === 'ACTIVE';
  const isMeetingComplete = chimeMeeting.status === 'COMPLETE';
  const vfgDeployed = isVFGDeployed(survey, session, state.selectedVfgJoinerId, enrolleesInfo.enrolleeMap);

  const virtualFocusGroupSubscribe = subAction => {
    dispatch(
      virtualFocusGroupSubscribeActions.request({
        subAction,
        sessionId,
        questionJoinerIds: vfgJoiners.map(j => j.id)
      })
    );
  };

  const filtersAndParticipantsSubscribe = subAction => {
    dispatch(filtersAndParticipantsSubscribeActions.request({ subAction, sessionId }));
  };

  // search/filter rule: when search/filter is applied,
  // all previously selected participants are unselected
  const updateFilters = filters => {
    const { waitlist, invited } = setupWaitInvitedList(enrolleesInfo, state.selectedVfgJoinerId);
    const waiting = cloneDeep(state[WAITING]);
    waiting.allSelected = false;
    waiting.selected = [];
    if (filters.length === 0) {
      waiting.enrollees = state.defaultValue ? findMatches(waitlist, state.defaultValue) : waitlist;
    } else {
      const { filteredParticipants } = filteresAndParticipants;
      let filteredResult = filterScreening(filters, filteredParticipants, waitlist);
      if (state.defaultValue) {
        filteredResult = findMatches(filteredResult, state.defaultValue);
      }
      waiting.enrollees = filteredResult;
    }

    setState({
      selectedFilters: filters,
      [WAITING]: waiting,
      [INVITED]: {
        ...state[INVITED],
        enrollees: invited
      }
    });
  };

  const onParticipantInputChange = e => {
    const participant = e.target.value;
    const { waitlist } = setupWaitInvitedList(enrolleesInfo, state.selectedVfgJoinerId);
    let filteredResult = filterScreening(state.selectedFilters, filteresAndParticipants.filteredParticipants, waitlist);
    const searchResult = participant ? findMatches(filteredResult, participant) || [] : filteredResult;
    setState({
      defaultValue: participant || null,
      [WAITING]: {
        ...state[WAITING],
        enrollees: searchResult,
        allSelected: false,
        selected: []
      }
    });
  };

  useEffect(() => {
    virtualFocusGroupSubscribe('subscribe');
    filtersAndParticipantsSubscribe('subscribe');
    return () => {
      virtualFocusGroupSubscribe('unsubscribe');
      filtersAndParticipantsSubscribe('unsubscribe');
    };
  }, []);

  useEffect(() => {
    if (state.selectedVfgJoinerId) {
      const { waitlist, invited } = setupWaitInvitedList(enrolleesInfo, state.selectedVfgJoinerId);
      setState({
        defaultValue: null, // This should reset the search input field, but it doesn't ?!?!
        selectedFilters: [],
        [WAITING]: {
          columns: state[WAITING].columns,
          enrollees: waitlist,
          selected: []
        },
        [INVITED]: {
          columns: state[INVITED].columns,
          enrollees: invited,
          selected: []
        }
      });
      // Hack to reset the search input field (see above).
      const searchInput = document.querySelector('#vfg-participant-search') || {};
      searchInput.value = '';
    }
  }, [state.selectedVfgJoinerId]);

  useEffect(() => {
    const { waitlist, invited } = setupWaitInvitedList(enrolleesInfo, state.selectedVfgJoinerId);
    if (state.selectedFilters.length === 0 && !state.defaultValue) {
      setState({
        [WAITING]: {
          ...state[WAITING],
          enrollees: waitlist
        },
        [INVITED]: {
          ...state[INVITED],
          enrollees: invited
        }
      });
    } else {
      updateFilters(state.selectedFilters);
    }
  }, [enrolleesInfo]);

  useEffect(() => {
    if (!isEqual(state.waitingColumns, waitingColumns)) {
      setState({
        [WAITING]: {
          ...state[WAITING],
          columns: [SELECTION, ...waitingColumns.columnPositions]
        },
        [INVITED]: {
          ...state[INVITED],
          columns: [SELECTION, ...invitedColumns.columnPositions]
        }
      });
    }
  }, [virtualFocusGroupConfig]);

  function getVfgStatus() {
    let statusColor;
    let status;
    let statusMessage = '';
    if (isMeetingComplete) {
      statusColor = 'var(--gray-500)';
      status = intl.get('app.complete');
    } else if (isMeetingActive) {
      statusColor = 'var(--red)';
      status = intl.get('app.active');
      statusMessage = intl.get('app.meeting.inProgress');
    } else if (vfgDeployed && !chimeMeeting.status) {
      statusColor = 'var(--blue)';
      status = intl.get('app.waiting');
      statusMessage = intl.get('app.vfgWaiting.message');
    } else if (!vfgDeployed) {
      statusColor = 'var(--blue)';
      status = intl.get('app.pending');
      statusMessage = intl.get('app.vfgNotDeployed.message');
    }

    return (
      <>
        <span style={{ color: statusColor, fontWeight: '600' }} className="mx-5">
          {status.toUpperCase()}
        </span>
        <span>{statusMessage}</span>
      </>
    );
  }

  const columnConfig =
    virtualFocusGroupConfig &&
    state.tableToBeConfigured &&
    rdConfigUtil.getColumnConfig(
      virtualFocusGroupConfig[state.tableToBeConfigured],
      DEFAULT_COLUMNS,
      DEFAULT_HIDDEN_COLUMNS,
      columnOrder
    );

  function selectVfgJoiner(e) {
    setState({ selectedVfgJoinerId: e.target.value });
  }

  const onSelectAllClick = table => {
    const selected = state[table].allSelected ? [] : cloneDeep(state[table].enrollees);
    setState({
      [table]: {
        ...state[table],
        selected,
        allSelected: !state[table].allSelected
      }
    });
  };

  const onNameClick = (enrollee, e) => {
    e?.stopPropagation();
    setState({ showSelectedEnrollee: enrollee.id });
  };

  const updateFavoriteParticipant = enrollee => {
    dispatch(
      toggleFavoriteParticipantAction.request({
        sessionId,
        participantId: enrollee.id,
        favorite: enrollee.Fav
      })
    );
  };

  const onFlagClick = (group, enrollee) => {
    dispatch(
      setParticipantGroupActions.request({
        sessionId,
        participantId: enrollee.id,
        group
      })
    );
  };

  const sort = (sortColumn, table) => {
    // need to save to backend VirtualFocusGroupConfig
    const sortDirection = state[table].sortDirection === 'desc' ? 'asc' : 'desc';
    const enrollees = cloneDeep(state[table].enrollees).sort((a, b) => {
      const valueA = typeof a[sortColumn] === 'boolean' ? a[sortColumn] : a[sortColumn].toUpperCase();
      const valueB = typeof b[sortColumn] === 'boolean' ? b[sortColumn] : b[sortColumn].toUpperCase();
      if (sortDirection === 'asc') {
        return valueA > valueB ? 1 : -1;
      }
      return valueA > valueB ? -1 : 1;
    });
    setState({
      [table]: {
        ...state[table],
        sortColumn,
        sortDirection,
        enrollees
      }
    });
  };

  const onRowClick = (participant, table) => {
    const selected = cloneDeep(state[table].selected);
    const found = selected.findIndex(s => s.id === participant.id);
    found >= 0 ? selected.splice(found, 1) : selected.push(participant);
    setState({
      [table]: {
        ...state[table],
        selected,
        allSelected: selected.length === state[table].enrollees.length
      }
    });
  };

  const getColumn = (column, enrollee, table) => {
    if (column === 'Nickname') {
      return (
        <td onClick={() => onRowClick(enrollee, table)}>
          <button className="link-button" onClick={e => onNameClick(enrollee, e)}>
            {enrollee.Nickname}
          </button>
        </td>
      );
    }
    if (column === 'Fav') {
      return (
        <td>
          {
            <Icons.StarIcon
              className={`favorite-icon ps-2 ${enrollee[column] ? 'favorite' : ''}`}
              onClick={() => updateFavoriteParticipant(enrollee)}
            />
          }
        </td>
      );
    }
    if (column === 'Flag') {
      const group = enrollee[column];
      const flag = PARTICIPANT_FLAG_COLORS[group] || DEFAULT_FLAG_COLOR;
      return (
        <td className="ps-5">
          <div style={{ position: 'relative' }}>
            <Icons.FlagsDropdown
              id={enrollee.id}
              flagClasses={`${flag} small-font`}
              title={
                flag === DEFAULT_FLAG_COLOR ? intl.get('app.setFlags') : intl.get(`app.participantFlag.${group}.color`)
              }
              colorProp={PARTICIPANT_FLAG_COLORS}
              showBan
              onFlagClick={grp => onFlagClick(grp, enrollee)}
            />
          </div>
        </td>
      );
    }
    return <td>{enrollee.metadata?.dataStore?.[column] || ''}</td>;
  };

  const getColumns = (enrollee, table) => {
    const { columns, selected, allSelected } = state[table];
    return columns.map(column => {
      const i = selected.findIndex(s => s.id === enrollee.id);
      return column === SELECTION ? (
        <td className="ps-4">
          <Input type="checkbox" checked={allSelected || i >= 0} onClick={() => onRowClick(enrollee, table)} />
        </td>
      ) : (
        getColumn(column, enrollee, table)
      );
    });
  };

  const getRows = table => {
    return state[table].enrollees.map(enrollee => {
      const classname = state[table].selected.some(s => s.id === enrollee.id) ? 'selected' : '';
      return (
        <tr className={classname} key={enrollee.id}>
          {getColumns(enrollee, table)}
        </tr>
      );
    });
  };

  const getSortIcon = (column, table) => {
    const { sortColumn, sortDirection } = state[table];
    const sortOrder = sortColumn === column ? sortDirection : undefined;
    return <SortIcon order={sortOrder} onClick={() => sort(column, table)} />;
  };

  const getTableHeader = table => {
    const { columns, selected } = state[table];
    return columns.map(column => {
      if (column === SELECTION) {
        return (
          <th style={{ verticalAlign: 'text-bottom' }}>
            <Input
              type="checkbox"
              onClick={() => onSelectAllClick(table)}
              title={selected.length === 0 ? intl.get('app.selectAll') : intl.get('app.unselectAll')}
              checked={state[table].allSelected}
            />
          </th>
        );
      }
      return (
        <th style={{ verticalAlign: 'text-bottom' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'inline-block', marginRight: '1rem' }}>{column}</div>
            <div style={{ display: 'inline-block', width: column === 'Nickname' && '10rem' }}>
              {getSortIcon(column, table)}
            </div>
          </div>
        </th>
      );
    });
  };

  const getTable = table => (
    <table className="vfg-table table invoke-table" style={{ height: '1rem' }}>
      <thead>{getTableHeader(table)}</thead>
      <tbody>{getRows(table)}</tbody>
    </table>
  );

  const onConfigureColumns = table => {
    setState({
      showConfigureColumns: !state.showConfigureColumns,
      tableToBeConfigured: table
    });
  };

  const updateParticipantStatus = (source, dest) => {
    dispatch(
      setVFGstatusActions.request({
        sessionId,
        questionJoinerId: state.selectedVfgJoinerId,
        participantIds: state[source].selected.map(s => s.id),
        virtualFocusGroupStatus: dest
      })
    );
    setState({
      [source]: {
        ...state[source],
        selected: [],
        allSelected: false
      }
    });
  };

  const onRemoveSelected = () => {
    updateParticipantStatus(WAITING, UNINVITED);
    toggleRemoveParticipantModal();
  };

  function toggleRemoveParticipantModal() {
    setState({ showRemoveParticipantModal: !state.showRemoveParticipantModal });
  }

  function onEndMeetingClicked() {
    setState({ endMeetingRequested: !state.endMeetingRequested });
  }

  function onEndMeeting() {
    dispatch(
      endVFGMeetingActions.request({
        sessionId,
        questionJoinerId: state.selectedVfgJoinerId
      })
    );
    onEndMeetingClicked();
  }

  if (!vfgData) {
    return <Loader spinner fullScreen />;
  }

  const maxValidationError = state[INVITED].enrollees.length + state[WAITING].selected.length > maxParticipants;
  const activateButtonDisabled = state[WAITING].selected.length === 0 || maxValidationError;

  return (
    <div className="virtual-focus-group">
      <div className="top-row">
        <Input type="select" style={{ width: 'auto' }} value={state.selectedVfgJoinerId} onChange={selectVfgJoiner}>
          {vfgJoiners.map(j => (
            <option value={j.id} key={j.id}>
              {j.researchPrompt}
            </option>
          ))}
        </Input>
        {getVfgStatus()}
      </div>
      {isMeetingComplete ? (
        <VirtualFocusGroupCompleted
          vfgData={vfgData}
          vfgJoiner={vfgJoiner}
          sessionId={sessionId}
          participants={state.INVITED.enrollees}
          setViewLanguage={props.setViewLanguage}
          language={props.language}
          viewLanguage={props.viewLanguage}
        />
      ) : (
        <>
          <div className="link-row">
            <div>{intl.get('app.moderatorLink')}:</div>
            <div className="read-only-link text-truncate">{moderatorUrl}</div>
            <CopyToClipboard
              copyText={moderatorUrl}
              title={intl.get('app.copyLink')}
              toastText={intl.get('app.urlCopiedToClipboard')}
            />
          </div>
          <div className="link-row">
            <div>{intl.get('app.observerLink')}:</div>
            <div className="read-only-link text-truncate">{observerUrl}</div>
            <CopyToClipboard
              copyText={observerUrl}
              title={intl.get('app.copyLink')}
              toastText={intl.get('app.urlCopiedToClipboard')}
            />
          </div>
          {isMeetingActive && hasProjectManage && (
            <div className="link-row">
              <Button color="primary" onClick={onEndMeetingClicked}>
                {' '}
                {intl.get('app.endMeeting')}
              </Button>
            </div>
          )}
          <div className="waiting-and-active">
            <div>
              <strong>
                {intl.get('app.waitingRoom')} ({state[WAITING].enrollees.length})
              </strong>
              <div className="mt-4">
                {(maxValidationError && (
                  <span style={{ color: 'red' }}>
                    {intl.get('app.vfg.maxParticipantMsg', { max: maxParticipants })}
                  </span>
                )) ||
                  intl.get('app.selectParticipants')}
              </div>
              <div className="mt-2 waiting-table-settings">
                <div style={{ width: '70%' }}>
                  <Icons.FilterIcon className="participant-filter" />
                  <FilterSelect
                    filters={filterList}
                    quotaFilters={props.quotaFilters}
                    selectedFilters={state.selectedFilters}
                    onFilterSelect={updateFilters}
                    style={{ width: '60%' }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline', width: '30%', height: '2.5rem' }}>
                  <SearchInput
                    id="vfg-participant-search"
                    className="search-participants me-4"
                    defaultValue={state.defaultValue}
                    placeholder={intl.get('app.search')}
                    onChange={onParticipantInputChange}
                  />
                  <Icons.CogIcon
                    className="small-font clickable mb-4"
                    onClick={() => onConfigureColumns('waitingTableColumnConfig')}
                    title={intl.get('app.configureColumns')}
                  />
                </div>
              </div>
              <div className="table-container">{getTable(WAITING)}</div>
              <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between', width: '98%' }}>
                <Button
                  color="secondary"
                  onClick={() => updateParticipantStatus(WAITING, INVITED)}
                  disabled={activateButtonDisabled}
                >
                  {intl.get('app.activateSelected')}
                </Button>
                <Button
                  className="btn-warn"
                  onClick={toggleRemoveParticipantModal}
                  disabled={state[WAITING].selected.length === 0}
                >
                  {intl.get('app.removeSelected')}
                </Button>
              </div>
            </div>
            <div>
              <strong>
                {intl.get('app.active')} ({state[INVITED].enrollees.length})
              </strong>
              <div className="mt-4">{intl.get('app.activeParticipants')}</div>
              <div className="mt-2" style={{ textAlign: 'right', marginRight: '2%', height: '2.5rem' }}>
                <Icons.CogIcon
                  className="small-font clickable mb-4"
                  onClick={() => onConfigureColumns('invitedTableColumnConfig')}
                  title={intl.get('app.configureColumns')}
                />
              </div>
              <div className="table-container">{getTable(INVITED)}</div>
              <div style={{ marginTop: '1rem', width: '98%' }}>
                <Button
                  className="btn-warn"
                  style={{ float: 'right' }}
                  onClick={() => updateParticipantStatus(INVITED, WAITING)}
                  disabled={state[INVITED].selected.length === 0}
                >
                  {intl.get('app.removeSelected')}
                </Button>
              </div>
            </div>
          </div>
          {!!state.showSelectedEnrollee && (
            <RDParticipantDetailsModal
              sessionId={sessionId}
              participant={enrolleesInfo.enrolleeMap[state.showSelectedEnrollee]}
              toggle={() => onNameClick({})}
              setViewLanguage={props.setViewLanguage}
              language={props.language}
              viewLanguage={props.viewLanguage}
            />
          )}
          {state.showConfigureColumns && (
            <ConfigureDataColumns
              showModal={state.showConfigureColumns}
              toggle={() => onConfigureColumns(state.tableToBeConfigured)}
              rdConfig={rdConfig}
              targetConfig={'virtualFocusGroupConfig'}
              columnConfig={columnConfig}
              defaultColumns={Object.values(DEFAULT_COLUMNS)}
              columnOrder={columnOrder}
              keyTransformMap={keyTransformMap}
              sessionId={sessionId}
              saveRDConfig={saveRDConfig}
              table={state.tableToBeConfigured}
            />
          )}
          {state.showRemoveParticipantModal && (
            <InvokeModal
              showModal
              modalTitle={intl.get('app.removeParticipant')}
              toggle={toggleRemoveParticipantModal}
              primaryButtonText={intl.get('app.remove')}
              cancelButtonText={intl.get('app.cancel')}
              enableSave
              save={onRemoveSelected}
            >
              {intl.get('app.removeParticipantModalText')}
            </InvokeModal>
          )}
          {state.endMeetingRequested && (
            <ConfirmModal
              showModal
              modalTitle={intl.get('app.endMeeting')}
              toggle={onEndMeetingClicked}
              primaryButtonText={intl.get('app.endMeeting')}
              cancelButtonText={intl.get('app.cancel')}
              onConfirm={onEndMeeting}
              enableSave
            >
              <div>{intl.get('app.endMeetingMsg')}</div>
            </ConfirmModal>
          )}
        </>
      )}
    </div>
  );
};
