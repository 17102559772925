import {
  fetchProjectListActions,
  fetchRecentProjectsActions,
  fetchAllProjectsActions,
  fetchArchivedProjectListActions
} from '../actions/projectListActions';
import { fetchProjects, fetchRecentProjects, fetchAllProjects, fetchArchivedProjects } from '../../../api/projectsApi';
import createFetchSaga from './createFetchSaga';

function* projectListSaga() {
  const saga = createFetchSaga(fetchProjectListActions, fetchProjects);
  yield* saga();
}

function* recentProjectsSaga() {
  const saga = createFetchSaga(fetchRecentProjectsActions, fetchRecentProjects);
  yield* saga();
}

function* allProjectsSaga() {
  const saga = createFetchSaga(fetchAllProjectsActions, fetchAllProjects);
  yield* saga();
}

function* archivedProjectListSaga() {
  const saga = createFetchSaga(fetchArchivedProjectListActions, fetchArchivedProjects);
  yield* saga();
}

export { projectListSaga, recentProjectsSaga, allProjectsSaga, archivedProjectListSaga };
