import { handleActions } from 'redux-actions';
import { activeParticipantsActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdActiveParticipantsReducer = handleActions(
  {
    [activeParticipantsActions.succeeded](state, { payload }) {
      const { sessionId, participantCount } = payload;
      return {
        ...state,
        [sessionId]: {
          participantCount
        }
      };
    }
  },
  initialState
);
