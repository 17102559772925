import React, { useRef, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { InvokeModal } from 'webapp-common';
import { getFileExtention } from '../../../util/jsUtil';

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const ImportSurveyModal = props => {
  const {
    show,
    toggle,
    modalTitle = '',
    primaryButtonText = intl.get('app.ok'),
    cancelButtonText = intl.get('app.cancel'),
    fileType = 'text'
  } = props;

  const [state, setState] = useReducer(reducer, {
    dataFile: {},
    showErrorMsg: false
  });
  const { dataFile, showErrorMsg } = state;

  const fileUploadField = useRef();

  const handleSelectFileClick = () => {
    fileUploadField.current.click();
  };

  const onFileSelect = e => {
    const file = e.target.files[0];
    setState({
      dataFile: file,
      showErrorMsg: !fileType.includes(getFileExtention(file.name))
    });
  };

  const onSubmit = () => {
    props.onSubmit({ dataFile });
  };

  return (
    <InvokeModal
      showModal={show}
      className="import-survey-modal"
      toggle={toggle}
      modalTitle={modalTitle}
      cancelButtonText={cancelButtonText}
      primaryButtonText={primaryButtonText}
      enableSave={dataFile.name && !showErrorMsg}
      save={onSubmit}
    >
      <Button onClick={handleSelectFileClick}>
        <span className="fa fa-upload" />
        {intl.get('app.uploadFile')}
      </Button>
      <div className={dataFile.name ? '' : 'invisible'}>
        <span className="fa fa-paperclip" /> {dataFile.name}
      </div>
      <input type="file" className="hidden" accept={fileType} ref={fileUploadField} onChange={onFileSelect} />

      {showErrorMsg && <span className="text-danger">{intl.get('app.uploadFile.fileType', { name: fileType })}</span>}
    </InvokeModal>
  );
};
