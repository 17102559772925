import React from 'react';
import intl from 'react-intl-universal';
import { Switch } from 'antd';

import './AllowedDevices.css';

const DESKTOP = 'DESKTOP';
const TABLET = 'TABLET';
const PHONE = 'PHONE';

export const AllowedDevices = props => {
  const { agentSupport = [], origAgentSupport, readOnly, setSessionField } = props;

  function updateAgentSupport(device) {
    const copy = [...agentSupport];
    const index = copy.indexOf(device);
    if (index === -1) {
      copy.push(device);
    } else {
      copy.splice(index, 1);
    }

    if (copy.toSorted().join() === [...origAgentSupport].toSorted().join()) {
      setSessionField('agentSupport', origAgentSupport);
    } else {
      setSessionField('agentSupport', copy);
    }
  }

  return (
    <div className="allowed-devices">
      <div>
        <div>{intl.get('app.desktopSlashLaptop')}</div>
        <Switch
          checked={agentSupport.indexOf(DESKTOP) !== -1}
          onChange={() => updateAgentSupport(DESKTOP)}
          disabled={readOnly}
        />
      </div>
      <div>
        <div>{intl.get('app.tablet')}</div>
        <Switch
          checked={agentSupport.indexOf(TABLET) !== -1}
          onChange={() => updateAgentSupport(TABLET)}
          disabled={readOnly}
        />
      </div>
      <div>
        <div>{intl.get('app.mobilePhone')}</div>
        <Switch
          checked={agentSupport.indexOf(PHONE) !== -1}
          onChange={() => updateAgentSupport(PHONE)}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};
