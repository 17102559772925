import { handleActions } from 'redux-actions';
import { find, get } from 'lodash';
import {
  fetchParticipantDataDefActions,
  fetchParticipantsActions,
  fetchEnrollmentInfoActions,
  receiveScreenerResponseDataActions,
  pingParticipantDataActions,
  receiveScreenerSummaryActions,
  fetchScreenerPreviewResultsActions
} from '../actions/participantDataActions';

const initialState = {};

const participantDataReducer = handleActions(
  {
    [fetchParticipantDataDefActions.triggered](state, { payload: sessionId }) {
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          participantDataDefRequested: true
        }
      };
    },

    [fetchParticipantDataDefActions.succeeded](state, { payload }) {
      return {
        ...state,
        [payload.sessionId]: {
          ...state[payload.sessionId],
          participantDataDefs: payload.defs,
          participantDataDefRequested: false
        }
      };
    },

    [fetchParticipantDataDefActions.failed](state, { payload: { sessionId } }) {
      return {
        ...state,
        [sessionId]: {
          ...state[sessionId],
          participantDataDefRequested: false
        }
      };
    },

    [fetchParticipantsActions.triggered](state, { payload }) {
      const { entityId } = payload;
      return {
        ...state,
        [entityId]: {
          ...state[entityId],
          participantDataRequested: true
        }
      };
    },

    [fetchParticipantsActions.succeeded](state, { payload }) {
      const { entityId, participantData } = payload;
      return {
        ...state,
        [entityId]: {
          ...state[entityId],
          participantDataRequested: false,
          participantData,
          metadataCollection: find(participantData.metadataCollections, mc => mc.id === entityId)
        }
      };
    },

    [fetchParticipantsActions.failed](state, { payload: { participantsRequest } }) {
      const id = participantsRequest.sessionId || participantsRequest.id;
      return {
        ...state,
        [id]: {
          ...state[id],
          participantDataRequested: false
        }
      };
    },

    [fetchEnrollmentInfoActions.triggered](state, { payload }) {
      const { metaDataCollectionId } = payload;
      return {
        ...state,
        [metaDataCollectionId]: {
          ...state[metaDataCollectionId],
          enrollmentInfoRequested: true
        }
      };
    },

    [fetchEnrollmentInfoActions.succeeded](state, { payload }) {
      const { metaDataCollectionId, enrollmentInfo } = payload;
      return {
        ...state,
        [metaDataCollectionId]: {
          ...state[metaDataCollectionId],
          enrollmentInfoRequested: false,
          enrollmentInfo
        }
      };
    },

    [fetchEnrollmentInfoActions.failed](state, { payload }) {
      const { metaDataCollectionId } = payload;
      return {
        ...state,
        [metaDataCollectionId]: {
          ...state[metaDataCollectionId],
          enrollmentInfoRequested: false
        }
      };
    },

    [receiveScreenerResponseDataActions.succeeded](
      state,
      {
        payload: {
          sessionId,
          participantTotal,
          partDataSummaries,
          partDataTotals: { answers }
        }
      }
    ) {
      return {
        ...state,
        [sessionId]: {
          participantTotal,
          screenersAnswersTotal: answers,
          screenersAnswersSummaries: partDataSummaries && partDataSummaries.responses
        },
        screenerSummary: {
          ...state.screenerSummary,
          [sessionId]: {
            ...get(state, `screenerSummary[${sessionId}]`),
            totalParticipants: participantTotal
          }
        }
      };
    },

    [pingParticipantDataActions.succeeded](state, { payload }) {
      const { entityId, participantData } = payload;
      return {
        ...state,
        [entityId]: {
          ...state[entityId],
          participantData,
          metadataCollection: find(participantData.metadataCollections, mc => mc.id === entityId)
        }
      };
    },

    [receiveScreenerSummaryActions.succeeded](state, { payload }) {
      const { sessionId } = payload;
      return {
        ...state,
        screenerSummary: {
          ...state.screenerSummary,
          [sessionId]: {
            ...payload,
            lastUpdated: Date.now()
          }
        }
      };
    },

    [fetchScreenerPreviewResultsActions.triggered](state, { payload }) {
      const { sessionId } = payload;
      return {
        ...state,
        screenerPreviewResults: {
          ...state.screenerPreviewResults,
          [sessionId]: {
            screenerPreviewResultsRequested: true
          }
        }
      };
    },

    [fetchScreenerPreviewResultsActions.succeeded](state, { payload }) {
      const { screenerPreviewResults, sessionId } = payload;
      return {
        ...state,
        screenerPreviewResults: {
          ...state.screenerPreviewResults,
          [sessionId]: {
            screenerPreviewResultsRequested: false,
            ...screenerPreviewResults
          }
        }
      };
    },

    [fetchScreenerPreviewResultsActions.failed](state, { payload }) {
      const { sessionId } = payload;
      return {
        ...state,
        screenerPreviewResults: {
          ...state.screenerPreviewResults,
          [sessionId]: {
            screenerPreviewResultsRequested: false
          }
        }
      };
    }
  },
  initialState
);
export default participantDataReducer;
