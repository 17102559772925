import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';

export const ProdegeCountrySelector = props => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', ...(props.style || {}) }}>
      {intl.get('app.country')}:
      <Input
        type="select"
        style={{ marginLeft: '1rem', width: 'auto' }}
        value={props.selectedCountry}
        disabled={props.disabled}
        onChange={props.onCountrySelect}
      >
        <option value="1">{intl.get('app.prodege.country.1')}</option>
      </Input>
    </div>
  );
};
