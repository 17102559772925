import createFetchActions from './createFetchActions';

const fetchProjectListActions = createFetchActions('PROJECT_LIST');
const fetchRecentProjectsActions = createFetchActions('RECENT_PROJECTS');
const fetchAllProjectsActions = createFetchActions('ALL_PROJECTS');
const fetchArchivedProjectListActions = createFetchActions('ARCHIVED_PROJECT_LIST');

export {
  fetchProjectListActions,
  fetchRecentProjectsActions,
  fetchAllProjectsActions,
  fetchArchivedProjectListActions
};
