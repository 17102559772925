import { createSelector, createStructuredSelector } from 'reselect';

const selectFunctionStore = state => state.function;
const selectFunctionListRequested = functionStore => functionStore.functionListRequested;
const selectFunctionList = functionStore => functionStore.functions;

const selectAllFunctionsRequested = functionStore => functionStore.allFunctionsRequested;
const selectAllFunctions = functionStore => functionStore.allFunctions;

const selectFunctionId = (state, { selectedFunction: { id: functionId } = {} }) => functionId;
const selectFunctionRequested = (functionStore, functionId) => functionStore.functionRequested;
const selectFunction = (functionStore, functionId) => functionStore.functions[functionId];

const functionListSelector = () =>
  createSelector(
    selectFunctionStore,
    createStructuredSelector({
      functionsRequested: selectFunctionListRequested,
      functions: selectFunctionList
    })
  );

const functionSelector = () =>
  createSelector(
    [selectFunctionStore, selectFunctionId],
    createStructuredSelector({
      functionRequested: selectFunctionRequested,
      functionRule: selectFunction
    })
  );

const allFunctionsSelector = () =>
  createSelector(
    selectFunctionStore,
    createStructuredSelector({
      functionsRequested: selectAllFunctionsRequested,
      functions: selectAllFunctions
    })
  );

export { functionListSelector, functionSelector, allFunctionsSelector };
