import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'reactstrap';
import { isEqual } from 'lodash';
import { InvokeModal } from 'webapp-common';
import { SelectDataPointsModal } from '../selectDataPoints/SelectDataPointsModal';
import { filterUtil } from '../../../../util/filterUtil';

import './AdvancedFilterBuilder.css';

const ADVANCED_FILTER = true;
const reducer = (state, payload) => {
  return { ...state, ...payload };
};

export const AdvancedFilterBuilder = props => {
  const {
    filter,
    sessionId,
    title,
    onSave,
    toggle,
    enrolleesInfo = {},
    project,
    questionJoiners,
    rdConfig,
    checkFilter,
    filterResult,
    isQuota
  } = props;

  const [state, setState] = useReducer(reducer, {
    showDataPoints: false,
    filterToEdit: null,
    filterComponents: []
  });

  const getQueryString = (components, prop) => {
    let query = '';
    components.forEach(c => {
      if (typeof c === 'object') {
        query += c[prop];
      } else {
        const op = c === 'not' ? '!' : c;
        query += ` ${op} `;
      }
    });
    return query;
  };

  useEffect(() => {
    if (filter && filter.expression && questionJoiners && enrolleesInfo) {
      const allTerms = filterUtil.getFilterExpression(filter.expression);
      const filterComponents = [];
      allTerms.forEach((term, i) => {
        if (term.type === filterUtil.TERM_TYPE.operator || term.type === filterUtil.TERM_TYPE.bracket) {
          if (term.value === '!') {
            filterComponents.push('not');
          } else {
            filterComponents.push(term.value);
          }
        } else {
          const component = filterUtil.renderDataPointExpression(
            { expression: filterUtil.makeCleanFilterExpression(term) },
            questionJoiners,
            enrolleesInfo.keyTransformMap
          );
          component.key += `-${i}`;
          filterComponents.push(component);
        }
      });
      setState({ filterComponents });
    }
  }, [filter]);

  useEffect(() => {
    if (state.filterComponents.length > 0) {
      const filterString = getQueryString(state.filterComponents, 'expression');
      const description = getQueryString(state.filterComponents, 'filterStr');
      checkFilter({
        sessionId,
        filterString
      });
      setState({ description, expression: filterString });
    }
  }, [state.filterComponents]);

  useEffect(() => {
    if (filterResult.sessionId === sessionId) {
      setState({ ...filterResult });
    }
  }, [filterResult]);

  const moveComponent = (srcIndex, targetIndex) => {
    const filterComponents = [...state.filterComponents];
    const [removed] = filterComponents.splice(srcIndex, 1);
    filterComponents.splice(targetIndex, 0, removed);
    setState({ filterComponents });
  };

  const toggleDataPoints = filterToEdit => {
    setState({ showDataPoints: !state.showDataPoints, filterToEdit });
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    moveComponent(result.source.index, result.destination.index);
  };

  const removeFilterPart = index => {
    const filterComponents = [...state.filterComponents];
    filterComponents.splice(index, 1);
    setState({ filterComponents });
  };

  const getStyle = (backgroundColor, isDragging, draggableStyle) => ({
    background: isDragging ? 'lightgreen' : backgroundColor,
    ...draggableStyle
  });

  const getFilterExpressionElem = term => {
    return filterUtil.renderDataPointExpression(
      { expression: filterUtil.makeCleanFilterExpression(term) },
      questionJoiners,
      enrolleesInfo.keyTransformMap
    );
  };

  const displayFilterComponents = () => {
    return state.filterComponents.map((c, i) => {
      let backgroundColor = '#f5f5f5';
      let fontSize = '0.75rem';
      let isDataPoints = false;
      let id = typeof c === 'object' ? `advanced-filter-${i}` : `operator-${i}`;
      if (c === '(' || c === ')') {
        backgroundColor = '#dafac6';
        fontSize = '2rem';
      } else if (['and', 'or', 'not'].indexOf(c) >= 0) {
        backgroundColor = '#eee7fa';
        fontSize = '1rem';
      } else {
        isDataPoints = true;
        id = `data-point-${i}`;
      }
      return (
        <Draggable key={id} draggableId={id} index={i}>
          {(provided, snapshot) => (
            <div
              className="filter-parts"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getStyle(backgroundColor, snapshot.isDragging, provided.draggableProps.style)}
            >
              <div className="filter-eraser" onClick={() => removeFilterPart(i)}>
                <span style={{ float: 'right' }}>x</span>
              </div>
              {isDataPoints ? (
                <div
                  style={{ fontSize }}
                  className="data-point"
                  onClick={() => toggleDataPoints(c)}
                  title={c.filterStr}
                  key={c.key}
                >
                  <div className="field">{c.fromQuestion}</div>
                  <div className="value">{c.fromAnswer}</div>
                </div>
              ) : (
                <div className="parts-label uppercase" style={{ fontSize }}>
                  {c}
                </div>
              )}
            </div>
          )}
        </Draggable>
      );
    });
  };

  const onComponentAdd = (component, isDataPoints) => {
    const filterComponents = [...state.filterComponents];
    const index = state.filterComponents.findIndex(c => isEqual(c, state.filterToEdit));
    if (index >= 0) {
      filterComponents.splice(index, 1, ...component);
    } else if (!isDataPoints) {
      filterComponents.push(component);
    } else {
      if (
        filterComponents.length > 0 &&
        ['and', 'or', 'not', '!', '(', ')'].indexOf(filterComponents[filterComponents.length - 1]) === -1
      ) {
        filterComponents.push('or');
      }
      component.forEach(c => {
        filterComponents.push(c);
      });
    }
    setState({ filterComponents });
  };

  const addDataPoints = expression => {
    const terms = filterUtil.getFilterExpression(expression);
    const converted = terms.map(term => (term.type === 'operator' ? term.value : getFilterExpressionElem(term)));
    onComponentAdd(converted, true);
    toggleDataPoints();
  };

  const getMatches = () => (state.enrolleeMap && Object.keys(state.enrolleeMap).length) || 0;

  const saveFilter = () => {
    onSave(state.expression, ADVANCED_FILTER);
  };

  return (
    <div>
      <InvokeModal
        showModal
        toggle={toggle}
        className="advance-filter-editor"
        modalTitle={title}
        primaryButtonText={intl.get('app.save')}
        save={saveFilter}
        cancelButtonText={intl.get('app.cancel')}
        enableSave={state.filterComponents.length > 0 && !state.exception}
      >
        <div>
          <div className="hint">{intl.get('app.advanceFilter.hint')}</div>
          <div className="dnd-area">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div style={{ display: 'flex' }} ref={provided.innerRef} {...provided.droppableProps}>
                    {displayFilterComponents()}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="mt-4">
            {state.exception && (
              <div style={{ color: 'red' }}>
                <i className="fa fa-exclamation-triangle me-4" style={{ fontSize: '1.5rem', color: 'red' }} />
                {intl.get('app.filter.exception')}
              </div>
            )}
          </div>
          <div className="mt-4">
            <Button secondary className="me-3" onClick={toggleDataPoints}>
              {intl.get('app.dataPoints')}
            </Button>
            <Button secondary className="me-3 uppercase" onClick={() => onComponentAdd(intl.get('app.and'))}>
              {intl.get('app.and')}
            </Button>
            <Button secondary className="me-3 uppercase" onClick={() => onComponentAdd(intl.get('app.or'))}>
              {intl.get('app.or')}
            </Button>
            <Button secondary className="me-3 uppercase" onClick={() => onComponentAdd(intl.get('app.not'))}>
              {intl.get('app.not')}
            </Button>
            <Button secondary className="me-3 uppercase" onClick={() => onComponentAdd('(')}>
              {'('}
            </Button>
            <Button secondary className="me-3 uppercase" onClick={() => onComponentAdd(')')}>
              {')'}
            </Button>
          </div>
          <div className="mt-4" style={{ fontWeight: '600' }}>
            {intl.get('app.filter.matches', { number: getMatches() })}
          </div>
        </div>
      </InvokeModal>
      {state.showDataPoints && (
        <SelectDataPointsModal
          filter={state.filterToEdit}
          enrolleesInfo={enrolleesInfo}
          project={project}
          questionJoiners={questionJoiners}
          rdConfig={rdConfig}
          selectedTab="participantData"
          toggle={toggleDataPoints}
          onSave={addDataPoints}
          isQuota={isQuota}
        />
      )}
    </div>
  );
};
