import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import { toast } from '../../../../util/toast';
import appConfig from '../../../../appConfig';
import { fetchProjectActions } from '../../../../store/redux/actions/fetchProject';
import { fetchSaveSessionAction, fetchAllSessionsAction } from '../../../../store/redux/actions/sessionActions';
import { fetchSurveyActions } from '../../../../store/redux/actions/surveyActions';
import { saveProjectActions } from '../../../../store/redux/actions/saveProject';
import { archiveProjectActions } from '../../../../store/redux/actions/archiveProjectActions';
import {
  useProjectSelector,
  useSessionSelector,
  useSessionUserSelector,
  useSurveySelector
} from '../../../../customHooks/reduxHelper';
import { sessionStates } from '../../../../util/sessionUtil';
import { RECRUIT_TAB, REPORTS_AND_DATA_TAB, removeRecentProject } from '../../../../util/projectUtil';
import { feasibilityCheck, getProdegeTestUrl } from '../../../../api/prodegeApi';
import { Icons } from '../../../../components/icons/Icons';
import { ConnectedArchiveModal } from '../../../../containers/projectDetails/projectArchiveForm';

import './ProjectDetailsSS.css';

const stepStates = {
  locked: 'locked',
  tbd: 'tbd',
  ok: 'ok'
};

const SelfServStep = props => {
  return (
    <div className="self-serve-step">
      {props.icon}
      <div>{props.num}</div>
      <div className="step-desc">
        <div>{props.title}</div>
        <div>{props.desc}</div>
      </div>
    </div>
  );
};

export const ProjectDetailsSS = props => {
  const { projectId, readOnly } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { projectDetails: project } = useProjectSelector({ projectId });
  const { prodegeConfig = {} } = project;

  const { allSessions, requestInProgress } = useSessionSelector();
  const { sessionUser } = useSessionUserSelector();
  const session = allSessions.content?.find(s => s.projectId === projectId);

  const { fetchSurveyInProgress, survey } = useSurveySelector({ surveyId: session?.surveyId });

  const [projectName, setProjectName] = useState(project.name || '');
  const [showArchive, setShowArchive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectActions.request({ projectId }));
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchAllSessionsAction.request({ projectId }));
    }
  }, [prodegeConfig.feasibilityCheck]);

  useEffect(() => {
    if (session?.surveyId) {
      dispatch(fetchSurveyActions.request({ surveyId: session.surveyId }));
    }
  }, [session]);

  function saveProject() {
    dispatch(
      saveProjectActions.request({
        ...project,
        name: projectName.trim()
      })
    );
  }

  const [feasibilityCheckMutation, { isLoading: feasibilityCheckInProgress }] = useMutation(
    payload => feasibilityCheck(payload),
    {
      onError: error => toast.error({ text: error.errorMessage }),
      onSuccess: updatedProject => dispatch(fetchProjectActions.succeeded(updatedProject))
    }
  );

  props.showLoader(requestInProgress || fetchSurveyInProgress || feasibilityCheckInProgress || showLoader);

  function updateProjectName(e) {
    setProjectName(e.target.value);
  }

  function launchOrPause() {
    setSessionState(session.state === sessionStates.OPEN ? sessionStates.PAUSED : sessionStates.OPEN);
  }

  function setSessionState(state) {
    const payload = {
      ...session,
      state
    };
    dispatch(fetchSaveSessionAction.request({ payload }));
  }

  function getStepIcon(stepState) {
    return stepState === stepStates.locked ? <Icons.LockIcon /> : <Icons.CheckIcon className={stepState} />;
  }

  function archive() {
    dispatch(archiveProjectActions.request({ projectId: project.id, name: project.name }));
    navigate(appConfig.archivedProjectsPath);
    removeRecentProject(project.id, sessionUser.clientId);
    toggleArchiveModal();
  }

  function toggleArchiveModal() {
    setShowArchive(!showArchive);
  }

  async function openTestUrl() {
    setShowLoader(true);
    const url = await getProdegeTestUrl(prodegeConfig.prodegeProjectId);
    setShowLoader(false);
    window.open(url);
  }

  const isSessionOpen = session?.state === sessionStates.OPEN;
  const isSessionPaused = session?.state === sessionStates.PAUSED;
  const isSessionClosed = session?.state === sessionStates.CLOSED;
  const isSessionTest = session?.state === sessionStates.TEST;

  const step1State = survey?.joiners?.length > 0 ? stepStates.ok : stepStates.tbd;
  const step2State = prodegeConfig.quotas?.length > 0 ? stepStates.ok : stepStates.tbd;
  const step3State =
    ((step1State !== stepStates.ok || step2State !== stepStates.ok) && stepStates.locked) ||
    (!prodegeConfig.feasibilityCheck && stepStates.tbd) ||
    stepStates.ok;
  const step4State =
    ((isSessionOpen || isSessionClosed) && stepStates.ok) ||
    (step3State === stepStates.ok && stepStates.tbd) ||
    stepStates.locked;
  const step5State =
    (isSessionClosed && stepStates.ok) || ((isSessionOpen || isSessionPaused) && stepStates.tbd) || stepStates.locked;

  const surveyEditHref = `${appConfig.projectPagePath}/${projectId}/survey/${session?.id}/editor`;
  const recruitHref = `${appConfig.projectPagePath}/${projectId}/${RECRUIT_TAB}`;
  const surveyResponsesHref = `${appConfig.projectPagePath}/${projectId}/survey/${session?.id}/responses`;
  const reportsHref = `${appConfig.projectPagePath}/${projectId}/${REPORTS_AND_DATA_TAB}`;

  return (
    <section className="project-details-ss">
      <div className="project-name-and-status">
        <div>
          <div>{intl.get('app.projectName')}:</div>
          <Input value={projectName} disabled={readOnly} onChange={updateProjectName} />
          <Button
            className="save-button"
            color="primary"
            disabled={projectName.trim() === project.name}
            onClick={saveProject}
          >
            {intl.get('app.save')}
          </Button>
        </div>
        <div>
          <div>{intl.get('app.currentStatus')}:</div>
          <div className="status-box">{isSessionOpen ? intl.get('app.active').toUpperCase() : session?.state}</div>
        </div>
      </div>
      <div>{intl.get('app.selfServe.getStarted')}</div>
      <SelfServStep
        icon={getStepIcon(step1State)}
        num="1"
        title={intl.get('app.programSurvey')}
        desc={
          <>
            {intl.get('app.programSurvey.desc')}&nbsp;
            <NavLink to={surveyEditHref}>{intl.get('app.surveyEditorTab')}</NavLink>
          </>
        }
      />
      <SelfServStep
        icon={getStepIcon(step2State)}
        num="2"
        title={intl.get('app.recruitParticipants')}
        desc={
          <>
            {intl.get('app.recruitParticipants.desc')}&nbsp;
            <NavLink to={recruitHref}>{intl.get('app.recruitTab')}</NavLink>
          </>
        }
      />
      <SelfServStep
        icon={getStepIcon(step3State)}
        num="3"
        title={intl.get('app.preFlightCheck')}
        desc={
          <>
            <div className="me-4">
              <Button disabled={step3State !== stepStates.tbd} onClick={() => feasibilityCheckMutation(project)}>
                {intl.get('app.feasibilityCheck')}
              </Button>
              {step3State === stepStates.ok && (
                <div style={{ fontSize: '75%', textAlign: 'center' }}>{intl.get('app.feasibilityCheckPassed')}</div>
              )}
            </div>
            <Button disabled={!isSessionTest} onClick={openTestUrl}>
              {intl.get('app.testSurvey')}
            </Button>
          </>
        }
      />
      <SelfServStep
        icon={getStepIcon(step4State)}
        num="4"
        title={intl.get('app.goLive')}
        desc={
          <>
            <Button
              className="me-4"
              disabled={step4State === stepStates.locked || isSessionClosed}
              onClick={launchOrPause}
            >
              {isSessionOpen ? intl.get('app.pause') : intl.get('app.launch')}
            </Button>
            {intl.get('app.goLive.desc')}&nbsp;
            <NavLink to={surveyResponsesHref}>{intl.get('app.surveyResponsesTab')}</NavLink>
          </>
        }
      />
      <SelfServStep
        icon={getStepIcon(step5State)}
        num="5"
        title={intl.get('app.finalizeAndReport')}
        desc={
          <>
            <Button
              className="me-4"
              disabled={step5State !== stepStates.tbd}
              onClick={() => setSessionState(sessionStates.CLOSED)}
            >
              {intl.get('app.complete')}
            </Button>
            {intl.get('app.finalizeAndReport.desc')}&nbsp;
            <NavLink to={reportsHref}>{intl.get('app.reportsTab')}</NavLink>
          </>
        }
      />
      <div className="align-right">
        <Button color="secondary" onClick={toggleArchiveModal} disabled={readOnly}>
          {intl.get('app.archiveProject')}
        </Button>
      </div>
      {showArchive && (
        <ConnectedArchiveModal
          toggle={toggleArchiveModal}
          onSave={archive}
          projectName={project.name}
          projectId={project.id}
        />
      )}
    </section>
  );
};
