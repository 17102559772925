import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';

export const ConfirmJoinerRemove = ({ toggle, joiner, deleteJoiner }) => {
  const title = joiner.container ? intl.get('app.title.deletePage') : intl.get('app.title.deleteQuestion');
  const text = joiner.container ? intl.get('app.page.delete.confirm') : intl.get('app.question.delete.confirm');
  return (
    <InvokeModal
      showModal
      toggle={toggle}
      modalTitle={title}
      primaryButtonText={intl.get('app.delete')}
      cancelButtonText={intl.get('app.cancel')}
      save={deleteJoiner}
      enableSave={true}
    >
      {text}
    </InvokeModal>
  );
};
