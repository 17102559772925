import { takeEvery, takeLatest, select } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  dialDataChannelSubscribeActions,
  dialDataFileChannelSubscribeActions,
  exportDialDataActions,
  fetchDialSettingConfigActions,
  saveDialSettingConfigActions,
  fetchVideoWatermarkActions,
  deleteVideoWatermarkActions
} from '../actions/dialDataActions';
import { subscribeToChannel } from '../../../util/websocket';
import { sessionUserSelector } from '../selectors/sessionUserSelector';
import { generateReport } from '../../../api/reportsApi';
import {
  fetchDialSettingConfig,
  saveDialSettingConfig,
  fetchVideoWatermark,
  deleteVideoWatermark
} from '../../../api/dialSettingConfigApi';

function* dialDataChannelSubscribe({ payload: { subAction, sessionId, joinerId } }) {
  yield* subscribeToChannel({
    action: 'dialData',
    subAction,
    key: `${sessionId}_${joinerId}`,
    sessionId,
    joinerId
  });
}

function* dialDataFileChannelSubscribe({ payload: { subAction, rdConfig } }) {
  yield* subscribeToChannel({
    action: 'dialReportFilesData',
    subAction,
    key: `${rdConfig.sessionId}`,
    rdConfig
  });
}

function* handleExportDialDataRequested({ payload }) {
  const state = yield select();
  const sessionUser = sessionUserSelector(state).sessionUser;
  const email = (sessionUser && sessionUser.email) || '';
  toast.success({ text: intl.get('app.dialReport.mail', { mail: email, ref: payload.id }) });
}

function* dialDataExportSaga() {
  const saga = createFetchSaga(exportDialDataActions, generateReport);
  yield* saga();
  yield takeLatest(exportDialDataActions.succeeded, handleExportDialDataRequested);
}

function* dialDataFileChannelSubscribeSaga() {
  yield takeEvery(dialDataFileChannelSubscribeActions.request, dialDataFileChannelSubscribe);
}

function* dialDataChannelSubscribeSaga() {
  yield takeEvery(dialDataChannelSubscribeActions.request, dialDataChannelSubscribe);
}

function* fetchDialSettingConfigSaga() {
  const saga = createFetchSaga(fetchDialSettingConfigActions, fetchDialSettingConfig);
  yield* saga();
}

function* saveDialSettingConfigSaga() {
  const saga = createFetchSaga(saveDialSettingConfigActions, saveDialSettingConfig);
  yield* saga();
}

function* fetchVideoWatermarkSaga() {
  const saga = createFetchSaga(fetchVideoWatermarkActions, fetchVideoWatermark);
  yield* saga();
}

function* deleteVideoWatermarkSaga() {
  const saga = createFetchSaga(deleteVideoWatermarkActions, deleteVideoWatermark);
  yield* saga();
}

export {
  dialDataChannelSubscribeSaga,
  dialDataFileChannelSubscribeSaga,
  dialDataExportSaga,
  fetchDialSettingConfigSaga,
  saveDialSettingConfigSaga,
  fetchVideoWatermarkSaga,
  deleteVideoWatermarkSaga
};
