import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { VIDEO_STIM_OPTIONS } from '../../../../../util/joinerUtil';
import { getVideoStimOptionsFromCRJoiner } from '../../../../../util/conceptRotationUtil';

import './DetectionOptions.css';

const PAUSE_VIDEO = 'PAUSE_VIDEO';
const TERMINATE = 'TERMINATE';

export const DetectionOptions = props => {
  const { updateHandlers, readOnly, crJoiner } = props;

  const options = crJoiner ? getVideoStimOptionsFromCRJoiner(crJoiner) : props.options;

  if (!options) {
    return null;
  }

  return (
    <div className="detect-options">
      <div className="flex-row detect-options-row">
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={options.detectRecordingDevice}
            onChange={e =>
              updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.detectRecordingDevice, e.target.checked)
            }
          />
          {intl.get('app.detectRecordingDevices')}
        </label>
        <label>
          {intl.get('app.onDetection')}:
          <Input
            type="select"
            value={options.detectRecordingDeviceAction}
            disabled={readOnly || !options.detectRecordingDevice}
            onChange={e =>
              updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.detectRecordingDeviceAction, e.target.value)
            }
          >
            <option value={PAUSE_VIDEO}>{intl.get('app.onDetection.pauseVideo')}</option>
            <option value={TERMINATE}>{intl.get('app.onDetection.terminate')}</option>
          </Input>
        </label>
      </div>
      <div className="flex-row detect-options-row">
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={options.detectMultipleViewers}
            onChange={e =>
              updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.detectMultipleViewers, e.target.checked)
            }
          />
          {intl.get('app.detectMultipleViewers')}
        </label>
        <label>
          {intl.get('app.onDetection')}:
          <Input
            type="select"
            value={options.detectMultipleViewersAction}
            disabled={readOnly || !options.detectMultipleViewers}
            onChange={e =>
              updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.detectMultipleViewersAction, e.target.value)
            }
          >
            <option value={PAUSE_VIDEO}>{intl.get('app.onDetection.pauseVideo')}</option>
            <option value={TERMINATE}>{intl.get('app.onDetection.terminate')}</option>
          </Input>
        </label>
      </div>
      <div>
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={options.detectEmotions}
            onChange={e => updateHandlers.updateVideoStimOptions(VIDEO_STIM_OPTIONS.detectEmotions, e.target.checked)}
          />
          {intl.get('app.detectEmotions')}
        </label>
      </div>
    </div>
  );
};
