import { connect } from 'react-redux';
import { fetchRolesSummaryAction, saveUserActions } from '../../../store/redux/actions/clientAdminActions';
import { fetchAllProjectsActions } from '../../../store/redux/actions/projectListActions';
import { clientAdminUsersSelector } from '../../../store/redux/selectors/clientAdminUsersSelector';
import { tenantRolesSelector } from '../../../store/redux/selectors/clientAdminTenantRolesSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import { allProjectsSelector } from '../../../store/redux/selectors/projectListSelector';
import { UserEdit } from './UserEdit';

const mapStateToProps = (state, { user }) => {
  const usersSelector = clientAdminUsersSelector()(state, user.userID);
  const rolesSelector = tenantRolesSelector()(state);
  const projectsSelector = allProjectsSelector(state);
  const { tenantRoles } = rolesSelector;
  const { hasUserAccountsManage } = sessionUserSelector(state).abilities;
  return {
    roles: tenantRoles.summary.content,
    ...projectsSelector,
    rolesSummaryRequested: tenantRoles.rolesSummaryRequested,
    saveUserInProgress: usersSelector.saveUserInProgress,
    userSaveSuccess: usersSelector.userSaveSuccess,
    hasUserAccountsManage
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRoles: payload => dispatch(fetchRolesSummaryAction.request(payload)),
  fetchAllProjects: () => dispatch(fetchAllProjectsActions.request()),
  saveUser: payload => dispatch(saveUserActions.request(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
