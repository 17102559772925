import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Button, Input } from 'reactstrap';
import localtime from '../../../../util/localtime';
import { panelConfigUtil } from '../../../clientAdmin/panelConfigs/panelConfigUtil';
import { PanelNameRow } from './PanelNameRow';

function getDefaultConfig() {
  return panelConfigUtil.getDefaultPanelConfig({
    type: panelConfigUtil.panelConfigType.DYNATA,
    active: false,
    panelId: 'samplify',
    name: 'Dynata - Samplify'
  });
}

export const DynataSamplifyConfig = props => {
  const { isScreener, readOnly, onPanelSelect, setQuota, setExpectedIR, setExternalId, updateDynataPanelConfig } =
    props;
  const config = props.config || getDefaultConfig();
  const isSelected = config.active;

  function openInSamplify() {
    const url = new URL(config.completedUrl);
    const { hostname } = url;
    const domain = hostname.replace('api.', 'samplify.');
    window.open(`https://${domain}/project/${config.externalId}/surveys`, '_blank');
  }

  return (
    <div className={classnames({ panel: true, 'selected-panel': isSelected })}>
      <PanelNameRow
        config={config}
        maxQuota={config.maxQuota}
        expectedIR={config.expectedIR}
        isSelected={isSelected}
        isScreener={isScreener}
        readOnly={readOnly}
        onPanelSelect={onPanelSelect}
        setQuota={setQuota}
        setExpectedIR={setExpectedIR}
      />
      {isSelected && (
        <div className="samplify-config">
          <div>
            <label style={{ whiteSpace: 'nowrap', marginRight: '1rem' }}>{intl.get('app.samplifyProjectId')}:</label>
            <Input
              style={{ marginRight: '1rem', width: '25rem' }}
              placeholder="extProjectId"
              value={config.externalId}
              onChange={e => setExternalId(config, e.target.value)}
            />
            {config.modifiedDate && (
              <i className="fas fa-external-link-alt" title={intl.get('app.openInSamplify')} onClick={openInSamplify} />
            )}
          </div>
          <div style={{ marginBottom: '.75rem' }}>
            <Button
              style={{ margin: '0 1rem 0 0', padding: '0 .5rem' }}
              disabled={!config.externalId}
              onClick={() => updateDynataPanelConfig(config)}
            >
              {intl.get('app.updateProject')}
            </Button>
            {config.modifiedDate && (
              <span style={{ fontSize: '85%' }}>
                {intl.get('app.lastUpdated')}: {localtime.getFormattedDate(config.modifiedDate)}
              </span>
            )}
          </div>
          <div className="read-only-url-row">
            <label>{intl.get('app.completed')}:</label>{' '}
            <Input value={panelConfigUtil.buildUrl(config, 'completed')} disabled />
          </div>
          <div className="read-only-url-row">
            <label>{intl.get('app.terminated')}:</label>{' '}
            <Input value={panelConfigUtil.buildUrl(config, 'terminate')} disabled />
          </div>
          <div className="read-only-url-row">
            <label>{intl.get('app.overQuota')}:</label>{' '}
            <Input value={panelConfigUtil.buildUrl(config, 'overQuota')} disabled />
          </div>
        </div>
      )}
    </div>
  );
};
