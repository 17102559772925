import { handleActions } from 'redux-actions';
import { newParticipantAnswersActions } from '../../actions/researchDashboardActions';

const initialState = {};

export const rdParticipantAnswersReducer = handleActions(
  {
    [newParticipantAnswersActions.succeeded](state, { payload }) {
      const { sessionId, participantId, questionAnswers } = payload;
      return {
        ...state,
        [`${sessionId}-${participantId}`]: questionAnswers
      };
    }
  },
  initialState
);
