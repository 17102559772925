import createFetchActions from './createFetchActions';

// clients/tenants
const fetchClientsActions = createFetchActions('FETCH_CLIENTS');
const fetchClientActions = createFetchActions('FETCH_CLIENT');
const createClientActions = createFetchActions('CREATE_CLIENT');
const saveClientActions = createFetchActions('SAVE_CLIENT');
const archiveClientActions = createFetchActions('ARCHIVE_CLIENT');
const uploadLogoActions = createFetchActions('UPLOAD_LOGO');
const updateTenantLogoActions = createFetchActions('UPDATE_TENANT_LOGO');
const fetchRolesSummaryAction = createFetchActions('FETCH_ALL_ADMIN_ROLES');
const fetchTenantRolesAction = createFetchActions('FETCH_TENANT_ROLES');
const updateTenantRolesAction = createFetchActions('UPDATE_TENANT_ROLES');
const deleteTenantRolesAction = createFetchActions('DELETE_TENANT_ROLES');
const fetchTenantAbilitiesAction = createFetchActions('FETCH_TENANT_ABILITIES');
const addRoleAction = createFetchActions('ADD_ROLE');
const addToBlacklistAction = createFetchActions('ADD_TO_BLACKLIST');
const deleteFromBlacklistAction = createFetchActions('DELETE_FROM_BLACKLIST');
const fetchBlacklistAction = createFetchActions('FETCH_BLACKLIST');

// licenses
const fetchAllLicensesActions = createFetchActions('FETCH_ALL_LICENSES');
const updateTenantLicensesActions = createFetchActions('UPDATE_TENANT_LICENSES');

// users
const fetchUsersActions = createFetchActions('FETCH_USERS');
const fetchUserActions = createFetchActions('FETCH_USER');
const saveUserActions = createFetchActions('SAVE_USER');

export {
  fetchClientsActions,
  fetchClientActions,
  createClientActions,
  saveClientActions,
  archiveClientActions,
  uploadLogoActions,
  updateTenantLogoActions,
  fetchRolesSummaryAction,
  fetchAllLicensesActions,
  updateTenantLicensesActions,
  fetchUsersActions,
  fetchUserActions,
  saveUserActions,
  fetchTenantRolesAction,
  updateTenantRolesAction,
  deleteTenantRolesAction,
  addRoleAction,
  fetchTenantAbilitiesAction,
  addToBlacklistAction,
  deleteFromBlacklistAction,
  fetchBlacklistAction
};
