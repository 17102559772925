import React, { useCallback, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import { Link, useNavigate } from 'react-router-dom';
import { InvokeTable } from 'webapp-common';
import appConfig from '../../appConfig';
import ValidationIcon from '../validationStatus';
import { entityType } from '../../util/jsUtil';
import { getRecentProjects, REPORTS_AND_DATA_TAB, getProjectPageURL } from '../../util/projectUtil';
import localtime from '../../util/localtime';

const getSessionInfo = (noSessionLinks, row, cell, projectData) => {
  if (noSessionLinks) {
    return (
      <span>
        {cell} ({projectData.activeSessionCount})
      </span>
    );
  }
  return (
    <Link to={`${appConfig.projectPagePath}/${row.id}/${REPORTS_AND_DATA_TAB}`} className="no-text-underline">
      {cell} ({projectData.activeSessionCount})
    </Link>
  );
};

const ProjectList = props => {
  const {
    donotload,
    enableSort,
    enablePagination,
    fetchProjects,
    fetchRecentProjects,
    filter,
    hasProjectManage,
    projects: projectList,
    totalElements,
    projectListRequested,
    recentProjects,
    noSessionLinks,
    displayRecentProjects,
    clientId
  } = props;

  const { pageNumber = 1, pageSize = 10 } = props.pagination || {};

  const navigate = useNavigate();

  useEffect(() => {
    if (!donotload) {
      if (displayRecentProjects) {
        fetchRecentProjects({
          projectIds: getRecentProjects(clientId).map(p => p.id)
        });
      } else {
        fetchProjects({
          pageSize: props.pageSize,
          filter
        });
      }
    }
  }, [clientId, displayRecentProjects, donotload, fetchProjects, fetchRecentProjects, filter, props.pageSize]);

  const onSortingChange = useCallback(
    ({ sortBy, sortOrder }) => {
      fetchProjects({ pageSize: props.pageSize, sortBy, sortOrder, filter });
    },
    [fetchProjects, props.pageSize, filter]
  );

  const onPaginationChange = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchProjects({ pageNumber, pageSize, sortBy, sortOrder, filter });
    },
    [fetchProjects, filter]
  );

  const go2Project = useCallback(
    row => {
      const validationIcon = document.querySelector(`#validation_icon_${row.id}`);
      if (validationIcon?.dataset.validationMsg.includes('Project does not exist')) {
        return;
      }
      const url = getProjectPageURL(row.id, hasProjectManage, appConfig.projectPagePath);
      navigate(url);
    },
    [navigate, hasProjectManage]
  );

  const projectData = useMemo(() => {
    return (displayRecentProjects ? recentProjects?.content : projectList) || [];
  }, [displayRecentProjects, projectList, recentProjects?.content]);

  const getNameCell = useCallback(
    info => {
      return (
        <span>
          <span
            className="table-cell-name text-truncate align-middle link"
            title={info.getValue()}
            onClick={() => go2Project(info.row.original)}
          >
            {info.getValue()}
          </span>
          <ValidationIcon id={info.row.original.id} entity={entityType.project} onlyWarning />
        </span>
      );
    },
    [go2Project]
  );

  const projectsColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: intl.get('app.projectName'),
        cell: getNameCell
      },
      {
        accessorKey: 'createDate',
        header: intl.get('app.createDate'),
        cell: info => localtime.getFormattedDate(info.getValue())
      },
      {
        accessorKey: 'totalSessionCount',
        header: `${intl.get('app.surveys')} (${intl.get('app.active')})`,
        cell: info => {
          const { cell, row } = info;
          return getSessionInfo(noSessionLinks, row.original, cell.getValue(), projectData[row.index]);
        },
        enableSorting: false
      }
    ],
    [noSessionLinks, projectData, getNameCell]
  );

  const tableProps = {
    className: 'invoke-table',
    columns: projectsColumns,
    data: projectData,
    enablePagination,
    enableSort,
    initialState: {
      sorting: [
        {
          id: 'createDate',
          desc: true
        }
      ]
    },
    loading: projectListRequested,
    pagination: {
      pageNumber,
      pageSize,
      totalSize: totalElements
    },
    onPaginationChange,
    onSortingChange
  };

  return <InvokeTable {...tableProps} />;
};

export default ProjectList;
