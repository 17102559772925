import React from 'react';
import intl from 'react-intl-universal';
import localtime from '../../util/localtime';
import { InvokeTable, SearchInput } from 'webapp-common';

import './SurveyList.css';

class SurveyList extends React.Component {
  state = {
    currentSearch: ''
  };

  componentDidMount() {
    this.props.fetchSurveyTemplates({
      isScreener: this.props.isScreener
    });
  }

  sort = ({ sortBy, sortOrder }) => {
    const { pageRequest } = this.props.surveyTemplates;
    this.props.fetchSurveyTemplates({
      pageNumber: 1,
      pageSize: pageRequest.pageSize,
      sortBy,
      sortOrder,
      name: this.state.currentSearch,
      isScreener: this.props.isScreener
    });
  };

  paginate = ({ pageNumber, pageSize, sortBy, sortOrder }) => {
    this.props.fetchSurveyTemplates({
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      name: this.state.currentSearch,
      isScreener: this.props.isScreener
    });
  };

  handleSearchChange = e => {
    this.setState({ currentSearch: e.target.value });
    const { pageRequest } = this.props.surveyTemplates;
    this.props.fetchSurveyTemplates({
      pageNumber: 1,
      pageSize: pageRequest.pageSize,
      sortBy: pageRequest.sortBy,
      sortOrder: pageRequest.sortOrder,
      name: e.target.value,
      isScreener: this.props.isScreener
    });
  };

  surveyNameFormatter(info) {
    return (
      <span className="link" title={info.getValue()}>
        {info.getValue()}
      </span>
    );
  }

  formatDate(info) {
    return localtime.getFormattedDate(info.getValue());
  }

  getTableColumns() {
    return [
      {
        accessorKey: 'name',
        header: intl.get('app.title.name'),
        headerStyle: { width: '23rem' },
        cellClassName: 'text-truncate',
        cell: this.surveyNameFormatter
      },
      {
        accessorKey: 'createDate',
        header: intl.get('app.createDate'),
        headerStyle: { width: '12rem' },
        cell: this.formatDate
      },
      {
        accessorKey: 'modifiedDate',
        header: intl.get('app.lastModified'),
        headerStyle: { width: '12rem' },
        cell: this.formatDate
      }
    ];
  }

  onRowSelect = survey => {
    this.props.onSurveySelect?.(survey);
  };

  render() {
    const { surveyTemplates } = this.props;
    const { content: templateList, pageRequest, surveyTemplatesRequested, totalElements } = surveyTemplates;
    const { pageNumber, pageSize } = pageRequest || {};

    const pagination = {
      pageNumber,
      pageSize,
      totalSize: totalElements
    };

    return (
      <div className="survey-table-container">
        <SearchInput placeholder={intl.get('app.search')} onChange={this.handleSearchChange} />
        <InvokeTable
          className="invoke-table"
          columns={this.getTableColumns()}
          data={templateList || []}
          initialState={{
            sorting: [
              {
                id: 'modifiedDate',
                desc: true
              }
            ]
          }}
          loading={surveyTemplatesRequested}
          pagination={pagination}
          onPaginationChange={this.paginate}
          onSortingChange={this.sort}
          onRowSelect={this.onRowSelect}
        />
      </div>
    );
  }
}

export default SurveyList;
