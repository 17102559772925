import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { Loader, Pagination, SearchInput, usePagination } from 'webapp-common';
import appConfig from '../../../../appConfig';
import { NEWPROJECT, project_survey_view } from '../../../../util/projectUtil';
import { saveProject } from '../../../../api/projectsApi';
import ProjectList from '../../../projectList';
import { Icons } from '../../../../components/icons/Icons';
import { ProjectCard } from './ProjectCard';
import { ProjectTypeModal } from './ProjectTypeModal';

import './ActiveProjectsPage.css';

const SIZE_PER_PAGE = 10;

const getViewStyle = () => localStorage.getItem(project_survey_view.view_style);

export const ActiveProjectsPage = props => {
  const { projectList, hasProjectManage, fetchProjects } = props;

  const { projectListRequested, projects, totalElements, pagination: pageRequest = {} } = projectList;
  const { fullServe, selfServe } = props.sessionTenant;

  const [filter, setFilter] = useState([]);
  const [viewStyle, setViewStyle] = useState(getViewStyle() || project_survey_view.block);
  const [showProjectTypeModal, setShowProjectTypeModal] = useState();
  const [showLoader, setShowLoader] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects({
      pageSize: SIZE_PER_PAGE
    });
  }, []);

  useEffect(() => {
    if (filter.length > 0) {
      fetchProjects({
        pageSize: SIZE_PER_PAGE,
        filter
      });
    }
  }, [filter]);

  function go2AddProjectPage() {
    navigate(`${appConfig.projectPagePath}/${NEWPROJECT}/details`);
  }

  // TODO: how to set default countryId?
  async function saveSelfServiceProject(countryId = 1) {
    setShowLoader(true);
    const savedProject = await saveProject({
      name: 'Self Service Project',
      selfServe: true,
      prodegeConfig: {
        countryId
      }
    });
    setShowLoader(false);
    if (savedProject) {
      navigate(`${appConfig.projectPagePath}/${savedProject.id}/details`);
    }
  }

  function onAddProjectClick() {
    if (fullServe && selfServe) {
      setShowProjectTypeModal(true);
    } else if (fullServe) {
      go2AddProjectPage();
    } else {
      saveSelfServiceProject();
    }
  }

  function onProjectTypeSelect(obj) {
    const { projectType, selectedCountry } = obj;
    toggleProjectTypeModal();
    if (projectType === 'fullServe') {
      go2AddProjectPage();
    } else {
      saveSelfServiceProject(selectedCountry);
    }
  }

  const searchProjectsDebounced = debounce(value => {
    setFilter(value.split(','));
  }, 500);

  const handleSearchChange = e => {
    searchProjectsDebounced(e.target.value);
  };

  const setLocalViewStyle = style => {
    localStorage.setItem(project_survey_view.view_style, style);
    setViewStyle(style);
  };

  const getProjectCards = () => {
    if (!projects || projects.length === 0) {
      return <div>{intl.get('app.noProjects')}</div>;
    }
    return projects.map(p => {
      return (
        <ProjectCard
          key={p.id}
          name={p.name}
          projectId={p.id}
          created={p.createDate}
          addBorder={p.activeSessionCount > 0}
          active={p.activeSessionCount}
          closed={p.closedSessionCount}
          pending={p.pendingSessionCount}
          hasProjectManage={hasProjectManage}
          stim={p.firstStim}
        />
      );
    });
  };

  const pagination = usePagination({ pageRequest, totalElements });

  const paginate = useCallback(
    ({ pageNumber, pageSize }) => {
      fetchProjects({
        pageNumber,
        pageSize,
        filter,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder
      });
    },
    [filter, pageRequest.sortBy, pageRequest.sortOrder, fetchProjects]
  );

  function toggleProjectTypeModal() {
    setShowProjectTypeModal(!showProjectTypeModal);
  }

  return (
    <div className="active-projects">
      {showLoader && <Loader fullScreen spinner />}
      <button className="btn btn-primary mb-4" onClick={onAddProjectClick}>
        {intl.get('app.addProject')}
      </button>
      <div className="flex-row">
        <SearchInput
          placeholder={intl.get('app.search')}
          disabled={false}
          onChange={handleSearchChange}
          className="me-4"
        />
        <Icons.ListIcon
          className={viewStyle === project_survey_view.list ? 'selected clickable' : 'unselected clickable'}
          onClick={() => setLocalViewStyle(project_survey_view.list)}
        />
        <Icons.BlockIcon
          className={viewStyle === project_survey_view.block ? 'selected clickable' : 'unselected clickable'}
          onClick={() => setLocalViewStyle(project_survey_view.block)}
        />
      </div>
      <Row className="mt-3">
        {viewStyle === project_survey_view.list && (
          <ProjectList pageSize={SIZE_PER_PAGE} filter={filter} clientId={props.sessionUser.clientId} donotload />
        )}
        {viewStyle === project_survey_view.block && (
          <>
            {(showLoader || projectListRequested) && <Loader fullScreen spinner />}
            {getProjectCards()}
            <Pagination pagination={pagination} onPaginationChange={paginate} />
          </>
        )}
      </Row>
      {showProjectTypeModal && <ProjectTypeModal onSelect={onProjectTypeSelect} toggle={toggleProjectTypeModal} />}
    </div>
  );
};
