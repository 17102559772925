import { get, post } from '../util/request';

const SESSIONS_API = '/a/api/sessions';
const CLEAN_UP_API = '/a/api/completeActiveRunners';

async function fetchSessions(opts) {
  const params =
    (opts && {
      pageNumber: opts.pageNumber || 1,
      pageSize: opts.pageSize || 10,
      sortBy: opts.sortBy || 'createDate',
      sortOrder: opts.sortOrder || 'desc',
      includeResponseRates: !!opts.includeResponseRates,
      screener: !!opts.screener,
      projectId: opts.projectId || '',
      includeMedia: !!opts.includeMedia
    }) ||
    {};
  return get(SESSIONS_API)(params).then(response => response.body.payload);
}

async function fetchSession({ sessionId }) {
  const url = `${SESSIONS_API}/${sessionId}`;
  return get(url)().then(response => response.body.payload);
}

async function saveSession({ payload }) {
  return post('/a/api/session')(payload).then(res => res?.body?.payload || {});
}

async function cloneSession(sessionId) {
  return post(`/a/api/cloneSession/${sessionId}`)({}).then(res => (res && res.body && res.body.payload) || {});
}

async function sessionCleanUp(payload) {
  return post(CLEAN_UP_API)(payload).then(res => (res && res.body && res.body.payload) || false);
}

async function updateDynataPanelConfig(payload) {
  return post('/a/api/panelConfigs/dynata')(payload).then(res => (res && res.body && res.body.payload) || {});
}

export { fetchSessions, fetchSession, saveSession, cloneSession, sessionCleanUp, updateDynataPanelConfig };
