import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import MediaLibrary from '../../../../mediaLibrary';

import './SelectMediaModal.css';

class SelectMediaModal extends React.Component {
  state = {
    selectedMedia: {}
  };

  onMediaSelect = media => {
    this.setState({
      selectedMedia: media
    });
  };

  onSave = () => {
    this.props.onMediaUpdate(this.state.selectedMedia);
  };

  render() {
    const {
      enableAdd = true,
      enableEdit = true,
      enableDelete = false,
      primaryButtonText = intl.get('app.save'),
      cancelButtonText = intl.get('app.cancel'),
      toggle,
      title
    } = this.props;
    return (
      <InvokeModal
        showModal
        className="select-media-modal"
        toggle={toggle}
        modalTitle={title || intl.get('app.title.selectMedia')}
        primaryButtonText={primaryButtonText}
        cancelButtonText={cancelButtonText}
        save={this.onSave}
        enableSave={!!this.state.selectedMedia._id}
      >
        <MediaLibrary
          enableAdd={enableAdd}
          enableEdit={enableEdit}
          enableDelete={enableDelete}
          sortable
          onMediaSelect={this.onMediaSelect}
          onDeleteMedia={this.props.onDeleteMedia}
          pageSize={8}
        />
      </InvokeModal>
    );
  }
}

export default SelectMediaModal;
