import { connect } from 'react-redux';
import {
  saveClientActions,
  uploadLogoActions,
  updateTenantLogoActions
} from '../../../store/redux/actions/clientAdminActions';
import { clientAdminSelector } from '../../../store/redux/selectors/clientAdminSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';
import ClientAdminCustomUIForm from './ClientAdminCustomUIForm';

const mapStateToProps = (state, props) => {
  const selector = clientAdminSelector()(state, props.clientId);
  return {
    client: selector.client,
    saveClientInProgress: selector.saveClientInProgress,
    updateLogoInProgress: selector.updateLogoInProgress,
    abilities: sessionUserSelector(state).abilities
  };
};

const mapDispatchToProps = dispatch => ({
  saveClient: client => dispatch(saveClientActions.request(client)),
  uploadLogo: (client, logoFile) => dispatch(uploadLogoActions.request({ client, logoFile })),
  updateTenantLogo: (client, media) => dispatch(updateTenantLogoActions.request({ client, media }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientAdminCustomUIForm);
