import createArchiveActions from './createFetchActions';

const archiveProjectActions = createArchiveActions('ARCHIVE_PROJECT', {
  failure_payload: (projectId, exception) => ({
    projectId,
    exception
  })
});

const restoreArchivedProjectAction = createArchiveActions('RESTORE_ARCHIVE_PROJECT');

const deleteArchivedProjectAction = createArchiveActions('DELETE_ARCHIVE_PROJECT');

export { archiveProjectActions, restoreArchivedProjectAction, deleteArchivedProjectAction };
