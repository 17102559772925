import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { isRequired } from '../../../../../util/joinerUtil';

class RankedOptions extends React.Component {
  getChoiceOptions = () => {
    return this.props.joiner.def.responseSet.entries.rows
      .filter(choice => !choice.value.disable)
      .map((row, i) => (
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      ));
  };

  render() {
    const { tabs, joiner, updateHandlers, readOnly } = this.props;
    const { responseSet } = joiner.def;

    return (
      <div className={`${tabs.options} ranked-options`}>
        <label disabled={readOnly}>
          <Input type="checkbox" checked={isRequired(joiner)} onChange={updateHandlers.updateQuestionRequired} />
          {intl.get('app.questionRequired')}
        </label>
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={joiner.hidden}
            onChange={e => updateHandlers.updateHidden(e.target.checked)}
          />
          {intl.get('app.hideQuestion')}
        </label>
        <label disabled={readOnly}>
          <Input type="checkbox" checked={responseSet.randomOptions} onChange={updateHandlers.toggleRandomOptions} />
          {intl.get('app.randomizeRows')}
        </label>
        <label className="min-choices" disabled={readOnly}>
          <Input type="checkbox" checked={responseSet.validate} onChange={updateHandlers.toggleMinRanking} />
          {intl.get('app.minRanking.msg.1')}
          <Input
            type="select"
            disabled={readOnly || !responseSet.validate}
            placeholder={responseSet.minAnswers}
            value={responseSet.minAnswers}
            onChange={e => updateHandlers.updateMinChoices(e.target.value)}
          >
            <option key={0} value={''}>
              {' '}
              0{' '}
            </option>
            {this.getChoiceOptions()}
          </Input>
          {intl.get('app.minRanking.msg.2')}
        </label>
      </div>
    );
  }
}

export default RankedOptions;
