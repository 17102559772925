import React from 'react';
import intl from 'react-intl-universal';

const VISIBLE = 'visible';
const SELECTED = 'selected';

class JoinerEditorTabs extends React.Component {
  handleClick = tab => {
    const event = new CustomEvent('selectJoinerEditTab', { detail: tab });
    document.dispatchEvent(event);
  };

  render() {
    const { tabs, joinerInfo, hideEdit, hideMedia, hideOptions, hideTiming, hideRules } = this.props;
    const { joiner, isPageChild } = joinerInfo;

    return (
      <ul className="joiner-editor-tabs">
        {hideEdit || (
          <li className={tabs.edit === VISIBLE ? SELECTED : ''} onClick={() => this.handleClick('edit')}>
            <i className="far fa-edit fa-2x" />
            <div>{intl.get('app.edit')}</div>
          </li>
        )}
        {hideMedia || !!joiner.conceptRotationId || isPageChild || (
          <li className={tabs.media === VISIBLE ? SELECTED : ''} onClick={() => this.handleClick('media')}>
            <i className="far fa-image fa-2x" />
            <div>{intl.get('app.media')}</div>
          </li>
        )}
        {hideOptions || (
          <li className={tabs.options === VISIBLE ? SELECTED : ''} onClick={() => this.handleClick('options')}>
            <i className="fas fa-cog fa-2x" />
            <div>{intl.get('app.options')}</div>
          </li>
        )}
        {hideTiming || (
          <li className={tabs.timing === VISIBLE ? SELECTED : ''} onClick={() => this.handleClick('timing')}>
            <i className="far fa-clock fa-2x" />
            <div>{intl.get('app.timing')}</div>
          </li>
        )}
        {hideRules || (
          <li className={tabs.rules === VISIBLE ? SELECTED : ''} onClick={() => this.handleClick('rules')}>
            <i className="fas fa-check fa-2x" />
            <div>{intl.get('app.rules')}</div>
          </li>
        )}
      </ul>
    );
  }
}

export default JoinerEditorTabs;
