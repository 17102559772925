import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { Select } from 'antd';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { InvokeModal } from 'webapp-common';

import './TargetCriteriaModal.css';
import { Icons } from '../../../components/icons/Icons';

const QUESTION_TYPES = {
  NUMERIC: 'Numeric',
  TEXT: 'Text',
  SINGLE: 'Single-Select',
  MULTIPLE: 'Multi-Select'
};
const CRITERION = {
  question_id: null,
  operator: 'OR',
  precodes: []
};

export const TargetCriteriaModal = props => {
  const { saveEnabled, save, toggle, targetRule, questions, readOnly } = props;
  const [rule, setRule] = useState(targetRule);
  const { name, quota, criteria } = rule;

  const getQuestions = (source, id, text) => {
    return source.map(obj => (
      <option key={obj[id]} value={obj[id]}>
        {obj[text]}
      </option>
    ));
  };

  const onTargetChange = (questionId, index) => {
    const clone = cloneDeep(criteria);
    clone[index].question_id = questionId;
    clone[index].precodes = [];
    const question = questions.find(q => q.question_id === questionId);
    if (question?.question_type === QUESTION_TYPES.NUMERIC) {
      clone[index].operator = 'BETWEEN';
    } else {
      clone[index].operator = 'OR';
    }
    setRule({ ...rule, criteria: clone });
  };

  const getOptions = options => {
    return options.map(o => <Select.Option key={o.option_id}>{o.option_text}</Select.Option>);
  };

  const showQuestionSelect = index => (
    <Input
      style={{ margin: '0.5rem 0' }}
      type="select"
      className="clickable"
      value={null}
      disabled={readOnly}
      onChange={e => onTargetChange(e.target.value, index)}
    >
      <option value="" disabled selected>
        --- {intl.get('app.pickAQuestion')} ---
      </option>
      {getQuestions(questions, 'question_id', 'question_text')}
    </Input>
  );

  const onTextChange = (e, index) => {
    const clone = cloneDeep(criteria);
    clone[index].precodes = e.target.value.split(',');
    setRule({ ...rule, criteria: clone });
  };

  const onBoundaryChange = (e, precodes, i, index) => {
    const clone = cloneDeep(criteria);
    clone[index].precodes[i] = e.target.value;
    setRule({ ...rule, criteria: clone });
  };

  const onMultiSelectChange = (values, index) => {
    const clone = cloneDeep(criteria);
    clone[index].precodes = values;
    setRule({ ...rule, criteria: clone });
  };

  const getCriteria = (criterion, index) => {
    const { question_id, precodes } = criterion;
    if (!question_id) {
      return showQuestionSelect(index);
    }
    const question = questions.find(q => q.question_id === question_id);
    switch (question.question_type) {
      case QUESTION_TYPES.TEXT:
        return (
          <div style={{ marginRight: '3rem' }}>
            <p style={{ marginBottom: '0.5rem', paddingLeft: '0.5rem ' }}>{intl.get('app.map.values.msg')}:</p>
            <Input
              type="textarea"
              className="mapping"
              value={precodes}
              disabled={readOnly}
              onChange={e => onTextChange(e, index)}
            />
          </div>
        );
      case QUESTION_TYPES.NUMERIC:
        return (
          <div>
            <div className="from-to">
              <strong>{intl.get('app.from')}:</strong>
              <Input
                type="number"
                className="number-input"
                value={precodes.length > 0 && precodes[0]}
                disabled={readOnly}
                onChange={e => onBoundaryChange(e, precodes, 0, index)}
              />
            </div>
            <div className="from-to">
              <strong>{intl.get('app.to')}:</strong>
              <Input
                type="number"
                className="number-input"
                value={precodes.length > 0 && precodes[1]}
                disabled={readOnly}
                onChange={e => onBoundaryChange(e, precodes, 1, index)}
              />
            </div>
          </div>
        );
      default:
    }

    return (
      <Select
        mode="multiple"
        className="clickable"
        allowClear
        placeholder="Please select"
        value={precodes.map(p => p.toString())}
        disabled={readOnly}
        onChange={val => onMultiSelectChange(val, index)}
      >
        {getOptions(question.options)}
      </Select>
    );
  };

  const onAddCriteria = () => {
    const clone = cloneDeep(criteria);
    clone.push(cloneDeep(CRITERION));
    setRule({ ...rule, criteria: clone });
  };

  const onRemoveCriteriaClick = (id, index) => {
    const clone = cloneDeep(criteria);
    clone.length === 1 ? (clone[0] = cloneDeep(CRITERION)) : clone.splice(index, 1);
    setRule({ ...rule, criteria: clone });
  };

  const getTargetRule = () => {
    return criteria.map((criterion, i) => {
      const { question_id } = criterion;
      if (!question_id) {
        return showQuestionSelect(i);
      }
      return (
        <div className="rule-criteria" key={question_id}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
            <Input
              type="select"
              className="clickable"
              value={question_id}
              disabled={readOnly}
              onChange={e => onTargetChange(e.target.value, i)}
            >
              <option value="" disabled selected>
                --- {intl.get('app.pickAQuestion')} ---
              </option>
              {getQuestions(questions, 'question_id', 'question_text')}
            </Input>
            <Icons.TrashAltIcon
              className={readOnly && 'disabled'}
              onClick={e => onRemoveCriteriaClick(e.target.value, i)}
            />
          </div>
          {getCriteria(criterion, i)}
        </div>
      );
    });
  };

  return (
    <InvokeModal
      showModal
      className="target-criteria-modal"
      modalTitle={intl.get('app.createQuota')}
      toggle={toggle}
      primaryButtonText={intl.get('app.ok')}
      cancelButtonText={intl.get('app.cancel')}
      enableSave={saveEnabled}
      save={() => save(rule)}
    >
      <div className="rule-name">
        <strong>{intl.get('app.name')}:</strong>
        <Input
          value={name}
          disabled={readOnly}
          onChange={e => setRule({ ...rule, name: e.target.value })}
          className="text-input"
        />
      </div>
      <div className="rule-quota">
        <strong>{intl.get('app.quota')}:</strong>
        <Input
          type="number"
          value={quota}
          disabled={readOnly}
          onChange={e => setRule({ ...rule, quota: e.target.value })}
          className="number-input"
        />
      </div>
      <div>
        <strong>{intl.get('app.criteria')}:</strong>
        {getTargetRule()}
      </div>
      <div style={{ marginTop: '0.5rem' }}>
        <Button color="secondary" disabled={readOnly} onClick={onAddCriteria}>
          {intl.get('app.addCriteria')}
        </Button>
      </div>
    </InvokeModal>
  );
};
