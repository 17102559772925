import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { Select } from 'antd';
import { cloneDeep, find, findIndex, findLastIndex, debounce } from 'lodash';
import { FlexRow } from 'webapp-common';
import { isRequired, OPTION_TYPE, REQUIRED_OPTION, ANSWER_TYPE } from '../../../../../util/joinerUtil';
import { toast } from '../../../../../util/toast';

const Option = Select.Option;
const DISPLAY_OPTION_VERTICAL = 'Vertical';
const DISPLAY_OPTION_DROPDOWN = 'Dropdown';
const SINGLE = 'single';
const MULTI = 'multi';
const DROPDOWN = 'dropdown';
const OTHER = 'OTHER';
const MIN = 'Min';
const MAX = 'Max';
const TYPE = 'type';
const LIMIT_TYPE = 'limitType';
const LIMIT_OPTION = 'limitoption';
const answerTypes = [
  ANSWER_TYPE.string,
  ANSWER_TYPE.alphanumeric,
  ANSWER_TYPE.alphaonly,
  ANSWER_TYPE.double,
  ANSWER_TYPE.numeric
];

class ChoiceOptions extends React.Component {
  constructor(props) {
    super(props);
    const otherChoice = props.joiner && this.getOtherChoice(props.joiner.def.responseSet);
    otherChoice
      ? (this.state = {
          rangeEnabled:
            otherChoice.value.expectedAnswerType === ANSWER_TYPE.numeric ||
            otherChoice.value.expectedAnswerType === ANSWER_TYPE.double,
          [MIN]: this.getValFromAnswerFormat(otherChoice, MIN),
          [MAX]: this.getValFromAnswerFormat(otherChoice, MAX)
        })
      : (this.state = {
          rangeEnabled: false,
          [MIN]: 0,
          [MAX]: 100
        });
  }

  getChoiceType = () => {
    const { allowMultipleAnswers, options } = this.props.joiner.def.responseSet;
    const option = (options && find(options, o => o.name === OPTION_TYPE.displayoption)) || {};
    if (allowMultipleAnswers) {
      return MULTI;
    }
    if (option.displayType === DISPLAY_OPTION_DROPDOWN) {
      return DROPDOWN;
    }
    return SINGLE;
  };

  updateChoiceType = e => {
    const { value } = e.target;
    const { updateChoiceDisplay } = this.props.updateHandlers;
    if (value === SINGLE) {
      updateChoiceDisplay(false, DISPLAY_OPTION_VERTICAL);
    } else if (value === DROPDOWN) {
      updateChoiceDisplay(false, DISPLAY_OPTION_DROPDOWN);
    } else if (value === MULTI) {
      updateChoiceDisplay(true, DISPLAY_OPTION_VERTICAL);
    }
  };

  getOtherChoice = responseSet => {
    const { choices } = responseSet;
    const otherChoiceIndex = findLastIndex(choices, c => c.value.entryType === OTHER && !c.value.disable);
    return otherChoiceIndex >= 0 ? choices[otherChoiceIndex] : null;
  };

  isOtherOptionRequired = otherChoice => {
    if (!otherChoice) {
      return false;
    }
    const {
      value: { otherInputOptions }
    } = otherChoice;
    return otherInputOptions.some(option => option.name === OPTION_TYPE.requiredoption);
  };

  onRequiredOtherOption = (e, otherChoice) => {
    if (e.target.checked) {
      otherChoice.value.otherInputOptions.push(REQUIRED_OPTION);
    } else {
      const optionIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, TYPE, OPTION_TYPE.requiredoption);
      otherChoice.value.otherInputOptions.splice(optionIndex, 1);
    }
    this.props.updateHandlers.updateChoiceOtherOptionFormat(otherChoice);
  };

  checkValidation = otherChoice => {
    if (!otherChoice) {
      return false;
    }
    return this.getOptionIndex(otherChoice.value.otherInputOptions, TYPE, OPTION_TYPE.limitoption) >= 0;
  };

  getAnswerFormatOptions = () =>
    answerTypes.map(a => {
      const text = intl.get(`app.choices.${a}`);
      return (
        <Option key={a} title={text}>
          {text}
        </Option>
      );
    });

  getOptionIndex = (options, type, target) => {
    return findIndex(options, o => o[type] === target);
  };

  onAnswerFormatChange = (e, responseSet, otherChoice) => {
    if (e.target.checked) {
      otherChoice.value.expectedAnswerType = ANSWER_TYPE.alphaonly;
      otherChoice.value.otherInputOptions.push({
        limit: 0,
        limitType: MIN,
        name: LIMIT_OPTION,
        type: LIMIT_OPTION
      });
      otherChoice.value.otherInputOptions.push({
        limit: 100,
        limitType: MAX,
        name: LIMIT_OPTION,
        type: LIMIT_OPTION
      });
    } else {
      otherChoice.value.expectedAnswerType = ANSWER_TYPE.string;
      const minOptionIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, LIMIT_TYPE, MIN);
      if (minOptionIndex > -1) {
        otherChoice.value.otherInputOptions.splice(minOptionIndex, 1);
      }
      const maxOptionIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, LIMIT_TYPE, MAX);
      if (maxOptionIndex > -1) {
        otherChoice.value.otherInputOptions.splice(maxOptionIndex, 1);
      }
    }

    this.setState({ [MIN]: 0, [MAX]: 100 });
    this.props.updateHandlers.updateChoiceOtherOptionFormat(otherChoice);
  };

  getAnswerFormat = (format, otherChoice) => {
    const rangeEnabled = format === ANSWER_TYPE.numeric || format === ANSWER_TYPE.double;
    const min = 0;
    const max = 100;
    otherChoice.value.expectedAnswerType = format;
    const minIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, LIMIT_TYPE, MIN);
    const maxIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, LIMIT_TYPE, MAX);
    otherChoice.value.otherInputOptions[minIndex].limit = min;
    otherChoice.value.otherInputOptions[maxIndex].limit = max;
    this.props.updateHandlers.updateChoiceOtherOptionFormat(otherChoice);
    this.setState({
      [MIN]: min,
      [MAX]: max,
      rangeEnabled
    });
  };

  getValFromAnswerFormat = (otherChoice, choice) => {
    if (choice === MIN) {
      if (otherChoice.value.otherInputOptions.length === 0) {
        return 0;
      }
      const minOptionIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, LIMIT_TYPE, MIN);
      return minOptionIndex > -1 ? otherChoice.value.otherInputOptions[minOptionIndex].limit : 0;
    }
    if (choice === MAX) {
      if (otherChoice.value.otherInputOptions.length === 0) {
        return 100;
      }
      const maxOptionIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, LIMIT_TYPE, MAX);
      return maxOptionIndex > -1 ? otherChoice.value.otherInputOptions[maxOptionIndex].limit : 100;
    }
  };

  setValFromAnswerFormatDebounced = debounce((otherChoice, value, type) => {
    if (value < 0 || value > 100) {
      toast.error({ text: intl.get('app.choices.answerValueOutOfRange') });
    } else {
      const optionIndex = this.getOptionIndex(otherChoice.value.otherInputOptions, LIMIT_TYPE, type);
      otherChoice.value.otherInputOptions[optionIndex].limit = value;
      this.props.updateHandlers.updateChoiceOtherOptionFormat(otherChoice);
    }
  }, 500);

  setValFromAnswerFormat = (otherChoice, e, type) => {
    this.setState(
      {
        [type]: e.target.value
      },
      () => this.setValFromAnswerFormatDebounced(otherChoice, this.state[type], type)
    );
  };

  render() {
    const { tabs, joiner, updateHandlers, readOnly } = this.props;
    const { responseSet } = joiner.def;
    const choiceType = this.getChoiceType();
    const otherChoice = cloneDeep(this.getOtherChoice(responseSet));
    const isOtherRequired = this.isOtherOptionRequired(otherChoice);
    const isValidationChecked = this.checkValidation(otherChoice);

    return (
      <div className={`${tabs.options} choice-options`}>
        <label disabled={readOnly}>
          <Input type="checkbox" checked={isRequired(joiner)} onChange={updateHandlers.updateQuestionRequired} />
          {intl.get('app.questionRequired')}
        </label>
        <label disabled={readOnly}>
          <Input
            type="checkbox"
            checked={joiner.hidden}
            onChange={e => updateHandlers.updateHidden(e.target.checked)}
          />
          {intl.get('app.hideQuestion')}
        </label>
        <label disabled={readOnly}>
          <Input type="checkbox" checked={responseSet.randomOptions} onChange={updateHandlers.toggleRandomOptions} />
          {intl.get('app.randomizeRows')}
        </label>
        <FlexRow alignItems="flex-start">
          {intl.get('app.choiceType')}:
          <div className="choice-types">
            <label disabled={readOnly}>
              <Input
                type="radio"
                name="choiceType"
                value={SINGLE}
                checked={choiceType === SINGLE}
                onChange={this.updateChoiceType}
              />
              {intl.get('app.singleChoice')}
            </label>
            <label disabled={readOnly}>
              <Input
                type="radio"
                name="choiceType"
                value={MULTI}
                checked={choiceType === MULTI}
                onChange={this.updateChoiceType}
              />
              {intl.get('app.multipleChoice')}
            </label>
            <label disabled={readOnly}>
              <Input
                type="radio"
                name="choiceType"
                value={DROPDOWN}
                checked={choiceType === DROPDOWN}
                onChange={this.updateChoiceType}
              />
              {intl.get('app.dropdown')}
            </label>
          </div>
        </FlexRow>
        {otherChoice && (
          <>
            <div className="label {{fontWeight: '600'}}">{intl.get('app.choices.otherOptions') + ':'}</div>
            <label>
              <Input
                type="checkbox"
                checked={isOtherRequired}
                disabled={readOnly}
                onChange={e => this.onRequiredOtherOption(e, otherChoice)}
              />
              {intl.get('app.choices.requireOther')}
            </label>
            <div className="flex-row select-option-rows">
              <label>
                <Input
                  type="checkbox"
                  checked={isValidationChecked}
                  disabled={readOnly}
                  onChange={e => this.onAnswerFormatChange(e, responseSet, otherChoice)}
                />
                {intl.get('app.choices.validateOther')}:
              </label>
              <Select
                className="mx-2 select-options"
                onChange={format => this.getAnswerFormat(format, otherChoice)}
                disabled={readOnly || !isValidationChecked}
                value={otherChoice.value.expectedAnswerType || intl.get('app.surveyEditor.STRING')}
              >
                {this.getAnswerFormatOptions()}
              </Select>
              {intl.get('app.choices.rangeBetween')}:
              <Input
                className="select-option-input-min mx-2"
                type="number"
                min="0"
                value={this.state[MIN]}
                onChange={e => this.setValFromAnswerFormat(otherChoice, e, MIN)}
                disabled={readOnly || !this.state.rangeEnabled || !isValidationChecked}
              />
              {intl.get('app.and')}
              <Input
                className="select-option-input-max ms-2"
                type="number"
                max="100"
                value={this.state[MAX]}
                onChange={e => this.setValFromAnswerFormat(otherChoice, e, MAX)}
                disabled={readOnly || !this.state.rangeEnabled || !isValidationChecked}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ChoiceOptions;
