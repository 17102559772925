import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useQuery } from 'react-query';
import { Button, Progress } from 'reactstrap';
import Select from 'react-select';
import hugeUploader from 'huge-uploader';
import { InvokeModal, Loader, SearchInput, usePagination } from 'webapp-common';
import { fetchSessions } from '../../api/sessionsApi';
import { mediaLibraryUtil } from './mediaLibraryUtil';
import { MediaGrid } from './MediaGrid';
import { MediaTable } from './MediaTable';
import AddOrEditMediaModal from './AddOrEditMediaModal';
import { DeleteMediaModal } from './DeleteMediaModal';

import './MediaLibrary.css';

const reducer = (state, payload) => ({ ...state, ...payload });

const LIST_VIEW = 'list';
const GRID_VIEW = 'grid';

export const MediaLibrary = props => {
  const {
    mediaListLibrary,
    enableSearch = true,
    enableAdd = true,
    enableEdit = true,
    enableDelete = true,
    enablePagination = true,
    sortable,
    fetchMediaList,
    onMediaSelect,
    uploadMediaCancelled,
    uploadMediaStart,
    uploadMediaSuccess,
    whitelistedDomains
  } = props;

  const { pagedList = {}, mediaListRequested, uploadMediaInProgress } = mediaListLibrary;
  const { content: mediaList = [], pageRequest = {}, totalElements = 0 } = pagedList;

  const [state, setState] = useReducer(reducer, {
    mediaTitle: '',
    mediaType: '',
    selectedSession: '',
    selectedMedia: {},
    showAddOrEditMediaModal: false,
    showDeleteMediaModal: false,
    uploadProgress: 0,
    showProgressModal: false,
    showMediauploadInProgressModal: false,
    uploadingFileName: '',
    selectedView: props.selectedView || LIST_VIEW
  });

  const uploader = useRef();
  const uploadMediaTitle = useRef();

  const fetchMedia = useCallback(
    params => {
      fetchMediaList({
        pageNumber: params.pageNumber,
        pageSize: (state.selectedView === GRID_VIEW && params.pageSize) || props.pageSize,
        sortBy: params.sortBy,
        sortOrder: params.sortOrder,
        title: params.title !== undefined ? params.title : state.mediaTitle,
        type: params.type !== undefined ? params.type : mediaLibraryUtil.getMediaType(state.mediaType),
        sessionId: params.sessionId !== undefined ? params.sessionId : state.selectedSession?.value || ''
      });
    },
    [fetchMediaList, props.pageSize, state.selectedView, state.mediaTitle, state.mediaType, state.selectedSession]
  );

  // TODO: Use useSessionsQuery() instead
  const { data: allSessions, isFetching: fetchSessionsInProgress } = useQuery(
    'all-sessions',
    () => fetchSessions({ pageSize: -1, sortBy: 'startDate' }),
    { refetchOnWindowFocus: false }
  );

  const getSelectedMedia = useCallback(() => {
    return mediaList.find(m => m._id === state.selectedMedia._id);
  }, [mediaList, state.selectedMedia._id]);

  useEffect(() => {
    if (state.selectedMedia._id) {
      const selectedMedia = getSelectedMedia();
      if (selectedMedia) {
        setState({ selectedMedia });
      }
    }
  }, [getSelectedMedia, state.selectedMedia._id]);

  function selectView(selectedView) {
    setState({ selectedView });
  }

  const pagination = usePagination({ pageRequest, totalElements });

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      fetchMedia({ pageNumber, pageSize, sortBy, sortOrder });
    },
    [fetchMedia]
  );

  const handleSearchInput = useCallback(
    e => {
      setState({ mediaTitle: e.target.value });
      fetchMedia({
        pageNumber: pageRequest.pageNumber,
        pageSize: pageRequest.pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder,
        title: e.target.value
      });
    },
    [fetchMedia, pageRequest]
  );

  const handleSelectType = useCallback(
    e => {
      setState({ mediaType: e.target.value });
      fetchMedia({
        pageNumber: pageRequest.pageNumber,
        pageSize: pageRequest.pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder,
        type: mediaLibraryUtil.getMediaType(e.target.value)
      });
    },
    [fetchMedia, pageRequest]
  );

  const handleSessionSelect = useCallback(
    selectedSession => {
      setState({ selectedSession });
      fetchMedia({
        pageNumber: pageRequest.pageNumber,
        pageSize: pageRequest.pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder,
        sessionId: selectedSession?.value || ''
      });
    },
    [fetchMedia, pageRequest]
  );

  const toggleMediauploadInProgressModal = useCallback(() => {
    setState({
      showMediauploadInProgressModal: !state.showMediauploadInProgressModal
    });
  }, [state.showMediauploadInProgressModal]);

  const toggleAddOrEditMediaModal = useCallback(
    mode => {
      if (uploadMediaInProgress) {
        toggleMediauploadInProgressModal();
      } else {
        setState({
          showAddOrEditMediaModal: !state.showAddOrEditMediaModal,
          addOrEditMode: mode
        });
      }
    },
    [uploadMediaInProgress, state.showAddOrEditMediaModal, toggleMediauploadInProgressModal]
  );

  const toggleDeleteMediaModal = useCallback(() => {
    setState({
      showDeleteMediaModal: !state.showDeleteMediaModal
    });
  }, [state.showDeleteMediaModal]);

  const saveMedia = useCallback(
    params => {
      props.saveMedia({ ...params, pageRequest });
    },
    [pageRequest, props.saveMedia]
  );

  const deleteMedia = useCallback(
    params => {
      params.onDeleteMedia = props.onDeleteMedia;
      params.pageRequest = {
        pageNumber: 1,
        pageSize: pageRequest.pageSize,
        sortBy: pageRequest.sortBy,
        sortOrder: pageRequest.sortOrder,
        title: state.mediaTitle,
        type: mediaLibraryUtil.getMediaType(state.mediaType)
      };
      props.deleteMedia(params);
    },
    [pageRequest, props.onDeleteMedia, props.deleteMedia, state.mediaTitle, state.mediaType]
  );

  const onRowSelect = useCallback(
    media => {
      if (state.selectedMedia._id === media._id) {
        setState({ selectedMedia: {} });
      } else {
        setState({ selectedMedia: media });
        onMediaSelect?.(media);
      }
    },
    [onMediaSelect, state.selectedMedia._id]
  );

  const onMediaToggle = useCallback(
    media => {
      onRowSelect(media);
    },
    [onRowSelect]
  );

  const cancelUpload = useCallback(
    err => {
      setState({
        showProgressModal: false,
        uploadProgress: 0
      });
      // also stop file upload
      uploader.current.togglePause();

      uploadMediaCancelled({
        name: uploadMediaTitle.current,
        error: err.detail
      });
    },
    [uploadMediaCancelled]
  );

  const handleMultiUpload = useCallback(
    (file, title, id) => {
      const currCookies = mediaLibraryUtil.getCookies();
      const mediaId = id || '';
      uploader.current = new hugeUploader({
        endpoint: '/a/binapi/uploadFileChunk',
        file,
        headers: {
          'custom-cookie': currCookies,
          fileName: file.name,
          fileType: file.type,
          mediaId
        },
        chunkSize: 6,
        postParams: { title, lastChunk: true }
      });
      uploadMediaStart(title);
      uploadMediaTitle.current = title;
      setState({
        uploadProgress: 0,
        showProgressModal: true,
        uploadingFileName: file.name
      });

      uploader.current.on('error', err => {
        cancelUpload(err);
      });

      uploader.current.on('progress', progress => {
        setState({
          uploadProgress: progress.detail
        });
      });

      uploader.current.on('finish', () => {
        setState({
          uploadProgress: 100,
          showProgressModal: false,
          showAddOrEditMediaModal: false
        });

        uploadMediaSuccess({
          name: uploadMediaTitle.current,
          type: file.type,
          pageRequest
        });
      });
    },
    [cancelUpload, pageRequest, uploadMediaStart, uploadMediaSuccess]
  );

  const uploadInBackground = useCallback(() => {
    setState({
      showAddOrEditMediaModal: false,
      showProgressModal: false
    });
  }, []);

  function getSessionListOptions() {
    const { content = [] } = allSessions || {};
    return content.map(session => ({
      label: session.name,
      value: session.id
    }));
  }

  const readOnly = !props.hasProjectManage;

  return (
    <div id="media-library" className="media-library">
      {(fetchSessionsInProgress || mediaListRequested) && <Loader spinner fullScreen />}
      <div className="top-row">
        <div className="buttons">
          {enableAdd && (
            <Button color="primary" disabled={readOnly} onClick={() => toggleAddOrEditMediaModal('add')}>
              {intl.get('app.add')}
            </Button>
          )}
          {enableEdit && (
            <Button
              color="secondary"
              disabled={readOnly || !state.selectedMedia._id}
              onClick={() => toggleAddOrEditMediaModal('edit')}
            >
              {intl.get('app.edit')}
            </Button>
          )}
          {enableDelete && (
            <Button
              className="btn-warn"
              disabled={readOnly || !state.selectedMedia._id}
              onClick={toggleDeleteMediaModal}
            >
              {intl.get('app.delete')}
            </Button>
          )}
        </div>
        <div className="view-icons">
          <i
            className={classnames({ fas: true, 'fa-list': true, selected: state.selectedView === LIST_VIEW })}
            onClick={() => selectView(LIST_VIEW)}
          />
          <i
            className={classnames({ fas: true, 'fa-th': true, selected: state.selectedView === GRID_VIEW })}
            onClick={() => selectView(GRID_VIEW)}
          />
        </div>
      </div>
      {enableSearch && (
        <div className="filters">
          <SearchInput placeholder={intl.get('app.searchTitle')} onChange={handleSearchInput} />
          <Select
            classNamePrefix="react-select"
            value={state.selectedSession}
            onChange={handleSessionSelect}
            options={getSessionListOptions()}
            isSearchable={true}
            isClearable={true}
            placeholder={intl.get('app.searchSessions')}
          />
          <select value={state.mediaType} onChange={handleSelectType}>
            <option value="" disabled>
              {intl.get('app.type')}
            </option>
            <option value="all">{intl.get('app.all')}</option>
            <option value="Image">{intl.get('app.stim.image')}</option>
            <option value="Video">{intl.get('app.stim.video')}</option>
            <option value="WebContent">{intl.get('app.stim.webcontent')}</option>
          </select>
        </div>
      )}
      {state.selectedView === LIST_VIEW && (
        <div style={{ width: 'min-content', minWidth: '42.5rem' }}>
          <MediaTable
            mediaListLibrary={mediaListLibrary}
            enablePagination={enablePagination}
            pagination={pagination}
            sortable={sortable}
            fetchMedia={fetchMedia}
            onPaginationChange={paginate}
            onRowSelect={onRowSelect}
          />
        </div>
      )}
      {state.selectedView === GRID_VIEW && (
        <MediaGrid
          mediaListLibrary={mediaListLibrary}
          pagination={pagination}
          selectedMedia={state.selectedMedia}
          fetchMedia={fetchMedia}
          onPaginationChange={paginate}
          onMediaToggle={onMediaToggle}
        />
      )}
      {state.showAddOrEditMediaModal && (
        <AddOrEditMediaModal
          selectedMedia={state.selectedMedia}
          mode={state.addOrEditMode}
          toggle={toggleAddOrEditMediaModal}
          saveMedia={saveMedia}
          handleMultiUpload={handleMultiUpload}
          whitelistedDomains={whitelistedDomains}
        />
      )}
      {state.showDeleteMediaModal && (
        <DeleteMediaModal
          selectedMedia={state.selectedMedia}
          toggle={toggleDeleteMediaModal}
          deleteMedia={deleteMedia}
        />
      )}
      {state.showProgressModal && (
        <InvokeModal
          showModal
          toggle={cancelUpload}
          modalTitle={intl.get('app.file.uplaod.title')}
          primaryButtonText={intl.get('app.upload.run.backgroud')}
          cancelButtonText={intl.get('app.cancel.upload')}
          save={uploadInBackground}
          enableSave={true}
        >
          <div className="uploading-filename-label">
            {intl.get('app.media.uploading.filename', { name: state.uploadingFileName })}
          </div>
          <Progress value={state.uploadProgress} />
          <div className="progress-label">{`${state.uploadProgress}%`}</div>
        </InvokeModal>
      )}
      {state.showMediauploadInProgressModal && (
        <InvokeModal
          showModal
          toggle={toggleMediauploadInProgressModal}
          modalTitle={intl.get('app.media.upload.inProgress.title')}
        >
          {intl.get('app.media.upload.inProgress')}
        </InvokeModal>
      )}
    </div>
  );
};
