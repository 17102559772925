import { deleteReportActions, fetchReportListActions } from '../actions/reportListActions';
import { deleteReport, fetchReports } from '../../../api/reportsApi';
import createFetchSaga from './createFetchSaga';

function* deleteReportSaga() {
  const saga = createFetchSaga(deleteReportActions, deleteReport);
  yield* saga();
}

function* reportListSaga() {
  const fetchReportListSaga = createFetchSaga(fetchReportListActions, fetchReports);
  yield* fetchReportListSaga();
}

export { deleteReportSaga, reportListSaga };
