import React, { memo, useEffect, useReducer } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import moment from 'moment';
import { cloneDeep, get, isEqual } from 'lodash';
import { Button } from 'reactstrap';
import { Empty } from 'antd';
import { rdAggregateAnswersSubscribeActions } from '../../../../../store/redux/actions/researchDashboardActions';
import { getRDConfig, getRDAggregateData } from '../../../../../store/redux/selectors/researchDashboardSelector';
import { saveRDConfig } from '../../../../../util/researchDashboardUtil';
import { rdConfigUtil } from '../../../../../util/rdConfigUtil';
import { netsUtil } from '../../../../../util/netsUtil';
import { mediaUtil } from '../../../../../util/mediaUtil';
import { ENGLISH, isBipolar, RESPONSE_SET_TYPE } from '../../../../../util/joinerUtil';
import { jsUtil } from '../../../../../util/jsUtil';
import { QuestionDisplayOptionSelector } from '../../../../../components/questionDisplayOptionSelector/QuestionDisplayOptionSelector';
import { CustomNetsModal } from './CustomNetsModal';
import { OneBar } from './customNets/OneBar';
import { TwoBars } from './customNets/TwoBars';
import { ThreeBars } from './customNets/ThreeBars';
import { Icons } from '../../../../../components/icons/Icons';
import { surveyUtil } from '../../../../../util/surveyUtil';

import './MatrixQuestionResponses.css';

const WIDTH = 50;
const getActiveMatrixColumns = joiner =>
  joiner.def.responseSet.entries.columnData.columns.filter(col => !col.value.disable);

const getRowLabelCell = (rowData, inEnglish) => {
  const { row } = rowData;
  if (row.value.type === 'imagelabel') {
    const caption = inEnglish ? 'origCaption' : 'caption';
    return (
      <div className="thumbnail">
        <img src={mediaUtil.getMediaUrl(row.value.imageStim.media)} alt={row.value.altTag || ''} />
        <span>{row.value.imageStim[caption] || ''}</span>
      </div>
    );
  }
  // default to stringlabel
  const value = inEnglish ? 'origValue' : 'value';
  return row.value[value] || row.value.abbreviatedValue;
};

const getRightLabel = (rowData, inEnglish) => {
  const value = inEnglish ? 'origValue2' : 'value2';
  return rowData.row.value[value];
};

const getHeaderText = data => {
  const texts = [];
  if (data.bottom && data.bottom.length > 0) {
    texts.push('bottom');
  }
  if (data.middle && data.middle.length > 0) {
    texts.push('middle');
  }
  if (data.top && data.top.length > 0) {
    texts.push('top');
  }
  return texts;
};

const getColumns = (matrixColumns, responseSet, inEnglish, rows) => {
  const cols = [];
  let rotation = false;
  matrixColumns.forEach(col => {
    const label = responseSet.type === 'ranked' || !inEnglish ? col.value.value : col.value.origValue;
    if (!rotation) {
      rotation = jsUtil.getStringWidth(label, 'th') > WIDTH;
    }
    cols.push({
      id: col.id,
      text: label,
      bipolarHeader: `${intl.get('app.scalar')}: ${label}-${col.value.scalar}`,
      key: col.value.value
    });
  });
  if (isBipolar(responseSet)) {
    rows.forEach(row => {
      row.rightLabel = getRightLabel(row, inEnglish);
    });
    cols.push({});
  }

  return {
    columns: cols,
    rotation
  };
};

const getTypeCounts = (rdConfig, qDefId) => {
  const details =
    get(rdConfig, `configs.questionsConfig.questionsConfigMap[${qDefId}.netDetails`) ||
    get(rdConfig, 'configs.questionsConfig.globalQuestionConfig.netDetails');
  if (!details) {
    return {
      top: 1,
      bottom: 1,
      middle: 1
    };
  }
  return {
    top: details.top.length || 1,
    bottom: details.bottom.length || 1,
    middle: details.middle.length || 1
  };
};

/*
 * Handles sorting by either custom nets or by columns
 */
const sortData = (data, sortConfig) => {
  const { sortBy, sortOrder } = sortConfig;
  const sorted = data.sort((a, b) => {
    const val1 = a[sortBy]?.count || a.columnDetails?.find(col => col.id === sortBy)?.count || 0;
    const val2 = b[sortBy]?.count || b.columnDetails?.find(col => col.id === sortBy)?.count || 0;
    return (val1 < val2 && -1) || (val1 > val2 && 1) || 0;
  });
  return sortOrder === 'desc' ? sorted.reverse() : sorted;
};

const getTableData = (joiner, aggregateData, displayOption, netDetails, sortConfig) => {
  if (!aggregateData) {
    return [];
  }
  const { rows, bipolarRows } = joiner.def.responseSet.entries;
  const dataRows = isBipolar(joiner.def.responseSet) ? bipolarRows : rows;

  const data = dataRows
    .filter(row => !row.value.disable)
    .map(row => {
      const rowAggregateData = aggregateData[row.id] || {};
      const netMap = rowAggregateData.netMap || {};
      const columnDetails = rowAggregateData.columnDetails || [];

      const dataObj = {
        id: row.id,
        row,
        displayOption,
        top: netMap.TOP || { type: 'top', count: 0, percentage: 0 },
        middle: netMap.MIDDLE || { type: 'middle', count: 0, percentage: 0 },
        bottom: netMap.BOTTOM || { type: 'bottom', count: 0, percentage: 0 },
        responseRate: rowAggregateData.responseRate || 0,
        columnDetails: []
      };

      // Add the data for each column
      columnDetails.forEach(obj => {
        dataObj.columnDetails.push(obj);
      });

      return dataObj;
    });
  return sortConfig ? sortData(data, sortConfig) : data;
};

const formatCounts = (count, percent, displayOption) => {
  const percentage = Math.round(percent);
  if (displayOption === rdConfigUtil.questionConfigDisplayOptions.COUNT_AND_RATE) {
    return count === 0 ? `${count}` : `${count} (${percentage}%)`;
  } else if (displayOption === rdConfigUtil.questionConfigDisplayOptions.RESPONSE_RATE) {
    return `${percentage}%`;
  }
  return `${count}`;
};

const getBarCounts = (counts, displayOption) => ({
  percent: counts.percentage,
  count: formatCounts(counts.count, counts.percentage, displayOption)
});

const getColor = position => {
  switch (position) {
    case 'bottomColor':
      return '#d0021b';
    case 'middleColor':
      return '#f8e71c';
    default:
      return '#69b01c';
  }
};

const getCountsBar = (row, netDetails) => {
  const types = getHeaderText(netDetails);
  const length = types.length;
  if (length === 0) {
    return <div />;
  }
  const { percent = 0, count = 0 } = (row[types[0]] && getBarCounts(row[types[0]], row.displayOption)) || {};
  if (length === 1) {
    return <OneBar percent={percent} count={count} backgroundColor={getColor(`${types[0]}Color`)} />;
  }
  if (length === 2) {
    const { percent: percentRight = 0, count: countRight = 0 } =
      (row[types[1]] && getBarCounts(row[types[1]], row.displayOption)) || {};
    return (
      <TwoBars
        percentLeft={percent}
        countLeft={count}
        backgroundColorLeft={getColor(`${types[0]}Color`)}
        percentRight={percentRight}
        countRight={countRight}
        backgroundColorRight={getColor(`${types[1]}Color`)}
      />
    );
  }
  const { percent: percentMiddle = 0, count: countMiddle = 0 } =
    (row[types[1]] && getBarCounts(row[types[1]], row.displayOption)) || {};
  const { percent: percentRight = 0, count: countRight = 0 } =
    (row[types[2]] && getBarCounts(row[types[2]], row.displayOption)) || {};
  return (
    <ThreeBars
      percentLeft={percent}
      countLeft={count}
      backgroundColorLeft={getColor(`${types[0]}Color`)}
      percentMiddle={percentMiddle}
      countMiddle={countMiddle}
      backgroundColorMiddle={getColor(`${types[1]}Color`)}
      percentRight={percentRight}
      countRight={countRight}
      backgroundColorRight={getColor(`${types[2]}Color`)}
    />
  );
};

const getTableColumns = (columns, row) => {
  const { columnDetails } = row;
  return columns.map(column => {
    const columnData = columnDetails.find(cd => cd.id === column.id);
    const responseRate = columnData?.responseRate || 0;
    return columnData ? (
      <td key={column.id}>
        <div
          className="response-rate-cell mb-3"
          style={{
            backgroundColor: `rgba(5, 105, 169, ${responseRate / 100})`,
            color: responseRate > 60 ? '#fff' : '#000',
            width: '80%',
            marginLeft: '1rem'
          }}
        >
          {formatCounts(columnData.count, columnData.responseRate, row.displayOption)}
        </div>
      </td>
    ) : (
      <td>{row.rightLabel}</td>
    );
  });
};

const getFirstColumnWidth = title => {
  if (!title) {
    return '6rem';
  }
  const width = jsUtil.getStringWidth(title, 'th');
  if (width < 16 * 10) {
    return '10rem';
  }
  if (width > 16 * 30) {
    return '30rem';
  }
  return `${width}px`;
};

const skipUpdate = (prevProps, nextProps) => {
  return (
    prevProps.sessionId === nextProps.sessionId &&
    isEqual(prevProps.questionJoiner, nextProps.questionJoiner) &&
    prevProps.viewLanguage === nextProps.viewLanguage
  );
};

const reducer = (state, payload) => ({ ...state, ...payload });

/**
 * This component also used for Ranked questions
 */
export const MatrixQuestionResponses = memo(props => {
  const { sessionId, questionJoiner, viewLanguage } = props;
  const [state, setState] = useReducer(reducer, {
    showCustomNetsModal: false,
    sortConfig: null,
    sortOrder: 'asc',
    data: []
  });

  const questionDefId = questionJoiner.def.id;

  const rdConfig = useSelector(state => getRDConfig(state, sessionId), shallowEqual);

  const aggregateData = useSelector(state => getRDAggregateData(state, sessionId, questionDefId), shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    const sortConfig = get(
      rdConfig,
      `configs.matrixChoiceSortingConfig.individualQuestionConfigs[${questionJoiner.id}]`,
      {}
    );
    if (!state.sortConfig) {
      setState({ sortConfig });
    }
  }, [rdConfig]);

  useEffect(() => {
    dispatch(
      rdAggregateAnswersSubscribeActions.request({
        subAction: 'subscribe',
        sessionId,
        questionDefId,
        rdConfig
      })
    );
    return () => {
      dispatch(
        rdAggregateAnswersSubscribeActions.request({
          subAction: 'unsubscribe',
          sessionId,
          questionDefId,
          rdConfig
        })
      );
    };
  }, []);

  function getReportFilename(ext) {
    const timeStamp = moment().format('MM-DD-YYYY');
    const joinerName = surveyUtil.stripBadCharacters(questionJoiner.researchPrompt);
    const matrixType = questionJoiner.def.responseSet.type === RESPONSE_SET_TYPE.ranked ? 'Ranked' : 'Matrix';
    return `${joinerName}-${matrixType}-${timeStamp}.${ext}`;
  }

  /*
   * Need to add @class field to imageStim so back-end can de-serialize
   */
  function addImageStimClass(matrixData) {
    return matrixData.map(d => {
      if (d.row.value.imageStim) {
        d.row.value.imageStim['@class'] = 'com.invoke.clark.stim.ImageStim';
      }
      return d;
    });
  }

  const generateExcelReport = () => {
    const filename = getReportFilename('xlsx');

    const params = {
      sessionId,
      filename,
      rows: [],
      matrixData: addImageStimClass(state.data),
      joinerId: questionJoiner.id,
      netTypeCounts: getTypeCounts(rdConfig, questionDefId),
      useOrigValue: viewLanguage === ENGLISH
    };

    jsUtil.initiateFileDownload('/a/binapi/exportQuestionExcel', params, filename);
  };

  const generatePowerPointReport = () => {
    const filename = getReportFilename('pptx');

    const params = {
      sessionId,
      filename,
      rows: [],
      matrixData: addImageStimClass(state.data),
      joinerId: questionJoiner.id,
      netTypeCounts: getTypeCounts(rdConfig, questionDefId),
      useOrigValue: viewLanguage === ENGLISH
    };
    jsUtil.initiateFileDownload('/a/binapi/exportQuestionPPT', params, filename);
  };

  const doSort = (sortBy, sortOrder) => {
    const cloned = cloneDeep(rdConfig);
    const { individualQuestionConfigs } = cloned.configs.matrixChoiceSortingConfig;
    if (!individualQuestionConfigs[questionJoiner.id]) {
      individualQuestionConfigs[questionJoiner.id] = {};
    }
    individualQuestionConfigs[questionJoiner.id].sortBy = state.sortBy;
    individualQuestionConfigs[questionJoiner.id].sortOrder = state.sortOrder;
    saveRDConfig(cloned);
  };

  const displayOption = rdConfigUtil.getQuestionConfigDisplayOption(rdConfig, questionDefId);
  const netDetails = netsUtil.getNetDetails(questionJoiner, rdConfig.configs.questionsConfig) || {};

  const matrixColumns = getActiveMatrixColumns(questionJoiner);
  const { columns, rotation } =
    (state.data.length > 0 &&
      getColumns(matrixColumns, questionJoiner.def.responseSet, viewLanguage === ENGLISH, state.data)) ||
    [];

  useEffect(() => {
    if (!isEqual(state.aggregateData, aggregateData.aggregate)) {
      setState({ aggregateData: aggregateData.aggregate });
    }
  }, [aggregateData?.aggregate]);

  useEffect(() => {
    if (state.sortBy) {
      doSort();
      setState({
        sortConfig: {
          sortBy: state.sortBy,
          sortOrder: state.sortOrder
        }
      });
    }
  }, [state.sortOrder, state.sortBy]);

  useEffect(() => {
    if (state.sortConfig) {
      setState({
        data: getTableData(questionJoiner, state.aggregateData, displayOption, netDetails, state.sortConfig)
      });
    }
  }, [state.sortConfig, state.aggregateData]);

  useEffect(() => {
    if (state.data.length > 0 && displayOption !== state.data[0].displayOption) {
      setState({
        data: getTableData(questionJoiner, state.aggregateData, displayOption, netDetails, state.sortConfig)
      });
    }
  }, [displayOption]);

  const getSortColumn = sortBy => {
    setState({
      sortBy,
      sortOrder: sortBy === state.sortBy && state.sortOrder === 'desc' ? 'asc' : 'desc'
    });
  };

  const getCountsDisplay = () => {
    const texts = getHeaderText(netDetails);
    const counts = getTypeCounts(rdConfig, questionDefId);
    const header = texts.map((t, i) => {
      let delimiter = '';
      if (texts.length === 3) {
        delimiter = '/';
      }
      const count = counts[t];
      const sortBy = state.sortBy || state.sortConfig.sortBy;
      const sortOrder = state.sortOrder || state.sortConfig.sortOrder;
      return (
        <div
          onClick={() => getSortColumn(t)}
          style={{ width: texts.length === 3 ? '' : 'inherit', textTransform: 'capitalize' }}
          key={t}
        >
          <span title={t} className="inline-block clickable">{`${t} ${count}`}</span>
          {sortBy === t && (
            <span className="ps-2" style={texts.length === 1 ? { float: 'right' } : {}}>
              {sortOrder === 'asc' && <Icons.CaretUpIcon />}
              {sortOrder === 'desc' && <Icons.CaretDownIcon />}
            </span>
          )}
          <span className="px-1 inline-block">{i !== 2 && delimiter}</span>
        </div>
      );
    });
    const width = texts.length < 3 ? 6 * texts.length + 3 : 15;
    return (
      <div
        className="matrix-details-column-header"
        style={{ width: `${width}rem`, justifyContent: texts.length === 3 ? 'center' : 'space-between' }}
      >
        {header}
      </div>
    );
  };

  const formatHeader = (header, id) => {
    const sortBy = state.sortBy || state.sortConfig.sortBy;
    const sortOrder = state.sortOrder || state.sortConfig.sortOrder;
    const classes = rotation ? 'clickable text-rotate' : 'clickable';
    return id ? (
      <div className={classes} onClick={() => getSortColumn(id)}>
        <span>{header}</span>
        {sortBy === id && (
          <span className="ps-4">
            {sortOrder === 'asc' && <Icons.CaretUpIcon />}
            {sortOrder === 'desc' && <Icons.CaretDownIcon />}
          </span>
        )}
      </div>
    ) : (
      <div />
    );
  };

  const getTable = () => {
    return state.data.map(row => {
      return (
        <tr key={row.id}>
          <td>
            <div className="mb-3">{getRowLabelCell(row, viewLanguage === ENGLISH)}</div>
          </td>
          <td>
            <div className="mb-3">{getCountsBar(row, netDetails)}</div>
          </td>
          {getTableColumns(columns, row)}
        </tr>
      );
    });
  };

  const getQATitle = () => {
    if (state.data.length < 1) {
      return '';
    }
    let width = 0;
    let title = '';
    const value = viewLanguage === ENGLISH ? 'origValue' : 'value';
    state.data.forEach(line => {
      const { row } = line;
      let text = '';
      if (row.value) {
        const imageStim = get(row, 'value.imageStim', null);
        if (imageStim?.media) {
          const caption = viewLanguage === ENGLISH ? 'origCaption' : 'caption';
          text = imageStim[caption] || imageStim.media.title;
        } else {
          text = row.value[value];
        }
        text = text || row.value.abbreviatedValue;
        if (text.length > width) {
          width = text.length;
          title = text;
        }
      }
    });
    return title;
  };

  const offsetHeight = jsUtil.getRdQuestionDetailsHeaderHeight();
  const QAtitle = getQATitle();
  const QAtitleWidth = getFirstColumnWidth(QAtitle);

  return (
    <div className="matrix-question-responses" style={{ overflowY: 'auto', height: `calc(100% - ${offsetHeight}px)` }}>
      <div className="controls-row">
        <QuestionDisplayOptionSelector rdConfig={rdConfig} questionDefId={questionDefId} />
        <Button
          style={{ padding: '0 0.5rem', margin: '0.25rem' }}
          onClick={() => setState({ showCustomNetsModal: !state.showCustomNetsModal })}
        >
          {intl.get('app.customNets')}
        </Button>
        <span className="export-buttons">
          <Icons.ExcelIcon onClick={generateExcelReport} />
          <Icons.PPTIcon onClick={generatePowerPointReport} />
        </span>
      </div>
      <div className="table-container">
        {state.data.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th style={{ width: QAtitleWidth, height: rotation ? '150px' : '' }}></th>
                <th style={{ verticalAlign: 'bottom' }}>{getCountsDisplay()}</th>
                {columns.map(col => (
                  <th key={col.id}>
                    <div
                      className="matrix-details-column-header"
                      style={{ display: 'inline-block', textAlign: 'center' }}
                    >
                      {formatHeader(col.text, col.id)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{getTable()}</tbody>
          </table>
        ) : (
          <div className="no-data">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{ height: 60 }}
              description={<span> {intl.get('app.nodata')} </span>}
            />
          </div>
        )}
      </div>
      {state.showCustomNetsModal && (
        <CustomNetsModal
          matrixColumns={matrixColumns}
          netDetails={netDetails}
          questionDefId={questionDefId}
          rdConfig={rdConfig}
          toggle={() => setState({ showCustomNetsModal: !state.showCustomNetsModal })}
        />
      )}
    </div>
  );
}, skipUpdate);
