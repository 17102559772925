import React, { memo, useReducer } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  getRDSession,
  getRDQuestionJoiners,
  getRDQuestionJoinerResponseRates
} from '../../../store/redux/selectors/researchDashboardSelector';
import { jsUtil } from '../../../util/jsUtil';
import { getTableData } from './RDQuestionListUtil';
import { RDQuestionList } from './RDQuestionList';
import { RDQuestionDetails } from './RDQuestionDetails';

import './RDSessionMgmt.css';

const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

const viewModes = {
  FULL: 'full',
  COLLAPSED: 'collapsed',
  NONE: 'none'
};

function skipUpdate(prevProps, nextProps) {
  return prevProps.sessionId === nextProps.sessionId && prevProps.viewLanguage === nextProps.viewLanguage;
}

export const RDSessionMgmt = memo(props => {
  const { sessionId } = props;

  const session = useSelector(state => getRDSession(state, sessionId), shallowEqual);
  const questionJoiners = useSelector(state => getRDQuestionJoiners(state, sessionId), shallowEqual);
  const questionJoinerResponseRates = useSelector(
    state => getRDQuestionJoinerResponseRates(state, sessionId),
    shallowEqual
  );

  const [state, setState] = useReducer(reducer, {
    selectedQuestion: undefined,
    questionListViewMode: viewModes.FULL,
    qlInProp: true,
    qdInProp: false,
    qdExpandInProp: false,
    qlExpandInProp: false
  });

  function onSelectQuestion(question) {
    if (question.selectable) {
      const questionListViewMode =
        state.questionListViewMode === viewModes.FULL ? viewModes.COLLAPSED : state.questionListViewMode;
      setState({
        selectedQuestion: question,
        questionListViewMode,
        qlInProp: false,
        qlExpandInProp: false,
        qdInProp: true,
        qdExpandInProp: false
      });
    }
  }

  // Toggles QuestionList viewMode between collapsed and none
  function toggleQuestionListViewMode() {
    const questionListViewMode = state.questionListViewMode === viewModes.NONE ? viewModes.COLLAPSED : viewModes.NONE;
    const questionDetailsViewMode = questionListViewMode === viewModes.NONE ? viewModes.FULL : '';
    const qdExpandInProp = questionListViewMode === viewModes.NONE;
    setState({
      questionListViewMode,
      questionDetailsViewMode,
      qdExpandInProp
    });
  }

  function closeQuestionDetail() {
    setState({
      selectedQuestion: undefined,
      questionListViewMode: viewModes.FULL,
      questionDetailsViewMode: '',
      qlInProp: true,
      qlExpandInProp: true,
      qdInProp: false
    });
  }

  const questions = getTableData(
    questionJoiners,
    questionJoinerResponseRates,
    session.currentJoinerId,
    props.viewLanguage
  );

  const heightOffset =
    jsUtil.getHeaderHeight() +
    jsUtil.getProjectStatusBarHeight() +
    jsUtil.getRdStatusBarHeight() +
    jsUtil.getFooterHeight() +
    16; // padding

  return (
    <div className="rd-session-mgmt" style={{ height: `calc(100vh - ${heightOffset}px)` }}>
      {state.questionListViewMode !== viewModes.NONE && (
        <TransitionGroup component={null}>
          <CSSTransition in={state.qlInProp} key="ql" timeout={500} classNames="ql" appear>
            <RDQuestionList
              questions={questions}
              viewMode={state.questionListViewMode}
              onSelectQuestion={onSelectQuestion}
              qlExpandInProp={state.qlExpandInProp}
            />
          </CSSTransition>
        </TransitionGroup>
      )}
      {state.selectedQuestion && (
        <RDQuestionDetails
          sessionId={sessionId}
          questions={questions}
          selectedQuestion={state.selectedQuestion}
          viewMode={state.questionDetailsViewMode}
          onSelectQuestion={onSelectQuestion}
          toggleQuestionListViewMode={toggleQuestionListViewMode}
          closeQuestionDetail={closeQuestionDetail}
          key={state.selectedQuestion.id}
          projectId={session.projectId}
          viewLanguage={props.viewLanguage}
          setViewLanguage={props.setViewLanguage}
          language={props.language}
        />
      )}
    </div>
  );
}, skipUpdate);
