import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';

class RestoreErrorModal extends React.Component {
  back = () => {
    this.props.toggle();
  };

  render() {
    const { toggle } = this.props;
    return (
      <InvokeModal
        showModal={true}
        toggle={toggle}
        modalTitle={intl.get('app.error')}
        primaryButtonText={intl.get('app.ok')}
        enableSave={true}
        save={this.back}
      >
        <p className="m-5">{intl.get('app.restore.project.error')}</p>
      </InvokeModal>
    );
  }
}

export default RestoreErrorModal;
