import { createStructuredSelector, createSelector } from 'reselect';

const selectQuestionListDetails = state => state.questionListDetails;
const selectQuestionListRequested = questionListDetails => questionListDetails.questionListRequested;
const selectQuestionPagination = questionListDetails => questionListDetails.pageRequest;
const selectQuestionContent = questionListDetails => questionListDetails.content;
const selectQuestionTotalElements = questionListDetails => questionListDetails.totalElements;

const questionListSelector = createSelector(
  selectQuestionListDetails,
  createStructuredSelector({
    questionListRequested: selectQuestionListRequested,
    questions: selectQuestionContent,
    pagination: selectQuestionPagination,
    totalElements: selectQuestionTotalElements
  })
);

export { questionListSelector };
