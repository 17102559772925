import { post, get, del } from '../util/request';

const FUNCTIONS_API = '/a/api/customFunctions';

async function createFunction(apiOptions) {
  return post(FUNCTIONS_API)(apiOptions).then(res => res.body || {});
}

async function fetchFunctionList(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy || 'modifiedDate',
      sortOrder: apiOptions.sortOrder || 'desc',
      name: apiOptions.name || ''
    }) ||
    {};
  return get(FUNCTIONS_API)(params).then(res => (res.body && res.body.payload) || {});
}

async function fetchFunction({ functionId }) {
  const url = `${FUNCTIONS_API}/${functionId}`;
  return get(url)().then(res => (res && res.body && res.body.payload) || {});
}

async function deleteFunction({ functionId }) {
  const url = `${FUNCTIONS_API}/${functionId}`;
  return del(url)({}).then(res => res && res.body && res.body.payload);
}

async function fetchAllFunctions() {
  const params = { pageSize: -1, includeDeleted: true };
  return get(FUNCTIONS_API)(params).then(res => (res.body && res.body.payload) || {});
}

export { createFunction, fetchFunctionList, fetchFunction, deleteFunction, fetchAllFunctions };
