import React, { memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { get, cloneDeep, debounce, find, isEqual } from 'lodash';
import moment from 'moment';
import { Input } from 'reactstrap';
import { usePrevious, CopyToClipboard, InvokeTable, usePagination } from 'webapp-common';
import {
  newVerbatimDataAction,
  rdVerbatimAnswersSubscribeActions,
  toggleFavoriteParticipantAction,
  openChatWindowAction
} from '../../../../../store/redux/actions/researchDashboardActions';
import { setParticipantGroupActions } from '../../../../../store/redux/actions/filtersAndParticipantsActions';
import { useSessionUserSelector } from '../../../../../customHooks/reduxHelper';
import { getRDChats, getRDSession } from '../../../../../store/redux/selectors/researchDashboardSelector';
import { jsUtil } from '../../../../../util/jsUtil';
import { ENGLISH } from '../../../../../util/joinerUtil';
import { rdConfigUtil } from '../../../../../util/rdConfigUtil';
import { PARTICIPANT_FLAG_COLORS, saveRDConfig } from '../../../../../util/researchDashboardUtil';
import { isPrivateSession } from '../../../../../util/sessionUtil';
import { ConfigureDataColumns } from '../../participantData/ConfigureDataColumns';
import { RDParticipantDetailsModal } from '../../../rdParticipantDetails/RDParticipantDetailsModal';
import { ViewSelector } from './viewSelector/ViewSelector';
import { Sentiments } from './Sentiments';
import { Keywords } from './Keywords';
import { Highlight } from '../../../../../components/highlight/Highlight';
import { SentimentSummary } from '../../../sentimentSummary/SentimentSummary';
import { WordCloud } from '../../../wordCloud/WordCloud';
import { WhitelistModal } from '../../../whitelistModal/WhitelistModal';
import { VoteSummary } from './VoteSummary';
import { VideoResponseIcon } from '../../../../../components/videoResponse/VideoResponseIcon';
import chatUtil from '../../../../../components/chat/chatUtil';
import { Icons } from '../../../../../components/icons/Icons';

import './OpenQuestionDetails.css';

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

const sortFields = {
  chat: 'chatSort',
  favorite: 'favoriteSort',
  response: 'responses',
  flag: 'groupSort',
  textRank: 'textRankSort'
};

const DEFAULT = 'gray';

function getQuestionConfig(rdConfig, questionDefId) {
  return rdConfig.configs.questionsConfig.questionsConfigMap[questionDefId] || {};
}

function getSelectedKeyWords(rdConfig, questionDefId) {
  return getQuestionConfig(rdConfig, questionDefId).selectedKeyWords || [];
}

function getVoteDataCell(info) {
  const voteData = info.row.original;
  return <VoteSummary voteData={voteData} index={info.row.index} />;
}

function getHighlightedText(text, keywords, selectedKeyWords, compositeId) {
  const highlightPositions = [];
  keywords.forEach(kw => {
    const selected = selectedKeyWords.indexOf(kw.value) !== -1;
    if (selected) {
      const locate = find(kw.answerIndexes, { compositeId });
      if (locate) {
        locate.indexes.forEach(match => {
          const { start, end } = match;
          highlightPositions.push({ start, end });
        });
      }
    }
  });
  return <Highlight text={text} highlightPositions={highlightPositions} />;
}

function getVerbatimTableData(verbatimData, enrolleesInfo, viewLanguage) {
  const { pagedAnswers } = verbatimData;
  if (!pagedAnswers) {
    return { content: [] };
  }
  const content = pagedAnswers.content.map(answer => {
    const { compositeId, createDate, participantId, videoCaptureStatus } = answer;
    const enrollee = enrolleesInfo.enrolleeMap[participantId] || {};
    const metadata = enrollee.metadata ? enrollee.metadata.dataStore : {};
    const answerText = (viewLanguage !== ENGLISH && answer.answer.origValue) || answer.answer.value;
    const response = answer.answer.pending ? `${intl.get('app.pending')} ...` : answerText;

    const voteData = {
      agreePercent: answer.agreePercent,
      disagreePercent: answer.disagreePercent,
      neutralPercent: answer.neutralPercent,
      totalVotes: answer.totalVotes,
      votes: answer.votes
    };

    return {
      compositeId,
      participantId,
      nickname: enrollee.nickname || enrollee.id,
      favorite: enrollee.favorite,
      response,
      videoCaptureStatus,
      wordCount: answer.answer.wordCount,
      textRank: answer.answer.textRank === -1 ? '' : answer.answer.textRank,
      voteData,
      time: moment(createDate).format('hh:mm:ss'),
      flag: PARTICIPANT_FLAG_COLORS[enrollee.participantGroup] || DEFAULT,
      participantGroup: enrollee.participantGroup,
      metadata
    };
  });

  return {
    content,
    pageRequest: pagedAnswers.pageRequest,
    totalElements: pagedAnswers.totalElements
  };
}

function skipUpdate(prevProps, nextProps) {
  if (!prevProps.session) {
    return false;
  }
  if (prevProps.session.targetedURL !== nextProps.session.targetedURL) {
    return false;
  }
  if (!isEqual(prevProps.verbatimData, nextProps.verbatimData)) {
    return false;
  }
  if (nextProps.enrolleesInfo && !prevProps.enrolleesInfo) {
    return false;
  }
  if (prevProps.enrolleesInfo && prevProps.enrolleesInfo !== nextProps.enrolleesInfo) {
    return false;
  }
  if (!isEqual(prevProps.project, nextProps.project)) {
    return false;
  }
  if (prevProps.projectDetailsRequested !== nextProps.projectDetailsRequested) {
    return false;
  }

  if (prevProps.viewLanguage !== nextProps.viewLanguage) {
    return false;
  }

  const prevQuestionConfig = getQuestionConfig(prevProps.rdConfig, prevProps.questionJoiner.def.id);
  const nextQuestionConfig = getQuestionConfig(nextProps.rdConfig, nextProps.questionJoiner.def.id);
  if (!isEqual(prevQuestionConfig, nextQuestionConfig)) {
    return false;
  }

  // See if any favorite flags or enrolleeStatus have changed.
  const answers = get(nextProps, 'verbatimData.pagedAnswers.content') || [];
  const enrolleesHaveChanged = answers.some(ans => {
    const prevEnrollee = prevProps.enrolleesInfo.enrolleeMap[ans.participantId] || {};
    const nextEnrollee = nextProps.enrolleesInfo.enrolleeMap[ans.participantId];
    return (
      prevEnrollee.favorite !== nextEnrollee.favorite ||
      prevEnrollee.enrolleeStatus !== nextEnrollee.enrolleeStatus ||
      chatUtil.isActiveParticipant(prevEnrollee) !== chatUtil.isActiveParticipant(nextEnrollee)
    );
  });
  if (enrolleesHaveChanged) {
    return false;
  }

  const prevColumnConfig = get(prevProps.rdConfig, 'configs.verbatimDetailsColumnConfig');
  const nextColumnConfig = get(nextProps.rdConfig, 'configs.verbatimDetailsColumnConfig');
  if (!isEqual(prevColumnConfig, nextColumnConfig)) {
    return false;
  }

  return true;
}

export const OpenQuestionDetails = memo(props => {
  const {
    sessionId,
    questionJoiner,
    rdConfig,
    enrolleesInfo,
    verbatimData,
    saveProject,
    project,
    projectDetailsRequested,
    viewLanguage
  } = props;

  // All default columns
  const DEFAULT_COLUMNS = useMemo(() => {
    return {
      response: intl.get('app.response'),
      favorite: intl.get('app.fav'),
      chat: intl.get('app.chat'),
      wordCount: intl.get('app.words'),
      textRank: intl.get('app.textRank'),
      voteData: intl.get('app.votes'),
      time: intl.get('app.time'),
      flag: intl.get('app.flag')
    };
  }, []);

  // Default columns that are hidden by default
  const DEFAULT_HIDDEN_COLUMNS = {
    [intl.get('app.time')]: true,
    [intl.get('app.flag')]: true
  };

  const submitSaveProject = keywords => {
    const updatedProject = {
      ...project,
      keywordWhiteList: keywords
    };

    saveProject(updatedProject);
  };

  const questionDefId = questionJoiner.def.id;
  const selectedKeyWords = getSelectedKeyWords(rdConfig, questionDefId);
  const { columnOrder, enrolleeMap, keyTransformMap } = enrolleesInfo;
  const { keywordWhiteList } = project;

  const columnConfig = rdConfigUtil.getColumnConfig(
    rdConfig.configs.verbatimDetailsColumnConfig,
    DEFAULT_COLUMNS,
    DEFAULT_HIDDEN_COLUMNS,
    columnOrder
  );

  const [showWordcloud, setShowWordcloud] = useState(false);
  const [showWhitelist, setShowWhitelist] = useState(false);
  const [showSentimentSummary, setShowSentimentSummary] = useState(false);
  const [showConfigureColumns, setShowConfigureColumns] = useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState();

  const rdSession = useSelector(state => getRDSession(state, sessionId), shallowEqual);
  const privateSession = isPrivateSession(rdSession);

  const userId = useSessionUserSelector().sessionUser.userID;
  const chats = useSelector(state => getRDChats(state, sessionId), shallowEqual);

  const [state, setState] = useReducer(reducer, {
    // same format as what we get from the server
    verbatimData: {
      pagedAnswers: {
        content: [],
        pageRequest: {},
        totalElements: 0
      },
      keywords: [],
      sentiments: {},
      keywordSentiments: {}
    }
  });

  // Hack to make sure the cleanup function in the main useEffect() below has access to the latest version of the rdConfig.
  const rdConfigRef = useRef();
  useEffect(() => {
    rdConfigRef.current = rdConfig;
  }, [rdConfig]);

  const dispatch = useDispatch();

  const prevProjectDetailsRequested = usePrevious(projectDetailsRequested);
  useEffect(() => {
    if (showWhitelist && prevProjectDetailsRequested === true && projectDetailsRequested === false) {
      // Re-sub so component will rerender after save of project from whitelist modal. Also close modal.
      dispatch(
        rdVerbatimAnswersSubscribeActions.request({
          subAction: 'subscribe',
          sessionId,
          questionDefId,
          rdConfig
        })
      );
      setShowWhitelist(false);
    }
  }, [projectDetailsRequested]);

  useEffect(() => {
    dispatch(
      rdVerbatimAnswersSubscribeActions.request({
        subAction: 'subscribe',
        sessionId,
        questionDefId,
        rdConfig
      })
    );
    return () => {
      // Clear the store so that if we come back, it will get fresh data from the back-end.
      dispatch(
        newVerbatimDataAction.succeeded({
          sessionId,
          questionDefId
        })
      );

      // Unsub from the channel
      dispatch(
        rdVerbatimAnswersSubscribeActions.request({
          subAction: 'unsubscribe',
          sessionId,
          questionDefId,
          rdConfig
        })
      );
    };
  }, []);

  useEffect(() => {
    if (!verbatimData.pagedAnswers) {
      return;
    }

    if (
      state.verbatimData.pagedAnswers.totalElements === 0 ||
      !verbatimData.event ||
      viewLanguage !== state.viewLanguage
    ) {
      // Initial answers have arrived, or new verbatim data based on filtering, sorting, etc.
      setState({
        verbatimData,
        viewLanguage
      });
      return;
    }

    // Update the state if any text ranks have changed
    let updateState;
    const stateVerbatimData = cloneDeep(state.verbatimData);
    stateVerbatimData.pagedAnswers.content.forEach(ans => {
      const found = verbatimData.pagedAnswers.content.find(a => a._id === ans._id);
      if (found && found.answer.textRank !== ans.answer.textRank) {
        ans.answer.textRank = found.answer.textRank;
        updateState = true;
      }
    });

    if (updateState) {
      setState({
        verbatimData: stateVerbatimData
      });
    }
  }, [verbatimData.pagedAnswers, enrolleesInfo.hashCode, viewLanguage]);

  function showNewAnswers() {
    setState({
      verbatimData
    });
  }

  const updateFavoriteParticipant = useCallback(
    ({ favorite, participantId }) => {
      dispatch(
        toggleFavoriteParticipantAction.request({
          sessionId,
          participantId,
          favorite
        })
      );
    },
    [dispatch, sessionId]
  );

  const onFlagClick = useCallback(
    (group, participantId) => {
      dispatch(setParticipantGroupActions.request({ sessionId, participantId, group, participantSelected: null }));
    },
    [dispatch, sessionId]
  );

  const getFlagsCell = useCallback(
    info => {
      const rowData = info.row.original;
      const { flag } = rowData;
      return (
        <div style={{ width: 'fit-content', marginLeft: '1rem' }}>
          <Icons.FlagsDropdown
            id={rowData.participantId}
            flagClasses={flag}
            title={
              flag === DEFAULT
                ? intl.get('app.setFlags')
                : intl.get(`app.participantFlag.${rowData.participantGroup}.color`)
            }
            colorProp={PARTICIPANT_FLAG_COLORS}
            showBan={true}
            onFlagClick={group => onFlagClick(group, rowData.participantId)}
          />
        </div>
      );
    },
    [onFlagClick]
  );

  const getResponseCell = useCallback(
    info => {
      const rowData = info.row.original;
      const { response } = rowData;
      const { compositeId, participantId, nickname, videoCaptureStatus } = rowData;
      return (
        <>
          <div>
            <span onClick={() => setSelectedParticipantId(participantId)}>{nickname}</span>
          </div>
          <span>
            {videoCaptureStatus && <VideoResponseIcon videoCaptureStatus={videoCaptureStatus} />}
            {getHighlightedText(response, state.verbatimData.keywords, selectedKeyWords, compositeId)}
          </span>
          <CopyToClipboard
            copyText={response}
            title={intl.get('app.copyToClipboard')}
            toastText={intl.get('app.copiedToClipboard')}
          />
        </>
      );
    },
    [selectedKeyWords, state.verbatimData.keywords]
  );

  const getFavoriteCell = useCallback(
    info => {
      const rowData = info.row.original;
      return (
        <i
          className={`fas fa-star favorite-icon ${rowData.favorite ? 'favorite' : ''}`}
          onClick={() => updateFavoriteParticipant(rowData)}
        />
      );
    },
    [updateFavoriteParticipant]
  );

  const getAnswers = useCallback(() => {
    return verbatimData.pagedAnswers?.content;
  }, [verbatimData.pagedAnswers?.content]);

  const getParticipantAnswer = useCallback(
    participantId => {
      const answers = getAnswers();
      if (!answers) {
        return;
      }
      const output = {};
      output[participantId] = find(answers, { participantId }).answer;
      return output;
    },
    [getAnswers]
  );

  const startChat = useCallback(
    payload => {
      const { stim } = questionJoiner;
      const { id } = payload.chatItem ?? {};
      let chatPayload = {
        id,
        sessionId,
        researcherId: payload.researcherId
      };
      if (!id) {
        Object.assign(chatPayload, {
          participantId: payload.participantId,
          chatTitle: payload.chatTitle,
          questionJoinerId: payload.questionJoinerId,
          stim,
          questionText: questionJoiner.def.question.prompt,
          answers: getParticipantAnswer(payload.participantId)
        });
      }
      dispatch(openChatWindowAction.request(chatPayload));
    },
    [dispatch, getParticipantAnswer, questionJoiner, sessionId]
  );

  /*
   * Convenience method for getting a chat object
   */
  const getChatItem = useCallback(
    (participantId, questionJoinerId) => {
      return chatUtil.getChatItem({
        chats,
        participantId,
        questionJoinerId
      });
    },
    [chats]
  );

  const getChatCell = useCallback(
    info => {
      const rowData = info.row.original;
      const chatItem = getChatItem(rowData.participantId, questionJoiner.id);
      const hasMessage = chatItem?.messages.length > 0;
      const chatPayload = {
        ...rowData,
        chatTitle: rowData.nickname || rowData.participantId,
        responses: [rowData.response],
        chatItem,
        researcherId: userId,
        sessionId,
        questionJoinerId: questionJoiner.id
      };
      const chattable = chatUtil.isActiveParticipant(enrolleeMap[rowData.participantId]);
      return (
        <div title={hasMessage || chattable ? '' : intl.get('app.noChat')}>
          <i
            className={`fas fa-comment chat-icon ${hasMessage ? 'existing-chat' : ''} ${
              hasMessage || chattable ? '' : 'disabled'
            }`}
            onClick={() => `${hasMessage || chattable ? startChat(chatPayload) : ''}`}
          />
        </div>
      );
    },
    [enrolleeMap, getChatItem, questionJoiner.id, sessionId, startChat, userId]
  );

  const columns = useMemo(() => {
    const cols = [];

    columnConfig.columnPositions.forEach(col => {
      if (col === DEFAULT_COLUMNS.response) {
        cols.push({
          accessorKey: 'response',
          header: col,
          headerStyle: { minWidth: '38rem' },
          cellClassName: 'response-cell',
          cell: getResponseCell
        });
      } else if (col === DEFAULT_COLUMNS.favorite) {
        cols.push({
          accessorKey: 'favorite',
          header: col,
          cellClassName: 'favorite-cell',
          cell: getFavoriteCell
        });
      } else if (col === DEFAULT_COLUMNS.chat) {
        cols.push({
          accessorKey: 'chat',
          header: col,
          cellClassName: 'chat-cell',
          cell: getChatCell,
          enableSorting: false
        });
      } else if (col === DEFAULT_COLUMNS.wordCount) {
        cols.push({
          accessorKey: 'wordCount',
          header: col,
          cellClassName: 'word-count-cell'
        });
      } else if (col === DEFAULT_COLUMNS.textRank) {
        cols.push({
          accessorKey: 'textRank',
          header: col,
          cellClassName: 'text-rank-cell'
        });
      } else if (col === DEFAULT_COLUMNS.time) {
        cols.push({
          accessorKey: 'time',
          header: col,
          cellClassName: 'time-cell'
        });
      } else if (col === DEFAULT_COLUMNS.flag) {
        cols.push({
          accessorKey: 'flag',
          header: col,
          cell: getFlagsCell,
          cellClassName: 'flag-cell'
        });
      } else if (col === DEFAULT_COLUMNS.voteData) {
        if (questionJoiner.def.responseSet.sharedResponseOption.type === 'VOTED') {
          cols.push({
            accessorKey: 'voteData',
            header: col,
            cell: getVoteDataCell
          });
        }
      } else {
        // Participant metadata
        cols.push({
          accessorKey: `metadata.${col.toLowerCase()}`,
          header: keyTransformMap[col] || col
        });
      }
    });

    return cols;
  }, [
    DEFAULT_COLUMNS,
    columnConfig.columnPositions,
    getChatCell,
    getFavoriteCell,
    getFlagsCell,
    getResponseCell,
    keyTransformMap,
    questionJoiner.def.responseSet.sharedResponseOption.type
  ]);

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      const { verbatimDetailsSortingConfig } = rdConfig.configs;
      verbatimDetailsSortingConfig.sortBy = sortFields[sortBy] || sortBy;
      verbatimDetailsSortingConfig.sortOrder = sortOrder;
      saveRDConfig(rdConfig);
    },
    [rdConfig]
  );

  const paginate = useCallback(
    ({ pageNumber }) => {
      const { verbatimDetailsSortingConfig } = rdConfig.configs;
      verbatimDetailsSortingConfig.pageNumber = pageNumber;
      saveRDConfig(rdConfig);
    },
    [rdConfig]
  );

  const searchResponseAnswerAndNicknameDebounce = debounce(value => {
    const rdConfigClone = cloneDeep(rdConfig);
    const { questionsConfigMap } = rdConfigClone.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    questionsConfigMap[questionDefId].searchTerm = value;
    saveRDConfig(rdConfigClone);
  }, 500);

  const handleSearchChange = e => {
    searchResponseAnswerAndNicknameDebounce(e.target.value);
  };

  function onKeywordClick(kw) {
    const rdConfigClone = cloneDeep(rdConfig);
    const { questionsConfigMap } = rdConfigClone.configs.questionsConfig;
    if (!questionsConfigMap[questionDefId]) {
      questionsConfigMap[questionDefId] = {};
    }
    const questionConfig = questionsConfigMap[questionDefId];
    if (!questionConfig.selectedKeyWords) {
      questionConfig.selectedKeyWords = [];
    }
    const index = questionConfig.selectedKeyWords.indexOf(kw);
    if (index === -1) {
      questionConfig.selectedKeyWords.push(kw);
    } else {
      questionConfig.selectedKeyWords.splice(index, 1);
    }
    saveRDConfig(rdConfigClone);
  }

  const onConfigureColumns = () => {
    setShowConfigureColumns(!showConfigureColumns);
  };

  const numNewAnswers =
    state.verbatimData.pagedAnswers.totalElements === 0
      ? 0
      : verbatimData.pagedAnswers.totalElements - state.verbatimData.pagedAnswers.totalElements;

  const offsetHeight = jsUtil.getRdQuestionDetailsHeaderHeight();

  const pagedList = getVerbatimTableData(state.verbatimData, enrolleesInfo, viewLanguage);
  const { pageRequest, totalElements } = pagedList;
  const pagination = usePagination({ pageRequest, totalElements });

  const getWordcloudOptions = () => {
    const container = document.querySelector('.open-question-details .right');
    const containerWidth = container.clientWidth - 2;
    return { width: containerWidth, height: Math.round(containerWidth / 3) };
  };

  return (
    <section className="open-question-details" style={{ height: `calc(100% - ${offsetHeight}px)` }}>
      <div className="left" style={{ overflowY: 'auto' }}>
        <ViewSelector view={props.view} setView={props.setView} />
        <Sentiments
          sentiments={state.verbatimData.sentiments}
          sessionId={sessionId}
          questionDefId={questionDefId}
          openSentimentSummary={() => setShowSentimentSummary(true)}
        />
        <Keywords
          keywords={state.verbatimData.keywords}
          keywordSentiments={state.verbatimData.keywordSentiments}
          title={intl.get('app.keywords')}
          selectedKeyWords={selectedKeyWords}
          onKeywordClick={onKeywordClick}
          openWordcloud={() => setShowWordcloud(true)}
          openWhitelist={() => setShowWhitelist(true)}
        />
      </div>
      <div className="right" style={{ overflowY: 'auto' }}>
        <div className="search-bar">
          <Input
            className="search-bar-input"
            defaultValue={getQuestionConfig(rdConfig, questionJoiner.def.id).searchTerm}
            onChange={handleSearchChange}
          />
          <i className="fas fa-search" />
        </div>
        {showSentimentSummary && (
          <SentimentSummary
            keywords={state.verbatimData.keywords}
            sentiments={state.verbatimData.sentiments}
            keywordSentiments={state.verbatimData.keywordSentiments}
            closeSentimentSummary={() => setShowSentimentSummary(false)}
          />
        )}
        {showWordcloud && (
          <div className="wordcloud-container">
            <i className="far fa-times-circle" onClick={() => setShowWordcloud(false)} />
            <WordCloud
              keywords={state.verbatimData.keywords}
              options={getWordcloudOptions()}
              onKeywordClick={onKeywordClick}
            />
          </div>
        )}
        <div className="pre-table-row">
          <span>{`${state.verbatimData.pagedAnswers.totalElements} ${intl.get('app.responses')}`}</span>
          {privateSession && (
            <div>
              <i className="fas fa-cog" />
              <button className="link-button" onClick={onConfigureColumns}>
                {intl.get('app.configureColumns')}
              </button>
            </div>
          )}
        </div>
        <div className="question-details">
          <InvokeTable
            className="invoke-table"
            columns={columns}
            data={pagedList.content}
            pagination={pagination}
            onPaginationChange={paginate}
            onSortingChange={sort}
          />
        </div>
        {numNewAnswers > 0 && (
          <div className="show-new-responses">
            <button className="link-button" onClick={showNewAnswers}>
              {intl.get('app.showNewResponses', { num: numNewAnswers })}
            </button>
          </div>
        )}
        {showConfigureColumns && (
          <ConfigureDataColumns
            showModal={showConfigureColumns}
            toggle={onConfigureColumns}
            rdConfig={rdConfig}
            columnConfig={columnConfig}
            defaultColumns={Object.values(DEFAULT_COLUMNS)}
            columnOrder={columnOrder}
            keyTransformMap={keyTransformMap}
            sessionId={sessionId}
            saveRDConfig={saveRDConfig}
          />
        )}
      </div>
      {!!selectedParticipantId && (
        <RDParticipantDetailsModal
          sessionId={sessionId}
          participant={enrolleeMap[selectedParticipantId]}
          toggle={() => setSelectedParticipantId('')}
          setViewLanguage={props.setViewLanguage}
          language={props.language}
        />
      )}
      {showWhitelist && (
        <WhitelistModal
          keywordWhiteList={keywordWhiteList}
          sessionId={sessionId}
          toggle={() => setShowWhitelist(false)}
          saveProject={submitSaveProject}
        />
      )}
    </section>
  );
}, skipUpdate);
