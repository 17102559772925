import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { Route, Routes, useLocation } from 'react-router-dom';
import intl from 'react-intl-universal';
import * as Sentry from '@sentry/react';
import { auth } from '../../api/Auth';
import { routes } from '../../router/routes';
import { falconSubscribeActions } from '../../store/redux/actions/falconActions';
import DefaultLayout from '../../components/layouts/DefaultLayout/DefaultLayout';
import { PageFooter } from '../../components/pageFooter/PageFooter';
import PageHeader from '../pageHeader';

function applyCustomUI(clientUIConfig) {
  const { style } = document.documentElement;
  for (const field in clientUIConfig) {
    style.setProperty(`--${field}`, clientUIConfig[field]);
  }
}

const App = props => {
  const { clientUIConfig, sessionUser, sessionTenant } = props;

  Sentry.setUser({ email: sessionUser?.email });

  const location = useLocation();
  const dispatch = useDispatch();

  function getActiveRoute() {
    return routes.find(r => {
      if (Array.isArray(r.path)) {
        return r.path.some(path => matchPath({ path }, location.pathname));
      }
      return matchPath({ path: r.path }, location.pathname);
    });
  }

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      if (props.mediaListLibrary?.uploadMediaInProgress) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = undefined;
        return intl.get('app.uploadInProgress');
      }
    });

    const route = getActiveRoute();
    if (route?.authenticated) {
      props.fetchSessionUser();
    }
  }, []);

  useEffect(() => {
    if (sessionTenant?.clientID) {
      dispatch(
        falconSubscribeActions.request({
          subAction: 'subscribe',
          clientId: sessionTenant.clientID
        })
      );
    }
  }, [sessionTenant?.clientID]);

  useEffect(() => {
    if (props.clientStore.saveClientSuccess) {
      props.fetchSessionUser();
    }
  }, [props.clientStore.saveClientSuccess]);

  useEffect(() => {
    if (props.clientStore.createClientSuccess) {
      props.fetchSessionUser();
    }
  }, [props.clientStore.createClientSuccess]);

  useEffect(() => {
    applyCustomUI(clientUIConfig);
  }, [clientUIConfig]);

  // Calling this here keeps the user's session active
  const isAuthenticated = auth.isAuthenticated(true);

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <DefaultLayout header={<PageHeader isAuthenticated={isAuthenticated} />} footer={<PageFooter />}>
        <Routes>
          {routes.map(route => (
            <Route path={route.path} element={<route.component {...props} routes={route.routes} />} key={route.path} />
          ))}
        </Routes>
      </DefaultLayout>
    </Sentry.ErrorBoundary>
  );
};

export default App;
