import { connect } from 'react-redux';
import {
  fetchCustomReportConfigListAction,
  saveCustomReportConfigActions
} from '../../../../store/redux/actions/customReportConfigActions';
import { customReportConfigSelector } from '../../../../store/redux/selectors/customReportConfigSelector';
import { CustomReportsPage } from './CustomReportsPage';

const mapDispatchToProps = dispatch => ({
  fetchCustomReportConfigs: params => dispatch(fetchCustomReportConfigListAction.request(params)),
  saveCustomReportConfig: config => dispatch(saveCustomReportConfigActions.request(config))
});

export default connect(customReportConfigSelector, mapDispatchToProps)(CustomReportsPage);
