import React, { memo } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { cloneDeep, isEqual } from 'lodash';
import { getFormatedTime } from '../../../util/joinerUtil';
import { getFilteredActionTotals, getColorFromSetting } from './DialDataUtil';

const dataOutputTypeMap = {
  AVERAGE: 'avg'
};

const skipUpdate = (prevProps, nextProps) => {
  return (
    prevProps.dataLastBuilt === nextProps.dataLastBuilt &&
    prevProps.currentVideoTime === nextProps.currentVideoTime &&
    prevProps.filterList.length === nextProps.filterList.length &&
    prevProps.selectedFilters.length === nextProps.selectedFilters.length &&
    prevProps.selectedAction === nextProps.selectedAction &&
    prevProps.overlay === nextProps.overlay &&
    isEqual(prevProps.dialSetting, nextProps.dialSetting)
  );
};

const DialDataFilters = memo(props => {
  const {
    filterList,
    selectedFilters,
    perSecondData,
    filterTotals,
    currentVideoTime,
    selectFilter,
    dataOutputType,
    scatterRatingData,
    selectedAction,
    overlay,
    rawScatterActionData,
    dialSetting
  } = props;

  const { dialColorSettings = [] } = dialSetting;
  const actionTotals = getFilteredActionTotals(rawScatterActionData, selectedAction);

  const updateDialFilters = (color, filter) => {
    const colorSettings = cloneDeep(dialColorSettings);
    if (colorSettings.length === 0) {
      colorSettings.push({
        filterId: filter.id,
        color: overlay ? null : color,
        overlayColor: overlay ? color : null
      });
    } else {
      let found = false;
      colorSettings.map(s => {
        if (s.filterId === filter.id) {
          if (overlay) {
            s.overlayColor = color;
          } else {
            s.color = color;
          }
          found = true;
        }
        return s;
      });
      if (!found) {
        colorSettings.push({
          filterId: filter.id,
          color: overlay ? null : color,
          overlayColor: overlay ? color : null
        });
      }
    }
    props.updateDialFilters({
      ...dialSetting,
      dialColorSettings: colorSettings
    });
  };

  const getFilterRow = filter => {
    const { name } = filter;
    const timeSlice = perSecondData[currentVideoTime] || {};
    const filterColor = getColorFromSetting(filter, overlay, dialColorSettings);
    const filterTimeSlice = timeSlice[name] || {};
    const filterTotal = scatterRatingData[name] || {};
    const totals = filterTotals[name] || {};
    const dataArray = filterTotal[dataOutputTypeMap[dataOutputType]] || [];
    const finalArray = [];
    dataArray.forEach(item => {
      item.y && finalArray.push(item.y);
    });
    const sum = finalArray.reduce((acc, val) => acc + val, 0);
    const maxValue = (finalArray.length && Math.max(...finalArray)) || '';
    const avg = (finalArray.length && Math.round(sum / finalArray.length)) || '';

    return (
      <tr key={name}>
        <td>
          <label>
            <Input
              type="checkbox"
              checked={selectedFilters.some(f => f.name === name)}
              onChange={() => selectFilter(filter)}
              className="me-2"
            />
            {name}
          </label>
        </td>
        <td style={{ textAlign: 'center' }}>
          <input
            type="color"
            value={filterColor}
            style={{
              border: 'none',
              height: '1.5rem',
              width: '1.25rem',
              padding: 0,
              background: 'none',
              margin: '0 auto'
            }}
            onChange={e => updateDialFilters(e.target.value, filter)}
            disabled={name === 'Total'}
            className={name === 'Total' ? 'not-allowed' : 'clickable'}
          />
        </td>
        <td>{totals.numParticipants}</td>
        <td>{avg}</td>
        <td>{maxValue}</td>
        <td>{filterTimeSlice.avg}</td>
        {selectedAction && <td>{actionTotals[name]}</td>}
      </tr>
    );
  };

  const getFilterRows = () => {
    return filterList.map(filter => getFilterRow(filter));
  };

  return (
    <div className="dial-data-filters">
      <div className="title">{intl.get('app.filters')}</div>
      <div className="filters-table">
        <table>
          <thead>
            <tr>
              <th>{intl.get('app.name')}</th>
              <th>{intl.get('app.color')}</th>
              <th>N</th>
              <th>{intl.get('app.avg')}</th>
              <th>{intl.get('app.max')}</th>
              <th>{getFormatedTime(currentVideoTime)}</th>
              {selectedAction && <th>{selectedAction}</th>}
            </tr>
          </thead>
          <tbody>{getFilterRows()}</tbody>
        </table>
      </div>
    </div>
  );
}, skipUpdate);

export default DialDataFilters;
