import { connect } from 'react-redux';
import { HomePage } from './HomePage';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';

const mapStateToProps = state => {
  const { sessionUser, abilities } = sessionUserSelector(state);
  return {
    sessionUser,
    abilities
  };
};

export default connect(mapStateToProps)(HomePage);
