import React from 'react';
import ReportList from '../../../reportList';

export const ReportDownload = props => {
  const { projectId } = props;
  return (
    <section className="report-download">
      <ReportList projectId={projectId} pageSize={-1} showStatusIcons />
    </section>
  );
};
