import { connect } from 'react-redux';
import { fetchAllFunctionsActions } from '../../../../../../store/redux/actions/functionActions';
import { allFunctionsSelector } from '../../../../../../store/redux/selectors/functionSelector';
import CustomFunctionCondition from './CustomFunctionCondition';

const mapDispatchToProps = dispatch => ({
  fetchAllFunctions: () => dispatch(fetchAllFunctionsActions.request())
});

export default connect(allFunctionsSelector, mapDispatchToProps)(CustomFunctionCondition);
