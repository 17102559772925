import React from 'react';
import { orderBy } from 'lodash';

import './Highlight.css';

function getHighlightedText(text, highlightPositions) {
  let alteredText = text;
  const sortedPositions = orderBy(highlightPositions, ['end'], ['desc']);
  sortedPositions.forEach(pos => {
    const { start, end } = pos;
    const testStr = alteredText.substring(start);
    if (!testStr.startsWith('<span class="highlight">')) {
      // Wrap the highlightedf text in a span, unless it's already wrapped.
      const pre = alteredText.substring(0, start);
      const highlighted = alteredText.substring(start, end + 1);
      const post = alteredText.substring(end + 1);
      alteredText = `${pre}<span class="highlight">${highlighted}</span>${post}`;
    }
  });
  return alteredText;
}

export const Highlight = props => {
  const { highlightPositions, text } = props;
  const highlightedText = getHighlightedText(text, highlightPositions);
  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};
