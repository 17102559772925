import { createWebSocketSendActions, createWebSocketReceiveActions, createFetchActions } from './createFetchActions';

const dialDataChannelSubscribeActions = createWebSocketSendActions('DIAL_DATA_CHANNEL_SUBSCRIBE');
const newDialDataActions = createWebSocketReceiveActions('NEW_DIAL_DATA');

const dialDataFileChannelSubscribeActions = createWebSocketSendActions('DIAL_REPORT_FILE_CHANNEL_SUBSCRIBE');
const newDialDataFileActions = createWebSocketReceiveActions('NEW_DIAL_REPORT_FILES_DATA');

const exportDialDataActions = createFetchActions('EXPORT_DIAL_DATA');

const fetchDialSettingConfigActions = createFetchActions('FETCH_DIAL_SETTING_CONFIG');
const saveDialSettingConfigActions = createFetchActions('SAVE_DIAL_SETTING_CONFIG');
const fetchVideoWatermarkActions = createFetchActions('FETCH_VIDEO_WATERMARK');
const deleteVideoWatermarkActions = createFetchActions('DELETE_VIDEO_WATERMARK');

export {
  dialDataChannelSubscribeActions,
  newDialDataActions,
  dialDataFileChannelSubscribeActions,
  newDialDataFileActions,
  exportDialDataActions,
  fetchDialSettingConfigActions,
  saveDialSettingConfigActions,
  fetchVideoWatermarkActions,
  deleteVideoWatermarkActions
};
