import React from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

import appConfig from '../../../appConfig';
import ProjectList from '../../projectList';

import './HomePage.css';

export const HomePage = props => {
  const { sessionUser, abilities } = props;

  const hasFirstName = sessionUser.firstName && sessionUser.firstName.length > 0;

  const clientId = sessionUser.clientId;

  return (
    <div className="home-page mt-3 pe-3">
      <Col md={7} className="ps-4">
        <div>
          <span className="p-2 title">
            {intl.get('app.welcome', { firstName: sessionUser.firstName || sessionUser.email })}
          </span>
          {!hasFirstName && (
            <Link to={appConfig.profilePagePath} className="nav-label ms-3 link">
              {intl.get('app.profileEdit')}
            </Link>
          )}
        </div>
        {abilities.hasProjectView && (
          <div className="p-2 mt-4">
            <div className="heading">{intl.get('app.recentProjects')}</div>
            <ProjectList
              pageSize={5}
              noSessionLinks
              displayRecentProjects
              enablePagination={false}
              enableSort={false}
              clientId={clientId}
            />
          </div>
        )}
      </Col>
    </div>
  );
};
