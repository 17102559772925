import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import { RESPONSE_SET_TYPE } from '../../../../../util/joinerUtil';
import { toast } from '../../../../../util/toast';

const DEFAULT = 'DEFAULT';
const reducer = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

const flattenQuestion = (joiner, questionType, isEnglish) => {
  const questionText = [];
  questionText.push(joiner.researchPrompt);
  questionText.push('');
  questionText.push(isEnglish ? joiner.def.question.prompt : joiner.def.question.origPrompt);
  questionText.push('');
  switch (questionType) {
    case RESPONSE_SET_TYPE.multi:
      joiner.def.responseSet.choices = joiner.def.responseSet.choices.filter(choice => !choice.value.disable);
      if (joiner.def.responseSet.choices && joiner.def.responseSet.choices.length > 0) {
        for (let i = 0; i < joiner.def.responseSet.choices.length; i++) {
          const choice = joiner.def.responseSet.choices[i];
          if (choice.value.entryType !== DEFAULT) {
            break; // no more joiners after 'Other' types
          }
          questionText.push(isEnglish ? choice.value.value : choice.value.origValue);
        }
      }
      break;
    case RESPONSE_SET_TYPE.ranked:
      if (
        joiner.def.responseSet.entries &&
        joiner.def.responseSet.entries.rows &&
        joiner.def.responseSet.entries.rows.length > 0
      ) {
        questionText.push(
          joiner.def.responseSet.entries.rows
            .filter(choice => !choice.value.disable)
            .map(row => (isEnglish ? row.value.value : row.value.origValue))
        );
      }
      break;
    case RESPONSE_SET_TYPE.matrix:
      if (
        joiner.def.responseSet.entries &&
        joiner.def.responseSet.entries.rows &&
        joiner.def.responseSet.entries.rows.length > 0
      ) {
        questionText.push(
          joiner.def.responseSet.entries.rows
            .filter(choice => !choice.value.disable)
            .map(row => (isEnglish ? row.value.value : row.value.origValue))
        );
      }
      questionText.push('');
      if (
        joiner.def.responseSet.entries &&
        joiner.def.responseSet.entries.columnData &&
        joiner.def.responseSet.entries.columnData.columns &&
        joiner.def.responseSet.entries.columnData.columns.length > 0
      ) {
        questionText.push(
          joiner.def.responseSet.entries.columnData.columns
            .filter(choice => !choice.value.disable)
            .map(column => (isEnglish ? column.value.value : column.value.origValue))
        );
      }
      break;
    default:
      break;
  }
  return questionText.flat().join('\n');
};

export const ImportQuestionModal = props => {
  const { joiner, toggle, modalTitle, questionType, importQuestion, isEnglish } = props;

  const [state, setState] = useReducer(reducer, {
    originalText: '',
    questionType: ''
  });

  useEffect(() => {
    const questionText = flattenQuestion(joiner, questionType, isEnglish);
    setState({
      originalText: questionText,
      questionText
    });
  }, []);

  const onQuestionChange = e => {
    setState({ questionText: e.target.value });
  };

  const validateText = text => {
    if (text.length < 1) {
      toast.error({ text: intl.get('app.importQuestion.titleError', {}) });
      return false;
    }
    return true;
  };

  const onSave = () => {
    const questionText = [];
    state.questionText.split('\n').forEach(t => {
      const text = t.trim();
      if (text !== '' || questionText.length > 2) {
        questionText.push(text);
      }
    });
    if (validateText(questionText)) {
      importQuestion(questionText);
    }
  };

  return (
    <InvokeModal
      showModal
      className="import-question-modal"
      toggle={toggle}
      modalTitle={modalTitle}
      primaryButtonText={intl.get('app.import')}
      cancelButtonText={intl.get('app.cancel')}
      save={onSave}
      enableSave={state.questionText !== state.originalText}
    >
      <div>{intl.get('app.importQuestion.note1')}.</div>
      <div>{intl.get('app.importQuestion.note2')}.</div>
      {questionType === RESPONSE_SET_TYPE.matrix && <div>{intl.get('app.importQuestion.note3')}.</div>}
      <textarea rows="15" style={{ width: '100%' }} value={state.questionText} onChange={onQuestionChange} />
    </InvokeModal>
  );
};
