import { createSelector } from 'reselect';

const surveyStore = state => state.surveys;
const selectSurveyId = (state, surveyId) => surveyId;

const surveySelector = () =>
  createSelector([surveyStore, selectSurveyId], (surveyStore, surveyId) => ({
    survey: surveyStore[surveyId],
    fetchSurveyInProgress: surveyStore.fetchSurveyInProgress,
    saveQuestionJoinerInProgress: surveyStore.saveQuestionJoinerInProgress,
    saveQuestionJoinerSuccess: surveyStore.saveQuestionJoinerSuccess,
    saveQuestionJoinerFailed: surveyStore.saveQuestionJoinerFailed,
    saveSurveyInProgress: surveyStore.saveSurveyInProgress,
    saveSurveySuccess: surveyStore.saveSurveySuccess,
    refetchSurvey: surveyStore.refetchSurvey,
    deleteSurveyQuestionsInProgress: surveyStore.deleteSurveyQuestionsInProgress
  }));

export { surveySelector };
