export const getRDActiveParticipants = (state, sessionId) => {
  const obj = state.rdActiveParticipants[sessionId];
  return obj ? obj.participantCount : {};
};

export const getRDChats = (state, sessionId) => {
  const obj = state.rdChats[sessionId];
  return obj ? obj.chats : [];
};

export const getRDConfig = (state, sessionId) => {
  const obj = state.rdConfig[sessionId];
  return obj ? obj.rdConfig : {};
};

export const getRDEnrollees = (state, sessionId) => {
  const obj = state.rdEnrollees[sessionId];
  return obj ? obj.enrolleesInfo : {};
};

export const getRDFilteredEnrollees = (state, sessionId) => {
  const obj = state.rdFilteredEnrollees[sessionId];
  return obj ? obj.filteredEnrollees : {};
};

export const getRDParticipantDataPoints = (state, sessionId) => {
  const obj = state.rdParticipantDataPoints[sessionId];
  return obj ? obj.participantDataPoints : {};
};

export const getRDParticipantSummaryFilters = (state, sessionId) => {
  const obj = state.rdParticipantSummaryFilters[sessionId];
  return obj ? obj.summaryFilters : [];
};

export const getRDQuestionJoiners = (state, sessionId) => {
  const obj = state.rdQuestionJoiners[sessionId];
  return obj ? obj.questionJoiners : [];
};

export const getRDQuestionJoinerResponseRates = (state, sessionId) => {
  const obj = state.rdQuestionJoinerResponseRates[sessionId];
  return obj ? obj.questionJoinerResponseRates : {};
};

export const getRDSession = (state, sessionId) => {
  const obj = state.rdSession[sessionId];
  return obj ? obj.session : {};
};

export const getRDSessionQuotaFilters = (state, sessionId) => {
  const obj = state.rdSessionQuotaFilters[sessionId];
  return obj ? obj.quotaFilters : [];
};

export const getRDAggregateData = (state, sessionId, questionDefId) => {
  const obj = state.rdAggregateData[`${sessionId}-${questionDefId}`];
  return obj || {};
};

export const getRDVerbatimData = (state, sessionId, questionDefId) => {
  const obj = state.rdVerbatimData[`${sessionId}-${questionDefId}`];
  return obj || {};
};

export const getRDParticipantAnswers = (state, sessionId, participantId) => {
  const obj = state.rdParticipantAnswers[`${sessionId}-${participantId}`];
  return obj || [];
};

export const getUserId = state => state.sessionUser.user && state.sessionUser.user.userID;

export const getAdvancedFiltered = (state, sessionId) => {
  const obj = state.rdFilteredEnrollees[`${sessionId}-advanced`];
  return obj || {};
};

export const getRDComparativeViewAggregateData = (state, comparativeViewId) =>
  state.rdComparativeViewAggregateData[comparativeViewId];

export const getClusterAnalysis = (state, sessionId, questionDefId) =>
  state.rdClusterAnalysis[`${sessionId}-${questionDefId}`] || {};
