import { connect } from 'react-redux';
import { sessionUserSelector } from '../../../../../../store/redux/selectors/sessionUserSelector';
import {
  getRDChats,
  getRDConfig,
  getRDEnrollees,
  getRDSessionQuotaFilters
} from '../../../../../../store/redux/selectors/researchDashboardSelector';
import { OpenQuestionClusterAnalysis } from './OpenQuestionClusterAnalysis';

const mapStateToProps = (state, props) => {
  const { sessionId, questionJoiner } = props;
  const {
    abilities: { hasProjectManage }
  } = sessionUserSelector(state);
  return {
    clusterAnalysis: state.rdClusterAnalysis[`${sessionId}-${questionJoiner.def.id}`],
    rdConfig: getRDConfig(state, sessionId),
    quotaFilters: getRDSessionQuotaFilters(state, sessionId),
    enrolleesInfo: getRDEnrollees(state, sessionId),
    chats: getRDChats(state, sessionId),
    userId: state.sessionUser.user.userID,
    hasProjectManage
  };
};

export default connect(mapStateToProps)(OpenQuestionClusterAnalysis);
