import { connect } from 'react-redux';
import { fetchClientActions } from '../../../store/redux/actions/clientAdminActions';
import { clientAdminSelector } from '../../../store/redux/selectors/clientAdminSelector';
import { sessionUserSelector } from '../../../store/redux/selectors/sessionUserSelector';

import { OrgPage } from './OrgPage';

const mapStateToProps = state => {
  const { sessionUser, abilities } = sessionUserSelector(state);
  return {
    client: clientAdminSelector()(state, sessionUser.clientId).client,
    abilities
  };
};

const mapDispatchToProps = dispatch => ({
  fetchClient: () => dispatch(fetchClientActions.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPage);
