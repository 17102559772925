import { get, post, del } from '../util/request';

async function fetchMediaList(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy || 'modifiedDate',
      sortOrder: apiOptions.sortOrder || 'desc',
      title: apiOptions.title || '',
      type: apiOptions.type || '',
      sessionId: apiOptions.sessionId || '',
      includeUsageCount: true
    }) ||
    {};

  return get('/a/api/medialist')(params).then(res => (res.body && res.body.payload) || {});
}

/*
 * Used for saving URL-based media
 *
 * @param accessUrl
 * @param fileName - should be same as accessUrl
 * @param type
 * @param title
 * @param _id - Optional - for edit
 */
async function saveMedia({ accessUrl, fileName, type, title, _id, pageRequest }) {
  const params = {
    accessUrl,
    fileName,
    type,
    title,
    _id
  };
  return post('/a/api/saveMedia')(params).then(response => ({ media: response.body.payload, pageRequest }));
}

/*
 * Used for deleting media
 *
 * @param id - media
 * @param fullDelete - shallow delete from media library only (false) or all references in all surveys (true)
 * @param pageRequest - page request params for refreshing this media list
 */
async function deleteMedia({ id, fullDelete, pageRequest, onDeleteMedia }) {
  const url = '/a/api/media';
  const payload = {
    mediaId: id,
    fullDelete
  };
  return del(url)(payload).then(res => {
    return {
      ...res.body,
      pageRequest,
      fullDelete,
      onDeleteMedia
    };
  });
}

export { fetchMediaList, saveMedia, deleteMedia };
