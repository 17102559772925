import React from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { Select } from 'antd';
import { toast } from '../../../../../util/toast';
import AddRemoveIcons from '../../../../../components/core/addRemoveIcons/AddRemoveIcons';
import { CommonJoinerEditFields } from '../../joinerEditor';
import MediaChoiceModal from '../../modals/mediaChoice/MediaChoiceModal';
import { ImportQuestionButton } from '../../modals/importQuestion/ImportQuestionButton';
import { ImportQuestionModal } from '../../modals/importQuestion/ImportQuestionModal';
import {
  RESPONSE_SET_TYPE,
  MATRIX_CHOICES,
  getChoiceValue,
  getStimCaption,
  ENGLISH
} from '../../../../../util/joinerUtil';
import { mediaUtil } from '../../../../../util/mediaUtil';
import { TranslateLabel } from './TranslateLabel';

const Option = Select.Option;
const OTHER = 'OTHER';

class RankedEdit extends React.Component {
  constructor(props) {
    super(props);
    const { joiner, joiners, updateHandlers } = props;
    const option = updateHandlers.findReferencedQuestion(joiner);
    const isReferenced = option.length > 0;
    const referencedJoiner = isReferenced ? joiners.filter(j => j.id === option[0].joinerId)[0] : {};
    const multipleChoiceQuestions = isReferenced
      ? updateHandlers.onChooseAnswersChange(joiners, joiner, MATRIX_CHOICES.choiceAnswers)
      : [];
    this.state = {
      showSelectMediaModal: false,
      showImportQuestionModal: false,
      chooseChoiceAnswers: isReferenced ? MATRIX_CHOICES.choiceAnswers : MATRIX_CHOICES.enterRows,
      selectedChoiceQuestionId: referencedJoiner?.id,
      multipleChoiceQuestions
    };
  }

  toggleSelectMediaModal = (choiceIndex, choiceStim) => {
    if (!this.props.readOnly) {
      this.setState({
        showSelectMediaModal: !this.state.showSelectMediaModal,
        choiceIndex,
        choiceStim
      });
    }
  };

  toggleImportQuestionModal = imported => {
    this.setState({ showImportQuestionModal: !this.state.showImportQuestionModal, imported });
  };

  onMediaUpdate = stim => {
    this.props.updateHandlers.updateRankedChoiceMedia(this.state.choiceIndex, stim);
    this.toggleSelectMediaModal();
  };

  updateRankedChoiceValue = (e, index) => {
    this.props.updateHandlers.updateRankedChoiceValue(
      index,
      e.target.value,
      this.props.language,
      this.props.viewLanguage
    );
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  updateRankedChoiceAbbreviatedValue = (e, index) => {
    this.props.updateHandlers.updateRankedChoiceAbbreviatedValue(index, e.target.value);
    if (this.state.imported) {
      this.setState({ imported: false });
    }
  };

  getChoices = choices => {
    const { language, viewLanguage, readOnly } = this.props;
    if (choices.length < 1) {
      this.props.updateHandlers.addRankedChoice(-1);
      return;
    }
    return choices
      .filter(choice => !choice.value.disable)
      .map(choice => {
        const index = parseInt(choice.index, 10);
        const choiceValue = getChoiceValue(choice, language, viewLanguage);
        const showRemove =
          choices.length > 1 ||
          (choice.value &&
            (choice.value.abbreviatedValue ||
              choice.value.value ||
              choice.value.origValue ||
              choice.value.imageStim ||
              choice.value.scalar));
        return (
          choice.value.entryType !== OTHER && (
            <div className="mb-2" key={`ranked_${choice.id}`}>
              {choice.value.imageStim ? (
                <React.Fragment>
                  <Input
                    className="first"
                    value={getStimCaption(choice.value.imageStim, language, viewLanguage)}
                    disabled
                  />
                  <div className="second" onClick={() => this.toggleSelectMediaModal(index, choice.value.imageStim)}>
                    {choice.value.imageStim.media ? (
                      <img src={mediaUtil.getMediaUrl(choice.value.imageStim.media)} alt="" />
                    ) : (
                      <i className="far fa-image image" />
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Input
                    key={`ranked_${choice.id}`}
                    value={choiceValue}
                    disabled={readOnly}
                    className="first"
                    onChange={e => this.updateRankedChoiceValue(e, index)}
                  />
                  <div className="second" onClick={() => this.toggleSelectMediaModal(index, choice.value.imageStim)}>
                    <i className="far fa-image fa-2x image" />
                  </div>
                </React.Fragment>
              )}
              <Input
                value={this.state.imported && viewLanguage !== ENGLISH ? '' : choice.value.abbreviatedValue || ''}
                disabled={readOnly}
                className="third"
                onChange={e => this.updateRankedChoiceAbbreviatedValue(e, index)}
              />
              {!readOnly && (
                <AddRemoveIcons
                  onAdd={() => this.props.updateHandlers.addRankedChoice(index + 1)}
                  onRemove={showRemove ? () => this.props.updateHandlers.removeRankedChoice(index) : null}
                />
              )}
            </div>
          )
        );
      });
  };

  importQuestion = question => {
    this.props.updateHandlers.importQuestion(
      question,
      RESPONSE_SET_TYPE.ranked,
      this.props.viewLanguage,
      this.props.language
    );
    this.toggleImportQuestionModal(true);
  };

  onChooseAnswersChange = value => {
    const { joiner, joiners = [], updateHandlers } = this.props;
    const multipleChoiceQuestions = updateHandlers.onChooseAnswersChange(joiners, joiner, value);
    this.setState({ multipleChoiceQuestions, chooseChoiceAnswers: value });
  };

  getChoiceAnswers = () => {
    if (this.state.multipleChoiceQuestions.length < 1) {
      toast.error({ text: intl.get('app.matrixEdit.noPreviousChoiceQuestions') });
      this.setState({ chooseChoiceAnswers: MATRIX_CHOICES.enterRows });
      return;
    }
    return this.state.multipleChoiceQuestions.map(j => (
      <Option value={j.id} key={j.id}>
        {j.researchPrompt}
      </Option>
    ));
  };

  pickChoiceQuestion = joinerId => {
    const question = this.state.multipleChoiceQuestions.find(j => j.id === joinerId);
    this.props.updateHandlers.pickChoiceQuestion(question, this.props.joiner);
    this.setState({
      selectedChoiceQuestionId: joinerId
    });
  };

  render() {
    const { joiner, tabs, readOnly, isSessionOpen, insertOrEditAction, viewLanguage, language, setViewLanguage } =
      this.props;
    const { rows } = joiner.def.responseSet.entries;
    const disableImport = this.state.chooseChoiceAnswers === MATRIX_CHOICES.choiceAnswers;
    return (
      <div className={`${tabs.edit} ranked-edit`}>
        <CommonJoinerEditFields {...this.props} />
        <div className="choices">
          <div className="label editor-left-column">
            <div>{intl.get('app.choices')}</div>
          </div>
          <div className="editor-right-column">
            {this.state.chooseChoiceAnswers === MATRIX_CHOICES.enterRows && (
              <div>
                <div className="column-title first">{intl.get('app.choices')}</div>
                <div className="column-title second">{intl.get('app.media')}</div>
                <div className="column-title third">{intl.get('app.choiceEdit.abbreviate')}</div>
              </div>
            )}
          </div>
        </div>
        <div className="choices">
          <div className="rows-area editor-left-column">
            <Select
              value={this.state.chooseChoiceAnswers}
              onChange={this.onChooseAnswersChange}
              className="rows-value-selector"
            >
              <Option value={MATRIX_CHOICES.enterRows}>{intl.get('app.matrixEdit.enterRowOptions')}</Option>
              <Option value={MATRIX_CHOICES.choiceAnswers}>{intl.get('app.matrixEdit.choiceAnswers')}</Option>
            </Select>
          </div>
          <div className="editor-right-column">
            {this.state.chooseChoiceAnswers === MATRIX_CHOICES.enterRows && this.getChoices(rows)}
            {this.state.chooseChoiceAnswers === MATRIX_CHOICES.choiceAnswers && (
              <div style={{ width: '100%' }}>
                <Select
                  placeholder={intl.get('app.matrixEdit.chooseChoiceQuestions')}
                  value={this.state.selectedChoiceQuestionId}
                  onChange={this.pickChoiceQuestion}
                  className="choice-question-selector"
                >
                  {this.getChoiceAnswers()}
                </Select>
              </div>
            )}
          </div>
        </div>
        <div className="import-and-language">
          <ImportQuestionButton
            isDisabled={disableImport}
            readOnly={readOnly}
            isSessionOpen={isSessionOpen}
            insertOrEditAction={insertOrEditAction}
            toggleImportQuestionModal={this.toggleImportQuestionModal}
          />
          {language !== ENGLISH && (
            <TranslateLabel
              setViewLanguage={setViewLanguage}
              language={language}
              viewLanguage={viewLanguage}
              sessionId={this.props.sessionId}
            />
          )}
        </div>
        {this.state.showSelectMediaModal && (
          <MediaChoiceModal
            stim={this.state.choiceStim}
            language={language}
            viewLanguage={viewLanguage}
            readOnly={readOnly}
            toggle={this.toggleSelectMediaModal}
            onSave={this.onMediaUpdate}
          />
        )}
        {this.state.showImportQuestionModal && (
          <ImportQuestionModal
            toggle={this.toggleImportQuestionModal}
            modalTitle={intl.get('app.import.rankedQuestion')}
            questionType={RESPONSE_SET_TYPE.ranked}
            joiner={joiner}
            importQuestion={this.importQuestion}
            isEnglish={language === viewLanguage}
          />
        )}
      </div>
    );
  }
}

export default RankedEdit;
