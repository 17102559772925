import { takeLatest, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  fetchMediaListActions,
  saveMediaActions,
  deleteMediaActions,
  uploadLargeMediaActions
} from '../actions/mediaActions';
import { fetchMediaList, saveMedia, deleteMedia } from '../../../api/mediaApi';

function* mediaListSaga() {
  const saga = createFetchSaga(fetchMediaListActions, fetchMediaList);
  yield* saga();
}

function* reFetchMediaList({ payload }) {
  yield put(fetchMediaListActions.request(payload.pageRequest));
}

function* saveMediaSaga() {
  const saga = createFetchSaga(saveMediaActions, saveMedia);
  yield* saga();
  yield takeLatest(saveMediaActions.succeeded, reFetchMediaList);
}

function handleLargeMediaUploadSuccess({ payload }) {
  toast.success({ text: intl.get('app.media.uploaded', { name: payload.name }) });
}

function handleLargeMediaUploadFailure({ payload }) {
  if (payload.error.includes(403)) {
    toast.error({ text: intl.get('app.media.upload.malware', { name: payload.name }) });
  } else {
    toast.error({ text: intl.get('app.media.upload.failed', { name: payload.name }) });
  }
}

function handleDeleteMediaSuccess({ payload }) {
  payload.onDeleteMedia && payload.onDeleteMedia(payload);
}

function* uploadLargeMediaSaga() {
  yield takeLatest(uploadLargeMediaActions.succeeded, reFetchMediaList);
  yield takeLatest(uploadLargeMediaActions.succeeded, handleLargeMediaUploadSuccess);
  yield takeLatest(uploadLargeMediaActions.failed, handleLargeMediaUploadFailure);
}

function* deleteMediaSaga() {
  const saga = createFetchSaga(deleteMediaActions, deleteMedia);
  yield* saga();
  yield takeLatest(deleteMediaActions.succeeded, reFetchMediaList);
  yield takeLatest(deleteMediaActions.succeeded, handleDeleteMediaSuccess);
}

export { mediaListSaga, saveMediaSaga, deleteMediaSaga, uploadLargeMediaSaga };
