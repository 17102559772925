import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { accessHandler, Loader, NavTab } from 'webapp-common';
import appConfig from '../../../appConfig';
import ClientSettings from '../../clientAdmin/clientSettings';
import { ListRoles } from '../../clientAdmin/editRoles/ListRoles';
import ClientUsers from '../../clientAdmin/users';

import './OrgPage.css';

const SETTINGS_TAB = 'settings';
const ROLES_TAB = 'roles';
const USERS_TAB = 'users';

export const OrgPage = props => {
  const { client, abilities } = props;
  const clientId = client?.clientID;
  const { hasOrgView, hasRoleView, hasUserAccountsView } = abilities;

  const { tab: activeTab = SETTINGS_TAB } = useParams();

  function getUrl(tab) {
    return `${appConfig.orgPagePath}/${tab}`;
  }

  useEffect(() => {
    props.fetchClient();
  }, [activeTab]);

  const accessCheck = accessHandler.checkAccess(hasOrgView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  if (!client) {
    return <Loader spinner />;
  }

  return (
    <section className="app-body">
      <section className="left-nav">
        {hasOrgView && (
          <NavTab
            icon="fa-sliders-h"
            iconStyle="solid"
            label={intl.get('app.settings')}
            active={activeTab === SETTINGS_TAB}
            to={getUrl(SETTINGS_TAB)}
          />
        )}
        {hasUserAccountsView && (
          <NavTab
            icon="fa-users"
            iconStyle="solid"
            label={intl.get('app.users')}
            active={activeTab === USERS_TAB}
            to={getUrl(USERS_TAB)}
          />
        )}
        {hasRoleView && (
          <NavTab
            icon="fa-id-card"
            iconStyle="solid"
            label={intl.get('app.roles')}
            active={activeTab === ROLES_TAB}
            to={getUrl(ROLES_TAB)}
          />
        )}
      </section>
      <section className="org-admin">
        {activeTab === SETTINGS_TAB && <ClientSettings clientId={clientId} tab={SETTINGS_TAB} />}
        {activeTab === ROLES_TAB && <ListRoles />}
        {activeTab === USERS_TAB && <ClientUsers />}
      </section>
    </section>
  );
};
