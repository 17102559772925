import { handleActions } from 'redux-actions';
import { saveSurveyAsTemplateActions } from '../actions/surveyActions';

const initialState = {
  saveSurveyAsTemplateRequested: false
};

const surveyTemplateReducer = handleActions(
  {
    [saveSurveyAsTemplateActions.triggered](state) {
      return {
        name: state.name,
        templateAlreadyExist: null,
        templateCopied: null,
        saveSurveyAsTemplateRequested: true
      };
    },
    [saveSurveyAsTemplateActions.succeeded](state, { payload }) {
      return {
        ...payload,
        saveSurveyAsTemplateRequested: false
      };
    },
    [saveSurveyAsTemplateActions.failed](state) {
      return {
        name: state.name,
        templateAlreadyExist: null,
        templateCopied: null,
        saveSurveyAsTemplateRequested: false
      };
    }
  },
  initialState
);

export default surveyTemplateReducer;
