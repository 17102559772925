import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FilterLabel } from '../../../../researchDashboard/rdFilters/FilterLabel';

export const BannerFilters = props => {
  const { banner, bannerIndex, removeFilter, moveFilter } = props;

  function onDragEnd(result) {
    if (result.destination) {
      moveFilter(bannerIndex, result.source.index, result.destination.index);
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable-filters-${bannerIndex}`} direction="horizontal">
        {dropProvided => (
          <div
            style={{ display: 'flex', flexWrap: 'wrap', marginTop: '.25rem' }}
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            {banner.filters.map((filter, filterIndex) => (
              <Draggable
                key={`filter-${bannerIndex}-${filterIndex}`}
                draggableId={`filter-${bannerIndex}-${filterIndex}`}
                index={filterIndex}
              >
                {dragProvided => (
                  <div ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps}>
                    <FilterLabel name={filter.name} close={() => removeFilter(bannerIndex, filterIndex)} />
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
