import React from 'react';
import update from 'immutability-helper';
import { Input } from 'reactstrap';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual } from 'lodash';
import { FlexRow, InvokeModal } from 'webapp-common';
import { toast } from '../../util/toast';

import './ReportConfigNormsModal.css';

class ReportConfigNormsModal extends React.Component {
  state = {
    questions: cloneDeep(this.props.questions)
  };

  updateNorm = (norm, qIndex) => {
    let state = {};
    if (norm && (norm < 1 || norm > 100)) {
      return toast.error({ text: intl.get('app.norm.change.bounds') });
    }
    state = update(this.state, {
      questions: {
        [qIndex]: {
          answerData: { norm: { $set: parseInt(norm, 10) || null } }
        }
      }
    });
    this.setState(state);
  };

  displayQuestionNorms = questions => {
    return questions.map((q, qIndex) => {
      const category = q.category || intl.get('app.uncategorized');
      const label = q.answerData.row && q.answerData.row.length > 0 ? `${q.label} - ${q.answerData.row}` : q.label;
      return (
        <div key={category + qIndex}>
          {(qIndex === 0 || category !== questions[qIndex - 1].category) && (
            <h6 className={qIndex > 0 ? 'mt-4' : ''}>{category}</h6>
          )}
          <div className="row-answer-data">
            <span>{label}</span>
            <Input
              type="number"
              min={1}
              max={100}
              value={q.answerData.norm || ''}
              onChange={e => this.updateNorm(e.target.value, qIndex)}
            />
          </div>
        </div>
      );
    });
  };

  closeModal = () => {
    this.props.toggle();
  };

  saveNorms = norms => {
    this.props.saveNorms(this.state.questions);
    this.closeModal();
  };

  isSaveEnabled = () => {
    return !isEqual(this.state.questions, this.props.questions);
  };

  render() {
    const { questions } = this.state;
    return (
      <InvokeModal
        showModal={true}
        toggle={this.closeModal}
        modalTitle={intl.get('app.configureExternalNorms')}
        primaryButtonText={intl.get('app.save')}
        cancelButtonText={intl.get('app.cancel')}
        save={this.saveNorms}
        enableSave={this.isSaveEnabled()}
        className="custom-report-config report-config-norms-modal"
      >
        <section className="top-section">
          <FlexRow justifyContent="space-between">
            <h6 className="ms-4">{intl.get('app.dataPoint')}</h6>
            <h6 className="me-4">{intl.get('app.externalNorm')}, %</h6>
          </FlexRow>
        </section>
        <section className="main-section">
          <div className="ms-2">{this.displayQuestionNorms(questions)}</div>
        </section>
      </InvokeModal>
    );
  }
}

export default ReportConfigNormsModal;
