import React, { useEffect, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import { InvokeModal } from 'webapp-common';

import './ParticipantDataPicker.css';

const reducer = (state, payload) => ({ ...state, ...payload });

function getTableData(questionDataDefList) {
  return questionDataDefList.map(qDef => {
    const values = qDef.value ? qDef.value.map(v => v.answerText).join(', ') : [];
    return {
      key: qDef.varId || qDef.formId,
      label: qDef.questionTitle,
      value: values
    };
  });
}

export const ParticipantDataPicker = props => {
  const [state, setState] = useReducer(reducer, {
    selectedDataPoints: [],
    tableData: []
  });

  // Initialize state
  useEffect(() => {
    const tableData = getTableData(props.questionDataDefList);
    const selectedDataPoints = props.includeAllDataPoints ? tableData.map(d => d.key) : props.selectedDataPoints;
    setState({
      selectedDataPoints,
      tableData
    });
  }, [props.questionDataDefList]);

  const allSelected = state.tableData.length === state.selectedDataPoints.length;

  function selectDataPoint(key) {
    const selectedDataPoints = new Set(state.selectedDataPoints);
    selectedDataPoints.has(key) ? selectedDataPoints.delete(key) : selectedDataPoints.add(key);
    setState({
      selectedDataPoints: [...selectedDataPoints]
    });
  }

  function selectAllData() {
    setState({
      selectedDataPoints: allSelected ? [] : state.tableData.map(d => d.key)
    });
  }

  function save() {
    props.setSelectedDataPoints({
      includeAllDataPoints: allSelected,
      selectedDataPoints: allSelected ? [] : state.selectedDataPoints
    });
    props.closeModal();
  }

  function getTableRows() {
    return state.tableData.map(d => {
      return (
        <tr style={{ cursor: 'pointer' }} onClick={() => selectDataPoint(d.key)}>
          <td>
            <Input
              type="checkbox"
              checked={state.selectedDataPoints.includes(d.key)}
              onChange={() => selectDataPoint(d.key)}
            />
          </td>
          <td title={d.label}>{d.label}</td>
          <td className="text-truncate" title={d.value}>
            {d.value}
          </td>
        </tr>
      );
    });
  }

  const saveEnabled = state.selectedDataPoints.length !== 0;

  return (
    <InvokeModal
      showModal
      className="participant-data-picker-modal"
      modalTitle={intl.get('app.selectParticipantData')}
      primaryButtonText={intl.get('app.save')}
      cancelButtonText={intl.get('app.cancel')}
      toggle={props.closeModal}
      save={save}
      enableSave={saveEnabled}
    >
      <div>
        <table className="invoke-table">
          <thead>
            <tr>
              <th>
                <Input type="checkbox" checked={allSelected} onChange={selectAllData} />
              </th>
              <th>{intl.get('app.label')}</th>
              <th>{intl.get('app.values')}</th>
            </tr>
          </thead>
          <tbody>{getTableRows()}</tbody>
        </table>
      </div>
    </InvokeModal>
  );
};
