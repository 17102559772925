import { get, post, del } from '../util/request';

const SURVEY_TEMPLATES_API = '/a/api/surveyTemplates';
const SURVEYS_API = '/a/api/surveys';
const QUESTION_JOINERS_API = '/a/api/questionJoiners';
const SAVE_AND_DELETE_SURVEY_API = '/a/api/survey';
const GENERATE_SURVEY_API = '/a/api/generateSurveyContentReview';
const GENERATE_RAW_SURVEY_API = '/a/api/generateSurveyRawExport';
const COPY_TEMPLATE_API = '/a/api/copyTemplate';
const IMPORT_SURVEY_API = '/a/binapi/importSurvey';
const UPLOAD_TRANSLATIONS_API = '/a/binapi/uploadTranslations';
const DELETE_QUESTION_JOINERS_API = '/a/api/deleteQuestionJoiners';

async function fetchSurveyTemplates(apiOptions) {
  const params =
    (apiOptions && {
      pageNumber: apiOptions.pageNumber || 1,
      pageSize: apiOptions.pageSize || 10,
      sortBy: apiOptions.sortBy || 'modifiedDate',
      sortOrder: apiOptions.sortOrder || 'desc',
      name: apiOptions.name || '',
      isScreener: apiOptions.isScreener || false
    }) ||
    {};
  return get(SURVEY_TEMPLATES_API)(params).then(res => (res.body && res.body.payload) || []);
}

async function createSurveyTemplate({ surveyName, isScreener }) {
  const survey = {
    joiners: [],
    name: surveyName,
    surveyState: isScreener ? 'screener_template' : 'template'
  };
  return post(SURVEYS_API)(survey).then(res => {
    const payload = (res && res.body && res.body.payload) || {};
    payload.isScreener = isScreener;
    payload.survey = survey;
    return payload;
  });
}

/*
  payload = {
    surveyId: SurveyId for fetching,
    sessionId: sessionId for requesting session validation after the fetch. If you don't need session validation, don't pass this in.
  }
*/
async function fetchSurvey(payload) {
  const { surveyId, sessionId } = payload;
  const url = `${SURVEYS_API}/${surveyId}`;
  return get(url)().then(res => {
    const response = (res && res.body && res.body.payload) || {};
    response.sessionId = sessionId;
    return response;
  });
}

/*
  payload = {
    action: 'INSERT', 'EDIT', 'MOVE', or 'DELETE',
    conceptVisibility: Concept visibility map for CR joiners only,
    position: Position to insert or move joiner to,
    questionJoiner: Question joiner being updated,
    surveyId: surveyId,
    version: Survey version,
    sessionId: sessionId for requesting session validation after the save
  }
*/
async function saveQuestionJoiner(payload) {
  const { sessionId, projectId } = payload;
  return post(QUESTION_JOINERS_API)(payload).then(res => {
    const response = (res && res.body && res.body.payload) || {};
    response.sessionId = sessionId;
    response.projectId = projectId;
    return response;
  });
}

/*
  payload = {
    survey: Survey for saving
    sessionId: Optional sessionId for requesting session validation after the save
    projectId: Optional projectId for requesting project validation after the save
    suppressToast: Optional bool to not show the toast message
  }
*/
async function saveSurvey(payload) {
  const { sessionId, survey, projectId, suppressToast } = payload;
  return post(SAVE_AND_DELETE_SURVEY_API)(survey).then(res => {
    const response = (res && res.body && res.body.payload) || {};
    response.sessionId = sessionId;
    response.projectId = projectId;
    response.suppressToast = suppressToast;
    response.includeSessionCount = survey.includeSessionCount;
    return response;
  });
}

async function deleteSurvey(payload) {
  const { surveyId, deleteSession = false, isTemplate, session } = payload;
  const url = `${SAVE_AND_DELETE_SURVEY_API}/${surveyId}/${deleteSession}`;
  return del(url)({}).then(res => {
    const response = (res && res.body && res.body.payload) || {};
    response.isTemplate = isTemplate;
    response.deleteSession = deleteSession && !isTemplate;
    response.session = session;
    response.projectId = session && session.projectId;
    response.includeSessionCount = payload.includeSessionCount;
    return response;
  });
}

async function generateSurveyContentReview(payload) {
  return post(GENERATE_SURVEY_API)(payload).then(res => (res && res.body && res.body.payload) || {});
}

async function generateSurveyRawExport(payload) {
  return post(GENERATE_RAW_SURVEY_API)(payload).then(res => (res && res.body && res.body.payload) || {});
}

async function saveSurveyAsTemplate(payload) {
  const { name } = payload;
  return post(COPY_TEMPLATE_API)(payload).then(res => {
    const response = (res && res.body && res.body.payload) || {};
    response.name = name;
    return response;
  });
}

async function importSurvey({ sessionId, name, dataFile }) {
  const params = {
    sessionId,
    name,
    dataFile,
    useType: 'doc'
  };
  return post(IMPORT_SURVEY_API)(params).then(res => (res && res.body) || {});
}

async function uploadTranslations({ file, name, useType, sessionId }) {
  const params = {
    file,
    name,
    useType,
    sessionId,
    size: file.size
  };
  return post(UPLOAD_TRANSLATIONS_API)(params).then(res => res.body || {});
}

async function deleteSurveyQuestions({ surveyId }) {
  const url = `${DELETE_QUESTION_JOINERS_API}/${surveyId}`;
  return get(url)().then(res => (res && res.body && res.body.payload) || {});
}

export {
  fetchSurveyTemplates,
  createSurveyTemplate,
  fetchSurvey,
  saveQuestionJoiner,
  saveSurvey,
  generateSurveyContentReview,
  generateSurveyRawExport,
  saveSurveyAsTemplate,
  deleteSurvey,
  importSurvey,
  uploadTranslations,
  deleteSurveyQuestions
};
