import React from 'react';
import { idUtils } from './Id';
import moment from 'moment';
import { get, words } from 'lodash';

import conceptRotationIcon from '../images/concept-rotation-icon.png';
import dialsmithIcon from '../images/dialsmith-icon.png';
import matrixIcon from '../images/matrix-icon.png';
import multiIcon from '../images/multi-icon.png';
import openTextIcon from '../images/open-text-icon.png';
import rankedIcon from '../images/ranked-icon.png';
import textStimIcon from '../images/text-stim-icon.png';
import webcontentIcon from '../images/icons8-internet.png';
import pageIcon from '../images/page-icon.png';
import { Icons } from '../components/icons/Icons';

const DEFAULT = 'DEFAULT';
const TIME_FORMAT = 'mm:ss';
const HOUR_TIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_PAGE_SIZE = 10;
export const STRING_LABEL = 'stringlabel';

const questionJoinerIcons = {
  conceptRotation: conceptRotationIcon,
  matrix: matrixIcon,
  multi: multiIcon,
  open: openTextIcon,
  ranked: rankedIcon,
  text: textStimIcon,
  thirdparty: dialsmithIcon,
  webcontent: webcontentIcon,
  page: pageIcon
};

// From ResponseSet.java
export const RESPONSE_SET_TYPE = {
  open: 'open',
  matrix: 'matrix',
  multi: 'multi',
  ranked: 'ranked',
  thirdparty: 'thirdparty',
  virtualFocusGroup: 'virtualFocusGroup',
  // These are not from ResponseSet.java but are here for convenience.
  conceptRotation: 'conceptRotation',
  page: 'page'
};

// From TypeRef.java
export const ANSWER_TYPE = {
  string: 'STRING',
  boolean: 'BOOLEAN',
  double: 'DOUBLE',
  numeric: 'NUMERIC',
  alphanumeric: 'ALPHANUMERIC',
  alphaonly: 'ALPHAONLY',
  zipcode: 'ZIPCODE',
  email: 'EMAIL',
  notGibberish: 'NOTGIBBERISH'
};

// From Stim.java
export const STIM_TYPE = {
  text: 'text',
  image: 'image',
  video: 'video',
  thirdparty: 'thirdparty',
  webcontent: 'webcontent'
};

// From VideoStimOptions.java
export const VIDEO_STIM_OPTIONS = {
  showVideoControls: 'showVideoControls',
  showPlayPause: 'showPlayPause',
  showVolume: 'showVolume',
  closedCaptions: 'closedCaptions',
  fullScreen: 'fullScreen',
  detectRecordingDevice: 'detectRecordingDevice',
  detectRecordingDeviceAction: 'detectRecordingDeviceAction',
  detectMultipleViewers: 'detectMultipleViewers',
  detectMultipleViewersAction: 'detectMultipleViewersAction',
  detectEmotions: 'detectEmotions'
};

// From ExitPageType.java
export const EXIT_PAGE_TYPE = {
  DIALSMITH_COMPLETE: 'DIALSMITH_COMPLETE',
  DIALSMITH_CONTINUE_SESSION: 'DIALSMITH_CONTINUE_SESSION'
};

// from MediaType.java
export const MEDIA_TYPE = {
  image: 'Image',
  video: 'Video',
  webcontent: 'WebContent',
  thirdParty: 'ThirdParty'
};

// From MatrixType.java
export const MATRIX_TYPE = {
  singleChoice: 'SingleChoice',
  multipleChoice: 'MultipleChoice',
  forcedRank: 'ForcedRank'
};

export const MATRIX_CHOICES = {
  enterRows: 'enterRows',
  choiceAnswers: 'choiceAnswers',
  bipolar: 'bipolar'
};

export const REFERENCED_QUESTION = 'showSelectedChoicesFromJoinerOption';

// From DisplayLayout.java
export const DISPLAY_LAYOUT = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};

// From Option.java
export const OPTION_TYPE = {
  displayoption: 'displayoption',
  limitoption: 'limitoption',
  requiredoption: 'requiredoption',
  bipolar: 'bipolar'
};

export const REQUIRED_OPTION = {
  name: OPTION_TYPE.requiredoption,
  type: OPTION_TYPE.requiredoption
};

export const BIPOLAR_OPTION = {
  name: OPTION_TYPE.bipolar,
  type: OPTION_TYPE.bipolar
};

// From SurveyRuleType.java
export const SURVEY_RULE_TYPE = {
  terminationRule: 'TERMINATION_RULE',
  includeQuestionRule: 'INCLUDE_QUESTION_RULE',
  segmentationRule: 'SEGMENTATION_RULE',
  setVariableRule: 'SET_VARIABLE_RULE',
  setFlagRule: 'SET_FLAG_RULE'
};

// From RuleConditionType.java
export const RULE_CONDITION_TYPE = {
  questionCondition: 'QUESTION_CONDITION',
  lookupValueCondition: 'LOOKUP_VALUE_CONDITION',
  customFunctionCondition: 'CUSTOM_FUNCTION_CONDITION',
  participantMetadataCondition: 'PARTICIPANT_METADATA_CONDITION',
  participantFlagCondition: 'PARTICIPANT_FLAG_CONDITION'
};

// From Operator.java
export const OPERATORS = {
  eq: 'EQUAL',
  ne: 'NOT_EQUAL',
  lt: 'LT',
  lte: 'LTE',
  gt: 'GT',
  gte: 'GTE',
  startsWith: 'STARTS_WITH',
  endsWith: 'ENDS_WITH',
  contains: 'CONTAINS',
  containsAll: 'CONTAINS_ALL',
  doesNotContain: 'DOES_NOT_CONTAIN',
  in: 'IN',
  notIn: 'NOT_IN',
  isGibberish: 'IS_GIBBERISH',
  isIgnoreCase: 'IS_IGNORE_CASE',
  null: 'NULL',
  notNull: 'NOT_NULL'
};

export const ENGLISH = 'en';
// Supported languages. Also defined in Phoenix gulpfile.js
export const LANGUAGES = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  hy: 'Armenian',
  az: 'Azerbaijani',
  bn: 'Bengali',
  bs: 'Bosnian',
  bg: 'Bulgarian',
  ca: 'Catalan',
  zh: 'Chinese',
  'zh-TW': 'Chinese (Traditional)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  'fa-AF': 'Dari',
  nl: 'Dutch',
  en: 'English',
  et: 'Estonian',
  tl: 'Filipino, Tagalog',
  fi: 'Finnish',
  fr: 'French',
  'fr-CA': 'French (Canada)',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  gu: 'Gujarati',
  ht: 'Haitian Creole',
  ha: 'Hausa',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  id: 'Indonesian',
  ga: 'Irish',
  it: 'Italian',
  ja: 'Japanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  ko: 'Korean',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mk: 'Macedonian',
  ms: 'Maylay',
  ml: 'Malayalam (ml)',
  mt: 'Maltese (mt)',
  mr: 'Marathi (mr)',
  mn: 'Mongolian',
  no: 'Norwegian',
  fa: 'Persian',
  ps: 'Pashto',
  pl: 'Polish',
  pt: 'Portuguese',
  'pt-PT': 'Portuguese (Portugal) (pt)',
  pa: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  es: 'Spanish',
  'es-MX': 'Spanish (Mexico)',
  sw: 'Swahili',
  sv: 'Swedish',
  ta: 'Tamil',
  te: 'Telugu',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  cy: 'Welsh'
};

//Languages supported by Gibberish detection service
export const GIBBERISH_LANGUAGES = {
  [ENGLISH]: 'english'
};

export const isRequired = joiner => {
  const { options } = joiner.def.responseSet;
  if (!options || options.length === 0) {
    return false;
  }
  return options.some(o => o.type === OPTION_TYPE.requiredoption);
};

export const getAbbreviation = value => {
  return words(value)
    .map(word => word?.[0])
    .join('')
    .toUpperCase();
};

export const getDefaultChoice = (value, i, source) => {
  // source = 1: both view and language are in English
  // source = 2: view and language are different but it's viewing English
  // source = 3: both view and language are in a foreign language
  return {
    id: idUtils.getId(),
    index: i || 0,
    value: {
      entryType: DEFAULT,
      scalar: 0,
      type: STRING_LABEL,
      abbreviatedValue: source !== 3 ? getAbbreviation(value) : '',
      value: source && source !== 2 ? value || '' : '',
      origValue: source && source !== 3 ? value || '' : '',
      value2: source && source !== 2 ? value || '' : '',
      origValue2: source && source !== 3 ? value || '' : ''
    }
  };
};

export const getJoinerType = joiner => {
  const { def, stim, conceptRotation, container } = joiner;
  const { responseSet } = def || {};
  return (
    responseSet?.type ||
    stim?.type ||
    (conceptRotation && RESPONSE_SET_TYPE.conceptRotation) ||
    (container && RESPONSE_SET_TYPE.page)
  );
};

export const getJoinerTitle = (joiner, concept) => {
  const title = (joiner.stimOnly && joiner.stim.label) || joiner.researchPrompt || '';
  if (!concept?.title) {
    return title;
  }
  return title.substr(concept.title.length + 3);
};

// Removes the concept title in parens from a concept rotation joiner title
export const cleanJoinerTitle = title => {
  if (title && title.indexOf('(') === 0) {
    return title.replace(/^\(.+?\)/, '').trim();
  }
  return title;
};

export const getJoinerIcon = joiner => {
  if (joiner.emailCollector) {
    return <i className="joiner-icon fas fa-at" />;
  }
  if (get(joiner, 'def.responseSet.type') === RESPONSE_SET_TYPE.virtualFocusGroup) {
    return <Icons.VFGIcon className="joiner-icon" iconStyle="light" />;
  }
  const type = getJoinerType(joiner);
  const src = questionJoinerIcons[type];
  return <img className="joiner-icon" src={src} alt="" />;
};

// This should probably be in jsUtil.js or localtime.js
const padZero = number => (number < 10 ? `0${number}` : number);

export const getFormatedTime = (seconds, showHour) => {
  if (seconds < 0) {
    const pSec = seconds * -1;
    let hh = Math.floor(pSec / 3600);
    let mm = Math.floor((pSec - hh * 3600) / 60);
    let ss = pSec - hh * 3600 - mm * 60;
    hh = padZero(hh);
    mm = padZero(mm);
    ss = padZero(ss);
    return hh > 0 ? `-${hh}:${mm}:${ss}` : `-${mm}:${ss}`;
  }
  const duration = moment.duration({ seconds });
  const args = {
    hour: duration.hours(),
    minute: duration.minutes(),
    second: duration.seconds()
  };
  const format = args.hour === 0 && !showHour ? TIME_FORMAT : HOUR_TIME_FORMAT;
  return moment(args).format(format);
};

/*
 * Note: The total survey time is also calculated in SurveyTimingCalculator on the
 * back-end. Any changes made to this logic need to be applied there as well.
 */
export const getTotalTiming = (surveyJoiners, joiner, excludeLast) => {
  if (!surveyJoiners || surveyJoiners.length < 1) {
    if (joiner) {
      return excludeLast ? '00:00' : getFormatedTime(joiner.timingOptions.configuredTime);
    }
    return '00:00';
  }

  let length = -1;
  const index = joiner ? surveyJoiners.findIndex(j => j.id === joiner.id) : -999;

  if (!joiner) {
    length = surveyJoiners.length;
  } else {
    if (index > -1) {
      if (excludeLast) {
        if (index === 0) {
          return getFormatedTime(0);
        }
        if (index > 0) {
          length = index;
        }
      } else {
        if (index >= 0) {
          length = index + 1;
        }
      }
    } else {
      length = joiner.displayIndex;
    }
  }
  const joiners = joiner ? surveyJoiners.slice(0, length) : surveyJoiners;
  const totalTiming = joiners
    .filter(joiner => !joiner.hidden)
    .reduce((acc, joiner) => {
      const { conceptRotation } = joiner;
      if (conceptRotation) {
        const conceptTimings = conceptRotation.concepts.map(concept => {
          const conceptTiming = concept.joiners
            .filter(conceptJoiner => !conceptJoiner.hidden)
            .reduce((conceptAcc, conceptJoiner) => {
              return conceptAcc + conceptJoiner.timingOptions.configuredTime;
            }, 0);
          if (concept.startJoiner) {
            return conceptTiming + concept.startJoiner.timingOptions.configuredTime;
          }
          return conceptTiming;
        });
        let conceptRotationTiming = conceptTimings.reduce((acc, value) => acc + value, 0);
        const { exitStimJoiner, introStimJoiner, transitionalStimJoiner } = conceptRotation;
        if (exitStimJoiner) {
          conceptRotationTiming += exitStimJoiner.timingOptions.configuredTime;
        }
        if (introStimJoiner) {
          conceptRotationTiming += introStimJoiner.timingOptions.configuredTime;
        }
        if (transitionalStimJoiner) {
          const numOfRotation =
            conceptRotation.numOfRotation === 0 ? conceptRotation.concepts.length : conceptRotation.numOfRotation;
          const totalTransitions = numOfRotation > 0 ? numOfRotation - 1 : 0;
          conceptRotationTiming += transitionalStimJoiner.timingOptions.configuredTime * totalTransitions;
        }
        return acc + conceptRotationTiming;
      }
      if (!joiner.timingOptions) {
        return acc;
      }
      return acc + joiner.timingOptions.configuredTime;
    }, 0);
  return index === -1 && !excludeLast
    ? getFormatedTime(totalTiming + joiner.timingOptions.configuredTime)
    : getFormatedTime(totalTiming);
};

export const questionJoinerGenerator = ({
  stimType,
  responseSetType,
  surveyOptions,
  displayIndex,
  conceptRotationId,
  containerId
}) => {
  const joiner = {
    id: idUtils.getId(),
    displayIndex,
    conceptRotationId,
    containerId,
    researchPrompt: '',
    timingOptions: {
      timingEnabled: true,
      defaultTime: 60,
      configuredTime: 60
    }
  };
  if (stimType) {
    joiner.stim = {
      type: stimType
    };
    switch (stimType) {
      case STIM_TYPE.image:
        joiner.stim.media = { accessUrl: '' };
        break;
      case STIM_TYPE.text:
        joiner.stim.contents = '';
        break;
      case STIM_TYPE.thirdparty:
        joiner.def = {
          id: idUtils.getId(),
          responseSet: {
            id: idUtils.getId(),
            type: RESPONSE_SET_TYPE.thirdparty,
            expectedAnswerType: ANSWER_TYPE.boolean
          }
        };

        joiner.stim = {
          type: stimType,
          tpid: 1262016430,
          requireCallBack: true,
          exitPage: EXIT_PAGE_TYPE.DIALSMITH_COMPLETE,
          media: {
            accessUrl: '',
            accessUrlParameterMapping: {},
            type: MEDIA_TYPE.thirdParty
          }
        };
        break;
      default:
    }
  } else {
    joiner.def = { question: { prompt: '' } };
    let responseSet = {
      type: responseSetType,
      options: []
    };

    switch (responseSetType) {
      case RESPONSE_SET_TYPE.conceptRotation:
        joiner.conceptRotation = {
          concepts: []
        };
        break;
      case RESPONSE_SET_TYPE.page:
        joiner.container = true;
        break;
      case RESPONSE_SET_TYPE.ranked:
        responseSet = {
          ...responseSet,
          matrixType: capitalizeFirstLetter(RESPONSE_SET_TYPE.ranked),
          entries: {
            columnData: {
              columns: [getDefaultChoice('1')],
              type: RESPONSE_SET_TYPE.matrix
            },
            rangeLabels: [],
            rows: [getDefaultChoice()]
          }
        };
        break;
      case RESPONSE_SET_TYPE.matrix:
        responseSet = {
          ...responseSet,
          entries: {
            columnData: {
              columns: [getDefaultChoice()],
              type: RESPONSE_SET_TYPE.matrix
            },
            rangeLabels: [],
            rows: [getDefaultChoice()],
            bipolarRows: [getDefaultChoice()]
          }
        };
        break;
      case RESPONSE_SET_TYPE.multi:
        responseSet = {
          ...responseSet,
          choices: [getDefaultChoice()],
          options: [
            {
              displayType: 'Vertical',
              name: 'displayoption',
              type: 'displayoption'
            }
          ]
        };
        joiner.timingOptions.defaultTime = 25;
        joiner.timingOptions.configuredTime = 25;
        break;
      case RESPONSE_SET_TYPE.open:
        responseSet = {
          ...responseSet,
          sharedResponseOption: {
            maxExposures: 8,
            maxResponses: 10,
            minResponses: 30
          },
          expectedAnswerType: ANSWER_TYPE.string
        };
        break;
      case RESPONSE_SET_TYPE.virtualFocusGroup:
        responseSet = {
          ...responseSet,
          expectedAnswerType: ANSWER_TYPE.boolean,
          maxParticipants: 16
        };
        delete joiner.timingOptions;
        delete joiner.def.question;
        break;
      default:
    }
    if (responseSetType === RESPONSE_SET_TYPE.conceptRotation || responseSetType === RESPONSE_SET_TYPE.page) {
      delete joiner.def;
      delete joiner.timingOptions;
    } else {
      if (surveyOptions.allQuestionsRequired) {
        responseSet.options.push(REQUIRED_OPTION);
      }
      joiner.def.responseSet = responseSet;
    }
  }
  return joiner;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const UPDATE_HANDLER_TYPE = {
  dialConfig: 'videoDialConfig',
  dialConfigField: 'videoDialConfigField',
  dialActionButton: 'videoDialActionButton',
  addActionButton: 'addVideoDialActionButton',
  removeActionButton: 'removeVideoDialActionButton'
};

export const getDefaultVideoOptions = () => {
  return {
    showVideoControls: true,
    showPlayPause: true,
    showVolume: true,
    closedCaptions: true,
    fullScreen: false,
    dialConfig: {
      enabled: false,
      labelLeft: '',
      origLabelLeft: '',
      labelRight: '',
      origLabelRight: '',
      scaleLeft: 0,
      scaleRight: 100,
      actionButtons: []
    },
    detectRecordingDevice: false,
    detectRecordingDeviceAction: 'TERMINATE',
    detectMultipleViewers: false,
    detectMultipleViewersAction: 'TERMINATE',
    detectEmotions: false
  };
};

export const getQuestionPrompt = (joiner, language, viewLanguage) => {
  const { question = {} } = joiner?.def ?? {};
  return (language === viewLanguage ? question.prompt : question.origPrompt) || '';
};

export const getQuestionRichContents = (joiner, language, viewLanguage) => {
  const { question = {} } = joiner?.def ?? {};
  return (language === viewLanguage ? question.richContents : question.origRichContents) || '';
};

export const getStimCaption = (stim, language, viewLanguage) => {
  return (stim && (language === viewLanguage ? stim.caption : stim.origCaption)) || '';
};

export const getStimAltTag = (stim, language, viewLanguage) => {
  return (stim && (language === viewLanguage ? stim.altTag : stim.origAltTag)) || '';
};

export const getTextStimContents = (stim, language, viewLanguage) => {
  return (stim && (language === viewLanguage ? stim.contents : stim.origContents)) || '';
};

export const getTextStimRichContents = (stim, language, viewLanguage) => {
  return (stim && (language === viewLanguage ? stim.richContents : stim.origRichContents)) || '';
};

// Get the correct choice/row/column value for a choice, matrix, or ranked joiner
export const getChoiceValue = (choice, language, viewLanguage) => {
  return (language === viewLanguage ? choice.value.value : choice.value.origValue) || '';
};

// Get the correct row value2 (for a bipolar matrix)
export const getChoiceValue2 = (choice, language, viewLanguage) => {
  return (language === viewLanguage ? choice.value.value2 : choice.value.origValue2) || '';
};

// Helper function for setting values in Choice question, Matrix question and Ranked question choices
export const setChoiceValues = (language, viewLanguage, input, output, useValue2) => {
  if (language === viewLanguage) {
    output.value = input;
  }
  if (viewLanguage === ENGLISH) {
    output.origValue = input;
    if (!useValue2) {
      output.abbreviatedValue = getAbbreviation(input);
    }
  }
};

// Helper function for setting value2's in (bipolar) Matrix question
export const setChoiceValue2s = (language, viewLanguage, input, output) => {
  if (language === viewLanguage) {
    output.value2 = input;
  }
  if (viewLanguage === ENGLISH) {
    output.origValue2 = input;
    output.abbreviatedValue = input;
  }
};

export const getCustomChoice = (choice, language, viewLanguage) => {
  return (language === viewLanguage ? choice.value.value : choice.value.origValue) || '';
};

export const isBipolar = responseSet => {
  return responseSet?.options.find(opt => opt.type === 'bipolar') !== undefined;
};

export const isStimVideoOrImage = stim => {
  return stim && (stim.type === STIM_TYPE.video || stim.type === STIM_TYPE.image);
};

// Same logic as in SessionHandler.java
export const getFirstMedia = survey => {
  const { joiners } = survey || {};
  if (!joiners) {
    return null;
  }
  let joiner = joiners.find(j => j.conceptRotation);
  if (joiner) {
    // get a stim from the first concept: cr.concepts(0).startJoiner || cr.introJoiner
    const cr = joiner.conceptRotation;
    const concept = cr.concepts?.[0];
    const { startJoiner } = concept || {};
    let stim = startJoiner?.stim;
    if (isStimVideoOrImage(stim)) {
      return stim;
    }
    const { introStimJoiner } = cr;
    stim = introStimJoiner?.stim;
    if (isStimVideoOrImage(stim)) {
      return stim;
    }
  }

  // stand-alone media
  joiner = joiners.find(j => j.stimOnly && isStimVideoOrImage(j.stim));
  if (!joiner) {
    // still no media? find first question joiner with a media
    joiner = joiners.find(j => isStimVideoOrImage(j.stim));
  }

  return joiner?.stim ?? null;
};
