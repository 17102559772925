import React from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { accessHandler, NavTab } from 'webapp-common';
import appConfig from '../../../appConfig';
import ActiveProjectsPage from '../projectsPage/activeProjectsPage';
import ArchivedProjectsPage from '../projectsPage/archivedProjectsPage';

const ACTIVE_TAB = 'active';
const ARCHIVE_TAB = 'archived';

export const ProjectsHomePage = props => {
  const { hasProjectView } = props;

  const { projectId, tab: activeTab = ACTIVE_TAB } = useParams();

  const accessCheck = accessHandler.checkAccess(hasProjectView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <section className="app-body">
      <section className="left-nav">
        <NavTab
          icon="fa-bolt"
          iconStyle="solid"
          label={intl.get('app.active')}
          active={activeTab === ACTIVE_TAB}
          to={appConfig.projectsPath}
        />
        <NavTab
          icon="fa-archive"
          iconStyle="solid"
          label={intl.get('app.archived')}
          active={activeTab === ARCHIVE_TAB}
          to={appConfig.archivedProjectsPath}
        />
      </section>
      <section className="body-container l2-bg">
        {activeTab === ACTIVE_TAB && <ActiveProjectsPage projectId={projectId} />}
        {activeTab === ARCHIVE_TAB && <ArchivedProjectsPage />}
      </section>
    </section>
  );
};
