import { takeEvery, takeLatest, select } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { toast } from '../../../util/toast';
import createFetchSaga from './createFetchSaga';
import {
  emotionDataChannelSubscribeActions,
  emotionReportFilesChannelSubscribeActions,
  exportEmotionDataActions,
  fetchEmotionSettingConfigActions,
  saveEmotionSettingConfigActions
} from '../actions/emotionDataActions';
import { subscribeToChannel } from '../../../util/websocket';
import { sessionUserSelector } from '../selectors/sessionUserSelector';
import { generateReport } from '../../../api/reportsApi';
import { fetchEmotionSettingConfig, saveEmotionSettingConfig } from '../../../api/emotionSettingConfigApi';

function* emotionDataChannelSubscribe({ payload: { subAction, sessionId, joinerId } }) {
  yield* subscribeToChannel({
    action: 'emotions',
    subAction,
    key: `${sessionId}_${joinerId}`,
    sessionId,
    joinerId
  });
}

function* emotionReportFilesChannelSubscribe({ payload: { subAction, rdConfig } }) {
  yield* subscribeToChannel({
    action: 'emotionReportFiles',
    subAction,
    key: `${rdConfig.sessionId}`,
    rdConfig
  });
}

function* handleExportEmotionDataRequested({ payload }) {
  const state = yield select();
  const sessionUser = sessionUserSelector(state).sessionUser;
  const email = (sessionUser && sessionUser.email) || '';
  toast.success({ text: intl.get('app.emotionReport.mail', { mail: email, ref: payload.id }) });
}

function* emotionDataExportSaga() {
  const saga = createFetchSaga(exportEmotionDataActions, generateReport);
  yield* saga();
  yield takeLatest(exportEmotionDataActions.succeeded, handleExportEmotionDataRequested);
}

function* emotionReportFilesChannelSubscribeSaga() {
  yield takeEvery(emotionReportFilesChannelSubscribeActions.request, emotionReportFilesChannelSubscribe);
}

function* emotionDataChannelSubscribeSaga() {
  yield takeEvery(emotionDataChannelSubscribeActions.request, emotionDataChannelSubscribe);
}

function* fetchEmotionSettingConfigSaga() {
  const saga = createFetchSaga(fetchEmotionSettingConfigActions, fetchEmotionSettingConfig);
  yield* saga();
}

function* saveEmotionSettingConfigSaga() {
  const saga = createFetchSaga(saveEmotionSettingConfigActions, saveEmotionSettingConfig);
  yield* saga();
}

export {
  emotionDataChannelSubscribeSaga,
  emotionReportFilesChannelSubscribeSaga,
  emotionDataExportSaga,
  fetchEmotionSettingConfigSaga,
  saveEmotionSettingConfigSaga
};
