// This file will have all the API's for participant data and participant data def for a given session id

import { get, post } from '../util/request';

const PARTICIPANTS_ANSWERS_TOTAL = '/a/api/participantAnswersResponseTotals';
const PARTICIPANTS_ANSWERS_SUMMARY = '/a/api/participantResponseSummary';
const GROUPING_PARTICIPANTS = '/a/api/participantGroup';
const VIRTUAL_FOCUS_GROUP_API = '/a/api/virtualFocusGroupStatus';
const END_VFG_API = '/a/api/endVFGMeeting';

async function fetchParticipantDataDef(sessionId) {
  const url = `/a/api/participantDataDef/${sessionId}`;
  return get(url)().then(res => (res.body && res.body.payload && { sessionId, defs: res.body.payload }) || {});
}

/*
 * Note that entityId can be a single ID or comma-separated list of IDs
 */
async function fetchParticipantData(opts) {
  const params =
    (opts && {
      pageNumber: opts.pageNumber || 1,
      pageSize: opts.pageSize || 10,
      sortBy: opts.sortBy || '',
      sortOrder: opts.sortOrder || 'asc',
      activeOnly: opts.activeOnly,
      searchString: opts.searchString || ''
    }) ||
    {};
  const { entityId, entityType = 'metadata_collection', participantType = 'participant' } = opts;
  const url = `/a/api/participantList/${entityType}/${entityId}/${participantType}`;
  return get(url)(params).then(
    res => (res.body && res.body.payload && { entityId, participantData: res.body.payload }) || {}
  );
}

async function fetchEnrollmentInfo({ metaDataCollectionId }) {
  return get(`/a/api/enrollmentInfo/${metaDataCollectionId}`)().then(
    res => (res.body && res.body.payload && { metaDataCollectionId, enrollmentInfo: res.body.payload }) || {}
  );
}

async function saveParticipantActive({ participantId, active, entityId, activeOnly, sessionId }) {
  return post('/a/api/participantActive')({
    participantId,
    sessionId,
    active
  }).then(res => res.body && res.body.payload && { entityId, activeOnly, payload: res.body.payload });
}

async function fetchScreenerPreviewResults({ sessionId, participantId }) {
  const url = `/a/api/screenerPreviewResults/${sessionId}/${participantId}`;
  return get(url)().then(
    res => (res.body && res.body.payload && { sessionId, screenerPreviewResults: res.body.payload }) || {}
  );
}

async function fetchScreenersAnswersTotal({ sessionId }) {
  const url = `${PARTICIPANTS_ANSWERS_TOTAL}/${sessionId}`;
  return get(url)().then(
    res => (res.body && res.body.payload && { sessionId, screenersAnswersTotal: res.body.payload }) || {}
  );
}

async function fetchParticipantsAnswersSummary(sessionId, joinerIds) {
  const url = `${PARTICIPANTS_ANSWERS_SUMMARY}/${sessionId}/${joinerIds}`;
  return get(url)().then(
    res => (res.body && res.body.payload && { sessionId, participantsAnswersSummary: res.body.payload.responses }) || {}
  );
}

async function setParticipantGroup(params) {
  return post(GROUPING_PARTICIPANTS)(params).then(res => res.body && res.body.payload);
}

async function setVFGstatus(params) {
  return post(VIRTUAL_FOCUS_GROUP_API)(params).then(res => res.body && res.body.payload);
}

async function endVFGMeetingApi(params) {
  return post(END_VFG_API)(params).then(res => res.body && res.body.payload);
}

export {
  fetchParticipantDataDef,
  fetchParticipantData,
  fetchEnrollmentInfo,
  saveParticipantActive,
  fetchScreenerPreviewResults,
  fetchScreenersAnswersTotal,
  fetchParticipantsAnswersSummary,
  setParticipantGroup,
  setVFGstatus,
  endVFGMeetingApi
};
