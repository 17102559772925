import React from 'react';
import intl from 'react-intl-universal';
import { InvokeModal } from 'webapp-common';
import conceptRotationIcon from '../../../../../images/concept-rotation-icon.png';
import dialsmithIcon from '../../../../../images/dialsmith-icon.png';
import libraryIcon from '../../../../../images/question-library-icon.png';
import matrixIcon from '../../../../../images/matrix-icon.png';
import multiIcon from '../../../../../images/multi-icon.png';
import openTextIcon from '../../../../../images/open-text-icon.png';
import mediaIcon from '../../../../../images/media-icon.png';
import rankedIcon from '../../../../../images/ranked-icon.png';
import textStimIcon from '../../../../../images/text-stim-icon.png';
import pageIcon from '../../../../../images/page-icon.png';
import { Icons } from '../../../../../components/icons/Icons';
import { RESPONSE_SET_TYPE, STIM_TYPE } from '../../../../../util/joinerUtil';

import './AddToSurveyModal.css';

const AddToSurveyModal = ({
  toggle,
  addToSurvey,
  handleQuestionLibraryClick,
  isConceptRotation,
  isScreener,
  isPageContainer,
  isVirtualFocusGroup
}) => {
  const hideVFG = isConceptRotation || isScreener || isPageContainer;

  return (
    <InvokeModal
      showModal
      toggle={toggle}
      modalTitle={intl.get('app.title.addToSurvey')}
      cancelButtonText={intl.get('app.cancel')}
    >
      <div className="add-to-survey-modal-container">
        <h6 className="my-2 mb-4">
          <b>{intl.get('app.label.question')}:</b>
        </h6>
        <div className="flex-row">
          <div onClick={() => addToSurvey(RESPONSE_SET_TYPE.multi)} className="border rounded border-secondary">
            <img className="joiner-icon" src={multiIcon} alt="" />
            <div className="text-center small">{intl.get('app.questionType.multi')}</div>
          </div>
          <div onClick={() => addToSurvey(RESPONSE_SET_TYPE.matrix)} className="border rounded border-secondary">
            <img className="joiner-icon" src={matrixIcon} alt="" />
            <div className="text-center small">{intl.get('app.questionType.matrix')}</div>
          </div>
          {!isVirtualFocusGroup && (
            <div onClick={() => addToSurvey(RESPONSE_SET_TYPE.open)} className="border rounded border-secondary">
              <img className="joiner-icon" src={openTextIcon} alt="" />
              <div className="text-center small">{intl.get('app.questionType.open')}</div>
            </div>
          )}
          {isScreener ? (
            <div onClick={() => addToSurvey(STIM_TYPE.text, true)} className="border rounded border-secondary">
              <img className="joiner-icon" src={textStimIcon} alt="" />
              <div className="text-center small">{intl.get('app.questionType.standAloneText')}</div>
            </div>
          ) : (
            <div onClick={() => addToSurvey(RESPONSE_SET_TYPE.ranked)} className="border rounded border-secondary">
              <img className="joiner-icon" src={rankedIcon} alt="" />
              <div className="text-center small">{intl.get('app.questionType.ranked')}</div>
            </div>
          )}
        </div>

        {!isScreener && (
          <>
            <h6 className="mb-4 mt-4">
              <b>{intl.get('app.label.stimulus')}:</b>
            </h6>
            <div className="flex-row">
              {!isConceptRotation && (
                <div onClick={() => addToSurvey(STIM_TYPE.image, true)} className="border rounded border-secondary">
                  <img src={mediaIcon} className="joiner-icon joiner-media-icon" alt="" />
                  <div className="text-center small mt-1">{intl.get('app.title.media')}</div>
                </div>
              )}
              <div onClick={() => addToSurvey(STIM_TYPE.text, true)} className="border rounded border-secondary">
                <img className="joiner-icon" src={textStimIcon} alt="" />
                <div className="text-center small">{intl.get('app.questionType.standAloneText')}</div>
              </div>
              {!isConceptRotation && !isPageContainer && (
                <div
                  onClick={() => addToSurvey(RESPONSE_SET_TYPE.conceptRotation)}
                  className="border rounded border-secondary"
                >
                  <img className="joiner-icon" src={conceptRotationIcon} alt="" />
                  <div className="text-center small">{intl.get('app.questionType.conceptRotation')}</div>
                </div>
              )}
              {!isConceptRotation && !isPageContainer && (
                <div
                  onClick={() => addToSurvey(STIM_TYPE.thirdparty, true)}
                  className="border rounded border-secondary"
                >
                  <img className="joiner-icon" src={dialsmithIcon} alt="" />
                  <div className="text-center small">{intl.get('app.questionType.dialSmithVideo')}</div>
                </div>
              )}
            </div>
          </>
        )}

        <>
          <div className="flex-row space-between">
            <div style={{ flexShrink: 'inherit', width: 'auto' }}>
              <h6 className="mb-4 mt-4">
                <b>{intl.get('app.library')}:</b>
              </h6>
              <div className="border rounded border-secondary" onClick={handleQuestionLibraryClick}>
                <img className="joiner-icon" src={libraryIcon} alt="" />
                <div className="text-center small">{intl.get('app.questionLibrary')}</div>
              </div>
            </div>
            {!isConceptRotation && !isPageContainer && (
              <div style={{ flexShrink: 'inherit', width: 'auto' }}>
                <h6 className="mb-4 mt-4">
                  <b>{intl.get('app.page')}:</b>
                </h6>
                <div className="border rounded border-secondary" onClick={() => addToSurvey(RESPONSE_SET_TYPE.page)}>
                  <img className="joiner-icon" src={pageIcon} alt="" />
                  <div className="text-center small">{intl.get('app.questionPage')}</div>
                </div>
              </div>
            )}
            <div style={{ visibility: hideVFG ? 'hidden' : 'visible', flexShrink: 'inherit', width: 'auto' }}>
              <h6 className="mb-4 mt-4">
                <b>{intl.get('app.Vis-a-Vis')}:</b>
              </h6>
              <div
                className="border rounded border-secondary"
                onClick={() => addToSurvey(RESPONSE_SET_TYPE.virtualFocusGroup)}
              >
                <div className="joiner-icon">
                  <Icons.VFGIcon iconStyle="light" size={1.5} />
                </div>
                <div className="text-center small">{intl.get('app.Vis-a-Vis')}</div>
              </div>
            </div>
          </div>
        </>
      </div>
    </InvokeModal>
  );
};

export default AddToSurveyModal;
