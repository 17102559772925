import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { accessHandler, InvokeTable, SearchInput, usePagination } from 'webapp-common';
import { fetchRolesSummaryAction } from '../../../store/redux/actions/clientAdminActions';
import { tenantRolesSelector } from '../../../store/redux/selectors/clientAdminTenantRolesSelector';
import { useSessionUserSelector } from '../../../customHooks/reduxHelper';
import EditRoles from './index';

const PAGE_SIZE = 10;
const DEFAULT_SORT_FIELD = 'roleLabel';
const DEFAULT_SORT_ORDER = 'asc';
const ADD_ROLE = true;
const EDIT_ROLE = !ADD_ROLE;

const reducer = (state, payload) => {
  return { ...state, ...payload };
};

export const ListRoles = props => {
  const roles = useSelector(state => tenantRolesSelector()(state), shallowEqual);
  const { tenantRoles = {} } = roles;
  const { roleUpdateSuccess } = tenantRoles;
  const { pageRequest, totalElements } = tenantRoles.summary;

  const { hasRoleManage, hasRoleView } = useSessionUserSelector().abilities;

  const [state, setState] = useReducer(reducer, {
    roleLabel: '',
    showRole: false,
    tenantRoleId: '',
    roleAdd: false
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchRolesSummaryAction.request({
        pageNum: 1,
        pageSize: PAGE_SIZE,
        sortBy: DEFAULT_SORT_FIELD,
        sortOrder: DEFAULT_SORT_ORDER
      })
    );
  }, []);

  useEffect(() => {
    if (roleUpdateSuccess) {
      dispatch(
        fetchRolesSummaryAction.request({
          pageNum: 1,
          pageSize: PAGE_SIZE,
          sortBy: DEFAULT_SORT_FIELD,
          sortOrder: DEFAULT_SORT_ORDER
        })
      );
      toggleRoleModal();
    }
  }, [roleUpdateSuccess]);

  function addRole() {
    toggleRoleModal('addRole', ADD_ROLE);
  }

  const toggleRoleModal = useCallback(
    (tenantRoleId, roleAdd) => {
      setState({
        tenantRoleId,
        showRole: !state.showRole,
        roleAdd
      });
    },
    [state.showRole]
  );

  const formatLabel = useCallback(
    info => {
      const role = info.row.original;
      const { roleLabel, tenantRoleId } = role;
      return (
        <span
          onClick={() => {
            toggleRoleModal(tenantRoleId, EDIT_ROLE);
          }}
          className="link"
          title={roleLabel}
        >
          {roleLabel}
        </span>
      );
    },
    [toggleRoleModal]
  );

  const sort = useCallback(
    ({ sortBy, sortOrder }) => {
      dispatch(
        fetchRolesSummaryAction.request({
          pageNum: 1,
          pageSize: pageRequest.pageSize,
          sortBy,
          sortOrder,
          searchString: state.roleLabel
        })
      );
    },
    [dispatch, state.roleLabel, pageRequest.pageSize]
  );

  const paginate = useCallback(
    ({ pageNumber, pageSize, sortBy, sortOrder }) => {
      dispatch(
        fetchRolesSummaryAction.request({
          pageNum: pageNumber,
          pageSize,
          sortBy: sortBy || DEFAULT_SORT_FIELD,
          sortOrder: sortOrder || DEFAULT_SORT_ORDER,
          searchString: state.roleLabel
        })
      );
    },
    [dispatch, state.roleLabel]
  );

  const handleSearchInput = useCallback(
    e => {
      setState({ roleLabel: e.target.value });
      dispatch(
        fetchRolesSummaryAction.request({
          pageNum: 1,
          pageSize: pageRequest.pageSize,
          sortBy: pageRequest.sortBy || DEFAULT_SORT_FIELD,
          sortOrder: pageRequest.sortOrder || DEFAULT_SORT_ORDER,
          searchString: e.target.value
        })
      );
    },
    [dispatch, pageRequest.pageSize, pageRequest.sortBy, pageRequest.sortOrder]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: DEFAULT_SORT_FIELD,
        header: intl.get('app.name'),
        cell: formatLabel,
        cellClassName: 'text-truncate'
      },
      {
        accessorKey: 'userCount',
        header: intl.get('app.user.count')
      }
    ],
    [formatLabel]
  );

  const pagination = usePagination({ pageRequest, totalElements });

  const accessCheck = accessHandler.checkAccess(hasRoleView);
  if (accessCheck !== true) {
    return accessCheck;
  }

  return (
    <div className="body-container l2-bg client-roles">
      {hasRoleManage && (
        <Button color="primary" onClick={addRole}>
          {intl.get('app.add.role')}
        </Button>
      )}
      <SearchInput placeholder={intl.get('app.search')} onChange={handleSearchInput} className="my-4" />
      <div style={{ maxWidth: '50%' }}>
        <InvokeTable
          className="invoke-table"
          columns={columns}
          data={tenantRoles.summary.content}
          initialState={{
            sorting: [
              {
                id: DEFAULT_SORT_FIELD,
                desc: false
              }
            ]
          }}
          loading={tenantRoles.rolesSummaryRequested}
          pagination={pagination}
          onSortingChange={sort}
          onPaginationChange={paginate}
        />
      </div>
      {state.showRole && (
        <EditRoles tenantRoleId={state.tenantRoleId} roleAdd={state.roleAdd} toggle={toggleRoleModal} />
      )}
    </div>
  );
};
