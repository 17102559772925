import React, { memo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Switch } from 'antd';
import intl from 'react-intl-universal';
import moment from 'moment';
import { isEqual } from 'lodash';
import {
  getRDQuestionJoiners,
  getRDQuestionJoinerResponseRates
} from '../../../../store/redux/selectors/researchDashboardSelector';

import './TimeRemainingWidget.css';

function getResponseRate(questionJoinerResponseRates, joiner) {
  return questionJoinerResponseRates[joiner.id] || {};
}

/**
 * Return the timeSpan (timeRemaining or timeElapsed) string for the UI.
 * @param timeSpan in seconds
 * @param total time in minutes
 */
function getTimeSpanString(seconds, total) {
  const timeSpanPercent = seconds > 0 && total > 0 ? Math.round((seconds / total) * 100) : 0;
  const duration = moment.duration({ seconds });
  const minutes = Math.ceil(duration.asMinutes());
  const human = `${minutes} minutes`;
  return <span>{`${human} (${timeSpanPercent}%)`}</span>;
}

// Note: The total survey time is also calculated in the Survey Content code on the back-end.
// Any changes made to this logic need to be applied there as well.

function calculateConceptRotationTiming({ joiner, responseRate }) {
  const { conceptRotation } = joiner;
  if (!conceptRotation.concepts) {
    return 0;
  }

  const conceptTimings = conceptRotation.concepts.map(concept => {
    const conceptTiming = concept.joiners
      .filter(j => !j.hidden)
      .reduce((conceptAcc, j) => {
        return conceptAcc + j.timingOptions.configuredTime;
      }, 0);
    if (concept.startJoiner) {
      return conceptTiming + concept.startJoiner.timingOptions.configuredTime;
    }
    return conceptTiming;
  });

  const { position, positionAnswerPcts } = responseRate;
  const answerPercentsExist = positionAnswerPcts && positionAnswerPcts.some(val => val > 0); // positionAnswerPcts && check was not in Firefly
  const totalRotations = answerPercentsExist ? positionAnswerPcts.length : conceptRotation.concepts.length;
  let totalRotationTiming, remainingRotationsTiming;
  if (answerPercentsExist) {
    // Once the survey is underway, we estimated the time remaining using the longest concept time.
    // This is not perfect, but it's the best we can do without jumping through hoops.
    const longestConceptTiming = Math.max.apply(null, conceptTimings);
    const remainingRotations = totalRotations - position;
    totalRotationTiming = longestConceptTiming * totalRotations;
    remainingRotationsTiming = longestConceptTiming * remainingRotations;
  } else {
    // Survey hasn't started yet.
    totalRotationTiming = conceptTimings.reduce((acc, value) => acc + value, 0);
    remainingRotationsTiming = totalRotationTiming;
  }

  const { exitStimJoiner, introStimJoiner, transitionalStimJoiner } = conceptRotation;
  if (exitStimJoiner) {
    remainingRotationsTiming += !!remainingRotationsTiming ? exitStimJoiner.timingOptions.configuredTime : 0;
    totalRotationTiming += exitStimJoiner.timingOptions.configuredTime;
  }
  if (introStimJoiner) {
    remainingRotationsTiming += !!remainingRotationsTiming ? introStimJoiner.timingOptions.configuredTime : 0;
    totalRotationTiming += introStimJoiner.timingOptions.configuredTime;
  }
  if (transitionalStimJoiner) {
    const numOfRotations =
      conceptRotation.numOfRotation === 0 ? conceptRotation.concepts.length : conceptRotation.numOfRotation;
    const totalTransitions = numOfRotations > 0 ? numOfRotations - 1 : 0;
    remainingRotationsTiming += !!remainingRotationsTiming
      ? transitionalStimJoiner.timingOptions.configuredTime * totalTransitions
      : 0;
    totalRotationTiming += transitionalStimJoiner.timingOptions.configuredTime * totalTransitions;
  }

  return {
    totalRotationTiming,
    remainingRotationsTiming
  };
}

/*
 * Calculates the estimated time remaining and time elapsed by looking for all active, pending and completed questions and summing their display times accordingly.
 */
function calculateEstimatedTimes(questionJoiners, questionJoinerResponseRates) {
  let estTimeRemaining = 0,
    estTimeElapsed = 0;
  questionJoiners
    .filter(joiner => !joiner.hidden)
    .forEach(joiner => {
      const responseRate = getResponseRate(questionJoinerResponseRates, joiner);
      if (joiner.conceptRotation) {
        const { totalRotationTiming, remainingRotationsTiming } = calculateConceptRotationTiming({
          joiner,
          responseRate
        });
        estTimeRemaining += remainingRotationsTiming;
        estTimeElapsed += totalRotationTiming - remainingRotationsTiming;
      } else {
        const questionStatus = responseRate.joinerStatus;
        if (questionStatus === 'pending') {
          estTimeRemaining += joiner.displayTimeInSecs;
        } else if (questionStatus === 'completed' || questionStatus === 'active') {
          estTimeElapsed += joiner.displayTimeInSecs;
        }
      }
    });
  return {
    estTimeRemaining: estTimeRemaining || 0,
    estTimeElapsed: estTimeElapsed || 0
  };
}

function calculateData(questionJoiners, questionJoinerResponseRates) {
  const { estTimeRemaining, estTimeElapsed } = calculateEstimatedTimes(questionJoiners, questionJoinerResponseRates);
  return {
    estTimeRemaining,
    estTimeElapsed,
    estTimeTotal: estTimeRemaining + estTimeElapsed
  };
}

function skipUpdate(prevProps, nextProps) {
  return isEqual(prevProps.session, nextProps.session);
}

export const TimeRemainingWidget = memo(props => {
  const { sessionId } = props;

  const questionJoiners = useSelector(state => getRDQuestionJoiners(state, sessionId), shallowEqual);
  const questionJoinerResponseRates = useSelector(
    state => getRDQuestionJoinerResponseRates(state, sessionId),
    shallowEqual
  );

  const [showElapsed, setShowElapsedInState] = useState(false);

  const getDisplayString = function () {
    const data = calculateData(questionJoiners, questionJoinerResponseRates);
    const estTimeSpan = showElapsed ? data.estTimeElapsed : data.estTimeRemaining;
    return getTimeSpanString(estTimeSpan, data.estTimeTotal);
  };

  return (
    <div className="rd-time-remaining-widget">
      <i className="fa fa-clock clock" />
      <div className="time-span">{getDisplayString()}</div>
      <Switch
        className="time-remaining-switch"
        checkedChildren={intl.get('app.remaining')}
        unCheckedChildren={intl.get('app.elapsed')}
        checked={!showElapsed}
        onChange={() => setShowElapsedInState(!showElapsed)}
      />
    </div>
  );
}, skipUpdate);
